// Step 1: Import the S3Client object and all necessary SDK commands.
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

// Step 2: The s3Client function validates your request and directs it to your Space's specified endpoint using the AWS SDK.
const s3Client = new S3Client({
  endpoint: 'https://nyc3.digitaloceanspaces.com', // Find your endpoint in the control panel, under Settings. Prepend "https://".
  region: 'nyc3', // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_KEY, // Access key pair. You can create access key pairs using the control panel or API.
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET, // Secret access key defined through an environment variable.
  },
})

function getSuffix(filename) {
  // console.log('GETSUFFIX', filename)
  const pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos !== -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}

function getFileName(filename) {
  return filename.substring(0, filename.lastIndexOf('.'))
}

const uploadFile = async (formData) => {
  // console.log(formData)

  const files = {}
  const promises = []
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of formData.entries()) {
    // console.log(pair)
    const suffix = getSuffix(pair[1].name || pair[1].path || pair[1].filename)
    const fileName = getFileName(pair[1].name || pair[1].path || pair[1].filename)
    const time = new Date().getTime()
    const pathname = `${fileName}_${time}${suffix}`

    // Step 3: Define the parameters for the object you want to upload.
    const params = {
      Bucket: process.env.REACT_APP_SPACES_BUCKET || 'beni-development', // The path to the directory you want to upload the object to, starting with your Space name.
      Key: pathname, // Object key, referenced whenever you want to access this file later.
      Body: pair[1].rawFile || pair[1], // The object's contents. This variable is an object, not a string.
      ACL: 'public-read', // Defines ACL permissions, such as private or public.
      // Metadata: { // Defines metadata tags.
      //   "x-amz-meta-my-key": "your-value"
      // }
    }

    // Step 4: Define a function that uploads your object using SDK's PutObjectCommand object and catches any errors.
    const uploadObject = async () => {
      try {
        const data = await s3Client.send(new PutObjectCommand(params))
        promises.push(data)
        // console.log(data)
        // console.log('Successfully uploaded object: ' + params.Bucket + '/' + params.Key)
        // https://beni-development.nyc3.digitaloceanspaces.com/worm_holy_hand_grenade_1665506346712.png
        // beni-development/worm_holy_hand_grenade_1665506346712.png
        return `https://${params.Bucket}.nyc3.digitaloceanspaces.com/${params.Key}`
      } catch (err) {
        console.log('Error', err)
        throw new Error(err)
      }
    }

    // Step 5: Call the uploadObject function.
    const resp = await uploadObject()

    // console.log(resp)
    files[pair[0]] = {
      url: resp,
    }
  }
  await Promise.all(promises)
  return {
    data: {
      files,
    },
  }
}
export default uploadFile
