import React, { useState } from 'react'
import {
  useDataProvider,
  Create,
  FormWithRedirect,
  TextInput,
  DateInput,
  required,
  SimpleFormIterator,
  // AutocompleteInput,
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  useRefresh,
} from 'react-admin'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import AutocompleteInput from '../../components/AutocompleteInput'
import { getDisplayName } from '../../../utils/utils'

const useStyles = makeStyles(() => ({
  dateInputNoMargin: {
    minWidth: '110px',
  },
  dateInput: {
    marginLeft: '10px',
    minWidth: '110px',
  },
  textInput: {
    marginLeft: '10px',
    minWidth: '220px',
  },
  autocompleteInput: {
    marginLeft: '10px',
    minWidth: '220px',
    display: 'flex',
    padding: '0px',
  },
  popper: {
    minWidth: '220px',
  },
  popperList: {
    fontSize: '12px',
    minWidth: '200px',
  },
}))

const CreateDialog = (props) => {
  const dataProvider = useDataProvider()
  const { createOpen, setCreateOpen } = props
  const refresh = useRefresh()
  const [saving, setSaving] = useState(false)

  const handleSubmit = async (submission) => {
    setSaving(true)
    // console.log(submission)
    await dataProvider.createMany('tasks', {
      data: submission.tasks.map((task) => ({
        project_id: task.project?.id,
        name: task.name,
        assigned_to: task.assigned_to,
        deadline_date: task.deadline_date,
        status: task.status,
        received_date: task.received_date,
      })),
    })
    setSaving(false)
    refresh()
    setCreateOpen(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={createOpen || false}
      onClose={() => setCreateOpen(false)}
      aria-label="CreateTasks"
    >
      <DialogTitle>Create Tasks(s)</DialogTitle>
      <DialogContent>
        <Create resource="tasks" basePath="/tasks">
          <FormWithRedirect
            initialValues={{ tasks: [{}] }}
            {...props}
            render={() => (
              <div>
                {createOpen ? (
                  <CreateTasksPopupForm handleSubmit={handleSubmit} saving={saving} />
                ) : null}
              </div>
            )}
          />
        </Create>
      </DialogContent>
    </Dialog>
  )
}

const CreateTasksPopupForm = ({ handleSubmit, saving }) => {
  const classes = useStyles()
  const { values } = useFormState()

  // console.log(values)

  return (
    <Box>
      <ArrayInput source="tasks" label="">
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          addButton={
            <Button
              style={{
                backgroundColor: '#F8F8FA',
                color: 'black',
              }}
            >
              <AddIcon />
              Add another task
            </Button>
          }
          removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, rest }) => (
              <Box display="flex">
                <DateInput
                  className={classes.dateInputNoMargin}
                  variant="standard"
                  fullWidth
                  source={getSource('received_date')}
                  {...rest}
                  label="Received date"
                  record={scopedFormData}
                  validate={required()}
                />
                <TextInput
                  className={classes.textInput}
                  variant="standard"
                  fullWidth
                  source={getSource('name')}
                  {...rest}
                  label="Task"
                  record={scopedFormData}
                  validate={required()}
                />
                <AutocompleteInput
                  variant="standard"
                  fullWidth
                  label="Project"
                  reference="projects"
                  source={getSource('project')}
                  {...rest}
                  record={scopedFormData}
                  className={classes.autocompleteInput}
                  classes={{ listbox: classes.popperList, popper: classes.popper }}
                />
                <ReferenceInput
                  reference="users"
                  sort={{ field: 'id', order: 'DESC' }}
                  alwaysOn
                  label="Assigned to"
                  style={{ marginLeft: '10px', minWidth: '220px' }}
                  variant="standard"
                  source={getSource('assigned_to')}
                  {...rest}
                  record={scopedFormData}
                >
                  <SelectInput optionText={(record) => getDisplayName(record)} fullWidth />
                </ReferenceInput>
                <TextInput
                  style={{ marginLeft: '10px', minWidth: '80px' }}
                  variant="standard"
                  fullWidth
                  source={getSource('status')}
                  {...rest}
                  record={scopedFormData}
                  label="Status"
                  validate={required()}
                />
                <DateInput
                  className={classes.dateInput}
                  variant="standard"
                  fullWidth
                  source={getSource('deadline_date')}
                  {...rest}
                  label="Deadline date"
                  record={scopedFormData}
                  validate={required()}
                />
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="80px">
        <Button
          className={classes.createButton}
          disabled={saving}
          onClick={async () => {
            await handleSubmit(values)
          }}
        >
          CREATE
        </Button>
      </Box>
    </Box>
  )
}

export default CreateDialog
