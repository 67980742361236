import React, { useState, useEffect } from 'react'
import { useNotify, useDataProvider } from 'react-admin'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import lodashGet from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CachedIcon from '@material-ui/icons/Cached'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { useFormState, useForm } from 'react-final-form'
import useStyles from './styles'
import NumberFieldInput from './fieldInputs/NumberFieldInput2'
import NumberFormat from './CustomNumberFormat'
import SelectFieldInput from './fieldInputs/SelectFieldInput'
import { roundTo } from '../../utils/utils'
import useLazyEffect from '../../utils/useLazyEffect'

const StyledTableCell = withStyles(() => ({
  head: {
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ClientQuotePackagingSizesTable = () => {
  const classes = useStyles()
  const notify = useNotify()
  const form = useForm()
  const dataProvider = useDataProvider()

  const [firstRenderDone, setFirstRenderDone] = useState(false)

  const { values } = useFormState()
  const [editing, setEditing] = useState()
  const [saving, setSaving] = useState()

  // console.log('CQPKGSIZETBLVALS', values)

  const formChange = (field, value) => {
    const oldValue = lodashGet(values, field)
    // console.log(field, 'oldValue', oldValue, 'newValue', value)
    if (oldValue != value) {
      form.change(field, value)
    }
  }

  const groupByChoices = []
  values.product_quote?.allow_order_splitting_by_colour || !values.allow_order_splitting_by_size
    ? groupByChoices.push({ name: 'Colour', value: 'Colour' })
    : null
  values.product_quote?.allow_order_splitting_by_size
    ? groupByChoices.push({ name: 'Size', value: 'Size' })
    : null

  const calcShippingCosts = async () => {
    const defaultOption = values.packagingSizes.find(
      (opt) => opt.id === values.default_packaging_option_id,
    )
    const defaultIndex = values.packagingSizes.findIndex(
      (opt) => opt.id === values.default_packaging_option_id,
    )
    // console.log('oldDefault', values.packagingSizes[defaultIndex])

    await calcShippingCostRow({ pkgOpt: defaultOption, index: defaultIndex })

    // console.log('newdefault', values.packagingSizes[defaultIndex])

    await Promise.all(
      values.packagingSizes
        .filter((opt) => opt.id !== values.default_packaging_option_id)
        .map((pkgOpt) => {
          calcShippingCostRow({ pkgOpt, index: pkgOpt.rowIndex })
        }),
    )
  }

  const calcShippingCostRow = async ({ pkgOpt, index }) => {
    const debugMode = false
    const freightRateAir = values.freightRateAir
    const freightRateSea = values.freightRateSea
    const dutyRate = values.dutyRate / 100 + 1
    const seaIncludesDuties = values.does_sea_freight_include_duties
    debugMode && console.log(pkgOpt, index)

    const cartonLength = pkgOpt.carton_dimensions?.length / 100
    const cartonWidth = pkgOpt.carton_dimensions?.width / 100
    const cartonHeight = pkgOpt.carton_dimensions?.height / 100
    const grossWeight = pkgOpt.carton_dimensions?.gross_weight / 100
    const piecesPerCarton = pkgOpt.pieces_per_carton
    const lclSeaBuffer = 1 + pkgOpt.lclSeaBuffer / 100 || 1
    const lclAirBuffer = 1 + pkgOpt.lclAirBuffer / 100 || 1

    debugMode && console.log('-------PACKAGING SHIPPING RATES---------')
    debugMode && console.log(`------------${index}----------------`)
    debugMode && console.log('-----------------------------')
    debugMode && console.log('GW:', grossWeight)
    debugMode && console.log('CTNLTH:', cartonLength)
    debugMode && console.log('CTNWTH:', cartonWidth)
    debugMode && console.log('CTNHGT:', cartonHeight)
    debugMode && console.log('FREIGHTRATEAIR', freightRateAir)
    debugMode && console.log('FREIGHTRATESEA', freightRateSea)
    debugMode && console.log('DTYR:', dutyRate)
    debugMode && console.log('PPC', piecesPerCarton)
    debugMode && console.log('SEABUFFER', lclSeaBuffer)
    debugMode && console.log('AIRBUFFER', lclAirBuffer)

    if (
      pkgOpt.available &&
      piecesPerCarton &&
      dutyRate &&
      freightRateAir &&
      freightRateSea &&
      grossWeight
    ) {
      /////////   CHARGEABLE SEA
      const seaWeight =
        parseInt((((cartonLength * cartonWidth * cartonHeight) / 6000) * 100).toFixed(2)) || 0

      const chargeableSeaWeight = seaWeight / 100 > grossWeight ? seaWeight / 100 : grossWeight

      debugMode && console.log('ChargeableSea', chargeableSeaWeight)

      const baseSeaLCL = roundTo(
        ((chargeableSeaWeight * freightRateSea) / piecesPerCarton) * lclSeaBuffer,
        2,
      )
      debugMode && console.log('BaseSeaLCL', baseSeaLCL)

      const seaLCL = roundTo(seaIncludesDuties ? baseSeaLCL : baseSeaLCL * dutyRate, 2)
      debugMode && console.log('SeaLCL', seaLCL)

      /////////   CHARGEABLE AIR
      const airWeight =
        parseInt((((cartonLength * cartonWidth * cartonHeight) / 5000) * 100).toFixed(2)) || null

      const chargeableAirWeight = airWeight / 100 > grossWeight ? airWeight / 100 : grossWeight
      debugMode && console.log('ChargeableAir', chargeableAirWeight)

      const airLCL = roundTo(
        ((chargeableAirWeight * freightRateAir) / piecesPerCarton) * lclAirBuffer,
        2,
      )
      debugMode && console.log('AirLCL', airLCL)

      form.batch(() => {
        formChange(`packagingSizes[${index}].lclSea`, Number(seaLCL))
        formChange(`packagingSizes[${index}].lclAir`, Number(airLCL))
      })

      const defaultPackagingOption = values.packagingSizes.find(
        (opt) => opt.id === values.default_packaging_option_id,
      )

      if (pkgOpt.id === defaultPackagingOption?.id) {
        form.batch(() => {
          formChange(`packagingSizes[${index}].seaModifier`, 0)
          formChange(`packagingSizes[${index}].airModifier`, 0)
        })
      } else {
        debugMode && console.log('DEFAULTPKG', defaultPackagingOption)

        debugMode && console.log('OPT', pkgOpt)
        if (defaultPackagingOption) {
          const defaultPriceSea = defaultPackagingOption?.lclSea || 0

          const defaultPriceAir = defaultPackagingOption?.lclAir || 0
          form.batch(() => {
            formChange(`packagingSizes[${index}].seaModifier`, seaLCL - defaultPriceSea)
            formChange(`packagingSizes[${index}].airModifier`, airLCL - defaultPriceAir)
          })
        }
      }
    }
  }

  useLazyEffect(
    () => {
      const calcRow = async () => {
        values.packagingSizes
          .filter((el) => el.available) // && el.id !== values.default_packaging_option_id)
          .map((item) => calcShippingCostRow({ pkgOpt: item, index: item.rowIndex }))
      }
      firstRenderDone && calcRow()
    },
    [
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclSea
        ? values.packagingSizes[
            values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)
              ?.rowIndex
          ]?.lclSea
        : null,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclAir
        ? values.packagingSizes[
            values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)
              ?.rowIndex
          ]?.lclAir
        : null,
    ],
    ['LCLSea', 'LCLAir'],
  )

  useLazyEffect(() => {
    setFirstRenderDone(true)
  }, [])

  return (
    <Box
      style={{
        marginBottom: '20px',
        borderRadius: '6px',
      }}
    >
      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}> Available Packaging & Product Sizes</span>
        {editing ? (
          <div>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
            <IconButton
              onClick={async () => {
                // console.log(values)
                setSaving(true)

                try {
                  // loop through pkg options
                  await Promise.all(
                    values.packagingSizes?.map(async (pkgSize, index) => {
                      // console.log('pkgSize', pkgSize)
                      // if (available) {
                      if (pkgSize.available) {
                        // if (id is number) update pkg option, update size option
                        if (typeof pkgSize.id === 'number') {
                          const pkgParams = {
                            id: pkgSize.pkgId,
                            price_modifier: Math.round(pkgSize.packagingModifier * 100) || 0,
                            moq: pkgSize.packagingMOQ || 0,
                            ...(pkgSize?.lclAir && { air_lcl: Math.round(pkgSize.lclAir * 100) }),
                            ...(pkgSize?.lclSea && { sea_lcl: Math.round(pkgSize.lclSea * 100) }),
                            air_modifier: Math.round(pkgSize.airModifier * 100),
                            sea_modifier: Math.round(pkgSize.seaModifier * 100),
                            sea_buffer: Math.round(pkgSize.lclSeaBuffer * 100),
                            air_buffer: Math.round(pkgSize.lclAirBuffer * 100),
                          }
                          await dataProvider.update('scm_quote_packaging_options', {
                            data: pkgParams,
                          })
                          const sizeParams = {
                            id: pkgSize.sizeId,
                            price_modifier: Math.round(pkgSize.sizeModifier * 100) || 0,
                            moq: pkgSize.sizeMOQ || 0,
                          }
                          await dataProvider.update('scm_quote_product_sizes', { data: sizeParams })

                          if (pkgSize.default) {
                            // console.log('saving default', pkgSize)
                            await dataProvider.update('scm_quote', {
                              data: {
                                id: values.id,
                                default_packaging_option_id: pkgSize.id,
                              },
                            })
                            form.batch(() => {
                              formChange('default_packaging_option', {
                                id: pkgSize.id,
                                scm_quote_packaging_option: pkgSize,
                              })
                              formChange('default_packaging_option_id', pkgSize.id)
                            })
                          }
                        } else {
                          // else create new pkg option , create new size option, create pkg / size relationship

                          // console.log(values.packagingSizes)
                          // console.log(pkgSize.pkgId)

                          const existingPkg = values.packagingSizes?.find(
                            (item) =>
                              item.packagingFactoryReferenceId === pkgSize.pkgId &&
                              typeof item.id === 'number',
                          )

                          // console.log('ExistingPkg', existingPkg)

                          const pkgParams = {
                            price_modifier: Math.round(pkgSize.packagingModifier * 100) || 0,
                            moq: pkgSize.packagingMOQ || 0,
                            ...(pkgSize?.lclAir && { air_lcl: Math.round(pkgSize.lclAir * 100) }),
                            ...(pkgSize?.lclSea && { sea_lcl: Math.round(pkgSize.lclSea * 100) }),
                            ...(pkgSize.airModifier && {
                              air_modifier: Math.round(pkgSize.airModifier * 100),
                            }),
                            ...(pkgSize.seaModifier && {
                              sea_modifier: Math.round(pkgSize.seaModifier * 100),
                            }),
                            scm_quote_id: values.id,
                            carton_dimensions_id: pkgSize.carton_dimensions_id,
                            packaging_dimensions_id: pkgSize.packaging_dimensions_id,
                            type: pkgSize.packagingType,
                            label: pkgSize.packagingLabel,
                            pieces_per_carton: pkgSize.pieces_per_carton,
                            product_quote_packaging_option_id: pkgSize.packagingFactoryReferenceId,
                          }
                          const pkgResp = !existingPkg
                            ? await dataProvider.create('scm_quote_packaging_options', {
                                data: pkgParams,
                                on_conflict: {
                                  constraint:
                                    'scm_quote_packaging_options_scm_quote_id_product_quote_packagin',
                                  update_columns: ['moq', 'pieces_per_carton', 'label'],
                                },
                              })
                            : { data: { id: existingPkg.pkgId } }

                          const existingSize = values.packagingSizes?.find(
                            (item) =>
                              item.sizeFactoryReferenceId === pkgSize.sizeId &&
                              typeof item.id === 'number',
                          )

                          // console.log('ExistingSize', existingSize)

                          const sizeParams = {
                            price_modifier: Math.round(pkgSize.sizeModifier * 100) || 0,
                            moq: pkgSize.sizeMOQ || 0,
                            scm_quote_id: values.id,
                            dimensions_id: pkgSize.product_dimensions_id,
                            name: pkgSize.sizeName,
                            short_name: pkgSize.sizeShortName,
                            sequence: pkgSize.sequence,
                            product_quote_product_size_id: pkgSize.sizeFactoryReferenceId,
                          }
                          const sizeResp = !existingSize
                            ? await dataProvider.create('scm_quote_product_sizes', {
                                data: sizeParams,
                                on_conflict: {
                                  constraint:
                                    'scm_quote_product_sizes_scm_quote_id_product_quote_product_size',
                                  update_columns: ['moq', 'name', 'short_name'],
                                },
                              })
                            : { data: { id: existingSize.sizeId } }

                          const pkgSizeResp = await dataProvider.create(
                            'scm_quote_product_size_packagings',
                            {
                              data: {
                                scm_quote_product_size_id: sizeResp.data.id,
                                scm_quote_packaging_option_id: pkgResp.data.id,
                              },
                              on_conflict: {
                                constraint:
                                  'scm_quote_product_size_packagings_scm_quote_product_size_id_scm',
                                update_columns: [
                                  'scm_quote_product_size_id',
                                  'scm_quote_packaging_option_id',
                                ],
                              },
                            },
                          )
                          // update form ids
                          form.batch(() => {
                            formChange(`packagingSizes[${index}].pkgId`, pkgResp.data.id)
                            formChange(`packagingSizes[${index}].sizeId`, sizeResp.data.id)
                            formChange(`packagingSizes[${index}].id`, pkgSizeResp.data.id)
                          })

                          if (pkgSize.default) {
                            // console.log('saving default new', pkgSizeResp.data.id)
                            await dataProvider.update('scm_quote', {
                              data: {
                                id: values.id,
                                default_packaging_option_id: pkgSizeResp.data.id,
                              },
                            })
                            form.batch(() => {
                              formChange('default_packaging_option', {
                                id: pkgSizeResp.data.id,
                                scm_quote_packaging_option: pkgSize,
                              })
                              formChange('default_packaging_option_id', pkgSizeResp.data.id)
                            })
                          }
                        }
                      } else {
                        // } else { // not available
                        if (typeof pkgSize.id === 'number') {
                          // if (id is number) delete pkg / size relationship, delete pkg option, delete size option
                          // update / remove form ids
                          await dataProvider.delete('scm_quote_product_size_packagings', {
                            id: pkgSize.id,
                          })
                          await dataProvider.delete('scm_quote_product_sizes', {
                            id: pkgSize.sizeId,
                          })
                          await dataProvider.delete('scm_quote_packaging_options', {
                            id: pkgSize.pkgId,
                          })
                          form.batch(() => {
                            formChange(`packagingSizes[${index}].id`, pkgSize.id.toString())
                            formChange(`packagingSizes[${index}].sizeId`, pkgSize.sizeId.toString())
                            formChange(`packagingSizes[${index}].pkgId`, pkgSize.pkgId.toString())
                          })
                        }
                      }
                    }),
                  )
                  setEditing(false)
                  setSaving(false)
                  notify('Saved Successfully.', { type: 'info' })
                } catch (error) {
                  setEditing(false)
                  setSaving(false)
                  notify(error.message.split('.')[0], { type: 'warning' })
                }
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>

            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <div style={{ width: '100%', height: '20px' }} />

      <Box display="flex">
        <Box flex={2}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ fontSize: '12px', padding: '10px', backgroundColor: 'lightgray' }}
                    align="center"
                    colSpan={10}
                  >
                    <Box display="flex">
                      <Box flex={1} style={{ textAlign: 'right' }} />
                      <span style={{ flex: 1 }}>Shipping Details</span>
                      <Box flex={1}>
                        <IconButton
                          disabled={!editing}
                          onClick={() => {
                            calcShippingCosts()
                            if (!values.default_packaging_option_id) {
                              notify(
                                'No default packaging option is set, modifiers not calculated.',
                                {
                                  type: 'info',
                                },
                              )
                            }
                          }}
                          style={{ margin: '0px', maxWidth: '20px', maxHeight: '20px' }}
                        >
                          <CachedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    style={{
                      width: '2%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    Ref
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '6%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    Pkg Type
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '6%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    Product Size
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '4%', fontSize: '10px', padding: '6px' }}
                    align="left"
                  >
                    Default
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '7%',
                      fontSize: '10px',
                      padding: '6px',
                      // backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    LCL Sea Buffer
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '7%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    LCL Sea/Unit
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '7%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    Sea Modifier
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '7%',
                      fontSize: '10px',
                      padding: '6px',
                      // backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    LCL Air Buffer
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '7%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    LCL Air/Unit
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: '7%',
                      fontSize: '10px',
                      padding: '6px',
                      backgroundColor: 'lightgray',
                    }}
                    align="left"
                  >
                    Air Modifier
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.packagingSizes
                  .filter((item) => item.available)
                  ?.map((item) => (
                    <ShippingDetailsRow
                      key={`pkgSzRow${item.id}`}
                      index={item.rowIndex}
                      id={item.id}
                      editing={editing}
                      calcShippingCostRow={calcShippingCostRow}
                      item={item}
                      formChange={formChange}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box flex={1} />
      </Box>

      <div style={{ width: '100%', height: '20px' }} />

      <TableContainer component={Paper}>
        <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ fontSize: '12px', padding: '10px', backgroundColor: 'lightgray' }}
                align="center"
                colSpan={8}
              >
                Factory Details
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: '12px', padding: '10px' }}
                align="center"
                colSpan={6}
              >
                Client Details
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  width: '2%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Ref
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '2%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Pcs/Ctn
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '6%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Pkg Type
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '6%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Product Size
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '4%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Packaging MOQ
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Packaging Price
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '4%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Size MOQ
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Size Cost
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '4%', fontSize: '10px', padding: '6px' }}
                align="left"
              >
                Available
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '6%', fontSize: '10px', padding: '6px' }}
                align="left"
              >
                Packaging MOQ
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '7%', fontSize: '10px', padding: '6px' }}
                align="left"
              >
                Packaging Price
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '6%', fontSize: '10px', padding: '6px' }}
                align="left"
              >
                Size MOQ
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '7%', fontSize: '10px', padding: '6px' }}
                align="left"
              >
                Size Price
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.packagingSizes?.map((item, index) => (
              <PackagingSizesRow
                key={`pkgSzRow${item.id}`}
                index={index}
                id={item.id}
                editing={editing}
                formChange={formChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ width: '100%', height: '30px' }} />
      <Box display="flex">
        <NumberFieldInput
          className={classes.fieldInputLeft}
          label="Standard Colour MOQ:"
          value={values?.standard_colour_option_moq}
          resource="scm_quote"
          field="standard_colour_option_moq"
          formField="standard_colour_option_moq"
          defaultValue={0}
          decimalScale={0}
          fixedDecimalScale
          // suffix=""
          id={values.id}
        />
        <NumberFieldInput
          className={classes.fieldInputLeft}
          label="Standard Colour Price Modifier:"
          value={values?.standard_colour_option_price_modifier}
          resource="scm_quote"
          field="standard_colour_option_price_modifier"
          formField="standardColourPriceModifier"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values.id}
        />
        <SelectFieldInput
          variant="standard"
          field="order_group_by"
          resource="scm_quote"
          id={values.id}
          label="Order Form Grouped By"
          choices={groupByChoices}
          value={
            values?.groupByChoices < 2 ? groupByChoices[0].value : values.order_group_by || 'Colour'
          }
          optionText="name"
          optionValue="value"
          style={{ width: '240px', minWidth: '240px' }}
        />
      </Box>
      <Box display="flex">
        <span style={{ fontSize: '10px' }} className={classes.fieldInputLeft}>
          Factory: {values.product_quote?.standard_colour_moq}
        </span>
        <span style={{ fontSize: '10px', marginLeft: '90px' }} className={classes.fieldInputLeft}>
          Factory: ${(values.product_quote?.standard_colour_cost_modifier / 100).toFixed(2)}
        </span>
      </Box>
    </Box>
  )
}

const PackagingSizesRow = ({ index, editing, id, formChange }) => {
  const classes = useStyles()
  // const dataProvider = useDataProvider()

  const { values } = useFormState()
  // const form = useForm()

  // console.log(index)
  // console.log(values.packagingSizes[index])

  // const [seaInfoOpen, setSeaInfoOpen] = useState(false)
  // const [infoOpen, setInfoOpen] = useState(false)
  return (
    <TableRow key={`pkgSzRow${index}`}>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <span>{id}</span>
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <span>{values.packagingSizes[index].pieces_per_carton}</span>
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <span>
          {values.packagingSizes[index].packagingType}
          {values.packagingSizes[index].packagingLabel
            ? ` (${values.packagingSizes[index].packagingLabel})`
            : null}
        </span>
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <span>
          {values.packagingSizes[index].sizeShortName
            ? values.packagingSizes[index].sizeShortName
            : values.packagingSizes[index].sizeName}
        </span>
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px', backgroundColor: 'lightgray' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled
          source={`packagingSizes[${index}].packagingFactoryMOQ`}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px', backgroundColor: 'lightgray' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          source={`packagingSizes[${index}].packagingFactoryModifier`}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled
          source={`packagingSizes[${index}].sizeFactoryMOQ`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].sizeFactoryModifier`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="center"
        style={{
          padding: '0px',
        }}
      >
        <Checkbox
          disabled={!editing}
          checked={values.packagingSizes[index].available}
          onChange={() => {
            // console.log('changing', values.packagingSizes[index])
            formChange(
              `packagingSizes[${index}].available`,
              !values.packagingSizes[index].available,
            )
          }}
          inputProps={{ 'aria-label': 'option available checkbox' }}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`packagingSizes[${index}].packagingMOQ`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          prefix="$ "
          source={`packagingSizes[${index}].packagingModifier`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`packagingSizes[${index}].sizeMOQ`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          prefix="$ "
          source={`packagingSizes[${index}].sizeModifier`}
        />
      </BodyTableCell>
    </TableRow>
  )
}

const ShippingDetailsRow = ({ index, id, editing, calcShippingCostRow, item, formChange }) => {
  const classes = useStyles()
  // const dataProvider = useDataProvider()
  const [firstRenderDone, setFirstRenderDone] = useState(false)

  const { values } = useFormState()
  const form = useForm()

  // console.log(firstRenderDone)

  useLazyEffect(
    () => {
      const calcRow = async () => {
        // console.log('calculating...', values, item, item.rowIndex)
        await calcShippingCostRow({ pkgOpt: item, index: item.rowIndex })
      }
      // console.log(values.packagingSizes[index].lclAir, values.packagingSizes[index].lclSea)
      firstRenderDone && calcRow()
    },
    [
      values.packagingSizes[index].lclAirBuffer ? values.packagingSizes[index].lclAirBuffer : null,
      values.packagingSizes[index].lclSeaBuffer ? values.packagingSizes[index].lclSeaBuffer : null,
    ],
    ['LCLAirBuffer', 'LCLSeaBuffer'],
  )

  useLazyEffect(() => {
    setFirstRenderDone(true)
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  // console.log(index)

  // const [seaInfoOpen, setSeaInfoOpen] = useState(false)
  // const [infoOpen, setInfoOpen] = useState(false)
  return (
    <TableRow key={`pkgSzRow${index}`}>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <Tooltip
          title={`${item.carton_dimensions.length / 100}x${item.carton_dimensions.height / 100}x${
            item.carton_dimensions.width / 100
          }, ${item.carton_dimensions.gross_weight / 100}kg, ${item.pieces_per_carton}pcs`}
        >
          <span>{id}</span>
        </Tooltip>
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <span>
          {values.packagingSizes[index].packagingType}
          {values.packagingSizes[index].packagingLabel
            ? ` (${values.packagingSizes[index].packagingLabel})`
            : null}
        </span>
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px 10px', backgroundColor: 'lightgray' }}>
        <span>
          {values.packagingSizes[index].sizeShortName
            ? values.packagingSizes[index].sizeShortName
            : values.packagingSizes[index].sizeName}
        </span>
      </BodyTableCell>
      <BodyTableCell
        align="center"
        style={{
          padding: '0px',
        }}
      >
        <Checkbox
          disabled={!editing || !values.packagingSizes[index].available}
          checked={values.packagingSizes[index].default}
          onChange={() => {
            formChange('default_packaging_option_id', values.packagingSizes[index].id)
            // console.log('changing', values.packagingSizes[index])
            values.packagingSizes
              .filter((obj) => obj.available)
              ?.map((opt, ind) => {
                if (opt.id === values.packagingSizes[index].id) {
                  form.batch(() => {
                    formChange(`packagingSizes[${ind}].default`, true)
                    formChange(`default_packaging_option`, opt)
                  })
                } else {
                  formChange(`packagingSizes[${ind}].default`, false)
                }
              })
          }}
          inputProps={{ 'aria-label': 'option available checkbox' }}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          // backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          suffix="%"
          source={`packagingSizes[${index}].lclSeaBuffer`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].lclSea`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].seaModifier`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          // backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          suffix="%"
          source={`packagingSizes[${index}].lclAirBuffer`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].lclAir`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].airModifier`}
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default ClientQuotePackagingSizesTable
