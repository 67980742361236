import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const sourcingRequestFilesCreateMany = ({ context, params, variables }) => {
  console.log(params, variables)
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables,
    query: gql`
            mutation insertSRFiles {
              insert_sourcing_request_file(
                ${jsonParams}
              ) {
                returning {
                  id
                  type
                  sourcing_request_id
                  file {
                    id
                    filename
                    url
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_sourcing_request_file?.returning) {
        return {
          data: data.insert_sourcing_request_file.returning,
        }
      }
      return false
    },
  }
}

export const sourcingRequestCriteriaCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
        mutation insertManySourcingRequestCriteria {
          insert_sourcing_request_criteria(
            ${jsonParams}
          ) {
            returning {
              id
              criteria
              value
              sourcing_request_id
              type
              description
            }
          }
        }
      `,
    parseResponse: ({ data }) => {
      if (data && data.insert_sourcing_request_criteria?.returning) {
        return {
          data: data.insert_sourcing_request_criteria.returning,
        }
      }
      return false
    },
  }
}

export const sourcingRequestReferenceCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
        mutation insertManySourcingRequestReference {
          insert_sourcing_request_reference(
            ${jsonParams}
          ) {
            returning {
              id
              url
              brand
              reason
              sourcing_request_id
            }
          }
        }
      `,
    parseResponse: ({ data }) => {
      if (data && data.insert_sourcing_request_reference?.returning) {
        return {
          data: data.insert_sourcing_request_reference.returning,
        }
      }
      return false
    },
  }
}

export const sourcingRequestRecommendationCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
        mutation insertManySourcingRequestRecommendations {
          insert_sourcing_request_recommendation(
            ${jsonParams}
          ) {
            returning {
              id
              description
              recommendation_id
              recommendation {
                id
                name
              }
              sourcing_request_id
            }
          }
        }
      `,
    parseResponse: ({ data }) => {
      if (data && data.insert_sourcing_request_recommendation?.returning) {
        return {
          data: data.insert_sourcing_request_recommendation.returning,
        }
      }
      return false
    },
  }
}
