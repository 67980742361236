// import UserIcon from '@material-ui/icons/AccountBox'
import UserIcon from '@material-ui/icons/PermIdentity';
import UserList from './UserList'
import UserEdit from './UserEdit'
// import UserCreate from './UserCreate'

export default {
  list: UserList,
  // create: UserCreate,
  edit: UserEdit,
  icon: UserIcon,
}
