import React from 'react'
import { required, TextInput, ReferenceInput, SelectInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import ItemTitle from '../../components/ItemTitle'
import ProfileIcon from '../../../assets/profile/profile_icon.png'
// import ProfileUpdateIcon from '../../../assets/headerUpdateIcon.png'

const useStyles = makeStyles({
  hideLabel: {
    display: 'none',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageRemove: {
    '& .MuiButtonBase-root': {
      position: 'absolute',
      top: '0px',
      left: '120px',
      zIndex: '100',
    },
  },
  imagePreview: {
    position: 'absolute',
    top: '0px',
    left: '-10px',
    '& img': {
      width: '162px',
      maxHeight: '162px',
      height: '162px',
      borderRadius: '162px',
      margin: '7px 0px 0px 9px',
    },
  },
  imageDropZone: {
    width: '160px',
    height: '160px',
    borderRadius: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage:
      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(50, 50, 50, 0.5)), url(' +
      // ProfileUpdateIcon +
      ')' +
      ', url(' +
      ProfileIcon +
      ')',
    background: 'no-repeat center center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  imageDropRoot: {
    width: '160px',
    height: '160px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  update: {
    textDecoration: 'underline',
    color: '#21C6CF',
    fontSize: '12px',
  },
})

const FormData = ({ id }) => {
  const classes = useStyles()
  // console.log(props)

  return (
    <Box p="0 60px 60px">
      <ItemTitle title="User Details" />
      <Box display="flex" mt="50px">
        <Box flex={1}>
          <div className={classes.imageDropZone} />
        </Box>
        <Box flex={7}>
          <Box display="flex">
            <Box flex={1}>
              <TextInput
                fullWidth
                source="forename"
                variant="outlined"
                // validate={required()}
                label="First Name:"
              />
            </Box>
            <Box flex={1}>
              <TextInput
                fullWidth
                source="surname"
                variant="outlined"
                // validate={required()}
                label="Last Name:"
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box flex={1}>
              <TextInput
                fullWidth
                source="email"
                variant="outlined"
                validate={required()}
                label="Email:"
              />
            </Box>
            <Box flex={1}>
              <ReferenceInput
                source="active_role_id"
                reference="role"
                label="Active Role"
                // sort={{ field: 'id', order: 'ASC' }}
                filter={{ user_id: id }}
                // style={{ minWidth: '200px' }}
                alwaysOn
                variant="outlined"
              >
                <SelectInput optionText={(val) => val.name} fullWidth />
              </ReferenceInput>
            </Box>
          </Box>
        </Box>
        <Box flex={2} />
      </Box>
    </Box>
  )
}

export default FormData
