import React, { useState, useEffect, cloneElement } from 'react'
import { Card } from '@material-ui/core'
import {
  FormWithRedirect,
  useDataProvider,
  useCreateController,
  CreateContextProvider,
} from 'react-admin'
import SCMQuoteCreateForm from './components/SCMQuoteCreateForm'
// import ProductDetailsView from './components/ProductDetailsView'

const SCMQuoteCreate = (props) => {
  const dataProvider = useDataProvider()

  const [dataLists, setDataLists] = useState([])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const getDataLists = async () => {
      const listsTemp = {}
      const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
      listsTemp.clientMilestones = milestonesTemp.filter((item) => item.type === 'client')

      const { data: clientsTemp } = await dataProvider.getList('organisation', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: { is_public: true },
      })
      listsTemp.clients = clientsTemp

      const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
        fields: [
          'estimated_lead_time_sea',
          'estimated_lead_time_air',
          'estimated_freight_rate_sea',
          'estimated_freight_rate_air',
        ],
      })
      const config = {}
      configTemp.global_preferences.forEach(
        (item) => (config[item.setting_key] = item.setting_value),
      )
      listsTemp.config = config

      if (!abortController.signal.aborted) {
        setDataLists(listsTemp)
      }
    }

    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [dataProvider])

  return (
    <div>
      <CreateSCMQuote {...props} dataLists={dataLists} />

      {/* <ProductDetailsView productData={productData} /> */}
    </div>
  )
}

const MyCreate = (props) => {
  const createControllerProps = useCreateController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = createControllerProps
  return (
    <CreateContextProvider value={createControllerProps}>
      <div>
        {/* <h1>{defaultTitle}</h1> */}
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </CreateContextProvider>
  )
}

const CreateSCMQuote = (props) => {
  const { productData, dataLists } = props

  // console.log(productData)

  return (
    <MyCreate {...props}>
      <FormWithRedirect
        {...props}
        validate={(values) => {
          const errors = {}
          values.quotes?.map((item, index) => {
            if (!item.estimated_freight_air && !item.estimated_freight_sea) {
              errors.quotes = []
              errors.quotes[index] = { estimated_freight_sea: 'Required sea or air' }
              errors.quotes[index].estimated_freight_air = 'Required sea or air'
            }
          })
          return errors
        }}
        render={(formProps) => (
          <Card>
            <SCMQuoteCreateForm
              productData={productData}
              formProps={formProps}
              dataLists={dataLists}
            />
          </Card>
        )}
      />
    </MyCreate>
  )
}

export default SCMQuoteCreate
