import React from 'react'
import {
  required,
  FileField,
  useDataProvider,
  ImageField,
  // email,
  regex,
  useNotify,
} from 'react-admin'
import { Box, makeStyles, InputLabel, withStyles } from '@material-ui/core'
import lodashMemoize from 'lodash/memoize'
import lodashGet from 'lodash/get'
import { useFormState } from 'react-final-form'
import moment from 'moment'
// import FormControl from '@material-ui/core/FormControl'
import SectionTitle from '../../../../components/SectionTitle'
import TextInput from '../../../../components/OutlinedTextInput'
import TextInputLabel from '../../../../components/TextInputLabel'
import AutocompleteInput from '../../../../components/AutocompleteInput'
import ReferenceInput from '../../../../components/OutlinedReferenceInput'
import FormIterator, { RemoveButton, AddButton } from '../../../../components/CustomIterator'
import FileInput from '../../../../components/input/FileInput'
import SelectInput from '../../../../components/input/SelectInput'
import CheckboxGroupInput from '../../../../components/input/CheckboxGroupInput'
import DateInput from '../../../../components/input/DateInput'
import ImageInput from '../../../../components/input/ImageInput'
import FormArrayInput from '../../../../components/input/ArrayInput'

const useStyles = makeStyles({
  w50: {
    minWidth: '40%',
  },
  formHelperText: {
    left: 10,
  },
  inputLeft: {
    marginRight: '0.5em',
  },
  inputRight: {
    marginLeft: '0.5em',
  },
  textArrayInputArray: {
    transition: 'none',
    transform: 'none',
    transformOrigin: 'unset',
    position: 'relative',
    display: 'block',
    paddingTop: '17px',
  },
  textInput: {},
  formArrayInputArray: {
    transition: 'none',
    transform: 'none',
    transformOrigin: 'unset',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    fontFamily: 'Roboto',
    color: '#333333',
    position: 'relative',
  },
  formArrayInput: {
    flexDirection: 'column',
  },
  textArrayInput: {
    marginTop: '0',
  },
})

const FullInput = withStyles(() => ({
  root: {
    height: '3rem',
  },
}))(TextInput)

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args))

export const mobile = memoize((message = 'Must be 11 numbers') =>
  regex(/^1[3456789]\d{9}$/, message),
)

export const numberAZ = memoize((message = 'Must be numbers and a-z') =>
  regex(/^[A-Za-z0-9]+$/, message),
)

export const dateForm1 =
  (max, message = 'Must be after today.') =>
  (value) =>
    value && moment(value).valueOf() < moment().valueOf() ? message : undefined

// dateForm1

const years = Array.from(Array(100), (v, k) => k + 1990)
  .filter((item) => item <= moment().year())
  .map((item) => ({
    id: item,
    name: `${item}`,
  }))
years.push({ id: 0, name: '0' })

const CompanyDetails = ({ record }) => {
  if (
    (record && !record.certifications) ||
    (record.certifications && record.certifications.length === 0)
  ) {
    // eslint-disable-next-line no-param-reassign
    record.certifications = []
  }
  if ((record && !record.website) || (record.website && record.website.length === 0)) {
    // eslint-disable-next-line no-param-reassign
    record.website = ['']
  }
  const classes = useStyles()

  const initCategories = []
  const [categories, setCategories] = React.useState(initCategories)
  // eslint-disable-next-line no-unused-vars
  const [AddressTypes, setAddressTypes] = React.useState([])
  // eslint-disable-next-line no-unused-vars
  const [modes, setModes] = React.useState([])
  // eslint-disable-next-line no-unused-vars
  const [companyTypes, setCompanyTypes] = React.useState([])
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [stateIds, setStateIds] = React.useState([])

  const [cities, setCities] = React.useState([])

  const { values, errors, touched } = useFormState()

  const dataProvider = useDataProvider()
  const notify = useNotify()

  React.useEffect(() => {
    const fetchDataProvider = async () => {
      try {
        const { data } = await dataProvider.getList('categories', {})
        // const {
        //   data: {
        //     getSupplierDict: { addressTypes, supplierModes, supplierTypes },
        //   },
        // } = await dataProvider.getSupplierDict('SupplierDict', {})

        // console.log(addressTypes, supplierModes, supplierTypes)
        // eslint-disable-next-line no-shadow
        const { data: countries } = await dataProvider.getList('country', {})
        const { data: countrySubdivision = [] } = await dataProvider.getList(
          'country_subdivision',
          {},
        )
        setCountries(countries)
        setCategories(data)
        // eslint-disable-next-line no-shadow
        const cities = {}
        // eslint-disable-next-line no-shadow
        const states = {}
        // eslint-disable-next-line no-shadow
        const stateIds = {}
        countrySubdivision.forEach((item) => {
          if (item.level === 1) {
            stateIds[item.id] = item.id
            if (!states[item.country_code]) {
              states[item.country_code] = [item]
            } else {
              states[item.country_code].push(item)
            }
          } else if (item.level === 2) {
            if (!cities[item.parent_id]) {
              cities[item.parent_id] = [item]
            } else {
              cities[item.parent_id].push(item)
            }
          }
        })
        setStateIds(stateIds)
        setStates(states)
        setCities(cities)
        // setAddressTypes(
        //   Object.keys(AddressType).map(key => ({
        //     id: key,
        //     name: AddressType[key],
        //   })),
        // )
        // setModes(
        //   Object.keys(Mode).map(key => ({
        //     id: key,
        //     name: Mode[key],
        //   })),
        // )
        // setCompanyTypes(
        //   Object.keys(Type).map(key => ({
        //     id: key,
        //     name: Type[key],
        //   })),
        // )
      } catch (error) {
        notify(
          lodashGet(
            error.graphQLErrors,
            '0.extensions.exception.errors.0.message',
            'Data collection error',
          ),
          'warning',
        )
      }
    }
    fetchDataProvider()
  }, [dataProvider, notify])

  // console.log(cities)
  // console.log(stateIds)

  return (
    <>
      <SectionTitle label="Basic Information" />
      <Box display="flex" mb="2em" mt="1em">
        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
          <TextInput
            source="companyName"
            defaultValue=""
            fullWidth
            validate={required()}
            label="Company Name:"
          />
          <TextInput
            source="chineseName"
            fullWidth
            validate={required()}
            label="Company Name（Chinese）:"
            defaultValue=""
          />
          <TextInput
            source="legalAddress"
            fullWidth
            validate={required()}
            label="Legal Address:"
            defaultValue=""
          />
          <TextInput
            fullWidth
            source="businessLicenceNO"
            label="Business License Number:"
            defaultValue=""
            validate={required()}
          />
          <FileInput
            source="businessLicences"
            label="Business License:"
            boxStyle={{
              width: '200px',
              height: '200px',
            }}
            addLabel="false"
            accept="application/pdf"
            placeholder="Upload File"
            InputLabelProps={{ shrink: false }}
            multiple
          >
            <FileField target="_blank" source="url" title="title" />
          </FileInput>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '1em' }} />
      </Box>
      <Box display="flex">
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }} pt="0.5em">
          <SelectInput
            fullWidth
            label="Address Type:"
            source="address.type"
            choices={AddressTypes}
            optionText="name"
            optionValue="id"
            // validate={required()}
          />

          {/* <FormControl variant='outlined' fullWidth style={{ paddingBottom: 0, marginBottom: 0 }}>
            <InputLabel
              style={{ alignItems: "flex-start", paddingTop: "7px" }}
              error={
                (lodashGet(errors, "address.line1") && lodashGet(touched, "address.line1")) ||
                (lodashGet(errors, "address.line2") && lodashGet(touched, "address.line2"))
              }
            >
              Address
            </InputLabel> */}
          <Box flex={1}>
            <TextInput
              fullWidth
              column
              label="Address Line 1:"
              source="address.line1"
              // validate={required()}
              defaultValue=""
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            />
            <TextInput
              fullWidth
              column
              label="Address Line 2:"
              source="address.line2"
              // validate={required()}
              defaultValue=""
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            />
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <SelectInput
                  fullWidth
                  label="Country:"
                  column
                  source="address.countryCode"
                  defaultValue=""
                  choices={countries}
                  optionText="name"
                  optionValue="code"
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <SelectInput
                  fullWidth
                  column
                  label="State/Province:"
                  source="address.province"
                  defaultValue=""
                  choices={
                    values.address && values.address.countryCode
                      ? states[values.address.countryCode]
                      : []
                  }
                  optionText="name"
                  optionValue="id"
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <SelectInput
                  fullWidth
                  column
                  label="City:"
                  source="address.city"
                  choices={
                    values.address && values.address.province
                      ? cities[stateIds[values.address.province]]
                      : []
                  }
                  defaultValue=""
                  optionText="name"
                  optionValue="id"
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInputLabel
                  fullWidth
                  column
                  label="Postal code:"
                  validate={numberAZ()}
                  source="address.postCode"
                  className={classes.TextInput}
                  defaultValue=""
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Box>
            </Box>
            <TextInput
              fullWidth
              column
              label="Chinese Address:"
              source="address.fullAddress"
              // validate={required()}
              defaultValue=""
            />
          </Box>
          {/* </FormControl> */}

          <ReferenceInput
            fullWidth
            label="Shipping Port:"
            // filterToQuery={searchText => ({ name: searchText })}
            source="address.shippingPort"
            reference="shippingPorts"
            // validate={required()}
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput optionText="name" optionValue="id" />
          </ReferenceInput>
        </Box>
        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} mb="2em">
          {/* <FormControl variant='outlined' fullWidth style={{ paddingBottom: 0, marginBottom: 0 }}> */}
          <InputLabel
            error={
              (lodashGet(errors, 'contact.firstName') && lodashGet(touched, 'contact.firstName')) ||
              (lodashGet(errors, 'contact.lastName') && lodashGet(touched, 'contact.lastName'))
            }
          >
            Contact Person
          </InputLabel>
          <Box display="flex" flex={1}>
            <FullInput
              className={classes.inputLeft}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              fullWidth
              label=""
              source="contact.firstName"
              // validate={required()}
              defaultValue=""
              placeholder="First Name:"
            />
            <FullInput
              className={classes.inputRight}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              inputProps={{
                className: classes.fullInput,
              }}
              fullWidth
              label=""
              source="contact.lastName"
              // validate={required()}
              defaultValue=""
              placeholder="Last/Family Name:"
            />
          </Box>
          {/* <OutlinedInput id="component-outlined" value={1} label="Name" /> */}
          {/* </FormControl> */}
          <TextInput
            fullWidth
            label="Position:"
            source="contact.position"
            // validate={required()}
            defaultValue=""
          />
          <TextInput
            fullWidth
            label="Mobile Number:"
            source="contact.mobile"
            // type="number"
            // validate={[required(), mobile()]}
            defaultValue=""
          />
          <TextInput
            fullWidth
            label="Email:"
            type="email"
            // validate={[email(), required()]}
            source="contact.email"
            defaultValue=""
          />
          <TextInput fullWidth label="Wechat:" source="contact.wechat" defaultValue="" />
          <TextInput fullWidth label="WhatsApp:" source="contact.whatsapp" defaultValue="" />
        </Box>
      </Box>
      <TextInput
        fullWidth
        label="Company Introduction:"
        source="introduction"
        defaultValue=""
        multiline
        rows={4}
      />
      <Box width="75%">
        {/* <FormArrayInput
          className={classes.textArrayInput}
          InputLabelProps={{
            className: classes.textArrayInputArray,
          }}
          source='website'
          label='Website:'
          fullWidth
        >
          <TextIterator fullWidth>
            <TextInput fullWidth defaultValue='' />
          </TextIterator>
        </FormArrayInput> */}
      </Box>
      <Box>
        <div
          style={{
            color: '#333333',
            fontSize: '24px',
            fontWeight: 600,
          }}
        >
          Certification
        </div>
        <FormArrayInput
          className={classes.formArrayInput}
          style={{ marginTop: '0' }}
          label=""
          source="certifications"
          InputLabelProps={{
            className: classes.formArrayInputArray,
          }}
        >
          <FormIterator removeButton={<RemoveButton />} addButton={<AddButton />}>
            <FileInput
              source="url"
              boxStyle={{
                width: '200px',
                height: '200px',
              }}
              style={{ width: '180px', height: '180px' }}
              label=""
              addLabel={false}
              accept="application/pdf"
              placeholder="Upload File"
            >
              <FileField target="_blank" source="src" title="title" />
            </FileInput>
            <TextInput
              fullWidth
              label="Certificate Type:"
              source="type"
              defaultValue=""
              // validate={required()}
            />

            <TextInput
              fullWidth
              label="Certification:"
              source="name"
              defaultValue=""
              // validate={required()}
            />
            <TextInput
              fullWidth
              label="Certification #:"
              source="number"
              defaultValue=""
              // validate={required()}
            />
            <DateInput
              fullWidth
              label="Certification Start Date:"
              source="validStartDate"
              validate={dateForm1()}
              // defaultValue={moment().format('YYYY/MM/DD')}
            />
            <DateInput
              fullWidth
              label="Certification End Date:"
              source="validEndDate"
              validate={dateForm1()}
              // defaultValue={moment().format('YYYY/MM/DD')}
            />
            <TextInput
              fullWidth
              label="Certification Issued By:"
              source="notifyingBody"
              // validate={required()}
            />
          </FormIterator>
        </FormArrayInput>
      </Box>
      <Box>
        <SectionTitle label="Other Information:" />
        <SelectInput
          className={classes.w50}
          label="Company Type:"
          source="companyType"
          choices={companyTypes}
          optionText="name"
          // validate={required()}
        />

        <CheckboxGroupInput
          source="companyMode"
          label="OEM/ODM/Brand:"
          choices={modes}
          // validate={required()}
        />
        <CheckboxGroupInput
          source="categories"
          label="Product Category:"
          choices={categories}
          // validate={required()}
        />
        {/* <FormControl
          variant='outlined'
          fullWidth
          style={{
            paddingBottom: 0,
            marginBottom: 15,
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        > */}
        <InputLabel>Scale of Company:</InputLabel>
        <Box style={{ flexGrow: 2 }}>
          <Box display="flex" flex={1}>
            <Box flex={1} mr="0.5em">
              <SelectInput
                fullWidth
                column
                label="Total Employees:"
                source="employeeTotal"
                choices={[
                  {
                    id: 'Unknown',
                    name: 'Unknown',
                  },
                  {
                    id: '1-10',
                    name: '1-10',
                  },
                  {
                    id: '11-50',
                    name: '11-50',
                  },
                  {
                    id: '51-100',
                    name: '51-100',
                  },
                  {
                    id: '101-500',
                    name: '101-500',
                  },
                  {
                    id: '500 +',
                    name: '500 +',
                  },
                ]}
                defaultValue="Unknown"
                optionText="name"
                optionValue="id"
              />
            </Box>
            <Box flex={1} mr="0.5em">
              <SelectInput
                fullWidth
                column
                label="Company Size:"
                source="factorySize"
                choices={[
                  {
                    id: 'Unknown',
                    name: 'Unknown',
                  },
                  {
                    id: '1-10sqm',
                    name: '1-10sqm',
                  },
                  {
                    id: '11-25sqm',
                    name: '11-25sqm',
                  },
                  {
                    id: '11-25sqm',
                    name: '11-25sqm',
                  },
                  {
                    id: '51-100sqm',
                    name: '51-100sqm',
                  },
                  {
                    id: '101 + sqm',
                    name: '101 + sqm',
                  },
                ]}
                defaultValue="Unknown"
                optionText="name"
              />
            </Box>
            <Box flex={1} ml="0.5em">
              <SelectInput
                fullWidth
                column
                label="Total Annual Revenue (US$):"
                source="annualTurnover"
                choices={[
                  {
                    id: 'Unknown',
                    name: 'Unknown',
                  },
                  {
                    id: '<10,000 USD',
                    name: '<10,000 USD',
                  },
                  {
                    id: '<25,000 USD',
                    name: '<25,000 USD',
                  },
                  {
                    id: '<50,000 USD',
                    name: '<50,000 USD',
                  },
                  {
                    id: '<100,000 USD',
                    name: '<100,000 USD',
                  },
                  {
                    id: '100,000 + USD',
                    name: '100,000 + USD',
                  },
                ]}
                defaultValue="Unknown"
                optionText="name"
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <SelectInput
                fullWidth
                column
                label="Primary Market:"
                source="primaryMarket"
                choices={[
                  {
                    id: 'Unknown',
                    name: 'Unknown',
                  },
                  {
                    id: 'Europe',
                    name: 'Europe',
                  },
                  {
                    id: 'North America',
                    name: 'North America',
                  },
                  {
                    id: 'South America',
                    name: 'South America',
                  },
                  {
                    id: 'Asia- Oceania',
                    name: 'Asia- Oceania',
                  },
                  {
                    id: 'Africa',
                    name: 'Africa',
                  },
                  {
                    id: 'Middle East',
                    name: 'Middle East',
                  },
                ]}
                defaultValue="Unknown"
                optionText="name"
              />
            </Box>
            <Box flex={1} mr="0.5em">
              <SelectInput
                fullWidth
                column
                label="Year Established:"
                source="yearEstablished"
                choices={years}
                defaultValue={0}
                optionText="name"
                optionValue="id"
              />
            </Box>
            <Box flex={1} ml="0.5em" />
          </Box>
        </Box>
        {/* </FormControl> */}
      </Box>
      <Box>
        <SectionTitle label="Uploads" />
        <FileInput
          column
          source="companyPresentation"
          label="Company Presentation"
          addLabel="false"
          multiple
          accept="application/msword,application/vnd.ms-powerpoint,application/pdf"
          placeholder="Upload File"
        >
          <FileField target="_blank" source="url" title="title" />
        </FileInput>
        <FileInput
          column
          source="productCatalog"
          label="Product Catalog"
          addLabel="false"
          accept="application/*"
          multiple
          placeholder="Upload File"
        >
          <FileField target="_blank" source="url" title="title" />
        </FileInput>
        <FileInput
          column
          source="businessLicences"
          label="Business License"
          addLabel="false"
          accept="application/pdf"
          placeholder="Upload File"
          multiple
        >
          <FileField target="_blank" source="url" title="title" />
        </FileInput>
        <ImageInput
          column
          source="companyPhotos"
          label="Company Photos"
          addLabel="false"
          multiple
          placeholder="Upload File"
        >
          {/* <ImageShow source="src" > */}
          <ImageField style={{ width: '100%' }} source="src" title="title" />
          {/* </ImageShow> */}
        </ImageInput>
      </Box>
    </>
  )
}

// const ImageShow = (props) => {
//   console.log(props)
//   const {record, children} = props
//   return <a href={record.src} target="_blank">
//     {children}
//   </a>
// }
export default CompanyDetails
