import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'

const CategoriesFieldInput = (props) => {
  const [editing, setEditing] = useState(false)

  // console.log(props)
  const { values } = useFormState()
  const form = useForm()

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)
  const {
    fieldName,
    referenceField,
    referenceId,
    resourceName,
    oldField,
    fieldLabel,
    formField,
    onConflict,
    ...rest
  } = props

  const [categories, setCategories] = useState([])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: catData } = await dataProvider.getList('categories', {
          filter: { level: 0 },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        !abortController.signal.aborted && setCategories(catData)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  const options = []

  categories.map((cat) => {
    cat.children?.map((scat) => {
      if (scat.children?.length > 0) {
        scat.children?.map((scac) => {
          options.push({ ...scac, parent: `${cat.name}-${scat.name}` })
        })
      } else {
        options.push({ ...scat, parent: cat.name })
      }
    })
  })

  return (
    <Box display="flex" width="100%">
      <Autocomplete
        disabled={!editing}
        id="categories-mutiple-chip"
        multiple
        variant="standard"
        value={values[formField]}
        filterSelectedOptions
        onChange={(e, v) => form.change(formField, v)}
        options={options.sort((a, b) => -b.parent.localeCompare(a.parent))}
        groupBy={(option) => option.parent}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => {
          if (value === '') {
            return true
          } else if (value.id === option.id) {
            return true
          }
        }}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={fieldLabel} placeholder="" />
        )}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                // Compare new values to old and insert difference
                // console.log('Existing Values', values[formField])
                // console.log('Old values', values[oldField])
                const newValues = []
                const deleteValues = []

                values[formField]?.map((item) => {
                  if (
                    !values[oldField].some(
                      (oldItem) =>
                        oldItem?.category?.category_id === item.id ||
                        oldItem?.category?.id === item.id,
                    )
                  ) {
                    newValues.push({
                      [fieldName]: item.id,
                      [referenceField]: referenceId,
                    })
                  }
                })

                // Compare old values to new and delete difference
                values[oldField]?.map((oldItem) => {
                  if (
                    !values[formField]?.some((item) => item.id === oldItem?.category?.category_id)
                  ) {
                    oldItem.id && deleteValues.push(oldItem.id)
                  }
                })

                // console.log('NewValues', newValues, 'DelValues', deleteValues)
                if (newValues.length > 0) {
                  await dataProvider.create(resourceName, {
                    data: newValues,
                    ...(onConflict && { ...onConflict }),
                  })
                  // console.log(resp)
                }
                if (deleteValues.length > 0) {
                  await dataProvider.deleteMany(resourceName, {
                    ids: deleteValues,
                  })
                }

                form.change(
                  oldField,
                  values[formField]?.map((el) => ({
                    category: el,
                    category_id: el.id,
                  })),
                )
                setSaving(false)

                setEditing(false)
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                setSaving(false)
                console.log(error)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default CategoriesFieldInput
