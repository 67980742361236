import React from 'react'
import FormData from './FormData'
import { FormWithRedirect } from 'react-admin'
import { handleSave, validation } from '../common/utils'

const OfferEditForm = (props) => {
  // console.log('OFFORM', props)

  const { record, setStatus, dataLists, setDataLists } = props

  // const id = parseInt(props.id)

  const trackingMedia = []

  if (record.offer_files?.length > 0) {
    record.offer_files.map((item) => {
      switch (item.type) {
        case 'TRACKING_MEDIA':
          trackingMedia.push(item.file)
          return null
        default:
          return null
      }
    })
  }

  const factoryPaymentTerms = []
  const qualityPaymentTerms = []
  const logisticsPaymentTerms = []
  const clientPaymentTerms = []
  if (record.offer_payment_terms?.length > 0) {
    record.offer_payment_terms.map((item) => {
      switch (item.type) {
        case 'FACTORY':
          factoryPaymentTerms.push(item)
          return null
        case 'QUALITY':
          qualityPaymentTerms.push(item)
          return null
        case 'LOGISTICS':
          logisticsPaymentTerms.push(item)
          return null
        case 'CLIENT':
          clientPaymentTerms.push(item)
          return null
        default:
          return null
      }
    })
  }

  // const activeFactoryQuote = record.client_quote?.product_quote?.prices.find(item => item.quantity >= quantity)

  const factoryQuote = {
    factoryQuotePrice:
      (record.client_quote?.product_quote?.prices?.length > 0 &&
        record.client_quote?.product_quote?.prices[0].unit_price) ||
      null,
    factoryQuoteQuantity:
      (record.client_quote?.product_quote?.prices?.length > 0 &&
        record.client_quote?.product_quote?.prices[0].quantity) ||
      null,
    factoryQuoteLeadTime:
      (record.client_quote?.product_quote?.prices?.length > 0 &&
        record.client_quote?.product_quote?.prices[0].lead_time) ||
      null,
    factoryQuoteToolingCost: record.client_quote?.product_quote?.tooling_cost || null,
    factoryQuoteSampleCost: record.client_quote?.product_quote?.sample_cost || null,
    factoryQuoteSampleLeadTime: record.client_quote?.product_quote?.sample_lead_time || null,
    factoryQuoteCustomSampleCost: record.client_quote?.product_quote?.custom_sample_cost || null,
  }

  const categories = record.offer_categories
    ?.filter((item) => !item.deleted_at)
    .map((item) => item.category.id)

  const otherSpecifications = []
  const productColours = record.quote?.specifications.filter(
    (spec) => spec.option_value?.option?.name === 'Colour',
  )
  const productMaterials = record.quote?.specifications.filter(
    (spec) => spec.option_value?.option?.name === 'Material',
  )

  // console.log('RECORD', record)

  const uniqueSpecs = []

  record.quote?.specifications.map((spec) => {
    if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
      uniqueSpecs.push(spec.option_value.option.name)
    }
  })

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material') {
      otherSpecifications.push({
        name: spec,
        option_value: {
          value: record.quote?.specifications
            .filter((qspec) => qspec.option_value.option?.name === spec)
            .map((item) => item.option_value?.value),
        },
      })
    }
  })

  const poFile =
    record.offer_purchases?.length > 0 ? record.offer_purchases[0].purchase_order_file : null

  return (
    <FormWithRedirect
      {...props}
      validate={validation}
      // redirect="/customOffer"
      redirect={null}
      initialValues={{
        trackingMedia: trackingMedia,
        productColours: productColours,
        productMaterials: productMaterials,
        otherSpecifications: otherSpecifications,
        factoryPaymentTerms: factoryPaymentTerms,
        qualityPaymentTerms: qualityPaymentTerms,
        logisticsPaymentTerms: logisticsPaymentTerms,
        clientPaymentTerms: clientPaymentTerms,
        categories,
        poFile,
        ...factoryQuote,
      }}
      render={(formProps) => (
        <FormData
          dataLists={dataLists || {}}
          quoteData={{}}
          formProps={formProps}
          handleSave={handleSave}
          recordStatus={record.status}
          showTracking
          setDataLists={setDataLists}
          record={record}
          setStatus={setStatus}
        />
      )}
    />
  )
}

export default OfferEditForm
