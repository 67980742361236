import React, { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import ClientSelectWithCreate from '../../components/ClientSelectWithCreate'

const ClientSelectFieldInput = (props) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)
  const [organisations, setOrganisations] = useState(props.options || [])

  const [editing, setEditing] = useState(false)

  const { resource, field, id, onSave, className, ...rest } = props

  return (
    <Box display="flex">
      <ClientSelectWithCreate
        options={organisations}
        setOptions={(newOptions) => {
          const newList = [...organisations]
          organisations.push(newOptions)
          setOrganisations(newList)
        }}
        optionText={(value) => value?.name}
        source={field}
        className={className}
        disabled={!editing}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)

              try {
                await dataProvider.update(resource, {
                  data: { id, [field]: values[field] },
                })

                onSave && (await onSave())
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                setEditing(false)
                setSaving(false)
                notify(error.message, { type: 'warning' })
              }
              setEditing(false)
              setSaving(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default ClientSelectFieldInput
