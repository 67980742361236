/* eslint-disable no-unreachable */
import uploadFile from '../../../dataProvider/upload'

export const saveFactoryQuote = async ({
  setSaving,
  values,
  dataProvider,
  notify,
  setEditing,
  // form,
}) => {
  setSaving ? setSaving(true) : null
  try {
    const prices = []
    await Promise.all(
      values.factoryPrices?.map(async (item) => {
        // console.log('PRICE:', item)
        prices.push({
          ...(typeof item.id === 'number'
            ? { id: item.id }
            : { product_quote_id: values.product_quotes[0].id }),
          quantity: item.quantity,
          unit_price: Math.round(item.unitPrice * 100),
          lead_time: item.lead_time || item.production_lead_time,
          ...(item.landed_air && {
            est_landed_cost_air: Math.round(item.landed_air * 100),
          }),
          ...(item.landed_sea && {
            est_landed_cost_sea: Math.round(item.landed_sea * 100),
          }),
          ...(item.totalCBM && { total_cbm: Math.round(item.totalCBM * 100) }),
          ...(item.shipmentWeight && {
            shipment_weight: Math.round(item.shipmentWeight * 100),
          }),
          ...(item.totalNumCartons && {
            total_cartons: Math.round(item.totalNumCartons),
          }),
          ...(item.totalDuties && {
            total_duties: Math.round(item.totalDuties * 100),
          }),
          ...(item.totalFreightCostAir && {
            freight_cost_air: Math.round(item.totalFreightCostAir * 100),
          }),
          ...(item.totalFreightCostSea && {
            freight_cost_sea: Math.round(item.totalFreightCostSea * 100),
          }),
          ...(item.freightCostPerPieceAir && {
            freight_cost_air_per_piece: Math.round(item.freightCostPerPieceAir * 100),
          }),
          ...(item.freightCostPerPieceSea && {
            freight_cost_sea_per_piece: Math.round(item.freightCostPerPieceSea * 100),
          }),
          ...(item.containers && { containers: item.containers }),
        })
      }),
    )
    // console.log('PRICES', prices)

    await Promise.all(
      prices.map(async (el) => {
        if (el.id) {
          await dataProvider.update('product_quote_price', { data: el })
        } else {
          await dataProvider.create('product_quote_price', {
            data: el,
          })
        }
      }),
    )

    const quoteParams = {
      ...(!!values.product_quotes.length > 0 &&
        values.product_quotes[0].id && { id: values.product_quotes[0].id }),
      ...(values.containerRate20ft && {
        container_rate_20ft: Math.round(values.containerRate20ft * 100),
      }),
      ...(values.containerRate40ft && {
        container_rate_40ft: Math.round(values.containerRate40ft * 100),
      }),
      ...(values.containerRate40ftHC && {
        container_rate_40ft_hc: Math.round(values.containerRate40ftHC * 100),
      }),
      ...(values.freightRateSea && {
        freight_rate_sea: Math.round(values.freightRateSea * 100),
      }),
      ...(values.freightRateAir && {
        freight_rate_air: Math.round(values.freightRateAir * 100),
      }),
      ...(values.does_sea_freight_include_duties && {
        does_sea_freight_include_duties: values.does_sea_freight_include_duties,
      }),
      ...(values.product_quotes[0]?.included_packaging_option_id && {
        included_packaging_option_id: values.product_quotes[0].included_packaging_option_id,
      }),

      container_capacity_20ft: 25,
      container_capacity_40ft: 60,
      container_capacity_40ft_hc: 65,
    }

    // console.log('QUOTEPARAMS', quoteParams)

    dataProvider.update('product_quote', { data: quoteParams })

    // const cartonParams = {
    //   ...(values.cartonDimensions?.id && { id: values.cartonDimensions.id }),
    //   length: Math.round(values.cartonDimensions.length * 100),
    //   width: Math.round(values.cartonDimensions.width * 100),
    //   height: Math.round(values.cartonDimensions.height * 100),
    //   gross_weight: Math.round(values.cartonDimensions.gross_weight * 100),
    // }
    // if (cartonParams.id) {
    //   await dataProvider.update('dimension', { data: cartonParams })
    // } else {
    //   const resp = await dataProvider.create('dimension', { data: cartonParams })
    //   form.change('cartonDimensions.id', resp.data.id)
    //   await dataProvider.update('product', {
    //     data: { id: values.id, carton_dimensions_id: resp.data.id },
    //   })
    // }

    if (values.dutyRate) {
      await dataProvider.update('product', {
        data: { id: values.id, duty_rate: Math.round(values.dutyRate * 100) },
      })
    }
    //   console.log(resp)
    notify ? notify('Update Complete', { type: 'info' }) : null
  } catch (error) {
    console.log(error)
    notify ? notify(error.message, { type: 'warning' }) : null
  }
  setEditing ? setEditing(false) : null
  setSaving ? setSaving(false) : null
}

export const handleSave = async ({ submission, dataProvider, notify, identity }) => {
  try {
    // Set to false to disable console logs
    const debugMode = false
    debugMode && console.log('SAVING DATA')
    debugMode && console.time('SAVING DATA TIMER')
    debugMode && console.log('SUB:', submission)

    const params = {}

    // PRODUCT
    debugMode && console.count('STEP')
    debugMode && console.log('PRODUCT', params)

    debugMode && console.log('IDENTITY:', identity)

    params.sca_user_id = submission.sca_user_id || identity.userId

    params.status = '^PRIVATE^'
    submission.name ? (params.name = submission.name) : null
    submission.is_catalogue ? (params.is_catalogue = submission.is_catalogue || false) : null
    submission.short_description ? (params.short_description = submission.short_description) : null
    submission.notes ? (params.notes = submission.notes) : null
    submission.allow_colour_selection
      ? (params.allow_colour_selection = submission.allow_colour_selection)
      : null
    submission.root_sku ? (params.root_sku = submission.root_sku) : null
    submission.catalogue_sku ? (params.catalogue_sku = submission.catalogue_sku) : null
    submission.packaging_type ? (params.packaging_type = submission.packaging_type) : null
    submission.pieces_per_carton === 0
      ? (params.pieces_per_carton = 0)
      : submission.pieces_per_carton
      ? (params.pieces_per_carton = submission.pieces_per_carton)
      : null
    submission.hts_code ? (params.hts_code = submission.hts_code) : null
    submission.dutyRate === 0
      ? (params.duty_rate = 0)
      : submission.dutyRate
      ? Math.round((params.duty_rate = submission.dutyRate * 100))
      : null
    submission.brand ? (params.brand = submission.brand) : null
    submission.root_product_id ? (params.root_product_id = submission.root_product_id) : null
    submission.chargeable_weight_air === 0
      ? (params.chargeable_weight_air = 0)
      : submission.chargeable_weight_air
      ? (params.chargeable_weight_air = Math.round(submission.chargeable_weight_air))
      : null
    submission.chargeable_weight_sea === 0
      ? (params.chargeable_weight_sea = 0)
      : submission.chargeable_weight_sea
      ? (params.chargeable_weight_sea = Math.round(submission.chargeable_weight_sea))
      : null

    // PRODUCT MEDIA / FILES
    debugMode && console.count('STEP')

    debugMode && console.log('PRIMARYPHOTO', submission.primary_photo)

    // PRIMARY PHOTO
    if (submission.primary_photo) {
      const file = await uploadFile([submission.primary_photo])
      // console.log(file)
      params.primary_photo = {
        data: {
          url: file?.data?.files[0]?.url || '',
          filename: submission.primary_photo.filename || submission.primary_photo.name,
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('THUMBNAIL', submission.primary_photo)

    // THUMBNAIL PHOTO
    if (submission.primary_photo) {
      const file = await uploadFile([submission.primary_photo])
      params.thumbnail_photo = {
        data: {
          url: file?.data?.files[0]?.url || '',
          filename: submission.primary_photo.filename || submission.primary_photo.name,
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('ADDITIONAL', [...submission.additional_files, ...submission.images])

    const addFiles = [...submission.additional_files, ...submission.images]

    // ADDITIONAL FILES
    const files = []
    if (addFiles && addFiles?.length > 0) {
      await Promise.all(
        addFiles.map(async (photo) => {
          // console.log('FILE', photo)

          const file = await uploadFile([photo.file])
          // debugMode && console.log(photo)
          files.push({
            type: `^${photo.type}^`,
            file: {
              data: {
                url: file?.data?.files[0]?.url || '',
                filename: photo.file.name || photo.file.path || photo.file.filename,
              },
            },
          })
        }),
      )
    }

    if (files.length > 0) {
      params.product_files = {
        data: files,
      }
    }
    // console.log('FILES', files)
    // return null

    debugMode && console.count('STEP')
    debugMode && console.log('PRODTAGS', submission?.tags)

    // TAGS
    const tags = []

    submission.tags?.map((tag) => {
      tags.push({
        tag_id: tag,
      })
    })

    if (tags.length > 0) {
      params.product_tags = {
        data: tags,
      }
      debugMode && console.log('PARTAGS', params.product_tags)
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODCATS', submission?.categories)

    // CATEGORIES
    if (submission.categories?.length > 0) {
      const cats = []

      submission.categories?.map((cat) => {
        cats.push({
          category_id: cat,
          deleted_at: null,
        })
      })

      if (cats.length > 0) {
        params.product_categories = {
          data: cats,
        }
        debugMode && console.log('PARCATS', params.product_categories)
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODDECS', submission.product_declarations)
    // PRODUCT DECLARATIONS
    if (
      submission.product_declarations?.length > 0 &&
      submission.product_declarations[0].declaration &&
      submission.product_declarations[0].file
    ) {
      const decs = []
      await Promise.all(
        submission.product_declarations.map(async (dec) => {
          const file = dec.file ? await uploadFile([dec.file.rawFile]) : null
          decs.push({
            declaration: dec.declaration,
            ...(!!dec.file.rawFile && {
              file: {
                data: {
                  filename: dec.file.filename,
                  url: file.data.files[0].url,
                },
              },
            }),
          })
        }),
      )
      params.product_declarations = {
        data: decs,
      }
    }

    // CUSTOMISATIONS
    debugMode && console.count('STEP')
    const customisations = []
    debugMode && console.log('CUSTOMOPTS', 'SUB', submission.productCustomisations)

    submission.productCustomisations?.map((spec) => {
      // console.log(spec)
      spec.option_value.value.map((item) => {
        // console.log(item)
        customisations.push({
          option_value: {
            data: {
              value: item,
              option: {
                data: {
                  name: spec.name,
                },
                on_conflict: {
                  constraint: '^option_name_key^',
                  update_columns: '^name^',
                },
              },
            },
            on_conflict: {
              constraint: '^option_value_option_id_value_key^',
              update_columns: ['^value^', '^option_id^'],
            },
          },
        })
      })
    })

    if (customisations.length > 0) {
      params.customisations = {
        data: customisations,
      }
    }

    params.product_dimensions = { data: {} }
    params.packaging_dimensions = { data: {} }
    params.carton_dimensions = { data: {} }
    debugMode && console.count('STEP')
    debugMode && console.log('PRODDIMS', submission.productDimensions)
    // QUOTE.PRODUCT DIMENSIONS
    submission.productDimensions?.length === 0
      ? (params.product_dimensions.data.length = 0)
      : submission.productDimensions?.length
      ? (params.product_dimensions.data.length = Math.round(
          submission.productDimensions?.length * 100,
        ))
      : null
    submission.productDimensions?.width === 0
      ? (params.product_dimensions.data.width = 0)
      : submission.productDimensions?.width
      ? (params.product_dimensions.data.width = Math.round(
          submission.productDimensions?.width * 100,
        ))
      : null
    submission.productDimensions?.height === 0
      ? (params.prduct_dimensions.data.height = 0)
      : submission.productDimensions?.height
      ? (params.product_dimensions.data.height = Math.round(
          submission.productDimensions?.height * 100,
        ))
      : null
    submission.productDimensions?.gross_weight === 0
      ? (params.product_dimensions.data.gross_weight = 0)
      : submission.productDimensions?.gross_weight
      ? (params.product_dimensions.data.gross_weight = Math.round(
          submission.productDimensions?.gross_weight * 100,
        ))
      : null
    // QUOTE.PACKAGING DIMENSIONS
    debugMode && console.log('PKGDIMS', submission.packagingDimensions)
    submission.packagingDimensions?.length === 0
      ? (params.packaging_dimensions.data.length = 0)
      : submission.packagingDimensions?.length
      ? (params.packaging_dimensions.data.length = Math.round(
          submission.packagingDimensions?.length * 100,
        ))
      : null
    submission.packagingDimensions?.width === 0
      ? (params.packaging_dimensions.data.width = 0)
      : submission.packagingDimensions?.width
      ? (params.packaging_dimensions.data.width = Math.round(
          submission.packagingDimensions?.width * 100,
        ))
      : null
    submission.packagingDimensions?.height === 0
      ? (params.packaging_dimensions.data.height = 0)
      : submission.packagingDimensions?.height
      ? (params.packaging_dimensions.data.height = Math.round(
          submission.packagingDimensions?.height * 100,
        ))
      : null
    submission.packagingDimensions?.gross_weight === 0
      ? (params.packaging_dimensions.data.gross_weight = 0)
      : submission.packagingDimensions?.gross_weight
      ? (params.packaging_dimensions.data.gross_weight = Math.round(
          submission.packagingDimensions?.gross_weight * 100,
        ))
      : null
    // QUOTE.CARTON DIMENSIONS
    debugMode && console.log('CARTDIMS', submission.cartonDimensions)
    submission.cartonDimensions?.length === 0
      ? (params.carton_dimensions.data.length = 0)
      : submission.cartonDimensions?.length
      ? (params.carton_dimensions.data.length = Math.round(
          submission.cartonDimensions?.length * 100,
        ))
      : null
    submission.cartonDimensions?.width === 0
      ? (params.carton_dimensions.data.width = 0)
      : submission.cartonDimensions?.width
      ? (params.carton_dimensions.data.width = Math.round(submission.cartonDimensions?.width * 100))
      : null
    submission.cartonDimensions?.height === 0
      ? (params.carton_dimensions.data.height = 0)
      : submission.cartonDimensions?.height
      ? (params.carton_dimensions.data.height = Math.round(
          submission.cartonDimensions?.height * 100,
        ))
      : null
    submission.cartonDimensions?.gross_weight === 0
      ? (params.carton_dimensions.data.gross_weight = 0)
      : submission.cartonDimensions?.gross_weight
      ? (params.carton_dimensions.data.gross_weight = Math.round(
          submission.cartonDimensions?.gross_weight * 100,
        ))
      : null

    if (
      params.carton_dimensions?.data &&
      Object.keys(params.carton_dimensions?.data).length === 0
    ) {
      delete params.carton_dimensions
    }
    if (
      params.packaging_dimensions?.data &&
      Object.keys(params.packaging_dimensions?.data).length === 0
    ) {
      delete params.packaging_dimensions
    }
    if (
      params.product_dimensions?.data &&
      Object.keys(params.product_dimensions?.data).length === 0
    ) {
      delete params.product_dimensions
    }

    // PRODOPTIONS

    const availableOptions = []

    debugMode && console.log('COLOUROPTIONS', 'SUB', submission.colourOptions)

    if (submission.colourOptions?.length > 0) {
      await Promise.all(
        submission.colourOptions.map((item) => {
          availableOptions.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Colour',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('MATERIALOPTIONS', 'SUB', submission.materialOptions)

    if (submission.materialOptions?.length > 0) {
      await Promise.all(
        submission.materialOptions.map((item) => {
          availableOptions.push({
            deleted_at: null,
            option_value: {
              data: {
                value:
                  item.name || item.option_value?.name || item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Material',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('SIZEOPTIONS', 'SUB', submission.sizeOptions)

    if (submission.sizeOptions?.length > 0) {
      await Promise.all(
        submission.sizeOptions.map((item) => {
          availableOptions.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item,
                option: {
                  data: {
                    name: 'Size',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODOPTS', availableOptions)

    if (availableOptions.length > 0) {
      params.product_options = {
        data: availableOptions,
      }
    }

    const availableSpecs = []

    debugMode && console.log('COLOURSPECS', 'SUB', submission.productColours)

    if (submission.productColours?.length > 0) {
      await Promise.all(
        submission.productColours.map((item) => {
          availableSpecs.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Colour',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('MATERIALSPECS', 'SUB', submission.productMaterials)

    if (submission.productMaterials?.length > 0) {
      await Promise.all(
        submission.productMaterials.map((item) => {
          availableSpecs.push({
            deleted_at: null,
            option_value: {
              data: {
                value:
                  item.name || item.option_value?.name || item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Material',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('OTHERSPECS', 'SUB', submission.otherSpecifications)

    submission?.otherSpecifications?.map((spec) => {
      // console.log(spec)
      spec.option_value.value.map((value) => {
        availableSpecs.push({
          deleted_at: null,
          option_value: {
            data: {
              value,
              option: {
                data: {
                  name: spec.name,
                },
                on_conflict: {
                  constraint: '^option_name_key^',
                  update_columns: '^name^',
                },
              },
            },
            on_conflict: {
              constraint: '^option_value_option_id_value_key^',
              update_columns: ['^value^', '^option_id^'],
            },
          },
        })
      })
    })

    debugMode && console.count('STEP')
    debugMode && console.log('PRODSPECS', availableSpecs)

    if (availableSpecs.length > 0) {
      params.product_specifications = {
        data: availableSpecs,
      }
    }

    params.product_quotes = {
      data: [
        {
          components: {
            data: [
              {
                name: 'Base',
              },
            ],
          },
          ...(submission.pieces_per_carton && {
            pieces_per_carton: submission.pieces_per_carton,
          }),
          ...(submission.containerRate20ft && {
            container_rate_20ft: Math.round(submission.containerRate20ft * 100),
          }),
          ...(submission.containerRate40ft && {
            container_rate_40ft: Math.round(submission.containerRate40ft * 100),
          }),
          ...(submission.containerRate40ftHC && {
            container_rate_40ft_hc: Math.round(submission.containerRate40ftHC * 100),
          }),
          ...(submission.freightRateSea && {
            freight_rate_sea: Math.round(submission.freightRateSea * 100),
          }),
          ...(submission.freightRateAir && {
            freight_rate_air: Math.round(submission.freightRateAir * 100),
          }),
          ...(submission.does_sea_freight_include_duties && {
            does_sea_freight_include_duties: submission.does_sea_freight_include_duties,
          }),
          container_capacity_20ft: 25,
          container_capacity_40ft: 60,
          container_capacity_40ft_hc: 65,
          scm_quotes: {
            data: [
              {
                ...(submission.containerRate20ft && {
                  container_rate_20ft: Math.round(submission.containerRate20ft * 100),
                }),
                ...(submission.containerRate40ft && {
                  container_rate_40ft: Math.round(submission.containerRate40ft * 100),
                }),
                ...(submission.containerRate40ftHC && {
                  container_rate_40ft_hc: Math.round(submission.containerRate40ftHC * 100),
                }),
                ...(submission.freightRateSea && {
                  freight_rate_sea: Math.round(submission.freightRateSea * 100),
                }),
                ...(submission.freightRateAir && {
                  freight_rate_air: Math.round(submission.freightRateAir * 100),
                }),
                ...(submission.dutyRate && {
                  duty_rate: Math.round(submission.dutyRate * 100),
                }),
                ...(submission.does_sea_freight_include_duties && {
                  does_sea_freight_include_duties: submission.does_sea_freight_include_duties,
                }),
                container_capacity_20ft: 25,
                container_capacity_40ft: 60,
                container_capacity_40ft_hc: 65,
              },
            ],
          },
        },
      ],
    }

    params.product_quotes.data.components = { data: [{ name: 'Base' }] }

    submission.product_quotes[0]?.allow_order_splitting_by_size
      ? (params.product_quotes.data.allow_order_splitting_by_size =
          submission.product_quotes[0]?.allow_order_splitting_by_size)
      : null
    submission.product_quotes[0]?.supplier_id
      ? (params.product_quotes.data[0].supplier_id = submission.product_quotes[0]?.supplier_id)
      : null
    submission.product_quotes[0]?.toolingCost === 0
      ? (params.tooling_cost = 0)
      : submission.product_quotes[0]?.tooling_lead_time
      ? (params.product_quotes.data[0].tooling_cost = Math.round(submission.toolingCost * 100))
      : null
    submission.product_quotes[0]?.tooling_lead_time === 0
      ? params.product_quotes.data[0].tooling_lead_time
      : submission.product_quotes[0]?.tooling_lead_time
      ? (params.product_quotes.data[0].tooling_lead_time =
          submission.product_quotes[0]?.tooling_lead_time)
      : null
    submission.sampleCost === 0
      ? (params.product_quotes.data[0].sample_cost = 0)
      : submission.sampleCost
      ? (params.product_quotes.data[0].sample_cost = Math.round(submission.sampleCost * 100))
      : null
    submission.customSampleCost === 0
      ? (params.product_quotes.data[0].custom_sample_cost = 0)
      : submission.customSampleCost
      ? (params.product_quotes.data[0].custom_sample_cost = Math.round(
          submission.customSampleCost * 100,
        ))
      : null
    submission.product_quotes[0]?.sample_lead_time === 0
      ? (params.product_quotes.data[0].sample_lead_time = 0)
      : submission.product_quotes[0]?.sample_lead_time
      ? (params.product_quotes.data[0].sample_lead_time =
          submission.product_quotes[0]?.sample_lead_time)
      : null
    submission.product_quotes[0]?.valid_date
      ? (params.product_quotes.data[0].valid_date = submission.product_quotes[0]?.valid_date)
      : null
    submission.product_quotes[0]?.packaging_type
      ? (params.product_quotes.data[0].packaging_type =
          submission.product_quotes[0]?.packaging_type)
      : null
    submission.product_quotes[0]?.pieces_per_carton === 0
      ? (params.product_quotes.data[0].pieces_per_carton = 0)
      : submission.product_quotes[0]?.pieces_per_carton
      ? (params.product_quotes.data[0].pieces_per_carton =
          submission.product_quotes[0]?.pieces_per_carton)
      : null
    submission.product_quotes[0]?.cartons_per_pallet === 0
      ? (params.product_quotes.data[0].cartons_per_pallet = 0)
      : submission.product_quotes[0]?.cartons_per_pallet
      ? (params.product_quotes.data[0].cartons_per_pallet =
          submission.product_quotes[0]?.cartons_per_pallet)
      : null
    submission.product_quotes[0]?.cartons_per_container_20ft === 0
      ? (params.product_quotes.data[0].cartons_per_container_20ft = 0)
      : submission.product_quotes[0]?.cartons_per_container_20ft
      ? (params.product_quotes.data[0].cartons_per_container_20ft =
          submission.product_quotes[0]?.cartons_per_container_20ft)
      : null
    submission.product_quotes[0]?.cartons_per_container_40ft === 0
      ? (params.product_quotes.data[0].cartons_per_container_40ft = 0)
      : submission.product_quotes[0]?.cartons_per_container_40ft
      ? (params.product_quotes.data[0].cartons_per_container_40ft =
          submission.product_quotes[0]?.cartons_per_container_40ft)
      : null
    submission.product_quotes[0]?.cartons_per_container_40ft_hc === 0
      ? (params.product_quotes.data[0].cartons_per_container_40ft_hc = 0)
      : submission.product_quotes[0]?.cartons_per_container_40ft_hc
      ? (params.product_quotes.data[0].cartons_per_container_40ft_hc =
          submission.product_quotes[0]?.cartons_per_container_40ft_hc)
      : null

    debugMode && console.count('STEP')
    debugMode && console.log('QUOTEPRICES', submission.factoryPrices)
    // PRODUCT QUOTE PRICES
    if (submission.factoryPrices?.length > 0) {
      const prices = []
      await Promise.all(
        submission.factoryPrices?.map(async (item) => {
          prices.push({
            quantity: item.quantity,
            unit_price: Math.round(item.unitPrice * 100),
            lead_time: item.production_lead_time,
            ...(item.landed_air && {
              est_landed_cost_air: Math.round(item.landed_air * 100),
            }),
            ...(item.landed_sea && {
              est_landed_cost_sea: Math.round(item.landed_sea * 100),
            }),
            ...(item.totalCBM && { total_cbm: Math.round(item.totalCBM * 100) }),
            ...(item.shipmentWeight && {
              shipment_weight: Math.round(item.shipmentWeight * 100),
            }),
            ...(item.totalNumCartons && {
              total_cartons: Math.round(item.totalNumCartons),
            }),
            ...(item.totalDuties && {
              total_duties: Math.round(item.totalDuties * 100),
            }),
            ...(item.totalFreightCostAir && {
              freight_cost_air: Math.round(item.totalFreightCostAir * 100),
            }),
            ...(item.totalFreightCostSea && {
              freight_cost_sea: Math.round(item.totalFreightCostSea * 100),
            }),
            ...(item.freightCostPerPieceAir && {
              freight_cost_air_per_piece: Math.round(item.freightCostPerPieceAir * 100),
            }),
            ...(item.freightCostPerPieceSea && {
              freight_cost_sea_per_piece: Math.round(item.freightCostPerPieceSea * 100),
            }),
            ...(item.containers && { containers: item.containers }),
          })
        }),
      )
      params.product_quotes.data.prices = {
        data: prices,
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PARAMS:', params)

    const product = {
      objects: params,
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODUCT: ', product)
    const resp = await dataProvider.createProduct('product', product)

    debugMode && console.count('STEP')
    debugMode && console.log('RESPONSE: ', resp)

    notify('Updated Successfully.', { type: 'info' })
    debugMode && console.timeEnd('SAVING DATA TIMER')
  } catch (error) {
    console.timeEnd('SAVING DATA TIMER')
    console.log(error)
    notify(error.message.split('.')[0], { type: 'warning' })

    return false
  }
}

// export const handleSave = async ({
//   submission,
//   action,
//   dataProvider,
//   notify,
//   record,
//   identity,
// }) => {
//   // Set to false to disable console logs
//   const debugMode = true
//   debugMode && console.log('SAVING DATA')
//   debugMode && console.time('SAVING DATA TIMER')
//   debugMode && console.log('REC:', record)
//   debugMode && console.log('SUB:', submission)
//   debugMode && console.log('ACTION:', action)

//   const currentTime = moment().toISOString()

//   const params = {}

//   debugMode && console.log('IDENTITY:', identity)

//   params.sca_user_id = submission.sca_user_id || identity.id

//   // PRODUCT
//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODUCT', params)

//   // action === 'submit' ? (params.status = '^PRIVATE^') : (params.status = '^PENDING_SUBMISSION^')
//   params.status = '^PRIVATE^'
//   submission.id ? (params.id = submission.id) : null
//   submission.name ? (params.name = submission.name) : null
//   submission.is_catalogue ? (params.is_catalogue = submission.is_catalogue) : null
//   submission.short_description ? (params.short_description = submission.short_description) : null
//   submission.notes ? (params.notes = submission.notes) : null
//   submission.allow_colour_selection
//     ? (params.allow_colour_selection = submission.allow_colour_selection)
//     : null
//   submission.root_sku ? (params.root_sku = submission.root_sku) : null
//   submission.catalogue_sku ? (params.catalogue_sku = submission.catalogue_sku) : null
//   submission.packaging_type ? (params.packaging_type = submission.packaging_type) : null
//   submission.pieces_per_carton === 0
//     ? (params.pieces_per_carton = 0)
//     : submission.pieces_per_carton
//     ? (params.pieces_per_carton = submission.pieces_per_carton)
//     : null
//   submission.hts_code ? (params.hts_code = submission.hts_code) : null
//   submission.dutyRate === 0
//     ? (params.duty_rate = 0)
//     : submission.dutyRate
//     ? (params.duty_rate = Math.round(submission.dutyRate * 100))
//     : null
//   submission.brand ? (params.brand = submission.brand) : null
//   submission.root_product_id ? (params.root_product_id = submission.root_product_id) : null
//   submission.chargeable_weight_air === 0
//     ? (params.chargeable_weight_air = 0)
//     : submission.chargeable_weight_air
//     ? (params.chargeable_weight_air = Math.round(submission.chargeable_weight_air))
//     : null
//   submission.chargeable_weight_sea === 0
//     ? (params.chargeable_weight_sea = 0)
//     : submission.chargeable_weight_sea
//     ? (params.chargeable_weight_sea = Math.round(submission.chargeable_weight_sea))
//     : null

//   // PRODUCT MEDIA / FILES
//   debugMode && console.count('STEP')

//   debugMode && console.log('PRIMARYPHOTO', submission.primary_photo)

//   // PRIMARY PHOTO
//   if (submission.primary_photo) {
//     if (submission.primary_photo.id) {
//       params.primary_photo = {
//         data: {
//           id: submission.primary_photo.id,
//           url: submission.primary_photo.url,
//           filename: submission.primary_photo.filename,
//         },
//         on_conflict: {
//           constraint: '^file_pkey^',
//           update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//         },
//       }
//     } else {
//       const file = await uploadFile([submission.primary_photo])
//       console.log(file)
//       params.primary_photo = {
//         data: {
//           url: file?.data?.files[0]?.url || '',
//           filename: submission.primary_photo.name,
//         },
//         // on_conflict: {
//         //   constraint: '^file_filename_key^',
//         //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//         // },
//       }
//     }
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('THUMBNAIL', submission.primary_photo)

//   // THUMBNAIL PHOTO
//   if (submission.primary_photo) {
//     if (submission.primary_photo.id) {
//       params.thumbnail_photo = {
//         data: {
//           id: submission.primary_photo.id,
//           url: submission.primary_photo.url,
//           filename: submission.primary_photo.filename,
//         },
//         on_conflict: {
//           constraint: '^file_pkey^',
//           update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//         },
//       }
//     } else {
//       const file = await uploadFile([submission.primary_photo])
//       params.thumbnail_photo = {
//         data: {
//           url: file?.data?.files[0]?.url || '',
//           filename: submission.primary_photo.name,
//         },
//         // on_conflict: {
//         //   constraint: '^file_filename_key^',
//         //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//         // },
//       }
//     }
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('ADDITIONAL', [...submission.additional_files, ...submission.images])

//   const addFiles = [...submission.additional_files, ...submission.images]

//   // ADDITIONAL FILES
//   const files = []
//   if (addFiles && addFiles?.length > 0) {
//     await Promise.all(
//       addFiles.map(async (photo) => {
//         if (photo.file.id) {
//           files.push({
//             file: {
//               data: {
//                 id: photo.file.id,
//                 filename: photo.file.filename,
//                 url: photo.file.url,
//               },
//               on_conflict: {
//                 constraint: '^file_pkey^',
//                 update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//               },
//             },
//             deleted_at: photo.deleted_at || null,
//             type: `^${photo.type}^`,
//             id: photo.id,
//           })
//         } else {
//           const file = await uploadFile([photo.file])
//           debugMode && console.log(photo)
//           files.push({
//             type: `^${photo.type}^`,
//             file: {
//               data: {
//                 url: file?.data?.files[0]?.url || '',
//                 filename: photo.file.name || photo.file.path,
//               },
//               // on_conflict: {
//               //   constraint: '^file_filename_key^',
//               //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//               // },
//             },
//           })
//         }
//       }),
//     )
//   }

//   if (files.length > 0) {
//     params.product_files = {
//       data: files,
//       on_conflict: {
//         constraint: '^product_file_pkey^',
//         update_columns: ['^type^', '^product_id^', '^deleted_at^'],
//       },
//     }
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODTAGS', record?.product_tags, submission?.tags)

//   const delTags = []

//   // TAGS
//   const tags = []

//   submission.tags.map((tag) => {
//     tags.push({
//       tag_id: tag,
//     })
//   })

//   record?.product_tags?.map((recTag) => {
//     if (!submission.tags.some((tag) => tag === recTag.tag.id)) {
//       // tags.push({
//       //   category_id: recCat.category.id,
//       //   deleted_at: currentTime,
//       // })
//       delTags.push(recTag.id)
//     }
//   })

//   if (tags.length > 0) {
//     params.product_tags = {
//       data: tags,
//       on_conflict: {
//         constraint: '^product_tags_tag_id_product_id_key^',
//         update_columns: ['^tag_id^', '^product_id^'],
//       },
//     }
//     debugMode && console.log('PARTAGS', params.product_tags)
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODCATS', record?.product_categories, submission?.categories)

//   // CATEGORIES
//   if (submission.categories?.length > 0) {
//     const cats = []

//     submission.categories.map((cat) => {
//       cats.push({
//         category_id: cat,
//         deleted_at: null,
//       })
//     })

//     record?.product_categories?.map((recCat) => {
//       if (!submission.categories.some((cat) => cat === recCat.category.id)) {
//         cats.push({
//           category_id: recCat.category.id,
//           deleted_at: currentTime,
//         })
//       }
//     })

//     if (cats.length > 0) {
//       params.product_categories = {
//         data: cats,
//         on_conflict: {
//           constraint: '^product_categories_product_id_category_id_key^',
//           update_columns: ['^category_id^', '^deleted_at^'],
//         },
//       }
//       debugMode && console.log('PARCATS', params.product_categories)
//     }
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODDECS', submission.product_declarations)
//   // PRODUCT DECLARATIONS
//   if (
//     submission.product_declarations?.length > 0 &&
//     submission.product_declarations[0].declaration &&
//     submission.product_declarations[0].file
//   ) {
//     const decs = []
//     await Promise.all(
//       submission.product_declarations.map(async (dec) => {
//         if (dec.file?.id) {
//           decs.push({
//             ...(!!dec.id && { id: dec.id }),
//             // id: dec.id || null,
//             declaration: dec.declaration,
//             file: {
//               data: {
//                 id: dec.file.id,
//                 url: dec.file.url,
//                 filename: dec.file.filename,
//               },
//               on_conflict: {
//                 constraint: '^file_pkey^',
//                 update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//               },
//             },
//           })
//         } else {
//           const file = dec.file ? await uploadFile([dec.file.rawFile]) : null
//           decs.push({
//             ...(!!dec.id && { id: dec.id }),
//             // id: dec.id || null,
//             declaration: dec.declaration,
//             ...(!!dec.file.rawFile && {
//               file: {
//                 data: {
//                   filename: dec.file.filename,
//                   url: file.data.files[0].url,
//                 },
//                 // on_conflict: {
//                 //   constraint: '^file_filename_key^',
//                 //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
//                 // },
//               },
//             }),
//           })
//         }
//       }),
//     )
//     params.product_declarations = {
//       data: decs,
//       on_conflict: {
//         constraint: '^product_declaration_pkey^',
//         update_columns: '^declaration^',
//       },
//     }
//   }

//   // CUSTOMISATIONS
//   debugMode && console.count('STEP')
//   const customisations = []
//   const delCusts = []
//   debugMode &&
//     console.log(
//       'CUSTOMOPTS',
//       'REC',
//       record?.customisations,
//       'SUB',
//       submission.productCustomisations,
//     )

//   record?.customisations?.map((spec) => {
//     if (
//       !submission.productCustomisations?.some((item) => item.name === spec.option_value.option.name)
//     ) {
//       delCusts.push(spec.option_value.option.name)
//     }
//   })

//   debugMode && console.count('STEP')
//   debugMode && console.log('DELCUSTS', delCusts)

//   const delCustsUnique = []
//   if (delCusts) {
//     delCusts.map((spec) => {
//       if (delCustsUnique.indexOf(spec) === -1) {
//         delCustsUnique.push(spec)
//       }
//     })
//   }

//   debugMode && console.log('DELCUSTSUQ', delCustsUnique)

//   submission.productCustomisations?.map((spec) => {
//     console.log(spec)
//     spec.option_value.value.map((item) => {
//       console.log(item)
//       customisations.push({
//         option_value: {
//           data: {
//             value: item,
//             option: {
//               data: {
//                 name: spec.name,
//               },
//               on_conflict: {
//                 constraint: '^option_name_key^',
//                 update_columns: '^name^',
//               },
//             },
//           },
//           on_conflict: {
//             constraint: '^option_value_option_id_value_key^',
//             update_columns: ['^value^', '^option_id^'],
//           },
//         },
//       })
//     })
//   })

//   if (customisations.length > 0) {
//     params.customisations = {
//       data: customisations,
//       on_conflict: {
//         constraint: '^product_customisations_product_id_customisation_id_key^',
//         update_columns: ['^product_id^', '^customisation_id^'],
//       },
//     }
//   }

//   params.product_dimensions = { data: {} }
//   params.packaging_dimensions = { data: {} }
//   params.carton_dimensions = { data: {} }
//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODDIMS', submission.productDimensions)
//   // QUOTE.PRODUCT DIMENSIONS
//   submission.productDimensions?.length === 0
//     ? (params.product_dimensions.data.length = 0)
//     : submission.productDimensions?.length
//     ? (params.product_dimensions.data.length = Math.round(
//         submission.productDimensions?.length * 100,
//       ))
//     : null
//   submission.productDimensions?.width === 0
//     ? (params.product_dimensions.data.width = 0)
//     : submission.productDimensions?.width
//     ? (params.product_dimensions.data.width = Math.round(submission.productDimensions?.width * 100))
//     : null
//   submission.productDimensions?.height === 0
//     ? (params.product_dimensions.data.height = 0)
//     : submission.productDimensions?.height
//     ? (params.product_dimensions.data.height = Math.round(
//         submission.productDimensions?.height * 100,
//       ))
//     : null
//   submission.productDimensions?.gross_weight === 0
//     ? (params.product_dimensions.data.gross_weight = 0)
//     : submission.productDimensions?.gross_weight
//     ? (params.product_dimensions.data.gross_weight = Math.round(
//         submission.productDimensions?.gross_weight * 100,
//       ))
//     : null
//   submission.productDimensions?.id
//     ? (params.product_dimensions.data.id = submission.productDimensions?.id)
//     : null
//   // QUOTE.PACKAGING DIMENSIONS
//   debugMode && console.log('PKGDIMS', submission.packagingDimensions)
//   submission.packagingDimensions?.length === 0
//     ? (params.packaging_dimensions.data.length = 0)
//     : submission.packagingDimensions?.length
//     ? (params.packaging_dimensions.data.length = Math.round(
//         submission.packagingDimensions?.length * 100,
//       ))
//     : null
//   submission.packagingDimensions?.width === 0
//     ? (params.packaging_dimensions.data.width = 0)
//     : submission.packagingDimensions?.width
//     ? (params.packaging_dimensions.data.width = Math.round(
//         submission.packagingDimensions?.width * 100,
//       ))
//     : null
//   submission.packagingDimensions?.height === 0
//     ? (params.packaging_dimensions.data.height = 0)
//     : submission.packagingDimensions?.height
//     ? (params.packaging_dimensions.data.height = Math.round(
//         submission.packagingDimensions?.height * 100,
//       ))
//     : null
//   submission.packagingDimensions?.gross_weight === 0
//     ? (params.packaging_dimensions.data.gross_weight = 0)
//     : submission.packagingDimensions?.gross_weight
//     ? (params.packaging_dimensions.data.gross_weight = Math.round(
//         submission.packagingDimensions?.gross_weight * 100,
//       ))
//     : null
//   submission.packagingDimensions?.id
//     ? (params.packaging_dimensions.data.id = submission.packagingDimensions?.id)
//     : null
//   // QUOTE.CARTON DIMENSIONS
//   debugMode && console.log('CARTDIMS', submission.cartonDimensions)
//   submission.cartonDimensions?.length === 0
//     ? (params.carton_dimensions.data.length = 0)
//     : submission.cartonDimensions?.length
//     ? (params.carton_dimensions.data.length = Math.round(submission.cartonDimensions?.length * 100))
//     : null
//   submission.cartonDimensions?.width === 0
//     ? (params.carton_dimensions.data.width = 0)
//     : submission.cartonDimensions?.width
//     ? (params.carton_dimensions.data.width = Math.round(submission.cartonDimensions?.width * 100))
//     : null
//   submission.cartonDimensions?.height === 0
//     ? (params.carton_dimensions.data.height = 0)
//     : submission.cartonDimensions?.height
//     ? (params.carton_dimensions.data.height = Math.round(submission.cartonDimensions?.height * 100))
//     : null
//   submission.cartonDimensions?.gross_weight === 0
//     ? (params.carton_dimensions.data.gross_weight = 0)
//     : submission.cartonDimensions?.gross_weight
//     ? (params.carton_dimensions.data.gross_weight = Math.round(
//         submission.cartonDimensions?.gross_weight * 100,
//       ))
//     : null
//   submission.cartonDimensions?.id
//     ? (params.carton_dimensions.data.id = submission.cartonDimensions?.id)
//     : null

//   if (params.carton_dimensions?.data && Object.keys(params.carton_dimensions?.data).length === 0) {
//     delete params.carton_dimensions
//   } else {
//     params.carton_dimensions.on_conflict = {
//       constraint: '^dimension_pkey^',
//       update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
//     }
//   }
//   if (
//     params.packaging_dimensions?.data &&
//     Object.keys(params.packaging_dimensions?.data).length === 0
//   ) {
//     delete params.packaging_dimensions
//   } else {
//     params.packaging_dimensions.on_conflict = {
//       constraint: '^dimension_pkey^',
//       update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
//     }
//   }
//   if (
//     params.product_dimensions?.data &&
//     Object.keys(params.product_dimensions?.data).length === 0
//   ) {
//     delete params.product_dimensions
//   } else {
//     params.product_dimensions.on_conflict = {
//       constraint: '^dimension_pkey^',
//       update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
//     }
//   }

//   // PRODOPTIONS

//   const availableOptions = []

//   const delColourOptions =
//     record?.product_options
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Colour' &&
//           !submission.colourOptions.some(
//             (item) => spec.option_value?.value === item.option_value?.value,
//           ),
//       )
//       .map((item) => item.id) || []

//   debugMode &&
//     console.log('COLOUROPTIONS', 'SUB', submission.colourOptions, 'DEL', delColourOptions)

//   if (submission.colourOptions?.length > 0) {
//     await Promise.all(
//       submission.colourOptions.map((item) => {
//         availableOptions.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item.value || item.option_value?.value,
//               option: {
//                 data: {
//                   name: 'Colour',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   // MATERIALOPTIONS
//   const delMaterialOptions =
//     record?.product_options
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Material' &&
//           !submission.materialOptions.some(
//             (item) => spec.option_value?.value === item.option_value?.value,
//           ),
//       )
//       .map((item) => item.id) || []

//   debugMode &&
//     console.log('MATERIALOPTIONS', 'SUB', submission.materialOptions, 'DEL', delMaterialOptions)

//   if (submission.materialOptions?.length > 0) {
//     await Promise.all(
//       submission.materialOptions.map((item) => {
//         availableOptions.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item.name || item.option_value?.name || item.value || item.option_value?.value,
//               option: {
//                 data: {
//                   name: 'Material',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   // IMPRINTOPTIONS
//   // const imprintOptions = record?.product_options?.filter(
//   //   (spec) => spec.option_value?.option?.name === 'Imprint',
//   // )
//   const delImprintOptions =
//     record?.product_options
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Imprint' &&
//           !submission.imprintOptions.some(
//             (item) => spec.option_value?.value === item.option_value?.value,
//           ),
//       )
//       .map((item) => item.id) || []

//   debugMode &&
//     console.log('IMPRINTOPTIONS', 'SUB', submission.imprintOptions, 'DEL', delImprintOptions)

//   if (submission.imprintOptions?.length > 0) {
//     await Promise.all(
//       submission.imprintOptions.map((item) => {
//         availableOptions.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item.name || item.option_value?.name || item.value || item.option_value?.value,
//               option: {
//                 data: {
//                   name: 'Imprint',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   // SIZEOPTIONS
//   const delSizeOptions =
//     record?.product_options
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Size' &&
//           !submission.sizeOptions.some((item) => spec.option_value?.value === item),
//       )
//       .map((item) => item.id) || []

//   debugMode && console.log('SIZEOPTIONS', 'SUB', submission.sizeOptions, 'DEL', delSizeOptions)

//   // NEW SIZE
//   // submission.sizeOption yes
//   // sizeOption no

//   // OLD SIZE
//   // submission.sizeOption yes
//   // sizeOption yes

//   // DELETE SIZE
//   // submission.sizeOption no
//   // sizeOption yes

//   if (submission.sizeOptions?.length > 0) {
//     await Promise.all(
//       submission.sizeOptions.map((item) => {
//         availableOptions.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item,
//               option: {
//                 data: {
//                   name: 'Size',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODOPTS', availableOptions)

//   if (availableOptions.length > 0) {
//     params.product_options = {
//       data: availableOptions,
//       on_conflict: {
//         constraint: '^product_options_product_id_specification_id_key^',
//         update_columns: ['^specification_id^', '^deleted_at^'],
//       },
//     }
//   }
//   // return null

//   const availableSpecs = []

//   // COLOURSPECS
//   const delColourSpecs =
//     record?.product_specifications
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Colour' &&
//           !submission.productColours.some(
//             (item) => spec.option_value?.value === item.option_value?.value,
//           ),
//       )
//       .map((item) => item.id) || []

//   debugMode && console.log('COLOURSPECS', 'SUB', submission.productColours, 'DEL', delColourSpecs)

//   if (submission.productColours?.length > 0) {
//     await Promise.all(
//       submission.productColours.map((item) => {
//         availableSpecs.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item.value || item.option_value?.value,
//               option: {
//                 data: {
//                   name: 'Colour',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   // MATERIALSPECS
//   const delMaterialSpecs =
//     record?.product_specifications
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Material' &&
//           !submission.productMaterials.some(
//             (item) => spec.option_value?.value === item.option_value?.value,
//           ),
//       )
//       .map((item) => item.id) || []

//   debugMode &&
//     console.log('MATERIALSPECS', 'SUB', submission.productMaterials, 'DEL', delMaterialSpecs)

//   if (submission.productMaterials?.length > 0) {
//     await Promise.all(
//       submission.productMaterials.map((item) => {
//         availableSpecs.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item.name || item.option_value?.name || item.value || item.option_value?.value,
//               option: {
//                 data: {
//                   name: 'Material',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   // IMPRINTSPECS
//   const delImprintSpecs =
//     record?.product_specifications
//       ?.filter(
//         (spec) =>
//           spec.option_value?.option?.name === 'Imprint' &&
//           !submission.productImprints.some(
//             (item) => spec.option_value?.value === item.option_value?.value,
//           ),
//       )
//       .map((item) => item.id) || []

//   debugMode &&
//     console.log('IMPRINTSPECS', 'SUB', submission.productImprints, 'DEL', delImprintSpecs)

//   if (submission.productImprints?.length > 0) {
//     await Promise.all(
//       submission.productImprints.map((item) => {
//         availableSpecs.push({
//           deleted_at: null,
//           option_value: {
//             data: {
//               value: item.name || item.option_value?.name || item.value || item.option_value?.value,
//               option: {
//                 data: {
//                   name: 'Imprint',
//                 },
//                 on_conflict: {
//                   constraint: '^option_name_key^',
//                   update_columns: '^name^',
//                 },
//               },
//             },
//             on_conflict: {
//               constraint: '^option_value_option_id_value_key^',
//               update_columns: ['^value^', '^option_id^'],
//             },
//           },
//         })
//       }),
//     )
//   }

//   const delOtherSpecifications = []
//   record?.product_specifications
//     ?.filter(
//       (spec) =>
//         spec.option_value?.option?.name !== 'Colour' &&
//         spec.option_value?.option?.name !== 'Material' &&
//         spec.option_value?.option?.name !== 'Imprint',
//     )
//     .map((spec) => {
//       const obj = submission.otherSpecifications.find(
//         (item) => item?.name === spec.option_value?.option?.name,
//       )
//       // console.log(obj, spec)
//       if (!obj?.option_value?.value?.some((item) => item === spec.option_value?.value)) {
//         delOtherSpecifications.push(spec.id)
//       }
//     }) || []

//   debugMode &&
//     console.log('OTHERSPECS', 'SUB', submission.otherSpecifications, 'DEL', delOtherSpecifications)

//   submission?.otherSpecifications?.map((spec) => {
//     // console.log(spec)
//     spec.option_value.value.map((value) => {
//       availableSpecs.push({
//         deleted_at: null,
//         option_value: {
//           data: {
//             value,
//             option: {
//               data: {
//                 name: spec.name,
//               },
//               on_conflict: {
//                 constraint: '^option_name_key^',
//                 update_columns: '^name^',
//               },
//             },
//           },
//           on_conflict: {
//             constraint: '^option_value_option_id_value_key^',
//             update_columns: ['^value^', '^option_id^'],
//           },
//         },
//       })
//     })
//   })

//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODSPECS', availableSpecs)

//   if (availableSpecs.length > 0) {
//     params.product_specifications = {
//       data: availableSpecs,
//       on_conflict: {
//         constraint: '^product_specifications_product_id_specification_id_key^',
//         update_columns: ['^specification_id^', '^deleted_at^'],
//       },
//     }
//   }

//   params.product_quotes = {
//     data: [
//       {
//         ...(!!submission.product_quotes.length > 0 && { id: submission.product_quotes[0].id }),
//         ...(submission.containerRate20ft && {
//           container_rate_20ft: Math.round(submission.containerRate20ft * 100),
//         }),
//         ...(submission.containerRate40ft && {
//           container_rate_40ft: Math.round(submission.containerRate40ft * 100),
//         }),
//         ...(submission.containerRate40ftHC && {
//           container_rate_40ft_hc: Math.round(submission.containerRate40ftHC * 100),
//         }),
//         ...(submission.freightRateSea && {
//           freight_rate_sea: Math.round(submission.freightRateSea * 100),
//         }),
//         ...(submission.freightRateAir && {
//           freight_rate_air: Math.round(submission.freightRateAir * 100),
//         }),
//         container_capacity_20ft: 25,
//         container_capacity_40ft: 60,
//         container_capacity_40ft_hc: 65,
//       },
//     ],
//   }
//   submission.product_quotes[0]?.supplier_id
//     ? (params.product_quotes.data[0].supplier_id = submission.product_quotes[0]?.supplier_id)
//     : null
//   submission.product_quotes[0]?.allow_order_splitting_by_size
//     ? (params.product_quotes.data.allow_order_splitting_by_size =
//         submission.product_quotes[0]?.allow_order_splitting_by_size)
//     : null
//   submission.toolingCost === 0
//     ? (params.product_quotes.data[0].tooling_cost = 0)
//     : submission.toolingCost
//     ? (params.product_quotes.data[0].tooling_cost = Math.round(submission.toolingCost * 100))
//     : null
//   submission.product_quotes[0]?.tooling_lead_time === 0
//     ? params.product_quotes.data[0].tooling_lead_time
//     : submission.product_quotes[0]?.tooling_lead_time
//     ? (params.product_quotes.data[0].tooling_lead_time =
//         submission.product_quotes[0]?.tooling_lead_time)
//     : null
//   submission.sampleCost === 0
//     ? (params.product_quotes.data[0].sample_cost = 0)
//     : submission.sampleCost
//     ? (params.product_quotes.data[0].sample_cost = Math.round(submission.sampleCost * 100))
//     : null
//   submission.customSampleCost === 0
//     ? (params.product_quotes.data[0].custom_sample_cost = 0)
//     : submission.customSampleCost
//     ? (params.product_quotes.data[0].custom_sample_cost = Math.round(
//         submission.customSampleCost * 100,
//       ))
//     : null
//   submission.product_quotes[0]?.sample_lead_time === 0
//     ? (params.product_quotes.data[0].sample_lead_time = 0)
//     : submission.product_quotes[0]?.sample_lead_time
//     ? (params.product_quotes.data[0].sample_lead_time =
//         submission.product_quotes[0]?.sample_lead_time)
//     : null
//   submission.product_quotes[0]?.valid_date
//     ? (params.product_quotes.data[0].valid_date = submission.product_quotes[0]?.valid_date)
//     : null
//   submission.product_quotes[0]?.packaging_type
//     ? (params.product_quotes.data[0].packaging_type = submission.product_quotes[0]?.packaging_type)
//     : null
//   submission.product_quotes[0]?.pieces_per_carton === 0
//     ? (params.product_quotes.data[0].pieces_per_carton = 0)
//     : submission.product_quotes[0]?.pieces_per_carton
//     ? (params.product_quotes.data[0].pieces_per_carton =
//         submission.product_quotes[0]?.pieces_per_carton)
//     : null
//   submission.product_quotes[0]?.cartons_per_pallet === 0
//     ? (params.product_quotes.data[0].cartons_per_pallet = 0)
//     : submission.product_quotes[0]?.cartons_per_pallet
//     ? (params.product_quotes.data[0].cartons_per_pallet =
//         submission.product_quotes[0]?.cartons_per_pallet)
//     : null
//   submission.product_quotes[0]?.cartons_per_container_20ft === 0
//     ? (params.product_quotes.data[0].cartons_per_container_20ft = 0)
//     : submission.product_quotes[0]?.cartons_per_container_20ft
//     ? (params.product_quotes.data[0].cartons_per_container_20ft =
//         submission.product_quotes[0]?.cartons_per_container_20ft)
//     : null
//   submission.product_quotes[0]?.cartons_per_container_40ft === 0
//     ? (params.product_quotes.data[0].cartons_per_container_40ft = 0)
//     : submission.product_quotes[0]?.cartons_per_container_40ft
//     ? (params.product_quotes.data[0].cartons_per_container_40ft =
//         submission.product_quotes[0]?.cartons_per_container_40ft)
//     : null
//   submission.product_quotes[0]?.cartons_per_container_40ft_hc === 0
//     ? (params.product_quotes.data[0].cartons_per_container_40ft_hc = 0)
//     : submission.product_quotes[0]?.cartons_per_container_40ft_hc
//     ? (params.product_quotes.data[0].cartons_per_container_40ft_hc =
//         submission.product_quotes[0]?.cartons_per_container_40ft_hc)
//     : null

//   debugMode && console.count('STEP')
//   debugMode && console.log('QUOTEPRICES', submission.prices)
//   // PRODUCT QUOTE PRICES
//   if (submission.prices?.length > 0) {
//     const prices = []
//     await Promise.all(
//       submission.prices?.map(async (item) => {
//         prices.push({
//           ...(typeof item.id === 'number' && { id: item.id }),
//           quantity: item.quantity,
//           unit_price: Math.round(item.unit_price * 100),
//           lead_time: item.lead_time,
//           ...(item.landed_air && { est_landed_cost_air: Math.round(item.landed_air * 100) }),
//           ...(item.landed_sea && { est_landed_cost_sea: Math.round(item.landed_sea * 100) }),
//           ...(item.totalCBM && { total_cbm: Math.round(item.totalCBM * 100) }),
//           ...(item.shipmentWeight && { shipment_weight: Math.round(item.shipmentWeight * 100) }),
//           ...(item.totalNumCartons && { total_cartons: Math.round(item.totalNumCartons) }),
//           ...(item.totalDuties && { total_duties: Math.round(item.totalDuties * 100) }),
//         })
//       }),
//     )
//     params.product_quotes.data[0].prices = {
//       data: prices,
//       on_conflict: {
//         constraint: '^product_quote_price_pkey^',
//         update_columns: [
//           '^unit_price^',
//           '^quantity^',
//           '^lead_time^',
//           '^est_landed_cost_air^',
//           '^est_landed_cost_sea^',
//           '^total_cbm^',
//           '^shipment_weight^',
//           '^total_cartons^',
//           '^total_duties^',
//         ],
//       },
//     }
//   }

//   if (Object.keys(params.product_quotes.data[0]).length === 0) {
//     delete params.product_quotes
//   } else {
//     params.product_quotes.on_conflict = {
//       constraint: '^product_quote_pkey1^',
//       update_columns: [
//         '^tooling_cost^',
//         '^sample_cost^',
//         '^custom_sample_cost^',
//         '^tooling_lead_time^',
//         '^sample_lead_time^',
//         '^valid_date^',
//         '^supplier_id^',
//         '^pieces_per_carton^',
//         '^cartons_per_pallet^',
//         '^cartons_per_container_20ft^',
//         '^cartons_per_container_40ft^',
//         '^cartons_per_container_40ft_hc^',
//         '^allow_order_splitting_by_size^',
//         '^packaging_type^',
//         '^container_rate_20ft^',
//         '^container_rate_40ft^',
//         '^container_rate_40ft_hc^',
//         '^freight_rate_sea^',
//         '^freight_rate_air^',
//         '^container_capacity_20ft^',
//         '^container_capacity_40ft^',
//         '^container_capacity_40ft_hc^',
//       ],
//     }
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('PARAMS:', params)

//   const product = {
//     objects: params,
//     on_conflict: {
//       constraint: '^product_pkey^',
//       update_columns: [
//         '^name^',
//         '^status^',
//         '^short_description^',
//         '^product_dimensions_id^',
//         '^notes^',
//         '^allow_colour_selection^',
//         '^packaging_dimensions_id^',
//         '^primary_photo_id^',
//         '^carton_dimensions_id^',
//         '^root_sku^',
//         '^thumbnail_photo_id^',
//         '^packaging_type^',
//         '^pieces_per_carton^',
//         '^root_product_id^',
//         '^hts_code^',
//         '^duty_rate^',
//         '^brand^',
//         '^chargeable_weight_sea^',
//         '^catalogue_sku^',
//         '^is_catalogue^',
//         '^chargeable_weight_air^',
//       ],
//     },
//   }

//   debugMode && console.count('STEP')
//   debugMode && console.log('PRODUCT: ', product)

//   // return null

//   try {
//     const resp = await dataProvider.createProduct('product', product)

//     debugMode && console.count('STEP')
//     debugMode && console.log('RESPONSE: ', resp)

//     const delSpecs = [
//       ...delColourSpecs,
//       ...delMaterialSpecs,
//       ...delImprintSpecs,
//       ...delOtherSpecifications,
//     ]
//     debugMode && console.count('STEP')
//     debugMode && console.log('DELSPECS', delSpecs)

//     if (delSpecs.length > 0) {
//       const delSpecsResp = await dataProvider.delete('product_specifications', { delSpecs })

//       debugMode && console.count('STEP')
//       debugMode && console.log('DELSPECSRESPONSE: ', delSpecsResp)
//     }

//     const delOpts = [
//       ...delColourOptions,
//       ...delMaterialOptions,
//       ...delImprintOptions,
//       ...delSizeOptions,
//     ]
//     debugMode && console.count('STEP')
//     debugMode && console.log('DELOPTS', delOpts)
//     if (delOpts.length > 0) {
//       const delOptsResp = await dataProvider.delete('product_options', { delOpts })

//       debugMode && console.count('STEP')
//       debugMode && console.log('DELOPTSRESPONSE: ', delOptsResp)
//     }

//     debugMode && console.count('STEP')
//     debugMode && console.log('DELCUSTS', delCustsUnique)
//     if (delCustsUnique.length > 0) {
//       const delCustsResp = await dataProvider.delete('product_customisations', { delCustsUnique })

//       debugMode && console.count('STEP')
//       debugMode && console.log('DELCUSTSRESPONSE: ', delCustsResp)
//     }

//     if (delTags.length > 0) {
//       debugMode && console.count('STEP')
//       debugMode && console.log('DELTAGS: ', delTags)
//       const delTagsResp = await dataProvider.deleteMany('product_tags', { ids: delTags })

//       debugMode && console.log('DELTAGSRESPONSE: ', delTagsResp)
//     }

//     notify('Updated Successfully.', { type: 'info' })
//     debugMode && console.timeEnd('SAVING DATA TIMER')
//   } catch (error) {
//     debugMode && console.log(error)
//     debugMode && console.timeEnd('SAVING DATA TIMER')
//     try {
//       // const LOGDNA_INGESTION_KEY = '0df56c85d5b5250a0b95784097b033a2'
//       // logdna.init(LOGDNA_INGESTION_KEY)
//       // logdna.error('Error saving product data', {
//       //   message: error.message,
//       //   submission: product,
//       // })
//     } catch (error) {
//       debugMode && console.log('DEBUG ERROR', error)
//     }
//     return false
//   }
// }

export const getDataLists = async (setDataLists, dataProvider, notify, abortController) => {
  const debugMode = false
  debugMode && console.log('GETTING DATA LISTS')
  debugMode && console.time('GETTING DATA TIMER')
  try {
    const listsTemp = {}

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING IMPRINTS')
    const { data: imprintsTemp } = await dataProvider.getList('imprints', {
      filter: { own: 'imprintTechnique' },
    })
    listsTemp.imprints = imprintsTemp
    debugMode && console.timeEnd('GETTING IMPRINTS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING PACKAGING STYLES')
    const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
      filter: { own: 'packagingStyle' },
    })
    listsTemp.packagingTypes = packagingTypesTemp
    debugMode && console.timeEnd('GETTING PACKAGING STYLES')

    // debugMode && console.count('STEP')
    // debugMode && consotimelog('GETTING CERTIFICATION TYPES')
    // const { data: certificationTypesList } = await dataProvider.getList('certificationType', {})
    // listsTemp.certificationTypes = certificationTypesList
    // debugMode && console.timeEnd('GETTING CERTIFICATION TYPES')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING CATEGORIES')
    const { data: categoriesTemp } = await dataProvider.getList('categories', {})
    listsTemp.categories = categoriesTemp
    debugMode && console.timeEnd('GETTING CATEGORIES')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING MATERIALS')
    const { data: materialsTemp } = await dataProvider.getList('materials', {
      filter: { own: '%material%' },
    })
    listsTemp.materials = materialsTemp
    debugMode && console.timeEnd('GETTING MATERIALS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING SUPPLIERS')
    const { data: suppliersTemp } = await dataProvider.getList('supplier', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    })
    listsTemp.suppliers = suppliersTemp
    debugMode && console.timeEnd('GETTING SUPPLIERS')

    // debugMode && console.count('STEP')
    // debugMode && console.time('GETTING QUALITY AGENCIES')
    // const { data: qualityAgenciesTemp } = await dataProvider.getList('qualityAgency', {})
    // listsTemp.qualityAgencies = qualityAgenciesTemp
    // debugMode && console.timeEnd('GETTING QUALITY AGENCIES')

    // debugMode && console.count('STEP')
    // debugMode && console.time('GETTING PAYMENT MILESTONES')
    // const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
    // listsTemp.factoryMilestones = milestonesTemp.filter((item) => item.type === 'factory')
    // listsTemp.qualityMilestones = milestonesTemp.filter((item) => item.type === 'quality')
    // listsTemp.logisticsMilestones = milestonesTemp.filter((item) => item.type === 'logistics')
    // listsTemp.clientMilestones = milestonesTemp.filter((item) => item.type === 'client')
    // debugMode && console.timeEnd('GETTING PAYMENT MILESTONES')

    // debugMode && console.count('STEP')
    // debugMode && console.time('GETTING INSPECTION LEVELS')
    // const { data: inspLevelsTemp } = await dataProvider.getList('inspectionLevel', {})
    // listsTemp.inspectionLevels = inspLevelsTemp
    // debugMode && console.timeEnd('GETTING INSPECTION LEVELS')

    // debugMode && console.count('STEP')
    // debugMode && console.time('GETTING INSPECTION TYPES')
    // const { data: inspectionTypesTemp } = await dataProvider.getList('inspectionType', {})
    // listsTemp.inspectionTypes = inspectionTypesTemp
    // debugMode && console.timeEnd('GETTING INSPECTION TYPES')

    // debugMode && console.count('STEP')
    // debugMode && console.time('GETTING FREIGHT FORWARDERS')
    // const { data: logisticsTemp } = await dataProvider.getList('freightForwarder', {})
    // listsTemp.logisticsProviders = logisticsTemp
    // debugMode && console.timeEnd('GETTING FREIGHT FORWARDERS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING CLIENTS')
    const { data: clientsTemp } = await dataProvider.getList('app_user', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
      clientsOnly: true,
    })
    listsTemp.clients = clientsTemp
    debugMode && console.timeEnd('GETTING CLIENTS')

    if (!abortController.signal.aborted) {
      setDataLists(listsTemp)
      debugMode && console.timeEnd('GETTING DATA TIMER')
    }
  } catch (error) {
    console.log(error)
    notify(error.message.split('.')[0], { type: 'warning' })

    debugMode && console.timeEnd('SAVING DATA TIMER')
    return
  }
}

export const onboardingStatus = (record) => {
  // checks which fields are required to be able to complete each section
  // console.log(record)

  const onboardingComplete =
    record.title &&
    record.sku &&
    record.short_desc &&
    record.brand_id &&
    record.sub_category_id &&
    record.primary_supplier_id &&
    true

  const designComplete = record.lengthCM && record.widthCM && record.heightCM && record.weightKG
  const packagingComplete =
    record.packagings &&
    record.packagings[0].master_carton_quantity &&
    record.packagings[0].carton_width_cm &&
    record.packagings[0].carton_length_cm &&
    record.packagings[0].carton_height_cm &&
    record.packagings[0].carton_gross_weight &&
    record.packagings[0].carton_net_weight &&
    record.packagings[0].packaging_price &&
    record.packagings[0].sku_inset &&
    record.packagings[0].packagingLengthCM &&
    record.packagings[0].packagingHeightCM &&
    record.packagings[0].packagingWidthCM &&
    record.packagings[0].packagingVolume &&
    record.packagings[0].packagingWeightKG &&
    record.packagings[0].carton_volume &&
    record.packagings[0].packagingStyleID

  const qualityComplete = record.product_inspection && record.product_inspection[0].pass
  const quotingComplete = record.quotes && true

  const readyFDR = record.scmAgentFDR && record.commissionFDR
  const readySC = record.influencerSC && record.commissionSC

  return {
    onboardingComplete,
    designComplete,
    qualityComplete,
    quotingComplete,
    packagingComplete,
    readyFDR,
    readySC,
    approved: record.approved_by,
  }
}
