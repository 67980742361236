import React, { useState } from 'react'
import {
  Box,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import {
  required,
  email,
  useNotify,
  useDataProvider,
  FormWithRedirect,
  Button,
  regex,
} from 'react-admin'
import { uniqueId } from 'lodash'
import lodashGet from 'lodash/get'
import lodashMemoize from 'lodash/memoize'
import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import useStyles from '../styles'
import SelectInput from '../../../../../components/input/SelectInput'
import TextInput from '../../../../../components/OutlinedTextInput'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#232323',
    color: '#FFFFFF',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRowCell = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '2px',
  },
}))(TableCell)

const StyledTableRowCellRight = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    padding: '2px',
  },
}))(TableCell)

const Contact = (props) => {
  // console.log(props)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const [editRow, setEditRow] = useState()
  const [newRow, setNewRow] = useState()

  const createContact = async (params) => {
    const submission = {
      ...params,
      supplier: props.supplierID,
    }
    try {
      const { data } = await dataProvider.createSupplierContact('supplier', { ...submission })
      if (data.id !== undefined) {
        refresh()
      }

      setNewRow(null)
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const deleteContact = async (params) => {
    // console.log("DEL", params)
    try {
      const { data } = await dataProvider.deleteSupplierContact('supplier', { id: params })
      // console.log(data)
      if (data) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const updateContact = async (params, id) => {
    const submission = {
      ...params,
      id,
    }
    try {
      const { data } = await dataProvider.updateSupplierContact('supplier', { ...submission })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const updateSupplier = async (submission) => {
    const { supplierID } = props
    const params = {
      id: supplierID,
    }
    if (submission.primaryContact) {
      params.primary_contact_id = submission.primaryContact
    }

    // console.log(params)
    try {
      const { data } = await dataProvider.updateSupplier('supplier', { ...params })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const [editPrimary, setEditPrimary] = useState(false)

  return (
    <Box>
      <Box className={classes.approvalBoxBlue}>
        <Box flex={4} className={classes.approvalTextSales}>
          <span className={classes.sectionHeader}>Contacts</span>
          <Box m="10px 0px 0px" display="flex">
            {editPrimary ? (
              <FormWithRedirect
                key="updateSupplierPrimary"
                // {...props}
                initialValues={{ primaryContact: props.primaryContact?.id }}
                render={() => (
                  <EditContactForm
                    setEditPrimary={setEditPrimary}
                    contacts={props.contacts}
                    updateSupplier={updateSupplier}
                  />
                )}
              />
            ) : (
              <Box display="flex">
                <span className={classes.fieldLabelEditable}>Primary Contact:</span>
                <span className={classes.fieldValueEditable}>
                  {props.primaryContact
                    ? `${
                        props.primaryContact.first_name ? props.primaryContact.first_name + ' ' : ''
                      } ${
                        props.primaryContact.last_name ? props.primaryContact.last_name + ' ' : ''
                      } ${props.primaryContact.email ? props.primaryContact.email + ' ' : ''} ${
                        props.primaryContact.mobile ? props.primaryContact.mobile : ''
                      }`
                    : null}
                  <IconButton
                    onClick={() => {
                      setEditPrimary(true)
                    }}
                    style={{ maxWidth: '50px', maxHeight: '50px' }}
                  >
                    <BorderColorIcon fontSize="small" />
                  </IconButton>
                </span>
              </Box>
            )}
          </Box>

          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Contact Person</StyledTableCell>
                  <StyledTableCell align="center">Department</StyledTableCell>
                  <StyledTableCell align="center">Mobile Number</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">WeChat</StyledTableCell>
                  <StyledTableCell align="center">WhatsApp</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.contacts.map((row, index) =>
                  editRow === index ? (
                    <FormWithRedirect
                      key="form1"
                      {...props}
                      initialValues={{
                        firstName: row.first_name,
                        lastName: row.last_name,
                        email: row.email,
                        whatsapp: row.whatsapp,
                        wechat: row.wechat,
                        position: row.position,
                        mobile: row.mobile,
                      }}
                      render={() => (
                        <EditableRow
                          key={row.id}
                          data={row}
                          index={index}
                          setEditRow={setEditRow}
                          updateContact={updateContact}
                        />
                      )}
                    />
                  ) : (
                    <TableRow key={`cert${row.id}`}>
                      <TableCell align="center">{`${row.first_name ? row.first_name : ''} ${
                        row.last_name ? row.last_name : ''
                      }`}</TableCell>
                      <StyledTableRowCell align="center">
                        <span style={{ textTransform: 'capitalize' }}>{row.position}</span>
                      </StyledTableRowCell>
                      <StyledTableRowCell align="center">{row.mobile}</StyledTableRowCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <StyledTableRowCell align="center">{row.wechat}</StyledTableRowCell>
                      <StyledTableRowCell align="center">{row.whatsapp}</StyledTableRowCell>
                      <StyledTableRowCellRight align="center">
                        <Box display="flex">
                          <IconButton
                            onClick={() => {
                              setEditRow(index)
                            }}
                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                          >
                            <BorderColorIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              deleteContact(row.id)
                            }}
                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                          >
                            <DeleteForeverOutlined fontSize="small" />
                          </IconButton>
                        </Box>
                      </StyledTableRowCellRight>
                    </TableRow>
                  ),
                )}
                {newRow && (
                  <FormWithRedirect
                    key="form2"
                    {...props}
                    render={() => (
                      <EditableRow
                        key={newRow.id}
                        newContact
                        setNewRow={setNewRow}
                        createContact={createContact}
                      />
                    )}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!newRow && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                className={classes.addNewButton}
                label="Add new"
                onClick={() => setNewRow({ id: uniqueId() })}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const EditableRow = (props) => {
  const classes = useStyles()
  // const { data } = props
  const form = useForm()
  const { values } = useFormState()
  // const [saving, setSaving] = useState(false)

  const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args))

  const mobile = memoize((message = 'Must be valid number') => regex(/^1[3456789]\d{9}$/, message))

  return (
    <TableRow>
      <StyledTableRowCell align="center">
        <Box display="flex">
          <TextInput
            variant="outlined"
            fullWidth
            validate={required()}
            className={`${classes.areaText} ${classes.hideLabel}`}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="firstName"
            label="First Name"
          />
          {/* </StyledTableRowCell>

      <StyledTableRowCell align='center'> */}
          <TextInput
            variant="outlined"
            fullWidth
            // validate={required()}
            className={`${classes.areaText} ${classes.hideLabel}`}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="lastName"
            label="Last Name:"
          />
        </Box>
      </StyledTableRowCell>

      <StyledTableRowCell>
        <SelectInput
          label="Department"
          source="position"
          // validate={required()}
          choices={[
            { id: 'sales', name: 'Sales' },
            { id: 'management', name: 'Management' },
            { id: 'quality', name: 'Quality' },
            { id: 'designEngineering', name: 'Design/Engineering' },
            { id: 'accounting', name: 'Accounting' },
          ]}
          optionText="name"
          optionValue="id"
          defaultValue=""
          fullWidth
          className={`${classes.areaTextSelect} ${classes.hideLabel}`}
        />
      </StyledTableRowCell>

      <StyledTableRowCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          validate={[required(), mobile()]}
          // validate={mobile()}
          source="mobile"
          label="Mobile:"
        />
      </StyledTableRowCell>

      <StyledTableRowCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          // validate={[email(), required()]}
          validate={email()}
          source="email"
          label="Email:"
        />
      </StyledTableRowCell>

      <StyledTableRowCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          source="wechat"
          label="WeChat:"
        />
      </StyledTableRowCell>

      <StyledTableCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          source="whatsapp"
          label="Whatsapp:"
        />
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex">
          <IconButton
            onClick={() => {
              // setSaving(true)

              const formState = form.getState()

              if (formState.invalid) {
                form.submit()
              } else if (props.newContact) {
                props.createContact(values, props.index)
                props.setNewRow(null)
              } else {
                props.updateContact(values, props.data?.id)
                props.setEditRow(null)
              }
              // setSaving(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              if (props.newContact) {
                props.setNewRow(null)
              } else {
                props.setEditRow(null)
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      </StyledTableCell>
    </TableRow>
  )
}

const EditContactForm = (props) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const { updateSupplier, setEditPrimary, readOnly, contacts } = props

  const optionRenderer = (choice) =>
    `${choice.first_name ? choice.first_name + ' ' : ''} ${
      choice.last_name ? choice.last_name + ' ' : ''
    } ${choice.email ? choice.email + ' ' : ''} ${choice.mobile ? choice.mobile : ''}`

  return (
    <Box display="flex">
      <SelectInput
        optionText={optionRenderer}
        optionValue="id"
        label="Primary Contact"
        // className={`${classes.areaTextSelect} ${classes.hideLabel}`}
        className={classes.addressTextSelect}
        source="primaryContact"
        choices={contacts}
        validate={required()}
        disabled={readOnly}
        fullWidth
      />
      <IconButton
        onClick={() => {
          const formState = form.getState()

          if (formState.invalid) {
            form.submit()
          } else {
            updateSupplier(values)
            setEditPrimary(false)
          }
        }}
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CheckCircleOutlineOutlined fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          setEditPrimary(false)
        }}
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CancelOutlined fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default Contact
