/* eslint-disable no-unused-vars */
import React from 'react'
import { Route } from 'react-router-dom'
import OfferSimulator from './pages/demo/simulator'
import CustomerPurchase from './pages/demo/customerPurchase'
import SCMQuoteCreate from './pages/scmQuote/SCMQuoteCreate'
import Configuration from './pages/configuration/ConfigurationPanel'
import PlatformConfigList from './pages/configuration/platformConfig/PlatformConfigList'
import PlatformConfigEdit from './pages/configuration/platformConfig/PlatformConfigEdit'
import ProductImport from './pages/products/components/ProductImport'
import ProductDebug from './pages/configuration/ProductDebug'
import Product from './pages/products/ProductsEdit'
import AdminTools from './pages/admin'

export default [
  <Route
    exact
    path="/productImport"
    key="/productImport"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <ProductImport resource="products" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/productImport/:id"
    key="/productImportSR"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <ProductImport resource="products" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/simulator"
    key="/simulator"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <OfferSimulator resource="offer" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/simulator/:type"
    key="/simulatorType"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <OfferSimulator resource="offer" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/simulator/:type/:id"
    key="/simulatorTypeId"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <OfferSimulator resource="offer" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/customerPurchase"
    key="/customerPurchase"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return (
        <CustomerPurchase
          resource="saleable_product_sale"
          basePath={routeProps.match.url}
          {...rest}
        />
      )
    }}
  />,
  <Route
    exact
    path="/scmQuote/create/:id"
    key="/createSCMQuote"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <SCMQuoteCreate resource="products" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/configuration"
    key="/configuration"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <Configuration resource="configuration" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/platformConfig"
    key="/platformConfig"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return (
        <PlatformConfigList resource="configSection" basePath={routeProps.match.url} {...rest} />
      )
    }}
  />,
  <Route
    exact
    path="/platformConfig/:id"
    key="/platformConfig"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return (
        <PlatformConfigEdit resource="configSection" basePath={routeProps.match.url} {...rest} />
      )
    }}
  />,
  <Route
    exact
    path="/productDebug"
    key="/productDebug"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <ProductDebug resource="productDebug" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/adminTools"
    key="/adminTools"
    render={(routeProps) => {
      const { staticContext, ...rest } = routeProps
      return <AdminTools resource="adminTools" basePath={routeProps.match.url} {...rest} />
    }}
  />,
  <Route
    exact
    path="/products/:id/:cqid"
    key="/productscq"
    render={(routeProps) => {
      const { id, cqid } = routeProps.match.params
      const { staticContext, ...rest } = routeProps
      return (
        <Product
          resource="products"
          basePath={routeProps.match.url}
          {...rest}
          id={id}
          cqid={cqid}
        />
      )
    }}
  />,
]
