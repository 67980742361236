import React from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  FunctionField,
  SelectInput,
  // AutocompleteInput,
  // useTranslate,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  DeleteButton,
  CreateButton,
  TopToolbar,
  // EditButton,
  useGetIdentity,
  useRedirect,
} from 'react-admin'
import { Box, Button } from '@material-ui/core'
import SupplierDefaultImg from '../../assets/supplier_default.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  requestButton: {
    backgroundColor: '#ddeeff',
    '&:hover': {
      backgroundColor: '#00B3C3',
    },
  },
})
const ListActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  )
}

export const SCMQuoteFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
    <ReferenceInput
      source="category"
      reference="category"
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <DateInput
      source="date_gte"
      label="Issue date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Issue date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
  </Filter>
)

export const OrgFilters = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
  </Filter>
)

const ClientQuoteList = (props) => {
  const redirect = useRedirect()
  const classes = useStyles()
  const { identity, loading } = useGetIdentity()
  const { basePath } = props

  if (loading) return null

  const isAdmin = identity?.roles?.some((role) => role === 'sca' || role === 'sca-manager')

  return (
    <Box>
      <List
        {...props}
        filters={isAdmin && basePath === '/scmQuotes' ? <SCMQuoteFilter /> : <OrgFilters />}
        actions={isAdmin && basePath === '/scmQuotes' ? <ListActions /> : null}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        // actions={null}
        // filter={{ is_catalogue: false }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid
          optimized
          // rowClick={(id, _, record) => {
          //   if (record.sourcing_request_id) {
          //     redirect('edit', 'sourcingRequests', record.sourcing_request_id)
          //   } else {
          //     redirect('edit', 'scmQuote', id)
          //   }
          // }}
          rowClick="edit"
        >
          <TextField source="id" label="ID" />
          <FunctionField
            label="Photo"
            render={(record) => (
              <div style={{ height: '50px', width: '50px' }}>
                <img
                  style={{ maxHeight: '50px' }}
                  src={record.image?.url || SupplierDefaultImg}
                  alt={<img src={SupplierDefaultImg} alt="" />}
                />
              </div>
            )}
          />
          <TextField source="product_quote.product.name" label="Product Name" sortable={false} />
          {/* <FunctionField
                label="Source"
                render={(record) =>
                  record.product_quote?.product?.product_categories?.map((cat) => (
                    <Chip key={`cats${cat.id}`} label={cat.category.name} />
                  ))
                }
              /> */}
          {/* <TextField source="product_quote.product.brand" label="Brand" sortable={false} /> */}
          {basePath === '/scmQuote' && (
            <TextField source="client.name" label="Client" sortable={false} />
          )}
          <FunctionField
            render={(record) => {
              const minPrice =
                record.prices?.length > 0
                  ? record.prices?.reduce((prev, curr) =>
                      prev.quantity < curr.quantity ? prev : curr,
                    )
                  : null
              const maxPrice =
                record.prices?.length > 1
                  ? record.prices?.reduce((prev, curr) =>
                      prev.quantity > curr.quantity ? prev : curr,
                    )
                  : null

              return (
                <Box display="flex" flexDirection="column">
                  {minPrice ? <span>{minPrice.quantity}</span> : null}
                  {maxPrice ? <span> {maxPrice.quantity}</span> : null}
                </Box>
              )
            }}
            label="Quantity"
          />
          <FunctionField
            render={(record) => {
              const minPrice =
                record.prices?.length > 0
                  ? record.prices?.reduce((prev, curr) =>
                      prev.quantity < curr.quantity ? prev : curr,
                    )
                  : null
              const maxPrice =
                record.prices?.length > 1
                  ? record.prices?.reduce((prev, curr) =>
                      prev.quantity > curr.quantity ? prev : curr,
                    )
                  : null
              return (
                <Box display="flex" flexDirection="column">
                  {minPrice ? (
                    <span>
                      {`$${(minPrice.fob_price / 100).toFixed(2)}/$${(
                        minPrice.estimated_freight_sea / 100
                      ).toFixed(2)}/$${(minPrice.estimated_freight_air / 100).toFixed(2)}`}
                    </span>
                  ) : null}

                  {maxPrice ? (
                    <span>
                      {`$${(maxPrice.fob_price / 100).toFixed(2)}/$${(
                        maxPrice.estimated_freight_sea / 100
                      ).toFixed(2)}/$${(maxPrice.estimated_freight_air / 100).toFixed(2)}`}
                    </span>
                  ) : null}
                </Box>
              )
            }}
            label="Quote FOB/Sea/Air"
          />
          {/* <FunctionField
                render={(record) => {
                  const length = record.payment_terms?.length
                  return length > 0
                    ? record.payment_terms.map((item, index) =>
                        index === length - 1 ? item.value : `${item.value}-`,
                      )
                    : 'N/A'
                }}
                label="Payment Milestones"
              /> */}
          <DateField label="Issue date" source="launch_date" type="date" />
          <FunctionField
            label="Request Id"
            render={(record) =>
              record.sourcing_request_id ? (
                <Button
                  className={classes.requestButton}
                  onClick={() => redirect('edit', 'sourcingRequest', record.sourcing_request_id)}
                >
                  See request
                </Button>
              ) : null
            }
          />
          <DeleteButton undoable={false} />
        </Datagrid>
      </List>
    </Box>
  )
}

export default ClientQuoteList
