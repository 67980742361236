import React from "react"
import {
  Box,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import useStyles from "../styles"
import Tag from "../../../../../components/Tag"

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#232323",
    color: "#FFFFFF",
    fontSize: 12,
  },
  body: {
    fontSize: 12,
    padding: "6px",
  },
}))(TableCell)

const Audits = props => {
  const classes = useStyles()

  // console.log("AUDITPROPS", props)

  const getStatus = value => {
    switch (value) {
      case "Fail":
      case "fail":
        return <Tag style={{ cursor: "default" }} title="Fail" red />
      case "Pass":
      case "pass":
        return <Tag style={{ cursor: "default" }} title="Pass" green />
      default:
        return <Tag style={{ cursor: "default" }} title="N/A" gray />
    }
  }

  return (
    <Box mt="30px">
      <span className={classes.sectionHeader}>Audit Results</span>
      <Box display="flex">
        <Box className={classes.auditResultBoxLeftInner}>
          <Box display="flex">
            <span className={classes.fieldLabel}>Result:</span>
            <span className={classes.fieldValue}>
              {props.data ? getStatus(props.data[0]?.conclusion) : "N/A"}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Auditor:</span>
            <span className={classes.fieldValue}>
              {props.data ? props.data[0]?.quality_agency?.name : "N/A"}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Type:</span>
            <span className={classes.fieldValue}>{props.data ? props.data[0]?.type : "N/A"}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Audit Date:</span>
            <span className={classes.fieldValue}>
              {props.data ? props.data[0]?.audit_date : "N/A"}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Recommended Next Audit:</span>
            <span className={classes.fieldValue}>{props.data ? props.data[0]?.next : "N/A"}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Most Recent Audit Report:</span>
            {props.data && props.data[0]?.file?.url ? (
              <Box display="flex">
                <AttachFileIcon fontSize="small" />
                {/* <AttachFileIcon sx={{ fontSize: 8 }} /> */}
                <a
                  style={{ display: "flex", alignItems: "flex-start", fontSize: "12px" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.data[0].file.url}
                >
                  {props.data[0]?.file?.filename.length > 10
                    ? `${props.data[0]?.file?.filename?.substring(0, 10)}...`
                    : props.data[0]?.file?.filename}
                </a>
              </Box>
            ) : (
              <span className={classes.fieldValue}>N/A</span>
            )}
          </Box>
        </Box>
        <Box flex={2} className={classes.auditResultBoxRight}>
          <TableContainer component={Paper} style={{ marginTop: 40 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Overall Evaluation from Auditor</StyledTableCell>
                  <StyledTableCell align="left">Grade</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left">General Organization</StyledTableCell>
                  <StyledTableCell align="left">N/A</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">
                    Cleanliness and maintenance of machines
                  </StyledTableCell>
                  <StyledTableCell align="left">N/A</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">Quality System</StyledTableCell>
                  <StyledTableCell align="left">N/A</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">
                    Social, safety and environmental compliance
                  </StyledTableCell>
                  <StyledTableCell align="left">N/A</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">Cooperation of factory management</StyledTableCell>
                  <StyledTableCell align="left">N/A</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">TOTAL</StyledTableCell>
                  <StyledTableCell align="left">N/A</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box flex={1} />
      </Box>
    </Box>
  )
}

export default Audits
