import CatalogueProductIcon from '@material-ui/icons/Collections'
import CatalogueProductList from './CatalogueProductList'
import CatalogueProductEdit from './CatalogueProductEdit'
import CatalogueProductCreate from './CatalogueProductCreate'
// import CatalogueProductShow from './CatalogueProductShow'

export default {
  list: CatalogueProductList,
  create: CatalogueProductCreate,
  edit: CatalogueProductEdit,
  icon: CatalogueProductIcon,
  // show: CatalogueProductShow,
}
