import React, { useState } from 'react'
import { Box, Tooltip, CircularProgress } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { useNotify, useDataProvider, FormWithRedirect } from 'react-admin'
import { useFormState } from 'react-final-form'
import { uniqueId } from 'lodash'
import { useDropzone } from 'react-dropzone'
import uploadFile from '../../../dataProvider/aws_upload'
// import useStyles from '../styles'

const TextFieldInput = (props) => {
  const { value, ...rest } = props

  return (
    <div>
      <FormWithRedirect
        key="formName"
        //   {...props}
        initialValues={{ value }}
        render={() => <EditFieldForm {...rest} />}
      />
    </div>
  )
}

const EditFieldForm = (props) => {
  // const form = useForm()
  const { values } = useFormState()
  // const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [uploading, setUploading] = useState(false)

  const { resource, field, id } = props

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*'],
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        setUploading(true)
        const files = []

        await Promise.all(
          acceptedFiles.map(async (document) => {
            const resp = await uploadFile(document)
            // console.log(resp)

            files.push({ id: uniqueId(), url: resp, filename: document.name })
          }),
        )

        // console.log(resource, field)

        // console.log([...(values.value || []), ...files])

        setUploading(false)

        try {
          await dataProvider.update(resource, {
            data: { id, [field]: [...(values.value || []), ...files] },
          })

          setUploading(false)
          // setProgress(0)

          notify('Upload Complete', { type: 'info' })
        } catch (error) {
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  return (
    <div>
      <div
        {...getRootProps({
          style: {
            width: '90%',
            maxHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: '0.9',
            cursor: 'pointer',
            border: 'dashed gray 1px',
          },
        })}
      >
        <input {...getInputProps()} />

        <span>{`Drag & drop, or click here`}</span>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          // overflowY: 'auto',
          // width: '200px',
          margin: '5px 0px 5px',
          // maxHeight: '50px',
        }}
        alignItems="baseline"
      >
        {/* {previews} */}
        {values.value?.map((item) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            key={`file${item.name || item.filename}${item.id}`}
          >
            <a style={{ lineHeight: '12px' }} href={item.url}>
              {item.filename?.length > 20 ? (
                <Tooltip title={item.filename}>
                  <span>{`${item.filename?.substring(0, 20)}...`}</span>
                </Tooltip>
              ) : (
                item.filename
              )}
            </a>
            <IconButton
              aria-label="delete"
              //  className={classes.deleteBtn}
              onClick={async () => {
                setUploading(true)
                const newArray = [...values.value]
                const index = newArray?.findIndex((obj) => obj.id === item.id)
                newArray.splice(index, 1)

                await dataProvider.update(resource, {
                  data: { id, [field]: newArray.length === 0 ? null : newArray },
                })
                setUploading(false)
              }}
            >
              <ClearIcon style={{ fontSize: '14px' }} />
            </IconButton>
          </Box>
        ))}

        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
    </div>
  )
}

export default TextFieldInput
