import ConsumerOrdersIcon from '@material-ui/icons/Collections'
import ConsumerOrdersList from './ConsumerOrdersList'
import ConsumerOrdersEdit from './ConsumerOrdersEdit'
// import ConsumerOrdersCreate from './ConsumerOrdersCreate'
// import ConsumerOrdersShow from './ConsumerOrdersShow'

export default {
  list: ConsumerOrdersList,
  // create: ConsumerOrdersCreate,
  edit: ConsumerOrdersEdit,
  icon: ConsumerOrdersIcon,
  // show: ConsumerOrdersShow,
}
