import React, { useState } from 'react'
import { useNotify, useDataProvider, required, TextInput } from 'react-admin'
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Paper,
} from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { useFormState, useForm } from 'react-final-form'
import useStyles from './styles'
import NumberFormat from './CustomNumberFormat'
import { ColorField } from './ColourPicker'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const ClientQuoteOptionSelection = () => {
  const classes = useStyles()
  const notify = useNotify()
  const form = useForm()
  const dataProvider = useDataProvider()

  const { values } = useFormState()
  const [decorationsEditing, setDecorationsEditing] = useState(false)
  const [decorationsSaving, setDecorationsSaving] = useState(false)
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  if (!values.clientOption || values.clientOptions?.length < 1) {
    return null
  }

  const [currentTab, setCurrentTab] = useState(
    values.clientOptions?.length > 0 ? `${values.clientOptions[0]?.id}` : '',
  )

  // console.log(currentTab)

  // console.log('OPTIONSELECTIONVALS', values)

  if (
    values.clientOptions?.length > 0 &&
    !values.clientOptions.some((opt) => `${opt.id}` === currentTab)
  ) {
    setCurrentTab(`${values.clientOptions[0]?.id}`)
  }

  return (
    <Box
      style={{
        // backgroundColor: 'lightgray',
        marginBottom: '20px',
        // padding: '10px',
        borderRadius: '6px',
      }}
    >
      <span className={classes.sectionHeader}>Available Options</span>
      <Box display="flex" alignItems="center">
        <span className={classes.sectionSubHeader}>Decorations Options</span>
        {decorationsEditing ? (
          <div>
            {decorationsSaving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
            <IconButton
              onClick={async () => {
                // console.log(values)
                setDecorationsSaving(true)

                // create = available && id !== number
                // upsert = available && id === number
                // delete = !available && id === number
                try {
                  await Promise.all(
                    values.clientDecorations?.map(async (deco, index) => {
                      console.log(deco)
                      if (deco.available) {
                        console.log('saving', values.clientDecorations)
                        const resp = await dataProvider.create('scm_quote_decorations', {
                          data: {
                            ...(typeof deco.id === 'number' ? { id: deco.id } : null),
                            scm_quote_id: values.id,
                            product_quote_decoration_id:
                              deco.refId || deco.product_quote_decoration_id,
                            label: deco.label,
                            max_dimensions: deco.max_dimensions,
                            price: deco.unitPrice * 100,
                            imprint_style: deco.imprint_style,
                            imprint_styles: deco.imprintStyles
                              .filter((el) => el.available)
                              .map((imp) => imp.name),
                          },
                          on_conflict: {
                            constraint: 'scm_quote_decorations_pkey',
                            update_columns: [
                              'scm_quote_id',
                              'product_quote_decoration_id',
                              'label',
                              'max_dimensions',
                              'price',
                              'imprint_style',
                              'imprint_styles',
                            ],
                          },
                        })
                        form.change(`clientDecorations[${index}].id`, resp.data.id)
                      }
                      if (!deco.available && typeof deco.id === 'number') {
                        await dataProvider.delete('scm_quote_decorations', {
                          data: { id: deco.id },
                        })
                        form.change(`clientDecorations[${index}].id`, `D${deco.id}`)
                      }
                    }),
                  )
                  setDecorationsEditing(false)
                  setDecorationsSaving(false)
                  notify('Saved Successfully.', { type: 'info' })
                } catch (error) {
                  setDecorationsEditing(false)
                  setDecorationsSaving(false)
                  notify(error.message.split('.')[0], { type: 'warning' })
                }
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>

            <IconButton
              onClick={() => {
                setDecorationsEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setDecorationsEditing(true)
            }}
            style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box display="flex" flexDirection="column" style={{ maxWidth: '80vw', flexWrap: 'wrap' }}>
        <DecorationsTable
          decorationsEditing={decorationsEditing}
          decorationSaving={decorationsSaving}
          setDecorationsSaving={setDecorationsSaving}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <span className={classes.sectionSubHeader}>Other Options</span>
        {editing ? (
          <div>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
            <IconButton
              onClick={async () => {
                // console.log(values)
                setSaving(true)

                // create = available && id !== number
                // upsert = available && id === number
                // delete = !available && id === number
                const createOptions = []

                try {
                  await Promise.all(
                    values.clientOptions
                      ?.filter((el) =>
                        el.values.some((val) => val.components.some((comp) => comp.available)),
                      )
                      .map(async (option) => {
                        // console.log(option)

                        const createOption = {
                          ...(typeof option.id === 'number' ? { id: option.id } : null),
                          name: option.name,
                          max_unique_selections: option.max_unique_selections,
                          additional_cost_per_unique_choice: option.additionalCostPerChoice * 100,
                          product_quote_option_id: option.product_quote_option_id,
                          scm_quote_id: values.id,
                          values: {
                            data: [],
                            on_conflict: {
                              constraint: '^scm_quote_option_values_scm_quote_option_id_value_key^',
                              update_columns: ['^setup^', '^price^', '^moq^', '^value^'],
                            },
                          },
                        }

                        option.values?.map(async (value) => {
                          // TODO: for each option value, loop components and check if the option is available.

                          const availableComps = value.components.filter((item) => item.available)

                          if (availableComps?.length > 0) {
                            const createValue = {
                              ...(typeof value.id === 'number' ? { id: value.id } : null),
                              price: value.unitCost * 100,
                              setup: value.setupCost * 100,
                              moq: value.moq,
                              is_custom: value.is_custom,
                              value: value.value,
                              product_quote_option_value_id: value.product_quote_option_value_id,
                              component_options: {
                                data: [],
                                on_conflict: {
                                  constraint:
                                    '^scm_quote_component_options_scm_quote_component_id_scm_quot_key^',
                                  update_columns: [
                                    '^scm_quote_component_id^',
                                    '^scm_quote_option_value_id^',
                                  ],
                                },
                              },
                            }

                            availableComps.map((el) => {
                              createValue.component_options.data.push({
                                component: {
                                  data: {
                                    ...(typeof el.id === 'number' ? { id: el.id } : null),
                                    name: el.name,
                                    scm_quote_id: values.id,
                                    product_quote_component_id: el.product_quote_component_id,
                                  },
                                  on_conflict: {
                                    constraint:
                                      '^scm_quote_components_scm_quote_id_product_quote_component_id_ke^',
                                    update_columns: ['^name^', '^scm_quote_id^'],
                                  },
                                },
                              })
                            })

                            createOption.values.data.push(createValue)
                          }
                        })
                        createOptions.push(createOption)

                        // console.log(createOptions)

                        if (createOptions?.length > 0) {
                          await dataProvider.createMany('scm_quote_options', {
                            objects: createOptions,
                            on_conflict: {
                              constraint: '^scm_quote_options_scm_quote_id_name_key^',
                              update_columns: [
                                '^product_quote_option_id^',
                                '^name^',
                                '^scm_quote_id^',
                                '^max_unique_selections^',
                                '^additional_cost_per_unique_choice^',
                              ],
                            },
                          })
                          // console.log(data)
                        }
                      }),
                  )

                  const delVals = []

                  await Promise.all(
                    values.clientOptions
                      ?.filter((el) =>
                        el.values.some((val) => val.components.some((comp) => !comp.available)),
                      )
                      .map(async (option) => {
                        // console.log('delopt', option)
                        option.values?.map((val) => {
                          // console.log('deloptVal', val)
                          val.components.map((item) => {
                            // console.log('deloptComp', item)
                            if (!item.available && typeof item.componentRefId === 'number') {
                              delVals.push(item.componentRefId)
                            }
                          })
                        })
                      }),
                  )

                  // console.log(delVals)

                  delVals?.length > 0
                    ? await dataProvider.deleteMany('scm_quote_component_options', {
                        ids: delVals,
                      })
                    : null

                  setEditing(false)
                  setSaving(false)
                  notify('Saved Successfully.', { type: 'info' })
                } catch (error) {
                  setEditing(false)
                  setSaving(false)
                  notify(error.message.split('.')[0], { type: 'warning' })
                }
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>

            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Box display="flex" flexDirection="column" style={{ maxWidth: '80vw', flexWrap: 'wrap' }}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          aria-label="orders"
          style={{ marginTop: '20px' }}
        >
          {values.clientOptions?.map((opt) => {
            return (
              <Tab
                key={opt.id}
                value={`${opt.id}`}
                label={opt.name}
                wrapped
                style={{ width: '100px' }}
                className={currentTab === `${opt.id}` ? classes.tabButton : null}
              />
            )
          })}
        </Tabs>
        {values.clientOptions?.map((item, index) => (
          <TabPanel key={item.id} value={currentTab} index={`${item.id}`}>
            <ClientOptionsTable
              index={index}
              editing={editing}
              saving={saving}
              setSaving={setSaving}
            />
          </TabPanel>
        ))}
      </Box>
    </Box>
  )
}

const DecorationsTable = ({ decorationsEditing }) => {
  const { values } = useFormState()

  return (
    <Box mt="20px" display="flex">
      <Box flex={1}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="center"
                >
                  Id
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '24%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Location (Display Text)
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '24%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Max Dimensions (W x H)
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '24%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Imprint Styles
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="center"
                >
                  Available
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.clientDecorations?.map((item, index) => (
                <DecorationsRow
                  disabled={!decorationsEditing}
                  key={`deco${item.id}`}
                  index={index}
                  id={item.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box flex={1} />
    </Box>
  )
}

const DecorationsRow = ({ index, disabled }) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()

  return (
    <TableRow key={`decoRow${index}`}>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px 5px', margin: '0px', height: '100%' }}
          source={`clientDecorations[${index}].id`}
          label={false}
          disabled
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px 5px', margin: '0px', height: '100%' }}
          source={`clientDecorations[${index}].label`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px 5px', margin: '0px', height: '100%' }}
          source={`clientDecorations[${index}].max_dimensions`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      {/* <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px 5px', margin: '0px', height: '100%' }}
          source={`clientDecorations[${index}].imprint_style`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell> */}
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        {values.clientDecorations[index].imprintStyles?.map((el, ind) => (
          <Box key={`el${el.name}`} display="flex" justifyContent="center" alignItems="center">
            <span>{el.name}</span>
            <Checkbox
              disabled={disabled}
              checked={el.available || false}
              onChange={() => {
                form.change(
                  `clientDecorations[${index}].imprintStyles[${ind}].available`,
                  !el.available,
                )
              }}
              inputProps={{ 'aria-label': 'component deco imprint available checkbox' }}
            />
          </Box>
        ))}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`clientDecorations[${index}].unitPrice`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell
        align="center"
        style={{
          padding: '0px',
        }}
      >
        <Checkbox
          disabled={disabled}
          checked={values.clientDecorations[index]?.available || false}
          onChange={() => {
            form.change(
              `clientDecorations[${index}].available`,
              !values.clientDecorations[index].available,
            )
          }}
          inputProps={{ 'aria-label': 'component deco available checkbox' }}
        />
      </BodyTableCell>
    </TableRow>
  )
}

const ClientOptionsTable = ({ index, editing }) => {
  const classes = useStyles()
  const { values } = useFormState()

  // console.log(values.clientOptions[index], editing)

  return (
    <Box>
      <span style={{ fontSize: '10px' }} className="MuiFormLabel-root">
        Id: {values.clientOptions[index].id}
      </span>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <NumberFormat
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            label="Maxiumum unique selections"
            disabled={!editing}
            source={`clientOptions[${index}].max_unique_selections`}
            // validate={required()}
          />
        </Box>
        <Box ml="20px" flex={1}>
          <NumberFormat
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            label="Additional price per unique choice"
            disabled={!editing}
            source={`clientOptions[${index}].additionalCostPerChoice`}
            prefix="$ "
            // validate={required()}
          />
        </Box>
        <Box flex={3} />
      </Box>

      <Box mt="20px" display="flex">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="center"
                >
                  Id
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '12%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Option
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  MOQ
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Setup Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Cost
                </StyledTableCell>
                {values.clientOptions[index].values?.length > 0 &&
                  values.clientOptions[index].values[0].components?.map((cmp) => (
                    <StyledTableCell
                      key={cmp.id}
                      style={{
                        width: `${
                          60 / (values.clientOptions[index].values[0].components?.length + 1)
                        }%`,
                        fontSize: '10px',
                        padding: '10px',
                      }}
                      align="center"
                    >
                      {cmp.name}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.clientOptions[index]?.values?.map((item, ind) => (
                <OptionsRow
                  disabled={!editing}
                  key={`cfg${item.id}`}
                  optionIndex={index}
                  rowIndex={ind}
                  id={item.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

const OptionsRow = ({ rowIndex, optionIndex, disabled }) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()

  return (
    <TableRow
      key={`cfgRow${rowIndex}`}
      style={
        values.clientOptions[optionIndex].values[rowIndex].is_custom
          ? { backgroundColor: '#e3b5ef' }
          : null
      }
    >
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px 5px', margin: '0px', height: '100%' }}
          source={`clientOptions[${optionIndex}].values[${rowIndex}].id`}
          label={false}
          disabled
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px 10px' }}>
        {values.clientOptions[optionIndex].name === 'Colour' ||
        values.clientOptions[optionIndex].name === 'Colours' ||
        values.clientOptions[optionIndex].name === 'Color' ||
        values.clientOptions[optionIndex].name === 'Colors' ? (
          <ColorField record={values.clientOptions[optionIndex].values[rowIndex]} source="value" />
        ) : (
          values.clientOptions[optionIndex].values[rowIndex].value
        )}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`clientOptions[${optionIndex}].values.[${rowIndex}].moq`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`clientOptions[${optionIndex}].values.[${rowIndex}].setupCost`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`clientOptions[${optionIndex}].values.[${rowIndex}].unitCost`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      {values.clientOptions[optionIndex]?.values[rowIndex]?.components?.map((cmp, cmpInd) => (
        <BodyTableCell key={cmp.id} align="center" style={{ padding: '0px' }}>
          <Checkbox
            disabled={disabled}
            checked={
              values.clientOptions[optionIndex]?.values[rowIndex]?.components[cmpInd]?.available ||
              false
            }
            onChange={() => {
              form.change(
                `clientOptions[${optionIndex}].values[${rowIndex}].components[${cmpInd}].available`,
                !values.clientOptions[optionIndex].values[rowIndex].components[cmpInd]?.available,
              )
            }}
            inputProps={{ 'aria-label': 'component allow custom colour checkbox' }}
          />
        </BodyTableCell>
      ))}
    </TableRow>
  )
}

export default ClientQuoteOptionSelection
