import React, { useState } from 'react'
import {
  useRedirect,
  useDataProvider,
  Create,
  FormWithRedirect,
  // useNotify,
  SelectInput,
  required,
} from 'react-admin'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { uniqueId } from 'lodash'
import { generateProductVariants } from '../../../utils/utils'

const useStyles = makeStyles(() => ({
  table: {
    overflowX: 'unset',
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiInput-underline::before': {
      borderBottom: 'none',
    },
  },
  optionTitle: {
    fontSize: '14px',
    marginTop: '12px',
  },
  optionTitleNoMargin: {
    fontSize: '14px',
  },
  optionValue: {
    fontSize: '10px',
  },
  optionsToggleCheckbox: {
    padding: '1px',
  },
  createButton: {
    color: 'black',
    border: 'solid 1px rgba(180, 125, 80, 0.5)',
    backgroundColor: 'rgba(255, 149, 102, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(255, 149, 102, 0.3)',
    },
  },
}))

const CreateDialog = (props) => {
  //   const classes = useStyles()
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const { createOpen, setCreateOpen, prodData } = props

  const handleSubmit = async (submission) => {
    console.log(prodData)
    console.log(submission)
    const params = {
      ...(prodData.name && { name: prodData.name }),
      ...(prodData.description && { description: prodData.description }),
      ...(prodData.long_description && { long_description: prodData.long_description }),
      ...(prodData.features && { selling_features: prodData.features }),
      ...(prodData.brand && { brand: prodData.brand }),
      ...(prodData.sku && { sku: prodData.sku }),
      ...(prodData.hts_code && { hts_code: prodData.hts_code }),
      ...(prodData.productDimensionsId && { product_dimensions_id: prodData.productDimensionsId }),
      ...(prodData.packagingDimensionsId && {
        packaging_dimensions_id: prodData.packagingDimensionsId,
      }),
      ...(prodData.cartonDimensionsId && {
        bulk_carton_dimensions_id: prodData.cartonDimensionsId,
      }),
      ...(prodData.dutyRate && { duty_rate: prodData.dutyRate }),
      ...(prodData.primary_photo_id && { primary_photo_id: prodData.primary_photo_id }),
      base_product_id: prodData.id,
      ...(prodData.quotes?.length > 0 ? { product_quote_id: prodData.quotes[0].id } : null),
      ...(prodData?.specifications?.length > 0
        ? { specifications: { data: prodData.specifications } }
        : null),
      ...(prodData?.tags?.length > 0 ? { tags: { data: prodData.tags } } : null),
      ...(prodData?.categories?.length > 0 ? { categories: { data: prodData.categories } } : null),
      ...(prodData?.files?.length > 0 ? { files: { data: prodData.files } } : null),
      ...(prodData?.activities?.length > 0 ? { activities: { data: prodData.activities } } : null),
      platforms: { data: { platform: submission.platform } },
    }

    console.log(params)

    const {
      data: { id },
    } = await dataProvider.create('saleable_product', { data: params })

    // console.log(id)

    redirect(`/saleableProducts/${id}`)
  }

  const options = prodData.quotes?.length > 0 ? [...prodData.quotes[0].options] : null
  // console.log(prodData)

  const variants = createOpen
    ? options?.length > 0
      ? generateProductVariants({ options, existingVariants: prodData.variants })
      : [{ id: `NV${uniqueId()}`, available: true, stock: 0, values: [] }]
    : []

  // console.log(variants)

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={createOpen || false}
      onClose={() => setCreateOpen(false)}
      aria-label="CreateSaleableProduct"
    >
      <DialogTitle>Create B2C Product</DialogTitle>
      <DialogContent>
        <Create resource="variant" basePath="/saleableProduct">
          <FormWithRedirect
            id={uniqueId()}
            {...props}
            initialValues={{
              newVariants: variants,
              packagingType: '',
              productSize: '',
            }}
            render={(formProps) => (
              <div>
                {createOpen ? (
                  <CreateSaleableProductDropshippingForm
                    {...formProps}
                    options={options}
                    packagingOptions={prodData.packagingOptions}
                    handleSubmit={handleSubmit}
                  />
                ) : null}
              </div>
            )}
          />
        </Create>
      </DialogContent>
    </Dialog>
  )
}

const CreateSaleableProductDropshippingForm = ({ handleSubmit }) => {
  const classes = useStyles()
  const { values } = useFormState()
  const [saving, setSaving] = useState(false)

  return (
    <Box display="flex" flexDirection="column" p="30px">
      <SelectInput
        label="Platform"
        source="platform"
        choices={[
          { id: 0, name: 'Clique Stock', value: 'CLIQUESTOCK' },
          { id: 1, name: 'Solid Blanks', value: 'SOLIDBLANKS' },
        ]}
        optionText="name"
        optionValue="value"
        defaultValue="CLIQUESTOCK"
        fullWidth
        variant="standard"
        validate={required()}
      />
      <Box display="flex" justifyContent="center" mt="20px">
        <Button
          className={classes.createButton}
          onClick={async () => {
            setSaving(true)
            await handleSubmit(values)
            setSaving(false)
          }}
          disabled={!values.platform || saving}
        >
          {saving ? (
            <CircularProgress style={{ width: '20px', height: '20px' }} />
          ) : (
            <span style={{ fontSize: '12px' }}>CREATE</span>
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default CreateDialog
