import React from 'react'
import { saveAs } from 'file-saver'
// import { utils, write } from 'xlsx'
import { Button } from '@material-ui/core'
import XLSX from 'sheetjs-style'

const ExcelExport = ({ data, fileName, disabled }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data)

    const cells = [
      { name: 'A1', wch: 20 },
      { name: 'B1', wch: 20 },
      { name: 'C1', wch: 24 },
      { name: 'D1', wch: 20 },
      { name: 'E1', wch: 20 },
      { name: 'F1', wch: 10 },
      { name: 'G1', wch: 12 },
      { name: 'H1', wch: 22 },
      { name: 'I1', wch: 10 },
      { name: 'J1', wch: 14 },
      { name: 'K1', wch: 15 },
      { name: 'L1', wch: 10 },
      { name: 'M1', wch: 14 },
      { name: 'N1', wch: 12 },
      { name: 'O1', wch: 12 },
      { name: 'P1', wch: 12 },
      { name: 'Q1', wch: 14 },
    ]

    const wscols = []
    for (let i = 0; i < cells.length; i++) {
      // columns length added
      wscols.push({ wch: cells[i].wch })
    }

    worksheet['!cols'] = wscols

    cells.map((el) => {
      worksheet[el.name].s = {
        border: {
          bottom: { style: 'thin', color: '#000000' },
        },
        font: {
          bold: true,
        },
      }
    })

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, `${fileName}.xlsx`)
  }

  return (
    <Button
      style={{ marginLeft: '20px', border: 'solid 1px lightgray', height: '36px' }}
      disabled={disabled}
      onClick={exportToExcel}
    >
      Export to Excel
    </Button>
  )
}

export default ExcelExport
