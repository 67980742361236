import React from 'react'
import { Box } from '@material-ui/core'
import { FormWithRedirect, Edit } from 'react-admin'
import FormData from './components/ProjectEditForm'
import { uniqueId } from 'lodash'

const ProjectEdit = (props) => {
  return (
    <Edit {...props} actions={null} component="div" id={props.id} mutationMode="pessimistic">
      <ProjectEditForm id={props.id} {...props} />
    </Edit>
  )
}
const ProjectEditForm = (props) => {
  const chosenServices =
    props.record.services?.map((service) => ({
      id: service.id,
      own: 'service',
      name: service.service,
    })) || []

  const productPrices =
    props.record.products?.map((product) => ({
      ...product,
      prices: product.prices?.map((price) => ({
        ...price,
        fobPrice: price.fob_price / 100,
        ddpAir: price.ddp_air / 100,
        ddpSea: price.ddp_sea / 100,
      })),
    })) || []

  if (productPrices?.length < 1) {
    productPrices.push({ id: uniqueId(), prices: [{ id: uniqueId() }] })
  } else {
    productPrices.map((el, index) => {
      if (el.prices?.length < 1) {
        productPrices[index].prices.push({ id: uniqueId() })
      }
    })
  }

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        oldReferences: props.record.references,
        chosenServices,
        products: productPrices,
      }}
      render={(formProps) => (
        <Box>
          <FormData formProps={formProps} />
        </Box>
      )}
    />
  )
}

export default ProjectEdit
