import React, { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useNotify, useDataProvider } from 'react-admin'
import { get } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import uploadFile from '../../../dataProvider/aws_upload'

const useStyles = makeStyles({
  primaryPhoto: {
    // border: 'dashed gray 2px',
    width: '85px',
    height: '85px',
    position: 'absolute',
    zIndex: '-1',
  },
  primaryPhotoZoomContainer: {
    border: 'solid gray 1vw',
    borderRadius: '1vw',
    maxWidth: '65vw',
    // maxHeight: '50vh',
    position: 'absolute',
    backgroundColor: 'white',
    top: '0px',
    left: '0px',
    // minWidth: '50vw',
    // minHeight: '50vh',
    zIndex: '1000',
  },
  primaryPhotoZoom: {
    maxWidth: '64vw',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '400px',
    // margin: '0px 0px 5px',
  },
  previewImageBox: {
    width: '83px',
    height: '83px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    border: 'solid gray 2px',
    '&:hover': {
      border: 'gray dashed 2px',
    },
  },
  previewImage: {
    zIndex: '100',
    maxWidth: '100%',
    maxHeight: '100%',
    // cursor: 'pointer',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  deleteBtn: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  zoomBtn: {
    position: 'absolute',
    top: '1px',
    left: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  imageDropZone: {
    width: '85px',
    height: '85px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  imageDropZoneEditing: {
    width: '85px',
    height: '85px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    cursor: 'pointer',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },

  loadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '35px',
    top: '35px',
  },
})

const SinglePhotoFieldInput = ({
  source,
  resource,
  resourceId,
  referenceField,
  referenceValue,
  onConflict,
  fileReference,
  field,
  parentField,
  parentId,
  disabled = false,
}) => {
  const classes = useStyles()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { values } = useFormState()
  const [uploading, setUploading] = useState(false)
  // const [progress, setProgress] = useState(0)
  const [zoomOpen, setZoomOpen] = useState(null)
  const preview = get(values, field)

  // console.log(preview)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    multiple: false,
    disabled,
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles)
      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploading(true)

        const acceptedFile = acceptedFiles[0]

        // console.log(acceptedFile)

        const file = await uploadFile(acceptedFile)

        // console.log(file)

        const params = {
          [referenceField]: referenceValue,
          [parentField]: parentId,
          [fileReference]: {
            data: {
              url: file,
              filename: acceptedFile.name || acceptedFile.path,
            },
          },
        }

        // console.log(params)

        try {
          if (parentField) {
            const { data } = await dataProvider.create(resource, {
              data: params,
              on_conflict: onConflict,
            })

            form.change(source, data.id)
          } else {
            const { data: fileData } = await dataProvider.create('file', {
              data: {
                url: file,
                filename: acceptedFile.name || acceptedFile.path,
              },
            })

            await dataProvider.update(resource, {
              data: { id: resourceId, [fileReference]: fileData.id },
            })
            // form.change(source, fileData.id)
          }
          form.change(field, file)

          setUploading(false)
          // setProgress(0)

          notify('Upload Complete', { type: 'info' })
        } catch (error) {
          console.log(error)
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  return (
    <Box display="flex" position="relative" alignItems="center" justifyContent="center">
      {zoomOpen ? (
        <Box className={classes.primaryPhotoZoomContainer}>
          <img
            src={zoomOpen}
            className={classes.primaryPhotoZoom}
            onClick={async () => {
              setZoomOpen(null)
            }}
          />
        </Box>
      ) : null}
      <div
        {...getRootProps({
          className: disabled ? classes.imageDropZone : classes.imageDropZoneEditing,
        })}
      >
        <input {...getInputProps()} />
        {!uploading && !disabled && <span>{`Drag / Upload`}</span>}
      </div>
      {preview && (
        <div className={classes.previewImageBox}>
          <img src={preview} className={classes.previewImage} alt="" />
          {!disabled && (
            <IconButton
              aria-label="delete"
              className={classes.deleteBtn}
              onClick={async () => {
                try {
                  setUploading(true)
                  if (parentField) {
                    await dataProvider.update(resource, {
                      data: { id: referenceValue, [source]: null },
                    })
                  } else {
                    await dataProvider.update(resource, {
                      data: { id: resourceId, [fileReference]: null },
                    })
                  }
                  form.change(source, null)
                  form.change(field, null)
                  notify('Image Deleted', { type: 'info' })
                  setUploading(false)
                } catch (error) {
                  notify(error.message, { type: 'warning' })
                }
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
          <IconButton
            aria-label="zoomin"
            className={classes.zoomBtn}
            onClick={async () => {
              setZoomOpen(preview)
            }}
          >
            <ZoomInIcon />
          </IconButton>
        </div>
      )}
      {uploading && <CircularProgress className={classes.loadingIcon} />}
    </Box>
  )
}

export default SinglePhotoFieldInput
