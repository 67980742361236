import React, { useEffect, useState } from 'react'
import {
  AutocompleteInput,
  useCreate,
  useNotify,
  useDataProvider,
  FormWithRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form'
import { useFormState } from 'react-final-form'
import { CircularProgress } from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'

import SupplierSelectWithCreateForm from './SupplierSelectWithCreateForm'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
})

const SupplierSelectWithCreate = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [create, { loading }] = useCreate('suppliers')
  const { values } = useFormState()

  const form = useForm()
  // const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [addressTypes, setAddressTypes] = useState([])
  // const [countries, setCountries] = useState([])
  // const [states, setStates] = useState([])
  // const [cities, setCities] = useState([])

  // eslint-disable-next-line no-unused-vars
  const { label, source, options, setOptions, optionText, optionValue, ...rest } = props

  const handleChange = (option) => {
    const newOptions = [...suppliers]
    newOptions.push(option)
    const abortController = new AbortController()
    !abortController.signal.aborted && setSuppliers(newOptions)
    // console.log(newOptions)
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data: suppliersTemp } = await dataProvider.getList('supplier', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
        !abortController.signal.aborted && setSuppliers(suppliersTemp)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })

        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const getData = async () => {
      try {
        const {
          data: { addressTypes: addressTypesList },
        } = await dataProvider.getSupplierDict('SupplierDict', {})

        if (!abortController.signal.aborted) {
          setAddressTypes(addressTypesList)
        }
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }
    if (showDialog) getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, showDialog])

  const handleClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(true)
  }

  const handleCloseClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(false)
  }

  useEffect(() => {
    return () => {}
  }, [])

  const handleSubmit = async (params) => {
    // console.log(params)
    const abortController = new AbortController()
    create(
      {
        payload: {
          data: {
            name: params.supplier_name,
            business_licence_no: params.supplier_businessLicenceNumber,
            addresses: {
              data: {
                type: params.supplier_address_type,
                local_full_address: params.supplier_local_full_address,
                country_code: params.supplier_country_code || 'CN',
              },
            },
            contacts: {
              data: {
                local_name: params.supplier_local_name,
                mobile: params.supplier_mobile,
              },
            },
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          // console.log('changing', data.id)
          !abortController.signal.aborted && form.change(source, data.id)
          handleChange({ id: data.id, name: params.supplier_name })
          !abortController.signal.aborted && setShowDialog(false)
        },
        onFailure: (resp) => {
          if (
            resp.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "supplier_business_licence_no_key"'
          ) {
            // console.log('changing')
            form.change(
              source,
              suppliers.find(
                (item) => item.business_licence_no === params.supplier_businessLicenceNumber,
              ).id,
            )

            // console.log(
            //   suppliers.find(
            //     (item) => item.business_licence_no === params.supplier_businessLicenceNumber,
            //   ).id,
            // )
            notify('Supplier Licence already exists', { type: 'info' })
            !abortController.signal.aborted && setShowDialog(false)
          } else {
            console.log('error', resp.message)
            notify(resp.message, { type: 'error' })
          }
        },
      },
    )
  }

  // console.log('SELWITHCREATE', props, rest)

  return (
    <div className={classes.root}>
      <AutocompleteInput
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        label={label || 'Supplier:'}
        source={source}
        choices={suppliers}
        disabled={!editing}
        // optionText={optionText}
        // optionValue={optionValue}
        {...rest}
      />

      {editing ? (
        <div>
          <FormWithRedirect
            resource="suppliers"
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <SupplierSelectWithCreateForm
                handleClick={handleClick}
                handleCloseClick={handleCloseClick}
                showDialog={showDialog}
                addressTypes={addressTypes}
                // countries={countries}
                // states={states}
                // cities={cities}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                loading={loading}
              />
            )}
          />
          <IconButton
            onClick={async () => {
              // const formState = form.getState()

              // console.log(values)

              const quoteId = values.product_quotes?.length > 0 && values.product_quotes[0].id
              const supplierId =
                values.product_quotes?.length > 0 && values.product_quotes[0].supplier_id

              setSaving(true)
              try {
                if (quoteId && supplierId) {
                  await dataProvider.update('product_quote', {
                    data: {
                      id: quoteId,
                      supplier_id: supplierId,
                    },
                  })
                }
                setSaving(false)
                // form.change(resourceName, newChosenItems)
                setEditing(false)
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                setSaving(false)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress />}
        </div>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  )
}

export default SupplierSelectWithCreate
