import React from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  FunctionField,
  SelectInput,
  // AutocompleteInput,
  // useTranslate,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  TopToolbar,
  CreateButton,
  useRedirect,
} from 'react-admin'
import { Chip, Tooltip, Button } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { ImportButton } from 'react-admin-import-csv'
import SupplierDefaultImg from '../../assets/supplier_default.png'
import { getDisplayName } from '../../utils/utils'

const ListActions = (props) => {
  const { className } = props
  return (
    <TopToolbar className={className}>
      <CreateButton />
      <ImportButton {...props} />
    </TopToolbar>
  )
}

export const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
    <ReferenceInput
      source="supplier"
      reference="suppliers"
      // sort={{ field: 'id', order: 'ASC' }}
      alwaysOn
      style={{ minWidth: '200px' }}
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="sca"
      reference="users"
      label="SCA"
      // sort={{ field: 'id', order: 'ASC' }}
      filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="category"
      reference="category"
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <DateInput
      source="date_gte"
      label="Quote date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Quote date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
  </Filter>
)

const ProductList = (props) => {
  // const user = JSON.parse(localStorage.getItem('user'))
  const redirect = useRedirect()

  return (
    <div>
      <List
        {...props}
        filter={{
          // eslint-disable-next-line camelcase
          // sca_user_id: props.isManager ? null : user?.id,
          status: props.filter?.status ? props.filter?.status : null,
        }}
        filters={props.basePath === '/products' ? <ProductFilter /> : null}
        actions={<ListActions />}
        // actions={null}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid path="products" rowClick="edit">
          <TextField source="id" label="Id" />
          <FunctionField
            label="Photo"
            render={(record) => (
              <img
                style={{ maxHeight: '50px' }}
                src={record.thumbnail_photo?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            )}
          />
          <FunctionField
            label="Product Name"
            render={(record) => {
              const name = record.name
              return name?.length > 40 ? (
                <Tooltip title={name}>
                  <span>{`${name?.substring(0, 40)}...`}</span>
                </Tooltip>
              ) : (
                name
              )
            }}
          />
          <FunctionField
            label="Categories"
            render={(record) =>
              record.product_categories?.map((cat) => (
                <Chip key={`cats${cat.id}`} label={cat.category.name} />
              ))
            }
          />

          {/* <TextField source="status" label="Status" /> */}
          <TextField source="product_quotes[0].prices[0].quantity" label="MOQ" sortable={false} />
          <FunctionField
            render={(record) =>
              record.product_quotes.length > 0 && record.product_quotes[0].prices.length > 0
                ? `$ ${(record.product_quotes[0].prices[0].unit_price / 100).toFixed(2)}`
                : ''
            }
            label="Price"
          />
          {/* <TextField source="product_quotes[0].prices[0].unit_price" label="Price" /> */}
          <FunctionField render={(record) => getDisplayName(record.sca)} label="SCA" />
          <DateField source="created_at" label="Created Date" />
          <FunctionField
            render={(record) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation()

                  const otherSpecifications = []

                  const uniqueSpecs = []
                  if (record.product_quotes) {
                    record.product_quotes[0].specifications?.map((spec) => {
                      if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
                        uniqueSpecs.push(spec.option_value.option.name)
                      }
                    })
                  }

                  uniqueSpecs.map((spec) => {
                    if (spec !== 'Colour' && spec !== 'Material' && spec !== 'Imprint') {
                      otherSpecifications.push({
                        name: spec,
                        option_value: {
                          value: record.product_quotes
                            ? record.product_quotes[0].specifications
                                .filter(
                                  (qspec) =>
                                    qspec.option_value.option?.name === spec &&
                                    qspec.deleted_at === null,
                                )
                                .map((item) => item.option_value?.value)
                            : [],
                        },
                      })
                    }
                  })

                  // console.log(otherSpecifications)
                  const newRecord = {
                    name: record.name,
                    brand: record.brand,
                    short_description: record.short_description,
                    notes: record.notes,
                    hts_code: record.hts_code,
                    dutyRate: record.duty_rate === 0 ? 0 : record.duty_rate / 100 || null,
                    chargeable_weight_air: record.chargeable_weight_air || null,
                    chargeable_weight_sea: record.chargeable_weight_sea || null,
                    root_sku: record.root_sku,
                    root_product_id: record.root_product_id || record.id,
                    status: '^PENDING_SUBMISSION^',
                    sampleCost:
                      record.product_quotes.length > 0
                        ? record.product_quotes[0]?.sample_cost === 0
                          ? 0
                          : record.product_quotes[0]?.sample_cost / 100 || null
                        : null,
                    toolingCost:
                      record.product_quotes.length > 0
                        ? record.product_quotes[0]?.tooling_cost === 0
                          ? 0
                          : record.product_quotes[0]?.tooling_cost / 100 || null
                        : null,
                    product_declarations: record.product_declarations,
                    primary_photo: record.primary_photo,
                    thumbnail_photo: record.thumbnail_photo,
                    additional_files: record.additional_files,
                    images: record.images,
                    prices:
                      record.product_quotes.length > 0 &&
                      record.product_quotes[0].prices?.map((item) => {
                        return {
                          quantity: item.quantity,
                          unit_price: item.unit_price / 100 || null,
                          lead_time: item.lead_time,
                        }
                      }),
                    product_quote: {
                      packaging_type:
                        record.product_quotes.length > 0
                          ? record.product_quotes[0].packaging_type
                          : null,
                      pieces_per_carton:
                        record.product_quotes.length > 0
                          ? record.product_quotes[0].pieces_per_carton === 0
                            ? 0
                            : record.product_quotes[0].pieces_per_carton
                          : null,
                      sample_lead_time:
                        record.product_quotes.length > 0
                          ? record.product_quotes[0].sample_lead_time === 0
                            ? 0
                            : record.product_quotes[0].sample_lead_time
                          : null,
                      tooling_lead_time:
                        record.product_quotes.length > 0
                          ? record.product_quotes[0].tooling_lead_time === 0
                            ? 0
                            : record.product_quotes[0].tooling_lead_time
                          : null,
                      supplier_id:
                        record.product_quotes.length > 0
                          ? record.product_quotes[0].supplier_id
                          : null,
                      valid_date:
                        record.product_quotes.length > 0
                          ? record.product_quotes[0].valid_date
                          : null,
                    },
                    packagingDimensions: {
                      length:
                        record.packaging_dimensions?.length === 0
                          ? 0
                          : record.packaging_dimensions?.length / 100 || null,
                      width:
                        record.packaging_dimensions?.width === 0
                          ? 0
                          : record.packaging_dimensions?.width / 100 || null,
                      height:
                        record.packaging_dimensions?.height === 0
                          ? 0
                          : record.packaging_dimensions?.height / 100 || null,
                      gross_weight:
                        record.packaging_dimensions?.gross_weight === 0
                          ? 0
                          : record.packaging_dimensions?.gross_weight / 100 || null,
                      lengthIN: record.packaging_dimensions?.length
                        ? (record.packaging_dimensions?.length / 100 / 2.54).toFixed(2)
                        : null,
                      widthIN: record.packaging_dimensions?.width
                        ? (record.packaging_dimensions?.width / 100 / 2.54).toFixed(2)
                        : null,
                      heightIN: record.packaging_dimensions?.height
                        ? (record.packaging_dimensions?.height / 100 / 2.54).toFixed(2)
                        : null,
                      weightLB: record.packaging_dimensions?.gross_weight
                        ? ((record.packaging_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
                        : null,
                    },
                    productDimensions: {
                      length:
                        record.product_dimensions?.length === 0
                          ? 0
                          : record.product_dimensions?.length / 100 || null,
                      width:
                        record.product_dimensions?.width === 0
                          ? 0
                          : record.product_dimensions?.width / 100 || null,
                      height:
                        record.product_dimensions?.height === 0
                          ? 0
                          : record.product_dimensions?.height / 100 || null,
                      gross_weight:
                        record.product_dimensions?.gross_weight === 0
                          ? 0
                          : record.product_dimensions?.gross_weight / 100 || null,
                      lengthIN: record.product_dimensions?.length
                        ? (record.product_dimensions?.length / 100 / 2.54).toFixed(2)
                        : null,
                      widthIN: record.product_dimensions?.width
                        ? (record.product_dimensions?.width / 100 / 2.54).toFixed(2)
                        : null,
                      heightIN: record.product_dimensions?.height
                        ? (record.product_dimensions?.height / 100 / 2.54).toFixed(2)
                        : null,
                      weightLB: record.product_dimensions?.gross_weight
                        ? ((record.product_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
                        : null,
                    },
                    cartonDimensions: {
                      length:
                        record.carton_dimensions?.length === 0
                          ? 0
                          : record.carton_dimensions?.length / 100 || null,
                      width:
                        record.carton_dimensions?.width === 0
                          ? 0
                          : record.carton_dimensions?.width / 100 || null,
                      height:
                        record.carton_dimensions?.height === 0
                          ? 0
                          : record.carton_dimensions?.height / 100 || null,
                      gross_weight:
                        record.carton_dimensions?.gross_weight === 0
                          ? 0
                          : record.carton_dimensions?.gross_weight / 100 || null,
                      lengthIN: record.carton_dimensions?.length
                        ? (record.carton_dimensions?.length / 100 / 2.54).toFixed(2)
                        : null,
                      widthIN: record.carton_dimensions?.width
                        ? (record.carton_dimensions?.width / 100 / 2.54).toFixed(2)
                        : null,
                      heightIN: record.carton_dimensions?.height
                        ? (record.carton_dimensions?.height / 100 / 2.54).toFixed(2)
                        : null,
                      weightLB: record.carton_dimensions?.gross_weight
                        ? ((record.carton_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
                        : null,
                    },
                    categories: record.product_categories?.map((item) => item.category.id),
                    tags: record.product_tags?.map((item) => item.tag.id),
                    productImprints: record.product_specifications
                      ?.filter(
                        (item) => !item.deleted_at && item.option_value.option.name === 'Imprint',
                      )
                      .map((item) => {
                        return {
                          id: item.option_value.id,
                          name: item.option_value.value,
                          value: item.option_value.value,
                        }
                      }),
                    productColours: record.product_specifications
                      ?.filter(
                        (item) => !item.deleted_at && item.option_value.option.name === 'Colour',
                      )
                      .map((item) => {
                        return { option_value: { value: item.option_value.value } }
                      }),
                    productMaterials: record.product_specifications
                      ?.filter(
                        (item) => !item.deleted_at && item.option_value.option.name === 'Material',
                      )
                      .map((item) => {
                        return {
                          id: item.option_value.id,
                          name: item.option_value.value,
                          value: item.option_value.value,
                        }
                      }),
                    imprintOptions: record.product_quotes[0]?.specifications
                      ?.filter(
                        (item) => !item.deleted_at && item.option_value.option.name === 'Imprint',
                      )
                      .map((item) => {
                        return {
                          id: item.option_value.id,
                          name: item.option_value.value,
                          value: item.option_value.value,
                        }
                      }),
                    colourOptions: record.product_quotes[0]?.specifications
                      ?.filter(
                        (item) => !item.deleted_at && item.option_value.option.name === 'Colour',
                      )
                      .map((item) => {
                        return { option_value: { value: item.option_value.value } }
                      }),
                    materialOptions: record.product_quotes[0]?.specifications
                      ?.filter(
                        (item) => !item.deleted_at && item.option_value.option.name === 'Material',
                      )
                      .map((item) => {
                        return {
                          id: item.option_value.id,
                          name: item.option_value.value,
                          value: item.option_value.value,
                        }
                      }),
                    otherSpecifications,
                  }
                  // console.log(newRecord)

                  redirect('create', 'products', {}, null, {
                    record: newRecord,
                  })
                }}
              >
                Duplicate
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </div>
  )
}

export default ProductList
