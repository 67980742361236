import React, { useState } from 'react'
import {
  Box,
  Button,
  // Popover,
  // Checkbox,
} from '@material-ui/core'
import {
  useNotify,
  useDataProvider,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  required,
} from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import get from 'lodash/get'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import useStyles from '../styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import SelectInput from '../../../components/input/SelectInput'

const PaymentTermsInput = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [editing, setEditing] = useState(false)
  const { values } = useFormState()
  const form = useForm()
  const {
    hasMissingFields,
    milestones,
    milestonesOptions,
    id,
    resource,
    parentField,
    formField,
    field,
    title,
    titleClass,
  } = props

  // console.log(values)

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {title ? <span className={titleClass}>{title}</span> : null}
        {editing ? (
          <Box display="flex">
            <IconButton
              onClick={async () => {
                try {
                  const terms = get(values, formField || field)
                  console.log(terms)

                  const params = terms.map((item) => ({
                    ...(item.id && { id: item.id }),
                    [parentField]: id,
                    milestone: item.milestone,
                    value: item.value,
                  }))

                  await Promise.all(
                    params.map(async (el, index) => {
                      if (typeof el.id === 'number') {
                        // console.log('UPD', el)
                        await dataProvider.update(resource, {
                          data: el,
                        })
                      } else {
                        // console.log('CRE', el)
                        const resp = await dataProvider.create(resource, {
                          data: {
                            ...el,
                          },
                        })
                        await form.change(`${field}[${index}].id`, resp.data.id)
                      }
                    }),
                  )
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  notify(error.message, { type: 'warning' })
                }
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px', padding: '0px 12px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px', padding: '0px 12px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px', padding: '0px 12px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <ArrayInput
        source={formField || field}
        label={false}
        style={{ width: '140px', fontSize: '14px', marginTop: '0px' }}
      >
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          disableAdd={!editing}
          disableRemove={!editing}
          addButton={
            <Button
              style={{
                width: '120px',
                backgroundColor: hasMissingFields ? 'lightgray' : '#F8F8FA',
                color: 'black',
              }}
              disabled={hasMissingFields}
            >
              <AddIcon />
            </Button>
          }
          removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, rest }) => (
              <Box display="flex">
                <Box>
                  {milestones.length > 0 && (
                    <SelectInput
                      label="Milestone"
                      source={getSource('milestone')}
                      {...rest}
                      record={scopedFormData}
                      choices={milestones}
                      optionText="name"
                      optionValue="name"
                      defaultValue=""
                      fullWidth
                      disabled={!editing}
                      validate={required()}
                      variant="standard"
                      className={classes.dialogInput}
                    />
                  )}
                </Box>
                <Box ml="10px">
                  <SelectInput
                    label="Value"
                    source={getSource('value')}
                    {...rest}
                    record={scopedFormData}
                    choices={milestonesOptions}
                    optionText="name"
                    optionValue="value"
                    defaultValue=""
                    disabled={!editing}
                    validate={required()}
                    fullWidth
                    variant="standard"
                    className={classes.dialogInput}
                  />
                </Box>
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  )
}

export default PaymentTermsInput
