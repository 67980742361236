import React, { useState } from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  FunctionField,
  SelectInput,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  TopToolbar,
  ExportButton,
  DeleteButton,
  NullableBooleanInput,
  useRedirect,
} from 'react-admin'
import { Button, Chip, Tooltip } from '@material-ui/core'
import SupplierDefaultImg from '../../assets/supplier_default.png'
import { getDisplayName, duplicateProduct } from '../../utils/utils'
import CreateDialog from './components/CreateDialog'

const ListActions = (props) => {
  const { className } = props
  const redirect = useRedirect()
  const [createOpen, setCreateOpen] = useState(false)

  return (
    <TopToolbar className={className}>
      <ExportButton />
      <Button style={{ color: '#1E88E5' }} onClick={() => redirect('/productImport')}>
        Import
      </Button>
      <Button
        style={{
          color: 'black',
          border: 'solid 1px rgba(180, 125, 80, 0.5)',
          backgroundColor: 'rgba(255, 149, 102, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(255, 149, 102, 0.3)',
          },
        }}
        onClick={async () => {
          setCreateOpen(true)
        }}
      >
        Create
      </Button>
      <CreateDialog createOpen={createOpen} setCreateOpen={setCreateOpen} />
    </TopToolbar>
  )
}

export const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
    <ReferenceInput
      source="supplier"
      reference="suppliers"
      // sort={{ field: 'id', order: 'ASC' }}
      alwaysOn
      style={{ minWidth: '200px' }}
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="sca"
      reference="users"
      label="SCA"
      // sort={{ field: 'id', order: 'ASC' }}
      filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="category"
      reference="category"
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <DateInput
      source="date_gte"
      label="Quote date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Quote date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <NullableBooleanInput
      source="base_products"
      label="Base Products Only"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
  </Filter>
)

const ProductList = (props) => {
  const redirect = useRedirect()

  return (
    <List
      {...props}
      filter={{
        // eslint-disable-next-line camelcase
        // sca_user_id: props.isManager ? null : user?.id,
        status: props.filter?.status ? props.filter?.status : null,
      }}
      filters={props.basePath === '/products' ? <ProductFilter /> : null}
      filterDefaultValues={{ base_products: true }}
      actions={<ListActions />}
      // actions={null}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
      bulkActionButtons={false}
    >
      <Datagrid path="products" rowClick="edit">
        <TextField source="id" label="Id" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ width: '50px', height: '50px' }}>
              <img
                style={{ maxHeight: '50px', maxWidth: '50px' }}
                src={record.thumbnail_photo?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <FunctionField
          label="Product Name"
          render={(record) => {
            const name = record.name
            return name?.length > 40 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 40)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        <FunctionField
          label="Categories"
          render={(record) =>
            record.product_categories?.map((cat) => (
              <Chip key={`cats${cat.id}`} label={cat.category.name} />
            ))
          }
        />

        {/* <TextField source="status" label="Status" /> */}
        <TextField source="product_quotes[0].prices[0].quantity" label="MOQ" sortable={false} />
        <FunctionField
          render={(record) =>
            record.product_quotes.length > 0 && record.product_quotes[0].prices.length > 0
              ? `$ ${(record.product_quotes[0].prices[0].unit_price / 100).toFixed(2)}`
              : ''
          }
          label="Price"
        />
        {/* <TextField source="product_quotes[0].prices[0].unit_price" label="Price" /> */}
        <FunctionField render={(record) => getDisplayName(record.sca)} label="SCA" />
        <DateField source="created_at" label="Created Date" />
        <FunctionField
          render={(record) => (
            <Button
              onClick={(e) => {
                e.stopPropagation()

                const newRecord = duplicateProduct({ record })

                // console.log('DUPE', newRecord)

                redirect('create', 'products', {}, null, {
                  record: newRecord,
                })
              }}
            >
              Duplicate
            </Button>
          )}
        />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export default ProductList
