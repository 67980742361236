import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const suppliersCategoriesList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          query getSupplierCategories {supplier_by_pk(id: ${params.id}) {
            id
            categories_aggregate {
              aggregate {count}
            }
            categories {
              id
              category {
                id
                name
              }
            }
          }}
        `,
    parseResponse: ({ data }) => {
      if (data && data.supplier_by_pk) {
        return {
          data: data.supplier_by_pk.categories,
          total: data.supplier_by_pk.categories_aggregate.aggregate.count,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const suppliersAddressList = ({ context, params }) => {
  return {
    ...context,
    query: gql`
    query getSupplierAddresses {
      supplier(where: {id: {_eq: ${params.id || params.filter.id}}}) {
        id
        addresses {
          address_line1
          address_line2
          city
          country_code
          created_at
          id
          local_full_address
          post_code
          province
          shipping_port {
            name
            id
            port_code
            type
          }
          shipping_port_id
          supplier_id
          type
        }
      }
    }
  `,
    variables: { params },
    parseResponse: ({ data }) => ({ data }),
  }
}

export const suppliersFileCreate = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      createSupplierFile(
          supplier: ${params.supplier}
          type: "${params.type}"
          file: {
            url: "${params.url}"
            filename: "${params.filename}"
          }
        ) {
        id
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.createSupplierFile,
    }),
  }
}

export const suppliersFileDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      deleteSupplierFile(
          id: ${params.id}
        )
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.deleteSupplierFile,
    }),
  }
}

export const suppliersAddressCreate = ({ context, params }) => {
  const _params = {
    supplier: params.supplier,
    shippingPort: params.shipping_port_id || params.shippingPort,
    fullAddress: params.local_full_address || params.localAddress,
    type: params.type,
    line1: params.address_line1 || params.addressLine1,
    line2: params.address_line2 || params.addressLine2,
    city: params.city,
    province: params.province,
    countryCode: params.country_code || params.country,
    postCode: params.post_code || params.country,
  }
  return {
    ...context,
    variables: {
      ..._params,
    },
    query: gql`
    mutation {
      createSupplierAddress(
        address: {
          supplier: ${params.supplier}
          shippingPort: ${params.shipping_port_id || params.shippingPort}
          fullAddress: "${params.local_full_address || params.localAddress}"
          type: "${params.type}"
          line1: "${params.address_line1 || params.addressLine1}"
          line2: "${params.address_line2 || params.addressLine2}"
          city: "${params.city}"
          province: "${params.province}"
          countryCode: "${params.country_code || params.country}"
          postCode: "${params.post_code || params.postCode}"
        }
        ) {
        id
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.createSupplierAddress,
    }),
  }
}

export const suppliersAddressUpdate = ({ context, params }) => {
  const _params = {
    supplier: params.supplier,
    shippingPort: params.shipping_port_id || params.shippingPortID,
    fullAddress: params.local_full_address || params.localAddress,
    type: params.type,
    line1: params.address_line1 || params.addressLine1,
    line2: params.address_line2 || params.addressLine2,
    city: params.city,
    province: params.province,
    countryCode: params.country,
    postCode: params.post_code || params.postCode,
  }
  return {
    ...context,
    variables: {
      ..._params,
    },
    query: gql`
    mutation {
      updateSupplierAddress(
        address: {
          id: ${params.id}
          shippingPort: ${params.shipping_port_id || params.shippingPort}
          fullAddress: "${params.local_full_address || params.localAddress}"
          line1: "${params.address_line1 || params.addressLine1}"
          line2: "${params.address_line2 || params.addressLine2}"
          city: "${params.city}"
          province: "${params.province}"
          countryCode: "${params.country_code || params.country}"
          postCode: "${params.post_code || params.postCode}"
          type: "${params.type}"
        }
        ) {
        id
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.updateSupplierAddress,
    }),
  }
}

export const suppliersRegistrationCheckUpdate = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      updateSupplierRegistrationCheck(
        id: ${params.supplierId}
        companyName: "${params.companyName || ''}"
        companyAddress: "${params.companyAddress || ''}"
        legalRepresentative: "${params.legalRepresentative || ''}"
        registeredCapital: "${params.registeredCapital || ''}"
        verificationLink: "${params.verificationLink || ''}"
        active: ${params.active}
        confirmed: ${params.confirmed}
        businessScope: "${params.businessScope || ''}"
        dateRetrieved: "${params.dateRetrieved || ''}"
        remarks: "${params.remarks || ''}"
      )
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.updateSupplierRegistrationCheck,
    }),
  }
}

export const suppliersAddressDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      deleteSupplierAddress(
        id: ${params.id}
      )
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.deleteSupplierAddress,
    }),
  }
}

export const suppliersContactCreate = ({ context, params }) => {
  const _params = {
    supplier: params.supplier,
    firstName: params.first_name || params.firstName,
    lastName: params.last_name || params.lastName,
  }

  if (params.email) _params.email = params.email
  if (params.mobile) _params.mobile = params.mobile
  if (params.wechat) _params.wechat = params.wechat
  if (params.whatsapp) _params.whatsapp = params.whatsapp
  if (params.position) _params.position = params.position

  let jsonParams = JSON.stringify(_params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      createSupplierContact(
        contact: {${jsonParams}}
        ) {
        id
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.createSupplierContact,
    }),
  }
}

export const suppliersContactDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      deleteSupplierContact(
        id: ${params.id}
      )
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.deleteSupplierContact,
    }),
  }
}

export const suppliersContactUpdate = ({ context, params }) => {
  const _params = {
    id: params.id,
    firstName: params.first_name || params.firstName,
    lastName: params.last_name || params.lastName,
  }

  if (params.email) _params.email = params.email
  if (params.mobile) _params.mobile = params.mobile
  if (params.wechat) _params.wechat = params.wechat
  if (params.whatsapp) _params.whatsapp = params.whatsapp
  if (params.position) _params.position = params.position

  let jsonParams = JSON.stringify(_params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      updateSupplierContact(
        contact: {${jsonParams}}
        ) {
        id
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.updateSupplierContact,
    }),
  }
}

export const suppliersBankDetailUpdate = ({ context, params }) => {
  const _params = {
    id: params.id,
  }

  if (params.accountNumber) _params.accountNumber = params.accountNumber
  if (params.accountCurrency) _params.accountCurrency = params.accountCurrency
  if (params.beneficiaryName) _params.beneficiaryName = params.beneficiaryName
  if (params.branchAddress) _params.branchAddress = params.branchAddress
  if (params.branchName) _params.branchName = params.branchName
  if (params.clearingCode) _params.clearingCode = params.clearingCode
  if (params.swiftCode) _params.swiftCode = params.swiftCode
  if (params.bank) _params.bank = params.bank
  if (params.beneficiaryAddress) _params.beneficiaryAddress = params.beneficiaryAddress
  if (params.taxNumber) _params.taxNumber = params.taxNumber

  let jsonParams = JSON.stringify(_params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      updateSupplierBankDetail(
        bankDetail: {${jsonParams}}
        ) {
        id
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.updateSupplierBankDetail,
    }),
  }
}

export const suppliersCertificationCreate = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  // console.log(`
  //     mutation {
  //       createSupplierCertifications(
  //         certifications:
  //           [${jsonParams}]
  //       ) {
  //         ids
  //       }
  //     }
  //   `)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      createSupplierCertifications(
        certifications: 
          [${jsonParams}]
      ) {
        ids
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.createSupplierCertifications,
    }),
  }
}

export const suppliersCertificationDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
    mutation {
      deleteSupplierCertification(
        id: ${params.id}
      )
    }
  `,
    parseResponse: ({ data }) => ({
      data: data.deleteSupplierCertification,
    }),
  }
}
export const suppliersCertificationUpdate = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  // console.log(`
  //     mutation {
  //       updateSupplierCertification(
  //         certification: {
  //           ${jsonParams}
  //         }
  //       ) {
  //         id
  //       }
  //     }
  //   `)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation {
            updateSupplierCertification(
              certification: {
                ${jsonParams}
              }
            ) {
              id
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.updateSupplierCertification,
    }),
  }
}

export const suppliersDict = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getSupplierDict {
        supplierModes: system_dictionary(where: { own: { _eq: "supplier-mode" } }) {
          id
          name
          own
          value
        }
        supplerTypes: system_dictionary(where: { own: { _eq: "supplier-type" } }) {
          id
          name
          own
          value
        }
        addressTypes: system_dictionary(where: { own: { _eq: "address-type" } }) {
          id
          name
          own
          value
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}

export const banksList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getBanks {
        bank(order_by: { name: asc_nulls_last }) {
          id
          name
          region
          swift_code
        }
        bank_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.bank,
      total: data.bank_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}
