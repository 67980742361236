import React from 'react'
import {
  FormWithRedirect,
  Create,
  useNotify,
  useDataProvider,
  AutocompleteInput,
  ReferenceInput,
  required,
} from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import { Card, Box } from '@material-ui/core'
import Confirm from '../../components/Confirm'
import useStyles from '../styles'

export const CarouselContentCreate = (props) => (
  <Create {...props}>
    <FormData params={props} />
  </Create>
)

const FormData = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSubmit = async (record) => {
    const params = {
      name: record.name,
      thumbnail_photo_url: record.selectedURL,
      client_quote_id: record.client_quote_id,
      product_id: record.product_id,
    }

    // console.log(params)
    try {
      // await dataProvider.create('offer', { objects: params })
      await dataProvider.create('app_carousel_products', { data: params })
      notify('Created Successfully.', { type: 'info' })
    } catch (error) {
      console.log(error)
      notify(error.message, 'warning')
    }
  }

  // console.log(props)

  const optionText = (value) => `${value?.id}: ${value?.product_quote?.product?.name}`

  return (
    <FormWithRedirect
      {...props}
      validate={(values) => {
        const errors = {}
        !values.selectedURL ? (errors.selectedURL = 'No image selected') : null
        return errors
      }}
      render={(formProps) => (
        <Card>
          <Box display="flex" flexDirection="column" p="60px">
            <ReferenceInput
              source="client_quote_id"
              reference="scmQuote"
              label="Choose a product"
              filter={{ is_catalogue: 'true' }}
              sort={{ field: 'id', order: 'ASC' }}
              className={props.className}
              variant="standard"
              validate={required()}
            >
              {/* <AutocompleteInput /> */}
              <CustomAutocompleteInput optionText={optionText} />
            </ReferenceInput>
          </Box>
          <Confirm
            redirect="/carouselContent"
            // redirect={null}
            record={formProps.record}
            basePath={formProps.basePath}
            label="SUBMIT"
            undoable
            isSave
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            disabled={formProps.saving || formProps.invalid}
            resource="carouselContent"
          />
        </Card>
      )}
    />
  )
}

const CustomAutocompleteInput = (props) => {
  const { values } = useFormState()
  const form = useForm()
  // console.log(values)
  // console.log(props.choices)
  const chosenProduct = props.input?.value
    ? props.choices.find((item) => item.id === props.input.value)
    : null

  const images =
    chosenProduct && chosenProduct?.product_quote?.product?.images?.length > 0
      ? [...chosenProduct.product_quote.product.images]
      : []
  // chosenProduct?.product_quote?.product?.thumbnail_photo?.id &&
  //   images.push({ file: chosenProduct.product_quote.product.thumbnail_photo })

  // console.log(props.input?.value, chosenProduct, images)

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Box flex={1}>
          <AutocompleteInput fullWidth variant="outlined" label="" {...props} />
        </Box>
        <Box flex={3} />
      </Box>
      {images.length > 0 && (
        <Box>
          <span style={{ fontSize: '12px', padding: '10px 0px' }}>Choose an image</span>
          <Box display="flex" flexWrap="wrap">
            <ImageSelector
              form={form}
              name={chosenProduct.product_quote?.product?.name}
              selectedURL={values.selectedURL}
              choices={images}
              productId={chosenProduct.product_quote?.product?.id}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

const ImageSelector = ({ choices, name, form, selectedURL, productId }) => {
  const classes = useStyles()
  // console.log(choices, selectedURL)

  const handleClick = ({ url }) => {
    form.change('selectedURL', url)
    form.change('name', name)
    form.change('product_id', productId)
  }
  const selections = choices?.map((item) => {
    return (
      <Box
        key={`img${item.id}`}
        className={classes.productImagesBox}
        style={
          item.file?.url === selectedURL
            ? { cursor: 'default', boxShadow: 'inset 0 0 5px black' }
            : { cursor: 'pointer' }
        }
      >
        <img
          className={classes.productImages}
          src={item.file?.url}
          alt=""
          onClick={() => handleClick({ url: item.file?.url })}
        />
      </Box>
    )
  })

  return selections
}
