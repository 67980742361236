import React from 'react'
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import NumberFormat from 'react-number-format'
import useStyles from './styles'
import CheckoutForm from './checkout'

const ProductDetails = ({
  setQuantity,
  open,
  setOpen,
  data,
  totalStock,
  totalPrice,
  options,
  stripePromise,
  clientSecret,
  quantity,
  courierFees,
}) => {
  const classes = useStyles()

  const payableAmount = parseInt(totalPrice) + courierFees / 100
  return (
    <Box display="flex" flexDirection="column">
      <span className={classes.nameText}>{data.name}</span>
      <span className={classes.priceText}>${data.retail_price / 100} per piece</span>
      <span className={classes.descText}>{data.description}</span>
      <span className={classes.subtitleText}>Quantity:</span>
      <TextField
        type="number"
        inputProps={{
          min: 0,
          max: totalStock,
        }}
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        variant="outlined"
      />
      <Box display="flex" justifyContent="space-between" alignItems="baseline" mt="20px">
        <span className={classes.subtitleText}>Total Payable:</span>
        <span className={classes.priceText}>
          <NumberFormat
            value={payableAmount}
            className={classes.numberInput}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            // disabled={confirmed}
            prefix="$"
            // suffix={suffix}
            renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
          />
        </span>
      </Box>
      <Box mt="20px" display="flex" justifyContent="end">
        <Button
          className={classes.button}
          disabled={!data || !data.retail_price || quantity < 1}
          onClick={() => setOpen(true)}
        >
          Buy Now
        </Button>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogContent>
          <Box>
            <DialogTitle>Make a Purchase</DialogTitle>
            {clientSecret && open && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ProductDetails
