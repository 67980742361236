import React, { useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { uniqueId } from 'lodash'
import NumberFormat from './CustomNumberFormat'
import TextInput from '../../components/OutlinedTextInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ProductOptionsForm = () => {
  const form = useForm()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const { values } = useFormState()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Product Materials</span>

        {editing ? (
          <div>
            <IconButton
              onClick={async () => {
                setSaving(true)
                try {
                  const colours = []

                  const params = values.productMaterials.map((el) => {
                    el.colours?.length > 0 &&
                      el.colours.map((col) =>
                        colours.push({
                          ...(typeof col.id === 'number' && { id: col.id }),
                          product_material_id: el.id,
                          colour: col.colour,
                          reference: col.reference,
                        }),
                      )
                    return {
                      where: { id: { _eq: el.id } },
                      _set: {
                        ...(el.name && { name: el.name }),
                        ...(el.type && { type: el.type }),
                        ...(el.vendor && { vendor: el.vendor }),
                        ...(el.vendor_code && { vendor_code: el.vendor_code }),
                        ...(el.unit_of_measurement && {
                          unit_of_measurement: el.unit_of_measurement,
                        }),
                        ...(el.quantity_per_piece && { quantity_per_piece: el.quantity_per_piece }),
                        ...(el.estimatedCost && { estimated_cost: el.estimatedCost * 100 }),
                        ...(el.notes && { notes: el.notes }),
                      },
                    }
                  })

                  if (colours.length > 0) {
                    await dataProvider.create('product_materials_colours', {
                      data: colours,
                      on_conflict: {
                        constraint: 'product_materials_colours_pkey',
                        update_columns: ['colour', 'reference'],
                      },
                    })
                  }

                  //   console.log(params)

                  await dataProvider.updateMany('product_materials', {
                    data: params,
                    on_conflict: {
                      constraint: 'product_materials_pkey',
                      update_columns: [
                        'type',
                        'name',
                        'vendor',
                        'vendor_code',
                        'unit_of_measurement',
                        'quantity_per_piece',
                        'estimated_cost',
                        'notes',
                      ],
                    },
                  })

                  setSaving(false)
                  setEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: '#FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="materialsTable" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Id
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Type
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Vendor
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '6%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Vendor Code
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Unit of Measurement
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '6%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Notes
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Qty per Piece
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Est. Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '30%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Colours
                  <br />
                  Value - Reference
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Delete
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.productMaterials?.map((item, index) => (
                <MaterialsRow
                  disabled={!editing}
                  key={`material${item.id}`}
                  index={index}
                  id={item.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {editing ? (
          <IconButton
            aria-label="add-material"
            // className={classes.infoBtn}
            disabled={!editing || saving}
            onClick={async () => {
              try {
                setSaving(true)
                const newOptResp = await dataProvider.create('product_materials', {
                  data: {
                    product_id: values.id,
                    name: `mat${uniqueId()}`,
                  },
                })

                const newMaterials = values.materials?.length > 0 ? [...values.materials] : []
                newMaterials.push({
                  id: newOptResp.data.id,
                  name: '',
                })
                await form.change('productMaterials', newMaterials)

                setSaving(false)
              } catch (error) {
                console.log(error)
                setSaving(false)
              }
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  )
}

const MaterialsRow = ({ index, disabled }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()

  return (
    <TableRow key={`matRow${index}`}>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].id`}
          label={false}
          disabled
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].type`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].name`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].vendor`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].vendor_code`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].unit_of_measurement`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productMaterials[${index}].notes`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productMaterials[${index}].quantity_per_piece`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productMaterials[${index}].estimatedCost`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{ height: 'unset', padding: '5px', verticalAlign: 'baseline' }}
      >
        {!disabled && (
          <Box
            display="flex"
            justifyContent="flex-end"
            mr="8px"
            style={{ height: 'unset !important' }}
          >
            <IconButton
              aria-label="add-material"
              style={{
                width: '10px',
                height: '10px',
                padding: '0px',
                fontSize: '14px',
                marginRight: '40px',
              }}
              // className={classes.infoBtn}
              disabled={disabled}
              onClick={async () => {
                const newOptResp = await dataProvider.create('product_materials_colours', {
                  data: {
                    product_material_id: values.productMaterials[index].id,
                    reference: `col${uniqueId()}`,
                  },
                })

                const newColours =
                  values.productMaterials[index].colours?.length > 0
                    ? [...values.productMaterials[index].colours]
                    : []
                newColours.push({ id: newOptResp.data.id })
                form.change(`productMaterials[${index}].colours`, newColours)
              }}
            >
              <AddIcon /> 1
            </IconButton>
            <IconButton
              aria-label="add-material"
              style={{
                width: '10px',
                height: '10px',
                padding: '0px',
                fontSize: '14px',
                marginRight: '5px',
              }}
              // className={classes.infoBtn}
              disabled={disabled}
              onClick={() => {
                const newColours = [...values.productMaterials[index].colours]
                newColours.push({ id: uniqueId() })
                newColours.push({ id: uniqueId() })
                newColours.push({ id: uniqueId() })
                newColours.push({ id: uniqueId() })
                newColours.push({ id: uniqueId() })
                form.change(`productMaterials[${index}].colours`, newColours)
              }}
            >
              <AddIcon /> 5
            </IconButton>
          </Box>
        )}
        {values.productMaterials[index].colours?.map((colour, colInd) => (
          <Box key={`col${colour.id}`} display="flex" alignItems="flex-end">
            <TextInput
              variant="standard"
              fullWidth
              style={{ padding: '0px', margin: '0px', height: '100%' }}
              source={`productMaterials[${index}].colours[${colInd}].colour`}
              label={false}
              placeholder="Value"
              disabled={disabled}
              // validate={required()}
            />
            <TextInput
              variant="standard"
              fullWidth
              style={{ padding: '0px', margin: '0px 0px 0px 5px', height: '100%' }}
              source={`productMaterials[${index}].colours[${colInd}].reference`}
              label={false}
              placeholder="Reference"
              disabled={disabled}
              // validate={required()}
            />
            <IconButton
              aria-label="del-material"
              style={{ maxWidth: '15px', maxHeight: '15px', padding: '0px', margin: '0x' }}
              onClick={async () => {
                try {
                  //   console.log('here', values.productMaterials)

                  if (typeof values.productMaterials[index].colours[colInd].id === 'number') {
                    await dataProvider.delete('product_materials_colours', {
                      data: { id: values.productMaterials[index].colours[colInd].id },
                    })
                  }

                  const newMaterials = [...values.productMaterials]
                  newMaterials[index].colours.splice(colInd, 1)
                  form.change('productMaterials', newMaterials)

                  // check if id is number or text
                  // if number await dp delete
                  // split array by 1
                  // update form
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              <CancelOutlined style={{ maxWidth: '15px', maxHeight: '15px' }} />
            </IconButton>
          </Box>
        ))}
      </BodyTableCell>

      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <IconButton
          aria-label="info"
          className={classes.infoBtn}
          onClick={async () => {
            try {
              await dataProvider.delete('product_materials_colours', {
                where: { product_material_id: { _eq: values.productMaterials[index].id } },
              })
              await dataProvider.delete('product_materials', {
                data: { id: values.productMaterials[index].id },
              })
              const newMaterials = [...values.productMaterials]
              newMaterials.splice(index, 1)
              form.change('productMaterials', newMaterials)
            } catch (error) {
              console.log(error)
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

export default ProductOptionsForm
