import React, { useState, useEffect } from 'react'
import { FormWithRedirect, TextInput, useDataProvider, useNotify } from 'react-admin'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  IconButton,
  Popper,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { uniqueId } from 'lodash'
import { useFormState, useForm } from 'react-final-form'
import { ColorInput } from '../../components/ColourPicker'
import uploadFile from '../../../dataProvider/aws_upload'

const useStyles = makeStyles(() => ({
  confirmButton: {
    backgroundColor: 'rgba(255, 149, 102, 0.5)',
    color: '#FFF',
    '&:hover': {
      backgroundColor: 'rgba(235,130,75,0.67)',
      color: '#FFF',
    },
  },
  iconStyle: {
    color: '#232323',
    cursor: 'pointer',
    width: '18px',
    height: '24px',
    margin: '0px 3px',
    borderRadius: '25%',
    '&:hover': {
      color: '#000000',
      backgroundColor: 'rgba(180,180,180,0.67)',
    },
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '400px',
    // margin: '0px 0px 5px',
  },
  previewImageBox: {
    top: '0px',
    left: '0px',
    width: '146',
    height: '146',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    border: 'solid gray 2px',
    // '&:hover': {
    //   border: 'gray dashed 2px',
    // },
  },
  previewImage: {
    zIndex: '100',
    maxWidth: '100%',
    maxHeight: '100%',
    // cursor: 'pointer',
  },
  deleteBtn: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  imageDropZone: {
    width: '150px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
    cursor: 'pointer',
  },
  loadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '70px',
    top: '70px',
  },
  bannerPreviewImageBox: {
    top: '0px',
    left: '0px',
    width: '180px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    border: 'solid gray 2px',
  },
  bannerImageDropZone: {
    width: '180px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
    cursor: 'pointer',
  },
  bannerLoadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '80px',
    top: '20px',
  },
  heroBannerPreviewImageBox: {
    top: '0px',
    left: '0px',
    width: '250px',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    border: 'solid gray 2px',
  },
  heroBannerImageDropZone: {
    width: '250px',
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
    cursor: 'pointer',
  },
  heroBannerLoadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '115px',
    top: '48%',
  },
  textInputField: {
    padding: '0px',
    margin: '0px',
    '& .MuiInput-root': {
      width: '200px',
    },
  },
  textInputFieldWide: {
    padding: '0px',
    margin: '0px',
    '& .MuiInput-root': {
      width: '400px',
    },
  },
  colourPickerItem: {
    padding: '0px',
    margin: '0px',
    height: '38px',
    width: '250px',
  },
  imageBox: {
    width: '152px',
    height: '152px',
    border: 'solid 2px white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  imageBoxSelected: {
    width: '152px',
    height: '152px',
    border: 'solid 2px black',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
  imageFaded: {
    maxWidth: '150px',
    maxHeight: '150px',
    opacity: '50%',
  },
}))

const CategoryList = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [categories, setCategories] = useState([])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: catData } = await dataProvider.getList('categories', {
          filter: { level: 0 },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        !abortController.signal.aborted && setCategories(catData)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  if (categories?.length === 0)
    return <CircularProgress style={{ width: '20px', height: '20px' }} />

  return (
    <FormWithRedirect
      initialValues={{ categories, selectedGrandparent: 0, selectedCategory: 0 }}
      render={() => <FormData />}
    />
  )
}

const FormData = () => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)
  const [draggingItem, setDraggingItem] = useState(null)
  const [createOpen, setCreateOpen] = useState(false)
  const [createOpen1, setCreateOpen1] = useState(false)
  const [createOpen2, setCreateOpen2] = useState(false)

  const handleDragStart = (e, item) => {
    setDraggingItem(item)
    e.dataTransfer.setData('text/plain', '')
  }

  const handleDragEnd = () => {
    setDraggingItem(null)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e, targetItem, level) => {
    e.preventDefault()
    e.stopPropagation()
    if (!draggingItem) return

    if (level === 2) {
      const currentIndex =
        values.categories[values.selectedGrandparent].children[
          values.selectedCategory
        ].children?.indexOf(draggingItem)
      const targetIndex =
        values.categories[values.selectedGrandparent].children[
          values.selectedCategory
        ].children?.indexOf(targetItem)

      if (currentIndex === -1 || targetIndex === -1) return
      const newCategories0 = [...values.categories]
      const newSubCat = [
        ...newCategories0[values.selectedGrandparent].children[values.selectedCategory].children,
      ]
      newSubCat.splice(currentIndex, 1)
      newSubCat.splice(targetIndex, 0, draggingItem)
      newCategories0[values.selectedGrandparent].children[values.selectedCategory].children =
        newSubCat

      form.change('categories', newCategories0)
    } else if (level === 1) {
      const selectedCatId =
        values.categories[values.selectedGrandparent].children[values.selectedCategory].id
      const currentIndex =
        values.categories[values.selectedGrandparent].children?.indexOf(draggingItem)
      const targetIndex =
        values.categories[values.selectedGrandparent].children?.indexOf(targetItem)

      if (currentIndex === -1 || targetIndex === -1) return
      const newCategories0 = [...values.categories]
      const newCat = [...newCategories0[values.selectedGrandparent].children]
      newCat.splice(currentIndex, 1)
      newCat.splice(targetIndex, 0, draggingItem)
      newCategories0[values.selectedGrandparent].children = newCat
      const newSelectedCat = newCategories0[values.selectedGrandparent].children?.findIndex(
        (el) => el.id === selectedCatId,
      )

      form.batch(() => {
        form.change('categories', newCategories0)
        form.change('selectedCategory', newSelectedCat)
      })
    } else {
      const currentIndex = values.categories.indexOf(draggingItem)
      const targetIndex = values.categories.indexOf(targetItem)
      if (currentIndex === -1 || targetIndex === -1) return

      const newCategories0 = [...values.categories]
      newCategories0.splice(currentIndex, 1)
      newCategories0.splice(targetIndex, 0, draggingItem)

      const newselectedGrandparent = newCategories0?.findIndex(
        (el) => el.id === values.categories[values.selectedGrandparent].id,
      )
      form.batch(() => {
        form.change('categories', newCategories0)
        form.change('selectedGrandparent', newselectedGrandparent)
      })
    }
  }

  const addCategory = (level, selectedGrandparent, selectedCategory) => {
    // console.log(level, selectedGrandparent, selectedCategory)
    if (level === 2) {
      const subCats = [
        ...values.categories[selectedGrandparent].children[selectedCategory].children,
      ]
      subCats.push({
        id: uniqueId('sc'),
        name: values.newCategoryName,
        reference: values.newCategoryReference,
      })

      form.batch(() => {
        form.change(
          `categories[${selectedGrandparent}].children[${selectedCategory}].children`,
          subCats,
        )
        form.change('newCategoryName', '')
        form.change('newCategoryReference', '')
      })
    } else if (level === 1) {
      const cats = [...values.categories[selectedGrandparent].children]
      cats.push({
        id: uniqueId('c'),
        name: values.newCategoryName,
        reference: values.newCategoryReference,
        children: [],
      })
      form.batch(() => {
        form.change(`categories[${selectedGrandparent}].children`, cats)
        form.change('newCategoryName', '')
        form.change('newCategoryReference', '')
      })
    } else {
      const newCategories = [...values.categories]
      newCategories.push({
        id: uniqueId('t'),
        name: values.newCategoryName,
        reference: values.newCategoryReference,
        children: [],
      })
      form.batch(() => {
        form.change('categories', newCategories)
        form.change('newCategoryName', '')
        form.change('newCategoryReference', '')
      })
    }
  }

  // console.log(values)

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" p="30px 20px">
        {saving && (
          <CircularProgress style={{ width: '20px', height: '20px', marginRight: '20px' }} />
        )}
        <Button
          className={classes.confirmButton}
          disabled={saving}
          onClick={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            setSaving(true)

            // console.log(values)

            // const updateCategories = []
            const createCategories = values.categories?.map((grandparent, grandParentIndex) => ({
              active: grandparent.active,
              banner_id: grandparent.banner?.id,
              hero_banner_id: grandparent.hero_banner?.id,
              marketing_image_id: grandparent.marketing_image?.id,
              image_id: grandparent.image?.id,
              level: 0,
              name: grandparent.name,
              parent_id: grandparent.parent_id,
              reference: grandparent.reference,
              sequence: grandParentIndex,
              primary_colour: grandparent.primary_colour,
              description: grandparent.description,
              subcategories: {
                data: grandparent.children?.map((parent, parentIndex) => ({
                  active: parent.active,
                  banner_id: parent.banner?.id,
                  hero_banner_id: parent.hero_banner?.id,
                  image_id: parent.image?.id,
                  level: 1,
                  name: parent.name,
                  reference: parent.reference,
                  sequence: parentIndex,
                  description: parent.description,
                  primary_colour: parent.primary_colour,
                  products: {
                    data: parent.products?.map((el) => ({
                      saleable_product_variant_id:
                        el.product_variant_id || el.saleable_product_variant?.id,
                      type: 'FEATURED',
                    })),
                    on_conflict: {
                      constraint:
                        '^category_products_category_id_saleable_product_variant_id_type_^',
                      update_columns: ['^category_id^', '^saleable_product_variant_id^', '^type^'],
                    },
                  },
                  subcategories: {
                    data: parent?.children?.map((child, childIndex) => ({
                      active: child.active,
                      icon_svg: child.icon_svg,
                      level: 2,
                      name: child.name,
                      primary_colour: child.primary_colour,
                      reference: child.reference,
                      sequence: childIndex,
                    })),
                    on_conflict: {
                      constraint: '^category_level_name_parent_id_key^',
                      update_columns: [
                        '^active^',
                        '^icon_svg^',
                        '^level^',
                        '^name^',
                        '^primary_colour^',
                        '^reference^',
                        '^sequence^',
                      ],
                    },
                  },
                })),
                on_conflict: {
                  constraint: '^category_level_name_parent_id_key^',
                  update_columns: [
                    '^active^',
                    '^banner_id^',
                    '^hero_banner_id^',
                    '^image_id^',
                    '^level^',
                    '^name^',
                    '^reference^',
                    '^primary_colour^',
                    '^sequence^',
                    '^description^',
                  ],
                },
              },
            }))
            try {
              console.log('CREATE CATS', createCategories)
              if (createCategories?.length > 0) {
                await dataProvider.createMany('categories', {
                  objects: createCategories,
                  on_conflict: {
                    constraint: '^category_level_name_parent_id_key^',
                    update_columns: [
                      '^active^',
                      '^banner_id^',
                      '^hero_banner_id^',
                      '^marketing_image_id^',
                      '^image_id^',
                      '^level^',
                      '^primary_colour^',
                      '^name^',
                      '^reference^',
                      '^sequence^',
                      '^description^',
                    ],
                  },
                })
              }

              notify('Upload Complete', { type: 'info' })
              setSaving(false)
            } catch (error) {
              console.log(error)
              notify(error.message.split('.')[0], { type: 'warning' })
              setSaving(false)
            }
          }}
        >
          Commit
        </Button>
      </Box>
      <Box display="flex">
        <Box
          flex={1}
          style={{
            height: `${values.categories.length * 90 + 90}px`,
            margin: '0px 20px',
            position: 'relative',
            overflow: 'auto',
            padding: '10px',
            border: 'solid 1px gray',
            borderRadius: '4px',
          }}
        >
          <Box display="flex" justifyContent="space=between">
            <span>Grandparents</span>
          </Box>
          {values.categories.map((el, index) => (
            <ThemeBox
              key={el.id}
              index={index}
              level={0}
              selectedGrandparent={values.selectedGrandparent}
              selectedCategory={values.selectedCategory}
              isSelected={index === values.selectedGrandparent}
              draggingItem={draggingItem}
              handleDragEnd={handleDragEnd}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              addCategory={addCategory}
            />
          ))}
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="add-quote-price"
              // className={classes.infoBtn}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setCreateOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          {createOpen && (
            <CreateDialog
              createOpen={createOpen}
              setCreateOpen={setCreateOpen}
              selectedGrandparent={values.selectedGrandparent}
              selectedCategory={values.selectedCategory}
              level={0}
              addCategory={addCategory}
            />
          )}
        </Box>
        <Box
          flex={1}
          style={{
            height: `${
              values.categories.length > values.selectedGrandparent &&
              values.categories[values.selectedGrandparent].children?.length * 90 + 90
            }px`,
            margin: '0px 20px',
            position: 'relative',
            overflow: 'auto',
            padding: '10px',
            border: 'solid 1px gray',
            borderRadius: '4px',
          }}
        >
          <Box display="flex" justifyContent="space=between">
            <span>Parents</span>
          </Box>
          {values.categories?.length > values.selectedGrandparent &&
            values.categories[values.selectedGrandparent].children?.map((el, index) => (
              <ThemeBox
                key={el?.id}
                index={index}
                level={1}
                selectedGrandparent={values.selectedGrandparent}
                selectedCategory={values.selectedCategory}
                isSelected={index === values.selectedCategory}
                items={
                  (values.categories?.length > values.selectedGrandparent &&
                    values.categories[values.selectedGrandparent].children) ||
                  []
                }
                // setItems={setItems}
                // setSelectedItem={setSelectedCategory}
                draggingItem={draggingItem}
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
              />
            ))}
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="add-quote-price"
              // className={classes.infoBtn}
              onClick={() => {
                setCreateOpen1(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          {createOpen1 && (
            <CreateDialog
              createOpen={createOpen1}
              setCreateOpen={setCreateOpen1}
              selectedGrandparent={values.selectedGrandparent}
              selectedCategory={values.selectedCategory}
              level={1}
              addCategory={addCategory}
            />
          )}
        </Box>
        <Box
          flex={1}
          style={{
            height: `${
              values.categories?.length > values.selectedGrandparent &&
              values.categories[values.selectedGrandparent].children?.length >
                values.selectedCategory &&
              values.categories[values.selectedGrandparent].children[values.selectedCategory]
                .children?.length *
                90 +
                90
            }px`,
            margin: '0px 20px',
            position: 'relative',
            overflow: 'auto',
            padding: '10px',
            border: 'solid 1px gray',
            borderRadius: '4px',
          }}
        >
          <Box display="flex" justifyContent="space=between">
            <span>Children</span>
          </Box>
          {values.categories?.length > values.selectedGrandparent &&
            values.categories[values.selectedGrandparent].children?.length >
              values.selectedCategory &&
            values.categories[values.selectedGrandparent].children[
              values.selectedCategory
            ].children?.map((el, index) => (
              <ThemeBox
                key={el?.id}
                index={index}
                level={2}
                selectedGrandparent={values.selectedGrandparent}
                selectedCategory={values.selectedCategory}
                items={
                  (values.categories?.length > values.selectedGrandparent &&
                    values.categories[values.selectedGrandparent].children?.length >
                      values.selectedCategory &&
                    values.categories[values.selectedGrandparent].children[values.selectedCategory]
                      .children) ||
                  []
                }
                draggingItem={draggingItem}
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
              />
            ))}
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="add-quote-price"
              // className={classes.infoBtn}
              onClick={() => {
                setCreateOpen2(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          {createOpen2 && (
            <CreateDialog
              createOpen={createOpen2}
              setCreateOpen={setCreateOpen2}
              selectedGrandparent={values.selectedGrandparent}
              selectedCategory={values.selectedCategory}
              level={2}
              addCategory={addCategory}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

const ThemeBox = (props) => {
  const form = useForm()
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const {
    // item,
    index,
    // setSelectedItem,
    draggingItem,
    handleDragEnd,
    handleDragStart,
    handleDragOver,
    handleDrop,
    isSelected,
    selectedGrandparent,
    selectedCategory,
    level = 0,
  } = props

  const { values } = useFormState()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const [editOpen, setEditOpen] = useState(false)
  const item =
    level === 0
      ? values.categories[index]
      : level === 1
      ? values.categories[selectedGrandparent].children[index]
      : values.categories[selectedGrandparent].children[selectedCategory].children[index]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: 'solid 1px black',
        borderRadius: '4px',
        padding: '10px',
        margin: '10px 5px',
        height: '50px',
        cursor: level === 2 ? 'default' : 'pointer',
        backgroundColor: isSelected ? '#E3FFE3' : '#FFF',
      }}
      onClick={() => {
        level === 0
          ? form.change('selectedGrandparent', index)
          : level === 1
          ? form.change('selectedCategory', index)
          : null
        // setSelectedItem && setSelectedItem(index)
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{item?.name}</span>
        <div style={{ display: 'flex' }}>
          <Popper
            open={open}
            placement="bottom"
            anchorEl={anchorEl}
            transition
            style={{ zIndex: 2000 }}
            // disablePortal={false}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Card style={{ padding: '5px' }}>
                  <span>Are you sure?</span>
                  <Box display="flex">
                    <Button
                      onClick={async (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setDeleting(true)
                        if (level === 2) {
                          // Message === ''
                          // Get products
                          // Check if any products,
                          // if so, set message
                          // if not, delete all

                          // Message === 'Products exist'
                          // Delete all
                          const newSubCats = [
                            ...values.categories[values.selectedGrandparent].children[
                              values.selectedCategory
                            ].children,
                          ]
                          // try {
                          const { data: spList } =
                            message !==
                            'There are products associated with this category, are you sure you wish to continue?'
                              ? await dataProvider.getList('saleable_product_categories', {
                                  pagination: { page: 1, perPage: 1 },
                                  // sort: { field: 'name', order: 'ASC' },
                                  filter: { category_id: newSubCats[index].id },
                                })
                              : []
                          const { data: pdList } =
                            message !==
                            'There are products associated with this category, are you sure you wish to continue?'
                              ? await dataProvider.getList('product_categories', {
                                  pagination: { page: 1, perPage: 1 },
                                  // sort: { field: 'name', order: 'ASC' },
                                  filter: { category_id: newSubCats[index].id },
                                })
                              : []

                          console.log(spList?.length, pdList?.length)

                          if (
                            message ===
                              'There are products associated with this category, are you sure you wish to continue?' ||
                            (spList?.length === 0 && pdList?.length === 0)
                          ) {
                            await dataProvider.delete('saleable_product_categories', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newSubCats[index].id } },
                                  { category: { parent_id: { _eq: newSubCats[index].id } } },
                                ],
                              },
                            })

                            await dataProvider.delete('product_categories', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newSubCats[index].id } },
                                  { category: { parent_id: { _eq: newSubCats[index].id } } },
                                ],
                              },
                            })

                            await dataProvider.delete('category_products', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newSubCats[index].id } },
                                  { category: { parent_id: { _eq: newSubCats[index].id } } },
                                ],
                              },

                              // where: { category_id: { _eq: newSubCats[index].id } },
                            })
                            await dataProvider.delete('category_files', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newSubCats[index].id } },
                                  { category: { parent_id: { _eq: newSubCats[index].id } } },
                                ],
                              },
                            })
                            await dataProvider.delete('categories', {
                              where: { parent_id: { _eq: newSubCats[index].id } },
                            })
                            await dataProvider.delete('category', {
                              data: { id: newSubCats[index].id },
                            })
                          } else {
                            setMessage(
                              'There are products associated with this category, are you sure you wish to continue?',
                            )
                            setDeleting(false)
                            return null
                          }

                          newSubCats.splice(index, 1)
                          form.change(
                            `categories[${values.selectedGrandparent}].children[${values.selectedCategory}].children`,
                            newSubCats,
                          )
                        } else if (level === 1) {
                          const newCats = [
                            ...values.categories[values.selectedGrandparent].children,
                          ]
                          if (newCats[index].children?.length > 0) {
                            setMessage('Please delete all child categories first')
                            setDeleting(false)
                            return null
                          }
                          // try {
                          const { data: spList } = await dataProvider.getList(
                            'saleable_product_categories',
                            {
                              pagination: { page: 1, perPage: 1 },
                              // sort: { field: 'name', order: 'ASC' },
                              filter: { category_id: newCats[index].id },
                            },
                          )
                          const { data: pdList } = await dataProvider.getList(
                            'product_categories',
                            {
                              pagination: { page: 1, perPage: 1 },
                              // sort: { field: 'name', order: 'ASC' },
                              filter: { category_id: newCats[index].id },
                            },
                          )

                          console.log(spList.length, pdList.length)

                          if (spList?.length === 0 && pdList?.length === 0) {
                            await dataProvider.delete('category_products', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newCats[index].id } },
                                  { category: { parent_id: { _eq: newCats[index].id } } },
                                ],
                              },

                              // where: { category_id: { _eq: newCats[index].id } },
                            })
                            await dataProvider.delete('category_files', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newCats[index].id } },
                                  { category: { parent_id: { _eq: newCats[index].id } } },
                                ],
                              },
                            })
                            await dataProvider.delete('categories', {
                              where: { parent_id: { _eq: newCats[index].id } },
                            })
                            await dataProvider.delete('category', {
                              data: { id: newCats[index].id },
                            })
                          } else {
                            setMessage(
                              'There are products associated with this category, are you sure you wish to continue?',
                            )
                            setDeleting(false)
                            return null
                          }

                          //   console.log(data)

                          //   // await dataProvider.delete('category', {
                          //   //   data: { id: newSubCats[index].id },
                          //   // })
                          // } catch (error) {
                          //   console.log(error)
                          // }

                          newCats.splice(index, 1)
                          form.change(`categories[${values.selectedGrandparent}].children`, newCats)
                        } else {
                          const newCategories0 = [...values.categories]

                          if (newCategories0[index].children?.length > 0) {
                            setMessage('Please delete all child categories first')
                            setDeleting(false)
                            return null
                          }

                          const { data: spList } = await dataProvider.getList(
                            'saleable_product_categories',
                            {
                              pagination: { page: 1, perPage: 1 },
                              // sort: { field: 'name', order: 'ASC' },
                              filter: { category_id: newCategories0[index].id },
                            },
                          )
                          const { data: pdList } = await dataProvider.getList(
                            'product_categories',
                            {
                              pagination: { page: 1, perPage: 1 },
                              // sort: { field: 'name', order: 'ASC' },
                              filter: { category_id: newCategories0[index].id },
                            },
                          )

                          console.log(spList.length, pdList.length)

                          if (spList?.length === 0 && pdList?.length === 0) {
                            await dataProvider.delete('category_products', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newCategories0[index].id } },
                                  { category: { parent_id: { _eq: newCategories0[index].id } } },
                                ],
                              },
                            })
                            await dataProvider.delete('category_files', {
                              where: {
                                _or: [
                                  { category_id: { _eq: newCategories0[index].id } },
                                  { category: { parent_id: { _eq: newCategories0[index].id } } },
                                ],
                              },
                            })
                            await dataProvider.delete('categories', {
                              where: { parent_id: { _eq: newCategories0[index].id } },
                            })
                            await dataProvider.delete('category', {
                              data: { id: newCategories0[index].id },
                            })
                          } else {
                            setMessage(
                              'There are products associated with this category, are you sure you wish to continue?',
                            )
                            setDeleting(false)
                            return null
                          }

                          newCategories0.splice(index, 1)
                          form.change('categories', newCategories0)
                        }
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        setOpen(false)
                      }}
                    >
                      No
                    </Button>
                  </Box>
                  {message && <span style={{ fontSize: '10px', color: 'red' }}>{message}</span>}
                  {deleting && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                </Card>
              </Fade>
            )}
          </Popper>
          <IconButton
            style={{
              cursor: 'pointer',
              width: '18px',
              height: '24px',
              margin: '0px 3px',
              padding: '0px',
            }}
            onClick={(e) => handleClick(e)}
          >
            <DeleteIcon
              className={classes.iconStyle}
              // style={{ cursor: 'pointer', width: '18px', height: '24px', color: '#232323' }}
            />
          </IconButton>
          <BorderColorIcon
            className={classes.iconStyle}
            // style={{ cursor: 'pointer', width: '18px', height: '24px', margin: '0px 3px' }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setEditOpen(true)
            }}
          />
          <div
            key={item?.id}
            className={`item ${item === draggingItem ? 'dragging' : ''}`}
            draggable="true"
            onDragStart={(e) => handleDragStart(e, item)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, item, level)}
            style={{ display: 'flex', cursor: 'move' }}
          >
            <DragIndicatorIcon />
          </div>
        </div>
      </div>
      <ContentStatusBox level={level} item={item} />
      <EditDialog
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        selectedGrandparent={selectedGrandparent}
        selectedCategory={selectedCategory}
        index={index}
        level={level}
        categoryId={item?.id}
      />
    </div>
  )
}

const CreateDialog = (props) => {
  const { createOpen, setCreateOpen, selectedGrandparent, selectedCategory, level, addCategory } =
    props
  console.log(props)
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={createOpen}
      onClose={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setCreateOpen(false)
      }}
      aria-label="CreateCat"
    >
      <DialogTitle>Create Category</DialogTitle>
      <DialogContent>
        <TextInput variant="standard" source="newCategoryName" label="Name" />
        <TextInput variant="standard" source="newCategoryReference" label="Reference" />

        <Button
          onClick={() => {
            addCategory(level, selectedGrandparent, selectedCategory)
            setCreateOpen(false)
          }}
        >
          Confirm
        </Button>
      </DialogContent>
    </Dialog>
  )
}

const EditDialog = (props) => {
  const { editOpen, index, setEditOpen, selectedGrandparent, selectedCategory, level, categoryId } =
    props
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={editOpen}
      onClose={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setEditOpen(false)
      }}
      aria-label="EditCat"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <span>Edit Category</span>
          <IconButton
            aria-label="closeBtn"
            // className={classes.closeBtn}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setEditOpen(false)
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {level === 0 ? (
          <EditContent
            selectedGrandparent={index}
            level={0}
            categoryId={categoryId}
            content={[
              'active',
              'name',
              'reference',
              'primary_colour',
              'description',
              'top_banner',
              'hero_banner',
              'filter_image',
              'marketing_image',
            ]}
          />
        ) : level === 1 ? (
          <EditContent
            selectedGrandparent={selectedGrandparent}
            selectedCategory={index}
            level={1}
            categoryId={categoryId}
            content={[
              'active',
              'name',
              'reference',
              'primary_colour',
              'description',
              'top_banner',
              'hero_banner',
              'circle_image',
              'featured_products',
            ]}
          />
        ) : (
          <EditContent
            selectedGrandparent={selectedGrandparent}
            selectedCategory={selectedCategory}
            selectedSubCategory={index}
            level={2}
            categoryId={categoryId}
            content={['active', 'name', 'reference', 'primary_colour', 'icon_svg']}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const EditContent = (props) => {
  const {
    selectedGrandparent,
    selectedCategory,
    selectedSubCategory,
    level = 0,
    content = [],
    categoryId,
  } = props
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const { values } = useFormState()
  const classes = useStyles()

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const { data: productsList } = await dataProvider.getList('saleable_product_variants', {
          filter: {
            is_listed: true,
            ...(typeof categoryId === 'number' && { parentCategory: categoryId }),
          },
          exclude: { saleable_product_id: values.id },
          sort: { field: 'id', order: 'desc' },
          pagination: { page: 1, perPage: 100 },
        })
        setProducts(productsList)
        setLoading(false)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        setLoading(false)
      }
    }
    const abortController = new AbortController()
    const loadProducts = content.some((el) => el === 'featured_products')

    !abortController.signal.aborted && loadProducts && getData()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Box display="flex" flexWrap="wrap">
      {content.map((el) => {
        switch (el) {
          case 'name':
          case 'reference':
          case 'icon_svg':
            return (
              <TextInput
                key={`txt${el}`}
                variant="standard"
                className={classes.textInputField}
                source={
                  level === 0
                    ? `categories[${selectedGrandparent}].${el}`
                    : level === 1
                    ? `categories[${selectedGrandparent}].children[${selectedCategory}].${el}`
                    : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].${el}`
                }
                label={el === 'name' ? 'Name' : el === 'reference' ? 'Reference' : 'Icon SVG'}
              />
            )
          case 'description':
            return (
              <TextInput
                key={`des${el}`}
                variant="standard"
                className={classes.textInputFieldWide}
                source={
                  level === 0
                    ? `categories[${selectedGrandparent}].description`
                    : level === 1
                    ? `categories[${selectedGrandparent}].children[${selectedCategory}].description`
                    : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].description`
                }
                label="Description"
              />
            )
          case 'active':
            return (
              <FormControlLabel
                key={`chk${el}`}
                value="active"
                control={
                  <Checkbox
                    checked={
                      level === 0
                        ? values.categories[selectedGrandparent].active
                        : level === 1
                        ? values.categories[selectedGrandparent].children[selectedCategory].active
                        : values.categories[selectedGrandparent].children[selectedCategory]
                            .children[selectedSubCategory].active
                    }
                    onChange={() => {
                      const value =
                        level === 0
                          ? values.categories[selectedGrandparent].active
                          : level === 1
                          ? values.categories[selectedGrandparent].children[selectedCategory].active
                          : values.categories[selectedGrandparent].children[selectedCategory]
                              .children[selectedSubCategory].active
                      const formField =
                        level === 0
                          ? `categories[${selectedGrandparent}].active`
                          : level === 1
                          ? `categories[${selectedGrandparent}].children[${selectedCategory}].active`
                          : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].active`
                      form.change(formField, !value)
                    }}
                    inputProps={{ 'aria-label': 'checkbox category active' }}
                  />
                }
                label="Active"
                labelPlacement="end"
              ></FormControlLabel>
            )
          case 'top_banner':
            return (
              <Box key={`tpb${el}`} flex={1} mt="10px">
                <ImageDropzone
                  title={`Category Top Banner (Aspect Ratio 1:8, recommended 1440 x 180)`}
                  formField={
                    level === 0
                      ? `categories[${selectedGrandparent}].banner`
                      : level === 1
                      ? `categories[${selectedGrandparent}].children[${selectedCategory}].banner`
                      : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].banner`
                  }
                  imageDropZoneClass={classes.bannerImageDropZone}
                  loadingIconClass={classes.bannerLoadingIcon}
                  previewImageBoxClass={classes.bannerPreviewImageBox}
                  preview={
                    level === 0
                      ? values.categories[selectedGrandparent]?.banner?.url || null
                      : level === 1
                      ? values.categories[selectedGrandparent]?.children[selectedCategory]?.banner
                          ?.url || null
                      : values.categories[selectedGrandparent]?.children[selectedCategory]
                          ?.children[selectedSubCategory]?.banner?.url || null
                  }
                />
              </Box>
            )
          case 'hero_banner':
            return (
              <Box key={`hb${el}`} flex={1} mt="10px">
                <ImageDropzone
                  title={`Category Hero Banner, recommended minimum width 2000px)`}
                  formField={
                    level === 0
                      ? `categories[${selectedGrandparent}].hero_banner`
                      : level === 1
                      ? `categories[${selectedGrandparent}].children[${selectedCategory}].hero_banner`
                      : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].hero_banner`
                  }
                  imageDropZoneClass={classes.heroBannerImageDropZone}
                  loadingIconClass={classes.heroBannerLoadingIcon}
                  previewImageBoxClass={classes.heroBannerPreviewImageBox}
                  preview={
                    level === 0
                      ? values.categories[selectedGrandparent]?.hero_banner?.url || null
                      : level === 1
                      ? values.categories[selectedGrandparent]?.children[selectedCategory]
                          ?.hero_banner?.url || null
                      : values.categories[selectedGrandparent]?.children[selectedCategory]
                          ?.children[selectedSubCategory]?.hero_banner?.url || null
                  }
                />
              </Box>
            )
          case 'filter_image':
          case 'circle_image':
            return (
              <Box key={`img${el}`} flex={1} mt="10px">
                <ImageDropzone
                  title={
                    el === 'filter_image'
                      ? `Filter Image (tbc)`
                      : `Category Image (Circular 1:1, transparent background)`
                  }
                  formField={
                    level === 0
                      ? `categories[${selectedGrandparent}].image`
                      : level === 1
                      ? `categories[${selectedGrandparent}].children[${selectedCategory}].image`
                      : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].image`
                  }
                  imageDropZoneClass={classes.imageDropZone}
                  loadingIconClass={classes.loadingIcon}
                  previewImageBoxClass={classes.previewImageBox}
                  preview={
                    level === 0
                      ? values.categories[selectedGrandparent]?.image?.url || null
                      : level === 1
                      ? values.categories[selectedGrandparent]?.children[selectedCategory]?.image
                          ?.url || null
                      : values.categories[selectedGrandparent]?.children[selectedCategory]
                          ?.children[selectedSubCategory]?.image?.url || null
                  }
                />
              </Box>
            )
          case 'primary_colour':
            return (
              <Box style={{ width: '50%', flexBasis: '100%' }} mt="10px" key={`clp${el}`}>
                <ColorInput
                  source={
                    level === 0
                      ? `categories[${selectedGrandparent}].${el}`
                      : level === 1
                      ? `categories[${selectedGrandparent}].children[${selectedCategory}].${el}`
                      : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].${el}`
                  }
                  className={classes.colourPickerItem}
                  picker="Sketch"
                  label="Color"
                  // validate={required()}
                />
              </Box>
            )
          case 'featured_products':
            return (
              <Box
                style={{ flexBasis: '100%' }}
                mt="10px"
                key={`fpp${el}`}
                display="flex"
                flexDirection="column"
              >
                <span>Featured Products</span>
                {loading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                {!loading && products.length === 0 && (
                  <span style={{ marginTop: '10px', fontSize: '12px' }}>
                    No active products found in this category
                  </span>
                )}
                <Box display="flex" flexWrap="wrap" mt="10px" justifyContent="flex-start">
                  {products.map((product) => (
                    <RelatedProduct
                      key={`prd${product.id}`}
                      product={product}
                      grandParentIndex={selectedGrandparent}
                      parentIndex={selectedCategory}
                      selectedProducts={
                        level === 0
                          ? values.categories[selectedGrandparent].products
                          : level === 1
                          ? values.categories[selectedGrandparent].children[selectedCategory]
                              .products
                          : values.categories[selectedGrandparent].children[selectedCategory]
                              .children[selectedSubCategory].products
                      }
                      formField={
                        level === 0
                          ? `categories[${selectedGrandparent}].products`
                          : level === 1
                          ? `categories[${selectedGrandparent}].children[${selectedCategory}].products`
                          : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].products`
                      }
                    />
                  ))}
                </Box>
              </Box>
            )
          case 'marketing_image':
            return (
              <Box key={`tpb${el}`} flex={1} mt="10px">
                <ImageDropzone
                  title={`Category Marketing Image (Aspect Ratio 3:1, recommended 1200 x 400)`}
                  formField={
                    level === 0
                      ? `categories[${selectedGrandparent}].marketing_image`
                      : level === 1
                      ? `categories[${selectedGrandparent}].children[${selectedCategory}].marketing_image`
                      : `categories[${selectedGrandparent}].children[${selectedCategory}].children[${selectedSubCategory}].marketing_image`
                  }
                  imageDropZoneClass={classes.bannerImageDropZone}
                  loadingIconClass={classes.bannerLoadingIcon}
                  previewImageBoxClass={classes.bannerPreviewImageBox}
                  preview={
                    level === 0
                      ? values.categories[selectedGrandparent]?.marketing_image?.url || null
                      : level === 1
                      ? values.categories[selectedGrandparent]?.children[selectedCategory]
                          ?.marketing_image?.url || null
                      : values.categories[selectedGrandparent]?.children[selectedCategory]
                          ?.children[selectedSubCategory]?.marketing_image?.url || null
                  }
                />
              </Box>
            )
          default:
            return null
        }
      })}
    </Box>
  )
}

const ImageDropzone = (props) => {
  const form = useForm()
  const classes = useStyles()
  // const [deleting, setDeleting] = useState(false)
  const [uploading, setUploading] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const { title, formField, imageDropZoneClass, loadingIconClass, previewImageBoxClass, preview } =
    props

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', 'video/*'],
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploading(true)
        const acceptedFile = acceptedFiles[0]
        const file = await uploadFile(acceptedFile)

        const imageResp = await dataProvider.create('file', {
          data: {
            url: file,
            filename: acceptedFile.name,
          },
        })

        form.change(formField, {
          id: imageResp.data.id,
          filename: acceptedFile.name,
          url: file,
        })

        notify('Upload Complete', { type: 'info' })
        setUploading(false)
      }
    },
  })
  return (
    <Box display="flex" m="20px" flexDirection="column">
      <span style={{ fontSize: '12px' }}>{title}</span>

      <Box mr="20px" style={{ position: 'relative' }}>
        <div
          {...getRootProps({
            className: imageDropZoneClass,
          })}
        >
          <input {...getInputProps()} />
          {!uploading && <span>{`Drag / Upload`}</span>}
        </div>

        {uploading && <CircularProgress className={loadingIconClass} />}
        {preview && (
          <div className={previewImageBoxClass}>
            <img src={preview} className={classes.previewImage} alt="" />
            <IconButton
              aria-label="delete"
              className={classes.deleteBtn}
              onClick={() => {
                form.change(formField, null)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </Box>
    </Box>
  )
}

const RelatedProduct = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const { product, formField, grandParentIndex, parentIndex } = props

  return (
    <Box
      m="5px"
      p="5px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      key={`prodImg${product.id}`}
      className={
        values.categories[grandParentIndex].children[parentIndex].products?.some(
          (relProd) => relProd.saleable_product_variant?.id === product.id,
        )
          ? classes.imageBoxSelected
          : classes.imageBox
      }
      onClick={async () => {
        const newFeaturedProducts = [
          ...(values.categories[grandParentIndex].children[parentIndex].products || []),
        ]
        const ind = values.categories[grandParentIndex].children[parentIndex].products?.findIndex(
          (el) => el.saleable_product_variant?.id === product.id,
        )
        if (ind !== -1) {
          try {
            newFeaturedProducts.splice(ind, 1)
            form.change(formField, newFeaturedProducts)
          } catch (error) {
            console.log(error)
          }
        } else {
          newFeaturedProducts.push({
            __typename: 'saleable_product_related_products',
            id: uniqueId('rel'),
            type: 'FEATURED_PRODUCT',
            product_variant_id: product.id,
            saleable_product_variant: product,
          })
          form.change(formField, newFeaturedProducts)
        }
      }}
    >
      <Tooltip title={`${product.name} - ${product.stock || 0}`}>
        <img className={classes.image} src={product.image?.url} alt="" />
      </Tooltip>
    </Box>
  )
}

const ContentStatusBox = (props) => {
  const { item, level } = props
  return (
    <Box display="flex">
      <ContentStatusItem
        fullLabel={`Active: `}
        label="Act"
        status={item?.active}
        value={item?.active}
      />
      <ContentStatusItem
        fullLabel={`Reference: `}
        label="Ref"
        status={!!item?.reference}
        value={item?.reference}
      />
      {(level === 0 || level === 1) && (
        <ContentStatusItem
          fullLabel={`Top Banner: `}
          label="Top"
          status={!!item?.banner?.url}
          value={item?.banner?.url}
        />
      )}
      {(level === 0 || level === 1) && (
        <ContentStatusItem
          fullLabel={`Hero Banner: `}
          label="Hero"
          status={!!item?.hero_banner?.url}
          value={item?.hero_banner?.url}
        />
      )}
      {level === 0 && (
        <ContentStatusItem
          fullLabel={`Filter Image: `}
          label="Fltr"
          status={!!item?.image?.url}
          value={item?.image?.url}
        />
      )}
      {level === 0 && (
        <ContentStatusItem
          fullLabel={`Market Image: `}
          label="Mkt"
          status={!!item?.marketing_image?.url}
          value={item?.marketing_image?.url}
        />
      )}
      {(level === 0 || level === 1) && (
        <ContentStatusItem
          fullLabel={`Description: `}
          label="Desc"
          status={!!item?.description}
          value={item?.description}
        />
      )}
      {level === 1 && (
        <ContentStatusItem
          fullLabel={`Circle Image: `}
          label="Circ"
          status={!!item?.image?.url}
          value={item?.image?.url}
        />
      )}
      {level === 1 && (
        <ContentStatusItem
          fullLabel={`Featured Products: `}
          label="Feat"
          status={item?.products?.length > 4}
          value={item?.products?.length}
        />
      )}
      <ContentStatusItem
        fullLabel={`Primary Color: `}
        label="Col"
        status={!!item?.primary_colour}
        value={item?.primary_colour}
      />
      {level === 2 && (
        <ContentStatusItem
          fullLabel={`Icon: `}
          label="Icon"
          status={!!item?.icon_svg}
          value={item?.icon_svg}
        />
      )}
    </Box>
  )
}

const ContentStatusItem = (props) => {
  // const content=['name', 'reference', 'top_banner', 'hero_banner', 'filter_image']
  // const content1=['name', 'reference', 'top_banner', 'hero_banner', 'circle_image', 'featured_products',]
  // const content2=['name', 'reference', 'primary_colour', 'icon_svg']

  const { fullLabel, label, status, value } = props
  return (
    <Box display="flex">
      <Tooltip title={value ? `${fullLabel}${value}` : `${fullLabel}None`}>
        <div
          style={{
            marginLeft: label === 'Act' ? null : '5px',
            backgroundColor: 'white',
            border: 'solid 1px black',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              zIndex: '200',
              lineHeight: '0px',
              // fontSize: '12px',
              backgroundColor: 'transparent',
            }}
          >
            <span style={{ fontSize: '12px' }}>{label}</span>
          </div>
          {!status && (
            <div
              style={{
                zIndex: '100',
                position: 'absolute',
                width: '30px',
                height: '30px',
                top: '0px',
                left: '0px',
                transform: 'rotate(-45deg)',
                background:
                  'linear-gradient(to bottom, transparent 40%, #DD7878 40%, #DD7878 60%, transparent 60%), linear-gradient(to right, transparent 40%, #DD7878 40%, #DD7878 60%, transparent 60%)',
              }}
            />
          )}
        </div>
      </Tooltip>
    </Box>
  )
}

export default CategoryList
