import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

const saleableProductVariantsGetQuery = `
  id
  active
  always_in_stock
  list_weighting
  image_file_id
  is_listed
  name
  url_slug
  image {
    id
    filename
    url
  }
  retail_price
  saleable_product_id
  saleable_product {
    id
    name
    total_stock
    retail_price
    primary_photo {
      id
      url
      filename
    }
  }
  stock
  variant_id
  variant {
    id
    sku
    name
    values {
      id
      value
      option_name
    }
  }
`

export const saleableProductVariantsGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getSaleableProductVariants {
        saleable_product_variants_by_pk(id: ${params.id}) {
          ${saleableProductVariantsGetQuery}
        }
      }
      `,

    parseResponse: ({ data }) => {
      if (data && data.saleable_product_variants_by_pk) {
        // console.log("data", data)
        return {
          data: data.saleable_product_variants_by_pk,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const saleableProductVariantsList = ({ params, context }) => {
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = {}
  if (params.filter) {
    variables.where = {
      saleable_product: { status: { _eq: 'ACTIVE' } },
      ...(params.exclude?.saleable_product_id && {
        saleable_product_id: { _neq: params.exclude.saleable_product_id },
      }),
      ...(params.filter.saleable_product_id && {
        saleable_product_id: { _eq: params.filter.saleable_product_id },
      }),
      ...(params.filter.is_listed && {
        is_listed: { _eq: true },
      }),
      ...(params.filter.categories && {
        saleable_product: {
          categories: { category_id: { _in: params.filter.categories } },
        },
      }),
      ...(params.filter.brand && {
        saleable_product: {
          brand: { _eq: params.filter.brand },
        },
      }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
        query getSaleableProductVariantsList ($where: saleable_product_variants_bool_exp) {
          saleable_product_variants(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              ${saleableProductVariantsGetQuery}
            }
            saleable_product_variants_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.saleable_product_variants,
      total: data.saleable_product_variants_aggregate.aggregate.count,
    }),
  }
}
