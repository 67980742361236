import React from 'react'
import { Box } from '@material-ui/core'
import BasicBox from './BasicBox'

const MainBox = ({ record }) => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box>{record.id && <BasicBox label="Basic Information" />}</Box>
  </Box>
)

export default MainBox
