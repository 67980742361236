import React from 'react'
import useStyles from '../styles'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Paper,
  Collapse,
  IconButton,
} from '@material-ui/core'
import { useSubscription, gql } from '@apollo/client'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { getDisplayName } from '../../../../utils/utils'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '8px',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
  },
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    fontSize: 12,
  },
}))(TableCell)

const StyledInsetTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    // color: '#000000',
    padding: '8px',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    fontSize: 10,
  },
  body: {
    backgroundColor: '#FFFFFF',
    // color: '#000000',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    fontSize: 10,
  },
}))(TableCell)

const OfferCustomerPurchases = ({ id }) => {
  const classes = useStyles()

  const { data: { saleable_product_sale: customerPruchasesData } = {} } =
    useSubscription(
      gql`subscription getCustomerPurchases {
        saleable_product_sale(order_by: {id: desc_nulls_last} where: {saleable_product: {offer_id: {_eq: ${id}}}}) {
          id
          amount
          courier_fees
          quantity
          status
          created_at
          stripe_payment_intent_id
          seller {
            id
            email
            forename
            surname
            stripe_connected_account_id
            stripe_customer_account_id
          }
          saleable_product_sale_transfers {
            id
            type
            stripe_transfer {
              id
              amount
              status
              stripe_transfer_id
            }
          }
          saleable_product_sale_allocations {
            id
            quantity
            organisation_inventory {
              id
              owner {
                id
                name
                stripe_connected_account_id
                stripe_customer_account_id
              }
            }
          }
          allocated: saleable_product_sale_allocations_aggregate {
            aggregate {
              sum {
                quantity
              }
            }
          }
        }
      }
    `,
      //   { client: apolloClient },
    ) || []

  // console.log(customerPruchasesData)

  const CustomerPurchaseTableRow = (item) => {
    const [open, setOpen] = React.useState(false)
    const [paymentsOpen, setPaymentsOpen] = React.useState(false)

    return (
      <React.Fragment>
        <TableRow>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setPaymentsOpen(!paymentsOpen)}
            >
              {paymentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>{item.row?.id}</StyledTableCell>
          <StyledTableCell>{item.row?.seller?.id}</StyledTableCell>
          <StyledTableCell>{getDisplayName(item.row?.seller, 20, 20, true)}</StyledTableCell>
          <StyledTableCell>{item.row?.quantity}</StyledTableCell>
          <StyledTableCell>
            <NumberFormat
              value={item.row?.amount / 100}
              // className='numberFormat'
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
          </StyledTableCell>
          <StyledTableCell>{item.row?.stripe_payment_intent_id}</StyledTableCell>
          <StyledTableCell>{item.row?.status}</StyledTableCell>
          <StyledTableCell>{item.row?.allocated?.aggregate?.sum?.quantity || 0}</StyledTableCell>
          <StyledTableCell>{moment(item.row?.created_at).format('L')}</StyledTableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <span style={{ fontSize: '10px' }}>Allocated Inventory Data</span>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledInsetTableCell>ID</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Buyer ID</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Buyer</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Quantity</StyledInsetTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.row?.saleable_product_sale_allocations?.map((allocationRow) => (
                      <TableRow key={allocationRow?.id}>
                        <StyledInsetTableCell component="th" scope="row">
                          {allocationRow?.id}
                        </StyledInsetTableCell>
                        <StyledInsetTableCell>
                          {allocationRow?.organisation_inventory?.owner?.id}
                        </StyledInsetTableCell>
                        <StyledInsetTableCell align="right">
                          {allocationRow?.organisation_inventory?.owner.name}
                        </StyledInsetTableCell>
                        <StyledInsetTableCell align="right">
                          {allocationRow?.quantity}
                        </StyledInsetTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={paymentsOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <span style={{ fontSize: '10px' }}>Transfers Data</span>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledInsetTableCell>ID</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Type</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Amount</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Stripe Transfer ID</StyledInsetTableCell>
                      <StyledInsetTableCell align="right">Status</StyledInsetTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.row?.saleable_product_sale_transfers?.map((transfersRow) => (
                      <TableRow key={transfersRow?.id}>
                        <StyledInsetTableCell component="th" scope="row">
                          {transfersRow?.id}
                        </StyledInsetTableCell>
                        <StyledInsetTableCell>{transfersRow?.type}</StyledInsetTableCell>
                        <StyledInsetTableCell align="right">
                          <NumberFormat
                            value={transfersRow?.stripe_transfer?.amount / 100}
                            // className='numberFormat'
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                            renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                          />
                        </StyledInsetTableCell>
                        <StyledInsetTableCell align="right">
                          {transfersRow?.stripe_transfer.stripe_transfer_id}
                        </StyledInsetTableCell>
                        <StyledInsetTableCell align="right">
                          {transfersRow?.stripe_transfer.status}
                        </StyledInsetTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <Box display="flex" flexDirection="column" mt="20px">
      <span className={classes.title}>Offer Customer Purchase Details</span>
      <span className={classes.bodyText}>
        These are all the sales for the current saleable product, and may include other offers.
      </span>
      <Box display="flex" mt="10px">
        <Box flex={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Allocations
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Transfers
                  </StyledTableCell>
                  <StyledTableCell
                    width="6%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    ItemID
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    SellerID
                  </StyledTableCell>
                  <StyledTableCell
                    width="20%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Seller
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Total Paid
                  </StyledTableCell>
                  <StyledTableCell
                    width="16%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Stripe ID
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Status
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Allocated
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Date
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerPruchasesData?.map((item) => (
                  <CustomerPurchaseTableRow key={`customerPurchaseRow${item?.id}`} row={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box flex={2} />
      </Box>
    </Box>
  )
}

export default OfferCustomerPurchases
