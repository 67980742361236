import React, { useState, useEffect } from 'react'
import SinglePhotoFieldInput from '../../components/fieldInputs/SinglePhotoFieldInput'
import {
  Box,
  //   Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { useDataProvider, useNotify, FormWithRedirect, Edit, useRedirect } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
// import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'

const useStyles = makeStyles(() => ({
  editButton: {
    border: 'solid 1px lightblue',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  imageThumb: {
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '5px',
  },
  table: { marginRight: '50px' },
  imageBox: {
    width: '152px',
    height: '152px',
    border: 'solid 2px white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  imageBoxSelected: {
    width: '152px',
    height: '152px',
    border: 'solid 2px black',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
  imageFaded: {
    maxWidth: '150px',
    maxHeight: '150px',
    opacity: '50%',
  },
}))
const BrandsEdit = (props) => (
  <Edit {...props}>
    <BrandsEditForm params={props} />
  </Edit>
)

const BrandsEditForm = (props) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => <FormData formProps={formProps} record={props.record} />}
    />
  )
}

const FormData = () => {
  // const classes = useStyles()
  const { values } = useFormState()
  const redirect = useRedirect()

  return (
    <Box p="30px 30px" maxWidth="250px" display="flex" flexDirection="column">
      <span
        style={{ fontSize: '10px', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => redirect('/brands', 'list')}
      >
        Back to List
      </span>
      <div style={{ height: '20px', width: '100%' }} />
      <span style={{ fontSize: '24px' }}>Edit Brand</span>
      <div style={{ height: '20px', width: '100%' }} />
      <TextFieldInput
        variant="standard"
        fullWidth
        field="name"
        resource="brand"
        id={values.id}
        label="Name:"
        value={values.name}
      />
      <TextFieldInput
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
        field="description"
        resource="brand"
        id={values.id}
        label="Description:"
        value={values.description}
      />
      <Box mt="5px" display="flex" justifyContent="space-between">
        <span style={{ fontSize: '12px' }}>Top banner:</span>
        <SinglePhotoFieldInput
          resourceId={values.id}
          resource="brand"
          source="top_banner"
          fileReference="top_banner_id"
          field="top_banner.url"
        />
      </Box>
      <Box mt="5px" display="flex" justifyContent="space-between">
        <span style={{ fontSize: '12px' }}>Hero banner:</span>
        <SinglePhotoFieldInput
          resourceId={values.id}
          resource="brand"
          source="hero_banner"
          fileReference="hero_banner_id"
          field="hero_banner.url"
        />
      </Box>
      <TextFieldInput
        variant="standard"
        fullWidth
        field="owner_name"
        resource="brand"
        id={values.id}
        label="Owner name:"
        value={values.owner_name}
      />
      <FeaturedProductsSelection />
    </Box>
  )
}

const FeaturedProductsSelection = () => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [editFeaturedProducts, setEditFeaturedProductsOpen] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const { values } = useFormState()

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const { data: productsList } = await dataProvider.getList('saleable_product_variants', {
          filter: { brand: values.name },
          exclude: { saleable_product_id: values.id },
          sort: { field: 'id', order: 'desc' },
          pagination: { page: 1, perPage: 100 },
        })
        setProducts(productsList)
        setLoading(false)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        setLoading(false)
      }
    }
    const abortController = new AbortController()

    editFeaturedProducts && !abortController.signal.aborted && getData()

    return () => {
      abortController.abort()
    }
  }, [editFeaturedProducts])

  //   console.log(values.featured_products)

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span style={{ fontSize: '12px' }}>Featured products:</span>

        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              setEditFeaturedProductsOpen(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" mt="10px" flexWrap="wrap">
        {values.featured_products?.map((relatedProduct) => (
          <Box
            key={`prod${relatedProduct?.saleable_product_variant?.id}`}
            className={classes.imageThumb}
          >
            <img
              src={
                relatedProduct.saleable_product_variant?.image?.url ||
                relatedProduct.saleable_product_variant?.saleable_product?.primary_photo?.url
              }
              alt=""
              className={classes.imageThumb}
            />
          </Box>
        ))}
        <Dialog
          fullWidth
          maxWidth="md"
          open={editFeaturedProducts || false}
          onClose={() => setEditFeaturedProductsOpen(false)}
          aria-label="relatedProductsSelection"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <span>Select Featured Products</span>

              <Box display="flex">
                {loading && (
                  <Box style={{ width: '100%', height: '100%' }}>
                    <CircularProgress
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </Box>
                )}

                <IconButton
                  onClick={() => {
                    setEditFeaturedProductsOpen(false)
                  }}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                >
                  <CancelOutlined fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" minHeight="50vh" flexWrap="wrap" justifyContent="flex-start">
              {products.map((product) => (
                <RelatedProduct key={`prd${product.id}`} product={product} />
              ))}
              {!loading && products?.length === 0 && (
                <span style={{ fontSize: '16px' }}>No products found</span>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  )
}

const RelatedProduct = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const { product } = props

  return (
    <Box
      m="5px"
      p="5px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      key={`prodImg${product.id}`}
      className={
        values.featured_products?.some(
          (relProd) => relProd.saleable_product_variant?.id === product.id,
        )
          ? classes.imageBoxSelected
          : classes.imageBox
      }
      onClick={async () => {
        setSaving(true)
        // console.log(product)
        const featuredProducts = [...(values.featured_products || [])]
        const ind = values.featured_products?.findIndex(
          (el) => el.saleable_product_variant?.id === product.id,
        )
        // console.log(ind)
        if (ind !== -1) {
          try {
            await dataProvider.delete('brand_featured', {
              data: {
                id: values.featured_products[ind].id,
              },
            })
            featuredProducts.splice(ind, 1)
            form.change(`featured_products`, featuredProducts)
          } catch (error) {
            console.log(error)
            setSaving(false)
            notify(error.message, { type: 'warning' })
          }
        } else {
          const resp = await dataProvider.create('brand_featured', {
            data: {
              brand_id: values.id,
              saleable_product_variant_id: product.id,
            },
            on_conflict: {
              constraint: 'brand_featured_brand_id_saleable_product_variant_id_key',
              update_columns: ['brand_id', 'saleable_product_variant_id'],
            },
          })
          featuredProducts.push({
            __typename: 'brand_featured',
            id: resp.data.id,
            saleable_product_variant: product,
          })
          form.change(`featured_products`, featuredProducts)
        }
        setSaving(false)
      }}
    >
      <Tooltip title={`${product.name} - ${product.stock || 0}`}>
        <img
          className={saving ? classes.imageFaded : classes.image}
          src={product.image?.url || product.saleable_product?.primary_photo?.url}
          alt=""
        />
      </Tooltip>
      {saving && (
        <CircularProgress
          style={{
            width: '20px',
            height: '20px',
            zIndex: '100',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      )}
    </Box>
  )
}

export default BrandsEdit
