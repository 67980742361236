// import UserIcon from '@material-ui/icons/AccountBox'
import AppUserIcon from '@material-ui/icons/PermIdentity'
import AppUserList from './AppUserList'
import AppUserEdit from './AppUserEdit'
import AppUserCreate from './AppUserCreate'

export default {
  list: AppUserList,
  create: AppUserCreate,
  edit: AppUserEdit,
  icon: AppUserIcon,
}
