import React from 'react'
import {
  // Filter,
  List,
  // ReferenceInput,
  // SearchInput,
  FunctionField,
  // SelectInput,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  // DateInput,
  // TopToolbar,
  // NullableBooleanInput,
  // useRedirect,
  // useDataProvider,
} from 'react-admin'
// import InfoIcon from '@material-ui/icons/InfoOutlined'
import { Tooltip, withStyles } from '@material-ui/core'
// import SupplierDefaultImg from '../../assets/supplier_default.png'
import { getDisplayName, getPlatformName, getConsumerOrderStatus } from '../../utils/utils'

import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  itemPhotosBox: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
  },
  itemPhoto: {
    position: 'relative',
    border: '2px solid #efefef',
    backgroundColor: '#fff',
    borderRadius: '50%',
    overflow: 'hidden',
    maxWidth: '30px',
    maxHeight: '30px',
    marginLeft: '-55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemPhotoLast: {
    position: 'relative',
    border: '2px solid #efefef',
    backgroundColor: '#fff',
    borderRadius: '50%',
    overflow: 'hidden',
    maxWidth: '30px',
    maxHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemImg: {
    width: '100%',
    display: 'block',
  },
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)
// export const ProductFilter = (props) => (
//   <Filter {...props}>
//     <SearchInput
//       // label="Name/Sku"
//       source="name"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       placeholder="Product Name"
//       style={{ minWidth: '200px' }}
//     />
//     <ReferenceInput
//       source="supplier"
//       reference="suppliers"
//       // sort={{ field: 'id', order: 'ASC' }}
//       alwaysOn
//       style={{ minWidth: '200px' }}
//       variant="standard"
//     >
//       <SelectInput optionText="name" fullWidth />
//     </ReferenceInput>
//     <ReferenceInput
//       source="sca"
//       reference="users"
//       label="SCA"
//       // sort={{ field: 'id', order: 'ASC' }}
//       filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
//       style={{ minWidth: '200px' }}
//       alwaysOn
//       variant="standard"
//     >
//       <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
//     </ReferenceInput>
//     <ReferenceInput
//       source="category"
//       reference="category"
//       // sort={{ field: 'id', order: 'ASC' }}
//       style={{ minWidth: '200px' }}
//       alwaysOn
//       variant="standard"
//     >
//       <SelectInput optionText="name" fullWidth />
//     </ReferenceInput>
//     <DateInput
//       source="date_gte"
//       label="Quote date after"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       style={{ minWidth: '120px' }}
//     />
//     <DateInput
//       source="date_lte"
//       label="Quote date before"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       style={{ minWidth: '120px' }}
//     />
//     <NullableBooleanInput
//       source="base_products"
//       label="Base Products Only"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       style={{ minWidth: '120px' }}
//     />
//   </Filter>
// )

const SaleableProductList = (props) => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const redirect = useRedirect()
  const classes = useStyles()

  // const rowStyle = (record) => ({
  //   backgroundColor: record?.is_catalogue ? '#ddd' : 'white',
  //   cursor: record?.is_catalogue ? 'default' : 'pointer',
  // })

  // console.log(prodData)

  return (
    <List
      {...props}
      // filters={props.basePath === '/products' ? <ProductFilter /> : null}
      // filterDefaultValues={{ base_products: true }}
      actions={null}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
      bulkActionButtons={false}
    >
      <Datagrid
        path="consumerOrders"
        rowClick="edit"
        // rowStyle={rowStyle}
      >
        <TextField source="id" label="Id" />
        {/* <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ width: '50px', height: '50px' }}>
              <img
                style={{ maxHeight: '50px' }}
                src={record.primary_photo?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        /> */}

        <FunctionField
          label="Items"
          render={(record) => (
            <HtmlTooltip
              placement="bottom-start"
              title={
                <div style={{ backgroundColor: '#fff5f5', padding: '5px' }}>
                  <table>
                    <thead>
                      <tr style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.items?.map((el) => (
                        <tr
                          key={el.id}
                          style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}
                        >
                          <td>
                            <img
                              src={el.saleable_product?.primary_photo?.url}
                              alt={el.saleable_product.name}
                              style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '5px' }}
                            />
                          </td>
                          <td style={{ padding: '3px' }}>{el.saleable_product?.name}</td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>{el.quantity}</td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>{`$${(
                            el.amount / 100
                          ).toFixed(2)}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
            >
              <div className={classes.itemPhotosBox}>
                {record.items?.map((el, index) => (
                  <div
                    key={el.id}
                    className={
                      index === record.items?.length - 1 ? classes.itemPhotoLast : classes.itemPhoto
                    }
                  >
                    <img
                      className={classes.itemImg}
                      src={el.saleable_product?.primary_photo?.url}
                      alt={el.saleable_product.name}
                    />
                  </div>
                ))}
              </div>
            </HtmlTooltip>
          )}
        />
        {/* <FunctionField
          label="No. Items"
          render={(record) => (
            <HtmlTooltip
              placement="bottom-start"
              title={
                <div>
                  {record.items?.map((el) => (
                    <div key={el.id} style={{ display: 'flex' }}>
                      <img
                        src={el.saleable_product?.primary_photo?.url}
                        alt={el.saleable_product.name}
                        style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '5px' }}
                      />
                      <span>{`Product: ${el.saleable_product?.name} Quantity: ${
                        el.quantity
                      } - Price: $${(el.amount / 100).toFixed(2)}`}</span>
                    </div>
                  ))}
                </div>
              }
            >
              <span>
                {`${record.items?.length}`} <InfoIcon style={{ fontSize: '14px' }} />
              </span>
            </HtmlTooltip>
          )}
        /> */}
        <NumberField source="total_quantity" label="Total Quantity" />
        <FunctionField
          label="Total Amount"
          render={(record) => `$${(record.sum_amount / 100).toFixed(2)}`}
        />
        <FunctionField label="Buyer" render={(record) => getDisplayName(record.consumer)} />
        <FunctionField label="Selller" render={(record) => getDisplayName(record.seller)} />
        <DateField source="created_at" label="Order Created" />
        <FunctionField
          label="Platform"
          render={(record) => <span>{getPlatformName(record.platform)}</span>}
        />
        <FunctionField
          label="Status"
          render={(record) => <span>{getConsumerOrderStatus(record.status)}</span>}
        />
      </Datagrid>
    </List>
  )
}

export default SaleableProductList
