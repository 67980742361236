import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { useDataProvider, useNotify } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { makeStyles } from '@material-ui/core/styles'
import CostsAndPricing from '../../offers/offer/components/CostsAndPricing'
import OfferBasicDetails from './OfferBasicDetails'
import OfferPurchaseDetails from './OfferPurchaseDetails'
import OfferProcurementDetails from './OfferProcurementDetails'
import POManagement from '../../offers/shared/components/POManagement'

const useStyles = makeStyles(() => ({
  accordionStyle: {
    '& .MuiAccordionSummary-root': {
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
}))

const OfferEditDetails = () => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  // console.log('OFFERVALS', values)
  const [saving, setSaving] = useState(false)

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        border: 'solid 1px #CDCDCD',
        padding: '30px',
        margin: '0px 30px',
        backgroundColor: '#FFF',
        borderRadius: '6px',
      }}
    >
      <Box display="flex" justifyContent="space-between" mb="30px">
        <span>
          Offer Id: {values.id} - Status: {values.status}
        </span>

        <Box display="flex">
          {saving && (
            <CircularProgress
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          )}
          {values.status === 'PENDING_SUBMISSION' && (
            <Button
              style={{
                border: 'solid 1px #CDCDCD',
                borderRadius: '6px',
              }}
              onClick={async () => {
                try {
                  setSaving(true)
                  await dataProvider.update('offer', {
                    data: { id: values.id, status: 'PENDING_PURCHASE_ORDER' },
                  })
                  form.change('status', 'PENDING_PURCHASE_ORDER')
                  setSaving(false)
                } catch (error) {
                  console.log(error)
                  setSaving(false)
                  notify(error.message, { type: 'warning' })
                }
              }}
            >
              Submit
            </Button>
          )}
          {values.status === 'PENDING_APPROVAL' && (
            <Button
              style={{
                border: 'solid 1px #CDCDCD',
                borderRadius: '6px',
              }}
              onClick={async () => {
                try {
                  setSaving(true)
                  await dataProvider.update('offer', {
                    data: { id: values.id, status: 'PRODUCTION' },
                  })
                  form.change('status', 'PRODUCTION')
                  setSaving(false)
                } catch (error) {
                  console.log(error)
                  setSaving(false)
                  notify(error.message, { type: 'warning' })
                }
              }}
            >
              Approve Production
            </Button>
          )}
        </Box>
      </Box>
      <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Variants & Basic Details</Typography>
          {typeof values?.manufacturing_lead_time === 'number' &&
          values?.minimum_purchase_quantity > 0 &&
          values?.platforms?.length > 0 &&
          values?.batches?.length > 0 ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CancelOutlinedIcon style={{ color: 'red' }} />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <OfferBasicDetails />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Costs and Pricing</Typography>
          {typeof values?.bulkCommissionFees === 'number' &&
          typeof values?.bulkPartnerFees === 'number' &&
          typeof values?.bulkRoyaltiesFees === 'number' &&
          typeof values?.bulkSoftwareFees === 'number' &&
          typeof values?.paymentProcessingFees === 'number' &&
          typeof values?.qualityEstimate === 'number' &&
          typeof values?.dropshippingEstimate === 'number' &&
          typeof values?.marketingFees === 'number' &&
          typeof values?.commissionFees === 'number' &&
          typeof values?.consumerSoftwareFees === 'number' &&
          typeof values?.consumerPaymentFees === 'number' &&
          typeof values?.consumerPartnerFees === 'number' &&
          typeof values?.consumerServiceFees === 'number' &&
          typeof values?.consumerRoyaltiesFees === 'number' &&
          typeof values?.supplyChainFees === 'number' &&
          typeof values?.retailPrice === 'number' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CancelOutlinedIcon style={{ color: 'red' }} />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <CostsAndPricing fieldForm />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Offer Procurement Details</Typography>
          {/* {values?.total_sold >= values?.moq ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CancelOutlinedIcon style={{ color: 'red' }} />
          )} */}
        </AccordionSummary>
        <AccordionDetails>
          <OfferProcurementDetails />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Offer Purchase Details</Typography>
          {values?.total_sold >= values?.moq ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CancelOutlinedIcon style={{ color: 'red' }} />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <OfferPurchaseDetails />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Tracking & Progress</Typography>
          {/* {typeof values?.commissionFees === 'number' &&
          typeof values?.marketingFees === 'number' &&
          typeof values?.supplyChainFees === 'number' &&
          typeof values?.retailPrice === 'number' ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CancelOutlinedIcon style={{ color: 'red' }} />
          )} */}
        </AccordionSummary>
        <AccordionDetails>
          <POManagement id={values.id} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default OfferEditDetails
