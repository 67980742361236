import React, { useState } from 'react'
import { Box, Tooltip, CircularProgress } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
// import { uniqueId } from 'lodash'
import { useDropzone } from 'react-dropzone'
import uploadFile from '../../../dataProvider/aws_upload'
// import useStyles from '../styles'

const FileTypeFieldInput = (props) => {
  const form = useForm()
  const { values } = useFormState()
  // const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [uploading, setUploading] = useState(false)

  const {
    resource,
    field,
    referenceField,
    referenceId,
    type,
    onConflict,
    title,
    nameLength = 20,
  } = props

  // console.log(values[field])

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*'],
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        // console.log(acceptedFiles)
        setUploading(true)
        const files = []

        await Promise.all(
          acceptedFiles.map(async (document) => {
            const resp = await uploadFile(document)

            files.push({
              [referenceField]: referenceId,
              ...(type && { type }),
              file: {
                data: { url: resp, filename: document.name },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^id^', '^url^'],
                },
              },
            })
          }),
        )

        setUploading(false)

        // await dataProvider.create(resource, {
        //   data: { id, [field]: [...(values.value || []), ...files] },
        // })

        try {
          const resp = await dataProvider.createMany(resource, {
            objects: files,
            ...(onConflict && onConflict),
          })
          // console.log(resp)

          const abc = values[field]
          // const newVals = [...values, resp.dat ]

          form.change(field, [...abc, ...resp.data])

          setUploading(false)

          notify('Upload Complete', { type: 'info' })
        } catch (error) {
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  return (
    <div>
      <span style={{ fontSize: '16px', marginBottom: '10px' }}>{title}</span>
      <div
        {...getRootProps({
          style: {
            width: '90%',
            maxHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: '0.9',
            cursor: 'pointer',
            border: 'dashed gray 1px',
            marginTop: '20px',
          },
        })}
      >
        <input {...getInputProps()} />

        <span>{`Drag & drop, or click here`}</span>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          margin: '5px 0px 5px',
        }}
        alignItems="baseline"
      >
        {values[field]?.map((item) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            key={`file${item.name || item.file?.filename}${item.id}`}
          >
            <a style={{ lineHeight: '12px' }} href={item.file?.url}>
              {item.file?.filename?.length > nameLength ? (
                <Tooltip title={item.file?.filename}>
                  <span>{`${item.file?.filename?.substring(0, nameLength)}...`}</span>
                </Tooltip>
              ) : (
                item.file?.filename
              )}
            </a>
            <IconButton
              aria-label="delete"
              onClick={async () => {
                setUploading(true)
                const newArray = [...values[field]]
                const index = newArray?.findIndex((obj) => obj.id === item.id)
                newArray.splice(index, 1)

                // console.log(newArray)

                await dataProvider.delete(resource, { id: values[field][index].id })

                form.change(field, newArray)

                notify('Deleted', { type: 'info' })

                setUploading(false)
              }}
            >
              <ClearIcon style={{ fontSize: '14px' }} />
            </IconButton>
          </Box>
        ))}

        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
    </div>
  )
}

export default FileTypeFieldInput
