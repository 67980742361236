import React from 'react'
import FormData from './FormData'
import { FormWithRedirect } from 'react-admin'
import { handleSave, validation } from '../common/utils'

const OfferEditForm = (props) => {
  // console.log('OFFORM', props)

  const { record, setStatus, dataLists, setDataLists } = props

  // const id = parseInt(props.id)

  const trackingMedia = []

  if (record.order_products[0]?.offer_purchase?.offer?.offer_files?.length > 0) {
    record.order_products[0]?.offer_purchase?.offer?.offer_files.map((item) => {
      switch (item.type) {
        case 'TRACKING_MEDIA':
          trackingMedia.push(item.file)
          return null
        default:
          return null
      }
    })
  }

  const factoryPaymentTerms = []
  const qualityPaymentTerms = []
  const logisticsPaymentTerms = []
  const clientPaymentTerms = []
  if (record.order_products[0]?.offer_purchase?.offer?.offer_payment_terms?.length > 0) {
    record.order_products[0]?.offer_purchase?.offer?.offer_payment_terms.map((item) => {
      switch (item.type) {
        case 'FACTORY':
          factoryPaymentTerms.push(item)
          return null
        case 'QUALITY':
          qualityPaymentTerms.push(item)
          return null
        case 'LOGISTICS':
          logisticsPaymentTerms.push(item)
          return null
        case 'CLIENT':
          clientPaymentTerms.push(item)
          return null
        default:
          return null
      }
    })
  }

  // const activeFactoryQuote = record.client_quote?.product_quote?.prices.find(item => item.quantity >= quantity)

  const factoryQuote = {
    factoryQuotePrice:
      (record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.prices
        ?.length > 0 &&
        record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.prices[0]
          .unit_price / 100) ||
      null,
    factoryQuoteQuantity:
      (record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.prices
        ?.length > 0 &&
        record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.prices[0]
          .quantity) ||
      null,
    factoryQuoteLeadTime:
      (record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.prices
        ?.length > 0 &&
        record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.prices[0]
          .lead_time) ||
      null,
    factoryQuoteToolingCost:
      record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.tooling_cost /
        100 || null,
    factoryQuoteSampleCost:
      record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote?.sample_cost /
        100 || null,
    factoryQuoteSampleLeadTime:
      record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote
        ?.sample_lead_time || null,
    factoryQuoteCustomSampleCost:
      record.order_products[0]?.offer_purchase?.offer?.client_quote?.product_quote
        ?.custom_sample_cost / 100 || null,
  }

  const categories = record.order_products[0]?.offer_purchase?.offer?.offer_categories
    ?.filter((item) => !item.deleted_at)
    .map((item) => item.category.id)

  const otherSpecifications = []
  const productColours =
    record.order_products[0]?.offer_purchase?.offer?.offer_specifications?.filter(
      (spec) => spec.specification?.option?.name === 'Colour',
    )
  const productMaterials =
    record.order_products[0]?.offer_purchase?.offer?.offer_specifications?.filter(
      (spec) => spec.specification?.option?.name === 'Material',
    )

  // console.log('OFFERSPECS', record.order_products[0]?.offer_purchase?.offer?.offer_specifications)

  const uniqueSpecs = []

  record.order_products[0]?.offer_purchase?.offer?.offer_specifications?.map((spec) => {
    if (uniqueSpecs.indexOf(spec.specification?.option?.name) === -1) {
      uniqueSpecs.push(spec.specification?.option?.name)
    }
  })

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material' && spec !== 'Imprint') {
      // console.log(spec, record.order_products[0]?.offer_purchase?.offer?.offer_specifications)
      otherSpecifications.push({
        name: spec,
        option_value: {
          value: record.order_products[0]?.offer_purchase?.offer?.offer_specifications
            .filter((qspec) => qspec.specification.option?.name === spec)
            .map((item) => item.specification?.value),
        },
      })
    }
  })

  // console.log(otherSpecifications)

  const variants = record.order_products[0]?.offer_purchase?.items.map((item) => {
    // console.log(item)

    const data = {
      id: item.variant?.id,
      productId: item.variant?.product_id,
      name: item.variant?.name,
      itemId: item.id,
      quantity: item.quantity,
      unitPrice: item.unit_price / 100,
      is_custom_order: item.is_custom_order,
      is_sample_order: item.is_sample_order,
      amount: item.amount / 100,
      incoterms: item.incoterms,
      shippingMethod: item.shipping_method,
    }
    item.variant?.values?.map((value) => {
      data[value.option_value?.name?.toLowerCase() || value.option_name?.toLowerCase()] = {
        id: value.option_value?.id || value.id,
        value: value.option_value?.id || value.value,
        reference_file: value.option_value?.reference_file
          ? (data[`${value.option_value?.name}_referenceFile`] = value.option_value?.reference_file)
          : null,
      }
    })
    return data
  })
  // console.log('variants', variants)
  const poFile = record.order_products[0]?.offer_purchase?.purchase_order_file

  return (
    <FormWithRedirect
      {...props}
      validate={validation}
      // redirect="/customOffer"
      redirect={null}
      initialValues={{
        offerId: record.order_products[0]?.offer_purchase?.offer?.id,
        trackingMedia: trackingMedia,
        productColours: productColours,
        productMaterials: productMaterials,
        otherSpecifications: otherSpecifications,
        factoryPaymentTerms: factoryPaymentTerms,
        qualityPaymentTerms: qualityPaymentTerms,
        logisticsPaymentTerms: logisticsPaymentTerms,
        clientPaymentTerms: clientPaymentTerms,
        categories,
        poFile,
        variants,
        ...factoryQuote,
        ...record.order_products[0]?.offer_purchase?.offer,
      }}
      render={(formProps) => (
        <FormData
          dataLists={dataLists || {}}
          quoteData={{}}
          formProps={formProps}
          handleSave={handleSave}
          recordStatus={record.status}
          showTracking
          setDataLists={setDataLists}
          record={record}
          setStatus={setStatus}
        />
      )}
    />
  )
}

export default OfferEditForm
