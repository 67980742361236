import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { useNotify, useDataProvider, BooleanInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'

const BooleanFieldInput = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [editing, setEditing] = useState(false)
  const { values } = useFormState()

  const { field, resource, id, ...rest } = props

  return (
    <Box display="flex" width="300px">
      {/* <BooleanInput source={field} label="Allow order splitting by sizes?" /> */}

      <BooleanInput
        // variant="standard"
        label={false}
        {...rest}
        fullWidth
        disabled={!editing}
        default={false}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              try {
                await dataProvider.update(resource, {
                  data: { id, [field]: values[field] || false },
                })

                notify('Update Complete', { type: 'info' })
              } catch (error) {
                notify(error.message, { type: 'warning' })
              }
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default BooleanFieldInput
