// import gql from 'graphql-tag'
// import set from 'lodash/set'
import buildVariables from './buildVariables'
import buildGqlQuery from './buildGqlQuery'
// import getResponseParser, { sanitizeResource } from './getResponseParser'
import getResponseParser from './getResponseParser'
import { platformConfig, globalConfigList, configSectionList } from './customQueries/config'
import { sendConsumerOrderShippedEmail } from './customQueries/actions'
import {
  roleList,
  userRoleCreate,
  userRoleGet,
  userRoleList,
  appUserGet,
  appUserList,
  adminUserPreferencesGet,
  appUserStripeList,
  userList,
  userAssignRole,
  userGetMany,
  userGet,
} from './customQueries/users'
import {
  productDebug,
  productCreate,
  productList,
  productDelete,
  productGet,
  productCataloguePaymentIntent,
  getVariantsList,
} from './customQueries/products'
import {
  offerCreate,
  offerList,
  offerDelete,
  offerGet,
  offerPaymentIntent,
  offerStatusUpdate,
  offerCreateTransfer,
  createOfferPurchaseOrder,
  createOfferConsumerOrder,
} from './customQueries/offers'
import { offerPurchaseDelete, offerPurchasesList } from './customQueries/offerPurchases'
import {
  // orderCreate,
  orderList,
  orderDelete,
  orderGet,
} from './customQueries/orders'
import {
  // consumerOrderCreate,
  consumerOrderList,
  consumerOrderDelete,
  consumerOrderGet,
} from './customQueries/consumerOrders'
import {
  sourcingRequestCreate,
  sourcingRequestList,
  sourcingRequestDelete,
  sourcingRequestGet,
} from './customQueries/sourcingRequests'
import {
  // scmQuoteCreate,
  scmQuoteList,
  scmQuoteGet,
  scmQuoteDelete,
  scmQuoteApprove,
  scmQuoteUpdateMany,
  scmQuoteAvailablePackagingOptionsList,
  scmQuoteTotals,
} from './customQueries/scmQuotes'
import {
  organisationInventoryDelete,
  organisationMembersCreate,
  organisationMembersGet,
  organisationMembersList,
  organisationCreate,
  organisationGet,
  organisationList,
} from './customQueries/organisations'
import {
  productQuotePricesDelete,
  productColoursCreateMany,
  productOptionsDeleteMany,
} from './customQueries/productQuote'
import {
  suppliersGet,
  suppliersNameList,
  suppliersList,
  suppliersCreate,
  suppliersUpdate,
  suppliersStripeList,
} from './customQueries/suppliers'
import {
  systemDictAdapterHeadList,
  systemDictAddressTypeList,
  systemDictAnnualRevenueList,
  systemDictConnectionList,
  systemDictCordLengthList,
  systemDictEmployeesList,
  systemDictFactorySizeList,
  systemDictPowerInputList,
  systemDictPowerOutputList,
  systemDictPowerRequirementsList,
  systemDictPowerSourceList,
  systemDictPrimaryMarketList,
  systemDictSellingFeaturesList,
  systemDictSizeList,
  systemDictCertificateTypeList,
  systemDictCertificationTypeList,
  // systemDictCreate,
  systemDictDesignFileTypeList,
  systemDictInspLevelList,
  systemDictInspTypeList,
  systemDictMaterialDelete,
  systemDictMaterialList,
  systemDictMatPkgImpGet,
  systemDictPackagingStyleDelete,
  systemDictPackagingStyleList,
  systemDictSupplierTypeList,
  systemDictUpdate,
  // systemDictGetMany,
} from './customQueries/systemDictionary'
import {
  notificationsCreate,
  notificationsDelete,
  notificationsList,
  notificationsMarkRead,
  notificationsPushCreate,
} from './customQueries/notifications'
import {
  geoCountryList,
  geoCountrySubdivisionsList,
  geoShippingPortsList,
  geoPlatformAddressConfig,
  geoAddressesList,
} from './customQueries/geographical'
import {
  categoriesDeleteMany,
  categoriesGet,
  categoriesList,
  categoriesUpdate,
  categoriesUpdateMany,
  categoriesCreateMany,
  productCategoriesDelete,
  saleableProductCategoriesDelete,
  categoriesFilesDelete,
  categoriesProductsDelete,
  categoriesProductsCreateMany,
  tagsList,
  brandsList,
  brandsGet,
  productOptionsDelete,
  productOptionsList,
  productOptionsCreateMany,
  productCustomisationsDelete,
  productCustomisationsList,
  productCustomisationsCreateMany,
  productFilesCreateMany,
  productSpecificationsDelete,
  productSpecificationsCreateMany,
  productMaterialsUpdateMany,
  variantsUpdateMany,
} from './customQueries/productFields'
import {
  paymentMilestonesList,
  scmQuotePackagingOptionsList,
  updateManyCQPkgs,
  updateManyCQSizes,
  scmQuoteOptionsCreateMany,
} from './customQueries/scmQuoteFields'
import {
  suppliersAddressList,
  suppliersBankDetailUpdate,
  suppliersCategoriesList,
  suppliersCertificationCreate,
  suppliersCertificationDelete,
  suppliersCertificationUpdate,
  suppliersDict,
  suppliersAddressCreate,
  suppliersAddressDelete,
  suppliersAddressUpdate,
  suppliersContactCreate,
  suppliersContactDelete,
  suppliersContactUpdate,
  suppliersFileCreate,
  suppliersFileDelete,
  suppliersRegistrationCheckUpdate,
} from './customQueries/supplierFields'
import {
  sourcingRequestCriteriaCreateMany,
  sourcingRequestReferenceCreateMany,
  sourcingRequestFilesCreateMany,
  sourcingRequestRecommendationCreateMany,
} from './customQueries/sourcingRequestFields'
import {
  offerTrackingEventCreate,
  offerTrackingEventDelete,
  offerTrackingEventList,
  offerFeaturesDelete,
  offerSpecificationsDelete,
  offerCategoriesDelete,
  offerFilesDelete,
} from './customQueries/offerFields'
import {
  thirdPartiesFreightForwarderList,
  thirdPartiesQualityAgencyList,
  thirdPartiesStripeLogisticsProviders,
  thirdPartiesStripeQualityAgencyList,
  thirdPartiesStripeSupplyChainAgenciesList,
} from './customQueries/thirdParties'
import { banksList } from './customQueries/supplierFields'
import {
  saleableProductVariantsGet,
  saleableProductVariantsList,
} from './customQueries/saleableProductVariants'
import {
  saleableProductsPaymentIntent,
  saleableProductAllocationDelete,
  saleableProductsGet,
  saleableProductsList,
  saleableProductDelete,
  saleableProductFilesCreateMany,
  saleableProductVariantsCreateMany,
  saleableProductVariantDeleteMany,
  saleableProductVariantsUpdateMany,
  saleableProductFilesUpdateMany,
  saleableProductBannersUpdateMany,
  saleableProductSpecificationsCreateMany,
} from './customQueries/saleableProducts'
import { invoicePaymentIntent } from './customQueries/invoice'
import {
  projectsGet,
  projectsGetList,
  projectsCreateMany,
  projectReferencesCreateMany,
  projectFilesCreateMany,
  projectDelete,
} from './customQueries/projects'
import { tasksGet, tasksGetList, tasksCreateMany, tasksDelete } from './customQueries/tasks'

export const buildQueryFactory =
  (buildVariablesImpl, buildGqlQueryImpl, getResponseParserImpl) => (introspectionResults) => {
    const knownResources = introspectionResults.resources.map((r) => r.type.name)

    return (aorFetchType, resourceName, params) => {
      const localToken = localStorage.getItem('token')

      // console.log(localToken)
      const token = typeof localToken === 'string' ? localToken : localToken?.access_token

      const context = {
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      }
      const type = aorFetchType
      // console.log('BQ', 'TYPE', type, 'RESOURCENAME', resourceName, 'PARAMS', params)

      if (type === 'GET_LIST' && resourceName === 'user') {
        return userList({ context, params })
      } else if (type === 'assignRoles') {
        return userAssignRole({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'user') {
        return userGet({ context, params })
      } else if (type === 'GET_MANY' && resourceName === 'user') {
        return userGetMany({ context, params })
      } else if (type === 'getAppUsers') {
        return appUserStripeList({ context, params })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'organisation') {
        return organisationList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'organisation') {
        return organisationGet({ context, params, variables })
      } else if (type === 'CREATE' && resourceName === 'organisation') {
        return organisationCreate({ context, params })
      } else if (
        (type === 'GET_LIST' || type === 'GET_MANY') &&
        resourceName === 'organisation_members'
      ) {
        return organisationMembersList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'organisation_members') {
        return organisationMembersGet({ context, params, variables })
      } else if (type === 'CREATE' && resourceName === 'organisation_members') {
        return organisationMembersCreate({ context, params })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'role') {
        return roleList({ context, params })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'user_role') {
        return userRoleList({ context, params })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'address') {
        return geoAddressesList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'user_role') {
        return userRoleGet({ context, params })
      } else if (type === 'CREATE' && resourceName === 'user_role') {
        return userRoleCreate({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'app_user') {
        return appUserList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'app_user') {
        return appUserGet({ context, params, variables })
      } else if (type === 'GET_ONE' && resourceName === 'user_preferences') {
        return adminUserPreferencesGet({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'platform_configuration') {
        return platformConfig({ context, params })
      } else if (type === 'globalConfig') {
        return globalConfigList({ context, params })
      } else if (type === 'action' && resourceName === 'sendConsumerOrderShippedEmail') {
        return sendConsumerOrderShippedEmail({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'supplier_categories') {
        return suppliersCategoriesList({ context, params })
      } else if (type === 'getSupplierAddresses' || resourceName === 'supplierAddress') {
        return suppliersAddressList({ context, params })
      } else if (type === 'CREATE' && resourceName === 'offer') {
        return offerCreate({ params, context })
      } else if (type === 'createProduct') {
        return productCreate({ params, context })
      } else if (type === 'GET_LIST' && resourceName === 'variant') {
        return getVariantsList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'project') {
        return projectsGet({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'project') {
        return projectsGetList({ context, params })
      } else if (type === 'createMany' && resourceName === 'project') {
        return projectsCreateMany({ context, params })
      } else if (type === 'createMany' && resourceName === 'project_references') {
        return projectReferencesCreateMany({ context, params })
      } else if (type === 'createMany' && resourceName === 'project_files') {
        return projectFilesCreateMany({ context, params })
      } else if (type === 'DELETE' && resourceName === 'project') {
        return projectDelete({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'tasks') {
        return tasksGet({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'tasks') {
        return tasksGetList({ context, params })
      } else if (type === 'createMany' && resourceName === 'tasks') {
        return tasksCreateMany({ context, params })
      } else if (type === 'DELETE' && resourceName === 'tasks') {
        return tasksDelete({ context, params })
      } else if (type === 'createTransfer') {
        return offerCreateTransfer({ context, params })
      } else if (type === 'createOfferConsumerOrder') {
        return createOfferConsumerOrder({ context, params })
      } else if (type === 'createOfferPurchaseOrder') {
        return createOfferPurchaseOrder({ context, params })
      } else if (type === 'UPDATE' && resourceName === 'offerStatus') {
        return offerStatusUpdate({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'offer') {
        return offerList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'offer_purchase') {
        return offerPurchasesList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'order') {
        return orderList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'order') {
        return orderGet({ context, params })
      } else if (type === 'DELETE' && resourceName === 'consumer_order') {
        return consumerOrderDelete({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'consumer_order') {
        return consumerOrderGet({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'consumer_order') {
        return consumerOrderList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'saleable_product') {
        return saleableProductsGet({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'saleable_product') {
        return saleableProductsList({ context, params })
      } else if (type === 'createMany' && resourceName === 'saleable_product_files') {
        return saleableProductFilesCreateMany({ context, params })
      } else if (type === 'createMany' && resourceName === 'saleable_product_specifications') {
        return saleableProductSpecificationsCreateMany({ context, params })
      } else if (type === 'createMany' && resourceName === 'saleable_product_variants') {
        return saleableProductVariantsCreateMany({ context, params })
      } else if (type === 'deleteMany' && resourceName === 'saleable_product_variants') {
        return saleableProductVariantDeleteMany({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'saleable_product_variants') {
        return saleableProductVariantsUpdateMany({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'saleable_product_variants') {
        return saleableProductVariantsList({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'product_materials') {
        return productMaterialsUpdateMany({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'saleable_product_files') {
        return saleableProductFilesUpdateMany({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'saleable_product_banners') {
        return saleableProductBannersUpdateMany({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'saleable_product_variants') {
        return saleableProductVariantsGet({ context, params })
      } else if (type === 'DELETE' && resourceName === 'saleable_product') {
        return saleableProductDelete({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'variants') {
        return variantsUpdateMany({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'offer') {
        return offerGet({ context, params })
      } else if (type === 'DELETE' && resourceName === 'offer') {
        return offerDelete({ context, params })
      } else if (type === 'DELETE' && resourceName === 'order') {
        return orderDelete({ context, params })
      } else if (type === 'DELETE' && resourceName === 'product') {
        return productDelete({ params, context })
      } else if (type === 'DELETE' && resourceName === 'sourcing_request') {
        return sourcingRequestDelete({ context, params })
      } else if (type === 'deleteOfferCategories') {
        return offerCategoriesDelete({ context, params })
      } else if (type === 'deleteOfferFeats') {
        return offerFeaturesDelete({ context, params })
      } else if (type === 'deleteOfferSpecs') {
        return offerSpecificationsDelete({ context, params })
      } else if (type === 'deleteOfferFiles') {
        return offerFilesDelete({ context, params })
      } else if (type === 'deleteOfferPurchases') {
        return offerPurchaseDelete({ context, params })
      } else if (type === 'deleteOfferCustomerPurchases') {
        return saleableProductAllocationDelete({ context, params })
      } else if (type === 'deleteOfferEvents') {
        return offerTrackingEventDelete({ context, params })
      } else if (type === 'deleteOfferBuyerInventories') {
        return organisationInventoryDelete({ context, params })
      } else if (type === 'getOfferTrackingDetails') {
        return offerTrackingEventList({ context, params, variables })
      } else if (type === 'CREATE' && resourceName === 'offer_tracking_event') {
        return offerTrackingEventCreate({ context, params, variables })
      } else if (type === 'getPaymentIntent' && resourceName === 'catalogue') {
        return productCataloguePaymentIntent({ context, params })
      } else if (type === 'getPaymentIntent' && resourceName === 'invoice') {
        return invoicePaymentIntent({ context, params })
      } else if (type === 'getPaymentIntent' && resourceName === 'offer') {
        return offerPaymentIntent({ context, params })
      } else if (type === 'getPaymentIntent' && resourceName === 'saleable_product') {
        return saleableProductsPaymentIntent({ context, params })
      } else if (type === 'getStripeSuppliers') {
        return suppliersStripeList({ context, params })
      } else if (type === 'getStripeLogisticsProviders') {
        return thirdPartiesStripeLogisticsProviders({ context, params })
      } else if (type === 'getStripeQualityAgencies') {
        return thirdPartiesStripeQualityAgencyList({ context, params })
      } else if (type === 'getStripeSupplyChainAgencies') {
        return thirdPartiesStripeSupplyChainAgenciesList({ context, params })
      } else if (type === 'approve' && resourceName === 'scm_quote') {
        return scmQuoteApprove({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'sourcing_request') {
        return sourcingRequestGet({ context, params })
      } else if (
        (type === 'GET_LIST' || type === 'GET_MANY') &&
        resourceName === 'sourcing_request'
      ) {
        // console.log(params)
        return sourcingRequestList({ context, params })
      } else if (type === 'CREATE' && resourceName === 'sourcing_request') {
        return sourcingRequestCreate({ context, params, variables })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'scm_quote') {
        return scmQuoteList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'scm_quote') {
        return scmQuoteGet({ context, params })
      } else if (type === 'DELETE' && resourceName === 'scm_quote') {
        return scmQuoteDelete({ context, params })
      } else if (type === 'createSupplier') {
        return suppliersCreate({ context, params })
      } else if (type === 'updateSupplier' && resourceName === 'supplier') {
        return suppliersUpdate({ context, params })
      } else if (type === 'createSupplierFile') {
        return suppliersFileCreate({ context, params })
      } else if (type === 'deleteSupplierFile') {
        return suppliersFileDelete({ context, params })
      } else if (type === 'createSupplierAddress') {
        return suppliersAddressCreate({ context, params })
      } else if (type === 'updateSupplierAddress') {
        return suppliersAddressUpdate({ context, params })
      } else if (type === 'updateSupplierRegistrationCheck') {
        return suppliersRegistrationCheckUpdate({ context, params })
      } else if (type === 'deleteSupplierAddress') {
        return suppliersAddressDelete({ context, params })
      } else if (type === 'createSupplierContact') {
        return suppliersContactCreate({ context, params })
      } else if (type === 'deleteSupplierContact') {
        return suppliersContactDelete({ context, params })
      } else if (type === 'updateSupplierContact') {
        return suppliersContactUpdate({ context, params })
      } else if (type === 'updateSupplierBank') {
        return suppliersBankDetailUpdate({ context, params })
      } else if (type === 'createSupplierCertifications') {
        return suppliersCertificationCreate({ context, params })
      } else if (type === 'deleteSupplierCertification') {
        return suppliersCertificationDelete({ context, params })
      } else if (type === 'updateSupplierCertification') {
        return suppliersCertificationUpdate({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'supplier') {
        return suppliersList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'supplierNames') {
        return suppliersNameList({ context, params })
      } else if (type === 'getSupplierDict') {
        return suppliersDict({ context, params })
      } else if (type === 'getPlatformAddressDetail') {
        return geoPlatformAddressConfig({ context, params })
      } else if (resourceName === 'supplier' && type === 'GET_ONE') {
        return suppliersGet({ context, params })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'product') {
        return productList({ context, params })
      } else if (resourceName === 'product' && type === 'GET_ONE') {
        return productGet({ context, params })
      } else if (type === 'DELETE' && resourceName === 'product_specifications') {
        return productSpecificationsDelete({ context, params })
      } else if (type === 'DELETE' && resourceName === 'product_options') {
        return productOptionsDelete({ context, params })
      } else if (type === 'DELETE' && resourceName === 'product_customisations') {
        return productCustomisationsDelete({ context, params })
      } else if (type === 'DELETE' && resourceName === 'product_quote_price') {
        return productQuotePricesDelete({ context, params })
      } else if (type === 'createMany' && resourceName === 'component_colours') {
        return productColoursCreateMany({ context, params, variables })
      } else if (type === 'DELETE_MANY' && resourceName === 'product_options') {
        return productOptionsDeleteMany({ context, params, variables })
        // } else if (
        //   type === 'createSystemDictionary' ||
        //   (resourceName === 'system_dictionary' && type === 'CREATE')
        // ) {
        //   return systemDictCreate({ context, params })
      } else if (type === 'updateSystemDictionary') {
        return systemDictUpdate({ context, params })
        // } else if (type === 'GET_MANY' && resourceName === 'system_dictionary') {
        //   return systemDictGetMany({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'qualityAgency') {
        return thirdPartiesQualityAgencyList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'freightForwarder') {
        return thirdPartiesFreightForwarderList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'config_section') {
        return configSectionList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'product_options') {
        return productOptionsList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'product_customisations') {
        return productCustomisationsList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'bank') {
        return banksList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'paymentMilestones') {
        return paymentMilestonesList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'scmQuotePackagingOptions') {
        return scmQuotePackagingOptionsList({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'scmQuotePackagingOptions') {
        return updateManyCQPkgs({ context, params })
      } else if (type === 'UPDATE_MANY' && resourceName === 'scmQuoteProductSizes') {
        return updateManyCQSizes({ context, params })
      } else if (type === 'createMany' && resourceName === 'scm_quote_options') {
        return scmQuoteOptionsCreateMany({ context, params, variables })
      } else if (type === 'UPDATE_MANY' && resourceName === 'scm_quote') {
        return scmQuoteUpdateMany({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'scmQuoteAvailablePackagingOptionsList') {
        return scmQuoteAvailablePackagingOptionsList({ context, params })
      } else if (type === 'getTotals' && resourceName === 'scm_quote') {
        return scmQuoteTotals({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'tag') {
        return tagsList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'brand') {
        return brandsGet({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'brand') {
        return brandsList({ context, params })
      } else if ((type === 'GET_LIST' || type === 'GET_MANY') && resourceName === 'category') {
        return categoriesList({ context, params })
      } else if (type === 'GET_ONE' && resourceName === 'category') {
        return categoriesGet({ context, params })
      } else if (type === 'UPDATE' && resourceName === 'category') {
        return categoriesUpdate({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'category') {
        return categoriesCreateMany({ context, params, variables })
      } else if (type === 'UPDATE_MANY' && resourceName === 'category') {
        return categoriesUpdateMany({ context, params, variables })
      } else if (type === 'DELETE' && resourceName === 'categories') {
        return categoriesDeleteMany({ context, params, variables })
      } else if (type === 'DELETE' && resourceName === 'category_files') {
        return categoriesFilesDelete({ context, params, variables })
      } else if (type === 'DELETE' && resourceName === 'category_products') {
        return categoriesProductsDelete({ context, params, variables })
      } else if (type === 'DELETE' && resourceName === 'product_categories') {
        return productCategoriesDelete({ context, params, variables })
      } else if (type === 'DELETE' && resourceName === 'saleable_product_categories') {
        return saleableProductCategoriesDelete({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'categories_products') {
        return categoriesProductsCreateMany({ context, params, variables })
      } else if ((type === 'GET_MANY' || type === 'GET_LIST') && resourceName === 'country') {
        return geoCountryList({ context, params })
      } else if (
        (type === 'GET_LIST' || type === 'GET_MANY') &&
        resourceName === 'country_subdivision'
      ) {
        return geoCountrySubdivisionsList({ context, params })
      } else if (type === 'GET_LIST' && resourceName === 'shipping_port') {
        return geoShippingPortsList({ context, params })
      } else if (resourceName === 'supplier_type' && type === 'GET_LIST') {
        return systemDictSupplierTypeList({ context, params })
      } else if (resourceName === 'material' && type === 'GET_LIST') {
        return systemDictMaterialList({ context, params })
      } else if (
        (resourceName === 'material' ||
          resourceName === 'packagingStyle' ||
          resourceName === 'imprintTechnique') &&
        type === 'GET_ONE'
      ) {
        return systemDictMatPkgImpGet({ context, params, variables })
      } else if (resourceName === 'material' && type === 'DELETE') {
        return systemDictMaterialDelete({ context, params })
      } else if (resourceName === 'certificatesType' && type === 'GET_LIST') {
        return systemDictCertificateTypeList({ context, params })
      } else if (resourceName === 'certificationType' && type === 'GET_LIST') {
        return systemDictCertificationTypeList({ context, params })
      } else if (resourceName === 'designFileType' && type === 'GET_LIST') {
        return systemDictDesignFileTypeList({ context, params })
      } else if (
        resourceName === 'packagingStyle' &&
        (type === 'GET_LIST' || type === 'GET_MANY')
      ) {
        return systemDictPackagingStyleList({ context, params })
      } else if (resourceName === 'packagingStyle' && type === 'DELETE') {
        return systemDictPackagingStyleDelete({ context, params })
      } else if (resourceName === 'inspectionType' && type === 'GET_LIST') {
        return systemDictInspTypeList({ context, params })
      } else if (resourceName === 'inspectionLevel' && type === 'GET_LIST') {
        return systemDictInspLevelList({ context, params })
      } else if (resourceName === 'employees' && type === 'GET_LIST') {
        return systemDictEmployeesList({ context, params })
      } else if (resourceName === 'annualRevenue' && type === 'GET_LIST') {
        return systemDictAnnualRevenueList({ context, params })
      } else if (resourceName === 'factorySize' && type === 'GET_LIST') {
        return systemDictFactorySizeList({ context, params })
      } else if (resourceName === 'primaryMarket' && type === 'GET_LIST') {
        return systemDictPrimaryMarketList({ context, params })
      } else if (resourceName === 'sellingFeatures' && type === 'GET_LIST') {
        return systemDictSellingFeaturesList({ context, params })
      } else if (resourceName === 'powerRequirements' && type === 'GET_LIST') {
        return systemDictPowerRequirementsList({ context, params })
      } else if (resourceName === 'powerInput' && type === 'GET_LIST') {
        return systemDictPowerInputList({ context, params })
      } else if (resourceName === 'powerOutput' && type === 'GET_LIST') {
        return systemDictPowerOutputList({ context, params })
      } else if (resourceName === 'powerSource' && type === 'GET_LIST') {
        return systemDictPowerSourceList({ context, params })
      } else if (resourceName === 'adapterHead' && type === 'GET_LIST') {
        return systemDictAdapterHeadList({ context, params })
      } else if (resourceName === 'cordLength' && type === 'GET_LIST') {
        return systemDictCordLengthList({ context, params })
      } else if (resourceName === 'connection' && type === 'GET_LIST') {
        return systemDictConnectionList({ context, params })
      } else if (resourceName === 'addressType' && (type === 'GET_LIST' || type === 'GET_MANY')) {
        return systemDictAddressTypeList({ context, params })
      } else if (resourceName === 'sizes' && type === 'GET_LIST') {
        return systemDictSizeList({ context, params })
      } else if (resourceName === 'notification' && type === 'GET_LIST') {
        return notificationsList({ context, params })
      } else if (resourceName === 'notification' && type === 'readNotification') {
        return notificationsMarkRead({ context, params })
      } else if (resourceName === 'notification' && type === 'CREATE') {
        return notificationsCreate({ context, params })
      } else if (resourceName === 'push_notification' && type === 'CREATE') {
        return notificationsPushCreate({ context, params })
      } else if (type === 'createMany' && resourceName === 'product_file') {
        return productFilesCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'product_specifications') {
        return productSpecificationsCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'product_customisations') {
        return productCustomisationsCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'sourcing_request_criteria') {
        return sourcingRequestCriteriaCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'sourcing_request_reference') {
        return sourcingRequestReferenceCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'sourcing_request_recommendation') {
        return sourcingRequestRecommendationCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'sourcing_request_file') {
        return sourcingRequestFilesCreateMany({ context, params, variables })
      } else if (type === 'createMany' && resourceName === 'product_options') {
        return productOptionsCreateMany({ context, params, variables })
      } else if (resourceName === 'productDebug' && type === 'GET_LIST') {
        return productDebug({ context, params })
      } else if (resourceName === 'notification' && type === 'deleteNotification') {
        return notificationsDelete({ context, params })
        // } else {
        //   console.log(`Default query type: ', ${type} for ${resourceName}`)
      }
      const resource = introspectionResults.resources.find((r) => r.type.name === resourceName)
      if (!resource) {
        if (knownResources?.length) {
          throw new Error(
            `Unknown resource ${resourceName}. Make sure it has been declared on your server side schema. Known resources are ${knownResources.join(
              ', ',
            )}`,
          )
        } else {
          throw new Error(
            `Unknown resource ${resourceName}. No resources were found. Make sure it has been declared on your server side schema and check if your Authorization header is properly set up.`,
          )
        }
      }
      const queryType = resource[aorFetchType]

      // console.log(resource, aorFetchType)

      if (!queryType) {
        throw new Error(
          `No query or mutation matching fetch type ${aorFetchType} could be found for resource ${resource.type.name}`,
        )
      }

      const variables = buildVariablesImpl(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType,
      )
      const query = buildGqlQueryImpl(introspectionResults)(
        resource,
        aorFetchType,
        queryType,
        variables,
      )
      const parseResponse = getResponseParserImpl(introspectionResults)(
        aorFetchType,
        resource,
        queryType,
      )

      return {
        ...context,
        query,
        variables,
        parseResponse,
      }
    }
  }

export default buildQueryFactory(buildVariables, buildGqlQuery, getResponseParser)
