import React, { useState, useEffect } from 'react'
import {
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useFormState, useForm } from 'react-final-form'
import useStyles from './styles'
import NumberFormat from '../components/CustomNumberFormat'

import { useNotify, useDataProvider, FormWithRedirect } from 'react-admin'

const StyledTableCell = withStyles(() => ({
  head: {
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const FreightRatesCalc = () => {
  const dataProvider = useDataProvider()
  const [data, setData] = useState([])

  useEffect(() => {
    // console.log('calculating...')
    getData()
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  const getData = async () => {
    const resp = await dataProvider.getList('scmQuotePackagingOptions', {})

    setData(resp.data)
  }

  // console.log(data)

  const initData = data.map((item, index) => {
    // calcLCL(item)

    const opt = {
      id: item.id,
      productName: item.scm_quote_packaging_option?.scm_quote?.product_quote?.product?.name,
      quoteId: item.scm_quote_packaging_option?.scm_quote?.id,
      pkgId: item.scm_quote_packaging_option?.id,
      selected: true,
      index,
      cartonDimensions: {
        id: item.scm_quote_packaging_option?.carton_dimensions?.id,
        length: item.scm_quote_packaging_option?.carton_dimensions?.length / 100,
        height: item.scm_quote_packaging_option?.carton_dimensions?.height / 100,
        width: item.scm_quote_packaging_option?.carton_dimensions?.width / 100,
        gross_weight: item.scm_quote_packaging_option?.carton_dimensions?.gross_weight / 100,
      },
      packagingType: item.scm_quote_packaging_option?.type,
      packagingLabel: item.scm_quote_packaging_option?.label,
      sizeName: item.scm_quote_product_size?.name,
      sizeShortName: item.scm_quote_product_size?.short_name,
      sizeSequence: item.scm_quote_product_size?.sequence,
      sizeId: item.scm_quote_product_size?.id,
      pieces_per_carton: item.scm_quote_packaging_option?.pieces_per_carton,
      default: item.scm_quote_packaging_option?.scm_quote.default_packaging_option_id === item.id,
      airLCL: item.scm_quote_packaging_option?.air_lcl / 100,
      seaLCL: item.scm_quote_packaging_option?.sea_lcl / 100,
      airModifier: item.scm_quote_packaging_option?.air_modifier / 100,
      seaModifier: item.scm_quote_packaging_option?.sea_modifier / 100,
      dutyRate: item.scm_quote_packaging_option?.scm_quote?.duty_rate / 100,
      freightRateAir: item.scm_quote_packaging_option?.scm_quote?.freight_rate_air / 100,
      freightRateSea: item.scm_quote_packaging_option?.scm_quote?.freight_rate_sea / 100,
      seaIncludesDuties:
        item.scm_quote_packaging_option?.scm_quote?.does_sea_freight_include_duties,
    }

    return opt
  })

  return (
    <FormWithRedirect initialValues={{ packagingSizes: initData }} render={() => <FormData />} />
  )
}

const FormData = () => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState()

  const form = useForm()
  // console.log(values)

  return (
    <Box>
      {saving ? (
        <CircularProgress style={{ width: '20px', height: '20px' }} />
      ) : (
        <div style={{ width: '20px', height: '20px' }} />
      )}

      <TableContainer component={Paper}>
        <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ fontSize: '12px', padding: '10px', backgroundColor: 'lightgray' }}
                align="center"
                colSpan={8}
              >
                Values
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: '12px', padding: '10px', backgroundColor: 'lightgray' }}
                align="center"
                colSpan={4}
              >
                Existing Values
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: '12px', padding: '10px', backgroundColor: 'lightgray' }}
                align="center"
                colSpan={4}
              >
                Calculated
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  width: '2%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Selected
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '6%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Name
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '2%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                OptId
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '2%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                QuoteId
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '6%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Pkg Type
              </StyledTableCell>
              {/* <StyledTableCell
                style={{
                  width: '4%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Size Sequence
              </StyledTableCell> */}
              <StyledTableCell
                style={{
                  width: '4%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Default
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '4%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Carton Dims
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '4%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Pieces per Carton
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                LCL Sea/Unit
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Sea Modifier
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                LCL Air/Unit
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Air Modifier
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                LCL Sea/Unit
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Sea Modifier
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                LCL Air/Unit
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '7%',
                  fontSize: '10px',
                  padding: '6px',
                  backgroundColor: 'lightgray',
                }}
                align="left"
              >
                Air Modifier
              </StyledTableCell>
              {/* <StyledTableCell
          style={{ width: '10%', fontSize: '10px', padding: '10px' }}
          align="left"
        >
          Actions
        </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {values?.packagingSizes?.map((item, index) => (
              <PackagingSizesRow key={`pkgSzRow${item.id}`} index={index} item={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={() => {
          {
            values?.packagingSizes?.map((item, index) => {
              if (!item.selected) {
                form.change(`packagingSizes[${index}].selected`, true)
              }
            })
          }
        }}
      >
        Select All
      </Button>
      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={() => {
          {
            values?.packagingSizes?.map((item, index) => {
              if (item.selected) {
                form.change(`packagingSizes[${index}].selected`, false)
              }
            })
          }
        }}
      >
        Select None
      </Button>
      {/* <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={async () => {
          setSaving(true)

          const sizeParams = []
          values.packagingSizes.map((el) => {
            if (el.sizeSequence !== 0) {
              sizeParams.push({
                where: { id: { _eq: el.sizeId } },
                _set: { sequence: el.sizeSequence },
              })
            }
          })
          console.log(sizeParams)

          try {
            if (sizeParams.length > 0) {
              await dataProvider.updateMany('scmQuoteProductSizes', { data: sizeParams })
              notify('Saved Successfully.', { type: 'info' })
            }
            setSaving(false)
          } catch (error) {
            setSaving(false)
            notify(error.message.split('.')[0], { type: 'warning' })
          }
        }}
      >
        Save Sequences
      </Button> */}
      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={() => {
          values.packagingSizes.map((item, index) => {
            if (item.selected) {
              const cartonLength = item.cartonDimensions?.length
              const cartonWidth = item.cartonDimensions?.width
              const cartonHeight = item.cartonDimensions?.height
              const grossWeight = item.cartonDimensions?.gross_weight
              const piecesPerCarton = item.pieces_per_carton

              const freightRateAir = item.freightRateAir || 10
              const freightRateSea = item.freightRateSea || 2.65
              const seaIncludesDuties = item.seaIncludesDuties || true
              const dutyRate = item.dutyRate

              const seaWeight =
                parseInt((((cartonLength * cartonWidth * cartonHeight) / 6000) * 100).toFixed(2)) ||
                null

              const chargeableSeaWeight =
                seaWeight / 100 > grossWeight ? seaWeight / 100 : grossWeight

              // console.log('ChargeableSea', chargeableSeaWeight)

              const seaLCL = seaIncludesDuties
                ? (chargeableSeaWeight * freightRateSea) / piecesPerCarton
                : ((chargeableSeaWeight * freightRateSea) / piecesPerCarton) * dutyRate

              form.change(`packagingSizes[${index}].seaLCLCalc`, seaLCL)

              /////////   CHARGEABLE AIR
              const airWeight =
                parseInt((((cartonLength * cartonWidth * cartonHeight) / 5000) * 100).toFixed(2)) ||
                null

              const chargeableAirWeight =
                airWeight / 100 > grossWeight ? airWeight / 100 : grossWeight
              // console.log('ChargeableAir', chargeableAirWeight)

              const airLCL = (chargeableAirWeight * freightRateAir) / piecesPerCarton

              form.change(`packagingSizes[${index}].airLCLCalc`, airLCL)
            }
          })
        }}
      >
        Calc Selected LCLs
      </Button>
      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={() => {
          values.packagingSizes.map((item, index) => {
            // console.log(item)
            if (item.selected) {
              const quotePkgs = values.packagingSizes.filter(
                (el) => el.selected && el.quoteId === item.quoteId,
              )
              // console.log(quotePkgs)

              const lowest =
                quotePkgs?.length > 1
                  ? quotePkgs.reduce((prev, curr) =>
                      prev.seaLCLCalc <= curr.seaLCLCalc && prev.sizeSequence < curr.sizeSequence
                        ? prev
                        : curr,
                    )
                  : quotePkgs[0]

              // console.log(lowest)
              // form.change(`packagingSizes[${index}].airModifierCalc`, airMod)

              // if (index === 0) {
              //   newDefaults.push({ quoteId: item.quoteId, defaultId: lowest.id })
              // }

              if (item.id === lowest.id) {
                form.change(`packagingSizes[${index}].default`, true)
              } else {
                if (item.default) {
                  form.change(`packagingSizes[${index}].default`, false)
                }
              }

              // const airMod = item.airLCLCalc - lowest.airLCLCalc
              // form.change(`packagingSizes[${index}].airModifierCalc`, airMod)
              // const seaMod = item.seaLCLCalc - lowest.seaLCLCalc
              // form.change(`packagingSizes[${index}].seaModifierCalc`, seaMod)
            }
          })
        }}
      >
        Set Defaults (lowest)
      </Button>
      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={() => {
          values.packagingSizes.map((item, index) => {
            // console.log(item)
            if (item.selected) {
              const defaultOption = values.packagingSizes.find(
                (el) => el.default && el.quoteId === item.quoteId,
              )

              // console.log(defaultOption)

              const airMod = item.airLCLCalc - defaultOption.airLCLCalc
              form.change(`packagingSizes[${index}].airModifierCalc`, airMod)
              const seaMod = item.seaLCLCalc - defaultOption.seaLCLCalc
              form.change(`packagingSizes[${index}].seaModifierCalc`, seaMod)
            }
          })
        }}
      >
        Calculate Modifiers
      </Button>
      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={() => {
          {
            values?.packagingSizes?.map((item, index) => {
              if (item.seaLCLCalc || item.seaLCLCalc === 0) {
                form.change(`packagingSizes[${index}].seaLCLCalc`, null)
              }
              if (item.airLCLCalc || item.airLCLCalc === 0) {
                form.change(`packagingSizes[${index}].airLCLCalc`, null)
              }
              if (item.seaModifierCalc || item.seaModifierCalc === 0) {
                form.change(`packagingSizes[${index}].seaModifierCalc`, null)
              }
              if (item.airModifierCalc || item.airModifierCalc === 0) {
                form.change(`packagingSizes[${index}].airModifierCalc`, null)
              }
            })
          }
        }}
      >
        Reset Calculations
      </Button>
      <Button
        style={{ border: 'lightgray solid 1px', maxWidth: '200px' }}
        onClick={async () => {
          setSaving(true)

          // console.log(values.packagingSizes.filter((el) => el.selected))

          const defaultOptions = values.packagingSizes
            .filter((el) => el.default)
            .map((opt) => ({
              where: { id: { _eq: opt.quoteId } },
              _set: { default_packaging_option_id: opt.id },
            }))

          // console.log(defaultOptions)

          try {
            if (defaultOptions.length > 0) {
              await dataProvider.updateMany('scmQuotes', { data: defaultOptions })
            }
          } catch (error) {
            setSaving(false)
            notify(error.message.split('.')[0], { type: 'warning' })
          }

          const pkgParams = []
          values.packagingSizes.map((el) => {
            if (el.airLCLCalc && el.seaLCLCalc && el.airModifierCalc && el.seaModifierCalc)
              pkgParams.push({
                where: {
                  id: { _eq: el.pkgId },
                },
                _set: {
                  air_lcl: Math.round(el.airLCLCalc * 100),
                  sea_lcl: Math.round(el.seaLCLCalc * 100),
                  air_modifier: Math.round(el.airModifierCalc * 100),
                  sea_modifier: Math.round(el.seaModifierCalc * 100),
                },
              })
          })
          // console.log(pkgParams)

          try {
            if (pkgParams.length > 0) {
              await dataProvider.updateMany('scmQuotePackagingOptions', { data: pkgParams })
            }

            notify('Saved Successfully.', { type: 'info' })
            setSaving(false)
          } catch (error) {
            setSaving(false)
            notify(error.message.split('.')[0], { type: 'warning' })
          }
        }}
      >
        Save Selected
      </Button>
    </Box>
  )
}

const PackagingSizesRow = ({ index, item }) => {
  const classes = useStyles()
  const form = useForm()
  // const dataProvider = useDataProvider()

  const { values } = useFormState()

  // console.log(item)

  // const [seaInfoOpen, setSeaInfoOpen] = useState(false)
  // const [infoOpen, setInfoOpen] = useState(false)
  return (
    <TableRow key={`pkgSzRow${index}`}>
      <BodyTableCell
        align="left"
        style={{ padding: '0px 10px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <Checkbox
          checked={item.selected}
          onChange={() => {
            form.change(`packagingSizes[${index}].selected`, !item.selected)
          }}
          inputProps={{ 'aria-label': 'option default checkbox' }}
        />
      </BodyTableCell>
      <BodyTableCell
        align="left"
        style={{ padding: '0px 10px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <span>{item.productName}</span>
      </BodyTableCell>
      <BodyTableCell
        align="left"
        style={{ padding: '0px 10px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <span>{item.id}</span>
      </BodyTableCell>
      <BodyTableCell
        align="left"
        style={{ padding: '0px 10px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <span>{item.quoteId}</span>
      </BodyTableCell>
      <BodyTableCell
        align="left"
        style={{ padding: '0px 10px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <span>
          {item.packagingType}
          {item.packagingLabel ? ` (${item.packagingLabel})` : null}
          {item.sizeName}
          {item.sizeShortName ? ` (${item.sizeShortName})` : null}
        </span>
      </BodyTableCell>
      {/* <BodyTableCell
        align="right"
        style={{ padding: '0px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`packagingSizes[${index}].sizeSequence`}
        />
      </BodyTableCell> */}
      <BodyTableCell
        align="left"
        style={{ padding: '0px 10px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <Checkbox
          checked={item.default}
          onChange={() => {
            const quotePkgs = values.packagingSizes.filter(
              (el) => el.quoteId === item.quoteId && item.id !== el.id,
            )
            form.change(`packagingSizes[${item.index}].default`, true)
            quotePkgs.map((opt) => {
              form.change(`packagingSizes[${opt.index}].default`, false)
            })
          }}
          inputProps={{ 'aria-label': 'option default checkbox' }}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{ padding: '0px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <span>
          {item.cartonDimensions?.length}x{item.cartonDimensions?.width}x
          {item.cartonDimensions?.height}-{item.cartonDimensions?.gross_weight}
          kg
        </span>
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{ padding: '0px', backgroundColor: item.selected ? 'white' : 'lightgray' }}
      >
        <span>{item.pieces_per_carton}</span>
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].seaLCL`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].seaModifier`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].airLCL`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].airModifier`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].seaLCLCalc`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].seaModifierCalc`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].airLCLCalc`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: item.selected ? 'white' : 'lightgray',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`packagingSizes[${index}].airModifierCalc`}
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default FreightRatesCalc
