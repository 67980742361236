import React, { useState, useEffect } from 'react'
import { Box, Chip, Tooltip, Typography, Button, CircularProgress } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
// import VisibilityIcon from '@material-ui/icons/Visibility'
import WarningIcon from '@material-ui/icons/Warning'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import { getRandomString } from '../../../utils/utils'

const HeaderSection = (props) => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const [saving, setSaving] = useState(false)
  const [data, setData] = useState()
  const { id, formErrors = [] } = props

  // console.log(formErrors)

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: prodData } = await dataProvider.getOne('saleable_product', {
          id,
        })
        // console.log(prodData)
        !abortController.signal.aborted && setData(prodData)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })

        return
      }
    }

    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [])

  if (!data) {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="30px !important"
      style={{ backgroundColor: '#FFF' }}
    >
      <Box display="flex" alignItems="flex-start">
        <img
          src={data.primary_photo?.url}
          alt=""
          style={{
            maxWidth: '80px',
            maxHeight: '80px',
            margin: '0px 10px',
            border: 'solid 1px lightgray',
          }}
        />
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Typography variant="h5">{data.name}</Typography>
          <Typography variant="h6">{data.platforms[0].platform}</Typography>
          {/* {data.url_slug ? (
            <a rel="noreferrer" target="_blank" href={`${baseURL}${data.url_slug}`}>
              {data.url_slug}
            </a>
          ) : null} */}
        </Box>
        <span style={{ fontSize: '18px', marginLeft: '20px' }}>
          <Chip label={data.status} />
        </span>
      </Box>
      <Box display="flex">
        {data.status === 'ACTIVE' ? (
          <Button
            style={{ marginRight: '20px', backgroundColor: '#EEBCBC', border: '1px solid #AA5656' }}
            disabled={saving}
            onClick={async () => {
              setSaving(true)
              await dataProvider.update('saleableProduct', {
                data: { id: data.id, status: 'PENDING' },
              })
              const newData = { ...data }
              newData.status = 'PENDING'
              setData(newData)
              setSaving(false)
            }}
          >
            SET INACTIVE
          </Button>
        ) : (
          <Box position="relative">
            <Button
              style={{
                marginRight: '20px',
                backgroundColor: '#BCEEBC',
                border: '1px solid #56AA56',
              }}
              disabled={saving || formErrors.length > 0}
              onClick={async () => {
                setSaving(true)

                const rnd = getRandomString(10)
                const slug = data.name.replace(/[^A-Z0-9]+/gi, '-') + '-' + rnd
                await dataProvider.update('saleableProduct', {
                  data: {
                    id: data.id,
                    status: 'ACTIVE',
                    url_slug: slug,
                  },
                })
                const newData = { ...data }
                newData.status = 'ACTIVE'
                setData(newData)
                setSaving(false)
              }}
            >
              PUBLISH
            </Button>
            {formErrors.length > 0 && (
              <Tooltip
                style={{
                  height: '25px',
                  width: '25px',
                  position: 'absolute',
                  top: '-12px',
                  right: '8px',
                  backgroundColor: '#fff',
                  borderRadius: '6px',
                }}
                title={formErrors?.join(', ')}
              >
                <WarningIcon />
              </Tooltip>
            )}
          </Box>
        )}
        {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        {data.product_quote?.product?.id && (
          <Button
            style={{ border: 'solid 1px black', marginRight: '20px' }}
            onClick={() => redirect(`/products/${data.product_quote?.product?.id}`)}
          >
            Go to base product
          </Button>
        )}
        {/* <Button style={{ border: 'solid 1px black', marginRight: '20px' }}>
          <VisibilityIcon style={{ marginRight: '2px' }} />
          Preview
        </Button> */}
        <Button style={{ border: 'solid 1px black' }}>
          <GetAppIcon style={{ marginRight: '2px' }} />
          Download
        </Button>
      </Box>
    </Box>
  )
}

export default HeaderSection
