import CatalogueProductIcon from '@material-ui/icons/Collections'
import CatalogueProductList from './CatalogueProductList'
// import CatalogueProductEdit from './catalogueproductEdit/CatalogueproductEdit'
import CatalogueProductCreate from './CatalogueProductCreate'
// import CatalogueProductShow from './catalogueproductDetail/CatalogueproductDetail'

export default {
  list: CatalogueProductList,
  create: CatalogueProductCreate,
  //   edit: CatalogueProductEdit,
  icon: CatalogueProductIcon,
  // show: CatalogueProductShow,
}
