import React, { useState, useEffect, cloneElement } from 'react'
import { Card } from '@material-ui/core'
import {
  FormWithRedirect,
  useDataProvider,
  useNotify,
  useCreateController,
  CreateContextProvider,
} from 'react-admin'
import FormData from './components/FormData'
import { handleSave, validation, getDataLists } from './common/utils'

const MyCreate = (props) => {
  const createControllerProps = useCreateController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = createControllerProps
  return (
    <CreateContextProvider value={createControllerProps}>
      <div>
        {/* <h1>{defaultTitle}</h1> */}
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </CreateContextProvider>
  )
}

const Create = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  // const [loading, setLoading] = useState(true)
  const [dataLists, setDataLists] = useState()
  const [id, setID] = useState(null)
  // const [supplierList, setSupplierList] = useState([])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    getDataLists(setDataLists, dataProvider, notify, abortController)
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <MyCreate {...props}>
      <FormWithRedirect
        {...props}
        validate={validation}
        initialValues={{
          clientPaymentTerms: [{}],
          factoryPaymentTerms: [{}],
          qualityPaymentTerms: [{}],
          logisticsPaymentTerms: [{}],
          id,
        }}
        render={(formProps) => (
          <Card>
            <FormData
              dataLists={dataLists || {}}
              setDataLists={setDataLists}
              quoteData={{}}
              formProps={formProps}
              id={id}
              setID={setID}
              handleSave={handleSave}
            />
          </Card>
        )}
      />
    </MyCreate>
  )
}

export default Create
