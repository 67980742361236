import React, { useState, useEffect } from 'react'
import {
  Box,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import {
  required,
  useNotify,
  useDataProvider,
  FormWithRedirect,
  Button,
  DateInput,
  FileInput,
  FileField,
} from 'react-admin'
import { uniqueId } from 'lodash'
import lodashGet from 'lodash/get'
import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import useStyles from '../styles'
import SelectInput from '../../../../../components/input/SelectInput'
import TextInput from '../../../../../components/OutlinedTextInput'
import uploadFile from '../../../../../dataProvider/upload'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#232323',
    color: '#FFFFFF',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRowCell = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '2px',
  },
}))(TableCell)

const StyledTableRowCellRight = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    padding: '2px',
  },
}))(TableCell)

const Certifications = (props) => {
  // console.log(props)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const [editRow, setEditRow] = useState()
  const [newRow, setNewRow] = useState()
  const [certificateTypes, setCertificateTypes] = React.useState()
  const [certificationTypes, setCertificationTypes] = React.useState()

  useEffect(() => {
    const fetchDataProvider = async () => {
      const { data: certificateTypesList } = await dataProvider.getList('certificateType', {})
      setCertificateTypes(certificateTypesList)

      const { data: certificationTypesList } = await dataProvider.getList('certificationType', {})
      setCertificationTypes(certificationTypesList)
    }
    fetchDataProvider()
  }, [dataProvider])

  const createCertification = async (params) => {
    const submission = {
      name: params.name,
      number: params.number,
      notifyingBody: params.notifyingBody,
      type: params.type,
      validEndDate: params.validEndDate,
      validStartDate: params.validStartDate,
      supplier: props.supplierID,
    }

    if (params.document) {
      // console.log('UPLOADING...', params.document)
      const file = await uploadFile([params.document?.rawFile])
      submission.document = {
        url: file.data.files[0].url,
        filename: params.document?.rawFile.name,
      }
      // console.log('UPLOADED', params.document)
    }

    try {
      const { data } = await dataProvider.createSupplierCertifications('supplier', [
        {
          ...submission,
        },
      ])
      if (data.ids !== undefined) {
        refresh()
      }

      setNewRow(null)
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const deleteCertification = async (params) => {
    // console.log("DEL", params)
    try {
      const { data } = await dataProvider.deleteSupplierCertification('supplier', { id: params })
      // console.log('deleteSupplierCertification', data)
      if (data) {
        refresh()
      }
    } catch (error) {
      // console.log('deleteSupplierCertification', error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const updateCertification = async (params, id) => {
    const submission = {
      name: params.name,
      number: params.number,
      notifyingBody: params.notifyingBody,
      type: params.type,
      validEndDate: params.validEndDate,
      validStartDate: params.validStartDate,
      id,
    }

    if (params.document && !params.document.id) {
      // console.log('UPLOADING...', params.document)
      const file = await uploadFile([params.document?.rawFile])
      submission.document = {
        url: file.data.files[0].url,
        filename: params.document?.rawFile.name,
      }

      // console.log('UPLOADED', params.document)
    } else if (params.document && params.document.id) {
      submission.document = {
        id: params.document.id,
        filename: params.document.filename,
        url: params.document.url,
      }
    }

    try {
      const { data } = await dataProvider.updateSupplierCertification('supplier', { ...submission })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  return (
    <Box mt="30px">
      <span className={classes.sectionHeader}>Certifications</span>
      <TableContainer component={Paper} style={{ marginTop: 40 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Preview</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Certification</StyledTableCell>
              <StyledTableCell align="left">Certification Issued By</StyledTableCell>
              <StyledTableCell align="left">Certification Number</StyledTableCell>
              <StyledTableCell align="left">Valid Date</StyledTableCell>
              <StyledTableCell align="left">Verification Link</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.certificates.map((row, index) =>
              editRow === index ? (
                <FormWithRedirect
                  key="formCertCreate"
                  {...props}
                  initialValues={{
                    document: row.document,
                    type: row.type,
                    name: row.name,
                    number: row.number,
                    notifyingBody: row.notifying_body,
                    validStartDate: row.valid_start_date,
                    validEndDate: row.valid_end_date,
                    link: row.link,
                  }}
                  render={() => (
                    <EditableRow
                      key={row.id}
                      data={row}
                      index={index}
                      setEditRow={setEditRow}
                      certifcateTypes={certificateTypes}
                      certificationTypes={certificationTypes}
                      updateCertification={updateCertification}
                    />
                  )}
                />
              ) : (
                <TableRow key={`cert${row.id}`}>
                  <TableCell align="left">
                    <a
                      rel="noopener noreferrer"
                      type="link"
                      target="_blank"
                      href={row.document && row.document?.url ? row.document.url : null}
                    >
                      <AttachFileIcon />
                    </a>
                  </TableCell>
                  <StyledTableRowCell align="left">{row.type}</StyledTableRowCell>
                  <StyledTableRowCell align="left">{row.name}</StyledTableRowCell>
                  <StyledTableRowCell align="left">{row.notifying_body}</StyledTableRowCell>
                  <TableCell align="left">{row.number}</TableCell>
                  <StyledTableRowCell align="left">
                    <Box display="flex" flexDirection="column">
                      <span>{row.valid_start_date} ~</span> <span>{row.valid_end_date}</span>
                    </Box>
                  </StyledTableRowCell>
                  <StyledTableRowCell align="left">{row.verification_link}</StyledTableRowCell>
                  <StyledTableRowCellRight align="left">
                    <Box display="flex">
                      <IconButton
                        onClick={() => {
                          setEditRow(index)
                        }}
                        style={{ maxWidth: '50px', maxHeight: '50px' }}
                      >
                        <BorderColorIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          deleteCertification(row.id)
                        }}
                        style={{ maxWidth: '50px', maxHeight: '50px' }}
                      >
                        <DeleteForeverOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                  </StyledTableRowCellRight>
                </TableRow>
              ),
            )}
            {newRow && (
              <FormWithRedirect
                key="formCertEdit"
                {...props}
                render={() => (
                  <EditableRow
                    key={newRow.id}
                    newCertification
                    setNewRow={setNewRow}
                    certificateTypes={certificateTypes}
                    certificationTypes={certificationTypes}
                    createCertification={createCertification}
                  />
                )}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!newRow && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            className={classes.addNewButton}
            label="Add new"
            onClick={() => setNewRow({ id: uniqueId() })}
          />
        </Box>
      )}
    </Box>
  )
}

const EditableRow = (props) => {
  const classes = useStyles()
  // const { data } = props
  const form = useForm()
  const { values } = useFormState()
  // const [saving, setSaving] = useState(false)

  // <StyledTableCell align='left'>Preview</StyledTableCell>
  // <StyledTableCell align='left'>Type</StyledTableCell>
  // <StyledTableCell align='left'>Certification</StyledTableCell>
  // <StyledTableCell align='left'>Certification Issued By</StyledTableCell>
  // <StyledTableCell align='left'>Certification Number</StyledTableCell>
  // <StyledTableCell align='left'>Valid Date</StyledTableCell>
  // <StyledTableCell align='left'>Verification Link</StyledTableCell>
  // console.log(values)
  return (
    <TableRow>
      <StyledTableRowCell>
        <FileInput
          column
          source="document"
          label="Certificate"
          classes={{ root: classes.hideLabel }}
          addLabel="false"
          accept="application/pdf"
          validate={required()}
          placeholder="Upload File"
        >
          <FileField target="document" source="document" title="certificateFile" />
        </FileInput>
      </StyledTableRowCell>
      <StyledTableRowCell>
        <SelectInput
          label="Type"
          source="type"
          choices={props.certificateTypes}
          validate={required()}
          optionText="name"
          optionValue="value"
          defaultValue=""
          fullWidth
          className={`${classes.areaTextSelect} ${classes.hideLabel}`}
        />
      </StyledTableRowCell>
      <StyledTableRowCell>
        <SelectInput
          label="Certification"
          source="name"
          choices={props.certificationTypes}
          optionText="name"
          optionValue="value"
          defaultValue=""
          fullWidth
          className={`${classes.areaTextSelect} ${classes.hideLabel}`}
        />
      </StyledTableRowCell>
      <StyledTableRowCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          validate={required()}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          source="notifyingBody"
          label="Notifying Body:"
        />
      </StyledTableRowCell>
      <StyledTableRowCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          validate={required()}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          source="number"
          label="Certification Number:"
        />
      </StyledTableRowCell>
      <StyledTableRowCell>
        <Box display="flex" flexDirection="column">
          <DateInput
            variant="outlined"
            fullWidth
            className={`${classes.areaText} ${classes.hideLabel}`}
            InputProps={{
              // maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="validStartDate"
            label="Valid Start Date:"
          />
          <DateInput
            variant="outlined"
            fullWidth
            className={`${classes.areaText} ${classes.hideLabel}`}
            validate={required()}
            InputProps={{
              // maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="validEndDate"
            label="Valid End Date:"
          />
        </Box>
      </StyledTableRowCell>
      <StyledTableCell>
        <TextInput
          variant="outlined"
          fullWidth
          className={`${classes.areaText} ${classes.hideLabel}`}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          disabled
          source="link"
          label="Verification Link:"
        />
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex">
          <IconButton
            onClick={() => {
              // setSaving(true)

              const formState = form.getState()

              if (formState.invalid) {
                form.submit()
              } else {
                if (props.newCertification) {
                  props.createCertification(values, props.index)
                  props.setNewRow(null)
                } else {
                  props.updateCertification(values, props.data?.id)
                  props.setEditRow(null)
                }
              }
              // setSaving(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              if (props.newCertification) {
                props.setNewRow(null)
              } else {
                props.setEditRow(null)
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      </StyledTableCell>
    </TableRow>
  )
}

export default Certifications
