import React from 'react'
import { Box } from '@material-ui/core'
import SinglePhotoFieldInput from '../../components/fieldInputs/SinglePhotoFieldInput'

const ColourImagesSelection = ({ id }) => {
  return (
    <Box>
      <Box>
        <SinglePhotoFieldInput
          resourceId={id}
          resource="saleable_product"
          source="size_chart"
          fileReference="size_chart_file_id"
          field="size_chart.url"
        />
      </Box>
    </Box>
  )
}

export default ColourImagesSelection
