import React, { useState, useEffect } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  withStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDataProvider, FormWithRedirect } from 'react-admin'
import { useSubscription, gql } from '@apollo/client'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import useStyles from '../styles'
import NumberFormat from '../../../components/fieldInputs/NumberFieldInput'
import TextFieldInput from '../../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../../components/fieldInputs/DateFieldInput'
import SelectFieldInput from '../../../components/fieldInputs/SelectFieldInput'
import FileFieldInput from '../../../components/fieldInputs/FileFieldInput'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#1E1E1E',
    color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const POTrackingTable = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [offerTrackingEventsData, setOfferTrackingEventsData] = useState([])
  const [loading, setLoading] = useState(true)
  const [newEvent, setNewEvent] = useState('')
  const [newEventInput, setNewEventInput] = useState('')

  const { id } = props

  const { data: { offer_tracking_event: offerTrackingEventsLiveData } = {} } = useSubscription(
    gql`subscription GetOfferTrackingDetails {
          offer_tracking_event(where: {offer_id: {_eq: ${id}}} order_by: { sequence: asc_nulls_last}) {
            id
            date
            status
            sequence
            type
            event
            confirmed
            created_at
            updated_at
            attachments
            internal_attachments
            offer_id
        }
      },
    `,
  )

  useEffect(() => {
    // console.log(offerTrackingEventsLiveData)
    if (offerTrackingEventsLiveData) {
      setOfferTrackingEventsData(offerTrackingEventsLiveData)
    }
  }, [dataProvider, offerTrackingEventsLiveData])

  useEffect(() => {
    const getData = async () => {
      const res = await dataProvider.getOfferTrackingDetails('getOfferTrackingDetails', {
        id: parseInt(id),
      })
      // console.log('DATA', res)
      setOfferTrackingEventsData(res.data)
      setLoading(false)
    }
    getData()
  }, [dataProvider])

  const getType = (event) => {
    switch (event) {
      case 'Payment - Factory Deposit - Production Start':
      case 'Inspection Results':
      case 'Inspection Approved':
      case 'Payment - Factory Balance - Production Complete':
      case 'Payment - Inspection Company':
      case 'Sample Received from Factory':
      case 'Sample Review':
      case 'Sample Shipped, Tracking #:':
      case 'Sample Approved':
      case 'Inspection Booked':
      case 'Inspection Report':
      case 'Delay - Product Rework Required':
      case 'Delay - Waiting for Approval':
        return 'Production'
      case 'Delay - Customs Clearance':
      case 'Warehouse Receiving Order Submitted':
      case 'Goods Pick-up Successful':
      case 'Port Departure - Bill of Lading':
      case 'Port Arrival':
      case 'Warehouse Arrival':
      case 'Payment - Logistics Company':
        return 'Shipping'
      default:
        return 'Other'
    }
  }

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress style={{ width: '20px', height: '20px' }} />
      </Box>
    )
  }

  const options = [
    'Payment - Factory Deposit - Production Start',
    'Payment - Factory Balance - Production Complete',
    'Sample Received from Factory',
    'Sample Review',
    'Sample Shipped, Tracking #:',
    'Sample Approved',
    'Inspection Booked',
    'Inspection Report',
    'Goods Pick-up Successful',
    'Warehouse Arrival',
    'Delay - Product Rework Required',
    'Delay - Waiting for Approval',
    'Delay - Customs Clearance',
  ]

  return (
    <Box>
      <Box display="flex" p="10px 0px">
        <Autocomplete
          freeSolo
          value={newEvent}
          onChange={(e, v) => {
            setNewEvent(v)
          }}
          inputValue={newEventInput}
          onInputChange={(e, v) => {
            setNewEventInput(v)
          }}
          id="event-input"
          getOptionLabel={(opt) => {
            return opt
          }}
          options={options}
          className={classes.autocompleteFieldWidth}
          renderInput={(params) => <TextField {...params} label="Event" variant="standard" />}
        />
        <Button
          style={{
            height: '40px',
            margin: '20px 10px',
            border: 'black solid 1px',
            borderRadius: '4px',
          }}
          onClick={async () => {
            const sequence =
              offerTrackingEventsData[offerTrackingEventsData.length - 1]?.sequence + 1 ||
              offerTrackingEventsData.length + 1
            await dataProvider.create('offer_tracking_event', {
              object: {
                offer_id: id,
                sequence,
                event: newEventInput,
                type: getType(event),
              },
            })
            setNewEvent('')
            setNewEventInput('')
          }}
        >
          Add new
        </Button>
      </Box>
      <Box display="flex" flexDirection="column">
        <span style={{ fontSize: '10px' }}>Note: you *must* add the following:</span>
        <span style={{ fontSize: '10px' }}>
          Goods Pick-up Successful - will set status to Shipping when confirmed
        </span>
        <span style={{ fontSize: '10px', marginBottom: '20px' }}>
          Warehouse Arrival - will set status to Completed when confirmed
        </span>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ width: '10%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Sequence
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '22%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Event
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '10%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Date
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Internal Files
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Client Files
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '8%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '5%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Confirmed
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '5%', fontSize: '10px', backgroundColor: '#232323' }}
                align="center"
              >
                Delete
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offerTrackingEventsData.map((item) => (
              <TableRow key={item.id}>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <FormWithRedirect
                    {...props}
                    initialValues={{ value: item.sequence }}
                    render={() => (
                      <NumberFormat
                        value={item.sequence}
                        resource="offer_tracking_event"
                        field="sequence"
                        parentId={id}
                        parentField="offer_id"
                        id={item.id}
                        className={classes.sequence}
                        fieldLength={offerTrackingEventsData.length}
                      />
                    )}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <FormWithRedirect
                    {...props}
                    initialValues={{ event: item.event }}
                    render={() => (
                      <TextFieldInput
                        variant="standard"
                        fullWidth
                        field="event"
                        resource="offer_tracking_event"
                        id={item.id}
                        label=""
                        // validate={required()}
                        value={item.event}
                      />
                    )}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <FormWithRedirect
                    {...props}
                    initialValues={{ date: item.date }}
                    render={() => (
                      <DateFieldInput
                        variant="standard"
                        fullWidth
                        field="date"
                        resource="offer_tracking_event"
                        id={item.id}
                        label=""
                        // validate={required()}
                        value={item.date}
                      />
                    )}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <FormWithRedirect
                    {...props}
                    initialValues={{ internal_attachments: item.internal_attachments }}
                    render={() => (
                      <FileFieldInput
                        variant="standard"
                        fullWidth
                        field="internal_attachments"
                        resource="offer_tracking_event"
                        id={item.id}
                        label=""
                        value={item.internal_attachments}
                      />
                    )}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <FormWithRedirect
                    {...props}
                    initialValues={{ attachments: item.attachments }}
                    render={() => (
                      <FileFieldInput
                        variant="standard"
                        fullWidth
                        field="attachments"
                        resource="offer_tracking_event"
                        id={item.id}
                        label=""
                        value={item.attachments}
                      />
                    )}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <FormWithRedirect
                    {...props}
                    initialValues={{ status: item.status }}
                    render={() => (
                      <SelectFieldInput
                        variant="standard"
                        fullWidth
                        field="status"
                        resource="offer_tracking_event"
                        id={item.id}
                        label=""
                        style={{ width: '120px', minWidth: '120px' }}
                        choices={[
                          { name: 'Not Started', id: 'Not Started' },
                          { name: 'In Process', id: 'In Process' },
                          { name: 'Complete', id: 'Complete' },
                        ]}
                        value={item.status}
                      />
                    )}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <Box p="10px">
                    {item.confirmed ? (
                      moment(item.confirmed).format('YYYY-MM-DD')
                    ) : (
                      <IconButton
                        onClick={async () => {
                          const date = moment().toISOString()

                          await dataProvider.update('offer_tracking_event', {
                            data: { id: item.id, confirmed: date },
                          })
                        }}
                      >
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Box>
                </BodyTableCell>
                <BodyTableCell>
                  <IconButton
                    onClick={async () => {
                      await dataProvider.delete('offer_tracking_event', {
                        data: { id: item.id },
                      })
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </BodyTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default POTrackingTable
