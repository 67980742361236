import gql from 'graphql-tag'

const projectsGetQuery = `
    id
    created_at
    updated_at
    project_name
    client_name
    brand_name
    status
    priority
    is_design_required
    is_design_completed
    current_action
    requested_by_name
    product_requirements
    packaging_requirements
    budget_per_piece
    required_by_date
    received_date
    required_quantities
    budget_notes
    update_notes
    quote_notes
    assigned_to
    client_quote_created_by
    client_quote_created_at
    client_quote_project_name
    client_quote_notes
    assignee {
        id
        surname
        forename
        code
        email
        username
    }
    sca_id
    sca {
        id
        surname
        forename
        code
        email
        username
    }
    designFiles: files (where: { type: { _eq: "design" } }, order_by: { id: asc_nulls_last } ) {
        id
        type
        uploaded_by {
            id
            surname
            forename
            code
            email
            username
        }
        file {
          id
          url
          filename
        }
    }
    referencePhotos: files (where: { type: { _eq: "reference_photo" } }, order_by: { id: asc_nulls_last } ) {
        id
        type
        uploaded_by {
            id
            surname
            forename
            code
            email
            username
        }
        file {
          id
          url
          filename
        }
    }
    quoteFiles: files (where: { type: { _eq: "quote" } }, order_by: { id: asc_nulls_last } ) {
        id
        type
        uploaded_by {
            id
            surname
            forename
            code
            email
            username
        }
        file {
          id
          url
          filename
        }
    }
    additionalFiles: files (where: { type: { _nin: ["design", "reference_photo", "quote"] } }, order_by: { id: asc_nulls_last } ) {
        id
        type
        uploaded_by {
            id
            surname
            forename
            code
            email
            username
        }
        file {
          id
          url
          filename
        }
    }
    references (order_by: { id: asc_nulls_last } ) {
        id
        url
        notes
    }
    services (order_by: { id: asc_nulls_last } ) {
        id
        service
    }
    products (order_by: { id: asc_nulls_last } ) {
      id
      created_at
      updated_at
      product_name
      product_notes
      image_id
      image {
        id
        filename
        url
      }
      prices (order_by: { id: asc_nulls_last } ) {
        id
        quantity
        fob_price
        ddp_sea
        ddp_air
        total_lead_time
      }
    }
`

export const projectsGet = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getProject {
        project_by_pk(id: ${params.id}) {
          ${projectsGetQuery}
        }
      }
      `,

    parseResponse: ({ data }) => {
      if (data && data.project_by_pk) {
        return {
          data: data.project_by_pk,
        }
      }
      return false
    },
  }
}

export const projectsGetList = ({ params, context }) => {
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...(params.filter.name && { project_name: { _ilike: `%${params.filter.name}%` } }),
      ...(params.filter.client_name && {
        client_name: { _ilike: `%${params.filter.client_name}%` },
      }),
      ...(params.filter.assigned_to && { assigned_to: { _eq: params.filter.assigned_to } }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
        query getProjects ($where: project_bool_exp) {
          project(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              ${projectsGetQuery}
            }
            project_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.project,
      total: data.project_aggregate.aggregate.count,
    }),
  }
}

export const projectsCreateMany = ({ context, params, variables }) => {
  console.log(params, variables)
  return {
    ...context,
    variables: {
      objects: params.data,
    },
    query: gql`
      mutation projectsCreateMany($objects: [project_insert_input!]!) {
        insert_project(objects: $objects) {
          returning {
            id
          }
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.insert_project?.returning) {
        return {
          data: data.insert_project.returning,
        }
      }
      return false
    },
  }
}

export const projectReferencesCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
          mutation insertManyProjectReference {
            insert_project_references(
              ${jsonParams}
            ) {
              returning {
                id
                url
                notes
                project_id
              }
            }
          }
        `,
    parseResponse: ({ data }) => {
      if (data && data.insert_project_references?.returning) {
        return {
          data: data.insert_project_references.returning,
        }
      }
      return false
    },
  }
}

export const projectFilesCreateMany = ({ context, params, variables }) => {
  console.log(params, variables)
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables,
    query: gql`
        mutation projectFilesCreateMany {
            insert_project_files(
                ${jsonParams}
            ){
                returning {
                    id
                    type
                    project_id
                    file {
                        id
                        filename
                        url
                    }
                }
            }
        }
    `,
    parseResponse: ({ data }) => {
      if (data && data.insert_project_files?.returning) {
        return {
          data: data.insert_project_files.returning,
        }
      }
      return false
    },
  }
}

export const projectDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
            mutation delProject {

              delete_project_files(where: {project_id: {_eq: ${params.id}}}) {
                affected_rows
              }
              delete_project_services(where: {project_id: {_eq: ${params.id}}}) {
                affected_rows
              }
              delete_project_references(where: {project_id: {_eq: ${params.id}}}) {
                affected_rows
              }
              delete_project(where: {id: {_eq: ${params.id}}}) {
                affected_rows
              }
            }
            `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}
