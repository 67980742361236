import React from 'react'
import {
  Datagrid,
  // EditButton,
  List,
  // TextField,
  // ImageField,
  NumberField,
  Pagination,
  // TextInput,
  SearchInput,
  // DateInput,
  //   NumberInput,
  DeleteButton,
  SelectInput,
  FunctionField,
} from 'react-admin'
import { Tooltip, withStyles } from '@material-ui/core'
// import get from 'lodash/get'
import { getDisplayName } from '../../utils/utils'
import NumberFormat from 'react-number-format'
// import SupplierDefaultImg from '../../assets/supplier_default.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  itemPhotosBox: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
  },
  itemPhoto: {
    position: 'relative',
    border: '2px solid #efefef',
    backgroundColor: '#fff',
    borderRadius: '50%',
    overflow: 'hidden',
    maxWidth: '30px',
    maxHeight: '30px',
    // marginLeft: '-55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemPhotoLast: {
    position: 'relative',
    border: '2px solid #efefef',
    backgroundColor: '#fff',
    borderRadius: '50%',
    overflow: 'hidden',
    maxWidth: '30px',
    maxHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemImg: {
    width: '100%',
    display: 'block',
  },
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const OrderPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const ListCom = (props) => {
  const classes = useStyles()

  const orderFilters = [
    // <SearchInput key='searchQ' label="Search" source="q" alwaysOn />,
    <SearchInput
      key="search"
      InputProps={{ placeholder: 'Product SKU / Name' }}
      source="name"
      alwaysOn
      className={classes.searchBackground}
      variant="standard"
    />,
    // <SelectInput options={[{name: }]} key='titleSearch' label="Title" source="title" defaultValue="Hello, World!" />,
    // <SelectInput
    //   key='supplierSearch'
    //   InputProps={{ placeholder: 'Supplier' }}
    //   label='Supplier'
    //   source='supplier'
    //   choices={[{ value: 'SUP1' }, { value: 'SUP2' }]}
    //   optionText='value'
    //   optionValue='value'
    //   helperText='Choose a supplier'
    //   emptyText='Supplier'
    //   className={classes.hideLabelAutoComplete}
    //   fullWidth
    //   variant='outlined'
    //   alwaysOn
    // />,
    <SelectInput
      key="statusSearch"
      InputProps={{ placeholder: 'Status' }}
      label="Status"
      source="status"
      choices={[
        { name: 'Pending Purchase Order', value: 'PENDING_PURCHASE_ORDER' },
        { name: 'Pending Submission', value: 'PENDING_SUBMISSION' },
      ]}
      optionText="name"
      optionValue="value"
      className={classes.hideLabelAutoComplete}
      fullWidth
      variant="standard"
      alwaysOn
    />,
  ]

  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      //   filter={getFilter()}
      filter={{ type: ['PURCHASE_ORDER', 'OFFER_PURCHASE'] }}
      filters={orderFilters}
      actions={null}
      empty={false}
      perPage={50}
      bulkActionButtons={false}
      pagination={<OrderPagination />}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" label="Order ID" />
        <NumberField
          source="order_products[0].offer_purchase.purchase_order_number"
          label="Order #"
        />
        <FunctionField
          label="Items"
          render={(record) => (
            <HtmlTooltip
              placement="bottom-start"
              title={
                <div style={{ backgroundColor: '#fff5f5', padding: '5px' }}>
                  <table>
                    <thead>
                      <tr style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>PO#</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.order_products?.map((prd) => (
                        <tr
                          key={prd.id}
                          style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}
                        >
                          <td>
                            <img
                              src={prd.offer_purchase?.offer?.thumbnail?.url}
                              alt={prd.offer_purchase?.offer.name}
                              style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '5px' }}
                            />
                          </td>
                          <td style={{ padding: '3px' }}>{prd.offer_purchase?.offer?.name}</td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {prd.offer_purchase?.quantity}
                          </td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>{`$${(
                            prd.offer_purchase?.amount / 100
                          ).toFixed(2)}`}</td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {prd.offer_purchase?.purchase_order_number}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
            >
              <div className={classes.itemPhotosBox}>
                {record.order_products?.map((el, index) => (
                  <div
                    key={el.id}
                    className={
                      index === record.items?.length - 1 ? classes.itemPhotoLast : classes.itemPhoto
                    }
                  >
                    <img
                      className={classes.itemImg}
                      src={el.image?.url || el.offer_purchase.offer?.thumbnail?.url}
                      alt={el.name || el.offer_purchase.offer.name}
                    />
                  </div>
                ))}
              </div>
            </HtmlTooltip>
          )}
        />
        {/* <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ maxHeight: '50px', maxWidth: '50px' }}>
              <img
                style={{ maxHeight: '50px' }}
                src={
                  record.order_products[0]?.offer_purchase?.offer?.thumbnail?.url ||
                  SupplierDefaultImg
                }
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        /> */}
        {/* <FunctionField
          label="Name"
          render={(record) => record.order_products[0]?.offer_purchase?.offer?.name}
        /> */}
        {/* <TextField source="itemsCount.aggregate.count" label="# of Items" /> */}
        <FunctionField
          label="Total Amount"
          render={(record) => (
            <NumberFormat
              value={record.sum_amount / 100 || 0}
              className={classes.numberFormat}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              // disabled={retailPriceConfirmed}
              prefix="$"
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
          )}
        />
        {/* <TextField source='salesRatio' label='% Purchased / Sold' /> */}
        <FunctionField label="Client" render={(record) => record.client?.name} />
        <FunctionField
          label="Invoices Outstanding"
          render={(record) => {
            const totalDue =
              record.order_products[0]?.offer_purchase?.offer_purchase_invoices?.reduce(
                (previousValue, currentValue) => {
                  return previousValue + currentValue.invoice.amount_due
                },
                0,
              )
            const totalPaid =
              record.order_products[0]?.offer_purchase?.offer_purchase_invoices?.reduce(
                (previousValue, currentValue) => {
                  return (
                    previousValue +
                    currentValue.invoice.invoice_payments_aggregate.aggregate.sum.amount
                  )
                },
                0,
              )

            return (
              <NumberFormat
                value={(totalDue - totalPaid) / 100 || 0}
                className={classes.numberFormat}
                displayType="text"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                // disabled={retailPriceConfirmed}
                prefix="$"
                renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
              />
            )

            // return `$${(totalDue - totalPaid) / 100 || 0}`
          }}
        />
        {/* <FunctionField
          label="Status"
          render={(record) => record.order_products[0]?.offer_purchase?.offer?.status}
        /> */}
        <FunctionField
          label="Order Status"
          render={(record) => {
            switch (record.status) {
              case 'CONFIRMED':
                return 'Confirmed'
              case 'PENDING_PAYMENT':
                return 'Pending Payment'
              case 'CANCELLED':
                return 'Cancelled'
              case 'IN_PROCESS':
                return 'In Process'
              case 'COMPLETED':
                return 'Completed'
              default:
                return record.status
            }
          }}
        />
        <FunctionField
          label="Offer Status"
          render={(record) => {
            const status =
              record.order_products?.length > 0
                ? record.order_products[0]?.offer_purchase?.offer?.status
                : record.status
            switch (status) {
              case 'PENDING_APPROVAL':
                return 'Pending Approval'
              case 'PRODUCTION':
                return 'Production'
              case 'SHIPPING':
                return 'Shipping'
              case 'PENDING_QUOTE':
                return 'Awaiting Quote'
              case 'CANCELLED':
                return 'Cancelled'
              case 'IN_PROCESS':
                return 'In Process'
              case 'COMPLETED':
                return 'Completed'
              case 'ACTIVE':
                return 'Active'
              case 'PAYMENT_REJECTED':
                return 'Payment Rejected'
              case 'PENDING_PURCHASE_ORDER':
                return 'Awaiting Orders'
              case 'SELLING':
                return 'Payment Rejected'
              case 'PENDING_SUBMISSION':
                return 'Pending Submission'
              default:
                return record.status
            }
          }}
        />
        {/* <TextField source="status" label="Status" /> */}
        <FunctionField
          label="Client SCA"
          render={(record) => getDisplayName(record.client?.default_sca)}
        />
        {/* <EditButton /> */}
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export default ListCom
