import React from 'react'
import { SelectInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.6rem',
    minWidth: '160px',
  },
  rootColumn: {
    flexDirection: 'column',
    fontSize: '0.36rem',
  },
  label: {
    paddingBottom: 15,
    width: 'auto',
    justifyContent: 'flex-start',
  },
}))

const CustomSelectInput = ({ variant = 'outlined', column = false, ...props }) => {
  const classes = useStyles()
  let columnLabel = ''
  let columnRoot = ''
  if (column) {
    columnLabel = classes.label
    columnRoot = classes.rootColumn
  }
  return (
    <SelectInput
      variant={variant}
      className={columnRoot}
      InputLabelProps={{
        className: columnLabel,
      }}
      InputProps={{
        className: classes.root,
      }}
      {...props}
    />
  )
}
CustomSelectInput.defaultProps = SelectInput.defaultProps

export default CustomSelectInput
