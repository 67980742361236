import React from 'react'
import useStyles from '../styles'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Paper,
} from '@material-ui/core'
import { useSubscription, gql } from '@apollo/client'
// import { getDisplayName } from '../../../../utils/utils'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '8px',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
  },
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    fontSize: 12,
  },
}))(TableCell)

const OfferInventories = ({ id }) => {
  const classes = useStyles()

  const OwnerInventory = (item) => (
    <TableRow>
      <StyledTableCell>{item.row?.id}</StyledTableCell>
      <StyledTableCell>{item.row?.owner?.id}</StyledTableCell>
      <StyledTableCell>{item.row?.owner?.name}</StyledTableCell>
      <StyledTableCell>{item.row?.stock}</StyledTableCell>
      {/* <StyledTableCell>
        <Button
          onClick={() =>
            history.push({
              pathname: '/customerPurchase',
              search: `id=${item.row?.offer?.saleable_product_id}&seller=${item.row?.owner?.id}`,
            })
          }
        >
          {item.row?.offer?.saleable_product_id}
        </Button>
      </StyledTableCell> */}
    </TableRow>
  )

  //   console.log('OFFERINVCLIENT', apolloClient)

  const { data: { organisation_inventory: inventoryData } = {} } =
    useSubscription(
      gql`subscription GetOfferInventoryData {
        organisation_inventory(where: {offer_id: {_eq: ${id}}}) {
          id
          stock
          offer_id
          offer {
            id
            saleable_products {
              id
            }
          }
          owner {
            id
            name
            stripe_connected_account_id
            stripe_customer_account_id
          }
        }
      }
    `,
      //   { client: apolloClient },
    ) || []

  // console.log(inventoryData)

  return (
    <Box display="flex" flexDirection="column" mt="20px" p="20px">
      <span className={classes.title}>Offer Inventory Details</span>
      <Box display="flex" mt="10px">
        <Box flex={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    width="10%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    ID
                  </StyledTableCell>
                  <StyledTableCell
                    width="10%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    OwnerId
                  </StyledTableCell>
                  <StyledTableCell
                    width="70%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Name
                  </StyledTableCell>
                  <StyledTableCell
                    width="10%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Inventory
                  </StyledTableCell>
                  {/* <StyledTableCell
                width="10%"
                style={{
                  borderTop: 'black solid 2px',
                  borderBottom: 'black solid 2px',
                  textAlign: 'center',
                  // borderRight: 'none',
                }}
              >
                Action
              </StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryData?.map((item) => (
                  <OwnerInventory key={`inventoryItem${item?.id}`} row={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
}

export default OfferInventories
