import React, { useState, useEffect } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import {
  useNotify,
  useDataProvider,
  // FormWithRedirect,
  AutocompleteArrayInput,
} from 'react-admin'
import { useFormState } from 'react-final-form'
// import useStyles from '../styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'

const AutocompleteArrayFieldInput = (props) => {
  const [editing, setEditing] = useState(false)

  // console.log(props)
  const { values } = useFormState()
  // const classes = useStyles()
  // const form = useForm()

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)
  const {
    choices,
    oldData,
    fieldName,
    referenceField,
    referenceId,
    resourceName,
    elementName,
    fieldLabel,
    reference,
    formField,
    valueType,
    // classes,
    onConflict,
    ...rest
  } = props

  const [options, setOptions] = useState(choices || [])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data: optionsTemp } = await dataProvider.getList(reference, {})
        !abortController.signal.aborted && setOptions(optionsTemp)
      } catch (error) {
        console.log(error)
        notify(error.message, { type: 'warning' })
        return
      }
    }

    const abortController = new AbortController()
    !choices && getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  // console.log(options)

  return (
    <Box display="flex" width="100%">
      <AutocompleteArrayInput
        // source={formField}
        source={formField || reference}
        disabled={!editing}
        // classes={classes}
        variant="standard"
        label={fieldLabel}
        choices={options}
        style={{ width: '600px' }}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                // Compare new values to old and insert difference
                console.log(values[formField || reference])
                console.log(oldData)
                const newValues = []
                const deleteValues = []
                if (valueType === 'text') {
                  values[formField || reference]?.map((item) => {
                    if (!oldData.some((oldItem) => oldItem[elementName] === item)) {
                      newValues.push({ [fieldName]: item, [referenceField]: referenceId })
                    }
                  })

                  // Compare old values to new and delete difference
                  oldData?.map((oldItem) => {
                    if (
                      !values[formField || reference]?.some((item) => item === oldItem[elementName])
                    ) {
                      deleteValues.push(oldItem.id)
                    }
                  })
                } else {
                  values[formField || reference]?.map((item) => {
                    if (!oldData.some((oldItem) => oldItem[elementName]?.id === item)) {
                      newValues.push({ [fieldName]: item, [referenceField]: referenceId })
                    }
                  })

                  // Compare old values to new and delete difference
                  oldData?.map((oldItem) => {
                    if (
                      !values[formField || reference]?.some(
                        (item) => item === oldItem[elementName]?.id,
                      )
                    ) {
                      deleteValues.push(oldItem.id)
                    }
                  })
                }

                // console.log(newValues, deleteValues)
                if (newValues.length > 0) {
                  await dataProvider.create(resourceName, {
                    data: newValues,
                    ...(onConflict && { ...onConflict }),
                  })
                  // console.log(resp)
                }
                if (deleteValues.length > 0) {
                  await dataProvider.deleteMany(resourceName, {
                    ids: deleteValues,
                  })
                }
                setSaving(false)

                setEditing(false)
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                setSaving(false)
                console.log(error)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default AutocompleteArrayFieldInput
