import React from 'react'
import { Box } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import AutocompleteArrayFieldInput from '../../components/fieldInputs/AutocompleteArrayFieldInput'

const useStyles = makeStyles(() => ({
  accordionStyle: {
    '& .MuiAccordionSummary-root': {
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
}))

const OfferBasicDetailsForm = () => {
  const classes = useStyles()
  const { values } = useFormState()

  //   console.log(values)

  return (
    <Box display="flex" width="100%">
      <Box flex={1}>
        <span style={{ fontSize: '12px', marginBottom: '20px' }}>Variants</span>
        {values.batches.map((el, index) => (
          <Box key={el.id} display="flex" alignItems="flex-end">
            <span
              style={{
                fontSize: '12px',
                paddingBottom: '20px',
                marginRight: '10px',
                minWidth: '150px',
                textAlign: 'right',
              }}
            >
              {el.saleable_product_variant.variant.sku}
            </span>
            <NumberFieldInput
              // className={classes.fieldInputNarrow}
              label="Order Quantity"
              value={values?.batches[index].quantity}
              resource="offer"
              field={`batches[${index}.quantity`}
              defaultValue={0}
              decimalScale={0}
              fixedDecimalScale
              id={values.id}
            />
          </Box>
        ))}
        <TextFieldInput
          variant="standard"
          fullWidth
          field="warranty"
          resource="offer"
          id={values.id}
          label="Destination:"
          // validate={required()}
          InputProps={{
            maxLength: 800,
            style: {
              paddingBottom: 0,
              alignItems: 'baseline',
            },
          }}
          value={values.warranty}
          multiline
          minRows={3}
          className={classes.fieldInputLeft}
        />
      </Box>
      <Box flex={1}>
        <AutocompleteArrayFieldInput
          variant="standard"
          fullWidth
          reference="chosenPlatforms"
          fieldLabel="Platforms"
          elementName="platform"
          valueType="text"
          choices={[
            { id: 'MYFUNDRAISEGUY', value: 'MYFUNDRAISEGUY', name: 'My Fundraise Guy' },
            { id: 'CLIQUESTOCK', value: 'CLIQUESTOCK', name: 'Clique Stock' },
            { id: 'SOLIDBLANKS', value: 'SOLIDBLANKS', name: 'Solid Blanks' },
          ]}
          data={values.chosenPlatforms}
          oldData={values.platforms}
          resourceName="offer_platforms"
          fieldName="platform"
          referenceField="offer_id"
          referenceId={values.id}
          onConflict={{
            on_conflict: {
              constraint: 'offer_platforms_offer_id_platform_key',
              update_columns: ['platform'],
            },
          }}
        />
      </Box>
      <Box flex={1} ml="30px">
        <NumberFieldInput
          className={classes.fieldInputNarrow}
          label="Manufacturing Lead Time:"
          value={values?.manufacturing_lead_time}
          resource="offer"
          field="manufacturing_lead_time"
          decimalScale={0}
          fixedDecimalScale
          id={values.id}
        />
        <NumberFieldInput
          className={classes.fieldInputNarrow}
          label="Min Purchase Qty:"
          value={values?.minimum_purchase_quantity}
          resource="offer"
          field="minimum_purchase_quantity"
          decimalScale={0}
          fixedDecimalScale
          id={values.id}
        />
      </Box>
    </Box>
  )
}

export default OfferBasicDetailsForm
