import { split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { ApolloClient, InMemoryCache } from '@apollo/client'

export default (options) => {
  if (!options) {
    throw new Error('Data client error')
    // return new ApolloClient()
  }

  const { cache, link, uri, ...otherOptions } = options
  let httpLink = link
  let finalCache = cache

  if (!link && uri) {
    httpLink = new HttpLink({ uri })
  }

  const localToken = localStorage.getItem('token')
  // console.log(localToken)
  const token = typeof localToken === 'string' ? localToken : localToken?.access_token

  const wsLink = new GraphQLWsLink(
    createClient({
      url:
        process.env.NODE_ENV === 'development'
          ? 'wss://hasura.dev.benib2b.com/v1/graphql' // dev
          : // ?  'wss://hasura.benib2b.com/v1/graphql' // prod
            // ? 'wss://hasura.staging.benib2b.com/v1/graphql' // staging
            process.env.REACT_APP_WEBSOCKET_HOST,
      reconnect: true,
      lazy: true,
      timeout: 30000,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }),
  )

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  if (!cache) {
    finalCache = new InMemoryCache()
  }

  // console.log('SPLITLINK', splitLink)
  // console.log('FINALCACHE', finalCache)
  // console.log('OTHEROPTIONS', otherOptions)

  const client = new ApolloClient({
    link: splitLink,
    cache: finalCache,
    ...otherOptions,
  })

  // console.log('BACCLIENT', client)

  return client

  // return new ApolloClient({
  // })
}
