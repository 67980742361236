import gql from 'graphql-tag'

const orderGetQuery = `
id
sum_amount
sum_quantity
status
delivery_address {
  id
  address_line1
  address_line2
  city
  province
  country_code
  post_code
  delivery_contact
  delivery_email
  delivery_mobile
}
billing_address {
  id
  address_line1
  address_line2
  city
  province
  country_code
  post_code
  delivery_contact
  delivery_email
  delivery_mobile
}
client {
  id
  name
  stripe_connected_account_id
  stripe_customer_account_id
  default_sca {
    id
    forename
    surname
    email
  }
}
itemsCount: order_products_aggregate {
  aggregate { count }
}
order_products {
  id
  offer_purchase {
    id
    amount
    quantity
    platform
    status
    created_at
    brand
    required_delivery_date
    client_price
    client_quantity
    purchase_order_number
    purchase_order_date
    offer_purchase_invoices {
      id
      invoice {
        id
        amount_due
        invoice_payments_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
        invoice_payments {
          id
          amount
        }
      }
    }
    purchase_order_file {
      id
      filename
      url
    }
    client_sku
    client {
      id
      name
      stripe_connected_account_id
      stripe_customer_account_id
      members {
        id
        code
        member{
          id
          forename
          surname
          email
        }
      }
    }
    buyer {
      id
      forename
      surname
      email
    }
    items {
      id
      quantity
      amount
      unit_price
      is_custom_order
      is_sample_order
      shipping_method
      incoterms
      url_slug
      name
      image {
        id
        filename
        url
      }
      variant {
        id
        product_id
        name
        values {
          id
          option_name
          value
          option {
            id
            name
          }
          option_value {
            id
            value
            reference_file {
              id
              filename
              url
            }
          }
        }
      }
    }
    offer {
      id
      status
      created_at
      pieces_per_carton
      cartons_per_pallet
      cartons_per_container_20ft
      cartons_per_container_40ft
      cartons_per_container_40ft_hc
      allow_order_splitting_by_size
      packaging_type
      retail_price
      duty_rate
      logistics_estimate
      inspection_estimate
      payment_processing_fees
      supply_chain_fees
      marketing_budget
      bulk_software_fees
      bulk_commission_fees
      bulk_partner_fees
      bulk_royalties_fees
      moq
      notes
      freight_method
      incoterms
      consignee_id
      destination_address_id
      estimated_ROI
      hts_code
      inspection_checklist {
        id
        filename
        url
      }
      inspection_level
      inspection_type
      inspection_work_days
      inspection_quote_file {
        id
        filename
        url
      }
      investor_price_per_piece
      quality_agency_id
      logistics_agency_id
      logistics_disclaimer
      logistics_lead_time
      logistics_lead_time_to_port
      logistics_lead_time_to_warehouse
      logistics_quote_valid_date
      logistics_tracking_number
      logistics_tracking_url
      logistics_carrier
      logistics_tracking_number
      logistics_tracking_url
      logistics_carrier
      logistics_quote_file {
        id
        filename
        url
      }
      long_description
      minimum_purchase_value
      minimum_purchase_quantity
      supplier_pickup_address_id
      supplier_contact_id
      supplier_factory_address_id
      sku
      short_description
      sca_id
      shipper_id
      warranty
      upc_code
      upc_code_photo {
        id
        filename
        url
      }
      offer_categories(where: { deleted_at: { _is_null: true } }) {
        id
        category {
          id
          name
        }
      }
      offer_features(where: { deleted_at: { _is_null: true } }) {
        id
        feature {
          id
          name
          value
        }
      }
      offer_files(where: { deleted_at: { _is_null: true } }) {
        id
        type
        file {
          id
          filename
          url
        }
      }
      offer_payment_terms(where: { deleted_at: { _is_null: true } }) {
        id
        milestone
        type
        value
        types {
          type
        }
      }
      offer_tags(where: { deleted_at: { _is_null: true } }) {
        id
        tag {
          id
          name
        }
      }
      offer_specifications {
        id
        specification {
          id
          value
          option {
            id
            name
          }
        }
      }
      product_dimensions {
        id
        length
        height
        gross_weight
        width
      }
      packaging_dimensions {
        id
        length
        height
        gross_weight
        width
      }
      carton_dimensions {
        id
        length
        height
        width
        gross_weight
      }
      saleable_products {
        id
      }
      sca {
        id
        surname
        forename
        email
        username
      }
      name
      thumbnail {
        id
        filename
        url
      }
      primary_photo {
        id
        filename
        url
      }
      client_quote {
        id
        warranty_term
        warranty_conditions
        scm_quote_packaging_options {
          id
          product_quote_packaging_option_id
          product_quote_packaging_option {
            id
            moq
            cost_modifier
          }
          price_modifier
          moq
          label
          pieces_per_carton
          type
          carton_dimensions {
            id
            length
            width
            height
            gross_weight
          }
          packaging_dimensions {
            id
            length
            width
            height
            gross_weight
          }
          scm_quote_product_size_packagings {
            id
            scm_quote_product_size_id
            scm_quote_product_size {
              id
              moq
              name
              short_name
              price_modifier
              dimensions {
                id
                length
                width
                height
                gross_weight
              }
              product_quote_product_size_id
              product_quote_product_size {
                id
                moq
                cost_modifier
              }
            }
          }
        }
        product_components {
          id
          name
          colours {
            id
            value
          }
        }
        client_id
        client {
          id
          name
          stripe_connected_account_id
          stripe_customer_account_id
        }
        product_quote {
          id
          quantity
          price
          valid_date
          sample_cost
          custom_sample_cost
          sample_lead_time
          tooling_cost
          other_requirements
          lead_time
          prices(order_by: { quantity: asc_nulls_last }) {
            id
            quantity
            unit_price
            lead_time
            est_landed_cost_air
            est_landed_cost_sea
            total_cbm
            total_cartons
            total_duties
            shipment_weight
          }
          product {
            id
            product_options {
              id
              deleted_at
              option_value {
                id
                value
                option {
                  id
                  name
                }
              }
            }
            product_declarations {
              id
              declaration
              file {
                id
                filename
                url
              }
            }
          }
          supplier {
            id
            name
            sca_user {
              id
              forename
              surname
              email
              username
            }
            bank_detail {
              id
              beneficiary_address_id
              beneficiary_name
              branch_address
              branch_name
              clearing_code
              currency
              bank_id
              account_number
              swift_code
              tax_number
            }
          }
          certificates {
            id
            certification {
              id
              name
              type
              notifying_body
              number
              valid_end_date
              valid_start_date
              document {
                id
                filename
                url
              }
            }
          }
          quote {
            id
            filename
            url
          }
        }
      }
      orders: offer_purchases_aggregate(
        where: { status: { _in: [PAYMENT_RECEIVED] } }
      ) {
        aggregate {
          count
        }
      }
      purchased: total_sold
    }
  }
}
`

export const orderDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation deleteOrder {
            delete_order_product(where: {order_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_order(where: {id: {_eq: ${params.id}}}) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const orderGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            query getOrder {
              order_by_pk(id: ${params.id}) {
                ${orderGetQuery}
              }
            }
        `,
    parseResponse: ({ data }) => {
      if (data && data.order_by_pk) {
        // console.log("data", data)
        return {
          data: data.order_by_pk,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const orderList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  const variables = {}
  const offerStatus = params.filter?.status ? params.filter?.status?.replace(/"\^|\^"/g, '') : null

  const offerType =
    typeof params.filter?.type === 'string'
      ? params.filter?.type?.replace(/"\^|\^"/g, '')
      : !params.filter.type
      ? null
      : params.filter.type

  if (params.filter.status || params.id || params.filter.type) {
    variables.where = {
      ...(params.filter.id && { id: { _eq: params.filter.id } }),
      ...(offerStatus && { status: { _eq: offerStatus } }),
      ...(params.filter.type && typeof params.filter.tpye === 'string'
        ? { type: { _eq: offerType } }
        : params.filter.type && { type: { _in: offerType } }),
    }
  }
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
      query getOrders ($where: order_bool_exp) {
       order(where: $where, limit: ${params.pagination.perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
            ${orderGetQuery}
          }
          order_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }
    `,
    parseResponse: ({ data }) => {
      if (data && data.order) {
        // console.log("data", data)
        return {
          data: data.order,
          total: data.order_aggregate.aggregate.count,
          // validUntil,
        }
      }
      return false
    },
  }
}
