import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core'
import { TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import NumberFormat from '../../components/CustomNumberFormat'
import useStyles from '../styles'
// import SelectInput from '@/components/input/SelectInput'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    // backgroundColor: 'rgba(35, 35, 35, 0.05)',
    fontSize: 14,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    // color: '#FFFFFF',
    // backgroundColor: 'rgba(35, 35, 35, 0.05)',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    backgroundColor: 'rgba(35, 35, 35, 0.05)',
    fontSize: 14,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

export const DimensionsTable = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()

  // console.log(values)

  const { source } = props

  return (
    <TableContainer
      className={classes.tableBox}
      component={Paper}
      style={{ marginTop: 20, marginRight: '24px', width: 'auto' }}
    >
      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: '10%' }} align="right">
              Size Option
            </StyledTableCell>
            <StyledTableCell style={{ width: '18%' }} align="right" />
            <StyledTableCell style={{ width: '18%' }} align="right">
              Length
            </StyledTableCell>
            <StyledTableCell style={{ width: '18%' }} align="right">
              Width
            </StyledTableCell>
            <StyledTableCell style={{ width: '18%' }} align="right">
              Height
            </StyledTableCell>
            <StyledTableCell style={{ width: '18%' }} align="right">
              Weight
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="productDim">
            <StyledTableCell align="right">Product</StyledTableCell>
            <StyledTableCell align="right" />
            <StyledTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.length`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.lengthIN`, value ? (value / 2.54).toFixed(2) : 0)
                // }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.width`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.widthIN`, (value ? value / 2.54 : 0).toFixed(2))
                // }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.height`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.heightIN`, value ? (value / 2.54).toFixed(2) : 0)
                // }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.gross_weight`}
                onChange={(e) => {
                  const { value } = e.target
                  const imperialValue = value ? (value * 2.205).toFixed(2) : 0
                  form.change(`${source}.weightLB`, imperialValue)

                  const seaWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          5000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableSeaWeight = seaWeight / 100 > value ? seaWeight : value * 100

                  form.change('chargeable_weight_sea', chargeableSeaWeight)

                  const airWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          6000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableAirWeight = airWeight / 100 > value ? airWeight : value * 100

                  form.change('chargeable_weight_air', chargeableAirWeight)

                  // console.log(
                  //   'SEA',
                  //   seaWeight,
                  //   'AIR',
                  //   airWeight,
                  //   'METRIC',
                  //   value,
                  //   'CHGSEA',
                  //   chargeableSeaWeight,
                  //   'CHGAIR',
                  //   chargeableAirWeight,
                  // )
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const PackagingOption = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()

  // console.log(values)

  const { source } = props

  return (
    <TableContainer className={classes.tableBox} component={Paper}>
      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
        <TableHead>
          <TableRow key="packagingDim">
            <BodyTableCell align="right" style={{ width: '10%' }}>
              Packaging
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <TextInput
                variant="standard"
                fullWidth
                source="packaging_type"
                label="Packaging Type"
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.lengthIN`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.length`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.widthIN`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.width`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.heightIN`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.height`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.weightLB`}
                onChange={(e) => {
                  const { value } = e.target
                  const metricValue = value ? parseInt((value / 2.205).toFixed(2)) : 0
                  // console.log(value, metricValue)
                  form.change(`${source}.gross_weight`, metricValue)

                  const seaWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          5000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableSeaWeight =
                    seaWeight / 100 > metricValue ? seaWeight : metricValue * 100

                  form.change('chargeable_weight_sea', chargeableSeaWeight)

                  const airWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          6000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableAirWeight =
                    airWeight / 100 > metricValue ? airWeight : metricValue * 100

                  form.change('chargeable_weight_air', chargeableAirWeight)
                }}
              />
            </BodyTableCell>
          </TableRow>
          <TableRow key="cartonDim">
            <BodyTableCell align="right" style={{ width: '10%' }}>
              Carton
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                label="Piieces per carton"
                source={`${source}.pieces_per_carton`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.length`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.lengthIN`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.length`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.widthIN`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.width`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.heightIN`}
                // onChange={(e) => {
                //   const { value } = e.target
                //   form.change(`${source}.height`, (value ? value * 2.54 : 0).toFixed(2))
                // }}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ width: '18%' }}>
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.weightLB`}
                onChange={(e) => {
                  const { value } = e.target
                  const metricValue = value ? parseInt((value / 2.205).toFixed(2)) : 0
                  // console.log(value, metricValue)
                  form.change(`${source}.gross_weight`, metricValue)

                  const seaWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          5000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableSeaWeight =
                    seaWeight / 100 > metricValue ? seaWeight : metricValue * 100

                  form.change('chargeable_weight_sea', chargeableSeaWeight)

                  const airWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          6000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableAirWeight =
                    airWeight / 100 > metricValue ? airWeight : metricValue * 100

                  form.change('chargeable_weight_air', chargeableAirWeight)
                }}
              />
            </BodyTableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  )
}
