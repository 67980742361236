import React, { useEffect, useState } from 'react'
import {
  // AutocompleteInput,
  AutocompleteArrayInput,
  useCreate,
  useNotify,
  useDataProvider,
  FormWithRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, useFormState } from 'react-final-form'

import TagSelectWithCreateForm from './TagSelectWithCreateForm'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
})

const TagSelectWithCreate = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [create, { loading }] = useCreate('tags')

  const form = useForm()
  const { values } = useFormState()
  const [showDialog, setShowDialog] = useState(false)
  const [tags, setTags] = useState([])

  // eslint-disable-next-line no-unused-vars
  const { mutationMode, label, source, options, setOptions, optionText, optionValue, ...rest } =
    props

  const handleChange = (option) => {
    const newOptions = [...tags]
    newOptions.push(option)
    const abortController = new AbortController()
    !abortController.signal.aborted && setTags(newOptions)
    // console.log(newOptions)
  }

  // console.log(values)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data: tagsTemp } = await dataProvider.getList('tag', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
        !abortController.signal.aborted && setTags(tagsTemp)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  const handleClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(true)
  }

  const handleCloseClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(false)
  }

  useEffect(() => {
    return () => {}
  }, [])

  const handleSubmit = async (params) => {
    // console.log(params)
    const abortController = new AbortController()
    if (tags.some((tag) => tag.name === params.tag_name)) {
      // console.log('Already exists')
      !abortController.signal.aborted && setShowDialog(false)
      form.change(source, [
        ...values[source],
        tags.find((item) => item.name === params.tag_name).id,
      ])
      return null
    }
    create(
      {
        payload: {
          data: {
            name: params.tag_name,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          // console.log(data, values[source])
          const oldTags = [...values[source]]
          !abortController.signal.aborted && form.change(source, [...oldTags, data.id])
          // console.log([...oldTags, data.id])
          handleChange({ id: data.id, name: params.tag_name })
          !abortController.signal.aborted && setShowDialog(false)
        },
        onFailure: (resp) => {
          console.log('error', resp.message)
          notify(resp.message, { type: 'error' })
        },
      },
    )
  }

  // console.log('TAGWITHCREATE', props, rest)

  return (
    <div className={classes.root}>
      {tags.length > 0 && (
        <AutocompleteArrayInput
          // className={classes.hideLabelAutoComplete}
          fullWidth
          variant="standard"
          label={label || 'Tag:'}
          source={source}
          choices={tags}
          // optionText={optionText}
          // optionValue={optionValue}
          {...rest}
        />
      )}

      <FormWithRedirect
        resource="tags"
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <TagSelectWithCreateForm
            handleClick={handleClick}
            handleCloseClick={handleCloseClick}
            showDialog={showDialog}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            pristine={pristine}
            saving={saving}
            loading={loading}
          />
        )}
      />
    </div>
  )
}

export default TagSelectWithCreate
