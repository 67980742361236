import React from 'react'
import { useRedirect, Edit, FormWithRedirect } from 'react-admin'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import moment from 'moment'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import NumberFormat from '../../components/CustomNumberFormat'

const useStyles = makeStyles(() => ({
  editBox: {
    margin: 0,
    padding: 0,
  },
  table: {
    overflowX: 'unset',
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#333333',
    marginBottom: '10px',
    marginTop: '10px',
  },
  filesLabel: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  labelText: {
    fontSize: '12px',
  },
  numberInput: {
    width: '80px',
    height: '36px',
    textAlign: 'center',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      minHeight: '30px',
      '& .input': {
        border: 'solid 1px green',
        width: '50%',
      },
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const PricingDetails = (props) => {
  const classes = useStyles()
  const { saleableProductId, ...rest } = props
  return (
    <Edit
      {...rest}
      resource="saleableProducts"
      actions={null}
      component="div"
      id={saleableProductId}
      mutationMode="pessimistic"
      className={classes.editBox}
    >
      <SaleableProductsSalesList id={saleableProductId} />
    </Edit>
  )
}

const SaleableProductsSalesList = (props) => {
  // const dataProvider = useDataProvider()
  // const { record } = props

  // console.log(record)

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Box p="0px 20px">
          <FormData formProps={formProps} />
        </Box>
      )}
    />
  )
}

const FormData = () => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <Box display="flex">
      <TableContainer component={Box} className={classes.table}>
        <Table className={classes.table} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">Id</StyledTableCell>
              <StyledTableCell width="10%">Date</StyledTableCell>
              <StyledTableCell width="40%">Options</StyledTableCell>
              <StyledTableCell width="25%">SKU</StyledTableCell>
              {/* <StyledTableCell width="10%">Name</StyledTableCell> */}
              <StyledTableCell width="10%">Quantity</StyledTableCell>
              <StyledTableCell width="10%">View Order</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.sales.map((sale, index) => (
              <SaleRow key={`sale${sale.id}`} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const SaleRow = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const redirect = useRedirect()

  const { index } = props

  const colourValue = values.sales[index].product_variant?.variant?.values?.find(
    (value) => value.option_name === 'Color',
  )

  return (
    <TableRow>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        {values.sales[index].id}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ padding: '10px' }}>
        {moment(values.sales[index].created_at).format('YYYY/MM/DD')}
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {colourValue ? (
            <div
              style={{
                margin: '0px 10px',
                width: '20px',
                height: '20px',
                borderRadius: '3px',
                border:
                  parseInt(colourValue.value?.slice(1, 3), 16) +
                    parseInt(colourValue.value?.slice(3, 5), 16) +
                    parseInt(colourValue.value?.slice(5, 7), 16) <
                  600
                    ? `1px solid ${colourValue?.value}`
                    : '1px solid #ABABAB',
                backgroundColor: colourValue?.value,
              }}
            />
          ) : null}
          {values.sales[index].product_variant?.variant?.values?.map((el) => el.value).join()}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ padding: '10px' }}>
        {values.sales[index].product_variant?.variant?.sku}
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled
          source={`sales[${index}].quantity`}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <IconButton
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
          }}
          onClick={async () => {
            // goto order
            console.log(values.sales[index].order.id)
            redirect('edit', '/consumerOrders', values.sales[index].order.id)
          }}
        >
          <OpenInNewIcon fontSize="small" style={{ color: '#454545' }} />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  )
}

export default PricingDetails
