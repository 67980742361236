import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import useStyles from '../styles'
import IncomeTables from './IncomeTables'
import ExpensesTables from './ExpensesTables'
import SummaryTable from './SummaryTable'
import ConfirmationArea from './ConfirmationArea'
import { roundNumber } from '../../../../utils/utils'

const CostsAndPricing = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  // const form = useForm()

  const { showDetails = false, fieldForm = false } = props

  // console.log(props)

  const {
    qualityEstimate,
    internationalFreightEstimate,
    dropshippingEstimate,
    duty_rate,
    price,
    quantity,
    pieces_per_carton,
    supplyChainFees,
    bulkSoftwareFees,
    bulkCommissionFees,
    bulkPartnerFees,
    paymentProcessingFees,
    bulkRoyaltiesFees,
    marketingFees,
    commissionFees,
    consumerPartnerFees,
    consumerPaymentFees,
    consumerServiceFees,
    consumerSoftwareFees,
    consumerRoyaltiesFees,
    retailPrice,
    moq,
    // minimum_purchase_quantity,
  } = values

  // console.log(values)

  const factoryTotal = quantity * price || 0
  const qualityTotal = qualityEstimate || 0
  const qualityUnit = qualityEstimate / quantity || 0
  const logisticsTotal = internationalFreightEstimate || 0
  const logisticsUnit = internationalFreightEstimate / quantity || 0
  const dutiesTotal = (duty_rate / 10000) * factoryTotal || 0
  const dutiesUnit = ((duty_rate / 10000) * factoryTotal) / quantity || 0

  const landedTotal = qualityTotal // + dutiesTotal + logisticsTotal

  const supplyChainTotal = (supplyChainFees / 100) * factoryTotal
  const supplyChainUnit = supplyChainTotal / quantity

  const supplyChainOpsTotal = landedTotal + supplyChainTotal
  const supplyChainOpsUnit = supplyChainOpsTotal / quantity || 0

  const factoryLanded = landedTotal + factoryTotal + supplyChainTotal

  const calculatedExpensesFields = useMemo(() =>
    GetCalculatedExpensesFields({
      bulkSoftwareFees,
      bulkCommissionFees,
      dropshippingEstimate,
      factoryTotal,
      qualityTotal,
      factoryLanded,
      quantity,
      bulkPartnerFees,
      paymentProcessingFees,
      bulkRoyaltiesFees,
    }),
  )

  // console.log('EXPVALUES', calculatedExpensesFields)

  // SALES INCOME CALC

  const calculatedIncomeFields = useMemo(() =>
    GetCalculatedIncomeFields({
      commissionFees,
      marketingFees,
      retailPrice,
      clientCostTotal: calculatedExpensesFields.clientCostTotal,
      quantity,
      dropshippingEstimate,
      consumerPartnerFees,
      consumerPaymentFees,
      consumerServiceFees,
      consumerSoftwareFees,
      consumerRoyaltiesFees,
    }),
  )

  // console.log('INCVALUES', calculatedIncomeFields)

  // useEffect(() => {
  //   return () => {}
  // }, [])

  // useEffect(() => {
  //   // set calc vals for submission

  //   const setValues = async () => {
  //     !abortController.signal.aborted && calculatedExpensesFields?.clientCostUnit
  //       ? form.change(
  //           'investorPricePerPiece',
  //           parseInt(calculatedExpensesFields?.clientCostUnit * 100),
  //         )
  //       : null
  //     !abortController.signal.aborted &&
  //     moq &&
  //     calculatedExpensesFields?.clientCostUnit &&
  //     pieces_per_carton
  //       ? form.change(
  //           'minimumPurchaseValue',
  //           parseInt(pieces_per_carton * minimum_purchase_quantity * price * 100),
  //         )
  //       : null
  //     !abortController.signal.aborted && moq && pieces_per_carton
  //       ? form.change('minimumPurchaseQuantity', pieces_per_carton * moq)
  //       : null
  //     !abortController.signal.aborted && calculatedIncomeFields?.buyersReturn
  //       ? form.change('estimatedROI', parseInt(calculatedIncomeFields.buyersReturn * 100))
  //       : null
  //   }

  //   const abortController = new AbortController()
  //   setValues()
  //   return () => {
  //     abortController.abort()
  //   }
  // }, [
  //   moq,
  //   calculatedExpensesFields.clientCostUnit,
  //   calculatedIncomeFields.buyersReturn,
  //   pieces_per_carton,
  // ])

  return (
    <Box>
      <ConfirmationArea
        fieldForm={fieldForm}
        values={values}
        clientCostUnit={calculatedExpensesFields.clientCostUnit || 0}
        buyersReturn={calculatedIncomeFields.buyersReturn}
      />

      <SummaryTable
        calculatedIncomeFields={calculatedIncomeFields}
        calculatedExpensesFields={calculatedExpensesFields}
        supplyChainTotal={supplyChainTotal}
        quantity={quantity}
        piecesPerCarton={pieces_per_carton}
        moq={moq}
        retailPrice={values.retailPrice}
      />
      {showDetails && (
        <Box display="flex" mt="20px">
          <Box className={classes.outerTabBoxLeft} flex={1} pr="5px">
            <ExpensesTables
              quantity={quantity}
              factoryUnit={price}
              logisticsUnit={logisticsUnit}
              qualityUnit={qualityUnit}
              factoryTotal={factoryTotal}
              logisticsTotal={logisticsTotal}
              qualityTotal={qualityTotal}
              dutiesUnit={dutiesUnit}
              dutiesTotal={dutiesTotal}
              landedTotal={landedTotal}
              supplyChainTotal={supplyChainTotal}
              supplyChainUnit={supplyChainUnit}
              supplyChainOpsTotal={supplyChainOpsTotal}
              supplyChainOpsUnit={supplyChainOpsUnit}
              calculatedExpensesFields={calculatedExpensesFields}
            />
          </Box>
          <Box className={classes.outerTabBoxRight} flex={1} pl="5px">
            <IncomeTables
              calculatedIncomeFields={calculatedIncomeFields}
              dropshippingEstimate={dropshippingEstimate}
              clientCostTotal={calculatedExpensesFields.clientCostTotal}
              clientCostUnit={calculatedExpensesFields.clientCostUnit}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export const GetCalculatedExpensesFields = ({
  bulkSoftwareFees,
  bulkCommissionFees,
  dropshippingEstimate,
  factoryLanded,
  quantity,
  bulkPartnerFees,
  paymentProcessingFees,
  bulkRoyaltiesFees,
}) => {
  // console.log('CALCFIELDS', bulkSoftwareFees)

  const bulkSoftwareUnit = roundNumber(((bulkSoftwareFees / 100) * factoryLanded) / quantity, 2)
  const bulkSoftwareTotal = roundNumber((bulkSoftwareFees / 100) * factoryLanded, 2)
  const bulkPartnerUnit = roundNumber(((bulkPartnerFees / 100) * factoryLanded) / quantity, 2)
  const bulkPartnerTotal = roundNumber((bulkPartnerFees / 100) * factoryLanded, 2)
  const bulkRoyaltiesUnit = roundNumber(((bulkRoyaltiesFees / 100) * factoryLanded) / quantity, 2)
  const bulkRoyaltiesTotal = roundNumber((bulkRoyaltiesFees / 100) * factoryLanded, 2)
  const bulkCommissionUnit = roundNumber(((bulkCommissionFees / 100) * factoryLanded) / quantity, 2)
  const bulkCommissionTotal = roundNumber((bulkCommissionFees / 100) * factoryLanded, 2)
  const bulkServiceFeesTotal =
    bulkSoftwareTotal +
    bulkPartnerTotal +
    bulkRoyaltiesTotal +
    bulkCommissionUnit +
    bulkCommissionTotal

  const bulkServiceFeesUnit = bulkServiceFeesTotal / quantity

  const paymentProcessingTotal = roundNumber(
    (paymentProcessingFees / 100) * (bulkServiceFeesTotal + factoryLanded),
    2,
  )
  const paymentProcessingUnit = roundNumber(paymentProcessingTotal / quantity, 2)

  // const productionTotal = factoryTotal + qualityTotal
  // const proudctionUnit = productionTotal / quantity

  const clientCostTotal = factoryLanded + bulkServiceFeesTotal + paymentProcessingTotal
  const clientCostUnit = roundNumber(clientCostTotal / quantity, 2)
  const consumerDropshippingPrice = clientCostUnit + dropshippingEstimate

  const data = {
    bulkSoftwareUnit,
    bulkSoftwareTotal,
    bulkPartnerUnit,
    bulkPartnerTotal,
    bulkCommissionUnit,
    bulkCommissionTotal,
    paymentProcessingUnit,
    paymentProcessingTotal,
    bulkRoyaltiesUnit,
    bulkRoyaltiesTotal,
    clientCostUnit,
    clientCostTotal,
    bulkServiceFeesTotal,
    bulkServiceFeesUnit,
    quantity,
    consumerDropshippingPrice,
  }
  return data
}

export const GetCalculatedIncomeFields = ({
  commissionFees,
  marketingFees,
  retailPrice,
  clientCostTotal,
  quantity,
  dropshippingEstimate,
  consumerPartnerFees,
  consumerPaymentFees,
  consumerServiceFees,
  consumerSoftwareFees,
  consumerRoyaltiesFees,
}) => {
  // console.log(
  //   retailPrice,
  //   marketingFees,
  //   consumerPartnerFees,
  //   consumerPaymentFees,
  //   consumerServiceFees,
  //   consumerSoftwareFees,
  //   consumerRoyaltiesFees,
  //   commissionFees,
  // )
  const marketingBudgetUnit = roundNumber((marketingFees / 100) * retailPrice, 2)
  const consumerPartnerUnit = roundNumber((consumerPartnerFees / 100) * retailPrice, 2)
  const consumerPaymentUnit = roundNumber((consumerPaymentFees / 100) * retailPrice, 2)
  const consumerServiceUnit = roundNumber((consumerServiceFees / 100) * retailPrice, 2)
  const consumerSoftwareUnit = roundNumber((consumerSoftwareFees / 100) * retailPrice, 2)
  const consumerRoyaltiesUnit = roundNumber((consumerRoyaltiesFees / 100) * retailPrice, 2)
  const commissionUnit = roundNumber((commissionFees / 100) * retailPrice, 2)

  if (!quantity) {
    // console.log(
    //   marketingBudgetUnit,
    //   consumerPartnerUnit,
    //   consumerPaymentUnit,
    //   consumerServiceUnit,
    //   consumerSoftwareUnit,
    //   consumerRoyaltiesUnit,
    //   commissionUnit,
    // )
    return roundNumber(
      marketingBudgetUnit +
        consumerPartnerUnit +
        consumerPaymentUnit +
        consumerServiceUnit +
        consumerSoftwareUnit +
        consumerRoyaltiesUnit +
        commissionUnit,
      2,
    )
  }
  const potentialRevenueTotal = retailPrice * quantity
  const dropshippingEstimateTotal = dropshippingEstimate * quantity
  const marketingBudgetTotal = roundNumber((marketingFees / 100) * potentialRevenueTotal, 2)
  const consumerPartnerTotal = roundNumber((consumerPartnerFees / 100) * potentialRevenueTotal, 2)
  const consumerPaymentTotal = roundNumber((consumerPaymentFees / 100) * potentialRevenueTotal, 2)
  const consumerServiceTotal = roundNumber((consumerServiceFees / 100) * potentialRevenueTotal, 2)
  const consumerSoftwareTotal = roundNumber((consumerSoftwareFees / 100) * potentialRevenueTotal, 2)
  const consumerRoyaltiesTotal = roundNumber(
    (consumerRoyaltiesFees / 100) * potentialRevenueTotal,
    2,
  )
  const commissionTotal = roundNumber((commissionFees / 100) * potentialRevenueTotal, 2)

  const sellingFeesTotal =
    marketingBudgetTotal +
    commissionTotal +
    consumerPartnerTotal +
    consumerPaymentTotal +
    consumerServiceTotal +
    consumerSoftwareTotal +
    consumerRoyaltiesTotal +
    dropshippingEstimateTotal
  const grossMargin = potentialRevenueTotal - sellingFeesTotal
  const grossMarginUnit = grossMargin / quantity
  const netIncome = grossMargin - clientCostTotal
  const buyersReturn = roundNumber((netIncome / clientCostTotal) * 100, 2)
  const buyersReturnUnit = netIncome / quantity

  const data = {
    marketingBudgetUnit,
    consumerPartnerUnit,
    consumerPaymentUnit,
    consumerServiceUnit,
    consumerSoftwareUnit,
    consumerRoyaltiesUnit,
    commissionUnit,
    grossMarginUnit,
    buyersReturnUnit,
    dropshippingEstimateTotal,
    potentialRevenueTotal,
    marketingBudgetTotal,
    commissionTotal,
    consumerPartnerTotal,
    consumerPaymentTotal,
    consumerServiceTotal,
    consumerSoftwareTotal,
    consumerRoyaltiesTotal,
    sellingFeesTotal,
    netIncome,
    grossMargin,
    buyersReturn,
  }

  return data
}

export default CostsAndPricing
