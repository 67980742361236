import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useNotify, useDataProvider } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import useStyles from '../styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'

const AutocompleteSpecificationArrayFieldInput = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const form = useForm()
  const [editing, setEditing] = useState(false)
  const [options, setOptions] = useState([])
  const [saving, setSaving] = useState(false)
  const { values } = useFormState()

  // console.log('ASAFIPROPS', props)

  const {
    resourceName,
    oldData,
    filter,
    reference,
    fieldName,
    optionName,
    elementName,
    referenceField,
    referenceId,
    onConflict,
  } = props

  useEffect(() => {
    return () => {}
  }, [])

  // console.log('ASAFIVALS', values)

  useEffect(() => {
    const abortController = new AbortController()

    const getOptions = async () => {
      const { data: listTemp } = await dataProvider.getList(reference, {
        filter,
      })
      !abortController.signal.aborted && setOptions(listTemp)
    }
    getOptions()
    return () => {
      abortController.abort()
    }
  }, [dataProvider])

  return (
    <div>
      <Box display="flex">
        <Autocomplete
          multiple
          id="tags-outlined"
          options={options}
          limitTags={6}
          disabled={!editing || options?.length < 1}
          value={values[resourceName]}
          style={{ minWidth: '30%' }}
          getOptionSelected={(option, value) => {
            // console.log('OPTION', option)
            // console.log('VALUE', value)
            return (
              option.name === value.option_value?.value ||
              option.value === value.option_value?.value ||
              option.name === value.name ||
              option.value === value.name ||
              option.value === value ||
              option.name === value
            )
          }}
          getOptionLabel={(option) => {
            // console.log('OPTLABEL', option)
            return option.option_value?.value || option.name
          }}
          onChange={(e, v) => {
            // console.log('changing')
            form.change(resourceName, v)
          }}
          filterSelectedOptions
          className={classes.autocompleteSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label={false}
              placeholder=""
              variant="standard"
              className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
            />
          )}
        />
        {editing ? (
          <div>
            <IconButton
              onClick={async () => {
                setSaving(true)
                try {
                  // Compare new values to old and insert difference
                  // console.log('RESOURCEVALS', values[resourceName])
                  // console.log('OLDRESOURCEDATA', oldData)

                  const oldSpecs = oldData.filter(
                    (spec) => spec.option_value?.option?.name === optionName,
                  )
                  // console.log('FILTEREDOLDSPECS', oldSpecs)
                  const newValues = []
                  values[resourceName]?.map((item) => {
                    // console.log('ITEM', item)
                    if (!item.option_value?.value) {
                      newValues.push({
                        [elementName]: {
                          data: {
                            value: item.name,
                            option: {
                              data: { name: optionName },
                              on_conflict: {
                                constraint: '^option_name_key^',
                                update_columns: '^name^',
                              },
                            },
                          },
                          on_conflict: {
                            constraint: '^option_value_option_id_value_key^',
                            update_columns: ['^option_id^', '^value^'],
                          },
                        },
                        [referenceField]: referenceId,
                      })
                    }
                  })

                  // Compare old values to new and delete difference
                  const deleteValues = []
                  const retainValues = []
                  oldSpecs.map((oldItem) => {
                    if (!values[resourceName]?.some((item) => item.id === oldItem.id)) {
                      deleteValues.push(oldItem.id)
                    } else {
                      retainValues.push(oldItem)
                    }
                  })

                  // console.log('NEW', newValues, 'DEL', deleteValues, 'RET', retainValues)

                  newValues.length > 0
                    ? await dataProvider.createMany(fieldName, {
                        objects: newValues,
                        ...(onConflict && { ...onConflict }),
                      })
                    : null
                  // console.log('NEWRESP', newValsResp)
                  if (deleteValues.length > 0) {
                    await dataProvider.deleteMany(fieldName, {
                      ids: deleteValues,
                    })
                  }

                  setSaving(false)

                  // console.log('changing')
                  // await form.change(resourceName, [...retainValues, ...(newValsResp?.data || [])])
                  // console.log('changing')
                  // await form.change(fieldName, [...oldData, ...(newValsResp?.data || [])])
                  setEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </div>
  )
}

export default AutocompleteSpecificationArrayFieldInput
