import React, { useState, useEffect } from 'react'
import { Box, Button, Select, MenuItem } from '@material-ui/core'
import { useDataProvider, useNotify } from 'react-admin'
import { loadStripe } from '@stripe/stripe-js'
import { ApolloProvider } from '@apollo/client'
import NewClient from '../../../../dataProvider/hasura/buildApolloClient'
import useStyles from '../styles'
import OfferMakePurchase from './offerMakePurchase'
import OfferMakeConsumerPurchase from './offerMakeConsumerPurchase'
import OfferDetails from './offerDetails'
import OfferPurchases from './offerPurchases'
// import OfferPayments from './offerPayments'
import OfferInventories from './offerInventories'
// import OfferManagement from './offerManagement'
// import OfferTransfers from './offerTransfers'
import OfferProductLink from './offerProductLink'
import OfferCustomerPurchases from './offerCustomerPurchases'

const OfferSimulator = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [stripePromise, setStripePromise] = useState(
    loadStripe(
      'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
    ),
  )

  const { id } = props

  const [offer, setOffer] = useState('')
  // const [data, setData] = useState({})
  const [appUsers, setAppUsers] = useState([])
  // const [suppliers, setSuppliers] = useState([])
  // const [qualityAgencies, setQualityAgencies] = useState([])
  // const [logisticsProviders, setLogisticsProviders] = useState([])
  // const [supplyChainAgencies, setSupplyChainAgencies] = useState([])
  const [offers, setOffers] = useState([])
  // const [appUser, setAppUser] = useState('')
  // const [supplier, setSupplier] = useState('')
  // const [qualityAgency, setQualityAgency] = useState('')
  // const [logisticsProvider, setLogisticsProvider] = useState('')
  // const [supplyChainAgency, setSupplyChainAgency] = useState('')
  // const [quantity, setQuantity] = useState(0)
  // const [factoryDepositActual, setFactoryDepositActual] = useState('')
  // const [factoryBalanceActual, setFactoryBalanceActual] = useState('')
  // const [qualityFeesActual, setQualityFeesActual] = useState('')
  // const [logisticsFeesActual, setLogisticsFeesActual] = useState('')
  // const [orderingFeesActual, setOrderingFeesActual] = useState('')
  // const [supplyChainFeesActual, setSupplyChainFeesActual] = useState('')

  const isProduction = process.env.NODE_ENV === 'production'

  const handleResetOffer = async () => {
    try {
      await dataProvider.deleteOfferPurchases('offer', {
        offerId: offer?.id,
      })

      await dataProvider.deleteOfferEvents('offer', {
        offerId: offer?.id,
      })

      await dataProvider.deleteOfferCustomerPurchases('offer', {
        offerId: offer?.id,
      })

      await dataProvider.deleteOfferBuyerInventories('offer', {
        offerId: offer?.id,
      })

      await dataProvider.update('offerStatus', {
        id: offer?.id,
        status: 'PENDING_PURCHASE_ORDER',
      })

      // console.log(
      //   deletedOfferPurchases,
      //   deletedOfferEvents,
      //   deletedCustomerPurchases,
      //   deletedOfferBuyerInventories,
      //   offerStatus,
      // )
      // setQuantity(0)
      // setAppUser('')
      setOffer({ ...offer, status: 'PENDING_PURCHASE_ORDER', offer_purchases: [] })
    } catch (error) {
      notify(error.message.split('.')[0], { type: 'warning' })
    }
  }

  // const handleConfirmPayment = async ({ type }) => {
  //   console.log('here!!!!')
  //   const getAccountId = () => {
  //     switch (type) {
  //       case 'FD':
  //         return {
  //           accountId: supplier.stripe_connect_account_id,
  //           amount: factoryDepositActual,
  //           transferType: 'SUPPLIER_DEPOSIT',
  //         }
  //       case 'FB':
  //         return {
  //           accountId: supplier.stripe_connect_account_id,
  //           amount: factoryBalanceActual,
  //           transferType: 'SUPPLIER_FEES',
  //         }
  //       case 'QF':
  //         return {
  //           accountId: qualityAgency.stripe_connect_account_id,
  //           amount: qualityFeesActual,
  //           transferType: 'QUALITY_FEES',
  //         }
  //       case 'LF':
  //         return {
  //           accountId: logisticsProvider.stripe_connect_account_id,
  //           amount: logisticsFeesActual,
  //           transferType: 'FREIGHT_FEES',
  //         }
  //       case 'OF':
  //         return {
  //           accountId: supplyChainAgency.stripe_connect_account_id,
  //           amount: orderingFeesActual * 100,
  //           transferType: 'ORDERING_FEES',
  //         }
  //       case 'SF':
  //         return {
  //           accountId: supplyChainAgency.stripe_connect_account_id,
  //           amount: supplyChainFeesActual * 100,
  //           transferType: 'SUPPLY_CHAIN_FEES',
  //         }
  //       default:
  //         return null
  //     }
  //   }

  //   const paymentData = getAccountId()
  //   try {
  //     await dataProvider.createTransfer('offer', {
  //       offerId: offer?.id,
  //       amount: paymentData.amount,
  //       destination: paymentData.accountId,
  //       transferType: paymentData.transferType,
  //     })

  //     // console.log(data)
  //   } catch (error) {
  //     notify(error.message.split('.')[0], { type: 'warning' })
  //   }
  // }
  window.history.replaceState({}, document.title, '/#/simulator')

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const setStripe = async () => {
      const stripePromise = loadStripe(
        'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
      )
      setStripePromise(stripePromise)
    }
    setStripe()
  }, [])

  // const factoryTotal = (offer?.investor_price_per_piece / 100) * offer?.moq
  // const dutiesTotal = ((offer?.duty_rate / 100) * factoryTotal) / 100
  // const qualityEstimate = offer?.inspection_estimate / 100
  // const logisticsEstimate = offer?.logistics_estimate / 100 + dutiesTotal
  // const depositMilestone =
  //   offer?.offer_payment_terms?.find(
  //     (item) => item.type === 'FACTORY' && item.milestone === 'Before production start',
  //   )?.value / 100
  // const factoryDeposit = factoryTotal * depositMilestone
  // const factoryBalance = factoryTotal * (1 - depositMilestone)
  // const landedTotal = factoryTotal + logisticsEstimate + qualityEstimate + dutiesTotal
  // const orderingFees = ((offer?.ordering_fees / 100) * landedTotal) / 100
  // const supplyChainFees = ((offer?.supply_chain_fees / 100) * landedTotal) / 100

  offer && console.log('OFFER', offer)

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await dataProvider.getList('offer', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'desc' },
          filter: { type: 'PUBLIC' },
        })
        setOffers(data)
        const defaultOffer = id ? data.find((el) => el.id === Number(id)) : ''
        // console.log(defaultOffer)

        setOffer(defaultOffer)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [dataProvider, notify])

  useEffect(() => {
    const getData = async () => {
      try {
        // const { data } = await dataProvider.getOne('offer', { id: offer?.id })
        // setData(data)

        // const { data: qualityAgenciesList } = await dataProvider.getStripeQualityAgencies()
        // setQualityAgencies(qualityAgenciesList)

        // const { data: logisticsProvidersList } = await dataProvider.getStripeLogisticsProviders()
        // setLogisticsProviders(logisticsProvidersList)

        // const { data: supplyChainAgenciesList } = await dataProvider.getStripeSupplyChainAgencies()
        // setSupplyChainAgencies(supplyChainAgenciesList)

        // const { data: suppliersList } = await dataProvider.getStripeSuppliers()
        // setSuppliers(suppliersList)

        const { data: userData } = await dataProvider.getAppUsers()
        setAppUsers(userData.app_user)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [dataProvider, notify])

  const localToken = localStorage.getItem('token')

  // console.log(localToken)
  const token = typeof localToken === 'string' ? localToken : localToken?.access_token
  const client = NewClient(token)

  return (
    <Box display="flex" flexDirection="column" p="10px 60px">
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent={!isProduction ? 'space-between' : 'flex-start'}
      >
        <Box display="flex">
          <span className={classes.header}>Simulator</span>
          <Select
            style={{ width: '250px', marginLeft: '20px' }}
            value={offer}
            // renderValue={(item) => getDisplayName(item, 20, 20, false)}
            onChange={(e) => setOffer(e.target.value)}
          >
            {offers?.map((item) => (
              <MenuItem value={item} key={item.id}>
                {item.id} - {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {offer?.id && !isProduction && (
          <Box>
            <Button className={classes.resetButton} onClick={() => handleResetOffer()}>
              Reset this offer Data
            </Button>
          </Box>
        )}
      </Box>
      <Box mt="20px" display="flex">
        <Box flex={1}>
          {offer?.id && (
            <ApolloProvider client={client}>
              <OfferDetails id={offer?.id} />
            </ApolloProvider>
          )}
        </Box>
        <Box flex={3} />
      </Box>
      <Box mt="20px">
        {offer?.id && (
          <ApolloProvider client={client}>
            <OfferPurchases id={offer?.id} stripePromise={stripePromise} />
          </ApolloProvider>
        )}
      </Box>
      {offer?.id && (
        <Box mt="25px" display="flex" flexDirection="column">
          <span>Fundraising Offer Purchases</span>
          {offer?.type !== 'PUBLIC' && (
            <span style={{ fontStyle: 'italic', fontSize: '14px', color: 'grey' }}>
              Not applicable to Private/Custom Offers
            </span>
          )}
          {offer?.id && (
            <OfferMakePurchase
              data={offer}
              appUsers={appUsers}
              stripePromise={stripePromise}
              disabled={offer.type !== 'PUBLIC'}
            />
          )}
        </Box>
      )}
      {offer?.id && (
        <Box mt="25px" display="flex" flexDirection="column">
          <span>Consumer Group Purchases</span>
          {offer?.type !== 'PUBLIC' && (
            <span style={{ fontStyle: 'italic', fontSize: '14px', color: 'grey' }}>
              Not applicable to Private/Custom Offers
            </span>
          )}
          {offer?.id && (
            <OfferMakeConsumerPurchase
              data={offer}
              appUsers={appUsers}
              stripePromise={stripePromise}
              disabled={offer.type !== 'PUBLIC'}
            />
          )}
        </Box>
      )}
      {/* {offer?.id && (
        <OfferPayments
          handleConfirmPayment={handleConfirmPayment}
          supplier={supplier}
          offerSupplier={offer?.client_quote?.product_quote?.supplier}
          setSupplier={setSupplier}
          suppliers={suppliers}
          qualityAgency={qualityAgency}
          setQualityAgency={setQualityAgency}
          qualityAgencies={qualityAgencies}
          logisticsProvider={logisticsProvider}
          setLogisticsProvider={setLogisticsProvider}
          logisticsProviders={logisticsProviders}
          supplyChainAgency={supplyChainAgency}
          setSupplyChainAgency={setSupplyChainAgency}
          supplyChainAgencies={supplyChainAgencies}
          factoryDeposit={factoryDeposit}
          factoryDepositActual={factoryDepositActual}
          setFactoryDepositActual={setFactoryDepositActual}
          factoryBalance={factoryBalance}
          factoryBalanceActual={factoryBalanceActual}
          setFactoryBalanceActual={setFactoryBalanceActual}
          qualityEstimate={qualityEstimate}
          qualityFeesActual={qualityFeesActual}
          setQualityFeesActual={setQualityFeesActual}
          logisticsEstimate={logisticsEstimate}
          logisticsFeesActual={logisticsFeesActual}
          setLogisticsFeesActual={setLogisticsFeesActual}
          orderingFees={orderingFees}
          orderingFeesActual={orderingFeesActual}
          setOrderingFeesActual={setOrderingFeesActual}
          supplyChainFees={supplyChainFees}
          supplyChainFeesActual={supplyChainFeesActual}
          setSupplyChainFeesActual={setSupplyChainFeesActual}
          factoryPaymentTerms={offer?.offer_payment_terms?.filter(
            (item) => item.type === 'FACTORY',
          )}
          qualityPaymentTerms={offer?.offer_payment_terms?.filter(
            (item) => item.type === 'QUALITY',
          )}
          logisticsPaymentTerms={offer?.offer_payment_terms?.filter(
            (item) => item.type === 'LOGISTICS',
          )}
        />
      )}
      {offer?.id && (
        <ApolloProvider client={client}>
          <OfferTransfers id={offer?.id} />
        </ApolloProvider>
      )}
      {offer?.id && (
        <ApolloProvider client={client}>
          <OfferManagement id={offer?.id} offerStatus={offer.status} />
        </ApolloProvider>
      )} */}
      {offer?.id && (
        <ApolloProvider client={client}>
          <OfferProductLink appUsers={appUsers} id={offer?.id} />
        </ApolloProvider>
      )}
      <Box display="flex">
        <Box flex={1}>
          {offer?.id && (
            <ApolloProvider client={client}>
              <OfferCustomerPurchases id={offer?.id} />
            </ApolloProvider>
          )}
        </Box>
        <Box flex={1}>
          {offer?.id && (
            <ApolloProvider client={client}>
              <OfferInventories id={offer?.id} />
            </ApolloProvider>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default OfferSimulator
