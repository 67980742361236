import React from 'react'
import { useTranslate } from 'react-admin'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  title: { color: '#333333', fontWeight: '600', fontSize: '36px' },
})

const SectionTitle = ({ label }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Typography gutterBottom className={classes.title}>
      {translate(label)}
    </Typography>
  )
}

export default SectionTitle
