import React from 'react'
import { AutocompleteInput } from 'react-admin'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const CustomAutocompleteInput = props => (
  <AutocompleteInput
    // options={{
    //   InputProps: { endAdornment: <ArrowDropDownIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} /> },
    // }}
    {...props}
  />
)
CustomAutocompleteInput.defaultProps = AutocompleteInput.defaultProps

export default CustomAutocompleteInput
