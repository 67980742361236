import React, {
  useState,
  useEffect,
  // useMemo
} from 'react'
import { Box } from '@material-ui/core'
import { Edit, useDataProvider, useNotify, useGetIdentity, FormWithRedirect } from 'react-admin'
import { roundTo } from '../../utils/utils'
// import { uniqueId } from 'lodash'
import ProductEditForm from './components/ProductEditForm'

const CatalogueProductEdit = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { clientId, productData, ...rest } = props
  return (
    <Edit
      {...rest}
      actions={null}
      component="div"
      id={props.match?.params?.id || props.productData?.id}
      mutationMode="pessimistic"
    >
      <CatalogueProductEditForm id={props.match?.params?.id || props.productData?.id} />
    </Edit>
  )
}

const CatalogueProductEditForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  // const [oldRecord, setOldRecord] = useState()

  const [config, setConfig] = useState([])
  const [packagingTypes, setPackagingTypes] = useState([])
  const [milestones, setMilestones] = useState([])
  const [materials, setMaterials] = useState([])
  const [imprints, setImprints] = useState([])
  const { identity } = useGetIdentity()

  // eslint-disable-next-line no-unused-vars
  const { record, clientId, productData, ...rest } = props

  const oldRecord = { ...record }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      const debugMode = false
      debugMode && console.log('GETTING DATA LISTS')
      try {
        debugMode && console.count('STEP')
        debugMode && console.time('GETTING PACKAGING STYLES')
        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        !abortController.signal.aborted && setPackagingTypes(packagingTypesTemp)
        debugMode && console.timeEnd('GETTING PACKAGING STYLES')

        debugMode && console.time('GETTING IMRPINT STYLES')
        const { data: imprintsTemp } = await dataProvider.getList('imprints', {
          filter: { own: 'imprintTechnique' },
        })
        !abortController.signal.aborted && setImprints(imprintsTemp)
        debugMode && console.timeEnd('GETTING IMPRINT STYLES')

        debugMode && console.time('GETTING MATERIALS')
        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        !abortController.signal.aborted && setMaterials(materialsTemp)
        debugMode && console.timeEnd('GETTING MATERIALS')

        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setMilestones(milestonesTemp.filter((item) => item.type === 'client'))

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const config = {}
        configTemp.global_preferences.forEach(
          (item) => (config[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(config)
      } catch (error) {
        // console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        debugMode && console.timeEnd('SAVING DATA TIMER')
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  if (milestones?.length < 1 || packagingTypes?.length < 1 || config?.length < 1) {
    return null
  }

  const factoryPrices =
    oldRecord?.product_quotes?.length > 0 && oldRecord?.product_quotes[0].prices?.length > 0
      ? oldRecord?.product_quotes[0]?.prices?.map((item) => ({
          ...(item.id && { id: item.id }),
          unitPrice: roundTo(item.unit_price / 100, 2),
          production_lead_time: item.lead_time,
          quantity: item.quantity,
          landed_air: item.est_landed_cost_air / 100 || null,
          landed_sea: item.est_landed_cost_sea / 100 || null,
          totalCBM: item.total_cbm / 100 || null,
          shipmentWeight: item.shipment_weight / 100 || null,
          totalNumCartons: item.total_cartons,
          totalDuties: item.total_duties / 100 || null,
          freightCostPerPieceAir: item.freight_cost_air_per_piece / 100 || null,
          freightCostPerPieceSea: item.freight_cost_sea_per_piece / 100 || null,
          totalFreightCostAir: item.freight_cost_air / 100 || null,
          totalFreightCostSea: item.freight_cost_sea / 100 || null,
          containers: item.containers,
        }))
      : []

  const otherSpecifications = []

  const materialOptions =
    oldRecord?.product_options?.length > 0
      ? oldRecord.product_options?.filter(
          (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
        )
      : []

  const uniqueSpecs = []
  if (oldRecord?.product_specifications?.length > 0) {
    oldRecord.product_specifications.map((spec) => {
      if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
        uniqueSpecs.push(spec.option_value.option.name)
      }
    })
  }

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material') {
      otherSpecifications?.push({
        name: spec,
        option_value: {
          value:
            oldRecord?.product_specifications?.length > 0
              ? oldRecord.product_specifications
                  .filter(
                    (qspec) =>
                      qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                  )
                  .map((item) => item.option_value?.value)
              : [],
        },
      })
    }
  })

  const oldSpecifications = []

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material') {
      oldSpecifications?.push({
        name: spec,
        values:
          oldRecord?.product_specifications?.length > 0
            ? oldRecord.product_specifications
                .filter(
                  (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                )
                .map((val) => ({ id: val.id, value: val.option_value.value }))
            : [],
      })
    }
  })
  const productMaterials = oldRecord?.product_specifications?.filter(
    (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
  )

  const uniqueCustomSpecs = []
  oldRecord?.customisations?.map((spec) => {
    if (uniqueCustomSpecs.indexOf(spec.option_value.option.name) === -1) {
      uniqueCustomSpecs.push(spec.option_value.option.name)
    }
  })

  const productCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      option_value: {
        value: oldRecord?.customisations
          ?.filter((qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null)
          .map((item) => item.option_value?.value),
      },
    }
  })

  const oldCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      values: oldRecord?.customisations?.filter(
        (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
      ),
    }
  })

  const chosenCategories = oldRecord?.product_categories
    ?.filter((item) => !item.deleted_at)
    .map((item) => item.category.id)

  const chosenTags = oldRecord?.product_tags?.map((item) => item.tag.id)

  const packagingOptions = record.product_quotes[0].packaging_options?.map((opt) => ({
    ...opt,
    costModifier: opt.cost_modifier === 0 ? 0 : opt.cost_modifier / 100 || null,
    cartonDimensions: {
      id: opt.carton_dimensions?.id,
      length: opt.carton_dimensions?.length / 100 || null,
      width: opt.carton_dimensions?.width / 100 || null,
      height: opt.carton_dimensions?.height / 100 || null,
      gross_weight: opt.carton_dimensions?.gross_weight / 100 || null,
    },
    packagingDimensions: {
      id: opt.packaging_dimensions?.id,
      length: opt.packaging_dimensions?.length / 100 || null,
      width: opt.packaging_dimensions?.width / 100 || null,
      height: opt.packaging_dimensions?.height / 100 || null,
      gross_weight: opt.packaging_dimensions?.gross_weight / 100 || null,
    },
    sizes: record.product_quotes[0].product_sizes?.map((sz) => ({
      ...sz,
      relId:
        opt.product_quote_product_size_packagings?.find(
          (el) => el.product_quote_product_size_id === sz.id,
        )?.id || null,
      active: opt.product_quote_product_size_packagings?.some(
        (el) => el.product_quote_product_size_id === sz.id,
      ),
      costModifier: sz.cost_modifier / 100,
    })),
    scmQuotePkgs: opt.scm_quote_packaging_options?.map((scmOpt) => scmOpt.id),
  }))

  const productSizes = record.product_quotes[0].product_sizes.map((size) => ({
    ...size,
    costModifier: size.cost_modifier === 0 ? 0 : size.cost_modifier / 100 || null,
    productDimensions: {
      id: size.dimensions?.id / 100,
      length: size.dimensions?.length / 100 || null,
      width: size.dimensions?.width / 100 || null,
      height: size.dimensions?.height / 100 || null,
      gross_weight: size.dimensions?.gross_weight / 100 || null,
    },
    scmQuoteSizes: size.scm_quote_product_sizes.map((scmSize) => scmSize.id),
  }))

  const configurations = record.product_quotes[0].options?.map((opt) => ({
    ...opt,
    label: opt.name,
    max_unique_selections: opt.max_unique_selections,
    additional_cost_per_unique_choice: opt.additional_cost_per_unique_choice,
    additionalCostPerChoice: opt.additional_cost_per_unique_choice / 100 || 0,
    values: opt.values?.map((val) => ({
      ...val,
      setupCost: val.setup / 100 || 0,
      unitCost: val.cost / 100 || 0,
      components: record.product_quotes[0].components?.map((cmp) => {
        // console.log(val, cmp)
        return {
          ...cmp,
          active: val.components?.some((el) => el.component_id === cmp.id),
          relId: val.components?.find((el) => el.component_id === cmp.id)?.id || null,
        }
      }),
    })),
  }))

  const productDecorations = record.product_quotes[0].decorations?.map((el) => ({
    ...el,
    imprintStyles: el.imprint_styles,
    unitCost: el.cost / 100 || 0,
  }))

  const init = {
    ...oldRecord,
    productDecorations,
    configurations,
    productCustomisations,
    packagingOptions,
    productSizes,
    // images,
    additional_files: [],
    // productDimensions: productDimensions,
    // packagingDimensions: packagingDimensions,
    // cartonDimensions: cartonDimensions,
    dutyRate: oldRecord?.duty_rate === 0 ? 0 : oldRecord?.duty_rate / 100 || null,
    toolingCost: oldRecord?.product_quotes
      ? oldRecord?.product_quotes[0]?.tooling_cost === 0
        ? 0
        : oldRecord?.product_quotes[0]?.tooling_cost / 100 || null
      : null,
    sampleCost: oldRecord?.product_quotes
      ? oldRecord?.product_quotes[0]?.sample_cost === 0
        ? 0
        : oldRecord?.product_quotes[0]?.sample_cost / 100 || null
      : null,
    customSampleCost: oldRecord?.product_quotes
      ? oldRecord?.product_quotes[0]?.custom_sample_cost === 0
        ? 0
        : oldRecord?.product_quotes[0]?.custom_sample_cost / 100 || null
      : null,
    factoryPrices,
    launch_date:
      oldRecord?.product_quotes?.length > 0 && oldRecord?.product_quotes[0].scm_quotes?.length > 0
        ? oldRecord?.product_quotes[0]?.scm_quotes[0]?.launch_date
        : null,
    quote_notes:
      oldRecord?.product_quotes?.length > 0 && oldRecord?.product_quotes[0].scm_quotes?.length > 0
        ? oldRecord?.product_quotes[0]?.scm_quotes[0]?.notes
        : null,
    clientSamplePrice:
      oldRecord?.product_quotes?.length > 0 && oldRecord?.product_quotes[0].scm_quotes?.length > 0
        ? oldRecord?.product_quotes[0]?.scm_quotes[0]?.client_sample_price
        : null,
    categories: chosenCategories || [],
    tags: chosenTags || [],
    // productColours: productColours || [],
    productMaterials: productMaterials || [],
    // sizeOptions,
    otherSpecifications: otherSpecifications || [],
    oldSpecifications: oldSpecifications || [],
    // colourOptions: colourOptions || [],
    materialOptions: materialOptions || [],
    oldCustomisations: oldCustomisations || [],
    containerRate20ft:
      oldRecord?.product_quotes[0].container_rate_20ft === 0
        ? 0
        : oldRecord?.product_quotes[0].container_rate_20ft / 100 ||
          config.estimated_container_rate_20ft / 100 ||
          null,
    containerRate40ft:
      oldRecord?.product_quotes[0].container_rate_40ft === 0
        ? 0
        : oldRecord?.product_quotes[0].container_rate_40ft / 100 ||
          config.estimated_container_rate_40ft / 100 ||
          null,
    containerRate40ftHC:
      oldRecord?.product_quotes[0].container_rate_40ft_hc === 0
        ? 0
        : oldRecord?.product_quotes[0].container_rate_40ft_hc / 100 ||
          config.estimated_container_rate_40ft_hc / 100 ||
          null,
    freightRateSea:
      oldRecord?.product_quotes[0].freight_rate_sea === 0
        ? 0
        : oldRecord?.product_quotes[0].freight_rate_sea / 100 ||
          config.estimated_freight_rate_sea / 100 ||
          null,
    freightRateAir:
      oldRecord?.product_quotes[0].freight_rate_air === 0
        ? 0
        : oldRecord?.product_quotes[0].freight_rate_air / 100 ||
          config.estimated_freight_rate_air / 100 ||
          null,
    does_sea_freight_include_duties:
      oldRecord?.product_quotes[0].does_sea_freight_include_duties ||
      config.estimated_freight_rate_sea_duties_included === 'true' ||
      false,
    // additional_files_type,
  }

  // console.log('init', init)

  // const initVals = useMemo(() => init, [init])

  const validation = (values) => {
    const errors = {}

    if (values?.catagories?.length < 1 && values?.product_categories?.length < 1) {
      errors.categories = 'Min 1 category'
    }

    !values.name ? (errors.name = 'Name') : null
    !values.short_description ? (errors.short_description = 'Short Description') : null
    !values.hts_code ? (errors.hts_code = 'HTS Code') : null
    !values.primary_photo ? (errors.primary_photo = 'Product Photo') : null

    // !values.product_dimensions?.length && !values.productDimensions?.length
    //   ? (errors.productDimensions = { length: 'Product Length' })
    //   : null
    // !values.product_dimensions?.width && !values.productDimensions?.width
    //   ? (errors.productDimensions = { width: 'Product Width' })
    //   : null
    // !values.product_dimensions?.height && !values.productDimensions?.height
    //   ? (errors.productDimensions = { height: 'Product Height' })
    //   : null
    // !values.carton_dimensions?.length && !values.cartonDimensions?.length
    //   ? (errors.carton_length = 'Carton Length')
    //   : null
    // !values.carton_dimensions?.width && !values.cartonDimensions?.width
    //   ? (errors.carton_width = 'Carton Width')
    //   : null
    // !values.carton_dimensions?.height && !values.cartonDimensions?.height
    //   ? (errors.carton_height = 'Carton Height')
    //   : null
    // !values.carton_dimensions?.gross_weight && !values.cartonDimensions?.gross_weight
    //   ? (errors.carton_weight = 'Carton Weight')
    //   : null

    values.factoryPrices?.length < 1 ? (errors.prices = 'Min 1 Price') : null
    values.product_quotes?.length < 1 || !values.product_quotes[0].supplier_id
      ? (errors.supplier_id = 'Supplier')
      : null
    // !values.product_quotes[0].packaging_type ? (errors.packaging_type = 'Packaging Type') : null
    // values.product_quotes?.length < 1 || !values.product_quotes[0].pieces_per_carton
    //   ? (errors.pieces_per_carton = 'Pieces per Carton')
    //   : null

    return errors
  }

  // console.log(record)

  return (
    <FormWithRedirect
      {...rest}
      initialValues={init}
      validate={validation}
      render={(formProps) => (
        <Box>
          {/* {resp.loading && <CircularProgress style={{ width: '20px', height: '20px' }} />} */}
          <ProductEditForm
            // categories={categories}
            packagingTypes={packagingTypes}
            materials={materials}
            imprints={imprints}
            milestones={milestones}
            config={config}
            isManager={identity?.isManager}
            clientId={clientId}
            formProps={formProps}
          />
        </Box>
      )}
    />
  )
}

export default CatalogueProductEdit
