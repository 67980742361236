import React from 'react'
import {
  Filter,
  List,
  SearchInput,
  Datagrid,
  // TextField,
  FunctionField,
  TopToolbar,
  // EditButton,
} from 'react-admin'
import { Box } from '@material-ui/core'
import RoleAdd from './RoleAdd'

const ListActions = (props) => {
  const { className, userId } = props
  return (
    <TopToolbar className={className}>
      <RoleAdd userId={userId} />
    </TopToolbar>
  )
}

export const RolesFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="role_name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Role"
      style={{ minWidth: '200px' }}
    />
  </Filter>
)

const RoleList = (props) => {
  const { userId, ...rest } = props

  // console.log(props)

  return (
    <Box p="10px 60px">
      <div>Roles</div>
      <Box display="flex">
        <Box flex={1}>
          <List
            {...rest}
            resource="user_role"
            filters={<RolesFilter />}
            filter={{ userId }}
            perPage={25}
            actions={<ListActions userId={userId} />}
            empty={false}
            // bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
          >
            <Datagrid optimized path="users">
              {/* <TextField source="id" /> */}
              <FunctionField label="Name" render={(record) => record.role?.name} />
            </Datagrid>
          </List>
        </Box>
        <Box flex={2} />
      </Box>
    </Box>
  )
}

export default RoleList
