import gql from 'graphql-tag'

const tasksGetQuery = `
    id
    created_at
    updated_at
    name
    status
    assigned_to
    received_date
    deadline_date
    assignee {
      id
      surname
      forename
      code
      email
      username
    }
    project_id
    project {
      id
      project_name
      client_name
    }
`

export const tasksGet = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getTask {
        task_by_pk(id: ${params.id}) {
          ${tasksGetQuery}
        }
      }
      `,

    parseResponse: ({ data }) => {
      if (data && data.task_by_pk) {
        return {
          data: data.task_by_pk,
        }
      }
      return false
    },
  }
}

export const tasksGetList = ({ params, context }) => {
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...(params.filter.name && { name: { _ilike: `%${params.filter.name}%` } }),
      ...(params.filter.project_name && {
        project: { project_name: { _ilike: `%${params.filter.project_name}%` } },
      }),
      ...(params.filter.assigned_to && { assigned_to: { _eq: params.filter.assigned_to } }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
        query getTasks ($where: tasks_bool_exp) {
          tasks(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              ${tasksGetQuery}
            }
            tasks_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.tasks,
      total: data.tasks_aggregate.aggregate.count,
    }),
  }
}

export const tasksCreateMany = ({ context, params, variables }) => {
  console.log(params, variables)
  return {
    ...context,
    variables: {
      objects: params.data,
    },
    query: gql`
      mutation tasksCreateMany($objects: [tasks_insert_input!]!) {
        insert_tasks(objects: $objects) {
          returning {
            id
          }
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.insert_tasks?.returning) {
        return {
          data: data.insert_tasks.returning,
        }
      }
      return false
    },
  }
}

export const tasksDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delTask {
        delete_tasks(where: {id: {_eq: ${params.id}}}) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}
