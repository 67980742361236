import React, { useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Tooltip,
  Button,
  // Chip,
  // Drawer,
  IconButton,
} from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { required, useNotify, useDataProvider, useRedirect } from 'react-admin' // import NumberText from 'react-number-format'
// import { roundTo } from '../../../utils/utils'
import Confirm from '../../components/Confirm'
// import CancelIcon from '@material-ui/icons/Cancel'
// import RemoveCircle from '@material-ui/icons/Delete'
import TextInput from '../../../components/OutlinedTextInput'
import NumberFormat from '../../components/CustomNumberFormat'
import ClientQuoteCalculator from '../../sourcingRequests/components/ClientQuoteCalculator'
import ClientQuotePaymentTermsInput from '../../components/fieldInputs/PaymentTermsInput'
import ClientQuotePackagingSizesTable from '../../components/ClientQuotePackagingSizesTable'
import ClientQuoteColourSelection from '../../components/ClientQuoteColourSelection'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput2'
import CreateOrderForm from './CreateOrderForm'
import CreateOfferForm from './CreateOfferForm'
import useStyles from '../styles'
import ClientSelectFieldInput from '../../components/fieldInputs/ClientSelectFieldInput'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ClientQuoteForm = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const [createOrderOpen, setCreateOrderOpen] = useState(false)
  const [createOfferOpen, setCreateOfferOpen] = useState(false)
  // const form = useForm()

  const {
    milestones = [],
    formProps,
    isManager,
    loading,
    handleCreateOrder,
    handleCreateOffer,
    calcUnitPrice,
  } = props
  const { values, errors, invalid } = useFormState()

  // console.log('CQFORMVALS', values, errors, invalid)
  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)

  // const minPrice =
  //   values.prices?.length > 0
  //     ? values.prices?.reduce((prev, curr) => (prev.quantity < curr.quantity ? prev : curr))
  //     : null

  // console.log('MINPRICE', minPrice)

  const getMilestones = (x) => x * 5
  const milestonesOptions = Array.from(Array(20), (_, x) => {
    const v = getMilestones(x + 1)
    return {
      name: v.toString(),
      value: v,
    }
  })

  return (
    <Box
      style={
        !isManager
          ? {
              backgroundColor: 'lightgray',
              marginBottom: '20px',
              padding: '10px',
              borderRadius: '6px',
            }
          : null
      }
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <span className={classes.sectionHeader}>Client Quote Details (ID: {values.id})</span>
          {loading && (
            <CircularProgress
              style={{
                width: '18px',
                height: '18px',
                padding: '0px',
                margin: '0px 0px 0px 20px',
              }}
            />
          )}
          <Tooltip
            title={`CQ-ID: ${values.id} PQ-ID: ${values.product_quote?.id} PRD-ID:${values.product_quote?.product?.id}`}
          >
            <InfoIcon />
          </Tooltip>
        </Box>

        <ClientSelectFieldInput
          field="client_id"
          resource="scm_quote"
          id={values.id}
          className={classes.fieldInputLeft}
          // onSave={async () => {
          //   form.change('delivery_address_id', null)
          //   form.change('destination_post_code', null)
          //   // await dataProvider.update('scm_quote', {
          //   //   data: {
          //   //     id: values.id,
          //   //     delivery_address_id: null,
          //   //     destination_post_code: null,
          //   //   },
          //   // })
          // }}
        />
        <Box display="flex" m="20px 0px">
          <span className={classes.sectionHeader}>{values.product_quote.product.name}</span>
          <Button
            style={{
              height: '25px',
              width: '120px',
              border: 'black solid 1px',
              padding: '5px',
              marginLeft: '10px',
            }}
            onClick={() => redirect(`/products/${values.product_quote.product.id}`)}
          >
            Edit Product
          </Button>
        </Box>

        <Box
          style={{
            backgroundColor: 'lightgray',
            marginBottom: '20px',
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <span style={{ fontSize: '14px', marginTop: '30px' }}>Factory Quote (read only)</span>

          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              style={{ overflow: 'hidden', backgroundColor: 'lightgray' }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Order Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Factory Cost
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Days to Produce
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Est. Landed Cost Sea
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Est. Landed Cost Air
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.factoryPrices?.map((item, index) => (
                  <FactoryQuoteRow key={`price${item.id}`} index={index} id={item.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          style={{
            backgroundColor: '#dfe5f1',
            padding: '30px',
            marginTop: '20px',
            borderRadius: '4px',
          }}
        >
          <ClientQuoteCalculator />
        </Box>
        <Box
          style={{
            backgroundColor: '#dfe5f1',
            padding: '30px',
            marginTop: '20px',
            borderRadius: '4px',
          }}
        >
          <ClientQuotePackagingSizesTable />
        </Box>
        <Box
          style={{
            backgroundColor: '#dfe5f1',
            padding: '30px',
            marginTop: '20px',
            borderRadius: '4px',
          }}
        >
          <ClientQuoteColourSelection />
        </Box>
        <Box
          style={{
            backgroundColor: '#dfe5f1',
            padding: '30px',
            marginTop: '20px',
            borderRadius: '4px',
          }}
        >
          <ClientQuotePaymentTermsInput
            milestones={milestones}
            milestonesOptions={milestonesOptions}
            id={values.id}
            resource="scm_quote_payment_terms"
            parentField="scm_quote_id"
            field="payment_terms"
            title="Client Payment Terms"
          />
        </Box>
        <Box
          style={{
            backgroundColor: '#dfe5f1',
            padding: '30px',
            marginTop: '20px',
            borderRadius: '4px',
          }}
        >
          <span className={classes.sectionHeader}>Other Details</span>
          <Box display="flex">
            <DateFieldInput
              variant="standard"
              fullWidth
              field="launch_date"
              formField="launch_date"
              resource="scm_quote"
              id={values.id}
              label="Issue date:"
              className={classes.fieldInputLeft}
            />
            <NumberFieldInput
              className={classes.fieldInputLeft}
              label="Client sample price:"
              value={values?.clientSamplePrice}
              resource="scm_quote"
              field="client_sample_price"
              formField="clientSamplePrice"
              defaultValue={0}
              decimalScale={2}
              fixedDecimalScale
              parseMultiplier={100}
              prefix="$ "
              id={values.id}
            />
            <NumberFieldInput
              className={classes.fieldInputLeft}
              label="Sample lead time:"
              value={values?.client_sample_lead_time}
              resource="scm_quote"
              field="client_sample_lead_time"
              formField="client_sample_lead_time"
              defaultValue={0}
              decimalScale={0}
              fixedDecimalScale
              suffix=" days"
              id={values.id}
            />
            <NumberFieldInput
              className={classes.fieldInputLeft}
              label="Client custom sample price:"
              value={values?.clientCustomSamplePrice}
              resource="scm_quote"
              field="client_custom_sample_price"
              formField="clientCustomSamplePrice"
              defaultValue={0}
              decimalScale={2}
              fixedDecimalScale
              parseMultiplier={100}
              prefix="$ "
              id={values.id}
            />
          </Box>
          <Box display="flex">
            <TextFieldInput
              variant="standard"
              fullWidth
              field="warranty_term"
              resource="scm_quote"
              id={values.id}
              label="Warranty term:"
              // validate={required()}
              value={values.warranty_term}
              className={classes.fieldInputLeft}
            />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="warranty_conditions"
              className={classes.fieldInputWide}
              resource="scm_quote"
              id={values.id}
              multiline
              style={{
                alignItems: 'start',
              }}
              label="Warranty conditions:"
              // validate={required()}
              value={values.warranty_conditions}
            />
          </Box>
          <Box display="flex">
            <Box flex={1}>
              <TextFieldInput
                variant="standard"
                fullWidth
                field="client_notes"
                className={classes.textInputFull}
                resource="scm_quote"
                id={values.id}
                multiline
                style={{
                  alignItems: 'start',
                }}
                label="Long description (* visible to client *):"
                // validate={required()}
                value={values.client_notes}
              />
            </Box>
            <Box flex={3} />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1}>
            <TextFieldInput
              variant="standard"
              fullWidth
              field="notes"
              className={classes.textInputFull}
              resource="scm_quote"
              id={values.id}
              multiline
              style={{
                alignItems: 'start',
              }}
              label="Additional quote notes (internal use):"
              // validate={required()}
              value={values.notes}
            />
          </Box>
          <Box flex={3} />
        </Box>
        {values.status !== 'ACTIVE' ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt="30px">
            <Confirm
              // redirect='/search'
              // redirect="show"
              // redirect="/products"
              redirect={null}
              record={formProps.record}
              basePath={formProps.basePath}
              label="LAUNCH PRODUCT"
              undoable
              invalid={formProps.invalid}
              handleSubmit={async () => {
                await dataProvider.update('scmQuote', {
                  data: { id: values.id, status: 'ACTIVE' },
                })
                // console.log(resp)
                notify('Launched Successfully.', { type: 'info' })
              }}
              saving={formProps.saving}
              disabled={formProps.saving || !isManager || formProps.invalid}
              resource="scm_quote"
            />
            {Object.values(errors)?.length > 0 ? (
              <Tooltip title={Object.values(errors)?.join(', ')}>
                <WarningIcon style={{ marginLeft: '20px' }} />
              </Tooltip>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        // mt="30px"
      >
        <Button
          disabled={invalid || values.status !== 'ACTIVE'}
          style={
            invalid || values.status !== 'ACTIVE'
              ? {
                  color: 'rgba(0, 0, 0, 0.26)',
                  margin: '16px 16px 0px 0px',
                  padding: '8px 22px',
                  position: 'relative',
                  fontSize: '0.9375rem',
                  width: '300px',
                  boxShadow: 'none',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }
              : {
                  color: '#fff',
                  margin: '16px 16px 0px 0px',
                  padding: '8px 22px',
                  position: 'relative',
                  fontSize: '0.9375rem',
                  width: '300px',
                  boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                  borderRadius: '4px',
                  backgroundColor: '#00B3C3',
                }
          }
          onClick={() => setCreateOrderOpen(true)}
        >
          CREATE PURCHASE ORDER
        </Button>
        <Button
          disabled={invalid || values.status !== 'ACTIVE'}
          style={
            invalid || values.status !== 'ACTIVE'
              ? {
                  color: 'rgba(0, 0, 0, 0.26)',
                  margin: '16px 16px 0px 0px',
                  padding: '8px 22px',
                  position: 'relative',
                  fontSize: '0.9375rem',
                  width: '300px',
                  boxShadow: 'none',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }
              : {
                  color: '#fff',
                  margin: '16px 16px 0px 0px',
                  padding: '8px 22px',
                  position: 'relative',
                  fontSize: '0.9375rem',
                  width: '300px',
                  boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                  borderRadius: '4px',
                  backgroundColor: '#00B3C3',
                }
          }
          onClick={() => setCreateOfferOpen(true)}
        >
          CREATE OFFER
        </Button>
        {values.status !== 'ACTIVE' ? (
          <Confirm
            redirect="/scmQuote"
            //   redirect={null}
            record={formProps.record}
            basePath={formProps.basePath}
            label="PUBLISH"
            undoable
            invalid={formProps.invalid}
            handleSubmit={async () => {
              await dataProvider.update('scmQuote', {
                data: { id: values.id, status: 'ACTIVE' },
              })
              // console.log(resp)
              notify('Published Successfully.', { type: 'info' })
            }}
            saving={formProps.saving}
            disabled={formProps.saving || formProps.invalid}
            resource="scm_quote"
          />
        ) : null}
      </Box>
      <Dialog
        open={createOrderOpen}
        onClose={() => setCreateOrderOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Create a Purchase Order</DialogTitle>
        <DialogContent>
          {/* <Create resource="order" basePath="/scmQuote"> */}
          {/* <FormWithRedirect
              // validate={(values) => {
              //   console.log(values)
              //   const errors = { variants: values.variants.map(() => ({})) }
              //   console.log(errors)
              //   values.variants?.map((variant, index) => {
              //     console.log(variant)
              //     !variant.quantity || variant.quantity < 1
              //       ? (errors.variants[index].quantity = 'Quantity')
              //       : null
              //   })
              //   return errors
              // }}
              initialValues={{
                ...values,
                deliveryAddressId: values.client?.default_delivery_address_id,
                billingAddressId: values.client?.default_billing_address_id,
                incoterms: 'DDP',
                variants: [{}],
              }}
              render={(formProps) => (
                <Box> */}
          <CreateOrderForm
            formProps={formProps}
            handleCreateOrder={handleCreateOrder}
            calcUnitPrice={calcUnitPrice}
          />
          {/* </Box> */}
          {/* )}
            /> */}
          {/* </Create> */}
        </DialogContent>
      </Dialog>
      <Dialog
        open={createOfferOpen}
        onClose={() => setCreateOfferOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Create an Offer</DialogTitle>
        <DialogContent>
          <CreateOfferForm
            formProps={formProps}
            handleCreateOffer={handleCreateOffer}
            calcUnitPrice={calcUnitPrice}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const FactoryQuoteRow = ({ index }) => {
  const classes = useStyles()
  // const dataProvider = useDataProvider()

  const { values } = useFormState()
  // const form = useForm()

  // console.log(index)

  // const [seaInfoOpen, setSeaInfoOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  return (
    <TableRow key={`priceRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled
          source={`factoryPrices[${index}].factoryQuantity`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          source={`factoryPrices[${index}].factoryUnitPrice`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled
          source={`factoryPrices[${index}].factoryLeadTime`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`factoryPrices[${index}].factoryLandedSea`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          prefix="$ "
          source={`factoryPrices[${index}].factoryLandedAir`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <Box display="flex">
          <IconButton
            style={{ padding: '5px' }}
            aria-label="info"
            className={classes.infoBtn}
            onClick={() => {
              setInfoOpen(true)
            }}
          >
            <InfoIcon />
          </IconButton>
          <Dialog
            fullWidth
            maxWidth="md"
            open={infoOpen}
            onClose={() => setInfoOpen(false)}
            aria-label="ViewAirInfo"
          >
            <DialogTitle>Breakdown</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        CBM
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        G.W.
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '15%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        # Cartons
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Freight Cost Sea
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Freight Cost Air
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Duties
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`factoryPrices[${index}].factoryTotalCBM`}
                          suffix=" cbm"
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`factoryPrices[${index}].factoryShipmentWeight`}
                          suffix=" kg"
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`factoryPrices[${index}].factoryTotalNumCartons`}
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`factoryPrices[${index}].factoryTotalFreightCostSea`}
                          prefix="$ "
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`factoryPrices[${index}].factoryTotalFreightCostAir`}
                          prefix="$ "
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`factoryPrices[${index}].factoryTotalDuties`}
                          prefix="$ "
                          // validate={required()}
                        />
                      </BodyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ overflow: 'hidden', marginTop: '30px' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Container Size
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Cost
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Volume Used
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Cost Type
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.factoryPrices[index]?.factoryContainers?.map((container, ind) => (
                      <TableRow key={`cont${ind}`}>
                        <BodyTableCell>
                          <TextInput
                            variant="standard"
                            fullWidth
                            style={{ padding: '0px', margin: '0px', height: '100%' }}
                            source={`factoryPrices[${index}].factoryContainers[${ind}].size`}
                            label={false}
                            disabled
                            // validate={required()}
                          />
                        </BodyTableCell>
                        <BodyTableCell>
                          <NumberFormat
                            className={classes.numberTableInput}
                            displayType="input"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            isNumericString
                            source={`factoryPrices[${index}].factoryContainers[${ind}].cost`}
                            prefix="$ "
                            disabled
                            // validate={required()}
                          />
                        </BodyTableCell>
                        <BodyTableCell>
                          <NumberFormat
                            className={classes.numberTableInput}
                            displayType="input"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            isNumericString
                            source={`factoryPrices[${index}].factoryContainers[${ind}].volume`}
                            suffix=" cbm"
                            disabled
                            // validate={required()}
                          />
                        </BodyTableCell>
                        <BodyTableCell>
                          <TextInput
                            variant="standard"
                            fullWidth
                            style={{ padding: '0px', margin: '0px', height: '100%' }}
                            source={`factoryPrices[${index}].factoryContainers[${ind}].type`}
                            label={false}
                            disabled
                            // validate={required()}
                          />
                        </BodyTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Dialog>
        </Box>
      </BodyTableCell>
    </TableRow>
  )
}

export default ClientQuoteForm
