import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const productQuotePricesDelete = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delProductQuotePrices($delPrices: [Int!]) {
        delete_product_quote_price(where: { id: { _in: $delPrices } }) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.delete_product_quote_price,
      errors,
    }),
  }
}

export const productColoursCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation insertProductColoursMany {
              insert_component_colours(
                ${jsonParams}
              ) {
                returning {
                  id
                  component_id
                  value 
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_component_colours?.returning) {
        return {
          data: data.insert_component_colours.returning,
        }
      }
      return false
    },
  }
}

export const productOptionsDeleteMany = ({ context, params }) => {
  // console.log('prodoptsdelmany', context, params)
  if (!params.data.name) {
    return new Error('Name is required')
  }

  return {
    ...context,
    variables: { name: params.data.name },
    query: gql`
      mutation productOptionsDeleteMany($name: String!) {
        delete_scm_quote_component_options(
          where: { option_value: { option: { name: { _eq: $name } } } }
        ) {
          affected_rows
        }
        delete_scm_quote_option_values(where: { option: { name: { _eq: $name } } }) {
          affected_rows
        }
        delete_scm_quote_options(where: { name: { _eq: $name } }) {
          affected_rows
        }
        delete_component_options(
          where: { option_value: { product_quote_option: { name: { _eq: $name } } } }
        ) {
          affected_rows
        }
        delete_product_quote_option_values(
          where: { product_quote_option: { name: { _eq: $name } } }
        ) {
          affected_rows
        }
        delete_product_quote_options(where: { name: { _eq: $name } }) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.delete_product_quote_options) {
        return {
          data: [data.delete_product_quote_options.affected_rows],
        }
      }
      return false
    },
  }
}
