import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  headerBackground: {
    backgroundColor: '#30485E',
    padding: '20px',
  },
  headerImage: {
    width: '150px',
    height: '150px',
  },
  headerTitle: {
    fontSize: '38px',
    lineHeight: '38px',
    color: '#FFFFFF',
    marginBottom: '10px',
  },
  subHeaderLabel: {
    color: '#BDBDBD',
    fontSize: '14px',
    lineHeight: '28px',
    width: '100px',
  },
  subHeaderValue: {
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '28px',
  },
  subHeaderDate: {
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '12px',
    width: '60px',
  },
  subHeaderDateValue: {
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '12px',
  },
  generalInformationBox: {
    paddingTop: '20px',
  },
  sectionHeader: {
    color: '#232323',
    fontSize: '26px',
    margin: '30px 0px',
  },
  sectionSubHeader: {
    color: '#232323',
    fontSize: '18px',
    margin: '10px 0px',
  },
  fieldLabel: {
    color: '#828282',
    fontSize: '12px',
    lineHeight: '12px',
    marginBottom: '20px',
    minWidth: '160px',
  },
  fieldLabelEditable: {
    color: '#828282',
    fontSize: '12px',
    lineHeight: '12px',
    // marginBottom: "20px",
    minWidth: '160px',
    justifyContent: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  fieldValue: {
    color: '#232323',
    fontSize: '12px',
    lineHeight: '12px',
    marginBottom: '20px',
  },
  fieldValueEditable: {
    color: '#232323',
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'capitalize',
  },
  addressTextSelect: {
    textTransform: 'capitalize',
  },
  addressIteratorBox: {
    display: 'flex',
    border: 'red 1px solid',
  },
  addressBoxOuter: {
    border: 'grey 1px solid',
    borderRadius: '4px',
    padding: '20px',
    width: '350px',
    margin: '5px',
  },
  areaText: {
    margin: '5px 0px -12px 0px',
    padding: '0px',
    '& .MuiInputLabel-outlined': {
      width: '100px',
    },
  },
  areaTextSelect: {
    margin: '6px 0px',
    padding: '0px',
    '& .MuiInputLabel-outlined': {
      width: '100px',
    },
    '& .MuiInputBase-fullWidth ': {
      maxWidth: '160px',
    },
  },
  addNewButton: {
    backgroundColor: '#10B1C1',
    color: '#FFFFFF',
    width: '150px',
    margin: '15px',
  },
  auditResultBoxLeftInner: {
    padding: '20px',
    margin: '45px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F8F8FA',
  },
  dropzoneWrapper: {
    border: 'lightgrey dashed 1px',
    margin: '0px 30px 0px 0px',
    minHeight: '100px',
  },
  dropZoneMain: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    padding: '5px',
  },
  dropZoneButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'lightgrey 2px solid',
    borderRadius: '4px',
    margin: '5px',
    padding: '0 20px',
    cursor: 'pointer',
  },
  fileLinks: {
    '& .MuiSvgIcon-root': {
      marginTop: '5px',
      fontSize: '16px',
    },
  },
  hideLabel: {
    '& .MuiInputLabel-root': {
      display: 'none',
    },
  },
  arrayChip: {
    height: '20px',
    fontSize: '12px',
  },
  chipField: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}))
