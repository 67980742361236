import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Layout, useAuthState, useRedirect } from 'react-admin'
import authProvider from '../authProvider'
import AppBar from './AppBar'
import Menu from './Menu'
import { darkTheme, lightTheme } from './themes'

const CustomLayout = (props) => {
  const redirect = useRedirect()
  const [gettingToken, setGettingToken] = useState(true)
  const theme = useSelector((state) => (state.theme === 'dark' ? darkTheme : lightTheme))

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const getToken = async () => {
      setGettingToken(false)
      await authProvider.getToken().catch(() => redirect('/login'))
    }
    gettingToken && getToken()
    return () => {
      abortController.abort()
    }
  }, [gettingToken])

  const { loading, authenticated } = useAuthState()

  if (!loading && authenticated) {
    return <Layout {...props} appBar={AppBar} menu={Menu} theme={theme} />
  }

  return (
    <div className="loader-container">
      <div className="loader">Loading...</div>
    </div>
  )
}

export default CustomLayout
