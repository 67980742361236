import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  numberTableInput: {
    width: '100%',
    height: '100%',
    padding: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
}))
