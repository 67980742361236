import React, { useState } from 'react'
import { Box, Tooltip, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import { uniqueId } from 'lodash'
import { useDropzone } from 'react-dropzone'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import IconButton from '@material-ui/core/IconButton'
import uploadFile from '../../../dataProvider/aws_upload'
import SelectInput from '../../../components/input/SelectInput'
import useStyles from '../styles'

const AdditionalFilesFieldInput = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [uploading, setUploading] = useState(false)

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState()

  const { field, referenceField, referenceId, resource, resourceConstraint, typesList = [] } = props

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*'],
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        setUploading(true)
        const files = [...values[field]]

        try {
          await Promise.all(
            acceptedFiles.map(async (document) => {
              const resp = await uploadFile(document)
              // console.log('UPRESP', resp)

              const fileResp = await dataProvider.create('file', {
                data: { filename: document.name, url: resp },
              })

              // console.log('FRESP', fileResp)

              files.push({
                id: uniqueId(),
                file: { id: fileResp.data.id, url: resp, filename: document.name },
              })
            }),
          )
          // console.log(files)
          setUploading(false)
          // console.log('changing')
          await form.change(field, files)

          notify('Upload complete, remember to set Type(s) and Save', { type: 'info' })
        } catch (error) {
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {/* <span>Additional Files</span> */}
        <Box display="flex">
          {editing ? (
            <Box display="flex">
              <IconButton
                onClick={async () => {
                  // console.log('ADDFILES', values[field])

                  setSaving(true)
                  try {
                    const files = values[field]

                    files.map(async (item, index) => {
                      if (typeof item.id !== 'number') {
                        // console.log('CREATING', item)
                        const params = {
                          type: item.type,
                          file_id: item.file.id,
                          [referenceField]: referenceId,
                        }
                        const createResp = await dataProvider.create(resource, {
                          data: params,
                        })
                        // console.log('CRERESP', createResp)
                        // console.log('changing')
                        form.change(`${field}[${index}].id`, createResp.data.id)
                      } else {
                        // console.log('UPDATING', item)
                        await dataProvider.update(resource, {
                          on_conflict: {
                            constraint: `^${resourceConstraint}^`,
                            update_columns: ['^type^'],
                          },
                          data: { id: item.id, type: item.type },
                        })
                      }
                    })

                    notify('Update Complete', { type: 'info' })
                  } catch (error) {
                    console.log(error)
                    notify(error.message, { type: 'warning' })
                  }
                  setSaving(false)
                  setEditing(false)
                }}
                style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
              >
                <CheckCircleOutlineOutlined fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setEditing(false)
                }}
                style={{ maxWidth: '50px', maxHeight: '50px' }}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
      {editing ? (
        <div
          {...getRootProps({
            style: {
              width: '90%',
              maxHeight: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: '0.9',
              cursor: 'pointer',
              border: 'dashed gray 1px',
            },
          })}
        >
          <input {...getInputProps()} />

          <span>{`Drag & drop, or click here`}</span>
        </div>
      ) : null}
      <Box
        display="flex"
        flexDirection="column"
        style={{
          // overflowY: 'auto',
          // width: '200px',
          margin: '5px 0px 5px',
          // maxHeight: '50px',
        }}
        justifyContent="center"
        alignItems="baseline"
      >
        {/* {previews} */}
        {values[field]?.map((item, ind) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            key={`file${item.name || item.filename}${item.id}`}
            mt="5px"
          >
            <SelectInput
              label="Additional file type"
              disabled={!editing}
              source={`${field}[${ind}].type`}
              choices={typesList}
              className={classes.fieldInputNoMP}
              optionText="name"
              optionValue="value"
              defaultValue=""
              variant="standard"
              //  validate={required()}
              onChange={(e) => {
                // console.log(e.target.value)
                // console.log('changing')
                form.change(`${field}[${ind}].type`, e.target.value)
              }}
            />
            <a style={{ lineHeight: '12px', marginLeft: '5px' }} href={item.file?.url}>
              {item.file?.filename?.length > 20 ? (
                <Box>
                  <Tooltip title={item.file?.filename}>
                    <span>{`${item.file?.filename?.substring(0, 20)}...`}</span>
                  </Tooltip>
                </Box>
              ) : (
                item.file?.filename
              )}
            </a>
            {editing ? (
              <IconButton
                aria-label="delete"
                //  className={classes.deleteBtn}
                onClick={async () => {
                  // console.log(values)
                  setDeleting(ind)
                  const files = [...values[field]]
                  // const index = files?.findIndex((obj) => obj.id === item.id)
                  files.splice(ind, 1)
                  if (typeof item.id === 'number') {
                    await dataProvider.delete(resource, {
                      data: { id: item.id },
                    })
                  }
                  // console.log('NEWARRAY', files)
                  // console.log('changing')
                  form.change(field, files)
                  setDeleting()
                }}
              >
                <DeleteOutlineIcon style={{ fontSize: '14px' }} />
              </IconButton>
            ) : null}
            {deleting === ind && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </Box>
        ))}

        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
    </div>
  )
}

export default AdditionalFilesFieldInput
