import React, { useState } from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  FunctionField,
  SelectInput,
  Datagrid,
  TextField,
  DateField,
  // DateInput,
  TopToolbar,
  DeleteButton,
  // NullableBooleanInput,
} from 'react-admin'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { Tooltip, Button } from '@material-ui/core'
import SupplierDefaultImg from '../../assets/supplier_default.png'
import CreateDialog from './components/CreateDialog'
import { getSaleableProductStatus } from '../../utils/utils'

export const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
    {/* <ReferenceInput
      source="supplier"
      reference="suppliers"
      // sort={{ field: 'id', order: 'ASC' }}
      alwaysOn
      style={{ minWidth: '200px' }}
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput> */}
    {/* <ReferenceInput
      source="sca"
      reference="users"
      label="SCA"
      // sort={{ field: 'id', order: 'ASC' }}
      filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
    </ReferenceInput> */}
    <ReferenceInput
      source="category"
      reference="category"
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    {/* <DateInput
      source="date_gte"
      label="Quote date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Quote date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    /> */}
  </Filter>
)

const ListActions = (props) => {
  const { className, prodData } = props
  const [createOpen, setCreateOpen] = useState(false)

  const { name, description } = prodData

  const errors = []

  !name && errors.push('Missing name')
  !description && errors.push('Missing description')

  return (
    <TopToolbar className={className}>
      {errors?.length > 0 ? (
        <Tooltip title={errors.join()}>
          <div>
            <Button
              style={{
                color: 'dark-gray',
                border: 'solid 1px rgba(180, 125, 80, 0.5)',
                backgroundColor: 'light-gray',
              }}
              disabled
            >
              Create
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          style={{
            color: 'black',
            border: 'solid 1px rgba(180, 125, 80, 0.5)',
            backgroundColor: 'rgba(255, 149, 102, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(255, 149, 102, 0.3)',
            },
          }}
          onClick={async () => {
            setCreateOpen(true)
          }}
        >
          Create
        </Button>
      )}

      <CreateDialog createOpen={createOpen} setCreateOpen={setCreateOpen} prodData={prodData} />
    </TopToolbar>
  )
}

const SaleableProductList = (props) => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const redirect = useRedirect()

  // const rowStyle = (record) => ({
  //   backgroundColor: record?.is_catalogue ? '#ddd' : 'white',
  //   cursor: record?.is_catalogue ? 'default' : 'pointer',
  // })

  const { productId, prodData, showCreate, basePath, ...rest } = props

  // console.log(prodData)

  return (
    <List
      {...rest}
      basePath={basePath}
      sort={{ field: 'id', order: 'DESC' }}
      filter={{
        ...(basePath === '/cliqueStock' ? { platforms: 'CLIQUESTOCK' } : null),
        ...(basePath === '/solidBlanks' ? { platforms: 'SOLIDBLANKS' } : null),
        // sca_user_id: props.isManager ? null : user?.id,
        ...(productId ? { base_product_id: productId } : null),
      }}
      filters={!productId ? <ProductFilter /> : null}
      actions={showCreate ? <ListActions prodData={prodData} /> : null}
      // actions={null}
      empty={false}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid
        path="saleableProducts"
        rowClick="edit"
        // rowStyle={rowStyle}
      >
        <TextField source="id" label="Id" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <div
              style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ maxHeight: '50px', maxWidth: '50px' }}
                src={record.primary_photo?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <FunctionField
          label="Product Name"
          render={(record) => {
            const name = record.name
            return name?.length > 40 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 40)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        {/* <FunctionField
            label="Categories"
            render={(record) =>
              record.product_categories?.map((cat) => (
                <Chip key={`cats${cat.id}`} label={cat.category.name} />
              ))
            }
          /> */}

        <FunctionField
          render={(record) => {
            return record.total_stock || 0
          }}
          label="Total Stock"
        />
        <FunctionField
          render={(record) => {
            return record.total_inventory || 0
          }}
          label="Owned Stock"
        />
        <FunctionField
          render={(record) => {
            return record.pendingStock.reduce(
              (a, b) => a + b.batches?.aggregate?.sum?.quantity || 0,
              0,
            )
          }}
          label="Pending Stock"
        />
        <FunctionField
          render={(record) =>
            record.platforms_aggregate.aggregate.count > 0 ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ fontSize: '14px', marginRight: '5px' }}>
                  {record.platforms_aggregate.aggregate.count}
                </span>
                <Tooltip title={record.platforms.map((el) => el.platform).join()}>
                  <InfoIcon style={{ fontSize: '16px' }} />
                </Tooltip>
              </div>
            ) : (
              record.platforms_aggregate.aggregate.count
            )
          }
          label="Platform(s)"
        />
        <TextField source="variants_aggregate.aggregate.count" label="Active Variants" />
        <TextField source="offers_aggregate.aggregate.count" label="Active Offers" />
        <DateField source="created_at" label="Created Date" />
        <FunctionField
          label="Status"
          render={(record) => <span>{getSaleableProductStatus(record.status)}</span>}
        />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export default SaleableProductList
