import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import NumberFormat from '../../components/CustomNumberFormat'
import useStyles from '../styles'
// import SelectInput from '@/components/input/SelectInput'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    padding: '5px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    padding: '5px',
    fontSize: 14,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    // color: '#FFFFFF',
    padding: '5px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    padding: '2px 5px 0px 5px',
    height: '30px',
    minHeight: '30px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const DimensionsTable = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()

  // console.log(values)

  const { source } = props

  return (
    <TableContainer className={classes.tableBox} component={Paper} style={{ marginTop: 5 }}>
      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
        <TableHead>
          <TableRow>
            {!props.metric ? <StyledTableCell style={{ width: '4%' }} align="right" /> : null}
            <StyledTableCell style={{ width: '24%' }} align="right">
              Length
            </StyledTableCell>
            <StyledTableCell style={{ width: '24%' }} align="right">
              Width
            </StyledTableCell>
            <StyledTableCell style={{ width: '24%' }} align="right">
              Height
            </StyledTableCell>
            <StyledTableCell style={{ width: '24%' }} align="right">
              Weight
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="prodLength">
            {!props.metric ? <StyledTableCell align="right">Metric:</StyledTableCell> : null}
            <BodyTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.length`}
                onChange={(e) => {
                  const { value } = e.target
                  form.change(`${source}.lengthIN`, value ? (value / 2.54).toFixed(2) : 0)
                }}
              />
            </BodyTableCell>
            <BodyTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.width`}
                onChange={(e) => {
                  const { value } = e.target
                  form.change(`${source}.widthIN`, (value ? value / 2.54 : 0).toFixed(2))
                }}
              />
            </BodyTableCell>
            <BodyTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.height`}
                onChange={(e) => {
                  const { value } = e.target
                  form.change(`${source}.heightIN`, value ? (value / 2.54).toFixed(2) : 0)
                }}
              />
            </BodyTableCell>
            <BodyTableCell align="right">
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${source}.gross_weight`}
                onChange={(e) => {
                  const { value } = e.target
                  const imperialValue = value ? (value * 2.205).toFixed(2) : 0
                  form.change(`${source}.weightLB`, imperialValue)

                  const seaWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          5000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableSeaWeight = seaWeight / 100 > value ? seaWeight : value * 100

                  form.change('chargeable_weight_sea', chargeableSeaWeight)

                  const airWeight =
                    parseInt(
                      (
                        ((values?.cartonDimensions?.length *
                          values?.cartonDimensions?.width *
                          values?.cartonDimensions?.height) /
                          6000) *
                        100
                      ).toFixed(2),
                    ) || null

                  const chargeableAirWeight = airWeight / 100 > value ? airWeight : value * 100

                  form.change('chargeable_weight_air', chargeableAirWeight)

                  // console.log(
                  //   'SEA',
                  //   seaWeight,
                  //   'AIR',
                  //   airWeight,
                  //   'METRIC',
                  //   value,
                  //   'CHGSEA',
                  //   chargeableSeaWeight,
                  //   'CHGAIR',
                  //   chargeableAirWeight,
                  // )
                }}
              />
            </BodyTableCell>
          </TableRow>
          {!props.metric ? (
            <TableRow key="prodWidth">
              <StyledTableCell align="right">Imperial:</StyledTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`${source}.lengthIN`}
                  onChange={(e) => {
                    const { value } = e.target
                    form.change(`${source}.length`, (value ? value * 2.54 : 0).toFixed(2))
                  }}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`${source}.widthIN`}
                  onChange={(e) => {
                    const { value } = e.target
                    form.change(`${source}.width`, (value ? value * 2.54 : 0).toFixed(2))
                  }}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`${source}.heightIN`}
                  onChange={(e) => {
                    const { value } = e.target
                    form.change(`${source}.height`, (value ? value * 2.54 : 0).toFixed(2))
                  }}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`${source}.weightLB`}
                  onChange={(e) => {
                    const { value } = e.target
                    const metricValue = value ? parseInt((value / 2.205).toFixed(2)) : 0
                    // console.log(value, metricValue)
                    form.change(`${source}.gross_weight`, metricValue)

                    const seaWeight =
                      parseInt(
                        (
                          ((values?.cartonDimensions?.length *
                            values?.cartonDimensions?.width *
                            values?.cartonDimensions?.height) /
                            5000) *
                          100
                        ).toFixed(2),
                      ) || null

                    const chargeableSeaWeight =
                      seaWeight / 100 > metricValue ? seaWeight : metricValue * 100

                    form.change('chargeable_weight_sea', chargeableSeaWeight)

                    const airWeight =
                      parseInt(
                        (
                          ((values?.cartonDimensions?.length *
                            values?.cartonDimensions?.width *
                            values?.cartonDimensions?.height) /
                            6000) *
                          100
                        ).toFixed(2),
                      ) || null

                    const chargeableAirWeight =
                      airWeight / 100 > metricValue ? airWeight : metricValue * 100

                    form.change('chargeable_weight_air', chargeableAirWeight)
                  }}
                />
              </BodyTableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DimensionsTable
