import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from 'react'
import { debounce } from 'lodash'

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useLazyEffect = (
  effect: EffectCallback,
  deps: DependencyList = [],
  dependencyNames: [],
  wait = 300,
) => {
  const cleanUp = useRef<void | (() => void)>()
  const effectRef = useRef<EffectCallback>()
  effectRef.current = useCallback(effect, deps)

  const previousDeps = usePrevious(deps, [])

  const changedDeps = deps.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames?.length > index ? dependencyNames[index] : index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    // console.log('[use-effect-debugger] ', changedDeps)
  }
  const lazyEffect = useCallback(
    debounce(() => {
      if (cleanUp.current instanceof Function) {
        cleanUp.current()
      }
      cleanUp.current = effectRef.current?.()
    }, wait),
    [],
  )
  useEffect(lazyEffect, deps)
  useEffect(() => {
    return () => (cleanUp.current instanceof Function ? cleanUp.current() : undefined)
  }, [])
}

export default useLazyEffect
