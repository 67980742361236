import React, { useState, useEffect } from 'react'
import { connect } from 'getstream'
import { Box, Badge, Menu, Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
// import CancelIcon from '@material-ui/icons/Cancel'
import { useGetIdentity, useRedirect } from 'react-admin'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { GS_API_KEY, GS_APP_ID } from '../../dataProvider/api'

const GSNotifications = () => {
  const { identity } = useGetIdentity()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [notificationsList, setNotificationsList] = React.useState({})
  const [feed, setFeed] = useState('')

  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const markAsRead = async (id, activityId, group) => {
    try {
      // console.log(id, activityId)
      const abc = await feed.get({ mark_read: [id] })
      // console.log(abc)

      const index = abc.results.some((item) => item.id === id)
        ? abc.results.findIndex((item) => item.id === id)
        : abc.results.findIndex((item) => item.group === group)
      // console.log('groupindex', index)

      abc.results[index].is_read = true
      if (abc.unread > 0) abc.unread -= 1

      // console.log('abc', abc)

      setNotificationsList(abc)
    } catch (error) {
      console.log(error)
    }
  }

  //   const deleteMessage = async id => {
  //     try {
  //       await dataProvider.deleteNotification('notification', { id })
  //       notify('Delete Successful', 'info')
  //       // console.log(data)
  //     } catch (error) {
  //       notify(errorMessage(error), 'warning')
  //     }
  //   }

  useEffect(() => {
    const abortController = new AbortController()

    const init = async () => {
      const client = connect(GS_API_KEY, identity.gsToken, GS_APP_ID)

      const notificationFeed = client.feed('notification', identity.code)

      !abortController.signal.aborted && setFeed(notificationFeed)

      let latestNotificationsList = { results: [] }
      notificationFeed
        .get({ limit: 5, offset: notificationsList.results?.length, mark_seen: true })
        .then((resp) => {
          // console.log('SETTINGINITNotificationsList', resp)
          if (!abortController.signal.aborted) {
            setNotificationsList(resp)
            latestNotificationsList = resp
          }
        })
        .catch((err) => {
          console.log(err)
        })

      const callback = (data) => {
        const oldNotifications = cloneDeep(latestNotificationsList)
        // console.log('COPYNOTIFICATIONSINIT', oldNotifications)

        data.new?.map((item) => {
          // console.log('NEWITEM', item)
          const group = oldNotifications.results?.find((obj) => obj.group === item.group)
          if (group && !group.activities.some((act) => act.id === item.id)) {
            // console.log('ADD TO GROUP', group)
            const index = oldNotifications.results?.findIndex((obj) => obj.group === item.group)
            oldNotifications.results[index].activities.unshift(item)
            oldNotifications.unread += 1
            oldNotifications.unseen += 1
          } else {
            // console.log('NEW GROUP')
            oldNotifications.results.unshift({
              activities: [item],
              activity_count: 1,
              actor_count: 1,
              created_at: item.time,
              group: item.group,
              id: `${item.id}.${item.group}`,
              is_read: false,
              is_seen: false,
              updated_at: item.time,
              verb: item.verb,
            })
            oldNotifications.unread += 1
            oldNotifications.unseen += 1
          }
        })
        // console.log('SETTINGNEWNotificationsList', oldNotifications)
        !abortController.signal.aborted && setNotificationsList(oldNotifications)
        if (!abortController.signal.aborted) {
          setNotificationsList(oldNotifications)
          latestNotificationsList = oldNotifications
        }
      }

      const successCallback = () => {
        // console.log('now listening to changes in realtime for ', identity.code)
      }

      const failCallback = () => {
        alert('something went wrong, check the console logs')
        // console.log(data)
      }

      notificationFeed.subscribe(callback).then(successCallback, failCallback)
    }

    identity && !feed && init()
  }, [identity])

  // console.log('NOTIFICATIONS', notificationsList, notificationsList?.results?.length)

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Badge badgeContent={notificationsList?.unread} color="secondary" overlap="rectangular">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        style={{ top: 25 }}
      >
        <div
          //  className={classes.column}
          style={{ borderRadius: 2 }}
        >
          {notificationsList?.results?.length > 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              //    className={classes.column}
              style={{ width: 'auto', maxHeight: '80vh', overflowY: 'scroll', padding: '10px' }}
            >
              {notificationsList?.results?.map((item) => {
                // console.log('ITEM', item)

                return (
                  <NotificationTemplate
                    key={`notification${item.id}`}
                    {...item}
                    markAsRead={markAsRead}
                  />
                )
              })}
            </Box>
          ) : (
            <div
              //    className={classes.column}
              style={{ width: 279, height: 47, justifyContent: 'center', alignItems: 'center' }}
            >
              <span
                style={{
                  fontSize: 16,
                  color: '#333',
                  fontWeight: '600',
                }}
              >
                None
                {/* {translate('pos.notification.notifications')} */}
              </span>
            </div>
          )}
        </div>
      </Menu>
    </div>
  )
}

const NotificationTemplate = (props) => {
  const redirect = useRedirect()
  const {
    id,
    verb,
    created_at,
    // actor_count,
    activity_count,
    group,
    is_read,
    // is_seen,
    // meta,
    activities = [],
    markAsRead,
  } = props
  // console.log(props)
  // console.log(is_read)
  switch (verb) {
    case 'Added Product to Sourcing Request':
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          key={`activity${id}`}
          style={{
            border: 'lightgray solid 1px',
            borderRadius: '5px',
            padding: '10px',
          }}
          //    onClick={() => {
          //      readMessage(item.id)
          //    }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <span style={{ fontSize: '14px' }}>{`${activity_count} ${
                activity_count > 1 ? 'products were' : 'product was'
              } added to your requests`}</span>
              {!is_read && (
                <div
                  style={{
                    marginLeft: '5px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'red',
                  }}
                />
              )}
            </Box>

            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ padding: '2px' }}
              onClick={(e) => {
                e.stopPropagation()
                // handleClear()
              }}
              color="inherit"
            >
              <CancelIcon />
            </IconButton> */}
          </Box>

          {activities?.map((item) => (
            <Box
              key={`act${item.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="10px 0px"
            >
              <Box display="flex" alignItems="center">
                {item.meta?.reference_photo ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '25px', width: '25px' }}
                  >
                    <img
                      style={{ maxHeight: '25px', maxWidth: '25px' }}
                      src={item.meta?.reference_photo}
                      alt=""
                    />
                  </Box>
                ) : null}
                <span
                  style={{ marginLeft: '5px', fontSize: '12px' }}
                >{`Sourcing Request: ${item.foreign_id}-${item.meta?.new_product?.product_name}`}</span>
              </Box>
              <Button
                onClick={async () => {
                  await markAsRead(id, item.id, group)
                  redirect(`/sourcingRequests/${item.foreign_id}`)
                }}
              >
                Take a look <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
              </Button>
            </Box>
          ))}

          <span
            style={{ fontSize: '10px', marginTop: '5px', textAlign: 'end', marginRight: '10px' }}
          >
            {moment(created_at).format('lll')}
          </span>
        </Box>
      )
    case 'Submitted to Client':
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          key={`activity${id}`}
          style={{
            border: 'lightgray solid 1px',
            borderRadius: '5px',
            padding: '10px',
          }}
          //    onClick={() => {
          //      readMessage(item.id)
          //    }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <span style={{ fontSize: '10px' }}>{`${activity_count} ${
                activity_count > 1 ? 'products were' : 'product was'
              } added to your requests`}</span>
              {!is_read && (
                <div
                  style={{
                    marginLeft: '5px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'red',
                  }}
                />
              )}
            </Box>

            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ padding: '2px' }}
              onClick={(e) => {
                e.stopPropagation()
                // handleClear()
              }}
              color="inherit"
            >
              <CancelIcon />
            </IconButton> */}
          </Box>

          {activities?.map((item) => (
            <Box
              key={`act${item.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="10px 0px"
            >
              <Box display="flex" alignItems="center">
                {item.meta?.reference_photo ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '25px', width: '25px' }}
                  >
                    <img
                      style={{ maxHeight: '25px', maxWidth: '25px' }}
                      src={item.meta?.reference_photo}
                      alt=""
                    />
                  </Box>
                ) : null}
                <span
                  style={{ marginLeft: '5px', fontSize: '12px' }}
                >{`Sourcing Request: ${item.foreign_id} - ${item.meta?.products?.length} products`}</span>
              </Box>

              <Button
                onClick={async () => {
                  await markAsRead(id, item.id, group)
                  redirect(`/sourcingRequests/${item.foreign_id}`)
                }}
              >
                Take a look <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
              </Button>
            </Box>
          ))}

          <span
            style={{ fontSize: '10px', marginTop: '5px', textAlign: 'end', marginRight: '10px' }}
          >
            {moment(created_at).format('lll')}
          </span>
        </Box>
      )
    case 'Client Submitted New Sourcing Request':
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          key={`activity${id}`}
          style={{
            border: 'lightgray solid 1px',
            borderRadius: '5px',
            padding: '6px',
            minHeight: '100px',
            overflowY: 'auto',
          }}
          //    onClick={() => {
          //      readMessage(item.id)
          //    }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <span style={{ fontSize: '10px' }}>A new sourcing request was submitted</span>
              {!is_read && (
                <div
                  style={{
                    marginLeft: '5px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'red',
                  }}
                />
              )}
            </Box>

            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ padding: '2px' }}
              onClick={(e) => {
                e.stopPropagation()
                // handleClear()
              }}
              color="inherit"
            >
              <CancelIcon />
            </IconButton> */}
          </Box>

          {activities?.map((item) => (
            <Box
              key={`act${item.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="5px 0px"
            >
              <Box display="flex" alignItems="center">
                {item.meta?.reference_photo ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '25px', width: '25px' }}
                  >
                    <img
                      style={{ maxHeight: '25px', maxWidth: '25px' }}
                      src={item.meta?.reference_photo}
                      alt=""
                    />
                  </Box>
                ) : null}
                <span
                  style={{ marginLeft: '5px', fontSize: '12px' }}
                >{`Sourcing Request: ${item.foreign_id}`}</span>
              </Box>

              <Button
                onClick={async () => {
                  await markAsRead(id, item.id, group)
                  redirect(`/sourcingRequests/${item.foreign_id}`)
                }}
              >
                Take a look
                <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
              </Button>
            </Box>
          ))}

          <span
            style={{ fontSize: '10px', marginTop: '5px', textAlign: 'end', marginRight: '10px' }}
          >
            {moment(created_at).format('lll')}
          </span>
        </Box>
      )
    case 'New Purchase Order Placed':
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          key={`activity${id}`}
          style={{
            border: 'lightgray solid 1px',
            borderRadius: '5px',
            padding: '6px',
            minHeight: '100px',
            overflowY: 'auto',
          }}
          //    onClick={() => {
          //      readMessage(item.id)
          //    }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <span style={{ fontSize: '10px' }}>A new purchase order has been submitted</span>
              {!is_read && (
                <div
                  style={{
                    marginLeft: '5px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'red',
                  }}
                />
              )}
            </Box>
          </Box>
          {activities?.map((item) => (
            <Box
              key={`act${item.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="5px 0px"
            >
              <Box display="flex" alignItems="center">
                {item.meta?.reference_photo ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '25px', width: '25px' }}
                  >
                    <img
                      style={{ maxHeight: '25px', maxWidth: '25px' }}
                      src={item.meta?.reference_photo}
                      alt=""
                    />
                  </Box>
                ) : null}
                <span
                  style={{ marginLeft: '5px', fontSize: '12px' }}
                >{`Purchase Order: ${item.foreign_id}`}</span>
              </Box>

              <Button
                onClick={async () => {
                  await markAsRead(id, item.id, group)
                  redirect(`/order/${item.foreign_id}`)
                }}
              >
                Take a look
                <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
              </Button>
            </Box>
          ))}

          <span
            style={{ fontSize: '10px', marginTop: '5px', textAlign: 'end', marginRight: '10px' }}
          >
            {moment(created_at).format('lll')}
          </span>
        </Box>
      )
    default:
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          key={`activity${id}`}
          style={{
            border: 'lightgray solid 1px',
            borderRadius: '5px',
            padding: '6px',
            minHeight: '100px',
            overflowY: 'auto',
          }}
          //    onClick={() => {
          //      readMessage(item.id)
          //    }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <span style={{ fontSize: '10px' }}>New notification</span>
              {!is_read && (
                <div
                  style={{
                    marginLeft: '5px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: 'red',
                  }}
                />
              )}
            </Box>
          </Box>
          {activities?.map((item) => (
            <Box
              key={`act${item.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="5px 0px"
            >
              <Box display="flex" alignItems="center">
                {item.meta?.reference_photo ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '25px', width: '25px' }}
                  >
                    <img
                      style={{ maxHeight: '25px', maxWidth: '25px' }}
                      src={item.meta?.reference_photo}
                      alt=""
                    />
                  </Box>
                ) : null}
                <span style={{ marginLeft: '5px', fontSize: '12px' }}>
                  {verb} - {moment(created_at).format('lll')}
                </span>
              </Box>
              <Button
                onClick={async () => {
                  await markAsRead(id, item.id, group)
                }}
              >
                Mark as read
                <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
              </Button>
            </Box>
          ))}
          <span
            style={{ fontSize: '10px', marginTop: '5px', textAlign: 'end', marginRight: '10px' }}
          >
            {moment(created_at).format('lll')}
          </span>
        </Box>
      )
  }
}

export default GSNotifications
