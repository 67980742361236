import React from 'react'
import { List, Datagrid, TextField, FunctionField } from 'react-admin'
import NumberFormat from 'react-number-format'
import useStyles from '../styles'

const FieldList = (props) => {
  const classes = useStyles()

  // console.log(props)

  return (
    <List
      {...props}
      //    filters={<ImprintFilter />}
      perPage={25}
      filter={{
        setting_key: [
          'estimated_container_rate_40ft_hc',
          'estimated_container_rate_40ft',
          'estimated_container_rate_20ft',
          'estimated_freight_rate_sea',
          'estimated_freight_rate_air',
          'estimated_freight_rate_dropship',
          'estimated_freight_rate_sea_duties_included',
          'paid_membership_price',
        ],
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid className={classes.listRowsCondense} rowClick="edit">
        <TextField source="setting_key" label="Field" />
        <FunctionField
          render={(record) => {
            if (record.setting_key === 'estimated_freight_rate_sea_duties_included') {
              return <TextField source="setting_value" />
            }
            return (
              <NumberFormat
                value={record.setting_value / 100}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={2}
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '0px',
                  border: '0px',
                  fontSize: '12px',
                  margin: '0px',
                }}
                prefix="$ "
                renderText={(value, props) => <div {...props}>{value}</div>}
              />
            )
          }}
          label="Price"
        />
      </Datagrid>
    </List>
  )
}

export default FieldList
