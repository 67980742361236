import React from 'react'
import {
  Filter,
  List,
  useTranslate,
  DateField,
  TextField,
  TextInput,
  ImageField,
  Datagrid,
} from 'react-admin'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
// import NotificationsDatagrid from "../../component/NotificationsDatagrid"

const useStyles = makeStyles({
  headerRow: {
    color: '#ffffff',
    backgroundColor: '#000000',
    height: '60px',
    '& .MuiButtonBase-root': {
      color: '#ffffff',
    },
    '& .MuiTableSortLabel-root': {
      color: '#ffffff',
      '& .MuiTableSortLabel-icon': {
        color: '#ffffff !important',
      },
    },
  },
})

const useQuickFilterStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))

const QuickFilter = ({ label }) => {
  const translate = useTranslate()
  const classes = useQuickFilterStyles()
  return <Chip className={classes.root} label={translate(label)} />
}

export const AuditsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Product Name" source="title@_ilike" alwaysOn />
    <QuickFilter label="resources.products.fields.stock_lte" source="stock_lte" defaultValue={10} />
  </Filter>
)

const AuditsList = (props) => {
  const classes = useStyles()
  // const user = JSON.parse(localStorage.getItem("user"))

  return (
    <List
      {...props}
      // filter={{
      //   userID: props.isManager ? null : user.id,
      // }}
      filters={props.basePath === '/audits' ? <AuditsFilter /> : null}
      actions={null}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
    >
      <Datagrid path={null} classes={{ headerCell: classes.headerRow }}>
        <TextField source="id" />
        <TextField source="supplier.name" label="Supplier Name" />
        <ImageField source="file.url" label="Audit File" />
        <DateField source="audit_date" label="Audit Date" />
        <TextField source="quality_agency.name" label="Audit Agency" />
        <TextField source="conclusion" label="Result" />
        <DateField source="approved_at" label="Approved Date" />
      </Datagrid>
    </List>
  )
}

export default AuditsList
