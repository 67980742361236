import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  NumberField,
  // Pagination,
  // TextInput,
  SearchInput,
  // DateInput,
  //   NumberInput,
  DeleteButton,
  SelectInput,
  FunctionField,
} from 'react-admin'
// import get from 'lodash/get'
import { getDisplayName, getOfferStatus } from '../../../utils/utils'
import SupplierDefaultImg from '../../../assets/supplier_default.png'
import useStyles from './styles'

// const OfferPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

export const OfferFilters = (props) => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <SearchInput
        key="search"
        InputProps={{ placeholder: 'Product SKU / Name' }}
        source="name"
        alwaysOn
        className={classes.searchBackground}
        variant="standard"
      />
      <SelectInput
        key="statusSearch"
        InputProps={{ placeholder: 'Status' }}
        label="Status"
        source="status"
        choices={[
          { name: 'Pending Purchase Order', value: 'PENDING_PURCHASE_ORDER' },
          { name: 'Pending Submission', value: 'PENDING_SUBMISSION' },
          { name: 'Pending Approval', value: 'PENDING_APPROVAL' },
          { name: 'Production', value: 'PRODUCTION' },
          { name: 'Shipping', value: 'SHIPPING' },
          { name: 'Selling ', value: 'SELLING' },
          { name: 'Cancelled ', value: 'CANCELLED' },
          { name: 'Completed ', value: 'COMPLETED' },
        ]}
        optionText="name"
        optionValue="value"
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        alwaysOn
      />
      {/* <SelectInput
        key="typeSearch"
        InputProps={{ placeholder: 'Type' }}
        label="Type"
        source="filterType"
        choices={[
          { name: 'Public', value: 'PUBLIC' },
          { name: 'Private', value: 'PRIVATE' },
        ]}
        optionText="name"
        optionValue="value"
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        alwaysOn
      /> */}
    </Filter>
  )
}

const ListCom = (props) => {
  const { saleableProductId, basePath, ...rest } = props

  // console.log(props)
  return (
    <List
      {...rest}
      basePath={basePath}
      sort={{ field: 'id', order: 'DESC' }}
      filter={{
        ...(!saleableProductId && { type: ['PUBLIC', 'PRIVATE'] }),
        ...(saleableProductId && { saleable_product_id: saleableProductId }),
      }}
      filters={!saleableProductId ? <OfferFilters /> : null}
      actions={null}
      empty={false}
      perPage={25}
      bulkActionButtons={false}
      // pagination={<OfferPagination />}
    >
      <Datagrid path="offer" rowClick="show">
        <NumberField source="id" label="Offer ID" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ width: '50px', height: '50px' }}>
              <img
                style={{ maxHeight: '50px' }}
                src={record.thumbnail?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <TextField source="name" label="Product (SKU)" />
        <FunctionField
          label="Type"
          render={(record) => (record.type === 'PRIVATE' ? 'Private' : 'Public')}
        />
        <TextField source="moq" label="Order Qty" />
        <TextField source="orders.aggregate.count" label="# of Orders" />
        <FunctionField
          label="% Fulfilled"
          render={(record) => `${((record.total_sold / record.moq) * 100).toFixed(0) || 0}%`}
        />
        {/* <FunctionField label="# of Sellers" render={() => 0} /> */}
        {/* <FunctionField source='sellers' label='# of Sellers' /> */}
        <FunctionField
          label="% Sold"
          render={(record) => {
            return record.stock?.aggregate?.sum?.stock && record.moq
              ? `${(
                  ((record.moq - record.stock?.aggregate?.sum?.stock) / record.moq) *
                  100
                ).toFixed(0)}%`
              : '0%'
          }}
        />
        <FunctionField label="Returns" render={() => 0} />
        <FunctionField
          label="Status"
          render={(record) => <span>{getOfferStatus(record.status)}</span>}
        />
        <FunctionField label="SCA" render={(record) => getDisplayName(record.sca)} />
        {!saleableProductId && <EditButton />}
        {!saleableProductId && <DeleteButton undoable={false} />}
      </Datagrid>
    </List>
  )
}

export default ListCom
