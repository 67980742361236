import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  TopToolbar,
  CreateButton,
} from 'react-admin'
import SupplierDefaultImg from '../../../assets/supplier_default.png'
import useStyles from '../styles'

const CarouselContentList = (props) => {
  const classes = useStyles()

  //   console.log('list', props)

  const ListActions = (props) => {
    const { className } = props
    return (
      <TopToolbar className={className}>
        <CreateButton label="Add" />
      </TopToolbar>
    )
  }

  return (
    <List
      {...props}
      actions={<ListActions />}
      empty={false}
      //   bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid className={classes.listRowsCondense}>
        <TextField source="id" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <img
              style={{ maxHeight: '50px' }}
              src={record.thumbnail_photo_url || SupplierDefaultImg}
              // alt=""
              alt={<img src={SupplierDefaultImg} alt="" />}
            />
          )}
        />
        <NumberField source="client_quote_id" label="Quote ID" />
        <NumberField source="product_id" label="Product ID" />
        <TextField source="name" label="Product Name" />
      </Datagrid>
    </List>
  )
}

export default CarouselContentList
