import React, { Children, cloneElement, isValidElement } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { InputHelperText, sanitizeInputRestProps, useInput } from 'react-admin'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Labeled from './Labeled'
import FileInputPreview from './FileInputPreview'

const useStyles = makeStyles(
  (theme) => ({
    dropZone: {
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    uploadZone: {
      minHeight: '150px',
      background: '#FFFFFF',
      border: '1px dashed #D9D9D9',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
    previewBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    preview: {},
    removeButton: {
      textAlign: 'center',
    },
    root: { width: '100%' },
    column: { flexDirection: 'column' },
  }),
  { name: 'RaFileInput' },
)

const FileInput = (props) => {
  const {
    accept,
    children,
    className,
    // eslint-disable-next-line no-unused-vars
    classes: classesOverride,
    format,
    helperText,
    label,
    // labelMultiple = 'ra.input.file.upload_several',
    // labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    // placeholder = 'Upload',
    resource,
    source,
    validate,
    column,
    ...rest
  } = props
  // const translate = useTranslate()
  const classes = useStyles(props)

  // turn a browser dropped file structure into expected structure
  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file
    }

    const { source, title } = Children.only(children).props

    const preview = URL.createObjectURL(file)
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    }

    if (title) {
      transformedFile[title] = file.name
    }

    return transformedFile
  }

  const transformFiles = (files) => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  })
  const { touched, error } = meta
  const files = value ? (Array.isArray(value) ? value : [value]) : []

  const onDrop = (newFiles, rejectedFiles, event) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    if (multiple) {
      onChange(updatedFiles)
    } else {
      onChange(updatedFiles[0])
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event)
    }
  }

  const onRemove = (file) => () => {
    if (multiple) {
      const filteredFiles = files.filter((stateFile) => !shallowEqual(stateFile, file))
      onChange(filteredFiles)
    } else {
      onChange(null)
    }

    if (options.onRemove) {
      options.onRemove(file)
    }
  }

  const childrenElement =
    children && isValidElement(Children.only(children)) ? Children.only(children) : undefined

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  })

  const names = column
    ? classnames(classes.root, className, classes.column)
    : classnames(classes.root, className)
  return (
    <Labeled
      id={id}
      label={label}
      className={names}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
      column={column}
      {...sanitizeInputRestProps(rest)}
    >
      <div className={classes.uploadZone} style={{ ...props.boxStyle }}>
        <div data-testid="dropzone" className={classes.dropZone} {...getRootProps()}>
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          {/* {placeholder ||
            (multiple ? <p>{translate(labelMultiple)}</p> : <p>{translate(labelSingle)}</p>)} */}
          <p style={{ padding: 0 }}>Upload</p>
          <CloudUploadIcon />
        </div>
        <FormHelperText>
          <InputHelperText touched={touched} error={error} helperText={helperText} />
        </FormHelperText>
        {children && (
          <div className={classes.previewBox}>
            {files.map((file, index) => (
              <FileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={classes.removeButton}
              >
                {cloneElement(childrenElement, {
                  record: file,
                  className: classes.preview,
                })}
              </FileInputPreview>
            ))}
          </div>
        )}
      </div>
    </Labeled>
  )
}

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
}

export default FileInput
