import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  title: {
    position: 'relative',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '40px',
  },
  outerBox: {
    padding: '0px 20px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  imageInput: {
    '& .MuiInputLabel-root': {
      display: 'none',
    },
  },
  imageDropzone: {
    // border: 'green solid 1px',
    textAlign: 'left',
  },
  imageRemoveButton: {
    // border: 'blue solid 1px',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  imagePreview: {
    // display:''
  },
  imageDisplay: {
    // border: 'yellow solid 1px',
  },
  selectBoxWidth: {
    '& .MuiInputBase-root': {
      width: '220px',
    },
  },
  fieldInputLeftNarrow: {
    // marginRight: '15px',
    minWidth: '100px',
    width: '100px',
  },
  fieldInputLeft: {
    // marginRight: '20px',
    minWidth: '120px',
    width: '120px',
  },
  fieldInput: {
    margin: '0px 50px',
    minWidth: '120px',
    width: '120px',
  },
  fieldInputNoPadding: {
    width: '120px',
    padding: '0px',
  },
  chipInputNoPadding: {
    marginLeft: '20px',
    minWidth: '220px',
    padding: '0px',
  },
  labelText: {
    fontSize: '12px',
  },
  subtitle: {
    fontSize: '16px',
  },
  primaryPhoto: {
    border: 'dashed gray 2px',
    width: '400px',
    height: '400px',
    position: 'absolute',
    zIndex: '-1',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    maxWidth: '80vw',
    margin: '10px 0px 5px',
  },
  previewsContainerImprints: {
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    maxWidth: '23vw',
    margin: '10px 0px 5px',
  },
  previewImageBox: {
    margin: '2px',
    width: '85px',
    height: '85px',
    minWidth: '85px',
    minHeight: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
    position: 'relative',
  },
  previewImage: {
    '&:hover': {
      border: 'gray solid 2px',
    },
    zIndex: '100',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    cursor: 'pointer',
  },
  previewImprintImage: {
    zIndex: '100',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
  },
  deleteBtn: {
    position: 'absolute',
    top: '0px',
    left: '45px',
    zIndex: '100',
  },
  previewImageSelected: {
    border: 'black solid 5px',
  },
  addFilesDropZone: {
    width: '400px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'dashed gray 2px',
    cursor: 'pointer',
  },
  imageDropZone: {
    width: '400px',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'dashed gray 2px',
    cursor: 'pointer',
  },
  imageDropRoot: {
    width: '400px',
    height: '400px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  imageDropNone: {
    display: 'none',
  },
  reportDropZone: {
    width: '400px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    margin: '10px 0px',
    border: 'dashed gray 2px',
    cursor: 'pointer',
  },
  // inputFieldTable: {
  //   '& .MuiFormLabel-root': {
  //     width: '80px',
  //   },
  // },
  //   labelText: {
  //     marginBottom: '10px',
  //   },
  tableInput: {
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  arrayFieldInput: {
    marginRight: '50px',
    minWidth: '50%',
    width: '50%',
    padding: '0px',
    // maxHeight: '40px',
  },
  //   fieldInput: {
  //     margin: '0px 50px',
  //     // minWidth: '200px',
  //   },
  fieldInputNoMP: {
    margin: '0px',
    padding: '0px',
    maxHeight: '40px',
    // minWidth: '200px',
  },
  fieldInputWide: {
    // marginRight: '50px',
    minWidth: '200px',
    width: '200px',
  },
  textField: {
    marginRight: '50px',
    minWidth: '200px',
  },
  numberInput: {
    // border: '0px',
    width: '25%',
    // backgroundColor: 'none',
    margin: '0px 50px',
    // '& .MuiFilledInput-root': {
    //   backgroundColor: 'white',
    // },
    // '& .MuiFormLabel-root': {
    //   zIndex: 1,
    // },
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    padding: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  numberTableInputNarrow: {
    width: '100%',
    height: '100%',
    padding: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  textInputFull: {
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  // numberInputOuter: {
  //   width: '100%',
  //   height: '100%',
  //   padding: '0px',
  // },
  subHeader: {
    fontSize: '12px',
    marginBottom: '10px',
  },
  productHeader: {
    fontSize: '34px',
  },
  quoteButton: {
    '&:hover': {
      backgroundColor: '#21C6CF',
    },

    margin: '16px 16px 0px 0px',
    fontSize: '0.9375rem',
    backgroundColor: '#00B3C3',
    borderRadius: '4px',
    color: '#fff',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    minWidth: '120px',
    position: 'relative',
    padding: '8px 22px',
  },
  popover: {
    pointerEvents: 'none',
    // height: '550px',
    // width: '550px',
  },
  paper: {
    padding: '5px',
    // width: '550px',
    // height: '550px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popoverImage: {
    maxWidth: '500px',
    maxHeight: '500px',
  },
  relatedProductsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    // width: '400px',
    margin: '10px 0px 5px',
    position: 'relative',
  },
  baseProductLabel: {
    position: 'absolute',
    fontSize: '10px',
    top: '15px',
    right: '0px',
    color: 'red',
    transform: 'rotate3d(1,1,1,45deg)',
  },
  relatedProductsImageBox: {
    width: '150px',
    minWidth: '150px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  relatedProductsImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    cursor: 'pointer',
  },
  productsWarning: {
    textAlign: 'center',
    marginTop: '30px',
    color: '#FF0000',
  },
  deleteProductBtn: {
    padding: '0px',
    color: '#FF0000',
    // position: 'absolute',
    // top: '0',
    // right: '0',
  },
  designButton: {
    height: '15px',
  },
  designImageDropzone: {
    // border: 'green solid 1px',
    textAlign: 'left',
  },
  designImageRemoveButton: {
    // border: 'blue solid 1px',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  designImagePreview: {
    // display:''
  },
  designImageDisplay: {
    maxHeight: '50px',
    maxWidth: '50px',
    // border: 'yellow solid 1px',
  },
  infoBtn: {
    padding: '0px',
    margin: '0px',
  },
  categoryInput: {
    flexGrow: 0,
    fontSize: '12px',
  },
}))
