import React, { useEffect } from 'react'
import {
  Box,
  Button,
  //   Tooltip,
  // Chip,
  //   Drawer,
} from '@material-ui/core'
import { useFormState, useForm } from 'react-final-form'
// import AddIcon from '@material-ui/icons/Add'
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  required,
  SelectInput,
  // useDataProvider,
  // TextInput,
  // NullableBooleanInput,
  //   ReferenceInput,
  // AutocompleteArrayInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
// import NumberText from 'react-number-format'
// import { roundTo } from '../../../utils/utils'
// import WarningIcon from '@material-ui/icons/Warning'
import { getDisplayAddress } from '../../../utils/utils'
import Confirm from '../../components/Confirm'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
// import CancelIcon from '@material-ui/icons/Cancel'
// import RemoveCircle from '@material-ui/icons/Delete'
// import TextInput from '../../../components/OutlinedTextInput'
import NumberFormat from '../../components/CustomNumberFormat'
// import Customiser from './Customiser'
import { makeStyles } from '@material-ui/core/styles'
// import CostsAndPricing from '../../offers/offer/components/CostsAndPricing'

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '20px',
    marginTop: '60px',
  },
  title: {
    fontSize: '16px',
  },
  numberInput: {
    width: '100%',
    height: '100%',
    // padding: '0px',
    '& .MuiFilledInput-underline::before': {
      // borderBottom: 'none',
    },
  },
  areaTextSelect: { padding: 0 },
  numberInputOrder: {
    padding: '0px',
    width: '120px',
    margin: '0px 20px',
  },
  numberInputOrderNoMargin: {
    padding: '0px',
    width: '120px',
    margin: '0px',
  },
}))

const CreateOfferForm = ({ formProps, handleCreateStock, saleableProductData }) => {
  const { values, errors } = useFormState()
  const form = useForm()
  // const dataProvider = useDataProvider()
  //   const [open, setOpen] = useState(false)

  Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)
  const classes = useStyles()

  // console.log(saleableProductData.variants)
  // console.log(values.orderVariants)

  //   const dutiesMultiplier = values.dutyRate / 100 + 1

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      const totalQuantity = values.orderVariants.reduce((previousValue, currentValue) => {
        return previousValue + currentValue?.quantity || 0
      }, 0)
      //   console.log(totalQuantity)
      !abortController.signal.aborted && form.change('quantity', totalQuantity)
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [values.orderVariants])

  /*
    Photo
    Short Desc
    Long Desc
    Product_quote_id
    Marketing Agency : 1
    SCA_ID
    Status (Pending Purchase Order)
    Quantity (moq) / Investor Price Per Piece / Logistics Lead Time
    Retail Price
    Incoterms
    Freight Method
    Type
    Client Quote ID
    HTS Code
  */

  //arr1 = saleableProductData.variants
  //arr2 = values.orderVariants
  const filteredVariants = saleableProductData.variants?.filter(
    (item) => !values.orderVariants?.some((el) => el?.saleable_product_variant_id === item?.id),
  )

  // console.log(filteredVariants)

  return (
    <Box display="flex" flexDirection="column" p="20px">
      <Box mt="10px">
        <span className={classes.tableTitle}>Stock Variants</span>
      </Box>
      <Box display="flex" mt="10px">
        <Box display="flex" flexDirection="column">
          <ArrayInput source="orderVariants" label={false}>
            <SimpleFormIterator
              TransitionProps={{ enter: false, exit: false }}
              addButton={
                <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                  <AddIcon />
                </Button>
              }
              //   disableAdd={!editing}
              removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
              //   disableRemove={!editing}
            >
              <FormDataConsumer>
                {({ getSource, scopedFormData, rest }) => (
                  <Box display="flex" alignItems="flex-end">
                    <SelectInput
                      label="Variant:"
                      source={getSource('saleable_product_variant_id')}
                      record={scopedFormData}
                      {...rest}
                      choices={
                        scopedFormData?.saleable_product_variant_id
                          ? [
                              ...filteredVariants,
                              saleableProductData.variants?.find(
                                (el) => el.id === scopedFormData?.saleable_product_variant_id,
                              ) || [],
                            ]
                          : filteredVariants
                      }
                      optionText={(el) => {
                        const colourValue = el.variant.values.find(
                          (obj) => obj.option_name === 'Color',
                        )
                        return (
                          <Box display="flex" alignItems="center">
                            <span>SKU:{el.variant.sku}</span>
                            {colourValue && (
                              <div
                                style={{
                                  marginLeft: '3px',
                                  width: '15px',
                                  height: '15px',
                                  backgroundColor: colourValue.value,
                                  borderRadius: '4px',
                                }}
                              />
                            )}
                            <span style={{ marginLeft: '3px' }}>
                              {`${el.variant.values
                                ?.filter((val) => val.option_name !== 'Color')
                                .map((value) => value.value)
                                .join(', ')}`}
                            </span>
                          </Box>
                        )
                      }}
                      optionValue="id"
                      defaultValue=""
                      fullWidth
                      variant="standard"
                      className={classes.areaTextSelect}
                    />
                    <NumberFormat
                      label="Quantity"
                      source={getSource('quantity')}
                      {...rest}
                      className={classes.numberInputOrder}
                      record={scopedFormData}
                      validate={required()}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      defaultValue={0}
                      // style={{ margin: '0px 10px' }}
                    />
                  </Box>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
            <NumberFormat
              label="Total Quantity:"
              source="quantity"
              className={classes.numberInputOrderNoMargin}
              validate={required()}
              displayType="text"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
            />
          </Box>
        </Box>
        <Box ml="5rem">
          <SelectInput
            variant="standard"
            fullWidth
            style={{ padding: '0px', margin: '0px', minWidth: '100px' }}
            label="Location"
            source="delivery_address_id"
            choices={[
              {
                id: 124,
                address_line1: 'Lei Lei Trading Co.',
                address_line2: 'Dayawan',
                city: 'Huizhou',
                province: 'Guangdong',
                country_code: 'CN',
                post_code: '516200',
              },
            ]}
            optionValue="id"
            optionText={(val) => getDisplayAddress(val)}
            // className={classes.areaText}
          />
        </Box>
      </Box>
      {/* <Box mt="10px">
        <CostsAndPricing showDetails />
      </Box> */}

      <Confirm
        // redirect="/cliquestock"
        redirect={`/cliquestock/${saleableProductData.id}?tab=stock`}
        // redirect={null}
        record={formProps.record}
        basePath={formProps.basePath}
        label="SUBMIT"
        undoable
        invalid={formProps.invalid}
        handleSubmit={async (newRecord) => {
          await handleCreateStock({
            submission: newRecord,
          })
        }}
        saving={formProps.saving}
        disabled={formProps.saving || formProps.invalid}
        resource="saleable_product_batch"
      />
    </Box>
  )
}

export default CreateOfferForm
