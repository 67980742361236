import React from 'react'
import { useRedirect } from 'react-admin'
import { Box } from '@material-ui/core'

const PlatformConfigList = () => {
  const redirect = useRedirect()

  return (
    <Box p="30px" display="flex" flexDirection="column">
      <span style={{ fontSize: '18px', marginBottom: '20px' }}>Platforms</span>
      <div onClick={() => redirect('edit', 'platformConfig', 'CLIQUESTOCK')}>
        <span style={{ fontSize: '14px', textDecoration: 'underline', cursor: 'pointer' }}>
          CliqueStock
        </span>
      </div>
    </Box>
  )
}

export default PlatformConfigList
