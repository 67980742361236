import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { useDataProvider, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  editButton: {
    border: 'solid 1px lightblue',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  imageThumb: {
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '5px',
  },
  table: { marginRight: '50px' },
  imageBox: {
    width: '152px',
    height: '152px',
    border: 'solid 2px white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  imageBoxSelected: {
    width: '152px',
    height: '152px',
    border: 'solid 2px black',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
}))
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const ColourImagesSelection = () => {
  const classes = useStyles()
  const { values } = useFormState()

  const colours = values.product_quote.options
    .find((opt) => opt.name === 'Color')
    ?.values?.map((value) => value.value)
  // console.log(colours)

  if (!colours) {
    return <span style={{ fontSize: '10px' }}>Multiple colors not defined for this product.</span>
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end"></Box>
      <Box mr="50px">
        <TableContainer component={Box} className={classes.table}>
          <Table className={classes.table} aria-label="shipping table">
            <TableHead>
              <TableRow>
                <StyledTableCell width="20%">Color</StyledTableCell>
                <StyledTableCell width="60%">Images</StyledTableCell>
                <StyledTableCell width="10%">Default</StyledTableCell>
                <StyledTableCell width="10%">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {colours?.map((colour) => (
                <ColourRow key={`colour${colour}`} colour={colour} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

const ColourRow = (props) => {
  const classes = useStyles()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { colour } = props
  const { values, initialValues } = useFormState()

  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [savingDefault, setSavingDefault] = useState(false)

  //   console.log(colour)
  const images = values.images.filter((image) => image.reference_option_value === colour)
  return (
    <TableRow>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box display="flex" alignItems="center">
          <div
            style={{
              margin: '0px 10px',
              width: '16px',
              height: '16px',
              borderRadius: '3px',
              backgroundColor: colour,
              border:
                parseInt(colour.slice(1, 3), 16) +
                  parseInt(colour.slice(3, 5), 16) +
                  parseInt(colour.slice(5, 7), 16) <
                600
                  ? `1px solid ${colour}`
                  : '1px solid #ABABAB',
            }}
          />
          {colour}
        </Box>
      </StyledTableCell>

      <StyledTableCell align="left">
        <Box display="flex" p="10px 0px">
          {images.map((img) => (
            <img
              key={`img${img.file.id}`}
              src={img.file.url}
              alt=""
              className={classes.imageThumb}
            />
          ))}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Button
          onClick={async () => {
            setSavingDefault(true)
            try {
              await dataProvider.update('saleable_product', {
                data: { id: values.id, default_colour_value: colour },
              })
              form.change('default_colour_value', colour)
              notify('Saving Complete', { type: 'info' })
              setSavingDefault(false)
            } catch (error) {
              console.log(error)
              setSavingDefault(false)
              notify(error.message, { type: 'warning' })
            }
          }}
          disabled={savingDefault || values.default_colour_value === colour}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          {savingDefault ? (
            <CircularProgress
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                top: '-15px',
                left: '5px',
              }}
            />
          ) : values.default_colour_value === colour ? (
            'DEFAULT'
          ) : (
            'Set Default'
          )}
        </Button>
      </StyledTableCell>
      <StyledTableCell align="center">
        <IconButton
          onClick={() => {
            setOpen(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      </StyledTableCell>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open || false}
        onClose={() => setOpen(false)}
        aria-label="colourimageselection"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <span>Select images for this color</span>

            <Box display="flex">
              {saving && (
                <CircularProgress
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'absolute',
                    top: '-15px',
                    left: '5px',
                  }}
                />
              )}
              <IconButton
                onClick={async () => {
                  setSaving(true)
                  // console.log('InitVals', initialValues.images)
                  // console.log('NewVals', values.images)

                  try {
                    // updateMany saleable_product_files where id _eq... etc. set reference option value
                    const params = values.images
                      .filter(
                        (img) =>
                          initialValues.images.find((inImg) => inImg.id === img.id)
                            ?.reference_option_value !== img.reference_option_value,
                      )
                      .map((el) => ({
                        where: { id: { _eq: el.id } },
                        _set: { reference_option_value: el.reference_option_value || null },
                      }))

                    // console.log(params)

                    if (params.length > 0) {
                      await dataProvider.updateMany('saleable_product_files', {
                        data: params,
                      })
                      // console.log(resp)
                    }

                    notify('Save Complete', { type: 'info' })
                    setSaving(false)
                    setOpen(false)
                  } catch (error) {
                    console.log(error)
                    setSaving(false)
                    setOpen(false)
                    notify(error.message, { type: 'warning' })
                  }
                }}
                style={{
                  maxWidth: '30px',
                  maxHeight: '30px',
                }}
              >
                <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpen(false)
                }}
                style={{
                  maxWidth: '30px',
                  maxHeight: '30px',
                }}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
            {values.images.map((image, index) => (
              <Box
                m="5px"
                p="5px"
                key={`prodImg${image.id}`}
                className={
                  image.reference_option_value === colour
                    ? classes.imageBoxSelected
                    : classes.imageBox
                }
                onClick={() => {
                  form.change(
                    `images[${index}].reference_option_value`,
                    image.reference_option_value === colour ? null : colour,
                  )
                }}
              >
                <img className={classes.image} src={image.file.url} alt="" />
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </TableRow>
  )
}

export default ColourImagesSelection
