import React from 'react'
import { useCreateSuggestionContext, useCreate } from 'react-admin'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'

const ClientSelectWithCreateForm = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext()
  const [value, setValue] = React.useState(filter || '')
  const [create] = useCreate('organisation')

  const handleSubmit = (event) => {
    event.preventDefault()
    create(
      {
        payload: {
          data: {
            client_name: value,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue('')
          onCreate(data)
        },
      },
    )
  }

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>
        Note: this creates an empty client without assigning any users to it.
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New client name"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ClientSelectWithCreateForm
