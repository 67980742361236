import React, { useState } from 'react'
import { useDataProvider, useNotify, useRefresh, FormWithRedirect } from 'react-admin'
import { Button, Dialog, DialogTitle } from '@material-ui/core'
import AddressForm from './AddressForm'

const AddressAddForm = ({ clientId }) => {
  const [open, setOpen] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSubmit = async (submission) => {
    const params = {
      delivery_contact: submission.delivery_contact,
      delivery_email: submission.delivery_email,
      delivery_mobile: submission.delivery_mobile,
      organisation_id: clientId,
      address_line1: submission.address_line1,
      address_line2: submission.address_line2,
      city: submission.city,
      province: submission.province,
      country_code: submission.country,
      post_code: submission.post_code,
      type: submission.type,
    }

    // console.log(params, submission)

    try {
      await dataProvider.create('address', {
        data: params,
      })

      // setOpen(false)
      refresh()

      // notify('Created Successfully.', 'info')
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Add New Address</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create a new address</DialogTitle>
        <FormWithRedirect
          render={(formProps) => (
            <AddressForm formProps={formProps} setOpen={setOpen} handleSubmit={handleSubmit} />
          )}
        />
      </Dialog>
    </div>
  )
}

export default AddressAddForm
