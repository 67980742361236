import React from 'react'
import { useFormState } from 'react-final-form'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Paper,
  Collapse,
  IconButton,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import { getDisplayName } from '../../../utils/utils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  accordionStyle: {
    '& .MuiAccordionSummary-root': {
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
}))
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '8px',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
  },
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    fontSize: 12,
  },
}))(TableCell)
const StyledInsetTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    // color: '#000000',
    padding: '3px',
    height: '6px',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    fontSize: 10,
  },
  body: {
    backgroundColor: '#FFFFFF',
    // color: '#000000',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '3px',
    height: '6px',
    fontSize: 10,
  },
}))(TableCell)

const OfferPurchaseDetails = () => {
  const classes = useStyles()

  const { values } = useFormState()

  // console.log('OFFERPURCHASEDATA:', purchaseData)

  const OfferPurchaseRow = ({ item }) => {
    const [invoicesOpen, setInvoicesOpen] = React.useState(0)

    const totalPaid = item.offer_purchase_invoices?.reduce((a, b) => {
      return b.invoice.status === 'FULFILLED' ? a + b.invoice.amount_due : a
    }, 0)
    const totalInvoiced = item.offer_purchase_invoices?.reduce((a, b) => {
      return a + b.invoice.amount_due
    }, 0)

    return (
      <React.Fragment>
        <TableRow>
          <StyledTableCell>
            <span style={{ fontSize: '12px' }}>{item.offer_purchase_invoices.length}</span>
            {item.offer_purchase_invoices.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setInvoicesOpen(item.id === invoicesOpen ? 0 : item.id)}
              >
                {invoicesOpen === item.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </StyledTableCell>
          <StyledTableCell>{item.id}</StyledTableCell>
          <StyledTableCell>{item.client?.id}</StyledTableCell>
          <StyledTableCell>{item.client?.name}</StyledTableCell>
          <StyledTableCell>{item.buyer?.id}</StyledTableCell>
          <StyledTableCell>{getDisplayName(item.buyer, 20, 20, true)}</StyledTableCell>
          <StyledTableCell>{item.quantity}</StyledTableCell>
          <StyledTableCell>
            <NumberFormat
              value={item.amount / 100}
              // className='numberFormat'
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
          </StyledTableCell>
          <StyledTableCell>
            <NumberFormat
              value={totalInvoiced / 100}
              // className='numberFormat'
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
          </StyledTableCell>
          <StyledTableCell>
            <NumberFormat
              value={totalPaid / 100}
              // className='numberFormat'
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
          </StyledTableCell>
          <StyledTableCell>{item.client?.stripe_connected_account_id}</StyledTableCell>
          <StyledTableCell>{moment(item.created_at).format('L')}</StyledTableCell>
          <StyledTableCell>{item.status}</StyledTableCell>
        </TableRow>
        {invoicesOpen === item.id && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <OfferPurchaseInvoiceTable
                  clientId={item.client.id}
                  invoices={item?.offer_purchase_invoices}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  }

  return (
    <Box display="flex" flexDirection="column" mt="20px">
      <span className={classes.title}>Offer Purchase Details</span>
      <Box display="flex" mt="10px">
        <Box flex={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Invoices
                  </StyledTableCell>
                  <StyledTableCell
                    width="6%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Id
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Client Id
                  </StyledTableCell>
                  <StyledTableCell
                    width="20%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Client
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Buyer Id
                  </StyledTableCell>
                  <StyledTableCell
                    width="20%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Buyer
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Total Amount
                  </StyledTableCell>
                  <StyledTableCell
                    width="9%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Total Invoiced
                  </StyledTableCell>
                  <StyledTableCell
                    width="9%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Total Paid
                  </StyledTableCell>
                  <StyledTableCell
                    width="20%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Stripe Id
                  </StyledTableCell>
                  <StyledTableCell
                    width="8%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Date
                  </StyledTableCell>
                  <StyledTableCell
                    width="10%"
                    style={{
                      borderTop: 'black solid 2px',
                      borderBottom: 'black solid 2px',
                      textAlign: 'center',
                      // borderRight: 'none',
                    }}
                  >
                    Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.offer_purchases?.map((item) => (
                  <OfferPurchaseRow key={`offerPurchaseRow${item?.id}`} item={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
}

const OfferPurchaseInvoiceTable = ({ invoices = [] }) => {
  const [paymentsOpen, setPaymentsOpen] = React.useState(0)

  return (
    <Box margin={1}>
      <Table size="small" aria-label="invoices">
        <TableHead>
          <TableRow>
            <StyledInsetTableCell>Payments</StyledInsetTableCell>
            <StyledInsetTableCell>ID</StyledInsetTableCell>
            <StyledInsetTableCell align="right">Amount Due</StyledInsetTableCell>
            <StyledInsetTableCell align="right">Subject</StyledInsetTableCell>
            <StyledInsetTableCell align="right">Issue Date</StyledInsetTableCell>
            {/* <StyledInsetTableCell align="right">Fulfilled Date</StyledInsetTableCell> */}
            <StyledInsetTableCell align="right">Status</StyledInsetTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices?.map((item) => (
            <React.Fragment key={item.invoice?.id}>
              <TableRow>
                <StyledInsetTableCell>
                  <span style={{ fontSize: '10px' }}>{item.invoice?.invoice_payments?.length}</span>
                  {item.invoice?.invoice_payments?.length > 0 && (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setPaymentsOpen(item.id === paymentsOpen ? 0 : item.id)}
                    >
                      {paymentsOpen === item.id ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  )}
                </StyledInsetTableCell>
                <StyledInsetTableCell>{item.invoice?.id}</StyledInsetTableCell>
                <StyledInsetTableCell align="right">
                  <NumberFormat
                    value={item.invoice.amount_due / 100}
                    // className='numberFormat'
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                  />
                </StyledInsetTableCell>
                <StyledInsetTableCell align="right">{item.invoice?.subject}</StyledInsetTableCell>
                <StyledInsetTableCell align="right">
                  {item.invoice?.issue_date}
                </StyledInsetTableCell>
                {/* <StyledInsetTableCell align="right">
                    {item.invoice?.fulfilled_date}
                  </StyledInsetTableCell> */}
                <StyledInsetTableCell align="right">{item.invoice?.status}</StyledInsetTableCell>
              </TableRow>
              {paymentsOpen === item.id && (
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <OfferPurchaseInvoicePaymentsTable
                        payments={item.invoice?.invoice_payments}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

const OfferPurchaseInvoicePaymentsTable = ({ payments }) => (
  <Box margin={0}>
    <Table size="small" aria-label="invoices">
      <TableHead>
        <TableRow>
          <StyledInsetTableCell>Id</StyledInsetTableCell>
          <StyledInsetTableCell align="right">Amount</StyledInsetTableCell>
          <StyledInsetTableCell align="right">Payer</StyledInsetTableCell>
          <StyledInsetTableCell align="right">Stripe Payment Id</StyledInsetTableCell>
          <StyledInsetTableCell align="right">Date</StyledInsetTableCell>
          <StyledInsetTableCell align="right">Status</StyledInsetTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payments?.map((item) => (
          <TableRow key={`paymentrow${item.id}`}>
            <StyledInsetTableCell align="right">{item.id}</StyledInsetTableCell>
            <StyledInsetTableCell align="right">
              ${(item.amount / 100).toFixed(2)}
            </StyledInsetTableCell>
            <StyledInsetTableCell align="right">
              {getDisplayName(item.payer, 20, 20, true)}
            </StyledInsetTableCell>
            <StyledInsetTableCell align="right">{item.payment_intent_id}</StyledInsetTableCell>
            <StyledInsetTableCell align="right">
              {moment(item.created_at).format('L')}
            </StyledInsetTableCell>
            <StyledInsetTableCell align="right">{item.status}</StyledInsetTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default OfferPurchaseDetails
