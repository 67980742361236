import React from 'react'
import {
  // useRedirect,
  useDataProvider,
  Create,
  FormWithRedirect,
  TextInput,
  DateInput,
  required,
  useGetIdentity,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  useRefresh,
} from 'react-admin'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  textInput: {
    marginLeft: '10px',
  },
}))

const CreateDialog = (props) => {
  //   const classes = useStyles()
  const dataProvider = useDataProvider()
  // const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const { createOpen, setCreateOpen } = props
  const refresh = useRefresh()

  const handleSubmit = async (submission) => {
    await dataProvider.createMany('project', {
      data: submission.projects.map((project) => ({
        project_name: project.project_name,
        sca_id: identity.userId,
        client_name: project.client_name,
        brand_name: project.brand_name,
        status: project.status,
        received_date: project.received_date,
      })),
    })
    refresh()
    setCreateOpen(false)
    // redirect(`/projects`)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={createOpen || false}
      onClose={() => setCreateOpen(false)}
      aria-label="CreateProjects"
    >
      <DialogTitle>Create Project(s)</DialogTitle>
      <DialogContent>
        <Create resource="project" basePath="/project">
          <FormWithRedirect
            initialValues={{ projects: [{}] }}
            {...props}
            render={() => (
              <div>
                {createOpen ? <CreateProjectPopupForm handleSubmit={handleSubmit} /> : null}
              </div>
            )}
          />
        </Create>
      </DialogContent>
    </Dialog>
  )
}

const CreateProjectPopupForm = ({ handleSubmit }) => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <Box>
      <ArrayInput source="projects" label="">
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          addButton={
            <Button
              style={{
                backgroundColor: '#F8F8FA',
                color: 'black',
              }}
            >
              <AddIcon />
              Add another project
            </Button>
          }
          removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, rest }) => (
              <Box display="flex">
                <DateInput
                  variant="standard"
                  fullWidth
                  source={getSource('received_date')}
                  {...rest}
                  label="Received date"
                  record={scopedFormData}
                  validate={required()}
                />
                <TextInput
                  className={classes.textInput}
                  variant="standard"
                  fullWidth
                  source={getSource('project_name')}
                  {...rest}
                  label="Project name"
                  record={scopedFormData}
                  validate={required()}
                />
                <TextInput
                  className={classes.textInput}
                  variant="standard"
                  fullWidth
                  source={getSource('client_name')}
                  {...rest}
                  record={scopedFormData}
                  label="Client name"
                  validate={required()}
                />
                <TextInput
                  className={classes.textInput}
                  variant="standard"
                  fullWidth
                  source={getSource('brand_name')}
                  {...rest}
                  record={scopedFormData}
                  label="Brand name"
                  // validate={required()}
                />
                <TextInput
                  className={classes.textInput}
                  variant="standard"
                  fullWidth
                  source={getSource('status')}
                  {...rest}
                  record={scopedFormData}
                  label="Stage/Status"
                  validate={required()}
                />
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="80px">
        <Button
          className={classes.createButton}
          onClick={async () => {
            await handleSubmit(values)
          }}
        >
          CREATE
        </Button>
      </Box>
    </Box>
  )
}

export default CreateDialog
