import React from 'react'
import {
  TextField,
  FormWithRedirect,
  Edit,
  useNotify,
  useDataProvider,
  required,
  TextInput,
} from 'react-admin'
import { Card, Box, makeStyles } from '@material-ui/core'
import Confirm from '../../components/Confirm'
import NumberFormat from '../../components/CustomNumberFormat'
// import uploadFile from '../../../dataProvider/upload'

const useStyles = makeStyles({
  numberTableInput: {
    // width: '100%',
    // height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    // '& .MuiFilledInput-underline::before': {
    //   borderBottom: 'none',
    // },
  },
})

export const RateEdit = (props) => (
  <Edit {...props}>
    <FormData params={props} />
  </Edit>
)

const FormData = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { record } = props
  const classes = useStyles()

  // console.log(props)
  const handleSubmit = async (submission) => {
    // console.log(submission)
    const params = {
      data: {
        id: submission.id,
        setting_value:
          record.setting_key === 'estimated_freight_rate_sea_duties_included'
            ? submission.setting_value
            : `${submission.value * 100}`,
      },
      //   on_conflict: {
      //     constraint: '^tag_pkey^',
      //     update_columns: ['^name^'],
      //   },
    }

    // console.log(params)
    try {
      await dataProvider.update('defaultRates', {
        ...params,
      })
      notify('Updated Successfully.', { type: 'info' })
    } catch (error) {
      // console.log(error)
      notify(error.message, 'warning')
    }
  }

  return (
    <FormWithRedirect
      {...props}
      // validate={(values) => {
      //   const errors = {}
      //   if (values.name?.length > 16) {
      //     errors.name = 'Must be 16 characters or less'
      //   }
      //   return errors
      // }}
      initialValues={{ ...record, value: record.setting_value / 100 }}
      render={(formProps) => (
        <Card>
          <Box display="flex" flexDirection="column" p="30px">
            <TextField source="setting_key" />
            {record.setting_key === 'estimated_freight_rate_sea_duties_included' ? (
              <TextInput source="setting_value" variant="standard" fullWidth label="Value:" />
            ) : (
              <NumberFormat
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source="value"
                label="Value"
                prefix="$ "
                validate={required()}
              />
            )}
          </Box>
          <Confirm
            redirect="/defaultRates"
            // redirect={`/${props.resource}`}
            record={formProps.record}
            basePath={formProps.basePath}
            label="SUBMIT"
            undoable
            isSave
            // invalid={formProps.invalid}
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            disabled={formProps.saving}
            resource="tag"
          />
        </Card>
      )}
    />
  )
}
