import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import lodashGet from 'lodash/get'
// import { uniqueId } from "lodash"
import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import { useDataProvider, useNotify } from 'react-admin'
import uploadFile from '../../../../../dataProvider/upload'
import useStyles from '../styles'

const FilesSection = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  // console.log(props.data)

  const [businessLicenseFile, setBusinessLicenseFile] = useState(
    props.data?.files?.filter((item) => item.type === 'business-licence')[0],
  )
  const [catalogsFiles, setCatalogsFiles] = useState(
    props.data?.files?.filter((item) => item.type === 'product-catalog') || [],
  )
  const [auditsFiles, setAuditsFiles] = useState(props.data?.audits?.map((item) => item.file) || [])
  const [photosFiles, setPhotosFiles] = useState(
    props.data?.files?.filter((item) => item.type === 'company-photo') || [],
  )
  const [certificatesFiles, setCertificatesFiles] = useState(
    props.data?.certifications?.map((item) => item.document) || [],
  )

  useEffect(() => {
    const setFiles = async () => {
      setBusinessLicenseFile(
        props.data?.files?.filter((item) => item.type === 'business-licence')[0] || {},
      )
      setCatalogsFiles(props.data?.files?.filter((item) => item.type === 'product-catalog') || [])
      setAuditsFiles(props.data?.audits?.map((item) => item.file) || [])
      setPhotosFiles(props.data?.files?.filter((item) => item.type === 'company-photo') || [])
      setCertificatesFiles(props.data?.certifications?.map((item) => item.document) || [])
    }
    setFiles()
  }, [props.data?.files])

  // console.log(businessLicenseFile, catalogsFiles, auditsFiles, photosFiles, certificatesFiles)

  const handleDropBusinessLicense = async (acceptedFiles) => {
    // console.log('UPLOADING', acceptedFiles[0])
    const resp = await uploadFile([acceptedFiles[0]])
    // console.log('UPLOADED FILE: ', resp.data.files[0].url)

    const params = {
      supplier: props.data?.id,
      url: resp.data.files[0].url,
      filename: acceptedFiles[0].name,
      type: 'business-licence',
    }

    // console.log('PMS', params, businessLicenseFile)
    // return null
    try {
      const { data } = await dataProvider.createSupplierFile('supplier', { ...params })

      // console.log("RESPDATA", data)
      if (data.id !== undefined) {
        // if (businessLicenseFile) deleteFile(businessLicenseFile.id)
        setBusinessLicenseFile({
          file: { url: resp.data.files[0].url, filename: acceptedFiles[0].name },
        })
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const handleDropCatalogs = async (acceptedFiles) => {
    if (!acceptedFiles) {
      return
    }

    const _catalogFiles = catalogsFiles

    await Promise.all(
      acceptedFiles.map(async (file) => {
        // console.log('UPLOADING', file)
        const resp = await uploadFile([file])
        // console.log('UPLOADED FILE: ', resp.data.files[0].url)

        const params = {
          supplier: props.data?.id,
          url: resp.data.files[0].url,
          filename: file.name,
          type: 'product-catalog',
        }
        // return null
        try {
          const { data } = await dataProvider.createSupplierFile('supplier', { ...params })
          // console.log("RESPDATA", data)
          if (data.id !== undefined) {
            _catalogFiles.push({
              id: parseInt(data.id),
              file: { url: resp.data.files[0].url, filename: file.name },
            })
          }
          // _catalogFiles.push({ file: { url: resp.data.files[0].url, filename: file.name } })
        } catch (error) {
          // console.log(error)
          notify(
            lodashGet(
              error.graphQLErrors,
              '0.extensions.exception.errors.0.message',
              'Data update error',
            ),
            'warning',
          )
        }
      }),
    )

    setCatalogsFiles(_catalogFiles)

    refresh()
  }
  // const handleDropAudits = async acceptedFiles => {
  //   if (!acceptedFiles) {
  //     return
  //   }

  //   const _auditFiles = auditsFiles

  //   await Promise.all(
  //     acceptedFiles.map(async file => {
  //       console.log("UPLOADING", file)
  //       const resp = await uploadFile([file])
  //       console.log("UPLOADED FILE: ", resp.data.files[0].url)

  //       const params = {
  //         supplier: props.data?.id,
  //         url: resp.data.files[0].url,
  //         filename: file.name,
  //         type: "audit-result",
  //       }
  //       // return null
  //       try {
  //         const { data } = await dataProvider.createSupplierFile("supplier", { ...params })
  //         // console.log("RESPDATA", data)
  //         if (data.id !== undefined) {
  //           _auditFiles.push({
  //             id: parseInt(data.id),
  //             file: { url: resp.data.files[0].url, filename: file.name },
  //           })
  //         }
  //       } catch (error) {
  //         console.log(error)
  //         notify(
  //           lodashGet(
  //             error.graphQLErrors,
  //             "0.extensions.exception.errors.0.message",
  //             "Data update error",
  //           ),
  //           "warning",
  //         )
  //       }
  //     }),
  //   )

  //   setAuditsFiles(_auditFiles)

  //   refresh()

  //   // console.log("AF", auditsFiles)
  // }
  const handleDropPhotos = async (acceptedFiles) => {
    if (!acceptedFiles) {
      return
    }

    const _photosFiles = photosFiles

    await Promise.all(
      acceptedFiles.map(async (file) => {
        // console.log('UPLOADING', file)
        const resp = await uploadFile([file])
        // console.log('UPLOADED FILE: ', resp.data.files[0].url)

        const params = {
          supplier: props.data?.id,
          url: resp.data.files[0].url,
          filename: file.name,
          type: 'company-photo',
        }
        // return null
        try {
          const { data } = await dataProvider.createSupplierFile('supplier', { ...params })
          // console.log("RESPDATA", data)
          if (data.id !== undefined) {
            _photosFiles.push({
              id: parseInt(data.id),
              file: { url: resp.data.files[0].url, filename: file.name },
            })
          }
          // _photosFiles.push({ file: { url: resp.data.files[0].url, filename: file.name } })
        } catch (error) {
          // console.log(error)
          notify(
            lodashGet(
              error.graphQLErrors,
              '0.extensions.exception.errors.0.message',
              'Data update error',
            ),
            'warning',
          )
        }
      }),
    )

    setPhotosFiles(_photosFiles)

    refresh()
  }
  // const handleDropCertifications = async acceptedFiles => {
  //   if (!acceptedFiles) {
  //     return
  //   }

  //   const _certificatesFiles = certificatesFiles

  //   await Promise.all(
  //     acceptedFiles.map(async file => {
  //       console.log("UPLOADING", file)
  //       const resp = await uploadFile([file])
  //       console.log("UPLOADED FILE: ", resp.data.files[0].url)

  //       const params = {
  //         supplier: props.data?.id,
  //         url: resp.data.files[0].url,
  //         filename: file.name,
  //         type: "certification",
  //       }
  //       // return null
  //       try {
  //         const { data } = await dataProvider.createSupplierFile("supplier", { ...params })
  //         // console.log("RESPDATA", data)
  //         if (data.id !== undefined) {
  //           _certificatesFiles.push({
  //             id: parseInt(data.id),
  //             file: { url: resp.data.files[0].url, filename: file.name },
  //           })
  //         }
  //       } catch (error) {
  //         console.log(error)
  //         notify(
  //           lodashGet(
  //             error.graphQLErrors,
  //             "0.extensions.exception.errors.0.message",
  //             "Data update error",
  //           ),
  //           "warning",
  //         )
  //       }
  //     }),
  //   )

  //   setCertificatesFiles(_certificatesFiles)
  //   refresh()
  // }

  const deleteFile = async (params) => {
    // console.log("DEL", params)
    try {
      await dataProvider.deleteSupplierFile('supplier', { id: params })
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  return (
    <Box m="30px 0px">
      <span className={classes.sectionHeader}>Files</span>
      <Box display="flex">
        <Box flex={1}>
          <span className={classes.subTitle}>Business License:</span>
          <Box className={classes.dropzoneWrapper}>
            <Dropzone
              onDrop={handleDropBusinessLicense}
              // accept='image/*'
              minSize={1024}
              maxSize={3072000}
            >
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps({ className: classes.dropZoneMain })}>
                  <input {...getInputProps()} />
                  <p className={classes.dropZoneButton}>
                    <OpenInBrowserIcon />
                    Upload
                  </p>
                </Box>
              )}
            </Dropzone>

            {businessLicenseFile && businessLicenseFile?.file && businessLicenseFile?.file?.url ? (
              <Box display="flex" className={classes.fileLinks}>
                <AttachFileIcon sx={{ fontSize: 8 }} />
                <a
                  style={{ display: 'flex', alignItems: 'center' }}
                  target="_blank"
                  rel="noreferrer"
                  href={businessLicenseFile.file ? businessLicenseFile.file.url : null}
                >
                  {businessLicenseFile?.file?.filename.length > 20
                    ? `${businessLicenseFile?.file?.filename?.substring(0, 20)}...`
                    : businessLicenseFile?.file?.filename}
                </a>
                <IconButton
                  onClick={() => {
                    deleteFile(businessLicenseFile.id)
                    setBusinessLicenseFile(null)
                    refresh()
                  }}
                  style={{ maxWidth: '20px', maxHeight: '30px' }}
                >
                  <DeleteForeverOutlined sx={{ fontSize: 8 }} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box flex={1}>
          <span className={classes.subTitle}>Product Catalog:</span>
          <Box className={classes.dropzoneWrapper}>
            <Dropzone
              onDrop={handleDropCatalogs}
              // accept='*'
              multiple
              minSize={1024}
              maxSize={3072000}
            >
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps({ className: classes.dropZoneMain })}>
                  <input {...getInputProps()} />
                  <span className={classes.dropZoneButton}>
                    <OpenInBrowserIcon />
                    Upload
                  </span>
                </Box>
              )}
            </Dropzone>
            {catalogsFiles.map((file, index) => {
              if (!file || !file.file?.url || file.file?.url === '') return null
              return (
                <Box key={file.id} display="flex" className={classes.fileLinks}>
                  <AttachFileIcon sx={{ fontSize: 8 }} />
                  <a
                    style={{ display: 'flex', alignItems: 'center' }}
                    target="_blank"
                    rel="noreferrer"
                    href={file.file ? file.file.url : null}
                  >
                    {file?.file?.filename.length > 20
                      ? `${file?.file?.filename?.substring(0, 20)}...`
                      : file?.file?.filename}
                  </a>
                  <IconButton
                    onClick={() => {
                      deleteFile(file.id)
                      const newFilesList = catalogsFiles
                      newFilesList.splice(index, 1)
                      setCatalogsFiles(newFilesList)
                      refresh()
                    }}
                    style={{ maxWidth: '20px', maxHeight: '30px' }}
                  >
                    <DeleteForeverOutlined sx={{ fontSize: 8 }} />
                  </IconButton>
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box flex={1}>
          <span className={classes.subTitle}>Audit Results:</span>
          <Box className={classes.dropzoneWrapper} pt="50px">
            {/* <Dropzone
              onDrop={handleDropAudits}
              // accept='image/*'
              minSize={1024}
              maxSize={3072000}
            >
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps({ className: classes.dropZoneMain })}>
                  <input {...getInputProps()} />
                  <p className={classes.dropZoneButton}>
                    <OpenInBrowserIcon />
                    Upload
                  </p>
                </Box>
              )}
            </Dropzone> */}
            {auditsFiles.map((file) => {
              if (!file || !file.file?.url || file.file?.url === '') return null
              return (
                <Box key={file.id} display="flex" className={classes.fileLinks}>
                  <AttachFileIcon sx={{ fontSize: 8 }} />
                  <a
                    style={{ display: 'flex', alignItems: 'center' }}
                    target="_blank"
                    rel="noreferrer"
                    href={file.url ? file.url : null}
                  >
                    {file?.filename.length > 20
                      ? `${file?.filename?.substring(0, 20)}...`
                      : file?.filename}
                  </a>
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box flex={1}>
          <span className={classes.subTitle}>Company Photos:</span>
          <Box className={classes.dropzoneWrapper}>
            <Dropzone onDrop={handleDropPhotos} accept="image/*" minSize={1024} maxSize={3072000}>
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps({ className: classes.dropZoneMain })}>
                  <input {...getInputProps()} />
                  <p className={classes.dropZoneButton}>
                    <OpenInBrowserIcon />
                    Upload
                  </p>
                </Box>
              )}
            </Dropzone>
            {photosFiles.map((file, index) => {
              if (!file || !file.file?.url || file.file?.url === '') return null
              return (
                <Box key={file.id} display="flex" className={classes.fileLinks}>
                  <AttachFileIcon sx={{ fontSize: 8 }} />
                  <a
                    style={{ display: 'flex', alignItems: 'center' }}
                    target="_blank"
                    rel="noreferrer"
                    href={file.url ? file.url : null}
                  >
                    {file?.file?.filename.length > 20
                      ? `${file?.file?.filename?.substring(0, 20)}...`
                      : file?.file?.filename}
                  </a>
                  <IconButton
                    onClick={() => {
                      deleteFile(file.id)
                      const newFilesList = photosFiles
                      newFilesList.splice(index, 1)
                      setPhotosFiles(newFilesList)
                      refresh()
                    }}
                    style={{ maxWidth: '20px', maxHeight: '30px' }}
                  >
                    <DeleteForeverOutlined sx={{ fontSize: 8 }} />
                  </IconButton>
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box flex={1}>
          <span className={classes.subTitle}>Company Certificates:</span>
          <Box className={classes.dropzoneWrapper} pt="50px">
            {/* <Dropzone
              onDrop={handleDropCertifications}
              // accept='image/*'
              minSize={1024}
              maxSize={3072000}
            >
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps({ className: classes.dropZoneMain })}>
                  <input {...getInputProps()} />
                  <p className={classes.dropZoneButton}>
                    <OpenInBrowserIcon />
                    Upload
                  </p>
                </Box>
              )}
            </Dropzone> */}
            {certificatesFiles.map((file) => {
              if (!file || !file.url || file.url === '') return null
              return (
                <Box key={file?.id} display="flex" className={classes.fileLinks}>
                  <AttachFileIcon sx={{ fontSize: 8 }} />
                  <a
                    style={{ display: 'flex', alignItems: 'center' }}
                    target="_blank"
                    rel="noreferrer"
                    href={file && file.url ? file.url : null}
                  >
                    {file?.filename?.length > 20
                      ? `${file?.filename?.substring(0, 20)}...`
                      : file?.filename}
                  </a>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FilesSection
