import React from 'react'
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
// import { NumberInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '20px',
  },
  table: {
    overflowX: 'unset',
  },
}))
const StyledTableTitleCellSummary = withStyles(() => ({
  head: {
    border: '1px solid #DEDEDE',
    margin: '0px',
    backgroundColor: '#E3FEFF',
    color: '#000000',
    padding: '10px',
  },
}))(TableCell)
const StyledTableTitleCellSummaryBold = withStyles(() => ({
  head: {
    border: '1px solid #DEDEDE',
    margin: '0px',
    color: '#000000',
    backgroundColor: '#E3FEFF',
    padding: '10px',
    fontSize: 14,
    fontWeight: 600,
  },
}))(TableCell)

const SummaryTable = (props) => {
  const classes = useStyles()

  const { calculatedIncomeFields, calculatedExpensesFields, supplyChainTotal, retailPrice } = props

  // console.log(props)

  return (
    <div>
      <Box mt="10px" mb="10px" className={classes.tableTitle}>
        SUMMARY
      </Box>
      <Box display="flex">
        <Box flex={1} pr="5px">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableTitleCellSummary width="60%" style={{ borderTop: 'black solid 2px' }}>
                    Retail Price
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold
                    width="40%"
                    align="center"
                    style={{ borderTop: 'black solid 2px' }}
                  >
                    <NumberFormat
                      value={retailPrice}
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary width="60%">
                    Suggested Group Purchase Price
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold width="40%" align="center">
                    <NumberFormat
                      value={calculatedExpensesFields?.consumerDropshippingPrice}
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary width="60%">
                    {`"Investor" Price`}
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold width="40%" align="center">
                    <NumberFormat
                      value={calculatedExpensesFields?.clientCostUnit}
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary
                    width="60%"
                    // style={{ borderBottom: 'black solid 2px' }}
                  >
                    Potential Investor Return
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold
                    width="40%"
                    align="center"
                    // style={{ borderBottom: 'black solid 2px' }}
                  >
                    <Box display="flex" justifyContent="space-evenly" p="0px 20px">
                      <NumberFormat
                        value={calculatedIncomeFields.buyersReturnUnit}
                        className="numberFormat"
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                      />
                      <NumberFormat
                        value={calculatedIncomeFields.buyersReturn}
                        className="numberFormat"
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="("
                        suffix="%)"
                        renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                      />
                    </Box>
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary
                    width="60%"
                    style={{ borderBottom: 'black solid 3px' }}
                  >
                    Marketing Budget
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold
                    width="40%"
                    align="center"
                    style={{ borderBottom: 'black solid 3px' }}
                  >
                    <NumberFormat
                      value={calculatedIncomeFields.marketingBudgetTotal}
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
        <Box flex={1} pl="5px">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableTitleCellSummary width="60%" style={{ borderTop: 'black solid 2px' }}>
                    Software Income
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold
                    width="40%"
                    align="center"
                    style={{ borderTop: 'black solid 2px' }}
                  >
                    <NumberFormat
                      value={
                        calculatedIncomeFields.consumerSoftwareTotal +
                        calculatedExpensesFields.bulkSoftwareTotal
                      }
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      prefix="$"
                      fixedDecimalScale
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary
                    width="60%"
                    // style={{ borderBottom: 'black solid 2px' }}
                  >
                    Supply Chain & Service Income
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold
                    width="40%"
                    align="center"
                    // style={{ borderBottom: 'black solid 2px' }}
                  >
                    <NumberFormat
                      value={supplyChainTotal + calculatedIncomeFields.consumerServiceTotal}
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary width="60%">
                    Partner Income
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold width="40%" align="center">
                    <NumberFormat
                      value={
                        calculatedExpensesFields?.bulkPartnerTotal +
                        calculatedIncomeFields.consumerPartnerTotal
                      }
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary width="60%">
                    Licensing & Royalties Income
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold width="40%" align="center">
                    <NumberFormat
                      value={
                        calculatedExpensesFields?.bulkRoyaltiesTotal +
                        calculatedIncomeFields.consumerRoyaltiesTotal
                      }
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
                <TableRow>
                  <StyledTableTitleCellSummary
                    width="60%"
                    style={{ borderBottom: 'black solid 3px' }}
                  >
                    Available Commissions
                  </StyledTableTitleCellSummary>
                  <StyledTableTitleCellSummaryBold
                    width="40%"
                    align="center"
                    style={{ borderBottom: 'black solid 3px' }}
                  >
                    <NumberFormat
                      value={
                        calculatedExpensesFields?.bulkCommissionTotal +
                        calculatedIncomeFields.commissionTotal
                      }
                      className="numberFormat"
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                    />
                  </StyledTableTitleCellSummaryBold>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  )
}

export default SummaryTable
