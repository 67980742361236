import { Upload } from '@aws-sdk/lib-storage'
import { S3Client, S3 } from '@aws-sdk/client-s3'

const uploadFile = async (formData, setProgress) => {
  // console.log(formData, setProgress)

  function getSuffix(filename) {
    // console.log('GETSUFFIX', filename)
    const pos = filename.lastIndexOf('.')
    let suffix = ''
    if (pos !== -1) {
      suffix = filename.substring(pos)
    }
    return suffix
  }

  function getFileName(filename) {
    return filename.substring(0, filename.lastIndexOf('.'))
  }

  // eslint-disable-next-line no-restricted-syntax
  const suffix = getSuffix(formData.name)
  const fileName = getFileName(formData.name)
  const time = new Date().getTime()
  const pathname = `${fileName}_${time}${suffix}`

  try {
    const parallelUploads3 = new Upload({
      client:
        new S3({
          endpoint: 'https://nyc3.digitaloceanspaces.com', // Find your endpoint in the control panel, under Settings. Prepend "https://".
          region: 'nyc3', // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
          credentials: {
            accessKeyId: process.env.REACT_APP_SPACES_KEY, // Access key pair. You can create access key pairs using the control panel or API.
            secretAccessKey: process.env.REACT_APP_SPACES_SECRET, // Secret access key defined through an environment variable.
          },
        }) ||
        new S3Client({
          endpoint: 'https://nyc3.digitaloceanspaces.com', // Find your endpoint in the control panel, under Settings. Prepend "https://".
          region: 'nyc3', // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
          credentials: {
            accessKeyId: process.env.REACT_APP_SPACES_KEY, // Access key pair. You can create access key pairs using the control panel or API.
            secretAccessKey: process.env.REACT_APP_SPACES_SECRET, // Secret access key defined through an environment variable.
          },
        }),
      params: {
        Bucket: process.env.REACT_APP_SPACES_BUCKET || 'beni-development', // The path to the directory you want to upload the object to, starting with your Space name.
        Key: pathname, // Object key, referenced whenever you want to access this file later.
        Body: formData, // The object's contents. This variable is an object, not a string.
        ACL: 'public-read', // Defines ACL permissions, such as private or public.
      },

      //   tags: [
      /*...*/
      //   ], // optional tags
      queueSize: 4, // optional concurrency configuration
      partSize: 1024 * 1024 * 50, // optional size of each part, in bytes, at least 5MB
      leavePartsOnError: false, // optional manually handle dropped parts
    })

    parallelUploads3.on('httpUploadProgress', (progress) => {
      // console.log(Math.round(progress.loaded / progress.total) * 100)
      setProgress && setProgress(Math.round(progress.loaded / progress.total) * 100)
    })

    await parallelUploads3.done()
    return parallelUploads3.singleUploadResult.Location
  } catch (e) {
    console.log(e)
  }
}

export default uploadFile
