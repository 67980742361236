import React from 'react'
import { FunctionField } from 'react-admin'
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getDisplayName } from '../../../utils/utils'
import FileTypeFieldInput from '../../components/fieldInputs/FileTypeFieldInput'
import ReferencesFieldInput from './ReferencesFieldInput'
import ProductsFieldInput from './ProductsFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import AutocompleteArrayWithCreate from '../../components/fieldInputs/AutocompleteArrayWithCreate'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: '18px',
  },
  outerBox: {
    padding: '20px 40px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: '26px',
  },
  subtitle: {
    marginTop: '5px',
    fontSize: '16px',
    lineHeight: '16px',
  },
}))

const FormData = () => {
  const classes = useStyles()

  const { values } = useFormState()

  return (
    <Box display="flex" flexDirection="column" className={classes.outerBox}>
      <span className={classes.title}> {`${values.project_name} (${values.status})`}</span>
      <Box p="10px 0px" display="flex">
        <Box display="flex" flexDirection="column">
          <span className={classes.subtitle}>{`Id: ${values.id}`}</span>
          <Box display="flex">
            <span className={classes.subtitle}>SCA:</span>
            <FunctionField
              style={{ marginLeft: '5px' }}
              className={classes.subtitle}
              render={(record) => (record.sca ? getDisplayName(record.sca) : '')}
              label="SCA"
            />
          </Box>
          <Box display="flex">
            <span className={classes.subtitle}>Assigned to:</span>
            <FunctionField
              style={{ marginLeft: '5px' }}
              className={classes.subtitle}
              render={(record) => (record.assignee ? getDisplayName(record.assignee) : '')}
              label="Assigned to"
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" ml="30px">
          <span className={classes.subtitle}>{`Client: ${values.client_name || ''}`}</span>
          <span className={classes.subtitle}>{`Requested by: ${
            values.requested_by_name || ''
          }`}</span>
          <span className={classes.subtitle}>{`Received date: ${values.received_date || ''}`}</span>
        </Box>
      </Box>
      <div style={{ height: '20px' }} />
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Project Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" style={{ minWidth: '50%' }}>
            <Box display="flex">
              <TextFieldInput
                variant="standard"
                fullWidth
                field="client_name"
                resource="project"
                id={values.id}
                label="Client name:"
                value={values.client_name}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="requested_by_name"
                resource="project"
                id={values.id}
                label="Requested by:"
                value={values.requested_by_name}
              />
            </Box>
            <TextFieldInput
              variant="standard"
              fullWidth
              field="brand_name"
              resource="project"
              id={values.id}
              label="Brand:"
              value={values.brand_name}
            />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="project_name"
              resource="project"
              id={values.id}
              label="Project / product name:"
              value={values.project_name}
            />

            <Box display="flex" justifyContent="space-between">
              <TextFieldInput
                variant="standard"
                fullWidth
                field="required_quantities"
                resource="project"
                id={values.id}
                label="Quantity:"
                value={values.required_quantities}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="budget_per_piece"
                resource="project"
                id={values.id}
                label="Budget per piece:"
                value={values.budget_per_piece}
              />
              <DateFieldInput
                variant="standard"
                fullWidth
                field="required_by_date"
                formField="required_by_date"
                resource="project"
                id={values.id}
                label="Required by:"
                className={classes.dateInputTopMargin}
              />
            </Box>
            <TextFieldInput
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              field="budget_notes"
              resource="project"
              id={values.id}
              label="Budget notes:"
              value={values.budget_notes}
            />
            {/* <AutocompleteArrayWithCreate
              source="chosenServices"
              resource="system_dictionary"
              resourceFilter="services"
              label="Services required:"
              freeSolo
              fieldName="service"
              referenceField="project_id"
              referenceId={values?.id}
              resourceName="project_services"
              oldData="services"
            /> */}
            <FileTypeFieldInput
              variant="standard"
              fullWidth
              referenceField="project_id"
              field="referencePhotos"
              type="reference_photo"
              resource="project_files"
              referenceId={values.id}
              title="Reference files & images"
              value={values.referencePhotos}
              nameLength={50}
            />
            <ReferencesFieldInput
              source="references"
              oldSource="oldReferences"
              resource="project_references"
              label="References"
              referenceField="project_id"
            />
            <TextFieldInput
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              field="product_requirements"
              resource="project"
              id={values.id}
              label="Product requirements:"
              value={values.product_requirements}
            />
            <TextFieldInput
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              field="packaging_requirements"
              resource="project"
              id={values.id}
              label="Packaging requirements:"
              value={values.packaging_requirements}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Quotes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" style={{ minWidth: '50%' }}>
            <TextFieldInput
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              field="quote_notes"
              resource="project"
              id={values.id}
              label="Quote notes:"
              value={values.quote_notes}
            />
            <div style={{ height: '30px' }} />
            <FileTypeFieldInput
              variant="standard"
              fullWidth
              referenceField="project_id"
              field="quoteFiles"
              type="quote"
              resource="project_files"
              referenceId={values.id}
              title="Quote files and Supporting Information"
              value={values.quoteFiles}
              nameLength={50}
            />
            <div style={{ height: '30px' }} />
            <FileTypeFieldInput
              variant="standard"
              fullWidth
              referenceField="project_id"
              field="designFiles"
              type="design"
              resource="project_files"
              referenceId={values.id}
              title="Design files"
              value={values.designFiles}
              nameLength={50}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Notes & Updates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" style={{ minWidth: '50%' }}>
            <TextFieldInput
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              field="update_notes"
              resource="project"
              id={values.id}
              label="Quote notes:"
              value={values.update_notes}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Client Quote</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" style={{ minWidth: '50%' }}>
            <ProductsFieldInput />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default FormData
