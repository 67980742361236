import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  outerBox: {
    padding: '20px',
    backgroundColor: 'white',
  },
  heading: {
    fontSize: '14px',
    fontWeight: '600',
  },
  title: {
    position: 'relative',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '40px',
  },
  outlineBox: {
    padding: '24px',
    backgroundColor: '#F8F8FA',
  },
  leftBox: {
    padding: '12px',
    backgroundColor: 'white',
    // border: 'red 1px solid',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
  },
  thumbnailRemove: {
    // display: 'none',
    '& .MuiButtonBase-root': {
      position: 'absolute',
      top: '10px',
      left: '50px',
      width: '5px',
      height: '5px',
      zIndex: '100',
      backgroundColor: 'white',
    },
  },
  thumbnailPreview: {
    position: 'absolute',
    top: '0px',
    left: '-10px',
    '& img': {
      width: '80px',
      maxHeight: '80px',
      height: '80px',
    },
  },
  thumbnailDropZone: {
    width: '80px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumbnailDropRoot: {
    width: '80px',
    height: '80px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    // display: 'flex',
    // flexFlow: 'row-reverse',
    // justifyContent: 'flex-end',
    // alignItems: 'center',
  },
  imageRemove: {
    // display: 'none',
    '& .MuiButtonBase-root': {
      position: 'absolute',
      width: '5px',
      height: '5px',
      top: '20px',
      backgroundColor: 'white',
      left: '180px',
      zIndex: '100',
    },
  },
  imagePreview: {
    position: 'absolute',
    top: '0px',
    left: '-10px',
    '& img': {
      width: '220px',
      maxHeight: '220px',
      height: '220px',
    },
  },
  imageDropZone: {
    width: '220px',
    height: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageDropRoot: {
    width: '220px',
    height: '220px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    // display: 'flex',
    // flexFlow: 'row-reverse',
    // justifyContent: 'flex-end',
    // alignItems: 'center',
  },
  centerBox: {
    padding: '12px',
    marginLeft: '12px',
    marginRight: '12px',
    backgroundColor: 'white',
    width: '-webkit-fill-available',
    // border: 'blue 1px solid',
  },
  radioBoxOuter: {
    // border: 'red 1px solid',
    '& .MuiFormControl-root': {
      margin: '0px',
      padding: '0px',
      '& .MuiFormLabel-root': {
        display: 'none',
      },
    },
  },
  radioBoxInner: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    '& .MuiFormControl-root': {
      padding: '0px',
    },
  },
  rightBox: {
    padding: '12px',
    backgroundColor: 'white',
    // border: 'green 1px solid',
  },
  sample: {
    padding: '12px',
    backgroundColor: 'white',
    border: 'pink 1px solid',
  },
  input: {
    margin: 0,
    padding: 0,
    maxWidth: '340px',
    height: '30px',
    verticalAlign: 'middle',
    '& .MuiOutlinedInput-root': {
      // borderRadius: 0,
      borderLeft: 'none',
    },
  },
  dateInput: {
    margin: '10px 0px',
    padding: 0,
    maxWidth: '340px',
    height: '30px',
    verticalAlign: 'middle',
    '& .MuiOutlinedInput-root': {
      // borderRadius: 0,
      borderLeft: 'none',
    },
  },
  dateInputNoLabel: {
    margin: '10px 0px',
    padding: 0,
    maxWidth: '340px',
    height: '30px',
    verticalAlign: 'middle',
    '& .MuiOutlinedInput-root': {
      // borderRadius: 0,
      borderLeft: 'none',
    },
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  dialogInput: {
    margin: '10px 0px',
    padding: 0,
    maxWidth: '340px',
    height: '36px',
    verticalAlign: 'middle',
    '& .MuiOutlinedInput-root': {
      // borderRadius: 0,
      borderLeft: 'none',
    },
  },
  tableInput: {
    margin: 0,
    padding: 0,
    maxWidth: '340px',
    height: '36px',
    verticalAlign: 'middle',
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      borderLeft: 'none',
    },
    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },
  tableInputLarge: {
    margin: 0,
    padding: 0,
    maxWidth: '340px',
    height: '110px',
    verticalAlign: 'middle',
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      borderLeft: 'none',
    },
    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },
  samplePriceInput: {
    margin: '15px 0 0 0',
    padding: 0,
    maxWidth: '240px',
    height: '36px',
    '& .MuiInputLabel-outlined': {
      width: '90px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      borderLeft: 'none',
    },
  },
  itemBoxDropZone: {
    width: '20%',
    minWidth: '120px',
    height: '30px',
    border: '#E0E0E0 solid 1px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '4px 5px',
    fontSize: '0.8125rem',
    fontWeight: '500',
  },
  itemBoxDZRoot: {
    margin: '0px',
    padding: '0px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  itemBoxDZRemove: {
    // border: 'blue 1px solid',
    display: 'flex',
    flexFlow: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      transform: 'scale(0.6)',
      // position: 'absolute',
      // right: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  itemBoxDZPreview: {
    // border: 'green 1px solid',
    fontSize: '10px',
    lineHeight: '10px',
    color: '#10B1C1',
  },
  itemBoxDropZoneCert: {
    // border: 'red 1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // position: "absolute",
    width: '30%',
    marginLeft: '5%',
    backgroundColor: 'white',
    border: '1px dashed #E0E0E0',
  },
  itemBoxDZRemoveDec: {
    // border: 'blue 1px solid',
    display: 'flex',
    padding: '10px',
    flexFlow: 'row-reverse',
    alignItems: 'center',
    position: 'absolute',
    marginTop: '-62px',
    marginLeft: '180px',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      transform: 'scale(0.6)',
      // position: 'absolute',
      // right: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  itemBoxDZRemoveCert: {
    // border: 'blue 1px solid',
    display: 'flex',
    padding: '10px',
    flexFlow: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      transform: 'scale(0.6)',
      // position: 'absolute',
      // right: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  quoteLabel: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  filesLabel: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  addBoxButton: {
    border: '#E0E0E0 solid 1px',
    borderRadius: '4px',
    height: '30px',
    width: '20%',
    marginTop: '8px',
  },
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  autoCompleteBox: {
    display: 'contents',
  },
  labelText: {
    fontSize: '12px',
  },
  labelSubtitle: {
    fontSize: '12px',
    margin: '10px 0px',
  },
  autoCompleteContainer: {
    display: 'contents',
  },
  productImage: {
    maxWidth: '100px',
  },
  autocompleteSelect: {
    minWidth: '160px',
  },
  autocompleteSelectMats: {
    width: '272px',
  },
  autocompleteField: {
    '& .MuiInputBase-root': {
      //   height: '50px',
      '& .MuiButtonBase-root': {
        fontSize: '12px',
        // height: '28px',
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
        },
      },
    },
  },
  dividerLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'gray',
    margin: '25px 0px 5px',
  },
  chipInputPadding: {
    padding: '0px',
  },
  textInput: {
    padding: '2px 0px',
  },
  accordionStyle: {
    '& .MuiAccordionSummary-root': {
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  numberFormatInput: {
    width: '100%',
    height: '40px',
    border: 'none',
    textAlign: 'center',
  },
  optionsSelector: {
    width: '100%',
    height: '40px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
    },
  },
  descItem: {
    marginTop: '12px',
    flexDirection: 'row',
  },
  descLabel: {
    // marginTop: '12px',
    minWidth: '130px',
    color: '#BDBDBD',
  },
  descValue: {},
  tableTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '20px',
    marginTop: '60px',
  },
  calcBox: {
    margin: '20px 0',
  },
  numberInput: {
    width: '100px',
    height: '40px',
    textAlign: 'center',
  },
  orderManagementCard: {
    padding: '10px',
    margin: '10px',
  },
  addressTitle: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  addressSubtitle: {
    fontSize: '12px',
    marginBottom: '8px',
  },
  addressLabel: {
    fontSize: '10px',
    marginBottom: '4px',
  },
  addressValue: {
    fontSize: '10px',
    marginBottom: '4px',
  },
  contactBox: {
    padding: '10px',
    margin: '10px',
    boxShadow: '3px 3px lightgrey',
    border: 'solid 2px lightgrey',
    borderRadius: '4px',
  },
  addressBox: {
    padding: '10px',
    margin: '10px',
    boxShadow: '3px 3px lightgrey',
    border: 'solid 2px lightgrey',
    borderRadius: '4px',
  },
  colourInputPopup: {
    position: 'absolute',
    zIndex: 2,
  },
  colourInputCover: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  colourPickerItem: {
    padding: '0px',
    margin: '0px',
    height: '38px',
  },
  productHeader: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '34px',
  },
  primaryPhoto: {
    maxHeight: '200px',
    maxWidth: '200px',
  },
  statBox: {
    backgroundColor: 'lightGrey',
    minHeight: '40px',
    borderRadius: '4px',
  },
  statTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  statValue: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
  },
  subHeader: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
  },
  subHeaderSKU: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    color: '#10B1C1',
  },
}))
