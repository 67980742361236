import React from 'react'
import useStyles from '../styles'
import { useSubscription, gql } from '@apollo/client'

import { Box } from '@material-ui/core'

const OfferDetails = ({ id }) => {
  const classes = useStyles()

  const { data: { offer_by_pk: offerData } = {} } =
    useSubscription(
      gql`subscription GetOfferDetails {
      offer_by_pk(id: ${id}) {
        id
        name
        status
        investor_price_per_piece
        group_purchase_price
        shipbob_reference_id
        shipbob_product_id
        shipbob_wro_id
        shipbob_sku
        moq
        type
        total_sold
      }
    }
  `,
      //   { client: apolloClient },
    ) || []

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <span className={classes.title}>Offer Details</span>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Product Name</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.name}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Target Quantity</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.moq}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Quantity Bought</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.total_sold || 0}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Type</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.type}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Status</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.status}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Investor Purchase Price</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>
            ${(offerData?.investor_price_per_piece / 100).toFixed(2) || 0.0}
          </span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Group Purchase Price</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>
            ${(offerData?.group_purchase_price / 100).toFixed(2) || 0.0}
          </span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Shipbob Id</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.shipbob_product_id}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Shipbob Ref</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.shipbob_reference_id}</span>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box flex={2}>
          <span className={classes.bodyText}>Shipbob WRO Id</span>
        </Box>
        <Box flex={3}>
          <span className={classes.bodyText}>{offerData?.shipbob_wro_id}</span>
        </Box>
      </Box>
    </Box>
  )
}

export default OfferDetails
