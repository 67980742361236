import React from 'react'
import {
  required,
  //  ImageInput,
  // ImageField,
  TextInput,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import Confirm from '../../components/Confirm'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import ItemTitle from '../../components/ItemTitle'
import ProfileIcon from '../../../assets/profile/profile_icon.png'
// import ProfileUpdateIcon from '../../../assets/headerUpdateIcon.png'

const useStyles = makeStyles({
  hideLabel: {
    display: 'none',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageRemove: {
    '& .MuiButtonBase-root': {
      position: 'absolute',
      top: '0px',
      left: '120px',
      zIndex: '100',
    },
  },
  imagePreview: {
    position: 'absolute',
    top: '0px',
    left: '-10px',
    '& img': {
      width: '162px',
      maxHeight: '162px',
      height: '162px',
      borderRadius: '162px',
      margin: '7px 0px 0px 9px',
    },
  },
  imageDropZone: {
    width: '160px',
    height: '160px',
    borderRadius: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage:
      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(50, 50, 50, 0.5)), url(' +
      // ProfileUpdateIcon +
      ')' +
      ', url(' +
      ProfileIcon +
      ')',
    background: 'no-repeat center center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  imageDropRoot: {
    width: '160px',
    height: '160px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  update: {
    textDecoration: 'underline',
    color: '#21C6CF',
    fontSize: '12px',
  },
})

const FormData = (props) => {
  const classes = useStyles()
  // console.log(props)

  const { handleSubmit, formProps } = props

  const { dirtyFields } = useFormState()

  return (
    <Box p="0 60px 60px">
      <ItemTitle title="Profile Details" />
      <Box display="flex" mt="50px">
        <Box p="0px 10px" flex={1}>
          <div className={classes.imageDropZone} />
        </Box>
        <Box flex={7}>
          <Box display="flex">
            <Box p="0px 10px" flex={1}>
              <TextInput
                fullWidth
                source="forename"
                variant="standard"
                // validate={required()}
                label="First Name:"
              />
            </Box>
            <Box p="0px 10px" flex={1}>
              <TextInput
                fullWidth
                source="surname"
                variant="standard"
                // validate={required()}
                label="Last Name:"
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box p="0px 10px" flex={1}>
              <TextInput
                fullWidth
                source="email"
                variant="standard"
                validate={required()}
                label="Email:"
              />
            </Box>
            <Box p="0px 10px" flex={1}>
              <ReferenceInput
                source="default_organisation_id"
                reference="organisation"
                label="Default Organisation"
                // sort={{ field: 'id', order: 'ASC' }}
                filter={{ member_id: props.id }}
                // style={{ minWidth: '200px' }}
                alwaysOn
                variant="standard"
              >
                <SelectInput optionText={(val) => val.name} fullWidth />
              </ReferenceInput>
            </Box>
          </Box>
          <Box display="flex">
            <Box p="0px 10px" flex={1}>
              <TextInput fullWidth source="username" variant="standard" label="Username:" />
            </Box>
            <Box p="0px 10px" flex={1}>
              <NullableBooleanInput
                fullWidth
                style={{ width: '100%' }}
                source="emails_enabled"
                variant="standard"
                label="Emails Enabled:"
              />
            </Box>
          </Box>
        </Box>
        <Box flex={2} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mt="30px">
        <Confirm
          redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="SAVE"
          undoable
          invalid={formProps.invalid}
          handleSubmit={async (record) => {
            // console.log(formProps)
            handleSubmit({ record, dirtyFields })
          }}
          saving={formProps.saving}
          disabled={formProps.saving || formProps.invalid}
          resource="app_users"
        />
      </Box>
    </Box>
  )
}

export default FormData
