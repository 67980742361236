const API_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://hasura.dev.benib2b.com/v1/' // dev
    : // ?  'https://hasura.benib2b.com/v1/' // prod
      // ? 'https://hasura.staging.benib2b.com/v1/' // staging
      process.env.REACT_APP_API_HOST

export default API_HOST

export const WSHOST =
  process.env.NODE_ENV === 'development'
    ? 'wss://hasura.dev.benib2b.com/v1/graphql' // dev
    : // ?  'wss://hasura.benib2b.com/v1/graphql' // prod
      // ? 'wss://hasura.staging.benib2b.com/v1/graphql' // staging
      process.env.REACT_APP_WEBSOCKET_HOST

export const STRIPE_SIM_REDIRECT_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/#/simulator'
    : process.env.REACT_APP_STRIPE_SIM_REDIRECT

export const GS_API_KEY =
  process.env.NODE_ENV === 'development' ? 'yzzeh63zsxmx' : process.env.REACT_APP_GS_API_KEY

export const GS_APP_ID =
  process.env.NODE_ENV === 'development' ? '1242885' : process.env.REACT_APP_GS_APP_ID
