import React, {
  useState,
  // useCallback,
  //   useEffect,
  //  Fragment
} from 'react'
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  makeStyles,
  IconButton,
  // CircularProgress,
} from '@material-ui/core'
import {
  useNotify,
  useDataProvider,
  FormWithRedirect,
  //  TextInput,
  Edit,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
// import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
// import { useDropzone } from 'react-dropzone'
// import { Markup } from 'interweave'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import NumberFormat from '../../components/CustomNumberFormat'
// import uploadFile from '../../../dataProvider/aws_upload'
// import RichTextInput from 'ra-input-rich-text'
import ClientQuoteCreateDialog from './ClientQuoteCreateDialog'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFF',
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    backgroundColor: '#FFF',
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '16px',
    // color: 'rgb(18, 183, 106)',
    marginTop: '20px',
  },
  boxContainer: {
    marginTop: '20px',
    padding: '20px 10px',
  },
  editContainerMain: {
    marginTop: '20px',
  },
  editContainerCard: {
    padding: '0px',
  },
  // numberTableInput: {
  //   width: '99%',
  //   height: '100%',
  //   marginTop: '0px',
  //   marginBottom: '0px',
  //   marginLeft: '1%',
  //   // height: '30px',
  //   minHeight: '30px',
  //   minWidth: '60px',
  //   padding: '0px',
  //   '& .MuiInput-underline::before': {
  //     borderBottom: 'none !important',
  //   },
  //   '& .MuiFilledInput-underline::before': {
  //     borderBottom: 'none',
  //   },
  //   '& .MuiInputBase-root': {
  //     borderBottom: 'none !important',
  //     height: '100%',
  //     minHeight: '30px',
  //     '& .input': {
  //       border: 'solid 1px green',
  //       width: '50%',
  //     },
  //   },
  // },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    // minWidth: '60px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  fieldInputLeft: {
    minWidth: '200px',
  },
  fieldInput: {
    minWidth: '120px',
    marginLeft: '20px',
  },
  primaryPhoto: {
    // border: 'dashed gray 2px',
    width: '250px',
    height: '250px',
    position: 'absolute',
    zIndex: '-1',
  },
  primaryPhotoZoomContainer: {
    border: 'solid gray 1vw',
    borderRadius: '1vw',
    maxWidth: '65vw',
    // maxHeight: '50vh',
    position: 'absolute',
    backgroundColor: 'white',
    top: '0px',
    left: '0px',
    // minWidth: '50vw',
    // minHeight: '50vh',
    zIndex: '1000',
  },
  primaryPhotoZoom: {
    maxWidth: '64vw',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '400px',
    // margin: '0px 0px 5px',
  },
  previewImageBox: {
    top: '0px',
    left: '0px',
    width: '247px',
    height: '247px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    border: 'solid gray 2px',
    // '&:hover': {
    //   border: 'gray dashed 2px',
    // },
  },
  previewImage: {
    zIndex: '100',
    maxWidth: '100%',
    maxHeight: '100%',
    // cursor: 'pointer',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '250px',
    maxHeight: '250px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  deleteBtn: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  zoomBtn: {
    position: 'absolute',
    top: '1px',
    left: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  imageDropZone: {
    width: '250px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  imageDropZoneEditing: {
    width: '250px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    cursor: 'pointer',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  loadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '115px',
    top: '115px',
  },
  rtfInputBox: {
    '& .ra-rich-text-input': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '220px',
      '& .MuiFormLabel-root': {
        fontSize: '12px',
      },
    },
  },
  markupText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  fieldLabel: {
    fontSize: '14px',
    marginRight: '20px',
  },
  fieldText: {
    fontSize: '14px',
  },
}))

const ClientQuotesSection = (props) => {
  const classes = useStyles()

  const { quotes = [], milestones, config, items } = props

  //   const dataProvider = useDataProvider()

  //   useEffect(() => {
  //     // console.log('calculating...')
  //     getData()
  //   }, [])

  //   const getData = async () => {
  //     const resp = await dataProvider.getList('scm_quote', {
  //       pagination: { page: 1, perPage: 100 },
  //       sort: { field: 'id', order: 'ASC' },
  //       filter: { sourcingRequestId: id },
  //     })
  //     setData(resp.data)
  //   }

  // console.log(quotes, items)

  return (
    <Box mt="30px">
      <Card className={classes.boxContainer}>
        <Box display="flex" justifyContent="space-between">
          <span className={classes.sectionHeader}>Client Quotes</span>
        </Box>
        {quotes?.length === 0 && (
          <span style={{ fontSize: '12px', marginTop: '20px' }}>
            No quotes yet, add one by clicking the + buttons above
          </span>
        )}
        {quotes?.map((quote, index) => (
          <Edit
            key={quote.id}
            actions={null}
            basePath="/sourcingRequests"
            resource="scmQuote"
            component="div"
            id={quote.id}
            mutationMode="pessimistic"
            classes={{
              main: classes.editContainerMain,
              card: classes.editContainerCard,
            }}
          >
            <QuoteEdit
              key={quote.id}
              index={index}
              milestones={milestones}
              config={config}
              items={items}
            />
          </Edit>
        ))}
      </Card>
    </Box>
  )
}

const QuoteEdit = (props) => {
  const { record, milestones, config, items } = props
  const [createOpen, setCreateOpen] = useState(false)

  const foundQuote = items.find(
    (el) => record.product_quote_id === el.product?.product_quotes[0].id,
  )

  // console.log('FQ', foundQuote)
  // console.log('REC', record)

  const factoryPrices = foundQuote?.product?.product_quotes[0].prices?.map((el) => ({
    ...el,
    unitPrice: el.unit_price / 100,
    fobPriceInternal: el.internal_fob / 100 || el.unitPrice,
    seaPriceInternal: el.internal_sea / 100 || null,
    airPriceInternal: el.internal_air / 100 || null,
    fobLeadTime: el.production_lead_time || el.lead_time,
    seaLeadTime: (el.production_lead_time || el.lead_time || 0) + 50,
    airLeadTime: (el.production_lead_time || el.lead_time || 0) + 10,
    fobPublished: !el.id ? false : !el.internal_fob,
    airPublished: !el.id ? false : !el.internal_air,
    seaPublished: !el.id ? false : !el.internal_sea,
  }))

  const factoryQuote = {
    ...foundQuote.product.product_quotes[0],
    prices: factoryPrices,
    image_id: foundQuote.product?.thumbnail_photo?.id || foundQuote.product?.primary_photo?.id,
  }

  const dimensions = record.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions
    ? record.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions
    : factoryQuote.included_packaging_option.carton_dimensions

  const grossWeight = dimensions?.gross_weight / 100
  const cartonLength = dimensions?.length / 100
  const cartonWidth = dimensions?.width / 100
  const cartonHeight = dimensions?.height / 100

  const volumetricSeaWeight =
    parseInt(((cartonLength * cartonWidth * cartonHeight) / 6000).toFixed(2)) || null
  const chargeableSeaWeight = volumetricSeaWeight > grossWeight ? volumetricSeaWeight : grossWeight

  const volumetricAirWeight =
    parseInt(((cartonLength * cartonWidth * cartonHeight) / 5000).toFixed(2)) || null
  const chargeableAirWeight = volumetricAirWeight > grossWeight ? volumetricAirWeight : grossWeight

  return (
    <FormWithRedirect
      initialValues={{
        prices: record.prices?.map((el) => ({
          ...el,
          fobPrice: el.fob_price / 100,
          ddpAir: el.estimated_freight_air / 100,
          ddpSea: el.estimated_freight_sea / 100,
          chargeableAirWeight,
          chargeableSeaWeight,
          totalCBM: el.total_cbm / 100 || null,
        })),
        products: items,
        factoryQuote,
      }}
      render={(formProps) => (
        <Box>
          <Button
            onClick={() => {
              setCreateOpen(true)
            }}
            style={{ border: 'solid 1px lightgrey' }}
          >
            Edit
          </Button>
          {/* {resp.loading && <CircularProgress style={{ width: '20px', height: '20px' }} />} */}
          <QuoteEditForm formProps={formProps} />
          {config && (
            <ClientQuoteCreateDialog
              createOpen={createOpen}
              setCreateOpen={setCreateOpen}
              // productIndex={items.findIndex(
              //   (el) => record.product_quote_id === el.product?.product_quotes[0].id,
              // )}
              factoryQuote={factoryQuote}
              milestones={milestones}
              config={config}
            />
          )}
        </Box>
      )}
    />
  )
}

const QuoteEditForm = () => {
  // const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  // const dataProvider = useDataProvider()
  // const notify = useNotify()
  const [editing, setEditing] = useState(false)
  // const [editingImage, setEditingImage] = useState(false)
  //   const [deleting, setDeleting] = useState(false)
  // const [uploading, setUploading] = useState(false)
  //   const [zoomOpen, setZoomOpen] = useState(null)
  console.log(values)

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: ['image/*'],
  //   multiple: false,
  //   onDrop: async (acceptedFiles) => {
  //     // console.log(acceptedFiles)
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //       setUploading(true)
  //       const acceptedFile = acceptedFiles[0]
  //       // console.log(acceptedFile)
  //       const url = await uploadFile(acceptedFile)
  //       try {
  //         const fileResp = await dataProvider.create('file', {
  //           data: { filename: acceptedFiles[0].name, url },
  //         })

  //         await dataProvider.update('scm_quote', {
  //           data: { id: values.id, image_id: fileResp.data?.id },
  //         })

  //         await form.batch(() => {
  //           form.change(`image`, {
  //             id: fileResp.data.id,
  //             filename: acceptedFile.name,
  //             url,
  //           })
  //           form.change(`image_id`, fileResp.data.id)
  //         })

  //         notify('Upload Complete', { type: 'info' })

  //         setUploading(false)
  //       } catch (error) {
  //         console.log(error)
  //         notify(error.message, { type: 'warning' })
  //         setUploading(false)
  //       }
  //     }
  //   },
  // })

  //   if (!values.products[productIndex]) {
  //     return null
  //   }

  const preview = values.image?.url || null

  return (
    <Box>
      <Box display="flex" mt="10px">
        <Box>
          {/* <div className={classes.previewImageBox}> */}
          <img
            style={{ maxWidth: '150px', maxHeight: '150px' }}
            src={preview}
            className={classes.previewImage}
            alt=""
          />
          {/* </div> */}
        </Box>

        <Box display="flex" flexDirection="column" pl="30px">
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Client:</span>
            <span className={classes.fieldText}>{values.client?.name}</span>
          </Box>
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Product name:</span>
            <span className={classes.fieldText}>{values.client_product_name}</span>
          </Box>
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Internal ref:</span>
            <span className={classes.fieldText}>{values.id}</span>
          </Box>
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Description:</span>
            <span className={classes.fieldText}>{values.client_notes}</span>
          </Box>
        </Box>

        {/* <Box
          m="0px 20px"
          flex={1}
          onMouseEnter={() => setEditingImage(true)}
          onMouseLeave={() => setEditingImage(false)}
          style={{ position: 'relative' }}
        >
          <div
            {...getRootProps({
              className: !editingImage ? classes.imageDropZone : classes.imageDropZoneEditing,
            })}
          >
            <input {...getInputProps()} />
            {!uploading && editingImage && <span>{`Drag / Upload`}</span>}
          </div>

          {uploading && <CircularProgress className={classes.loadingIcon} />}
          {preview && (
            <div className={classes.previewImageBox}>
              <img src={preview} className={classes.previewImage} alt="" />
              {editingImage && (
                <IconButton
                  aria-label="delete"
                  className={classes.deleteBtn}
                  onClick={async () => {
                    try {
                      setUploading(true)
                      await dataProvider.update('scm_quote', {
                        data: { id: values.id, image_id: null },
                      })
                      await form.batch(() => {
                        form.change(`image`, null)
                        form.change(`image_id`, null)
                      })
                      notify('Image Deleted', { type: 'info' })
                      setUploading(false)
                      setEditingImage(false)
                    } catch (error) {
                      notify(error.message, { type: 'warning' })
                      setUploading(false)
                    }
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </div>
          )}
        </Box> */}
      </Box>

      <Box mt="40px">
        <span style={{ fontSize: '16px', marginBottom: '10px' }}>Pricing</span>
        <TableContainer component="div">
          <Table
            aria-label="simple table"
            style={{ overflow: 'hidden', backgroundColor: 'lightgray' }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: editing ? '15%' : '17%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Quantity
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: editing ? '15%' : '17%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  FOB
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: editing ? '15%' : '17%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  DDP Sea
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: editing ? '15%' : '16%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Lead Time Sea
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: editing ? '15%' : '17%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  DDP Air
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: editing ? '15%' : '16%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Lead Time Air
                </StyledTableCell>
                {editing && (
                  <StyledTableCell
                    style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Delete
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.prices?.map((item, index) => (
                <QuoteRow
                  key={`price${item.id}`}
                  quoteId={values.id}
                  index={index}
                  editing={editing}
                  setEditing={setEditing}
                  id={item.id}
                  // setSaving={setSaving}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box display="flex" mt="10px">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Setup cost:</span>
            <span className={classes.fieldText}>
              {values.client_tooling_cost
                ? Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(values.client_tooling_cost / 100)
                : '-'}
            </span>
          </Box>
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Sample cost:</span>
            <span className={classes.fieldText}>
              {values.client_sample_price
                ? Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(values.client_sample_price / 100)
                : '-'}
            </span>
          </Box>
          <Box mt="5px" display="flex" justifyContent="space-between">
            <span className={classes.fieldLabel}>Sample lead time:</span>
            <span className={classes.fieldText}>{values.client_sample_lead_time} days</span>
          </Box>
        </Box>
        <Box flex={2} />
      </Box>

      <Box mt="20px">
        <span style={{ fontSize: '16px' }}>Shipping</span>
        <TableContainer component={Card} style={{ marginTop: '10px' }}>
          <Table
            aria-label="simple table"
            style={{ overflow: 'hidden', backgroundColor: 'lightgray' }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    width: '20%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: 'lightgray',
                  }}
                  align="left"
                >
                  Quantity
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '20%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: 'lightgray',
                  }}
                  align="left"
                >
                  # of Cartons
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '20%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: 'lightgray',
                  }}
                  align="left"
                >
                  CBM
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '20%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: 'lightgray',
                  }}
                  align="left"
                >
                  C.W. Sea
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '20%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: 'lightgray',
                  }}
                  align="left"
                >
                  C.W. Air
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.prices?.map((item, index) => (
                <ShippingRow
                  key={`price${item.id}`}
                  quoteId={values.id}
                  index={index}
                  editing={editing}
                  setEditing={setEditing}
                  id={item.id}
                  // setSaving={setSaving}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" mt="10px">
          <Box display="flex" flexDirection="column" flex={1}>
            <Box mt="5px" display="flex" justifyContent="space-between">
              <span className={classes.fieldLabel}>Carton Dimensions:</span>
              <span className={classes.fieldText}>
                {`${
                  values.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions
                    .length / 100
                }cm x ${
                  values.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions
                    .width / 100
                }cm x ${
                  values.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions
                    .height / 100
                }cm`}
              </span>
            </Box>
          </Box>
          <Box flex={2} />
        </Box>
      </Box>
    </Box>
  )
}

const QuoteRow = ({ index, productIndex, editing, setEditing, id, setSaving }) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // if (!values.products[productIndex].prices[index]) {
  //   return null
  // }

  return (
    <TableRow key={`priceRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`prices[${index}].quantity`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          source={`prices[${index}].fobPrice`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          source={`prices[${index}].ddpSea`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`prices[${index}].total_lead_time_sea`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          source={`prices[${index}].ddpAir`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`prices[${index}].total_lead_time_air`}
          // validate={required()}
        />
      </BodyTableCell>
      {editing && (
        <BodyTableCell
          align="center"
          style={{
            padding: '0px',
          }}
        >
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                if (typeof id === 'number') {
                  await dataProvider.delete('project_products_prices', { id })
                }

                const formPrices = values.products[productIndex].prices
                formPrices.splice(index, 1)
                // console.log(formPrices)
                form.change(`prices`, formPrices)
                notify('Price Deleted', { type: 'info' })
                setSaving(false)
                setEditing(false)
              } catch (error) {
                console.log(error)
                setSaving(false)
              }
            }}
            style={{ maxWidth: '35px', maxHeight: '35px' }}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </BodyTableCell>
      )}
    </TableRow>
  )
}

const ShippingRow = ({ index, editing = false }) => {
  const classes = useStyles()

  return (
    <TableRow key={`shippingRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px', backgroundColor: 'lightgray' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`prices[${index}].quantity`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px', backgroundColor: 'lightgray' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`prices[${index}].total_cartons`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px', backgroundColor: 'lightgray' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          suffix=" cbm"
          source={`prices[${index}].totalCBM`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          suffix=" kg"
          source={`prices[${index}].chargeableSeaWeight`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
          backgroundColor: 'lightgray',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          suffix=" kg"
          source={`prices[${index}].chargeableAirWeight`}
          // validate={required()}
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default ClientQuotesSection
