import SupplierIcon from '@material-ui/icons/LocalShippingOutlined'
import SupplierList from './SupplierList'
import SupplierEdit from './SupplierEdit/SupplierEdit'
import SupplierCreate from './SupplierCreate'
// import SupplierShow from "./SupplierShow"

export default {
  list: SupplierList,
  create: SupplierCreate,
  edit: SupplierEdit,
  icon: SupplierIcon,
  show: SupplierEdit,
}
