import React, { useState } from 'react'
import {
  Box,
  // Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import { useNotify, useDataProvider, required, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import IconButton from '@material-ui/core/IconButton'
import { saveFactoryQuote } from '../common/utils'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import NumberFormat from '../../components/CustomNumberFormat'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const FactoryQuoteCalculator = () => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()

  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [editing, setEditing] = useState()
  const [saving, setSaving] = useState()

  return (
    <div
      style={{
        // padding: '30px',
        borderRadius: '4px',
        backgroundColor: editing ? '#eab2b9' : '#FFFFFF',
      }}
    >
      <Box mt="20px">
        <Box display="flex" alignItems="center">
          <span className={classes.sectionHeader}>Quote</span>
          {editing ? (
            <div>
              {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
              <IconButton
                onClick={async () => {
                  // console.log(values)
                  await saveFactoryQuote({
                    setSaving,
                    values,
                    dataProvider,
                    notify,
                    setEditing,
                    form,
                  })
                }}
                style={{ marginLeft: '30px', maxWidth: '25px', maxHeight: '25px' }}
              >
                <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
              </IconButton>

              <IconButton
                onClick={() => {
                  setEditing(false)
                }}
                style={{ maxWidth: '25px', maxHeight: '25px' }}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </div>
          ) : (
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ marginLeft: '30px', maxWidth: '25px', maxHeight: '25px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>

      <SelectInput
        label="Included Packaging Option:"
        className={classes.fieldInputWide}
        source="product_quotes[0].included_packaging_option_id"
        choices={values.packagingOptions || []}
        optionText={(value) =>
          value.label ? `${value.type} - ${value.label}` : value.type || value.id.toString()
        }
        optionValue="id"
        defaultValue=""
        disabled={!editing}
        fullWidth
        variant="standard"
      />

      <Box mt="20px">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Order Quantity
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Factory Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Days to Produce
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.factoryPrices?.map((item, index) => (
                <FactoryQuoteRow
                  key={`price${item.id}`}
                  index={index}
                  id={item.id}
                  editing={editing}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {editing && values.factoryPrices?.length < 3 ? (
          <IconButton
            aria-label="add-price"
            // className={classes.infoBtn}
            onClick={async () => {
              const newFactoryPrices = [...values.factoryPrices]
              // prices.push({ id: uniqueId() })
              // console.log(values.factoryPrices, prices)
              const resp = await dataProvider.create('product_quote_price', {
                data: { product_quote_id: values.product_quotes[0].id },
              })

              newFactoryPrices.push({
                id: resp.data.id,
              })

              form.change('factoryPrices', newFactoryPrices)
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </div>
  )
}

const FactoryQuoteRow = ({ index, id, editing = false }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const { values } = useFormState()
  const form = useForm()

  return (
    <TableRow key={`priceRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`factoryPrices[${index}].quantity`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`factoryPrices[${index}].unitPrice`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`factoryPrices[${index}].production_lead_time`}
          validate={required()}
        />
      </BodyTableCell>

      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        {editing ? (
          <IconButton
            aria-label="info"
            className={classes.infoBtn}
            onClick={async () => {
              const newFactoryPrices = [...values.factoryPrices]
              newFactoryPrices.splice(index, 1)
              // console.log('prices', newFactoryPrices)
              if (typeof id === 'number') {
                const delPrices = [id]
                await dataProvider.delete('product_quote_price', { delPrices })
                notify('Deleted', { type: 'info' })
              }
              // console.log('changing')
              form.change('factoryPrices', newFactoryPrices)
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        ) : null}
      </BodyTableCell>
    </TableRow>
  )
}

export default FactoryQuoteCalculator
