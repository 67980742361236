import React from 'react'
import {
  // Filter,
  List,
  // SearchInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  // EditButton,
} from 'react-admin'
import get from 'lodash/get'

// export const UserFilter = props => (
//   <Filter {...props} filterValues={{ ...props.filterValues, qFields: ['email'] }}>
//     <SearchInput InputProps={{ placeholder: 'email' }} source='q' alwaysOn />
//   </Filter>
// )

const OfferPurchaseList = (props) => (
  <List
    {...props}
    // filters={<UserFilter />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid optimized>
      <TextField source="id" />
      <TextField source="buyer_id" label="Buyer ID" />
      <FunctionField
        label="Buyer Name"
        render={(record) =>
          `${get(record, 'buyer.forename', '')} ${get(record, 'buyer.surname', '')}`
        }
      />
      <DateField source="created_at" type="date" />
      <DateField source="updated_at" showTime />
      {/* <EditButton /> */}
    </Datagrid>
  </List>
)

export default OfferPurchaseList
