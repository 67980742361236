import React from 'react'
import { useTranslate, email } from 'react-admin'
import { makeStyles, Box, Typography, Button, withStyles } from '@material-ui/core'
import TextInput from '../../../../components/OutlinedTextInput'

const useStyles = makeStyles({
  title: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '36px',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    marginTop: 30,
    marginBottom: 30,
  },
  titleli: {
    backgroundColor: '#3264FA',
    fontWeight: '600',
    fontSize: '20px',
    width: 8,
    height: 16,
    marginRight: 30,
  },
  headerDate: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#828282',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
  },
  titleOr: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '30px',
    marginTop: '60px',
  },
  inputLabel: {
    width: '125px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  sentText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    color: '#333',
    textIndent: '1em',
  },
  saveEmail: {
    width: '120px',
    marginLeft: '80px',
  },
})

const ColorButton = withStyles(theme => ({
  root: {
    color: '#fff',
    width: 120,
    marginTop: 6,
    marginLeft: 80,
    marginBottom: 18,
    border: 'none',
    backgroundColor: '#00B3C3',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#21C6CF',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
}))(Button)

const CardHeader = ({ isLi = true, label, isSend, setIsSend, date, sendAction }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [emailValue, setEmailValue] = React.useState('')

  return (
    <Box bgcolor="#F8FBFF" padding="30px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="#F8FBFF"
        minHeight="61px"
      >
        {isLi && <Box className={classes.titleli} />}
        <Box flex={1} className={classes.title}>
          {translate(label)}
        </Box>
        <Box className={classes.headerDate}>Date: {date}</Box>
      </Box>
      {!isSend ? (
        <Typography className={classes.sentText}>
          Thank you for your interest in joining our platform. XXXXXXX would like your help to fill
          out the form below so that we can start selling your products as soon as possible. We look
          forward to hearing from you soon. If you have any questions or comments, please do not
          hesitate to contact us
          <Button
            style={{ minWidth: 30, fontSize: '20px' }}
            color="primary"
            size="lg"
            onClick={() => {
              setIsSend(1)
            }}
          >
            here
          </Button>
        </Typography>
      ) : (
        <>
          {isSend > 1 ? (
            <Typography className={classes.sentText}>
              Thank you for your interest in joining our platform. We would like your help to fill
              out the form below so that we can start selling your products as soon as possible. We
              look forward to hearing from you soon. If you have any questions or comments, please
              do not hesitate to contact us at: [email address].
            </Typography>
          ) : (
            <Typography className={classes.titleText} gutterBottom>
              Send the registration form to your supplier, you&apos;ll be notified once it&apos;s
              been completed.
            </Typography>
          )}
        </>
      )}
      {isSend === 1 ? (
        <>
          <Box display="flex" alignItems="center">
            <Box style={{ width: 57 }}>
              Email<span style={{ color: 'red' }}>*</span>:
            </Box>
            <Box display="flex" width="660px" pt="0.5em" alignItems="center">
              <TextInput
                source="email"
                fullWidth
                type="email"
                label=""
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                validate={[email()]}
                onChange={e => {
                  setEmailValue(e.target.value)
                  // console.log(e.target.value)
                }}
              />
              <ColorButton
                variant="outlined"
                size="small"
                className={classes.saveEmail}
                onClick={() => {
                  // setIsSend(2)
                  sendAction(emailValue)
                }}
              >
                Send
              </ColorButton>
            </Box>
          </Box>
          <Typography gutterBottom className={classes.titleOr}>
            OR
          </Typography>
          <Typography gutterBottom className={classes.titleText}>
            Fill in the information below to begin the registration process.
          </Typography>
        </>
      ) : (
        <Box display="flex" justifyContent="flex-end" p="2em" className={classes.title}>
          —— The Porder Team
        </Box>
      )}
    </Box>
  )
}

export default CardHeader
