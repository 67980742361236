import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'proxy-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import Admin from './Admin'
import './index.css'
import 'react-calendar/dist/Calendar.css'

const envVars = ['REACT_APP_SPACES_SECRET', 'REACT_APP_SPACES_KEY']

const errs = []
envVars.map((item) => {
  if (!(item in process.env)) {
    errs.push(item)
  }
})

if (errs.length > 0) {
  ReactDOM.render('', document.getElementById('root'))
  throw new Error(`Missing env vars ${errs.toString()}`)
}

ReactDOM.render(<Admin />, document.getElementById('root'))
