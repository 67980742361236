import React from 'react'
import { List, Datagrid, DateField, TextField, EditButton } from 'react-admin'
import useStyles from '../styles'

const FieldList = (props) => {
  const classes = useStyles()

  return (
    <List
      {...props}
      //    filters={<ImprintFilter />}
      perPage={25}
      filter={{ own: `%${props.resource}%` }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid className={classes.listRowsCondense} rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="Name" />
        {props.resource === 'material' && <TextField source="own" label="System Hierarchy" />}
        <TextField source="value" label="System Value" />
        <DateField source="created_at" type="date" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default FieldList
