import {
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  UPDATE_MANY,
  DELETE_MANY,
} from './fetchActions'

export const sanitizeResource = (data = {}) => {
  const result = Object.keys(data).reduce((acc, key) => {
    if (key.startsWith('_')) {
      return acc
    }

    const dataKey = data[key]

    if (dataKey === null || dataKey === undefined) {
      return acc
    }
    if (Array.isArray(dataKey)) {
      if (typeof dataKey[0] === 'object') {
        // if var is an array of reference objects with id properties
        if (dataKey[0].id != null) {
          return {
            ...acc,
            [key]: dataKey.map(sanitizeResource),
            [`${key}Ids`]: dataKey.map((d) => d.id),
          }
        }
        return {
          ...acc,
          [key]: dataKey.map(sanitizeResource),
        }
      }
      return { ...acc, [key]: dataKey }
    }

    if (typeof dataKey === 'object') {
      return {
        ...acc,
        ...(dataKey &&
          dataKey.id && {
            [`${key}.id`]: dataKey.id,
          }),
        [key]: sanitizeResource(dataKey),
      }
    }

    return { ...acc, [key]: dataKey }
  }, {})

  return result
}

// eslint-disable-next-line no-unused-vars
export default (introspectionResults) => (aorFetchType, resource) => (res) => {
  const response = res.data

  // console.log('RESPONSEPARSER', response)

  switch (aorFetchType) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      return {
        data: response.items.map(sanitizeResource),
        total: response.total.aggregate.count,
      }

    case GET_MANY:
      return { data: response.items.map(sanitizeResource) }

    case GET_ONE:
      return { data: sanitizeResource(response.returning[0]) }

    case CREATE:
    case UPDATE:
    case DELETE:
      return {
        data:
          response.data?.returning?.length === 1
            ? sanitizeResource(response.data.returning[0])
            : { id: 0, objects: response.data.returning },
        // : res.data?.returning?.map((el) => sanitizeResource(el)),
      }

    case UPDATE_MANY:
    case DELETE_MANY:
      return { data: response.data.returning.map((x) => x.id) }

    default:
      throw Error('Expected a valid fetchType, got: ', aorFetchType)
  }
}
