import React, { useState } from 'react'
import useStyles from '../styles'
import { useSubscription, gql } from '@apollo/client'
import { Box, Button, Select, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { getDisplayName } from '../../../../utils/utils'

const OfferProductLink = ({ appUsers, id }) => {
  const classes = useStyles()
  const history = useHistory()
  const [seller, setSeller] = useState()

  const { data: { saleable_product: saleableProductData } = {} } =
    useSubscription(
      gql`subscription getSaleableProductData {
        saleable_product(where: {offer_id: {_eq: ${id}}}) {
          id
        }
      }
    `,
      //   { client: apolloClient },
    ) || []

  // console.log(saleableProductData)

  return (
    <Box display="flex" flexDirection="column" mt="20px">
      <span className={classes.title}>Product Link</span>
      <span className={classes.bodyText}>Seller Selection</span>
      <Select
        value={seller ?? ''}
        renderValue={(item) => getDisplayName(item, 20, 20, true)}
        onChange={(e) => setSeller(e.target.value)}
        style={{ width: '200px' }}
      >
        {appUsers.map((item) => (
          <MenuItem value={item} key={item.id}>
            {getDisplayName(item, 20, 20, true)}
          </MenuItem>
        ))}
      </Select>
      {seller && saleableProductData?.length > 0 && (
        <Box display="flex" flexDirection="column">
          <span className={classes.bodyText}>
            INSERT HERE ----- Extravagent Social Media Post with this button:
          </span>
          <Button
            className={classes.buyButton}
            onClick={() =>
              history.push({
                pathname: '/customerPurchase',
                search: `id=${saleableProductData[0].id}&seller=${seller.id}`,
              })
            }
          >
            BUY PRODUCT
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default OfferProductLink
