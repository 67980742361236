import gql from 'graphql-tag'

export const sourcingRequestCreate = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation createSourcingRequest {
              insert_sourcing_request_one(
                object: {${jsonParams}}
              ) {
                id
              }
            }
          `,
    parseResponse: ({ data, errors }) => ({
      data: { id: data.insert_sourcing_request_one?.id },
      errors,
    }),
  }
}

export const sourcingRequestList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'name'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter?.q ||
        (params.filter?.name && {
          name: { _ilike: `%${params.filter?.q || params.filter?.name}%` },
        })),
      ...(params.filter.sca && { sca_id: { _eq: params.filter.sca } }),
      ...(params.filter.status && { status: { _eq: params.filter.status } }),
      ...(params.filter.client && {
        client_id: { _eq: params.filter.client },
      }),
      ...(params.filter.date_gte &&
        !params.filter.date_lte && { required_date: { _gte: params.filter.date_gte } }),
      ...(params.filter.date_lte &&
        !params.filter.date_gte && { required_date: { _lte: params.filter.date_lte } }),
      ...(params.filter.date_lte &&
        params.filter.date_gte && {
          _and: [
            { required_date: { _gte: params.filter.gte } },
            { required_date: { _lte: params.filter.lte } },
          ],
        }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
          query getSourcingRequests ($where: sourcing_request_bool_exp) {
           sourcing_request(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
              id
              client_id
              target_price
              request_price
              target_quantity_min
              target_quantity_max
              destination_post_code
              delivery_address_id
              market
              description
              packaging_notes
              packaging_type
              required_date
              feedback
              name
              notes
              status
              sca_id
              payment_confirmed
              contact_email
              created_at
              priority
              urgency
              success_probability
              estimated_order_value
              brand_name
              categories {
                id
                category {
                  id
                  name
                }
              }
              feedbacks {
                id
                content
              }
              additionalFiles: files (where: {type: { _neq: PHOTO} deleted_at: { _is_null: true}}) {
                  id
                  type
                  file {
                    id
                    filename
                    url
                  }
                }
              images: files (where: {type: { _eq: PHOTO}  deleted_at: { _is_null: true}}) {
                id
                type
                file {
                  id
                  filename
                  url
                }
              }
              recommendations {
                id
                description
                recommendation_id
              }
              reference_photo {
                id
                filename
                url
              }
              references {
                id
                url
                brand
                reason
              }
              sca {
                id
                email
                forename
                surname
              }
              buyer {
                id
                email
                forename
                surname
              }
              client {
                id
                legal_name
                name
              }
              items {
                id
                name
                quantity
                budget_per_piece
                required_by
                reference
                description
                image {
                  id
                  filename
                  url
                }
              }
              quotes {
                id
              }
              products {
                id
                quantity_required
                budget_per_piece
                required_by
                reference
                description
                designs {
                  id
                  thumbnail {
                    id
                    filename
                    url
                  }
                  files {
                    id
                    file {
                      id
                      filename
                      url
                    }
                  }
                }
                product {
                  id
                  brand
                  created_at
                  is_catalogue
                  duty_rate
                  hts_code
                  name
                  notes
                  packaging_type
                  pieces_per_carton
                  root_sku
                  root_product_id
                  catalogue_sku
                  allow_colour_selection
                  root_product {
                    id
                    name
                    thumbnail_photo {
                      id
                      url
                    }
                    branch_products {
                      id
                      name
                      thumbnail_photo {
                        id
                        url
                      }
                    }
                  }
                  branch_products {
                    id
                    name
                    thumbnail_photo {
                      id
                      url
                    }
                  }
                  chargeable_weight_air
                  chargeable_weight_sea
                  sca_user_id
                  sca {
                    id
                    email
                    forename
                    surname
                  }
                  short_description
                  status
                  updated_at
                  primary_photo {
                    id
                    filename
                    url
                  }
                  thumbnail_photo {
                    id
                    filename
                    url
                  }
                  images: product_files (where: { type: { _eq: ADDITIONAL_PHOTO}}) {
                    id
                    type
                    deleted_at
                    file {
                      id
                      filename
                      url
                    }
                  }
                  additional_files: product_files (where: { type: { _neq: ADDITIONAL_PHOTO}}) {
                    id
                    type
                    deleted_at
                    file {
                      id
                      filename
                      url
                    }
                  }
                  product_categories (where: {deleted_at: {_is_null: true }}) {
                    id
                    deleted_at
                    category {
                      id
                      name
                    }
                  }
                  product_tags {
                    id
                    tag {
                      id
                      name
                    }
                  }
                  product_declarations {
                    id
                    declaration
                    file {
                      id
                      filename
                      url
                    }
                  }
                  product_dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                  packaging_dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                  carton_dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                  product_options {
                    id
                    deleted_at
                    option_value {
                      id
                      value
                      option {
                        id
                        name
                      }
                    }
                  }
                  product_specifications {
                    id
                    deleted_at
                    option_value {
                      id
                      value
                      option {
                        id
                        name
                      }
                    }
                  }
                  customisations {
                    id
                    deleted_at
                    option_value {
                      id
                      value
                      option {
                        id
                        name
                      }
                    }
                  }
                  product_quotes {
                    id
                    supplier_id
                    product_id
                    supplier {
                      id
                      name
                      primary_contact_id
                      primary_address_id
                      pickup_address_id
                    }
                    carton_dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                    included_packaging_option {
                      id
                      type
                      pieces_per_carton
                      carton_type
                      carton_label
                      carton_dimensions {
                        id
                        length
                        width
                        height
                        gross_weight
                      }
                    }
                    tooling_cost
                    tooling_lead_time
                    sample_cost
                    sample_lead_time
                    custom_sample_cost
                    packaging_type
                    pieces_per_carton
                    valid_date
                    cartons_per_pallet
                    cartons_per_container_20ft
                    cartons_per_container_40ft
                    cartons_per_container_40ft_hc
                    allow_order_splitting_by_size
                    freight_rate_air
                    freight_rate_sea
                    container_rate_20ft
                    container_rate_40ft
                    container_rate_40ft_hc
                    container_capacity_20ft
                    container_capacity_40ft
                    container_capacity_40ft_hc
                    included_packaging_option_id
                    prices (order_by: {quantity: asc_nulls_last}) {
                      id
                      quantity
                      unit_price
                      lead_time
                      est_landed_cost_air
                      est_landed_cost_sea
                      total_cbm
                      total_cartons
                      total_duties
                      shipment_weight
                      freight_cost_sea
                      freight_cost_air
                      freight_cost_sea_per_piece
                      freight_cost_air_per_piece
                      containers
                    }
                    scm_quotes (limit: 1, order_by: { created_at: desc_nulls_last}) {
                      id
                      status
                      launch_date
                      notes
                      client_notes
                      warranty_conditions
                      warranty_term
                      sourcing_request_id
                      client_sample_lead_time
                      client_sample_price
                      client_custom_sample_price
                      payment_terms ( where: { deleted_at: { _is_null: true } } ) {
                        id
                        milestone
                        value
                      }
                      features {
                        id
                        feature
                      }
                      prices (order_by: {quantity: asc_nulls_last}) {
                        id
                        quantity
                        price
                        fob_price
                        estimated_freight_air
                        estimated_freight_sea
                        production_lead_time
                        lead_time_air
                        lead_time_sea
                      }
                    }
                    specifications {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
              products_aggregate {
                aggregate {
                  count
                }
              }
            }
            sourcing_request_aggregate (where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.sourcing_request,
      total: data.sourcing_request_aggregate.aggregate.count,
    }),
  }
}

export const sourcingRequestGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            query getSourcingRequest {
              sourcing_request_by_pk(id: ${params.id}) {
                id
                client_id
                target_price
                request_price
                target_quantity_min
                target_quantity_max
                required_date
                name
                notes
                status
                destination_post_code
                delivery_address_id
                market
                description
                feedback
                packaging_type
                packaging_notes
                sca_id
                payment_confirmed
                contact_email
                created_at
                priority
                urgency
                success_probability
                estimated_order_value
                brand_name
                categories {
                  id
                  category {
                    id
                    name
                  }
                }
                feedbacks {
                  id
                  content
                }
                additionalFiles: files (where: {type: { _neq: PHOTO} deleted_at: { _is_null: true}}) {
                  id
                  type
                  file {
                    id
                    filename
                    url
                  }
                }
                images: files (where: {type: { _eq: PHOTO} deleted_at: { _is_null: true}}) {
                  id
                  type
                  file {
                    id
                    filename
                    url
                  }
                }
                recommendations {
                  id
                  description
                  recommendation_id
                }
                reference_photo {
                  id
                  filename
                  url
                }
                references {
                  id
                  url
                  brand
                  reason
                }
                sca {
                  id
                  email
                  forename
                  surname
                }
                buyer {
                  id
                  email
                  forename
                  surname
                }
                client {
                  id
                  legal_name
                  name
                  members {
                    id
                    code
                    app_user_id
                  }
                }
                items {
                  id
                  name
                  quantity
                  budget_per_piece
                  required_by
                  reference
                  description
                  image {
                    id
                    filename
                    url
                  }
                }
                quotes {
                  id
                }
                products {
                  id
                  quantity_required
                  budget_per_piece
                  required_by
                  reference
                  description
                  designs {
                    id
                    thumbnail {
                      id
                      filename
                      url
                    }
                    files {
                      id
                      file {
                        id
                        filename
                        url
                      }
                    }
                  }
                  product {
                    id
                    brand
                    created_at
                    is_catalogue
                    duty_rate
                    hts_code
                    name
                    notes
                    packaging_type
                    pieces_per_carton
                    root_sku
                    root_product_id
                    catalogue_sku
                    allow_colour_selection
                    root_product {
                      id
                      name
                      thumbnail_photo {
                        id
                        url
                      }
                      branch_products {
                        id
                        name
                        thumbnail_photo {
                          id
                          url
                        }
                      }
                    }
                    branch_products {
                      id
                      name
                      thumbnail_photo {
                        id
                        url
                      }
                    }
                    chargeable_weight_air
                    chargeable_weight_sea
                    sca_user_id
                    sca {
                      id
                      email
                      forename
                      surname
                    }
                    short_description
                    status
                    updated_at
                    primary_photo {
                      id
                      filename
                      url
                    }
                    thumbnail_photo {
                      id
                      filename
                      url
                    }
                    images: product_files (where: { type: { _eq: ADDITIONAL_PHOTO}}) {
                      id
                      type
                      deleted_at
                      file {
                        id
                        filename
                        url
                      }
                    }
                    additional_files: product_files (where: { type: { _neq: ADDITIONAL_PHOTO}}) {
                      id
                      type
                      deleted_at
                      file {
                        id
                        filename
                        url
                      }
                    }
                    product_categories {
                      id
                      deleted_at
                      category {
                        id
                        name
                      }
                    }
                    product_tags {
                      id
                      tag {
                        id
                        name
                      }
                    }
                    product_declarations {
                      id
                      declaration
                      file {
                        id
                        filename
                        url
                      }
                    }
                    product_dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                    packaging_dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                    carton_dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                    product_specifications {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                    product_options {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                    customisations {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                    product_quotes {
                      id
                      supplier_id
                      product_id
                      supplier {
                        id
                        name
                      }
                      carton_dimensions {
                        id
                        length
                        width
                        height
                        gross_weight
                      }
                      included_packaging_option {
                        id
                        type
                        pieces_per_carton
                        carton_type
                        carton_label
                        carton_dimensions {
                          id
                          length
                          width
                          height
                          gross_weight
                        }
                      }
                      tooling_cost
                      tooling_lead_time
                      sample_cost
                      sample_lead_time
                      custom_sample_cost
                      packaging_type
                      pieces_per_carton
                      valid_date
                      cartons_per_pallet
                      cartons_per_container_20ft
                      cartons_per_container_40ft
                      cartons_per_container_40ft_hc
                      allow_order_splitting_by_size
                      freight_rate_air
                      freight_rate_sea
                      container_rate_20ft
                      container_rate_40ft
                      container_rate_40ft_hc
                      container_capacity_20ft
                      container_capacity_40ft
                      container_capacity_40ft_hc
                      included_packaging_option_id
                      prices (order_by: {quantity: asc_nulls_last}) {
                        id
                        quantity
                        unit_price
                        lead_time
                        est_landed_cost_air
                        est_landed_cost_sea
                        total_cbm
                        total_cartons
                        total_duties
                        shipment_weight
                      }
                      scm_quotes (limit: 1, order_by: { created_at: desc_nulls_last}) {
                        id
                        status
                        sourcing_request_id
                        launch_date
                        notes
                        client_notes
                        warranty_conditions
                        warranty_term
                        client_sample_lead_time
                        client_sample_price
                        client_custom_sample_price
                        payment_terms ( where: { deleted_at: { _is_null: true } } ) {
                          id
                          milestone
                          value
                        }
                        features {
                          id
                          feature
                        }
                        prices (order_by: {quantity: asc_nulls_last}) {
                          id
                          quantity
                          price
                          fob_price
                          estimated_freight_air
                          estimated_freight_sea
                          production_lead_time
                          lead_time_air
                          lead_time_sea          
                        }
                      }
                      specifications {
                        id
                        deleted_at
                        option_value {
                          id
                          value
                          option {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                } 
                products_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
          `,
    parseResponse: ({ data, errors }) => ({
      data: data.sourcing_request_by_pk,
      errors,
      // validUntil,
    }),
  }
}

export const sourcingRequestDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation DelSourcingRequest {
            # update_scm_quote(where: {sourcing_request_id: {_eq: ${params.id}}}, _set: {default_packaging_option_id: null}) {
            #   affected_rows
            # }
            # delete_scm_quote_product_size_packagings(where: {scm_quote_packaging_option: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_packaging_options(where: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_product_sizes(where: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_component_options(where: {component: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_option_values(where: {option: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_options(where: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_component_colours(where: {component: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_components(where: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_payment_terms(where: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote_prices(where: {scm_quote: {sourcing_request_id: {_eq: ${params.id}}}}) {
            #   affected_rows
            # }
            # delete_scm_quote(where: {sourcing_request_id: { _eq: ${params.id}}}) {
            #   affected_rows
            #   __typename
            # }
            update_scm_quote(_set: {sourcing_request_id: null}, where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            update_sourcing_request(_set: {active_design_id: null}, where: {id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_design_files(where: {sourcing_request_design: {sourcing_request_product: {sourcing_request_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_sourcing_request_design(where: {sourcing_request_product: {sourcing_request_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_sourcing_request_products(where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_reference(where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_criteria(where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_file(where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_feedback(where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_recommendation(where: {sourcing_request_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request(where: {id: {_eq: ${params.id}}}) {
              affected_rows
            }
          }
          `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}
