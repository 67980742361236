import ProductsIcon from '@material-ui/icons/Collections'
import ProductsList from './ProductsList'
import ProductsEdit from './ProductsEdit'
// import ProductsCreate from './ProductsCreate'
// import ProductsShow from './ProductsShow'

export default {
  list: ProductsList,
  // create: ProductsCreate,
  edit: ProductsEdit,
  icon: ProductsIcon,
  // show: ProductsShow,
}
