import SaleableProductsIcon from '@material-ui/icons/Collections'
import SaleableProductsList from './SaleableProductsList'
import SaleableProductsEdit from './SaleableProductsEdit'
// import SaleableProductsCreate from './SaleableProductsCreate'
// import SaleableProductsShow from './SaleableProductsShow'

export default {
  list: SaleableProductsList,
  // create: SaleableProductsCreate,
  edit: SaleableProductsEdit,
  icon: SaleableProductsIcon,
  // show: SaleableProductsShow,
}
