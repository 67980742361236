/* eslint-disable no-unreachable */
import uploadFile from '../../../../dataProvider/upload'
import moment from 'moment'

export const handleSave = async (
  submission,
  action,
  dataProvider,
  notify,
  recordStatus,
  id,
  setID,
  record = {},
  form,
  setStatus,
  scaUserId,
) => {
  // Set to false to disable console logging
  const debugMode = false
  debugMode && console.group()
  debugMode && console.log('SAVING DATA')
  debugMode && console.time('SAVING DATA TIMER')
  debugMode && console.log('REC:', submission, action, 'SCA:', scaUserId)

  const currentTime = moment().toISOString()

  try {
    const params = {}
    const user = JSON.parse(localStorage.getItem('user'))

    const isManager =
      user['https://hasura.io/jwt/claims']['x-hasura-default-role'] === 'sca-manager'

    if (isManager) {
      params.sca_id = scaUserId || user.id
    }

    if (action === 'save') {
      params.status = `^${submission.status}^` || '^PENDING_SUBMISSION^'
      debugMode && console.log('SAVING', params.status)
    } else if (action === 'approve') {
      params.status = '^PRODUCTION^'
      debugMode && console.log('APPROVING', params.status)
    } else if (
      (action === 'submit' && submission.status === 'PENDING_SUBMISSION') ||
      !submission.status
    ) {
      params.status = '^PENDING_APPROVAL^'
      debugMode && console.log('SUBMIT FOR APPROVAL', params.status)
    } else if (
      action === 'submit' &&
      submission.status &&
      (submission.status !== 'PENDING_SUBMISSION' || submission.stauts !== 'PENDING_APPROVAL')
    ) {
      debugMode && console.log('UPDATE FOR APPROVED', params.status)
      params.status = `^${submission.status}^`
    }
    // return

    // OFFER
    debugMode && console.count('STEP')
    debugMode && console.log('OFFER', params)
    // action === 'submit'
    //   ? (params.status = '^PENDING_APPROVAL^')
    //   : (params.status = '^PENDING_SUBMISSION^')
    submission.id ? (params.id = submission.id) : null
    params.type = '^PRIVATE^'
    submission.name ? (params.name = submission.name) : null
    submission.short_description ? (params.short_description = submission.short_description) : null
    submission.incoterms ? (params.incoterms = submission.incoterms) : null
    submission.moq ? (params.moq = submission.moq) : null
    submission.investor_price_per_piece
      ? (params.investor_price_per_piece = submission.investor_price_per_piece)
      : null
    submission.notes ? (params.notes = submission.notes) : null
    submission.freight_method ? (params.freight_method = submission.freight_method) : null
    submission.quality_agency_id ? (params.quality_agency_id = submission.quality_agency_id) : null
    submission.inspection_type ? (params.inspection_type = submission.inspection_type) : null
    submission.inspection_level ? (params.inspection_level = submission.inspection_level) : null
    submission.inspection_estimate
      ? (params.inspection_estimate = Math.round(submission.inspection_estimate))
      : null
    submission.inspection_work_days
      ? (params.inspection_work_days = submission.inspection_work_days)
      : null
    submission.logistics_agency_id
      ? (params.logistics_agency_id = submission.logistics_agency_id)
      : null
    submission.hts_code ? (params.hts_code = submission.hts_code) : null
    submission.duty_rate ? (params.duty_rate = submission.duty_rate) : null
    submission.logistics_estimate
      ? (params.logistics_estimate = Math.round(submission.logistics_estimate))
      : null
    submission.logistics_quote_valid_date
      ? (params.logistics_quote_valid_date = submission.logistics_quote_valid_date)
      : null
    submission.logistics_lead_time_to_port
      ? (params.logistics_lead_time_to_port = submission.logistics_lead_time_to_port)
      : null
    submission.logistics_lead_time_to_warehouse
      ? (params.logistics_lead_time_to_warehouse = submission.logistics_lead_time_to_warehouse)
      : null
    submission.logistics_lead_time_to_port || submission.logistics_lead_time_to_warehouse
      ? (params.logistics_lead_time =
          (submission.logistics_lead_time_to_port || 0) +
          (submission.logistics_lead_time_to_warehouse || 0))
      : null
    submission.logistics_tracking_number
      ? (params.logistics_tracking_number = submission.logistics_tracking_number)
      : null
    submission.logistics_tracking_url
      ? (params.logistics_tracking_url = submission.logistics_tracking_url)
      : null
    submission.logistics_carrier ? (params.logistics_carrier = submission.logistics_carrier) : null

    // OFFER MEDIA / FILES
    debugMode && console.count('STEP')
    debugMode && console.log('PRIMARYPHOTO', params)

    // PRIMARY PHOTO
    if (submission.primary_photo) {
      if (submission.primary_photo.id) {
        params.primary_photo = {
          data: {
            id: submission.primary_photo.id,
            url: submission.primary_photo.url,
            filename: submission.primary_photo.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.primary_photo.rawFile])
        params.primary_photo = {
          data: {
            url: file.data.files[0].url,
            filename: submission.primary_photo.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('THUMBNAIL', params)

    // THUMBNAIL PHOTO
    if (submission.thumbnail) {
      if (submission.thumbnail.id) {
        params.thumbnail = {
          data: {
            id: submission.thumbnail.id,
            url: submission.thumbnail.url,
            filename: submission.thumbnail.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.thumbnail.rawFile])
        params.thumbnail = {
          data: {
            url: file.data.files[0].url,
            filename: submission.thumbnail.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('INSPQUOTEFILE', params)
    // INSPECTION QUOTE FILE
    if (submission.inspection_quote_file) {
      if (submission.inspection_quote_file.id) {
        params.inspection_quote_file = {
          data: {
            id: submission.inspection_quote_file.id,
            url: submission.inspection_quote_file.url,
            filename: submission.inspection_quote_file.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.inspection_quote_file.rawFile])
        params.inspection_quote_file = {
          data: {
            url: file.data.files[0].url,
            filename: submission.inspection_quote_file.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('CHECKFILE', params)
    // INSPECTION CHECKLIST FILE
    if (submission.inspection_checklist) {
      if (submission.inspection_checklist.id) {
        params.inspection_checklist = {
          data: {
            id: submission.inspection_checklist.id,
            url: submission.inspection_checklist.url,
            filename: submission.inspection_checklist.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.inspection_checklist.rawFile])
        params.inspection_checklist = {
          data: {
            url: file.data.files[0].url,
            filename: submission.inspection_checklist.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('LOGQUOTEFILE', params)
    // LOGISTICS QUOTE FILE
    if (submission.logistics_quote_file) {
      if (submission.logistics_quote_file.id) {
        params.logistics_quote_file = {
          data: {
            id: submission.logistics_quote_file.id,
            url: submission.logistics_quote_file.url,
            filename: submission.logistics_quote_file.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.logistics_quote_file.rawFile])
        params.logistics_quote_file = {
          data: {
            url: file.data.files[0].url,
            filename: submission.logistics_quote_file.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('TRACKINGFILES', params)

    // TRACKING FILES
    const files = []
    if (submission.trackingMedia && submission.trackingMedia?.length > 0) {
      await Promise.all(
        submission.trackingMedia.map(async (photo) => {
          if (photo.id) {
            files.push({
              file: {
                data: {
                  id: photo.id,
                  filename: photo.filename,
                  url: photo.url,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              },
              deleted_at: photo.deleted_at || null,
              type: '^TRACKING_MEDIA^',
              is_visible_to_client: true,
            })
          } else {
            const file = await uploadFile([photo.rawFile])
            files.push({
              type: '^TRACKING_MEDIA^',
              is_visible_to_client: true,
              deleted_at: photo.deleted_at || null,
              file: {
                data: {
                  url: file.data.files[0].url,
                  filename: photo.rawFile.name,
                },
                // on_conflict: {
                //   constraint: '^file_filename_key^',
                //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                // },
              },
            })
          }
        }),
      )
    }

    if (files.length > 0) {
      params.offer_files = {
        data: files,
        on_conflict: {
          constraint: '^offer_file_offer_id_file_id_type_key^',
          update_columns: ['^type^', '^file_id^', '^offer_id^', '^deleted_at^'],
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('OFFERCATS', params)

    // CATEGORIES
    if (submission.categories?.length > 0) {
      const cats = []

      submission.categories.map((cat) => {
        cats.push({
          category_id: cat,
          deleted_at: null,
        })
      })

      record?.offer_categories?.map((recCat) => {
        if (!submission.categories.some((cat) => cat === recCat.category.id)) {
          cats.push({
            category_id: recCat.category.id,
            deleted_at: currentTime,
          })
        }
      })

      if (cats.length > 0) {
        params.offer_categories = {
          data: cats,
          on_conflict: {
            constraint: '^offer_categories_offer_id_category_id_key^',
            update_columns: ['^category_id^', '^deleted_at^'],
          },
        }
        debugMode && console.log('PARCATS', params.offer_categories)
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('OFFERPAYS', params)
    // OFFER.PAYMENT TERMS
    if (
      submission.clientPaymentTerms?.length > 0 ||
      submission.factoryPaymentTerms?.length > 0 ||
      submission.qualityPaymentTerms?.length > 0 ||
      submission.logisticsPaymentTerms?.length > 0
    ) {
      const paymentTerms = []
      submission.clientPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^CLIENT^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      submission.factoryPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^FACTORY^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      submission.qualityPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^QUALITY^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      submission.logisticsPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^LOGISTICS^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      paymentTerms.length > 0
        ? (params.offer_payment_terms = {
            data: paymentTerms,
            on_conflict: {
              constraint: '^offer_payment_terms_offer_id_milestone_type_key^',
              update_columns: ['^value^', '^milestone^'],
            },
          })
        : null
    }

    debugMode && console.count('STEP')
    debugMode && console.log('QUOTE', params)
    // QUOTE
    params.client_quote = {
      data: {
        id: submission.client_quote.id,
        product_quote: { data: { id: submission.client_quote.product_quote.id } },
        ...(!!submission.warranty_conditions && { id: submission.warranty_conditions }),
        ...(!!submission.warranty_term && { id: submission.warranty_term }),
      },
      on_conflict: {
        constraint: '^client_quote_pkey^',
        update_columns: ['^warranty_conditions^', '^warranty_term^'],
      },
    }
    submission.quote?.id ? (params.quote_id = submission.quote.id) : null
    // submission.quote?.product?.id
    //   ? (params.quote.data.product_id = submission.quote.product.id)
    //   : null

    submission.packaging_type ? (params.packaging_type = submission.packaging_type) : null
    submission.pieces_per_carton ? (params.pieces_per_carton = submission.pieces_per_carton) : null
    submission.cartons_per_container_20ft
      ? (params.cartons_per_container_20ft = submission.cartons_per_container_20ft)
      : null
    submission.cartons_per_container_40ft
      ? (params.cartons_per_container_40ft = submission.cartons_per_container_40ft)
      : null
    submission.cartons_per_container_40ft_hc
      ? (params.cartons_per_container_40ft_hc = submission.cartons_per_container_40ft_hc)
      : null
    submission.cartons_per_pallet
      ? (params.cartons_per_pallet = submission.cartons_per_pallet)
      : null
    submission.factoryQuoteQuantity
      ? (params.client_quote.data.product_quote.data.quantity = submission.factoryQuoteQuantity)
      : null
    submission.factoryQuotePrice
      ? (params.client_quote.data.product_quote.data.price = submission.factoryQuotePrice)
      : null
    submission.factoryQuoteLeadTime
      ? (params.client_quote.data.product_quote.data.lead_time = submission.factoryQuoteLeadTime)
      : null
    submission.client_quote?.product_quote?.valid_date
      ? (params.client_quote.data.product_quote.data.valid_date =
          submission.client_quote?.product_quote?.valid_date)
      : null
    submission.client_quote.product_quote?.supplier?.id
      ? (params.client_quote.data.product_quote.data.supplier_id =
          submission.client_quote.product_quote?.supplier?.id)
      : null
    submission.factoryQuoteToolingCost
      ? (params.client_quote.data.product_quote.data.tooling_cost =
          submission.factoryQuoteToolingCost)
      : null
    submission.factoryQuoteSampleCost
      ? (params.client_quote.data.product_quote.data.sample_cost =
          submission.factoryQuoteSampleCost)
      : null
    submission.factoryQuoteSampleLeadTime
      ? (params.client_quote.data.product_quote.data.sample_lead_time =
          submission.factoryQuoteSampleLeadTime)
      : null
    submission.factoryQuoteCustomSampleCost
      ? (params.client_quote.data.product_quote.data.custom_sample_cost =
          submission.factoryQuoteCustomSampleCost)
      : null
    submission.quote?.other_requirements
      ? (params.quote.data.other_requirements = submission.quote.other_requirements)
      : null

    debugMode && console.count('STEP')
    debugMode && console.log('DIMS', params)
    // QUOTE.PRODUCT DIMENSIONS
    if (submission.product_dimensions) {
      params.product_dimensions = {
        data: {},
        on_conflict: {
          constraint: '^dimension_pkey^',
          update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
        },
      }
    }
    if (submission.carton_dimensions) {
      params.carton_dimensions = {
        data: {},
        on_conflict: {
          constraint: '^dimension_pkey^',
          update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
        },
      }
    }
    if (submission.packaging_dimensions) {
      params.packaging_dimensions = {
        data: {},
        on_conflict: {
          constraint: '^dimension_pkey^',
          update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
        },
      }
    }
    submission.product_dimensions?.length
      ? (params.product_dimensions.data.length = Math.round(submission.product_dimensions?.length))
      : null
    submission.product_dimensions?.width
      ? (params.product_dimensions.data.width = Math.round(submission.product_dimensions?.width))
      : null
    submission.product_dimensions?.height
      ? (params.product_dimensions.data.height = Math.round(submission.product_dimensions?.height))
      : null
    submission.product_dimensions?.gross_weight
      ? (params.product_dimensions.data.gross_weight = Math.round(
          submission.product_dimensions?.gross_weight,
        ))
      : null
    submission.product_dimensions?.id
      ? (params.product_dimensions.data.id = submission.product_dimensions?.id)
      : null
    // QUOTE.PACKAGING DIMENSIONS
    submission.packaging_dimensions?.length
      ? (params.packaging_dimensions.data.length = Math.round(
          submission.packaging_dimensions?.length,
        ))
      : null
    submission.packaging_dimensions?.width
      ? (params.packaging_dimensions.data.width = Math.round(
          submission.packaging_dimensions?.width,
        ))
      : null
    submission.packaging_dimensions?.height
      ? (params.packaging_dimensions.data.height = Math.round(
          submission.packaging_dimensions?.height,
        ))
      : null
    submission.packaging_dimensions?.gross_weight
      ? (params.packaging_dimensions.data.gross_weight = Math.round(
          submission.packaging_dimensions?.gross_weight,
        ))
      : null
    submission.packaging_dimensions?.id
      ? (params.packaging_dimensions.data.id = submission.packaging_dimensions?.id)
      : null
    // QUOTE.CARTON DIMENSIONS
    submission.carton_dimensions?.length
      ? (params.carton_dimensions.data.length = Math.round(submission.carton_dimensions?.length))
      : null
    submission.carton_dimensions?.width
      ? (params.carton_dimensions.data.width = Math.round(submission.carton_dimensions?.width))
      : null
    submission.carton_dimensions?.height
      ? (params.carton_dimensions.data.height = Math.round(submission.carton_dimensions?.height))
      : null
    submission.carton_dimensions?.gross_weight
      ? (params.carton_dimensions.data.gross_weight = Math.round(
          submission.carton_dimensions?.gross_weight,
        ))
      : null
    submission.carton_dimensions?.id
      ? (params.carton_dimensions.data.id = submission.carton_dimensions?.id)
      : null

    // if (Object.keys(params.carton_dimensions?.data).length === 0) {
    //   delete params.carton_dimensions
    // } else {
    //   params.carton_dimensions.on_conflict = {
    //     constraint: '^dimension_pkey^',
    //     update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
    //   }
    // }
    // if (Object.keys(params.packaging_dimensions?.data).length === 0) {
    //   delete params.packaging_dimensions
    // } else {
    //   params.packaging_dimensions.on_conflict = {
    //     constraint: '^dimension_pkey^',
    //     update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
    //   }
    // }
    // if (Object.keys(params.product_dimensions?.data).length === 0) {
    //   delete params.product_dimensions
    // } else {
    //   params.product_dimensions.on_conflict = {
    //     constraint: '^dimension_pkey^',
    //     update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
    //   }
    // }

    debugMode && console.count('STEP')
    debugMode && console.log('QUOTEFILE', submission.client_quote.product_quote.quote)
    // QUOTE FILE

    if (submission.client_quote?.product_quote?.quote) {
      if (submission.client_quote.product_quote.quote.id) {
        params.client_quote.data.product_quote.data.quote = {
          data: {
            id: submission.client_quote.product_quote.quote.id,
            url: submission.client_quote.product_quote.quote.url,
            filename: submission.client_quote.product_quote.quote.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.client_quote.product_quote.quote.rawFile])
        params.client_quote.data.product_quote.data.quote = {
          data: {
            url: file.data.files[0].url,
            filename: submission.client_quote.product_quote.quote.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('QUOTECERTS', params)
    // QUOTE.CERTIFICATES
    if (submission.certificates?.length > 0) {
      const certs = []
      await Promise.all(
        submission.certificates.map(async (item) => {
          const cert = {
            certification: {
              data: {
                id: item.id,
                name: item.name,
                notifying_body: item.notifying_body,
                number: item.number,
                valid_start_date: item.valid_start_date,
                valid_end_date: item.valid_end_date,
                type: 'product',
              },
              on_conflict: {
                constraint: '^supplier_certification_pkey^',
                update_columns: [
                  '^name^',
                  '^number^',
                  '^notifying_body^',
                  '^valid_end_date^',
                  '^valid_start_date^',
                  '^type^',
                  '^file_id^',
                ],
              },
            },
          }
          if (item.document) {
            if (item.document.id) {
              cert.certification.data.document = {
                data: {
                  filename: item.document.filename,
                  url: item.document.url,
                  id: item.document.id,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              }
            } else {
              const file = await uploadFile([item.document.rawFile])
              cert.certification.data.document = {
                data: {
                  filename: item.document.rawFile.name,
                  url: file.data.files[0].url,
                },
              }
            }
          }

          certs.push(cert)
        }),
      )
      if (certs.length > 0) {
        params.quote.data.certificates = {
          data: certs,
          on_conflict: {
            constraint: '^product_quote_certificates_product_quote_id_supplier_certificat^',
            update_columns: '^supplier_certification_id^',
          },
        }
      }
    }

    // QUOTE.SPECIFICATIONS
    const activeSpecifications = []

    // COLOURS
    debugMode && console.count('STEP')
    const productColours = record?.product_quotes
      ? record?.product_quotes[0].specifications?.filter(
          (spec) => spec.option_value?.option?.name === 'Colour',
        )
      : []
    debugMode && console.log('COLOURS', 'REC', productColours, 'SUB', submission.productColours)
    if (submission.productColours?.length > 0) {
      await Promise.all(
        submission.productColours.map((item) => {
          activeSpecifications.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Colour',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    if (productColours?.length > 0) {
      await Promise.all(
        productColours.map((spec) => {
          if (!submission.productColours.some((opt) => opt.id === spec.id)) {
            activeSpecifications.push({
              id: spec.id,
              deleted_at: currentTime,
              option_value: {
                data: {
                  value: spec.option_value.value,
                  option: {
                    data: {
                      name: spec.option_value.option.name,
                    },
                    on_conflict: {
                      constraint: '^option_name_key^',
                      update_columns: '^name^',
                    },
                  },
                },
                on_conflict: {
                  constraint: '^option_value_option_id_value_key^',
                  update_columns: ['^value^', '^option_id^'],
                },
              },
            })
          }
        }),
      )
    }

    debugMode && console.count('STEP')
    const productMaterials = record?.product_quotes
      ? record?.product_quotes[0].specifications?.filter(
          (spec) => spec.option_value?.option?.name === 'Material',
        )
      : []
    debugMode && console.log('MATS', 'REC', productMaterials, 'SUB', submission.productMaterials)
    // MATERIALS
    if (submission.productMaterials?.length > 0) {
      await Promise.all(
        submission.productMaterials.map((item) => {
          activeSpecifications.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Material',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    if (productMaterials?.length > 0) {
      await Promise.all(
        productMaterials.map((spec) => {
          if (!submission.productMaterials.some((opt) => opt.id === spec.id)) {
            activeSpecifications.push({
              id: spec.id,
              deleted_at: currentTime,
              option_value: {
                data: {
                  value: spec.option_value.value,
                  option: {
                    data: {
                      name: spec.option_value.option.name,
                    },
                    on_conflict: {
                      constraint: '^option_name_key^',
                      update_columns: '^name^',
                    },
                  },
                },
                on_conflict: {
                  constraint: '^option_value_option_id_value_key^',
                  update_columns: ['^value^', '^option_id^'],
                },
              },
            })
          }
        }),
      )
    }

    // OTHER SPECIFICATIONS
    const otherSpecifications = []

    const uniqueSpecs = []
    if (record?.product_quotes) {
      record?.product_quotes[0].specifications?.map((spec) => {
        if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
          uniqueSpecs.push(spec.option_value.option.name)
        }
      })
    }

    debugMode && console.log(uniqueSpecs)

    uniqueSpecs.map((spec) => {
      if (spec !== 'Colour' && spec !== 'Material' && spec !== 'Imprint') {
        otherSpecifications.push({
          name: spec,
          option_value: {
            value: record?.product_quotes
              ? record?.product_quotes[0].specifications
                  .filter((qspec) => qspec.option_value.option?.name === spec)
                  .map((item) => item.option_value?.value)
              : [],
          },
        })
      }
    })

    debugMode && console.count('STEP')
    debugMode &&
      console.log('SPECS', 'REC', otherSpecifications, 'SUB', submission.otherSpecifications)
    if (submission.otherSpecifications?.length > 0) {
      await Promise.all(
        submission.otherSpecifications.map((item) => {
          debugMode && console.count('STEP')
          debugMode && console.log('SPEC', item)
          item.option_value?.value?.map((val) => {
            activeSpecifications.push({
              deleted_at: null,
              option_value: {
                data: {
                  value: val,
                  option: {
                    data: {
                      name: item.name,
                    },
                    on_conflict: {
                      constraint: '^option_name_key^',
                      update_columns: '^name^',
                    },
                  },
                },
                on_conflict: {
                  constraint: '^option_value_option_id_value_key^',
                  update_columns: ['^value^', '^option_id^'],
                },
              },
            })
          })

          const productSpecs = record?.product_quotes
            ? record?.product_quotes[0].specifications?.filter(
                (spec) => spec.option_value?.option?.name === item.name,
              )
            : []

          if (productSpecs?.length > 0) {
            productSpecs.map((spec) => {
              debugMode && console.log('PRODSPEC', spec)
              if (
                !item.option_value.value.some((opt) => {
                  debugMode && console.log(opt, spec)
                  return opt === spec.option_value.value
                })
              ) {
                debugMode && console.log('DELSPEC', spec)
                activeSpecifications.push({
                  id: spec.id,
                  deleted_at: currentTime,
                  option_value: {
                    data: {
                      value: spec.option_value.value,
                      option: {
                        data: {
                          name: spec.option_value.option.name,
                        },
                        on_conflict: {
                          constraint: '^option_name_key^',
                          update_columns: '^name^',
                        },
                      },
                    },
                    on_conflict: {
                      constraint: '^option_value_option_id_value_key^',
                      update_columns: ['^value^', '^option_id^'],
                    },
                  },
                })
              }
            })
          }
        }),
      )
    }

    if (activeSpecifications.length > 0) {
      params.quote.data.specifications = {
        data: activeSpecifications,
        on_conflict: {
          constraint: '^product_quote_specification_product_quote_id_option_value_id_ke^',
          update_columns: ['^option_value_id^', '^deleted_at^'],
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('LOGDECS', params)
    // PRODUCT DECLARATIONS
    if (submission.quote?.product?.product_declarations?.length > 0) {
      const decs = []
      await Promise.all(
        submission.quote?.product?.product_declarations.map(async (dec) => {
          if (dec.file?.id) {
            decs.push({
              ...(!!dec.id && { id: dec.id }),
              // id: dec.id || null,
              declaration: dec.declaration,
              file: {
                data: {
                  id: dec.file.id,
                  url: dec.file.url,
                  filename: dec.file.filename,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              },
            })
          } else {
            const file = await uploadFile([dec.file.rawFile])
            decs.push({
              ...(!!dec.id && { id: dec.id }),
              // id: dec.id || null,
              declaration: dec.declaration,
              file: {
                data: {
                  filename: dec.file.rawFile.name,
                  url: file.data.files[0].url,
                },
                // on_conflict: {
                //   constraint: '^file_filename_key^',
                //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                // },
              },
            })
          }
        }),
      )

      params.quote.data.product = {
        data: {
          product_declarations: {
            data: decs,
            on_conflict: {
              constraint: '^product_declaration_pkey^',
              update_columns: '^declaration^',
            },
          },
        },
        on_conflict: { constraint: '^product_pkey^', update_columns: ['^product_id^'] },
      }
    }

    if (Object.keys(params.client_quote?.data?.product_quote?.data).length === 0) {
      delete params.client_quote.data.product_quote
    } else {
      params.client_quote.data.product_quote.on_conflict = {
        constraint: '^product_quote_pkey1^',
        update_columns: [
          '^lead_time^',
          '^valid_date^',
          '^other_requirements^',
          '^quote_file_id^',
          // '^supplier_id^',
        ],
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PO DATA:', params)

    const poFile =
      submission.poFile && !submission.poFile?.id
        ? await uploadFile([submission.poFile?.rawFile])
        : null

    debugMode && console.log('POFILE', poFile, submission.poFile)

    if (submission.offer_purchases?.length > 0) {
      params.offer_purchases = {
        data: {
          ...(!!submission.offer_purchases[0].id && { id: submission.offer_purchases[0].id }),
          status: '^PAYMENT_RECEIVED^',
          ...(!!submission.offer_purchases[0].brand && {
            brand: submission.offer_purchases[0].brand,
          }),
          ...(!!submission.offer_purchases[0].quantity && {
            quantity: submission.offer_purchases[0].quantity,
          }),
          ...(!!submission.offer_purchases[0].client_quantity &&
            !!submission.offer_purchases[0].client_price && {
              amount:
                submission.offer_purchases[0].client_price *
                submission.offer_purchases[0].client_quantity,
            }),
          platform: '^BENI_ADMIN^',
          ...(!!submission.offer_purchases[0].required_delivery_date && {
            required_delivery_date: submission.offer_purchases[0].required_delivery_date,
          }),
          ...(!!submission.offer_purchases[0].purchase_order_date && {
            purchase_order_date: submission.offer_purchases[0].purchase_order_date,
          }),
          ...(!!submission.offer_purchases[0].client_sku && {
            client_sku: submission.offer_purchases[0].client_sku,
          }),
          ...(!!submission.offer_purchases[0].client_price && {
            client_price: submission.offer_purchases[0].client_price,
          }),
          ...(!!submission.offer_purchases[0].client_quantity && {
            client_quantity: submission.offer_purchases[0].client_quantity,
          }),
          ...(!!submission.offer_purchases[0].purchase_order_number && {
            purchase_order_number: submission.offer_purchases[0].purchase_order_number,
          }),
          ...(!!submission.offer_purchases[0].buyer?.id && {
            buyer_id: submission.offer_purchases[0].buyer.id,
          }),
          ...(!!submission.offer_purchases[0].client?.id && {
            client_id: submission.offer_purchases[0].client.id,
          }),
          ...(submission.poFile && {
            purchase_order_file: submission.poFile?.id
              ? {
                  data: {
                    id: submission.poFile.id,
                    filename: submission.poFile.filename,
                    url: submission.poFile.url,
                  },
                  on_conflict: {
                    constraint: '^file_pkey^',
                    update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                  },
                }
              : {
                  data: {
                    filename: submission.poFile.filename,
                    url: poFile.data.files[0].url,
                  },
                  // on_conflict: {
                  //   constraint: '^file_filename_key^',
                  //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                  // },
                },
          }),
        },
        on_conflict: {
          constraint: '^offer_purchase_pkey^',
          update_columns: [
            '^brand^',
            '^buyer_id^',
            '^quantity^',
            '^required_delivery_date^',
            '^purchase_order_date^',
            '^amount^',
            '^status^',
            // '^client_id^',
            '^client_sku^',
            '^client_price^',
            '^client_quantity^',
            '^purchase_order_number^',
            '^purchase_order_file_id^',
          ],
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PARAMS:', params)
    const onConflict = {
      constraint: '^offer_pkey^',
      update_columns: [
        // '^long_description^',
        // '^supplier_pickup_address_id^',
        // '^supplier_contact_id^',
        // '^supplier_factory_address_id^',
        '^sku^',
        '^short_description^',
        // '^shipper_id^',
        // '^warranty^',
        // '^upc_code^',
        '^name^',
        // '^retail_price^',
        // '^marketing_budget^',
        '^duty_rate^',
        '^logistics_estimate^',
        '^inspection_estimate^',
        // '^ordering_fees^',
        // '^platform_fees^',
        // '^commission_fees^',
        '^moq^',
        // '^supply_chain_fees^',
        // '^fulfillment_fees^',
        // '^consignee_id^',
        // '^destination_address_id^',
        '^hts_code^',
        '^inspection_level^',
        '^inspection_type^',
        '^inspection_work_days^',
        '^quality_agency_id^',
        '^logistics_agency_id^',
        // '^logistics_disclaimer^',
        '^logistics_lead_time_to_port^',
        '^logistics_lead_time_to_warehouse^',
        '^logistics_carrier^',
        '^logistics_tracking_number^',
        '^logistics_tracking_url^',
        '^logistics_quote_valid_date^',
        '^quote_id^',
        '^status^',
        '^thumbnail_photo_id^',
        '^inspection_checklist_file_id^',
        '^logistics_quote_file_id^',
        '^inspection_quote_file_id^',
        '^primary_photo_id^',
        // '^upc_code_photo_id^',
        // '^minimum_purchase_value^',
        // '^minimum_purchase_quantity^',
        // '^estimated_ROI^',
        '^investor_price_per_piece^',
        '^incoterms^',
        '^freight_method^',
        '^notes^',
        '^pieces_per_carton^',
        '^cartons_per_pallet^',
        '^cartons_per_container_20ft^',
        '^cartons_per_container_40ft^',
        '^cartons_per_container_40ft_hc^',
        '^packaging_type^',
        '^sample_cost^',
        '^sample_lead_time^',
        '^custom_sample_cost^',
        '^packaging_dimensions_id^',
        '^product_dimensions_id^',
        '^carton_dimensions_id^',
      ],
    }

    debugMode && console.count('STEP')
    // debugMode && console.log('OFFER: ', offer)

    const resp = await dataProvider.create('offer', { objects: params, on_conflict: onConflict })

    debugMode && console.log(resp)

    if (setStatus) {
      setStatus(params.status.replaceAll('^', ''))
    }

    form.change('id', resp.data.id)
    form.change('status', params.status.replaceAll('^', ''))
    if (resp.data.offer_purchases && resp.data.offer_purchases.length > 0) {
      resp.data.offer_purchases[0].id &&
        form.change('offer_purchases[0].id', resp.data.offer_purchases[0].id)
      resp.data.offer_purchases[0].purchase_order_file_id &&
        form.change('poFile.id', resp.data.offer_purchases[0].purchase_order_file_id)
      resp.data.offer_purchases[0].purchase_order_file?.url &&
        form.change('poFile.url', resp.data.offer_purchases[0].purchase_order_file.url)
    }
    resp.data.primary_photo_id && form.change('primary_photo.id', resp.data.primary_photo_id)
    resp.data.primary_photo?.url && form.change('primary_photo.url', resp.data.primary_photo.url)
    resp.data.thumbnail_photo_id && form.change('thumbnail.id', resp.data.thumbnail_photo_id)
    resp.data.thumbnail?.url && form.change('thumbnail.url', resp.data.thumbnail.url)
    resp.data.logistics_quote_file_id &&
      form.change('logistics_quote_file.id', resp.data.logistics_quote_file_id)
    resp.data.logistics_quote_file?.url &&
      form.change('logistics_quote_file.url', resp.data.logistics_quote_file.url)
    resp.data.inspection_quote_file_id &&
      form.change('inspection_quote_file.id', resp.data.inspection_quote_file_id)
    resp.data.inspection_quote_file?.url &&
      form.change('inspection_quote_file.url', resp.data.inspection_quote_file.url)
    resp.data.inspection_checklist_file_id &&
      form.change('inspection_checklist.id', resp.data.inspection_checklist_file_id)
    resp.data.inspection_checklist?.url &&
      form.change('inspection_checklist.url', resp.data.inspection_checklist.url)

    resp.data.quote?.id && form.change('quote.id', resp.data.quote.id)
    resp.data.quote?.id && form.change('client_quote.product_quote.id', resp.data.quote.id)
    resp.data.quote?.quote_file_id && form.change('quote.quote.id', resp.data.quote.quote_file_id)
    resp.data.quote?.quote?.url && form.change('quote.quote.url', resp.data.quote.quote.url)

    if (params.id) {
      notify('Updated Successfully.', { type: 'info' })
    } else {
      notify('Created Successfully.', { type: 'info' })
    }
    debugMode && console.timeEnd('SAVING DATA TIMER')
    debugMode && console.groupEnd()
  } catch (error) {
    console.log(error)
    debugMode && console.timeEnd('SAVING DATA TIMER')
    debugMode && console.groupEnd()
    notify(error.message.split('.')[0], { type: 'warning' })

    return false
  }
}

export const validation = (values) => {
  // console.log(values, values.action)
  if (values?.action === 'saving') {
    const errors = {}
    if (!values?.name) {
      errors.name = 'The product name is required'
    }
    // console.log('SAVE_ERRORS', errors)
    return errors
  }
  const errors = {}
  if (!values?.primary_photo) {
    errors.primary_photo = 'The primary photo is required'
  }
  if (!values?.categories) {
    errors.categories = 'At least one category is required'
  }
  if (!values?.short_description) {
    errors.short_description = 'The short description is required'
  }
  if (!values?.name) {
    errors.name = 'The product name is required'
  }
  // if (!values?.productColours) {
  //   errors.productColours = 'At least one product colour is required'
  // }
  // if (!values?.productMaterials) {
  //   errors.productMaterials = 'At least on product material is required'
  // }

  if (!values?.factoryQuotePrice) {
    errors.factoryQuotePrice = 'The factory price is required'
  }
  if (!values?.factoryQuoteQuantity) {
    errors.factoryQuoteQuantity = 'The factory quantity is required'
  }
  if (!values?.factoryQuoteLeadTime) {
    errors.factoryQuoteLeadTime = 'The factory lead time is required'
  }

  if (!values?.client_quote?.product_quote?.supplier?.id) {
    errors.client_quote && errors.client_quote.product_quote
      ? (errors.client_quote.product_quote.supplier = { id: 'The supplier is required' })
      : (errors.client_quote = { product_quote: { supplier: { id: 'The supplier is required' } } })
  }
  if (!values?.factoryPaymentTerms) {
    errors.factoryPaymentTerms = 'The factory payment terms are required'
  }

  if (!values?.client_quote?.product_quote?.valid_date) {
    errors.client_quote && errors.client_quote.product_quote
      ? (errors.client_quote.product_quote.valid_date = 'The factory quote valid date is required')
      : (errors.client_quote = {
          product_quote: { valid_date: 'The factory quote valid date is required' },
        })
  }

  if (!values?.client_quote?.product_quote?.quote) {
    errors.client_quote && errors.client_quote.product_quote
      ? (errors.client_quote.product_quote.quote = 'The factory invoice file is required')
      : (errors.client_quote = {
          product_quote: { quote: 'The factory invoice file is required' },
        })
  }

  if (!values?.packaging_type) {
    errors.packaging_type = 'The packaging type is required'
  }

  if (!values?.pieces_per_carton) {
    errors.pieces_per_carton = 'The pieces per carton is required'
  }

  if (!values?.carton_dimensions?.height) {
    errors.carton_dimensions
      ? (errors.carton_dimensions.height = 'The carton dimensions height is required')
      : (errors.carton_dimensions = { height: 'The carton dimensions height is required' })
  }
  if (!values?.carton_dimensions?.length) {
    errors.carton_dimensions
      ? (errors.carton_dimensions = 'The carton dimensions length is required')
      : (errors.carton_dimensions = { length: 'The carton dimensions length is required' })
  }
  if (!values?.carton_dimensions?.width) {
    errors.carton_dimensions
      ? (errors.carton_dimensions = 'The carton dimensions width is required')
      : (errors.carton_dimensions = { width: 'The carton dimensions width is required' })
  }
  if (!values?.carton_dimensions?.gross_weight) {
    errors.carton_dimensions
      ? (errors.carton_dimensions = 'The carton dimensions weight is required')
      : (errors.carton_dimensions = { gross_weight: 'The carton dimensions weight is required' })
  }
  if (!values?.product_dimensions?.height) {
    errors.product_dimensions
      ? (errors.product_dimensions = 'The product dimensions height is required')
      : (errors.product_dimensions = { height: 'The product dimensions height is required' })
  }
  if (!values?.product_dimensions?.length) {
    errors.product_dimensions
      ? (errors.product_dimensions = 'The product dimensions length is required')
      : (errors.product_dimensions = { length: 'The product dimensions length is required' })
  }
  if (!values?.product_dimensions?.width) {
    errors.product_dimensions
      ? (errors.product_dimensions = 'The product dimensions width is required')
      : (errors.product_dimensions = { width: 'The product dimensions width is required' })
  }
  if (!values?.product_dimensions?.gross_weight) {
    errors.product_dimensions
      ? (errors.product_dimensions = 'The product dimensions weight is required')
      : (errors.product_dimensions = { gross_weight: 'The product dimensions weight is required' })
  }
  if (!values?.clientPaymentTerms || values?.clientPaymentTerms?.length < 1) {
    errors.clientPaymentTerms = 'The client payment terms are required'
  }
  if (!values?.factoryPaymentTerms || values?.factoryPaymentTerms?.length < 1) {
    errors.factoryPaymentTerms = 'The factory payment terms are required'
  }
  // console.log('SUBMIT_ERRORS', errors)
  return errors
}

export const getDataLists = async (setDataLists, dataProvider, notify, abortController) => {
  const debugMode = false
  debugMode && console.group()
  debugMode && console.log('GETTING DATA LISTS')
  debugMode && console.time('GETTING DATA TIMER')
  try {
    const listsTemp = {}

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING PACKAGING STYLES')
    const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
      filter: { own: 'packagingStyle' },
    })
    listsTemp.packagingTypes = packagingTypesTemp
    debugMode && console.timeEnd('GETTING PACKAGING STYLES')

    // const { data: certificationTypesList } = await dataProvider.getList('certificationType', {})
    // listsTemp.certificationTypes = certificationTypesList

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING CATEGORIES')
    const { data: categoriesTemp } = await dataProvider.getList('categories', {})
    listsTemp.categories = categoriesTemp
    debugMode && console.timeEnd('GETTING CATEGORIES')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING MATERIALS')
    const { data: materialsTemp } = await dataProvider.getList('materials', {
      filter: { own: '%material%' },
    })
    listsTemp.materials = materialsTemp
    debugMode && console.timeEnd('GETTING MATERIALS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING IMPRINTS')
    const { data: imprintsTemp } = await dataProvider.getList('imprints', {
      filter: { own: 'imprintTechnique' },
    })
    listsTemp.imprints = imprintsTemp
    debugMode && console.timeEnd('GETTING IMPRINTS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING SUPPLIERS')
    const { data: suppliersTemp } = await dataProvider.getList('supplier', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    })
    listsTemp.suppliers = suppliersTemp
    debugMode && console.timeEnd('GETTING SUPPLIERS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING QUALITY AGENCIES')
    const { data: qualityAgenciesTemp } = await dataProvider.getList('qualityAgency', {})
    listsTemp.qualityAgencies = qualityAgenciesTemp
    debugMode && console.timeEnd('GETTING QUALITY AGENCIES')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING PAYMENT MILESTONES')
    const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
    listsTemp.factoryMilestones = milestonesTemp.filter((item) => item.type === 'factory')
    listsTemp.qualityMilestones = milestonesTemp.filter((item) => item.type === 'quality')
    listsTemp.logisticsMilestones = milestonesTemp.filter((item) => item.type === 'logistics')
    listsTemp.clientMilestones = milestonesTemp.filter((item) => item.type === 'client')
    debugMode && console.timeEnd('GETTING PAYMENT MILESTONES')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING INSPECTION LEVELS')
    const { data: inspLevelsTemp } = await dataProvider.getList('inspectionLevel', {})
    listsTemp.inspectionLevels = inspLevelsTemp
    debugMode && console.timeEnd('GETTING INSPECTION LEVELS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING INSPECTION TYPES')
    const { data: inspectionTypesTemp } = await dataProvider.getList('inspectionType', {})
    listsTemp.inspectionTypes = inspectionTypesTemp
    debugMode && console.timeEnd('GETTING INSPECTION TYPES')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING FREIGHT FORWARDERS')
    const { data: logisticsTemp } = await dataProvider.getList('freightForwarder', {})
    listsTemp.logisticsProviders = logisticsTemp
    debugMode && console.timeEnd('GETTING FREIGHT FORWARDERS')

    debugMode && console.count('STEP')
    debugMode && console.time('GETTING CLIENTS')
    const { data: clientsTemp } = await dataProvider.getList('organisation', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { is_public: true },
    })
    listsTemp.clients = clientsTemp
    debugMode && console.timeEnd('GETTING CLIENTS')

    if (!abortController.signal.aborted) {
      setDataLists(listsTemp)
    }
  } catch (error) {
    console.log(error)
    notify(error.message.split('.')[0], { type: 'warning' })
  }
}
