// import ClientIcon from '@material-ui/icons/AccountBox'
import ClientIcon from '@material-ui/icons/Group'
import ClientList from './ClientList'
import ClientEdit from './ClientEdit'
import ClientCreate from './ClientCreate'

export default {
  list: ClientList,
  create: ClientCreate,
  edit: ClientEdit,
  icon: ClientIcon,
}
