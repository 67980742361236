import React from 'react'
import {
  Datagrid,
  // EditButton,
  List,
  TextField,
  // ImageField,
  NumberField,
  Pagination,
  // TextInput,
  SearchInput,
  // DateInput,
  //   NumberInput,
  DeleteButton,
  SelectInput,
  FunctionField,
} from 'react-admin'
// import get from 'lodash/get'
import { getDisplayName } from '../../../utils/utils'
import SupplierDefaultImg from '../../../assets/supplier_default.png'
import useStyles from './styles'

const OfferPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const ListCom = (props) => {
  const classes = useStyles()

  const offerFilters = [
    // <SearchInput key='searchQ' label="Search" source="q" alwaysOn />,
    <SearchInput
      key="search"
      InputProps={{ placeholder: 'Product SKU / Name' }}
      source="name"
      alwaysOn
      className={classes.searchBackground}
      variant="standard"
    />,
    // <SelectInput options={[{name: }]} key='titleSearch' label="Title" source="title" defaultValue="Hello, World!" />,
    // <SelectInput
    //   key='supplierSearch'
    //   InputProps={{ placeholder: 'Supplier' }}
    //   label='Supplier'
    //   source='supplier'
    //   choices={[{ value: 'SUP1' }, { value: 'SUP2' }]}
    //   optionText='value'
    //   optionValue='value'
    //   helperText='Choose a supplier'
    //   emptyText='Supplier'
    //   className={classes.hideLabelAutoComplete}
    //   fullWidth
    //   variant='outlined'
    //   alwaysOn
    // />,
    <SelectInput
      key="statusSearch"
      InputProps={{ placeholder: 'Status' }}
      label="Status"
      source="status"
      choices={[
        { name: 'Pending Purchase Order', value: 'PENDING_PURCHASE_ORDER' },
        { name: 'Pending Submission', value: 'PENDING_SUBMISSION' },
      ]}
      optionText="name"
      optionValue="value"
      className={classes.hideLabelAutoComplete}
      fullWidth
      variant="standard"
      alwaysOn
    />,
  ]

  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      //   filter={getFilter()}
      filter={{ type: 'PRIVATE' }}
      filters={offerFilters}
      // actions={null}
      empty={false}
      perPage={50}
      bulkActionButtons={false}
      pagination={<OfferPagination />}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" label="Offer ID" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ height: '50px' }}>
              <img
                style={{ maxHeight: '50px', maxWidth: '50px' }}
                src={record.thumbnail?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <TextField source="name" label="Product (SKU)" />
        <TextField source="orders.aggregate.count" label="# of Orders" />
        <FunctionField label="# of Sellers" render={() => 0} />
        {/* <FunctionField source='sellers' label='# of Sellers' /> */}
        <FunctionField
          label="% Purchased / Sold"
          render={(record) => {
            return record.purchased && record.quote?.quantity
              ? `${(
                  (record.purchased?.aggregate?.sum?.quantity / record.quote?.quantity) *
                  100
                ).toFixed(0)}%`
              : '0%'
          }}
        />
        {/* <TextField source='salesRatio' label='% Purchased / Sold' /> */}
        <FunctionField label="Returns" render={() => 0} />
        <TextField source="status" label="Status" />
        <FunctionField label="SCA" render={(record) => getDisplayName(record.sca)} />
        {/* <EditButton /> */}
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export default ListCom
