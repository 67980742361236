import React from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'
import useStyles from '../styles'
import SupplierDefaultImg from '../../../../../assets/supplier_default.png'
import Tag from '../../../../../components/Tag'

const Header = (props) => {
  const classes = useStyles()

  // console.log("HEADERPROPS", props)
  const getStatus = (value) => {
    switch (value) {
      case 'inactive':
        return <Tag style={{ cursor: 'default' }} title="Inactive" gray />
      case 'rejected':
        return <Tag style={{ cursor: 'default' }} title="Rejected" red />
      case 'active':
        return <Tag style={{ cursor: 'default' }} title="Active" green />
      case 'on_hold':
        return <Tag style={{ cursor: 'default' }} title="On hold" yellow />
      default:
        return <Tag style={{ cursor: 'default' }} title="On hold" yellow />
    }
  }

  return (
    <Box>
      <Box className={classes.headerBackground} display="flex">
        <Box flex={1}>
          <img
            className={classes.headerImage}
            src={props.data?.image?.file?.url || SupplierDefaultImg}
            alt={<img src={SupplierDefaultImg} alt="" />}
          />
        </Box>
        <Box flex={6} display="flex" flexDirection="column" padding="0 20px">
          <Box display="flex" alignItems="center">
            <Box flex={7}>
              <span className={classes.headerTitle}>{props.data?.name}</span>
            </Box>
            <Box flex={1}>{getStatus(props.data?.status)}</Box>
          </Box>
          <Box display="flex">
            <span className={classes.subHeaderLabel}>Registered:</span>
            <span className={classes.subHeaderValue}>
              {moment(props.data?.createdAt).format('YYYY/MM/DD')}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.subHeaderLabel}>ID:</span>
            <span className={classes.subHeaderValue}>{props.data?.id}</span>
          </Box>
          <Box display="flex">
            <span className={classes.subHeaderLabel}>SCA:</span>
            <span className={classes.subHeaderValue}>{props.data?.sca}</span>
          </Box>
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <Box display="flex" mb="40%">
            <span className={classes.subHeaderDate}>Date:</span>
            <span className={classes.subHeaderDateValue}> {moment().format('YYYY/MM/DD')}</span>
          </Box>
          <Box>{/* <span className={classes.subHeaderValue}>DOWNLOAD</span> */}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Header
