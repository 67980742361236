import React from 'react'
import {
  Filter,
  List,
  SearchInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  TopToolbar,
  // EditButton,
} from 'react-admin'
import { Box } from '@material-ui/core'
import OrganisationAdd from './OrganisationAdd'

const ListActions = (props) => {
  const { className, appUserId } = props
  return (
    <TopToolbar className={className}>
      <OrganisationAdd appUserId={appUserId} />
    </TopToolbar>
  )
}

export const OrganisationsFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Organisation Name"
      style={{ minWidth: '200px' }}
    />
  </Filter>
)

const OrganisationList = (props) => {
  const { appUserId, ...rest } = props

  return (
    <Box p="10px 60px">
      <div>Organisations List</div>
      <List
        {...rest}
        resource="organisation_members"
        filters={<OrganisationsFilter />}
        filter={{ appUserId }}
        perPage={25}
        actions={<ListActions appUserId={appUserId} />}
        empty={false}
        // bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid optimized path="clients">
          <TextField source="id" />
          <FunctionField label="Name" render={(record) => record.organisation.name} />
          <TextField source="role" label="Role" />
          <DateField source="created_at" label="Join date" type="date" />
        </Datagrid>
      </List>
    </Box>
  )
}

export default OrganisationList
