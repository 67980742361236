import React, { useCallback, useState, useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import {
  required,
  ReferenceInput,
  AutocompleteInput,
  // SelectArrayInput,
  // SimpleFormIterator,
  // ArrayInput,
  // FormDataConsumer,
  useNotify,
  useDataProvider,
  FormWithRedirect,
} from 'react-admin'
import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import { cloneDeep, uniqueId } from 'lodash'
import lodashGet from 'lodash/get'
import AddIcon from '@material-ui/icons/Add'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import IconButton from '@material-ui/core/IconButton'
import useStyles from '../styles'
import SelectInput from '../../../../../components/input/SelectInput'
import TextInput from '../../../../../components/OutlinedTextInput'
import { getDisplayAddress } from '../../../../../utils/utils'

const AddressesBox = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()
  const [newAddresses, setNewAddresses] = useState([])
  const [countries, setCountries] = React.useState([])
  const [provinces, setProvinces] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [editPrimary, setEditPrimary] = useState(false)
  const [editBilling, setEditBilling] = useState(false)
  const [editPickup, setEditPickup] = useState(false)
  const [editSample, setEditSample] = useState(false)

  useEffect(() => {
    const fetchDataProvider = async () => {
      const { data: countriesList } = await dataProvider.getList('country', {})
      const { data: countrySubdivision = [] } = await dataProvider.getList(
        'country_subdivision',
        {},
      )
      setCountries(countriesList)

      const citiesList = {}
      const statesList = {}
      const stateIds = {}
      countrySubdivision.forEach((item) => {
        if (item.level === 1) {
          stateIds[item.name] = item.id
          if (!statesList[item.country_code]) {
            statesList[item.country_code] = [item]
          } else {
            statesList[item.country_code].push(item)
          }
        } else if (item.level === 2) {
          if (!citiesList[item.parent_id]) {
            citiesList[item.parent_id] = [item]
          } else {
            citiesList[item.parent_id].push(item)
          }
        }
      })
      setProvinces(statesList)
      setCities(citiesList)
    }
    fetchDataProvider()
  }, [dataProvider])

  // console.log(cities)

  const addNewAddress = useCallback(() => {
    const list = cloneDeep(newAddresses)
    list.push({
      id: uniqueId(),
    })
    setNewAddresses(list)
  }, [newAddresses])

  const cancelNewAddress = (index) => {
    // console.log("CANCELLING", index)
    // console.log("NADDS", newAddresses)
    const addressList = newAddresses
    addressList.splice(index, 1)
    setNewAddresses(addressList)
    refresh()
    // console.log("AL", addressList)
  }

  const deleteAddress = async (params) => {
    // console.log("DEL", params)
    try {
      const { data } = await dataProvider.deleteSupplierAddress('supplier', { id: params })
      // console.log(data)
      if (data) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const createAddress = async (params, index) => {
    const submission = {
      ...params,
      supplier: props.supplierID,
    }
    try {
      const { data } = await dataProvider.createSupplierAddress('supplier', { ...submission })
      if (data.id !== undefined) {
        refresh()
      }

      const addressList = newAddresses
      addressList.splice(index, 1)
      setNewAddresses(addressList)
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const updateAddress = async (submission, id) => {
    const params = {
      ...submission,
      id,
    }
    try {
      const { data } = await dataProvider.updateSupplierAddress('supplier', { ...params })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const updateBankDetails = async (params) => {
    const submission = {
      ...params,
      id: props.bankDetail?.id,
    }

    // console.log(submission)
    try {
      const { data } = await dataProvider.updateSupplierBank('supplier', { ...submission })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  const updateSupplier = async (submission) => {
    const { supplierID } = props
    const params = {
      id: supplierID,
    }
    if (submission.primaryAddress) {
      params.primary_address_id = submission.primaryAddress
    }
    if (submission.billingAddress) {
      params.billing_address_id = submission.billingAddress
    }
    if (submission.pickupAddress) {
      params.pickup_address_id = submission.pickupAddress
    }
    if (submission.sampleAddress) {
      params.sample_address_id = submission.sampleAddress
    }

    // console.log(params)
    try {
      const { data } = await dataProvider.updateSupplier('supplier', { ...params })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  // console.log('ABPROPS', props)

  return (
    <Box p="20px 0">
      <span className={classes.sectionSubHeader}>Addresses</span>
      <Box display="flex">
        <Box flex={1}>
          {editPrimary ? (
            <FormWithRedirect
              key="updateSupplierPrimary"
              // {...props}
              initialValues={{ primaryAddress: props.primaryAddress?.id }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="primaryAddress"
                  label="Primary Address:"
                  addresses={props.addresses}
                  cancelAction={() => setEditPrimary(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Primary Address:</span>
              <span className={classes.fieldValueEditable}>
                {props.primaryAddress && props.primaryAddress?.type
                  ? `${props.primaryAddress?.type}: ${props.primaryAddress?.local_full_address}`
                  : props.primaryAddress?.local_full_address}
                <IconButton
                  onClick={() => {
                    setEditPrimary(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
        </Box>
        <Box flex={1}>
          {editBilling ? (
            <FormWithRedirect
              key="updateSupplierBilling"
              {...props}
              initialValues={{ billingAddress: props.billingAddress?.id }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="billingAddress"
                  label="Billing Address:"
                  addresses={props.addresses}
                  cancelAction={() => setEditBilling(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Billing Address:</span>
              <span className={classes.fieldValueEditable}>
                {props.billingAddress
                  ? `${props.billingAddress?.type}: ${props.billingAddress?.local_full_address}`
                  : props.billingAddress?.local_full_address}
                <IconButton
                  onClick={() => {
                    setEditBilling(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1}>
          {editPickup ? (
            <FormWithRedirect
              key="updateSupplierPickup"
              {...props}
              initialValues={{ pickupAddress: props.pickupAddress?.id }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="pickupAddress"
                  label="Pickup Address:"
                  addresses={props.addresses}
                  cancelAction={() => setEditPickup(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Pickup Address:</span>
              <span className={classes.fieldValueEditable}>
                {props.pickupAddress
                  ? `${props.pickupAddress?.type}: ${props.pickupAddress?.local_full_address}`
                  : props.pickupAddress?.local_full_address}
                <IconButton
                  onClick={() => {
                    setEditPickup(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
        </Box>
        <Box flex={1}>
          {editSample ? (
            <FormWithRedirect
              key="updateSupplierSample"
              {...props}
              initialValues={{ sampleAddress: props.sampleAddress?.id }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="sampleAddress"
                  label="Sample Delivery Address:"
                  addresses={props.addresses}
                  cancelAction={() => setEditSample(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Sample Delivery Address:</span>
              <span className={classes.fieldValueEditable}>
                {props.sampleAddress
                  ? `${props.sampleAddress?.type}: ${props.sampleAddress?.local_full_address}`
                  : props.sampleAddress?.local_full_address}
                <IconButton
                  onClick={() => {
                    setEditSample(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap">
        {props.addresses?.map((address, index) => (
          <FormWithRedirect
            key={`FOADD${address.id}`}
            {...props}
            initialValues={{
              addressLine1: address.address_line1,
              addressLine2: address.address_line2,
              city: address.city,
              province: address.province,
              country: address.country_code,
              localAddress: address.local_full_address,
              type: address.type,
              shippingPort: address.shipping_port?.id,
              postCode: address.post_code,
            }}
            render={() => (
              <AddressBox
                key={`OADD${address.id}`}
                data={address}
                countries={countries}
                provinces={provinces}
                cities={cities}
                index={index}
                deleteAddress={deleteAddress}
                updateAddress={updateAddress}
                count={index + 1}
              />
            )}
          />
        ))}
        {newAddresses?.map((address, index) => (
          <FormWithRedirect
            key={`FNADD${address.id}`}
            {...props}
            render={() => (
              <AddressBox
                key={`NADD${address.id}`}
                data={address}
                countries={countries}
                provinces={provinces}
                cities={cities}
                newAddress
                createAddress={createAddress}
                cancelNewAddress={cancelNewAddress}
                count={props.addresses.length + index + 1}
                index={index}
              />
            )}
          />
        ))}
        <Button
          onClick={() => {
            addNewAddress()
          }}
          fullWidth
          style={{
            backgroundColor: '#F8F8FA',
            color: 'black',
            height: '350px',
            width: '350px',
            margin: '5px',
          }}
        >
          <AddIcon />
        </Button>
      </Box>
      <Box className={classes.sectionSubHeader}>
        <span>Billing Details</span>
      </Box>
      <Box display="flex">
        <FormWithRedirect
          key={`FBENADD${props.bankDetail?.id}`}
          {...props}
          initialValues={{
            beneficiaryAddress: props.bankDetail?.beneficiary_address?.id,
          }}
          render={() => (
            <BankAddressBox
              key={`BENADD${props.bankDetail?.id}`}
              title="Beneficiary Address"
              countries={countries}
              provinces={provinces}
              cities={cities}
              data={props.bankDetail}
              addresses={props.addresses}
              updateBankDetails={updateBankDetails}
            />
          )}
        />
        <FormWithRedirect
          key={`FBADD${props.bankDetail?.id}`}
          {...props}
          initialValues={{
            accountNumber: props.bankDetail?.account_number,
            accountCurrency: props.bankDetail?.currency,
            beneficiaryName: props.bankDetail?.beneficiary_name,
            beneficiaryAddress: props.bankDetail?.beneficiary_address?.id,
            branchAddress: props.bankDetail?.branch_address,
            branchName: props.bankDetail?.branch_name,
            clearingCode: props.bankDetail?.clearing_code,
            swiftCode: props.bankDetail?.swift_code,
            bank: props.bankDetail?.bank?.id,
            taxNumber: props.bankDetail?.tax_number,
          }}
          render={() => (
            <BankDetailsBox
              key={`BADD${props.bankDetail?.id}`}
              title="Bank Details"
              data={props.bankDetail}
              updateBankDetails={updateBankDetails}
            />
          )}
        />
      </Box>
    </Box>
  )
}

const AddressBox = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  // console.log("AB", props)
  const [isEdit, setIsEdit] = useState(props.newAddress || false)
  const [types, setTypes] = React.useState()

  const { values } = useFormState()

  useEffect(() => {
    const fetchDataProvider = async () => {
      const { data: typesList } = await dataProvider.getList('addressType', {})
      setTypes(typesList)
    }
    if (isEdit) fetchDataProvider()
  }, [dataProvider, isEdit])

  // console.log("ADDBOXPROPS", props)

  // console.log("FV", values)

  // console.log(
  //   "LISTSCHECK",
  //   props.provinces.length === 0,
  //   props.cities.length === 0,
  //   props.countries.length > 0,
  //   !props.newAddress,
  // )
  // console.log("LISTS", props.provinces, props.cities, props.countries)
  const country =
    props.data?.country_code && props.countries.length > 0 && !props.newAddress
      ? props.countries.filter((item) => item.code === props.data?.country_code)[0]
      : null

  const city =
    props.data?.city && props.data?.province && props.cities?.length !== 0 && !props.newAddress
      ? props.cities[props.data?.province]?.filter(
          (item) => item.id === parseInt(props.data?.city),
        )[0]
      : null

  const province =
    props.data?.province && props.provinces.length !== 0 && !props.newAddress
      ? props.provinces[props.data?.country_code].filter(
          (item) => item.id === parseInt(props.data?.province),
        )[0]
      : null

  // console.log(country, city, province)

  return isEdit ? (
    <Box className={classes.addressBoxOuter}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <span>Address {props.count}</span>
          <SelectInput
            label="Type:"
            source="type"
            choices={types}
            optionText="name"
            optionValue="value"
            defaultValue=""
            fullWidth
            className={classes.areaTextSelect}
            validate={required()}
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="localAddress"
            label="Chinese Address:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="addressLine1"
            label="Address Line 1:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="addressLine2"
            label="Address Line 2:"
          />
          <SelectInput
            label="Country:"
            source="country"
            choices={props.countries}
            optionText="name"
            optionValue="code"
            defaultValue=""
            className={classes.areaTextSelect}
            validate={required()}
            fullWidth
          />
          <SelectInput
            label="Province:"
            source="province"
            choices={props.countries ? props.provinces[values.country] : []}
            optionText="name"
            optionValue="id"
            defaultValue=""
            className={classes.areaTextSelect}
            // validate={required()}
            fullWidth
          />
          <SelectInput
            label="City:"
            source="city"
            choices={props.countries && props.provinces ? props.cities[values.province] : []}
            optionText="name"
            optionValue="id"
            defaultValue=""
            className={classes.areaTextSelect}
            // validate={required()}
            fullWidth
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="postCode"
            label="Postal Code:"
          />
          <ReferenceInput
            source="shippingPort"
            reference="shippingPort"
            variant="outlined"
            fullWidth
            label="Shipping Port"
            className={classes.areaTextSelect}
            filter={{ q: '' }}
          >
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              // variant="outlined"
              // fullWidth
            />
            {/* <SelectInput optionText='name' /> */}
          </ReferenceInput>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              if (props.newAddress) {
                props.createAddress(values, props.index)
                setIsEdit(false)
              } else {
                props.updateAddress(values, props.data?.id)
                setIsEdit(false)
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsEdit(false)
              if (props.newAddress) {
                props.cancelNewAddress(props.index)
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              props.deleteAddress(props.data?.id)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <DeleteForeverOutlined fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.addressBoxOuter}>
      <Box display="flex" justifyContent="space-between">
        <span>Address {props.count}</span>
        <IconButton
          onClick={() => {
            setIsEdit(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Type:</span>
        <span style={{ textTransform: 'capitalize' }} className={classes.fieldValue}>
          {props.data?.type}
        </span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Chinese Address:</span>
        <span className={classes.fieldValue}>{props.data?.local_full_address}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Address Line1:</span>
        <span className={classes.fieldValue}>{props.data?.address_line1}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Address Line2:</span>
        <span className={classes.fieldValue}>{props.data?.address_line2}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>City:</span>
        <span className={classes.fieldValue}>{city ? city.name : null}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Province:</span>
        <span className={classes.fieldValue}>{province ? province.name : null}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Country:</span>
        <span className={classes.fieldValue}>{country ? country.name : null}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Postal Code:</span>
        <span className={classes.fieldValue}>{props.data?.post_code}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Shipping Port:</span>
        <span className={classes.fieldValue}>{props.data?.shipping_port?.name}</span>
      </Box>
    </Box>
  )
}

const BankDetailsBox = (props) => {
  const [isEdit, setIsEdit] = useState(props.newAddress || false)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const { values } = useFormState()
  const [banks, setBanks] = React.useState()

  // console.log("AB", props)

  useEffect(() => {
    const fetchDataProvider = async () => {
      const { data: banksList } = await dataProvider.getList('bank', {})
      setBanks(banksList)
    }
    if (isEdit) fetchDataProvider()
  }, [dataProvider, isEdit])

  return isEdit ? (
    <Box className={classes.addressBoxOuter}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <span>{props.title}</span>
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="beneficiaryName"
            label="Beneficiary Name:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="accountNumber"
            label="Account Number:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="clearingCode"
            label="Clearing Code:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="accountCurrency"
            label="Account Currency:"
          />
          <SelectInput
            label="Bank:"
            source="bank"
            choices={banks}
            optionText="name"
            optionValue="id"
            defaultValue=""
            fullWidth
            className={classes.areaTextSelect}
            validate={required()}
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="branchName"
            label="Branch Name:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="branchAddress"
            label="Branch Address:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="swiftCode"
            label="Swift Code:"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaText}
            InputProps={{
              maxLength: 300,
              style: {
                marginBottom: 15,
                alignItems: 'baseline',
              },
            }}
            source="taxNumber"
            label="Tax Number:"
          />
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              props.updateBankDetails(values)
              setIsEdit(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsEdit(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.addressBoxOuter}>
      <Box display="flex" justifyContent="space-between">
        <span>Banking Details</span>
        <IconButton
          onClick={() => {
            setIsEdit(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Beneficiary Name:</span>
        <span className={classes.fieldValue}>{props.data?.beneficiary_name}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Account Number:</span>
        <span className={classes.fieldValue}>{props.data?.account_number}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Clearing Code:</span>
        <span className={classes.fieldValue}>{props.data?.clearing_code}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Account Currency:</span>
        <span className={classes.fieldValue}>{props.data?.currency}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Bank Name:</span>
        <span className={classes.fieldValue}>{props.data?.bank?.name}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Branch Name:</span>
        <span className={classes.fieldValue}>{props.data?.branch_name}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Branch Address:</span>
        <span className={classes.fieldValue}>{props.data?.branch_address}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Swift Code:</span>
        <span className={classes.fieldValue}>{props.data?.swift_code}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Tax ID:</span>
        <span className={classes.fieldValue}>{props.data?.tax_number}</span>
      </Box>
    </Box>
  )
}

const BankAddressBox = (props) => {
  const [isEdit, setIsEdit] = useState(props.newAddress || false)
  const classes = useStyles()
  // const optionRenderer = (choice) =>
  //   `${choice.type} ${choice.address_line1} ${choice.address_line2}`

  const country =
    props.data?.beneficiary_address?.country_code && props.countries.length > 0 && !props.newAddress
      ? props.countries.filter(
          (item) => item.code === props.data?.beneficiary_address?.country_code,
        )[0]
      : null

  const city =
    props.data?.beneficiary_address?.city && props.cities?.length !== 0 && !props.newAddress
      ? props.cities[props.data?.beneficiary_address?.province]?.filter(
          (item) => item.id === parseInt(props.data?.beneficiary_address?.city),
        )[0]
      : null

  const province =
    props.data?.beneficiary_address?.province && props.provinces.length !== 0 && !props.newAddress
      ? props.provinces[props.data?.beneficiary_address?.country_code].filter(
          (item) => item.id === parseInt(props.data?.beneficiary_address?.province),
        )[0]
      : null

  const { values } = useFormState()

  // console.log("BANKPROPS", props)

  return isEdit ? (
    <Box className={classes.addressBoxOuter}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <span>Beneficiary Address</span>
          <SelectInput
            label="Address:"
            source="beneficiaryAddress"
            choices={props.addresses}
            // optionText={optionRenderer}
            optionText={(item) => getDisplayAddress(item)}
            optionValue="id"
            defaultValue=""
            fullWidth
            className={classes.areaTextSelect}
            validate={required()}
          />
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              props.updateBankDetails(values)
              setIsEdit(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsEdit(false)
              if (props.newAddress) {
                props.cancelNewAddress(props.index)
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.addressBoxOuter}>
      <Box display="flex" justifyContent="space-between">
        <span>Beneficiary Address</span>
        <IconButton
          onClick={() => {
            setIsEdit(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Type:</span>
        <span style={{ textTransform: 'capitalize' }} className={classes.fieldValue}>
          {props.data?.beneficiary_address?.type}
        </span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Chinese Address:</span>
        <span className={classes.fieldValue}>
          {props.data?.beneficiary_address?.local_full_address}
        </span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Address Line1:</span>
        <span className={classes.fieldValue}>{props.data?.beneficiary_address?.address_line1}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Address Line2:</span>
        <span className={classes.fieldValue}>{props.data?.beneficiary_address?.address_line2}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>City:</span>
        <span className={classes.fieldValue}>{city ? city.name : null}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Province:</span>
        <span className={classes.fieldValue}>{province ? province.name : null}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Country:</span>
        <span className={classes.fieldValue}>{country ? country.name : null}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Postal Code:</span>
        <span className={classes.fieldValue}>{props.data?.beneficiary_address?.post_code}</span>
      </Box>
      <Box display="flex">
        <span className={classes.fieldLabel}>Shipping Port:</span>
        <span className={classes.fieldValue}>
          {props.data?.beneficiary_address?.shipping_port?.name}
        </span>
      </Box>
    </Box>
  )
}

const EditFieldForm = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  // console.log(props.addresses)

  return (
    <Box display="flex">
      <SelectInput
        // optionText={optionRenderer}
        optionText={(item) => `${item.type}: ${item.local_full_address}`}
        optionValue="id"
        label={props.label}
        // className={`${classes.areaTextSelect} ${classes.hideLabel}`}
        className={classes.addressTextSelect}
        source={props.field}
        choices={props.addresses}
        validate={required()}
        disabled={props.readOnly}
        fullWidth
      />
      <IconButton
        onClick={() => {
          const formState = form.getState()

          if (formState.invalid) {
            form.submit()
          } else {
            props.updateSupplier(values)
            props.cancelAction()
          }
        }}
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CheckCircleOutlineOutlined fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          props.cancelAction()
        }}
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CancelOutlined fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default AddressesBox
