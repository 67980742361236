import React from 'react'
import { List, Datagrid, TextField, FunctionField, SearchInput } from 'react-admin'
import { Tooltip, withStyles, makeStyles } from '@material-ui/core'
import SupplierDefaultImg from '../../../assets/supplier_default.png'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const useStyles = makeStyles(() => ({
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  itemPhotosBox: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
  },
  itemPhoto: {
    position: 'relative',
    border: '2px solid #efefef',
    backgroundColor: '#fff',
    borderRadius: '50%',
    overflow: 'hidden',
    maxWidth: '30px',
    maxHeight: '30px',
    marginLeft: '-55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemPhotoLast: {
    position: 'relative',
    border: '2px solid #efefef',
    backgroundColor: '#fff',
    borderRadius: '50%',
    overflow: 'hidden',
    maxWidth: '30px',
    maxHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemImg: {
    width: '100%',
    display: 'block',
  },
}))

const BrandsList = (props) => {
  const classes = useStyles()
  // console.log(props)

  const brandFilters = [
    // <SearchInput key='searchQ' label="Search" source="q" alwaysOn />,
    <SearchInput
      key="search"
      InputProps={{ placeholder: 'Name' }}
      source="name"
      alwaysOn
      className={classes.searchBackground}
      variant="standard"
    />,
  ]

  return (
    <List
      {...props}
      actions={null}
      perPage={25}
      filters={brandFilters}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
      bulkActionButtons={false}
    >
      <Datagrid className={classes.listRowsCondense} rowClick="edit">
        <TextField source="name" />
        <FunctionField
          label="Top Banner"
          render={(record) => (
            <div
              style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ maxHeight: '50px', maxWidth: '50px' }}
                src={record.top_banner?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <FunctionField
          label="Hero Banner"
          render={(record) => (
            <div
              style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ maxHeight: '50px', maxWidth: '50px' }}
                src={record.hero_banner?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <FunctionField
          label="Featured Products"
          render={(record) => (
            <HtmlTooltip
              placement="bottom-start"
              title={
                <div style={{ backgroundColor: '#fff5f5', padding: '5px' }}>
                  <table>
                    <thead>
                      <tr style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Stock</th>
                        {/* <th>Retail</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {record.featured_products?.map((prd) => (
                        <tr
                          key={prd.id}
                          style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}
                        >
                          <td>
                            <img
                              src={
                                prd.saleable_product_variant?.image?.url ||
                                prd.saleable_product_variant?.saleable_product?.primary_photo?.url
                              }
                              alt={
                                prd.saleable_product_variant?.name ||
                                prd.saleable_product_variant?.saleable_product?.name
                              }
                              style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '5px' }}
                            />
                          </td>
                          <td style={{ padding: '3px' }}>
                            {prd.saleable_product_variant?.name ||
                              prd.saleable_product_variant?.saleable_product?.name}
                          </td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {prd.saleable_product_variant?.total_stock || '0'}
                          </td>
                          {/* <td style={{ padding: '3px', textAlign: 'center' }}>{`$${(
                            prd.offer_purchase?.amount / 100
                          ).toFixed(2)}`}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
            >
              <div className={classes.itemPhotosBox}>
                {record.featured_products?.map((el, index) => (
                  <div
                    key={el.id}
                    className={
                      index === record.featured_products?.length - 1
                        ? classes.itemPhotoLast
                        : classes.itemPhoto
                    }
                  >
                    <img
                      className={classes.itemImg}
                      src={
                        el.saleable_product_variant?.image?.url ||
                        el.saleable_product_variant?.saleable_product?.primary_photo?.url
                      }
                      alt={
                        el.saleable_product_variant?.name ||
                        el.saleable_product_variant?.saleable_product?.name
                      }
                    />
                  </div>
                ))}
              </div>
            </HtmlTooltip>
          )}
        />
        <FunctionField
          render={(record) => {
            return record.products_aggregate?.aggregate?.count || 0
          }}
          label="Base Products"
        />
        <FunctionField
          render={(record) => {
            return record.saleable_products_aggregate?.aggregate?.count || 0
          }}
          label="Saleable Products"
        />
        <TextField label="Owner Name" source="owner_name" />
      </Datagrid>
    </List>
  )
}

export default BrandsList
