import React, { useState } from 'react'
import { useDataProvider, useNotify, TextInput } from 'react-admin'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Typography,
  //   InputLabel,
  //   Select,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { uniqueId } from 'lodash'
// import SinglePhotoFieldInput from '../../components/fieldInputs/SinglePhotoFieldInput'
import { generateProductVariants } from '../../../utils/utils'

const useStyles = makeStyles(() => ({
  table: {
    overflowX: 'unset',
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiInput-underline::before': {
      borderBottom: 'none',
    },
  },
  optionTitle: {
    fontSize: '14px',
    marginTop: '12px',
  },
  optionTitleNoMargin: {
    fontSize: '14px',
  },
  optionValue: {
    fontSize: '10px',
  },
  optionsToggleCheckbox: {
    padding: '1px',
  },
  createButton: {
    color: 'black',
    border: 'solid 1px rgba(180, 125, 80, 0.5)',
    backgroundColor: 'rgba(255, 149, 102, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(255, 149, 102, 0.3)',
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const VariantsDetails = () => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const options = values.options

  // console.log(values)
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Typography>Select:</Typography>
        <Button
          disabled={!editing}
          onClick={() => {
            form.batch(() => {
              values.sortedVariants?.map((el, ind) =>
                form.change(`sortedVariants[${ind}].available`, true),
              )
            })
          }}
        >
          All
        </Button>
        <Button
          disabled={!editing}
          onClick={() => {
            form.batch(() => {
              values.sortedVariants?.map((el, ind) =>
                form.change(`sortedVariants[${ind}].available`, false),
              )
            })
          }}
        >
          None
        </Button>

        {options?.map((option, index) => {
          const [anchorEl, setAnchorEl] = React.useState(null)
          const isMenuOpen = Boolean(anchorEl)

          const handleMenuClick = (event) => {
            setAnchorEl(event.currentTarget)
          }

          const handleMenuClose = () => {
            setAnchorEl(null)
          }

          return (
            <div key={option.name}>
              <Button disabled={!editing} onClick={(event) => handleMenuClick(event)}>
                {option.name}
              </Button>
              <Menu
                id={`menu${option.name}`}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
              >
                {option.values.map((value) => (
                  <MenuItem key={value.value}>
                    {value.value}
                    <Checkbox
                      checked={
                        !values.sortedVariants
                          ?.filter((el) => el.variant.values[index].value === value.value)
                          ?.some((nwVariant) => !nwVariant.available)
                      }
                      className={classes.optionsToggleCheckbox}
                      onChange={() => {
                        const changes = []
                        values.sortedVariants?.map((el, ind) =>
                          el.variant.values[index].value === value.value ? changes.push(ind) : null,
                        )
                        changes.map((nvInd) => {
                          form.batch(() => {
                            form.change(
                              `sortedVariants[${nvInd}].available`,
                              values.sortedVariants
                                ?.filter((el) => el.variant.values[index].value === value.value)
                                ?.some((variant) => !variant.available),
                            )
                          })
                        })
                      }}
                      inputProps={{ 'aria-label': 'all option values available checkbox' }}
                    />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )
        })}
      </Box>

      <Box flex={6} position="relative">
        <TableContainer component={Box} className={classes.table}>
          <Table className={classes.table} aria-label="shipping table">
            <TableHead>
              <TableRow>
                <StyledTableCell width="5%">Active</StyledTableCell>
                {/* <StyledTableCell width="5%">Id</StyledTableCell> */}
                {/* <StyledTableCell width="10%">Image</StyledTableCell> */}
                {/* {values.sortedVariants[0].variant.values.map((opt) => ( */}
                {options.map((opt) => (
                  <StyledTableCell
                    key={uniqueId()}
                    width={`${75 / options.length}%`}
                    // width={`${70 / values.sortedVariants[0].variant.values.length}%`}
                  >
                    {opt.option || opt.name}
                  </StyledTableCell>
                ))}
                <StyledTableCell width="20%">SKU</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.sortedVariants.map((variant, index) => (
                <VariantRow
                  key={`variant${variant.id}`}
                  index={index}
                  variant={variant}
                  options={options}
                  disabled={!editing}
                  saleableProductId={values.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {editing ? (
          <Box display="flex">
            <IconButton
              onClick={async () => {
                setSaving(true)

                console.log('VSVs', values.sortedVariants)

                try {
                  const inactives = values.sortedVariants
                    .filter((el) => !el.available && typeof el.id === 'number')
                    .map((item) => item.id)

                  //   const inactivesResp =
                  inactives?.length > 0
                    ? await dataProvider.deleteMany('saleable_product_variants', {
                        ids: inactives,
                      })
                    : null

                  //   console.log(inactivesResp)

                  const params = {
                    objects: values.sortedVariants
                      .filter((el) => el.available)
                      .map((prodVariant) => ({
                        ...(typeof prodVariant?.id === 'number' && { id: prodVariant.id }),
                        saleable_product_id: values.id,
                        variant: {
                          data: {
                            ...(typeof prodVariant.variant?.id === 'number' && {
                              id: prodVariant.variant.id,
                            }),
                            product_id: values.base_product_id,
                            ...(prodVariant.variant?.sku && { sku: prodVariant.variant?.sku }),
                            ...(prodVariant.variant?.values?.length > 0 && {
                              values: {
                                data: prodVariant.variant?.values?.map((val) => ({
                                  product_id: values.base_product_id,
                                  option_name: val.option,
                                  option: {
                                    data: {
                                      name: val.option,
                                    },
                                    on_conflict: {
                                      constraint: '^option_name_key^',
                                      update_columns: ['^name^', '^description^'],
                                    },
                                  },
                                  value: val.value,
                                  option_value: {
                                    data: {
                                      value: val.value,
                                      option: {
                                        data: {
                                          name: val.option,
                                        },
                                        on_conflict: {
                                          constraint: '^option_name_key^',
                                          update_columns: ['^name^', '^description^'],
                                        },
                                      },
                                    },
                                    on_conflict: {
                                      constraint: '^option_value_option_id_value_key^',
                                      update_columns: ['^value^'],
                                    },
                                  },
                                })),
                                on_conflict: {
                                  constraint:
                                    '^variant_values_product_id_variant_id_option_id_value_id_key^',
                                  update_columns: [
                                    '^value^',
                                    '^value_id^',
                                    '^option_id^',
                                    '^option_name^',
                                  ],
                                },
                              },
                            }),
                          },
                          on_conflict: {
                            constraint: '^variant_pkey^',
                            update_columns: ['^name^', '^sku^'],
                          },
                        },
                      })),
                    on_conflict: {
                      constraint: '^saleable_product_variants_saleable_product_id_variant_id_key^',
                      update_columns: ['^saleable_product_id^', '^variant_id^'],
                    },
                  }

                  console.log('PARAMS', params)

                  const resp = await dataProvider.createMany('saleable_product_variants', {
                    ...params,
                  })

                  console.log('RESP', resp)

                  const newSortedVariants =
                    options?.length > 0
                      ? generateProductVariants({
                          options,
                          existingVariants: [
                            ...(resp.data || []),
                            ...values.sortedVariants.filter((el) => typeof el.id === 'number'),
                          ],
                        })
                      : resp.data?.length > 0
                      ? [{ ...resp.data[0], available: true }]
                      : [{ id: `NV${uniqueId()}`, available: false, values: [] }]

                  console.log('NSVs', newSortedVariants)
                  console.log('INACTIVES', inactives)
                  const newVals = newSortedVariants.map((el) => ({
                    ...el,
                    available:
                      typeof el.id === 'string' || inactives?.some((obj) => obj === el.id)
                        ? false
                        : true,
                  }))

                  form.change('sortedVariants', newVals)
                  console.log('NVs', newVals)

                  // const activeVariants = newVals.filter(
                  //   (el) => el.available && typeof el.id === 'number',
                  // )

                  // form.change('activeVariants', activeVariants || [])

                  // form change ids on resp

                  notify('Save Complete', { type: 'info' })
                  setSaving(false)
                  setEditing(false)
                } catch (error) {
                  console.log(error)
                  setSaving(false)
                  setEditing(false)
                  notify(error.message, { type: 'warning' })
                }
                setSaving(false)
                setEditing(false)
              }}
              style={{
                maxWidth: '30px',
                maxHeight: '30px',
                position: 'absolute',
                top: '-15px',
                right: '20px',
                backgroundColor: '#FFF',
                border: 'solid 1px lightgray',
              }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{
                maxWidth: '30px',
                maxHeight: '30px',
                position: 'absolute',
                top: '-15px',
                right: '-15px',
                backgroundColor: '#FFF',
                border: 'solid 1px lightgray',
              }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {saving && (
              <CircularProgress
                style={{
                  width: '20px',
                  height: '20px',
                  position: 'absolute',
                  top: '-15px',
                  left: '5px',
                }}
              />
            )}
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              position: 'absolute',
              top: '-15px',
              right: '-15px',
              backgroundColor: '#FFF',
              border: 'solid 1px lightgray',
            }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

const VariantRow = (props) => {
  const classes = useStyles()
  const form = useForm()

  const { variant, index, disabled } = props

  return (
    <TableRow style={index % 2 == 0 ? { backgroundColor: '#f6f6f6' } : { backgroundColor: '#fff' }}>
      <StyledTableCell align="center">
        <Checkbox
          disabled={disabled}
          checked={variant.available || false}
          className={classes.optionsToggleCheckbox}
          onChange={() => {
            form.change(`sortedVariants[${index}].available`, !variant.available)
          }}
          inputProps={{ 'aria-label': 'variant available checkbox' }}
        />
      </StyledTableCell>
      {/* <StyledTableCell align="right" style={{ padding: '10px' }}>
        {variant.id}
      </StyledTableCell> */}
      {/* <StyledTableCell align="right" style={{ padding: '10px' }}>
        <SinglePhotoFieldInput
          referenceField="id"
          referenceValue={variant.id}
          parentField="saleable_product_id"
          parentId={saleableProductId}
          resource="saleable_product_variants"
          source={`sortedVariants[${index}].variant.image_file_id`}
          fileReference="image"
          field={`sortedVariants[${index}].variant.image.url`}
          disabled={disabled}
          onConflict={{
            constraint: 'saleable_product_variants_pkey',
            update_columns: 'image_file_id',
          }}
        />
      </StyledTableCell> */}

      {variant.variant?.values?.map((opt, index) => (
        <StyledTableCell
          key={uniqueId()}
          align="right"
          style={{
            padding: '0px 10px',
          }}
        >
          <Box display="flex" alignItems="center">
            {opt.option === 'Color' ? (
              <div
                style={{
                  margin: '0px 10px',
                  width: '16px',
                  height: '16px',
                  borderRadius: '3px',
                  backgroundColor: variant.variant?.values[index].value,
                  border:
                    parseInt(variant.variant?.values[index].value.slice(1, 3), 16) +
                      parseInt(variant.variant?.values[index].value.slice(3, 5), 16) +
                      parseInt(variant.variant?.values[index].value.slice(5, 7), 16) <
                    600
                      ? `1px solid ${variant.variant?.values[index].value}`
                      : '1px solid #ABABAB',
                }}
              />
            ) : null}

            {variant.variant?.values[index].value}
          </Box>
        </StyledTableCell>
      ))}
      <StyledTableCell
        align="right"
        style={{
          padding: '0px 10px',
        }}
      >
        <TextInput
          variant="standard"
          className={classes.numberTableInput}
          label={false}
          fullWidth
          disabled={disabled}
          source={`sortedVariants[${index}].variant.sku`}
        />
      </StyledTableCell>
    </TableRow>
  )
}

export default VariantsDetails
