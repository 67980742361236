import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  header: {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '20px',
  },
  productPhoto: {
    width: '100%',
  },
  nameText: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '42px',
    margin: '10px 0px',
  },
  priceText: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '42px',
    lineHeight: '42px',
    margin: '10px 0px',
  },
  descText: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    margin: '10px 0px',
  },
  subtitleText: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '18px',
    margin: '10px 0px',
  },
  button: {
    border: '0px solid',
    borderRadius: '65px',
    background: 'linear-gradient(to right, #DB224E, #20A8BB)',
    color: 'white',
    fontSize: '20px',
    fontWeight: '500',
    height: '55px',
    minWidth: '250px',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  },
  dropZoneMain: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    padding: '5px',
  },
  dropZoneButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // border: 'lightgrey 2px solid',
    borderRadius: '4px',
    margin: '5px',
    padding: '0 20px',
    cursor: 'pointer',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: '500',
    height: '30px',
  },
  fileDropZone: {
    // border: 'red 1px solid',
    width: '60%',
    marginLeft: '10%',
    backgroundColor: 'white',
    border: '1px solid #DEDEDE',
    borderRadius: '4px',
    height: '30px',
  },
  fileRemove: {
    // border: 'blue 1px solid',
    display: 'flex',
    flexFlow: 'row-reverse',
    '& .MuiButtonBase-root': {
      transform: 'scale(0.6)',
      position: 'absolute',
      right: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  filePreview: {
    // border: 'green 1px solid',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#10B1C1',
    width: '100%',
  },
  fileRoot: {
    margin: '0px',
    padding: '0px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  uploadPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    fontSize: '13px',
    fontWeight: '500',
    marginTop: '-18px',
  },
  fileLink: {
    fontSize: '12px',
    lineHeight: '12px',
  },
  deleteBtn: {
    padding: '0px',
    // position: 'absolute',
    // top: '0',
    // right: '0',
  },
}))
