import React, { useEffect } from 'react'
import {
  Box,
  Button,
  //   Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  // Chip,
  //   Drawer,
} from '@material-ui/core'
import { useFormState, useForm } from 'react-final-form'
// import AddIcon from '@material-ui/icons/Add'
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  required,
  SelectInput,
  // useDataProvider,
  TextInput,
  // NullableBooleanInput,
  //   ReferenceInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
// import NumberText from 'react-number-format'
// import { roundTo } from '../../../utils/utils'
// import WarningIcon from '@material-ui/icons/Warning'
import { getDisplayAddress } from '../../../utils/utils'
import Confirm from '../../components/Confirm'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
// import CancelIcon from '@material-ui/icons/Cancel'
// import RemoveCircle from '@material-ui/icons/Delete'
// import TextInput from '../../../components/OutlinedTextInput'
import NumberFormat from '../../components/CustomNumberFormat'
// import Customiser from './Customiser'
import { makeStyles } from '@material-ui/core/styles'
import CostsAndPricing from '../../offers/offer/components/CostsAndPricing'

const StyledTableCell = withStyles(() => ({
  head: {
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    padding: '2px',
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '20px',
    marginTop: '60px',
  },
  title: {
    fontSize: '16px',
  },
  sectionTitle: {
    fontSize: '12px',
    marginTop: '5px',
  },
  bodyText: {
    fontSize: '10px',
    marginTop: '5px',
  },
  bodyTextHighlighted: {
    fontSize: '12px',
    marginTop: '5px',
    fontWeight: '600',
  },
  freightInnerBoxSea: {
    marginRight: '10px',
    borderRadius: '4px',
    backgroundColor: '#BBDEDF',
    padding: '10px 10px',
    border: '1px solid #00454E',
  },
  freightInnerBoxAir: {
    marginRight: '10px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    border: '1px solid #772349',
  },
  freightIconBoxSea: {
    height: '40px',
    width: '40px',
    borderRadius: '10px',
    backgroundColor: '#00454E',
  },
  freightIconBoxAir: {
    height: '40px',
    width: '40px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
  },
  freightTimeDesc: {
    padding: '5px 5px',
    fontSize: '10px',
  },
  freightTimeValue: {
    padding: '0px 5px',
    fontSize: '12px',
    fontWeight: '500',
  },
  numberInput: {
    width: '100%',
    height: '100%',
    // padding: '0px',
    '& .MuiFilledInput-underline::before': {
      // borderBottom: 'none',
    },
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      minHeight: '36px',
      '& .input': {
        width: '50%',
      },
    },
  },
  itemBoxDropZone: {
    width: '20%',
    minWidth: '120px',
    height: '30px',
    border: '#E0E0E0 solid 1px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '4px 5px',
    fontSize: '0.8125rem',
    fontWeight: '500',
  },
  itemBoxDZRemove: {
    // border: 'blue 1px solid',
    display: 'flex',
    flexFlow: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      transform: 'scale(0.6)',
      // position: 'absolute',
      // right: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  itemBoxDZPreview: {
    itemBoxDZPreview: {
      // border: 'green 1px solid',
      fontSize: '10px',
      lineHeight: '10px',
      color: '#10B1C1',
    },
  },
  itemBoxDZRoot: {
    margin: '0px',
    padding: '0px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  areaTextSelect: { padding: 0 },
  numberLeadTimeInput: {
    padding: '0px',
    width: '120px',
    margin: '20px 0px 0px',
  },
  numberInputOrder: {
    padding: '0px',
    width: '120px',
    margin: '0px 20px',
  },
  numberInputOrderNoMargin: {
    padding: '0px',
    width: '120px',
    margin: '0px',
  },
}))

const CreateOfferForm = ({ formProps, handleCreateOffer, saleableProductData }) => {
  const { values, errors } = useFormState()
  const form = useForm()
  // const dataProvider = useDataProvider()
  //   const [open, setOpen] = useState(false)

  Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)
  const classes = useStyles()

  console.log(saleableProductData)
  // console.log(values)

  //   const dutiesMultiplier = values.dutyRate / 100 + 1

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      const totalQuantity = values.orderVariants.reduce((previousValue, currentValue) => {
        return previousValue + currentValue?.quantity || 0
      }, 0)
      const matChanges = []
      values.materials.map((el, ind) => {
        matChanges.push(
          {
            field: `materials[${ind}].orderQuantityRequired`,
            value: totalQuantity * el.quantity_per_piece || 0,
          },
          {
            field: `materials[${ind}].estimatedOrderCost`,
            value: totalQuantity * el.estimatedCost || 0,
          },
        )
      })
      // console.log(totalQuantity)
      // console.log(values.materials)
      !abortController.signal.aborted &&
        form.batch(() => {
          form.change('quantity', totalQuantity)
          matChanges.map((el) => form.change(el.field, el.value))
        })
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [values.orderVariants])

  /*
    Photo
    Short Desc
    Long Desc
    Product_quote_id
    Marketing Agency : 1
    SCA_ID
    Status (Pending Purchase Order)
    Quantity (moq) / Investor Price Per Piece / Logistics Lead Time
    Retail Price
    Incoterms
    Freight Method
    Type
    Client Quote ID
    HTS Code
  */

  return (
    <Box display="flex" flexDirection="column" p="20px">
      <Box mt="10px">
        <span className={classes.tableTitle}>Set Offer Variants</span>
      </Box>
      <Box display="flex" mt="10px">
        <Box display="flex" flex={1} flexDirection="column">
          <ArrayInput source="orderVariants" label={false}>
            <SimpleFormIterator
              TransitionProps={{ enter: false, exit: false }}
              addButton={
                <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                  <AddIcon />
                </Button>
              }
              //   disableAdd={!editing}
              removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
              //   disableRemove={!editing}
            >
              <FormDataConsumer>
                {({ getSource, scopedFormData, rest }) => (
                  <Box display="flex" alignItems="flex-end">
                    <SelectInput
                      label="Variant:"
                      source={getSource('saleable_product_variant_id')}
                      record={scopedFormData}
                      {...rest}
                      choices={saleableProductData.variants}
                      optionText={(el) => {
                        const colourValue = el.variant.values.find((obj) => obj.option === 'Color')
                        return (
                          <Box display="flex" alignItems="center">
                            <span>SKU:{el.variant.sku}</span>
                            {colourValue && (
                              <div
                                style={{
                                  marginLeft: '3px',
                                  width: '15px',
                                  height: '15px',
                                  backgroundColor: colourValue.value,
                                  borderRadius: '4px',
                                }}
                              />
                            )}
                            <span style={{ marginLeft: '3px' }}>
                              {`${el.variant.values?.map((value) => value.value).join(', ')}`}
                            </span>
                          </Box>
                        )
                      }}
                      optionValue="id"
                      defaultValue=""
                      fullWidth
                      variant="standard"
                      className={classes.areaTextSelect}
                    />
                    <NumberFormat
                      label="Quantity"
                      source={getSource('quantity')}
                      {...rest}
                      className={classes.numberInputOrder}
                      record={scopedFormData}
                      validate={required()}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      defaultValue={0}
                      // style={{ margin: '0px 10px' }}
                    />
                  </Box>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <NumberFormat
              label="Retail Price:"
              source="retailPrice"
              className={classes.numberInputOrderNoMargin}
              validate={required()}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              prefix="$ "
            />
            <NumberFormat
              label="Min Purchase Qty:"
              source="minimum_purchase_quantity"
              className={classes.numberInputOrder}
              validate={required()}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
            />
            <NumberFormat
              label="Total Quantity:"
              source="quantity"
              className={classes.numberInputOrderNoMargin}
              validate={required()}
              displayType="text"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
            />
          </Box>
          <TextInput
            variant="standard"
            fullWidth
            className={classes.areaTextSelect}
            InputProps={{
              maxLength: 800,
              style: {
                paddingBottom: 0,
                alignItems: 'baseline',
              },
            }}
            minRows={3}
            multiline
            // validate={required()}
            source="warranty"
            label="Warranty:"
          />
        </Box>
        <Box ml="1rem" flex={1} display="flex" flexDirection="column">
          <AutocompleteArrayInput
            source="platforms"
            // fullWidth
            choices={[{ name: 'MyFundraiseGuy' }, { name: 'Cliquestock' }]}
            label="Platforms"
            optionText="name"
            optionValue="name"
            variant="standard"
          />
          <NumberFormat
            label="Manufacturing Lead Time:"
            source="manufacturing_lead_time"
            className={classes.numberLeadTimeInput}
            // validate={required()}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
          />
          <SelectInput
            variant="standard"
            fullWidth
            style={{ padding: '0px', margin: '20px 0px 0px', minWidth: '100px', maxWidth: '80%' }}
            label="Delivery Address"
            source="delivery_address_id"
            choices={[
              {
                id: 124,
                address_line1: 'Lei Lei Trading Co.',
                address_line2: 'Dayawan',
                city: 'Huizhou',
                province: 'Guangdong',
                country_code: 'CN',
                post_code: '516200',
              },
            ]}
            optionValue="id"
            optionText={(val) => getDisplayAddress(val)}
            // className={classes.areaText}
          />
        </Box>
        <Box ml="1rem" flex={3}>
          <span style={{ fontSize: '12px' }}>Materials Breakdown</span>
          <TableContainer component="div">
            <Table aria-label="materialsTable" style={{ marginTop: '20px', overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                    Material
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                    Qty per Piece
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                    Estimated Cost
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                    Vendor
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                    Vendor Code
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '20%', fontSize: '10px' }} align="left">
                    Order Qty Required
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '20%', fontSize: '10px' }} align="left">
                    Est. Cost
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.materials?.map((item, index) => (
                  <MaterialsRow key={`material${item.id}`} index={index} id={item.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box mt="10px">
        <CostsAndPricing showDetails />
      </Box>

      <Confirm
        redirect={`/cliquestock/${saleableProductData.id}?tab=offers`}
        // redirect={null}
        record={formProps.record}
        basePath={formProps.basePath}
        label="SUBMIT"
        undoable
        invalid={formProps.invalid}
        handleSubmit={async (newRecord) => {
          await handleCreateOffer({
            submission: newRecord,
          })
        }}
        saving={formProps.saving}
        disabled={formProps.saving || formProps.invalid}
        resource="offer"
      />
    </Box>
  )
}

const MaterialsRow = ({ index, disabled = true }) => {
  const classes = useStyles()

  return (
    <TableRow key={`matRow${index}`}>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].name`}
          label={false}
          disabled
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].perPiece`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].estCost`}
          label={false}
          disabled
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].vendor`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].vendor_code`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`materials[${index}].orderQuantityRequired`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`materials[${index}].estimatedOrderCost`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default CreateOfferForm
