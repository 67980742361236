import React, { useEffect } from 'react'
import {
  Box,
  Tooltip,
  //  Button, TextField
} from '@material-ui/core'
import { uniqueId } from 'lodash'
import { useForm, useFormState } from 'react-final-form'
// import AddIcon from '@material-ui/icons/Add'
// import { withStyles } from '@material-ui/core/styles'
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  AutocompleteArrayInput,
  required,
  // DateInput,
  // BooleanInput,
  // SimpleFormIterator,
  // ArrayInput,
  // FormDataConsumer,
} from 'react-admin'
import { useDropzone } from 'react-dropzone'
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import TextInput from '../../../components/OutlinedTextInput'
// import SelectInput from '../../../components/input/SelectInput'
// import ChipInput from '../../../components/input/ChipInput'
// import { ColorInput } from '../../components/ColourPicker'
// import NumberFormat from '../../components/CustomNumberFormat'
import SupplierSelectWithCreate from '../../components/SupplierSelectWithCreate'
import TagSelectWithCreate from '../../components/TagSelectWithCreate'
// import DimensionsTable from '../../products/components/DimensionsTable'
// import FactoryQuoteCalculator from './FactoryQuoteCalculator'
import useStyles from '../styles'

// const StyledSimpleFormIterator = withStyles(() => ({
//   root: {
//     display: 'inline-flex',
//   },
// }))(SimpleFormIterator)

const FormData = (props) => {
  // console.log('FDPROPS', props)
  const {
    // materials = [],
    categories = [],
    // imprints = [],
    // packagingTypes = [],
    title,
    // setClientQuoteProductData,
  } = props
  const classes = useStyles()
  const form = useForm()

  useEffect(() => {
    return () => {}
  }, [])

  const { values, errors } = useFormState()

  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)

  // console.log('CPDFORMVALS:', values)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        form.change('images', [
          ...(values.images || []),
          ...acceptedFiles.map((file) => {
            return {
              id: uniqueId(),
              type: 'ADDITIONAL_PHOTO',
              file: Object.assign(file, {
                url: URL.createObjectURL(file),
              }),
            }
          }),
        ])
        if (!values.primary_photo) {
          form.change('primary_photo', acceptedFiles[0])
          form.change('thumbnail_photo', acceptedFiles[0])
        }
      }
    },
  })

  // const { getRootProps: getAdditionalRootProps, getInputProps: getAdditionalInputProps } =
  //   useDropzone({
  //     onDrop: (acceptedFiles) => {
  //       // console.log(acceptedFiles)
  //       form.change('additional_files', [
  //         ...(values.additional_files || []),
  //         ...acceptedFiles.map((file) => {
  //           return { file: file, id: uniqueId() }
  //           //   Object.assign(file, {
  //           //     // preview: URL.createObjectURL(file),
  //           //     // file_type: 'ADDITIONAL_PHOTO',
  //         }),
  //       ])
  //     },
  //   })

  // useEffect(() => {
  //   // console.log('changing')
  //   setClientQuoteProductData({
  //     pieces_per_carton:
  //       values?.product_quotes?.length > 0 ? values.product_quotes[0].pieces_per_carton : 0,
  //     dutyRate: values?.duty_rate / 100 || 0,
  //     chargeable_weight_air: values?.chargeable_weight_air / 100 || 0,
  //     chargeable_weight_sea: values?.chargeable_weight_sea / 100 || 0,
  //     prices: values?.factoryPrices,
  //   })
  // }, [values])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.images?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.additional_files?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  const previews = values.images
    ?.filter((item) => !!item.deleted_at === false)
    .map((file) => {
      const currentTime = moment().toISOString()

      return (
        <Box
          key={`preview${file.file?.name || file.id}${uniqueId()}`}
          className={classes.previewImageBox}
        >
          <img
            src={file.file?.url}
            className={classes.previewImage}
            onClick={() => {
              form.change('primary_photo', file.file)
              form.change('thumbnail_photo', file.file)
              // form.change('primary_photo_number', index)
              // setSelectedPhoto(index)
            }}
          />
          <IconButton
            aria-label="delete"
            className={classes.deleteBtn}
            onClick={() => {
              const image = values.images.findIndex((item) => item.id === file.id)
              form.change(`images[${image}].deleted_at`, currentTime)
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  // const additionalPreviews = values.additional_files
  //   ?.filter((item) => !!item.deleted_at === false)
  //   .map((file, index) => {
  //     // console.log(file, index)
  //     const currentTime = moment().toISOString()
  //     const ind = values.additional_files.findIndex((item) => item.id === file.id)

  //     return (
  //       <Box
  //         key={`addpreview${file.file?.path || file.file?.name || file.id}`}
  //         className={classes.previewFileBox}
  //       >
  //         <SelectInput
  //           label="Additional file type"
  //           source={`additional_files[${ind}].type`}
  //           choices={[
  //             { id: 0, name: 'Design Artwork', value: 'DESIGN_ARTWORK' },
  //             { id: 1, name: 'Design Dieline', value: 'DESIGN_DIELINE' },
  //             { id: 2, name: 'Design Logo', value: 'DESIGN_LOGO' },
  //             { id: 3, name: 'Factory Quote', value: 'FACTORY_QUOTE' },
  //             { id: 4, name: 'Other', value: 'OTHER' },
  //           ]}
  //           className={classes.fieldInputNoMP}
  //           optionText="name"
  //           optionValue="value"
  //           defaultValue=""
  //           variant="standard"
  //           validate={required()}
  //           onChange={(e) => {
  //             // console.log(e.target.value)
  //             form.change(`additional_files[${ind}].type`, e.target.value)
  //           }}
  //         />

  //         <a href={file.file?.url || file.file?.path}>
  //           {`${index}. ${file.file?.name || file.file?.filename}`}
  //         </a>
  //         <IconButton
  //           aria-label="delete"
  //           className={classes.deleteAddBtn}
  //           onClick={() => {
  //             // const ind = values.additional_files.findIndex((item) => item.id === file.id)
  //             // console.log(`additional_files[${ind}].deleted_at`, ind, currentTime)
  //             form.change(`additional_files[${ind}].deleted_at`, currentTime)
  //           }}
  //         >
  //           <CancelIcon />
  //         </IconButton>
  //       </Box>
  //     )
  //   })

  return (
    <Box className={classes.outerBox}>
      {title && (
        <Box display="flex" justifyContent="space-between">
          <span className={classes.title}>{title}</span>
        </Box>
      )}

      <Box display="flex">
        <Box flex={1}>
          <div {...getRootProps({ className: classes.imageDropZone })}>
            <input {...getInputProps()} />
            {values.primary_photo ? (
              <img src={values.primary_photo.url} alt="alt" className={classes.primaryPhoto} />
            ) : null}

            {!values.primary_photo ? (
              <span>{`Drag & drop some files here, or click to select files`}</span>
            ) : null}
          </div>
          <Box className={classes.previewsContainer}>{previews}</Box>
        </Box>
        <Box flex={2} ml="50px">
          <TextInput
            variant="standard"
            fullWidth
            source="name"
            label="Product name"
            validate={required()}
          />
          {/* <span className={classes.productSKU}>124-373</span> */}
          {categories.length > 0 ? (
            <AutocompleteArrayInput
              source="categories"
              choices={categories || []}
              variant="standard"
            />
          ) : (
            <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
          )}
          {/* {tags.length > 0 ? (
            <AutocompleteArrayInput
              // optionValue={(record) => record.id}
              // optionText={(record) => `${record.id}`}
              source="tags"
              choices={tags || []}
              variant="standard"
            />
          ) : (
            <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
          )} */}
          <TagSelectWithCreate
            // validate={required()}
            label="Tags"
            // optionText={(value) => {
            //   return value?.official_verification_link ? value?.name : `${value?.name} (Unverified)`
            // }}
            source={'tags'}
          />
          <TextInput variant="standard" fullWidth source="brand" label="Brand" />
          <TextInput
            variant="standard"
            fullWidth
            source="short_description"
            label="Short description"
          />
          <SupplierSelectWithCreate
            validate={required()}
            label="Supplier"
            optionText={(value) => {
              return value?.official_verification_link ? value?.name : `${value?.name} (Unverified)`
            }}
            source={'product_quotes[0].supplier_id'}
          />
          <TextInput variant="standard" fullWidth source="catalogue_sku" label="Product SKU" />
        </Box>
      </Box>
      {/* <FactoryQuoteCalculator />
      <Box display="flex" mt="30px">
        <DateInput
          source="product_quotes[0].valid_date"
          label="Quote valid until"
          fullWidth
          variant="standard"
          margin="none"
          className={classes.fieldInputLeft}
        />
        <TextInput
          variant="standard"
          // fullWidth
          margin="none"
          source="hts_code"
          label="HTS code"
          className={classes.numberInput}
        />

        <div className={classes.numberInput} />
        <div className={classes.numberInput} />
        <div className={classes.numberInput} />
      </Box>
      <Box display="flex" mt="30px">
        <NumberFormat
          className={classes.fieldInputLeft}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          source="toolingCost"
          label="Tooling Cost"
          prefix="$ "
        />
        <NumberFormat
          className={classes.numberInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source="product_quotes[0].tooling_lead_time"
          label="Tooling Lead Time"
          // suffix={' days'}
        />
        <NumberFormat
          className={classes.numberInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          source="sampleCost"
          label="Sample Cost"
          prefix="$ "
        />
        <NumberFormat
          className={classes.numberInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          source="customSampleCost"
          label="Custom Sample Cost"
          prefix="$ "
        />
        <NumberFormat
          className={classes.numberInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source="product_quotes[0].sample_lead_time"
          label="Sample Lead Time"
          // suffix={' days'}
        />
      </Box>
      <Box mt="30px">
        <span className={classes.sectionHeader}>Color</span>
        <ArrayInput source="productColours" label="">
          <StyledSimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button
                style={{
                  width: '100px',
                  backgroundColor: '#F8F8FA',
                  color: 'black',
                  marginTop: '-10px',
                }}
              >
                <AddIcon />
                ADD COLOR
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box flex={1}>
                  <ColorInput
                    source={getSource('option_value.value')}
                    {...rest}
                    record={scopedFormData}
                    className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                    picker="Sketch"
                    label="Color"
                    // validate={required()}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </StyledSimpleFormIterator>
        </ArrayInput>
      </Box>
      <Box mt="30px">
        <span className={classes.sectionHeader}>Color options</span>
        <ArrayInput source="colourOptions" label="">
          <StyledSimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button
                style={{
                  width: '100px',
                  backgroundColor: '#F8F8FA',
                  color: 'black',
                  marginTop: '-10px',
                }}
              >
                <AddIcon />
                ADD COLOR
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box flex={1}>
                  <ColorInput
                    source={getSource('option_value.value')}
                    {...rest}
                    record={scopedFormData}
                    className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                    picker="Sketch"
                    label="Color"
                    // validate={required()}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </StyledSimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="allow_colour_selection" label="Allow custom colours?" />
      </Box>
      <Box mt="30px">
        <span className={classes.labelText}>Material(s)</span>
        <Box display="flex" position="relative">
          <Box flex={1} display="contents">
            {materials.length > 0 ? (
              <Autocomplete
                multiple
                id="tags-outlined"
                options={materials}
                limitTags={6}
                disabled={materials.length < 1}
                value={values.productMaterials}
                style={{ minWidth: '100%' }}
                getOptionSelected={(option, value) => {
                  // console.log('OPTION', option)
                  // console.log('VALUE', value)
                  return (
                    option.name === value.option_value?.value ||
                    option.value === value.option_value?.value ||
                    option.name === value.name ||
                    option.value === value.name ||
                    option.value === value ||
                    option.name === value
                  )
                }}
                getOptionLabel={(option) => {
                  // console.log('OPTLABEL', option)
                  return option.option_value?.value || option.name
                }}
                onChange={(e, v) => {
                  form.change('productMaterials', v)
                }}
                filterSelectedOptions
                className={classes.autocompleteSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Materials"
                    placeholder=""
                    variant="standard"
                    fullWidth
                    className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
                  />
                )}
              />
            ) : (
              <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
            )}
          </Box>
          <Box flex={1} />
        </Box>
        <Box mt="30px">
          <span className={classes.labelText}>Material options</span>
          <Box display="flex" position="relative">
            <Box flex={1} display="contents">
              {materials.length > 0 ? (
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={materials}
                  limitTags={6}
                  disabled={materials.length < 1}
                  style={{ minWidth: '100%' }}
                  value={values.materialOptions}
                  getOptionSelected={(option, value) => {
                    // console.log('OPTION', option)
                    // console.log('VALUE', value)
                    return (
                      option.name === value.option_value?.value ||
                      option.value === value.option_value?.value ||
                      option.name === value.name ||
                      option.value === value.name ||
                      option.value === value ||
                      option.name === value
                    )
                  }}
                  getOptionLabel={(option) => {
                    // console.log('OPTLABEL', option)
                    return option.option_value?.value || option.name
                  }}
                  onChange={(e, v) => {
                    form.change('materialOptions', v)
                  }}
                  filterSelectedOptions
                  className={classes.autocompleteSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Materials"
                      placeholder=""
                      variant="standard"
                      fullWidth
                      className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
                    />
                  )}
                />
              ) : (
                <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
              )}
            </Box>
            <Box flex={1} />
          </Box>
        </Box>
      </Box>
      <Box mt="30px">
        <span className={classes.labelText}>Imprint style(s)</span>
        <Box display="flex" position="relative">
          <Box flex={1} display="contents">
            {imprints.length > 0 ? (
              <Autocomplete
                multiple
                id="tags-outlined"
                options={imprints}
                limitTags={6}
                disabled={imprints.length < 1}
                value={values.productImprints}
                style={{ minWidth: '100%' }}
                getOptionSelected={(option, value) => {
                  // console.log('OPTION', option)
                  // console.log('VALUE', value)
                  return (
                    option.name === value.option_value?.value ||
                    option.value === value.option_value?.value ||
                    option.name === value.name ||
                    option.value === value.name ||
                    option.value === value ||
                    option.name === value
                  )
                }}
                getOptionLabel={(option) => {
                  // console.log('OPTLABEL', option)
                  return option.option_value?.value || option.name
                }}
                onChange={(e, v) => {
                  form.change('productImprints', v)
                }}
                filterSelectedOptions
                className={classes.autocompleteSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Imprints"
                    placeholder=""
                    variant="standard"
                    fullWidth
                    className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
                  />
                )}
              />
            ) : (
              <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
            )}
          </Box>
          <Box flex={1} />
        </Box>
        <Box mt="30px">
          <span className={classes.labelText}>Imprint options</span>
          <Box display="flex" position="relative">
            <Box flex={1} display="contents">
              {imprints.length > 0 ? (
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={imprints}
                  limitTags={6}
                  disabled={imprints.length < 1}
                  style={{ minWidth: '100%' }}
                  value={values.imprintOptions}
                  getOptionSelected={(option, value) => {
                    // console.log('OPTION', option.name)
                    // console.log('VALUE', value.option_value?.value)
                    // console.log(option.name === value.option_value?.value)
                    return (
                      option.name === value.option_value?.value ||
                      option.value === value.option_value?.value ||
                      option.name === value.name ||
                      option.value === value.name ||
                      option.value === value ||
                      option.name === value
                    )
                  }}
                  getOptionLabel={(option) => {
                    // console.log('OPTLABEL', option)
                    return option.option_value?.value || option.name
                  }}
                  onChange={(e, v) => {
                    form.change('imprintOptions', v)
                  }}
                  filterSelectedOptions
                  className={classes.autocompleteSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Imprints"
                      placeholder=""
                      variant="standard"
                      fullWidth
                      className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
                    />
                  )}
                />
              ) : (
                <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
              )}
            </Box>
            <Box flex={1} />
          </Box>
        </Box>
      </Box>
      <Box mt="30px">
        <span className={classes.labelText}>Sizes</span>
        <Box mt="20px" mb="10px" display="flex" position="relative">
          <Box flex={1} display="contents">
            <ChipInput
              source="sizeOptions"
              className={`${classes.fieldInputLeft}`}
              style={{ minWidth: '100%' }}
              variant="standard"
              margin="none"
            />
          </Box>
          <Box flex={1} />
        </Box>
        <BooleanInput
          source="product_quotes[0].allow_order_splitting_by_size"
          label="Allow order splitting by sizes?"
        />
      </Box>
      <Box mt="30px">
        <span className={classes.labelText}>Other specifications</span>
        <ArrayInput source="otherSpecifications" label="">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box display="flex">
                  <TextInput
                    source={getSource('name')}
                    {...rest}
                    record={scopedFormData}
                    label="Style"
                    // fullWidth
                    variant="standard"
                    margin="none"
                    className={`${classes.fieldInputNoMP}`}
                  />
                  <ChipInput
                    source={getSource('option_value.value')}
                    className={`${classes.fieldInput}`}
                    label="Values"
                    variant="standard"
                    style={{ marginLeft: '20px' }}
                    margin="none"
                    {...rest}
                    // defaultValue={scopedFormData?.option_value?.value}
                    record={scopedFormData}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <Box mt="30px">
        <span className={classes.labelText}>Customizations</span>
        <ArrayInput source="productCustomisations" label="">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box display="flex">
                  <TextInput
                    source={getSource('name')}
                    {...rest}
                    record={scopedFormData}
                    label="Option"
                    // fullWidth
                    variant="standard"
                    margin="none"
                    className={`${classes.fieldInputNoMP}`}
                  />
                  <ChipInput
                    source={getSource('option_value.value')}
                    className={`${classes.fieldInput}`}
                    style={{ marginLeft: '20px' }}
                    label="Values"
                    variant="standard"
                    margin="none"
                    {...rest}
                    // defaultValue={scopedFormData?.option_value?.value}
                    record={scopedFormData}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <Box mt="30px">
        <span className={classes.labelText}>Product dimensions</span>
        <DimensionsTable source="productDimensions" />
      </Box>
      <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Packaging type and dimensions</span>
        {packagingTypes.length > 0 ? (
          <SelectInput
            label="Packaging type"
            source="product_quotes[0].packaging_type"
            choices={packagingTypes}
            className={classes.fieldInputNoMP}
            optionText="name"
            optionValue="value"
            defaultValue=""
            variant="standard"
          />
        ) : (
          <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
        )}
        <DimensionsTable source="packagingDimensions" />
      </Box>
      <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Carton quantities</span>
        <Box mt="30px" display="flex">
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            source="product_quotes[0].cartons_per_pallet"
            label="Cartons per pallet"
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            source="product_quotes[0].cartons_per_container_20ft"
            label="Cartons per container (20ft)"
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            source="product_quotes[0].cartons_per_container_40ft"
            label="Cartons per container (40ft)"
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            source="product_quotes[0].cartons_per_container_40ft_hc"
            label="Cartons per container (40ft HQ)"
          />
        </Box>
      </Box>

      <Box mt="30px">
        <span className={classes.labelText}>Additional files</span>
        <Box flex={1}>
          <div {...getAdditionalRootProps({ className: classes.additionalDropZone })}>
            <input {...getAdditionalInputProps()} />
            <p>{`Drag & drop some files here, or click to select files`}</p>
          </div>
          <Box className={classes.additionalPreviewsContainer}>{additionalPreviews}</Box>
        </Box>
      </Box>
      <Box mt="30px" display="flex" flexDirection="column">
        <TextInput
          label="Product notes"
          source="notes"
          className={classes.textInputFull}
          style={{
            alignItems: 'start',
          }}
          multiline
          // rows={5}
          variant="standard"
        />
      </Box> */}
      <div style={{ textAlign: 'center' }}>
        {Object.values(errors)?.length > 0 ? (
          <Tooltip title={Object.values(errors).join(', ')}>
            <WarningIcon style={{ marginTop: '10px', marginLeft: '20px' }} />
          </Tooltip>
        ) : null}
      </div>
    </Box>
  )
}

export default FormData
