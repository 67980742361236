import React from 'react'
import { Edit } from 'react-admin'
// import { makeStyles } from "@material-ui/core/styles"
import { Box, Card } from '@material-ui/core'
// import { styles as createStyles } from "../SupplierCreate"
// import uploadFile from "../../../../dataProvider/upload"
import Header from './components/Header'
import GeneralInformation from './components/GeneralInformation'
import AddressesBox from './components/AddressesBox'
import ContactDetails from './components/ContactDetails'
import OtherInformation from './components/OtherInformation'
import Certification from './components/Certification'
import Audits from './components/Audits'
import Products from './components/Products'
import Files from './components/Files'

const SupplierTitle = ({ record }) => <span>Supplier #{record.reference}</span>

// const styles = {
//   ...createStyles,
//   comment: {
//     maxWidth: "20em",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     whiteSpace: "nowrap",
//   },
// }

// const useStyles = makeStyles(styles)

const SupplierEdit = (props) => (
  <Edit
    {...props}
    actions={null}
    title={<SupplierTitle />}
    component="div"
    mutationMode="pessimistic"
  >
    <SupplierForm />
  </Edit>
)

const SupplierForm = (props) => {
  // const classes = useStyles()
  // const dataProvider = useDataProvider()
  // const notify = useNotify()
  // const refresh = useRefresh()

  // console.log("SUPEDITPROPS", props)

  const companyPhoto = props.record?.files?.filter((item) => item.type === 'company-photo')[0]

  // const handleSubmit = async (record, formState) => {}

  return (
    // <FormWithRedirect
    //   {...props}
    //   render={formProps => (
    <Box>
      <Header
        data={{
          name: props.record?.name,
          createdAt: props.record?.created_at,
          id: props.record?.id,
          sca:
            props.record?.sca_user?.forename && props.record?.sca_user?.surname
              ? `${props.record?.sca_user?.forename} ${props.record?.sca_user?.surname}`
              : props.record?.sca_user?.email,
          status: props.record?.status,
          image: companyPhoto,
        }}
      />
      <Card>
        <Box p="30px">
          <GeneralInformation
            data={{
              name: props.record?.name,
              legalAddress: props.record?.legal_address,
              localName: props.record?.local_name,
              verificationLink: props.record?.official_verification_link,
              businessLicenseNumber: props.record?.business_licence_no,
              legalRepresentative: props.record?.official_legal_person_name,
              registeredCapital: props.record?.official_registered_capital,
              licenseScope: props.record?.official_business_scope,
              supplierID: props.record?.id,
              status: props.record?.status,
            }}
          />
          <AddressesBox
            addresses={props.record?.addresses || []}
            supplierID={props.record?.id}
            primaryAddress={props.record?.primary_address}
            billingAddress={props.record?.billing_address}
            pickupAddress={props.record?.pickup_address}
            sampleAddress={props.record?.sample_address}
            bankDetail={props.record?.bank_detail}
          />
          <ContactDetails
            contacts={props.record?.contacts || []}
            supplierID={props.record?.id}
            primaryContact={props.record?.primary_contact}
          />
          <OtherInformation data={props.record} supplierID={props.record?.id} />
          <Certification
            certificates={props.record?.certifications || []}
            supplierID={props.record?.id}
          />
          <Audits data={props.record?.audits} />
          <Products supplierID={props.record?.id} />
          <Files data={props.record} />
        </Box>
      </Card>
    </Box>
    //   )}
    // />
  )
}

export default SupplierEdit
