import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const invoicePaymentIntent = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            mutation getInvoicePaymentIntent {
              createInvoicePaymentIntentSim(
                arg1: {
                  ${jsonParams}
                }
            ) {
                paymentIntentSecret
                publishableKey
              }
            }
          `,
    parseResponse: ({ data, errors }) => ({
      data: data.createInvoicePaymentIntentSim,
      errors,
    }),
  }
}
