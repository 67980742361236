import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core'
import NumberFormat from '../../components/CustomNumberFormat'
import useStyles from '../styles'
// import SelectInput from '@/components/input/SelectInput'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    padding: '5px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    padding: '5px',
    fontSize: 14,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    // color: '#FFFFFF',
    padding: '5px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    padding: '2px 5px 0px 5px',
    height: '30px',
    minHeight: '30px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const FactoryQuoteTable = ({ prices = [], productIndex = 0 }) => {
  const classes = useStyles()
  //   const form = useForm()

  //   const { source } = props

  return (
    <TableContainer className={classes.tableBox} component={Paper} style={{ marginTop: 20 }}>
      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: '34%' }} align="right">
              Quantity
            </StyledTableCell>
            <StyledTableCell style={{ width: '33%' }} align="right">
              Price
            </StyledTableCell>
            <StyledTableCell style={{ width: '33%' }} align="right">
              Lead Time
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prices?.map((_, index) => (
            <TableRow key={`quoteRow${index}`}>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={0}
                  fixedDecimalScale
                  isNumericString
                  source={`data[${productIndex}].prices[${index}].quantity`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`data[${productIndex}].prices[${index}].price`}
                  prefix="$ "
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={0}
                  fixedDecimalScale
                  isNumericString
                  source={`data[${productIndex}].prices[${index}].lead_time`}
                />
              </BodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FactoryQuoteTable
