import React, { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider, SelectInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import useStyles from '../styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'

const SelectFieldInput = (props) => {
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)

  const [editing, setEditing] = useState(false)

  const { resource, field, id, ...rest } = props

  return (
    <Box display="flex">
      <SelectInput
        variant="standard"
        fullWidth
        source={field}
        disabled={!editing}
        className={classes.areaText}
        InputProps={{
          maxLength: 300,
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
          },
        }}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)

              try {
                await dataProvider.update(resource, {
                  data: { id, [field]: values[field] },
                })
                // console.log('changing', values.value)
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                setEditing(false)
                setSaving(false)
                notify(error.message, { type: 'warning' })
              }
              setEditing(false)
              setSaving(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default SelectFieldInput
