import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import {
  required,
  useNotify,
  useDataProvider,
  FormWithRedirect,
  SelectArrayInput,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import lodashGet from 'lodash/get'
import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import useStyles from '../styles'
import TextInput from '../../../../../components/OutlinedTextInput'
import SelectInput from '../../../../../components/input/SelectInput'
import ChipInput from '../../../../../components/input/ChipInput'

const GeneralInformation = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isEdit, setIsEdit] = useState(false)
  const [companyTypes, setCompanyTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [employees, setEmployees] = useState([])
  const [companySizes, setCompanySizes] = useState([])
  const [profits, setProfits] = useState([])
  const [markets, setMarkets] = useState([])

  // console.log("GENPROPS", props)

  const years = Array.from(Array(100), (v, k) => k + 1960)
    .filter((item) => item <= moment().year())
    .map((item) => ({
      id: item,
      name: `${item}`,
    }))
  years.push({ id: 0, name: '0' })

  const defaultCats = props.data?.categories.map((item) => item.category.id) || []

  useEffect(() => {
    const fetchDataProvider = async () => {
      const { data: employeesList } = await dataProvider.getList('employees', {})
      setEmployees(employeesList)
      const { data: categoriesTemp } = await dataProvider.getList('categories', {})
      setCategories(categoriesTemp.map((item) => ({ id: item.id, name: item.name })))
      const { data: companyTypesList } = await dataProvider.getList('supplier_type', {})
      setCompanyTypes(companyTypesList)
      const { data: companySizesList } = await dataProvider.getList('factorySize', {})
      setCompanySizes(companySizesList)
      const { data: profitsList } = await dataProvider.getList('annualRevenue', {})
      setProfits(profitsList)
      const { data: marketsList } = await dataProvider.getList('primaryMarket', {})
      setMarkets(marketsList)
    }
    if (isEdit) fetchDataProvider()
  }, [dataProvider, isEdit])

  const updateSupplier = async (params) => {
    const { supplierID } = props
    const submission = {
      // ...params,
      id: supplierID,
    }

    if (params.companyType) {
      submission.companyType = params.companyType
    }
    if (params.companyMode) {
      submission.companyMode = params.companyMode
    }
    if (params.annualTurnover) {
      submission.annualTurnover = params.annualTurnover
    }
    if (params.employeeTotal) {
      submission.employeeTotal = params.employeeTotal
    }
    if (params.factorySize) {
      submission.factorySize = params.factorySize
    }
    if (params.primaryMarket) {
      submission.primaryMarket = params.primaryMarket
    }
    if (params.website) {
      submission.website = params.website
    }
    if (params.introduction) {
      submission.introduction = params.introduction
    }
    if (params.categories) {
      submission.categories = params.categories
    }
    if (params.yearEstablished) {
      submission.yearEstablished = params.yearEstablished
    }

    // submission.companyMode = [submission.companyMode]
    delete submission.categories

    // console.log(submission)
    // return null
    try {
      const { data } = await dataProvider.updateSupplier('supplier', { ...submission })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  return (
    <Box>
      {isEdit ? (
        <FormWithRedirect
          key="formName"
          {...props}
          initialValues={{
            companyType: props.data?.company_type,
            companyMode: props.data?.company_mode,
            annualTurnover: props.data?.annual_turnover,
            employeeTotal: props.data?.employee_total,
            factorySize: props.data?.factory_size,
            primaryMarket: props.data?.primary_market,
            website: props.data?.website,
            introduction: props.data?.company_introduction,
            categories: defaultCats,
            yearEstablished: props.data?.year_established,
          }}
          render={() => (
            <Box>
              <EditForm
                updateSupplier={updateSupplier}
                cancelAction={() => setIsEdit(false)}
                companyTypes={companyTypes}
                categories={categories}
                employees={employees}
                companySizes={companySizes}
                profits={profits}
                markets={markets}
                years={years}
              />
            </Box>
          )}
        />
      ) : (
        <Box>
          <Box display="flex">
            <span className={classes.sectionHeader}>Other Information</span>
            <IconButton
              onClick={() => {
                setIsEdit(true)
              }}
              style={{ marginTop: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Company Type:</span>
            <span className={classes.fieldValue}>{props.data?.company_type}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>OEM/ODM/Brand:</span>
            <span className={classes.fieldValue}>{props.data?.company_mode?.join()}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Product Categories:</span>
            <span className={classes.fieldValue}>
              {props.data?.categories?.length > 0 &&
                props.data.categories.map((item) => (
                  <span key={`${item.category.id}cat${item.id}`}>
                    {item.category?.name} &nbsp;&nbsp;&nbsp;
                  </span>
                ))}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Total Employees:</span>
            <span className={classes.fieldValue}>{props.data?.employee_total}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Company Size:</span>
            <span className={classes.fieldValue}>{props.data?.factory_size}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Total Annual Profit (US$):</span>
            <span className={classes.fieldValue}>{props.data?.annual_turnover}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Primary Market:</span>
            <span className={classes.fieldValue}>{props.data?.primary_market}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Year Established:</span>
            <span className={classes.fieldValue}>{props.data?.year_established}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Company Introduction:</span>
            <span className={classes.fieldValue}>{props.data?.company_introduction}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Website(s):</span>
            <Box display="flex">
              {props.data?.website?.map((url) => (
                <span key={`${url}site`} className={classes.fieldValue}>
                  <a
                    style={{ display: 'flex', alignItems: 'center' }}
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                  >
                    {url.length > 20 ? `${url.substring(0, 20)}...` : url}
                  </a>
                </span>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const EditForm = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  // console.log("EFPROPS", props)

  return (
    <Box display="flex" flexDirection="column">
      <Box m="30px 0px">
        <span className={classes.sectionHeader}>Other Information</span>
        <IconButton
          onClick={() => {
            // setSaving(true)

            const formState = form.getState()

            if (formState.invalid) {
              form.submit()
            } else {
              props.updateSupplier(values)
              props.cancelAction()
            }
            // setSaving(false)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <CheckCircleOutlineOutlined fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => {
            props.cancelAction()
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <CancelOutlined fontSize="small" />
        </IconButton>
      </Box>
      {/* <Box display='flex' flexDirection='column' width='fit-content' minWidth='30%'> */}
      <Box display="inline-grid" width="30%">
        <SelectInput
          label="Company Type:"
          source="companyType"
          choices={props.companyTypes}
          optionText="name"
          optionValue="value"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
        <SelectInput
          label="OEM/ODM/Brand:"
          source="companyMode"
          choices={[
            { name: 'OEM', value: 'oem' },
            { name: 'ODM', value: 'odm' },
            { name: 'Brand', value: 'brand' },
          ]}
          optionText="name"
          optionValue="value"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
      </Box>
      <Box display="inline-grid" minWidth="30%" width="fit-content">
        {/* <SelectInput
          label='Categories:'
          source='categories'
          choices={props.categories}
          optionText='name'
          optionValue='id'
          defaultValue=''
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        /> */}
        <SelectArrayInput
          label="Categories:"
          source="categories"
          variant="outlined"
          defaultValue=""
          // fullWidth
          classes={{ chip: classes.arrayChip }}
          optionText="name"
          optionValue="id"
          choices={props.categories}
          validate={required()}
        />
      </Box>
      <Box display="inline-grid" width="30%">
        <SelectInput
          label="Total Employees:"
          source="employeeTotal"
          choices={props.employees}
          optionText="name"
          optionValue="name"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
        <SelectInput
          label="Company Size:"
          source="factorySize"
          choices={props.companySizes}
          optionText="name"
          optionValue="name"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
        <SelectInput
          label="Total Annual Profit (US$):"
          source="annualTurnover"
          choices={props.profits}
          optionText="name"
          optionValue="name"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
        <SelectInput
          label="Primary Market:"
          source="primaryMarket"
          choices={props.markets}
          optionText="name"
          optionValue="name"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
        <SelectInput
          label="Year Established:"
          source="yearEstablished"
          choices={props.years}
          optionText="name"
          optionValue="id"
          defaultValue=""
          // fullWidth
          // className={classes.areaTextSelect}
          validate={required()}
        />
      </Box>
      {/* <Box display='flex' flexDirection='column' width='fit-content' minWidth='60%'> */}
      <Box display="inline-grid" width="50%">
        <TextInput
          variant="outlined"
          // fullWidth
          // className={classes.areaText}
          multiline
          rows={4}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: 'baseline',
            },
          }}
          source="introduction"
          label="Company Introduction:"
        />
      </Box>
      <Box display="inline-grid" width="fit-content" minWidth="30%">
        {/* <TextInput
          variant='outlined'
          // fullWidth
          // className={classes.areaText}
          InputProps={{
            maxLength: 300,
            style: {
              marginBottom: 15,
              alignItems: "baseline",
            },
          }}
          source='website'
          label='Website(s):'
        /> */}
        <ChipInput
          // defaultValue={["abc", "def"]}
          className={classes.chipField}
          // fullWidth
          name="website"
          variant="outlined"
          label="Website(s):"
        />
      </Box>
    </Box>
  )
}

export default GeneralInformation
