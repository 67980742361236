import React, { useState } from 'react'
import {
  Box,
  Button,
  //  CircularProgress
} from '@material-ui/core'
import {
  useNotify,
  useDataProvider,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ChipInput from '../../../components/input/ChipInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  fieldInputLeft: {
    margin: '0px 10px',
    minWidth: '200px',
    padding: '0px',
    '& .WAMuiChipInput-chipContainer': {
      margin: '0px',
    },
  },
  fieldInputNoMP: {
    minWidth: '100px',
    margin: '0px',
    padding: '0px',
  },
}))

const SpecificationArrayFieldInput = (props) => {
  //   const classes = useStyles()
  const dataProvider = useDataProvider()

  const { values } = useFormState()
  const classes = useStyles()
  const notify = useNotify()
  const form = useForm()

  const [editing, setEditing] = useState(false)
  // const [loading, setLoading] = useState(true)

  const {
    referenceField,
    referenceId,
    resourceName,
    source,
    oldDataField,
    onConflict,
    valueField = 'option_value',
    valueSource = 'option_value.value',
  } = props

  const oldData = values[oldDataField]

  // if (loading) {
  //   return (
  //     <Box display="flex" alignItems="center" justifyContent="center">
  //       <CircularProgress style={{ width: '20px', height: '20px' }} />
  //     </Box>
  //   )
  // }

  // console.log('CHIPEDITPROPS', props)
  // console.log('CHIPEDITVALS', values[source])
  // console.log('CHIPEDITOLDVALS', values[oldDataField])

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center">
        <ArrayInput source={source} label={false} style={{ margin: '0px', padding: '0px' }}>
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            disableAdd={!editing}
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
            disableRemove={!editing}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box display="flex">
                  <TextInput
                    source={getSource('name')}
                    {...rest}
                    record={scopedFormData}
                    label={false}
                    // fullWidth
                    variant="standard"
                    margin="none"
                    disabled={!editing}
                    className={`${classes.fieldInputNoMP}`}
                  />
                  <ChipInput
                    source={getSource(valueSource)}
                    {...rest}
                    record={scopedFormData}
                    label={false}
                    className={`${classes.fieldInputLeft}`}
                    style={{ marginLeft: '20px' }}
                    variant="standard"
                    margin="none"
                    disabled={!editing}
                    // {...rest}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={
              async () => {
                const newVals = []
                const retainVals = []
                const delVals = []

                // console.log('OLDDATA', oldData)
                values[source].map((item) => {
                  // console.log('ITEM', item)
                  const opt = oldData.find((old) => old.name === item.name)

                  // console.log('OPT', opt)
                  // check if opt exists, if not, push all option vals for this option to new
                  if (!opt) {
                    //   console.log('ADDING', item)
                    item[valueField].value.map((val) => {
                      // console.log('add new', val)
                      newVals.push({
                        [referenceField]: referenceId,
                        [valueField]: {
                          data: {
                            value: val,
                            option: {
                              data: { name: item.name },
                              on_conflict: {
                                constraint: '^option_name_key^',
                                update_columns: '^name^',
                              },
                            },
                          },
                          on_conflict: {
                            constraint: '^option_value_option_id_value_key^',
                            update_columns: ['^option_id^', '^value^'],
                          },
                        },
                      })
                    })
                  } else {
                    // loop option vals
                    item[valueField].value.map((val) => {
                      // console.log('VAL', val)

                      if (!opt.values.some((old) => old.value === val)) {
                        // if old data doesn't exist, add to newVals
                        //   console.log('add new', val)
                        newVals.push({
                          [referenceField]: referenceId,
                          [valueField]: {
                            data: {
                              value: val,
                              option: {
                                data: { name: item.name },
                                on_conflict: {
                                  constraint: '^option_name_key^',
                                  update_columns: '^name^',
                                },
                              },
                            },
                            on_conflict: {
                              constraint: '^option_value_option_id_value_key^',
                              update_columns: ['^option_id^', '^value^'],
                            },
                          },
                        })
                      } else {
                        // else add to retainVails
                        //   console.log('retain', val)
                        retainVals.push({
                          [referenceField]: referenceId,
                          [valueField]: {
                            data: {
                              value: val,
                              option: {
                                data: { name: item.name },
                                on_conflict: {
                                  constraint: '^option_name_key^',
                                  update_columns: '^name^',
                                },
                              },
                            },
                            on_conflict: {
                              constraint: '^option_value_option_id_value_key^',
                              update_columns: ['^option_id^', '^value^'],
                            },
                          },
                        })
                      }
                    })
                  }
                })

                // map old data
                oldData.map((item) => {
                  // console.log('oldItem', item)

                  const delOpt = values[source].find((val) => val.name === item.name)
                  // console.log('delOpt', delOpt)
                  if (!delOpt) {
                    // if old option does not exist in new, delete all option vals
                    //   console.log('deleting old option')

                    item.values.map((val) => {
                      delVals.push(val.id)
                    })
                  }
                  // loop through old option values

                  item.values.map((val) => {
                    // console.log('vals', val)

                    // if old value does not exist in new, add to delVals
                    if (delOpt) {
                      if (!delOpt[valueField]?.value?.some((newVal) => newVal === val.value)) {
                        // console.log('deleting', val)
                        delVals.push(val.id)
                      }
                    }
                  })
                })
                try {
                  // console.log('NEWVALS', newVals)

                  // console.log(fieldName)

                  // const newValsResp =
                  if (newVals.length > 0) {
                    const resp = await dataProvider.createMany(resourceName, {
                      objects: newVals,
                      ...(onConflict && { ...onConflict }),
                    })

                    // console.log(resp)

                    const oldSpecs = []
                    const uniqueSpecs = []
                    resp.data.map((spec) => {
                      if (uniqueSpecs.indexOf(spec[valueField].option.name) === -1) {
                        uniqueSpecs.push(spec[valueField].option.name)
                      }
                    })

                    uniqueSpecs.map((spec) => {
                      oldSpecs?.push({
                        name: spec,
                        values: resp.data
                          .filter((qspec) => qspec[valueField]?.option?.name === spec)
                          .map((val) => ({ id: val.id, value: val[valueField].value })),
                      })
                    })

                    form.change(oldDataField, oldSpecs)
                  }

                  // console.log('NVRESP', newValsResp)

                  // console.log('DELVALS', delVals)

                  // const delValsResp =
                  delVals.length > 0
                    ? await dataProvider.deleteMany(resourceName, {
                        ids: delVals,
                      })
                    : []

                  // console.log('DVRESP', delValsResp)

                  // console.log('RETVALS', retainVals)

                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
                setEditing(false)
              }

              // setSaving(false)
            }
            style={{ marginLeft: '30px', marginTop: '16px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ marginTop: '16px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ marginLeft: '30px', marginTop: '16px', maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default SpecificationArrayFieldInput
