import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { useDataProvider, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
// import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
// import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  editButton: {
    border: 'solid 1px lightblue',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  imageThumb: {
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '5px',
  },
  table: { marginRight: '50px' },
  imageBox: {
    width: '152px',
    height: '152px',
    border: 'solid 2px white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  imageBoxSelected: {
    width: '152px',
    height: '152px',
    border: 'solid 2px black',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
  imageFaded: {
    maxWidth: '150px',
    maxHeight: '150px',
    opacity: '50%',
  },
}))

const RelatedProductsSelection = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const { values } = useFormState()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [editRelatedProductsOpen, setEditRelatedProductsOpen] = useState(false)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const { data: productsList } = await dataProvider.getList('saleable_product_variants', {
          filter: { is_listed: true },
          exclude: { saleable_product_id: values.id },
          sort: { field: 'id', order: 'desc' },
          pagination: { page: 1, perPage: 100 },
        })
        setProducts(productsList)
        setLoading(false)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        setLoading(false)
      }
    }
    const abortController = new AbortController()

    editRelatedProductsOpen && !abortController.signal.aborted && getData()

    return () => {
      abortController.abort()
    }
  }, [editRelatedProductsOpen])

  // console.log(products)
  // console.log(values.related_products)

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.editButton} onClick={() => setEditRelatedProductsOpen(true)}>
          Edit
        </Button>
      </Box>

      <Box display="flex" flexWrap="wrap">
        {values.related_products?.map((relatedProduct) => (
          <Box
            key={`prod${relatedProduct?.saleable_product_variant?.id}`}
            className={classes.imageThumb}
          >
            <img
              src={relatedProduct.saleable_product_variant?.image?.url}
              alt=""
              className={classes.imageThumb}
            />
          </Box>
        ))}
        <Dialog
          fullWidth
          maxWidth="md"
          open={editRelatedProductsOpen || false}
          onClose={() => setEditRelatedProductsOpen(false)}
          aria-label="relatedProductsSelection"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <span>Select Related Products</span>

              <Box display="flex">
                {loading && (
                  <Box style={{ width: '100%', height: '100%' }}>
                    <CircularProgress
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </Box>
                )}

                <IconButton
                  onClick={() => {
                    setEditRelatedProductsOpen(false)
                  }}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                >
                  <CancelOutlined fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
              {products.map((product) => (
                <RelatedProduct key={`prd${product.id}`} product={product} />
              ))}
              {!loading && products?.length === 0 && (
                <span style={{ fontSize: '20px' }}>No products found</span>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  )
}

const RelatedProduct = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const { product } = props

  // console.log(values.related_products)

  return (
    <Box
      m="5px"
      p="5px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      key={`prodImg${product.id}`}
      className={
        values.related_products?.some(
          (relProd) => relProd.saleable_product_variant?.id === product.id,
        )
          ? classes.imageBoxSelected
          : classes.imageBox
      }
      onClick={async () => {
        setSaving(true)
        // console.log(product)
        const relatedProducts = [...(values.related_products || [])]
        const ind = values.related_products?.findIndex(
          (el) => el.saleable_product_variant?.id === product.id,
        )
        // console.log(ind)
        if (ind !== -1) {
          try {
            await dataProvider.delete('saleable_product_related_products', {
              data: {
                id: values.related_products[ind].id,
              },
            })
            relatedProducts.splice(ind, 1)
            form.change(`related_products`, relatedProducts)
          } catch (error) {
            console.log(error)
            setSaving(false)
            notify(error.message, { type: 'warning' })
          }
        } else {
          const resp = await dataProvider.create('saleable_product_related_products', {
            data: {
              saleable_product_1_id: values.id,
              saleable_product_2_id: product.saleable_product.id,
              saleable_product_variant_id: product.id,
            },
            on_conflict: {
              constraint: 'saleable_product_related_products_saleable_product_1_id_saleabl',
              update_columns: [
                'saleable_product_1_id',
                'saleable_product_2_id',
                'saleable_product_variant_id',
              ],
            },
          })
          relatedProducts.push({
            __typename: 'saleable_product_related_products',
            id: resp.data.id,
            saleable_product_variant: product,
          })
          form.change(`related_products`, relatedProducts)
        }
        setSaving(false)
      }}
    >
      <Tooltip title={`${product.name} - ${product.stock || 0}`}>
        <img
          className={saving ? classes.imageFaded : classes.image}
          src={product.image?.url}
          alt=""
        />
      </Tooltip>
      {saving && (
        <CircularProgress
          style={{
            width: '20px',
            height: '20px',
            zIndex: '100',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      )}
    </Box>
  )
}

export default RelatedProductsSelection
