import React, { Fragment } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import {
  useNotify,
  useRefresh,
  // useDataProvider,
  useCreate,
  Create,
  FormWithRedirect,
  SaveButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, useFormState } from 'react-final-form'
import TextInput from '../../../components/OutlinedTextInput'
import useLazyEffect from '../../../utils/useLazyEffect'
// import { roundTo } from '../../../utils/utils'
import NumberFormat from '../../components/CustomNumberFormat'

const useStyles = makeStyles(() => ({
  fieldInputLeft: {
    // marginRight: '20px',
    minWidth: '120px',
    width: '120px',
  },
  fieldInput: {
    margin: '0px 50px',
    minWidth: '120px',
    width: '120px',
  },
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
    marginTop: '20px',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
}))
const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '5px',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    color: '#323232',
    fontSize: 12,
    padding: '5px',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ClientQuoteCreateDialog = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { createOpen, setCreateOpen, productIndex, config, factoryQuote } = props
  const { values } = useFormState()

  const [create, { loading: updating }] = useCreate('scm_quote')

  const handleSubmit = (submission) => {
    // console.log(submission)

    const params = {
      ...(submission.__typename === 'scm_quote' && { id: submission.id }),
      sourcing_request_id:
        submission.__typename === 'scm_quote' ? submission.sourcing_request_id : values.id,
      client_id: values.client_id,
      image_id: submission.image_id,
      product_quote_id: factoryQuote.id,
      freight_rate_sea: parseInt(submission.freightRateSea * 100),
      freight_rate_air: parseInt(submission.freightRateAir * 100),
      does_sea_freight_include_duties: submission.does_sea_freight_include_duties,
      client_sample_price: parseInt(submission.sample_cost * 100),
      client_sample_lead_time: submission.sample_lead_time,
      client_tooling_cost: parseInt(submission.setup_cost * 100),
      client_notes: submission.notes,
      client_product_name: submission.name,
      prices: {
        data: submission.prices?.map((el) => ({
          ...(el.id && { id: el.id }),
          price: parseInt(el.unitPrice * 100),
          quantity: el.quantity,
          production_lead_time: el.production_lead_time,
          estimated_freight_air: el.airPublished ? parseInt(el.airPriceInternal * 100) : null,
          estimated_freight_sea: el.seaPublished ? parseInt(el.seaPriceInternal * 100) : null,
          fob_price: el.fobPublished ? parseInt(el.fobPriceInternal * 100) : null,
          internal_air: parseInt(el.airPriceInternal * 100),
          internal_sea: parseInt(el.seaPriceInternal * 100),
          internal_fob: parseInt(el.fobPriceInternal * 100),
          lead_time_sea: el.lead_time_sea,
          lead_time_air: el.lead_time_air,
          total_cbm: parseInt(el.totalCBM * 100),
          shipment_weight: parseInt(el.shipmentWeight * 100),
          total_cartons: el.numCartons,
          total_duties: parseInt(el.totalDuties * 100),
          freight_cost_air: parseInt(el.airFreightCostTotal * 100),
          freight_cost_sea: parseInt(el.seaFreightCostTotal * 100),
          freight_cost_air_per_piece: parseInt(el.airFreightCostPerPiece * 100),
          freight_cost_sea_per_piece: parseInt(el.seaFreightCostPerPiece * 100),
          total_lead_time_sea: el.lead_time_sea + el.production_lead_time,
          total_lead_time_air: el.lead_time_air + el.production_lead_time,
        })),
        on_conflict: {
          constraint: 'client_quote_prices_pkey',
          update_columns: [
            'price',
            'quantity',
            'production_lead_time',
            'estimated_freight_air',
            'estimated_freight_sea',
            'fob_price',
            'internal_air',
            'internal_sea',
            'internal_fob',
            'lead_time_sea',
            'lead_time_air',
            'total_cbm',
            'shipment_weight',
            'total_cartons',
            'total_duties',
            'freight_cost_air',
            'freight_cost_sea',
            'freight_cost_air_per_piece',
            'freight_cost_sea_per_piece',
            'total_lead_time_sea',
            'total_lead_time_air',
          ],
        },
      },
      payment_terms: {
        data: submission.payment_terms?.map((el) => ({
          ...(el.id && { id: el.id }),
          milestone: el.milestone,
          value: el.value,
        })),
        on_conflict: {
          constraint: 'client_quote_payment_terms_milestone_client_quote_id_key',
          update_columns: ['milestone', 'value'],
        },
      },
    }

    create(
      {
        payload: {
          data: params,
          on_conflict: {
            constraint: 'client_quote_pkey',
            update_columns: [
              'sourcing_request_id',
              'client_id',
              'product_quote_id',
              'freight_rate_sea',
              'freight_rate_air',
              'does_sea_freight_include_duties',
              'client_sample_price',
              'client_sample_lead_time',
              'client_tooling_cost',
              'client_notes',
              'client_product_name',
              'image_id',
            ],
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          const pkgParams = {
            id: data?.id,
            product_quote_id: data?.product_quote_id,
            default_packaging_option: {
              data: {
                scm_quote_packaging_option: {
                  data: {
                    scm_quote_id: data?.id,
                    ...(submission.default_packaging_option?.scm_quote_packaging_option?.id && {
                      id: submission.default_packaging_option?.scm_quote_packaging_option?.id,
                    }),
                    carton_dimensions_id:
                      submission.carton_dimensions?.id ||
                      submission?.factoryQuote?.included_packaging_option?.carton_dimensions?.id,
                    pieces_per_carton:
                      submission.default_packaging_option?.pieces_per_carton ||
                      submission.factoryQuote?.included_packaging_option?.pieces_per_carton,
                    type:
                      submission.default_packaging_option?.type ||
                      submission?.factoryQuote?.included_packaging_option?.type,
                  },
                  on_conflict: {
                    constraint: 'scm_quote_packaging_options_scm_quote_id_product_quote_packagin',
                    update_columns: [
                      'carton_dimensions_id',
                      'pieces_per_carton',
                      'type',
                      'scm_quote_id',
                    ],
                  },
                },
                scm_quote_product_size: {
                  data: {
                    scm_quote_id: data?.id,
                  },
                },
              },
              on_conflict: {
                constraint: 'scm_quote_product_size_packagings_scm_quote_product_size_id_scm',
                update_columns: ['scm_quote_product_size_id', 'scm_quote_packaging_option_id'],
              },
            },
          }

          create(
            {
              payload: {
                data: pkgParams,
                on_conflict: {
                  constraint: 'client_quote_pkey',
                  update_columns: ['default_packaging_option_id'],
                },
              },
            },
            {
              onSuccess: () => {
                // console.log(data)
              },
              onFailure: ({ error }) => {
                notify(error, 'error')
              },
            },
          )

          setCreateOpen(false)
          refresh()

          // console.log(data)
        },
        onFailure: ({ error }) => {
          notify(error, 'error')
        },
      },
    )
  }

  // console.log(values)
  // console.log(productIndex)
  // console.log(values.products[productIndex])

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={createOpen}
      onClose={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setCreateOpen(false)
      }}
      aria-label="CreateClientQuote"
    >
      <Create basePath="sourcingRequests" resource="scm_quote">
        <FormWithRedirect
          redirect={null}
          save={handleSubmit}
          initialValues={{
            ...(values.__typename === 'scm_quote' && { ...values }),
            sourcing_request_id: productIndex > 1 ? values.id : values.sourcing_request_id,
            client_id: values.client_id,
            image_id:
              values.__typename === 'scm_quote'
                ? values.image_id
                : values.products[productIndex].product?.thumbnail_photo?.id,
            payment_terms:
              values.payment_terms?.length > 0
                ? values.payment_terms
                : [
                    {
                      milestone: 'Before production start',
                      value: 50,
                    },
                    {
                      milestone: 'Upon delivery',
                      value: 50,
                    },
                  ],
            name:
              values.__typename === 'sourcing_request'
                ? values.products[productIndex].product?.name
                : values.client_product_name,
            notes:
              values.__typename === 'sourcing_request'
                ? values.products[productIndex].product?.notes
                : values.client_notes,
            setup_cost:
              values.__typename === 'sourcing_request'
                ? values.products[productIndex].product?.product_quotes[0]?.toolingCost
                : values?.client_tooling_cost / 100 || 0,
            sample_cost:
              values.__typename === 'sourcing_request'
                ? values.products[productIndex].product?.product_quotes[0]?.sampleCost
                : values.client_sample_price / 100,
            sample_lead_time:
              values.__typename === 'sourcing_request'
                ? values.products[productIndex].product?.product_quotes[0]?.sample_lead_time
                : values.client_sample_lead_time,
            prices:
              values.__typename === 'sourcing_request'
                ? factoryQuote?.prices.map((el) => ({
                    unitPrice: el.unitPrice,
                    unit_price: el.unit_price,
                    quantity: el.quantity,
                    // lead_time: el.lead_time,
                    fobPriceInternal: el.internal_fob / 100 || el.unitPrice,
                    seaPriceInternal: el.internal_sea / 100 || null,
                    airPriceInternal: el.internal_air / 100 || null,
                    fobPublished: false,
                    airPublished: false,
                    seaPublished: false,
                    production_lead_time: el.lead_time,
                    lead_time_sea: Number(config.estimated_lead_time_sea),
                    lead_time_air: Number(config.estimated_lead_time_air),
                  }))
                : values.prices?.map((el) => ({
                    ...el,
                    fobPriceInternal: el.internal_fob / 100 || el.unitPrice,
                    seaPriceInternal: el.internal_sea / 100 || null,
                    airPriceInternal: el.internal_air / 100 || null,
                    fobPublished: el.fob_price ? true : false,
                    airPublished: el.estimated_freight_air ? true : false,
                    seaPublished: el.estimated_freight_sea ? true : false,
                    fobProfit: el.fob_price - el.internal_fob,
                    airProfit: (el.estimated_freight_air - el.internal_air) / 100,
                    seaProfit: (el.estimated_freight_sea - el.internal_sea) / 100,
                  })),
            factoryQuote,
            dutyRate:
              values.__typename === 'sourcing_request'
                ? factoryQuote?.duty_rate / 100 || 0
                : values.duty_rate / 100,
            freightRateSea:
              values.__typename === 'sourcing_request'
                ? factoryQuote?.freight_rate_sea / 100 ||
                  config.estimated_freight_rate_sea / 100 ||
                  null
                : factoryQuote?.freight_rate_sea === 0
                ? 0
                : values.freight_rate_sea / 100,
            freightRateAir:
              values.__typename === 'sourcing_request'
                ? factoryQuote?.freight_rate_air / 100 ||
                  config.estimated_freight_rate_air / 100 ||
                  null
                : factoryQuote?.freight_rate_air === 0
                ? 0
                : values.freight_rate_air / 100,
            does_sea_freight_include_duties:
              values.__typename === 'sourcing_request'
                ? factoryQuote?.does_sea_freight_include_duties ||
                  config.estimated_freight_rate_sea_duties_included === 'true' ||
                  false
                : values.does_sea_freight_include_duties,
            pieces_per_carton:
              values.default_packaging_option?.scm_quote_packaging_option?.pieces_per_carton,
            carton_dimensions:
              values.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions,
          }}
          render={(formProps) => (
            <ClientQuoteCreateDialogForm
              formProps={formProps}
              setCreateOpen={setCreateOpen}
              factoryQuote={factoryQuote}
              updating={updating}
            />
          )}
        />
      </Create>
    </Dialog>
  )
}

const ClientQuoteCreateDialogForm = (props) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const { setCreateOpen, formProps, factoryQuote } = props
  // const dataProvider = useDataProvider()
  // const refresh = useRefresh()
  // console.log(values)

  useLazyEffect(
    () => {
      if (values.prices?.length > 0) {
        const calcResults = calcFreightRatesRow({ index: 0 })
        // console.log(calcResults)

        // unitPrice,
        // seaFreightCostTotal,
        // seaUnitCostTotal,
        // seaFreightCostPerPiece,
        // airFreightCostTotal,
        // airUnitCostTotal,
        // airFreightCostPerPiece,
        // numCartons,
        // totalDuties,
        // shipmentWeight,
        // totalCBM,

        calcResults &&
          form.batch(() => {
            form.change(`prices[0].fobPriceInternal`, calcResults.unitPrice)
            form.change(`prices[0].airPriceInternal`, calcResults.airUnitCostTotal)
            form.change(`prices[0].seaPriceInternal`, calcResults.seaUnitCostTotal)
            form.change(`prices[0].fobPrice`, calcResults.unitPrice)
            form.change(`prices[0].ddpAir`, calcResults.airUnitCostTotal)
            form.change(`prices[0].ddpSea`, calcResults.seaUnitCostTotal)
            form.change(`prices[0].seaFreightTotal`, calcResults.seaFreightCostTotal)
            form.change(`prices[0].airFreightTotal`, calcResults.airFreightCostTotal)
            form.change(`prices[0].seaFreightPerPiece`, calcResults.seaFreightCostPerPiece)
            form.change(`prices[0].airFreightPerPiece`, calcResults.airFreightCostPerPiece)
            form.change(`prices[0].numCartons`, calcResults.numCartons)
            form.change(`prices[0].totalDuties`, calcResults.totalDuties)
            form.change(`prices[0].shipmentWeight`, calcResults.shipmentWeight)
            form.change(`prices[0].totalCBM`, calcResults.totalCBM)
            form.change(`prices[0].chargeableSeaWeight`, calcResults.chargeableSeaWeight)
            form.change(`prices[0].chargeableAirWeight`, calcResults.chargeableAirWeight)
            form.change(`prices[0].fobProfit`, 0)
            form.change(`prices[0].seaProfit`, 0)
            form.change(`prices[0].airProfit`, 0)
          })
      }
    },
    [
      values.dutyRate,
      values.prices?.length > 0 ? values.prices[0]?.quantity : null,
      values.prices?.length > 0 ? values.prices[0]?.unitPrice : null,
      values.freightRateAir,
      values.freightRateSea,
      values.does_sea_freight_include_duties,
    ],
    ['DutyRate', 'Quantity', 'UnitPrice', 'FreightRateAir', 'FreightRateSea', 'SeaIncludesDuties'],
  )

  useLazyEffect(
    () => {
      if (values.prices?.length > 1) {
        const calcResults = calcFreightRatesRow({ index: 1 })
        // console.log(calcResults)

        calcResults &&
          form.batch(() => {
            form.change(`prices[1].fobPriceInternal`, calcResults.unitPrice)
            form.change(`prices[1].airPriceInternal`, calcResults.airUnitCostTotal)
            form.change(`prices[1].seaPriceInternal`, calcResults.seaUnitCostTotal)
            form.change(`prices[1].fobPrice`, calcResults.unitPrice)
            form.change(`prices[1].ddpAir`, calcResults.airUnitCostTotal)
            form.change(`prices[1].ddpSea`, calcResults.seaUnitCostTotal)
            form.change(`prices[1].seaFreightTotal`, calcResults.seaFreightCostTotal)
            form.change(`prices[1].airFreightTotal`, calcResults.airFreightCostTotal)
            form.change(`prices[1].seaFreightPerPiece`, calcResults.seaFreightCostPerPiece)
            form.change(`prices[1].airFreightPerPiece`, calcResults.airFreightCostPerPiece)
            form.change(`prices[1].numCartons`, calcResults.numCartons)
            form.change(`prices[1].totalDuties`, calcResults.totalDuties)
            form.change(`prices[1].shipmentWeight`, calcResults.shipmentWeight)
            form.change(`prices[1].totalCBM`, calcResults.totalCBM)
            form.change(`prices[1].chargeableSeaWeight`, calcResults.chargeableSeaWeight)
            form.change(`prices[1].chargeableAirWeight`, calcResults.chargeableAirWeight)
            form.change(`prices[1].fobProfit`, 0)
            form.change(`prices[1].seaProfit`, 0)
            form.change(`prices[1].airProfit`, 0)
          })
      }
    },
    [
      values.dutyRate,
      values.prices?.length > 1 ? values.prices[1]?.quantity : null,
      values.prices?.length > 1 ? values.prices[1]?.unitPrice : null,
      values.freightRateAir,
      values.freightRateSea,
      values.does_sea_freight_include_duties,
    ],
    ['DutyRate', 'Quantity', 'UnitPrice', 'FreightRateAir', 'FreightRateSea', 'SeaIncludesDuties'],
  )

  useLazyEffect(
    () => {
      if (values.prices?.length > 2) {
        const calcResults = calcFreightRatesRow({ index: 2 })
        // console.log(calcResults)

        calcResults &&
          form.batch(() => {
            form.change(`prices[2].fobPriceInternal`, calcResults.unitPrice)
            form.change(`prices[2].airPriceInternal`, calcResults.airUnitCostTotal)
            form.change(`prices[2].seaPriceInternal`, calcResults.seaUnitCostTotal)
            form.change(`prices[2].fobPrice`, calcResults.unitPrice)
            form.change(`prices[2].ddpAir`, calcResults.airUnitCostTotal)
            form.change(`prices[2].ddpSea`, calcResults.seaUnitCostTotal)
            form.change(`prices[2].seaFreightTotal`, calcResults.seaFreightCostTotal)
            form.change(`prices[2].airFreightTotal`, calcResults.airFreightCostTotal)
            form.change(`prices[2].seaFreightPerPiece`, calcResults.seaFreightCostPerPiece)
            form.change(`prices[2].airFreightPerPiece`, calcResults.airFreightCostPerPiece)
            form.change(`prices[2].numCartons`, calcResults.numCartons)
            form.change(`prices[2].totalDuties`, calcResults.totalDuties)
            form.change(`prices[2].shipmentWeight`, calcResults.shipmentWeight)
            form.change(`prices[2].totalCBM`, calcResults.totalCBM)
            form.change(`prices[2].chargeableSeaWeight`, calcResults.chargeableSeaWeight)
            form.change(`prices[2].chargeableAirWeight`, calcResults.chargeableAirWeight)
            form.change(`prices[2].fobProfit`, 0)
            form.change(`prices[2].seaProfit`, 0)
            form.change(`prices[2].airProfit`, 0)
          })
      }
    },
    [
      values.dutyRate,
      values.prices?.length > 2 ? values.prices[2]?.quantity : null,
      values.prices?.length > 2 ? values.prices[2]?.unitPrice : null,
      values.freightRateAir,
      values.freightRateSea,
      values.does_sea_freight_include_duties,
    ],
    ['DutyRate', 'Quantity', 'UnitPrice', 'FreightRateAir', 'FreightRateSea', 'SeaIncludesDuties'],
  )

  const calcFreightRatesRow = ({ index }) => {
    const debugMode = true
    debugMode && console.log('--------CLIENT QUOTE---------')
    debugMode && console.log(`------------${index}----------------`)
    debugMode && console.log('-----------------------------')

    const dimensions = values.default_packaging_option?.scm_quote_packaging_option
      ?.carton_dimensions
      ? values.default_packaging_option?.scm_quote_packaging_option?.carton_dimensions
      : factoryQuote.included_packaging_option.carton_dimensions

    const grossWeight = dimensions?.gross_weight / 100
    const cartonLength = dimensions?.length / 100
    const cartonWidth = dimensions?.width / 100
    const cartonHeight = dimensions?.height / 100
    const piecesPerCarton =
      values.pieces_per_carton || factoryQuote.included_packaging_option?.pieces_per_carton
    const freightRateAir = values.freightRateAir
    const freightRateSea = values.freightRateSea
    const dutyRate = values.dutyRate / 100

    debugMode && console.log('CQCALCVALS:', values)
    debugMode && console.log('FQCALCVALS:', factoryQuote)
    const price = values.prices[index]
    // const fobPrice = price.unitPrice
    const unitPrice = price.price ? price.price / 100 : price.unitPrice
    const quantity = price.quantity
    debugMode && console.log('PRICEROW', price)
    debugMode && console.log('QTY:', quantity)
    // debugMode && console.log('FOBPRICE:', fobPrice)
    debugMode && console.log('PPC', piecesPerCarton)
    debugMode && console.log('GW:', grossWeight)
    debugMode && console.log('CTNLTH:', cartonLength)
    debugMode && console.log('CTNWTH:', cartonWidth)
    debugMode && console.log('CTNHGT:', cartonHeight)
    debugMode && console.log('FREIGHTRATEAIR', freightRateAir)
    debugMode && console.log('FREIGHTRATESEA', freightRateSea)
    debugMode && console.log('DTYR:', dutyRate)
    debugMode && console.log('UNITPRICE', unitPrice)
    if (quantity && piecesPerCarton && grossWeight && values.dutyRate > -1) {
      const numCartons = quantity / piecesPerCarton
      debugMode && console.log('#Cartons:', numCartons)

      const dutiesPerPiece = dutyRate * unitPrice

      debugMode && console.log('DUTIESPP', dutiesPerPiece)

      const volumetricSeaWeight =
        parseInt(((cartonLength * cartonWidth * cartonHeight) / 6000).toFixed(2)) || null
      const chargeableSeaWeight =
        volumetricSeaWeight > grossWeight ? volumetricSeaWeight : grossWeight
      debugMode && console.log('ChargeableSea', chargeableSeaWeight)
      const seaFreightCost = Number((chargeableSeaWeight * freightRateSea).toFixed(2))
      const seaFreightCostPerPiece = Number((seaFreightCost / piecesPerCarton).toFixed(2))
      const seaFreightCostTotal = Number((seaFreightCost * numCartons).toFixed(2))

      const seaUnitCostTotal =
        unitPrice +
        seaFreightCostPerPiece +
        (values.does_sea_freight_include_duties ? 0 : dutiesPerPiece)

      const volumetricAirWeight =
        parseInt(((cartonLength * cartonWidth * cartonHeight) / 5000).toFixed(2)) || null
      const chargeableAirWeight =
        volumetricAirWeight > grossWeight ? volumetricAirWeight : grossWeight
      debugMode && console.log('ChargeableAir', chargeableAirWeight)
      const airFreightCost = Number((chargeableAirWeight * freightRateAir).toFixed(2))
      const airFreightCostPerPiece = Number(airFreightCost / piecesPerCarton.toFixed(2))
      const airFreightCostTotal = Number((airFreightCost * numCartons).toFixed(2))

      const airUnitCostTotal = unitPrice + airFreightCostPerPiece + dutiesPerPiece

      debugMode &&
        console.log(
          'seaCost',
          seaFreightCost,
          'seaPP',
          seaFreightCostPerPiece,
          'seaUnit',
          seaUnitCostTotal,
          'seaTotal',
          seaFreightCostTotal,
          'airCost',
          airFreightCost,
          'airPP',
          airFreightCostPerPiece,
          'airUnit',
          airUnitCostTotal,
          'airTotal',
          airFreightCostTotal,
          'FOBP',
          unitPrice,
          'DPP',
          dutiesPerPiece,
          // 'PROFITFOB',
          // fobProfit,
          // 'PROFITSEA',
          // seaProfit,
          // 'PROFITAIR',
          // airProfit,
        )

      return {
        unitPrice,
        seaFreightCostTotal,
        seaUnitCostTotal,
        seaFreightCostPerPiece,
        airFreightCostTotal,
        airUnitCostTotal,
        airFreightCostPerPiece,
        numCartons,
        totalDuties: dutiesPerPiece * quantity,
        shipmentWeight: grossWeight * quantity,
        totalCBM: Math.round(cartonLength * cartonLength * cartonLength),
        chargeableSeaWeight,
        chargeableAirWeight,
        // fobProfit,
        // seaProfit,
        // airProfit,
      }
    }
  }

  // console.log(values)
  return (
    <Fragment>
      <DialogTitle>Add Client Quote</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <span>Client Quote:</span>
          {/* <Button
            onClick={async () => {
              // console.log(values.id)
              await dataProvider.delete('scm_quote', { id: values.id })
              setCreateOpen(false)
              // refresh()
            }}
          >
            Delete Quote
          </Button> */}
        </Box>

        <Box>
          <Box display="flex" mt="20px">
            <NumberFormat
              className={classes.fieldInputLeftNarrow}
              displayType="input"
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              source="freightRateSea"
              prefix="$ "
              label="Freight Rate Sea"
            />
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <span style={{ fontSize: '9px' }}>Duties included?</span>
              <Checkbox
                checked={values.does_sea_freight_include_duties}
                onChange={() => {
                  form.change(
                    'does_sea_freight_include_duties',
                    !values.does_sea_freight_include_duties,
                  )
                }}
                inputProps={{ 'aria-label': 'duties included checkbox' }}
              />
            </Box>
            <NumberFormat
              className={classes.fieldInput}
              displayType="input"
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              source="freightRateAir"
              prefix="$ "
              label="Freight Rate Air"
            />
            <NumberFormat
              className={classes.fieldInputLeft}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              suffix=" %"
              isNumericString
              source="dutyRate"
              label="Duty Rate"
            />
          </Box>
        </Box>
        <div style={{ height: '20px' }} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  rowSpan={2}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  rowSpan={2}
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  colSpan={3}
                >
                  FACTORY
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '40%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  colSpan={5}
                >
                  CLIENT
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '7%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Quantity
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '7%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Unit Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '6%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Lead Time
                </StyledTableCell>
                <StyledTableCell style={{ width: '8%' }} />
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Unit Price/Qty
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Lead time
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Profit
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Visible
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <BodyTableCell align="right" style={{ padding: '0px' }} rowSpan={14}>
                  <TextInput
                    variant="standard"
                    fullWidth
                    style={{ margin: '0px', height: '100%' }}
                    source={`name`}
                    label={false}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }} rowSpan={14}>
                  <TextInput
                    variant="standard"
                    fullWidth
                    multiline
                    style={{ margin: '0px', height: '100%' }}
                    source={`notes`}
                    label={false}
                  />
                </BodyTableCell>
                <TableCell rowSpan={2} colSpan={3} style={{ borderRight: 'solid 1px #ddd' }} />
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Tooling
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    prefix="$ "
                    source={`setup_cost`}
                  />
                </BodyTableCell>
                <TableCell colSpan={3} />
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Sample
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    prefix="$ "
                    source={`sample_cost`}
                  />
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    isNumericString
                    source={`sample_lead_time`}
                  />
                </BodyTableCell>
                <TableCell colSpan={2} />
              </TableRow>

              <TableRow>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="text"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    isNumericString
                    noBottomBorder
                    style={{ height: 'unset' }}
                    source={`factoryQuote.prices[0].quantity`}
                  />
                </BodyTableCell>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    prefix="$ "
                    noBottomBorder
                    style={{ height: 'unset' }}
                    source={`factoryQuote.prices[0].unitPrice`}
                  />
                </BodyTableCell>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="text"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    isNumericString
                    noBottomBorder
                    style={{ height: 'unset' }}
                    source={`factoryQuote.prices[0].lead_time`}
                  />
                </BodyTableCell>

                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[0].quantity`}
                    />
                  )}
                </BodyTableCell>
                <TableCell colSpan={3} />
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  FOB
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[0].fobPrice`}
                      onChange={(e) => {
                        // console.log(values.prices[0])

                        const profit =
                          Number(e.target.value.substring(2)) - values.prices[0].fobPriceInternal
                        form.batch(() => {
                          form.change(
                            'prices[0].ddpSea',
                            profit + values.prices[0].seaPriceInternal,
                          )
                          form.change(
                            'prices[0].ddpAir',
                            profit + values.prices[0].airPriceInternal,
                          )
                          form.change('prices[0].fobProfit', profit)
                          form.change('prices[0].airProfit', profit)
                          form.change('prices[0].seaProfit', profit)
                        })
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[0].production_lead_time`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[0].fobProfit`}
                      noBottomBorder
                      style={
                        values.prices[0].fobProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[0].fobProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <Checkbox
                      checked={values.prices[0].fobPublished}
                      onChange={() => {
                        form.change('prices[0].fobPublished', !values.prices[0].fobPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  DDP Sea
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[0].ddpSea`}
                      onChange={(e) => {
                        // console.log(values.prices[0])
                        form.change(
                          'prices[0].seaProfit',
                          Number(e.target.value.substring(2)) - values.prices[0].seaPriceInternal,
                        )
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[0].lead_time_sea`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[0].seaProfit`}
                      noBottomBorder
                      style={
                        values.prices[0].seaProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[0].seaProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <Checkbox
                      checked={values.prices[0].seaPublished}
                      onChange={() => {
                        form.change('prices[0].seaPublished', !values.prices[0].seaPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  DDP Air
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[0].ddpAir`}
                      onChange={(e) => {
                        form.change(
                          'prices[0].airProfit',
                          Number(e.target.value.substring(2)) - values.prices[0].airPriceInternal,
                        )
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[0].lead_time_air`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[0].airProfit`}
                      noBottomBorder
                      style={
                        values.prices[0].airProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[0].airProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 0 && (
                    <Checkbox
                      checked={values.prices[0].airPublished}
                      onChange={() => {
                        form.change('prices[0].airPublished', !values.prices[0].airPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="text"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      noBottomBorder
                      style={{ height: 'unset' }}
                      source={`factoryQuote.prices[1].quantity`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      noBottomBorder
                      style={{ height: 'unset' }}
                      source={`factoryQuote.prices[1].unitPrice`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="text"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      noBottomBorder
                      style={{ height: 'unset' }}
                      source={`factoryQuote.prices[1].lead_time`}
                    />
                  )}
                </BodyTableCell>

                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[1].quantity`}
                    />
                  )}
                </BodyTableCell>
                <TableCell colSpan={3} />
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  FOB
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[1].fobPrice`}
                      onChange={(e) => {
                        const profit =
                          Number(e.target.value.substring(2)) - values.prices[1].fobPriceInternal
                        form.batch(() => {
                          form.change(
                            'prices[1].ddpSea',
                            profit + values.prices[1].seaPriceInternal,
                          )
                          form.change(
                            'prices[1].ddpAir',
                            profit + values.prices[1].airPriceInternal,
                          )
                          form.change('prices[1].fobProfit', profit)
                          form.change('prices[1].airProfit', profit)
                          form.change('prices[1].seaProfit', profit)
                        })
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[1].production_lead_time`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[1].fobProfit`}
                      noBottomBorder
                      style={
                        values.prices[1].fobProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[1].fobProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <Checkbox
                      checked={values.prices[1].fobPublished}
                      onChange={() => {
                        form.change('prices[1].fobPublished', !values.prices[1].fobPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  DDP Sea
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[1].ddpSea`}
                      onChange={(e) => {
                        form.change(
                          'prices[1].seaProfit',
                          Number(e.target.value.substring(2)) - values.prices[1].seaPriceInternal,
                        )
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[1].lead_time_sea`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[1].seaProfit`}
                      noBottomBorder
                      style={
                        values.prices[1].seaProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[1].seaProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <Checkbox
                      checked={values.prices[1].seaPublished}
                      onChange={() => {
                        form.change('prices[1].seaPublished', !values.prices[1].seaPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  DDP Air
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[1].ddpAir`}
                      onChange={(e) => {
                        form.change(
                          'prices[1].airProfit',
                          Number(e.target.value.substring(2)) - values.prices[1].airPriceInternal,
                        )
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[1].lead_time_air`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[1].airProfit`}
                      noBottomBorder
                      style={
                        values.prices[1].airProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[1].airProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 1 && (
                    <Checkbox
                      checked={values.prices[1].airPublished}
                      onChange={() => {
                        form.change('prices[1].airPublished', !values.prices[1].airPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="text"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    isNumericString
                    noBottomBorder
                    style={{ height: 'unset' }}
                    source={`factoryQuote.prices[2].quantity`}
                  />
                </BodyTableCell>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    prefix="$ "
                    noBottomBorder
                    style={{ height: 'unset' }}
                    source={`factoryQuote.prices[2].unitPrice`}
                  />
                </BodyTableCell>
                <BodyTableCell rowSpan={4} align="right" style={{ padding: '0px' }}>
                  <NumberFormat
                    className={classes.numberTableInput}
                    displayType="text"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    isNumericString
                    noBottomBorder
                    style={{ height: 'unset' }}
                    source={`factoryQuote.prices[2].lead_time`}
                  />
                </BodyTableCell>

                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[2].quantity`}
                    />
                  )}
                </BodyTableCell>
                <TableCell colSpan={3} />
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  FOB
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[2].fobPrice`}
                      onChange={(e) => {
                        const profit =
                          Number(e.target.value.substring(2)) - values.prices[2].fobPriceInternal
                        form.batch(() => {
                          form.change(
                            'prices[2].ddpSea',
                            profit + values.prices[2].seaPriceInternal,
                          )
                          form.change(
                            'prices[2].ddpAir',
                            profit + values.prices[2].airPriceInternal,
                          )
                          form.change('prices[2].fobProfit', profit)
                          form.change('prices[2].airProfit', profit)
                          form.change('prices[2].seaProfit', profit)
                        })
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[2].production_lead_time`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[2].fobProfit`}
                      noBottomBorder
                      style={
                        values.prices[2].fobProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[2].fobProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <Checkbox
                      checked={values.prices[2].fobPublished}
                      onChange={() => {
                        form.change('prices[2].fobPublished', !values.prices[2].fobPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  DDP Sea
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[2].ddpSea`}
                      onChange={(e) => {
                        form.change(
                          'prices[2].seaProfit',
                          Number(e.target.value.substring(2)) - values.prices[2].seaPriceInternal,
                        )
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[2].lead_time_sea`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[2].seaProfit`}
                      noBottomBorder
                      style={
                        values.prices[2].seaProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[2].seaProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <Checkbox
                      checked={values.prices[2].seaPublished}
                      onChange={() => {
                        form.change('prices[2].seaPublished', !values.prices[2].seaPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  DDP Air
                </StyledTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      prefix="$ "
                      source={`prices[2].ddpAir`}
                      onChange={(e) => {
                        form.change(
                          'prices[2].airProfit',
                          Number(e.target.value.substring(2)) - values.prices[2].airPriceInternal,
                        )
                      }}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      source={`prices[2].lead_time_air`}
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="right" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <NumberFormat
                      className={classes.numberTableInput}
                      displayType="input"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      isNumericString
                      disabled
                      prefix="$ "
                      source={`prices[2].airProfit`}
                      noBottomBorder
                      style={
                        values.prices[2].airProfit === 0
                          ? { color: 'blue', height: 'unset' }
                          : values.prices[2].airProfit < 0
                          ? { color: 'red', height: 'unset' }
                          : { color: 'green', height: 'unset' }
                      }
                    />
                  )}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: '0px' }}>
                  {values.prices?.length > 2 && (
                    <Checkbox
                      checked={values.prices[2].airPublished}
                      onChange={() => {
                        form.change('prices[2].airPublished', !values.prices[2].airPublished)
                      }}
                      inputProps={{ 'aria-label': 'duties included checkbox' }}
                    />
                  )}
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <SaveButton
          style={{ margin: '20px 0px' }}
          saving={formProps.saving}
          handleSubmitWithRedirect={async () => {
            await formProps.handleSubmitWithRedirect()
            setCreateOpen(false)
            // refresh()
          }}
        />
        <Button
          style={{ margin: '20px', border: 'solid 1px gray' }}
          onClick={() => {
            setCreateOpen(false)
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Fragment>
  )
}

export default ClientQuoteCreateDialog
