import React, { useState, useEffect } from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import HeaderSection from './components/OrderHeaderSection'
import POManagement from '../offers/shared/components/POManagement'
import OrderPurchases from './components/OrderPurchases'
import OrderEditForm from './components/OrderEditForm'
import { ApolloProvider } from '@apollo/client'
import NewClient from '../../dataProvider/hasura/buildApolloClient'
import useStyles from './styles/index'
import { Edit, useDataProvider, useNotify } from 'react-admin'
import { getDataLists } from './common/utils'
import { GSFeed } from '../components/GSFeed'

const OrderEdit = (props) => (
  <Edit {...props} actions={null} component="div" mutationMode="pessimistic">
    <Order />
  </Edit>
)

const Order = (props) => {
  // console.log('ORDEREDITPROPS', props)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [currentTab, setCurrentTab] = useState('one')
  const [status, setStatus] = useState(
    props.record?.order_products[0]?.offer_purchase?.offer?.status,
  )
  const [scaUser, setSCAUser] = useState(props.record?.client?.default_sca)
  const [dataLists, setDataLists] = useState()

  useEffect(() => {
    const abortController = new AbortController()
    !abortController.signal.aborted &&
      getDataLists(setDataLists, dataProvider, notify, abortController)
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  const localToken = localStorage.getItem('token')

  // console.log(localToken)
  const token = typeof localToken === 'string' ? localToken : localToken?.access_token
  const client = NewClient(token)

  return (
    <div>
      <Box m="10px 30px">
        <HeaderSection {...props} scaUser={scaUser} setSCAUser={setSCAUser} status={status} />
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          aria-label="orders"
          style={{ marginTop: '20px' }}
        >
          <Tab
            value="one"
            label="Orders Management"
            wrapped
            style={{ width: '250px' }}
            className={currentTab === 'one' ? classes.tabButton : null}
            // {...a11yProps('one')}
          />
          <Tab
            value="two"
            label="PO Management"
            style={{ width: '250px' }}
            disabled={
              !status || status === 'PENDING_SUBMISSION' || status === 'PENDING_PURCHASE_ORDER'
            }
            wrapped
            className={currentTab === 'two' ? classes.tabButton : null}
          />
          <Tab
            value="three"
            label="Accounting"
            style={{ width: '250px' }}
            disabled={
              !status || status === 'PENDING_SUBMISSION' || status === 'PENDING_PURCHASE_ORDER'
            }
            wrapped
            className={currentTab === 'three' ? classes.tabButton : null}
          />
          <Tab
            value="four"
            label="Activity Feed"
            style={{ width: '250px' }}
            wrapped
            className={currentTab === 'three' ? classes.tabButton : null}
          />
        </Tabs>
        <TabPanel value={currentTab} index="one">
          <OrderEditForm
            {...props}
            setStatus={setStatus}
            dataLists={dataLists}
            setDataLists={setDataLists}
            scaUser={scaUser}
          />
        </TabPanel>
        <TabPanel value={currentTab} index="two">
          {status !== 'PENDING_SUBMISSION' && status !== 'PENDING_PURCHASE_ORDER' && (
            <POManagement
              id={parseInt(props.record?.order_products[0]?.offer_purchase?.offer?.id)}
            />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index="three">
          {status !== 'PENDING_SUBMISSION' && status !== 'PENDING_PURCHASE_ORDER' && (
            <ApolloProvider client={client}>
              <OrderPurchases
                id={parseInt(props.record?.order_products[0]?.offer_purchase?.offer?.id)}
              />
            </ApolloProvider>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index="four">
          <GSFeed
            feedType="purchase_order"
            id={`${props.record.id}`}
            // sca={values.sca}
            data={{ order: props.record }}
          />
        </TabPanel>
      </Box>
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default OrderEdit
