import React, { useState } from 'react'
import {
  // Filter,
  List,
  // ReferenceInput,
  // SearchInput,
  FunctionField,
  // SelectInput,
  Datagrid,
  TextField,
  TopToolbar,
} from 'react-admin'
// import InfoIcon from '@material-ui/icons/InfoOutlined'
import { Tooltip, Button, Chip } from '@material-ui/core'
import SupplierDefaultImg from '../../../../assets/supplier_default.png'
import CreateDialog from '../../components/CreateDialog'

// export const ProductFilter = (props) => (
//   <Filter {...props}>
//     <SearchInput
//       // label="Name/Sku"
//       source="name"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       placeholder="Product Name"
//       style={{ minWidth: '200px' }}
//     />
//     {/* <ReferenceInput
//       source="supplier"
//       reference="suppliers"
//       // sort={{ field: 'id', order: 'ASC' }}
//       alwaysOn
//       style={{ minWidth: '200px' }}
//       variant="standard"
//     >
//       <SelectInput optionText="name" fullWidth />
//     </ReferenceInput> */}
//     {/* <ReferenceInput
//       source="sca"
//       reference="users"
//       label="SCA"
//       // sort={{ field: 'id', order: 'ASC' }}
//       filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
//       style={{ minWidth: '200px' }}
//       alwaysOn
//       variant="standard"
//     >
//       <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
//     </ReferenceInput> */}
//     <ReferenceInput
//       source="category"
//       reference="category"
//       // sort={{ field: 'id', order: 'ASC' }}
//       style={{ minWidth: '200px' }}
//       alwaysOn
//       variant="standard"
//     >
//       <SelectInput optionText="name" fullWidth />
//     </ReferenceInput>
//     {/* <DateInput
//       source="date_gte"
//       label="Quote date after"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       style={{ minWidth: '120px' }}
//     />
//     <DateInput
//       source="date_lte"
//       label="Quote date before"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       style={{ minWidth: '120px' }}
//     /> */}
//   </Filter>
// )

const ListActions = (props) => {
  const { className, prodData } = props
  const [createOpen, setCreateOpen] = useState(false)

  const { name, description } = prodData

  const errors = []

  !name && errors.push('Missing name')
  !description && errors.push('Missing description')

  return (
    <TopToolbar className={className}>
      {errors?.length > 0 ? (
        <Tooltip title={errors.join()}>
          <div>
            <Button
              style={{
                color: 'dark-gray',
                border: 'solid 1px rgba(180, 125, 80, 0.5)',
                backgroundColor: 'light-gray',
              }}
              disabled
            >
              Create
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          style={{
            color: 'black',
            border: 'solid 1px rgba(180, 125, 80, 0.5)',
            backgroundColor: 'rgba(255, 149, 102, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(255, 149, 102, 0.3)',
            },
          }}
          onClick={async () => {
            setCreateOpen(true)
          }}
        >
          Create
        </Button>
      )}

      <CreateDialog createOpen={createOpen} setCreateOpen={setCreateOpen} prodData={prodData} />
    </TopToolbar>
  )
}

const SaleableProductVariantsList = (props) => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const redirect = useRedirect()

  // const rowStyle = (record) => ({
  //   backgroundColor: record?.is_catalogue ? '#ddd' : 'white',
  //   cursor: record?.is_catalogue ? 'default' : 'pointer',
  // })

  console.log(props)
  const { saleableProductId, showActions, basePath, ...rest } = props

  // console.log(prodData)

  return (
    <List
      {...rest}
      basePath={basePath}
      filter={{
        ...(saleableProductId ? { saleable_product_id: saleableProductId } : null),
      }}
      // filters={<ProductFilter />}
      actions={showActions ? <ListActions /> : null}
      // actions={null}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
      // bulkActionButtons
    >
      <Datagrid
        path="saleableProductVariants"
        // rowClick="edit"
        // rowStyle={rowStyle}
      >
        <TextField source="id" label="Id" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ width: '50px', height: '50px' }}>
              <img
                style={{ maxHeight: '50px' }}
                src={record.image_file?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <TextField source="sku" label="SKU" />
        <TextField source="stock" label="Stock" />
        <FunctionField
          label="Options"
          render={(record) => {
            return <span> {record.variant?.values?.map((el) => el.value).join()}</span>
          }}
        />
        <FunctionField
          label="Always in Stock"
          render={(record) => {
            return <span> {record.always_in_stock ? 'Yes' : 'No'}</span>
          }}
        />
        <FunctionField
          render={(record) => <Chip label={record.active ? 'PUBLISHED' : 'INACTIVE'} />}
          label="Status"
        />
      </Datagrid>
    </List>
  )
}

export default SaleableProductVariantsList
