import React from 'react'
import { FormWithRedirect, Create, useNotify, useDataProvider, TextInput } from 'react-admin'
import { Card, CardContent, Box } from '@material-ui/core'
import CustomToolbar from '../../components/CustomToolbar'
import SectionTitle from '../../components/SectionTitle'

const ClientForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSubmit = async (record) => {
    const params = {
      client_name: record.client_name,
    }

    try {
      const newClient = await dataProvider.create('organisation', {
        data: params,
      })

      notify(`Client Created with ID: ${newClient.data?.id}`, { type: 'info' })
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <CardContent>
            <SectionTitle label="Create Client" />
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} ml={{ xs: 0, sm: '0em' }}>
                <TextInput
                  variant="outlined"
                  source="client_name"
                  label="Client Company Name"
                  fullWidth
                />
              </Box>
            </Box>
          </CardContent>
          <CustomToolbar
            redirect={'/clients'}
            record={formProps.record}
            basePath={formProps.basePath}
            undoable
            invalid={formProps.invalid}
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            resource="organisation"
          />
        </Card>
      )}
    />
  )
}

const ClientCreate = (props) => (
  <Create {...props}>
    <ClientForm />
  </Create>
)

export default ClientCreate
