import React from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from '../styles'
import ProductList from '../../../../scmQuote/SCMQuoteList'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Products = (props) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <span className={classes.sectionHeader}>Products</span>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {/* <Tab style={{ textTransform: 'none' }} label="All Quotes" {...a11yProps(0)} /> */}
          {/* <Tab style={{ textTransform: "none" }} label="In Development" {...a11yProps(1)} /> */}
          <Tab style={{ textTransform: 'none' }} label="Active" {...a11yProps(2)} />
          <Tab style={{ textTransform: 'none' }} label="Rejected" {...a11yProps(3)} />
        </Tabs>
      </Box>
      {/* <TabPanel value={value} index={0}>
        <CatalogItemList
          location={props.location}
          match={props.match}
          history={props.history}
          permissions={props.permissions}
          basePath="/supplier"
          resource="supplierCatalogItem"
          hasCreate={false}
          hasEdit={false}
          hasList
          hasShow={false}
          syncWithLocation
          filter={{ supplierID: props.supplierID }}
          // filter={{ userID: "rejected" }}
        />
      </TabPanel> */}
      {/* <TabPanel value={value} index={1}>
        <ProjectList
          location={props.location}
          match={props.match}
          history={props.history}
          permissions={props.permissions}
          basePath="/supplier"
          resource="projectDashboard"
          hasCreate={false}
          hasEdit={false}
          hasList
          hasShow={false}
          syncWithLocation
          filter={{ supplierID: props.supplierID }}
        />
      </TabPanel> */}
      <TabPanel value={value} index={2}>
        <ProductList
          location={props.location}
          match={props.match}
          history={props.history}
          permissions={props.permissions}
          basePath="/supplier"
          resource="products"
          hasCreate={false}
          hasEdit={false}
          hasList
          hasShow={false}
          syncWithLocation
          filter={{ status: 'active' }}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProductList
          location={props.location}
          match={props.match}
          history={props.history}
          permissions={props.permissions}
          basePath="/supplier"
          resource="products"
          hasCreate={false}
          hasEdit={false}
          hasList
          hasShow={false}
          syncWithLocation
          filter={{ status: 'rejected' }}
        />
      </TabPanel>
    </Box>
  )
}

//   return (
//     <Box>
//       <div className='TableTabs'>
//         <Tabs
//           defaultActiveKey='1'
//           // onTabClick={(key, e) => {
//           //   changeTab(key, e)
//           // }}
//         >

//               <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//           <Tab tab='All Quotes' key='aqTab'>
//           <Tab label="Item One" {...a11yProps(0)} />
//           <Tab label="Item Two" {...a11yProps(1)} />
//           <Tab label="Item Three" {...a11yProps(2)} />
//         </Tabs>
//             <CatalogItemList
//               location={props.location}
//               match={props.match}
//               history={props.history}
//               permissions={props.permissions}
//               basePath='/'
//               resource='supplierCatalogItem'
//               hasCreate={false}
//               hasEdit={false}
//               hasList
//               hasShow={false}
//               syncWithLocation
//               // filter={{ userID: "rejected" }}
//             />
//           </Tab>
//           <Tab tab='In Development' key='idTab'>
//             <ProjectList
//               location={props.location}
//               match={props.match}
//               history={props.history}
//               permissions={props.permissions}
//               basePath='/'
//               resource='projectDashboard'
//               hasCreate={false}
//               hasEdit={false}
//               hasList
//               hasShow={false}
//               syncWithLocation
//             />
//           </Tab>
//           <Tab tab='Active' key='acTab'>
//             <ProductList
//               location={props.location}
//               match={props.match}
//               history={props.history}
//               permissions={props.permissions}
//               basePath='/'
//               resource='products'
//               hasCreate={false}
//               hasEdit={false}
//               hasList
//               hasShow={false}
//               syncWithLocation
//               filter={{ status: "active" }}
//             />
//           </Tab>
//           <Tab tab='Rejected' key='rjTab'>
//             <ProductList
//               location={props.location}
//               match={props.match}
//               history={props.history}
//               permissions={props.permissions}
//               basePath='/'
//               resource='products'
//               hasCreate={false}
//               hasEdit={false}
//               hasList
//               hasShow={false}
//               syncWithLocation
//               filter={{ status: "rejected" }}
//             />
//           </Tab>
//         </Tabs>
//       </div>
//     </Box>
//   )
// }

export default Products
