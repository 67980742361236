import React from 'react'
import {
  Filter,
  List,
  // useTranslate,
  DateField,
  TextField,
  FunctionField,
  // TextInput,
  SearchInput,
  // DateInput,
  Datagrid,
} from 'react-admin'
import { Box, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import NotificationsDatagrid from '../../component/NotificationsDatagrid'
import { getDisplayName } from '../../../utils/utils'

const useStyles = makeStyles({
  searchBackground: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
})

export const SupplierFilter = (props) => {
  const classes = useStyles()

  return (
    <Filter {...props}>
      <SearchInput
        InputProps={{ placeholder: 'Supplier Name' }}
        source="name"
        alwaysOn
        className={classes.searchBackground}
        variant="standard"
      />
    </Filter>
  )
}

const SuppliersList = (props) => {
  const user = JSON.parse(localStorage.getItem('user'))

  const getFilter = () => {
    if (props.filter?.role === 'scm' || props.filter?.role === 'management') {
      // eslint-disable-next-line camelcase
      return { status: null, sca_user_id: null }
    }
    if (props.filter?.role === 'quality') {
      // eslint-disable-next-line camelcase
      return { status: 'unverified', sca_user_id: null }
    }

    return {
      // eslint-disable-next-line camelcase
      sca_user_id: user.id,
      status: props.filter?.status ? props.filter?.status : ['active', 'unverified'],
    }
  }

  if (props.filter?.role === 'quality') {
    return (
      <Box padding="0 20%">
        <List
          {...props}
          filter={getFilter()}
          filters={props.basePath === '/suppliers' ? <SupplierFilter /> : null}
          actions={null}
          perPage={25}
          sort={{ field: 'id', order: 'DESC' }}
          empty={false}
          bulkActionButtons={false}
        >
          <Datagrid isQuality path="suppliers">
            <TextField source="id" />
            <TextField source="status" />
            <DateField source="created_at" label="Date Added" />
            <DateField source="updated_at" label="Last Updated" />
          </Datagrid>
        </List>
      </Box>
    )
  }

  if (props.filter?.role === 'scm') {
    return (
      <List
        {...props}
        filter={getFilter()}
        filters={props.basePath === '/suppliers' ? <SupplierFilter /> : null}
        actions={null}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid path="suppliers">
          <TextField source="id" />
          <FunctionField
            label="Supplier Name"
            render={(record) => {
              const name = record.name
              return name?.length > 10 ? (
                <Tooltip title={name}>
                  <span>{`${name?.substring(0, 10)}...`}</span>
                </Tooltip>
              ) : (
                name
              )
            }}
          />
          {/* <TextField source='sca_user.email' label='SCA' /> */}
          <FunctionField
            label="SCA"
            source="sca_user.email"
            render={(record) => {
              const sca = record.sca_user
              return sca ? getDisplayName(sca, 20, 20) : null
            }}
          />
          <TextField source="status" />
          {/* <TextField source="newQuote" /> */}
          <TextField source="categories[0].category.name" label="Category" />
          <TextField source="quotes_aggregate.agggregate.count" label="Quotes" />
          <TextField source="projects_aggregate.aggregate.count" label="Products" />
          <TextField source="orders_aggregate.aggregate.count" label="Orders" />
          <DateField source="created_at" label="Date Added" />
          <DateField source="updated_at" label="Last Updated" />
          <TextField source="notifications" label="" />
        </Datagrid>
      </List>
    )
  }
  return (
    <List
      {...props}
      filter={getFilter()}
      filters={props.basePath === '/suppliers' ? <SupplierFilter /> : null}
      actions={null}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
      bulkActionButtons={false}
    >
      <Datagrid path="suppliers" rowClick="show">
        <TextField source="id" />
        <FunctionField
          label="Supplier Name"
          render={(record) => {
            const name = record.name
            return name?.length > 40 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 40)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        {/* <TextField source='name' /> */}
        <TextField source="status" />
        {/* <TextField source="newQuote" /> */}
        {/* <TextField source="categories[0].category.name" label="Category" /> */}
        <TextField source="quotes.aggregate.count" label="Quotes" />
        <TextField source="activeOrders.aggregate.count" label="Active Orders" />
        <FunctionField
          label="SCA"
          source="sca_user.email"
          render={(record) => {
            const sca = record.sca_user
            return sca ? getDisplayName(sca, 20, 20) : null
          }}
        />
        <DateField source="created_at" label="Date Added" />
        <DateField source="updated_at" label="Last Updated" />
        <TextField source="notifications" label="" />
      </Datagrid>
    </List>
  )
}

export default SuppliersList
