import React, { useEffect, useState } from 'react'
import {
  AppBar,
  // useDataProvider,
  useNotify,
  useTranslate,
  useLogout,
  useGetIdentity,
} from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { Menu, Button, Box } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useDispatch } from 'react-redux'
import { CHANGE_USERTOKEN } from '../reducers/actions'
import GSNotifications from '../pages/components/GSNotifications'
import { getDisplayName } from '../utils/utils'
import TalkContainer from './TalkContainer'

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
})

const CustomAppBar = (props) => {
  const classes = useStyles()
  const { identity } = useGetIdentity()

  return (
    <AppBar
      {...props}
      position="relative"
      userMenu={false}
      style={{ backgroundColor: '#FF956680' }}
    >
      <div className={classes.grow} />

      {identity && <Notifications user={identity} />}
      {identity && <TalkContainer identity={identity} />}
      <Account />
    </AppBar>
  )
}

const Notifications = ({ user }) => {
  useEffect(() => {
    return () => {}
  }, [])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <span>{getDisplayName(user)}</span>
      <GSNotifications identity={user} />
    </Box>
  )
}

const Account = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const dispatch = useDispatch()
  const logout = useLogout()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        style={{ top: 25 }}
        onClose={handleClose}
      >
        <Button
          className={classes.row}
          style={{ width: 110, height: 50, alignItems: 'center', justifyContent: 'center' }}
          onClick={() => {
            notify('Feature disabled', { type: 'info' })
          }}
        >
          <SettingsIcon style={{ color: '#000' }} />

          <span
            style={{
              fontSize: 14,
              color: '#000',
              marginLeft: 10,
            }}
          >
            {translate('pos.Settings')}
          </span>
        </Button>
        <Button
          className={classes.row}
          onClick={() => {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('admin')
            dispatch({ type: CHANGE_USERTOKEN, payload: '' })
            logout()
          }}
          style={{ width: 110, height: 50, alignItems: 'center', justifyContent: 'center' }}
        >
          <ExitToAppIcon style={{ color: '#000' }} />

          <span
            style={{
              fontSize: 14,
              color: '#000',
              marginLeft: 10,
            }}
          >
            {translate('pos.Logout')}
          </span>
        </Button>
      </Menu>
    </div>
  )
}

export default CustomAppBar
