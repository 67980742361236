import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const thirdPartiesStripeLogisticsProviders = ({ context }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getStripeLogisticsProviders {
        international_logistics_provider(
          where: { stripe_connect_account_id: { _is_null: false } }
        ) {
          id
          name
          stripe_connect_account_id
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.international_logistics_provider,
      errors,
    }),
  }
}

export const thirdPartiesStripeQualityAgencyList = ({ context }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getStripeQualityAgencies {
        quality_agency(where: { stripe_connect_account_id: { _is_null: false } }) {
          id
          name
          stripe_connect_account_id
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.quality_agency,
      errors,
    }),
  }
}

export const thirdPartiesStripeSupplyChainAgenciesList = ({ context }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getStripeSupplyChainAgencies {
        supply_chain_management_company(where: { stripe_connect_account_id: { _is_null: false } }) {
          id
          name
          stripe_connect_account_id
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.supply_chain_management_company,
      errors,
    }),
  }
}

export const thirdPartiesQualityAgencyList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getQualityAgencies {
        quality_agency {
          id
          name
          contact {
            id
            email
            last_name
            first_name
            mobile
            position
          }
          address {
            id
            address_line1
            address_line2
            city
            province
            post_code
            country_code
          }
          bank_detail {
            account_number
            beneficiary_name
            branch_address
            branch_name
            clearing_code
            created_at
            currency
            id
            swift_code
            tax_number
            updated_at
            beneficiary_address {
              id
              address_line1
              address_line2
              city
              country_code
              post_code
              local_full_address
              province
              type
              shipping_port {
                id
                name
                port_code
                type
              }
            }
            bank {
              id
              name
              region
              abbr
              swift_code
              clearing_code
            }
          }
        }
        quality_agency_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.quality_agency,
      total: data.quality_agency_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const thirdPartiesFreightForwarderList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getFreightProviders {
        international_logistics_provider {
          id
          name
          contact {
            id
            email
            last_name
            first_name
            mobile
            position
          }
          address {
            id
            address_line1
            address_line2
            city
            province
            post_code
            country_code
          }
          bank_detail {
            account_number
            beneficiary_name
            branch_address
            branch_name
            clearing_code
            created_at
            currency
            id
            swift_code
            tax_number
            updated_at
            beneficiary_address {
              id
              address_line1
              address_line2
              city
              country_code
              post_code
              local_full_address
              province
              type
              shipping_port {
                id
                name
                port_code
                type
              }
            }
            bank {
              id
              name
              region
              abbr
              swift_code
              clearing_code
            }
          }
        }
        international_logistics_provider_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.international_logistics_provider,
      total: data.international_logistics_provider_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}
