import React from 'react'
import { Button, makeStyles, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/Add'
import SimpleFormIterator from './form/SimpleFormIterator'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles({
  addButton: {
    height: '100%',
    display: 'flex',
    backgroundColor: '#EFF1F4',
    border: '1px dashed #D9D9D9',
    minWidth: '480px',
    minHeight: '390px',
  },
  deleteBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  addIcon: {
    fontSize: '120px',
    color: '#BDBDBD',
  },
})
export const RemoveButton = props => {
  const classes = useStyles(props)
  return (
    <IconButton
      aria-label="delete"
      className={classes.deleteBtn}
      style={{ position: 'absolute', top: '-10px', right: '-25px' }}
      {...props}
    >
      <CloseIcon className={classes.leftIcon}/>
    </IconButton>
  )
}

export const AddButton = props => {
  const classes = useStyles(props)
  return (
    <Button size="small" {...props} className={classes.addButton}>
      <AddIcon className={classes.addIcon} />
    </Button>
  )
}

const CustomFormIterator = withStyles(() => ({
  root: {
    display: 'flex',
  },
  line: {},
}))(SimpleFormIterator)

export default CustomFormIterator
