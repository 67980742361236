import { Auth0Client } from '@auth0/auth0-spa-js'
import logdna from '@logdna/browser'

const auth0 = new Auth0Client({
  domain:
    process.env.NODE_ENV === 'development'
      ? // ? 'scmsos.us.auth0.com' // prod
        'dev-scmsos.us.auth0.com' // dev
      : process.env.REACT_APP_AUTH_HOST,
  client_id:
    process.env.NODE_ENV === 'development'
      ? // ? '2gJ9vD2OKaLApEpeoU39biC6CWKin88r' // prod
        'lVaY28y3SC8kRBNYrvtE6VbnNAfONF3b' // dev
      : process.env.REACT_APP_AUTH_CLIENT,
  redirect_uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/#/login' // dev
      : // ? 'https://admin.benib2b.com/#/login' // prod
        process.env.REACT_APP_REDIRECT_URL,

  cacheLocation: 'localstorage',
  useRefreshTokens: true,
})

export default {
  login: async (url) => {
    // console.log(typeof url, url)
    if (typeof url === 'undefined' || url === 'undefined') {
      await auth0.loginWithRedirect({ invitation_code: 'ACODEHERE' })
      // throw new Error(' ')

      return await Promise.resolve()
    } else {
      await auth0.handleRedirectCallback(url.location)
      const tempurl = new URL(window.location.href)
      const { searchParams } = tempurl
      const code = searchParams?.get('code')
      const state = searchParams?.get('state')
      if (code && state) {
        window.history.replaceState({}, document.title, window.location.pathname)
      }
    }
    const token = await auth0.getTokenSilently().catch((error) => {
      console.log('ERR', error)
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      throw new Error('Login Error')
    })
    const user = await auth0.getUser()
    localStorage.setItem('token', token?.access_token || token)
    localStorage.setItem('user', JSON.stringify(user))

    return await Promise.resolve()
  },
  logout: async () => {
    await auth0.isAuthenticated().then(async function () {
      // if (isAuthenticated) {
      return await auth0.logout({
        returnTo:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000/#/login'
            : process.env.REACT_APP_REDIRECT_URL,
        federated: true, // have to be enabled to invalidate refresh token
      })
      // }
      // return await Promise.resolve()
    })
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    return Promise.resolve({ redirectTo: false })
  },
  checkError: async (error) => {
    const { status } = error
    // console.log('checking auth error...', error)
    if (status === 401 || status === 403) {
      return Promise.reject({ message: false })
    } else if (error.message === 'Could not verify JWT: JWTExpired') {
      try {
        // console.log('checking token silently...')
        const token = await auth0.getTokenSilently()
        localStorage.setItem('token', token?.access_token || token)
        // console.log('got silent token...')
        return Promise.resolve()
      } catch (error) {
        // console.log('error', error)
        if (error.error !== 'login_required') {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          return Promise.reject({ message: false })
        }
      }
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      return Promise.reject({ message: false })
    }
  },
  checkAuth: async () => {
    const isAuthenticated = await auth0.isAuthenticated()
    // console.log('checking auth...isAuth?', isAuthenticated)
    if (isAuthenticated) {
      const token = await auth0.getTokenSilently()
      const user = await auth0.getUser()
      // const claims = await auth0.getIdTokenClaims()
      // console.log('CHECKAUTHTOKEN', token)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', token?.access_token || token)

      logdna.addContext({
        user,
      })

      return Promise.resolve()
    }

    try {
      // console.log('getting...')
      const token = await auth0.getTokenSilently({
        authorizationParams: {
          redirect_uri:
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:3000/#/login' // dev
              : // ? 'https://admin.benib2b.com/#/login' // prod
                process.env.REACT_APP_REDIRECT_URL,
        },
        detailedResponse: true,
        timeoutInSeconds: 10,
      })
      // console.log('CHECKAUTHGOTTOKEN', token)
      // console.log('CHECKAUTHGOTTOKEN', token?.access_token)
      const user = await auth0.getUser()
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', token?.access_token || token)
      return Promise.resolve()
    } catch (error) {
      error?.message !== 'Login required' && console.log(error)
    }

    return Promise.reject({ message: false })
  },
  getPermissions: () => {
    return Promise.resolve()
  },
  getIdentity: () => {
    const user = JSON.parse(localStorage.getItem('user'))

    const identity = {
      userId: user['https://hasura.io/jwt/claims']['x-hasura-user-id'],
      isManager: user['https://hasura.io/jwt/claims']['x-hasura-default-role'] === 'sca-manager',
      roles: user['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
      gsToken: user['https://hasura.io/jwt/claims']['gs_token'],
      talkToken: user['https://hasura.io/jwt/claims']['talkjs_code'],
      code: user['https://hasura.io/jwt/claims']['code'],
      name: user['https://hasura.io/jwt/claims']['name'] || user.nickname || user.name,
    }

    delete user['https://hasura.io/jwt/claims']

    return {
      ...user,
      ...identity,
    }
  },
  getToken: async () => {
    const token = await auth0.getTokenSilently().catch(() => {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      throw new Error('Login Required')
    })
    const user = await auth0.getUser()
    localStorage.setItem('token', token?.access_token || token)
    localStorage.setItem('user', JSON.stringify(user))

    return token
  },
}
