import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const scmQuotePackagingOptionsList = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query scmQuotePkgOptsList {
        scm_quote_product_size_packagings(
          order_by: [
            { scm_quote_packaging_option: { type: asc_nulls_last } }
            { scm_quote_product_size: { sequence: asc_nulls_last } }
          ]
        ) {
          id
          scm_quote_product_size {
            id
            name
            short_name
            sequence
          }
          scm_quote_packaging_option {
            id
            air_lcl
            air_modifier
            sea_buffer
            air_buffer
            carton_dimensions {
              id
              height
              length
              gross_weight
              width
            }
            label
            pieces_per_carton
            sea_lcl
            sea_modifier
            type
            scm_quote {
              id
              does_sea_freight_include_duties
              duty_rate
              freight_rate_air
              freight_rate_sea
              container_rate_20ft
              container_rate_40ft
              container_rate_40ft_hc
              default_packaging_option_id
              default_packaging_option {
                id
                scm_quote_packaging_option {
                  id
                  moq
                  price_modifier
                  pieces_per_carton
                  sea_lcl
                  air_lcl
                  sea_modifier
                  air_modifier
                  sea_buffer
                  air_buffer
                  product_quote_packaging_option_id
                  packaging_dimensions {
                    length
                    width
                    height
                    gross_weight
                  }
                  carton_dimensions {
                    length
                    width
                    height
                    gross_weight
                  }
                }
                scm_quote_product_size {
                  id
                  moq
                  price_modifier
                  product_quote_product_size_id
                  dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                }
              }
              product_quote {
                id
                product {
                  id
                  name
                }
              }
            }
          }
        }
        scm_quote_product_size_packagings_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.scm_quote_product_size_packagings,
      total: data.scm_quote_product_size_packagings_aggregate.aggregate.count,
    }),
  }
}

export const updateManyCQSizes = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation updateManyCQSizes {
        update_scm_quote_product_sizes_many(updates: [${jsonParams}]) {
          returning {
            dimensions_id
            id
            moq
            name
            price_modifier
            product_quote_product_size_id
            scm_quote_id
            short_name
            __typename
          }
          __typename
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_scm_quote_product_sizes_many,
    }),
  }
}

export const updateManyCQPkgs = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation updateManyCQPkgs {
        update_scm_quote_packaging_options_many(updates: [${jsonParams}]) {
          returning {
            packaging_dimensions_id
            carton_dimensions_id
            pieces_per_carton
            id
            moq
            type
            label
            price_modifier
            scm_quote_id
            air_lcl
            sea_lcl
            air_modifier
            sea_modifier
            sea_buffer
            air_buffer
            __typename
          }
          __typename
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_scm_quote_packaging_options_many,
    }),
  }
}

export const paymentMilestonesList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getPaymentMilestones {
        payment_milestone {
          id
          name
          type
        }
        payment_milestone_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.payment_milestone,
      total: data.payment_milestone_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const scmQuoteOptionsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation scmQuoteOptionsCreateMany {
              insert_scm_quote_options(
                ${jsonParams}
              ) {
                returning {
                  id
                  name
                  scm_quote_id
                  max_unique_selections
                  additional_cost_per_unique_choice
                  product_quote_option_id
                  values {
                    id
                    moq
                    value
                    setup
                    price
                    product_quote_option_value_id
                    component_options {
                      id
                      component {
                        id
                        name
                        product_quote_component_id
                      }
                    }
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      // console.log(data)
      if (data && data.insert_scm_quote_options?.returning) {
        return {
          data: data.insert_scm_quote_options.returning,
        }
      }
      return false
    },
  }
}
