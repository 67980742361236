import React from 'react'
import {
  Box,
  // Button,
  Typography,
} from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { FormWithRedirect, Edit } from 'react-admin'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import GeneralDetails from './GeneralDetails'
import PackagingDetails from './PackagingDetails'
import PricingDetails from './PricingDetails'
import ProductOrganisation from './ProductOrganisation'
import VariantsDetails from './VariantsDetails'
import { makeStyles } from '@material-ui/core/styles'
import { GetCalculatedIncomeFields } from '../../offers/offer/components/CostsAndPricing'
import { generateProductVariants, roundNumber } from '../../../utils/utils'

const useStyles = makeStyles(() => ({
  outerBox: {
    padding: '20px 40px',
    // backgroundColor: 'white',
  },
  heading: {
    fontSize: '15px',
    fontWeight: '600',
  },
  centerBox: {
    padding: '0px 10px 12px 20px !important',
    width: '-webkit-fill-available',
  },
  rightBox: {
    padding: '0px 20px 12px 10px !important',
    width: '-webkit-fill-available',
  },
  contentBox: {
    backgroundColor: 'white',
    padding: '20px 20px !important',
    borderRadius: '8px',
    border: 'solid 1px #CDCDCD',
  },
  spacer20: {
    height: '20px',
  },
  //   leftNavItem: {
  //     color: 'rgba(255, 149, 102, 0.5)',
  //     marginTop: '5px',
  //     cursor: 'pointer',
  //   },
}))

const SaleableProductEdit = (props) => {
  // console.log(props)
  const { categories, packagingTypes, materials, config, checkFormValid, ...rest } = props
  return (
    <Edit {...rest} actions={null} component="div" id={props.id} mutationMode="pessimistic">
      <FormData
        id={props.id}
        {...props}
        categories={categories}
        packagingTypes={packagingTypes}
        materials={materials}
        config={config}
        checkFormValid={checkFormValid}
      />
    </Edit>
  )
}

const FormData = (props) => {
  const { packagingTypes = [], config = [], record, checkFormValid } = props

  // console.log('RECORD', config)

  const chosenCategories = record?.categories
    ?.filter((item) => !item.deleted_at)
    .map((item) => ({ ...item.category, category_id: item.category?.id, id: item.category?.id }))

  // console.log('CHOSENCATS', chosenCategories)

  const chosenTags = record?.tags?.map((item) => item.tag.id)

  const platforms = [
    { id: 'CLIQUESTOCK', value: 'CLIQUESTOCK', name: 'Clique Stock' },
    { id: 'SOLIDBLANKS', value: 'SOLIDBLANKS', name: 'Solid Blanks' },
  ]

  const chosenPlatforms = record?.platforms?.map((item) => item.platform)

  // PRESET CALCULATED FIELDS
  const unitCost = record.unit_cost
    ? record.unit_cost / 100
    : record.product_quote?.prices?.length > 0
    ? record.product_quote.prices[0].unit_price / 100
    : 0

  const fobPrice = record.fob_price / 100

  const singleCartonLength = record.single_carton_dimensions?.length / 100 || null
  const singleCartonWidth = record.single_carton_dimensions?.width / 100 || null
  const singleCartonHeight = record.single_carton_dimensions?.height / 100 || null
  const singleCartonWeight = record.single_carton_dimensions?.gross_weight / 100 || null

  const dutyRate = record.duty_rate / 100

  const dropshipRate = config.estimated_freight_rate_dropship / 100
  const airRate = config.estimated_freight_rate_air / 100

  const retailPrice = record.retail_price / 100
  const memberPrice = record.member_price / 100

  // console.log(
  //   'FOB_PRICE',
  //   fobPrice,
  //   'RETAIL_PRICE',
  //   retailPrice,
  //   'DTY_RT',
  //   dutyRate,
  //   'S_CRTN_LNGTH',
  //   singleCartonLength,
  //   'S_CRTN_HGHT',
  //   singleCartonHeight,
  //   'S_CRTN_WDTH',
  //   singleCartonWidth,
  //   'S_CRTN_WGHT',
  //   singleCartonWeight,
  //   'AIR_RT',
  //   dropshipRate,
  //   'UNITCOST',
  //   unitCost,
  // )

  const fobMargin = fobPrice > 0 ? ((fobPrice - unitCost) / fobPrice) * 100 : 0

  const airWeight =
    parseInt(
      (((singleCartonLength * singleCartonWidth * singleCartonHeight) / 5000) * 100).toFixed(2),
    ) || null

  const chargeableAirWeight =
    airWeight / 100 > singleCartonWeight ? airWeight / 100 : singleCartonWeight

  const estLandedCostDropship = chargeableAirWeight * dropshipRate

  const estLandedCostAir = chargeableAirWeight * airRate

  const dutiesPerPiece = dutyRate === 0 ? 0 : Number(((dutyRate / 100) * unitCost).toFixed(2))

  const estLandedRateDropship = estLandedCostDropship + dutiesPerPiece
  const estLandedRateAir = estLandedCostAir + dutiesPerPiece

  const sellingFeesTotal =
    record.retail_price > 0
      ? GetCalculatedIncomeFields({
          commissionFees: record.commission_fees / 100,
          marketingFees: record.marketing_fees / 100,
          retailPrice: record.retail_price / 100,
          consumerPartnerFees: record.consumer_partner_fees / 100,
          consumerPaymentFees: record.consumer_payment_fees / 100,
          consumerServiceFees: record.consumer_service_fees / 100,
          consumerSoftwareFees: record.consumer_software_fees / 100,
          consumerRoyaltiesFees: record.consumer_royalties_fees / 100,
        })
      : 0

  const totalCost = estLandedRateDropship + fobPrice + sellingFeesTotal
  const estimatedProfit = roundNumber(retailPrice - totalCost, 2)
  // const estimatedMemberProfit = memberPrice - totalCost

  // console.log(
  //   'AIR_WEIGHT',
  //   airWeight,
  //   'CGW:',
  //   chargeableAirWeight,
  //   'DTYS',
  //   dutiesPerPiece,
  //   'ESTCOSTDROP',
  //   estLandedCostDropship,
  //   'WITHDUTIESDROP',
  //   estLandedRateDropship,
  //   'ESTCOSTAIR',
  //   estLandedCostAir,
  //   'WITHDUTIESAIR',
  //   estLandedRateAir,
  //   'SELLFEES',
  //   sellingFeesTotal,
  //   'ESTPROFIT',
  //   estimatedProfit,
  // )

  // console.log(record.specifications)

  const otherSpecifications = []

  const uniqueSpecs = []
  if (record?.specifications?.length > 0) {
    record.specifications.map((spec) => {
      if (uniqueSpecs.indexOf(spec.specification.option.name) === -1) {
        uniqueSpecs.push(spec.specification.option.name)
      }
    })
  }

  // console.log(uniqueSpecs)

  uniqueSpecs.map((spec) => {
    // console.log(spec, otherSpecifications)
    otherSpecifications?.push({
      name: spec,
      specification: {
        value:
          record?.specifications?.length > 0
            ? record.specifications
                .filter((qspec) => qspec.specification.option?.name === spec)
                .map((item) => item.specification?.value)
            : [],
      },
    })
  })
  // console.log(otherSpecifications)

  const oldSpecifications = []

  uniqueSpecs.map((spec) => {
    oldSpecifications?.push({
      name: spec,
      values:
        record?.specifications?.length > 0
          ? record.specifications
              .filter((qspec) => qspec.specification.option?.name === spec)
              .map((val) => ({ id: val.id, value: val.specification.value }))
          : [],
    })
  })

  // console.log(oldSpecifications)

  const chosenFeatures =
    record.features?.map((ft) => ({
      id: ft.id,
      own: 'selling-features',
      name: ft.feature,
    })) || []

  const chosenActivities =
    record.activities?.map((act) => ({
      id: act.id,
      own: 'activity',
      name: act.activity,
    })) || []

  const customOptions = record.product_quote?.options || []
  const sizes =
    record.product_quote?.product_sizes?.length > 1
      ? {
          name: 'Size',
          values: record.product_quote?.product_sizes?.map((el) => ({ value: el.name })),
        } || null
      : null

  // console.log(sizes)
  const options = [...customOptions]

  sizes ? options.push(sizes) : null

  // console.log(options)
  const sortedVariants =
    options?.length > 0
      ? generateProductVariants({
          options,
          existingVariants: record.variants || [],
        })
      : record.variants.map((el) => ({ ...el, available: true }))

  // console.log(sortedVariants)

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        retailPrice,
        estimatedProfit,
        // estimatedMemberProfit,
        fobPrice,
        fobMargin,
        dutyRate,
        options,
        consumerServiceFees: record.consumer_service_fees / 100 || 0,
        consumerRoyaltiesFees: record.consumer_royalties_fees / 100 || 0,
        marketingFees: record.marketing_fees / 100 || 0,
        fulfillmentFees: record.fulfillment_fees / 100 || 0,
        commissionFees: record.commission_fees / 100 || 0,
        consumerSoftwareFees: record.consumer_software_fees / 100 || 0,
        consumerPartnerFees: record.consumer_partner_fees / 100 || 0,
        consumerPaymentFees: record.consumer_payment_fees / 100 || 0,
        dropshippingEstimate: estLandedRateDropship,
        courierEstimate: estLandedRateAir,
        totalFees: sellingFeesTotal,
        totalCost,
        productLength: record.product_dimensions?.length / 100 || null,
        productWidth: record.product_dimensions?.width / 100 || null,
        productHeight: record.product_dimensions?.height / 100 || null,
        productWeight: record.product_dimensions?.gross_weight / 100 || null,
        packagingLength: record.packaging_dimensions?.length / 100 || null,
        packagingWidth: record.packaging_dimensions?.width / 100 || null,
        packagingHeight: record.packaging_dimensions?.height / 100 || null,
        packagingWeight: record.packaging_dimensions?.gross_weight / 100 || null,
        bulkCartonLength: record.bulk_carton_dimensions?.length / 100 || null,
        bulkCartonWidth: record.bulk_carton_dimensions?.width / 100 || null,
        bulkCartonHeight: record.bulk_carton_dimensions?.height / 100 || null,
        bulkCartonWeight: record.bulk_carton_dimensions?.gross_weight / 100 || null,
        singleCartonLength,
        singleCartonWidth,
        singleCartonHeight,
        singleCartonWeight,
        dropshipRate,
        airRate,
        chargeableAirWeight,
        inspectionEstimate: 0,
        unitCost,
        minimum_purchase_quantity: 1,
        moq: 1,
        quantity: 1,
        chosenCategories: chosenCategories || [],
        chosenTags: chosenTags || [],
        chosenPlatforms: chosenPlatforms || [],
        sp_categories: record.categories || [],
        sp_tags: record.tags || [],
        sp_platforms: record.platforms || [],
        chosenActivities,
        chosenFeatures,
        activities: record.activities,
        features: record.features,
        sortedVariants,
        // activeVariants,
        otherSpecifications,
        oldSpecifications,
        memberPrice,
        expressPrice: record.express_price / 100 || null,
        memberExpressPrice: record.member_express_price / 100 || null,
        chosenBrand: { id: 0, name: record.brand },
        // banners: [...record.banners, ...testBanners],
      }}
      render={() => (
        <EditForm
          packagingTypes={packagingTypes}
          platforms={platforms}
          checkFormValid={checkFormValid}
        />
      )}
    />
  )
}

const EditForm = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const { platforms, packagingTypes, checkFormValid } = props

  // useEffect(() => {
  //   checkFormValid(values)
  // }, [])

  return (
    <Box display="flex" flexDirection="column" className={classes.outerBox}>
      <Box display="flex">
        <Box flex={7} display="flex" flexDirection="column" className={classes.centerBox}>
          <Box className={classes.contentBox}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
              <Typography variant="h5">Product Details</Typography>
              {values?.primary_photo &&
              values.name &&
              values.description &&
              values.long_description ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </Box>
            <GeneralDetails platforms={platforms} checkFormValid={checkFormValid} />
          </Box>
          <div className={classes.spacer20} />

          <Box className={classes.contentBox}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
              <Typography variant="h5">Dimensions and Packaging</Typography>
              {values?.singleCartonLength &&
              values?.singleCartonWidth &&
              values.singleCartonHeight &&
              values?.singleCartonWeight &&
              values.single_carton_type ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </Box>
            <PackagingDetails packagingTypes={packagingTypes} checkFormValid={checkFormValid} />
          </Box>
          <div className={classes.spacer20} />

          <Box className={classes.contentBox}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
              <Typography variant="h5">Pricing Details</Typography>
              {values?.retailPrice ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </Box>
            <PricingDetails checkFormValid={checkFormValid} />
          </Box>
          <div className={classes.spacer20} />

          {/* <Box className={classes.contentBox}>
            <Typography variant="h5">Price Calculator</Typography>
              {values?.primary_photo && values?.thumbnail ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
              <ConsumerPriceCalculator />
          </Box> */}
          {/* <div className={classes.spacer20} /> */}

          <Box className={classes.contentBox}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
              <Typography variant="h5">Variants and Options</Typography>
              {values?.sortedVariants?.filter((el) => el.available).length > 0 ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </Box>
            {packagingTypes?.length > 0 ? <VariantsDetails prodData={values.prodData} /> : null}
          </Box>
        </Box>
        <Box flex={3} className={classes.rightBox}>
          <ProductOrganisation checkFormValid={checkFormValid} />
        </Box>
      </Box>
    </Box>
  )
}

export default SaleableProductEdit
