import React, { useState, useEffect } from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import HeaderSection from './components/OfferHeaderSection'
import OfferManagement from './components/OfferManagement'
import POManagement from '../shared/components/POManagement'
import SalesManagement from './components/SalesManagement'
import useStyles from './styles/index'
import { uniqueId } from 'lodash'
import { Show, useDataProvider, useNotify } from 'react-admin'

const OfferShow = (props) => (
  // console.log(props)
  <Show {...props} title="Development Brief" actions={null} component="div">
    <OfferShowForm {...props} />
  </Show>
)

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

const OfferShowForm = (props) => {
  const [currentTab, setCurrentTab] = useState('one')
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log('OFFORM', props)

  const [trackingRows, setTrackingRows] = useState([
    {
      id: 0,
      event: 'Payment - Factory Deposit - Production Start',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 1,
      event: 'Inspection Results',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 2,
      event: 'Inspection Approved',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 3,
      event: 'Payment - Factory Balance - Production Complete',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 4,
      event: 'Payment - Inspection Company',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 5,
      event: 'Warehouse Receiving Order Submitted',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 6,
      event: 'Goods Pick-up Successful',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 7,
      event: 'Port Departure - Bill of Lading',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 8,
      event: 'Port Arrival',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 9,
      event: 'Warehouse Arrival',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
    {
      id: 10,
      event: 'Payment - Logistics Company',
      date: null,
      file: null,
      status: 'Not Started',
      confirmed: null,
    },
  ])

  const id = parseInt(props.id)

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await dataProvider.getOfferTrackingDetails('getOfferTrackingDetails', {
          id,
        })
        // console.log('HeaderData', data)

        const podRow = data.find(
          (item) => item.event === 'Payment - Factory Deposit - Production Start',
        )
        const psdRow = data.find((item) => item.event === 'Inspection Results')
        const indRow = data.find((item) => item.event === 'Inspection Approved')
        const inrRow = data.find(
          (item) => item.event === 'Payment - Factory Balance - Production Complete',
        )
        const pfdRow = data.find((item) => item.event === 'Payment - Inspection Company')
        const wroRow = data.find((item) => item.event === 'Warehouse Receiving Order Submitted')
        const pudRow = data.find((item) => item.event === 'Goods Pick-up Successful')

        const ptdRow = data.find((item) => item.event === 'Port Departure - Bill of Lading')
        const ptaRow = data.find((item) => item.event === 'Port Arrival')
        const wadRow = data.find((item) => item.event === 'Warehouse Arrival')
        const plcRow = data.find((item) => item.event === 'Payment - Logistics Company')

        const newData = [...data]

        // console.log('RECEIVEDROWS', newData)

        !podRow &&
          newData.push({
            id: uniqueId(),
            event: 'Payment - Factory Deposit - Production Start',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 1,
            type: 'Production',
            stub: true,
          })
        !psdRow &&
          newData.push({
            id: uniqueId(),
            event: 'Inspection Results',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 2,
            type: 'Production',
            stub: true,
          })
        !indRow &&
          newData.push({
            id: uniqueId(),
            event: 'Inspection Approved',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 3,
            type: 'Production',
            stub: true,
          })
        !inrRow &&
          newData.push({
            id: uniqueId(),
            event: 'Payment - Factory Balance - Production Complete',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 4,
            type: 'Production',
            stub: true,
          })
        !pfdRow &&
          newData.push({
            id: uniqueId(),
            event: 'Payment - Inspection Company',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 5,
            type: 'Production',
            stub: true,
          })
        !wroRow &&
          newData.push({
            id: uniqueId(),
            event: 'Warehouse Receiving Order Submitted',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 6,
            type: 'Shipping',
            stub: true,
          })
        !pudRow &&
          newData.push({
            id: uniqueId(),
            event: 'Goods Pick-up Successful',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 7,
            type: 'Shipping',
            stub: true,
          })
        !ptdRow &&
          newData.push({
            id: uniqueId(),
            event: 'Port Departure - Bill of Lading',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 8,
            type: 'Shipping',
            stub: true,
          })
        !ptaRow &&
          newData.push({
            id: uniqueId(),
            event: 'Port Arrival',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 9,
            type: 'Shipping',
            stub: true,
          })
        !wadRow &&
          newData.push({
            id: uniqueId(),
            event: 'Warehouse Arrival',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 10,
            type: 'Shipping',
            stub: true,
          })
        !plcRow &&
          newData.push({
            id: uniqueId(),
            event: 'Payment - Logistics Company',
            date: null,
            file: null,
            status: 'Not Started',
            sequence: 11,
            type: 'Shipping',
            stub: true,
          })
        // console.log('FULLROWS', newData)

        setTrackingRows(newData)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }
    getData()
  }, [dataProvider, id, notify])

  const saveTrackingEvent = async (props) => {
    // console.log('SAVING', props)

    const submission = {
      object: { ...props, offer_id: id },
      on_conflict: {
        constraint: props.id
          ? '^offer_purchase_order_tracking_update_pkey^'
          : '^offer_tracking_event_id_offer_id_event_key^',
        update_columns: [
          '^updated_at^',
          '^date^',
          '^event^',
          '^attachments^',
          '^status^',
          '^sequence^',
          '^confirmed^',
          '^type^',
        ],
      },
    }

    // console.log('SUBMISSION', submission)

    try {
      const resp = await dataProvider.create('offer_tracking_event', submission)

      // console.log(resp)

      notify('Saved Successfully.', 'info')
      return resp
      // history.push({ pathname: `/projectDashboard/${projectID}`, query: { projectID } })
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }

    return null
  }

  const saveOrderStatus = async (props) => {
    // console.log('SAVING', props)

    try {
      await dataProvider.update('offerStatus', { id, status: props })
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }

    return null
  }

  return (
    <div>
      <Box m="10px 30px">
        <HeaderSection {...props} />
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          aria-label="offers"
          style={{ marginTop: '20px' }}
        >
          <Tab
            value="one"
            label="Offers Management"
            wrapped
            style={{ width: '250px' }}
            className={currentTab === 'one' ? classes.tabButton : null}
            // {...a11yProps('one')}
          />
          <Tab
            value="two"
            label="PO Management"
            style={{ width: '250px' }}
            wrapped
            className={currentTab === 'two' ? classes.tabButton : null}
          />
          <Tab
            value="three"
            label="Sales Management"
            style={{ width: '250px' }}
            wrapped
            className={currentTab === 'three' ? classes.tabButton : null}
          />
        </Tabs>
        <TabPanel value={currentTab} index="one">
          <OfferManagement id={id} {...props} />
        </TabPanel>
        <TabPanel value={currentTab} index="two">
          <POManagement
            id={id}
            trackingRows={trackingRows}
            setTrackingRows={setTrackingRows}
            saveTrackingEvent={saveTrackingEvent}
            saveOrderStatus={saveOrderStatus}
          />
        </TabPanel>
        <TabPanel value={currentTab} index="three">
          <SalesManagement id={id} />
        </TabPanel>
      </Box>
    </div>
  )
}

export default OfferShow
