import React, { useState, useEffect } from 'react'
import {
  Filter,
  List,
  SearchInput,
  FunctionField,
  SelectInput,
  Datagrid,
  DatagridBody,
  DateField,
  TopToolbar,
  Pagination,
  useRefresh,
  useNotify,
  useDataProvider,
} from 'react-admin'
import {
  Button,
  // Checkbox,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import moment from 'moment'
import { getDisplayName } from '../../utils/utils'
import CreateDialog from './components/CreateDialog'
// import EditDialog from './components/EditDialog'

const ListActions = (props) => {
  const { className } = props
  const [createOpen, setCreateOpen] = useState(false)

  return (
    <TopToolbar className={className}>
      <Button
        style={{
          color: 'black',
          border: 'solid 1px rgba(180, 125, 80, 0.5)',
          backgroundColor: 'rgba(255, 149, 102, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(255, 149, 102, 0.3)',
          },
        }}
        onClick={async () => {
          setCreateOpen(true)
        }}
      >
        Create
      </Button>
      <CreateDialog createOpen={createOpen} setCreateOpen={setCreateOpen} />
    </TopToolbar>
  )
}

export const TasksFilter = (props) => {
  const { scaUsers, ...rest } = props
  return (
    <Filter {...rest}>
      <SearchInput
        // label="Name/Sku"
        source="name"
        alwaysOn
        variant="standard"
        fullWidth
        placeholder="Task Name"
        style={{ minWidth: '160px' }}
      />
      <SearchInput
        // label="Name/Sku"
        source="project_name"
        alwaysOn
        variant="standard"
        fullWidth
        placeholder="Project Name"
        style={{ minWidth: '160px' }}
      />
      <SelectInput
        label="Assigned To"
        source="assigned_to"
        style={{ minWidth: '160px' }}
        alwaysOn
        choices={scaUsers || []}
        variant="standard"
        optionText={(val) => getDisplayName(val)}
        fullWidth
      />
    </Filter>
  )
}

const TasksList = (props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const today = moment()

  const [scaUsers, setSCAUsers] = useState([])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userData } = await dataProvider.getList('user', {
          // filter: { roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        // console.log(userData)
        !abortController.signal.aborted && setSCAUsers(userData)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  const MyDatagridRow = (props) => {
    const {
      record,
      resource,
      id,
      // onToggleItem,
      children,
      // selected,
      // selectable,
      // basePath,
    } = props
    // console.log(props)
    if (!record) {
      return null
    }

    const [editing, setEditing] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [editableValues, setEditableValues] = useState({
      name: record.name || '',
      assigned_to: scaUsers.find((el) => el.id === record.assigned_to) || '',
      status: record.status || '',
      received_date: record.received_date || '',
      deadline_date: record.deadline_date || '',
      project_id:
        record.project?.project_name && record.project?.client_name
          ? `${record.project?.project_name} - ${record.project?.client_name}`
          : '',
      updated_at: record.updated_at,
      created_at: record.created_at,
    })

    // console.log(moment(record.deadline_date) < today)

    return (
      <TableRow key={id} className="MuiTableRow-root MuiTableRow-hover">
        {/* <TableCell padding="none">
          <Checkbox
            disabled={selectable}
            checked={selected}
            onClick={(event) => onToggleItem(id, event)}
          />
        </TableCell> */}
        {React.Children.map(children, (field) => (
          <TableCell
            style={
              moment(record.deadline_date) < today
                ? { margin: '0px', padding: '0px 10px', backgroundColor: '#FFE6E6' }
                : { margin: '0px', padding: '0px 10px' }
            }
            key={`${id}-${field.props.source}`}
          >
            <EditableField
              fieldType={field?.props?.type || 'static'}
              scaUsers={scaUsers}
              label={field?.props?.label}
              record={editableValues}
              editing={editing}
              field={field?.props?.source}
              setEditableValues={setEditableValues}
              resource={resource}
            />
          </TableCell>
        ))}
        {editing ? (
          <TableCell style={{ display: 'flex' }}>
            <IconButton
              onClick={async () => {
                try {
                  await dataProvider.update('tasks', {
                    data: {
                      id,
                      assigned_to: record.assigned_to.id,
                      name: record.name,
                      deadline_date: editableValues.deadline_date,
                      received_date: editableValues.received_date,
                      status: editableValues.status,
                    },
                  })

                  refresh()
                  setEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setEditing(false)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: '#FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </TableCell>
        ) : deleting ? (
          <TableCell style={{ display: 'flex' }}>
            <IconButton
              onClick={async () => {
                try {
                  await dataProvider.delete('tasks', {
                    id,
                  })

                  refresh()
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setDeleting(false)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: '#FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleting(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </TableCell>
        ) : (
          <TableCell style={{ minWidth: '88px' }}>
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleting(true)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    )
  }

  const MyDatagridBody = (props) => <DatagridBody {...props} row={<MyDatagridRow />} />
  const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />
  const TasksPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

  return (
    <List
      {...props}
      filter={{
        status: props.filter?.status ? props.filter?.status : null,
      }}
      filters={props.basePath === '/tasks' ? <TasksFilter scaUsers={scaUsers} /> : null}
      actions={<ListActions />}
      perPage={100}
      sort={{ field: 'updated_at', order: 'DESC' }}
      empty={false}
      pagination={<TasksPagination />}
      bulkActionButtons={false}
    >
      <MyDatagrid path="tasks" rowClick="edit">
        <DateField source="received_date" label="Created" type="date" />
        <FunctionField
          label="Project"
          source="project_id"
          render={(record) => {
            console.log(record)
            const name = `${record.project?.project_name}(${record.project?.client_name})`
            return name?.length > 15 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 12)}...`}</span>
              </Tooltip>
            ) : (
              <span>{name}</span>
            )
          }}
        />
        <FunctionField
          label="Task"
          source="name"
          type="text"
          render={(record) => {
            const name = record.name
            return name?.length > 15 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 12)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        <FunctionField
          render={(record) => (record.assigned_to ? getDisplayName(record.assigned_to) : 'None')}
          source="assigned_to"
          label="Assigned To"
          type="list"
        />
        <FunctionField
          render={(record) => record.status}
          label="Status"
          source="status"
          type="text"
        />
        <DateField source="updated_at" label="Updated" type="static" />
        <DateField source="deadline_date" label="Deadline" type="date" />
      </MyDatagrid>
    </List>
  )
}

const EditableField = (props) => {
  const { record, field, fieldType, setEditableValues, editing, scaUsers = [], ...rest } = props

  if (editing && fieldType === 'text') {
    return (
      <TextField
        id="editableTextField"
        value={record[field]}
        disabled={!editing}
        fullWidth
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEditableValues((prevState) => {
            return {
              ...prevState,
              [field]: event.target.value,
            }
          })
        }}
        {...rest}
        label={false}
      />
    )
  } else if (editing && fieldType === 'date') {
    return (
      <TextField
        id="editableTextField"
        value={record[field]}
        disabled={!editing}
        fullWidth
        type="date"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEditableValues((prevState) => {
            return {
              ...prevState,
              [field]: event.target.value,
            }
          })
        }}
        {...rest}
      />
    )
  } else if (editing && field === 'assigned_to') {
    return (
      <Select
        id="scaSelect"
        value={record.assigned_to}
        fullWidth
        style={{ marginBottom: '4px' }}
        variant="standard"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEditableValues((prevState) => {
            return {
              ...prevState,
              assigned_to: event.target.value,
            }
          })
        }}
      >
        {scaUsers.map((el) => (
          <MenuItem key={`sca${el.id}`} value={el}>
            {getDisplayName(el)}
          </MenuItem>
        ))}
      </Select>
    )
  } else if (!editing && field === 'assigned_to') {
    return <span>{record.assigned_to ? getDisplayName(record.assigned_to) : 'None'}</span>
  } else if (field === 'updated_at') {
    return <span> {moment(record.updated_at).format('YYYY/MM/DD')}</span>
  } else {
    return <span>{record[field]}</span>
  }
}

export default TasksList
