import React from 'react'
import { Box, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useFormState } from 'react-final-form'
import { getConsumerOrderStatus } from '../../../utils/utils'
// import { makeStyles } from '@material-ui/core/styles'

// const useStyles = makeStyles(() => ({
//     sectionHeader: {
//       fontSize: '18px',
//       color: 'rgb(18, 183, 106)',
//       margin: '0px 0px 20px',
//     },
// }))

const ConsumerOrdersHeader = () => {
  const { values } = useFormState()
  // const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="30px !important"
      style={{ backgroundColor: '#FFF' }}
    >
      <Box display="flex" flexDirection="column">
        <span style={{ fontSize: '24px', lineHeight: '36px', fontWeight: '500' }}>
          {`Order Details (INV-${values.order_number})`}
        </span>
        <span
          style={{ marginTop: '20px', fontSize: '20px', lineHeight: '20px', fontWeight: '400' }}
        >
          {getConsumerOrderStatus(values.status)}
        </span>
      </Box>
      <Box display="flex">
        <Button>
          <GetAppIcon />
          Preview
        </Button>
        <Button>
          <VisibilityIcon />
          Download
        </Button>
      </Box>
    </Box>
  )
}

export default ConsumerOrdersHeader
