import Talk from 'talkjs'
import React, { useEffect, useState } from 'react'
import { Box, Drawer, Badge, IconButton } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'

const TalkContainer = ({ identity }) => {
  const [chatOpen, setChatOpen] = useState(false)
  const [unreadsCount, setUnreadsCount] = useState(false)
  return (
    <Box>
      <IconButton
        aria-label="open-chat-dialog"
        aria-controls="chat-appbar"
        aria-haspopup="true"
        onClick={() => setChatOpen(true)}
        color="inherit"
      >
        <Badge badgeContent={unreadsCount} color="secondary" overlap="rectangular">
          <ChatIcon />
        </Badge>
      </IconButton>
      <Drawer open={chatOpen} anchor="right" onClose={() => setChatOpen(false)}>
        <Box style={{ minWidth: '800px', backgroundColor: '#ffffff', height: '100%' }}>
          <ChatBox identity={identity} setUnreadsCount={setUnreadsCount} />
        </Box>
      </Drawer>
    </Box>
  )
}

const ChatBox = ({ identity, setUnreadsCount }) => {
  const [talkLoaded, markTalkLoaded] = useState(false)

  useEffect(() => {
    // console.log('id', identity, talkLoaded)

    const initTalk = async () => {
      await Talk.ready
      markTalkLoaded(true)
    }

    if (!talkLoaded) {
      initTalk()
    }
    if (identity && talkLoaded) {
      // Create users here

      const currentUser = new Talk.User({
        id: identity.code,
        name: identity.name,
      })

      const session = new Talk.Session({
        appId:
          process.env.NODE_ENV === 'development' ? 'tQPn9UM8' : process.env.REACT_APP_TALKJS_APP_ID,
        me: currentUser,
        signature: identity.talkToken,
      })
      session.unreads.onChange((e) => {
        // console.log(e)
        setUnreadsCount(e.length)

        if (e.length > 0) {
          document.title = '(' + e.length + ') MFG Admin'
        } else {
          document.title = 'MFG Admin'
        }
      })

      const inbox = session.createInbox()
      inbox.mount(document.getElementById('inbox-container'))

      // return () => session.destroy()
    }
  }, [talkLoaded])

  return <div id={'inbox-container'} style={{ height: '100%' }} />
}

export default TalkContainer
