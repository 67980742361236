import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  sectionHeader: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '18px',
    margin: '10px 0px',
  },
  listButton: {
    justifyContent: 'flex-start',
    textDecoration: 'underline',
    width: 'fit-content',
  },
  listRowsCondense: {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-sizeSmall': {
          height: 'auto',
        },
      },
    },
  },
  productImagesBox: {
    width: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productImages: {
    maxHeight: '120px',
    maxWidth: '120px',
  },
  cls1: {
    fill: '#FFFFFF',
  },
  hat: {
    '& .cls2': {
      fill: '#FFFFFF',
    },
    '& .cls1': {
      fill: '#CCCCCC',
    },
  },
}))
