import React, { useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { uniqueId } from 'lodash'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import {
  AutocompleteArrayInput,
  required,
  FileField,
  FileInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
} from 'react-admin'
import { useDropzone } from 'react-dropzone'
import moment from 'moment'
import TextInput from '../../../components/OutlinedTextInput'
import SelectInput from '../../../components/input/SelectInput'
import ChipInput from '../../../components/input/ChipInput'
import { ColorInput } from '../../components/ColourPicker'
import NumberFormat from '../../components/CustomNumberFormat'
import SupplierSelectWithCreate from '../../components/SupplierSelectWithCreate'
import { DimensionsTable, PackagingOption } from './DimensionsTable'
import useStyles from '../styles'

const StyledSimpleFormIterator = withStyles(() => ({
  // root: {
  //   display: 'inline-flex',
  // },
  line: {
    justifyContent: 'center',
  },
}))(SimpleFormIterator)

const FormData = (props) => {
  // console.log('FDPROPS', props)
  const { dataLists = [], setDataLists, title } = props
  const classes = useStyles()
  const form = useForm()

  useEffect(() => {
    return () => {}
  }, [])

  const { values } = useFormState()

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        form.change('images', [
          ...(values.images || []),
          ...acceptedFiles.map((file) => {
            return {
              id: uniqueId(),
              type: 'ADDITIONAL_PHOTO',
              file: Object.assign(file, {
                url: URL.createObjectURL(file),
              }),
            }
          }),
        ])
        if (!values.primary_photo) {
          form.change('primary_photo', acceptedFiles[0])
          form.change('thumbnail_photo', acceptedFiles[0])
        }
      }
    },
  })

  const { getRootProps: getAdditionalRootProps, getInputProps: getAdditionalInputProps } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        // console.log(acceptedFiles)
        form.change('additional_files', [
          ...(values.additional_files || []),
          ...acceptedFiles.map((file) => {
            return { file: file, id: uniqueId() }
            //   Object.assign(file, {
            //     // preview: URL.createObjectURL(file),
            //     // file_type: 'ADDITIONAL_PHOTO',
          }),
        ])
      },
    })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.images?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.additional_files?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  const previews = values.images
    ?.filter((item) => !!item.deleted_at === false)
    .map((file) => {
      const currentTime = moment().toISOString()

      return (
        <Box key={`preview${file.file?.name || file.id}`} className={classes.previewImageBox}>
          <img
            src={file.file?.url}
            className={classes.previewImage}
            onClick={() => {
              form.change('primary_photo', file.file)
              form.change('thumbnail_photo', file.file)
              // form.change('primary_photo_number', index)
              // setSelectedPhoto(index)
            }}
          />
          <IconButton
            aria-label="delete"
            className={classes.deleteBtn}
            onClick={() => {
              const image = values.images.findIndex((item) => item.id === file.id)
              form.change(`images[${image}].deleted_at`, currentTime)
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  const additionalPreviews = values.additional_files
    ?.filter((item) => !!item.deleted_at === false)
    .map((file, index) => {
      // console.log(file, index)
      const currentTime = moment().toISOString()
      const ind = values.additional_files.findIndex((item) => item.id === file.id)

      return (
        <Box
          key={`addpreview${file.file?.path || file.file?.name || file.id}`}
          className={classes.previewFileBox}
        >
          <SelectInput
            label="Additional file type"
            source={`additional_files[${ind}].type`}
            choices={[
              { id: 0, name: 'Design Artwork', value: 'DESIGN_ARTWORK' },
              { id: 1, name: 'Design Dieline', value: 'DESIGN_DIELINE' },
              { id: 2, name: 'Design Logo', value: 'DESIGN_LOGO' },
              { id: 3, name: 'Factory Quote', value: 'FACTORY_QUOTE' },
              { id: 4, name: 'Other', value: 'OTHER' },
              // { id: 0, name: 'Packing List', value: 'PACKING_LIST' },
              // { id: 1, name: 'Product Spec Sheet', value: 'PRODUCT_SPEC_SHEET' },
              // { id: 2, name: 'Product Artwork', value: 'PRODUCT_ARTWORK' },
              // { id: 3, name: 'Product Concept Rendering', value: 'PRODUCT_CONCEPT_RENDERING' },
              // { id: 4, name: 'Product Technical Drawing', value: 'PRODUCT_TECHNICAL_DRAWING' },
              // { id: 5, name: 'Packaging Artwork', value: 'PACKAGING_ARTWORK' },
              // { id: 6, name: 'Packaging Concept Rendering', value: 'PACKAGING_CONCEPT_RENDERING' },
              // { id: 7, name: 'Packaging Technical Drawing', value: 'PACKAGING_TECHNICAL_DRAWING' },
              // { id: 8, name: 'Carton Artwork', value: 'CARTON_ARTWORK' },
              // { id: 9, name: 'Carton Concept Rendering', value: 'CARTON_CONCEPT_RENDERING' },
              // { id: 10, name: 'Carton Technical Drawing', value: 'CARTON_TECHNICAL_DRAWING' },
              // { id: 11, name: 'Other Design File', value: 'DESIGN_FILE' },
            ]}
            className={classes.fieldInputNoMP}
            optionText="name"
            optionValue="value"
            defaultValue=""
            variant="standard"
            validate={required()}
            onChange={(e) => {
              // console.log(e.target.value)
              form.change(`additional_files[${ind}].type`, e.target.value)
            }}
          />

          <a href={file.file?.url || file.file?.path}>
            {`${index}. ${file.file?.name || file.file?.filename}`}
          </a>
          <IconButton
            aria-label="delete"
            className={classes.deleteAddBtn}
            onClick={() => {
              // const ind = values.additional_files.findIndex((item) => item.id === file.id)
              // console.log(`additional_files[${ind}].deleted_at`, ind, currentTime)
              form.change(`additional_files[${ind}].deleted_at`, currentTime)
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  // console.log(values)

  const quoteRequirements = []

  !values.name && quoteRequirements.push('Name')
  !values.short_description && quoteRequirements.push('Short Description')
  !values.chargeable_weight_air && quoteRequirements.push('Chargeable Weight - Air')
  !values.chargeable_weight_sea && quoteRequirements.push('Chargeable Weight - Sea')
  !values.duty_rate && quoteRequirements.push('Duty Rate')
  !values.hts_code && quoteRequirements.push('HTS Code')
  !values.categories?.length > 0 && quoteRequirements.push('Min 1 Category')
  !values.productColours?.length > 0 && quoteRequirements.push('Min 1 Colour')
  !values.productMaterials?.length > 0 && quoteRequirements.push('Min 1 Material')
  !values.product_dimensions?.length && quoteRequirements.push('Product Length')
  !values.product_dimensions?.width && quoteRequirements.push('Product Width')
  !values.product_dimensions?.height && quoteRequirements.push('Product Height')
  !values.carton_dimensions?.length && quoteRequirements.push('Carton Length')
  !values.carton_dimensions?.width && quoteRequirements.push('Carton Width')
  !values.carton_dimensions?.height && quoteRequirements.push('Carton Height')
  !values.carton_dimensions?.gross_weight && quoteRequirements.push('Carton Weight')

  if (values.product_quotes?.length > 0) {
    !values.product_quotes[0].supplier_id && quoteRequirements.push('Supplier')
    !values.product_quotes[0].prices?.length > 0 && quoteRequirements.push('Min 1 Quote')
    !values.product_quotes[0].packaging_type && quoteRequirements.push('Packaging Type')
    !values.product_quotes[0].pieces_per_carton && quoteRequirements.push('Pieces per Carton')
  }

  return (
    <Box className={classes.outerBox}>
      <Box display="flex" justifyContent="space-between">
        <span className={classes.title}>{title}</span>
        {/* <span
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Button
            className={classes.quoteButton}
            disabled={quoteRequirements?.length > 0}
            onClick={() => redirect(`/scmQuote/create/${values.id}`)}
          >
            Create New Quote
          </Button>
        </span>
        {quoteRequirements?.length > 0 && (
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box display="flex" flexDirection="column">
              {quoteRequirements?.map((item) => (
                <span key={`qtReq${item}`}>{item}</span>
              ))}
            </Box>
          </Popover>
        )} */}
      </Box>
      <Box display="flex">
        <Box flex={1}>
          <div {...getRootProps({ className: classes.imageDropZone })}>
            <input {...getInputProps()} />
            {values.primary_photo ? (
              <img src={values.primary_photo.url} alt="alt" className={classes.primaryPhoto} />
            ) : null}

            {!values.primary_photo ? (
              <span>{`Drag & drop some files here, or click to select files`}</span>
            ) : null}
          </div>
          <Box className={classes.previewsContainer}>{previews}</Box>
        </Box>
        <Box flex={2} ml="50px">
          <TextInput
            variant="standard"
            fullWidth
            source="name"
            label="Product name"
            validate={required()}
          />
          {/* <span className={classes.productSKU}>124-373</span> */}
          {/* {dataLists.categories?.length > 0 ? ( */}
          <AutocompleteArrayInput
            source="categories"
            choices={dataLists.categories || []}
            fullWidth
            variant="standard"
          />
          {/* ) : (
            <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
          )} */}
          <TextInput variant="standard" fullWidth source="brand" label="Brand" />
          <TextInput
            variant="standard"
            fullWidth
            source="short_description"
            label="Short description"
          />
          <ChipInput
            source="contents"
            className={`${classes.fieldInputLeft}`}
            label="Contents"
            style={{ minWidth: '100%' }}
            variant="standard"
            margin="none"
          />
          <SupplierSelectWithCreate
            options={dataLists.suppliers}
            setOptions={(newOptions) => {
              const newLists = { ...props.dataLists }
              newLists.suppliers = newOptions
              setDataLists(newLists)
            }}
            validate={required()}
            label="Supplier"
            optionText={(value) => {
              return value?.official_verification_link ? value?.name : `${value?.name} (Unverified)`
            }}
            source={'product_quote.supplier_id'}
          />
          <Box display="flex" mt="30px">
            <TextInput
              variant="standard"
              // fullWidth
              margin="none"
              source="hts_code"
              label="HTS code"
              className={classes.fieldInputLeft}
            />
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              defaultValue={0}
              source="dutyRate"
              label="Duty Rate"
              suffix=" %"
            />
          </Box>
        </Box>
      </Box>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <Box mt="30px">
              <span className={classes.sectionHeader}>Physical Attributes and Options</span>
              <ArrayInput source="components" label="">
                <StyledSimpleFormIterator
                  TransitionProps={{ enter: false, exit: false }}
                  addButton={
                    <Button
                      style={{
                        width: '300px',
                        backgroundColor: '#F8F8FA',
                        color: 'black',
                        // marginTop: '-10px',
                      }}
                    >
                      <AddIcon />
                      ADD NEW COMPONENT / OPTION
                    </Button>
                  }
                  removeButton={
                    <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                  }
                >
                  <FormDataConsumer>
                    {({ getSource, scopedFormData, rest }) => (
                      <Box display="flex" style={{ backgroundColor: 'rgba(35, 35, 35, 0.05)' }}>
                        <Box flex={3} display="flex" flexDirection="column" padding="5px 50px">
                          <TextInput
                            variant="standard"
                            fullWidth
                            source={getSource('name')}
                            {...rest}
                            record={scopedFormData}
                            label="Component"
                          />
                          <TextInput
                            variant="standard"
                            fullWidth
                            source={getSource('description')}
                            {...rest}
                            record={scopedFormData}
                            label="Description"
                          />

                          <Box display="flex">
                            <span style={{ fontSize: '10px' }}>Dimensions:</span>
                            <NumberFormat
                              // className={classes.numberInput}
                              displayType="input"
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale
                              isNumericString
                              defaultValue={0}
                              margin="5px"
                              source={getSource('length')}
                              {...rest}
                              record={scopedFormData}
                              label="Length"
                              suffix=" cm"
                            />
                            <NumberFormat
                              // className={classes.numberInput}
                              displayType="input"
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale
                              isNumericString
                              defaultValue={0}
                              source={getSource('width')}
                              {...rest}
                              record={scopedFormData}
                              label="Width"
                              suffix=" cm"
                            />
                            <NumberFormat
                              // className={classes.numberInput}
                              displayType="input"
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale
                              isNumericString
                              defaultValue={0}
                              source={getSource('height')}
                              {...rest}
                              record={scopedFormData}
                              label="Height"
                              suffix=" cm"
                            />
                          </Box>
                        </Box>

                        <Box flex={5} display="flex" flexDirection="column" padding="5px 50px">
                          <ChipInput
                            source="materials"
                            className={`${classes.fieldInputLeft}`}
                            label="Material(s)"
                            style={{ minWidth: '100%' }}
                            variant="standard"
                            margin="none"
                          />
                          <ChipInput
                            source="finishes"
                            className={`${classes.fieldInputLeft}`}
                            label="Finish(es)"
                            style={{ minWidth: '100%' }}
                            variant="standard"
                            margin="none"
                          />
                          <ColorInput
                            source={getSource('option_value.value')}
                            {...rest}
                            record={scopedFormData}
                            className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                            picker="Sketch"
                            label="Color"
                            // validate={required()}
                          />
                        </Box>
                      </Box>
                      // <Box flex={1}>

                      // </Box>
                    )}
                  </FormDataConsumer>
                </StyledSimpleFormIterator>
              </ArrayInput>
            </Box>
            <Box mt="30px">
              <span className={classes.sectionHeader}>Other Attributes and Options</span>
              <ArrayInput source="attributes" label="">
                <StyledSimpleFormIterator
                  TransitionProps={{ enter: false, exit: false }}
                  addButton={
                    <Button
                      style={{
                        width: '300px',
                        backgroundColor: '#F8F8FA',
                        color: 'black',
                        // marginTop: '-10px',
                      }}
                    >
                      <AddIcon />
                      ADD NEW COMPONENT / OPTION
                    </Button>
                  }
                  removeButton={
                    <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                  }
                >
                  <FormDataConsumer>
                    {({ getSource, scopedFormData, rest }) => (
                      <Box display="flex" style={{ backgroundColor: 'rgba(35, 35, 35, 0.05)' }}>
                        <Box flex={1} display="flex" flexDirection="column" padding="5px 50px">
                          <TextInput
                            variant="standard"
                            fullWidth
                            source={getSource('name')}
                            {...rest}
                            record={scopedFormData}
                            label="Attribute"
                          />
                          <TextInput
                            variant="standard"
                            fullWidth
                            source={getSource('description')}
                            {...rest}
                            record={scopedFormData}
                            label="Description"
                          />

                          <Box display="flex">
                            <span style={{ fontSize: '10px' }}>Dimensions:</span>
                            <NumberFormat
                              // className={classes.numberInput}
                              displayType="input"
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale
                              isNumericString
                              defaultValue={0}
                              margin="5px"
                              source={getSource('length')}
                              {...rest}
                              record={scopedFormData}
                              label="Length"
                              suffix=" cm"
                            />
                            <NumberFormat
                              // className={classes.numberInput}
                              displayType="input"
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale
                              isNumericString
                              defaultValue={0}
                              source={getSource('width')}
                              {...rest}
                              record={scopedFormData}
                              label="Width"
                              suffix=" cm"
                            />
                            <NumberFormat
                              // className={classes.numberInput}
                              displayType="input"
                              thousandSeparator
                              decimalScale={2}
                              fixedDecimalScale
                              isNumericString
                              defaultValue={0}
                              source={getSource('height')}
                              {...rest}
                              record={scopedFormData}
                              label="Height"
                              suffix=" cm"
                            />
                          </Box>
                        </Box>

                        <Box flex={1} display="flex" flexDirection="column" padding="5px 50px">
                          <ChipInput
                            source="options"
                            className={`${classes.fieldInputLeft}`}
                            label="Option(s)"
                            style={{ minWidth: '100%' }}
                            variant="standard"
                            margin="none"
                          />
                        </Box>
                      </Box>
                    )}
                  </FormDataConsumer>
                </StyledSimpleFormIterator>
              </ArrayInput>
            </Box>
            <Box mt="30px">
              <span className={classes.sectionHeader}>Imprint Options</span>
              <ArrayInput source="imprintOptions" label="">
                <StyledSimpleFormIterator
                  TransitionProps={{ enter: false, exit: false }}
                  addButton={
                    <Button
                      style={{
                        width: '300px',
                        backgroundColor: '#F8F8FA',
                        color: 'black',
                        // marginTop: '-10px',
                      }}
                    >
                      <AddIcon />
                      ADD NEW IMPRINT OPTION
                    </Button>
                  }
                  removeButton={
                    <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                  }
                >
                  <FormDataConsumer>
                    {({ getSource, scopedFormData, rest }) => (
                      <Box display="flex" style={{ backgroundColor: 'rgba(35, 35, 35, 0.05)' }}>
                        <Box flex={2} display="flex" flexDirection="column" padding="5px 50px">
                          <TextInput
                            variant="standard"
                            fullWidth
                            source={getSource('style')}
                            {...rest}
                            record={scopedFormData}
                            label="Imprint Style"
                          />
                          <TextInput
                            variant="standard"
                            fullWidth
                            source={getSource('description')}
                            {...rest}
                            record={scopedFormData}
                            label="Description"
                          />
                        </Box>

                        <Box flex={3} display="flex" flexDirection="column" padding="5px 50px">
                          <ArrayInput source="locations" label="">
                            <StyledSimpleFormIterator
                              TransitionProps={{ enter: false, exit: false }}
                              addButton={
                                <Button
                                  style={{
                                    width: '300px',
                                    backgroundColor: '#F8F8FA',
                                    color: 'black',
                                    // marginTop: '-10px',
                                  }}
                                >
                                  <AddIcon />
                                  ADD NEW LOCATION
                                </Button>
                              }
                              removeButton={
                                <DeleteOutlineIcon
                                  style={{ cursor: 'pointer', marginTop: '-25px' }}
                                />
                              }
                            >
                              <FormDataConsumer>
                                {({ getSource, scopedFormData, rest }) => (
                                  <Box display="flex" flexDirection="column">
                                    <TextInput
                                      variant="standard"
                                      fullWidth
                                      source={getSource('style')}
                                      {...rest}
                                      record={scopedFormData}
                                      label="Location"
                                    />
                                    <Box display="flex">
                                      <TextInput
                                        variant="standard"
                                        fullWidth
                                        source={getSource('min_length')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Min Length"
                                      />

                                      <TextInput
                                        variant="standard"
                                        fullWidth
                                        source={getSource('min_width')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Min Width"
                                      />

                                      <TextInput
                                        variant="standard"
                                        fullWidth
                                        source={getSource('max_length')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Max Length"
                                      />

                                      <TextInput
                                        variant="standard"
                                        fullWidth
                                        source={getSource('max_width')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Max Width"
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </FormDataConsumer>
                            </StyledSimpleFormIterator>
                          </ArrayInput>
                        </Box>
                      </Box>
                    )}
                  </FormDataConsumer>
                </StyledSimpleFormIterator>
              </ArrayInput>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Size and Packaging Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mt="30px">
            <span className={classes.sectionHeader}>Size and Packaging Options</span>
            <ArrayInput source="packagingSizes" label="">
              <StyledSimpleFormIterator
                TransitionProps={{ enter: false, exit: false }}
                addButton={
                  <Button
                    style={{
                      width: '300px',
                      backgroundColor: '#F8F8FA',
                      color: 'black',
                      // marginTop: '-10px',
                    }}
                  >
                    <AddIcon />
                    ADD NEW SIZE OPTION
                  </Button>
                }
                removeButton={
                  <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                }
              >
                <FormDataConsumer>
                  {({ getSource, scopedFormData, rest }) => (
                    <Box
                      mt="30px"
                      display="flex"
                      flexDirection="column"
                      // style={{ backgroundColor: 'rgba(35, 35, 35, 0.05)' }}
                    >
                      <DimensionsTable
                        source={getSource('dimensions')}
                        {...rest}
                        record={scopedFormData}
                      />

                      <ArrayInput source="packagingOptions" label="">
                        <StyledSimpleFormIterator
                          TransitionProps={{ enter: false, exit: false }}
                          addButton={
                            <Button
                              style={{
                                width: '300px',
                                backgroundColor: '#F8F8FA',
                                color: 'black',
                                // marginTop: '-10px',
                              }}
                            >
                              <AddIcon />
                              ADD NEW PACKAGING OPTION
                            </Button>
                          }
                          removeButton={
                            <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                          }
                        >
                          <FormDataConsumer>
                            {({ getSource, scopedFormData, rest }) => (
                              <div>
                                <PackagingOption />
                                <Box mt="30px">
                                  <span className={classes.labelText}>Chargeable weights</span>
                                  <Box display="flex">
                                    <TextField
                                      label="Sea Freight"
                                      placeholder="-"
                                      variant="standard"
                                      className={classes.textField}
                                      source={getSource('chargeable_weight_sea')}
                                      {...rest}
                                      record={scopedFormData}
                                      disabled
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">kg</InputAdornment>
                                        ),
                                      }}
                                    />
                                    <TextField
                                      label="Air Freight"
                                      placeholder="-"
                                      variant="standard"
                                      // className={classes.textField}
                                      source={getSource('chargeable_weight_air')}
                                      {...rest}
                                      record={scopedFormData}
                                      disabled
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">kg</InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Box mt="30px">
                                  <span className={classes.labelText}>Packaging files</span>
                                  <Box flex={1}>
                                    <div
                                      {...getAdditionalRootProps({
                                        className: classes.additionalDropZone,
                                      })}
                                    >
                                      <input {...getAdditionalInputProps()} />
                                      <p>{`Drag & drop some files here, or click to select files`}</p>
                                    </div>
                                    <Box className={classes.additionalPreviewsContainer}>
                                      {additionalPreviews}
                                    </Box>
                                  </Box>
                                </Box>
                              </div>
                            )}
                          </FormDataConsumer>
                        </StyledSimpleFormIterator>
                      </ArrayInput>
                    </Box>
                  )}
                </FormDataConsumer>
              </StyledSimpleFormIterator>
            </ArrayInput>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Product Notes and Files</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <Box mt="30px" display="flex" flexDirection="column">
              <span className={classes.subtitle}>Shipping notes:</span>
              <TextInput
                label="Shipping notes"
                source="shipping_notes"
                className={classes.textInputFull}
                style={{
                  alignItems: 'start',
                }}
                multiline
                // rows={5}
                variant="standard"
              />
            </Box>
            <Box mt="30px">
              <span className={classes.labelText}>Shipping Declarations</span>
              <ArrayInput source="product_declarations" label="">
                <SimpleFormIterator
                  TransitionProps={{ enter: false, exit: false }}
                  addButton={
                    <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                      <AddIcon />
                    </Button>
                  }
                  removeButton={
                    <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                  }
                >
                  <FormDataConsumer>
                    {({ getSource, scopedFormData, rest }) => (
                      <Box display="flex">
                        <Box flex={1}>
                          <TextInput
                            source={getSource('declaration')}
                            {...rest}
                            record={scopedFormData}
                            label="Declaration"
                            fullWidth
                            variant="standard"
                            validate={required()}
                            margin="none"
                            className={classes.fieldInputLeft}
                          />
                        </Box>
                        <Box flex={2} ml="5px">
                          <FileInput
                            column
                            margin="none"
                            source={getSource('file')}
                            validate={required()}
                            {...rest}
                            record={scopedFormData}
                            classes={{
                              dropZone: classes.itemBoxDropZoneCert,
                              removeButton: classes.itemBoxDZRemoveDec,
                              preview: classes.itemBoxDZPreview,
                              root: classes.itemBoxDZRoot,
                            }}
                            label=""
                            addLabel="false"
                            accept="application/pdf"
                            placeholder={
                              <div className={classes.itemBoxUploadPlaceholder}>Upload</div>
                            }
                          >
                            <FileField source="url" title="filename" />
                          </FileInput>
                        </Box>
                      </Box>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
            <Box mt="30px" display="flex" flexDirection="column">
              <span className={classes.subtitle}>Quality notes:</span>
              <TextInput
                label="quality notes"
                source="notes"
                className={classes.textInputFull}
                style={{
                  alignItems: 'start',
                }}
                multiline
                // rows={5}
                variant="standard"
              />
            </Box>
            <Box mt="30px">
              <span className={classes.labelText}>Quality files</span>
              <Box flex={1}>
                <div {...getAdditionalRootProps({ className: classes.additionalDropZone })}>
                  <input {...getAdditionalInputProps()} />
                  <p>{`Drag & drop some files here, or click to select files`}</p>
                </div>
                <Box className={classes.additionalPreviewsContainer}>{additionalPreviews}</Box>
              </Box>
            </Box>
            <Box mt="30px" display="flex" flexDirection="column">
              <span className={classes.subtitle}>Product notes:</span>
              <TextInput
                label="Product notes"
                source="notes"
                className={classes.textInputFull}
                style={{
                  alignItems: 'start',
                }}
                multiline
                // rows={5}
                variant="standard"
              />
            </Box>
            <Box mt="30px">
              <span className={classes.labelText}>Product files</span>
              <Box flex={1}>
                <div {...getAdditionalRootProps({ className: classes.additionalDropZone })}>
                  <input {...getAdditionalInputProps()} />
                  <p>{`Drag & drop some files here, or click to select files`}</p>
                </div>
                <Box className={classes.additionalPreviewsContainer}>{additionalPreviews}</Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default FormData
