import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const globalConfigList = ({ context, params }) => {
  const variables = {}
  variables.where = {
    ...(params.fields && { setting_key: { _in: params.fields } }),
  }

  return {
    ...context,
    variables,
    query: gql`
      query globalConfigList($where: global_preferences_bool_exp) {
        global_preferences(where: $where) {
          setting_key
          setting_value
          setting_json
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}

export const configSectionList = ({ context, params }) => {
  const variables = {}
  variables.where = {
    ...(params?.filter?.platform && { platform: { _eq: params.filter.platform } }),
  }

  return {
    ...context,
    variables,
    query: gql`
      query configSectionList($where: config_section_bool_exp) {
        config_section(order_by: { position: asc_nulls_last }, where: $where) {
          id
          title
          position
          status
          type
          platform
          layout
          reference
          content(order_by: { position: asc_nulls_last }) {
            id
            action
            position
            url
            col_span
            row_span
            x_pos
            y_pos
            image {
              id
              filename
              url
            }
            offer {
              id
              name
              moq
              total_sold
              primary_photo {
                id
                filename
                url
              }
            }
            brand {
              id
              name
              top_banner_id
              top_banner {
                id
                filename
                url
              }
              hero_banner_id
              hero_banner {
                id
                filename
                url
              }
            }
            category {
              id
              name
              reference
              level
              image {
                id
                filename
                url
              }
              banner {
                id
                filename
                url
              }
              hero_banner {
                id
                filename
                url
              }
            }
            saleable_product_variant {
              id
              name
              image {
                id
                filename
                url
              }
            }
          }
        }
        config_section_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.config_section,
      total: data.config_section_aggregate.aggregate.count,
    }),
  }
}

export const platformConfig = ({ context, params }) => {
  const variables = {}
  // variables.where = {
  //   ...(params.platform && { setting_key: { _in: params.fields } }),
  // }

  return {
    ...context,
    variables,
    query: gql`
      query platformConfig {
        platform_configuration_by_pk(id: ${params.id}) {
          id
          configuration
          products (order_by: { position: asc_nulls_last}) {
            id
            type
            position
            type
            saleable_product_variant_id
            saleable_product_variant {
              id
              name
              url_slug
              is_listed
              stock
              image {
                id
                filename
                url
              }
              saleable_product {
                id
                retail_price
                total_stock
              }
            }
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.platform_configuration_by_pk,
    }),
  }
}
