import React from 'react'
import { required, Button, SaveButton, TextInput } from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  areaTextSelect: { padding: 0 },
  openDialogButton: {
    margin: '5px',
    fontSize: '12px',
    border: ' gray solid 1px',
    borderRadius: '4px',
    color: 'black',
  },
})

import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@material-ui/core'

function TagSelectWithCreateForm({
  handleClick,
  handleCloseClick,
  showDialog,
  handleSubmitWithRedirect,
  loading,
  saving,
  pristine,
}) {
  // const { values } = useFormState()
  const classes = useStyles()

  // console.log(values)

  return (
    <>
      <Button className={classes.openDialogButton} onClick={handleClick} label="ADD NEW TAG">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create Tag">
        <DialogTitle>Create Tag</DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box flex={2}>
              <TextInput variant="outlined" source="tag_name" validate={required()} fullWidth />
            </Box>
            <Box flex={1} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
            <IconCancel />
          </Button>
          <SaveButton
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            pristine={pristine}
            saving={saving}
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TagSelectWithCreateForm
