import React, { useState } from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import useStyles from './styles/index'
import FreightRatesCalc from './FreightRatesCalc'
const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

const AdminTools = () => {
  const [currentTab, setCurrentTab] = useState('one')
  const classes = useStyles()

  return (
    <Box m="10px 30px">
      <span
        style={{
          fontSize: '20px',
          marginTop: '30px',
          marginBottom: '30px',
        }}
      >
        Admin Tools
      </span>
      <Tabs
        value={currentTab}
        onChange={(e, v) => setCurrentTab(v)}
        aria-label="offers"
        style={{ marginTop: '20px' }}
      >
        <Tab
          value="one"
          label="Packaging Options Selector"
          wrapped
          style={{ width: '250px' }}
          className={currentTab === 'one' ? classes.tabButton : null}
          // {...a11yProps('one')}
        />
        <Tab
          value="two"
          label="Freight Rates Calculator"
          style={{ width: '250px' }}
          wrapped
          className={currentTab === 'two' ? classes.tabButton : null}
        />
      </Tabs>
      <TabPanel value={currentTab} index="one">
        <div />
      </TabPanel>
      <TabPanel value={currentTab} index="two">
        <FreightRatesCalc />
      </TabPanel>
    </Box>
  )
}

export default AdminTools
