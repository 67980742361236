import React from 'react'
import { DateInput, TextInput, ReferenceInput, SelectInput, required } from 'react-admin'
import { Box } from '@material-ui/core'
import SectionTitle from '../../components/SectionTitle'
import { getDisplayName, getDisplayAddress } from '../../../utils/utils'

const MainBox = ({ record }) => (
  <Box display="flex" flexDirection="column">
    <Box>
      <SectionTitle label={record.name} />
    </Box>
    <Box display="flex">
      <Box flex={1} display="flex" flexDirection="column">
        <TextInput fullWidth source="name" label="Company Name" validate={[required()]} />
        <TextInput fullWidth source="legal_name" label="Legal Name" />
        <TextInput fullWidth source="legal_representative" label="Legal Representative" />
        <TextInput
          fullWidth
          source="business_registration_number"
          label="Business Registration Number"
        />
        <TextInput fullWidth source="website" label="Website" />
        <DateInput disabled label="Date created" source="created_at" />
      </Box>
      <Box flex={2} />
      <Box flex={1} display="flex" flexDirection="column">
        <SelectInput
          fullWidth
          choices={[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
          ]}
          optionValue="value"
          source="allow_global_notifications"
          label="Allow Global Notifications"
        />

        <ReferenceInput
          label="Organisation Owner"
          source="owner_user_id"
          reference="app_user"
          validate={[required()]}
          filter={{ clientId: record.id }}
          perPage={100}
        >
          <SelectInput optionText={(record) => getDisplayName(record)} fullWidth />
        </ReferenceInput>

        <ReferenceInput
          label="Default Delivery Address"
          source="default_delivery_address_id"
          reference="address"
          filter={{ organisation_id: record.id }}
          perPage={100}
        >
          <SelectInput fullWidth optionText={(record) => getDisplayAddress(record)} />
        </ReferenceInput>
        <ReferenceInput
          label="Default Billing Address"
          source="default_billing_address_id"
          reference="address"
          filter={{ organisation_id: record.id }}
          perPage={100}
        >
          <SelectInput fullWidth optionText={(record) => getDisplayAddress(record)} />
        </ReferenceInput>
        <SelectInput
          fullWidth
          choices={[
            { name: 'Public', value: true },
            { name: 'Private', value: false },
          ]}
          optionValue="value"
          source="is_public"
          label="Visibility"
        />
        <ReferenceInput
          label="Default SCA"
          source="default_sca_id"
          reference="users"
          filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
          perPage={100}
        >
          <SelectInput fullWidth optionText={(record) => getDisplayName(record)} />
        </ReferenceInput>
      </Box>
      <Box flex={2} />
    </Box>
  </Box>
)

export default MainBox
