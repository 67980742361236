import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const userList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  // console.log('BQ', params)

  const variables = {}
  // (!!v.id && { id: v.id })

  if (params.id || params.email || params.filter?.roles || params.filter?.q) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.email ||
        (params.filter?.q && {
          email: { _ilike: `%${params.email || params.filter?.q || ''}%` },
        })),
      ...(!!params.filter?.roles && { roles: params.filter.roles }),
    }
  }
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    query: gql`
          query user ($where: user_bool_exp) {
            users: user(where: $where, limit: ${
              params.pagination.perPage
            }, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              id
              email
              forename
              surname
              username
              created_at
              updated_at
              code
              status
              preferences {
                id
                data_key
                data_val
                data_type
              }
              active_role_id
              active_role {
                id
                name
              }
              roles {
                id
                role {
                  id
                  name
                }
              }
              roles_aggregate {
                aggregate {
                  count
                }
              }
            }
            total: user_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    variables,
    parseResponse: ({ data }) => {
      if (data && data.users) {
        // console.log("data", data)
        return {
          data: data.users,
          total: data.total.aggregate.count,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const userAssignRole = ({ context, params }) => {
  return {
    ...context,
    query: gql`
        mutation assignRoles {
          assignRole(user: ${params.userID}, roles: ${params.roles})
        }
      `,
    variables: {},
    parseResponse: ({ data }) => ({ data }),
  }
}

export const userGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  // console.log(params)
  return {
    ...context,
    query: gql`
          query getUser {
            user_by_pk(id: ${params.id}) {
              id
              email
              forename
              surname
              username
              code
              created_at
              updated_at
              status
              preferences {
                id
                data_key
                data_val
                data_type
              }
              active_role_id
              active_role {
                id
                name
              }
              roles {
                id
                role {
                  id
                  name
                }
              }
              roles_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        `,
    variables: {},
    parseResponse: ({ data }) => ({
      data: data.user_by_pk,
      // validUntil,
    }),
  }
}

export const userGetMany = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  // console.log(params)

  const ids = typeof params.ids[0] === 'object' ? params.ids.map((item) => item.id) : params.ids
  return {
    ...context,
    query: gql`
          query getManyUsers {
            user(where: {id: {_in: ${ids}}}) {
              id
              username
              email
              forename
              surname
            }
          }
        `,
    variables: {},
    parseResponse: ({ data }) => ({
      data: data.user,
      // validUntil,
    }),
  }
}

export const roleList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'name'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  if (params.id || params.filter?.user_id) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter?.user_id && {
        user_roles: { user_id: { _eq: params.filter?.user_id } },
      }),
    }
  }
  return {
    ...context,
    variables,
    query: gql`
      query getRoles ($where: role_bool_exp) {
       role(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
            id
            name
          }
          role_aggregate (where: $where) {
            aggregate {
              count
            }
          }
        }
    `,
    parseResponse: ({ data }) => ({
      data: data.role,
      total: data.role_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const userRoleList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'name'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  if (params.id || params.filter?.role_name || params.filter?.role || params.filter?.userId) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter?.userId && {
        user_id: { _eq: params.filter?.userId },
      }),
      ...(params.filter?.role && { role: { _eq: params.filter?.role } }),
      ...(params.filter?.role_name && {
        role: {
          name: { _ilike: `%${params.filter?.role_name}%` },
        },
      }),
    }
  }
  return {
    ...context,
    variables,
    query: gql`
          query getUserRoles ($where: user_role_bool_exp) {
           user_role(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                role {
                  id
                  name
                }
              }
              user_role_aggregate (where: $where) {
                aggregate {
                  count
                }
              }
            }
        `,
    parseResponse: ({ data }) => ({
      data: data.user_role,
      total: data.user_role_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}
export const userRoleGet = ({ context, params, variables }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables,
    query: gql`
          query getOneOrganisationMember ($where: user_role_bool_exp) {
            user_role_by_pk(id: ${params.id}) {
                id
                role {
                  id
                  name
                }
              }
            }
        `,
    parseResponse: ({ data }) => ({
      data: data.user_role_by_pk,
      // validUntil,
    }),
  }
}

export const userRoleCreate = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation addOrganisationMember {
            insert_user_role_one(
            object: {
              user_id: ${params.objects?.user_id}
              role_id: ${params.objects?.role_id}
            }
          ){
            id
          }
        }
      `,
    parseResponse: ({ data, errors }) => {
      return {
        data: data.insert_user_role_one,
        errors,
      }
    },
  }
}

export const appUserStripeList = ({ context }) => {
  return {
    ...context,
    query: gql`
      query getAppUsers {
        app_user(
          where: { default_organisation: { stripe_connected_account_id: { _is_null: false } } }
        ) {
          id
          forename
          surname
          email
          default_organisation_id
          default_organisation {
            id
            name
            stripe_connected_account_id
            stripe_customer_account_id
          }
        }
      }
    `,
    variables: {},
    parseResponse: ({ data }) => ({ data }),
  }
}

export const appUserList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'email'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  if (params.filter?.status || params.filter?.clientId || params.id || params.email) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter?.clientId && {
        member_organisations: { organisation_id: { _eq: params.filter?.clientId } },
      }),
      ...(params.filter?.status && { status: { _in: params.filter?.status } }),
      ...(params.email && { email: { _ilike: `%${params.email}%` } }),
    }
  }
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
          query getAppUsers ($where: app_user_bool_exp) {
           app_user(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                forename
                surname
                username
                email
                emails_enabled
                user_id
                code
                created_at
                updated_at
                default_organisation_id
                default_organisation {
                  id
                  name
                }
                member_organisations {
                  id
                  role
                  code
                  organisation {
                    id
                    name
                  }
                }
                member_organisations_aggregate {
                  aggregate {
                    count
                  }
                }
              }
              app_user_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.app_user,
      total: data.app_user_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const appUserGet = ({ context, params, variables }) => {
  return {
    ...context,
    variables,
    query: gql`
          query getAppUser ($where: app_user_bool_exp) {
            app_user_by_pk(id: ${params.id}) {
              id
              forename
              surname
              username
              email
              emails_enabled
              user_id
              code
              created_at
              updated_at
              default_organisation_id
              default_organisation {
                id
                name
              }
              member_organisations {
                id
                role
                code
                organisation {
                  id
                  name
                }
              }
              member_organisations_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
      `,
    parseResponse: ({ data }) => ({
      data: data.app_user_by_pk,
    }),
  }
}

export const adminUserPreferencesGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getUserPrefs($id: String!) {
        user(where: { auth0_user_id: { _eq: $id } }) {
          id
          surname
          forename
          email
          username
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.user[0],
      // validUntil,
    }),
  }
}
