import set from 'lodash/set'
import omit from 'lodash/omit'
import {
  GET_ONE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE_MANY,
} from './fetchActions'

import getFinalType from './getFinalType'

function keyLike(obj) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      keyLike(obj[key])
    } else if (key === '_like' || key === '_ilike') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = `%${obj[key]}%`
    }
  })
}

// eslint-disable-next-line no-unused-vars
const buildGetListVariables = (introspectionResults) => (resource, aorFetchType, params) => {
  const result = {}
  let { filter: filterObj = {} } = params
  const { customFilters = [] } = params

  /**
         keys with comma separated values
        {
            'title@ilike,body@like,authors@similar': 'test',
            'col1@like,col2@like': 'val'
        }
     */
  const orFilterKeys = Object.keys(filterObj).filter((e) => e.includes(','))
  /**
        format filters
        {
            'title@ilike': 'test',
            'body@like': 'test',
            'authors@similar': 'test',
            'col1@like': 'val',
            'col2@like': 'val'
        }
    */
  const orFilterObj = orFilterKeys.reduce((acc, commaSeparatedKey) => {
    const keys = commaSeparatedKey.split(',')
    return {
      ...acc,
      ...keys.reduce((acc2, key) => {
        return {
          ...acc2,
          [key]: filterObj[commaSeparatedKey],
        }
      }, {}),
    }
  }, {})
  filterObj = omit(filterObj, orFilterKeys)
  const filterReducer = (obj) => (acc, key) => {
    let filter
    if (key === 'ids') {
      filter = { id: { _in: obj.ids } }
    } else if (Array.isArray(obj[key])) {
      filter = { [key]: { _in: obj[key] } }
    } else if (obj[key] && obj[key].format === 'hasura-raw-query') {
      filter = { [key]: obj[key].value || {} }
    } else {
      // eslint-disable-next-line prefer-const
      let [keyName, operation = ''] = key.split('@')
      // console.log('KEYNAME:', keyName, 'OP:', operation)
      const field = resource.type.fields.find((f) => f.name === keyName)
      // console.log(field, resource, aorFetchType, params)
      switch (getFinalType(field.type).name) {
        case 'String':
          operation = operation || '_ilike'
          filter = {
            [keyName]: { [operation]: operation.includes('like') ? `%${obj[key]}%` : obj[key] },
          }
          break
        default:
          keyLike(obj[key])
          filter = { [keyName]: { [operation || '_eq']: obj[key] } }
      }
    }
    return [...acc, filter]
  }
  const andFilters = Object.keys(filterObj).reduce(filterReducer(filterObj), customFilters)
  const orFilters = Object.keys(orFilterObj).reduce(filterReducer(orFilterObj), [])

  result.where = { _and: andFilters, ...(orFilters.length && { _or: orFilters }) }

  if (params.pagination) {
    result.limit = parseInt(params.pagination.perPage, 10)
    result.offset = parseInt((params.pagination.page - 1) * params.pagination.perPage, 10)
  }

  if (params.sort) {
    result.order_by = set({}, params.sort.field, params.sort.order.toLowerCase())
  }

  return result
}

// eslint-disable-next-line no-unused-vars
const buildUpdateVariables = (resource, aorFetchType, params, queryType) =>
  Object.keys(params.data).reduce((acc, key) => {
    // If hasura permissions do not allow a field to be updated like (id),
    // we are not allowed to put it inside the variables
    // RA passes the whole previous Object here
    // https://github.com/marmelab/react-admin/issues/2414#issuecomment-428945402

    // console.log(params, resource, acc, key)

    // TODO: To overcome this permission issue,
    // it would be better to allow only permitted inputFields from *_set_input INPUT_OBJECT
    if (params.previousData && params.data[key] === params.previousData[key]) {
      return acc
    }

    if (resource.type.fields.some((f) => f.name === key)) {
      return {
        ...acc,
        [key]: params.data[key],
      }
    }

    if (typeof params.data[key] === 'boolean') {
      return {
        ...acc,
        [key]: params.data[key],
      }
    }

    return acc
  }, {})

// eslint-disable-next-line no-unused-vars
const buildCreateVariables = (resource, aorFetchType, params, queryType) => params.data

export default (introspectionResults) => (resource, aorFetchType, params, queryType) => {
  // console.log(resource, params)
  const id = params.id || params.data?.id
  switch (aorFetchType) {
    case GET_LIST:
      return buildGetListVariables(introspectionResults)(resource, aorFetchType, params, queryType)
    case GET_MANY_REFERENCE: {
      const built = buildGetListVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType,
      )
      if (params.filter) {
        return {
          ...built,
          where: {
            _and: [
              ...built.where._and,
              {
                [params.target]: {
                  _eq: params.id ? parseInt(params.id) : parseInt(params.data.id),
                },
              },
            ],
          },
        }
      }
      return {
        ...built,
        where: {
          [params.target]: { _eq: params.id ? parseInt(params.id) : parseInt(params.data.id) },
        },
      }
    }
    case GET_MANY:
      if (params.where) {
        return {
          ...params,
        }
      }
      return {
        where: { id: { _in: params.ids } },
      }

    case DELETE_MANY:
      // console.log(params.ids)
      if (!params.ids || params.ids?.length < 1) {
        throw new Error('Invalid data request, missing Ids')
      }
      return {
        where: { id: { _in: params.ids } },
      }

    case GET_ONE:
      return {
        where: { id: { _eq: parseInt(params.id) } },
        limit: 1,
      }

    case DELETE:
      // console.log(params)
      if (!params.id && !params.data?.id) {
        if (!params.where && !params.data?.where) {
          throw new Error('Invald data request, missing Ids or where')
        } else {
          return {
            where: params.where || params.data.where,
          }
        }
      }
      return {
        where: { id: { _eq: params.id ? parseInt(params.id) : parseInt(params.data.id) } },
      }
    case CREATE:
      // console.log(params, resource, queryType)
      // console.log(buildCreateVariables(resource, aorFetchType, params, queryType))
      return {
        objects: buildCreateVariables(resource, aorFetchType, params, queryType),
        ...(params.on_conflict && { on_conflict: params.on_conflict }),
      }

    case UPDATE:
      if (!params.data?.id && !params.where) {
        console.log('ERROR', queryType, params)
        throw new Error('Missing ID')
      }
      delete params.id
      delete params.data?.id

      // console.log({
      //   _set: buildUpdateVariables(resource, aorFetchType, params, queryType),
      //   where: { id: { _eq: id } },
      // })

      if (params._inc) {
        const where = params.where
        const _inc = params._inc

        delete params.where
        delete params._inc

        // console.log('incrementing', { _inc, where })
        // return null
        // eslint-disable-next-line no-unreachable
        return {
          _inc,
          where,
        }
      }

      if (params.where) {
        const where = params.where
        delete params.where

        // console.log('_set:', buildUpdateVariables(resource, aorFetchType, params, queryType))
        // console.log(where)
        // return null
        return {
          _set: buildUpdateVariables(resource, aorFetchType, params, queryType),
          where,
        }
      }

      // console.log(id, buildUpdateVariables(resource, aorFetchType, params, queryType))
      // console.log(`_set: ${buildUpdateVariables(resource, aorFetchType, params, queryType)},
      // where: { id: { _eq: ${id} } },`)
      // return null

      // eslint-disable-next-line no-unreachable
      return {
        _set: buildUpdateVariables(resource, aorFetchType, params, queryType),
        where: { id: { _eq: id } },
      }

    case UPDATE_MANY:
      return 'Unsupported'
    // console.log(params, aorFetchType, queryType)
    // if (!params.id || !params.data?.id) {
    //   throw new Error('Missing ID')
    // }
    // eslint-disable-next-line no-case-declarations
    // const vals = {
    //   updates: params.data
    //     .filter((opt) => !!opt.id)
    //     .map((el) => {
    //       const obj = {}
    //       obj.where = { id: { _eq: el.id } }
    //       // console.log(resource, aorFetchType, el, queryType)

    //       delete el.id
    //       obj._set = buildUpdateVariables(resource, aorFetchType, { data: el }, queryType)
    //       console.log(obj)
    //       return obj
    //     }),
    // }
    // // console.log(vals)
    // return vals
    default:
      return {}
  }
}
