import React from 'react'
import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import SetupForm from './SetupForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
)

const CheckoutIntent = ({ clientSecret }) => {
  const options = {
    // passing the SetupIntent's client secret
    clientSecret:
      clientSecret || 'seti_1OcOWhG3YCdXCMUgg6s6QP6H_secret_PRH4NfIoI64HbRqRY9krPKpWJmnXGCg',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm />
    </Elements>
  )
}

const SetupForm = () => {
  return (
    <form>
      <PaymentElement />
      <button>Submit</button>
    </form>
  )
}

export default CheckoutIntent

// import React from 'react'
// import { Elements, PaymentElement, useStripe } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js'
// // import SetupForm from './SetupForm';
// import { STRIPE_SIM_REDIRECT_URL } from '../../../../dataProvider/api'

// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
// )

// const CheckoutIntent = ({ id }) => {
//   const stripe = useStripe()

//   const options = {
//     // passing the SetupIntent's client secret
//     clientSecret: 'seti_1ObzmKG3YCdXCMUgra8dg7NZ_secret_PQrVzmto6wgVWiafqvtlSCgV8fQ6Zo1',
//     // Fully customizable with appearance API.
//     appearance: {
//       /*...*/
//     },
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()

//     console.log('here!!!')

//     const { error } = await stripe.confirmSetup({
//       confirmParams: {
//         // Make sure to change this to your payment completion page
//         return_url: `${STRIPE_SIM_REDIRECT_URL}/offer/${id}`,
//       },
//     })

//     // This point will only be reached if there is an immediate error when
//     // confirming the payment. Otherwise, your customer will be redirected to
//     // your `return_url`. For some payment methods like iDEAL, your customer will
//     // be redirected to an intermediate site first to authorize the payment, then
//     // redirected to the `return_url`.
//     if (error.type === 'card_error' || error.type === 'validation_error') {
//       // setMessage(error.message)
//     } else {
//       // setMessage('An unexpected error occurred.')
//     }
//   }

//   return (
//     <Elements stripe={stripePromise} options={options}>
//       <SetupForm handleSubmit={handleSubmit} />
//     </Elements>
//   )
// }

// const SetupForm = ({ handleSubmit }) => {
//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <button>Submit</button>
//     </form>
//   )
// }

// export default CheckoutIntent
