import React, { useCallback } from 'react'
import { useFormState, useForm } from 'react-final-form'
import { SaveButton, useRedirect } from 'react-admin'

const CustomSaveButton = (props) => {
  const redirectTo = useRedirect()
  const { redirect, handleSubmit } = props

  // const notify = useNotify()

  const formState = useFormState()
  const form = useForm()
  const [saving, setSaving] = React.useState(false)
  const handleClick = useCallback(async () => {
    const abortController = new AbortController()
    if (!abortController.signal.aborted) {
      setSaving(true)
    }
    if (formState.invalid) {
      form.submit()
    } else {
      await handleSubmit(formState.values, formState)
      if (redirect) {
        redirectTo(redirect)
      }
    }
    if (!abortController.signal.aborted) {
      setSaving(false)
    }
  }, [form, formState, redirectTo, redirect, handleSubmit])

  return <SaveButton {...props} saving={saving} handleSubmitWithRedirect={handleClick} />
}

export default CustomSaveButton
