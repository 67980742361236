import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  zone: {
    alignItems: 'center',
    border: '2px dashed #CCC',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
    cursor: 'pointer',
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '80%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: '#686868',
  },
  default: {
    borderColor: '#CCC',
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
  primaryPhoto: {
    border: 'dashed gray 2px',
    width: '280px',
    height: '280px',
    position: 'absolute',
    zIndex: '-1',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    maxWidth: '21vw',
    margin: '10px 0px 5px',
  },
  previewImageBox: {
    margin: '2px',
    width: '85px',
    height: '85px',
    minWidth: '85px',
    minHeight: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
    position: 'relative',
  },
  previewImage: {
    '&:hover': {
      border: 'gray solid 2px',
    },
    zIndex: '100',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    cursor: 'pointer',
  },
  deleteBtn: {
    position: 'absolute',
    top: '0px',
    left: '45px',
    zIndex: '100',
  },
  previewImageSelected: {
    border: 'black solid 5px',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  imageDropZone: {
    width: '280px',
    height: '280px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'dashed gray 2px',
    cursor: 'pointer',
  },
}))
