import React from 'react'
import { Toolbar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import CustomSaveButton from './CustomSaveButton'

const styles = {
  customToolbar: { backgroundColor: 'transparent' },
  customSaveButton: {
    height: '47px',
    textTransform: 'uppercase',
    fontSize: 16,
    backgroundColor: '#00B3C3',
    '&:hover': {
      backgroundColor: '#21C6CF',
    },
  },
}

const useStyles = makeStyles(styles)

const CustomToolbar = (props) => {
  const classes = useStyles()
  return (
    <Toolbar
      record={props.record}
      basePath={props.basePath}
      undoable
      resource={props.redirect}
      invalid={props.invalid}
      handleSubmit={props.handleSubmit}
      saving={props.saving}
      className={classes.customToolbar}
    >
      <CustomSaveButton
        submitOnEnter={false}
        invalid={props.invalid}
        basePath={props.basePath}
        saving={props.saving}
        redirect={props.redirect}
        handleSubmit={props.handleClick}
        icon={<></>}
        label="Submit"
        color="primary"
        className={classes.customSaveButton}
      />
    </Toolbar>
  )
}

export default CustomToolbar
