import React, { useState, useEffect, cloneElement } from 'react'
import {
  FormWithRedirect,
  useCreateController,
  CreateContextProvider,
  useGetIdentity,
  TextInput,
  SelectInput,
  BooleanInput,
  useDataProvider,
  // useNotify
} from 'react-admin'
import { connect } from 'getstream'
import {
  // TextField,
  Box,
  Button,
  // InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import { useDropzone } from 'react-dropzone'
import { useForm, useFormState } from 'react-final-form'
import { uniqueId } from 'lodash'
import moment from 'moment'
import uploadFile from '../../dataProvider/upload'
import { GS_API_KEY, GS_APP_ID } from '../../dataProvider/api'

const MyCreate = (props) => {
  // console.log(props)
  const createControllerProps = useCreateController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = createControllerProps
  return (
    <CreateContextProvider value={createControllerProps}>
      <div>
        {/* <h1>{defaultTitle}</h1> */}
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </CreateContextProvider>
  )
}

export const GSFeed = ({ feedType, id, data, sca }) => {
  const { identity } = useGetIdentity()

  const [feed, setFeed] = useState('')
  const [createSRActivityOpen, setCreateSRActivityOpen] = useState(false)
  const [createPOActivityOpen, setCreatePOActivityOpen] = useState(false)
  const [activities, setActivities] = useState([])

  // const [comments, setComments] = useState('')
  const [loading, setLoading] = useState(false)

  const userId = identity?.code
  const abortController = new AbortController()

  useEffect(() => {
    const init = async () => {
      const client = connect(GS_API_KEY, identity.gsToken, GS_APP_ID)

      const feed = client ? client.feed(feedType, id, identity.gsToken) : null

      !abortController.signal.aborted && setFeed(feed)

      feed
        .get({ limit: 5 })
        .then((resp) => {
          // console.log(resp)
          const newActivities = []

          resp.results?.map((item) => {
            if (!activities.some((act) => act.id === item.id)) {
              newActivities.push(
                feedType === 'sourcing_request' ? (
                  <SourcingRequestActivityTemplate key={`ab${item.id}`} {...item} />
                ) : (
                  <PurchaseOrderActivityTemplate key={`pb${item.id}`} {...item} />
                ),
              )
            }
          })

          !abortController.signal.aborted && setActivities(newActivities)
        })
        .catch((err) => {
          console.log(err)
        })

      const callback = (newData) => {
        // console.log('newData', newData)

        if (newData.new?.length > 0) {
          !abortController.signal.aborted &&
            setActivities((prevState) => {
              const newActivities = []

              newData.new?.map((item) => {
                if (!activities.some((act) => act.id === item.id)) {
                  newActivities.push(
                    feedType === 'sourcing_request' ? (
                      <SourcingRequestActivityTemplate key={`ab${item.id}`} {...item} />
                    ) : (
                      <PurchaseOrderActivityTemplate key={`pb${item.id}`} {...item} />
                    ),
                  )
                }
              })

              const newState = [...newActivities, ...prevState]
              return newState
            })
        }
      }

      const successCallback = () => {
        // console.log(`now listening to changes in realtime for ${id}`)
      }

      const failCallback = (error) => {
        alert('something went wrong, check the console logs')
        console.log(error)
      }

      await feed?.subscribe(callback).then(successCallback, failCallback)
    }

    identity && id && !feed && init()
  }, [identity])

  const loadMore = async () => {
    // console.log('Load more')

    if (!feed) {
      return null
    }

    feed
      .get({ limit: 5, offset: activities.length })
      .then((resp) => {
        // console.log(resp)
        const newActivities = []

        resp.results?.map((item) => {
          // console.log(item)
          if (!activities.some((act) => act.id === item.id)) {
            newActivities.push(
              feedType === 'sourcing_request' ? (
                <SourcingRequestActivityTemplate key={`ab${item.id}`} {...item} />
              ) : (
                <PurchaseOrderActivityTemplate key={`pb${item.id}`} {...item} />
              ),
            )
          }
        })

        !abortController.signal.aborted && setActivities([...activities, ...newActivities])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //   console.log(userId, identity, id)
  if (!id || !identity) return null

  // console.log('Activities', activities)

  return (
    <Box>
      <span style={{ fontSize: '24px' }}>Activity Feed</span>

      {feedType === 'sourcing_request' ? (
        <Box display="flex">
          <Button
            style={{
              color: 'white',
              backgroundColor: '#00B3C3',
              height: '35px',
              marginTop: '8px',
            }}
            disabled={loading}
            onClick={() => {
              !abortController.signal.aborted && setCreateSRActivityOpen(true)
            }}
          >
            Add Event
          </Button>
          <SourcingRequestActivityCreateForm
            feed={feed}
            userId={userId}
            identityId={identity.userId}
            createSRActivityOpen={createSRActivityOpen}
            setCreateSRActivityOpen={setCreateSRActivityOpen}
            loading={loading}
            setLoading={setLoading}
            data={data}
            sca={sca}
          />
        </Box>
      ) : (
        <Box display="flex">
          <Button
            style={{
              color: 'white',
              backgroundColor: '#00B3C3',
              height: '35px',
              marginTop: '8px',
            }}
            disabled={loading}
            onClick={() => {
              !abortController.signal.aborted && setCreatePOActivityOpen(true)
            }}
          >
            Add Event
          </Button>

          <MyCreate resource="purchase_order" basePath="/order">
            <FormWithRedirect
              // {...props}
              initialValues={{}}
              render={(formProps) => (
                <PurchaseOrderActivityCreateForm
                  formProps={formProps}
                  feed={feed}
                  userId={userId}
                  createPOActivityOpen={createPOActivityOpen}
                  setCreatePOActivityOpen={setCreatePOActivityOpen}
                  loading={loading}
                  setLoading={setLoading}
                  data={data}
                />
              )}
            />
          </MyCreate>
        </Box>
      )}
      {activities}

      <Button onClick={() => loadMore()}>Load more</Button>
    </Box>
  )
}

const SourcingRequestActivityCreateForm = ({
  feed,
  userId,
  // identityId,
  createSRActivityOpen,
  setCreateSRActivityOpen,
  loading,
  setLoading,
  data,
  sca,
}) => {
  const dataProvider = useDataProvider()
  const [type, setType] = useState('')
  const [quote, setQuote] = useState('')
  const abortController = new AbortController()
  const { values } = useFormState()

  const addEvent = async () => {
    // console.log('Add', type, userId, data, identityId)

    if (
      loading ||
      !feed ||
      (type === 'Comment' && !values.comments) ||
      (type === 'Question' && !values.comments)
    ) {
      !abortController.signal.aborted && setLoading(false)
      return null
    }

    const newActivity = {
      actor: `SU:${userId}`,
      verb: type,
      object: `sourcing_request`,
      ...(values.comments && { body: values.comments }),
      ...((type === 'Quote Updated' || type === 'Quote Feedback') && {
        quote_id: quote.id,
        product_name: quote.product?.name,
        quote_prices:
          quote.product?.product_quotes?.length > 0 &&
          quote.product.product_quotes[0].scm_quotes?.length > 0 &&
          quote.product.product_quotes[0].scm_quotes[0].prices,
        product_photo: quote.product?.primary_photo?.url,
        product_id: quote.product?.id,
      }),
      ...(type === 'Report Submission' && {
        report: data.report?.length > 100 ? `${data.report.substring(0, 97)}...` : data.report,
        quotes: data.quotes?.map((quote) => {
          return {
            quote_id: quote.id,
            product_name: quote.product?.name,
            quote_prices:
              quote.product?.product_quotes?.length > 0 &&
              quote.product.product_quotes[0].scm_quotes?.length > 0 &&
              quote.product.product_quotes[0].scm_quotes[0].prices,
            product_photo: quote.product?.primary_photo?.url,
            product_id: quote.product?.id,
          }
        }),
      }),
      ...(type === 'SCA Introduction' && {
        sca_name:
          sca.forename && sca.surname
            ? `${sca.forename} ${sca.surname}`
            : sca.email.split('@')[0].replace('.', ' '),
      }),
    }
    // console.log('activity', newActivity)
    await feed.addActivity(newActivity)
    // console.log(resp)
    // !abortController.signal.aborted && setComments('')

    if (values.sendNotifications) {
      data.organisation?.members?.map(async (member) => {
        // console.log('MEMBER', member)
        const scaName =
          sca?.forename && sca?.surname
            ? `${sca?.forename} ${sca?.surname}`
            : sca?.email?.split('@')[0]?.replace('.', ' ') || null

        const params = {
          userCode: member.code,
          actorCode: userId,
          verb: type,
          object: 'sourcing_request',
          foreignId: `${data.sourcingRequestId}`,
          scaName,
          referencePhoto: quote.product?.primary_photo?.url || null,
          productName: quote.product?.name || null,
          clientName: data.organisation?.name,
        }

        // console.log(params)
        await dataProvider.create('notification', {
          ...params,
        })

        if (values.sendAlerts) {
          const pushParams = {
            userId: member.app_user_id,
            title: type,
            message: `Sourcing Request ${data.sourcingRequestId}: ${values.comments || type}`,
            scaName,
            referencePhoto: quote.product?.primary_photo?.url || null,
            productName: quote.product?.name || null,
            clientName: data.organisation?.name,
          }
          await dataProvider.create('push_notification', {
            ...pushParams,
          })
        }

        // console.log(resp)
      })
    }

    !abortController.signal.aborted && setLoading(false)
  }

  // console.log('Data', data)
  // console.log('Quote', quote)

  return (
    <Dialog
      open={createSRActivityOpen}
      onClose={() => !abortController.signal.aborted && setCreateSRActivityOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add an activity</DialogTitle>
      {/* <form onSubmit={handleSubmit}> */}
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Select
            value={type ?? ''}
            onChange={(e) => !abortController.signal.aborted && setType(e.target.value)}
          >
            {/* <MenuItem value={'Sourcing Request Created'}>Sourcing Request Created</MenuItem> */}
            <MenuItem value={'Report Submission'}>Report Submission</MenuItem>
            <MenuItem value={'Quote Updated'}>Quote Updated</MenuItem>
            <MenuItem value={'Quote Feedback'}>Quote Feedback</MenuItem>
            <MenuItem value={'Project Update'}>Project Update</MenuItem>
            <MenuItem value={'Question'}>Question</MenuItem>
            <MenuItem value={'Comment'}>Comment</MenuItem>
            <MenuItem value={'SCA Introduction'}>SCA Introduction</MenuItem>
          </Select>
          {(type === 'Quote Updated' || type === 'Quote Feedback') && (
            <Select
              value={quote ?? ''}
              onChange={(e) => !abortController.signal.aborted && setQuote(e.target.value)}
            >
              {data.quotes?.map((quote) => (
                <MenuItem key={`qup${quote.id}`} value={quote}>
                  {quote.product?.name}
                </MenuItem>
              ))}
              {/* <MenuItem value={'Sourcing Request Created'}>Sourcing Request Created</MenuItem>
              <MenuItem value={'Report Submission'}>Report Submission</MenuItem>
              <MenuItem value={'Quote Updated'}>Quote Updated</MenuItem>
              <MenuItem value={'Quote Feedback'}>Quote Feedback</MenuItem>
              <MenuItem value={'Project Update'}>Project Update</MenuItem>
              <MenuItem value={'Question'}>Question</MenuItem>
              <MenuItem value={'Comment'}>Comment</MenuItem> */}
            </Select>
          )}
          {(type === 'Quote Feedback' ||
            type === 'Project Update' ||
            type === 'Quote Updated' ||
            type === 'Comment' ||
            type === 'SCA Introduction' ||
            type === 'Question') && (
            // <TextField
            //   id="content"
            //   label="Comments"
            //   multiline
            //   variant="standard"
            //   fullWidth
            //   maxRows={5}
            //   value={values.comments}
            //   onChange={(e) => !abortController.signal.aborted && setComments(e.target.value)}
            //   InputProps={{
            //     endAdornment: loading ? (
            //       <InputAdornment position="end">
            //         <CircularProgress style={{ width: '20px', height: '20px' }} />
            //       </InputAdornment>
            //     ) : null,
            //   }}
            //   onKeyDown={async (e) => {
            //     if (e.keyCode === 13 && !e.shiftKey) {
            //       e.preventDefault()
            //       await setLoading(true)
            //       await addEvent(type)
            //       !abortController.signal.aborted && setCreateSRActivityOpen(false)
            //     }
            //   }}
            // />
            <TextInput
              variant="standard"
              fullWidth
              // className={classes.selectBoxWidth}
              inputProps={{
                maxLength: 100,
              }}
              source="comments"
              label="Comments:"
              style={{ marginRight: '10px', maxWidth: '200px' }}
            />
          )}
        </Box>

        <BooleanInput source="sendNotifications" />
        <BooleanInput source="sendAlerts" />
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            color: 'white',
            backgroundColor: '#00B3C3',
            height: '35px',
            marginTop: '8px',
          }}
          disabled={
            loading ||
            (type === 'Comment' && !values.comments) ||
            (type === 'Question' && !values.comments)
          }
          onClick={async () => {
            !abortController.signal.aborted && (await setLoading(true))
            await addEvent(type)
            !abortController.signal.aborted && setCreateSRActivityOpen(false)
          }}
        >
          Send
          {loading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  )
}

const SourcingRequestActivityTemplate = (props) => {
  const {
    id,
    verb,
    time,
    actor,
    body = '',
    quotes = [],
    report = '',
    quote_id,
    product_name,
    quote_prices,
    product_photo,
    target_price,
    target_quantity_min,
    target_quantity_max,
    sourcing_request_id,
    required_date,
    sca_name,
    // ...rest
  } = props
  // console.log(verb)
  // console.log(rest)
  switch (verb) {
    case 'Create Sourcing Request':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
          >
            <Box style={{ width: '50px', height: '50px' }}>
              <img src={product_photo} style={{ maxWidth: '50px', maxHeight: '50px' }} alt="" />
            </Box>
            <Box ml="10px" display="flex" flexDirection="column">
              <span style={{ fontSize: '12px' }}>SR-{sourcing_request_id}</span>
              <span style={{ fontSize: '12px' }}>{product_name}</span>
              <Box alignItems="center" justifyContent="space-between">
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="center"
                  alignItems="center"
                  mt="5px"
                >
                  <span style={{ fontSize: '10px' }}>Req. Qty / Price</span>
                  <span style={{ fontSize: '10px' }}>
                    {target_quantity_min || target_quantity_max} @
                    {target_price ? `${target_price}` : 'N/A'}
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="center"
                  alignItems="center"
                >
                  <span style={{ fontSize: '10px' }}>Req. in-hands date</span>
                  <span style={{ fontSize: '10px' }}>{required_date}</span>
                </Box>
              </Box>
            </Box>
          </Box>

          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {verb} - {actor?.data?.name ? `${actor.data.name} - ` : null}
            {moment(time).format('lll')}
          </span>
        </Box>
      )
    case 'Project Update':
    case 'Comment':
    case 'comment':
    case 'Question':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <span style={{ fontSize: '16px' }}>{body}</span>
          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {verb} - {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
          </span>
        </Box>
      )
    case 'SCA Introduction':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <span style={{ fontSize: '16px' }}>Introducting your new SCA {sca_name}</span>
          <span style={{ fontSize: '16px' }}>{body}</span>
          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {verb} - {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
          </span>
        </Box>
      )
    case 'Report Submission':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <span style={{ fontSize: '16px' }}>{report}</span>
          {quotes.map((item) => (
            <QuoteCard key={`rsi${item.quote_id}`} item={item} />
          ))}
          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {verb} - {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
          </span>
        </Box>
      )
    case 'Quote Updated':
    case 'Quote Feedback':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <span style={{ fontSize: '16px' }}>{body}</span>
          <QuoteCard
            key={`rsi${quote_id}`}
            item={{ quote_id, product_name, product_photo, quote_prices }}
          />
          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {verb} - {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
          </span>
        </Box>
      )
    default:
      return null
  }
}

const QuoteCard = ({ item }) => (
  <Box
    display="flex"
    alignItems="center"
    // justifyContent="space-between"
  >
    <Box style={{ width: '50px', height: '50px' }}>
      <img src={item.product_photo} style={{ maxWidth: '50px', maxHeight: '50px' }} alt="" />
    </Box>
    <Box ml="10px" display="flex" flexDirection="column">
      <span style={{ fontSize: '12px' }}>{item.product_name}</span>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {item &&
          item.quote_prices &&
          item.quote_prices?.map((price) => (
            <Box
              key={`iqp${price.id}`}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* <span style={{ fontSize: '12px' }}>${price.estimated_freight_air / 100}</span>
            <span style={{ fontSize: '10px' }}>{price.quantity}</span> */}
            </Box>
          ))}
      </Box>
    </Box>
  </Box>
)

const PurchaseOrderActivityCreateForm = ({
  feed,
  userId,
  createPOActivityOpen,
  setCreatePOActivityOpen,
  loading,
  setLoading,
  data,
}) => {
  const [type, setType] = useState('')
  const abortController = new AbortController()
  const dataProvider = useDataProvider()
  // const notify = useNotify()

  // console.log(data)

  const form = useForm()
  const {
    values,
    //  errors
  } = useFormState()

  // console.log(errors)

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*'],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        form.change('media', [
          ...(values.media || []),
          ...acceptedFiles.map((file) => {
            return {
              id: uniqueId(),
              file: Object.assign(file, {
                url: URL.createObjectURL(file),
              }),
            }
          }),
        ])
      }
    },
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.media?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  const previews = values.media
    ?.filter((item) => !!item.deleted_at === false)
    .map((file) => {
      // const currentTime = moment().toISOString()

      return (
        <Box
          key={`preview${file.file?.name || file.id}`}
          style={{
            margin: '2px',
            width: '50px',
            height: '50px',
            minWidth: '50px',
            minHeight: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2px',
            position: 'relative',
          }}
        >
          <img
            src={file.file?.url}
            alt=""
            style={{
              '&:hover': {
                border: 'gray solid 2px',
              },
              zIndex: '100',
              margin: '2px',
              maxWidth: '45px',
              maxHeight: '45px',
              cursor: 'pointer',
            }}
          />
          <IconButton
            aria-label="delete"
            style={{
              position: 'absolute',
              width: '15px',
              height: '15px',
              maxWidth: '15px',
              maxHeight: '15px',
              top: '0px',
              left: '25px',
              zIndex: '100',
            }}
            onClick={() => {
              // const image = values.media.findIndex((item) => item.id === file.id)
              // form.change(`media[${image}].deleted_at`, currentTime)
              form.change(
                'media',
                values.media.filter((item) => item.id !== file.id),
              )
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  const { getRootProps: getAdditionalRootProps, getInputProps: getAdditionalInputProps } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        // console.log(acceptedFiles)
        form.change('additional_files', [
          ...(values.additional_files || []),
          ...acceptedFiles.map((file) => {
            return { file: file, id: uniqueId() }
            //   Object.assign(file, {
            //     // preview: URL.createObjectURL(file),
            //     // file_type: 'ADDITIONAL_PHOTO',
          }),
        ])
      },
    })

  const additionalPreviews = values.additional_files
    ?.filter((item) => !!item.deleted_at === false)
    .map((file, index) => {
      // console.log(file, index)
      const currentTime = moment().toISOString()
      const ind = values.additional_files.findIndex((item) => item.id === file.id)

      return (
        <Box
          key={`addpreview${file.file?.path || file.file?.name || file.id}`}
          style={{
            margin: '2px',
            width: '100%',
            fontSize: '10px',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            padding: '2px',
            position: 'relative',
          }}
          // className={classes.previewFileBox}
        >
          <SelectInput
            label="Additional file type"
            source={`additional_files[${ind}].type`}
            choices={[
              { id: 0, name: 'Digital Proof', value: 'DIGITAL_PROOF' },
              { id: 1, name: 'Packing List', value: 'PACKING_LIST' },
              { id: 2, name: 'Bill of Lading', value: 'BILL_OF_LADING' },
              { id: 3, name: 'Customs Declaration', value: 'CUSTOMS_DECLARATION' },
              { id: 4, name: 'Commercial Invoice', value: 'COMMERCIAL_INVOICE' },
              { id: 5, name: 'Purchase Order', value: 'PURCHASE_ORDER' },
              { id: 6, name: 'Inspection Report', value: 'INSPECTION_REPORT' },
              { id: 7, name: 'Inspection Checklist', value: 'INSPECTION_CHECKLIST' },
              { id: 8, name: 'Miscellaneous', value: 'MISCELLANEOUS' },
              { id: 9, name: 'Payment Receipt', value: 'PAYMENT_RECEIPT' },
              { id: 10, name: 'Proof of Delivery', value: 'PROOF_OF_DELIVERY' },
              { id: 11, name: 'Arrival Notice', value: 'ARRIVAL_NOTICE' },
            ]}
            // className={classes.fieldInputNoMP}
            optionText="name"
            optionValue="value"
            defaultValue=""
            variant="standard"
            // validate={required()}
            onChange={(e) => {
              // console.log(e.target.value)
              form.change(`additional_files[${ind}].type`, e.target.value)
            }}
          />
          {`${index}. ${file.file?.name.substring(0, 47) || file.file?.filename.substring(0, 47)}`}
          <IconButton
            aria-label="delete"
            style={{
              // position: 'absolute',
              width: '15px',
              height: '15px',
              maxWidth: '15px',
              maxHeight: '15px',
              marginLeft: '10px',
              // top: '0px',
              // right: '10px',
              zIndex: '100',
            }}
            onClick={() => {
              // const ind = values.additional_files.findIndex((item) => item.id === file.id)
              // console.log(`additional_files[${ind}].deleted_at`, ind, currentTime)
              form.change(`additional_files[${ind}].deleted_at`, currentTime)
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.additional_files?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  const addPOEvent = async () => {
    // console.log('Add', type, userId, values)

    try {
      if (loading || !feed) {
        // !abortController.signal.aborted && setLoading(false)
        return null
      }

      const additionalFiles = []

      const mediaFiles = []

      const additionalFilesSubmission = []

      const mediaFilesSubmission = []

      if (values.media?.length > 0) {
        await Promise.all(
          values.media?.map(async (item) => {
            const file = await uploadFile([item.file])
            // console.log('UPLOADED:', item, file)
            mediaFiles.push({
              type: item.type,
              url: file?.data?.files[0]?.url || '',
              filename: item.file.name || item.file.path,
            })
            mediaFilesSubmission.push({
              type: `^${item.type}^`,
              file: {
                data: {
                  url: file?.data?.files[0]?.url || '',
                  filename: item.file.name || item.file.path,
                },
              },
            })
          }),
        )
      }

      if (values.additional_files?.length > 0) {
        await Promise.all(
          values.additional_files?.map(async (item) => {
            const file = await uploadFile([item.file])
            // console.log('UPLOADED:', item, file)
            additionalFiles.push({
              type: item.type,
              url: file?.data?.files[0]?.url || '',
              filename: item.file.name || item.file.path,
            })
            additionalFilesSubmission.push({
              type: `^${item.type}^`,
              file: {
                data: {
                  url: file?.data?.files[0]?.url || '',
                  filename: item.file.name || item.file.path,
                },
              },
            })
          }),
        )
      }

      // console.log(mediaFiles, additionalFiles, mediaFiles.length, additionalFiles.length)

      if (mediaFiles.length > 0 || additionalFiles.length > 0) {
        dataProvider.create('order_file', {
          data: [...mediaFilesSubmission, ...additionalFilesSubmission],
        })
        // console.log(resp)
      }

      const newActivity = {
        actor: `SU:${userId}`,
        verb: type,
        object: `purchase_order:${data.order?.id}`,
        ...(values.comments && { body: values.comments }),
        ...(mediaFiles.length > 0 && { mediaFiles }),
        ...(additionalFiles.length > 0 && { additionalFiles }),
        ...(values.poTrackingNumber && { poTrackingNumber: values.poTrackingNumber }),
        ...(values.poTrackingURL && { poTrackingURL: values.poTrackingURL }),
        ...(values.billOfLadingRef && { billOfLadingRef: values.billOfLadingRef }),
        // ...(type === 'SCA Introduction' && {
        //   sca_name:
        //     sca.forename && sca.surname
        //       ? `${sca.forename} ${sca.surname}`
        //       : sca.email.split('@')[0].replace('.', ' '),
        // }),
      }
      // console.log('activity', newActivity)
      await feed.addActivity(newActivity)
      // console.log(resp)

      // console.log(values)

      if (values.sendNotifications) {
        data.order.order_products[0].offer_purchase?.client?.members?.map(async (member) => {
          const sca = data.order.order_products[0].offer_purchase?.offer?.sca

          const scaName =
            sca?.forename && sca?.surname
              ? `${sca?.forename} ${sca?.surname}`
              : sca?.email?.split('@')[0]?.replace('.', ' ') || null

          const params = {
            userCode: member.code,
            actorCode: userId,
            verb: type,
            object: 'purchase_order',
            foreignId: `${data.order.id}`,
            scaName,
            referencePhoto: data.order.order_products[0].offer_purchase?.offer?.primary_photo?.url,
            productName: data.order.order_products[0].offer_purchase?.offer?.name,
            clientName: data.order.order_products[0].offer_purchase?.client?.name,
          }

          // console.log(params)
          await dataProvider.create('notification', {
            ...params,
          })

          if (values.sendAlerts) {
            const pushParams = {
              userId: member.member.id,
              title: type,
              message: `PO ${data.order.id}: ${values.comments || type}`,
              scaName,
              referencePhoto:
                data.order.order_products[0].offer_purchase?.offer?.primary_photo?.url,
              productName: data.order.order_products[0].offer_purchase?.offer?.name,
              clientName: data.order.order_products[0].offer_purchase?.client?.name,
            }
            await dataProvider.create('push_notification', {
              ...pushParams,
            })
          }

          // console.log(resp)
        })
      }

      // !abortController.signal.aborted && setComments('')
      !abortController.signal.aborted && setLoading(false)
    } catch (error) {
      console.log(error)
      !abortController.signal.aborted && setLoading(false)
      return null
    }
  }

  // console.log('Data', data)
  // console.log('Quote', quote)

  return (
    <Dialog
      open={createPOActivityOpen}
      onClose={() => !abortController.signal.aborted && setCreatePOActivityOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Add an activity</DialogTitle>
      {/* <form onSubmit={handleSubmit}> */}
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Select
            value={type ?? ''}
            onChange={(e) => !abortController.signal.aborted && setType(e.target.value)}
          >
            {/* <MenuItem value={'Purchase Order Created'}>Purchase Order Created</MenuItem> */}
            <MenuItem value={'Shipping Update'}>Shipping Update</MenuItem>
            <MenuItem value={'Product Update'}>Product Update</MenuItem>
            <MenuItem value={'Inspection Update'}>Inspection Update</MenuItem>
            <MenuItem value={'Project Update'}>Project Update</MenuItem>
            <MenuItem value={'General Update'}>General Update</MenuItem>
            <MenuItem value={'Comment'}>Comment</MenuItem>
            {/* <MenuItem value={'SCA Introduction'}>SCA Introduction</MenuItem> */}
          </Select>
          <TextInput
            variant="standard"
            fullWidth
            // className={classes.selectBoxWidth}
            inputProps={{
              maxLength: 100,
            }}
            source="comments"
            label="Comments:"
            style={{ marginRight: '10px', maxWidth: '200px' }}
          />
          <Box display="flex">
            <TextInput
              variant="standard"
              fullWidth
              // className={classes.selectBoxWidth}
              inputProps={{
                maxLength: 100,
              }}
              source="poTrackingNumber"
              label="Tracking Number:"
              style={{ marginRight: '10px', maxWidth: '200px' }}
            />
            <TextInput
              variant="standard"
              fullWidth
              // className={classes.selectBoxWidth}
              inputProps={{
                maxLength: 100,
              }}
              source="poTrackingURL"
              label="Tracking URL:"
              style={{ marginRight: '10px', maxWidth: '200px' }}
            />
            <TextInput
              variant="standard"
              fullWidth
              // className={classes.selectBoxWidth}
              inputProps={{
                maxLength: 100,
              }}
              source="billOfLadingRef"
              label="Bill of Lading Ref:"
              style={{ marginRight: '10px', maxWidth: '200px' }}
            />
          </Box>

          <span>Media Files (Photos & videos)</span>
          <div
            {...getRootProps()}
            style={{
              width: '380px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: '0.9',
              border: 'dashed gray 2px',
              cursor: 'pointer',
            }}
          >
            <input {...getInputProps()} />
            <span>{`Drag & drop media files here, or click to select`}</span>
          </div>
          <Box
            style={{
              display: 'flex',
              overflowX: 'scroll',
              width: '100%',
              maxWidth: '30vw',
              margin: '10px 0px 5px',
            }}
            // className={classes.previewsContainer}
          >
            {previews}
          </Box>
          <span>Additional Files (documents)</span>
          <div
            {...getAdditionalRootProps()}
            style={{
              width: '380px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: '0.9',
              border: 'dashed gray 2px',
              cursor: 'pointer',
            }}
          >
            <input {...getAdditionalInputProps()} />
            <span>{`Drag & drop documents here, or click to select`}</span>
          </div>
          <Box
            style={{
              width: '100%',
              margin: '10px 0px 5px',
            }}
            // className={classes.previewsContainer}
          >
            {additionalPreviews}
          </Box>
          <BooleanInput source="sendNotifications" />
          <BooleanInput source="sendAlerts" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            color: 'white',
            backgroundColor: '#00B3C3',
            height: '35px',
            marginTop: '8px',
          }}
          disabled={
            loading ||
            (type === 'Comment' && !values.comments) ||
            (type === 'Question' && !values.comments)
          }
          onClick={async () => {
            !abortController.signal.aborted && (await setLoading(true))
            await addPOEvent(type)
            !abortController.signal.aborted && setCreatePOActivityOpen(false)
          }}
        >
          Send
          {loading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  )
}

const PurchaseOrderActivityTemplate = (props) => {
  const {
    id,
    verb,
    time,
    actor,
    body = '',
    mediaFiles = [],
    additionalFiles = [],
    billOfLadingRef,
    poTrackingNumber,
    poTrackingURL,
  } = props
  // console.log(props)
  switch (verb) {
    case 'Create Purchase Order':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
          >
            {/* <Box style={{ width: '50px', height: '50px' }}>
              <img src={product_photo} style={{ maxWidth: '50px', maxHeight: '50px' }} alt="" />
            </Box>
            <Box ml="10px" display="flex" flexDirection="column">
              <span style={{ fontSize: '12px' }}>SR-{sourcing_request_id}</span>
              <span style={{ fontSize: '12px' }}>{product_name}</span>
              <Box alignItems="center" justifyContent="space-between">
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="center"
                  alignItems="center"
                  mt="5px"
                >
                  <span style={{ fontSize: '10px' }}>Req. Qty / Price</span>
                  <span style={{ fontSize: '10px' }}>
                    {target_quantity_min || target_quantity_max} @
                    {target_price ? `$${target_price / 100}` : 'N/A'}
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="center"
                  alignItems="center"
                >
                  <span style={{ fontSize: '10px' }}>Req. in-hands date</span>
                  <span style={{ fontSize: '10px' }}>{required_date}</span>
                </Box>
              </Box>
            </Box> */}
          </Box>

          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {verb} - {actor?.data?.name ? `${actor.data.name} - ` : null}
            {moment(time).format('lll')}
          </span>
        </Box>
      )
    case 'Comment':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <span style={{ fontSize: '16px' }}>{body}</span>
          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {mediaFiles.length > 0 && (
              <Box display="flex">
                {mediaFiles.map((item) => (
                  <img src={item.url} alt="" key={item.url} />
                ))}
              </Box>
            )}
            {verb} - {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
          </span>
        </Box>
      )
    case 'Project Update':
    case 'Inspection Update':
    case 'Shipping Update':
    case 'Product Update':
    case 'General Update':
      return (
        <Box
          style={{ border: 'gray solid 1px', borderRadius: '5px' }}
          mt="10px"
          p="10px"
          key={`activity${id}`}
          display="flex"
          flexDirection="column"
        >
          <span style={{ fontSize: '20px' }}>{verb}</span>
          <span style={{ fontSize: '16px' }}>{body}</span>
          {poTrackingNumber && poTrackingURL && (
            <a href={poTrackingURL} target="_blank" rel="noreferrer noopener">
              Tracking: {poTrackingNumber}
            </a>
          )}
          {billOfLadingRef && (
            <span style={{ fontSize: '14px' }}>Bill of Lading Ref: {billOfLadingRef}</span>
          )}
          <Box display="flex">
            {mediaFiles?.length > 0 && (
              <Box flex={1}>
                <span style={{ fontSize: '14px' }}>{`Media: (Photo's and Video's):`}</span>
                <Box display="flex">
                  {mediaFiles.map((item) => (
                    <img
                      style={{
                        margin: '5px',
                        maxWidth: '50px',
                        maxHeight: '50px',
                        borderRadius: '4px',
                      }}
                      src={item?.url}
                      alt=""
                      key={item?.url}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {additionalFiles?.length > 0 && (
              <Box flex={1} display="flex" flexDirection="column">
                <span style={{ fontSize: '14px' }}>Additional Documents:</span>
                {additionalFiles.map((item, index) => (
                  <Box key={item?.url}>
                    {/* <span>{item.type}</span> */}
                    <a href={item?.url} style={{ fontSize: '12px' }}>{`${index + 1}. ${
                      item.type
                    }`}</a>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <span style={{ fontSize: '12px', marginTop: '5px' }}>
            {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
          </span>
        </Box>
      )
    // case 'SCA Introduction':
    //   return (
    //     <Box
    //       style={{ border: 'gray solid 1px', borderRadius: '5px' }}
    //       mt="10px"
    //       p="10px"
    //       key={`activity${id}`}
    //       display="flex"
    //       flexDirection="column"
    //     >
    //       <span style={{ fontSize: '16px' }}>Introducting your new SCA {sca_name}</span>
    //       <span style={{ fontSize: '16px' }}>{body}</span>
    //       <span style={{ fontSize: '12px', marginTop: '5px' }}>
    //         {verb} - {actor?.data?.name || 'Unknown'} - {moment(time).format('lll')}
    //       </span>
    //     </Box>
    //   )

    default:
      return null
  }
}

export default GSFeed
