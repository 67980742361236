import englishMessages from 'ra-language-english'

export default {
  ...englishMessages,
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    Settings: 'Settings',
    Logout: 'Logout',
    notification: {
      notifications: 'Notifications',
      upload_factory_files: 'Upload Factory Files',
      concept_renderings: 'Concept Renderings',
      instruction_booklet: 'Instruction Booklet',
      packing_method_diagram: 'Packing Method Diagram',
      marketing_materials: 'Marketing Materials',
    },
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to MFG Admin',
        subtitle: 'This is the admin of MFG Websites.',
        aor_button: 'Retail Site',
        demo_button: 'Direct Site',
      },
    },
    menu: {
      supplier: 'Supplier',
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
    },
  },
  resources: {
    undefined: {
      fields: {
        feature: 'Feature',
        material: 'Material',
        certification: 'Certification',
        reference: 'Reference',
      },
    },
    common: {
      action: {
        accept: 'Accept',
        reject: 'Reject',
        setCurrent: 'Current',
      },
    },
    suppliers: {
      name: 'Supplier |||| Suppliers',
      fields: {},
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        notes: 'Notes',
        reviews: 'Reviews',
      },
    },
    purchaseOrders: {
      name: 'Purchase Orders |||| Purchase Orders',
      fields: {
        products: 'Products',
      },
    },

    products: {
      name: 'Product |||| Products',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        notes: 'Notes',
        reviews: 'Reviews',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    materials: {
      name: 'Material |||| Materials',
      fields: {
        products: 'Products',
      },
    },
    qualityInspectionTypes: {
      name: 'Quality Inspection Type |||| Quality Inspection Types',
      fields: {
        products: 'Products',
      },
    },
    qualityAgencies: {
      name: 'Quality Agency |||| Quality Agencies',
      fields: {
        products: 'Products',
      },
    },
    shippingPorts: {
      name: 'Shipping Ports |||| Shipping Ports',
      fields: {
        suppliers: 'Suppliers',
      },
    },
  },
}
