import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { TextInput } from 'react-admin'
import NumberFormat from '../../components/CustomNumberFormat'
import { makeStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(() => ({
  head: {
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    padding: '2px',
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const useStyles = makeStyles(() => ({
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      minHeight: '36px',
      '& .input': {
        width: '50%',
      },
    },
  },
}))

const OfferProcurementDetails = () => {
  const { values } = useFormState()

  return (
    <Box ml="1rem" flex={3}>
      <span style={{ fontSize: '12px' }}>Materials Breakdown</span>
      <TableContainer component="div">
        <Table aria-label="materialsTable" style={{ marginTop: '20px', overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                Material
              </StyledTableCell>
              <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                Qty per Piece
              </StyledTableCell>
              <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                Estimated Cost
              </StyledTableCell>
              <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                Vendor
              </StyledTableCell>
              <StyledTableCell style={{ width: '12%', fontSize: '10px' }} align="left">
                Vendor Code
              </StyledTableCell>
              <StyledTableCell style={{ width: '20%', fontSize: '10px' }} align="left">
                Order Qty Required
              </StyledTableCell>
              <StyledTableCell style={{ width: '20%', fontSize: '10px' }} align="left">
                Est. Cost
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.materials?.map((item, index) => (
              <MaterialsRow key={`material${item.id}`} index={index} id={item.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const MaterialsRow = ({ index, disabled = true }) => {
  const classes = useStyles()

  return (
    <TableRow key={`matRow${index}`}>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].name`}
          label={false}
          disabled
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].perPiece`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].estCost`}
          label={false}
          disabled
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].vendor`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '5px', margin: '10px 0px 0px', height: '100%' }}
          source={`materials[${index}].vendor_code`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`materials[${index}].orderQuantityRequired`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`materials[${index}].estimatedOrderCost`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default OfferProcurementDetails
