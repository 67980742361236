import gql from 'graphql-tag'
import set from 'lodash/set'
import { sanitizeResource } from '../getResponseParser'

export const systemDictGetMany = ({ context, params, variables }) => {
  !variables ? (variables = {}) : null
  !variables?.limit ? (variables.limit = 100) : null
  !variables?.offset ? (variables.offset = 0) : null
  !variables?.sort ? (variables.sort = { id: 'desc_nulls_last' }) : null
  !variables?.name ? (variables.name = params.ids[0]) : null

  // console.log(variables)
  if (typeof variables.name === 'string') {
    return {
      ...context,
      query: gql`
        query getDictMany(
          $name: String
          $limit: Int
          $offset: Int
          $sort: [system_dictionary_order_by!]!
        ) {
          items: system_dictionary(
            limit: $limit
            offset: $offset
            order_by: $sort
            where: { name: { _eq: $name } }
          ) {
            created_at
            id
            name
            own
            value
          }
          total: system_dictionary_aggregate(order_by: $sort, where: { name: { _eq: $name } }) {
            aggregate {
              count
            }
          }
        }
      `,
      variables,
      parseResponse: ({ data }) => {
        // console.log(data)

        return {
          data: data?.items?.map(sanitizeResource),
          total: data?.total?.aggregate?.count,
        }
      },
    }
  }
}

export const systemDictCreate = ({ context, params }) => {
  return {
    ...context,
    variables: {},
    query: gql`
            mutation insertSystemDict {
              insert_system_dictionary_one(object: {name: "${params.name}", own: "${params.own}", value: "${params.value}"}, on_conflict: {constraint: system_dictionary_own_name_key, update_columns: [name, value]}) {
                id
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: { ...data, id: data.insert_system_dictionary_one?.id },
    }),
  }
}

export const systemDictUpdate = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation {
            updateSystemDictionary(
              input: {${jsonParams}}
            )
            {
              id
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}

export const systemDictSupplierTypeList = ({ context, params }) => {
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getSupplierTypes($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "supplier-type" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "supplier-type" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // data,
    }),
  }
}

export const systemDictMaterialList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getMaterials($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _like: "material%" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _like: "material%" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
    }),
  }
}

export const systemDictMatPkgImpGet = ({ context, params, variables }) => {
  return {
    ...context,
    query: gql`
          query getSystemDictItem {
            system_dictionary_by_pk(
             id: ${params.id}
            ) {
              created_at
              id
              name
              own
              value
            }
          }
        `,
    variables,
    parseResponse: ({ data }) => ({
      data: data.system_dictionary_by_pk,
    }),
  }
}

export const systemDictMaterialDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
        mutation {
          deleteSystemDictionary(
            input: {
              id: ${params.id}
            }
          )
        }
      `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}

export const systemDictCertificateTypeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getCertificatesTypes(
        $limit: Int
        $offset: Int
        $order_by: [system_dictionary_order_by!]!
      ) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _like: "%certificates-type%" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _like: "%certificates-type%" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictCertificationTypeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getCertificationTypes(
        $limit: Int
        $offset: Int
        $order_by: [system_dictionary_order_by!]!
      ) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _like: "%certification-type%" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _like: "%certification-type%" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictDesignFileTypeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getDesignFileTypes(
        $limit: Int
        $offset: Int
        $order_by: [system_dictionary_order_by!]!
      ) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _like: "%design-file-type%" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _like: "%design-file-type%" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPackagingStyleList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getPkgStyles($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "packagingStyle" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "packagingStyle" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPackagingStyleDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
        mutation {
          deleteSystemDictionary(
            input: {
              id: ${params.id}
            }
          )
        }
      `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}

export const systemDictInspTypeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getInspTypes($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "inspection-type" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "inspection-type" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictInspLevelList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getInspLvls($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "inspection-level" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "inspection-level" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictEmployeesList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getEmployees($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "employees" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "employees" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictAnnualRevenueList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getAnnualRevenues(
        $limit: Int
        $offset: Int
        $order_by: [system_dictionary_order_by!]!
      ) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "annual-revenue" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "annual-revenue" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictFactorySizeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getFactSizes($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "factory-size" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "factory-size" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPrimaryMarketList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getPrimMarkets($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "primary-market" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "primary-market" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictSellingFeaturesList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getSellingFeatures(
        $limit: Int
        $offset: Int
        $order_by: [system_dictionary_order_by!]!
      ) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "selling-features" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "selling-features" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPowerRequirementsList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getPowerRequirements(
        $limit: Int
        $offset: Int
        $order_by: [system_dictionary_order_by!]!
      ) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "power-requirements" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "power-requirements" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPowerInputList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getPowerInputs($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "power-input" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "power-input" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPowerOutputList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getPowerOutputs($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "power-output" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "power-output" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictPowerSourceList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getPowerSources($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "power-source" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "power-source" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictAdapterHeadList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getAdapterHeads($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "adapter-head" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "adapter-head" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictConnectionList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getConnections($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "connection" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "connection" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictAddressTypeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getAddressTypes($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "address-type" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "address-type" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map((item) => {
        return { ...item, id: item.name }
      }),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictCordLengthList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getCordLengths($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _eq: "cord-length" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _eq: "cord-length" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}

export const systemDictSizeList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {
    limit: parseInt(params?.pagination?.perPage, 10),
    offset: parseInt((params?.pagination?.page - 1) * params?.pagination?.perPage, 0),
    order_by: set({}, params?.sort?.field, params?.sort?.order.toLowerCase()),
    // where: {},
  }
  return {
    ...context,
    query: gql`
      query getSizes($limit: Int, $offset: Int, $order_by: [system_dictionary_order_by!]!) {
        items: system_dictionary(
          limit: $limit
          offset: $offset
          order_by: $order_by
          where: { own: { _like: "%size%" } }
        ) {
          created_at
          id
          name
          own
          value
        }
        total: system_dictionary_aggregate(
          order_by: $order_by
          where: { own: { _like: "%size%" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables,
    parseResponse: ({ data }) => ({
      data: data?.items?.map(sanitizeResource),
      total: data?.total?.aggregate?.count,
      // validUntil,
      // data,
    }),
  }
}
