import SCMQuoteIcon from '@material-ui/icons/Collections'
import SCMQuoteList from './SCMQuoteList'
import SCMQuoteEdit from './SCMQuoteEdit'
import SCMQuoteCreate from './SCMQuoteCreate'
// import SCMQuoteShow from './SCMQuoteDetail'

export default {
  list: SCMQuoteList,
  create: SCMQuoteCreate,
  edit: SCMQuoteEdit,
  icon: SCMQuoteIcon,
  // show: SCMQuoteShow,
}
