import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const suppliersStripeList = ({ context }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getStripeSuppliers {
        supplier(where: { stripe_connect_account_id: { _is_null: false } }) {
          id
          name
          stripe_connect_account_id
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.supplier,
      errors,
    }),
  }
}

export const suppliersCreate = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation createSupplier {
            insert_supplier(
              ${jsonParams}
            ) {
              returning {
                id
              }
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data: data.createSupplier,
      errors,
    }),
  }
}

export const suppliersUpdate = ({ context, params }) => {
  const id = params.id
  delete params.id
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation {
            update_supplier(where: { id: {_eq: ${id}}}, _set: {
                ${jsonParams}
              }
            ) {
              returning{
                id
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.update_supplier.returning[0],
    }),
  }
}

export const suppliersList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  const variables = {}

  if (params.filter?.status || params.filter?.name || params.id || params.name) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...((params.name || params.filter?.name) && {
        name: { _ilike: `%${params.name || params.filter?.name}%` },
      }),
    }
  }
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
          query getSuppliers ($where: supplier_bool_exp) {
           supplier(where: $where, limit: ${
             params.pagination.perPage
           }, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              id
              created_at
              updated_at
              status
              name
              business_licence_no
              official_verification_link
              official_verification_link
              local_name
              legal_address
              sca_user_id
              sca_user {
                id
                forename
                surname
                email
              }
              primary_address {
                id
                local_full_address
              }
              quotes: product_quotes_aggregate {
                aggregate {
                  count
                }
              }
              activeOrders: product_quotes_aggregate (where: {
                scm_quotes: { offers: { status: { _in: [PRODUCTION SELLING SHIPPING COMPLETED]}}}
              }) {
                aggregate {
                    count
                }
              }
              categories {
                id
                category {
                  id
                  name
                }
              }
            }
             supplier_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.supplier,
      total: data.supplier_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const suppliersNameList = ({ context, params }) => {
  const variables = {}
  if (params.filter?.status || params.filter?.sca_user_id || params.filter?.name) {
    variables.where = {
      ...(params.filter.sca_user_id && { sca_user_id: { _eq: params.filter.sca_user_id } }),
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...(params.filter.name && { name: { _ilike: `%${params.filter.name}%` } }),
    }
  }
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
      query getSuppliers($where: supplier_bool_exp) {
        supplier(where: $where, order_by: { name: asc_nulls_last }) {
          id
          name
          official_verification_link
        }
        supplier_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.supplier,
      total: data.supplier_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const suppliersGet = ({ context, params }) => {
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            query getOneSupplier {
              supplier_by_pk(id: ${params.id}) {
                id
                addresses {
                  address_line1
                  address_line2
                  city
                  country_code
                  created_at
                  id
                  local_full_address
                  post_code
                  province
                  type
                  shipping_port {
                    id
                    name
                    port_code
                    type
                  }
                }
                agreements {
                  created_at
                  document {
                    created_at
                    filename
                    id
                    mime
                    size
                    type
                    url
                  }
                  file_id
                  id
                  term_end_date
                  term_start_date
                }
                annual_turnover
                audits (order_by: {audit_date: desc}) {
                  annual_turnover
                  approved_at
                  approved_user_id
                  audit_date
                  brand_name
                  company_mode
                  company_size
                  company_type
                  conclusion
                  created_at
                  employee_total
                  file_id
                  id
                  primary_market
                  quality_agency {
                    id
                    name
                    contact_id
                    address {
                      id
                      address_line1
                      address_line2
                      city
                      province
                      post_code
                      country_code
                    }
                  }
                  remark
                  updated_at
                  valid_date
                  year_established
                  file {
                    filename
                    id
                    url
                  }
                }
                billing_address {
                  address_line1
                  address_line2
                  city
                  country_code
                  created_at
                  id
                  local_full_address
                  post_code
                  province
                  type
                  shipping_port {
                    id
                    name
                    port_code
                    type
                  }
                }
                sample_address {
                  address_line1
                  address_line2
                  city
                  country_code
                  created_at
                  id
                  local_full_address
                  post_code
                  province
                  type
                  shipping_port {
                    id
                    name
                    port_code
                    type
                  }
                }
                pickup_address {
                  address_line1
                  address_line2
                  city
                  country_code
                  created_at
                  id
                  local_full_address
                  post_code
                  province
                  type
                  shipping_port {
                    id
                    name
                    port_code
                    type
                  }
                }
                business_licence_file {
                  filename
                  id
                  url
                }
                business_licence_no
                categories {
                  id
                  category {
                    id
                    name
                  }
                }
                certifications {
                  comment
                  created_at
                  document {
                    filename
                    id
                    url
                  }
                  file_id
                  id
                  name
                  notifying_body
                  number
                  type
                  updated_at
                  valid_end_date
                  valid_start_date
                  verification_link
                  verified_at
                }
                code
                commission_structure
                company_introduction
                company_mode
                company_type
                contacts {
                  email
                  created_at
                  first_name
                  id
                  mobile
                  last_name
                  position
                  updated_at
                  wechat
                  whatsapp
                }
                created_at
                employee_total
                factory_size
                files (order_by: {id: desc}) {
                  id
                  type
                  file {
                    filename
                    id
                    url
                  }
                }
                id
                legal_address
                local_name
                name
                notes {
                  author_id
                  content
                  created_at
                  id
                  title
                  type
                  updated_at
                }
                official_active
                official_address
                official_business_scope
                official_company_name
                official_legal_person_name
                official_registered_capital
                official_remark
                official_verification_link
                primary_address {
                  address_line1
                  address_line2
                  city
                  country_code
                  created_at
                  id
                  local_full_address
                  post_code
                  province
                  shipping_port {
                    id
                    name
                    port_code
                    type
                  }
                  type
                }
                primary_contact {
                  created_at
                  email
                  first_name
                  id
                  last_name
                  mobile
                  position
                  updated_at
                  wechat
                  whatsapp
                }
                primary_market
                rating
                sca_user {
                  id
                  username
                  email
                  forename
                  surname
                }
                status
                updated_at
                website
                year_established
                bank_detail {
                  account_number
                  beneficiary_name
                  branch_address
                  branch_name
                  clearing_code
                  created_at
                  currency
                  id
                  swift_code
                  tax_number
                  updated_at
                  beneficiary_address {
                    id
                    address_line1
                    address_line2
                    city
                    country_code
                    post_code
                    local_full_address
                    province
                    type
                    shipping_port {
                      id
                      name
                      port_code
                      type
                    }
                  }
                  bank {
                    id
                    name
                    abbr
                    region
                    swift_code
                    clearing_code
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.supplier_by_pk,
      // validUntil,
    }),
  }
}
