import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const organisationList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'name'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  if (
    params.id ||
    params.name ||
    params.filter?.q ||
    params.filter?.is_public ||
    params.filter?.member_id
  ) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter?.is_public && { is_public: { _eq: true } }),
      ...(params.filter?.q && { name: { _ilike: `%${params.filter?.q}%` } }),
      ...(params.name && { name: { _ilike: `%${params.name}%` } }),
      ...(params.filter?.member_id && {
        members: { app_user_id: { _eq: params.filter.member_id } },
      }),
    }
  }
  return {
    ...context,
    variables,
    query: gql`
          query getOrganisations ($where: organisation_bool_exp) {
           organisation(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                name
                legal_name
                legal_representative
                logo_file_url
                business_registration_number
                is_public
                allow_global_notifications
                default_billing_address_id
                default_delivery_address_id
                stripe_connected_account_id
                stripe_customer_account_id
                founder_user_id
                owner_user_id
                default_sca_id
                website
                created_at
                updated_at
                default_sca {
                  id
                  forename
                  surname
                  email
                }
                owner {
                  id
                  forename
                  surname
                  email
                }
                members {
                  id
                  member {
                    id
                    forename
                    surname
                  }                  
                }
                members_aggregate {
                  aggregate {
                    count
                  }
                }
                quotes_aggregate {
                  aggregate {
                    count
                  }
                }
              }
              organisation_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.organisation,
      total: data.organisation_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const organisationGet = ({ context, params, variables }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables,
    query: gql`
          query getOneOrganisation ($where: organisation_bool_exp) {
            organisation_by_pk(id: ${params.id}) {
                id
                name
                allow_global_notifications
                website
                legal_name
                legal_representative
                logo_file_url
                business_registration_number
                default_billing_address_id
                default_delivery_address_id
                stripe_connected_account_id
                stripe_customer_account_id
                founder_user_id
                owner_user_id
                default_sca_id
                created_at
                updated_at
                is_public
                default_sca {
                  id
                  forename
                  surname
                  email
                }
                owner {
                  id
                  forename
                  surname
                  email
                }
                members {
                  id
                  member {
                    id
                    forename
                    surname
                  }                  
                }
                members_aggregate {
                  aggregate {
                    count
                  }
                }
                quotes_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
        `,
    parseResponse: ({ data }) => ({
      data: data.organisation_by_pk,
      // validUntil,
    }),
  }
}

export const organisationCreate = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      mutation createOrganisation {
        insert_organisation_one(
        object: {
          name: "${params.data?.client_name}"
        }
      ){
        id
        name
      }
    }
  `,
    parseResponse: ({ data, errors }) => {
      // console.log(data, errors)
      return {
        data: data.insert_organisation_one,
        errors,
      }
    },
  }
}

export const organisationMembersList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'created_at'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  if (
    params.id ||
    params.filter?.member_name ||
    params.filter?.role ||
    params.filter?.clientId ||
    params.filter?.appUserId
  ) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter?.clientId && {
        organisation_id: { _eq: params.filter?.clientId },
      }),
      ...(params.filter?.appUserId && {
        app_user_id: { _eq: params.filter?.appUserId },
      }),
      ...(params.filter?.role && { role: { _eq: params.filter?.role } }),
      ...(params.filter?.member_name && {
        member: {
          _or: [
            { forename: { _ilike: `%${params.filter?.member_name}%` } },
            { surname: { _ilike: `%${params.filter?.member_name}%` } },
          ],
        },
      }),
    }
  }
  return {
    ...context,
    variables,
    query: gql`
          query getOrganisationMembers ($where: organisation_members_bool_exp) {
           organisation_members(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                role
                created_at
                organisation {
                  id
                  name
                }
                member {
                  id
                  forename
                  surname
                  email
                }
              }
              organisation_members_aggregate (where: $where) {
                aggregate {
                  count
                }
              }
            }
        `,
    parseResponse: ({ data }) => ({
      data: data.organisation_members,
      total: data.organisation_members_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const organisationMembersGet = ({ context, params, variables }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables,
    query: gql`
          query getOneOrganisationMember ($where: organisation_members_bool_exp) {
            organisation_members_by_pk(id: ${params.id}) {
                id
                role
                created_at
                organisation {
                  id
                  name
                }
                member {
                  id
                  forename
                  surname
                  email
                }
              }
            }
        `,
    parseResponse: ({ data }) => ({
      data: data.organisation_members_by_pk,
      // validUntil,
    }),
  }
}

export const organisationMembersCreate = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      mutation addOrganisationMember {
        insert_organisation_members_one(
        object: {
          organisation_id: ${params.objects?.organisation_id}
          app_user_id: ${params.objects?.app_user_id}
        }
      ){
        id
      }
    }
  `,
    parseResponse: ({ data, errors }) => {
      return {
        data: data.insert_organisation_members_one,
        errors,
      }
    },
  }
}

export const organisationInventoryDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation deleteOfferBuyerInventories {
            delete_organisation_inventory(
              where: { offer_id: { _eq: ${params.offerId} } }
            ) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}
