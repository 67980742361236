import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const geoAddressesList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'type'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  if (params.id || params.filter?.organisation_id) {
    variables.where = {
      ...(params.id && { id: { _eq: params.id } }),
      ...(params.filter?.organisation_id && {
        organisation_id: { _eq: params.filter.organisation_id },
      }),
    }
  }
  return {
    ...context,
    variables,
    query: gql`
          query getAddresses ($where: address_bool_exp) {
           address(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                address_line1
                address_line2
                city
                country_code
                post_code
                province
                delivery_contact
                delivery_email
                delivery_mobile
                company_name
                label
                type
              }
              address_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.address,
      total: data.address_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const geoPlatformAddressConfig = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
    query getPlatformAddressDetail {
      Consignee: supply_chain_management_company_by_pk(id: ${parseInt(params.default_scm_id)}) {
        id
        address {
          id
          address_line1
          address_line2
          city
          country_code
          created_at
          post_code
          province
          type
        }
        contact {
          id
          first_name
          last_name
          email
          mobile
          position
        }
      }
      ShippingAddress: warehouse_by_pk(id: ${parseInt(params.default_warehouse_id)}) {
        id
        address {
          id
          address_line1
          address_line2
          city
          country_code
          created_at
          post_code
          province
          type
        }
        contact {
          id
          first_name
          last_name
          email
          mobile
          position
        }
      }
      Shipper: international_logistics_provider_by_pk(id: ${parseInt(
        params.default_logistics_id,
      )}) {
        id
        address {
          id
          address_line1
          address_line2
          city
          country_code
          created_at
          post_code
          province
          type
        }
        contact {
          id
          first_name
          last_name
          email
          mobile
          position
        }
      }
    }
  `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}

export const geoCountryList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'id'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  if (params.id) {
    variables.where = {
      ...(params.ids && { id: { _in: params.ids } }),
    }
  }
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables,
    query: gql`
          query getManyCountries ($where: country_bool_exp) {
           country (where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                code
                local_name
                name
                alpha3_code
                numeric_code
              }
              country_aggregate(where: $where) {
                aggregate {
                  count
                }
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.country.map((item) => {
        return { ...item, id: item.name }
      }),
      total: data.country_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const geoCountrySubdivisionsList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'name'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  if (params.ids || params.filter?.country || params.filter?.level || params.filter?.province) {
    variables.where = {
      ...(params.ids && { name: { _in: params.ids } }),
      ...(params.filter?.country && { country: { _eq: params.filter?.country } }),
      ...(params.filter?.level && { level: { _eq: params.filter?.level } }),
      ...(params.filter?.province && { province: { _eq: params.filter?.province } }),
      ...(params.filter?.q && { name: { _ilike: `%${params.filter.q}%` } }),
    }
  }
  // console.log(params, variables)
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
          query getManyCountrySubdivisions ($where: country_subdivision_bool_exp) {
           country_subdivision (where: $where, distinct_on: name, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                code
                local_name
                name
                country
                province
                level
              }
              country_subdivision_aggregate(where: $where) {
                aggregate {
                  count
                }
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.country_subdivision.map((item) => {
        return { ...item, id: item.name }
      }),
      total: data.country_subdivision_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const geoShippingPortsList = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  // console.log(params)
  return {
    ...context,
    variables: {
      q: `%${params.filter.q}%`,
    },
    query: gql`
      query getShippingPorts($q: String) {
        shipping_port(
          where: { name: { _ilike: $q } }
          limit: 25
          offset: 0
          order_by: { id: desc }
        ) {
          id
          name
        }
        shipping_port_aggregate(where: { name: { _ilike: $q } }) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.shipping_port,
      total: data.shipping_port_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}
