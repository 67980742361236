import React, { useState } from 'react'
import {
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  withStyles,
  IconButton,
  Popper,
  Fade,
  //   Card,
  CircularProgress,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  required,
  useNotify,
  useDataProvider,
  Button,
  //   SelectInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  //   ReferenceInput,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import WarningIcon from '@material-ui/icons/Warning'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { uniqueId } from 'lodash'
import NumberFormat from './CustomNumberFormat'
import { ColorInput } from './ColourPicker'
import TextInput from '../../components/OutlinedTextInput'
// import BooleanFieldInput from './fieldInputs/BooleanFieldInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

const ProductOptionsForm = (props) => {
  const form = useForm()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const { values } = useFormState()
  const [currentTab, setCurrentTab] = useState(
    values.configurations?.length > 0 ? `${values.configurations[0]?.id}` : 'new',
  )

  const { materials = [], imprints = [] } = props

  const [decorationsEditing, setDecorationsEditing] = useState(false)
  const [decorationsSaving, setDecorationsSaving] = useState(false)
  const [componentEditing, setComponentEditing] = useState(false)
  const [componentSaving, setComponentSaving] = useState(false)
  const [optionEditing, setOptionEditing] = useState(false)
  const [optionSaving, setOptionSaving] = useState(false)

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Colors & Other Options</span>

        {optionEditing ? (
          <div>
            <IconButton
              onClick={async () => {
                // console.log('saving')
                setOptionSaving(true)

                try {
                  await Promise.all(
                    values.configurations?.map(async (cfg) => {
                      // console.log('CFG', cfg)
                      const cfgParams = {
                        id: cfg.id,
                        name: cfg.name,
                        max_unique_selections: cfg.max_unique_selections,
                        additional_cost_per_unique_choice: cfg.additionalCostPerChoice * 100,
                      }
                      await dataProvider.update('product_quote_options', {
                        data: cfgParams,
                        on_conflict: {
                          constraint: 'product_quote_options_product_quote_id_name_key',
                          update_columns: [
                            'name',
                            'product_quote_id',
                            '^max_unique_selections^',
                            '^additional_cost_per_unique_choice^',
                          ],
                        },
                      })

                      await Promise.all(
                        cfg.values?.map(async (val) => {
                          // console.log('VAL', val)

                          const valParams = {
                            id: val.id,
                            value: val.value,
                            moq: val.moq || 0,
                            setup: Math.round(val.setupCost * 100) || 0,
                            cost: Math.round(val.unitCost * 100) || 0,
                          }
                          await dataProvider.update('product_quote_option_values', {
                            data: valParams,
                            on_conflict: {
                              constraint:
                                'product_quote_option_values_product_quote_option_id_value_key',
                              update_columns: ['value', 'product_quote_option_id'],
                            },
                          })

                          await Promise.all(
                            val.components?.map(async (cmp) => {
                              // console.log('CMP', cmp)

                              if (cmp.active) {
                                await dataProvider.create('component_options', {
                                  data: {
                                    component_id: cmp.id,
                                    product_quote_option_value_id: val.id,
                                  },
                                  on_conflict: {
                                    constraint:
                                      'component_options_component_id_product_quote_option_value_id_ke',
                                    update_columns: [
                                      'component_id',
                                      'product_quote_option_value_id',
                                    ],
                                  },
                                })
                              } else if (cmp.relId) {
                                await dataProvider.delete('component_options', {
                                  data: { id: cmp.relId },
                                })
                              }
                            }),
                          )
                        }),
                      )
                    }),
                  )
                  setOptionSaving(false)
                  setOptionEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setOptionSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: '#FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setOptionEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {optionSaving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setOptionEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Tabs
        value={currentTab}
        onChange={(e, v) => setCurrentTab(v)}
        aria-label="orders"
        style={{ marginTop: '0px' }}
      >
        {values.configurations?.map((item) => {
          return (
            <Tab
              key={item.id}
              value={`${item.id}`}
              label={item.name}
              wrapped
              style={{ width: '100px' }}
              className={currentTab === `${item.id}` ? classes.tabButton : null}
            />
          )
        })}
        {optionEditing ? (
          <Tab
            value="new"
            label="Add New"
            style={{ width: '100px' }}
            wrapped
            onClick={async () => {
              const resp = await dataProvider.create('product_quote_options', {
                data: { product_quote_id: values.product_quotes[0].id, name: '' },
              })
              const valResp = await dataProvider.create('product_quote_option_values', {
                data: { product_quote_option_id: resp.data.id, value: '' },
              })
              const newConfigurations = values.configurations ? [...values.configurations] : []
              newConfigurations.push({
                id: resp.data.id,
                label: '',
                name: '',
                additionalCostPerChoice: 0,
                max_unique_selections: 0,
                values: [
                  {
                    id: valResp.data.id,
                    value: '',
                    moq: 0,
                    setupCost: 0,
                    unitCost: 0,
                    components: values.product_quotes[0].components?.map((el) => ({
                      ...el,
                      active: false,
                    })),
                  },
                ],
              })
              form.change('configurations', newConfigurations)
              setCurrentTab(`${newConfigurations[newConfigurations.length - 1].id}`)
            }}
            className={currentTab === 'new' ? classes.tabButton : null}
          />
        ) : null}
      </Tabs>
      {values.configurations?.map((item, index) => {
        return (
          <TabPanel key={item.id} value={currentTab} index={`${item.id}`}>
            <ProductOptionsTable
              materials={materials}
              index={index}
              optionEditing={optionEditing}
              optionSaving={optionSaving}
              setOptionSaving={setOptionSaving}
              setCurrentTab={setCurrentTab}
            />
          </TabPanel>
        )
      })}
      <TabPanel value={currentTab} index="new"></TabPanel>
      {/* <TabPanel value={currentTab} index="two"></TabPanel> */}

      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Product Decorations</span>
        {decorationsEditing ? (
          <div>
            <IconButton
              onClick={async () => {
                // console.log('saving', values.productDecorations)
                setDecorationsSaving(true)

                try {
                  await Promise.all(
                    values.productDecorations?.map(async (deco) => {
                      console.log(deco)
                      const decoParams = {
                        id: deco.id,
                        max_dimensions: deco.max_dimensions,
                        label: deco.label,
                        cost: deco.unitCost * 100,
                        imprint_style: deco.imprint_style || '',
                        imprint_styles: deco.imprintStyles,
                      }
                      await dataProvider.update('product_quote_decorations', {
                        data: decoParams,
                      })
                    }),
                  )

                  setDecorationsSaving(false)
                  setDecorationsEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setDecorationsSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: '#FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setDecorationsEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {decorationsSaving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setDecorationsEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box mt="20px">
        <TableContainer component={Paper}>
          <Table aria-label="decorationsTable" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Location (Display Text)
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '14%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Max Dimensions (W x H)
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '60%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Imprint Style(s)
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '6%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Delete
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.productDecorations?.map((item, index) => (
                <DecorationsRow
                  disabled={!decorationsEditing}
                  imprints={imprints}
                  key={`deco${item.id}`}
                  index={index}
                  id={item.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {decorationsEditing ? (
          <IconButton
            aria-label="add-decoration"
            // className={classes.infoBtn}
            disabled={!decorationsEditing || decorationsSaving}
            onClick={async () => {
              try {
                setDecorationsSaving(true)
                const newDecosResp = await dataProvider.create('product_quote_decorations', {
                  data: {
                    product_quote_id: values.product_quotes[0].id,
                    label: '',
                    max_dimensions: '',
                    cost: 0,
                    imprint_style: '',
                  },
                })
                // console.log(newDecosResp)
                const newDecosOptions =
                  values.productDecorations?.length > 0 ? [...values.productDecorations] : []
                newDecosOptions.push({
                  id: newDecosResp.data.id,
                })
                await form.change('productDecorations', newDecosOptions)

                setDecorationsSaving(false)
              } catch (error) {
                console.log(error)
                setDecorationsSaving(false)
              }
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>

      <Box mt="20px" display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Product Components</span>
        {componentEditing ? (
          <div>
            <IconButton
              onClick={async () => {
                // console.log('saving')
                setComponentSaving(true)

                try {
                  await Promise.all(
                    values.product_quotes[0].components?.map(async (component) => {
                      const compParams = {
                        id: component.id,
                        name: component.name,
                      }
                      await dataProvider.update('component', {
                        data: compParams,
                      })

                      //   size.scmQuoteSizes?.length > 0
                      //     ? await dataProvider.update('scm_quote_product_sizes', {
                      //         data: sizeParams,
                      //         where: { id: { _in: size.scmQuoteSizes } },
                      //       })
                      //     : null
                    }),
                  )

                  setComponentSaving(false)
                  setComponentEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setComponentSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: '#FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setComponentEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {componentSaving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setComponentEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box mt="20px" display="flex">
        <Box flex={1}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ width: '100%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Name
                  </StyledTableCell>
                  {/* <StyledTableCell
                    style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Allow Custom Colour
                  </StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.product_quotes[0].components?.map((item, index) => (
                  <ComponentRow
                    disabled={!componentEditing}
                    key={`comp${item.id}`}
                    index={index}
                    id={item.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {componentEditing ? (
            <IconButton
              aria-label="add-component"
              // className={classes.infoBtn}
              disabled={!componentEditing || componentSaving}
              onClick={async () => {
                setComponentSaving(true)
                const newCompResp = await dataProvider.create('component', {
                  data: {
                    product_quote_id: values.product_quotes[0].id,
                    name: '',
                  },
                })
                // console.log(newCompResp)
                const newCompOptions =
                  values.product_quotes[0].components?.length > 0
                    ? [...values.product_quotes[0].components]
                    : []
                newCompOptions.push({
                  id: newCompResp.data.id,
                  allow_custom_colour: newCompResp.allow_custom_colour,
                })
                await form.change('product_quotes[0].components', newCompOptions)

                const newVals = values.configurations.map((nval) => ({
                  ...nval,
                  values: nval.values?.map((nopt) => ({
                    ...nopt,
                    components: [...nopt.components, { id: newCompResp.data.id }],
                  })),
                }))
                await form.change('configurations', newVals)
                // console.log(newVals)
                setComponentSaving(false)
              }}
            >
              <AddIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box flex={4} />
      </Box>
    </Box>
  )
}

const DecorationsRow = ({ index, disabled, imprints = [] }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()

  return (
    <TableRow key={`decoRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productDecorations[${index}].label`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productDecorations[${index}].max_dimensions`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="left" style={{ padding: '0px' }}>
        {/* <AutocompleteInput
          source={`productDecorations[${index}].imprint_style`}
          fullWidth
          choices={imprints}
          label={false}
          optionText="name"
          optionValue="name"
          variant="standard"
          className={classes.numberTableInput}
          disabled={disabled}
        /> */}
        {imprints?.length > 0 ? (
          <AutocompleteArrayInput
            label={false}
            style={{ padding: '0px', margin: '0px', height: '38px' }}
            source={`productDecorations[${index}].imprintStyles`}
            choices={imprints || []}
            variant="standard"
            optionValue="name"
          />
        ) : (
          <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
        )}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productDecorations[${index}].unitCost`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <IconButton
          aria-label="info"
          className={classes.infoBtn}
          onClick={async () => {
            // console.log(values.productDecorations[index].id)

            try {
              await dataProvider.delete('product_quote_decorations', {
                data: { id: values.productDecorations[index].id },
              })
              const newCompOptions = [...values.productDecorations]
              newCompOptions.splice(index, 1)
              form.change('productDecorations', newCompOptions)
            } catch (error) {
              console.log(error)
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

const ComponentRow = ({ index, disabled }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()

  return (
    <TableRow key={`compRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`product_quotes[0].components[${index}].name`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      {/* <BodyTableCell align="right" style={{ padding: '0px' }}>
        <Checkbox
          disabled={disabled}
          checked={values.product_quotes[0].components[index].allow_custom_colour}
          onChange={() => {
            console.log('changing', values.packagingOptions)
            form.change(
              `product_quotes[0].components[${index}].allow_custom_colour`,
              !values.product_quotes[0].components[index].allow_custom_colour,
            )
          }}
          inputProps={{ 'aria-label': 'component allow custom colour checkbox' }}
        />
      </BodyTableCell> */}
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <IconButton
          aria-label="info"
          className={classes.infoBtn}
          onClick={async () => {
            // console.log(values.product_quotes[0].components[index].id)

            try {
              await dataProvider.delete('component', {
                data: { id: values.product_quotes[0].components[index].id },
              })
              const newCompOptions = [...values.product_quotes[0].components]
              newCompOptions.splice(index, 1)
              form.change('product_quotes[0].components', newCompOptions)
            } catch (error) {
              console.log(error)
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

const ProductOptionsTable = ({
  index,
  materials = [],
  optionEditing,
  optionSaving,
  setOptionSaving,
  setCurrentTab,
}) => {
  const form = useForm()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const { values } = useFormState()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="end">
        <Button
          label="Delete Option"
          style={{ margin: '10px 0px', color: 'maroon', border: 'solid 1px maroon' }}
          aria-describedby={id}
          onClick={handleClick}
        />
        {/* Delete Option
        </Button> */}
        <Popper
          style={{ zIndex: 1000 }}
          id={id}
          open={open}
          placement="left"
          anchorEl={anchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{
                  border: '1px solid',
                  borderRadius: '4px',
                  padding: '10px',
                  backgroundColor: 'white',
                }}
              >
                <WarningIcon style={{ color: 'red' }} />
                <span style={{ fontSize: '16px', margin: '10px 0px' }}>Are you sure?</span>
                <span style={{ fontSize: '12px', margin: '10px 0px' }}>
                  This will remove this option from all products and any associated client quotes.
                </span>
                <TextInput
                  variant="standard"
                  fullWidth
                  style={{ padding: '0px', margin: '0px', height: '100%' }}
                  source="deleteConfirm"
                  label="Enter the field name below to confirm"
                  className={classes.numberTableInput}
                  // validate={required()}
                />
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    label="Cancel"
                    className={classes.cancelButton}
                    // style={{ marginRight: '10px', color: 'green', border: '1px solid green' }}
                    onClick={() => setAnchorEl(null)}
                  />
                  {/* Cancel
                  </Button> */}
                  <Button
                    label="Confirm"
                    className={classes.confirmButton}
                    // style={{ color: 'red', border: '1px solid red' }}
                    disabled={values.configurations[index].name !== values.deleteConfirm}
                    onClick={async () => {
                      console.log('del', values.configurations[index].name)
                      await dataProvider.deleteMany('product_options', {
                        data: { name: values.configurations[index].name },
                      })

                      console.log(values.configurations)
                      const newConfigurations = [...values.configurations]
                      newConfigurations.splice(index, 1)

                      // console.log(newConfigurations)
                      setCurrentTab(`${newConfigurations[0].id}`)

                      form.change(`configurations`, newConfigurations)
                      form.change(`deleteConfirm`, '')

                      setAnchorEl(null)
                    }}
                  />
                  {/* Confirm
                  </Button> */}
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <NumberFormat
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            label="Maxiumum unique selections"
            disabled={!optionEditing}
            source={`configurations[${index}].max_unique_selections`}
            // validate={required()}
          />
        </Box>
        <Box ml="20px" flex={1}>
          <NumberFormat
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            label="Additional cost per unique choice"
            disabled={!optionEditing}
            source={`configurations[${index}].additionalCostPerChoice`}
            prefix="$ "
            // validate={required()}
          />
        </Box>
        <Box flex={3} />
      </Box>

      <Box mt="20px" display="flex" flexDirection="column">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '10px', padding: '0px' }}
                  align="left"
                >
                  <TextInput
                    variant="standard"
                    fullWidth
                    style={{ padding: '0px', margin: '0px', height: '100%' }}
                    source={`configurations[${index}].name`}
                    label={false}
                    disabled={!optionEditing}
                    className={classes.numberTableInput}
                    // validate={required()}
                  />
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  MOQ
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Setup Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Cost
                </StyledTableCell>
                {values.product_quotes[0].components?.map((cmp) => (
                  <StyledTableCell
                    key={cmp.id}
                    style={{
                      width: `${50 / (values.product_quotes[0].components?.length + 1)}%`,
                      fontSize: '10px',
                      padding: '10px',
                    }}
                    align="center"
                  >
                    {cmp.name}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.configurations[index]?.values?.map((item, ind) => (
                <ConfigurationRow
                  disabled={!optionEditing}
                  key={`cfg${item.id}`}
                  cfgIndex={index}
                  rowIndex={ind}
                  id={item.id}
                  materials={materials}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {optionEditing ? (
        <Box display="flex">
          <IconButton
            aria-label="add-value"
            // className={classes.infoBtn}
            disabled={!optionEditing || optionSaving}
            onClick={async () => {
              setOptionSaving(true)
              const newOptResp = await dataProvider.create('product_quote_option_values', {
                data: {
                  product_quote_option_id: values.configurations[index].id,
                  value: `value-${uniqueId()}`,
                  is_custom: false,
                },
              })

              const newCompOptions =
                values.configurations[index].values?.length > 0
                  ? [...values.configurations[index].values]
                  : []
              newCompOptions.push({
                id: newOptResp.data.id,
                moq: 0,
                setupCost: 0,
                unitCost: 0,
                components: values.product_quotes[0].components,
              })
              await form.change(`configurations[${index}].values`, newCompOptions)
              setOptionSaving(false)
            }}
          >
            <AddIcon />
            <span style={{ fontSize: '12px' }}>Add value</span>
          </IconButton>
          <IconButton
            aria-label="add-value"
            // className={classes.infoBtn}
            style={{ color: '#e3b5ef' }}
            disabled={!optionEditing || optionSaving}
            onClick={async () => {
              setOptionSaving(true)
              const newOptResp = await dataProvider.create('product_quote_option_values', {
                data: {
                  product_quote_option_id: values.configurations[index].id,
                  value: '',
                  is_custom: true,
                },
              })

              const newCompOptions =
                values.configurations[index].values?.length > 0
                  ? [...values.configurations[index].values]
                  : []
              newCompOptions.push({
                id: newOptResp.data.id,
                moq: 0,
                setupCost: 0,
                unitCost: 0,
                components: values.product_quotes[0].components,
              })
              await form.change(`configurations[${index}].values`, newCompOptions)
              setOptionSaving(false)
            }}
          >
            <AddIcon />
            <span style={{ fontSize: '12px', color: '#e3b5ef' }}>Add custom value</span>
          </IconButton>
        </Box>
      ) : null}
      {/* {values.configurations[index]?.name === 'Color' ||
      values.configurations[index]?.name === 'Colors' ? (
        <BooleanFieldInput
          source="product_quotes[0].allow_order_splitting_by_colour"
          field="allow_order_splitting_by_colour"
          resource="product_quote"
          id={values.product_quotes[0].id}
          label="Allow order splitting by colors?"
          style={{ marginTop: '20px' }}
        />
      ) : null} */}
    </Box>
  )
}

const ConfigurationRow = ({ rowIndex, cfgIndex, disabled, materials = [] }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()

  // console.log(values.configurations[cfgIndex].is_custom)

  if (values.confignurations?.length < cfgIndex) return null

  return (
    <TableRow
      key={`cfgRow${rowIndex}`}
      style={
        values.configurations[cfgIndex]?.values[rowIndex]?.is_custom
          ? { backgroundColor: '#e3b5ef' }
          : null
      }
    >
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        {!values.configurations[cfgIndex]?.values[rowIndex]?.is_custom &&
        (values.configurations[cfgIndex]?.name === 'Material' ||
          values.configurations[cfgIndex]?.name === 'Materials') ? (
          <AutocompleteInput
            source={`configurations[${cfgIndex}].values.[${rowIndex}].value`}
            fullWidth
            choices={materials}
            label={false}
            optionText="name"
            optionValue="name"
            variant="standard"
            style={{ padding: '0px', margin: '0px', height: '100%' }}
            className={classes.numberTableInput}
            disabled={disabled}
          />
        ) : !values.configurations[cfgIndex]?.values[rowIndex]?.is_custom &&
          (values.configurations[cfgIndex]?.name === 'Color' ||
            values.configurations[cfgIndex]?.name === 'Colors') ? (
          <ColorInput
            source={`configurations[${cfgIndex}].values.[${rowIndex}].value`}
            className={`${classes.hideLabel} ${classes.colourPickerItem}`}
            picker="Sketch"
            label={false}
            // validate={required()}
          />
        ) : (
          <TextInput
            variant="standard"
            fullWidth
            style={{ padding: '0px', margin: '0px', height: '100%' }}
            source={`configurations[${cfgIndex}].values.[${rowIndex}].value`}
            label={false}
            disabled={disabled}
            // validate={required()}
          />
        )}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`configurations[${cfgIndex}].values.[${rowIndex}].moq`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`configurations[${cfgIndex}].values.[${rowIndex}].setupCost`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`configurations[${cfgIndex}].values.[${rowIndex}].unitCost`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      {values.configurations[cfgIndex]?.values[rowIndex]?.components?.map((cmp, cmpInd) => (
        <BodyTableCell key={cmp.id} align="center" style={{ padding: '0px' }}>
          <Checkbox
            disabled={disabled}
            checked={
              values.configurations[cfgIndex]?.values[rowIndex]?.components[cmpInd]?.active || false
            }
            onChange={() => {
              form.change(
                `configurations[${cfgIndex}].values[${rowIndex}].components[${cmpInd}].active`,
                !values.configurations[cfgIndex].values[rowIndex].components[cmpInd]?.active,
              )
            }}
            inputProps={{ 'aria-label': 'component allow custom colour checkbox' }}
          />
        </BodyTableCell>
      ))}

      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <IconButton
          aria-label="info"
          className={classes.infoBtn}
          onClick={async () => {
            // console.log(values.configurations)
            // console.log(values.configurations[cfgIndex])
            // console.log(values.configurations[cfgIndex].values[rowIndex].id)
            try {
              await dataProvider.delete('component_options', {
                data: {
                  where: {
                    product_quote_option_value_id: {
                      _eq: values.configurations[cfgIndex].values[rowIndex].id,
                    },
                  },
                },
              })

              await dataProvider.delete('product_quote_option_values', {
                data: { id: values.configurations[cfgIndex].values[rowIndex].id },
              })
              const newCompOptions = [...values.configurations[cfgIndex].values]
              newCompOptions.splice(rowIndex, 1)
              form.change(`configurations[${cfgIndex}].values`, newCompOptions)
            } catch (error) {
              console.log(error)
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

export default ProductOptionsForm
