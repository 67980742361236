import React, { useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { useForm } from 'react-final-form'
// import { useNotify, useDataProvider } from 'react-admin'
// import moment from 'moment'
// import uploadFile from '../../../dataProvider/upload'
import uploadFile from '../../../dataProvider/aws_upload'
import { useDropzone } from 'react-dropzone'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import useStyles from '../styles/importerStyles'

const Photos = ({ id, values }) => {
  const classes = useStyles()
  const form = useForm()
  // const dataProvider = useDataProvider()
  // const notify = useNotify()
  const [uploading, setUploading] = useState(false)
  // const [progress, setProgress] = useState(0)
  const [newImages, setNewImages] = useState([
    // {
    //   name: 'fwewf',
    //   url: 'https://beni-development.nyc3.digitaloceanspaces.com/test_1677867220909.jpeg',
    // },
  ])

  // const { values } = useFormState()

  // console.log('VALS', values)

  // console.log('UPLOADING', uploading, 'NEWIMGS', newImages)

  const previews = values.data[id].images
    ?.filter((item) => !!item.deleted_at === false)
    .map((file) => {
      // const currentTime = moment().toISOString()

      return (
        <Box
          key={`preview${file.file?.data?.filename || file.id}`}
          className={classes.previewImageBox}
        >
          <img
            src={file.file?.data?.url}
            className={classes.previewImage}
            onClick={async () => {
              // form.change('primary_photo_number', index)
              // setSelectedPhoto(index)
              try {
                // await dataProvider.update('product', {
                //   data: { thumbnail_photo_id: file.file.id, primary_photo_id: file.file.id, id },
                // })
                form.change(`data[${id}].primary_photo`, file?.file?.data)
                form.change(`data[${id}].thumbnail_photo`, file?.file?.data)
                // notify('Update Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                // notify(error.message, { type: 'warning' })
              }
            }}
          />
          <IconButton
            aria-label="delete"
            className={classes.deleteBtn}
            onClick={async () => {
              // console.log(values.data[id].images, file)
              const image = values.data[id].images.findIndex((item) => item.id === file.id)
              try {
                // await dataProvider.delete('product_file', {
                //   data: { id: file.id },
                // })
                const newImages = values.data[id].images.splice(image, 1)
                form.change(`data[${id}].images`, newImages)
                // notify('Image Deleted', { type: 'info' })
              } catch (error) {
                console.log(error)
                // notify(error.message, { type: 'warning' })
              }
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles)
      if (acceptedFiles && acceptedFiles.length > 0) {
        setNewImages(() => {
          let fileReader,
            isCancel = false
          const files = []
          acceptedFiles?.map(async (item) => {
            fileReader = new FileReader()
            fileReader.onload = (e) => {
              const { result } = e.target
              if (result && !isCancel) {
                // console.log(!!result, !isCancel)
                item.preview = result
              }
            }
            await fileReader.readAsDataURL(item)
            files.push(item)
            // console.log(item)
            return () => {
              isCancel = true
              if (fileReader && fileReader.readyState === 1) {
                fileReader.abort()
              }
            }
          })
          // console.log(files)
          return files
        })
        setUploading(true)
        const files = []

        await Promise.all(
          acceptedFiles.map(async (photo) => {
            const file = await uploadFile(photo)

            // console.log('FILEURL', file)

            files.push({
              ...(id && { product_id: id }),
              type: '^ADDITIONAL_PHOTO^',
              file: {
                data: {
                  url: file,
                  filename: photo.name || photo.path,
                },
              },
            })
          }),
        )

        // console.log('PRODIMGS', values.data[id].images, 'FILES', files)

        try {
          // const { data } = await dataProvider.createMany('product_file', {
          //   objects: files,
          // })

          // console.log('RESP', data)
          form.change(`data[${id}].images`, [...(values.data[id].images || []), ...(files || [])])
          setNewImages([])

          if (!values.data[id].primary_photo?.id) {
            // await dataProvider.update('product', {
            //   data: {
            //     thumbnail_photo_id: data[0].file?.id,
            //     primary_photo_id: data[0].file?.id,
            //     id,
            //   },
            // })
            form.change(`data[${id}].primary_photo`, {
              // id: data[0].file?.id,
              filename: files[0].file?.data?.filename,
              url: files[0].file?.data?.url,
            })
            form.change(`data[${id}].thumbnail_photo`, {
              // id: data[0].file?.id,
              filename: files[0].file?.data?.filename,
              url: files[0].file?.data?.url,
            })
          }
          setUploading(false)
          // setProgress(0)

          // notify('Upload Complete', { type: 'info' })
        } catch (error) {
          // notify(error.message, { type: 'warning' })
          console.log(error)
          setUploading(false)
        }
      }
    },
  })

  return (
    <div>
      <div {...getRootProps({ className: classes.imageDropZone })}>
        <input {...getInputProps()} />
        {values.data[id].primary_photo ? (
          <img src={values.data[id].primary_photo.url} alt="alt" className={classes.primaryPhoto} />
        ) : null}

        {!values.data[id].primary_photo ? (
          <span>{`Drag & drop some files here, or click to select files`}</span>
        ) : null}
      </div>
      <Box className={classes.previewsContainer} alignItems="center">
        {previews}
        {newImages.map((file) => {
          // console.log(file)
          return <img key={`preview${file.name}`} src={file} className={classes.previewNewImage} />
        })}
        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
    </div>
  )
}

export default Photos
