import React, { useState, useEffect } from 'react'
import { FormWithRedirect, useNotify, useDataProvider, useRedirect } from 'react-admin'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grow,
  IconButton,
  MenuItem,
  Select,
  // Switch,
  TextField,
  makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CancelIcon from '@material-ui/icons/Cancel'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
// import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useFormState, useForm } from 'react-final-form'
import { uniqueId } from 'lodash'
import GridLayout from 'react-grid-layout'
// import ImageDropzone from './ImageDropzone'
import { useDropzone } from 'react-dropzone'
import uploadFile from '../../../dataProvider/aws_upload'

const useStyles = makeStyles({
  sectionContentBoxHeader: {
    position: 'absolute',
    padding: '15px',
    borderRadius: '4px',
    top: '20px',
    left: '20px',
    backgroundColor: 'rgba(150,150,200, 1)',
    zIndex: 1000,
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  menuButton: {
    position: 'absolute',
    width: '5px',
    height: '5px',
    top: '5%',
    backgroundColor: 'white',
    left: '2%',
    zIndex: '100',
  },
  removeButton: {
    position: 'absolute',
    width: '5px',
    height: '5px',
    top: '5%',
    backgroundColor: 'white',
    right: '2%',
    zIndex: '100',
  },
  dragging: {
    opacity: 0.5,
  },
  buttonStyle: {
    border: 'solid 1px gray',
    backgroundColor: 'rgba(255, 149, 102, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(255, 149, 102, 0.8)',
    },
  },
  preview: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: '50',
    width: '99%',
    height: '99%',
    margin: '0px',
    padding: '0px',
  },
})
const PlatformConfigEdit = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  // const { record } = props
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState(false)
  const [platformData, setPlatformData] = useState()
  const [draggingItem, setDraggingItem] = useState(null)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const { data: platformData } = await dataProvider.getList('configSection', {
          pagination: { page: 1, perPage: 1000 },
          filter: { platform: props?.match?.params?.id },
          sort: { field: 'position', order: 'ASC' },
        })
        !abortController.signal.aborted && setPlatformData(platformData)
        const { data: productsList } = await dataProvider.getList('saleable_product_variants', {
          filter: {
            is_listed: true,
          },
          sort: { field: 'id', order: 'desc' },
          pagination: { page: 1, perPage: 100 },
        })
        !abortController.signal.aborted && setProducts(productsList)
        const { data: catData } = await dataProvider.getList('categories', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        !abortController.signal.aborted && setCategories(catData)
        const { data: brandData } = await dataProvider.getList('brands', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        !abortController.signal.aborted && setBrands(brandData)
        const { data: offerData } = await dataProvider.getList('offer', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'desc' },
          filter: { type: 'PUBLIC', status: 'PENDING_PURCHASE_ORDER' },
        })
        !abortController.signal.aborted && setOffers(offerData)

        setLoading(false)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        setLoading(false)
      }
    }
    const abortController = new AbortController()

    !abortController.signal.aborted && getData()

    return () => {
      abortController.abort()
    }
  }, [])

  const sections = platformData?.map((el) => ({
    ...el,
    active: el.status === 'ACTIVE',
    title: el.title || '',
    reference: el.reference || '',
    content: el.content?.map((cont, index) => ({
      ...cont,
      x: cont.x_pos,
      y: cont.y_pos,
      w: cont.col_span,
      h: cont.row_span,
      i: `${index}`,
      action: cont.action,
      static: false,
      file: cont.image,
      option:
        cont.action === 'product'
          ? cont.saleable_product_variant
          : cont.action === 'brand'
          ? cont.brand
          : cont.action === 'offer'
          ? cont.offer
          : cont.action === 'category'
          ? cont.category
          : cont.action === 'external' || cont.action === 'internal'
          ? cont.url
          : '',
      image: cont.image,
      url: cont.url || '',
    })),
  }))

  // console.log('LoadedSections:', sections)

  if (loading) {
    return <CircularProgress style={{ width: '20px', height: '20px' }} />
  }

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        sections,
      }}
      render={(formProps) => (
        <FormData
          formProps={formProps}
          loading={loading}
          setLoading={setLoading}
          offers={offers}
          brands={brands}
          categories={categories}
          products={products}
          draggingItem={draggingItem}
          setDraggingItem={setDraggingItem}
        />
      )}
    />
  )
}

const FormData = (props) => {
  const form = useForm()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const { values } = useFormState()
  const { brands, offers, categories, products, draggingItem, setDraggingItem } = props

  // console.log('FormVals', values)
  return (
    <Box p="20px">
      <Box m="20px 0px" display="flex" justifyContent="space-between" alignItems="flex-start">
        <span style={{ fontSize: '26px' }}>Edit Homepage Sections</span>

        <Button
          className={classes.buttonStyle}
          onClick={async () => {
            // console.log(values)

            const params = values.sections?.map((section, index) => {
              const sortedContent = section.content?.sort((a, b) => a.y - b.y || a.x - b.x)
              // console.log(sortedContent)

              return {
                ...(typeof section.id === 'number' && { id: section.id }),
                type: 'home',
                ...(section.title && { title: section.title }),
                position: index,
                status: section.active ? 'ACTIVE' : 'INACTIVE',
                ...(section.reference && { reference: section.reference }),
                platform: 'CLIQUESTOCK',
                layout: section.layout,
                num_rows: section.layout === '6x1' ? 1 : 2,
                num_cols: section.layout === '9x2' ? 9 : section.layout === '6x1' ? 6 : 4,
                content: {
                  data: sortedContent?.map((cont, contentIndex) => ({
                    ...(typeof cont.id === 'number' && { id: cont.id }),
                    saleable_product_variant_id: cont.action === 'product' ? cont.option?.id : null,
                    brand_id: cont.action === 'brand' ? cont.option?.id : null,
                    offer_id: cont.action === 'offer' ? cont.option?.id : null,
                    category_id: cont.action === 'category' ? cont.option?.id : null,
                    ...(cont.image?.id && { image_id: cont.image.id }),
                    // ...(cont.action === 'product' && {saleable_product_variant_id: option.id}),
                    action: cont.action,
                    position: contentIndex,
                    ...(cont.url && { url: cont.url }),
                    col_span: cont.w,
                    row_span: cont.h,
                    x_pos: cont.x,
                    y_pos: cont.y,
                  })),
                  on_conflict: {
                    constraint: 'config_section_references_pkey',
                    update_columns: [
                      'saleable_product_variant_id',
                      'brand_id',
                      'offer_id',
                      'category_id',
                      'image_id',
                      'action',
                      'position',
                      'url',
                      'col_span',
                      'row_span',
                      'x_pos',
                      'y_pos',
                    ],
                  },
                },
              }
            })

            try {
              // console.log(params)
              await dataProvider.create('config_section', {
                data: params,
                on_conflict: {
                  constraint: 'platform_configuration_section_pkey',
                  update_columns: [
                    'type',
                    'title',
                    'position',
                    'status',
                    'reference',
                    'platform',
                    'layout',
                    'num_cols',
                    'num_rows',
                  ],
                },
              })
              notify('Saved', { type: 'info' })

              redirect('/platformConfig')
            } catch (error) {
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          Save
        </Button>
      </Box>
      {values.sections?.map((section, index) => (
        <SectionBox
          key={section.id}
          index={index}
          brands={brands}
          offers={offers}
          products={products}
          categories={categories}
          draggingItem={draggingItem}
          setDraggingItem={setDraggingItem}
        />
      ))}
      <Box display="flex" mt="20px">
        <Button
          className={classes.buttonStyle}
          onClick={() => {
            const newSections = [...(values.sections || [])]
            newSections.push({
              id: uniqueId('9g'),
              layout: '9x2',
              reference: '',
              active: false,
              title: '',
              content: [
                {
                  x: 0,
                  y: 0,
                  w: 5,
                  h: 1,
                  i: '0',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 5,
                  y: 0,
                  w: 2,
                  h: 1,
                  i: '1',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 7,
                  y: 0,
                  w: 2,
                  h: 1,
                  i: '2',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 0,
                  y: 1,
                  w: 2,
                  h: 1,
                  i: '3',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 2,
                  y: 1,
                  w: 2,
                  h: 1,
                  i: '4',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 4,
                  y: 1,
                  w: 5,
                  h: 1,
                  i: '5',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
              ],
            })
            // console.log('newSections', newSections)
            form.change('sections', newSections)
          }}
        >
          Add new 9x2 section
        </Button>
        <Button
          className={classes.buttonStyle}
          style={{ margin: '0px 10px' }}
          onClick={() => {
            const newSections = [...(values.sections || [])]
            newSections.push({
              id: uniqueId('6g'),
              layout: '6x1',
              reference: '',
              active: false,
              title: '',
              content: [
                {
                  x: 0,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '0',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 1,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '1',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 2,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '2',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 3,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '3',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 4,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '4',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 5,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '5',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
              ],
            })
            // console.log(newSections)
            form.change('sections', newSections)
          }}
        >
          Add new 6x1 section
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={() => {
            // console.log(values.sections)
            const newSections = [...(values.sections || [])]
            // console.log(newSections)
            newSections.push({
              id: uniqueId('5g'),
              layout: '1+2x2',
              reference: '',
              active: false,
              title: '',
              content: [
                {
                  x: 0,
                  y: 0,
                  w: 2,
                  h: 2,
                  i: '0',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 2,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '1',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 4,
                  y: 0,
                  w: 1,
                  h: 1,
                  i: '2',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 2,
                  y: 1,
                  w: 1,
                  h: 1,
                  i: '3',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
                {
                  x: 4,
                  y: 1,
                  w: 1,
                  h: 1,
                  i: '4',
                  static: false,
                  file: {},
                  action: 'product',
                  option: '',
                  image: '',
                  url: '',
                },
              ],
            })
            // console.log(newSections)
            form.change('sections', newSections)
          }}
        >
          Add new 1 + 2x2 section
        </Button>
      </Box>
    </Box>
  )
}

const SectionBox = ({
  index,
  brands,
  offers,
  products,
  categories,
  draggingItem,
  setDraggingItem,
}) => {
  const { values } = useFormState()
  const form = useForm()
  const classes = useStyles()

  const handleDragStart = (e, item) => {
    // console.log('dragStart', item)
    setDraggingItem(item)
    e.dataTransfer.setData('text/plain', '')
  }

  const handleDragEnd = () => {
    // console.log('dragNull')
    setDraggingItem(null)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e, targetItem) => {
    // console.log(draggingItem)
    // console.log(targetItem)
    e.preventDefault()
    e.stopPropagation()
    // console.log(draggingItem)

    const currentIndex = values.sections.indexOf(draggingItem)
    const targetIndex = values.sections.indexOf(targetItem)
    // console.log(draggingItem, currentIndex, targetIndex)
    if (currentIndex === -1 || targetIndex === -1) return

    const newSections = [...values.sections]
    newSections.splice(currentIndex, 1)
    newSections.splice(targetIndex, 0, draggingItem)
    // console.log(newSections)
    form.batch(() => {
      form.change('sections', newSections)
    })
    // setDraggingItem(null)
  }

  const layout = values.sections[index].layout

  // console.log('section', index, values.sections[index].layout)

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ margin: '0px', padding: '4px 0px', height: '50px' }}
      >
        <Box display="flex" width="100%" alignItems="flex-end" justifyContent="space-between">
          <Box display="flex" m="10px">
            {values.sections[index].layout}
            {values.sections[index].reference && ` - ${values.sections[index].reference}`}
          </Box>
          <Box
            display="flex"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <FormControlLabel
              value="active"
              control={
                <Checkbox
                  key={`chk${index}`}
                  checked={values.sections[index].active}
                  onChange={() =>
                    form.change(`sections[${index}].active`, !values.sections[index].active)
                  }
                  inputProps={{ 'aria-label': 'section active checkbox' }}
                />
              }
              label="Active"
              labelPlacement="start"
            />
            <div
              key={values.sections[index].id}
              className={values.sections[index].id === draggingItem?.id ? 'dragging' : undefined}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, values.sections[index])}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, values.sections[index])}
              style={{ display: 'flex', cursor: 'move', margin: '10px' }}
            >
              <DragIndicatorIcon />
            </div>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column">
          <Box display="flex" m="10px">
            <TextField
              label="Title"
              fullWidth
              value={values.sections[index]?.title}
              onChange={(e) => form.change(`sections[${index}].title`, e.target.value)}
              autoFocus
              style={{ maxWidth: '220px', margin: '0px 20px' }}
            />
            <TextField
              label="Internal Ref:"
              fullWidth
              value={values.sections[index]?.reference}
              onChange={(e) => form.change(`sections[${index}].reference`, e.target.value)}
              autoFocus
              style={{ maxWidth: '220px', margin: '0px 20px' }}
            />
          </Box>

          <GridLayout
            className="layout"
            cols={layout === '9x2' ? 9 : layout === '6x1' ? 6 : 4}
            rowHeight={layout === '9x2' ? 240 : layout === '6x1' ? 180 : 260}
            width={1200}
            draggableCancel=".drag-cancel"
            isResizable={false}
            style={{ backgroundColor: '#FFF' }}
            onLayoutChange={(e) => {
              // console.log(e)
              const oldItems = values.sections[index].content
              // console.log(oldItems)
              const newItems = e.map((el) => {
                // console.log(el)
                const oldItem = oldItems.find((old) => old.i === el.i)
                return {
                  ...oldItem,
                  ...el,
                }
              })
              newItems.sort((a, b) => a.y - b.y || a.x - b.x)
              form.change(`sections[${index}].content`, newItems)
            }}
            compactType="horizontal"
          >
            {values.sections[index].content?.map((l, i) => {
              // index === 0 && console.log(l, i)
              const [showMenu, setShowMenu] = useState(false)
              return (
                <div
                  key={l.i}
                  style={
                    showMenu
                      ? { position: 'relative', border: 'solid 1px gray' }
                      : { position: 'relative', cursor: 'move', border: 'solid 1px gray' }
                  }
                  data-grid={{ x: l.x || 0, y: l.y || 0, w: l.w || 1, h: l.h || 1 }}
                >
                  <SectionBoxItem
                    key={l.id}
                    l={l}
                    i={i}
                    index={index}
                    brands={brands}
                    offers={offers}
                    products={products}
                    categories={categories}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                  />
                </div>
              )
            })}
          </GridLayout>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const SectionBoxItem = (props) => {
  const { values } = useFormState()
  const form = useForm()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const { l, index, i, brands, offers, products, categories, showMenu, setShowMenu } = props
  const [options, setOptions] = useState(
    l.action === 'product'
      ? products
      : l.action === 'brand'
      ? brands
      : l.action === 'offer'
      ? offers
      : l.action === 'category'
      ? categories
      : products,
  )

  // console.log(l.type, options)

  useEffect(() => {
    l.action === 'product'
      ? setOptions(products)
      : l.action === 'brand'
      ? setOptions(brands)
      : l.action === 'offer'
      ? setOptions(offers)
      : l.action === 'category'
      ? setOptions(categories)
      : []
  }, [values.sections[index].content[i].action])

  const option = values.sections[index].content[i].option

  // console.log(option)

  const imageOptions = []
  option?.image?.url &&
    imageOptions.push({
      id: option?.image.id,
      value: option.image?.url,
      url: option.image?.url,
      filename: option.image?.filename,
      name: option?.image?.filename,
    }),
    option?.top_banner?.url &&
      imageOptions.push({
        id: option?.top_banner.id,
        value: option.top_banner?.url,
        url: option.top_banner?.url,
        filename: option.top_banner?.filename,
        name: option?.top_banner?.filename,
      }),
    option?.hero_banner?.url &&
      imageOptions.push({
        id: option?.hero_banner.id,
        value: option.hero_banner?.url,
        url: option.hero_banner?.url,
        filename: option.hero_banner?.filename,
        name: option?.hero_banner?.filename,
      }),
    option?.banner?.url &&
      imageOptions.push({
        id: option?.banner.id,
        value: option.banner?.url,
        url: option.banner?.url,
        filename: option.banner?.filename,
        name: option?.banner?.filename,
      })
  option?.primary_photo?.url &&
    imageOptions.push({
      id: option?.primary_photo?.id,
      value: option.primary_photo?.url,
      url: option.primary_photo?.url,
      filename: option.primary_photo?.filename,
      name: option?.primary_photo?.filename,
    })

  const currentImage =
    values.sections[index].content[i].file || values.sections[index].content[i].image

  if (currentImage?.id && !imageOptions.some((el) => el.id === currentImage?.id)) {
    imageOptions.push({
      id: currentImage?.id,
      value: currentImage?.url,
      url: currentImage?.url,
      filename: currentImage?.filename,
      name: currentImage?.filename,
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    // onClick: (e) => e.stopPropagation(),
    accept: ['image/*'],
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const url = await uploadFile(acceptedFiles[0])
        // console.log(acceptedFiles)

        // const a = new FileReader()
        // a.readAsArrayBuffer(acceptedFiles[0])
        // const getHash = (a.onloadend = async () => {
        //   return await crypto.subtle.digest('SHA-256', a.result) // it outputs a promise
        // })

        // const hashResp = getHash()
        // console.log(hashResp)
        const fileResp = await dataProvider.create('file', {
          data: { filename: acceptedFiles[0].name, url },
        })

        form.change(`sections[${index}].content[${i}].image`, {
          id: fileResp.data.id,
          url: url,
          blob: acceptedFiles[0],
        })
      }
    },
  })

  // console.log(i, l)
  return (
    <div>
      <Grow in={showMenu} timeout={300}>
        <Box
          className={classes.sectionContentBoxHeader}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Select
            label="Action"
            value={values.sections[index]?.content[i].action}
            onChange={(e) => form.change(`sections[${index}].content[${i}].action`, e.target.value)}
            // fullWidth
            style={{ minWidth: '100px' }}
          >
            <MenuItem value="product" key="product">
              Product
            </MenuItem>
            <MenuItem value="brand" key="brand">
              Brand
            </MenuItem>
            <MenuItem value="offer" key="offer">
              Group Purchase
            </MenuItem>
            <MenuItem value="category" key="category">
              Category
            </MenuItem>
            <MenuItem value="external" key="external">
              External Link
            </MenuItem>
            <MenuItem value="internal" key="internal">
              Internal Link
            </MenuItem>
            <MenuItem value="collection" disabled key="collection">
              Collection
            </MenuItem>
          </Select>
          {l.action === 'internal' ? (
            <TextField
              label="Full url"
              fullWidth
              value={values.sections[index]?.content[i].url}
              onChange={(e) => form.change(`sections[${index}].content[${i}].url`, e.target.value)}
              autoFocus
              style={{ maxWidth: '120px', margin: '5px 0px' }}
            />
          ) : l.action === 'external' ? (
            <TextField
              label="Page url"
              fullWidth
              value={values.sections[index]?.content[i].url}
              onChange={(e) => form.change(`sections[${index}].content[${i}].url`, e.target.value)}
              autoFocus
              style={{ maxWidth: '120px', margin: '5px 0px' }}
            />
          ) : (
            <Autocomplete
              id="options"
              options={options}
              disabled={options?.length < 1}
              value={values.sections[index]?.content[i].option}
              onChange={(e, v) => form.change(`sections[${index}].content[${i}].option`, v)}
              getOptionSelected={(option, value) => {
                if (value === '') {
                  return true
                } else if (value.id === option.id) {
                  return true
                }
              }}
              getOptionLabel={(option) => {
                return option.__typename === 'offer'
                  ? `${option.name} ${option.total_sold || 0}/${option.moq}`
                  : option.name || ''
              }}
              // filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Option"
                  placeholder=""
                  variant="standard"
                  fullWidth
                  style={{ margin: '5px 0px' }}
                />
              )}
            />
          )}

          {l.action !== 'internal' && l.action !== 'external' && (
            <Autocomplete
              id="imageoptions"
              options={imageOptions}
              // disabled={!option}
              value={values.sections[index]?.content[i].image}
              onChange={(e, v) => {
                // console.log(v)
                // console.log(imageOptions)
                form.change(`sections[${index}].content[${i}].image`, v)
              }}
              getOptionSelected={(option, value) => {
                if (value === '') {
                  return true
                } else if (value.id === option.id) {
                  return true
                }
              }}
              getOptionLabel={(option) => {
                return option.name || option.filename || ''
              }}
              // filterSelectedOptions
              renderOption={(option) => {
                // console.log(option, state)
                return (
                  <img
                    src={option.url}
                    alt={option.filename}
                    style={{ width: '150px', maxHeight: '150px' }}
                  />
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Image"
                  placeholder=""
                  variant="standard"
                  fullWidth
                  style={{ margin: '5px 0px' }}
                />
              )}
            />
          )}
          <div
            {...getRootProps()}
            className="drag-cancel"
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              opacity: '0.9',
              border: 'dashed gray 1px',
              cursor: 'pointer',
              display: 'flex',
            }}
          >
            <input {...getInputProps()} />
            <span
              className="drag-cancel"
              style={{ fontSize: '10px' }}
            >{`Select above, or drag & drop / click here to upload`}</span>
          </div>
        </Box>
      </Grow>
      <Box
        className="drag-cancel"
        style={{ height: '30px', width: '100%', zIndex: 2220, position: 'absolute' }}
      >
        <IconButton
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
          }}
          className={classes.menuButton}
        >
          {showMenu ? <CancelIcon fontSize="small" /> : <BorderColorIcon fontSize="small" />}
        </IconButton>
        {values.sections?.length > index &&
          values.sections[index].content?.length > i &&
          values.sections[index].content[i].image?.url && (
            <IconButton
              onClick={() => {
                form.change(`sections[${index}].content[${i}].image`, null)
              }}
              style={{
                maxWidth: '30px',
                maxHeight: '30px',
              }}
              className={classes.removeButton}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          )}
      </Box>
      {values.sections?.length > index &&
        values.sections[index].content?.length > i &&
        values.sections[index].content[i].image?.url && (
          <img
            alt=""
            src={values.sections[index].content[i].image?.url}
            className={classes.preview}
          />
        )}
    </div>
  )
}

export default PlatformConfigEdit
