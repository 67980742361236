import { TypeKind } from 'graphql'
import * as gqlTypes from 'graphql-ast-types-browser'
import {
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE_MANY,
} from './fetchActions'

import getFinalType from './getFinalType'
import isList from './isList'
import isRequired from './isRequired'

export const buildFragments = (introspectionResults) => (possibleTypes) =>
  possibleTypes.reduce((acc, possibleType) => {
    const type = getFinalType(possibleType)

    const linkedType = introspectionResults.types.find((t) => t.name === type.name)

    return [
      ...acc,
      gqlTypes.inlineFragment(
        gqlTypes.selectionSet(buildFields(introspectionResults)(linkedType)),
        gqlTypes.namedType(gqlTypes.name(type.name)),
      ),
    ]
  }, [])

export const buildFields =
  () =>
  // introspectionResults,
  //  path = []
  (types) =>
    types.fields.reduce((acc, field) => {
      // console.log('ACCFIELD', field)
      const type = getFinalType(field.type)

      if (type.name.startsWith('_')) {
        return acc
      }

      if (type.kind !== TypeKind.OBJECT && type.kind !== TypeKind.INTERFACE) {
        return [...acc, gqlTypes.field(gqlTypes.name(field.name))]
      }
      // const linkedResource = introspectionResults.resources.find((r) => r.type.name === type.name)

      // console.log(linkedResource)

      // if (linkedResource.type?.name === 'file') {
      //   // return acc
      //   return [
      //     ...acc,
      //     gqlTypes.field(
      //       gqlTypes.name(field.name),
      //       null,
      //       null,
      //       null,
      //       gqlTypes.selectionSet([
      //         ...buildFields(introspectionResults, [...path, linkedResource.name])(
      //           linkedResource.type,
      //         ),
      //       ]),
      //     ),
      //   ]
      // }

      // const linkedType = introspectionResults.types.find((t) => t.name === type.name)

      // if (linkedType && !path.includes(linkedType.name)) {
      //   return acc
      //   // return [
      //   //   ...acc,
      //   //   gqlTypes.field(
      //   //     gqlTypes.name(field.name),
      //   //     null,
      //   //     null,
      //   //     null,
      //   //     gqlTypes.selectionSet([
      //   //       ...buildFragments(introspectionResults)(linkedType.possibleTypes || []),
      //   //       ...buildFields(introspectionResults, [...path, linkedType.name])(linkedType),
      //   //     ]),
      //   //   ),
      //   // ]
      // }

      return acc
    }, [])

export const getArgType = (arg) => {
  // console.log('ARG', arg)
  const type = getFinalType(arg.type)
  const required = isRequired(arg.type)
  const list = isList(arg.type)

  if (required) {
    if (list) {
      return gqlTypes.nonNullType(
        gqlTypes.listType(gqlTypes.nonNullType(gqlTypes.namedType(gqlTypes.name(type.name)))),
      )
    }

    return gqlTypes.nonNullType(gqlTypes.namedType(gqlTypes.name(type.name)))
  }

  if (list) {
    return gqlTypes.listType(gqlTypes.namedType(gqlTypes.name(type.name)))
  }

  return gqlTypes.namedType(gqlTypes.name(type.name))
}

export const buildArgs = (query, variables) => {
  if (query.args.length === 0) {
    return []
  }

  const validVariables = Object.keys(variables).filter((k) => typeof variables[k] !== 'undefined')

  const args = query.args
    .filter((a) => validVariables.includes(a.name))
    .reduce(
      (acc, arg) => [
        ...acc,
        gqlTypes.argument(gqlTypes.name(arg.name), gqlTypes.variable(gqlTypes.name(arg.name))),
      ],
      [],
    )

  return args
}

export const buildMetaArgs = (query, variables, aorFetchType) => {
  if (query.args.length === 0) {
    return []
  }

  const validVariables = Object.keys(variables).filter((k) => {
    if (
      aorFetchType === GET_LIST ||
      aorFetchType === GET_MANY ||
      aorFetchType === GET_MANY_REFERENCE
    ) {
      return typeof variables[k] !== 'undefined' && k !== 'limit' && k !== 'offset'
    }

    return typeof variables[k] !== 'undefined'
  })

  const args = query.args
    .filter((a) => validVariables.includes(a.name))
    .reduce(
      (acc, arg) => [
        ...acc,
        gqlTypes.argument(gqlTypes.name(arg.name), gqlTypes.variable(gqlTypes.name(arg.name))),
      ],
      [],
    )

  return args
}

export const buildApolloArgs = (query, variables) => {
  if (query.args.length === 0) {
    return []
  }

  const validVariables = Object.keys(variables).filter((k) => typeof variables[k] !== 'undefined')

  const args = query.args
    .filter((a) => validVariables.includes(a.name))
    .reduce((acc, arg) => {
      return [
        ...acc,
        gqlTypes.variableDefinition(gqlTypes.variable(gqlTypes.name(arg.name)), getArgType(arg)),
      ]
    }, [])

  return args
}

export const buildGqlQueryFun =
  (introspectionResults, _buildFields, _buildMetaArgs, _buildArgs, _buildApolloArgs) =>
  (resource, aorFetchType, queryType, variables) => {
    // eslint-disable-next-line no-unused-vars
    const { sortField, sortOrder, ...metaVariables } = variables
    const apolloArgs = _buildApolloArgs(queryType, variables)
    const args = _buildArgs(queryType, variables)
    const metaArgs = _buildMetaArgs(queryType, metaVariables, aorFetchType)
    const fields = _buildFields(introspectionResults)(resource.type)
    if (
      aorFetchType === GET_LIST ||
      aorFetchType === GET_MANY ||
      aorFetchType === GET_MANY_REFERENCE
    ) {
      return gqlTypes.document([
        gqlTypes.operationDefinition(
          'query',
          gqlTypes.selectionSet([
            gqlTypes.field(
              gqlTypes.name(queryType.name),
              gqlTypes.name('items'),
              args,
              null,
              gqlTypes.selectionSet(fields),
            ),
            gqlTypes.field(
              gqlTypes.name(`${queryType.name}_aggregate`),
              gqlTypes.name('total'),
              metaArgs,
              null,
              gqlTypes.selectionSet([
                gqlTypes.field(
                  gqlTypes.name('aggregate'),
                  null,
                  null,
                  null,
                  gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name('count'))]),
                ),
              ]),
            ),
          ]),
          gqlTypes.name(queryType.name),
          apolloArgs,
        ),
      ])
    }

    if (
      aorFetchType === CREATE ||
      aorFetchType === UPDATE ||
      aorFetchType === DELETE ||
      aorFetchType === DELETE_MANY ||
      aorFetchType === UPDATE_MANY
    ) {
      return gqlTypes.document([
        gqlTypes.operationDefinition(
          'mutation',
          gqlTypes.selectionSet([
            gqlTypes.field(
              gqlTypes.name(queryType.name),
              gqlTypes.name('data'),
              args,
              null,
              gqlTypes.selectionSet([
                gqlTypes.field(
                  gqlTypes.name('returning'),
                  null,
                  null,
                  null,
                  gqlTypes.selectionSet(fields),
                ),
              ]),
            ),
          ]),
          gqlTypes.name(queryType.name),
          apolloArgs,
        ),
      ])
    }

    return gqlTypes.document([
      gqlTypes.operationDefinition(
        'query',
        gqlTypes.selectionSet([
          gqlTypes.field(
            gqlTypes.name(queryType.name),
            gqlTypes.name('returning'),
            args,
            null,
            gqlTypes.selectionSet(fields),
          ),
        ]),
        gqlTypes.name(queryType.name),
        apolloArgs,
      ),
    ])
  }

export default (introspectionResults) =>
  buildGqlQueryFun(introspectionResults, buildFields, buildMetaArgs, buildArgs, buildApolloArgs)
