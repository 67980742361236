import React from 'react'
import {
  FormWithRedirect,
  Create,
  useNotify,
  useDataProvider,
  // useRefresh,
  TextInput,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent } from '@material-ui/core'
import CustomToolbar from '../../components/CustomToolbar'

const styles = {
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const useStyles = makeStyles(styles)

const UserForm = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSubmit = async (record) => {
    try {
      await dataProvider.create('app_users', {
        data: record,
      })
      notify('ra.notification.created', 'info')
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form className={classes.form}>
            <CardContent>
              <TextInput
                fullWidth
                source="email"
                variant="standard"
                validate={required()}
                label="Email:"
              />
            </CardContent>
            <CustomToolbar
              redirect="/app_users"
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleSubmit={handleSubmit}
              saving={formProps.saving}
              resource="app_users"
            />
          </form>
        </Card>
      )}
    />
  )
}

const UserCreate = (props) => (
  <Create {...props}>
    <UserForm />
  </Create>
)

export default UserCreate
