import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useDataProvider, useNotify, FormWithRedirect } from 'react-admin'
import { loadStripe } from '@stripe/stripe-js'
import { useLocation } from 'react-router-dom'
import FormData from './formData'
import FormDetails from './formDetails'
import useStyles from './styles'

const CustomerPurchase = () => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')
  const sellerId = new URLSearchParams(search).get('seller')

  const [data, setData] = useState({})
  const [quantity, setQuantity] = useState(0)
  const [courierFees, setCourierFees] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [addressTypes, setAddressTypes] = React.useState([])
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  // const [stateIds, setStateIds] = React.useState([])

  const [cities, setCities] = React.useState([])

  // const zones = [
  //   { id: 1, name: 'Zone 1', value: 1187 },
  //   { id: 2, name: 'Zone 2', value: 1416 },
  //   { id: 3, name: 'Zone 3', value: 1568 },
  //   { id: 4, name: 'Zone 4', value: 1586 },
  //   { id: 5, name: 'Zone 5', value: 1644 },
  //   { id: 6, name: 'Zone 6', value: 1693 },
  //   { id: 7, name: 'Zone 7', value: 1695 },
  //   { id: 8, name: 'Zone 8', value: 1697 },
  //   { id: 9, name: 'Zone 9', value: 4327 },
  //   { id: 10, name: 'Zone 10', value: 3025 },
  //   { id: 11, name: 'Zone 116', value: 1962 },
  //   { id: 12, name: 'Zone 196', value: 1962 },
  // ]

  const stripePromise = loadStripe('pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx')

  useEffect(() => {
    const getPI = async () => {
      if (open) {
        await dataProvider
          .getPaymentIntent('saleable_product', {
            saleableProductId: parseInt(id),
            quantity: parseInt(quantity),
            courierFees: parseInt(courierFees),
            sellerId: parseInt(sellerId),
            // buyerId: appUser.id,
          })
          .then((result) => {
            // console.log('Success:', result)
            setClientSecret(result.data.paymentIntentSecret)
          })
          .catch((error) => {
            console.error('Error:', error)
          })
        // console.log(res)
      }
    }
    getPI()
  }, [open])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await dataProvider.getOne('saleable_product', {
          id,
        })

        // console.log(data)
        setData(data)

        const {
          data: { addressTypes: addressTypesList },
        } = await dataProvider.getSupplierDict('SupplierDict', {})

        setAddressTypes(addressTypesList)

        // console.log(addressTypes, supplierModes, supplierTypes)
        // eslint-disable-next-line no-shadow
        const { data: countries } = await dataProvider.getList('country', {})
        const { data: countrySubdivision = [] } = await dataProvider.getList(
          'country_subdivision',
          {},
        )
        setCountries(countries)
        // eslint-disable-next-line no-shadow
        const cities = {}
        // eslint-disable-next-line no-shadow
        const states = {}
        // eslint-disable-next-line no-shadow
        // const stateIds = {}
        countrySubdivision.forEach((item) => {
          if (item.level === 1) {
            // stateIds[item.id] = item.id
            if (!states[item.country_code]) {
              states[item.country_code] = [item]
            } else {
              states[item.country_code].push(item)
            }
          } else if (item.level === 2) {
            if (!cities[item.parent_id]) {
              cities[item.parent_id] = [item]
            } else {
              cities[item.parent_id].push(item)
            }
          }
        })
        // setStateIds(stateIds)
        setStates(states)
        setCities(cities)
      } catch (error) {
        // console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }
    getData()
  }, [dataProvider, notify])

  // console.log(courierFees)

  if (!data || data === {}) {
    return null
  }

  const totalPrice = ((data.retail_price / 100) * quantity).toFixed(2)
  const totalStock =
    data?.totalStock?.app_user_inventories_aggregate?.aggregate?.sum?.stock > 0
      ? data.totalStock?.app_user_inventories_aggregate?.aggregate?.sum?.stock
      : 0

  return (
    <FormWithRedirect
      // {...props}
      render={() => (
        <Box display="flex" flexDirection="column" p="10px 60px">
          <span className={classes.header}>Customer Purchase Form</span>
          <Box display="flex">
            <Box flex={2}>
              <Box>
                {data?.offer && (
                  <img
                    src={data?.offer?.primary_photo?.url}
                    alt=""
                    className={classes.productPhoto}
                  />
                )}
              </Box>
            </Box>
            <Box flex={2} ml="50px">
              <Box>
                <span>Delivery Address</span>
                <FormData
                  countries={countries}
                  states={states}
                  addressTypes={addressTypes}
                  cities={cities}
                  // zones={zones}
                  setCourierFees={setCourierFees}
                />
              </Box>
            </Box>
            <Box display="flex" flex={2} flexDirection="column" mr="50px" ml="50px">
              <FormDetails
                setQuantity={setQuantity}
                setOpen={setOpen}
                open={open}
                totalPrice={totalPrice}
                totalStock={totalStock}
                stripePromise={stripePromise}
                clientSecret={clientSecret}
                options={options}
                data={data}
                quantity={quantity}
                courierFees={courierFees}
              />
            </Box>
            <Box flex={1} />
          </Box>
        </Box>
      )}
    />
  )
}

export default CustomerPurchase
