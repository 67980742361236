import SourcingRequestIcon from '@material-ui/icons/Collections'
import SourcingRequestList from './SourcingRequestList'
import SourcingRequestEdit from './SourcingRequestEdit'
// import SourcingRequestCreate from './SourcingRequestCreate'
// import SourcingRequestShow from './SourcingRequestShow'

export default {
  list: SourcingRequestList,
  // create: SourcingRequestCreate,
  edit: SourcingRequestEdit,
  icon: SourcingRequestIcon,
  // show: SourcingRequestShow,
}
