import React, { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider, FormWithRedirect } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import useStyles from '../styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import NumberFormat from '../../components/CustomNumberFormat'

const NumberFieldInput = (props) => {
  // const classes = useStyles()

  const { value, ...rest } = props

  return (
    <div>
      <FormWithRedirect
        key="formNumber"
        //   {...props}
        initialValues={{ value }}
        render={() => (
          <EditFieldForm
            //   updateSupplier={updateSupplier}
            {...rest}
          />
        )}
      />
    </div>
  )
}

const EditFieldForm = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const {
    resource,
    id,
    field,
    parentId,
    parentField,
    fieldLength,
    label,
    parseMultiplier,
    // onSave,
    ...rest
  } = props

  // console.log(rest)

  return (
    <Box display="flex">
      <NumberFormat
        label={label || false}
        source="value"
        disabled={!editing}
        // validate={required()}
        displayType="input"
        thousandSeparator
        decimalScale={0}
        fixedDecimalScale
        isNumericString
        defaultValue={values.value || 0}
        // style={{ padding: '0px' }}
        className={classes.textField}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)

              try {
                await dataProvider.update(resource, {
                  data: {
                    id,
                    [field]: parseMultiplier ? Math.round(values.value * 100) : values.value,
                  },
                })
                // console.log({
                //   data: { id, [field]: values.value },
                // })
                // console.log('changing', values.value)
                await form.change('value', values.value)

                // console.log('field', field, fieldLength, values.value)
                if (field === 'sequence' && fieldLength - 1 > values.value) {
                  await dataProvider.update(resource, {
                    data: { id, [field]: values.value },
                    where: {
                      [parentField]: { _eq: parentId },
                      sequence: { _gte: values.value },
                      id: { _neq: id },
                    },
                    _inc: { sequence: 1 },
                  })
                }

                // onSave ? await onSave() : null

                notify('Update Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                setSaving(false)
                setEditing(false)
                notify(error.message, { type: 'warning' })
              }
              setSaving(false)
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default NumberFieldInput
