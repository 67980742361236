import React from 'react'
import { Datagrid, EditButton, List, TextField, NumberField } from 'react-admin'

// import LinkToRelatedProducts from './LinkToRelatedProducts'

const QualityAgencyList = props => (
  <List {...props} sort={{ field: 'id', order: 'ASC' }}>
    <Datagrid>
      <TextField label="First Name" source="contact.firstName" />
      <TextField label="Last Name" source="contact.lastName" />
      <TextField label="Company Name" source="contact.companyName" />
      <TextField label="Position" source="contact.position" />
      <NumberField label="Basic Rate" source="basicRate" />
      <TextField label="Email" source="contact.email" />
      <TextField label="Phone ext" source="contact.phone_ext" />
      <TextField label="Phone" source="contact.phone" />
      <TextField label="Mobile" source="contact.mobile" />
      {/* <TextField source="contact.wechat" /> */}
      {/* <TextField source="contact.skype" /> */}
      {/* <TextField source="contact.whatsapp" /> */}
      {/* <TextField source="contact.fax" /> */}
      <EditButton />
    </Datagrid>
  </List>
)

export default QualityAgencyList
