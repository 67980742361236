import React, { useState } from 'react'
import { Login, useLogout } from 'react-admin'
import LoginForm from './LoginForm'

const LoginPage = (props) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const logout = useLogout()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const error = params.get('error')

  if (error === 'access_denied') {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('admin')
    window.history.replaceState({}, document.title, window.location.pathname)

    logout()
  }

  // logout()
  return loggedIn ? (
    // <div
    //   style={{
    //     height: '100vh',
    //     width: '100vw',
    //     backgroundColor: '#fafafa',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   }}
    // >
    <div className="loader-container">
      <div className="loader">Loading...</div>
    </div>
  ) : (
    // </div>
    <Login {...props}>
      <LoginForm loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
    </Login>
  )
}

export default LoginPage
