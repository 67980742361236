import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Popper } from '@material-ui/core'
import * as ReactColor from 'react-color'
import lodashGet from 'lodash/get'
import pure from 'recompose/pure'
import InputAdornment from '@material-ui/core/InputAdornment'
// import useStyles from '../styles'
import {
  useInput,
  //  required
} from 'react-admin'

const ColorFieldComponent = ({ source, record = {}, className, hideLabel = false }) => {
  // console.log(record, source)
  const hex = record?.name?.split('#')[1] || lodashGet(record, source)
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null

  return (
    <div style={{ display: 'flex', alignItems: 'center', minWidth: hideLabel ? '22px' : '182px' }}>
      <div
        style={{
          width: '20px',
          height: '20px',
          background: record?.name || lodashGet(record, source),
          border: rgb?.r + rgb?.g + rgb?.b > 600 ? 'solid 1px black' : null,
          marginRight: '5px',
          borderRadius: '5px',
        }}
      />
      {!hideLabel && (
        <span style={{ fontSize: '12px' }} className={className}>
          {record?.name || lodashGet(record, source)}
        </span>
      )}
    </div>
  )
}

ColorFieldComponent.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

const PureTextField = pure(ColorFieldComponent)

export const ColorInput = (props) => {
  // const [show, setShow] = useState()
  const [useBorder, setUseBorder] = useState(false)

  const {
    label,
    source,
    meta,
    className,
    options = { disableAlpha: true },
    picker = 'Sketch',
    input,
    resource,
    helperText,
    // isRequired,
  } = useInput(props)

  // const handleOpen = () => setShow(true)
  // const handleClose = () => setShow(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const handleChange = ({ hex, rgb }) => {
    // console.log(rgb)
    if (rgb.r + rgb.g + rgb.b > 600) {
      setUseBorder(true)
    } else {
      setUseBorder(false)
    }
    input.onChange(hex)
  }

  const { touched, error } = meta

  const Picker = ReactColor[`${picker}Picker`]
  // const classes = useStyles()

  const { disabled = false } = props

  return (
    <div style={{ height: '100%' }}>
      <TextField
        {...input}
        margin="normal"
        onFocus={handleClick}
        // validate={required()}
        label={label}
        placeholder="Select a color"
        source={source}
        disabled={disabled}
        fullWidth
        // isRequired={isRequired}
        resource={resource}
        error={!!(touched && error)}
        helperText={(touched && error) || helperText}
        style={{ padding: '0px', margin: '0px', height: '100%', width: '100%' }}
        className={className}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  background: input.value,
                  marginRight: '5px',
                  marginBottom: '2px',
                  borderRadius: '5px',
                  border: useBorder ? 'solid 1px black' : null,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      {anchorEl && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1500,
          }}
          onClick={handleClick}
        ></div>
      )}
      {picker && options ? (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          style={{
            zIndex: 2000,
            backgroundColor: '#FFF',
            borderRadius: '5px',
            border: 'solid 1px gray',
          }}
        >
          <div
          //  className={classes.colourInputPopup}
          >
            <div
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                marginBottom: '5px',
              }}
              //  className={classes.colourInputCover}
              onClick={handleClick}
            />
            <Picker {...options} color={input.value} onChange={handleChange} />
          </div>
        </Popper>
      ) : null}
    </div>
  )
}

export const ColorField = PureTextField
// export const ColorInput = addField(ColorInputComponent)
