/* eslint-disable no-unreachable */
import uploadFile from '../../../dataProvider/upload'

export const saveFactoryQuote = async ({
  setSaving,
  values,
  dataProvider,
  notify,
  setEditing,
  form,
}) => {
  setSaving ? setSaving(true) : null
  try {
    const prices = []
    await Promise.all(
      values.factoryPrices?.map(async (item) => {
        // console.log('PRICE:', item)
        prices.push({
          ...(typeof item.id === 'number'
            ? { id: item.id }
            : { product_quote_id: values.product_quotes[0].id }),
          quantity: item.quantity,
          unit_price: Math.round(item.unitPrice * 100),
          lead_time: item.lead_time || item.production_lead_time,
          ...(item.landed_air && {
            est_landed_cost_air: Math.round(item.landed_air * 100),
          }),
          ...(item.landed_sea && {
            est_landed_cost_sea: Math.round(item.landed_sea * 100),
          }),
          ...(item.totalCBM && { total_cbm: Math.round(item.totalCBM * 100) }),
          ...(item.shipmentWeight && {
            shipment_weight: Math.round(item.shipmentWeight * 100),
          }),
          ...(item.totalNumCartons && {
            total_cartons: Math.round(item.totalNumCartons),
          }),
          ...(item.totalDuties && {
            total_duties: Math.round(item.totalDuties * 100),
          }),
          ...(item.totalFreightCostAir && {
            freight_cost_air: Math.round(item.totalFreightCostAir * 100),
          }),
          ...(item.totalFreightCostSea && {
            freight_cost_sea: Math.round(item.totalFreightCostSea * 100),
          }),
          ...(item.freightCostPerPieceAir && {
            freight_cost_air_per_piece: Math.round(item.freightCostPerPieceAir * 100),
          }),
          ...(item.freightCostPerPieceSea && {
            freight_cost_sea_per_piece: Math.round(item.freightCostPerPieceSea * 100),
          }),
          ...(item.containers && { containers: item.containers }),
        })
      }),
    )
    // console.log('PRICES', prices)

    await Promise.all(
      prices.map(async (el) => {
        if (el.id) {
          await dataProvider.update('product_quote_price', { data: el })
        } else {
          await dataProvider.create('product_quote_price', {
            data: el,
          })
        }
      }),
    )

    const quoteParams = {
      ...(!!values.product_quotes.length > 0 &&
        values.product_quotes[0].id && { id: values.product_quotes[0].id }),
      ...(values.pieces_per_carton && {
        pieces_per_carton: values.pieces_per_carton,
      }),
      ...(values.dutyRate && {
        duty_rate: Math.round(values.dutyRate * 100),
      }),
      ...(values.containerRate20ft && {
        container_rate_20ft: Math.round(values.containerRate20ft * 100),
      }),
      ...(values.containerRate40ft && {
        container_rate_40ft: Math.round(values.containerRate40ft * 100),
      }),
      ...(values.containerRate40ftHC && {
        container_rate_40ft_hc: Math.round(values.containerRate40ftHC * 100),
      }),
      ...(values.freightRateSea && {
        freight_rate_sea: Math.round(values.freightRateSea * 100),
      }),
      ...(values.freightRateAir && {
        freight_rate_air: Math.round(values.freightRateAir * 100),
      }),
      ...(values.does_sea_freight_include_duties && {
        does_sea_freight_include_duties: values.does_sea_freight_include_duties,
      }),
      container_capacity_20ft: 25,
      container_capacity_40ft: 60,
      container_capacity_40ft_hc: 65,
    }

    // console.log('QUOTEPARAMS', quoteParams)

    dataProvider.update('product_quote', { data: quoteParams })

    const cartonParams = {
      ...(values.cartonDimensions?.id && { id: values.cartonDimensions.id }),
      length: Math.round(values.cartonDimensions.length * 100),
      width: Math.round(values.cartonDimensions.width * 100),
      height: Math.round(values.cartonDimensions.height * 100),
      gross_weight: Math.round(values.cartonDimensions.gross_weight * 100),
    }
    if (cartonParams.id) {
      await dataProvider.update('dimension', { data: cartonParams })
    } else {
      const resp = await dataProvider.create('dimension', { data: cartonParams })
      form.change('cartonDimensions.id', resp.data.id)

      await dataProvider.update('product', {
        data: { id: values.id, carton_dimensions_id: resp.data.id },
      })
    }

    await dataProvider.update('dimension', { data: cartonParams })

    //   console.log(resp)
    notify ? notify('Update Complete', { type: 'info' }) : null
  } catch (error) {
    console.log(error)
    notify ? notify(error.message, { type: 'warning' }) : null
  }
  setEditing ? setEditing(false) : null
  setSaving ? setSaving(false) : null
}

export const handleSave = async ({ submission, dataProvider, notify, identity }) => {
  try {
    // Set to false to disable console logs
    const debugMode = false
    debugMode && console.log('SAVING DATA')
    debugMode && console.time('SAVING DATA TIMER')
    debugMode && console.log('SUB:', submission)

    const params = {}

    // PRODUCT
    debugMode && console.count('STEP')
    debugMode && console.log('PRODUCT', params)

    debugMode && console.log('IDENTITY:', identity)

    params.sca_user_id = submission.sca_user_id || identity.userId

    params.is_catalogue = true
    params.status = '^PRIVATE^'
    submission.name ? (params.name = submission.name) : null
    submission.short_description ? (params.short_description = submission.short_description) : null
    submission.notes ? (params.notes = submission.notes) : null
    submission.allow_colour_selection
      ? (params.allow_colour_selection = submission.allow_colour_selection)
      : null
    submission.root_sku ? (params.root_sku = submission.root_sku) : null
    submission.catalogue_sku ? (params.catalogue_sku = submission.catalogue_sku) : null
    submission.packaging_type ? (params.packaging_type = submission.packaging_type) : null
    submission.pieces_per_carton === 0
      ? (params.pieces_per_carton = 0)
      : submission.pieces_per_carton
      ? (params.pieces_per_carton = submission.pieces_per_carton)
      : null
    submission.hts_code ? (params.hts_code = submission.hts_code) : null
    submission.dutyRate === 0
      ? (params.duty_rate = 0)
      : submission.dutyRate
      ? Math.round((params.duty_rate = submission.dutyRate * 100))
      : null
    submission.brand ? (params.brand = submission.brand) : null
    submission.root_product_id ? (params.root_product_id = submission.root_product_id) : null
    submission.chargeable_weight_air === 0
      ? (params.chargeable_weight_air = 0)
      : submission.chargeable_weight_air
      ? (params.chargeable_weight_air = Math.round(submission.chargeable_weight_air))
      : null
    submission.chargeable_weight_sea === 0
      ? (params.chargeable_weight_sea = 0)
      : submission.chargeable_weight_sea
      ? (params.chargeable_weight_sea = Math.round(submission.chargeable_weight_sea))
      : null

    // PRODUCT MEDIA / FILES
    debugMode && console.count('STEP')

    debugMode && console.log('PRIMARYPHOTO', submission.primary_photo)

    // PRIMARY PHOTO
    if (submission.primary_photo) {
      const file = await uploadFile([submission.primary_photo])
      // console.log(file)
      params.primary_photo = {
        data: {
          url: file?.data?.files[0]?.url || '',
          filename: submission.primary_photo.name,
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('THUMBNAIL', submission.primary_photo)

    // THUMBNAIL PHOTO
    if (submission.primary_photo) {
      const file = await uploadFile([submission.primary_photo])
      params.thumbnail_photo = {
        data: {
          url: file?.data?.files[0]?.url || '',
          filename: submission.primary_photo.name,
        },
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('ADDITIONAL', [...submission.additional_files, ...submission.images])

    const addFiles = [...submission.additional_files, ...submission.images]

    // ADDITIONAL FILES
    const files = []
    if (addFiles && addFiles?.length > 0) {
      await Promise.all(
        addFiles.map(async (photo) => {
          // console.log('FILE', photo)

          const file = await uploadFile([photo.file])
          debugMode && console.log(photo)
          files.push({
            type: `^${photo.type}^`,
            file: {
              data: {
                url: file?.data?.files[0]?.url || '',
                filename: photo.file.name || photo.file.path,
              },
            },
          })
        }),
      )
    }

    if (files.length > 0) {
      params.product_files = {
        data: files,
      }
    }
    // console.log('FILES', files)
    // return null

    debugMode && console.count('STEP')
    debugMode && console.log('PRODTAGS', submission?.tags)

    // TAGS
    const tags = []

    submission.tags?.map((tag) => {
      tags.push({
        tag_id: tag,
      })
    })

    if (tags.length > 0) {
      params.product_tags = {
        data: tags,
      }
      debugMode && console.log('PARTAGS', params.product_tags)
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODCATS', submission?.categories)

    // CATEGORIES
    if (submission.categories?.length > 0) {
      const cats = []

      submission.categories?.map((cat) => {
        cats.push({
          category_id: cat,
          deleted_at: null,
        })
      })

      if (cats.length > 0) {
        params.product_categories = {
          data: cats,
        }
        debugMode && console.log('PARCATS', params.product_categories)
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODDECS', submission.product_declarations)
    // PRODUCT DECLARATIONS
    if (
      submission.product_declarations?.length > 0 &&
      submission.product_declarations[0].declaration &&
      submission.product_declarations[0].file
    ) {
      const decs = []
      await Promise.all(
        submission.product_declarations.map(async (dec) => {
          const file = dec.file ? await uploadFile([dec.file.rawFile]) : null
          decs.push({
            declaration: dec.declaration,
            ...(!!dec.file.rawFile && {
              file: {
                data: {
                  filename: dec.file.filename,
                  url: file.data.files[0].url,
                },
              },
            }),
          })
        }),
      )
      params.product_declarations = {
        data: decs,
      }
    }

    // CUSTOMISATIONS
    debugMode && console.count('STEP')
    const customisations = []
    debugMode && console.log('CUSTOMOPTS', 'SUB', submission.productCustomisations)

    submission.productCustomisations?.map((spec) => {
      // console.log(spec)
      spec.option_value.value.map((item) => {
        // console.log(item)
        customisations.push({
          option_value: {
            data: {
              value: item,
              option: {
                data: {
                  name: spec.name,
                },
                on_conflict: {
                  constraint: '^option_name_key^',
                  update_columns: '^name^',
                },
              },
            },
            on_conflict: {
              constraint: '^option_value_option_id_value_key^',
              update_columns: ['^value^', '^option_id^'],
            },
          },
        })
      })
    })

    if (customisations.length > 0) {
      params.customisations = {
        data: customisations,
      }
    }

    params.product_dimensions = { data: {} }
    params.packaging_dimensions = { data: {} }
    params.carton_dimensions = { data: {} }
    debugMode && console.count('STEP')
    debugMode && console.log('PRODDIMS', submission.productDimensions)
    // QUOTE.PRODUCT DIMENSIONS
    submission.productDimensions?.length === 0
      ? (params.product_dimensions.data.length = 0)
      : submission.productDimensions?.length
      ? (params.product_dimensions.data.length = Math.round(
          submission.productDimensions?.length * 100,
        ))
      : null
    submission.productDimensions?.width === 0
      ? (params.product_dimensions.data.width = 0)
      : submission.productDimensions?.width
      ? (params.product_dimensions.data.width = Math.round(
          submission.productDimensions?.width * 100,
        ))
      : null
    submission.productDimensions?.height === 0
      ? (params.prduct_dimensions.data.height = 0)
      : submission.productDimensions?.height
      ? (params.product_dimensions.data.height = Math.round(
          submission.productDimensions?.height * 100,
        ))
      : null
    submission.productDimensions?.gross_weight === 0
      ? (params.product_dimensions.data.gross_weight = 0)
      : submission.productDimensions?.gross_weight
      ? (params.product_dimensions.data.gross_weight = Math.round(
          submission.productDimensions?.gross_weight * 100,
        ))
      : null
    // QUOTE.PACKAGING DIMENSIONS
    debugMode && console.log('PKGDIMS', submission.packagingDimensions)
    submission.packagingDimensions?.length === 0
      ? (params.packaging_dimensions.data.length = 0)
      : submission.packagingDimensions?.length
      ? (params.packaging_dimensions.data.length = Math.round(
          submission.packagingDimensions?.length * 100,
        ))
      : null
    submission.packagingDimensions?.width === 0
      ? (params.packaging_dimensions.data.width = 0)
      : submission.packagingDimensions?.width
      ? (params.packaging_dimensions.data.width = Math.round(
          submission.packagingDimensions?.width * 100,
        ))
      : null
    submission.packagingDimensions?.height === 0
      ? (params.packaging_dimensions.data.height = 0)
      : submission.packagingDimensions?.height
      ? (params.packaging_dimensions.data.height = Math.round(
          submission.packagingDimensions?.height * 100,
        ))
      : null
    submission.packagingDimensions?.gross_weight === 0
      ? (params.packaging_dimensions.data.gross_weight = 0)
      : submission.packagingDimensions?.gross_weight
      ? (params.packaging_dimensions.data.gross_weight = Math.round(
          submission.packagingDimensions?.gross_weight * 100,
        ))
      : null
    // QUOTE.CARTON DIMENSIONS
    debugMode && console.log('CARTDIMS', submission.cartonDimensions)
    submission.cartonDimensions?.length === 0
      ? (params.carton_dimensions.data.length = 0)
      : submission.cartonDimensions?.length
      ? (params.carton_dimensions.data.length = Math.round(
          submission.cartonDimensions?.length * 100,
        ))
      : null
    submission.cartonDimensions?.width === 0
      ? (params.carton_dimensions.data.width = 0)
      : submission.cartonDimensions?.width
      ? (params.carton_dimensions.data.width = Math.round(submission.cartonDimensions?.width * 100))
      : null
    submission.cartonDimensions?.height === 0
      ? (params.carton_dimensions.data.height = 0)
      : submission.cartonDimensions?.height
      ? (params.carton_dimensions.data.height = Math.round(
          submission.cartonDimensions?.height * 100,
        ))
      : null
    submission.cartonDimensions?.gross_weight === 0
      ? (params.carton_dimensions.data.gross_weight = 0)
      : submission.cartonDimensions?.gross_weight
      ? (params.carton_dimensions.data.gross_weight = Math.round(
          submission.cartonDimensions?.gross_weight * 100,
        ))
      : null

    if (
      params.carton_dimensions?.data &&
      Object.keys(params.carton_dimensions?.data).length === 0
    ) {
      delete params.carton_dimensions
    }
    if (
      params.packaging_dimensions?.data &&
      Object.keys(params.packaging_dimensions?.data).length === 0
    ) {
      delete params.packaging_dimensions
    }
    if (
      params.product_dimensions?.data &&
      Object.keys(params.product_dimensions?.data).length === 0
    ) {
      delete params.product_dimensions
    }

    // PRODOPTIONS

    const availableOptions = []

    debugMode && console.log('COLOUROPTIONS', 'SUB', submission.colourOptions)

    if (submission.colourOptions?.length > 0) {
      await Promise.all(
        submission.colourOptions.map((item) => {
          availableOptions.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Colour',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('MATERIALOPTIONS', 'SUB', submission.materialOptions)

    if (submission.materialOptions?.length > 0) {
      await Promise.all(
        submission.materialOptions.map((item) => {
          availableOptions.push({
            deleted_at: null,
            option_value: {
              data: {
                value:
                  item.name || item.option_value?.name || item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Material',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('SIZEOPTIONS', 'SUB', submission.sizeOptions)

    if (submission.sizeOptions?.length > 0) {
      await Promise.all(
        submission.sizeOptions.map((item) => {
          availableOptions.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item,
                option: {
                  data: {
                    name: 'Size',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODOPTS', availableOptions)

    if (availableOptions.length > 0) {
      params.product_options = {
        data: availableOptions,
      }
    }

    const availableSpecs = []

    debugMode && console.log('COLOURSPECS', 'SUB', submission.productColours)

    if (submission.productColours?.length > 0) {
      await Promise.all(
        submission.productColours.map((item) => {
          availableSpecs.push({
            deleted_at: null,
            option_value: {
              data: {
                value: item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Colour',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('MATERIALSPECS', 'SUB', submission.productMaterials)

    if (submission.productMaterials?.length > 0) {
      await Promise.all(
        submission.productMaterials.map((item) => {
          availableSpecs.push({
            deleted_at: null,
            option_value: {
              data: {
                value:
                  item.name || item.option_value?.name || item.value || item.option_value?.value,
                option: {
                  data: {
                    name: 'Material',
                  },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: ['^value^', '^option_id^'],
              },
            },
          })
        }),
      )
    }

    debugMode && console.log('OTHERSPECS', 'SUB', submission.otherSpecifications)

    submission?.otherSpecifications?.map((spec) => {
      // console.log(spec)
      spec.option_value.value.map((value) => {
        availableSpecs.push({
          deleted_at: null,
          option_value: {
            data: {
              value,
              option: {
                data: {
                  name: spec.name,
                },
                on_conflict: {
                  constraint: '^option_name_key^',
                  update_columns: '^name^',
                },
              },
            },
            on_conflict: {
              constraint: '^option_value_option_id_value_key^',
              update_columns: ['^value^', '^option_id^'],
            },
          },
        })
      })
    })

    debugMode && console.count('STEP')
    debugMode && console.log('PRODSPECS', availableSpecs)

    if (availableSpecs.length > 0) {
      params.product_specifications = {
        data: availableSpecs,
      }
    }

    params.product_quotes = {
      data: [
        {
          components: {
            data: [
              {
                name: 'Base',
              },
            ],
          },
          ...(submission.pieces_per_carton && {
            pieces_per_carton: submission.pieces_per_carton,
          }),
          ...(submission.containerRate20ft && {
            container_rate_20ft: Math.round(submission.containerRate20ft * 100),
          }),
          ...(submission.containerRate40ft && {
            container_rate_40ft: Math.round(submission.containerRate40ft * 100),
          }),
          ...(submission.containerRate40ftHC && {
            container_rate_40ft_hc: Math.round(submission.containerRate40ftHC * 100),
          }),
          ...(submission.freightRateSea && {
            freight_rate_sea: Math.round(submission.freightRateSea * 100),
          }),
          ...(submission.freightRateAir && {
            freight_rate_air: Math.round(submission.freightRateAir * 100),
          }),
          ...(submission.does_sea_freight_include_duties && {
            does_sea_freight_include_duties: submission.does_sea_freight_include_duties,
          }),
          container_capacity_20ft: 25,
          container_capacity_40ft: 60,
          container_capacity_40ft_hc: 65,
          scm_quotes: {
            data: {
              ...(submission.containerRate20ft && {
                container_rate_20ft: Math.round(submission.containerRate20ft * 100),
              }),
              ...(submission.containerRate40ft && {
                container_rate_40ft: Math.round(submission.containerRate40ft * 100),
              }),
              ...(submission.containerRate40ftHC && {
                container_rate_40ft_hc: Math.round(submission.containerRate40ftHC * 100),
              }),
              ...(submission.freightRateSea && {
                freight_rate_sea: Math.round(submission.freightRateSea * 100),
              }),
              ...(submission.freightRateAir && {
                freight_rate_air: Math.round(submission.freightRateAir * 100),
              }),
              ...(submission.dutyRate && {
                duty_rate: Math.round(submission.dutyRate * 100),
              }),
              ...(submission.does_sea_freight_include_duties && {
                does_sea_freight_include_duties: submission.does_sea_freight_include_duties,
              }),
              container_capacity_20ft: 25,
              container_capacity_40ft: 60,
              container_capacity_40ft_hc: 65,
              product_components: {
                data: [
                  {
                    name: 'Base',
                  },
                ],
              },
            },
          },
        },
      ],
    }

    submission.product_quotes[0]?.allow_order_splitting_by_size
      ? (params.product_quotes.data.allow_order_splitting_by_size =
          submission.product_quotes[0]?.allow_order_splitting_by_size)
      : null
    submission.product_quotes[0]?.supplier_id
      ? (params.product_quotes.data[0].supplier_id = submission.product_quotes[0]?.supplier_id)
      : null
    submission.product_quotes[0]?.toolingCost === 0
      ? (params.tooling_cost = 0)
      : submission.product_quotes[0]?.tooling_lead_time
      ? (params.product_quotes.data[0].tooling_cost = Math.round(submission.toolingCost * 100))
      : null
    submission.product_quotes[0]?.tooling_lead_time === 0
      ? params.product_quotes.data[0].tooling_lead_time
      : submission.product_quotes[0]?.tooling_lead_time
      ? (params.product_quotes.data[0].tooling_lead_time =
          submission.product_quotes[0]?.tooling_lead_time)
      : null
    submission.sampleCost === 0
      ? (params.product_quotes.data[0].sample_cost = 0)
      : submission.sampleCost
      ? (params.product_quotes.data[0].sample_cost = Math.round(submission.sampleCost * 100))
      : null
    submission.customSampleCost === 0
      ? (params.product_quotes.data[0].custom_sample_cost = 0)
      : submission.customSampleCost
      ? (params.product_quotes.data[0].custom_sample_cost = Math.round(
          submission.customSampleCost * 100,
        ))
      : null
    submission.product_quotes[0]?.sample_lead_time === 0
      ? (params.product_quotes.data[0].sample_lead_time = 0)
      : submission.product_quotes[0]?.sample_lead_time
      ? (params.product_quotes.data[0].sample_lead_time =
          submission.product_quotes[0]?.sample_lead_time)
      : null
    submission.product_quotes[0]?.valid_date
      ? (params.product_quotes.data[0].valid_date = submission.product_quotes[0]?.valid_date)
      : null
    submission.product_quotes[0]?.packaging_type
      ? (params.product_quotes.data[0].packaging_type =
          submission.product_quotes[0]?.packaging_type)
      : null
    submission.product_quotes[0]?.pieces_per_carton === 0
      ? (params.product_quotes.data[0].pieces_per_carton = 0)
      : submission.product_quotes[0]?.pieces_per_carton
      ? (params.product_quotes.data[0].pieces_per_carton =
          submission.product_quotes[0]?.pieces_per_carton)
      : null
    submission.product_quotes[0]?.cartons_per_pallet === 0
      ? (params.product_quotes.data[0].cartons_per_pallet = 0)
      : submission.product_quotes[0]?.cartons_per_pallet
      ? (params.product_quotes.data[0].cartons_per_pallet =
          submission.product_quotes[0]?.cartons_per_pallet)
      : null
    submission.product_quotes[0]?.cartons_per_container_20ft === 0
      ? (params.product_quotes.data[0].cartons_per_container_20ft = 0)
      : submission.product_quotes[0]?.cartons_per_container_20ft
      ? (params.product_quotes.data[0].cartons_per_container_20ft =
          submission.product_quotes[0]?.cartons_per_container_20ft)
      : null
    submission.product_quotes[0]?.cartons_per_container_40ft === 0
      ? (params.product_quotes.data[0].cartons_per_container_40ft = 0)
      : submission.product_quotes[0]?.cartons_per_container_40ft
      ? (params.product_quotes.data[0].cartons_per_container_40ft =
          submission.product_quotes[0]?.cartons_per_container_40ft)
      : null
    submission.product_quotes[0]?.cartons_per_container_40ft_hc === 0
      ? (params.product_quotes.data[0].cartons_per_container_40ft_hc = 0)
      : submission.product_quotes[0]?.cartons_per_container_40ft_hc
      ? (params.product_quotes.data[0].cartons_per_container_40ft_hc =
          submission.product_quotes[0]?.cartons_per_container_40ft_hc)
      : null

    debugMode && console.count('STEP')
    debugMode && console.log('QUOTEPRICES', submission.factoryPrices)
    // PRODUCT QUOTE PRICES
    if (submission.factoryPrices?.length > 0) {
      const prices = []
      await Promise.all(
        submission.factoryPrices?.map(async (item) => {
          prices.push({
            quantity: item.quantity,
            unit_price: Math.round(item.unitPrice * 100),
            lead_time: item.production_lead_time,
            ...(item.landed_air && {
              est_landed_cost_air: Math.round(item.landed_air * 100),
            }),
            ...(item.landed_sea && {
              est_landed_cost_sea: Math.round(item.landed_sea * 100),
            }),
            ...(item.totalCBM && { total_cbm: Math.round(item.totalCBM * 100) }),
            ...(item.shipmentWeight && {
              shipment_weight: Math.round(item.shipmentWeight * 100),
            }),
            ...(item.totalNumCartons && {
              total_cartons: Math.round(item.totalNumCartons),
            }),
            ...(item.totalDuties && {
              total_duties: Math.round(item.totalDuties * 100),
            }),
            ...(item.totalFreightCostAir && {
              freight_cost_air: Math.round(item.totalFreightCostAir * 100),
            }),
            ...(item.totalFreightCostSea && {
              freight_cost_sea: Math.round(item.totalFreightCostSea * 100),
            }),
            ...(item.freightCostPerPieceAir && {
              freight_cost_air_per_piece: Math.round(item.freightCostPerPieceAir * 100),
            }),
            ...(item.freightCostPerPieceSea && {
              freight_cost_sea_per_piece: Math.round(item.freightCostPerPieceSea * 100),
            }),
            ...(item.containers && { containers: item.containers }),
          })
        }),
      )
      params.product_quotes.data[0].prices = {
        data: prices,
      }
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PARAMS:', params)

    const product = {
      objects: params,
    }

    debugMode && console.count('STEP')
    debugMode && console.log('PRODUCT: ', product)
    const resp = await dataProvider.createProduct('product', product)

    debugMode && console.count('STEP')
    debugMode && console.log('RESPONSE: ', resp)

    notify('Updated Successfully.', { type: 'info' })
    debugMode && console.timeEnd('SAVING DATA TIMER')
  } catch (error) {
    console.log(error)
    console.timeEnd('SAVING DATA TIMER')
    notify(error.message.split('.')[0], { type: 'warning' })
    return false
  }
}

export const handleSaveClientQuote = async ({
  submission,
  dataProvider,
  notify,
  // client_id,
  product_quote_id,
  // sourcing_request_id,
  type,
  // record,
}) => {
  const debugMode = false

  debugMode && console.log('SAVECLIENTQUOTE', submission)
  try {
    const params = {}
    params.status = type === 'publish' ? '^ACTIVE^' : '^PENDING^'
    params.launch_date = submission.launch_date || null
    params.notes = submission.quote_notes || null
    params.client_notes = submission.client_notes || null
    params.client_sample_price = Math.round(submission.clientSamplePrice * 100)
    params.client_custom_sample_price = Math.round(submission.clientCustomSamplePrice * 100)
    params.client_sample_lead_time = submission.client_sample_lead_time
    params.warranty_term = submission.warranty_term || null
    params.warranty_conditions = submission.warranty_conditions || null
    submission.payment_terms?.length > 0
      ? (params.payment_terms = {
          data: submission.payment_terms?.map((item) => {
            return {
              milestone: item.milestone,
              value: item.value,
            }
          }),
        })
      : null
    params.product_quote_id = product_quote_id
    // params.client_id = client_id
    // params.sourcing_request_id = sourcing_request_id

    params.prices = {
      data: submission.prices?.map((item) => {
        return {
          production_lead_time: item.client_production_lead_time,
          lead_time_sea: 50,
          lead_time_air: 10,
          total_lead_time_sea: item.client_production_lead_time + 50,
          total_lead_time_air: item.client_production_lead_time + 10,
          quantity: item.quantity,
          price: Math.round(item.price * 100),
          estimated_freight_sea: Math.round(item.estimated_freight_sea * 100),
          estimated_freight_air: Math.round(item.estimated_freight_air * 100),
          fob_price: Math.round(item.fob_price * 100),
        }
      }),
    }

    debugMode && console.log('PARAMS', params)

    const resp = await dataProvider.create('scmQuote', {
      objects: params,
    })

    debugMode && console.log('RESP', resp)

    notify('Saved Successfully.', { type: 'info' })
    return resp
  } catch (error) {
    console.log(error)
    notify(error.message.split('.')[0], { type: 'warning' })
    throw new Error(error)
  }
}

export const getDataLists = async (setDataLists, dataProvider, notify, abortController) => {
  try {
    const listsTemp = {}
    const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
      filter: { own: 'packagingStyle' },
    })
    listsTemp.packagingTypes = packagingTypesTemp

    const { data: certificationTypesList } = await dataProvider.getList('certificationType', {})
    listsTemp.certificationTypes = certificationTypesList

    const { data: categoriesTemp } = await dataProvider.getList('categories', {})
    listsTemp.categories = categoriesTemp

    const { data: materialsTemp } = await dataProvider.getList('materials', {
      filter: { own: '%material%' },
    })
    listsTemp.materials = materialsTemp

    const { data: imprintsTemp } = await dataProvider.getList('imprints', {
      filter: { own: 'imprintTechnique' },
    })
    listsTemp.imprints = imprintsTemp

    const { data: suppliersTemp } = await dataProvider.getList('supplier', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    })
    listsTemp.suppliers = suppliersTemp

    const { data: qualityAgenciesTemp } = await dataProvider.getList('qualityAgency', {})
    listsTemp.qualityAgencies = qualityAgenciesTemp

    const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
    listsTemp.factoryMilestones = milestonesTemp.filter((item) => item.type === 'factory')
    listsTemp.qualityMilestones = milestonesTemp.filter((item) => item.type === 'quality')
    listsTemp.logisticsMilestones = milestonesTemp.filter((item) => item.type === 'logistics')
    listsTemp.clientMilestones = milestonesTemp.filter((item) => item.type === 'client')

    const { data: inspLevelsTemp } = await dataProvider.getList('inspectionLevel', {})
    listsTemp.inspectionLevels = inspLevelsTemp

    const { data: inspectionTypesTemp } = await dataProvider.getList('inspectionType', {})
    listsTemp.inspectionTypes = inspectionTypesTemp

    const { data: logisticsTemp } = await dataProvider.getList('freightForwarder', {})
    listsTemp.logisticsProviders = logisticsTemp

    const { data: clientsTemp } = await dataProvider.getList('app_user', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
      clientsOnly: true,
    })
    listsTemp.clients = clientsTemp
    if (!abortController.signal.aborted) {
      setDataLists(listsTemp)
    }
  } catch (error) {
    // console.log(error)
    notify(error.message.split('.')[0], { type: 'warning' })
    return
  }
}

export const toPath = (key) => {
  if (key === null || key === undefined || !key.length) {
    return []
  }
  if (typeof key !== 'string') {
    throw new Error('toPath() expects a string')
  }
  return key.split(/[.[\]]+/).filter(Boolean)
}

export const toStrPath = (arr, lastStr) => {
  if (!Array.isArray(arr)) {
    throw new Error('toPath() expects a array')
  }
  if (lastStr) {
    arr[arr.length - 1] = lastStr
  }
  return arr.join('.')
}

export const getIn = (state, complexKey) => {
  // Intentionally using iteration rather than recursion
  const path = toPath(complexKey)
  let current = state
  for (let i = 0; i < path.length; i++) {
    const key = path[i]
    if (
      current === undefined ||
      current === null ||
      typeof current !== 'object' ||
      (Array.isArray(current) && isNaN(key))
    ) {
      return undefined
    }
    current = current[key]
  }
  return current
}

const setInRecursor = (current, index, path, value, destroyArrays) => {
  if (index >= path.length) {
    // end of recursion
    return value
  }
  const key = path[index]

  // determine type of key
  if (isNaN(key)) {
    // object set
    if (current === undefined || current === null) {
      // recurse
      const result = setInRecursor(undefined, index + 1, path, value, destroyArrays)

      // delete or create an object
      return result === undefined ? undefined : { [key]: result }
    }
    if (Array.isArray(current)) {
      throw new Error('Cannot set a non-numeric property on an array')
    }
    // current exists, so make a copy of all its values, and add/update the new one
    const result = setInRecursor(current[key], index + 1, path, value, destroyArrays)
    if (result === undefined) {
      const numKeys = Object.keys(current).length
      if (current[key] === undefined && numKeys === 0) {
        // object was already empty
        return undefined
      }
      if (current[key] !== undefined && numKeys <= 1) {
        // only key we had was the one we are deleting
        if (!isNaN(path[index - 1]) && !destroyArrays) {
          // we are in an array, so return an empty object
          return {}
        }
        return undefined
      }
      // eslint-disable-next-line no-unused-vars
      const { [key]: _removed, ...final } = current
      return final
    }
    // set result in key
    return {
      ...current,
      [key]: result,
    }
  }
  // array set
  const numericKey = Number(key)
  if (current === undefined || current === null) {
    // recurse
    const result = setInRecursor(undefined, index + 1, path, value, destroyArrays)

    // if nothing returned, delete it
    if (result === undefined) {
      return undefined
    }

    // create an array
    const array = []
    array[numericKey] = result
    return array
  }
  if (!Array.isArray(current)) {
    throw new Error('Cannot set a numeric property on an object')
  }
  // recurse
  const existingValue = current[numericKey]
  const result = setInRecursor(existingValue, index + 1, path, value, destroyArrays)

  // current exists, so make a copy of all its values, and add/update the new one
  const array = [...current]
  if (destroyArrays && result === undefined) {
    array.splice(numericKey, 1)
    if (array.length === 0) {
      return undefined
    }
  } else {
    array[numericKey] = result
  }
  return array
}

export const setIn = (state, key, value, destroyArrays = false) => {
  if (state === undefined || state === null) {
    throw new Error(`Cannot call setIn() with ${String(state)} state`)
  }
  if (key === undefined || key === null) {
    throw new Error(`Cannot call setIn() with ${String(key)} key`)
  }
  // Recursive function needs to accept and return State, but public API should
  // only deal with Objects
  return setInRecursor(state, 0, toPath(key), value, destroyArrays)
}

export const hasChangeValues = (record, dirtyFields) => {
  let values = { id: record.id }
  Object.keys(dirtyFields).forEach((key) => {
    const data = getIn(record, key)
    const path = toPath(key)
    if (Array.isArray(path) && path.length > 1) {
      const idKey = toStrPath(path, 'id')
      const idData = getIn(record, idKey)
      values = setIn(values, key, data)
      values = setIn(values, idKey, idData)
    } else if (data && !(typeof data === 'object')) {
      values = setIn(values, key, data)
    }
  })
  return values
}

export const onboardingStatus = (record) => {
  // checks which fields are required to be able to complete each section
  // console.log(record)

  const onboardingComplete =
    record.title &&
    record.sku &&
    record.short_desc &&
    record.brand_id &&
    record.sub_category_id &&
    record.primary_supplier_id &&
    true

  const designComplete = record.lengthCM && record.widthCM && record.heightCM && record.weightKG
  const packagingComplete =
    record.packagings &&
    record.packagings[0].master_carton_quantity &&
    record.packagings[0].carton_width_cm &&
    record.packagings[0].carton_length_cm &&
    record.packagings[0].carton_height_cm &&
    record.packagings[0].carton_gross_weight &&
    record.packagings[0].carton_net_weight &&
    record.packagings[0].packaging_price &&
    record.packagings[0].sku_inset &&
    record.packagings[0].packagingLengthCM &&
    record.packagings[0].packagingHeightCM &&
    record.packagings[0].packagingWidthCM &&
    record.packagings[0].packagingVolume &&
    record.packagings[0].packagingWeightKG &&
    record.packagings[0].carton_volume &&
    record.packagings[0].packagingStyleID

  const qualityComplete = record.product_inspection && record.product_inspection[0].pass
  const quotingComplete = record.quotes && true

  const readyFDR = record.scmAgentFDR && record.commissionFDR
  const readySC = record.influencerSC && record.commissionSC

  return {
    onboardingComplete,
    designComplete,
    qualityComplete,
    quotingComplete,
    packagingComplete,
    readyFDR,
    readySC,
    approved: record.approved_by,
  }
}
