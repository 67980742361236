import React, { useState } from 'react'
import { Box, CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import { useNotify, useDataProvider, DateInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import lodashGet from 'lodash/get'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'

const useStyles = makeStyles(() => ({
  fieldInput: {
    minWidth: '200px',
  },
}))

const DateFieldInput = (props) => {
  const classes = useStyles()

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const { resource, field, id, formField, className, ...rest } = props

  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(props)

  return (
    <Box display="flex">
      <DateInput
        variant="standard"
        fullWidth
        source={formField || field}
        className={className || classes.fieldInput}
        InputProps={{
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
          },
        }}
        disabled={!editing}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                // console.log(field, values, formField)

                await dataProvider.update(resource, {
                  data: { id, [field]: lodashGet(values, formField) || values[field] },
                })
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                notify(error.message, { type: 'warning' })
              }
              setEditing(false)
              setSaving(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
      {saving ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : null}
    </Box>
  )
}

// const EditFieldForm = (props) => {
//   const form = useForm()
//   const { values } = useFormState()
//   const classes = useStyles()
//   const dataProvider = useDataProvider()
//   const notify = useNotify()

//   const { cancelAction, resource, field, id, formField, ...rest } = props

//   return (

//   )
// }

export default DateFieldInput
