import React, { useEffect, useState } from 'react'
import {
  AutocompleteInput,
  useCreate,
  useNotify,
  useDataProvider,
  FormWithRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form'

import SupplierSelectWithCreateForm from './SupplierSelectWithCreateForm'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
})

const SupplierSelectWithCreate = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [create, { loading }] = useCreate('suppliers')

  const form = useForm()
  // const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [addressTypes, setAddressTypes] = useState([])
  // const [countries, setCountries] = useState([])
  // const [states, setStates] = useState([])
  // const [cities, setCities] = useState([])

  // eslint-disable-next-line no-unused-vars
  const { label, source, options, setOptions, optionText, optionValue, ...rest } = props

  const handleChange = (option) => {
    const newOptions = [...suppliers]
    newOptions.push(option)
    const abortController = new AbortController()
    !abortController.signal.aborted && setSuppliers(newOptions)
    // console.log(newOptions)
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data: suppliersTemp } = await dataProvider.getList('supplier', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
        !abortController.signal.aborted && setSuppliers(suppliersTemp)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const getData = async () => {
      try {
        const {
          data: { addressTypes: addressTypesList },
        } = await dataProvider.getSupplierDict('SupplierDict', {})

        // console.log(addressTypes, supplierModes, supplierTypes)
        // const { data: countries } = await dataProvider.getList('country', {})
        // const { data: countrySubdivision = [] } = await dataProvider.getList(
        //   'country_subdivision',
        //   {},
        // )
        // const cities = {}
        // const states = {}
        // countrySubdivision.forEach((item) => {
        //   if (item.level === 1) {
        //     // stateIds[item.id] = item.id
        //     if (!states[item.country_code]) {
        //       states[item.country_code] = [item]
        //     } else {
        //       states[item.country_code].push(item)
        //     }
        //   } else if (item.level === 2) {
        //     if (!cities[item.parent_id]) {
        //       cities[item.parent_id] = [item]
        //     } else {
        //       cities[item.parent_id].push(item)
        //     }
        //   }
        // })
        // setStateIds(stateIds)
        if (!abortController.signal.aborted) {
          setAddressTypes(addressTypesList)
          // setCountries(countries)
          // setStates(states)
          // setCities(cities)
        }
      } catch (error) {
        // console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }
    if (showDialog) getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, showDialog])

  const handleClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(true)
  }

  const handleCloseClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(false)
  }

  useEffect(() => {
    return () => {}
  }, [])

  const handleSubmit = async (params) => {
    // console.log(params)
    const abortController = new AbortController()
    create(
      {
        payload: {
          data: {
            name: params.supplier_name,
            business_licence_no: params.supplier_businessLicenceNumber,
            addresses: {
              data: {
                type: params.supplier_address_type,
                local_full_address: params.supplier_local_full_address,
                country_code: params.supplier_country_code || 'CN',
              },
            },
            contacts: {
              data: {
                local_name: params.supplier_local_name,
                mobile: params.supplier_mobile,
              },
            },
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          !abortController.signal.aborted && form.change(source, data.id)
          handleChange({ id: data.id, name: params.supplier_name })
          !abortController.signal.aborted && setShowDialog(false)
        },
        onFailure: (resp) => {
          if (
            resp.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "supplier_business_licence_no_key"'
          ) {
            form.change(
              source,
              suppliers.find(
                (item) => item.business_licence_no === params.supplier_businessLicenceNumber,
              ).id,
            )

            // console.log(
            //   suppliers.find(
            //     (item) => item.business_licence_no === params.supplier_businessLicenceNumber,
            //   ).id,
            // )
            notify('Supplier Licence already exists', { type: 'info' })
            !abortController.signal.aborted && setShowDialog(false)
          } else {
            console.log('error', resp.message)
            notify(resp.message, { type: 'error' })
          }
        },
      },
    )
  }

  // console.log('SELWITHCREATE', props, rest)

  return (
    <div className={classes.root}>
      <AutocompleteInput
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        label={label || 'Supplier:'}
        source={source}
        choices={suppliers}
        // optionText={optionText}
        // optionValue={optionValue}
        {...rest}
      />

      <FormWithRedirect
        resource="suppliers"
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <SupplierSelectWithCreateForm
            handleClick={handleClick}
            handleCloseClick={handleCloseClick}
            showDialog={showDialog}
            addressTypes={addressTypes}
            // countries={countries}
            // states={states}
            // cities={cities}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            pristine={pristine}
            saving={saving}
            loading={loading}
          />
        )}
      />
    </div>
  )
}

export default SupplierSelectWithCreate
