import React, { useEffect } from 'react'
import { AutocompleteInput, ReferenceInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import ClientSelectWithCreateForm from './ClientSelectWithCreateForm'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '250px',
    width: '100%',
  },
  hideLabelAutoComplete: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
})

const ClientSelectWithCreate = (props) => {
  const classes = useStyles()

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <div className={classes.root}>
      <ReferenceInput
        source="client_id"
        reference="organisation"
        label="Client"
        sort={{ field: 'name', order: 'ASC' }}
        className={props.className}
        variant="standard"
        {...props}
      >
        <AutocompleteInput
          className={classes.hideLabelAutoComplete}
          create={<ClientSelectWithCreateForm />}
          fullWidth
          variant="outlined"
          label=""
          optionText={(record) => `${record?.id}. ${record?.name} (${record?.owner?.email})`}
        />
      </ReferenceInput>
    </div>
  )
}

export default ClientSelectWithCreate
