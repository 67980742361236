import React from 'react'
import { FormWithRedirect, Edit, useNotify, useDataProvider } from 'react-admin'
import { Card } from '@material-ui/core'
import CustomToolbar from '../../components/CustomToolbar'
import FormData from './FormData'
import RoleList from './RoleList'
import { hasChangeValues } from '../../../utils/utils'

const UserEdit = (props) => (
  <Edit {...props}>
    <UserForm />
  </Edit>
)

const UserForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSubmit = async (record, formState) => {
    const changeRecord = hasChangeValues(record, formState.dirtyFields)
    if (!changeRecord || Object.keys(changeRecord).length < 2) {
      return null
    }

    try {
      await dataProvider.update('users', {
        data: { ...changeRecord },
      })
      notify('Update Complete', { type: 'info' })
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <FormData id={formProps.record.id} />
          <RoleList basePath="/users" userId={formProps.record.id} />
          <CustomToolbar
            redirect={'/users'}
            record={formProps.record}
            basePath={formProps.basePath}
            undoable
            invalid={formProps.invalid}
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            resource="users"
          />
        </Card>
      )}
    />
  )
}

export default UserEdit
