import React, { useState, useEffect } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
} from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { withStyles } from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  required,
  AutocompleteArrayInput,
  NumberInput,
  FileField,
  ImageField,
  ImageInput,
  FileInput,
  DateInput,
  // ReferenceInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  useDataProvider,
  useNotify,
  useGetIdentity,
} from 'react-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextInput from '../../../../components/OutlinedTextInput'
import SelectInput from '../../../../components/input/SelectInput'
import ChipInput from '../../../../components/input/ChipInput'
import useStyles from '../styles'
import SupplierSelectWithCreate from '../../../components/SupplierSelectWithCreate'
import ClientSelectWithCreate from '../../../components/ClientSelectWithCreate'
import { ColorInput } from '../../../components/ColourPicker'
import Confirm from '../../../components/Confirm'
import { validation } from '../common/utils'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#1E1E1E',
    color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const FormData = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const {
    supplierID,
    formProps,
    handleSave,
    recordStatus,
    showTracking,
    setDataLists,
    id,
    setID,
    record,
    setStatus,
  } = props
  const {
    dataLists: {
      packagingTypes = [],
      logisticsProviders = [],
      qualityAgencies = [],
      categories = [],
      materials = [],
      suppliers = [],
      factoryMilestones = [],
      qualityMilestones = [],
      logisticsMilestones = [],
      clientMilestones = [],
      inspectionTypes = [],
      inspectionLevels = [],
      clients = [],
    },
  } = props
  const { identity } = useGetIdentity()
  const { values } = useFormState()
  const [productMaterials, setProductMaterials] = useState(values.productMaterials)

  useEffect(() => {
    return () => {}
  }, [])

  const getMilestones = (x) => x * 5
  const milestones = Array.from(Array(20), (_, x) => {
    const v = getMilestones(x + 1)
    return {
      name: v.toString(),
      value: v,
    }
  })

  const requiredFieldsObject = validation(values)

  const requiredFields = []

  const getRequiredFields = (obj) => {
    Object.values(obj).map((item) => {
      if (typeof item == 'object' && item !== null) {
        getRequiredFields(item)
      } else {
        requiredFields.push(item)
      }
    })
  }
  getRequiredFields(requiredFieldsObject)

  return (
    <Box className={classes.outlineBox}>
      <Box display="flex" style={{ border: 'lightgrey solid 1px' }}>
        <Box flex={6} display="flex" flexDirection="column" className={classes.centerBox}>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Product Information</Typography>
              {values?.primary_photo &&
              values?.categories?.length > 0 &&
              values?.short_description &&
              // values?.productColours?.length > 0 &&
              // values?.productMaterials?.length > 0 &&
              values?.name ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box mt="10px">
                  <span className={classes.filesLabel}>Primary Photo</span>
                </Box>
                <ImageInput
                  source="primary_photo"
                  label=""
                  accept="image/*"
                  maxSize={5000000}
                  classes={{
                    root: classes.imageDropRoot,
                    dropZone: classes.imageDropZone,
                    preview: classes.imagePreview,
                    removeButton: classes.imageRemove,
                  }}
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <ImageField source="url" title="filename" />
                </ImageInput>

                <Box mt="10px">
                  <span className={classes.filesLabel}>Thumbnail</span>
                </Box>
                <ImageInput
                  source="thumbnail"
                  label=""
                  accept="image/*"
                  maxSize={5000000}
                  //   validate={required()} // Enable Required
                  classes={{
                    root: classes.thumbnailDropRoot,
                    dropZone: classes.thumbnailDropZone,
                    preview: classes.thumbnailPreview,
                    removeButton: classes.thumbnailRemove,
                  }}
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <ImageField source="url" title="filename" />
                </ImageInput>

                <Box display="flex" mt="10px">
                  <Box flex={1} pr="5px">
                    <TextInput variant="standard" fullWidth source="name" label="Product Name:" />
                  </Box>
                  <Box flex={1} pl="5px">
                    <TextInput
                      variant="standard"
                      fullWidth
                      source="offer_purchases[0].brand"
                      label="Brand:"
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex" position="relative">
                  <Box flex={4} display="contents">
                    {categories?.length > 0 ? (
                      <AutocompleteArrayInput
                        source="categories"
                        // optionValue={(val) => {
                        //   console.log(val)
                        //   return val.category.id
                        // }}
                        // optionText={(val) => {
                        //   console.log(val)
                        //   return val.name
                        // }}
                        choices={categories}
                      />
                    ) : (
                      <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
                    )}
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <Box position="relative">
                      <TextInput
                        variant="outlined"
                        fullWidth
                        column
                        multiline
                        minRows={2}
                        inputProps={{
                          maxLength: 250,
                          style: {
                            marginBottom: 15,
                            alignItems: 'baseline',
                          },
                        }}
                        source="short_description"
                        label="Short Description:"
                      />
                      <span
                        style={{
                          position: 'absolute',
                          right: '20px',
                          bottom: '20px',
                          fontSize: '10px',
                        }}
                      >
                        {values.short_description?.length || 0}/250
                      </span>
                    </Box>
                  </Box>
                  <Box flex={1} />
                </Box>
                <span className={classes.labelText}>Specifications:</span>
                <Box display="flex" mt="20px">
                  <Box flex={1} display="flex" alignItems="center" mb="20px" pl="10px">
                    <span className={classes.labelText}>Colour(s)</span>
                  </Box>
                  <Box flex={2} ml="30px" display="flex">
                    <Box display="flex">
                      <ArrayInput source="productColours" label="">
                        <SimpleFormIterator
                          TransitionProps={{ enter: false, exit: false }}
                          addButton={
                            <Button
                              style={{ width: '100px', backgroundColor: '#F8F8FA', color: 'black' }}
                            >
                              <AddIcon />
                            </Button>
                          }
                          removeButton={
                            <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                          }
                        >
                          <FormDataConsumer>
                            {({ getSource, scopedFormData, rest }) => (
                              <Box flex={1}>
                                <ColorInput
                                  source={getSource('option_value.value')}
                                  {...rest}
                                  record={scopedFormData}
                                  className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                                  picker="Sketch"
                                  label=""
                                  validate={required()}
                                />
                              </Box>
                            )}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    </Box>
                  </Box>
                  <Box width="24px" />
                </Box>
                <Box display="flex">
                  <Box flex={1} display="flex" alignItems="center" mb="20px" pl="10px">
                    <span className={classes.labelText}>Material(s)</span>
                  </Box>
                  <Box flex={2} ml="30px">
                    <Box display="flex" position="relative">
                      <Box flex={1} display="contents">
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={materials}
                          limitTags={2}
                          disabled={materials?.length < 1}
                          value={productMaterials}
                          getOptionSelected={(option, value) => {
                            // console.log('OPTION', option)
                            // console.log('VALUE', value)
                            return (
                              option.name === value.option_value?.value ||
                              option.value === value.option_value?.value ||
                              option.name === value.name ||
                              option.value === value.name ||
                              option.value === value ||
                              option.name === value
                            )
                          }}
                          getOptionLabel={(option) => {
                            // console.log('OPTLABEL', option)
                            return option.option_value?.value || option.name
                          }}
                          onChange={(e, v) => {
                            setProductMaterials(v)
                            form.change('productMaterials', v)
                          }}
                          filterSelectedOptions
                          className={classes.autocompleteSelect}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Materials"
                              placeholder=""
                              variant="outlined"
                              fullWidth
                              className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
                            />
                          )}
                        />
                      </Box>
                      <Box flex={1} />
                    </Box>
                  </Box>
                  <Box width="24px" />
                </Box>

                <ArrayInput source="otherSpecifications" label="">
                  <SimpleFormIterator
                    TransitionProps={{ enter: false, exit: false }}
                    addButton={
                      <Button
                        style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}
                      >
                        <AddIcon />
                      </Button>
                    }
                    removeButton={
                      <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                    }
                  >
                    <FormDataConsumer>
                      {({ getSource, scopedFormData, rest }) => (
                        <Box display="flex">
                          <Box flex={1}>
                            <TextInput
                              source={getSource('name')}
                              {...rest}
                              record={scopedFormData}
                              label="Name"
                              fullWidth
                              variant="outlined"
                              margin="none"
                              className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                            />
                          </Box>
                          <Box flex={2} ml="30px">
                            <ChipInput
                              name={getSource('option_value.value')}
                              {...rest}
                              defaultValue={scopedFormData?.option_value?.value}
                              record={scopedFormData}
                              label="Value"
                              fullWidth
                              variant="outlined"
                              margin="none"
                              className={classes.hideLabelAutoComplete}
                            />
                          </Box>
                        </Box>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
                <Box display="flex">
                  <Box flex={1}>
                    <Box position="relative">
                      <TextInput
                        variant="outlined"
                        fullWidth
                        column
                        multiline
                        minRows={2}
                        inputProps={{
                          maxLength: 250,
                          style: {
                            marginBottom: 15,
                            alignItems: 'baseline',
                          },
                        }}
                        source="notes"
                        label="Notes:"
                      />
                      <span
                        style={{
                          position: 'absolute',
                          right: '20px',
                          bottom: '20px',
                          fontSize: '10px',
                        }}
                      >
                        {values.notes?.length || 0}/250
                      </span>
                    </Box>
                  </Box>
                  <Box flex={1} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>Dimensions</Typography>
              {values?.product_dimensions?.length &&
              values?.product_dimensions?.width &&
              values?.product_dimensions?.height &&
              values?.product_dimensions?.gross_weight &&
              // values?.packagingDimensions?.length &&
              // values?.packagingDimensions?.width &&
              // values?.packagingDimensions?.height &&
              // values?.packagingDimensions?.gross_weight &&
              values?.carton_dimensions?.length &&
              values?.carton_dimensions?.width &&
              values?.carton_dimensions?.height &&
              values?.carton_dimensions?.gross_weight &&
              values?.pieces_per_carton &&
              values?.packaging_type ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column">
                <Box mt="10px" p="10px">
                  <span className={classes.filesLabel}>Product Dimensions</span>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            L (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            W (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            H (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            G.W. (KG)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="productDimensions">
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="product_dimensions.length"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="product_dimensions.width"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="product_dimensions.height"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="product_dimensions.gross_weight"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt="10px" p="10px">
                  <span className={classes.filesLabel}>Packaging Dimensions</span>
                  <Box width="30%">
                    <Box flex={2}>
                      {packagingTypes?.length > 0 && (
                        <SelectInput
                          label="Packaging Type:"
                          source="packaging_type"
                          choices={packagingTypes}
                          optionText="name"
                          optionValue="value"
                          defaultValue=""
                          fullWidth
                          variant="standard"
                        />
                      )}
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            L (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            W (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            H (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            G.W. (KG)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="packagingDimensions">
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packaging_dimensions.length"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packaging_dimensions.width"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packaging_dimensions.height"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packaging_dimensions.gross_weight"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt="10px" p="10px">
                  <span className={classes.filesLabel}>Shipping Carton Dimensions</span>
                  <Box width="40%" display="flex">
                    <Box flex={1}>
                      <NumberInput
                        source="pieces_per_carton"
                        label="Pieces per carton:"
                        fullWidth
                        variant="standard"
                        margin="none"
                      />
                    </Box>
                    <Box flex={1} ml="10px">
                      <NumberInput
                        source="cartons_per_pallet"
                        label="Cartons per pallet:"
                        fullWidth
                        variant="standard"
                        margin="none"
                      />
                    </Box>
                  </Box>
                  <Box width="100%" display="flex">
                    <Box flex={1}>
                      <NumberInput
                        source="cartons_per_container_20ft"
                        label="Cartons per containerer (20ft):"
                        fullWidth
                        variant="standard"
                        margin="none"
                      />
                    </Box>
                    <Box flex={1} ml="10px">
                      <NumberInput
                        source="cartons_per_container_40ft"
                        label="Cartons per container (40ft):"
                        fullWidth
                        variant="standard"
                        margin="none"
                      />
                    </Box>
                    <Box flex={1} ml="10px">
                      <NumberInput
                        source="cartons_per_container_40ft_hc"
                        label="Cartons per container (40ft HC):"
                        fullWidth
                        variant="standard"
                        margin="none"
                      />
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            L (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            W (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            H (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            G.W. (KG)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="cartonDimensions">
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="carton_dimensions.length"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="carton_dimensions.width"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="carton_dimensions.height"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="carton_dimensions.gross_weight"
                              label=""
                              fullWidth
                              format={(value) => value / 100 || null}
                              parse={(value) => value * 100}
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                            />
                          </BodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>PO Information</Typography>
              {values?.offer_purchases?.length > 0 &&
              values?.offer_purchases[0].client_quantity &&
              values?.offer_purchases[0].client_price &&
              // values?.offer_purchases[0].client_sku &&
              values?.freight_method &&
              values?.incoterms &&
              // values?.offer_purchases[0].required_delivery_date &&
              // values?.offer_purchases[0].purchase_order_number &&
              values?.offer_purchases[0].client?.id ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box display="flex">
                  <Box flex={1}>
                    <ClientSelectWithCreate
                      options={clients}
                      setOptions={(newOptions) => {
                        const newLists = { ...props.dataLists }
                        newLists.clients = newOptions
                        setDataLists(newLists)
                      }}
                      optionText={(value) => value?.name}
                      source={'offer_purchases[0].client.id'}
                    />
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    <NumberInput
                      source="offer_purchases[0].client_quantity"
                      label="Client Order Quantity:"
                      fullWidth
                      variant="standard"
                      margin="none"
                    />
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <NumberInput
                      source="offer_purchases[0].client_price"
                      label="Client Purchase Price:"
                      format={(value) => value / 100}
                      parse={(value) => value * 100}
                      fullWidth
                      InputProps={{
                        min: 0,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      variant="standard"
                      margin="none"
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    {/* {freightMethods.length > 0 && ( */}
                    <SelectInput
                      label="Freight Method:"
                      source="freight_method"
                      // choices={freightMethods}
                      choices={[
                        { id: 1, name: 'Sea Freight' },
                        { id: 2, name: 'Air Freight' },
                        { id: 3, name: 'Courier' },
                      ]}
                      optionText="name"
                      optionValue="name"
                      // defaultValue=""
                      fullWidth
                      variant="standard"
                    />
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <SelectInput
                      label="IncoTerms:"
                      source="incoterms"
                      // choices={freightMethods}
                      choices={[
                        { id: 1, name: 'DDP' },
                        { id: 2, name: 'FOB' },
                        { id: 3, name: 'CIF' },
                      ]}
                      optionText="name"
                      optionValue="name"
                      defaultValue=""
                      fullWidth
                      variant="standard"
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={1} pr="5px">
                    <DateInput
                      source="offer_purchases[0].purchase_order_date"
                      label="Purchase Order Date:"
                      fullWidth
                      variant="standard"
                      margin="none"
                      // className={classes.dateInputNoLabel}
                    />
                  </Box>
                  <Box flex={1}>
                    <DateInput
                      source="offer_purchases[0].required_delivery_date"
                      label="Required Delivery Date:"
                      fullWidth
                      variant="standard"
                      margin="none"
                      // className={classes.dateInputNoLabel}
                    />
                  </Box>

                  <Box flex={2} pl="5px" />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    <TextInput
                      variant="standard"
                      fullWidth
                      source="offer_purchases[0].purchase_order_number"
                      label="Purchase Order Number:"
                    />
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <TextInput
                      variant="standard"
                      fullWidth
                      source="offer_purchases[0].client_sku"
                      label="Product SKU:"
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={3} p="10px 5px 10px 0px">
                    <TextInput
                      variant="standard"
                      fullWidth
                      multiline
                      source="client_quote.warranty_conditions"
                      label="Warranty conditions:"
                    />
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <TextInput
                      variant="standard"
                      fullWidth
                      source="client_quote.warranty_term"
                      label="Warranty term:"
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box mt="10px">
                  <span className={classes.filesLabel}>Client Payment Terms</span>
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <ArrayInput source="clientPaymentTerms" label="">
                      <SimpleFormIterator
                        TransitionProps={{ enter: false, exit: false }}
                        addButton={
                          <Button
                            style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                          >
                            <AddIcon />
                          </Button>
                        }
                        removeButton={
                          <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                        }
                      >
                        <FormDataConsumer>
                          {({ getSource, scopedFormData, rest }) => (
                            <Box display="flex">
                              <Box flex={1} p="10px 5px 10px 0px">
                                <SelectInput
                                  label="Value"
                                  source={getSource('value')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={milestones}
                                  optionText="name"
                                  optionValue="value"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              </Box>
                              <Box flex={1} p="10px 0px 10px 5px">
                                {clientMilestones?.length > 0 && (
                                  <SelectInput
                                    label="Milestone"
                                    source={getSource('milestone')}
                                    {...rest}
                                    record={scopedFormData}
                                    choices={clientMilestones}
                                    optionText="name"
                                    optionValue="name"
                                    defaultValue=""
                                    fullWidth
                                    variant="standard"
                                    className={classes.dialogInput}
                                  />
                                )}
                              </Box>
                            </Box>
                          )}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                    <Box flex={2} />
                  </Box>
                </Box>

                <Box mt="20px">
                  <span className={classes.filesLabel}>Purchase Order File:</span>
                  <FileInput
                    column
                    source="poFile"
                    classes={{
                      dropZone: classes.itemBoxDropZone,
                      removeButton: classes.itemBoxDZRemove,
                      preview: classes.itemBoxDZPreview,
                      root: classes.itemBoxDZRoot,
                    }}
                    label=""
                    addLabel="false"
                    placeholder={<div className={classes.itemBoxUploadPlaceholder}>Upload</div>}
                  >
                    <FileField source="url" title="filename" />
                  </FileInput>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Factory Invoice</Typography>
              {values?.client_quote?.product_quote?.supplier?.id &&
              values?.factoryQuoteQuantity &&
              values?.factoryQuotePrice &&
              values?.factoryQuoteLeadTime &&
              values?.factoryPaymentTerms?.length > 0 &&
              values?.client_quote?.product_quote?.quote &&
              values?.client_quote?.product_quote?.valid_date ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <span className={classes.filesLabel}>Factory Invoice</span>
                {!supplierID && (
                  <Box display="flex">
                    <Box flex={3}>
                      <SupplierSelectWithCreate
                        options={suppliers}
                        setOptions={(newOptions) => {
                          const newLists = { ...props.dataLists }
                          newLists.suppliers = newOptions
                          setDataLists(newLists)
                        }}
                        optionText={(value) => {
                          return value?.official_verification_link
                            ? value?.name
                            : `${value?.name} (Unverified)`
                        }}
                        source={'client_quote.product_quote.supplier.id'}
                      />
                    </Box>
                    <Box flex={1} />
                  </Box>
                )}
                <Box mt="10px">
                  <span className={classes.labelText}>Quote: *</span>
                </Box>
                <Box display="flex">
                  <Box flex={2}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '25%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Quantity
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '25%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Unit Price (FOB)
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '25%', fontSize: '10px', backgroundColor: '#00B3C3' }}
                              align="center"
                            >
                              Tooling Costs
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '25%', fontSize: '10px', backgroundColor: '#00B3C3' }}
                              align="center"
                            >
                              Lead Time
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuoteQuantity"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuotePrice"
                                label=""
                                format={(value) => (value / 100).toFixed(2)}
                                parse={(value) => value * 100}
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuoteToolingCost"
                                label=""
                                format={(value) => (value / 100).toFixed(2)}
                                parse={(value) => value * 100}
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuoteLeadTime"
                                label=""
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position="end">days</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex" mt="20px">
                  <Box flex={1}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '33%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Sample Cost
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '34%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Sample Lead Time
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '33%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Custom Sample Cost
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuoteSampleCost"
                                label=""
                                format={(value) => (value / 100).toFixed(2)}
                                parse={(value) => value * 100}
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuoteSampleLeadTime"
                                label=""
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position="end">days</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="factoryQuoteCustomSampleCost"
                                label=""
                                format={(value) => (value / 100).toFixed(2)}
                                parse={(value) => value * 100}
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex" padding="15px 0 0 0" flexDirection="row" width="100%">
                  <Box flex={2} mt="4px">
                    <span className={classes.quoteLabel}>Factory invoice upload:</span>
                    <FileInput
                      column
                      source="client_quote.product_quote.quote"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      addLabel="false"
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                    >
                      <FileField source="url" title="filename" />
                    </FileInput>
                  </Box>
                  <Box flex={1}>
                    <span className={classes.labelText}>Valid Date:</span>
                    <DateInput
                      source="client_quote.product_quote.valid_date"
                      label="Valid Date:"
                      fullWidth
                      variant="outlined"
                      margin="none"
                      className={classes.dateInputNoLabel}
                    />
                  </Box>
                  <Box flex={3} />
                </Box>
                <Box display="flex">
                  <Box flex={1} position="relative">
                    <TextInput
                      variant="outlined"
                      fullWidth
                      column
                      multiline
                      minRows={4}
                      inputProps={{
                        maxLength: 125,
                        style: {
                          marginBottom: 15,
                          alignItems: 'baseline',
                        },
                      }}
                      source="client_quote.product_quote.other_requirements"
                      label="Other Factory Requirements:"
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '20px',
                        bottom: '20px',
                        fontSize: '10px',
                      }}
                    >
                      {values.warranty?.length || 0}/125
                    </span>
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box mt="10px">
                  <span className={classes.filesLabel}>Factory Payment Terms</span>
                </Box>
                <Box mt="5px">
                  <ArrayInput source="factoryPaymentTerms" label="">
                    <SimpleFormIterator
                      TransitionProps={{ enter: false, exit: false }}
                      addButton={
                        <Button
                          style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                        >
                          <AddIcon />
                        </Button>
                      }
                      removeButton={
                        <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                      }
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData, rest }) => (
                          <Box display="flex">
                            <Box flex={2} ml="5px">
                              {factoryMilestones?.length > 0 && (
                                <SelectInput
                                  label="Milestone"
                                  source={getSource('milestone')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={factoryMilestones}
                                  optionText="name"
                                  optionValue="name"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              )}
                            </Box>
                            <Box flex={1}>
                              <SelectInput
                                label="Value"
                                source={getSource('value')}
                                {...rest}
                                record={scopedFormData}
                                choices={milestones}
                                optionText="name"
                                optionValue="value"
                                defaultValue=""
                                fullWidth
                                variant="standard"
                                className={classes.dialogInput}
                              />
                            </Box>
                          </Box>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Quality</Typography>
              {values?.quality_agency_id &&
              values?.inspection_type &&
              values?.inspection_level &&
              values?.inspection_quote_file &&
              values?.inspection_checklist &&
              values?.inspectionEstimate &&
              values?.inspection_work_days ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box display="flex">
                  <Box flex={1}>
                    <span className={classes.filesLabel}>Inspection Costs</span>

                    {qualityAgencies?.length > 0 && (
                      <SelectInput
                        label="Quality Agency:"
                        source="quality_agency_id"
                        choices={qualityAgencies}
                        optionText="name"
                        optionValue="id"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                      />
                    )}
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    {inspectionTypes?.length > 0 && (
                      <SelectInput
                        label="Inspection Type:"
                        source="inspection_type"
                        choices={inspectionTypes}
                        optionText="name"
                        optionValue="name"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                      />
                    )}
                  </Box>

                  <Box flex={1} p="10px 0px 10px 5px">
                    {inspectionLevels?.length > 0 && (
                      <SelectInput
                        label="Inspection Level:"
                        source="inspection_level"
                        choices={inspectionLevels}
                        optionText="name"
                        optionValue="name"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                      />
                    )}
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    <NumberInput
                      source="inspection_work_days"
                      label="Work days required :"
                      fullWidth
                      variant="standard"
                      margin="none"
                    />
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <NumberInput
                      source="inspection_estimate"
                      label="Inspection Estimate:"
                      format={(value) => (value / 100).toFixed(2)}
                      parse={(value) => value * 100}
                      fullWidth
                      InputProps={{
                        min: 0,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      variant="standard"
                      margin="none"
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px" mt="10px">
                    <span className={classes.quoteLabel}>Inspection Quote Upload:</span>
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <FileInput
                      column
                      source="inspection_quote_file"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      addLabel="false"
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                    >
                      <FileField source="url" title="filename" />
                    </FileInput>
                  </Box>
                  <Box flex={2} />
                </Box>

                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px" mt="10px">
                    <span className={classes.quoteLabel}>Upload Inspection Checklist:</span>
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <FileInput
                      column
                      source="inspection_checklist"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      addLabel="false"
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                    >
                      <FileField source="url" title="filename" />
                    </FileInput>
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box mt="10px">
                  <span className={classes.labelText}>Quality Inspection Agency Payment Terms</span>
                </Box>
                <Box mt="5px">
                  <ArrayInput source="qualityPaymentTerms" label="">
                    <SimpleFormIterator
                      TransitionProps={{ enter: false, exit: false }}
                      addButton={
                        <Button
                          style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                        >
                          <AddIcon />
                        </Button>
                      }
                      removeButton={
                        <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                      }
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData, rest }) => (
                          <Box display="flex">
                            <Box flex={1}>
                              <SelectInput
                                label="Value"
                                source={getSource('value')}
                                {...rest}
                                record={scopedFormData}
                                choices={milestones}
                                optionText="name"
                                optionValue="value"
                                defaultValue=""
                                fullWidth
                                variant="standard"
                                className={classes.dialogInput}
                              />
                            </Box>
                            <Box flex={2} ml="5px">
                              {qualityMilestones?.length > 0 && (
                                <SelectInput
                                  label="Milestone"
                                  source={getSource('milestone')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={qualityMilestones}
                                  optionText="name"
                                  optionValue="name"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Logistics</Typography>
              {values?.logistics_agency_id &&
              values?.logistics_lead_time_to_port &&
              values?.logistics_lead_time_to_warehouse &&
              values?.logistics_quote_file &&
              values?.logistics_quote_valid_date &&
              values?.hts_code &&
              values?.duty_rate ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box display="flex">
                  <Box flex={1}>
                    <span className={classes.filesLabel}>{`Int'l Freight Costs`}</span>
                    {logisticsProviders?.length > 0 && (
                      <SelectInput
                        label="Freight Agency:"
                        source="logistics_agency_id"
                        choices={logisticsProviders}
                        optionText="name"
                        optionValue="id"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                      />
                    )}
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box mt="10px">
                  <span className={classes.labelText}>Quote: *</span>
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '35%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              HTS Code
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '30%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Duty Rate
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '35%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Freight Estimate
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <TextInput
                                variant="filled"
                                margin="none"
                                fullWidth
                                source="hts_code"
                                label=""
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="duty_rate"
                                label=""
                                fullWidth
                                format={(value) => (value / 100).toFixed(2)}
                                parse={(value) => value * 100}
                                InputProps={{
                                  min: 0,
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="logistics_estimate"
                                label=""
                                format={(value) => (value / 100).toFixed(2)}
                                parse={(value) => value * 100}
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex" mt="20px">
                  <Box flex={1}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '50%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Lead Time To Port
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '50%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Lead Time To Warehouse
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="logistics_lead_time_to_port"
                                label=""
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position="end">days</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="logistics_lead_time_to_warehouse"
                                label=""
                                fullWidth
                                InputProps={{
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position="end">days</InputAdornment>
                                  ),
                                }}
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={1} />
                </Box>

                <Box display="flex" padding="15px 0 0 0" flexDirection="row" width="100%">
                  <Box flex={1} mt="4px" display="flex">
                    <Box flex={1}>
                      <span className={classes.quoteLabel}>Quote Upload:</span>
                      <FileInput
                        column
                        source="logistics_quote_file"
                        classes={{
                          dropZone: classes.itemBoxDropZone,
                          removeButton: classes.itemBoxDZRemove,
                          preview: classes.itemBoxDZPreview,
                          root: classes.itemBoxDZRoot,
                        }}
                        label=""
                        addLabel="false"
                        placeholder={
                          <div className={classes.placeholder}>
                            <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                          </div>
                        }
                      >
                        <FileField source="url" title="filename" />
                      </FileInput>
                    </Box>
                    <Box flex={1}>
                      <span className={classes.labelText}>Valid Date:</span>
                      <DateInput
                        source="logistics_quote_valid_date"
                        label="Valid Date:"
                        fullWidth
                        variant="outlined"
                        margin="none"
                        className={classes.dateInputNoLabel}
                      />
                    </Box>
                  </Box>

                  <Box flex={1} />
                </Box>
                <span className={classes.labelText}>Declarations:</span>
                <Box mt="5px">
                  <ArrayInput
                    source="client_quote.product_quote.product.product_declarations"
                    label=""
                  >
                    <SimpleFormIterator
                      TransitionProps={{ enter: false, exit: false }}
                      addButton={
                        <Button
                          style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                        >
                          <AddIcon />
                        </Button>
                      }
                      removeButton={
                        <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                      }
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData, rest }) => (
                          <Box display="flex">
                            <Box flex={1}>
                              <TextInput
                                source={getSource('declaration')}
                                {...rest}
                                record={scopedFormData}
                                label="Declaration"
                                fullWidth
                                variant="outlined"
                                margin="none"
                                className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                              />
                            </Box>
                            <Box flex={2} ml="5px">
                              <FileInput
                                column
                                margin="none"
                                source={getSource('file')}
                                {...rest}
                                record={scopedFormData}
                                classes={{
                                  dropZone: classes.itemBoxDropZoneCert,
                                  removeButton: classes.itemBoxDZRemoveDec,
                                  preview: classes.itemBoxDZPreview,
                                  root: classes.itemBoxDZRoot,
                                }}
                                label=""
                                addLabel="false"
                                accept="application/pdf"
                                placeholder={
                                  <div className={classes.itemBoxUploadPlaceholder}>Upload</div>
                                }
                              >
                                <FileField source="url" title="filename" />
                              </FileInput>
                            </Box>
                          </Box>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>
                <Box mt="10px">
                  <span className={classes.labelText}>{`Int'l Freight Payment Terms`}</span>
                </Box>
                <Box mt="5px" display="flex">
                  <Box flex={1}>
                    <ArrayInput source="logisticsPaymentTerms" label="">
                      <SimpleFormIterator
                        TransitionProps={{ enter: false, exit: false }}
                        addButton={
                          <Button
                            style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                          >
                            <AddIcon />
                          </Button>
                        }
                        removeButton={
                          <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                        }
                      >
                        <FormDataConsumer>
                          {({ getSource, scopedFormData, rest }) => (
                            <Box display="flex">
                              <Box flex={1}>
                                <SelectInput
                                  label="Value"
                                  source={getSource('value')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={milestones}
                                  optionText="name"
                                  optionValue="value"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              </Box>
                              <Box flex={2} ml="5px">
                                {logisticsMilestones?.length > 0 && (
                                  <SelectInput
                                    label="Milestone"
                                    source={getSource('milestone')}
                                    {...rest}
                                    record={scopedFormData}
                                    choices={logisticsMilestones}
                                    optionText="name"
                                    optionValue="name"
                                    defaultValue=""
                                    fullWidth
                                    variant="standard"
                                    className={classes.dialogInput}
                                  />
                                )}
                              </Box>
                            </Box>
                          )}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Box flex={1} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          {showTracking && (
            <Accordion className={classes.accordionStyle}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>Tracking Updates</Typography>
                {values?.logistics_carrier &&
                values.logistics_tracking_number &&
                values.logistics_tracking_url ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CancelOutlinedIcon style={{ color: 'red' }} />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="column" className={classes.centerBox}>
                  <Box display="flex" mt="10px">
                    <Box flex={1} pr="5px">
                      <TextInput
                        variant="standard"
                        fullWidth
                        source="logistics_carrier"
                        label="Freight Carrier:"
                      />
                    </Box>
                    <Box flex={3} pl="5px" />
                  </Box>
                  <Box display="flex" mt="10px">
                    <Box flex={1} pr="5px">
                      <TextInput
                        variant="standard"
                        fullWidth
                        source="logistics_tracking_number"
                        label="Tracking Number:"
                      />
                    </Box>
                    <Box flex={1} pl="5px">
                      <TextInput
                        variant="standard"
                        fullWidth
                        source="logistics_tracking_url"
                        label="Tracking URL:"
                      />
                    </Box>
                    <Box flex={2} />
                  </Box>

                  <Box mt="20px">
                    <span className={classes.filesLabel}>{`Tracking Photos & Videos:`}</span>
                    <FileInput
                      column
                      source="trackingMedia"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      multiple
                      addLabel="false"
                      placeholder={<div className={classes.itemBoxUploadPlaceholder}>Upload</div>}
                    >
                      <FileField target="trackingMedia" source="url" title="filename" />
                    </FileInput>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        {requiredFields?.length < 1 ? null : ( // <Box flex={2} />
          <Box p="20px" flex={2} display="flex" flexDirection="column">
            <span style={{ marginBottom: '15px' }}>Missing Fields</span>
            {requiredFields?.map((item) => (
              <span style={{ marginBottom: '10px', fontSize: '14px' }} key={`qtReq${item}`}>
                {item}
              </span>
            ))}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Confirm
          redirect="/customOffer"
          // redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="SAVE"
          undoable
          isSave
          handleSubmit={async (submission) => {
            await handleSave(
              submission,
              'save',
              dataProvider,
              notify,
              recordStatus,
              id,
              setID,
              record,
              form,
              setStatus,
            )
          }}
          saving={formProps.saving}
          disabled={
            formProps.saving ||
            materials?.length < 1 ||
            (values.status && values.status !== 'PENDING_SUBMISSION')
          }
          resource="offer"
        />
        <Confirm
          redirect="/customOffer"
          // redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="SUBMIT"
          undoable
          invalid={formProps.invalid}
          handleSubmit={async (submission) => {
            await handleSave(
              submission,
              'submit',
              dataProvider,
              notify,
              recordStatus,
              id,
              setID,
              record,
              form,
              setStatus,
            )
          }}
          saving={formProps.saving}
          disabled={
            formProps.saving ||
            formProps.invalid ||
            materials?.length < 1 ||
            (values.status && values.status !== 'PENDING_APPROVAL')
          }
          resource="offer"
        />
        <Confirm
          redirect="/customOffer"
          // redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="APPROVE"
          undoable
          invalid={formProps.invalid}
          handleSubmit={async (submission) => {
            await handleSave(
              submission,
              'approve',
              dataProvider,
              notify,
              recordStatus,
              id,
              setID,
              record,
              form,
              setStatus,
            )
          }}
          saving={formProps.saving}
          disabled={
            formProps.saving || formProps.invalid || materials?.length < 1 || !identity?.isManager
          }
          resource="offer"
        />
      </Box>
      <span style={{ fontStyle: 'italic', fontSize: '12px', color: 'grey' }}>
        Save is used for partial/incomplete forms. Submit is only avaliable when form is complete -
        you can see missing requirements on the right - this will eventually notify managers, who
        can then approve.
      </span>
    </Box>
  )
}

export default FormData
