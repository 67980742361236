import { makeStyles } from '@material-ui/core'
import React from 'react'

/**
 * Date: 2020/12/25 11:15
 * Author: caro
 * Description:
 * */

const _styles = {
  tagBox: {
    padding: '0',
    height: '20px',
    width: '70px',
    textAlign: 'center',
    lineHeight: '20px',
    borderRadius: '30px',
    margin: '0',
    fontSize: '12px',
    cursor: 'default',
  },
  pointer: {
    cursor: 'pointer',
  },
  primary: {
    background: '#3264FA',
    color: '#fff',
  },
  gray: {
    background: '#333333',
    color: '#fff',
  },
  yellow: {
    background: '#FFF133',
    color: '#333333',
  },
  orange: {
    background: '#FF9800',
    color: '#fff',
  },
  green: {
    background: '#4CAF50',
    color: '#fff',
  },
  red: {
    background: '#F44336',
    color: '#fff',
  },
  lightBlue: {
    background: '#C7EEFF',
    color: '#fff',
  },
  brightGreen: {
    background: '#82FFB6',
    color: '#fff',
  },
  amber: {
    background: '#F2C94C',
    color: '#fff',
  },
}
const _useStyles = makeStyles(_styles)
const Tag = props => {
  const classes = _useStyles()

  const green = props.green && classes.green
  const red = props.red && classes.red
  const orange = props.orange && classes.orange
  const yellow = props.yellow && classes.yellow
  const gray = props.gray && classes.gray
  const lightBlue = props.lightBlue && classes.lightBlue
  const amber = props.amber && classes.amber
  const brightGreen = props.brightGreen && classes.brightGreen
  const primary = props.primary && classes.primary
  const pointer = props.pointer && classes.pointer

  return (
    <div
      className={`${classes.tagBox} ${pointer} ${green} ${red} ${orange} ${yellow} ${gray} ${lightBlue} ${amber} ${brightGreen} ${primary}`}
    >
      {props.title}
    </div>
  )
}

export default Tag
