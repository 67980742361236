import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Tooltip,
  // Chip,
  //   Drawer,
} from '@material-ui/core'
import { useFormState, useForm } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  required,
  SelectInput,
  useDataProvider,
  // TextInput,
  NullableBooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  FileInput,
  FileField,
  ReferenceInput,
} from 'react-admin'
// import NumberText from 'react-number-format'
// import { roundTo } from '../../../utils/utils'
import WarningIcon from '@material-ui/icons/Warning'
import { getDisplayAddress } from '../../../utils/utils'
import { ColorField } from '../../components/ColourPicker'
import Confirm from '../../components/Confirm'
// import CancelIcon from '@material-ui/icons/Cancel'
// import RemoveCircle from '@material-ui/icons/Delete'
import TextInput from '../../../components/OutlinedTextInput'
import NumberFormat from '../../components/CustomNumberFormat'
// import Customiser from './Customiser'
import ClientSelectWithCreate from '../../components/ClientSelectWithCreate'
import useCustomiserStyles from '../styles/customiser'

const CreateOrderForm = ({ formProps, handleCreateOrder, calcUnitPrice }) => {
  const { values, errors } = useFormState()
  const dataProvider = useDataProvider()
  const form = useForm()
  const [clients, setClients] = useState([])
  //   const [open, setOpen] = useState(false)

  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)
  const classes = useCustomiserStyles()

  //   console.log(values)

  const colourOptions =
    values.product_components?.length > 0 && values.product_components[0].colours

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('organisation', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
        })
        !abortController.signal.aborted && setClients(clientsTemp)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider])

  //   console.log(colourOptions)

  const sortedPrices = [...values.prices].sort((a, b) => b.quantity - a.quantity)
  const dutiesMultiplier = values.dutyRate / 100 + 1

  return (
    <Box display="flex" flexDirection="column">
      {/* {values.client_id !== values.poClientId ? (
        <span style={{ marginBottom: '20px' }}>{values.client?.name}</span>
      ) : ( */}
      <ClientSelectWithCreate
        options={clients}
        setOptions={(newOptions) => {
          const newClients = { ...newOptions }
          setClients(newClients)
        }}
        default={values.client_id}
        optionText={(value) => value?.name}
        source={'poClientId'}
      />
      {/* )} */}
      <Box display="flex">
        <TextInput
          variant="standard"
          source="clientSKU"
          label="Product SKU"
          style={{ margin: '0px 10px', width: '220px' }}
        />
        <TextInput
          variant="standard"
          source="poNumber"
          label="Purchase order number"
          style={{ margin: '0px 10px', width: '220px' }}
        />
        <DateInput
          source="poDate"
          label="Purchase order issue date"
          variant="standard"
          validate={required()}
          style={{ margin: '0px 10px', width: '220px' }}
        />
      </Box>
      <Box>
        <FileInput
          source="poFile"
          classes={{
            dropZone: classes.itemBoxDropZone,
            removeButton: classes.itemBoxDZRemove,
            preview: classes.itemBoxDZPreview,
            root: classes.itemBoxDZRoot,
          }}
          label="Purchase order file"
          placeholder={
            <div className={classes.itemBoxUploadPlaceholder}>Purchase Order File Upload</div>
          }
        >
          <FileField source="url" title="filename" />
        </FileInput>
      </Box>
      <Box display="flex">
        <ReferenceInput
          source="deliveryAddressId"
          reference="address"
          sort={{ field: 'id', order: 'ASC' }}
          filter={{ organisation_id: values.poClientId }}
          perPage={100}
          validate={required()}
          alwaysOn
          style={{ minWidth: '200px' }}
          variant="standard"
        >
          <SelectInput optionText={(val) => getDisplayAddress(val)} fullWidth />
        </ReferenceInput>
        <ReferenceInput
          source="billingAddressId"
          reference="address"
          sort={{ field: 'id', order: 'ASC' }}
          filter={{ organisation_id: values.poClientId }}
          perPage={100}
          validate={required()}
          alwaysOn
          style={{ minWidth: '200px' }}
          variant="standard"
        >
          <SelectInput optionText={(val) => getDisplayAddress(val)} fullWidth />
        </ReferenceInput>
      </Box>
      <span style={{ fontSize: '12px' }}>Minimum total quantity: {values.prices[0].quantity}</span>
      <Box display="flex" mt="10px">
        <ArrayInput source="orderVariants" label="Variants">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData, rest }) => {
                const uniquePOPackagingOptions = []

                formData.packagingSizes?.map((spec) => {
                  if (
                    !uniquePOPackagingOptions.some(
                      (opt) => opt.packagingType === spec.packagingType,
                    )
                  ) {
                    uniquePOPackagingOptions.push(spec)
                  }
                })

                const uniquePOSizeOptions = formData.packagingSizes.filter(
                  (el) => el.packagingType === scopedFormData?.poPackagingOption,
                )

                const itemQuantity = formData.orderVariants?.reduce((a, b) => a + b?.quantity, 0)

                // console.log('ITEMQTY', itemQuantity)

                const freightOptions =
                  scopedFormData?.incoterms === 'FOB'
                    ? []
                    : [
                        {
                          id: 'Sea Freight',
                          name: 'Sea Freight',
                        },
                        {
                          id: 'Air Freight',
                          name: 'Air Freight',
                        },
                        // {
                        //   id: 'Courier',
                        //   name: 'Courier',
                        // },
                      ]

                const finalPrice = scopedFormData?.isSampleVariant
                  ? values.clientSamplePrice
                  : calcUnitPrice({
                      quantity: itemQuantity,
                      data: scopedFormData,
                      prices: sortedPrices,
                      dutiesMultiplier,
                      pkgOptions: formData.packagingSizes,
                      colourModifier: formData.standardColourPriceModifier,
                    })

                // console.log(finalPrice)

                const error = finalPrice
                  ? null
                  : !scopedFormData?.isSampleVariant && itemQuantity < values.prices[0].quantity
                  ? 'Quantity not met'
                  : 'No sample price'

                if (error) {
                  form.change('orderError', true)
                } else {
                  form.change('orderError', false)
                }
                // console.log('scopedForm', scopedFormData, error)
                return (
                  <Box display="flex" alignItems="center">
                    {uniquePOPackagingOptions?.length > 0 ? (
                      <SelectInput
                        label="Packaging"
                        source={getSource('poPackagingOption')}
                        {...rest}
                        record={scopedFormData}
                        choices={uniquePOPackagingOptions}
                        defaultValue=""
                        validate={required()}
                        fullWidth
                        optionText="packagingType"
                        optionValue="packagingType"
                        variant="standard"
                        style={{ margin: '0px 10px', width: '100px', minWidth: '100px' }}
                      />
                    ) : // ) : uniquePOPackagingOptions?.length === 1 ? (
                    //   <span style={{ fontSize: '12px' }}>
                    //     {uniquePOPackagingOptions[0].packagingType}
                    //   </span>
                    null}

                    {uniquePOSizeOptions?.length > 0 ? (
                      <SelectInput
                        choices={uniquePOSizeOptions}
                        label="Size"
                        optionValue="sizeName"
                        optionText="sizeName"
                        validate={required()}
                        source={getSource('size')}
                        {...rest}
                        record={scopedFormData}
                        variant="standard"
                        fullWidth
                        style={{ margin: '0px 10px', width: '100px', minWidth: '100px' }}
                      />
                    ) : // ) : uniquePOSizeOptions?.length === 1 && uniquePOSizeOptions[0].sizeName ? (
                    //   <span style={{ fontSize: '12px' }}>{uniquePOSizeOptions[0].sizeName}</span>
                    null}
                    {colourOptions?.length > 0 ? (
                      <SelectInput
                        label="Colour"
                        source={getSource('colour')}
                        {...rest}
                        record={scopedFormData}
                        choices={colourOptions}
                        // optionValue="id"
                        optionText={(val) =>
                          val.value.charAt(0) === '#' ? (
                            <ColorField addLabel record={val} source="value" />
                          ) : (
                            val.value
                          )
                        }
                        defaultValue=""
                        fullWidth
                        validate={required()}
                        variant="standard"
                        style={{ margin: '0px 10px', width: '220px' }}
                      />
                    ) : // ) : colourOptions?.length === 1 ? (
                    //   <span style={{ fontSize: '12px' }}>{colourOptions[0].value}</span>
                    null}
                    {/* {otherOptions.map((opt) => (
                  <SelectInput
                    key={`otherOpt${opt.name}`}
                    label={opt.name}
                    source={getSource(opt.name)}
                    {...rest}
                    record={scopedFormData}
                    choices={opt.option_value?.value}
                    defaultValue=""
                    fullWidth
                    validate={required()}
                    variant="standard"
                    style={{ margin: '0px 10px' }}
                  />
                ))} */}
                    <SelectInput
                      label="Incoterms"
                      source={getSource('incoterms')}
                      choices={[
                        {
                          id: 'DDP',
                          name: 'DDP',
                        },
                        {
                          id: 'FOB',
                          name: 'FOB',
                        },
                        {
                          id: 'CIF',
                          name: 'CIF',
                        },
                      ]}
                      defaultValue=""
                      validate={required()}
                      fullWidth
                      variant="standard"
                      style={{ margin: '0px 10px', width: '220px' }}
                    />
                    {freightOptions?.length > 0 ? (
                      <SelectInput
                        label="Shipping Method"
                        source={getSource('shippingMethod')}
                        choices={freightOptions}
                        defaultValue="Sea Freight"
                        validate={required()}
                        fullWidth
                        variant="standard"
                        style={{ margin: '0px 10px', width: '220px' }}
                      />
                    ) : null}
                    <NullableBooleanInput
                      label="Sample?"
                      source={getSource('isSampleVariant')}
                      {...rest}
                      record={scopedFormData}
                      defaultValue={false}
                      style={{ margin: '0px 10px', width: '100px' }}
                    />
                    <NumberFormat
                      label="Quantity"
                      source={getSource('quantity')}
                      {...rest}
                      className={classes.numberInputOrder}
                      record={scopedFormData}
                      validate={required()}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      defaultValue={0}
                      // style={{ margin: '0px 10px' }}
                    />
                    {/* {scopedFormData?.Colour === 'Custom' ? (
                <div>
                  <Button onClick={() => setOpen(true)}>Customise</Button>
                  <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ minWidth: '35vw' }}
                    >
                      <Customiser
                        svgString={
                          (values.product_quote?.svg_file, values.product_quote?.components)
                        }
                      />
                      <Button onClick={() => setOpen(false)}>Confirm</Button>
                    </Box>
                  </Drawer>
                </div>
              ) : (
                <div style={{ width: '120px', minWidth: '120px' }} />
              )} */}
                    <span style={finalPrice ? null : { color: 'red' }}>
                      {finalPrice ? `$${finalPrice.toFixed(2)}` : error}
                    </span>
                  </Box>
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <div style={{ textAlign: 'center' }}>
          {Object.values(errors)?.length > 0 ? (
            <Tooltip title={Object.values(errors).join(', ')}>
              <WarningIcon style={{ marginTop: '10px', marginLeft: '20px' }} />
            </Tooltip>
          ) : null}
        </div>
      </Box>
      <Confirm
        redirect="/order"
        // redirect={null}
        record={formProps.record}
        basePath={formProps.basePath}
        label="SUBMIT"
        undoable
        invalid={formProps.invalid}
        handleSubmit={async (newRecord) => {
          await handleCreateOrder({
            submission: newRecord,
            sortedPrices,
            dutiesMultiplier,
          })
        }}
        saving={formProps.saving}
        disabled={formProps.saving || formProps.invalid || values.orderError}
        resource="order"
      />
    </Box>
  )
}

export default CreateOrderForm
