/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { Create, useDataProvider, useNotify, useGetIdentity, FormWithRedirect } from 'react-admin'
import ProductCreateForm from './components/ProductCreateForm'
import Confirm from '../components/Confirm'
import {
  handleSave,
  //  validation,
  // getDataLists,
} from './common/utils'

const CatalogueProductCreate = (props) => (
  <Create
    {...props}
    actions={null}
    component="div"
    //    mutationMode="pessimistic"
  >
    <CatalogueProductCreateForm
    //  id={props.match.params.id}
    />
  </Create>
)

const CatalogueProductCreateForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [categories, setCategories] = useState([])
  const [materials, setMaterials] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [milestones, setMilestones] = useState([])
  const [config, setConfig] = useState()
  const [packagingTypes, setPackagingTypes] = useState([])
  const { identity } = useGetIdentity()

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      const debugMode = false
      debugMode && console.log('GETTING DATA LISTS')
      try {
        debugMode && console.count('STEP')
        debugMode && console.time('GETTING PACKAGING STYLES')
        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        !abortController.signal.aborted && setPackagingTypes(packagingTypesTemp)
        debugMode && console.timeEnd('GETTING PACKAGING STYLES')

        debugMode && console.count('STEP')
        debugMode && console.time('GETTING CATEGORIES')
        const { data: categoriesTemp } = await dataProvider.getList('categories', {})
        !abortController.signal.aborted && setCategories(categoriesTemp)
        debugMode && console.timeEnd('GETTING CATEGORIES')

        debugMode && console.count('STEP')
        debugMode && console.time('GETTING MATERIALS')
        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        !abortController.signal.aborted && setMaterials(materialsTemp)
        debugMode && console.timeEnd('GETTING MATERIALS')

        debugMode && console.count('STEP')
        debugMode && console.time('GETTING SUPPLIERS')
        const { data: suppliersTemp } = await dataProvider.getList('supplier', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
        !abortController.signal.aborted && setSuppliers(suppliersTemp)
        debugMode && console.timeEnd('GETTING SUPPLIERS')

        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setMilestones(milestonesTemp.filter((item) => item.type === 'client'))

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const config = {}
        configTemp.global_preferences.forEach(
          (item) => (config[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(config)
      } catch (error) {
        // console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        debugMode && console.timeEnd('SAVING DATA TIMER')
        debugMode && console.groupEnd()
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  //   console.log(config)
  if (!config) return null

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        factoryPrices: [],
        does_sea_freight_include_duties:
          config?.estimated_freight_rate_sea_duties_included === 'true' ? true : false,
        images: [],
        additional_files: [],
        tags: [],
        sizeOptions: [],
        freightRateSea: parseInt(config?.estimated_freight_rate_sea) / 100,
        freightRateAir: parseInt(config?.estimated_freight_rate_air) / 100,
        containerRate20ft: parseInt(config?.estimated_container_rate_20ft) / 100,
        containerRate40ft: parseInt(config?.estimated_container_rate_40ft) / 100,
        containerRate40ftHC: parseInt(config?.estimated_container_rate_40ft_hc) / 100,
      }}
      render={(formProps) => (
        <Box p="0px 20px">
          <ProductCreateForm
            isManager={identity?.isManager}
            categories={categories}
            suppliers={suppliers}
            packagingTypes={packagingTypes}
            materials={materials}
            milestones={milestones}
            setSuppliers={setSuppliers}
            config={config}
            {...formProps}
          />
          <Box display="flex" justifyContent="center" alignItems="center" mt="30px">
            <Confirm
              redirect="/catalogueProducts"
              // redirect={null}
              record={formProps.record}
              basePath={formProps.basePath}
              label="SAVE"
              undoable
              invalid={formProps.invalid}
              handleSubmit={async (submission) => {
                await handleSave({
                  submission,
                  dataProvider,
                  notify,
                  identity,
                })
              }}
              saving={formProps.saving}
              disabled={formProps.saving}
              resource="catalogueProducts"
            />
          </Box>
        </Box>
      )}
    />
  )
}

export default CatalogueProductCreate
