import React, { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider, SelectInput, ReferenceInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import { getDisplayName } from '../../../utils/utils'

const SCASelect = (props) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState(false)

  const { reference, resource, source, id, className, label } = props

  //   console.log(props)

  return (
    <Box display="flex">
      <ReferenceInput
        source={source}
        reference={reference}
        label={label || false}
        // sort={{ field: 'id', order: 'ASC' }}
        filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
        // style={{ minWidth: '200px' }}
        alwaysOn
        className={className}
        variant="standard"
      >
        <CustomSelectInput
          optionText={(val) => getDisplayName(val)}
          fullWidth
          disabled={!editing}
        />
        {/* <SelectInput optionText={(val) => getDisplayName(val)} fullWidth /> */}
      </ReferenceInput>
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)

              try {
                await dataProvider.update(resource, {
                  data: { id, [source]: values[source] },
                })

                notify('Update Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                setEditing(false)
                setSaving(false)
                notify(error.message, { type: 'warning' })
              }
              setEditing(false)
              setSaving(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

const CustomSelectInput = (props) => {
  const form = useForm()

  const { choices, source, disabled } = props

  if (choices.length < 1) {
    return null
  }

  return (
    <SelectInput
      choices={choices || []}
      variant="standard"
      source={source}
      label="SCA"
      fullWidth
      disabled={disabled}
      optionText={(val) => getDisplayName(val)}
      // {...props}
      onChange={(e) => {
        const sca = choices.find((el) => el.id === e.target.value)
        // console.log(sca)
        // console.log('changing')
        form.change('sca', sca)
      }}
    />
  )
}

export default SCASelect
