import React, { useState } from 'react'
import {
  useDataProvider,
  useNotify,
  Edit,
  FormWithRedirect,
  // SelectInput,
  TextInput,
  SelectArrayInput,
} from 'react-admin'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
// import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { useFormState, useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from '../../components/CustomNumberFormat'

const useStyles = makeStyles(() => ({
  editBox: {
    margin: 0,
    padding: 0,
  },
  table: {
    overflowX: 'unset',
    marginTop: '8px',
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#333333',
    marginBottom: '10px',
    marginTop: '10px',
  },
  imageBox: {
    width: '152px',
    height: '152px',
    border: 'solid 2px white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
  filesLabel: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  labelText: {
    fontSize: '12px',
  },
  numberInput: {
    width: '80px',
    height: '36px',
    textAlign: 'center',
  },
  numberTableInput: {
    width: '99%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '1%',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiInput-underline::before': {
      borderBottom: 'none !important',
    },
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      minHeight: '30px',
      '& .input': {
        border: 'solid 1px green',
        width: '50%',
      },
    },
  },
  selectTableInput: {
    width: '100%',
    padding: '0px',
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      width: '100%',
      minHeight: '30px',
    },
  },
  saveButton: {
    color: 'black',
    border: 'solid 1px rgba(180, 125, 80, 0.5)',
    backgroundColor: 'rgba(255, 149, 102, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(255, 149, 102, 0.3)',
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const ProductStockForm = (props) => {
  const classes = useStyles()
  const { saleableProductId, ...rest } = props
  return (
    <Edit
      {...rest}
      resource="saleableProducts"
      actions={null}
      component="div"
      id={saleableProductId}
      mutationMode="pessimistic"
      className={classes.editBox}
    >
      <EditForm id={saleableProductId} />
    </Edit>
  )
}

const EditForm = (props) => {
  // const dataProvider = useDataProvider()
  const { record } = props

  // console.log(record)

  const activeVariants = record.variants.map((el) => ({
    ...el,
    ...(el.show_variants && { show_variants: JSON.parse(el.show_variants) }),
  }))

  return (
    <FormWithRedirect
      {...props}
      validate={(values) => {
        // console.log(values)
        const errors = {}
        values.products?.length < 1 ? (errors.products = 'Required at least one product') : null
        !values.feedback ? (errors.feedback = 'Required feedback') : null
        // values.status !== 'IN_PROCESS' ? (errors.status = 'Request status not valid') : null
        return errors
      }}
      initialValues={{
        activeVariants,
      }}
      render={(formProps) => (
        <Box p="0px 20px">
          <FormData formProps={formProps} />
        </Box>
      )}
    />
  )
}

const FormData = () => {
  const classes = useStyles()
  const { values } = useFormState()

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)

  // console.log(values)

  const baseURL =
    process.env.NODE_ENV === 'development'
      ? 'https://dev.cliquestock.com/products/'
      : process.env.REACT_APP_CLIQUE_URL

  const prodOptions = values.product_quote.options.map((el) => ({ id: el.id, value: el.name }))
  const optionsValues = [
    values.product_quote?.product_sizes?.length > 0 && { id: 0, value: 'Size' },
    ...prodOptions,
  ]

  return (
    <Box
      display="flex"
      flexDirection="column"
      p="20px !important"
      style={{
        backgroundColor: '#FFF',
        borderRadius: '8px',
        border: 'solid 1px #CDCDCD',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <span>Variant Stock Details</span>
        <Button
          disabled={saving}
          className={classes.saveButton}
          onClick={async () => {
            setSaving(true)
            // const updateVariants = []
            try {
              const updateSPVs = []
              values.activeVariants.map((av) => {
                // updateVariants.push({
                //   id: av.variant.id,
                //   sku: av.variant.sku,
                // })

                updateSPVs.push({
                  where: { id: { _eq: av.id } },
                  _set: {
                    always_in_stock: av.always_in_stock,
                    list_weighting: av.list_weighting,
                    is_listed: av.is_listed,
                    url_slug: av.url_slug,
                    name: av.name,
                    ...(av.show_variants?.length > 0
                      ? { show_variants: JSON.stringify(av.show_variants) }
                      : { show_variants: null }),
                  },
                })
              })

              if (updateSPVs.length > 0) {
                // await dataProvider.updateMany('variants', { data: updateVariants })
                await dataProvider.updateMany('saleable_product_variants', { data: updateSPVs })
              }
              setSaving(false)
              notify('Save Complete', { type: 'info' })
            } catch (error) {
              setSaving(false)
              console.log(error)
              notify(error.message, { type: 'warning' })
            }
          }}
        >
          {saving ? (
            <CircularProgress
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          ) : (
            <span style={{ fontSize: '12px' }}>Save</span>
          )}
        </Button>
      </Box>
      <TableContainer component={Box} className={classes.table}>
        <Table className={classes.table} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="4%">Id</StyledTableCell>
              <StyledTableCell width="4%">Image</StyledTableCell>
              <StyledTableCell width="10%">Options</StyledTableCell>
              <StyledTableCell width="9%">SKU</StyledTableCell>
              <StyledTableCell width="8%">Stock</StyledTableCell>
              <StyledTableCell width="10%">Name</StyledTableCell>
              <StyledTableCell width="10%">URL Slug</StyledTableCell>
              <StyledTableCell width="5%">Always in Stock</StyledTableCell>
              <StyledTableCell width="5%">Weighting</StyledTableCell>
              <StyledTableCell width="5%">Listed</StyledTableCell>
              <StyledTableCell width="10%">Show Options</StyledTableCell>
              <StyledTableCell width="8%">Status</StyledTableCell>
              <StyledTableCell width="8%">Publish/Hide</StyledTableCell>
              <StyledTableCell width="6%">Open</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.activeVariants?.map((variant, index) => (
              <VariantRow
                key={`variant${variant.id}`}
                index={index}
                optionsValues={optionsValues}
                baseURL={baseURL}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt="30px">
        <span>Owner Inventories</span>
      </Box>
      <TableContainer component={Box} className={classes.table}>
        <Table className={classes.table} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="10%">Owner Id</StyledTableCell>
              <StyledTableCell width="65%">Organisation</StyledTableCell>
              <StyledTableCell width="25%">Quantity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.organisation_inventories?.map((inventory, index) => (
              <OwnerRow key={`inventory${inventory.id}`} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const VariantRow = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const form = useForm()
  const [saving, setSaving] = useState(false)
  const [savingImage, setSavingImage] = useState(false)
  const [open, setOpen] = useState(false)

  const { values } = useFormState()

  const { index, optionsValues, baseURL } = props

  const colourValue = values.activeVariants[index].variant?.values?.find(
    (value) => value.option_name === 'Color',
  )

  return (
    <TableRow>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        {values.activeVariants[index].id}
      </StyledTableCell>
      <StyledTableCell align="center" style={{ padding: '0px' }}>
        {values.activeVariants[index].image?.url ? (
          <img
            src={values.activeVariants[index].image.url}
            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <span
            onClick={() => setOpen(true)}
            style={{ width: '100%', height: '30px', cursor: 'pointer' }}
          >
            SELECT
          </span>
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {colourValue ? (
            <div
              style={{
                margin: '0px 10px',
                width: '20px',
                height: '20px',
                borderRadius: '3px',
                border:
                  parseInt(colourValue.value?.slice(1, 3), 16) +
                    parseInt(colourValue.value?.slice(3, 5), 16) +
                    parseInt(colourValue.value?.slice(5, 7), 16) <
                  600
                    ? `1px solid ${colourValue?.value}`
                    : '1px solid #ABABAB',
                backgroundColor: colourValue?.value,
              }}
            />
          ) : null}
          {values.activeVariants[index].variant?.values?.map((el) => el.value).join()}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ padding: '10px' }}>
        <span>{values.activeVariants[index].variant.sku}</span>
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="text"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`activeVariants[${index}].stock`}
        />
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <TextInput
          label={false}
          source={`activeVariants[${index}].name`}
          variant="standard"
          fullWidth
          className={classes.numberTableInput}
        />
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <TextInput
          label={false}
          source={`activeVariants[${index}].url_slug`}
          variant="standard"
          fullWidth
          className={classes.numberTableInput}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <Checkbox
          checked={values.activeVariants[index].always_in_stock}
          className={classes.optionsToggleCheckbox}
          onChange={() => {
            form.change(
              `activeVariants[${index}].always_in_stock`,
              !values.activeVariants[index].always_in_stock,
            )
          }}
          inputProps={{ 'aria-label': 'always in stock' }}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`activeVariants[${index}].list_weighting`}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <Checkbox
          checked={values.activeVariants[index].is_listed}
          className={classes.optionsToggleCheckbox}
          onChange={() => {
            form.change(
              `activeVariants[${index}].is_listed`,
              !values.activeVariants[index].is_listed,
            )
          }}
          inputProps={{ 'aria-label': 'is listed' }}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <SelectArrayInput
          label={false}
          source={`activeVariants[${index}].show_variants`}
          choices={optionsValues}
          variant="standard"
          defaultValue=""
          fullWidth
          optionText="value"
          optionValue="value"
          className={classes.selectTableInput}
        />
      </StyledTableCell>
      <StyledTableCell
        align="right"
        style={{
          padding: '10px',
          borderLeft: '0px',
          borderRight: '0px',
          borderTop: '0px',
          borderBottom: '1px solid lightgray',
        }}
      >
        <Chip label={values.activeVariants[index].active ? 'PUBLISHED' : 'INACTIVE'} />
      </StyledTableCell>
      <StyledTableCell align="center">
        {saving ? (
          <CircularProgress
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        ) : (
          <IconButton
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
            }}
            onClick={async () => {
              setSaving(true)
              if (typeof values.activeVariants[index].id === 'number') {
                try {
                  await dataProvider.update('saleable_product_variants', {
                    data: {
                      id: values.activeVariants[index].id,
                      active: !values.activeVariants[index].active,
                    },
                  })
                  form.change(
                    `activeVariants[${index}].active`,
                    !values.activeVariants[index].active,
                  )
                  notify('Save Complete', { type: 'info' })
                  setSaving(false)
                } catch (error) {
                  console.log(error)
                  setSaving(false)
                  notify(error.message, { type: 'warning' })
                }
                setSaving(false)
              }
            }}
          >
            <span style={{ fontSize: '12px' }}>
              {values.activeVariants[index].active ? 'Hide' : 'Publish'}
            </span>
          </IconButton>
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        {values.activeVariants[index].active && values.activeVariants[index].url_slug && (
          <a
            rel="noreferrer"
            target="_blank"
            href={`${baseURL}${values.activeVariants[index].url_slug}`}
          >
            <VisibilityIcon />
          </a>
        )}
      </StyledTableCell>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open || false}
        onClose={() => setOpen(false)}
        aria-label="imagePhotoSelection"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <span>Select primary image for this variant</span>

            <Box display="flex">
              {savingImage && (
                <CircularProgress
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              )}

              <IconButton
                onClick={() => {
                  setOpen(false)
                }}
                style={{
                  maxWidth: '30px',
                  maxHeight: '30px',
                }}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
            {values.images.map((image) => (
              <Box
                m="5px"
                p="5px"
                key={`prodImg${image.id}`}
                className={classes.imageBox}
                onClick={async () => {
                  console.log(image, index)
                  setSavingImage(true)

                  await dataProvider.update('saleable_product_variants', {
                    data: {
                      id: values.activeVariants[index].id,
                      image_file_id: image.file_id,
                    },
                  })
                  form.change(`activeVariants[${index}].image.url`, image.file.url)
                  console.log(values.activeVariants[index])
                  setSavingImage(false)
                  setOpen(false)
                }}
              >
                <img className={classes.image} src={image.file.url} alt="" />
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </TableRow>
  )
}

const OwnerRow = (props) => {
  const classes = useStyles()

  const { index } = props

  return (
    <TableRow>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <TextInput
          label={false}
          source={`organisation_inventories[${index}].owner.id`}
          disabled
          variant="standard"
          fullWidth
          className={classes.numberTableInput}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <TextInput
          label={false}
          source={`organisation_inventories[${index}].owner.name`}
          disabled
          variant="standard"
          fullWidth
          className={classes.numberTableInput}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="text"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`organisation_inventories[${index}].stock`}
        />
      </StyledTableCell>
    </TableRow>
  )
}

export default ProductStockForm
