import React from 'react'
import {
  Filter,
  List,
  SearchInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  TopToolbar,
  // EditButton,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { getDisplayName } from '../../../utils/utils'
import MemberAdd from './MemberAdd'

const ListActions = (props) => {
  const { className, clientId } = props
  return (
    <TopToolbar className={className}>
      <MemberAdd clientId={clientId} />
    </TopToolbar>
  )
}

export const MembersFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="member_name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Member Name"
      style={{ minWidth: '200px' }}
    />
  </Filter>
)

const MemberList = (props) => {
  const { clientId, ...rest } = props

  return (
    <Box p="10px 60px">
      <div>Members List</div>
      <List
        {...rest}
        resource="organisation_members"
        filters={<MembersFilter />}
        filter={{ clientId }}
        perPage={25}
        actions={<ListActions clientId={clientId} />}
        empty={false}
        // bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid optimized path="clients">
          <TextField source="id" />
          <FunctionField label="Name" render={(record) => getDisplayName(record.member)} />
          <TextField source="role" label="Role" />
          <DateField source="created_at" label="Join date" type="date" />
        </Datagrid>
      </List>
    </Box>
  )
}

export default MemberList
