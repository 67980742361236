import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  outerBox: {
    padding: '40px',
    backgroundColor: 'white',
  },
  title: {
    position: 'relative',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '40px',
  },
  heading: {
    fontSize: '22px',
    fontWeight: '600',
  },
  sectionHeader: {
    marginBottom: '20px',
  },
  primaryPhoto: {
    border: 'dashed gray 2px',
    width: '400px',
    height: '400px',
    position: 'absolute',
    zIndex: '-1',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '400px',
    margin: '10px 0px 5px',
  },
  previewImageBox: {
    margin: '2px',
    width: '85px',
    height: '85px',
    minWidth: '85px',
    minHeight: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
    position: 'relative',
  },
  previewImage: {
    '&:hover': {
      border: 'gray solid 2px',
    },
    zIndex: '100',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    cursor: 'pointer',
  },
  deleteBtn: {
    position: 'absolute',
    top: '0px',
    left: '45px',
    zIndex: '100',
  },
  previewImageSelected: {
    border: 'black solid 5px',
  },
  imageDropZone: {
    width: '400px',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'dashed gray 2px',
    cursor: 'pointer',
  },
  imageDropRoot: {
    width: '400px',
    height: '400px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
  imageDropNone: {
    display: 'none',
  },
  additionalDropZone: {
    width: '400px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    margin: '10px 0px',
    cursor: 'pointer',
  },
  // inputFieldTable: {
  //   '& .MuiFormLabel-root': {
  //     width: '80px',
  //   },
  // },
  labelText: {
    marginBottom: '10px',
  },
  tableInput: {
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  fieldInputLeft: {
    marginRight: '50px',
    minWidth: '140px',
    width: '140px',
  },
  fieldInput: {
    margin: '0px 50px',
    // minWidth: '200px',
  },
  fieldInputNoMP: {
    margin: '0px',
    padding: '0px',
    // minWidth: '200px',
  },
  fieldInputWide: {
    marginRight: '50px',
    minWidth: '200px',
  },
  textField: {
    marginRight: '50px',
    minWidth: '200px',
  },
  numberInput: {
    // border: '0px',
    width: '25%',
    // backgroundColor: 'none',
    margin: '0px 50px',
    // '& .MuiFilledInput-root': {
    //   backgroundColor: 'white',
    // },
    // '& .MuiFormLabel-root': {
    //   zIndex: 1,
    // },
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    padding: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  textInputFull: {
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  // numberInputOuter: {
  //   width: '100%',
  //   height: '100%',
  //   padding: '0px',
  // },
  subHeader: {
    fontSize: '12px',
    marginBottom: '10px',
  },
  productHeader: {
    fontSize: '34px',
  },
  quoteButton: {
    '&:hover': {
      backgroundColor: '#21C6CF',
    },

    margin: '16px 16px 0px 0px',
    fontSize: '0.9375rem',
    backgroundColor: '#00B3C3',
    borderRadius: '4px',
    color: '#fff',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    minWidth: '120px',
    position: 'relative',
    padding: '8px 22px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '5px',
  },
  relatedProductsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    // width: '400px',
    margin: '10px 0px 5px',
    position: 'relative',
  },
  baseProductLabel: {
    position: 'absolute',
    fontSize: '10px',
    top: '15px',
    right: '0px',
    color: 'red',
    transform: 'rotate3d(1,1,1,45deg)',
  },
  relatedProductsImageBox: {
    width: '150px',
    minWidth: '150px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  relatedProductsImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    cursor: 'pointer',
  },
}))
