import React from 'react'
import { TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column',
    // height: '36px',
    // marginTop: '3px',
    // '& .MuiOutlinedInput-root': {
    //   height: '36px',
    // },
  },
  label: {
    width: 'auto',
    position: 'relative',
    justifyContent: 'flex-start',
    fontSize: '0.65rem',
  },
}))

const OutlinedTextInput = ({ variant = 'outlined', column = false, ...props }) => {
  const classes = useStyles()
  let columnLabel = ''
  let columnRoot = ''
  if (column) {
    columnLabel = classes.label
    columnRoot = classes.root
  }
  return (
    <>
      <span className={classes.label}>{props.label}</span>
      <TextInput
        variant={variant}
        className={columnRoot}
        style={{ height: '50px', marginTop: '6px' }}
        InputLabelProps={{
          className: columnLabel,
        }}
        {...props}
        label=""
      />
    </>
  )
}

OutlinedTextInput.defaultProps = TextInput.defaultProps

export default OutlinedTextInput
