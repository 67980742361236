import React from 'react'
import { required, Button, SaveButton, TextInput } from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  areaTextSelect: { padding: 0 },
  openDialogButton: {
    margin: '5px',
    fontSize: '12px',
    border: ' gray solid 1px',
    borderRadius: '4px',
    color: 'black',
  },
})

import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@material-ui/core'
import SelectInput from '../../../components/input/SelectInput'
// import useStyles from './styles'

function SupplierSelectWithCreateForm({
  handleClick,
  handleCloseClick,
  showDialog,
  addressTypes,
  handleSubmitWithRedirect,
  loading,
  saving,
  pristine,
}) {
  // const { values } = useFormState()
  const classes = useStyles()

  // console.log(values)

  return (
    <>
      <Button className={classes.openDialogButton} onClick={handleClick} label="ADD NEW">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create Supplier">
        <DialogTitle>Create Supplier</DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box flex={2}>
              <TextInput
                variant="outlined"
                source="supplier_name"
                validate={required()}
                fullWidth
              />
              <TextInput
                variant="outlined"
                source="supplier_businessLicenceNumber"
                validate={required()}
                fullWidth
              />
            </Box>
            <Box flex={1} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="end" mt="10px">
            <SelectInput
              label="Address Type:"
              source="supplier_address_type"
              choices={addressTypes}
              optionText="name"
              optionValue="value"
              defaultValue=""
              fullWidth
              validate={required()}
              className={classes.areaTextSelect}
            />
            <TextInput
              variant="outlined"
              fullWidth
              className={classes.areaTextSelect}
              InputProps={{
                maxLength: 300,
                style: {
                  paddingBottom: 0,
                  alignItems: 'baseline',
                },
              }}
              validate={required()}
              source="supplier_local_full_address"
              label="Chinese Address:"
            />
          </Box>
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaTextSelect}
            InputProps={{
              maxLength: 300,
              style: {
                paddingBottom: 0,
                alignItems: 'baseline',
              },
            }}
            validate={required()}
            source="supplier_local_name"
            label="Contact Name (Pinyin):"
          />
          <TextInput
            variant="outlined"
            fullWidth
            className={classes.areaTextSelect}
            InputProps={{
              maxLength: 300,
              style: {
                paddingBottom: 0,
                alignItems: 'baseline',
              },
            }}
            validate={required()}
            source="supplier_mobile"
            label="Contact Mobile:"
          />
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
            <IconCancel />
          </Button>
          <SaveButton
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            pristine={pristine}
            saving={saving}
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SupplierSelectWithCreateForm
