import React, { useState } from 'react'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  withStyles,
  IconButton,
  // Card,
  CircularProgress,
} from '@material-ui/core'
import { required, useNotify, useDataProvider, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import TextInput from '../../components/OutlinedTextInput'
import NumberFormat from './CustomNumberFormat'
// import BooleanFieldInput from './fieldInputs/BooleanFieldInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
}))
const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ProductSizesTable = ({ packagingTypes = [] }) => {
  const form = useForm()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const [sizeEditing, setSizeEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [sizeSaving, setSizeSaving] = useState(false)
  const { values } = useFormState()

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Product Sizes</span>
        {sizeEditing ? (
          <div>
            <IconButton
              onClick={async () => {
                // console.log('saving')
                setSizeSaving(true)

                try {
                  await Promise.all(
                    values.productSizes?.map(async (size) => {
                      const sizeParams = {
                        id: size.id,
                        name: size.name || null,
                        sequence: size.sequence || 0,
                        short_name: size.short_name || null,
                        moq: size.moq || 0,
                        cost_modifier: Math.round(size.costModifier * 100) || 0,
                      }
                      const dimParams = {
                        id: size.dimensions_id || size.dimensions.id,
                        ...(size.productDimensions?.length && {
                          length: Math.round(size.productDimensions?.length * 100),
                        }),
                        ...(size.productDimensions?.width && {
                          width: Math.round(size.productDimensions?.width * 100),
                        }),
                        ...(size.productDimensions?.height && {
                          height: Math.round(size.productDimensions?.height * 100),
                        }),
                        ...(size.productDimensions?.gross_weight && {
                          gross_weight: Math.round(size.productDimensions?.gross_weight * 100),
                        }),
                      }
                      await dataProvider.update('product_quote_product_sizes', {
                        data: sizeParams,
                      })
                      delete sizeParams.id
                      delete sizeParams.cost_modifier
                      delete sizeParams.moq

                      size.scmQuoteSizes?.length > 0
                        ? await dataProvider.update('scm_quote_product_sizes', {
                            data: sizeParams,
                            where: { id: { _in: size.scmQuoteSizes } },
                          })
                        : null

                      await dataProvider.update('dimension', { data: dimParams })
                    }),
                  )

                  const newPkgOpts = values.packagingOptions?.map((opt) => ({
                    ...opt,
                    sizes: values.productSizes?.map((sz) => ({
                      ...sz,
                      relId:
                        opt.product_quote_product_size_packagings?.find(
                          (el) => el.product_quote_product_size_id === sz.id,
                        )?.id || null,
                      active: opt.product_quote_product_size_packagings?.some(
                        (el) => el.product_quote_product_size_id === sz.id,
                      ),
                      costModifier: sz.cost_modifier / 100,
                    })),
                  }))

                  form.change('packagingOptions', newPkgOpts)
                  setSizeSaving(false)
                  setSizeEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setSizeSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setSizeEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {sizeSaving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setSizeEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      {/* <span style={{ fontSize: '10px' }}>
          What are the product sizes that the factory can provide? There must be at least one size.
          Enter a size name and short name if there are more than one size, OR if there is only one
          size but you want it to show (e.g. a hat is OSFA, and you want to make sure the client can
          see this.)
        </span> */}
      <Box mt="20px">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '15%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Short Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Sequence
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  MOQ
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Size Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '10%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#CBDDEE',
                  }}
                  align="left"
                >
                  Length
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '10%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#CBDDEE',
                  }}
                  align="left"
                >
                  Width
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '10%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#CBDDEE',
                  }}
                  align="left"
                >
                  Height
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '10%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#CBDDEE',
                  }}
                  align="left"
                >
                  Weight
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.productSizes?.map((item, index) => (
                <SizeRow
                  disabled={!sizeEditing}
                  key={`price${item.id}`}
                  index={index}
                  id={item.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {sizeEditing ? (
          <IconButton
            aria-label="add-price"
            // className={classes.infoBtn}
            onClick={async () => {
              const newSizeResp = await dataProvider.create('product_quote_product_sizes', {
                data: {
                  product_quote_id: values.product_quotes[0].id,
                  dimensions: { data: {} },
                },
              })
              // console.log(newSizeResp)
              const newSizeOptions = values.productSizes ? [...values.productSizes] : []
              newSizeOptions.push({
                id: newSizeResp.data.id,
                dimensions_id: newSizeResp.data.dimensions_id,
              })
              // console.log(values.productSizes, newSizeOptions)
              await form.change('productSizes', newSizeOptions)

              // console.log('Sizeses', values.productSizes)

              // const newPkgOpts = [...values.packagingOptions]
              const newPkgOpts = values.packagingOptions?.map((opt) => ({
                ...opt,
                sizes: [...opt.sizes, { id: newSizeResp.data.id, active: false, relId: null }],
              }))

              form.change('packagingOptions', newPkgOpts)
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
      {/* <BooleanFieldInput
        field="allow_order_splitting_by_size"
        source="product_quotes[0].allow_order_splitting_by_size"
        resource="product_quote"
        id={values.product_quotes[0].id}
        label="Factory allows ordering by sizes."
      /> */}
      <div style={{ margin: '20px 80px', height: '5px', backgroundColor: '#EFEFEF' }} />
      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Packaging and Carton Information</span>
        {editing ? (
          <div>
            <IconButton
              onClick={async () => {
                // console.log('saving')
                setSaving(true)

                try {
                  await Promise.all(
                    values.packagingOptions?.map(async (pkgOpt) => {
                      // console.log(pkgOpt)

                      // const oldSizes =
                      //   values.product_quotes[0].packaging_options?.find(
                      //     (el) => el.id === pkgOpt.id,
                      //   )?.product_quote_product_size_packagings || []

                      // console.log(oldSizes)

                      // oldSizes = [relId, spId]
                      // newSizes = [relId, active=true]
                      const newActiveSizes = pkgOpt.sizes?.filter(
                        (size) => size.active && !size.relId,
                      )

                      await Promise.all(
                        newActiveSizes?.map(async (el) => {
                          await dataProvider.create('product_quote_product_size_packagings', {
                            data: {
                              product_quote_packaging_option_id: pkgOpt.id,
                              product_quote_product_size_id: el.id,
                            },
                          })
                        }),
                      )

                      const delSizes = pkgOpt.sizes?.filter(
                        (oldSize) => !oldSize.active && typeof oldSize.relId === 'number',
                      )
                      // console.log(delSizes)
                      await Promise.all(
                        delSizes?.map(async (el) => {
                          await dataProvider.delete('product_quote_product_size_packagings', {
                            data: { id: el.relId },
                          })
                        }),
                      )

                      const pkgOptParams = {
                        id: pkgOpt.id,
                        type: pkgOpt.type,
                        label: pkgOpt.label || null,
                        carton_type: pkgOpt.carton_type,
                        carton_label: pkgOpt.carton_label || null,
                        moq: pkgOpt.moq || 0,
                        pieces_per_carton: pkgOpt.pieces_per_carton || null,
                        cost_modifier: Math.round(pkgOpt.costModifier * 100) || 0,
                      }
                      const cartonDimParams = {
                        id: pkgOpt.carton_dimensions_id || pkgOpt.cartonDimensions.id,
                        ...(pkgOpt.cartonDimensions?.length && {
                          length: Math.round(pkgOpt.cartonDimensions.length * 100) || 0,
                        }),
                        ...(pkgOpt.cartonDimensions?.width && {
                          width: Math.round(pkgOpt.cartonDimensions.width * 100) || 0,
                        }),
                        ...(pkgOpt.cartonDimensions?.height && {
                          height: Math.round(pkgOpt.cartonDimensions.height * 100) || 0,
                        }),
                        ...(pkgOpt.cartonDimensions?.gross_weight && {
                          gross_weight: Math.round(pkgOpt.cartonDimensions.gross_weight * 100) || 0,
                        }),
                      }
                      const pkgDimParams = {
                        id: pkgOpt.packaging_dimensions_id || pkgOpt.packagingDimensions.id,
                        ...(pkgOpt.packagingDimensions?.length && {
                          length: Math.round(pkgOpt.packagingDimensions.length * 100) || 0,
                        }),
                        ...(pkgOpt.packagingDimensions?.width && {
                          width: Math.round(pkgOpt.packagingDimensions.width * 100) || 0,
                        }),
                        ...(pkgOpt.packagingDimensions?.height && {
                          height: Math.round(pkgOpt.packagingDimensions.height * 100) || 0,
                        }),
                        ...(pkgOpt.packagingDimensions?.gross_weight && {
                          gross_weight:
                            Math.round(pkgOpt.packagingDimensions.gross_weight * 100) || 0,
                        }),
                      }
                      await dataProvider.update('product_quote_packaging_options', {
                        data: pkgOptParams,
                      })

                      cartonDimParams?.id &&
                        (cartonDimParams.length ||
                          cartonDimParams.width ||
                          cartonDimParams.height ||
                          cartonDimParams.gross_weight) &&
                        (await dataProvider.update('dimension', { data: cartonDimParams }))
                      pkgDimParams?.id &&
                        (pkgDimParams.length ||
                          pkgDimParams.width ||
                          pkgDimParams.height ||
                          pkgDimParams.gross_weight) &&
                        (await dataProvider.update('dimension', { data: pkgDimParams }))

                      delete pkgOptParams.moq
                      delete pkgOptParams.cost_modifier
                      delete pkgOptParams.id

                      pkgOpt.scmQuotePkgs?.length > 0
                        ? await dataProvider.update('scm_quote_packaging_options', {
                            data: pkgOptParams,
                            where: { id: { _in: pkgOpt.scmQuotePkgs } },
                          })
                        : null
                    }),
                  )

                  notify('Update Complete', { type: 'info' })
                  setSaving(false)
                  setEditing(false)
                } catch (error) {
                  setSaving(false)
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        {/* <span style={{ fontSize: '10px' }}>
            What packaging types can the factory provide? There must be at least one, so if none
            were quoted by the factory, add a packaging type of None. Carton dimensions, pieces per
            carton are required. Ensure that one size option is checked.
          </span> */}
        {values.packagingOptions?.map((pkgOpt, ind) => (
          <Box key={pkgOpt.id} display="flex" flexDirection="column">
            <span className={classes.sectionHeader}>Option {ind + 1}</span>
            <PackagingCartonOption
              key={`pkg${pkgOpt.id}`}
              index={ind}
              id={pkgOpt.id}
              editing={editing}
              packagingTypes={packagingTypes}
              pkgOpt={pkgOpt}
            />
          </Box>
        ))}
        {editing ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              aria-label="add-pkg"
              style={{
                width: '260px',
                fontSize: '12px',
                display: 'flex',
                border: 'solid 1px #232323',
                borderRadius: '4px',
              }}
              // className={classes.infoBtn}
              onClick={async () => {
                const newPkgOptResp = await dataProvider.create('product_quote_packaging_options', {
                  data: {
                    product_quote_id: values.product_quotes[0].id,
                    packaging_dimensions: { data: {} },
                    carton_dimensions: { data: {} },
                  },
                })
                // console.log(newPkgOptResp)

                const newPkgOpts = values.packagingOptions ? [...values.packagingOptions] : []

                newPkgOpts.push({
                  id: newPkgOptResp.data.id,
                  packaging_dimensions_id: newPkgOptResp.data.packaging_dimensions_id,
                  carton_dimensions_id: newPkgOptResp.data.carton_dimensions_id,
                  sizes: values.productSizes?.map((size) => ({
                    ...size,
                    active: false,
                    relId: null,
                  })),
                })

                form.change('packagingOptions', newPkgOpts)
              }}
            >
              <AddIcon />
              <span>ADD A NEW PACKAGING OPTION</span>
            </Button>
          </Box>
        ) : null}
      </Box>

      {/* <span style={{ fontSize: '12px' }}>
          For the calculator to work, enter carton dimensions, pieces per carton, freight rates and
          optionally a duty rate.
        </span> */}
    </Box>
  )
}

const SizeRow = ({ index, disabled }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()

  return (
    <TableRow
      key={`sizeRow${index}`}
      style={index % 2 === 0 ? { backgroundColor: '#FFF' } : { backgroundColor: '#F3F3F3' }}
    >
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productSizes[${index}].name`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productSizes[${index}].short_name`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ padding: '0px', margin: '0px', height: '100%' }}
          source={`productSizes[${index}].sequence`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productSizes[${index}].moq`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productSizes[${index}].costModifier`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productSizes[${index}].productDimensions.length`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productSizes[${index}].productDimensions.width`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productSizes[${index}].productDimensions.height`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={disabled}
          source={`productSizes[${index}].productDimensions.gross_weight`}
          // validate={required()}
        />
      </BodyTableCell>

      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <IconButton
          aria-label="info"
          className={classes.infoBtn}
          onClick={async () => {
            // console.log(values.productSizes[index].id)

            try {
              await dataProvider.delete('product_quote_product_sizes', {
                data: { id: values.productSizes[index].id },
              })
              const newSizesOptions = [...values.productSizes]
              newSizesOptions.splice(index, 1)
              // console.log('productSizes', newSizesOptions, values.productSizes)
              // console.log('changing')
              form.change('productSizes', newSizesOptions)
            } catch (error) {
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

const PackagingCartonOption = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { values } = useFormState()
  const form = useForm()
  const { editing, index, id, packagingTypes, pkgOpt } = props

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ width: '20%', fontSize: '10px', padding: '10px' }}
                align="left"
              >
                Packaging
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '20%', fontSize: '10px', padding: '10px' }}
                align="left"
              >
                Label
                {/* here */}
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                align="left"
              >
                MOQ
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '10%', fontSize: '10px', padding: '10px' }}
                align="left"
              >
                Cost
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                }}
                align="left"
              >
                Length
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                }}
                align="left"
              >
                Width
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                }}
                align="left"
              >
                Height
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                }}
                align="left"
              >
                Weight
              </StyledTableCell>
              <StyledTableCell
                style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                align="left"
              >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={`pkgOptRowPkg${index}`} style={{ backgroundColor: '#EEE' }}>
              <BodyTableCell align="right">
                <SelectInput
                  variant="standard"
                  fullWidth
                  style={{ padding: '0px', margin: '0px', height: '100%' }}
                  label="Type"
                  source={`packagingOptions[${index}].type`}
                  choices={packagingTypes}
                  optionValue="name"
                  disabled={!editing}
                  // className={classes.areaText}
                />
              </BodyTableCell>
              <BodyTableCell align="left">
                <TextInput
                  source={`packagingOptions[${index}].label`}
                  style={{ padding: '0px', margin: '0px', height: '100%' }}
                  label={false}
                  // fullWidth
                  variant="standard"
                  margin="none"
                  disabled={!editing}
                  // className={`${classes.fieldInputNoMP}`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  // className={classes.numberTableInput}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  disabled={!editing}
                  decimalScale={0}
                  fixedDecimalScale
                  isNumericString
                  label={false}
                  source={`packagingOptions[${index}].moq`}
                  validate={required()}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  label={false}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled={!editing}
                  source={`packagingOptions[${index}].costModifier`}
                  validate={required()}
                  prefix="$ "
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].packagingDimensions.length`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].packagingDimensions.width`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].packagingDimensions.height`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].packagingDimensions.gross_weight`}
                />
              </BodyTableCell>

              <BodyTableCell
                align="right"
                style={{
                  padding: '0px',
                  backgroundColor: '#FFF',
                }}
                rowSpan={3}
              >
                {editing ? (
                  <IconButton
                    aria-label="info"
                    className={classes.infoBtn}
                    onClick={async () => {
                      const newPackagingOptions = [...values.packagingOptions]
                      newPackagingOptions.splice(index, 1)
                      // console.log('pkgopts', newPackagingOptions)
                      if (typeof id === 'number') {
                        await dataProvider.delete('product_quote_packaging_options', {
                          data: { id },
                        })
                        notify('Deleted', { type: 'info' })
                      }
                      // console.log('changing')
                      form.change('packagingOptions', newPackagingOptions)
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                ) : null}
              </BodyTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell
                style={{
                  width: '20%',
                  fontSize: '10px',
                  padding: '10px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Shipping Pkg
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '20%',
                  fontSize: '10px',
                  padding: '10px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Label
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              />
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Pcs Per
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Length
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Width
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Height
              </StyledTableCell>
              <StyledTableCell
                style={{
                  width: '10%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: '#CBDDEE',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                align="left"
              >
                Weight
              </StyledTableCell>
            </TableRow>

            <TableRow key={`pkgOptCrtRow${index}`} style={{ backgroundColor: '#EEE' }}>
              <BodyTableCell align="right">
                <SelectInput
                  variant="standard"
                  fullWidth
                  style={{ padding: '0px', margin: '0px', height: '100%' }}
                  label="Type"
                  source={`packagingOptions[${index}].carton_type`}
                  choices={packagingTypes}
                  optionValue="name"
                  disabled={!editing}
                  // className={classes.areaText}
                />
              </BodyTableCell>
              <BodyTableCell align="left">
                <TextInput
                  source={`packagingOptions[${index}].carton_label`}
                  style={{ padding: '0px', margin: '0px', height: '100%' }}
                  label={false}
                  // fullWidth
                  variant="standard"
                  margin="none"
                  disabled={!editing}
                  // className={`${classes.fieldInputNoMP}`}
                />
              </BodyTableCell>
              <BodyTableCell align="right"></BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  // className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  disabled={!editing}
                  className={classes.numberTableInput}
                  decimalScale={0}
                  fixedDecimalScale
                  isNumericString
                  label={false}
                  source={`packagingOptions[${index}].pieces_per_carton`}
                  validate={required()}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].cartonDimensions.length`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].cartonDimensions.width`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].cartonDimensions.height`}
                />
              </BodyTableCell>
              <BodyTableCell align="right">
                <NumberFormat
                  disabled={!editing}
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  source={`packagingOptions[${index}].cartonDimensions.gross_weight`}
                />
              </BodyTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {pkgOpt?.sizes?.length > 0 && values.productSizes?.length > 0 && (
        <Box p="10px">
          <span style={{ fontSize: '10px' }}>Size Options</span>
          <Box display="flex">
            {values?.productSizes?.map((size) => {
              // console.log('SFD', scopedFormData)
              // console.log(size, pkgOpt)
              const pkgOptSize = pkgOpt.sizes?.find((el) => el.id === size.id)
              return (
                <Box
                  key={`${size.id}size`}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p="5px"
                >
                  <span style={{ fontSize: '10px' }}>{size.name || 'Size 1'}</span>
                  <Checkbox
                    disabled={!editing}
                    checked={pkgOptSize?.active}
                    onChange={() => {
                      // console.log('changing', values.packagingOptions)
                      const index = values.packagingOptions?.findIndex((el) => el.id === pkgOpt?.id)
                      const pkgOptSizeIndex = pkgOpt?.sizes?.findIndex((el) => el.id === size.id)
                      form.change(
                        `packagingOptions[${index}].sizes[${pkgOptSizeIndex}].active`,
                        !pkgOptSize?.active,
                      )
                    }}
                    inputProps={{ 'aria-label': 'option selected checkbox' }}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ProductSizesTable
