import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { useDataProvider, useNotify, TextInput, NumberInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import RichTextInput from 'ra-input-rich-text'
import { sortBy } from 'lodash'
// import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import AddIconOutlined from '@material-ui/icons/AddCircleOutlineOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { ColorInput } from '../../components/ColourPicker'
import SinglePhotoFieldInput from '../../components/fieldInputs/SinglePhotoFieldInput'

const useStyles = makeStyles(() => ({
  editButton: {
    border: 'solid 1px lightblue',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  imageThumb: {
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '5px',
  },
  table: { marginRight: '50px' },
  imageBox: {
    width: '152px',
    height: '152px',
    border: 'solid 2px white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  imageBoxSelected: {
    width: '152px',
    height: '152px',
    border: 'solid 2px black',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
  imageFaded: {
    maxWidth: '150px',
    maxHeight: '150px',
    opacity: '50%',
  },
  addIcon: {
    fontSize: '20px',
  },
  templatePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  templatePreviewSmall: {
    maxWidth: '350px',
    maxHeight: '350px',
    border: 'solid 1px #DEDEDE',
    borderRadius: '5px',
    padding: '2px',
  },
  primaryPhotoZoomContainer: {
    border: 'solid gray 0.5vw',
    borderRadius: '1vw',
    maxWidth: '65vw',
    // maxHeight: '50vh',
    position: 'absolute',
    backgroundColor: 'white',
    top: '0px',
    left: '0px',
    // minWidth: '50vw',
    // minHeight: '50vh',
    zIndex: '200',
  },
  primaryPhotoZoom: {
    maxWidth: '64vw',
  },
  zoomBtn: {
    // position: 'absolute',
    // top: '1px',
    // left: '1px',
    // zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  rtfInputBox: {
    '& .ra-rich-text-input': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '220px',
      '& .MuiFormLabel-root': {
        fontSize: '12px',
      },
    },
  },
}))
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const ProductBannersEdit = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [showAddTemplate, setShowAddTemplate] = useState(false)
  const [editBannersContentOpen, setEditBannersContentOpen] = useState(false)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      !abortController.signal.aborted && setLoading(true)
      try {
        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: ['saleable_product_banner_templates'],
        })
        // console.log(configTemp)
        const newTemplates = configTemp.global_preferences[0].setting_json

        !abortController.signal.aborted && setTemplates(newTemplates)
        !abortController.signal.aborted && setLoading(false)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [editBannersContentOpen])

  // console.log(values.banners)

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.editButton} onClick={() => setEditBannersContentOpen(true)}>
          Edit
        </Button>
      </Box>

      <Box display="flex" flexDirection="column">
        {values.banners?.map((banner) => (
          <Box
            key={`banner${banner?.id}`}
            display="flex"
            mt="10px"
            p="5px !important"
            alignItems="center"
            border="solid 1px #DEDEDE"
          >
            <span style={{ fontSize: '10px', margin: '0px 5px' }}>{banner?.sequence}</span>
            <span style={{ fontSize: '10px', margin: '0px 5px 0px 0px' }}>
              Template {banner?.template_id}
            </span>
            <img src={banner.image?.url} alt="" className={classes.imageThumb} />
          </Box>
        ))}
        <Dialog
          fullWidth
          maxWidth="xl"
          open={editBannersContentOpen || false}
          onClose={() => setEditBannersContentOpen(false)}
          aria-label="productBannersEdit"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <span>Edit Product Banners</span>

              <Box display="flex" position="relative">
                {(loading || saving) && (
                  <Box style={{ width: '100%', height: '100%' }}>
                    <CircularProgress
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </Box>
                )}
                <IconButton
                  disabled={loading || saving}
                  onClick={async () => {
                    setSaving(true)

                    try {
                      // console.log(values.banners)

                      const params = values.banners.map((el) => ({
                        where: { id: { _eq: el.id } },
                        _set: {
                          ...(el.title && { title: el.title }),
                          ...(el.content_1 && { content_1: el.content_1 }),
                          ...(el.content_2 && { content_2: el.content_2 }),
                          ...(el.background_colour && { background_colour: el.background_colour }),
                          ...(el.sequence && { sequence: el.sequence }),
                          ...(el.active && { active: el.active }),
                        },
                      }))

                      // console.log(params)

                      if (params?.length > 0) {
                        await dataProvider.updateMany('saleable_product_banners', {
                          data: params,
                          on_conflict: {
                            constraint: '^saleable_product_banners_pkey^',
                            update_columns: [
                              '^title^',
                              '^content_1^',
                              '^content_2^',
                              '^background_colour^',
                              '^sequence^',
                              '^active^',
                            ],
                          },
                        })
                        notify('Save Complete', { type: 'info' })
                      }

                      const vals = [...values.banners]
                      const sortedBanners = sortBy(vals, 'sequence', 'asc')
                      console.log('sorted', sortedBanners)
                      form.change('banners', sortedBanners)
                      setSaving(false)
                      // setEditBannersContentOpen(false)
                    } catch (error) {
                      console.log(error)
                      setSaving(false)
                      setEditBannersContentOpen(false)
                      notify(error.message, { type: 'warning' })
                    }
                  }}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                >
                  <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
                </IconButton>
                <IconButton
                  onClick={async () => {
                    setShowAddTemplate(!showAddTemplate)
                  }}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                >
                  <AddIconOutlined className={classes.addIcon} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setEditBannersContentOpen(false)
                  }}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                >
                  <CancelOutlined fontSize="small" />
                </IconButton>
                {showAddTemplate && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      backgroundColor: '#FFF',
                      border: 'solid 2px #DEDEDE',
                      borderRadius: '6px',
                      zIndex: '100',
                      width: 'auto',
                    }}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        onClick={() => {
                          setShowAddTemplate(false)
                        }}
                        style={{
                          maxWidth: '30px',
                          maxHeight: '30px',
                        }}
                      >
                        <CancelOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                    {templates.map((template) => (
                      <Button
                        key={`template${template.templateId}`}
                        onClick={async () => {
                          const resp = await dataProvider.create('saleable_product_banners', {
                            data: {
                              saleable_product_id: values.id,
                              template_id: template.templateId,
                            },
                          })
                          // console.log(resp.data)

                          const newBanners = [
                            ...values.banners,
                            { id: resp.data.id, template_id: template.templateId, active: false },
                          ]

                          form.change('banners', newBanners)
                          setShowAddTemplate(false)
                        }}
                      >
                        <Box display="flex" mt="5px">
                          <img
                            src={template.referenceImageURL}
                            className={classes.templatePreviewSmall}
                            alt=""
                          />
                          <span style={{ fontSize: '10px' }}>Template {template.templateId}</span>
                        </Box>
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent style={{ minHeight: '50vh' }}>
            <TableContainer component={Box} className={classes.table}>
              <Table className={classes.table} aria-label="shipping table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="14%">Template</StyledTableCell>
                    <StyledTableCell width="10%">Title</StyledTableCell>
                    <StyledTableCell width="10%">Image</StyledTableCell>
                    <StyledTableCell width="23%">Content 1</StyledTableCell>
                    <StyledTableCell width="23%">Content 2</StyledTableCell>
                    <StyledTableCell width="8%">Background Colour</StyledTableCell>
                    <StyledTableCell width="4%">Sequence</StyledTableCell>
                    <StyledTableCell width="4%">Active</StyledTableCell>
                    <StyledTableCell width="4%">Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.banners?.map((banner, index) => (
                    <BannerTemplate
                      key={`banner${banner.id}`}
                      banner={banner}
                      index={index}
                      templates={templates}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  )
}

export default ProductBannersEdit

const BannerTemplate = (props) => {
  const dataProvider = useDataProvider()
  const form = useForm()
  const classes = useStyles()
  const { values } = useFormState()
  const [zoomOpen, setZoomOpen] = useState(false)
  const { banner, index, templates = [] } = props

  const activeTemplate = templates.find((el) => el.templateId === banner.template_id)
  return (
    <TableRow>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box
          display="flex"
          position="relative"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-start">
            <span>Template {banner.template_id}</span>
            <IconButton
              aria-label="zoomin"
              className={classes.zoomBtn}
              onClick={async () => {
                setZoomOpen(activeTemplate?.referenceImageURL)
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Box>
          <img src={activeTemplate?.referenceImageURL} alt="" className={classes.templatePreview} />
          {zoomOpen ? (
            <Box className={classes.primaryPhotoZoomContainer}>
              <img
                src={zoomOpen}
                className={classes.primaryPhotoZoom}
                onClick={async () => {
                  setZoomOpen(null)
                }}
              />
            </Box>
          ) : null}
        </Box>
      </StyledTableCell>

      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box>
          {typeof activeTemplate?.title === 'string' ? (
            <TextInput
              source={`banners[${index}].title`}
              label={false}
              variant="standard"
              multiline
            />
          ) : (
            'N/A'
          )}
        </Box>
      </StyledTableCell>

      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box>
          {typeof activeTemplate?.image === 'string' ? (
            <SinglePhotoFieldInput
              referenceField="id"
              referenceValue={banner.id}
              parentField="saleable_product_id"
              parentId={values.id}
              resource="saleable_product_banners"
              source={`banners[${index}].image_id`}
              fileReference="image"
              field={`banners[${index}].image.url`}
              // disabled={disabled}
              onConflict={{
                constraint: 'saleable_product_banners_pkey',
                update_columns: 'image_id',
              }}
            />
          ) : (
            'N/A'
          )}
        </Box>
      </StyledTableCell>

      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box>
          {typeof activeTemplate?.content1 === 'string' ? (
            <Box display="flex" className={classes.rtfInputBox}>
              <RichTextInput
                source={`banners[${index}].content_1`}
                // disabled={!editing}
                disabled
                label={false}
                className={classes.rtfInputBox}
              />
            </Box>
          ) : (
            'N/A'
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box>
          {typeof activeTemplate?.content2 === 'string' ? (
            <Box display="flex" className={classes.rtfInputBox}>
              <RichTextInput
                source={`banners[${index}].content_2`}
                // disabled={!editing}
                disabled
                label={false}
                className={classes.rtfInputBox}
              />
            </Box>
          ) : (
            'N/A'
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Box>
          {typeof activeTemplate?.backgroundColour === 'string' ? (
            <ColorInput
              source={`banners[${index}].background_colour`}
              // className={`${classes.hideLabel} ${classes.colourPickerItem}`}
              picker="Sketch"
              label={false}
              // validate={required()}
            />
          ) : (
            'N/A'
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <NumberInput source={`banners[${index}].sequence`} label={false} variant="standard" />
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <Checkbox
          checked={values.banners[index]?.active || false}
          className={classes.optionsToggleCheckbox}
          onChange={() => {
            form.change(`banners[${index}].active`, !values.banners[index].active)
          }}
          inputProps={{ 'aria-label': 'always in stock' }}
        />
      </StyledTableCell>
      <StyledTableCell align="right" style={{ padding: '10px' }}>
        <IconButton
          aria-label="deleteBanner"
          className={classes.zoomBtn}
          onClick={async () => {
            await dataProvider.delete('saleable_product_banners', {
              data: { id: values.banners[index].id },
            })

            const newBans = [...values.banners]
            newBans.splice(index, 1)

            form.change('banners', newBans)
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  )
}
