import React from 'react'
import { SelectInput, TextInput, ReferenceInput, required } from 'react-admin'
import { useFormState } from 'react-final-form'
import { Button, DialogActions, DialogContent } from '@material-ui/core'
import Confirm from '../../components/Confirm'

const AddressForm = (props) => {
  const { handleSubmit, setOpen, formProps } = props

  const { values } = useFormState()

  // console.log(values)

  return (
    <div>
      <DialogContent>
        <ReferenceInput
          label="Type"
          source="type"
          reference="addressType"
          sort={{ field: 'name', order: 'ASC' }}
          validate={[required()]}
        >
          <SelectInput fullWidth variant="standard" optionText="name" optionValue="name" />
        </ReferenceInput>
        <TextInput
          validate={[required()]}
          variant="standard"
          fullWidth
          source="delivery_contact"
          label="Contact Name"
        />
        <TextInput
          validate={[required()]}
          variant="standard"
          fullWidth
          source="delivery_email"
          label="Contact Email"
        />
        <TextInput
          validate={[required()]}
          variant="standard"
          fullWidth
          source="delivery_mobile"
          label="Contact Mobile"
        />
        <TextInput
          validate={[required()]}
          variant="standard"
          fullWidth
          source="address_line1"
          label="Address Line 1"
        />
        <TextInput variant="standard" fullWidth source="address_line2" label="Address Line 2" />

        <ReferenceInput
          label="Country"
          source="country"
          reference="country"
          sort={{ field: 'name', order: 'ASC' }}
          validate={[required()]}
        >
          <SelectInput fullWidth variant="standard" optionText="name" optionValue="name" />
        </ReferenceInput>
        {values.country && (
          <ReferenceInput
            label="Province"
            source="province"
            reference="country_subdivision"
            perPage={100}
            disabled={!values.country}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ country: values.country, level: 1 }}
            validate={[required()]}
          >
            <SelectInput fullWidth variant="standard" optionText="name" optionValue="name" />
          </ReferenceInput>
        )}
        {values.country && values.province && (
          <ReferenceInput
            label="City"
            source="city"
            reference="country_subdivision"
            disabled={!values.country && !values.province}
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ province: values.province }}
            validate={[required()]}
          >
            <SelectInput fullWidth variant="standard" optionText="name" optionValue="name" />
          </ReferenceInput>
        )}
        <TextInput fullWidth variant="standard" source="post_code" label="Post Code" />
      </DialogContent>
      <DialogActions>
        <Confirm
          redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="SAVE"
          undoable
          invalid={formProps.invalid}
          handleSubmit={async (submission) => {
            await handleSubmit(submission)
          }}
          saving={formProps.saving}
          disabled={formProps.saving || formProps.invalid}
          resource="address"
        />
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </div>
  )
}

export default AddressForm
