import React, {
  cloneElement,
  //  useMemo,
  useState,
  useEffect,
} from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import {
  FormWithRedirect,
  useEditController,
  EditContextProvider,
  useDataProvider,
  // useNotify,
} from 'react-admin'
import { uniqueId } from 'lodash'
import moment from 'moment'
// import { useSubscription, gql } from '@apollo/client'
import FormData from './ClientQuoteForm'

const MyEdit = (props) => {
  console.log(props)
  const editControllerProps = useEditController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = editControllerProps
  return (
    <EditContextProvider value={editControllerProps}>
      <div>
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </EditContextProvider>
  )
}

const ClientQuoteEdit = (props) => {
  return (
    <MyEdit {...props} actions={null} component="div" id={props.id} mutationMode="pessimistic">
      <ClientQuoteEditForm id={props.id} {...props} />
    </MyEdit>
  )
}

const ClientQuoteEditForm = (props) => {
  const dataProvider = useDataProvider()
  // const notify = useNotify()

  const [clientQuoteRecordData, setClientQuoteRecordData] = useState(props.record)
  const {
    milestones = [],
    config = {},
    isManager,
    product_quote_id,
    clientQuoteProductData,
    record,
    ...rest
  } = props

  useEffect(() => {
    // console.log(clientQuoteRecordData)

    const getDataLists = async () => {
      try {
        const { data } = await dataProvider.getOne('scm_quote', { id: record?.id })
        // console.log(data)
        setClientQuoteRecordData(data)
      } catch (error) {
        console.log(error)
      }
    }
    if (!clientQuoteRecordData?.prices) {
      record?.id && getDataLists()
    }
  }, [record])

  // console.log('record', record)
  // console.log('cqrd', clientQuoteRecordData)

  if (!record || !clientQuoteRecordData?.prices) {
    return (
      <Box display="flex" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  const clientQuoteData = {
    ...clientQuoteRecordData,
    product_quote: clientQuoteProductData?.product_quotes?.find(
      (el) => el.id === record?.product_quote?.id || record?.product_quote_id,
    ),
  }

  // console.log('CQPRODDATA', clientQuoteProductData)

  // console.log('CQDATA', clientQuoteData)

  const factoryPriceList =
    clientQuoteProductData?.factoryPrices || clientQuoteData?.product_quote?.prices

  const factoryPrices = factoryPriceList?.map((item) => ({
    ...(item.id && { id: item.id }),
    factoryUnitPrice: item.unit_price / 100 || item.unitPrice,
    factoryLeadTime: item.lead_time || item.production_lead_time,
    factoryQuantity: item.quantity,
    factoryLandedAir: item.est_landed_cost_air / 100 || item.landed_air || null,
    factoryLandedSea: item.est_landed_cost_sea / 100 || item.landed_sea || null,
    factoryTotalCBM: item.total_cbm / 100 || item.totalCBM || null,
    factoryShipmentWeight: item.shipment_weight / 100 || item.shipmentWeight || null,
    factoryTotalNumCartons: item.total_cartons || item.totalNumCartons,
    factoryTotalDuties: item.total_duties / 100 || item.totalDuties || null,
    factoryFreightCostPerPieceAir:
      item.freight_cost_air_per_piece / 100 || item.freightCostPerPieceAir || null,
    factoryFreightCostPerPieceSea:
      item.freight_cost_sea_per_piece / 100 || item.freightCostPerPieceSea || null,
    factoryTotalFreightCostAir: item.freight_cost_air / 100 || item.totalFreightCostAir || null,
    factoryTotalFreightCostSea: item.freight_cost_sea / 100 || item.totalFreightCostSea || null,
    factoryContainers: item.containers,
  }))

  // console.log('FACTORYPRICES', factoryPrices)

  const cqOptionsList = []

  // clientQuoteData.options?.map((opt) => {
  //   cqOptionsList.push({
  //     id: opt.id,
  //   })
  // })

  const components = []

  // console.log(components)

  clientQuoteProductData.product_quotes[0].components?.map((comp) => {
    // console.log(comp)
    const cqComp = clientQuoteData.product_components.find(
      (el) => el.product_quote_component_id === comp.id,
    )
    if (!cqComp) {
      components.push({
        ...comp,
        id: `C${uniqueId()}`,
        product_quote_component_id: comp.id,
      })
    } else {
      components.push(cqComp)
    }
  })

  clientQuoteProductData.configurations?.map((conf) => {
    // console.log('CONFIG', conf)
    // console.log('CQOPTS', clientQuoteData.options)
    const cqOpt = clientQuoteData.options?.find((el) => el.product_quote_option_id === conf.id)

    const baseOpt = cqOpt ? cqOpt : conf

    cqOptionsList.push({
      ...baseOpt,
      id: cqOpt?.id || `O${conf.id}`,
      additionalCostPerChoice: baseOpt.additional_cost_per_unique_choice / 100,
      product_quote_option_id: conf.id,
      values: conf.values?.map((el) => {
        // console.log('PQVAL', el)

        // console.log('CQVALS', cqOpt?.values)
        const cqVal = cqOpt?.values?.find((item) => item.product_quote_option_value_id === el.id)

        const baseVal = cqVal || el

        return {
          ...baseVal,
          id: cqVal?.id || `V${el.id}`,
          product_quote_option_value_id: el.id,
          unitCost: cqVal?.price / 100 || el.cost / 100 || 0,
          setupCost: cqVal?.setup / 100 || el.setup / 100 || 0,
          components: components?.map((comp) => {
            // console.log('PQCOMP', comp)
            // console.log('CQVALCOMPS', cqVal?.component_options)

            const cqComp = cqVal?.component_options?.find((obj) => obj.component?.id === comp.id)

            if (cqComp) {
              return {
                ...cqComp.component,
                componentRefId: cqComp.id,
                available: true,
              }
            } else {
              return {
                ...comp,
                available: false,
              }
            }
          }),
        }
      }),
    })
  })

  // console.log('OPTIONSLIST', cqOptionsList)

  const cqPkgSizesList = []

  clientQuoteData?.scm_quote_packaging_options?.map((pkgOpt) => {
    pkgOpt.scm_quote_product_size_packagings?.map((size) => {
      // console.log('cqpk', pkgOpt, 'cqsz', size)
      cqPkgSizesList.push({
        id: size.id,
        pkgId: pkgOpt.id,
        rowIndex: cqPkgSizesList.length,
        sizeId: size?.scm_quote_product_size?.id,
        lclSea: pkgOpt.sea_lcl / 100 || 0,
        lclAir: pkgOpt.air_lcl / 100 || 0,
        lclSeaBuffer: pkgOpt.sea_buffer / 100 || 0,
        lclAirBuffer: pkgOpt.air_buffer / 100 || 0,
        airModifier: pkgOpt.air_modifier / 100 || 0,
        seaModifier: pkgOpt.sea_modifier / 100 || 0,
        carton_dimensions_id: pkgOpt.carton_dimensions?.id,
        carton_dimensions: {
          id: pkgOpt.carton_dimensions?.id,
          length: pkgOpt.carton_dimensions?.length,
          height: pkgOpt.carton_dimensions?.height,
          width: pkgOpt.carton_dimensions?.width,
          gross_weight: pkgOpt.carton_dimensions?.gross_weight,
        },
        packaging_dimensions: {
          id: pkgOpt.packaging_dimensions?.id,
          length: pkgOpt.packaging_dimensions?.length,
          height: pkgOpt.packaging_dimensions?.height,
          width: pkgOpt.packaging_dimensions?.width,
          gross_weight: pkgOpt.packaging_dimensions?.gross_weight,
        },
        product_dimensions: {
          id: pkgOpt.product_dimensions?.id,
          length: pkgOpt.product_dimensions?.length,
          height: pkgOpt.product_dimensions?.height,
          width: pkgOpt.product_dimensions?.width,
          gross_weight: pkgOpt.product_dimensions?.gross_weight,
        },
        packaging_dimensions_id: pkgOpt.packaging_dimensions?.id,
        product_dimensions_id: size?.scm_quote_product_size?.dimensions?.id,
        sizeMOQ: size?.scm_quote_product_size?.moq,
        sizeModifier: size?.scm_quote_product_size?.price_modifier / 100,
        sizeName: size?.scm_quote_product_size?.name,
        sizeShortName: size?.scm_quote_product_size?.short_name,
        packagingType: pkgOpt.type,
        packagingLabel: pkgOpt.label,
        packagingMOQ: pkgOpt.moq,
        packagingModifier: pkgOpt.price_modifier / 100,
        pieces_per_carton: pkgOpt.pieces_per_carton,
        available: true,
        packagingFactoryReferenceId: pkgOpt.product_quote_packaging_option_id,
        sizeFactoryReferenceId: size?.scm_quote_product_size?.product_quote_product_size?.id,
        packagingFactoryMOQ: pkgOpt.product_quote_packaging_option?.moq,
        packagingFactoryModifier: pkgOpt.product_quote_packaging_option?.cost_modifier / 100,
        sizeFactoryMOQ: size?.scm_quote_product_size?.product_quote_product_size?.moq,
        sizeFactoryModifier:
          size?.scm_quote_product_size?.product_quote_product_size?.cost_modifier / 100,
        default: clientQuoteData?.default_packaging_option_id === size.id,
      })
    })
  })

  clientQuoteProductData?.packagingOptions?.map((pkgOpt) => {
    // console.log(pkgOpt)
    pkgOpt.sizes
      ?.filter((el) => el.active)
      .map((size) => {
        // console.log('pqpk', pkgOpt, 'pqsz', size)
        // console.log(size)
        if (
          !cqPkgSizesList.some(
            (cqPkg) =>
              (cqPkg.sizeFactoryReferenceId === size?.product_size?.id ||
                cqPkg.sizeFactoryReferenceId === size.id) &&
              cqPkg.packagingFactoryReferenceId === pkgOpt?.id,
          )
        ) {
          cqPkgSizesList.push({
            id: `N${uniqueId()}`,
            pkgId: pkgOpt.id || null,
            rowIndex: cqPkgSizesList.length,
            sizeId: size?.product_size?.id || null,
            lclSea: null,
            lclAir: null,
            airModifier: 0,
            seaModifier: 0,
            lclSeaBuffer: 0,
            lclAirBuffer: 0,
            carton_dimensions_id: pkgOpt.carton_dimensions?.id || pkgOpt.carton_dimensions_id,
            carton_dimensions: {
              id:
                pkgOpt.carton_dimensions?.id ||
                pkgOpt.cartonDimensions?.id ||
                pkgOpt.carton_dimensions_id,
              length: pkgOpt.carton_dimensions?.length || pkgOpt.cartonDimensions?.length * 100,
              height: pkgOpt.carton_dimensions?.height || pkgOpt.cartonDimensions?.height * 100,
              width: pkgOpt.carton_dimensions?.width || pkgOpt.cartonDimensions?.width * 100,
              gross_weight:
                pkgOpt.carton_dimensions?.gross_weight ||
                pkgOpt.cartonDimensions?.gross_weight * 100,
            },
            packaging_dimensions: {
              id:
                pkgOpt.packaging_dimensions?.id ||
                pkgOpt.packagingDimensions?.id ||
                pkgOpt.packaging_dimensions_id,
              length: pkgOpt.packaging_dimensions?.length || pkgOpt.packagingDimensions?.length,
              height: pkgOpt.packaging_dimensions?.height || pkgOpt.packagingDimensions?.height,
              width: pkgOpt.packaging_dimensions?.width || pkgOpt.packagingDimensions?.width,
              gross_weight:
                pkgOpt.packaging_dimensions?.gross_weight ||
                pkgOpt.packagingDimensions?.gross_weight,
            },
            product_dimensions: {
              id: pkgOpt.product_dimensions?.id || size.productDimensions?.id,
              length: pkgOpt.product_dimensions?.length || size.productDimensions?.length,
              height: pkgOpt.product_dimensions?.height || size.productDimensions?.height,
              width: pkgOpt.product_dimensions?.width || size.productDimensions?.width,
              gross_weight:
                pkgOpt.product_dimensions?.gross_weight || size.productDimensions?.gross_weight,
            },
            packaging_dimensions_id:
              pkgOpt.packaging_dimensions?.id || pkgOpt.packaging_dimensions_id,
            product_dimensions_id: size?.product_size?.dimensions?.id || size.dimensions?.id,
            sizeMOQ: null,
            sizeModifier: null,
            sizeName: size?.product_size?.name || size.name,
            sizeShortName: size?.product_size?.short_name || size.short_name,
            packagingType: pkgOpt.type,
            packagingLabel: pkgOpt.label,
            packagingMOQ: null,
            packagingModifier: null,
            pieces_per_carton: pkgOpt.pieces_per_carton,
            available: false,
            packagingFactoryReferenceId: pkgOpt.id,
            sizeFactoryReferenceId: size?.product_size?.id || size.id,
            packagingFactoryMOQ: pkgOpt?.moq,
            packagingFactoryModifier: pkgOpt?.cost_modifier / 100 || pkgOpt.costModifier,
            sizeFactoryMOQ: size?.product_size?.moq || size.moq,
            sizeFactoryModifier: size?.product_size?.cost_modifier / 100 || size.costModifier,
            default: false,
          })
        }
      })
  })

  // console.log('SIZESLIST', cqPkgSizesList)

  // loop all product_quote colours
  // set active if client quote colours match product_quote

  const productColours =
    clientQuoteData?.product_quote?.components
      ?.find((cmp) => cmp.name === 'Base')
      ?.colours?.map((colour) => {
        const scColour = clientQuoteData?.product_components
          ?.find((cmp) => cmp.name === 'Base')
          ?.colours?.find((scColour) => scColour.value === colour.value)

        return {
          pqId: colour.id,
          id: scColour?.id || `C${uniqueId()}`,
          value: colour.value,
          available: !!scColour,
        }
      }) || []

  const clientDecorations = clientQuoteProductData?.product_quotes[0].decorations?.map((item) => {
    const cqDeco = clientQuoteData.decorations?.find(
      (el) => el.product_quote_decoration_id === item.id,
    )

    // console.log(cqDeco)

    if (cqDeco) {
      const imprintStyles = item.imprint_styles?.map((el) => {
        const cqImp = cqDeco.imprint_styles?.find((imp) => imp === el)
        return { name: el, available: !!cqImp }
      })
      return {
        ...cqDeco,
        unitPrice: cqDeco.price / 100,
        imprintStyles,
        available: true,
      }
    }

    return {
      ...item,
      id: `D${uniqueId()}`,
      unitPrice: item.cost / 100,
      refId: item.id,
      available: false,
    }
  })

  // console.log(productColours)

  const clientPrices =
    clientQuoteData && clientQuoteData.product_quote?.prices?.length > 0
      ? clientQuoteData.prices?.map((item) => {
          // console.log(item)

          // const freightRateAir = clientQuoteData.freight_rate_air / 100
          // const freightRateSea = clientQuoteData.freight_rate_sea / 100
          const unitPrice = item.price / 100
          const fobMargin = 1 - item.margin_fob / 10000

          const defaultPackagingOption = cqPkgSizesList.find(
            (obj) => obj.id === clientQuoteData.default_packaging_option_id,
          )
          // console.log(defaultPackagingOption)

          // const grossWeight =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.gross_weight /
          //     100 || defaultPackagingOption?.carton_dimensions?.gross_weight / 100
          // const cartonLength =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.length / 100 ||
          //   defaultPackagingOption?.carton_dimensions?.length / 100
          // const cartonWidth =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.width / 100 ||
          //   defaultPackagingOption?.carton_dimensions?.width / 100
          // const cartonHeight =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.height / 100 ||
          //   defaultPackagingOption?.carton_dimensions?.height / 100
          // const piecesPerCarton =
          //   defaultPackagingOption?.scm_quote_packaging_option?.pieces_per_carton ||
          //   defaultPackagingOption?.pieces_per_carton
          const seaLCL =
            defaultPackagingOption?.scm_quote_packaging_option?.sea_lcl / 100 ||
            Number(defaultPackagingOption?.lclSea) ||
            0
          const airLCL =
            defaultPackagingOption?.scm_quote_packaging_option?.air_lcl / 100 ||
            Number(defaultPackagingOption?.lclAir) ||
            0

          const modifiers =
            (defaultPackagingOption?.packagingModifier || 0) +
            (defaultPackagingOption?.sizeModifier || 0) +
            (clientQuoteData.standardColourPriceModifier || 0)

          // console.log(
          //   'unitPrice',
          //   unitPrice,
          //   'fobMargin',
          //   fobMargin,
          //   'modifiers',
          //   modifiers,
          //   'airLCL',
          //   airLCL,
          //   'seaLCL',
          //   seaLCL,
          // )

          const basePrice = Number(((unitPrice + modifiers) / fobMargin).toFixed(2))

          // const dutyRate = values.duty_rate / 10000

          // const dutiesPerPiece = basePrice * dutyRate
          // form.change(`prices[${index}].totalDuties`, dutiesPerPiece)

          // console.log('basePrice', basePrice) //'dutyRate', dutyRate, 'dutiesPerPiece', dutiesPerPiece)

          const fobPrice = Number(basePrice) // + dutiesPerPiece).toFixed(2))

          const seaAdjuster = item.seaAdjustment / 100 || 0
          const airAdjuster = item.airAdjustment / 100 || 0
          const seaAdditions = Number(seaLCL) + Number(seaAdjuster)
          const airAdditions = Number(airLCL) + Number(airAdjuster)

          const dutiesPerPiece = (clientQuoteData.duty_rate / 10000) * fobPrice
          // const numCartons = item.quantity / piecesPerCarton

          // const seaWeight =
          //   parseInt((((cartonLength * cartonWidth * cartonHeight) / 6000) * 100).toFixed(2)) ||
          //   null

          // const chargeableSeaWeight = seaWeight / 100 > grossWeight ? seaWeight / 100 : grossWeight
          // const airWeight =
          //   parseInt((((cartonLength * cartonWidth * cartonHeight) / 5000) * 100).toFixed(2)) ||
          //   null

          // const chargeableAirWeight = airWeight / 100 > grossWeight ? airWeight / 100 : grossWeight

          // const airRate = chargeableAirWeight * numCartons * freightRateAir
          // const seaRate = chargeableSeaWeight * numCartons * freightRateSea

          const estLandedRateSea = Number(
            (fobPrice + Number(seaAdditions)) //+
              // (clientQuoteData.does_sea_freight_include_duties ? 0 : dutiesPerPiece) +
              // seaRate / item.quantity
              .toFixed(2),
          )
          const estLandedRateAir = Number(
            (fobPrice + Number(airAdditions) + dutiesPerPiece) //+
              // airRate / item.quantity
              .toFixed(2),
          )

          const estProfit = ((fobPrice - unitPrice) * item.quantity).toFixed(2)

          return {
            id: item.id,
            quantity: item.quantity,
            lead_time_air: item.lead_time_air,
            lead_time_sea: item.lead_time_sea,
            unitPrice: item.price / 100,
            formFobPrice: fobPrice,
            fobPrice,
            estProfit,
            estimatedFreightAir: estLandedRateAir,
            estimatedFreightSea: estLandedRateSea,
            savedFobPrice: item.fob_price / 100,
            savedEstimatedFreightAir: item.estimated_freight_air / 100,
            savedEstimatedFreightSea: item.estimated_freight_sea / 100,
            client_production_lead_time: item.production_lead_time,
            seaAdjustment: item.adjustment_sea / 100,
            airAdjustment: item.adjustment_air / 100,
            // fobLocked: item.fob_price ? true : false,
            fobLocked: false,
            fobMargin: item.margin_fob / 100 || 0,
            // estimatedFreightAirLocked: item.estimated_freight_air ? true : false,
            estimatedFreightAirLocked: false,
            // estimatedFreightSeaLocked: item.estimated_freight_sea ? true : false,
            estimatedFreightSeaLocked: false,
          }
        })
      : []

  const validation = (values) => {
    const errors = {}

    if (!values?.payment_terms || values?.payment_terms?.length < 1) {
      errors.paymentTermsLength = 'The client payment terms are required'
    }
    if (!values?.prices || values?.prices?.length < 1) {
      errors.prices = 'At least one pricing is required'
    }
    return errors
  }

  const initSCM = {
    ...clientQuoteData,
    clientDecorations,
    clientOptions: cqOptionsList,
    factorySizes: clientQuoteProductData.packagingOptions,
    productColours,
    standardColourPriceModifier: clientQuoteData.standard_colour_option_price_modifier / 100,
    packagingSizes: cqPkgSizesList,
    launch_date: clientQuoteData?.launch_date || moment().format('YYYY/MM/DD'),
    prices: clientPrices,
    clientSamplePrice: clientQuoteData?.client_sample_price / 100 || null,
    clientCustomSamplePrice: clientQuoteData?.client_custom_sample_price / 100 || null,
    quote_notes: clientQuoteData?.notes,
    factoryPrices,
    freightRateAir: clientQuoteData?.freight_rate_air / 100 || null,
    freightRateSea: clientQuoteData?.freight_rate_sea / 100 || null,
    containerRate20ft: clientQuoteData?.container_rate_20ft / 100 || null,
    containerRate40ft: clientQuoteData?.container_rate_40ft / 100 || null,
    containerRate40ftHC: clientQuoteData?.container_rate_40ft_hc / 100 || null,
    dutyRate:
      clientQuoteData?.duty_rate === 0
        ? 0
        : clientQuoteData?.duty_rate / 100 || clientQuoteProductData?.dutyRate || 0,
    does_sea_freight_include_duties: clientQuoteData?.does_sea_freight_include_duties || false,
  }

  // console.log('initSCM', initSCM)

  // const initValsSCM = useMemo(() => initSCM, [initSCM])

  return (
    <MyEdit {...rest}>
      <FormWithRedirect
        {...rest}
        validate={validation}
        // initialValuesEqual={(vals, nvals) => false}
        initialValues={initSCM}
        render={(formProps) => (
          <FormData
            milestones={milestones}
            config={config}
            pieces_per_carton={clientQuoteData?.pieces_per_carton}
            dutyRate={clientQuoteData?.dutyRate}
            formProps={formProps}
            product_quote_id={product_quote_id}
            isManager={isManager}
          />
        )}
      />
    </MyEdit>
  )
}

export default ClientQuoteEdit
