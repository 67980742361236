import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core'
import {
  //   required,
  DateInput,
  ReferenceInput,
  SelectInput,
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  FunctionField,
  useDataProvider,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import useStyles from '../styles'
import { getDisplayName } from '../../../utils/utils'
import SupplierDefaultImg from '../../../assets/supplier_default.png'
import ClientSelectWithCreate from '../../components/ClientSelectWithCreate'
// import ClientQuoteCreate from './ClientQuoteCreate'
import ProductEdit from '../../catalogueProducts/CatalogueProductEdit'
import { roundTo } from '../../../utils/utils'

const SCMQuoteCreateForm = () => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [productData, setProductData] = useState()
  const [loading, setLoading] = useState()
  const [saving, setSaving] = useState(false)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [clients, setClients] = useState([])
  const [config, setConfig] = useState()
  const { values } = useFormState()

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('organisation', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
        })
        !abortController.signal.aborted && setClients(clientsTemp)

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const config = {}
        configTemp.global_preferences.forEach(
          (item) => (config[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(config)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider])

  const ProductFilter = (props) => (
    <Filter {...props}>
      <SearchInput
        source="request_name"
        alwaysOn
        resettable
        variant="standard"
        fullWidth
        placeholder="Product Name"
        style={{ minWidth: '200px' }}
      />
      <ReferenceInput
        source="supplier"
        reference="suppliers"
        // sort={{ field: 'id', order: 'ASC' }}
        alwaysOn
        style={{ minWidth: '200px' }}
        variant="standard"
      >
        <SelectInput optionText="name" fullWidth resettable />
      </ReferenceInput>
      <ReferenceInput
        source="agent"
        reference="users"
        label="SCA"
        // sort={{ field: 'id', order: 'ASC' }}
        filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
        style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput
          optionValue="id"
          optionText={(val) => getDisplayName(val)}
          fullWidth
          resettable
        />
      </ReferenceInput>
      <ReferenceInput
        source="category"
        reference="category"
        // sort={{ field: 'id', order: 'ASC' }}
        style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput optionText="name" fullWidth resettable />
      </ReferenceInput>
      <DateInput
        source="date_gte"
        label="Quote date after"
        alwaysOn
        variant="standard"
        fullWidth
        style={{ minWidth: '120px' }}
      />
      <DateInput
        source="date_lte"
        label="Quote date before"
        alwaysOn
        variant="standard"
        fullWidth
        style={{ minWidth: '120px' }}
      />
      <SelectInput
        choices={[
          { name: 'Yes', value: 'true', id: 0 },
          { name: 'No', value: 'false', id: 1 },
        ]}
        allowEmpty
        emptyText=""
        resettable
        alwaysOn
        fullWidth
        optionValue="value"
        variant="standard"
        label="Quoted"
        source="has_quotes"
        style={{ minWidth: '120px' }}
      />
    </Filter>
  )

  // console.log('SCMFORMVALUES', values)
  return (
    <Box style={{ width: '100%' }}>
      {!productData ? (
        <Box mt="30px" display="flex" alignItems="center" justifyContent="center">
          <ClientSelectWithCreate
            options={clients}
            setOptions={(newOptions) => {
              const newClients = { ...newOptions }
              setClients(newClients)
            }}
            optionText={(value) => value?.name}
            source={'client_id'}
          />
          <Button
            disabled={!values.client_id}
            style={{ border: 'solid black 1px', marginLeft: '10px', padding: '5px 10px' }}
            onClick={() => setSearchOpen(true)}
          >
            Find existing product
          </Button>
          <Button
            disabled={!values.client_id}
            style={{ border: 'solid black 1px', marginLeft: '10px', padding: '5px 10px' }}
            onClick={async () => {
              setLoading(true)

              try {
                const resp = await dataProvider.create('product', {
                  data: {
                    name: '',
                    product_dimensions: { data: {} },
                    carton_dimensions: { data: {} },
                    packaging_dimensions: { data: {} },
                    product_quotes: {
                      data: {
                        components: {
                          data: [{ name: 'Base' }],
                        },
                        scm_quotes: {
                          data: [
                            {
                              client_id: values.client_id,
                              container_rate_20ft:
                                fqResp?.product_quotes[0].container_rate_20ft === 0
                                  ? 0
                                  : fqResp?.product_quotes[0].container_rate_20ft ||
                                    config.estimated_container_rate_20ft ||
                                    null,
                              container_rate_40ft:
                                fqResp?.product_quotes[0].container_rate_40ft === 0
                                  ? 0
                                  : fqResp?.product_quotes[0].container_rate_40ft ||
                                    config.estimated_container_rate_40ft ||
                                    null,
                              container_rate_40ft_hc:
                                fqResp?.product_quotes[0].container_rate_40ft_hc === 0
                                  ? 0
                                  : fqResp?.product_quotes[0].container_rate_40ft_hc ||
                                    config.estimated_container_rate_40ft_hc ||
                                    null,
                              freight_rate_sea:
                                fqResp?.product_quotes[0].freight_rate_sea === 0
                                  ? 0
                                  : fqResp?.product_quotes[0].freight_rate_sea ||
                                    config.estimated_freight_rate_sea ||
                                    null,
                              freight_rate_air:
                                fqResp?.product_quotes[0].freight_rate_air === 0
                                  ? 0
                                  : fqResp?.product_quotes[0].freight_rate_air ||
                                    config.estimated_freight_rate_air ||
                                    null,
                              does_sea_freight_include_duties:
                                fqResp?.product_quotes[0].does_sea_freight_include_duties ||
                                config.estimated_freight_rate_sea_duties_included === 'true' ||
                                false,

                              product_components: {
                                data: [
                                  {
                                    name: 'Base',
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                })

                const { data } = await dataProvider.getOne('product', {
                  id: resp.data.id,
                })

                const fqResp = { ...data }
                // console.log('FQRESP', fqResp)

                const prices =
                  fqResp?.product_quotes?.length > 0 && fqResp?.product_quotes[0].prices?.length > 0
                    ? fqResp?.product_quotes[0]?.prices?.map((item) => ({
                        ...(item.id && { id: item.id }),
                        unitPrice: roundTo(item.unit_price / 100, 2),
                        production_lead_time: item.lead_time,
                        quantity: item.quantity,
                        landed_air: item.est_landed_cost_air / 100 || null,
                        landed_sea: item.est_landed_cost_sea / 100 || null,
                        totalCBM: item.total_cbm / 100 || null,
                        shipmentWeight: item.shipment_weight / 100 || null,
                        totalNumCartons: item.total_cartons,
                        totalDuties: item.total_duties / 100 || null,
                        freightCostPerPieceAir: item.freight_cost_air_per_piece / 100 || null,
                        freightCostPerPieceSea: item.freight_cost_sea_per_piece / 100 || null,
                        totalFreightCostAir: item.freight_cost_air / 100 || null,
                        totalFreightCostSea: item.freight_cost_sea / 100 || null,
                        containers: item.containers,
                      }))
                    : []

                const productDimensions = {
                  id: fqResp?.product_dimensions?.id || null,
                  length:
                    fqResp?.product_dimensions?.length === 0
                      ? 0
                      : fqResp?.product_dimensions?.length / 100 || null,
                  lengthIN: fqResp?.product_dimensions?.length
                    ? (fqResp?.product_dimensions?.length / 100 / 2.54).toFixed(2)
                    : null,
                  width:
                    fqResp?.product_dimensions?.width === 0
                      ? 0
                      : fqResp?.product_dimensions?.width / 100 || null,
                  widthIN: fqResp?.product_dimensions?.width
                    ? (fqResp?.product_dimensions?.width / 100 / 2.54).toFixed(2)
                    : null,
                  height:
                    fqResp?.product_dimensions?.height === 0
                      ? 0
                      : fqResp?.product_dimensions?.height / 100 || null,
                  heightIN: fqResp?.product_dimensions?.height
                    ? (fqResp?.product_dimensions?.height / 100 / 2.54).toFixed(2)
                    : null,
                  gross_weight:
                    fqResp?.product_dimensions?.gross_weight === 0
                      ? 0
                      : fqResp?.product_dimensions?.gross_weight / 100 || null,
                  weightLB: fqResp?.product_dimensions?.gross_weight
                    ? ((fqResp?.product_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
                    : null,
                }

                const packagingDimensions = {
                  id: fqResp?.packaging_dimensions?.id || null,
                  length:
                    fqResp?.packaging_dimensions?.length === 0
                      ? 0
                      : fqResp?.packaging_dimensions?.length / 100 || null,
                  lengthIN: fqResp?.packaging_dimensions?.length
                    ? (fqResp?.packaging_dimensions?.length / 100 / 2.54).toFixed(2)
                    : null,
                  width:
                    fqResp?.packaging_dimensions?.width === 0
                      ? 0
                      : fqResp?.packaging_dimensions?.width / 100 || null,
                  widthIN: fqResp?.packaging_dimensions?.width
                    ? (fqResp?.packaging_dimensions?.width / 100 / 2.54).toFixed(2)
                    : null,
                  height:
                    fqResp?.packaging_dimensions?.height === 0
                      ? 0
                      : fqResp?.packaging_dimensions?.height / 100 || null,
                  heightIN: fqResp?.packaging_dimensions?.height
                    ? (fqResp?.packaging_dimensions?.height / 100 / 2.54).toFixed(2)
                    : null,
                  gross_weight:
                    fqResp?.packaging_dimensions?.gross_weight === 0
                      ? 0
                      : fqResp?.packaging_dimensions?.gross_weight / 100 || null,
                  weightLB: fqResp?.packaging_dimensions?.gross_weight
                    ? ((fqResp?.packaging_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
                    : null,
                }

                const cartonDimensions = {
                  id: fqResp?.carton_dimensions?.id || null,
                  length:
                    fqResp?.carton_dimensions?.length === 0
                      ? 0
                      : fqResp?.carton_dimensions?.length / 100 || null,
                  lengthIN: fqResp?.carton_dimensions?.length
                    ? (fqResp?.carton_dimensions?.length / 100 / 2.54).toFixed(2)
                    : null,
                  width:
                    fqResp?.carton_dimensions?.width === 0
                      ? 0
                      : fqResp?.carton_dimensions?.width / 100 || null,
                  widthIN: fqResp?.carton_dimensions?.width
                    ? (fqResp?.carton_dimensions?.width / 100 / 2.54).toFixed(2)
                    : null,
                  height:
                    fqResp?.carton_dimensions?.height === 0
                      ? 0
                      : fqResp?.carton_dimensions?.height / 100 || null,
                  heightIN: fqResp?.carton_dimensions?.height
                    ? (fqResp?.carton_dimensions?.height / 100 / 2.54).toFixed(2)
                    : null,
                  gross_weight:
                    fqResp?.carton_dimensions?.gross_weight === 0
                      ? 0
                      : fqResp?.carton_dimensions?.gross_weight / 100 || null,
                  weightLB: fqResp?.carton_dimensions?.gross_weight
                    ? ((fqResp?.carton_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
                    : null,
                }

                const otherSpecifications = []
                const colourOptions =
                  fqResp?.product_options?.length > 0
                    ? fqResp.product_options?.filter(
                        (spec) =>
                          !!spec.deleted_at === false &&
                          spec.option_value?.option?.name === 'Colour',
                      )
                    : []
                const materialOptions =
                  fqResp?.product_options?.length > 0
                    ? fqResp.product_options?.filter(
                        (spec) =>
                          !!spec.deleted_at === false &&
                          spec.option_value?.option?.name === 'Material',
                      )
                    : []

                const sizeOptions =
                  fqResp?.product_options?.length > 0
                    ? fqResp.product_options
                        ?.filter(
                          (spec) =>
                            !!spec.deleted_at === false &&
                            spec.option_value?.option?.name === 'Size',
                        )
                        .map((item) => item.option_value.value)
                    : []

                // console.log('fqResp', fqResp)

                const uniqueSpecs = []
                if (fqResp?.product_specifications?.length > 0) {
                  fqResp.product_specifications.map((spec) => {
                    if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
                      uniqueSpecs.push(spec.option_value.option.name)
                    }
                  })
                }

                uniqueSpecs.map((spec) => {
                  if (spec !== 'Colour' && spec !== 'Material') {
                    otherSpecifications?.push({
                      name: spec,
                      option_value: {
                        value:
                          fqResp?.product_specifications?.length > 0
                            ? fqResp.product_specifications
                                .filter(
                                  (qspec) =>
                                    qspec.option_value.option?.name === spec &&
                                    qspec.deleted_at === null,
                                )
                                .map((item) => item.option_value?.value)
                            : [],
                      },
                    })
                  }
                })

                const oldSpecifications = []

                uniqueSpecs.map((spec) => {
                  if (spec !== 'Colour' && spec !== 'Material') {
                    oldSpecifications?.push({
                      name: spec,
                      values:
                        fqResp?.product_specifications?.length > 0
                          ? fqResp.product_specifications
                              .filter(
                                (qspec) =>
                                  qspec.option_value.option?.name === spec &&
                                  qspec.deleted_at === null,
                              )
                              .map((val) => ({ id: val.id, value: val.option_value.value }))
                          : [],
                    })
                  }
                })

                const productColours = fqResp?.product_specifications?.filter(
                  (spec) =>
                    !!spec.deleted_at === false && spec.option_value?.option?.name === 'Colour',
                )
                const productMaterials = fqResp?.product_specifications?.filter(
                  (spec) =>
                    !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
                )

                const uniqueCustomSpecs = []
                fqResp?.customisations?.map((spec) => {
                  if (uniqueCustomSpecs.indexOf(spec.option_value.option.name) === -1) {
                    uniqueCustomSpecs.push(spec.option_value.option.name)
                  }
                })

                const productCustomisations = uniqueCustomSpecs.map((spec) => {
                  return {
                    name: spec,
                    option_value: {
                      value: fqResp?.customisations
                        ?.filter(
                          (qspec) =>
                            qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                        )
                        .map((item) => item.option_value?.value),
                    },
                  }
                })

                const oldCustomisations = uniqueCustomSpecs.map((spec) => {
                  return {
                    name: spec,
                    values: fqResp?.customisations?.filter(
                      (qspec) =>
                        qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                    ),
                  }
                })

                const chosenCategories = fqResp?.product_categories
                  ?.filter((item) => !item.deleted_at)
                  .map((item) => item.category.id)

                const chosenTags = fqResp?.product_tags?.map((item) => item.tag.id)

                const seaWeight =
                  parseInt(
                    (
                      ((cartonDimensions?.length *
                        cartonDimensions?.width *
                        cartonDimensions?.height) /
                        5000) *
                      100
                    ).toFixed(2),
                  ) || null

                const chargeableSeaWeight =
                  seaWeight / 100 > cartonDimensions?.gross_weight
                    ? seaWeight
                    : cartonDimensions?.gross_weight * 100

                const airWeight =
                  parseInt(
                    (
                      ((cartonDimensions?.length *
                        cartonDimensions?.width *
                        cartonDimensions?.height) /
                        6000) *
                      100
                    ).toFixed(2),
                  ) || null

                const chargeableAirWeight =
                  airWeight / 100 > cartonDimensions?.gross_weight
                    ? airWeight
                    : cartonDimensions?.gross_weight * 100

                // console.log(chargeableSeaWeight, chargeableAirWeight)

                fqResp ? (fqResp.chargeableSeaWeight = chargeableSeaWeight / 100) : null
                fqResp ? (fqResp.chargeableAirWeight = chargeableAirWeight / 100) : null

                const init = {
                  ...fqResp,
                  productCustomisations,
                  // images,
                  additional_files: [],
                  productDimensions: productDimensions,
                  packagingDimensions: packagingDimensions,
                  cartonDimensions: cartonDimensions,
                  dutyRate: fqResp?.duty_rate === 0 ? 0 : fqResp?.duty_rate / 100 || null,
                  toolingCost: fqResp?.product_quotes
                    ? fqResp?.product_quotes[0]?.tooling_cost === 0
                      ? 0
                      : fqResp?.product_quotes[0]?.tooling_cost / 100 || null
                    : null,
                  sampleCost: fqResp?.product_quotes
                    ? fqResp?.product_quotes[0]?.sample_cost === 0
                      ? 0
                      : fqResp?.product_quotes[0]?.sample_cost / 100 || null
                    : null,
                  customSampleCost: fqResp?.product_quotes
                    ? fqResp?.product_quotes[0]?.custom_sample_cost === 0
                      ? 0
                      : fqResp?.product_quotes[0]?.custom_sample_cost / 100 || null
                    : null,
                  prices,
                  launch_date:
                    fqResp?.product_quotes?.length > 0 &&
                    fqResp?.product_quotes[0].scm_quotes?.length > 0
                      ? fqResp?.product_quotes[0]?.scm_quotes[0]?.launch_date
                      : null,
                  quote_notes:
                    fqResp?.product_quotes?.length > 0 &&
                    fqResp?.product_quotes[0].scm_quotes?.length > 0
                      ? fqResp?.product_quotes[0]?.scm_quotes[0]?.notes
                      : null,
                  clientSamplePrice:
                    fqResp?.product_quotes?.length > 0 &&
                    fqResp?.product_quotes[0].scm_quotes?.length > 0
                      ? fqResp?.product_quotes[0]?.scm_quotes[0]?.client_sample_price
                      : null,
                  categories: chosenCategories || [],
                  tags: chosenTags || [],
                  productColours: productColours || [],
                  productMaterials: productMaterials || [],
                  sizeOptions,
                  otherSpecifications: otherSpecifications || [],
                  oldSpecifications: oldSpecifications || [],
                  colourOptions: colourOptions || [],
                  materialOptions: materialOptions || [],
                  oldCustomisations: oldCustomisations || [],
                  containerRate20ft:
                    fqResp?.product_quotes[0].container_rate_20ft === 0
                      ? 0
                      : fqResp?.product_quotes[0].container_rate_20ft / 100 ||
                        config.estimated_container_rate_20ft / 100 ||
                        null,
                  containerRate40ft:
                    fqResp?.product_quotes[0].container_rate_40ft === 0
                      ? 0
                      : fqResp?.product_quotes[0].container_rate_40ft / 100 ||
                        config.estimated_container_rate_40ft / 100 ||
                        null,
                  containerRate40ftHC:
                    fqResp?.product_quotes[0].container_rate_40ft_hc === 0
                      ? 0
                      : fqResp?.product_quotes[0].container_rate_40ft_hc / 100 ||
                        config.estimated_container_rate_40ft_hc / 100 ||
                        null,
                  freightRateSea:
                    fqResp?.product_quotes[0].freight_rate_sea === 0
                      ? 0
                      : fqResp?.product_quotes[0].freight_rate_sea / 100 ||
                        config.estimated_freight_rate_sea / 100 ||
                        null,
                  freightRateAir:
                    fqResp?.product_quotes[0].freight_rate_air === 0
                      ? 0
                      : fqResp?.product_quotes[0].freight_rate_air / 100 ||
                        config.estimated_freight_rate_air / 100 ||
                        null,
                  does_sea_freight_include_duties:
                    fqResp?.product_quotes[0].does_sea_freight_include_duties ||
                    config.estimated_freight_rate_sea_duties_included === 'true' ||
                    false,
                  // additional_files_type,
                }

                // console.log('init', init)

                setProductData(init)

                setLoading(false)
              } catch (error) {
                console.log(error)
                setLoading(false)
              }
            }}
          >
            Create new product
          </Button>
          {/* <Button
            disabled
            // onClick={() => redirect(`/productImport/${sourcing_request_id}`)}
          >
            Import products
          </Button> */}
          {loading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : null}
      <Box mt="30px">
        {productData ? (
          <ProductEdit
            basePath="/scmQuote"
            resource="products"
            productData={productData}
            clientId={values.client_id}
          />
        ) : null}
      </Box>

      <Dialog
        onClose={() => setSearchOpen(false)}
        open={searchOpen}
        classes={{ paper: classes.paper }}
        maxWidth="md"
      >
        <DialogTitle className={classes.checkModelHeader}>
          <Box width={800} style={{ maxHeight: 820 }}>
            <Typography variant="h5" gutterBottom>
              Product Search
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <List
              component="div"
              actions={null}
              empty={false}
              resource="products"
              basePath="sourcingRequests"
              bulkActionButtons={false}
              filters={<ProductFilter />}
              sort={{ field: 'id', order: 'DESC' }}
            >
              <Datagrid>
                <FunctionField
                  label="Image"
                  render={(record) => (
                    <img
                      style={{ maxHeight: '50px' }}
                      src={record.thumbnail_photo?.url || SupplierDefaultImg}
                      // alt=""
                      alt={<img src={SupplierDefaultImg} alt="" />}
                    />
                  )}
                />
                <TextField source="name" />
                <TextField label="Supplier" source="product_quotes[0].supplier.name" />
                <FunctionField
                  label="Select"
                  render={(record) => (
                    <Button
                      onClick={async () => {
                        // console.log('copying', record)
                        setSaving(true)

                        try {
                          const newProduct = {
                            root_product_id: record.id,
                            name: record.name,
                            brand: record.brand,
                            primary_photo_id: record.primary_photo.id,
                            thumbnail_photo_id: record.thumbnail_photo.id,
                            chargeable_weight_air: record.chargeable_weight_air,
                            chargeable_weight_sea: record.chargeable_weight_sea,
                            duty_rate: record.duty_rate,
                            hts_code: record.hts_code,
                            is_catalogue: false,
                            notes: record.notes,
                            sca_user_id: record.sca_user_id,
                            root_sku: record.root_sku,
                            short_description: record.short_description,
                            product_files: {
                              data: record.images?.map((image) => ({
                                file_id: image.file.id,
                                type: image.type,
                              })),
                            },
                            product_categories: {
                              data: record.product_categories?.map((cat) => ({
                                category_id: cat.category.id,
                              })),
                            },
                            product_options: {
                              data: record.product_options?.map((opt) => ({
                                specification_id: opt.option_value.id,
                              })),
                            },
                            product_specifications: {
                              data: record.product_specifications?.map((spec) => ({
                                specification_id: spec.option_value.id,
                              })),
                            },
                            product_tags: {
                              data: record.product_tags?.map((tag) => ({
                                tag_id: tag.tag.id,
                              })),
                            },
                            product_dimensions: {
                              data: {
                                length: record.product_dimensions?.length,
                                width: record.product_dimensions?.width,
                                height: record.product_dimensions?.height,
                                gross_weight: record.product_dimensions?.gross_weight,
                              },
                            },
                            packaging_dimensions: {
                              data: {
                                length: record.packaging_dimensions?.length,
                                width: record.packaging_dimensions?.width,
                                height: record.packaging_dimensions?.height,
                                gross_weight: record.packaging_dimensions?.gross_weight,
                              },
                            },
                            carton_dimensions: {
                              data: {
                                length: record.carton_dimensions?.length,
                                width: record.carton_dimensions?.width,
                                height: record.carton_dimensions?.height,
                                gross_weight: record.carton_dimensions?.gross_weight,
                              },
                            },
                            product_quotes: {
                              data: {
                                allow_order_splitting_by_size:
                                  record.product_quotes[0].allow_order_splitting_by_size,
                                cartons_per_container_20ft:
                                  record.product_quotes[0].cartons_per_container_20ft,
                                cartons_per_container_40ft:
                                  record.product_quotes[0].cartons_per_container_40ft,
                                cartons_per_container_40ft_hc:
                                  record.product_quotes[0].cartons_per_container_40ft_hc,
                                cartons_per_pallet: record.product_quotes[0].cartons_per_pallet,
                                container_capacity_20ft:
                                  record.product_quotes[0].container_capacity_20ft,
                                container_capacity_40ft:
                                  record.product_quotes[0].container_capacity_40ft,
                                container_capacity_40ft_hc:
                                  record.product_quotes[0].container_capacity_40ft_hc,
                                container_rate_20ft: record.product_quotes[0].container_rate_20ft,
                                container_rate_40ft: record.product_quotes[0].container_rate_40ft,
                                container_rate_40ft_hc:
                                  record.product_quotes[0].container_rate_40ft_hc,
                                does_sea_freight_include_duties:
                                  record.product_quotes[0].does_sea_freight_include_duties,
                                custom_sample_cost: record.product_quotes[0].custom_sample_cost,
                                freight_rate_air: record.product_quotes[0].freight_rate_air,
                                freight_rate_sea: record.product_quotes[0].freight_rate_sea,
                                packaging_type: record.product_quotes[0].packaging_type,
                                pieces_per_carton: record.product_quotes[0].pieces_per_carton,
                                sample_cost: record.product_quotes[0].sample_cost,
                                sample_lead_time: record.product_quotes[0].sample_lead_time,
                                supplier_id: record.product_quotes[0].supplier?.id,
                                tooling_cost: record.product_quotes[0].tooling_cost,
                                tooling_lead_time: record.product_quotes[0].tooling_lead_time,
                                valid_date: record.product_quotes[0].valid_date,
                                prices: {
                                  data: record.product_quotes[0].prices.map((price) => ({
                                    est_landed_cost_air: price.est_landed_cost_air,
                                    est_landed_cost_sea: price.est_landed_cost_sea,
                                    lead_time: price.lead_time,
                                    quantity: price.quantity,
                                    shipment_weight: price.shipment_weight,
                                    total_cartons: price.total_cartons,
                                    total_cbm: price.total_cbm,
                                    total_duties: price.total_duties,
                                    unit_price: price.unit_price,
                                  })),
                                },
                                components: {
                                  data: [
                                    {
                                      name: 'Base',
                                    },
                                  ],
                                },
                                scm_quotes: {
                                  data: [
                                    {
                                      client_id: values.client_id,
                                      container_capacity_20ft:
                                        record.product_quotes[0].container_capacity_20ft,
                                      container_capacity_40ft:
                                        record.product_quotes[0].container_capacity_40ft,
                                      container_capacity_40ft_hc:
                                        record.product_quotes[0].container_capacity_40ft_hc,
                                      container_rate_20ft:
                                        record.product_quotes[0].container_rate_20ft,
                                      container_rate_40ft:
                                        record.product_quotes[0].container_rate_40ft,
                                      container_rate_40ft_hc:
                                        record.product_quotes[0].container_rate_40ft_hc,
                                      freight_rate_air: record.product_quotes[0].freight_rate_air,
                                      freight_rate_sea: record.product_quotes[0].freight_rate_sea,
                                      does_sea_freight_include_duties:
                                        record.product_quotes[0].does_sea_freight_include_duties,
                                      duty_rate: record.duty_rate,
                                      product_components: {
                                        data: record.product_quotes[0].components.map((el) => ({
                                          name: el.name,
                                        })),
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                          }

                          const resp = await dataProvider.create('product', { data: newProduct })

                          // console.log('NEWPROD', newProduct)

                          const { data: newRecord } = await dataProvider.getOne('product', {
                            id: resp.data.id,
                          })
                          // console.log('NEWRECORD', newRecord)
                          setProductData(newRecord)

                          // form.change('', productsList)
                          // await setProduct({ index, data: newRecord })
                          await setSaving(false)
                          setSearchOpen(false)
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                    >
                      Select
                    </Button>
                  )}
                />
              </Datagrid>
            </List>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box display="flex" width={1} justifyContent="center" style={{ paddingBottom: 50 }}>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
            <Button
              onClick={() => setSearchOpen(false)}
              size="large"
              style={{ background: '#21C6CF', color: '#fff' }}
              disabled={saving}
            >
              CLOSE
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default SCMQuoteCreateForm
