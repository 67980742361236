import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  useInput,
  //  required
} from 'react-admin'

import { useDataProvider } from 'react-admin'
import { useForm } from 'react-final-form'

const AutocompleteSpecificationArrayFieldInput = (props) => {
  const dataProvider = useDataProvider()
  //   const classes = useStyles()
  const form = useForm()
  const [options, setOptions] = useState([])

  const { reference, ...params } = props

  //   console.log(props)

  const {
    // eslint-disable-next-line no-unused-vars
    label,
    source,
    meta,
    className,
    input,
    resource,
    helperText,
    // isRequired,
  } = useInput(props)

  const { touched, error } = meta

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const getOptions = async () => {
      const resp = await dataProvider.getList(reference, {
        sort: { field: 'id', order: 'ASC' },
        pagination: { perPage: 100, page: 1 },
      })
      //   console.log(resp)
      !abortController.signal.aborted && setOptions(resp.data)
    }
    getOptions()
    return () => {
      abortController.abort()
    }
  }, [dataProvider])

  //   console.log(options)
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div
        style={{ width: '100%' }}
        className={`${props.className} MuiFormControl-root MuiTextField-root`}
      >
        <div
          className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl"
          style={{ width: '100%' }}
        >
          <Autocomplete
            //   value={values[resourceName] || ''}
            id="auto-outlined"
            {...input}
            name={source}
            // label={label}
            source={source}
            // isRequired={isRequired}
            resource={resource}
            //   error={!!(touched && error)}
            //   helperText={(touched && error) || helperText}
            options={options}
            getOptionLabel={(option) => {
              // console.log('OPTLABEL', option, typeof option)
              return option.project_name && option.client_name
                ? `${option?.project_name} - ${option.client_name}`
                : ''
            }}
            getOptionSelected={(option, value) => {
              //nothing that is put in here will cause the warning to go away
              if (value === '') {
                return true
              } else if (value === option) {
                return true
              }
            }}
            onChange={(e, v) => {
              // console.log(props.source, source, v)
              if (v !== null) {
                form.change(props.source, v)
              }
            }}
            renderOption={(option) =>
              option.project_name && option.client_name
                ? `${option?.project_name} - ${option.client_name}`
                : ''
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="" label={props.label} variant="standard" />
            )}
            className={`MuiTextField-root ${className || null}`}
            {...params}
          />
        </div>
        <p
          className={
            touched && error
              ? 'MuiFormHelperText-root MuiFormHelperText-contained Mui-error'
              : 'MuiFormHelperText-root MuiFormHelperText-contained'
          }
        >
          {touched && error ? helperText : null}
        </p>
      </div>
    </div>
  )
}

export default AutocompleteSpecificationArrayFieldInput
