import React from 'react'
import {
  Filter,
  List,
  SearchInput,
  Datagrid,
  // DateField,
  TextField,
  NumberField,
  FunctionField,
  TopToolbar,
  CreateButton,
  // EditButton,
} from 'react-admin'
import { Tooltip } from '@material-ui/core'
import { getDisplayName } from '../../../utils/utils'

const ListActions = (props) => {
  const { className } = props
  return (
    <TopToolbar className={className}>
      <CreateButton />
    </TopToolbar>
  )
}

export const ClientFilter = (props) => (
  <Filter {...props} filterValues={{ ...props.filterValues, qFields: ['email'] }}>
    <SearchInput InputProps={{ placeholder: 'email' }} source="q" alwaysOn />
  </Filter>
)

const ClientList = (props) => {
  return (
    <List
      {...props}
      // filter={{ clientId }}
      // filters={<ClientFilter />}
      perPage={25}
      actions={<ListActions />}
      empty={false}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="Id" />
        <FunctionField
          label="Name"
          render={(record) => {
            const name = record.name
            return name?.length > 20 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 20)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        <FunctionField
          label="Legal Name"
          render={(record) => {
            const name = record.legal_name
            return name?.length > 20 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 20)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        <TextField source="legal_representative" label="Legal Representative" />
        <TextField source="business_registration_number" label="Business Registration Number" />
        <TextField source="quotes_aggregate.aggregate.count" label="Active Quotes" />
        <FunctionField
          label="Owner"
          render={(record) => (record.owner ? getDisplayName(record.owner) : 'None')}
        />
        <FunctionField
          label="SCA"
          render={(record) => (record.default_sca ? getDisplayName(record.default_sca) : 'None')}
        />
        <NumberField source="members_aggregate.aggregate.count" label="Members" />
        {/* <DateField source="created_at" type="date" />
      <DateField source="updated_at" showTime /> */}
        <FunctionField
          label="Public/Private"
          render={(record) => (record.is_public ? 'Public' : 'Private')}
        />
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  )
}
export default ClientList
