import React from 'react'
import { TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column',
  },
  label: {
    paddingBottom: 15,
    width: 'auto',
    position: 'relative',
    justifyContent: 'flex-start',
  },
}))

const OutlinedTextInput = ({ variant = 'outlined', column = false, ...props }) => {
  const classes = useStyles()
  let columnLabel = ''
  let columnRoot = ''
  if (column) {
    columnLabel = classes.label
    columnRoot = classes.root
  }
  return (
    <TextInput
      variant={variant}
      className={columnRoot}
      InputLabelProps={{
        className: columnLabel,
      }}
      {...props}
    />
  )
}

OutlinedTextInput.defaultProps = TextInput.defaultProps

export default OutlinedTextInput
