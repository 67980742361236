import React from 'react'
import { Box } from '@material-ui/core'
// import useStyles from '../styles/index'

const OfferManagement = () => {
  //   const classes = useStyles()

  return (
    <Box p='0px 60px'>
      <Box
        m='20px'
        p='30px'
        style={{ minHeight: '100px', backgroundColor: 'lightGrey', borderRadius: '4px' }}
      />
      <Box>
        {/* <List
          {...props}
          sort={{ field: 'id', order: 'DESC' }}
          actions={null}
          perPage={25}
          bulkActionButtons={false}
          basePath='/offer'
          filter={{ status: 'PENDING_PURCHASE_ORDER' }}
        >
          <Datagrid>
            <TextField source='id' label='Order # (Date)' />
            <TextField source='platform' label='Seller / Platform' />
            <TextField source='owners' label='Inventory Owner(s)' />
            <NumberField source='quantity' label='Order Quantity' />
            <NumberField source='total' label='Order Total' />
          </Datagrid>
        </List> */}
      </Box>
      <Box mt='30px' display='flex'>
        <Box flex={1}>
          {/* <List
            {...props}
            sort={{ field: 'id', order: 'DESC' }}
            actions={null}
            perPage={25}
            bulkActionButtons={false}
            basePath='/offer'
            filter={{ status: 'PENDING_PURCHASE_ORDER' }}
          >
            <Datagrid>
              <TextField source='id' label='Investor' />
              <NumberField source='inventory' label='Inventory' />
            </Datagrid>
          </List> */}
        </Box>
        <Box flex={3} />
      </Box>
    </Box>
  )
}

export default OfferManagement
