import React from 'react'
import { FunctionField, TextField, DateField, useRedirect } from 'react-admin'
import { Box, Breadcrumbs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getDisplayName } from '../../../utils/utils'
import { useFormState } from 'react-final-form'

const useStyles = makeStyles({
  breadcrumbLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primaryPhoto: {
    // border: 'dashed gray 2px',
    width: '100px',
    height: '100px',
    '& img': {
      width: '100px',
      height: '100px',
    },
  },
  productId: {
    fontSize: '2rem',
    marginRight: '8px',
    lineHeight: '2.8rem',
  },
  productName: {
    fontSize: '2rem',
  },
  textLabel: {
    fontSize: '14px',
    minWidth: '20px',
  },
  textBody: {
    padding: '0px',
    margin: '0px',
  },
})

const ProductSummary = ({ id }) => {
  const classes = useStyles()
  const redirect = useRedirect()
  const { values } = useFormState()

  return (
    <Box display="flex" flexDirection="column">
      <Breadcrumbs>
        <Typography
          className={classes.breadcrumbLink}
          onClick={(e) => {
            e.stopPropagation()

            redirect('/products')
          }}
        >
          Products
        </Typography>
        <Typography>{id}</Typography>
      </Breadcrumbs>
      <Box display="flex" p="20px" alignItems="start" style={{ backgroundColor: 'white' }}>
        <img src={values.primary_photo?.url} className={classes.primaryPhoto} />
        <Box display="flex" flexDirection="column" width="100%" mt="10px" ml="20px">
          <Box display="flex" alignItems="start" width="100%">
            <Typography className={classes.productId}>{id}:</Typography>
            <TextField source="name" className={classes.productName} />
          </Box>
          <Box display="flex" mt="10px">
            <Box display="flex" flexDirection="column">
              {/* <Box display="flex">
                <span className={classes.textLabel}>Created:</span>
                <DateField className={classes.textBody} source="created_at" />
              </Box> */}
              <Box display="flex">
                <span className={classes.textLabel}>Updated:</span>
                <DateField className={classes.textBody} source="updated_at" />
              </Box>
              {/* <Box display="flex">
                <span className={classes.textLabel}>SCA:</span>
                <FunctionField render={(record) => getDisplayName(record.sca)} />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductSummary
