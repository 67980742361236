import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  title: {
    fontSize: '16px',
  },
  sectionTitle: {
    fontSize: '12px',
    marginTop: '5px',
  },
  bodyText: {
    fontSize: '10px',
    marginTop: '5px',
  },
  bodyTextHighlighted: {
    fontSize: '12px',
    marginTop: '5px',
    fontWeight: '600',
  },
  freightInnerBoxSea: {
    marginRight: '10px',
    borderRadius: '4px',
    backgroundColor: '#BBDEDF',
    padding: '10px 10px',
    border: '1px solid #00454E',
  },
  freightInnerBoxAir: {
    marginRight: '10px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    border: '1px solid #772349',
  },
  freightIconBoxSea: {
    height: '40px',
    width: '40px',
    borderRadius: '10px',
    backgroundColor: '#00454E',
  },
  freightIconBoxAir: {
    height: '40px',
    width: '40px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
  },
  freightTimeDesc: {
    padding: '5px 5px',
    fontSize: '10px',
  },
  freightTimeValue: {
    padding: '0px 5px',
    fontSize: '12px',
    fontWeight: '500',
  },
  numberInput: {
    width: '100%',
    height: '100%',
    // padding: '0px',
    '& .MuiFilledInput-underline::before': {
      // borderBottom: 'none',
    },
  },
  itemBoxDropZone: {
    width: '20%',
    minWidth: '120px',
    height: '30px',
    border: '#E0E0E0 solid 1px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '4px 5px',
    fontSize: '0.8125rem',
    fontWeight: '500',
  },
  itemBoxDZRemove: {
    // border: 'blue 1px solid',
    display: 'flex',
    flexFlow: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      transform: 'scale(0.6)',
      // position: 'absolute',
      // right: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  itemBoxDZPreview: {
    itemBoxDZPreview: {
      // border: 'green 1px solid',
      fontSize: '10px',
      lineHeight: '10px',
      color: '#10B1C1',
    },
  },
  itemBoxDZRoot: {
    margin: '0px',
    padding: '0px',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
  },
}))
