import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import { useDataProvider, useNotify } from 'react-admin'
import useStyles from '../styles'
import CheckoutForm from './checkout'
import NumberFormat from 'react-number-format'
import { getDisplayName, getDisplayAddress } from '../../../../utils/utils'

const OfferMakePurchase = ({ disabled, data, stripePromise }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(data)
  const [clientSecret, setClientSecret] = useState('')
  const [appUsers, setAppUsers] = useState([])
  const [clients, setClients] = useState([])
  const [clientAddresses, setClientAddresses] = useState([])
  const [billingAddress, setBillingAddress] = useState('')

  // console.log(billingAddress)
  const [client, setClient] = useState('')
  const [appUser, setAppUser] = useState('')
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(0)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userData } = await dataProvider.getAppUsers()
        !abortController.signal.aborted && setAppUsers(userData.app_user)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('organisation', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
          filter: { member_id: appUser.id },
        })
        !abortController.signal.aborted && setClients(clientsTemp)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, appUser])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('address', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'type', order: 'ASC' },
          filter: { organisation_id: client.id },
        })
        !abortController.signal.aborted && setClientAddresses(clientsTemp)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, client])

  useEffect(() => {
    const getPI = async () => {
      if (open) {
        await dataProvider
          .createOfferPurchaseOrder('offer', {
            clientId: appUser.default_organisation_id,
            buyerId: appUser.id,
            billingAddressId: billingAddress.id,
            platform: 'ADMIN',
            notes: '',
            offerId: data.id,
            quantity: parseInt(quantity),
          })
          .then((result) => {
            // console.log('Success:', result)
            setClientSecret(result.data.paymentIntentSecret)
          })
          .catch((error) => {
            console.error('Error:', error)
            notify(error.message.split('.')[0], { type: 'warning' })
          })
        // console.log(res)
      }
    }
    getPI()
  }, [open])

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" p="20px 0">
        {appUsers?.length > 0 ? (
          <Box display="flex" flexDirection="column">
            <span className={classes.title}>User Selection</span>
            <Box display="flex">
              <Select
                value={appUser}
                renderValue={(item) => getDisplayName(item, 20, 20, false)}
                onChange={(e) => setAppUser(e.target.value)}
                disabled={disabled}
                fullWidth
              >
                {appUsers?.map((item) => (
                  <MenuItem value={item} key={item.id}>
                    {getDisplayName(item, 20, 20, true)}
                  </MenuItem>
                ))}
              </Select>
              <Select
                style={{ marginLeft: '20px' }}
                value={client}
                fullWidth
                renderValue={(item) => item.name}
                onChange={(e) => setClient(e.target.value)}
                disabled={disabled}
              >
                {clients?.map((item) => (
                  <MenuItem value={item} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel
                style={{ marginTop: '10px', fontSize: '10px', marginLeft: '20px' }}
                id="billingAddressLabel"
              >
                Billing address:
              </InputLabel>
              <Select
                labelId="billingAddressLabel"
                style={{ width: '300px', marginLeft: '25px' }}
                value={billingAddress}
                renderValue={(item) => getDisplayAddress(item, 30)}
                onChange={(e) => setBillingAddress(e.target.value)}
              >
                {clientAddresses?.map((item) => (
                  <MenuItem value={item} key={item.id}>
                    {getDisplayAddress(item)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        ) : null}
        <Box display="flex">
          <Box display="flex" flexDirection="column" ml="20px">
            <span className={classes.bodyText}>Stripe Connected Account ID:</span>
            <span className={classes.bodyText}>{client?.stripe_connected_account_id || ''}</span>
          </Box>
          <Box display="flex" flexDirection="column" ml="20px">
            <span className={classes.bodyText}>Stripe Customer Account ID:</span>
            <span className={classes.bodyText}>{client?.stripe_customer_account_id || ''}</span>
          </Box>
        </Box>
      </Box>
      <Box p={'20px'}>
        <span className={classes.title}>Make a Purchase</span>
        <Box display="flex" flexDirection="row">
          <Box m="0 20px">
            <TextField
              id="standard-number"
              label="Quantity"
              type="number"
              value={quantity}
              variant="standard"
              onChange={(e) => {
                setQuantity(e.target.value)
              }}
              inputProps={{
                min: data.minimum_purchase_quantity > 0 ? data.minimum_purchase_quantity : 0,
                max: data.quote?.quantity - data.purchased?.aggregate?.sum?.quantity || null,
                step: data.minimum_purchase_quantity || 1,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
            />
          </Box>
          <Box m="10px 20px">
            <NumberFormat
              value={((data.investor_price_per_piece * quantity) / 100).toFixed(2) || '$0.00'}
              // className='numberFormat'
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
          </Box>
        </Box>
        <Box m="10px 20px">
          <Button
            className={classes.makePurchaseButton}
            disabled={
              !appUser?.id || !billingAddress?.id || quantity < 1
                ? true
                : false || data.moq <= data.purchased?.aggregate?.sum?.quantity
            }
            onClick={handleOpen}
          >
            Make Purchase
          </Button>
        </Box>
        {appUser?.id && !(data.moq <= data.purchased?.aggregate?.sum?.quantity) && (
          <MakePurchaseOrderDialog
            open={open}
            handleClose={handleClose}
            clientSecret={clientSecret}
            options={options}
            stripePromise={stripePromise}
            offerId={data.id}
          />
        )}
      </Box>
    </Box>
  )
}

const MakePurchaseOrderDialog = ({
  open,
  handleClose,
  clientSecret,
  options,
  stripePromise,
  offerId,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <Box>
          <DialogTitle>Make a Purchase</DialogTitle>
          {clientSecret && open && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm id={offerId} />
            </Elements>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default OfferMakePurchase
