import React, { useEffect, useState } from 'react'
import {
  // AutocompleteInput,
  AutocompleteArrayInput,
  useCreate,
  useNotify,
  useDataProvider,
  FormWithRedirect,
} from 'react-admin'
import { Box, CircularProgress } from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
// import useStyles from '../styles'

import TagSelectWithCreateForm from './TagSelectWithCreateForm'

const TagSelectWithCreate = (props) => {
  // const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [create, { loading }] = useCreate('tags')

  const form = useForm()
  const { values } = useFormState()
  // const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [tags, setTags] = useState([])
  // const [countries, setCountries] = useState([])
  // const [states, setStates] = useState([])
  // const [cities, setCities] = useState([])

  // console.log(props)

  const {
    source = 'tags',
    resourceName,
    referenceField,
    referenceId,
    fieldName,
    fieldLabel,
    oldData,
    // data,
    ...rest
  } = props

  const handleChange = (option) => {
    const newOptions = [...tags]
    newOptions.push(option)
    const abortController = new AbortController()
    !abortController.signal.aborted && setTags(newOptions)
    // console.log(newOptions)
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data: tagsTemp } = await dataProvider.getList('tag', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
        !abortController.signal.aborted && setTags(tagsTemp)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })

        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  const handleClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(true)
  }

  const handleCloseClick = () => {
    const abortController = new AbortController()
    !abortController.signal.aborted && setShowDialog(false)
  }

  useEffect(() => {
    return () => {}
  }, [])

  const handleSubmit = async (params) => {
    // console.log(params)
    const abortController = new AbortController()
    if (tags.some((tag) => tag.name === params.tag_name)) {
      // console.log('Already exists')
      !abortController.signal.aborted && setShowDialog(false)
      // console.log('changing')
      form.change(source, [
        ...values[source],
        tags.find((item) => item.name === params.tag_name).id,
      ])
      return null
    }
    create(
      {
        payload: {
          data: {
            name: params.tag_name,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          // console.log(data, values[source])
          const oldTags = [...values[source]]
          // console.log('changing')
          !abortController.signal.aborted && form.change(source, [...oldTags, data.id])
          // console.log([...oldTags, data.id])
          handleChange({ id: data.id, name: params.tag_name })
          !abortController.signal.aborted && setShowDialog(false)
        },
        onFailure: (resp) => {
          console.log('error', resp.message)
          notify(resp.message, { type: 'error' })
        },
      },
    )
  }

  // console.log('TAGWITHCREATE', props, rest)

  // return editing ? (
  return (
    <Box display="flex" alignItems="center">
      <AutocompleteArrayInput
        disabled={!editing}
        variant="standard"
        source={source}
        choices={tags}
        label={fieldLabel}
        {...rest}
      />

      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              // const formState = form.getState()

              // console.log(values[source])
              // console.log(values.product_tags)

              setSaving(true)
              try {
                // Compare new values to old and insert difference
                const newValues = []
                values[source]?.map((item) => {
                  if (!oldData.some((oldItem) => oldItem.tag?.id === item)) {
                    newValues.push({ [fieldName]: item, [referenceField]: referenceId })
                  }
                })

                // Compare old values to new and delete difference
                const deleteValues = []
                oldData.map((oldItem) => {
                  if (!values[source]?.some((item) => item === oldItem.tag?.id)) {
                    deleteValues.push(oldItem.id)
                  }
                })

                // console.log(newValues, deleteValues, resourceName)

                if (newValues.length > 0) {
                  await dataProvider.create(resourceName, {
                    data: newValues,
                  })
                  // console.log(resp)
                }
                if (deleteValues.length > 0) {
                  await dataProvider.deleteMany(resourceName, {
                    ids: deleteValues,
                  })
                }
                setSaving(false)

                const newChosenItems = tags
                  .filter((item) => values[source].includes(item.id))
                  .map((tag) => {
                    return { tag: tag }
                  })

                // console.log('changing')
                form.change(resourceName, newChosenItems)
                setEditing(false)
                notify('Update Complete', { type: 'info' })
              } catch (error) {
                setSaving(false)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>

          <FormWithRedirect
            resource="tags"
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <TagSelectWithCreateForm
                handleClick={handleClick}
                handleCloseClick={handleCloseClick}
                showDialog={showDialog}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                loading={loading}
              />
            )}
          />

          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <Box display="flex">
          {/* <span className={classes.fieldLabelEditable}>{fieldLabel}</span>
      <span className={classes.fieldValueEditable}>
        {values.product_tags?.map((item, index) => {
          return index !== 0 ? ', ' + item.tag?.name : item.tag?.name
        })} */}
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
          {/* </span> */}
        </Box>
      )}
    </Box>
  )
}

export default TagSelectWithCreate
