import React, {
  // useState,
  useEffect,
} from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  //   Paper,
  // InputAdornment,
  // Dialog,
  // DialogContent,
  // DialogTitle,
  // DialogActions,
  Typography,
  // TextField,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { withStyles } from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import WarningIcon from '@material-ui/icons/Warning'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
// import { useFormState } from 'react-final-form'
// import Skeleton from '@material-ui/lab/Skeleton'
import {
  // required,
  AutocompleteInput,
  //   RadioButtonGroupInput,
  NumberInput,
  FileField,
  ImageField,
  ImageInput,
  FileInput,
  DateInput,
  ReferenceInput,
  // SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  useDataProvider,
  useNotify,
  // TextField,
  // useCreateSuggestionContext,
  // useCreate,
} from 'react-admin'
// import { uniqueId } from 'lodash'
// import Autocomplete from '@material-ui/lab/Autocomplete'
// import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import TextInput from '../../../../components/OutlinedTextInput'
import SelectInput from '../../../../components/input/SelectInput'
import ChipInput from '../../../../components/input/ChipInput'
import CostsAndPricing from './CostsAndPricing'
import useStyles from '../styles'
// import { ColorInput } from '../../../components/ColourPicker'
import Confirm from '../../../components/Confirm'
// import { getDisplayName } from '../../../utils/utils'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#1E1E1E',
    color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const FormData = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  // const refresh = useRefresh()

  //   console.log('FDPROPS', props)
  const {
    // packagingTypes,
    logisticsProviders,
    qualityAgencies,
    // banks,
    // bankRegions,
    addresses,
    // certificates,
    // categories,
    quoteData,
    // materials,
    supplierID,
    // certificationTypes,
    // features,
    // setFeatures,
    factoryMilestones,
    qualityMilestones,
    logisticsMilestones,
    inspectionTypes,
    inspectionLevels,
    // currencies,
    // countries,
    // provinces,
    // cities,
    readOnly,
    formProps,
    handleSave,
    // handleConfirm,
    // recordStatus,
  } = props
  const userID = JSON.parse(localStorage.getItem('user')).id

  const { values, errors } = useFormState()

  // console.log('VALS', values)

  // const errs = []
  // {
  //   Object.values(errors).forEach(val => errs.push(val))
  // }

  // console.log(valid, pristine, dirty, values, errors)

  //   console.log(banks.filter(item => item.region === values.supplierBankRegion))

  //   const classes = useStyles()
  //   return (
  //     <Box className={classes.outerBox}>
  //       <span className={classes.title}>Offer Input</span>

  //       <QuoteItem
  //         categories={categories}
  //         materials={materials}
  //         packagingTypes={packagingTypes}
  //         features={features}
  //         setFeatures={setFeatures}
  //         userID={user.id}
  //         certificationTypes={certificationTypes}
  //         supplierID={supplierID}
  //         certificates={values.item ? values?.item?.certificates : []}
  //       />
  //     </Box>
  //   )
  // }

  // export const QuoteItem = props => {
  //   const {
  //     certificationTypes,
  //     packagingTypes,
  //     categories,
  //     materials,
  //     certificates,
  //     features,
  //     setFeatures,
  //     userID,
  //     readOnly,
  //     supplierID,
  //   } = props

  const [loading, setLoading] = React.useState(true)
  // const [certsOpen, setCertsOpen] = React.useState(false)
  // const [certs, setCerts] = React.useState([])
  const [chosenSupplier, setChosenSupplier] = React.useState(
    supplierID || values.client_quote?.product_quote?.supplier?.id || null,
  )
  // const [supplierCategories, setSupplierCategories] = React.useState([])
  //   const [supplierAddresses, setSupplierAddresses] = React.useState([])
  //   const [supplierContacts, setSupplierContacts] = React.useState([])
  const [supplierData, setSupplierData] = React.useState([])

  // const [productMaterials, setProductMaterials] = React.useState(values.productMaterials)

  // console.log(productMaterials, supplierCategories)

  // useEffect(() => {
  //   const setSupCats = async () => {
  //     if (values?.categories?.length > 0 && categories?.length > 0) {
  //       const cats = []
  //       categories.filter((item) => {
  //         values.categories.map((selCat) => {
  //           if (selCat.id === item.id) cats.push(item)
  //         })
  //       })
  //       setSupplierCategories(cats)
  //     }
  //   }
  //   setSupCats()
  //   // } else {
  //   //   setSupplierCategories([])
  // }, [dataProvider, notify, categories])

  const getMilestones = (x) => x * 5
  const milestones = Array.from(Array(20), (_, x) => {
    const v = getMilestones(x + 1)
    return {
      name: v.toString(),
      value: v,
    }
  })

  // Validations for each 'form' when clicking SAVE
  // MediaForm
  // const isMediaFormValid = () => {
  //     if values.
  // }

  // Actions to take place when changing supplier
  // 1. Set all supplier related fields to empty
  // 2. Get new supplier details from backend
  // 3. Store supplier lists in state
  // 4. Set any default form values if applicable
  useEffect(() => {
    const getSupData = async () => {
      setLoading(true)
      form.change('supplierPickupAddress', '')
      form.change('supplierBillingAddress', '')
      form.change('supplierBeneficiaryAddress', '')
      form.change('supplierFactoryAddress', '')
      form.change('supplierContact', '')

      //   const { data: catsTemp } = await dataProvider.getList('supplier_categories', {
      //     id: chosenSupplier,
      //   })
      //   form.change('categoryIds', cats)

      //   const { data: addsTemp } = await dataProvider.getSupplierAddresses('getSupplierAddresses', {
      //     id: chosenSupplier,
      //   })
      //   const adds = addsTemp.map(cat => cat.category)

      const { data: supTemp } = await dataProvider.getOne('supplier', {
        id: chosenSupplier,
      })
      await setSupplierData(supTemp)
      //   setSupplierContacts(supTemp.contacts)
      if (supTemp.primary_address) {
        form.change('supplierFactoryAddress', supTemp.primary_address?.id)
      }
      if (supTemp.pickup_address) {
        form.change('supplierPickupAddress', supTemp.pickup_address?.id)
      }
      if (supTemp.billing_address) {
        form.change('supplierBillingAddress', supTemp.billing_address?.id)
      }
      if (supTemp.primary_contact) {
        form.change('supplierContact', supTemp.primary_contact?.id)
      }

      //   const cats = supTemp.categories?.map(cat => cat.category)
      //   form.change('categoryIds', cats)

      //   setSupplierCategories(cats)

      //   console.log(supTemp)

      setLoading(false)
    }
    if (chosenSupplier) {
      getSupData()
      // } else {
      //   setSupplierCategories([])
      //   setSupplierAddresses([])
    }
  }, [dataProvider, notify, chosenSupplier])

  // const handleClose = (e) => {
  //   e.stopPropagation()
  //   // console.log('closing')
  //   setCertsOpen(false)
  //   // setCerts(certificates)
  //   refresh()
  // }

  const chosenQuality =
    values.quality_agency_id && qualityAgencies?.length > 0
      ? qualityAgencies?.filter((item) => item.id === values.quality_agency_id)[0]
      : {}

  const chosenLogistics =
    values.logistics_agency_id && logisticsProviders?.length > 0
      ? logisticsProviders?.filter((item) => item.id === values.logistics_agency_id)[0]
      : {}

  // const filteredProvinces = values.beneficiaryCountry
  //   ? provinces.filter(item => item.country_code === values.beneficiaryCountry)
  //   : []
  // const filteredCities = values.beneficiaryProvince
  //   ? cities.filter(item => item.parent_id === values.beneficiaryProvince)
  //   : []

  //   console.log(values.beneficiaryCountry)

  //   console.log(supplierData)
  //   console.log(values)
  //   const { values } = useFormState()
  //   console.log(filteredProvinces)
  //   console.log(filteredCities)

  return (
    <Box className={classes.outlineBox}>
      {/* <Box p='20px' style={{ height: '200px' }}>
        Summary
      </Box> */}
      <Box display="flex" style={{ border: 'lightgrey solid 1px' }}>
        <Box flex={2} />
        <Box flex={6} display="flex" flexDirection="column" className={classes.centerBox}>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Photos and Media</Typography>
              {values?.primary_photo && values?.thumbnail ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box mt="10px">
                  <span className={classes.filesLabel}>Primary Photo</span>
                </Box>
                <ImageInput
                  source="primary_photo"
                  label=""
                  accept="image/*"
                  maxSize={5000000}
                  //   validate={required()} // Enable Required
                  classes={{
                    root: classes.imageDropRoot,
                    dropZone: classes.imageDropZone,
                    preview: classes.imagePreview,
                    removeButton: classes.imageRemove,
                  }}
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <ImageField source="url" title="filename" />
                </ImageInput>

                <Box mt="10px">
                  <span className={classes.filesLabel}>Additional Product Photos</span>
                </Box>
                <FileInput
                  column
                  source="photos"
                  multiple
                  classes={{
                    dropZone: classes.itemBoxDropZone,
                    removeButton: classes.itemBoxDZRemove,
                    preview: classes.itemBoxDZPreview,
                    root: classes.itemBoxDZRoot,
                  }}
                  label=""
                  addLabel="false"
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <FileField target="photos" source="url" title="filename" />
                </FileInput>
                <Box mt="10px">
                  <span className={classes.filesLabel}>Thumbnail</span>
                </Box>
                <ImageInput
                  source="thumbnail"
                  label=""
                  accept="image/*"
                  maxSize={5000000}
                  //   validate={required()} // Enable Required
                  classes={{
                    root: classes.thumbnailDropRoot,
                    dropZone: classes.thumbnailDropZone,
                    preview: classes.thumbnailPreview,
                    removeButton: classes.thumbnailRemove,
                  }}
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <ImageField source="url" title="thumbnail" />
                </ImageInput>
                {/* <Box mt="10px">
                  <span className={classes.filesLabel}>Facebook Photos</span>
                </Box>
                <FileInput
                  column
                  source="facebookPhotos"
                  multiple
                  //   validate={required()} // Enable Required
                  classes={{
                    dropZone: classes.itemBoxDropZone,
                    removeButton: classes.itemBoxDZRemove,
                    preview: classes.itemBoxDZPreview,
                    root: classes.itemBoxDZRoot,
                  }}
                  label=""
                  addLabel="false"
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <FileField target="facebookPhotos" source="url" title="filename" />
                </FileInput>
                <Box mt="10px">
                  <span className={classes.filesLabel}>Instagram Photos</span>
                </Box>
                <FileInput
                  column
                  source="instagramPhotos"
                  multiple
                  //   validate={required()} // Enable Required
                  classes={{
                    dropZone: classes.itemBoxDropZone,
                    removeButton: classes.itemBoxDZRemove,
                    preview: classes.itemBoxDZPreview,
                    root: classes.itemBoxDZRoot,
                  }}
                  label=""
                  addLabel="false"
                  placeholder={
                    <div className={classes.placeholder}>
                      <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                    </div>
                  }
                >
                  <FileField target="instagramPhotos" source="url" title="filename" />
                </FileInput> */}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Description and Specifications</Typography>
              {values?.categories?.length > 0 &&
              values?.long_description &&
              values?.short_description &&
              values?.name ? (
                // values?.productColours?.length > 0 &&
                // values?.productMaterials?.length > 0 &&
                // values?.upc_code ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box display="flex">
                  <Box flex={1} pr="5px">
                    <TextInput
                      variant="standard"
                      fullWidth
                      // column
                      source="name"
                      label="Product Name:"
                      //   validate={required()} // Enable Required
                    />
                  </Box>
                  <Box flex={1} pl="5px">
                    <span className={classes.labelText}>{quoteData.sku}</span>
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <NumberInput
                    source="moq"
                    label="Target Quantity"
                    fullWidth
                    variant="standard"
                    margin="none"
                  />
                  <NumberInput
                    source="price"
                    label="Investor Price ($)"
                    fullWidth
                    variant="standard"
                    margin="none"
                    style={{ marginLeft: '10px' }}
                  />
                  <Box flex={2} />
                </Box>
                <Box display="flex" position="relative">
                  <Box flex={2} display="contents">
                    {/* {categories?.length > 0 ? (
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={categories}
                        limitTags={2}
                        // disabled={loading}
                        value={supplierCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        //   isOptionEqualToValue={(option, value) => option.id === value.id} // for MUI V5+, replaces getOptionSelected
                        onChange={(e, v) => {
                          setSupplierCategories(v)
                          form.change('categories', v)
                        }}
                        filterSelectedOptions
                        className={classes.autocompleteSelect}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Categories"
                            placeholder=""
                            variant="standard"
                            fullWidth
                            className={classes.autocompleteField}
                          />
                        )}
                      />
                    ) : (
                      <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
                    )}
                    {loading && chosenSupplier ? (
                      <CircularProgress
                        size={24}
                        style={{
                          position: 'absolute',
                          marginLeft: '60px',
                        }}
                      />
                    ) : null} */}
                    {loading && chosenSupplier ? (
                      <CircularProgress
                        size={24}
                        style={{
                          position: 'absolute',
                          marginLeft: '60px',
                        }}
                      />
                    ) : null}
                    {loading && chosenSupplier ? (
                      <CircularProgress
                        size={24}
                        style={{
                          position: 'absolute',
                          marginLeft: '60px',
                        }}
                      />
                    ) : null}
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <Box position="relative">
                      <TextInput
                        variant="outlined"
                        fullWidth
                        column
                        multiline
                        minRows={2}
                        inputProps={{
                          maxLength: 250,
                          style: {
                            marginBottom: 15,
                            alignItems: 'baseline',
                          },
                        }}
                        source="short_description"
                        label="Short Description:"
                        // validate={required()} // Enable Required
                      />
                      <span
                        style={{
                          position: 'absolute',
                          right: '20px',
                          bottom: '20px',
                          fontSize: '10px',
                        }}
                      >
                        {values.short_description?.length || 0}/250
                      </span>
                    </Box>
                    <Box position="relative">
                      <TextInput
                        variant="outlined"
                        fullWidth
                        column
                        multiline
                        minRows={6}
                        inputProps={{
                          maxLength: 350,
                          style: {
                            marginBottom: 15,
                            alignItems: 'baseline',
                          },
                        }}
                        source="long_description"
                        label="Long Description:"
                        // validate={required()}
                      />
                      <span
                        style={{
                          position: 'absolute',
                          right: '20px',
                          bottom: '20px',
                          fontSize: '10px',
                        }}
                      >
                        {values.long_description?.length || 0}/350
                      </span>
                    </Box>
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <Box>
                      <span className={classes.labelText}>Special Features:</span>
                      <ArrayInput source="features" label="">
                        <SimpleFormIterator
                          getItemLabel={(index) => `${index + 1}. feature`}
                          TransitionProps={{ enter: false, exit: false }}
                          addButton={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <Button
                              style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}
                            >
                              <AddIcon />
                            </Button>
                          }
                          removeButton={
                            <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                          }
                        >
                          <FormDataConsumer>
                            {({ getSource, scopedFormData, rest }) => (
                              <Box display="flex">
                                <TextInput
                                  source={getSource('label')}
                                  {...rest}
                                  record={scopedFormData}
                                  variant="outlined"
                                  fullWidth
                                  column
                                  label="Feature:"
                                  style={{ maxHeight: '25px' }}
                                  // validate={required()}
                                />
                                <TextInput
                                  source={getSource('content')}
                                  {...rest}
                                  record={scopedFormData}
                                  variant="outlined"
                                  fullWidth
                                  column
                                  multiline
                                  minRows={6}
                                  inputProps={{
                                    maxLength: 300,
                                    style: {
                                      marginBottom: 15,
                                      alignItems: 'baseline',
                                    },
                                  }}
                                  label="Description:"
                                  // validate={required()}
                                />
                              </Box>
                            )}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    </Box>
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <Box>
                      <span className={classes.labelText}>Tags:</span>
                      <ChipInput
                        name="tags"
                        label="Value"
                        fullWidth
                        variant="outlined"
                        margin="none"
                        style={{ width: '100%' }}
                        defaultValue={values.tags}
                        // className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                        className={classes.hideLabelAutoComplete}
                        // validate={required()} // Enable Required
                      />
                    </Box>
                  </Box>
                  <Box flex={1} />
                </Box>
                {/* <Box>
                  <span style={{ padding: '10px 0px' }} className={classes.labelText}>
                    Key Selling Features:
                  </span>
                  {loading && features?.length < 1 ? (
                    <Box display="flex" alignItems="flex-end" ml="120px" mt="-10px">
                      <CircularProgress size={25} thickness={2} />
                    </Box>
                  ) : null}
                </Box> */}
                {/* <Box display="flex">
                  <Box flex={1}>
                    <ArrayInput source="features" label="">
                      <SimpleFormIterator
                        getItemLabel={(index) => `${index + 1}. feature`}
                        TransitionProps={{ enter: false, exit: false }}
                        addButton={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Button
                            style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}
                          >
                            <AddIcon />
                          </Button>
                        }
                        removeButton={
                          <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                        }
                      >
                        <FormDataConsumer>
                          {({ getSource, scopedFormData, rest }) => (
                            <>
                              <AutocompleteInput
                                optionText="name"
                                optionValue="id"
                                source={getSource('id')}
                                {...rest}
                                record={scopedFormData}
                                label="Feature:"
                                choices={features}
                                variant="outlined"
                                fullWidth
                                create={
                                  <CreateFeature
                                    features={features}
                                    setFeatures={setFeatures}
                                    setLoading={setLoading}
                                  />
                                }
                                className={`${classes.inputSelect} ${classes.hideLabel}`}
                                // validate={required()} // Enable Required
                              />
                            </>
                          )}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Box flex={1} />
                </Box> */}
                {/* <span className={classes.labelText}>Specifications:</span>
                <Box display="flex" mt="20px">
                  <Box flex={1} display="flex" alignItems="center" mb="20px" pl="10px">
                    <span className={classes.labelText}>Colour(s)</span>
                  </Box>
                  <Box flex={2} ml="30px" display="flex">
                    <Box display="flex">
                      <ArrayInput source="productColours" label="">
                        <SimpleFormIterator
                          TransitionProps={{ enter: false, exit: false }}
                          addButton={
                            <Button
                              style={{ width: '100px', backgroundColor: '#F8F8FA', color: 'black' }}
                            >
                              <AddIcon />
                            </Button>
                          }
                          removeButton={
                            <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                          }
                        >
                          <FormDataConsumer>
                            {({ getSource, scopedFormData, rest }) => (
                              <Box flex={1}>
                                <ColorInput
                                  source={getSource('option_value.value')}
                                  {...rest}
                                  record={scopedFormData}
                                  className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                                  picker="Sketch"
                                  label=""
                                  validate={required()}
                                />
                              </Box>
                            )}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    </Box>
                  </Box>
                  <Box width="24px" />
                </Box> */}
                {/* <Box display="flex">
                  <Box flex={1} display="flex" alignItems="center" mb="20px" pl="10px">
                    <span className={classes.labelText}>Material(s)</span>
                  </Box>
                  <Box flex={2} ml="30px">
                    <Box display="flex" position="relative">
                      <Box flex={1} display="contents">
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={materials}
                          limitTags={2}
                          disabled={materials.length < 1}
                          value={productMaterials}
                          getOptionLabel={(option) =>
                            option.option_value?.value || option.name || ''
                          }
                          getOptionSelected={(option, value) => {
                            // console.log(option, value)
                            return (
                              (option.option_value?.value || option.name) ===
                              (value.option_value?.value || value.name)
                            )
                          }}
                          //   isOptionEqualToValue={(option, value) => option.id === value.id} // for MUI V5+, replaces getOptionSelected
                          onChange={(e, v) => {
                            setProductMaterials(v)
                            form.change('productMaterials', v)
                          }}
                          filterSelectedOptions
                          className={classes.autocompleteSelect}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Materials"
                              placeholder=""
                              variant="outlined"
                              fullWidth
                              className={`${classes.hideLabelAutoComplete} ${classes.autocompleteField}`}
                              // className={classes.autocompleteField}
                            />
                          )}
                        />
                      </Box>
                      <Box flex={1} />
                    </Box>
                  </Box>
                  <Box width="24px" />
                </Box> */}

                {/* <ArrayInput source="specifications" label="">
                  <SimpleFormIterator
                    TransitionProps={{ enter: false, exit: false }}
                    addButton={
                      <Button
                        style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}
                      >
                        <AddIcon />
                      </Button>
                    }
                    removeButton={
                      <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                    }
                  >
                    <FormDataConsumer>
                      {({ getSource, scopedFormData, rest }) => (
                        <Box display="flex">
                          <Box flex={1}>
                            <TextInput
                              source={getSource('name')}
                              {...rest}
                              record={scopedFormData}
                              label="Name"
                              fullWidth
                              variant="outlined"
                              margin="none"
                              className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                              //   validate={required()} // Enable Required
                            />
                          </Box>
                          <Box flex={2} ml="30px">
                            <ChipInput
                              name={getSource('value')}
                              {...rest}
                              defaultValue={scopedFormData?.value}
                              record={scopedFormData}
                              label="Value"
                              fullWidth
                              variant="outlined"
                              margin="none"
                              className={classes.hideLabelAutoComplete}
                              //   validate={required()} // Enable Required
                            />
                          </Box>
                        </Box>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput> */}
                <Box mt="10px">
                  <span className={classes.filesLabel}>UPC Code</span>
                </Box>
                <Box display="flex">
                  <Box flex={1} display="flex">
                    <ImageInput
                      source="upc_code_photo"
                      label=""
                      accept="image/*"
                      maxSize={5000000}
                      //   validate={required()} // Enable Required
                      classes={{
                        root: classes.thumbnailDropRoot,
                        dropZone: classes.thumbnailDropZone,
                        preview: classes.thumbnailPreview,
                        removeButton: classes.thumbnailRemove,
                      }}
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                    >
                      <ImageField source="url" title="filename" />
                    </ImageInput>
                    <Box ml="20px">
                      <TextInput
                        variant="standard"
                        fullWidth
                        column
                        source="upc_code"
                        label="UPC Code:"
                        // validate={required()}
                      />
                    </Box>
                  </Box>

                  <Box flex={2} />
                </Box>
                {/* <Box flex={1} mt="20px" display="flex">
                  <Box flex={1} display="flex" justifyContent="flex-start" flexDirection="column">
                    <span className={classes.filesLabel}>Certificates</span>
                    <Button
                      disabled
                      className={classes.addBoxButton}
                      onClick={() => setCertsOpen(true)}
                    >
                      <div className={classes.placeholder}>
                        <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                      </div>
                    </Button>
                    <Dialog
                      onClose={handleClose}
                      open={certsOpen}
                      classes={{ paper: classes.paper }}
                      maxWidth="md"
                    >
                      <DialogTitle className={classes.checkModelHeader}>
                        <Box width={800} style={{ maxHeight: 820 }}>
                          <Typography variant="h5" gutterBottom>
                            Product Certificates
                          </Typography>
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        <Box>
                          <ArrayInput source="certificates" label="">
                            <SimpleFormIterator
                              TransitionProps={{ enter: false, exit: false }}
                              addButton={
                                <Button
                                  fullWidth
                                  style={{ backgroundColor: '#F8F8FA', color: 'black' }}
                                >
                                  <AddIcon />
                                  Add new
                                </Button>
                              }
                              removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer' }} />}
                            >
                              <FormDataConsumer>
                                {({ getSource, scopedFormData, rest }) => (
                                  <Box display="flex" padding="20px">
                                    <Box flex={1}>
                                      <SelectInput
                                        label="Certificate"
                                        source={getSource('name')}
                                        {...rest}
                                        record={scopedFormData}
                                        choices={certificationTypes}
                                        optionText="name"
                                        optionValue="value"
                                        defaultValue=""
                                        fullWidth
                                        className={classes.dialogInput}
                                      />
                                      <TextInput
                                        source={getSource('notifying_body')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Issued By"
                                        fullWidth
                                        variant="outlined"
                                        margin="none"
                                        className={classes.dialogInput}
                                        //   validate={required()} // Enable Required
                                      />
                                      <TextInput
                                        source={getSource('number')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Certificate Number"
                                        fullWidth
                                        variant="outlined"
                                        margin="none"
                                        className={classes.dialogInput}
                                        //   validate={required()} // Enable Required
                                      />
                                    </Box>
                                    <Box flex={1}>
                                      <DateInput
                                        source={getSource('valid_start_date')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Issue Date"
                                        fullWidth
                                        variant="outlined"
                                        margin="none"
                                        className={classes.dialogInput}
                                        //   validate={required()} // Enable Required
                                      />
                                      <DateInput
                                        source={getSource('valid_end_date')}
                                        {...rest}
                                        record={scopedFormData}
                                        label="Expiry Date"
                                        fullWidth
                                        variant="outlined"
                                        margin="none"
                                        className={classes.dialogInput}
                                        //   validate={required()} // Enable Required
                                      />
                                    </Box>
                                    <Box flex={1}>
                                      <FileInput
                                        column
                                        margin="none"
                                        source={getSource('document')}
                                        {...rest}
                                        record={scopedFormData}
                                        classes={{
                                          dropZone: classes.itemBoxDropZoneCert,
                                          removeButton: classes.itemBoxDZRemoveCert,
                                          preview: classes.itemBoxDZPreview,
                                          root: classes.itemBoxDZRoot,
                                        }}
                                        label=""
                                        addLabel="false"
                                        accept="application/pdf"
                                        placeholder={
                                          <div className={classes.itemBoxUploadPlaceholder}>
                                            Upload
                                          </div>
                                        }
                                      >
                                        <FileField source="url" title="filename" />
                                      </FileInput>
                                    </Box>
                                  </Box>
                                )}
                              </FormDataConsumer>
                            </SimpleFormIterator>
                          </ArrayInput>
                        </Box>
                      </DialogContent>

                      <DialogActions>
                        <Box
                          display="flex"
                          width={1}
                          justifyContent="center"
                          style={{ paddingBottom: 50 }}
                        >
                          <Button
                            onClick={handleClose}
                            size="large"
                            style={{ background: '#21C6CF', color: '#fff' }}
                          >
                            CLOSE
                          </Button>
                        </Box>
                      </DialogActions>
                    </Dialog>
                    <Box mt="20px">
                      <span className={classes.filesLabel}>Design Files</span>
                      <FileInput
                        column
                        source="designFiles"
                        multiple
                        classes={{
                          dropZone: classes.itemBoxDropZone,
                          removeButton: classes.itemBoxDZRemove,
                          preview: classes.itemBoxDZPreview,
                          root: classes.itemBoxDZRoot,
                        }}
                        label=""
                        addLabel="false"
                        placeholder={
                          <div className={classes.placeholder}>
                            <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                          </div>
                        }
                      >
                        <FileField source="url" title="filename" />
                      </FileInput>
                    </Box>
                  </Box>
                  <Box flex={2} display="flex" flexDirection="column">
                    {values?.certificates?.map((cert, ind) => (
                      <span
                        key={`certOpen${cert?.id}${uniqueId()}`}
                        style={{ margin: '4px 0px', fontSize: '12px' }}
                      >{`${ind}. ${cert?.name}`}</span>
                    ))}
                  </Box>
                </Box> */}
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>Dimensions</Typography>
              {values?.productDimensions?.length &&
              values?.productDimensions?.width &&
              values?.productDimensions?.height &&
              values?.productDimensions?.weight &&
              values?.packagingDimensions?.length &&
              values?.packagingDimensions?.width &&
              values?.packagingDimensions?.height &&
              values?.packagingDimensions?.weight &&
              values?.cartonDimensions?.length &&
              values?.cartonDimensions?.width &&
              values?.cartonDimensions?.height &&
              values?.cartonDimensions?.weight &&
              values?.pieces_per_carton &&
              values?.quote?.packaging_type ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column">
                <Box mt="10px" p="10px">
                  <span className={classes.filesLabel}>Product Dimensions</span>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            L (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            W (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            H (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            G.W. (KG)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="productDimensions">
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="productDimensions.length"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="productDimensions.width"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="productDimensions.height"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="productDimensions.weight"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt="10px" p="10px">
                  <span className={classes.filesLabel}>Packaging Dimensions</span>
                  <Box width="30%">
                    <Box flex={2}>
                      {packagingTypes.length > 0 && (
                        <SelectInput
                          label="Packaging Type:"
                          source="quote.packaging_type"
                          choices={packagingTypes}
                          optionText="name"
                          optionValue="value"
                          defaultValue=""
                          fullWidth
                          // validate={required()} // Enable Required
                          variant="standard"
                          //   className={classes.hideLabelAutoComplete}
                        />
                      )}
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            L (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            W (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            H (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            G.W. (KG)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="packagingDimensions">
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packagingDimensions.length"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packagingDimensions.width"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packagingDimensions.height"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="packagingDimensions.weight"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt="10px" p="10px">
                  <span className={classes.filesLabel}>Shipping Carton Dimensions</span>

                  <Box width="40%">
                    <Box flex={2}>
                      <NumberInput
                        source="pieces_per_carton"
                        label="Pieces per Carton:"
                        fullWidth
                        variant="standard"
                        margin="none"
                        //   className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                        //   className={classes.dialogInput}
                        // validate={required()} // Enable Required
                      />
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            L (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            W (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            H (CM)
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                            align="center"
                          >
                            G.W. (KG)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="cartonDimensions">
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="cartonDimensions.length"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="cartonDimensions.width"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="cartonDimensions.height"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                          <BodyTableCell align="right" style={{ padding: '0px' }}>
                            <NumberInput
                              source="cartonDimensions.weight"
                              label=""
                              fullWidth
                              variant="filled"
                              margin="none"
                              className={classes.tableInput}
                              //   validate={required()} // Enable Required
                            />
                          </BodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion> */}
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Factory Quote</Typography>
              {values?.client_quote?.product_quote?.supplier?.id &&
              values?.quantity &&
              values?.price &&
              values?.manufacturing_lead_time &&
              // values?.quote?.quote &&
              values?.client_quote?.product_quote?.valid_date ? (
                // values?.warranty ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <span className={classes.filesLabel}>Factory Quote</span>
                {!supplierID && (
                  <Box display="flex">
                    <Box flex={1}>
                      <ReferenceInput
                        key="supplierID"
                        source="client_quote.product_quote.supplier.id"
                        reference="suppliers"
                        //   className={classes.hideLabelAutoComplete}
                        label="Supplier Name:"
                        onChange={(e) => setChosenSupplier(e)}
                        classes={{ container: classes.autoCompleteContainer }}
                        column="true"
                        fullWidth
                        filter={{ sca_user_id: userID, status: 'active' }}
                      >
                        <AutocompleteInput
                          optionText="name"
                          optionValue="id"
                          variant="standard"
                          //   validate={required()} // Enable Required
                          disabled={readOnly}
                          fullWidth
                        />
                      </ReferenceInput>
                    </Box>
                    <Box flex={3} />
                  </Box>
                )}
                {/* <Box mt="10px">
                  <span className={classes.labelText}>Quote: *</span>
                </Box> */}
                {/* <Box display="flex">
                  <Box flex={1}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Quantity
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '20%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Unit Price ($, FOB)
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '20%', fontSize: '10px', backgroundColor: '#00B3C3' }}
                              align="center"
                            >
                              Lead Time
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="moq"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                                // validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="price"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                                // validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="manufacturing_lead_time"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                                // validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={1} />
                </Box> */}
                <Box display="flex" padding="15px 0 0 0" flexDirection="row" width="100%">
                  <Box flex={2} mt="4px">
                    <span className={classes.quoteLabel}>Quote Upload:</span>
                    <FileInput
                      column
                      //   validate={required()} // Enable Required
                      source="quote.quote"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      addLabel="false"
                      // accept="application/pdf"
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                      // disabled={disabled}
                    >
                      <FileField source="url" title="filename" />
                    </FileInput>
                  </Box>
                  <Box flex={1}>
                    <span className={classes.labelText}>Valid Date:</span>
                    <DateInput
                      source="client_quote.product_quote.valid_date"
                      label="Valid Date:"
                      fullWidth
                      variant="outlined"
                      margin="none"
                      className={classes.dateInputNoLabel}
                      //   validate={required()} // Enable Required
                    />
                  </Box>
                  <Box flex={3} />
                </Box>
                <Box display="flex">
                  <Box flex={1} position="relative">
                    <TextInput
                      variant="outlined"
                      fullWidth
                      column
                      multiline
                      minRows={4}
                      inputProps={{
                        maxLength: 125,
                        style: {
                          marginBottom: 15,
                          alignItems: 'baseline',
                        },
                      }}
                      source="warranty"
                      label="Warranty:"
                      //   validate={required()} // Enable Required
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '20px',
                        bottom: '20px',
                        fontSize: '10px',
                      }}
                    >
                      {values.warranty?.length || 0}/125
                    </span>
                  </Box>
                  <Box flex={1} />
                </Box>
                {/* <Box mt='10px'>
                  <span className={classes.filesLabel}>Factory Bank Information</span>
                </Box>
                {supplierData.bank_detail?.beneficiary_name && supplierData.bank_detail?.bank ? (
                  <Box display='flex'>
                    <Box flex={1} className={classes.addressBox}>
                      <span className={classes.labelSubtitle}>General Details:</span>
                      <Box display='flex' flexDirection='column' mt='10px'>
                        <Box display='flex'>
                          <Box flex={1}>
                            <span className={classes.labelText}>Account Number:</span>
                          </Box>
                          <Box flex={1}>
                            <span className={classes.labelText}>
                              {supplierData.bank_detail?.account_number}
                            </span>
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Box flex={1}>
                            <span className={classes.labelText}>Currency:</span>
                          </Box>
                          <Box flex={1}>
                            <span className={classes.labelText}>
                              {supplierData.bank_detail?.currency}
                            </span>
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Box flex={1}>
                            <span className={classes.labelText}>Tax Number:</span>
                          </Box>
                          <Box flex={1}>
                            <span className={classes.labelText}>
                              {supplierData.bank_detail?.tax_number}
                            </span>
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Box flex={1}>
                            <span className={classes.labelText}>Clearing Code:</span>
                          </Box>
                          <Box flex={1}>
                            <span className={classes.labelText}>
                              {supplierData.bank_detail?.clearing_code}
                            </span>
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Box flex={1}>
                            <span className={classes.labelText}>Swift Code:</span>
                          </Box>
                          <Box flex={1}>
                            <span className={classes.labelText}>
                              {supplierData.bank_detail?.swift_code}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box flex={1} className={classes.addressBox}>
                      <span className={classes.labelSubtitle}>Beneficiary Details:</span>
                      <Box display='flex' mt='10px'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Beneficiary Name:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.beneficiary_name}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Address Line 1:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.beneficiary_address?.address_line1}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Address Line 2:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.beneficiary_address?.address_line2}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>City:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.beneficiary_address?.city}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Province:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.beneficiary_address?.province}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Country Code:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.beneficiary_address?.country_code}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box flex={1} className={classes.addressBox}>
                      <span className={classes.labelSubtitle}>Bank Details:</span>
                      <Box display='flex' mt='10px'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Bank Name:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.bank?.name}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Branch Name:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.branch_name}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Branch Address:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.branch_address}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Region:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {supplierData.bank_detail?.bank?.region}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box display='flex'>
                    <Box flex={1} p='10px 5px 10px 0px'>
                      <span className={classes.labelText}>
                        No supplier banking details, please enter below.
                      </span>
                      <TextInput
                        variant='standard'
                        fullWidth
                        // column
                        source='beneficiaryName'
                        label='Beneficiary Name:'
                        // validate={required()} // Enable Required
                        className={classes.textInput}
                      />

                      <TextInput
                        variant='standard'
                        fullWidth
                        // column
                        source='accountNumber'
                        label='Account Number:'
                        // validate={required()} // Enable Required
                        className={classes.textInput}
                      />
                      {currencies && (
                        <SelectInput
                          label='Settlement Currency:'
                          source='supplierCurrency'
                          choices={currencies}
                          optionText='abbreviation'
                          optionValue='id'
                          defaultValue=''
                          fullWidth
                          //   validate={required()} // Enable Required
                          variant='standard'
                          className={classes.textInput}
                          //   className={classes.hideLabelAutoComplete}
                        />
                      )}
                      {bankRegions && (
                        <SelectInput
                          label='Bank Region:'
                          source='supplierBankRegion'
                          choices={bankRegions}
                          optionText='name'
                          optionValue='name'
                          defaultValue=''
                          fullWidth
                          //   validate={required()} // Enable Required
                          variant='standard'
                          className={classes.textInput}
                          //   className={classes.hideLabelAutoComplete}
                        />
                      )}
                      {banks && values.supplierBankRegion && (
                        <SelectInput
                          label='Bank:'
                          source='supplierBank'
                          choices={banks.filter(item => item.region === values.supplierBankRegion)}
                          optionText='name'
                          optionValue='id'
                          defaultValue=''
                          fullWidth
                          //   validate={required()} // Enable Required
                          variant='standard'
                          className={classes.textInput}
                          //   className={classes.hideLabelAutoComplete}
                        />
                      )}
                      <Box display='flex' mt='10px'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Swift Code:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {banks.filter(item => item.id === values.supplierBank)[0]?.swift_code}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box flex={1} p='10px 0px 10px 5px' mt='20px'>
                      <TextInput
                        variant='standard'
                        fullWidth
                        // column
                        source='beneficiaryAddressLine1'
                        label='Address Line 1:'
                        // validate={required()} // Enable Required
                        className={classes.textInput}
                      />
                      <TextInput
                        variant='standard'
                        fullWidth
                        // column
                        source='beneficiaryAddressLine2'
                        label='Address Line 2:'
                        // validate={required()} // Enable Required
                        className={classes.textInput}
                      />
                      <SelectInput
                        label='Country:'
                        source='beneficiaryCountry'
                        choices={countries}
                        optionText='name'
                        optionValue='code'
                        defaultValue=''
                        fullWidth
                        onChange={() => {
                          form.change('beneficiaryProvince', '')
                          form.change('beneficiaryCity', '')
                        }}
                        // validate={required()} // Enable Required
                        variant='standard'
                        //   className={classes.hideLabelAutoComplete}
                      />
                      <SelectInput
                        label='Provinces:'
                        source='beneficiaryProvince'
                        choices={filteredProvinces}
                        optionText='name'
                        optionValue='id'
                        defaultValue=''
                        fullWidth
                        onChange={() => form.change('beneficiaryCity', '')}
                        // validate={required()} // Enable Required
                        variant='standard'
                        //   className={classes.hideLabelAutoComplete}
                      />
                      <SelectInput
                        label='Cities:'
                        source='beneficiaryCity'
                        choices={filteredCities}
                        optionText='name'
                        optionValue='id'
                        defaultValue=''
                        fullWidth
                        // validate={required()} // Enable Required
                        variant='standard'
                        //   className={classes.hideLabelAutoComplete}
                      />
                      <TextInput
                        variant='standard'
                        fullWidth
                        // column
                        source='beneficiaryPostCode'
                        label='Postal Code:'
                        // validate={required()} // Enable Required
                        className={classes.textInput}
                      />
                    </Box>
                    <Box flex={2} />
                  </Box>
                )} */}
                <Box mt="10px">
                  <span className={classes.filesLabel}>Factory Payment Terms</span>
                </Box>
                <Box mt="5px">
                  <ArrayInput source="factoryPaymentTerms" label="">
                    <SimpleFormIterator
                      TransitionProps={{ enter: false, exit: false }}
                      addButton={
                        <Button
                          style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                        >
                          <AddIcon />
                        </Button>
                      }
                      removeButton={
                        <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                      }
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData, rest }) => (
                          <Box display="flex">
                            <Box flex={1}>
                              <SelectInput
                                label="Value"
                                source={getSource('value')}
                                {...rest}
                                record={scopedFormData}
                                choices={milestones}
                                optionText="name"
                                optionValue="value"
                                defaultValue=""
                                fullWidth
                                variant="standard"
                                className={classes.dialogInput}
                              />
                            </Box>
                            <Box flex={2} ml="5px">
                              {factoryMilestones.length > 0 && (
                                <SelectInput
                                  label="Milestone"
                                  source={getSource('milestone')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={factoryMilestones}
                                  optionText="name"
                                  optionValue="name"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Quality</Typography>
              {
                // values?.quality_agency_id &&
                // values?.inspection_type &&
                // values?.inspection_level &&
                // values?.inspection_quote_file &&
                // values?.inspection_checklist &&
                values?.inspectionEstimate && values?.inspection_work_days ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CancelOutlinedIcon style={{ color: 'red' }} />
                )
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box display="flex">
                  <Box flex={1}>
                    <span className={classes.filesLabel}>Inspection Costs</span>

                    {qualityAgencies.length > 0 && (
                      <SelectInput
                        label="Quality Agency:"
                        source="quality_agency_id"
                        choices={qualityAgencies}
                        optionText="name"
                        optionValue="id"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                        //   validate={required()} // Enable Required
                        //   className={classes.hideLabelAutoComplete}
                      />
                    )}
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    {inspectionTypes.length > 0 && (
                      <SelectInput
                        label="Inspection Type:"
                        source="inspection_type"
                        choices={inspectionTypes}
                        optionText="name"
                        optionValue="name"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                        //   validate={required()} // Enable Required
                        //   className={classes.hideLabelAutoComplete}
                      />
                    )}
                  </Box>

                  <Box flex={1} p="10px 0px 10px 5px">
                    {inspectionLevels.length > 0 && (
                      <SelectInput
                        label="Inspection Level:"
                        source="inspection_level"
                        choices={inspectionLevels}
                        optionText="name"
                        optionValue="name"
                        defaultValue=""
                        fullWidth
                        variant="standard"
                        //   validate={required()} // Enable Required
                        //   className={classes.hideLabelAutoComplete}
                      />
                    )}
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px">
                    <NumberInput
                      source="inspection_work_days"
                      label="Work days required :"
                      fullWidth
                      variant="standard"
                      margin="none"
                      //   className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                      //   className={classes.dialogInput}
                      //   validate={required()} // Enable Required
                    />
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <NumberInput
                      source="inspectionEstimate"
                      label="Inspection Estimate:"
                      fullWidth
                      variant="standard"
                      margin="none"
                      //   className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                      //   className={classes.dialogInput}
                      //   validate={required()} // Enable Required
                    />
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px" mt="10px">
                    <span className={classes.quoteLabel}>Inspection Quote Upload:</span>
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <FileInput
                      column
                      //   validate={required()}
                      source="inspection_quote_file"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      addLabel="false"
                      // accept="application/pdf"
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                      // disabled={disabled}
                    >
                      <FileField source="url" title="filename" />
                    </FileInput>
                  </Box>
                  <Box flex={2} />
                </Box>

                <Box display="flex">
                  <Box flex={1} p="10px 5px 10px 0px" mt="10px">
                    <span className={classes.quoteLabel}>Upload Inspection Checklist:</span>
                  </Box>
                  <Box flex={1} p="10px 0px 10px 5px">
                    <FileInput
                      column
                      //   validate={required()}
                      source="inspection_checklist"
                      classes={{
                        dropZone: classes.itemBoxDropZone,
                        removeButton: classes.itemBoxDZRemove,
                        preview: classes.itemBoxDZPreview,
                        root: classes.itemBoxDZRoot,
                      }}
                      label=""
                      addLabel="false"
                      // accept="application/pdf"
                      placeholder={
                        <div className={classes.placeholder}>
                          <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                        </div>
                      }
                      // disabled={disabled}
                    >
                      <FileField source="url" title="filename" />
                    </FileInput>
                  </Box>
                  <Box flex={2} />
                </Box>
                {/* <Box mt='10px'>
                  <span className={classes.filesLabel}>
                    Quality Inspection Agency Bank Information
                  </span>
                </Box>
                <Box display='flex'>
                  <Box flex={1} className={classes.addressBox}>
                    <span className={classes.labelSubtitle}>General Details:</span>
                    <Box display='flex' flexDirection='column' mt='10px'>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Account Number:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenQuality?.bank_detail?.account_number}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Currency:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenQuality?.bank_detail?.currency}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Tax Number:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenQuality?.bank_detail?.tax_number}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Clearing Code:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenQuality?.bank_detail?.clearing_code}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Swift Code:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenQuality?.bank_detail?.swift_code}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex={1} className={classes.addressBox}>
                    <span className={classes.labelSubtitle}>Beneficiary Details:</span>
                    <Box display='flex' mt='10px'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Beneficiary Name:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.beneficiary_name}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Address Line 1:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.beneficiary_address?.address_line1}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Address Line 2:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.beneficiary_address?.address_line2}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>City:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.beneficiary_address?.city}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Province:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.beneficiary_address?.province}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Country Code:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.beneficiary_address?.country_code}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex={1} className={classes.addressBox}>
                    <span className={classes.labelSubtitle}>Bank Details:</span>
                    <Box display='flex' mt='10px'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Bank Name:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.bank?.name}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Branch Name:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.branch_name}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Branch Address:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.branch_address}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Region:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenQuality?.bank_detail?.bank?.region}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}

                <Box mt="10px">
                  <span className={classes.labelText}>Quality Inspection Agency Payment Terms</span>
                </Box>
                <Box mt="5px">
                  <ArrayInput source="qualityPaymentTerms" label="">
                    <SimpleFormIterator
                      TransitionProps={{ enter: false, exit: false }}
                      addButton={
                        <Button
                          style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                        >
                          <AddIcon />
                        </Button>
                      }
                      removeButton={
                        <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                      }
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData, rest }) => (
                          <Box display="flex">
                            <Box flex={1}>
                              <SelectInput
                                label="Value"
                                source={getSource('value')}
                                {...rest}
                                record={scopedFormData}
                                choices={milestones}
                                optionText="name"
                                optionValue="value"
                                defaultValue=""
                                fullWidth
                                variant="standard"
                                className={classes.dialogInput}
                              />
                            </Box>
                            <Box flex={2} ml="5px">
                              {qualityMilestones.length > 0 && (
                                <SelectInput
                                  label="Milestone"
                                  source={getSource('milestone')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={qualityMilestones}
                                  optionText="name"
                                  optionValue="name"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Logistics</Typography>
              {
                // values?.logistics_agency_id &&
                // values?.logistics_disclaimer &&
                values?.logistics_lead_time &&
                // values?.logistics_quote_file &&
                values?.logistics_quote_valid_date &&
                values?.hts_code &&
                values?.dutyRate > -1 ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <CancelOutlinedIcon style={{ color: 'red' }} />
                )
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box display="flex">
                  <Box flex={1}>
                    <span className={classes.filesLabel}>{`Int'l Freight Costs`}</span>
                    {logisticsProviders.length > 0 && (
                      <SelectInput
                        label="Freight Agency:"
                        source="logistics_agency_id"
                        choices={logisticsProviders}
                        optionText="name"
                        optionValue="id"
                        defaultValue=""
                        fullWidth
                        //   validate={required()} // Enable Required
                        variant="standard"
                        //   className={classes.hideLabelAutoComplete}
                      />
                    )}
                  </Box>
                  <Box flex={2} />
                </Box>
                <Box mt="10px">
                  <span className={classes.labelText}>Quote: *</span>
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '70%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              HTS Code
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '30%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Duty Rate
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <TextInput
                                variant="filled"
                                margin="none"
                                fullWidth
                                source="hts_code"
                                label=""
                                className={classes.tableInput}
                                //   validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="dutyRate"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                                // validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: '50%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Freight Estimate
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '50%', fontSize: '10px', backgroundColor: '#232323' }}
                              align="center"
                            >
                              Lead Time
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="qty1">
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="logisticsEstimate"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                                // validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                            <BodyTableCell align="right" style={{ padding: '0px' }}>
                              <NumberInput
                                source="logistics_lead_time"
                                label=""
                                fullWidth
                                variant="filled"
                                margin="none"
                                className={classes.tableInput}
                                // validate={required()} // Enable Required
                              />
                            </BodyTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box flex={1} />
                </Box>

                <Box display="flex" padding="15px 0 0 0" flexDirection="row" width="100%">
                  <Box flex={1} mt="4px" display="flex">
                    <Box flex={1}>
                      <span className={classes.quoteLabel}>Quote Upload:</span>
                      <FileInput
                        column
                        // validate={required()} // Enable Required
                        source="logistics_quote_file"
                        classes={{
                          dropZone: classes.itemBoxDropZone,
                          removeButton: classes.itemBoxDZRemove,
                          preview: classes.itemBoxDZPreview,
                          root: classes.itemBoxDZRoot,
                        }}
                        label=""
                        addLabel="false"
                        // accept="application/pdf"
                        placeholder={
                          <div className={classes.placeholder}>
                            <OpenInBrowserIcon style={{ width: 30, height: 30 }} />
                          </div>
                        }
                        // disabled={disabled}
                      >
                        <FileField source="url" title="filename" />
                      </FileInput>
                    </Box>
                    <Box flex={1}>
                      <span className={classes.labelText}>Valid Date:</span>
                      <DateInput
                        source="logistics_quote_valid_date"
                        label="Valid Date:"
                        fullWidth
                        variant="outlined"
                        margin="none"
                        className={classes.dateInputNoLabel}
                        // validate={required()} // Enable Required
                      />
                    </Box>
                  </Box>

                  <Box flex={1} />
                </Box>
                <Box display="flex">
                  <Box flex={1} position="relative">
                    <TextInput
                      variant="outlined"
                      fullWidth
                      column
                      multiline
                      minRows={4}
                      inputProps={{
                        maxLength: 125,
                        style: {
                          marginBottom: 15,
                          alignItems: 'baseline',
                        },
                      }}
                      source="logistics_disclaimer"
                      label="Disclaimer:"
                      //   validate={required()} // Enable Required
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '20px',
                        bottom: '20px',
                        fontSize: '10px',
                      }}
                    >
                      {values.logistics_disclaimer?.length || 0}/125
                    </span>
                  </Box>
                  <Box flex={1} />
                </Box>

                <span className={classes.labelText}>Declarations:</span>
                <Box mt="5px">
                  <ArrayInput source="quote.product.product_declarations" label="">
                    <SimpleFormIterator
                      TransitionProps={{ enter: false, exit: false }}
                      addButton={
                        <Button
                          style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                        >
                          <AddIcon />
                        </Button>
                      }
                      removeButton={
                        <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                      }
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData, rest }) => (
                          <Box display="flex">
                            <Box flex={1}>
                              <TextInput
                                source={getSource('declaration')}
                                {...rest}
                                record={scopedFormData}
                                label="Declaration"
                                fullWidth
                                variant="outlined"
                                margin="none"
                                className={`${classes.dialogInput} ${classes.hideLabelAutoComplete}`}
                                // validate={required()} // Enable Required
                              />
                            </Box>
                            <Box flex={2} ml="5px">
                              <FileInput
                                column
                                margin="none"
                                source={getSource('file')}
                                {...rest}
                                record={scopedFormData}
                                classes={{
                                  dropZone: classes.itemBoxDropZoneCert,
                                  removeButton: classes.itemBoxDZRemoveDec,
                                  preview: classes.itemBoxDZPreview,
                                  root: classes.itemBoxDZRoot,
                                }}
                                label=""
                                addLabel="false"
                                accept="application/pdf"
                                placeholder={
                                  <div className={classes.itemBoxUploadPlaceholder}>Upload</div>
                                }
                              >
                                <FileField source="url" title="filename" />
                              </FileInput>
                            </Box>
                          </Box>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>

                {/* <Box mt='10px'>
                  <span
                    className={classes.filesLabel}
                  >{`Int'l Freight Forwarder Bank Information`}</span>
                </Box>
                <Box display='flex'>
                  <Box flex={1} className={classes.addressBox}>
                    <span className={classes.labelSubtitle}>General Details:</span>
                    <Box display='flex' flexDirection='column' mt='10px'>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Account Number:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenLogistics?.bank_detail?.account_number}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Currency:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenLogistics?.bank_detail?.currency}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Tax Number:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenLogistics?.bank_detail?.tax_number}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Clearing Code:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenLogistics?.bank_detail?.clearing_code}
                          </span>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        <Box flex={1}>
                          <span className={classes.labelText}>Swift Code:</span>
                        </Box>
                        <Box flex={1}>
                          <span className={classes.labelText}>
                            {chosenLogistics?.bank_detail?.swift_code}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex={1} className={classes.addressBox}>
                    <span className={classes.labelSubtitle}>Beneficiary Details:</span>
                    <Box display='flex' mt='10px'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Beneficiary Name:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.beneficiary_name}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Address Line 1:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.beneficiary_address?.address_line1}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Address Line 2:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.beneficiary_address?.address_line2}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>City:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.beneficiary_address?.city}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Province:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.beneficiary_address?.province}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Country Code:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.beneficiary_address?.country_code}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex={1} className={classes.addressBox}>
                    <span className={classes.labelSubtitle}>Bank Details:</span>
                    <Box display='flex' mt='10px'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Bank Name:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.bank?.name}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Branch Name:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.branch_name}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Branch Address:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.branch_address}
                        </span>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box flex={1}>
                        <span className={classes.labelText}>Region:</span>
                      </Box>
                      <Box flex={1}>
                        <span className={classes.labelText}>
                          {chosenLogistics?.bank_detail?.bank?.region}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}

                <Box mt="10px">
                  <span className={classes.labelText}>{`Int'l Freight Payment Terms`}</span>
                </Box>
                <Box mt="5px" display="flex">
                  <Box flex={1}>
                    <ArrayInput source="logisticsPaymentTerms" label="">
                      <SimpleFormIterator
                        TransitionProps={{ enter: false, exit: false }}
                        addButton={
                          <Button
                            style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}
                          >
                            <AddIcon />
                          </Button>
                        }
                        removeButton={
                          <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                        }
                      >
                        <FormDataConsumer>
                          {({ getSource, scopedFormData, rest }) => (
                            <Box display="flex">
                              <Box flex={1}>
                                <SelectInput
                                  label="Value"
                                  source={getSource('value')}
                                  {...rest}
                                  record={scopedFormData}
                                  choices={milestones}
                                  optionText="name"
                                  optionValue="value"
                                  defaultValue=""
                                  fullWidth
                                  variant="standard"
                                  className={classes.dialogInput}
                                />
                              </Box>
                              <Box flex={2} ml="5px">
                                {logisticsMilestones.length > 0 && (
                                  <SelectInput
                                    label="Milestone"
                                    source={getSource('milestone')}
                                    {...rest}
                                    record={scopedFormData}
                                    choices={logisticsMilestones}
                                    optionText="name"
                                    optionValue="name"
                                    defaultValue=""
                                    fullWidth
                                    variant="standard"
                                    className={classes.dialogInput}
                                  />
                                )}
                              </Box>
                            </Box>
                          )}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Box flex={1} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Costs and Pricing</Typography>
              {typeof values?.commissionFees === 'number' &&
              typeof values?.fulfillmentFees === 'number' &&
              typeof values?.marketingBudget === 'number' &&
              typeof values?.minimum_purchase_quantity === 'number' &&
              typeof values?.supplyChainFees === 'number' &&
              typeof values?.retailPrice === 'number' ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column">
                <CostsAndPricing
                  sales={props.sales || true}
                  options={props.data?.options || []}
                  tier_prices={props.data?.supplier_catalog_item?.tier_prices || []}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Order Management Set-Up</Typography>
              <CheckIcon style={{ color: 'green' }} />
            </AccordionSummary>
            <AccordionDetails style={{ display: 'unset' }}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                  <span className={classes.addressTitle}>Contacts</span>
                  {/* <span className={classes.addressSubtitle}>{supplierData.name}</span>
                  <span className={classes.addressSubtitle}>
                    {getDisplayName(supplierData.sca_user)}
                  </span> */}

                  <Box display="flex" mt="10px">
                    <Box flex={1} flexDirection="column" display="flex" className="">
                      <ContactField
                        label="Supplier Contact"
                        field="supplierContact"
                        selectedContact={
                          values.supplierContact && supplierData?.contacts?.length > 0
                            ? supplierData?.contacts?.filter(
                                (item) => item.id === values.supplierContact,
                              )[0]
                            : {}
                        }
                        choices={supplierData?.contacts}
                      />
                    </Box>
                    <Box flex={1} flexDirection="column" display="flex">
                      <ContactField
                        label={`Quality Contact (${chosenQuality.name || ''})`}
                        field="qualityContact"
                        selectedContact={chosenQuality.contact || {}}
                        disabled
                      />
                    </Box>
                    <Box flex={1} flexDirection="column" display="flex">
                      <ContactField
                        label={`Freight Forwarder Contact (${chosenLogistics.name || ''})`}
                        field="freightContact"
                        selectedContact={chosenLogistics.contact || {}}
                        disabled
                      />
                    </Box>
                  </Box>
                  <span className={classes.addressTitle}>Addresses</span>
                  <Box display="flex" mt="10px">
                    <Box flex={1} flexDirection="column" display="flex">
                      <AddressField
                        label="Factory (Inspection) Address"
                        field="supplierFactoryAddress"
                        selectedAddress={
                          values.supplierFactoryAddress && supplierData?.addresses?.length > 0
                            ? supplierData?.addresses?.filter(
                                (item) => item.id === values.supplierFactoryAddress,
                              )[0]
                            : {}
                        }
                        choices={supplierData?.addresses}
                      />
                    </Box>
                    <Box flex={1} flexDirection="column" display="flex">
                      <AddressField
                        label="Pickup Address"
                        field="supplierPickupAddress"
                        selectedAddress={
                          values.supplierPickupAddress && supplierData?.addresses?.length > 0
                            ? supplierData?.addresses?.filter(
                                (item) => item.id === values.supplierPickupAddress,
                              )[0]
                            : {}
                        }
                        choices={supplierData?.addresses}
                      />
                    </Box>
                    <Box flex={1} />
                  </Box>
                  <Box display="flex" mt="10px">
                    <Box flex={1} flexDirection="column" display="flex">
                      <AddressField
                        label="Shipping Address"
                        field="shipperAddress"
                        selectedAddress={addresses?.Shipper?.address}
                      />
                    </Box>
                    <Box flex={1} flexDirection="column" display="flex">
                      <AddressField
                        label="Consignee Address"
                        field="consigneeAddress"
                        selectedAddress={addresses?.Consignee?.address}
                      />
                    </Box>
                    <Box flex={1} flexDirection="column" display="flex">
                      <AddressField
                        label="Delivery Address"
                        field="deliveryAddress"
                        selectedAddress={addresses?.ShippingAddress?.address}
                      />
                    </Box>
                  </Box>
                </Box>
                {/* <Box mt='10px' display='flex' flexDirection='column'>
                  <span className={classes.addressTitle}>Payment Details</span>
                  <Box display='flex' mt='10px'>
                    <Box flex={1} flexDirection='column' display='flex'>
                      <AddressField
                        label='Supplier Beneficiary'
                        field='supplierBeneficiary'
                        beneficiaryName={values.beneficiaryName}
                        selectedAddress={
                          values.supplierBeneficiaryAddress && supplierData?.addresses?.length > 0
                            ? supplierData?.addresses?.filter(
                                item => item.id === values.supplierBeneficiaryAddress,
                              )[0]
                            : {}
                        }
                      />
                    </Box>
                    <Box flex={1} flexDirection='column' display='flex'>
                      <AddressField
                        label='Quality Beneficiary'
                        field='qualityBeneficiary'
                        selectedAddress={addresses?.Consignee?.address}
                      />
                    </Box>
                    <Box flex={1} flexDirection='column' display='flex'>
                      <AddressField
                        label='Logistics Beneficiary'
                        field='logisticsBeneficiary'
                        selectedAddress={addresses?.ShippingAddress?.address}
                      />
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Legal</Typography>
              <CheckIcon style={{ color: 'green' }} />
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" className={classes.centerBox}>
                <Box mt="20px">
                  <span className={classes.filesLabel}>Agreement:</span>
                  <FileInput
                    column
                    source="agreement"
                    classes={{
                      dropZone: classes.itemBoxDropZone,
                      removeButton: classes.itemBoxDZRemove,
                      preview: classes.itemBoxDZPreview,
                      root: classes.itemBoxDZRoot,
                    }}
                    label=""
                    addLabel="false"
                    placeholder={<div className={classes.itemBoxUploadPlaceholder}>Upload</div>}
                  >
                    <FileField source="url" title="invoice" />
                  </FileInput>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Confirm
          redirect="/offer"
          record={formProps.record}
          basePath={formProps.basePath}
          label="SAVE"
          undoable
          isSave
          // invalid={formProps.invalid}
          handleSubmit={async (record) => {
            await handleSave(record)
          }}
          saving={formProps.saving}
          disabled={formProps.saving}
          resource="offer"
        />
        {values.status === 'PENDING_SUBMISSION' && (
          <Confirm
            redirect="/offer"
            record={formProps.record}
            basePath={formProps.basePath}
            label="SUBMIT"
            undoable
            invalid={formProps.invalid}
            // handleSubmit={async (record) => {
            //   await handleSave(record, 'submit')
            // }}
            handleSubmit={async (record) => {
              await dataProvider.update('offer', {
                data: { id: record.id, status: 'PENDING_PURCHASE_ORDER' },
              })
            }}
            saving={formProps.saving}
            // disabled={
            //   formProps.saving || formProps.invalid || values.status === '^PENDING_PURCHASE_ORDER^'
            // }
            resource="offer"
          />
        )}
        {values.status === 'PENDING_APPROVAL' && (
          <Confirm
            redirect="/offer"
            record={formProps.record}
            basePath={formProps.basePath}
            label="APPROVE"
            undoable
            invalid={formProps.invalid}
            handleSubmit={async (record) => {
              await dataProvider.update('offer', {
                data: { id: record.id, status: 'PRODUCTION' },
              })
            }}
            saving={formProps.saving}
            disabled={formProps.saving || formProps.invalid || values.status !== 'PENDING_APPROVAL'}
            resource="offer"
          />
        )}
        {Object.values(errors)?.length > 0 ? (
          <Tooltip title={Object.values(errors)?.join(', ')}>
            <WarningIcon style={{ marginLeft: '20px' }} />
          </Tooltip>
        ) : null}
      </Box>
      {/* <Box display='flex' flexDirection='column'>
        {errs.map(err => (
          <span key={err}>{err}</span>
        ))}
      </Box> */}
    </Box>
  )
}

// const CreateFeature = (props) => {
//   const { filter, onCancel, onCreate } = useCreateSuggestionContext()
//   const [value, setValue] = useState(filter || '')
//   const [open, setOpen] = useState(true)
//   const [create] = useCreate('systemDictionary')
//   const { values } = useFormState()
//   const form = useForm()

//   //   console.log('WCCFVALS', values)

//   // console.log(props)
//   const handleSubmit = (event) => {
//     event.preventDefault()
//     props.setLoading(true)
//     const newFeat = {
//       name: value,
//       value: value.toLowerCase(),
//       own: 'selling-features',
//     }
//     create(
//       {
//         payload: newFeat,
//       },
//       {
//         onSuccess: ({ data }) => {
//           props.setFeatures((prevState) => {
//             prevState.unshift({ id: data.id, name: value, value: value, own: 'selling-features' })
//             return prevState
//           })
//           // console.log(data)
//           setValue('')
//           const feats = values.features
//           feats.pop()
//           feats.push({ name: value, value: value })
//           form.change('features', feats)
//           onCreate(data)
//         },
//       },
//     )
//     props.setLoading(false)
//     setOpen(false)
//   }

//   return (
//     <Dialog open={open} onClose={onCancel}>
//       <form onSubmit={handleSubmit}>
//         <DialogContent>
//           <TextField
//             label="New feature name"
//             value={value}
//             onChange={(event) => setValue(event.target.value)}
//             autoFocus
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button type="submit">
//             <span>Save</span>
//           </Button>
//           <Button onClick={onCancel}>
//             <span>Cancel</span>
//           </Button>
//         </DialogActions>
//       </form>
//     </Dialog>
//   )
// }

const AddressField = (props) => {
  //   const { values } = useFormState()

  const classes = useStyles()
  const { choices, field, label, selectedAddress, beneficiaryName } = props

  //   console.log('ADDRESS', choices, values)

  //   console.log('ADDRESS', field, selectedAddress)
  return (
    <Box className={classes.addressBox}>
      {choices && choices.length > 0 ? (
        <SelectInput
          label={label || 'Address'}
          source={field}
          choices={choices}
          optionText={(record) => `${record.local_full_address}` || ''}
          optionValue="id"
          defaultValue=""
          fullWidth
          variant="standard"
          //   className={classes.hideLabelAutoComplete}
        />
      ) : (
        <Box mt="18px" mb="20px">
          <span className={classes.addressSubtitle}>{label}</span>
        </Box>
      )}
      {beneficiaryName ? (
        <Box display="flex">
          <Box flex={3}>
            <span className={classes.addressLabel}>Beneficiary Name:</span>
          </Box>
          <Box flex={4}>
            <span className={classes.addressValue}>{beneficiaryName}</span>
          </Box>
        </Box>
      ) : null}
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Address Line 1:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedAddress?.address_line1}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Address Line 2:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedAddress?.address_line2}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>City:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedAddress?.city}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Province:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedAddress?.province}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Postal Code:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedAddress?.post_code}</span>
        </Box>
      </Box>
    </Box>
  )
}

const ContactField = (props) => {
  //   const { values } = useFormState()

  const classes = useStyles()
  const { choices, field, label, selectedContact, disabled } = props

  //   console.log('CONTACT', props)

  //   console.log('SELCONTACT', selectedContact)

  return (
    <Box className={classes.contactBox}>
      {!disabled ? (
        <SelectInput
          label={label || 'Contact'}
          source={field}
          choices={choices}
          optionText={(record) =>
            record.first_name && record.last_name
              ? `${record.first_name} ${record.last_name}`
              : `Unknown - ${record.email}`
          }
          optionValue="id"
          defaultValue=""
          fullWidth
          disabled={disabled}
          variant="standard"
          //   className={classes.hideLabelAutoComplete}
        />
      ) : (
        <Box display="flex" flexDirection="column">
          <Box mt="18px" mb="20px">
            <span className={classes.addressSubtitle}>{label}</span>
          </Box>

          <Box display="flex">
            <Box flex={3}>
              <span className={classes.addressLabel}>Name:</span>
            </Box>
            <Box flex={4}>
              <span className={classes.addressValue}>{`${selectedContact?.first_name || ''} ${
                selectedContact?.last_name || ''
              }`}</span>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Email:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedContact?.email}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Position:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedContact?.position}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={3}>
          <span className={classes.addressLabel}>Mobile:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedContact?.mobile}</span>
        </Box>
      </Box>
      {/* <Box display='flex'>
        <Box flex={3}>
          <span className={classes.addressLabel}>Postal Code:</span>
        </Box>
        <Box flex={4}>
          <span className={classes.addressValue}>{selectedContact?.post_code}</span>
        </Box>
      </Box> */}
    </Box>
  )
}

export default FormData
