import React from 'react'
import ChipInput from 'material-ui-chip-input'
// import useStyles from '../styles'
import {
  useInput,
  //  required
} from 'react-admin'

export const RAChipInput = (props) => {
  // console.log(props)
  const {
    // eslint-disable-next-line no-unused-vars
    label,
    source,
    meta,
    className,
    input,
    resource,
    helperText,
    // isRequired,
  } = useInput(props)

  const { touched, error } = meta

  const onAdd = (value) => {
    input.value.push(value)
    // input.onChange(value)
  }

  const onDel = (value) => {
    input.onChange(input.value.filter((item) => item !== value))
    // input.onChange(value)
  }

  if (!input.value) {
    input.onChange([])
  }

  return (
    <ChipInput
      {...input}
      name={source}
      // label={label}
      source={source}
      // isRequired={isRequired}
      resource={resource}
      error={!!(touched && error)}
      helperText={(touched && error) || helperText}
      variant="outlined"
      className={`MuiTextField-root ${className || null}`}
      onAdd={(value) => onAdd(value)}
      onDelete={(value) => onDel(value)}
      // onUpdate={(value) => onUpd(value)}
      {...props}
    />
  )
}

export default RAChipInput
