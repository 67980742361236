import AuditIcon from "@material-ui/icons/Collections"
import AuditList from "./AuditList"
// import AuditEdit from './AuditEdit'
// import AuditCreate from "./AuditCreate"
// import AuditShow from './AuditShow'

export default {
  list: AuditList,
  create: null,
  edit: null,
  icon: AuditIcon,
  show: null,
}
