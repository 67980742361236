import gql from 'graphql-tag'

const productGetQuery = `
  id
  brand
  created_at
  is_catalogue
  duty_rate
  hts_code
  name
  notes
  packaging_type
  pieces_per_carton
  root_sku
  root_product_id
  catalogue_sku
  allow_colour_selection
  chargeable_weight_air
  chargeable_weight_sea
  short_description
  long_description
  status
  updated_at
  selling_features
  variants {
    id
    sku
    name
    values {
      id
      option_name
      value
      option {
        id
        name
        type
        description
      }
      option_value {
        id
        value
        moq
        type
      }
    }
  }
  root_product {
    id
    name
    thumbnail_photo {
      id
      url
    }
    branch_products {
      id
      name
      thumbnail_photo {
        id
        url
      }
    }
  }
  branch_products {
    id
    name
    thumbnail_photo {
      id
      url
    }
  }
  sca_user_id
  sca {
    id
    email
    forename
    surname
  }
  primary_photo {
    id
    filename
    url
  }
  thumbnail_photo {
    id
    filename
    url
  }
  images: product_files (where: { type: { _eq: ADDITIONAL_PHOTO}}) {
    id
    type
    deleted_at
    file {
      id
      filename
      url
    }
  }
  additional_files: product_files (where: { type: { _neq: ADDITIONAL_PHOTO}}) {
    id
    type
    deleted_at
    file {
      id
      filename
      url
    }
  }
  product_categories (where: {deleted_at: {_is_null: true }}) {
    id
    deleted_at
    category {
      id
      name
    }
  }
  product_tags {
    id
    tag {
      id
      name
    }
  }
  product_declarations {
    id
    declaration
    file {
      id
      filename
      url
    }
  }
  product_dimensions {
    id
    length
    width
    height
    gross_weight
  }
  packaging_dimensions {
    id
    length
    width
    height
    gross_weight
  }
  carton_dimensions {
    id
    length
    width
    height
    gross_weight
  }
  activities {
    id
    activity
  }
  product_options {
    id
    deleted_at
    option_value {
      id
      value
      option {
        id
        name
      }
    }
  }
  product_specifications {
    id
    deleted_at
    option_value {
      id
      value
      option {
        id
        name
      }
    }
  }
  customisations {
    id
    deleted_at
    option_value {
      id
      value
      option {
        id
        name
      }
    }
  }
  packaging_options {
    id
    label
    type
    carton_dimensions {
      id
      length
      width
      height
      gross_weight
    }
    packaging_dimensions {
      id
      length
      width
      height
      gross_weight
    }
  }
  materials {
    id
    type
    name
    vendor
    vendor_code
    unit_of_measurement
    quantity_per_piece
    notes
    estimated_cost
    colours {
      id
      reference
      colour
    }
  }
  product_quotes {
    id
    supplier_id
    product_id
    svg_file
    standard_colour_cost_modifier
    standard_colour_moq
    packaging_options {
      id
      cost_modifier
      moq
      label
      pieces_per_carton
      type
      carton_type
      carton_label
      scm_quote_packaging_options {
        id
      }
      carton_dimensions {
        id
        length
        width
        height
        gross_weight
      }
      packaging_dimensions {
        id
        length
        width
        height
        gross_weight
      }
      product_quote_product_size_packagings {
        id
        product_quote_product_size_id
      }
    }
    product_sizes {
      id
      moq
      name
      short_name
      sequence
      cost_modifier
      scm_quote_product_sizes {
        id
      }
      dimensions {
        id
        length
        width
        height
        gross_weight
      }
      packaging_options  {
      id
      product_quote_packaging_option_id
      }
    }
    svg_mask {
      id
      filename
      url
    }
    payment_terms {
      id
      milestone
      value
    }
    supplier {
      id
      name
      primary_contact_id
      primary_address_id
      pickup_address_id
    }
    tooling_cost
    tooling_lead_time
    sample_cost
    sample_lead_time
    custom_sample_cost
    packaging_type
    pieces_per_carton
    valid_date
    cartons_per_pallet
    cartons_per_container_20ft
    cartons_per_container_40ft
    cartons_per_container_40ft_hc
    allow_order_splitting_by_size
    allow_order_splitting_by_colour
    freight_rate_air
    freight_rate_sea
    container_rate_20ft
    container_rate_40ft
    container_rate_40ft_hc
    container_capacity_20ft
    container_capacity_40ft
    container_capacity_40ft_hc
    included_packaging_option_id
    decorations {
      id
      label
      cost
      max_dimensions
      imprint_styles
      imprint_style
      preview_image {
        id
        filename
        url
      }
    }
    options {
      id
      name
      max_unique_selections
      additional_cost_per_unique_choice
      values {
        id
        value
        setup
        is_custom
        cost
        moq
        components {
          id
          component_id
          component {
            id
            name
          }                    
        }
      }
    }
    components {
      id
      name
      svg_shape_index
      svg_shape_id
      default_colour
      colours {
        id
        available
        value
      }
    }
    prices (order_by: {quantity: asc_nulls_last}) {
      id
      quantity
      unit_price
      lead_time
      est_landed_cost_air
      est_landed_cost_sea
      total_cbm
      total_cartons
      total_duties
      shipment_weight
    }
    scm_quotes (limit: 1, order_by: { created_at: desc_nulls_last}) {
      id
      status
      launch_date
      notes
      client_notes
      warranty_conditions
      warranty_term
      sourcing_request_id
      client_sample_lead_time
      client_sample_price
      client_custom_sample_price
      payment_terms ( where: { deleted_at: { _is_null: true } } ) {
        id
        milestone
        value
      }
      features {
        id
        feature
      }
      prices (order_by: {quantity: asc_nulls_last}) {
        id
        quantity
        price
        fob_price
        estimated_freight_air
        estimated_freight_sea
        production_lead_time
        lead_time_air
        lead_time_sea          
      }
    }
    specifications {
      id
      deleted_at
      option_value {
        id
        value
        option {
          id
          name
        }
      }
    }
  }
`

export const productCataloguePaymentIntent = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
        mutation getPurchaseOrderPaymentIntent {
          createPurchaseOrderSim(
            arg1: {
              ${jsonParams}
            }
        ) {
            offerIds
            orderId
            paymentIntentSecret
            publishableKey
          }
        }
      `,
    parseResponse: ({ data, errors }) => ({
      data: data.createPurchaseOrderSim,
      errors,
    }),
  }
}

export const productGet = ({ params, context }) => {
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getOneProduct {
        product_by_pk(id: ${params.id}) {
          ${productGetQuery}
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.product_by_pk,
      // validUntil,
    }),
  }
}

export const productList = ({ params, context }) => {
  // console.log(params)
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter.has_quotes
        ? params.filter.has_quotes === 'false'
          ? {
              _not: { product_quotes: { scm_quotes: { id: { _gte: 0 } } } },
            }
          : { product_quotes: { scm_quotes: { id: { _gte: 0 } } } }
        : null),
      ...(params.filter.is_catalogue && {
        is_catalogue: { _eq: params.filter.is_catalogue },
      }),
      ...(params.filter.is_active && {
        product_quotes: { scm_quotes: { prices: { _not: { id: { _is_null: true } } } } },
      }),
      ...(params.filter.brand && { brand: { _eq: params.filter.brand } }),
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...(params.filter.name && { name: { _ilike: `%${params.filter.name}%` } }),
      ...(params.filter.base_products && { root_product_id: { _is_null: true } }),
      ...(params.filter.request_name && {
        name: { _ilike: `%${params.filter.request_name}%` },
      }),
      ...(params.filter.sca && { sca_user_id: { _eq: params.filter.sca } }),
      ...(params.filter.agent && { sca_user_id: { _eq: params.filter.agent } }),
      ...(params.filter.supplier && {
        product_quotes: { supplier_id: { _eq: params.filter.supplier } },
      }),
      ...(params.filter.category && {
        product_categories: { category_id: { _in: params.filter.category } },
      }),
      ...(params.filter.date_gte &&
        !params.filter.date_lte && { created_at: { _gte: params.filter.date_gte } }),
      ...(params.filter.date_lte &&
        !params.filter.date_gte && { created_at: { _lte: params.filter.date_lte } }),
      ...(params.filter.date_lte &&
        params.filter.date_gte && {
          _and: [
            { created_at: { _gte: params.filter.gte } },
            { created_at: { _lte: params.filter.lte } },
          ],
        }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
  query getProducts ($where: product_bool_exp) {
   product(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
      ${productGetQuery}
    }
    product_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`,
    parseResponse: ({ data }) => ({
      data: data.product,
      total: data.product_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const productDelete = ({ params, context }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation DelProd {
            update_scm_quote(where: {product_quote: {product_id: {_eq: ${params.id}}}}, _set: {default_packaging_option_id: null}) {
              affected_rows
            }
            delete_scm_quote_product_size_packagings(where: {scm_quote_packaging_option: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}}) {
              affected_rows
            }
            delete_scm_quote_packaging_options(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_option_values(where: {option: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}}) {
              affected_rows
            }
            delete_scm_quote_options(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_product_sizes(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_component_colours(where: {component: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}}) {
              affected_rows
            }
            delete_scm_quote_components(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_decorations(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_feature(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_payment_terms(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote_prices(where: {scm_quote: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_scm_quote(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_product_quote_specification(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_product_quote_price(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_product_quote_decorations(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_component_colours(where: {component: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_component(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            update_product_quote(where: {product_id: {_eq: ${params.id}}}, _set: {included_packaging_option_id: null}) {
              affected_rows
            }
            delete_product_quote_option_values(where: {product_quote_option: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_product_quote_options(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_product_quote_product_size_packagings(where: {product_size: {product_quote: {product_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_product_quote_product_sizes(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_product_quote_packaging_options(where: {product_quote: {product_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_product_quote(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_file(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_specifications(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_categories(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_declaration(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_customisations(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_options(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_product_tags(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_sourcing_request_products(where: {product_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            update_product(where: {root_product_id: {_eq: ${params.id}}}, _set: {root_product_id: null}) {
              affected_rows
            }
            delete_product(where: {id: {_eq: ${params.id}}}) {
              affected_rows
            }
          }
          `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const productCreate = ({ params, context }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      mutation {
        insert_product(
            ${jsonParams}
        ) {
          returning {
            id
            product_quotes {
              id
            }
            sca_user_id
          }
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.insert_product?.returning,
      errors,
    }),
  }
}

export const productDebug = ({ context, params }) => {
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter.has_quotes
        ? params.filter.has_quotes === 'false'
          ? {
              _not: { product_quotes: { scm_quotes: { id: { _gte: 0 } } } },
            }
          : { product_quotes: { scm_quotes: { id: { _gte: 0 } } } }
        : null),
      ...(params.filter.is_catalogue && {
        is_catalogue: { _eq: params.filter.is_catalogue },
      }),
      ...(params.filter.is_active && {
        product_quotes: { scm_quotes: { prices: { _not: { id: { _is_null: true } } } } },
      }),
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...(params.filter.name && { name: { _ilike: `%${params.filter.name}%` } }),
      ...(params.filter.base_products && { root_product_id: { _is_null: true } }),
      ...(params.filter.request_name && {
        name: { _ilike: `%${params.filter.request_name}%` },
      }),
      ...(params.filter.sca && { sca_user_id: { _eq: params.filter.sca } }),
      ...(params.filter.agent && { sca_user_id: { _eq: params.filter.agent } }),
      ...(params.filter.supplier && {
        product_quotes: { supplier_id: { _eq: params.filter.supplier } },
      }),
      ...(params.filter.category && {
        product_categories: { category_id: { _in: params.filter.category } },
      }),
      ...(params.filter.date_gte &&
        !params.filter.date_lte && { created_at: { _gte: params.filter.date_gte } }),
      ...(params.filter.date_lte &&
        !params.filter.date_gte && { created_at: { _lte: params.filter.date_lte } }),
      ...(params.filter.date_lte &&
        params.filter.date_gte && {
          _and: [
            { created_at: { _gte: params.filter.gte } },
            { created_at: { _lte: params.filter.lte } },
          ],
        }),
    }
  }

  // console.log(variables, params)
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  const sortOrder = params.sort.order.toLowerCase()
  const orderBy =
    params.sort.field === 'product_quotes_aggregate.aggregate.count'
      ? { product_quotes_aggregate: { count: sortOrder } }
      : params.sort.field === 'product_categories_aggregate.aggregate.count'
      ? { product_categories_aggregate: { count: sortOrder } }
      : params.sort.field === 'sourcing_requests_aggregate.aggregate.count'
      ? { sourcing_requests_aggregate: { count: sortOrder } }
      : { [params.sort.field]: sortOrder }

  let jsonOrder = JSON.stringify(orderBy)

  jsonOrder = jsonOrder.replace(/"/g, '')

  return {
    ...context,
    variables,
    query: gql`
  query getProductDebug ($where: product_bool_exp) {
   product(where: $where, limit: ${perPage}, offset: ${offset}, order_by: ${jsonOrder}) {
          id
          name
          carton_dimensions_id
          status
          product_categories_aggregate {
            aggregate {
              count
            }
          }
          product_dimensions_id
          product_quotes_aggregate {
            aggregate {
              count
            }
          }
          product_quotes {
            id
            scm_quotes_aggregate {
              aggregate {
                count
              }
            }
          }
          sca {
            id
            forename
            surname
            email
          }
          sourcing_requests_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    `,
    // variables: {},
    parseResponse: ({ data }) => {
      if (data && data.product) {
        // console.log('data', data)
        return {
          data: data.product || [],
          total: data.product?.length || 0,
        }
      }
      return false
    },
  }
}

export const getVariantsList = ({ context }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getVariantsList {
        variant {
          id
          name
          sku
          product_id
          values {
            id
            option_name
            value
            option {
              id
              name
              type
              description
            }
            option_value {
              id
              value
              moq
              type
            }
          }
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.variant,
      total: data.variant.length,
      errors,
    }),
  }
}
