import * as React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { useChoices } from 'ra-core'

const useStyles = makeStyles(
  (theme) => ({
    checkbox: {
      height: 32,
    },
    root: {
      width: '24%',
      [theme.breakpoints.down('sm')]: {
        width: '30%',
      },
      [theme.breakpoints.down('md')]: {
        width: '30%',
      },
    },
  }),
  { name: 'RaCheckboxGroupInputItem' },
)

const CheckboxGroupInputItem = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    classes: classesOverride,
    id,
    choice,
    onChange,
    optionText,
    optionValue,
    options,
    translateChoice,
    value,
    ...rest
  } = props
  const classes = useStyles(props)
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  })

  const choiceName = getChoiceText(choice)

  return (
    <FormControlLabel
      className={classes.root}
      htmlFor={`${id}_${getChoiceValue(choice)}`}
      key={getChoiceValue(choice)}
      onChange={onChange}
      control={
        <Checkbox
          id={`${id}_${getChoiceValue(choice)}`}
          color="primary"
          className={classes.checkbox}
          checked={
            value
              ? value.find((v) => v == getChoiceValue(choice)) !== undefined // eslint-disable-line eqeqeq
              : false
          }
          value={String(getChoiceValue(choice))}
          {...options}
          {...rest}
        />
      }
      label={choiceName}
    />
  )
}

export default CheckboxGroupInputItem
