import SaleableProductVariantsIcon from '@material-ui/icons/Collections'
import SaleableProductVariantsList from './SaleableProductVariantsList'
// import SaleableProductVariantsEdit from './SaleableProductVariantsEdit'
// import SaleableProductVariantsCreate from './SaleableProductVariantsCreate'
// import SaleableProductVariantsShow from './SaleableProductVariantsShow'

export default {
  list: SaleableProductVariantsList,
  // create: SaleableProductVariantsCreate,
  //   edit: SaleableProductVariantsEdit,
  icon: SaleableProductVariantsIcon,
  // show: SaleableProductVariantsShow,
}
