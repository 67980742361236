import React from 'react'
import { Box } from '@material-ui/core'
import { AutocompleteInput, ReferenceInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import SelectInput from '../../../components/input/SelectInput'
import TextInput from '../../../components/OutlinedTextInput'
import useStyles from './styles'

const AddressForm = ({
  countries = [],
  addressTypes = [],
  //  zones = [], setCourierFees
}) => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <Box display="flex" flexDirection="column" alignItems="end">
      <SelectInput
        label="Type:"
        source="type"
        choices={addressTypes}
        optionText="name"
        optionValue="value"
        defaultValue=""
        fullWidth
        className={classes.areaTextSelect}
      />
      <TextInput
        variant="outlined"
        fullWidth
        className={classes.areaText}
        InputProps={{
          maxLength: 300,
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
          },
        }}
        source="addressLine1"
        label="Address Line 1:"
      />
      <TextInput
        variant="outlined"
        fullWidth
        className={classes.areaText}
        InputProps={{
          maxLength: 300,
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
          },
        }}
        source="addressLine2"
        label="Address Line 2:"
      />
      <SelectInput
        label="Country:"
        source="country"
        choices={countries}
        optionText="name"
        optionValue="name"
        defaultValue=""
        className={classes.areaTextSelect}
        // validate={required()}
        fullWidth
      />
      <ReferenceInput
        source="state"
        className={classes.productPhoto}
        reference="country_subdivision"
        label="State:"
        filter={{ level: 1, country: values.country }}
        sort={{ field: 'name', order: 'ASC' }}
        style={{ width: '100%' }}
        variant="outlined"
      >
        <AutocompleteInput fullWidth variant="outlined" label={false} optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="city"
        className={classes.productPhoto}
        reference="country_subdivision"
        label="City:"
        filter={{ level: 2, province: values.state }}
        sort={{ field: 'name', order: 'ASC' }}
        style={{ width: '100%' }}
        variant="outlined"
      >
        <AutocompleteInput fullWidth variant="outlined" label={false} optionText="name" />
      </ReferenceInput>
      <TextInput
        variant="outlined"
        fullWidth
        className={classes.areaText}
        InputProps={{
          maxLength: 300,
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
          },
        }}
        source="postCode"
        label="Postal Code:"
      />
      {/* <SelectInput
        label="Zone*:"
        source="zone"
        choices={zones}
        optionText={(v) => `${v.name} - $${v.value / 100}`}
        optionValue="id"
        defaultValue=""
        className={classes.areaTextSelect}
        onChange={(e) => {
          //   console.log(zones, e.target.value)
          const zone = zones.find((item) => item.id === e.target.value)
          //   console.log(zone)
          setCourierFees(zone.value)
        }}
        // validate={required()}
        fullWidth
      />
      <span style={{ fontSize: '10px', colour: 'grey' }}>
        * Based on one package of 12x13x3.5 inches, 35 oz (DIM: 130oz)
      </span> */}
    </Box>
  )
}

export default AddressForm
