import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const notificationsList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const limit = params.pagination.perPage || 25

  const userCode = `${params.filter.userCode}`

  return {
    ...context,
    query: gql`
          query getUserNotifications ($userCode: String!) {
            getAppUserNotifications(arg1: {limit: ${limit}, offset: ${offset}, userCode: $userCode}) {
              results {
                activity_count
                actor_count
                created_at
                group
                id
                is_read
                is_seen
                updated_at
                verb
                activities {
                  foreign_id
                  id
                  object
                  time
                  verb
                  meta {
                    reference_photo
                    sca_name
                    client_name
                    new_product {
                      product_name
                      product_photo
                    }
                    products {
                      product_name
                      product_photo
                    }
                  }
                  actor
                }
              }
            }
          }
        `,
    variables: { userCode },
    parseResponse: ({ data }) => {
      if (data && data.getAppUserNotifications) {
        // console.log("data", data)
        return {
          data: data.getAppUserNotifications?.results || [],
          total: data.getAppUserNotifications?.results?.length || 0,
        }
      }
      return false
    },
  }
}

export const notificationsMarkRead = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation {
        readNotification(
          id: ${params.id}
        )
      }
    `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}
export const notificationsCreate = ({ context, params }) => {
  // console.log(params)
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    // variables: {
    //   ...params,
    // },
    query: gql`
    mutation createUserNotification {
      createUserNotification(
        arg1: {${jsonParams}}
        ) {
          success
        }
    }
  `,
    parseResponse: ({ data }) => ({
      data: { id: 0, ...data },
    }),
  }
}
export const notificationsDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
            mutation {
              deleteNotification(
                id: ${params.id}
              )
            }
          `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}
export const notificationsPushCreate = ({ context, params }) => {
  // console.log(params)
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    // variables: {
    //   ...params,
    // },
    query: gql`
    mutation createUserPushNotification {
      createUserPushNotification(
        arg1: {${jsonParams}}
        ) {
          success
        }
    }
  `,
    parseResponse: ({ data }) => ({
      data: { id: 0, ...data },
    }),
  }
}
