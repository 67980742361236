import React from 'react'
import {
  Filter,
  List,
  SearchInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  TopToolbar,
  CreateButton,
} from 'react-admin'
import { getDisplayName } from '../../../utils/utils'

const ListActions = (props) => {
  const { className } = props
  return (
    <TopToolbar className={className}>
      <CreateButton />
    </TopToolbar>
  )
}

export const UserFilter = (props) => (
  <Filter {...props} filterValues={{ ...props.filterValues, qFields: ['email'] }}>
    <SearchInput InputProps={{ placeholder: 'Email' }} source="q" alwaysOn variant="standard" />
  </Filter>
)

const UserList = (props) => (
  <List
    {...props}
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<UserFilter />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid optimized rowClick="edit">
      <TextField source="id" />
      <TextField source="email" label="User email" />
      <FunctionField label="User name" render={(record) => getDisplayName(record)} />
      <FunctionField
        label="Default organisation"
        render={(record) => record.default_organisation?.name}
      />
      <FunctionField
        label="Organisations"
        render={(record) => record.member_organisations_aggregate?.aggregate?.count}
      />
      <DateField source="created_at" type="date" />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
)

export default UserList
