import React, { useState, useEffect } from 'react'
import {
  Box,
  // Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Checkbox,
  withStyles,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
// import { uniqueId } from 'lodash'
import lodashGet from 'lodash/get'
import TextInput from '../../../components/OutlinedTextInput'
import { useNotify, useDataProvider, required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import IconButton from '@material-ui/core/IconButton'
import { roundTo } from '../../../utils/utils'
import useLazyEffect from '../../../utils/useLazyEffect'
import AddIcon from '@material-ui/icons/AddOutlined'
// import RemoveIcon from '@material-ui/icons/RemoveOutlined'
// import WarningIcon from '@material-ui/icons/Warning'
// import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import InfoIcon from '@material-ui/icons/Info'
import NumberFormat from '../../components/CustomNumberFormat'
import useStyles from '../styles'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
    // backgroundColor: '#dfe5f1',
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
    backgroundColor: '#dfe5f1',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
    // backgroundColor: '#dfe5f1',
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
    backgroundColor: '#dfe5f1',
  },
}))(TableCell)

const FactoryQuoteCalculator = () => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()

  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [firstRenderDone, setFirstRenderDone] = useState(false)
  const [editing, setEditing] = useState()
  const [saving, setSaving] = useState()

  const formChange = (field, value) => {
    const oldValue = lodashGet(values, field)
    // console.log(field, 'oldValue', oldValue, 'newValue', value)
    if (oldValue != value) {
      form.change(field, value)
    }
  }

  useLazyEffect(
    () => {
      if (firstRenderDone && values.prices[0]) {
        // console.log('calculating...0', values.prices[0])
        calcFreightRatesRow({ index: 0 })
      }
    },
    [
      values.containerRate20ft,
      values.containerRate40ft,
      values.containerRate40ftHC,
      values.dutyRate,
      values.prices?.length > 0 ? values.prices[0]?.quantity : null,
      values.prices?.length > 0 ? values.prices[0]?.fobPrice : null,
      // values.prices?.length > 0 ? values.prices[0]?.seaAdjustment : null,
      // values.prices?.length > 0 ? values.prices[0]?.airAdjustment : null,
      values.freightRateAir,
      values.freightRateSea,
      values.does_sea_freight_include_duties,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclAir,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclSea,
      values.default_packaging_option_id,
      // values.default_packaging_option,
      // values.standardColourPriceModifier,
    ],
    [
      'ContainerRate20ft',
      'ContainerRate40ft',
      'ContainerRate40ftHC',
      'DutyRate',
      'Quantity',
      'FobPrice',
      'FreightRateAir',
      'FreightRateSea',
      'SeaIncludesDuties',
      'DefaultPkgLCLAir',
      'DefaultPkgLCLSea',
      'DefaultPkgID',
    ],
  )

  useLazyEffect(
    () => {
      if (firstRenderDone && values.prices[1]) {
        // console.log('calculating...1', values.prices[1])
        calcFreightRatesRow({ index: 1 })
      }
    },
    [
      values.containerRate20ft,
      values.containerRate40ft,
      values.containerRate40ftHC,
      values.dutyRate,
      values.prices?.length > 1 ? values.prices[1]?.quantity : null,
      values.prices?.length > 1 ? values.prices[1]?.fobPrice : null,
      // values.prices?.length > 1 ? values.prices[1]?.seaAdjustment : null,
      // values.prices?.length > 1 ? values.prices[1]?.airAdjustment : null,
      values.freightRateAir,
      values.freightRateSea,
      values.does_sea_freight_include_duties,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclAir,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclSea, // values.default_packaging_option,
      // values.standardColourPriceModifier,
    ],
    [
      'ContainerRate20ft',
      'ContainerRate40ft',
      'ContainerRate40ftHC',
      'DutyRate',
      'Quantity',
      'FobPrice ',
      'FreightRateAir',
      'FreightRateSea',
      'SeaIncludesDuties',
      'DefaultPkgLCLAir',
      'DefaultPkgLCLSea',
      'DefaultPkgID',
    ],
  )

  useLazyEffect(
    () => {
      if (firstRenderDone && values.prices[2]) {
        // console.log('calculating...2', values.prices[2])
        calcFreightRatesRow({ index: 2 })
      }
    },
    [
      values.containerRate20ft,
      values.containerRate40ft,
      values.containerRate40ftHC,
      values.dutyRate,
      values.prices?.length > 2 ? values.prices[2]?.quantity : null,
      values.prices?.length > 2 ? values.prices[2]?.fobPrice : null,
      // values.prices?.length > 2 ? values.prices[2]?.seaAdjustment : null,
      // values.prices?.length > 2 ? values.prices[2]?.airAdjustment : null,
      values.freightRateAir,
      values.freightRateSea,
      values.does_sea_freight_include_duties,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclAir,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.lclSea, // values.default_packaging_option,
      // values.standardColourPriceModifier,
    ],
    [
      'ContainerRate20ft',
      'ContainerRate40ft',
      'ContainerRate40ftHC',
      'DutyRate',
      'Quantity',
      'FobPrice ',
      'FreightRateAir',
      'FreightRateSea',
      'SeaIncludesDuties',
      'DefaultPkgLCLAir',
      'DefaultPkgLCLSea',
      'DefaultPkgID',
    ],
  )

  const calcFreightRatesRow = ({ index }) => {
    const debugMode = false

    const defaultPackagingOption = values.packagingSizes.find(
      (obj) => obj.id === values.default_packaging_option_id,
    )
    const defaultFactoryPackagingOption = values.factorySizes?.find(
      (el) => el.id === defaultPackagingOption.packagingFactoryReferenceId,
    )?.cartonDimensions
    const grossWeight =
      defaultFactoryPackagingOption?.gross_weight ||
      defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.gross_weight / 100 ||
      defaultPackagingOption?.carton_dimensions?.gross_weight / 100
    const cartonLength =
      defaultFactoryPackagingOption?.length ||
      defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.length / 100 ||
      defaultPackagingOption?.carton_dimensions?.length / 100
    const cartonWidth =
      defaultFactoryPackagingOption?.width ||
      defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.width / 100 ||
      defaultPackagingOption?.carton_dimensions?.width / 100
    const cartonHeight =
      defaultFactoryPackagingOption?.height ||
      defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.height / 100 ||
      defaultPackagingOption?.carton_dimensions?.height / 100
    const piecesPerCarton =
      defaultFactoryPackagingOption?.pieces_per_carton ||
      defaultPackagingOption?.scm_quote_packaging_option?.pieces_per_carton ||
      defaultPackagingOption?.pieces_per_carton
    const seaLCL =
      defaultPackagingOption?.scm_quote_packaging_option?.sea_lcl / 100 ||
      Number(defaultPackagingOption?.lclSea) ||
      0
    const airLCL =
      defaultPackagingOption?.scm_quote_packaging_option?.air_lcl / 100 ||
      Number(defaultPackagingOption?.lclAir) ||
      0

    const containerRate20ft = values.containerRate20ft
    const containerRate40ft = values.containerRate40ft
    const containerRate40ftHC = values.containerRate40ftHC
    const freightRateAir = values.freightRateAir
    const freightRateSea = values.freightRateSea
    const dutyRate = values.dutyRate / 100
    const fobPrice = values.prices[index]?.fobPrice
    const unitPrice = values.prices[index]?.unitPrice

    debugMode && console.log('CQCALCVALS:', values)
    const price = values.prices[index]
    // values.prices?.map((price, index) => {
    const quantity = price.quantity
    // const unitPrice = Number(price.unitPrice)
    const seaAdjuster = price.seaAdjustment / 100 || 0
    const airAdjuster = price.airAdjustment / 100 || 0
    // const pkgModifier = defaultPackagingOption.packagingModifier || 0
    const seaAdditions = Number(seaLCL) + Number(seaAdjuster)
    const airAdditions = Number(airLCL) + Number(airAdjuster)
    debugMode && console.log('--------CLIENT QUOTE---------')
    debugMode && console.log(`------------${index}----------------`)
    debugMode && console.log('-----------------------------')
    debugMode && console.log('PRICEROW', price)
    debugMode && console.log('QTY:', quantity)
    debugMode && console.log('FOBPRICE:', fobPrice)
    debugMode && console.log('DEFAULTPKG', defaultPackagingOption)
    debugMode && console.log('PPC', piecesPerCarton)
    debugMode && console.log('LCLSea', seaLCL)
    debugMode && console.log('LCLAir', airLCL)
    debugMode && console.log('ADDSEA', seaAdditions)
    debugMode && console.log('ADDAIR', airAdditions)
    debugMode && console.log('GW:', grossWeight)
    debugMode && console.log('CTNLTH:', cartonLength)
    debugMode && console.log('CTNWTH:', cartonWidth)
    debugMode && console.log('CTNHGT:', cartonHeight)
    debugMode && console.log('FREIGHTRATEAIR', freightRateAir)
    debugMode && console.log('FREIGHTRATESEA', freightRateSea)
    debugMode && console.log('CNTR20FT', containerRate20ft)
    debugMode && console.log('CNTR40FT', containerRate40ft)
    debugMode && console.log('CNTR40FTHQ', containerRate40ftHC)
    debugMode && console.log('DTYR:', dutyRate)
    debugMode && console.log('UNITPRICE', unitPrice)
    if (quantity && fobPrice && piecesPerCarton && grossWeight && values.dutyRate > -1) {
      const numCartons = quantity / piecesPerCarton
      debugMode && console.log('#Cartons:', numCartons)
      // console.log('changing')
      const totalCBM = ((cartonLength * cartonWidth * cartonHeight) / 1000000) * numCartons
      debugMode && console.log('TotalCBM:', totalCBM)

      const seaWeight =
        parseInt((((cartonLength * cartonWidth * cartonHeight) / 6000) * 100).toFixed(2)) || null

      const chargeableSeaWeight = seaWeight / 100 > grossWeight ? seaWeight / 100 : grossWeight

      debugMode && console.log('ChargeableSea', chargeableSeaWeight)
      // console.log('changing')

      const airWeight =
        parseInt((((cartonLength * cartonWidth * cartonHeight) / 5000) * 100).toFixed(2)) || null

      const chargeableAirWeight = airWeight / 100 > grossWeight ? airWeight / 100 : grossWeight
      debugMode && console.log('ChargeableAir', chargeableAirWeight)

      // console.log('changing')

      // console.log('changing')
      const shipmentWeight = grossWeight * numCartons
      // console.log('changing')
      debugMode && console.log('ShipWght:', shipmentWeight)

      const airRate = chargeableAirWeight * numCartons * freightRateAir
      const seaRate = chargeableSeaWeight * numCartons * freightRateSea
      // console.log('changing')
      // console.log('changing')

      const dutiesPerPiece = dutyRate * fobPrice

      debugMode && console.log('DUTIESPP', dutiesPerPiece)

      const duties = quantity * dutiesPerPiece || 0
      debugMode && console.log('DUTIES', duties)

      const estLandedRateSea = Number(
        (fobPrice + Number(seaAdditions)) // +
          // (values.does_sea_freight_include_duties ? 0 : dutiesPerPiece) +
          // seaRate / quantity
          .toFixed(2),
      )
      const estLandedRateAir = Number(
        (fobPrice + Number(airAdditions) + dutiesPerPiece) //+
          // airRate / quantity
          .toFixed(2),
      )

      debugMode && console.log('ESTSea', estLandedRateSea)
      debugMode && console.log('ESTAir', estLandedRateAir)

      //       (Sell price sea - Cost of goods - Cost of freight - Cost of duties) x Quantity
      // (Sell price sea - Cost of goods - Cost of freight - Cost of duties) x Quantity
      const estProfit = ((fobPrice - unitPrice) * quantity).toFixed(2)

      // CALC CBM BASED

      const capacity20ft = 25
      const capacity40ft = 60
      const capacity40ftHQ = 65

      let remainingCBM = totalCBM

      const calcLCL = (remaining) => {
        // calc lcl
        const remainingPercent = remaining / totalCBM
        debugMode && console.log('REM%', remainingPercent)

        const remainingWeight = chargeableSeaWeight * numCartons * remainingPercent
        debugMode && console.log('REMWGHT', remainingWeight)
        const lclCost = roundTo(remainingWeight * values.freightRateSea, 2)

        return lclCost
      }

      const containers = []
      // let seaRateCBM = 0

      do {
        debugMode && console.log('REMCBM', remainingCBM)
        //   debugMode && console.log('SEARATECBM', seaRateCBM)
        if (remainingCBM > capacity40ftHQ) {
          containers.push({
            type: 'FULL',
            size: '40ftHC',
            volume: capacity40ftHQ,
            cost: containerRate40ftHC,
          })
          // seaRateCBM += containerRate40ftHC
          remainingCBM = remainingCBM - capacity40ftHQ
        } else if (remainingCBM > capacity40ft) {
          if (remainingCBM < capacity40ftHQ) {
            containers.push({
              type: 'FULL',
              size: '40ft',
              volume: capacity40ft,
              cost: containerRate40ft,
            })
          }
          // seaRateCBM += containerRate40ft
          remainingCBM = remainingCBM - capacity40ft
        } else if (remainingCBM > capacity20ft) {
          containers.push({
            type: 'FULL',
            size: '20ft',
            volume: capacity20ft,
            cost: containerRate20ft,
          })
          // seaRateCBM += containerRate20ft
          remainingCBM = remainingCBM - capacity20ft
        } else {
          const lclCost = calcLCL(remainingCBM)

          if (lclCost < containerRate20ft) {
            containers.push({
              type: 'LCL',
              size: '20ft',
              volume: roundTo(remainingCBM, 2),
              cost: lclCost,
            })
            // seaRateCBM += lclCost
          } else {
            containers.push({
              type: 'FULL',
              size: '20ft',
              volume: remainingCBM,
              cost: containerRate20ft,
            })
            // seaRateCBM += containerRate20ft
          }

          remainingCBM = 0
        }
      } while (remainingCBM > 0)

      debugMode && console.log('CONTAINERS', containers)
      // console.log('changing')

      const seaRateCBM = containers.reduce((a, b) => a + Number(b.cost), 0)
      debugMode && console.log('SEACBM', seaRateCBM)

      const seaRateCBMPP = seaRateCBM / quantity
      debugMode &&
        console.log(
          'seaRate',
          seaRate,
          'SEAPP',
          seaRateCBMPP,
          'FOBP',
          fobPrice,
          'DPP',
          dutiesPerPiece,
        )

      const seaRateTotalPP =
        Number((seaRateCBMPP + fobPrice).toFixed(2)) +
        (values.does_sea_freight_include_duties ? 0 : dutiesPerPiece)

      debugMode && console.log('SEACBMTOTAL', seaRateTotalPP, 'ESTSEA', estLandedRateSea)

      // DISABLED TEMPORARILY - THIS SHOULD COMPARE THE LOWEST RATE AND SET THAT VALUE
      // if (seaRateTotalPP < estLandedRateSea) {
      //   // console.log('changing')
      //   formChange(`prices[${index}].totalFreightCostSea`, seaRateCBM)
      //   // console.log('changing')
      //   formChange(`prices[${index}].freightCostPerPieceSea`, seaRateTotalPP)
      //     formChange(`prices[${index}].estimatedFreightSea`, seaRateTotalPP)
      // } else {

      form.batch(() => {
        formChange(`prices[${index}].totalNumCartons`, numCartons)
        formChange('chargeableSeaWeight', chargeableSeaWeight)
        formChange('chargeableAirWeight', chargeableAirWeight)
        formChange(`prices[${index}].totalCBM`, totalCBM)
        formChange(`prices[${index}].shipmentWeight`, shipmentWeight)
        formChange(`prices[${index}].totalFreightCostAir`, airRate)
        formChange(`prices[${index}].freightCostPerPieceAir`, airRate / quantity)
        formChange(`prices[${index}].totalDuties`, duties)
        formChange(`prices[${index}].containers`, containers)
        formChange(`prices[${index}].totalFreightCostSea`, seaRate)
        formChange(`prices[${index}].freightCostPerPieceSea`, seaRateTotalPP)
        formChange(`prices[${index}].estimatedFreightAir`, estLandedRateAir)
        formChange(`prices[${index}].estimatedFreightSea`, estLandedRateSea)
        formChange(`prices[${index}].estProfit`, estProfit)
      })
    }

    // })
  }

  useEffect(() => {
    setFirstRenderDone(true)
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  // console.log(values)

  return (
    <div
      style={{
        // padding: '30px',
        borderRadius: '4px',
        backgroundColor: editing ? '#eab2b9' : null,
      }}
    >
      <Box mt="20px">
        <Box display="flex" alignItems="center">
          <span className={classes.sectionHeader}>Client Pricing</span>
          {editing ? (
            <div>
              {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
              <IconButton
                onClick={async () => {
                  // console.log(values)

                  setSaving(true)
                  try {
                    const prices = []
                    await Promise.all(
                      values.prices?.map(async (item) => {
                        const defaultPackagingOption = values.packagingSizes.find(
                          (obj) => obj.id === values.default_packaging_option_id,
                        )
                        // console.log(defaultPackagingOption)
                        const pkgParams = {
                          id: defaultPackagingOption.pkgId,
                          air_lcl: Math.round(defaultPackagingOption?.lclAir * 100) || 0,
                          sea_lcl: Math.round(defaultPackagingOption?.lclSea * 100) || 0,
                          air_buffer: Math.round(defaultPackagingOption?.lclAirBuffer * 100) || 0,
                          sea_buffer: Math.round(defaultPackagingOption?.lclSeaBuffer * 100) || 0,
                        }
                        await dataProvider.update('scm_quote_packaging_options', {
                          data: pkgParams,
                        })

                        // console.log('PRICE:', item)
                        prices.push({
                          ...(typeof item.id === 'number' ? { id: item.id } : null),
                          product_quote_id: values.product_quote?.id,
                          product_id: values.product_quote?.product?.id,
                          quantity: item.quantity,
                          price: Math.round(item.unitPrice * 100),
                          production_lead_time: item.client_production_lead_time,
                          lead_time_air: 10,
                          lead_time_sea: 50,
                          total_lead_time_air: 10 + item.client_production_lead_time || 0,
                          total_lead_time_sea: 50 + item.client_production_lead_time || 0,
                          fob_price: Math.round(item.fobPrice * 100),
                          estimated_freight_air: Math.round(item.estimatedFreightAir * 100),
                          estimated_freight_sea: Math.round(item.estimatedFreightSea * 100),
                          ...((item.fobMargin || item.fobMargin === 0) && {
                            margin_fob: Math.round(item.fobMargin * 100),
                          }),
                          ...(item.totalCBM && { total_cbm: Math.round(item.totalCBM * 100) }),
                          ...(item.shipmentWeight && {
                            shipment_weight: Math.round(item.shipmentWeight * 100),
                          }),
                          ...(item.totalNumCartons && {
                            total_cartons: Math.round(item.totalNumCartons),
                          }),
                          ...(item.totalDuties && {
                            total_duties: Math.round(item.totalDuties * 100),
                          }),
                          ...(item.totalFreightCostAir && {
                            freight_cost_air: Math.round(item.totalFreightCostAir * 100),
                          }),
                          ...(item.totalFreightCostSea && {
                            freight_cost_sea: Math.round(item.totalFreightCostSea * 100),
                          }),
                          ...(item.freightCostPerPieceAir && {
                            freight_cost_air_per_piece: Math.round(
                              item.freightCostPerPieceAir * 100,
                            ),
                          }),
                          ...(item.freightCostPerPieceSea && {
                            freight_cost_sea_per_piece: Math.round(
                              item.freightCostPerPieceSea * 100,
                            ),
                          }),
                          ...(item.containers && { containers: item.containers }),
                          ...(item.seaAdjustment && {
                            adjustment_sea: Math.round(item.seaAdjustment * 100),
                          }),
                          ...(item.airAdjustment && {
                            adjustment_air: Math.round(item.airAdjustment * 100),
                          }),
                        })
                      }),
                    )
                    // console.log('PRICES', prices)

                    await Promise.all(
                      prices.map(async (el) => {
                        if (el.id) {
                          await dataProvider.update('scm_quote_prices', { data: el })
                        } else {
                          await dataProvider.create('scm_quote_prices', {
                            data: el,
                          })
                        }
                      }),
                    )

                    const scmQuoteParams = {
                      id: values.id,
                      ...(values.containerRate20ft && {
                        container_rate_20ft: Math.round(values.containerRate20ft * 100),
                      }),
                      ...(values.containerRate40ft && {
                        container_rate_40ft: Math.round(values.containerRate40ft * 100),
                      }),
                      ...(values.containerRate40ftHC && {
                        container_rate_40ft_hc: Math.round(values.containerRate40ftHC * 100),
                      }),
                      ...(values.freightRateSea && {
                        freight_rate_sea: Math.round(values.freightRateSea * 100),
                      }),
                      ...(values.freightRateAir && {
                        freight_rate_air: Math.round(values.freightRateAir * 100),
                      }),
                      ...((values.dutyRate || values.dutyRate === 0) && {
                        duty_rate: Math.round(values.dutyRate * 100),
                      }),
                      ...(values.does_sea_freight_include_duties && {
                        does_sea_freight_include_duties: values.does_sea_freight_include_duties,
                      }),
                      container_capacity_20ft: 25,
                      container_capacity_40ft: 60,
                      container_capacity_40ft_hc: 65,
                    }

                    // console.log('SCMQUOTEPARAMS', scmQuoteParams)

                    dataProvider.update('scm_quote', { data: scmQuoteParams })

                    values.prices.map((price, index) => {
                      form.batch(() => {
                        formChange(`prices[${index}].savedFobPrice`, price.fobPrice)
                        formChange(
                          `prices[${index}].savedEstimatedFreightSea`,
                          price.estimatedFreightSea,
                        )
                        formChange(
                          `prices[${index}].savedEstimatedFreightAir`,
                          price.estimatedFreightAir,
                        )
                      })
                    })

                    //   console.log(resp)
                    notify('Update Complete', { type: 'info' })
                  } catch (error) {
                    console.log(error)
                    notify(error.message, { type: 'warning' })
                  }
                  setEditing(false)
                  setSaving(false)
                }}
                style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
              >
                <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
              </IconButton>

              <IconButton
                onClick={() => {
                  setEditing(false)
                }}
                style={{ maxWidth: '50px', maxHeight: '50px' }}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </div>
          ) : (
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          )}
        </Box>

        <span style={{ fontSize: '12px' }}>
          For the calculator to work, enter carton dimensions, pieces per carton, freight rates and
          optionally a duty rate.
        </span>

        <Box display="flex" mt="20px">
          <NumberFormat
            className={classes.fieldInputLeftNarrow}
            displayType="input"
            // thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            disabled={!editing}
            source="freightRateSea"
            // validate={required()}
            prefix="$ "
            label="Freight Rate Sea"
          />
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <span style={{ fontSize: '9px' }}>Duties included?</span>
            <Checkbox
              disabled={!editing}
              checked={values.does_sea_freight_include_duties}
              onChange={() => {
                // console.log('changing')
                formChange(
                  'does_sea_freight_include_duties',
                  !values.does_sea_freight_include_duties,
                )
              }}
              inputProps={{ 'aria-label': 'duties included checkbox' }}
            />
          </Box>
          <NumberFormat
            className={classes.fieldInput}
            displayType="input"
            // thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            disabled={!editing}
            source="freightRateAir"
            // validate={required()}
            prefix="$ "
            label="Freight Rate Air"
          />
          <NumberFormat
            className={classes.fieldInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            disabled={!editing}
            source="containerRate20ft"
            // validate={required()}
            prefix="$ "
            label="Container Rate 20ft"
            // onChange={(e) => {
            //   console.log(e.target.value)
            // }}
          />
          <NumberFormat
            className={classes.fieldInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            disabled={!editing}
            source="containerRate40ft"
            // validate={required()}
            prefix="$ "
            label="Container Rate 40ft"
            // onChange={(e) => {
            //   console.log(e.target.value)
            // }}
          />
          <NumberFormat
            className={classes.fieldInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            disabled={!editing}
            source="containerRate40ftHC"
            // validate={required()}
            prefix="$ "
            label="Container Rate 40ft HQ"
            // onChange={(e) => {
            //   console.log(e.target.value)
            // }}
          />
        </Box>
      </Box>
      <Box display="flex" mt="30px">
        <NumberFormat
          className={classes.fieldInputLeft}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          disabled={!editing}
          // validate={required()}
          suffix=" %"
          isNumericString
          source="dutyRate"
          label="Duty Rate"
        />
      </Box>
      <Box mt="20px">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="The default packaging option ID, see packaging table below">
                    <span>PkgRef</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '6%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="Quantities affect the price curve; each quantity acts as a milestone where the price changes">
                    <span>Order Quantity</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="Lead time, should be based on Factory Lead time plus a few days">
                    <span>MFG Lead Time</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="The starting price that you wish to set this product for, before any modifiers are applied">
                    <span>Client Base Price</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="Margin to be applied to the FOB Price">
                    <span>Client FOB Margin</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="(starting price + standard colour modifier + default packaging price + default size modifier) * fob margin">
                    <span>Client FOB Price</span>
                  </Tooltip>
                </StyledTableCell>
                {/* <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="A flat amount (not affected by margins/modifiers) to add to the sea freight price">
                    <span>Sea Adjuster</span>
                  </Tooltip>
                </StyledTableCell> */}
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="((fob price + default packaging sea modifier) * sea margin) + sea adjuster">
                    <span>Est. Landed Cost Sea</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="((fob price + default packaging air modifier) * air margin) + air adjuster">
                    <span>Est. Landed Cost Air</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  <Tooltip title="">
                    <span>Est. Gross Profit</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '8%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#AAFFAA',
                  }}
                  align="left"
                >
                  Saved FOB Price
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '8%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#AAFFAA',
                  }}
                  align="left"
                >
                  Saved Landed Cost Sea
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: '8%',
                    fontSize: '10px',
                    padding: '10px',
                    backgroundColor: '#AAFFAA',
                  }}
                  align="left"
                >
                  Saved Landed Cost Air
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.prices?.map((item, index) => (
                <FactoryQuoteRow
                  key={`price${item.id}`}
                  index={index}
                  id={item.id}
                  editing={editing}
                  formChange={formChange}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {editing && values.prices?.length < 3 ? (
          <IconButton
            aria-label="add-price"
            // className={classes.infoBtn}
            onClick={async () => {
              const newPrices = [...values.prices]
              const resp = await dataProvider.create('scm_quote_prices', {
                data: { scm_quote_id: values.id },
              })
              newPrices.push({ id: resp.data.id })
              // console.log(values.prices, newPrices)

              formChange('prices', newPrices)
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </div>
  )
}

const FactoryQuoteRow = ({ index, id, editing = false, formChange }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [firstRenderDone, setFirstRenderDone] = useState(false)

  const { values } = useFormState()
  const form = useForm()

  useLazyEffect(
    () => {
      const calcPrice = () => {
        const debugMode = false
        debugMode && console.log('------------------------------')
        debugMode &&
          console.log(
            'calc new price, margin:',
            values.prices[index].fobMargin,
            'base',
            values.prices[index].unitPrice,
          )
        debugMode && console.log('------------------------------')
        const unitPrice = values.prices[index].unitPrice
        const fobMargin = 1 - values.prices[index].fobMargin / 100

        const defaultPackagingOption = values.packagingSizes.find(
          (obj) => obj.id === values.default_packaging_option_id,
        )
        debugMode && console.log(defaultPackagingOption)

        const modifiers =
          (defaultPackagingOption?.packagingModifier || 0) +
          (defaultPackagingOption?.sizeModifier || 0) +
          (defaultPackagingOption?.scm_quote_packaging_option?.price_modifier || 0) +
          (defaultPackagingOption?.scm_quote_product_size?.price_modifier || 0) +
          (values.standardColourPriceModifier || 0)

        debugMode &&
          console.log('unitPrice', unitPrice, 'fobMargin', fobMargin, 'modifiers', modifiers)

        const basePrice = Number(((unitPrice + modifiers) / fobMargin).toFixed(2))

        debugMode && console.log('basePrice', basePrice)

        const newPrice = Number(basePrice)

        debugMode && console.log('rowInd', index, 'newPrice', newPrice)
        formChange(`prices[${index}].fobPrice`, newPrice)
      }

      firstRenderDone && calcPrice()
    },
    [
      values.prices[index].fobMargin,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.packagingModifier,
      values.packagingSizes[
        values.packagingSizes.find((opt) => opt.id === values.default_packaging_option_id)?.rowIndex
      ]?.sizeModifier,
    ],
    ['FOBMargin', 'DefaultPkgMod', 'DefaultSizeMod'],
  )

  useEffect(() => {
    return () => {}
  }, [])

  useLazyEffect(
    () => {
      const calcMargin = () => {
        const debugMode = false
        debugMode && console.log('------------------------------')
        debugMode &&
          console.log(
            'calc new margin, price:',
            values.prices[index].unitPrice,
            'fobPrice',
            values.prices[index].fobPrice,
          )
        debugMode && console.log('------------------------------')
        const unitPrice = values.prices[index].unitPrice

        const fobPrice = Number(values.prices[index].fobPrice)

        // const dutyRate = values.duty_rate / 10000 + 1

        // const fobPrice = Number((fobPriceWithDuties / dutyRate).toFixed(2))

        debugMode &&
          console.log(
            // 'DutyRate',
            // dutyRate,
            'FOBPriceWithoutDuties',
            fobPrice,
            // 'FOBWithDuties',
            // fobPriceWithDuties,
          )

        const defaultPackagingOption = values.packagingSizes.find(
          (obj) => obj.id === values.default_packaging_option_id,
        )

        debugMode && console.log(defaultPackagingOption)
        const modifiers =
          (defaultPackagingOption?.packagingModifier || 0) +
          (defaultPackagingOption?.sizeModifier || 0) +
          (defaultPackagingOption?.scm_quote_packaging_option?.price_modifier || 0) +
          (defaultPackagingOption?.scm_quote_product_size?.price_modifier || 0) +
          (values.standardColourPriceModifier || 0)

        debugMode && console.log('unitPrice', unitPrice, 'modifiers', modifiers)

        if (unitPrice + modifiers > fobPrice) {
          debugMode && console.log('unitPrice greater')
          form.batch(() => {
            formChange(`prices[${index}].fobPrice`, unitPrice + modifiers)
            formChange(`prices[${index}].fobMargin`, 0)
          })
        } else if (unitPrice + modifiers == fobPrice) {
          debugMode && console.log('unitPrice equal')
          formChange(`prices[${index}].fobMargin`, 0)
        } else {
          const marginMultiplier = Number((fobPrice - (unitPrice + modifiers)).toFixed(2))
          debugMode && console.log('newMarginPrecise', marginMultiplier / fobPrice)
          const newMargin = Number((marginMultiplier / fobPrice).toFixed(4) * 100)
          debugMode &&
            console.log(
              'newMarginMultiplier',
              marginMultiplier,
              'newMargin',
              newMargin,
              'oldMargin',
              values.prices[index].fobMargin,
              'modifiers',
              modifiers,
            )

          debugMode && console.log(newMargin != values.prices[index].fobMargin)
          debugMode && console.log('rowInd', index, 'newMargin', newMargin)

          if (newMargin != values.prices[index].fobMargin) {
            formChange(`prices[${index}].fobMargin`, newMargin)
          }
        }
      }
      firstRenderDone && calcMargin()
    },
    [values.prices[index].fobPrice, values.prices[index].unitPrice],
    ['FOBPrice', 'UnitPrice'],
  )

  useEffect(() => {
    setFirstRenderDone(true)
  }, [])

  // console.log(values)

  // const [seaInfoOpen, setSeaInfoOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  return (
    <TableRow key={`priceRow${index}`}>
      <BodyTableCell align="center" style={{ padding: '0px 10px' }}>
        <span>{values.default_packaging_option_id}</span>
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`prices[${index}].quantity`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`prices[${index}].client_production_lead_time`}
          validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`prices[${index}].unitPrice`}
          validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          suffix="%"
          disabled={!editing}
          source={`prices[${index}].fobMargin`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled={!editing}
          source={`prices[${index}].fobPrice`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled
          source={`prices[${index}].estimatedFreightSea`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled
          source={`prices[${index}].estimatedFreightAir`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled
          source={`prices[${index}].estProfit`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <Box display="flex">
          <IconButton
            aria-label="info"
            className={classes.infoBtn}
            onClick={() => {
              setInfoOpen(true)
            }}
          >
            <InfoIcon />
          </IconButton>
          {editing ? (
            <IconButton
              aria-label="info"
              className={classes.infoBtn}
              onClick={async () => {
                const prices = [...values.prices]
                // console.log('PRICES', prices)
                // console.log('INDEX', index)
                prices.splice(index, 1)
                // console.log('NEWPRICES', prices)
                // console.log(id)
                if (typeof id === 'number') {
                  // const delPrices = id
                  await dataProvider.delete('scm_quote_prices', { id })
                  notify('Deleted', { type: 'info' })
                }
                // console.log('changing')
                formChange('prices', prices)
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          ) : null}
          <Dialog
            fullWidth
            maxWidth="md"
            open={infoOpen}
            onClose={() => setInfoOpen(false)}
            aria-label="ViewAirInfo"
          >
            <DialogTitle>Breakdown</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        CBM
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        G.W.
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '15%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        # Cartons
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Freight Cost Sea
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Freight Cost Air
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Duties
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled={!editing}
                          source={`prices[${index}].totalCBM`}
                          suffix=" cbm"
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled={!editing}
                          source={`prices[${index}].shipmentWeight`}
                          suffix=" kg"
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled={!editing}
                          source={`prices[${index}].totalNumCartons`}
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled={!editing}
                          source={`prices[${index}].totalFreightCostSea`}
                          prefix="$ "
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled={!editing}
                          source={`prices[${index}].totalFreightCostAir`}
                          prefix="$ "
                          // validate={required()}
                        />
                      </BodyTableCell>
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled={!editing}
                          source={`prices[${index}].totalDuties`}
                          prefix="$ "
                          // validate={required()}
                        />
                      </BodyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ overflow: 'hidden', marginTop: '30px' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Container Size
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Cost
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Volume Used
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '17%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Cost Type
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.prices[index]?.containers?.map((container, ind) => (
                      <TableRow key={`cont${ind}`}>
                        <BodyTableCell>
                          <TextInput
                            variant="standard"
                            fullWidth
                            style={{ padding: '0px', margin: '0px', height: '100%' }}
                            source={`prices[${index}].containers[${ind}].size`}
                            label={false}
                            // validate={required()}
                          />
                        </BodyTableCell>
                        <BodyTableCell>
                          <NumberFormat
                            className={classes.numberTableInput}
                            displayType="input"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            isNumericString
                            disabled={!editing}
                            source={`prices[${index}].containers[${ind}].cost`}
                            prefix="$ "
                            // validate={required()}
                          />
                        </BodyTableCell>
                        <BodyTableCell>
                          <NumberFormat
                            className={classes.numberTableInput}
                            displayType="input"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            isNumericString
                            disabled={!editing}
                            source={`prices[${index}].containers[${ind}].volume`}
                            suffix=" cbm"
                            // validate={required()}
                          />
                        </BodyTableCell>
                        <BodyTableCell>
                          <TextInput
                            variant="standard"
                            fullWidth
                            style={{ padding: '0px', margin: '0px', height: '100%' }}
                            disabled={!editing}
                            source={`prices[${index}].containers[${ind}].type`}
                            label={false}
                            // validate={required()}
                          />
                        </BodyTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Dialog>
        </Box>
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={
          values.prices[index].fobPrice === values.prices[index].savedFobPrice
            ? { padding: '0px' }
            : { padding: '0px', border: 'solid red 2px' }
        }
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled
          source={`prices[${index}].savedFobPrice`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={
          values.prices[index].estimatedFreightSea === values.prices[index].savedEstimatedFreightSea
            ? { padding: '0px' }
            : { padding: '0px', border: 'solid red 2px' }
        }
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled
          source={`prices[${index}].savedEstimatedFreightSea`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={
          values.prices[index].estimatedFreightAir === values.prices[index].savedEstimatedFreightAir
            ? { padding: '0px' }
            : { padding: '0px', border: 'solid red 2px' }
        }
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled
          source={`prices[${index}].savedEstimatedFreightAir`}
          // validate={required()}
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default FactoryQuoteCalculator
