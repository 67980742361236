import React, { useState, useEffect } from 'react'
import {
  Filter,
  List,
  SearchInput,
  FunctionField,
  SelectInput,
  Datagrid,
  DatagridBody,
  DateField,
  TopToolbar,
  Pagination,
  useRedirect,
  useRefresh,
  useNotify,
  useDataProvider,
} from 'react-admin'
import {
  Button,
  // Checkbox,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import { getDisplayName } from '../../utils/utils'
import CreateDialog from './components/CreateDialog'
// import EditDialog from './components/EditDialog'

const ListActions = (props) => {
  const { className } = props
  const [createOpen, setCreateOpen] = useState(false)

  return (
    <TopToolbar className={className}>
      <Button
        style={{
          color: 'black',
          border: 'solid 1px rgba(180, 125, 80, 0.5)',
          backgroundColor: 'rgba(255, 149, 102, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(255, 149, 102, 0.3)',
          },
        }}
        onClick={async () => {
          setCreateOpen(true)
        }}
      >
        Create
      </Button>
      <CreateDialog createOpen={createOpen} setCreateOpen={setCreateOpen} />
    </TopToolbar>
  )
}

export const ProjectFilter = (props) => {
  const { scaUsers, ...rest } = props
  return (
    <Filter {...rest}>
      <SearchInput
        // label="Name/Sku"
        source="name"
        alwaysOn
        variant="standard"
        fullWidth
        placeholder="Project Name"
        style={{ minWidth: '160px' }}
      />
      <SearchInput
        // label="Name/Sku"
        source="client_name"
        alwaysOn
        variant="standard"
        fullWidth
        placeholder="Client Name"
        style={{ minWidth: '160px' }}
      />
      <SelectInput
        label="Assigned To"
        source="assigned_to"
        style={{ minWidth: '160px' }}
        alwaysOn
        choices={scaUsers || []}
        variant="standard"
        optionText={(val) => getDisplayName(val)}
        fullWidth
      />
      {/* <DateInput
      source="date_gte"
      label="Quote date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Quote date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    /> */}
    </Filter>
  )
}

const ProductList = (props) => {
  // const [editData, setEditData] = useState(null)
  const redirect = useRedirect()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [scaUsers, setSCAUsers] = useState([])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userData } = await dataProvider.getList('user', {
          // filter: { roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        // console.log(userData)
        !abortController.signal.aborted && setSCAUsers(userData)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  const MyDatagridRow = ({
    record,
    resource,
    id,
    // onToggleItem,
    children,
    // selected,
    // selectable,
    basePath,
  }) => {
    if (!record) {
      return null
    }
    const [editing, setEditing] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [editableValues, setEditableValues] = useState({
      current_action: record.current_action || '',
      assigned_to: scaUsers.find((el) => el.id === record.assigned_to) || '',
      status: record.status || '',
      priority: record.priority || '',
    })
    return (
      <TableRow key={id} className="MuiTableRow-root MuiTableRow-hover">
        {/* <TableCell padding="none">
          <Checkbox
            disabled={selectable}
            checked={selected}
            onClick={(event) => onToggleItem(id, event)}
          />
        </TableCell> */}
        {React.Children.map(children, (field) => {
          const editable =
            field?.props?.label === 'Current Action' ||
            field?.props?.label === 'Assigned To' ||
            field?.props?.label === 'Status' ||
            field?.props?.label === 'Priority'
          return (
            <TableCell
              style={!editing ? { cursor: 'pointer' } : { margin: '0px', padding: '0px 10px' }}
              onClick={() => (!editing ? redirect(`/projects/${record.id}`) : null)}
              key={`${id}-${field.props.source}`}
            >
              {editable ? (
                <EditableField
                  scaUsers={scaUsers}
                  label={field?.props?.label}
                  record={editableValues}
                  setEditableValues={setEditableValues}
                  editing={editing}
                  field={field?.props?.source}
                />
              ) : (
                React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })
              )}
            </TableCell>
          )
        })}
        {editing ? (
          <TableCell style={{ display: 'flex' }}>
            <IconButton
              onClick={async () => {
                try {
                  await dataProvider.update('project', {
                    data: {
                      id,
                      assigned_to: editableValues.assigned_to.id,
                      current_action: editableValues.current_action,
                      priority: editableValues.priority,
                      status: editableValues.status,
                    },
                  })

                  setEditing(false)
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setEditing(false)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </TableCell>
        ) : deleting ? (
          <TableCell style={{ display: 'flex' }}>
            <IconButton
              onClick={async () => {
                try {
                  await dataProvider.delete('project', {
                    id,
                  })

                  refresh()
                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  setDeleting(false)
                  notify(error.message, { type: 'warning' })
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleting(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </TableCell>
        ) : (
          <TableCell style={{ minWidth: '88px' }}>
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleting(true)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    )
  }

  const MyDatagridBody = (props) => <DatagridBody {...props} row={<MyDatagridRow />} />
  const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />
  const ProjectPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

  return (
    <List
      {...props}
      filter={{
        status: props.filter?.status ? props.filter?.status : null,
      }}
      filters={props.basePath === '/projects' ? <ProjectFilter scaUsers={scaUsers} /> : null}
      actions={<ListActions />}
      perPage={100}
      sort={{ field: 'updated_at', order: 'DESC' }}
      empty={false}
      pagination={<ProjectPagination />}
      bulkActionButtons={false}
    >
      <MyDatagrid path="projects" rowClick="edit">
        <DateField source="updated_at" label="Last update" />
        <DateField source="received_date" label="Received" />
        <FunctionField
          label="Client"
          source="client_name"
          render={(record) => {
            const name = record.client_name
            return name?.length > 15 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 12)}...`}</span>
              </Tooltip>
            ) : (
              <span>{name}</span>
            )
          }}
        />
        <FunctionField
          label="Project Name"
          source="project_name"
          render={(record) => {
            const name = record.project_name
            return name?.length > 15 ? (
              <Tooltip title={name}>
                <span>{`${name?.substring(0, 12)}...`}</span>
              </Tooltip>
            ) : (
              name
            )
          }}
        />
        <FunctionField
          label="Current Action"
          source="current_action"
          render={(record) => {
            const field = record.current_action
            return field?.length > 40 ? (
              <Tooltip title={field}>
                <span>{`${field?.substring(0, 37)}...`}</span>
              </Tooltip>
            ) : (
              field
            )
          }}
        />
        <FunctionField
          render={(record) => (record.assigned_to ? getDisplayName(record.assigned_to) : 'None')}
          source="assigned_to"
          label="Assigned To"
        />
        <FunctionField render={(record) => record.status} label="Status" source="status" />
        <FunctionField render={(record) => record.priority} label="Priority" source="priority" />
      </MyDatagrid>
    </List>
  )
}

const EditableField = (props) => {
  const { record, field, setEditableValues, editing, scaUsers = [], ...rest } = props
  if (editing && field !== 'assigned_to') {
    return (
      <TextField
        id="editableTextField"
        value={record[field]}
        disabled={!editing}
        fullWidth
        style={field === 'current_action' ? { minWidth: '200px' } : null}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEditableValues((prevState) => {
            return {
              ...prevState,
              [field]: event.target.value,
            }
          })
        }}
        {...rest}
      />
    )
  } else if (editing && field === 'assigned_to') {
    return (
      <Select
        id="scaSelect"
        value={record.assigned_to}
        fullWidth
        style={{ marginBottom: '4px' }}
        variant="standard"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEditableValues((prevState) => {
            return {
              ...prevState,
              assigned_to: event.target.value,
            }
          })
        }}
      >
        {scaUsers.map((el) => (
          <MenuItem key={`sca${el.id}`} value={el}>
            {getDisplayName(el)}
          </MenuItem>
        ))}
      </Select>
    )
  } else if (!editing && field === 'assigned_to') {
    return <span>{record.assigned_to ? getDisplayName(record.assigned_to) : 'None'}</span>
  } else {
    return <span>{record[field]}</span>
  }
}

export default ProductList
