import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#333',
    marginTop: '60px',
  },
})

const ItemTitle = ({ title, fontSize, style }) => {
  const classes = useStyles()
  return (
    <div style={{ fontSize, ...style }} className={classes.root}>
      {title}
    </div>
  )
}

export default ItemTitle
