import React from 'react'
import { Create, FormWithRedirect, useDataProvider, useNotify, useRedirect } from 'react-admin'
import { CardContent, Card } from '@material-ui/core'
import moment from 'moment'
import lodashGet from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import CustomToolbar from '../../components/CustomToolbar'
import MainBox from './component/MainBox'
import uploadFile from '../../../dataProvider/upload'
// import { errorMessage } from '../adminGroup/AdminGroupCreate'
import CompanyDetails from './component/CompanyDetails'
import CardHeader from './component/CardHeader'

export const styles = {
  doubleWidth: { width: 544 },
  leftFormGroup: { display: 'inline-block' },
  rightFormGroup: { display: 'inline-block', marginLeft: 32 },
}

const useStyles = makeStyles(styles)

const SupplierCreate = (props) => (
  <Create {...props}>
    <SupplierForm permissions={props.permissions} variant="outlined" />
  </Create>
)

const SupplierForm = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [certDates, setCertDates] = React.useState([])

  const notify = useNotify()

  const redirectTo = useRedirect()

  const handleSubmit = async (record) => {
    // const {
    // businessLicences = [],
    // companyPresentation = [],
    // productCatalog = [],
    // companyPhotos = [],
    // ...supplier
    // } = record

    const params = {
      name: record.companyName,
      local_name: record.chineseName,
      business_licence_no: record.businessLicenceNO,
      legal_address: record.legalAddress,
      // status: 'active',
      //  website: [
      //      ""
      //  ],
      employee_total: record.employeeTotal,
      factory_size: record.facotrySize,
      annual_turnover: record.annualTurnover,
      primary_market: record.primaryMarket,
      year_established: record.yearEstablished,
      company_introduction: record.introduction,
      addresses: [
        {
          ...(!!record.address?.line1 && { address_line1: record.address?.line1 }),
          ...(!!record.address?.line2 && { address_line2: record.address?.line2 }),
          ...(!!record.address?.city && { city: record.address?.city }),
          ...(!!record.address?.province && { province: record.address?.province }),
          ...(!!record.address?.countryCode && { country_code: record.address?.countryCode }),
          ...(!!record.address?.fullAddress && { local_full_address: record.address?.fullAddress }),
          ...(!!record.address?.shippingPort && { shipping_port_id: record.address?.shippingPort }),
        },
      ],
      contacts: [
        {
          ...(!!record.address?.first_name && { firstName: record.address?.first_name }),
          ...(!!record.address?.last_name && { lastName: record.address?.last_name }),
          ...(!!record.address?.position && { position: record.address?.position }),
          ...(!!record.address?.email && { email: record.address?.email }),
          ...(!!record.address?.mobile && { mobile: record.address?.mobile }),
        },
      ],
    }

    if (Object.keys(params.addresses[0]).length === 0) {
      delete params.addresses
    }

    if (Object.keys(params.contacts[0]).length === 0) {
      delete params.contacts
    }

    // certifications: {
    //   data: [
    //     {
    //       name: "ISO-xxxxx",
    //       number: "456-VTRB-456-EGRREGR",
    //       type: "factory",
    //       notifying_body: "ISOBODY",
    //       valid_end_date: "",
    //       valid_start_date: "",
    //       document: {
    //         data: {
    //           filename: "",
    //           url: ""
    //         }
    //       }
    //     }
    //   ]
    // },
    //  files: {
    //    data: [
    //      {
    //        type: "company-photo",
    //        file: {
    //          data: {
    //            filename: "",
    //            url: ""
    //          }
    //        }
    //      },
    //      {
    //        type: " product-catalog",
    //        file: {
    //          data: {
    //            filename: "",
    //            url: ""
    //          }
    //        }
    //      },
    //      {
    //        type: "business-licence",
    //        file: {
    //          data: {
    //            filename: "",
    //            url: ""
    //          }
    //        }
    //      },
    //      {
    //        type: "company-presentation",
    //        file: {
    //          data: {
    //            filename: "",
    //            url: ""
    //          }
    //        }
    //      }
    //    ]
    //  }
    // }

    const certs = []
    await Promise.all(
      record.certifications.map(async (item) => {
        const cert = item
        // console.log('CERT', cert)
        if (item.url && item.url.rawFile) {
          const formData = new FormData()
          formData.append('file', item.url.rawFile)
          const res = await uploadFile(formData)
          const file = {
            url: lodashGet(res.data, 'files.file.url', ''),
            filename: item.url.title || '',
          }
          cert.document = file
        } else {
          delete cert.url
        }
        delete cert.url
        certs.push(cert)
      }),
    )
    if (certs.length > 0) params.certifications = certs

    // const files = []

    // await Promise.all(
    //   record.businessLicences.map(async item => {
    //     console.log('item', item)
    //       const formData = new FormData()
    //       formData.append('file', item.url.rawFile)
    //       const res = await uploadFile(formData)
    //       const file = {
    //         url: lodashGet(res.data, 'files.file.url', ''),
    //         filename: item.url.title || '',
    //       }
    //       item.document = file
    //       files.push(item)
    //     }
    //   ),
    // )

    // for (let key in businessLicences) {
    //   const item = businessLicences[key]
    //   const formData = new FormData()
    //   formData.append('file', item.rawFile)
    //   const res = await uploadFile(formData)
    //   supplier.businessLicences.push({
    //     url: lodashGet(res.data, 'files.file.url', ''),
    //     filename: item.title,
    //   })
    // }

    // if (!supplier.companyPresentation) {
    //   supplier.companyPresentation = []
    // }

    // for (let key in companyPresentation) {
    //   const item = companyPresentation[key]
    //   const formData = new FormData()
    //   formData.append('file', item.rawFile)
    //   const res = await uploadFile(formData)
    //   supplier.companyPresentation.push({
    //     url: lodashGet(res.data, 'files.file.url', ''),
    //     filename: item.title,
    //   })
    // }

    // if (!supplier.productCatalog) {
    //   supplier.productCatalog = []
    // }
    // for (let key in productCatalog) {
    //   const item = productCatalog[key]
    //   const formData = new FormData()
    //   formData.append('file', item.rawFile)
    //   const res = await uploadFile(formData)
    //   supplier.productCatalog.push({
    //     url: lodashGet(res.data, 'files.file.url', ''),
    //     filename: item.title,
    //   })
    // }

    // if (!supplier.companyPhotos) {
    //   supplier.companyPhotos = []
    // }

    // for (let key in companyPhotos) {
    //   const item = companyPhotos[key]
    //   const formData = new FormData()
    //   formData.append('file', item.rawFile)
    //   const res = await uploadFile(formData)
    //   supplier.companyPhotos.push({
    //     url: lodashGet(res.data, 'files.file.url', ''),
    //     filename: item.title,
    //   })
    // }

    // console.log(params)

    try {
      const { data } = await dataProvider.createSupplier('suppliers', { objects: params })
      notify('Supplier created', 'info')
      redirectTo(`/suppliers/${data.id}/show`)
    } catch (error) {
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data submission error',
        ),
        'warning',
      )
    }
  }
  const [isSend, setIsSend] = React.useState(1)

  const sendAction = async () => {
    // console.log(value)
    try {
      // await dataProvider.createSupplierInvitation('suppliers', { email: value })
      // console.log(data)
      // refresh()
      // props.close()
      // notify('Sent successful', 'info')
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form className={classes.form}>
            <CardHeader
              isLi={isSend !== 1}
              label={isSend === 1 ? 'Supplier Registration Form' : 'Hi there!'}
              className={classes.title}
              isSend={isSend}
              date={moment().format('YYYY/MM/DD')}
              setIsSend={(value) => {
                setIsSend(value)
              }}
              sendAction={(value) => {
                sendAction(value)
              }}
            />
            <CardContent>
              <MainBox record={formProps.record} />
              <CompanyDetails
                record={formProps.record}
                certDates={certDates}
                setCertDates={setCertDates}
              />
            </CardContent>
            <CustomToolbar
              redirect={null}
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleSubmit={handleSubmit}
              saving={formProps.saving}
              resource="suppliers"
            />
          </form>
        </Card>
      )}
    />
  )
}

export default SupplierCreate
