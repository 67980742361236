import React from 'react'
import {
  Box,
  // Button,
  Tooltip,
  // Chip,
  //   Drawer,
} from '@material-ui/core'
import { useFormState, useForm } from 'react-final-form'
// import AddIcon from '@material-ui/icons/Add'
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  required,
  SelectInput,
  // useDataProvider,
  // TextInput,
  // NullableBooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
// import NumberText from 'react-number-format'
// import { roundTo } from '../../../utils/utils'
import WarningIcon from '@material-ui/icons/Warning'
// import { getDisplayAddress } from '../../../utils/utils'
import { ColorField } from '../../components/ColourPicker'
import Confirm from '../../components/Confirm'
// import CancelIcon from '@material-ui/icons/Cancel'
// import RemoveCircle from '@material-ui/icons/Delete'
// import TextInput from '../../../components/OutlinedTextInput'
import NumberFormat from '../../components/CustomNumberFormat'
// import Customiser from './Customiser'
import useCustomiserStyles from '../styles/customiser'

const CreateOrderForm = ({ formProps, handleCreateOffer, calcUnitPrice }) => {
  const { values, errors } = useFormState()
  // const dataProvider = useDataProvider()
  const form = useForm()
  //   const [open, setOpen] = useState(false)

  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)
  const classes = useCustomiserStyles()

  //   console.log(values)

  const colourOptions =
    values.product_components?.length > 0 && values.product_components[0].colours

  //   console.log(colourOptions)

  const sortedPrices = [...values.prices].sort((a, b) => b.quantity - a.quantity)
  const dutiesMultiplier = values.dutyRate / 100 + 1

  /*
    Photo
    Short Desc
    Long Desc
    Product_quote_id
    Marketing Agency : 1
    SCA_ID
    Status (Pending Purchase Order)
    Quantity (moq) / Investor Price Per Piece / Logistics Lead Time
    Retail Price
    Incoterms
    Freight Method
    Type
    Client Quote ID
    HTS Code
  */

  return (
    <Box display="flex" flexDirection="column">
      <span style={{ fontSize: '12px' }}>Minimum total quantity: {values.prices[0].quantity}</span>
      <Box display="flex" mt="10px">
        <ArrayInput source="orderVariants" label="Variants">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            // addButton={
            //   <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
            //     <AddIcon />
            //   </Button>
            // }
            disableAdd={true}
            disableRemove={true}
            // removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData, rest }) => {
                const uniquePOPackagingOptions = []

                formData.packagingSizes?.map((spec) => {
                  if (
                    !uniquePOPackagingOptions.some(
                      (opt) => opt.packagingType === spec.packagingType,
                    )
                  ) {
                    uniquePOPackagingOptions.push(spec)
                  }
                })

                const uniquePOSizeOptions = formData.packagingSizes.filter(
                  (el) => el.packagingType === scopedFormData?.poPackagingOption,
                )

                const itemQuantity = formData.orderVariants?.reduce((a, b) => a + b?.quantity, 0)

                // console.log('ITEMQTY', itemQuantity)

                const finalPrice = scopedFormData?.isSampleVariant
                  ? values.clientSamplePrice
                  : calcUnitPrice({
                      quantity: itemQuantity,
                      data: scopedFormData,
                      prices: sortedPrices,
                      dutiesMultiplier,
                      pkgOptions: formData.packagingSizes,
                      colourModifier: formData.standardColourPriceModifier,
                    })

                // console.log(finalPrice)

                const error = finalPrice
                  ? null
                  : !scopedFormData?.isSampleVariant && itemQuantity < values.prices[0].quantity
                  ? 'Quantity not met'
                  : 'No sample price'

                if (error) {
                  form.change('orderError', true)
                } else {
                  form.change('orderError', false)
                }
                // console.log('scopedForm', scopedFormData, error)
                return (
                  <Box display="flex" alignItems="center">
                    {uniquePOPackagingOptions?.length > 0 ? (
                      <SelectInput
                        label="Packaging"
                        source={getSource('poPackagingOption')}
                        {...rest}
                        record={scopedFormData}
                        choices={uniquePOPackagingOptions}
                        defaultValue=""
                        validate={required()}
                        fullWidth
                        optionText="packagingType"
                        optionValue="packagingType"
                        variant="standard"
                        style={{ margin: '0px 10px', width: '100px', minWidth: '100px' }}
                      />
                    ) : // ) : uniquePOPackagingOptions?.length === 1 ? (
                    //   <span style={{ fontSize: '12px' }}>
                    //     {uniquePOPackagingOptions[0].packagingType}
                    //   </span>
                    null}

                    {uniquePOSizeOptions?.length > 0 ? (
                      <SelectInput
                        choices={uniquePOSizeOptions}
                        label="Size"
                        optionValue="sizeName"
                        optionText="sizeName"
                        validate={required()}
                        source={getSource('size')}
                        {...rest}
                        record={scopedFormData}
                        variant="standard"
                        fullWidth
                        style={{ margin: '0px 10px', width: '100px', minWidth: '100px' }}
                      />
                    ) : // ) : uniquePOSizeOptions?.length === 1 && uniquePOSizeOptions[0].sizeName ? (
                    //   <span style={{ fontSize: '12px' }}>{uniquePOSizeOptions[0].sizeName}</span>
                    null}
                    {colourOptions?.length > 0 ? (
                      <SelectInput
                        label="Colour"
                        source={getSource('colour')}
                        {...rest}
                        record={scopedFormData}
                        choices={colourOptions}
                        // optionValue="id"
                        optionText={(val) =>
                          val.value.charAt(0) === '#' ? (
                            <ColorField addLabel record={val} source="value" />
                          ) : (
                            val.value
                          )
                        }
                        defaultValue=""
                        fullWidth
                        validate={required()}
                        variant="standard"
                        style={{ margin: '0px 10px', width: '220px' }}
                      />
                    ) : null}
                    <NumberFormat
                      label="Quantity"
                      source={getSource('quantity')}
                      {...rest}
                      className={classes.numberInputOrder}
                      record={scopedFormData}
                      validate={required()}
                      displayType="input"
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      isNumericString
                      defaultValue={0}
                      // style={{ margin: '0px 10px' }}
                    />
                    {/* {scopedFormData?.Colour === 'Custom' ? (
                <div>
                  <Button onClick={() => setOpen(true)}>Customise</Button>
                  <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ minWidth: '35vw' }}
                    >
                      <Customiser
                        svgString={
                          (values.product_quote?.svg_file, values.product_quote?.components)
                        }
                      />
                      <Button onClick={() => setOpen(false)}>Confirm</Button>
                    </Box>
                  </Drawer>
                </div>
              ) : (
                <div style={{ width: '120px', minWidth: '120px' }} />
              )} */}
                    <span style={finalPrice ? null : { color: 'red' }}>
                      {finalPrice ? `$${finalPrice.toFixed(2)}` : error}
                    </span>
                  </Box>
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <div style={{ textAlign: 'center' }}>
          {Object.values(errors)?.length > 0 ? (
            <Tooltip title={Object.values(errors).join(', ')}>
              <WarningIcon style={{ marginTop: '10px', marginLeft: '20px' }} />
            </Tooltip>
          ) : null}
        </div>
      </Box>
      <Confirm
        redirect="/offer"
        // redirect="edit"
        record={formProps.record}
        basePath={formProps.basePath}
        label="SUBMIT"
        undoable
        invalid={formProps.invalid}
        handleSubmit={async (newRecord) => {
          await handleCreateOffer({
            submission: newRecord,
            sortedPrices,
            dutiesMultiplier,
          })
        }}
        saving={formProps.saving}
        disabled={formProps.saving || formProps.invalid || values.orderError}
        resource="offer"
      />
    </Box>
  )
}

export default CreateOrderForm
