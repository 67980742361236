import React, { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState } from 'react-final-form'
import useStyles from '../styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import NumberFormat from '../../components/CustomNumberFormat'

const NumberFieldInput = (props) => {
  // const classes = useStyles()
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const { label, parseMultiplier, resource, id, field, formField, ...rest } = props

  return (
    <Box display="flex">
      <NumberFormat
        label={label || false}
        source={formField}
        disabled={!editing}
        // validate={required()}
        displayType="input"
        thousandSeparator
        decimalScale={0}
        fixedDecimalScale
        isNumericString
        // defaultValue={values[field]}
        // style={{ padding: '0px' }}
        className={classes.textField}
        {...rest}
      />
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)

              try {
                await dataProvider.update(resource, {
                  data: {
                    id,
                    [field]: parseMultiplier
                      ? Math.round(values[formField] * 100)
                      : values[formField],
                  },
                })
                // console.log({
                //   data: { id, [field]: values[formField] },
                // })

                // console.log('field', field, fieldLength, values[formField])

                notify('Update Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                setSaving(false)
                setEditing(false)
                notify(error.message, { type: 'warning' })
              }
              setSaving(false)
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ marginRight: '44px', maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default NumberFieldInput
