import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useDataProvider, useNotify } from 'react-admin'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import IconButton from '@material-ui/core/IconButton'
import lodashGet from 'lodash/get'
import { getDisplayName } from '../../../../utils/utils'
import useStyles from '../styles/index'

const OfferHeaderSection = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [scaUsers, setSCAUsers] = useState([])
  const [editSCA, setEditSCA] = useState(false)
  const { scaUser, setSCAUser } = props

  // console.log('HEADERPROPS', props)

  const handleSaveSCA = async () => {
    try {
      await dataProvider.update('offer', {
        data: { id: props.record.id, sca_id: scaUser.id },
      })

      // console.log(data)
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userData } = await dataProvider.getList('user', {
          filter: { roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
        })
        // console.log(userData)
        !abortController.signal.aborted && setSCAUsers(userData)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/#/customOffer"
      onClick={handleClickBreacrumb}
    >
      Client Orders
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   href="/material-ui/getting-started/installation/"
    //   onClick={handleClickBreacrumb}
    // >
    //   Core
    // </Link>,
    <Typography key="2" color="primary">
      Product
    </Typography>,
  ]

  const handleClickBreacrumb = () => {
    // console.log(props)
  }

  const StatBox = (props) => {
    const classes = useStyles()

    return (
      <Grid item xs={2} md={2}>
        <Box display="flex" flexDirection="column">
          <span className={classes.statValue}>{props.value || ''}</span>
          <span className={classes.statTitle}>{props.title}</span>
        </Box>
      </Grid>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" m="0px 0px 10px 0px">
        <span className={classes.productHeader}>{props.record?.name}</span>
      </Box>
      <Box display="flex" m="0px 0px 10px 0px">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Box display="flex">
        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <img className={classes.primaryPhoto} src={props.record?.primary_photo?.url} alt="" />
        </Box>

        <Box flex={3} display="flex" p="10px 30px" flexDirection="column">
          <span className={classes.subHeader}>{props.record?.name}</span>
          <span className={classes.subHeaderSKU}>{props.record?.id}</span>
          <Box mt="20px">
            <Grid container spacing={2}>
              <StatBox title="PO Quantity" value={props.record?.moq} />
              <StatBox
                title="Client Price Per Piece"
                value={`$${
                  props.record?.investor_price_per_piece
                    ? (props.record?.investor_price_per_piece / 100).toFixed(2)
                    : 'N/A'
                }`}
              />
              <StatBox
                title="Total Client Price"
                value={`$${
                  props.record?.investor_price_per_piece && props.record?.moq
                    ? ((props.record?.investor_price_per_piece / 100) * props.record?.moq).toFixed(
                        2,
                      )
                    : 'N/A'
                }`}
              />
            </Grid>
          </Box>
          <Box mt="20px" display="flex" alignItems="center">
            <InputLabel style={{ fontSize: '10px', marginRight: '10px' }} id="appUserLabel">
              SCA:
            </InputLabel>
            {!editSCA && (
              <Box display="flex" alignItems="center">
                <span style={{ fontSize: '14px' }}>{getDisplayName(scaUser, 30, 30, false)}</span>
                <IconButton
                  onClick={() => {
                    setEditSCA(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
            {editSCA && scaUsers?.length > 1 && (
              <Box display="flex" alignItems="center">
                <Select
                  style={{ width: '250px' }}
                  value={scaUser}
                  labelId="appUserLabel"
                  renderValue={(item) => getDisplayName(item, 30, 30, false)}
                  onChange={(e) => setSCAUser(e.target.value)}
                >
                  {scaUsers?.map((item) => (
                    <MenuItem value={item} key={item.id}>
                      {getDisplayName(item, 30, 30, true)}
                    </MenuItem>
                  ))}
                </Select>
                <IconButton
                  onClick={async () => {
                    await handleSaveSCA()
                    setEditSCA(false)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OfferHeaderSection
