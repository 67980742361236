import OffersIcon from '@material-ui/icons/Collections'
import OffersList from './OfferList'
import OffersEdit from './OfferEdit'
import OffersCreate from './OfferCreate'
import OffersShow from './OfferShow'

export default {
  list: OffersList,
  create: OffersCreate,
  edit: OffersEdit,
  icon: OffersIcon,
  show: OffersShow,
}
