export const CHANGE_THEME = 'CHANGE_THEME'

export const changeTheme = (theme) => ({
  type: CHANGE_THEME,
  payload: theme,
})

export const CHANGE_USERTOKEN = 'CHANGE_USERTOKEN'

export const changeUserToken = (usertoken) => ({
  type: CHANGE_USERTOKEN,
  payload: usertoken,
})

export const CHANGE_ACTIVE_ORG = 'CHANGE_ACTIVE_ORG'

export const changeActiveOrg = (activeOrgId) => ({
  type: CHANGE_ACTIVE_ORG,
  payload: activeOrgId,
})

export const CHANGE_ACTIVE_ORG_NAME = 'CHANGE_ACTIVE_ORG_NAME'

export const changeActiveOrgName = (activeOrgName) => ({
  type: CHANGE_ACTIVE_ORG_NAME,
  payload: activeOrgName,
})
