import React from 'react'
import { Box, Card, Button } from '@material-ui/core/'
import {
  useRedirect,
  //  FormWithRedirect,
  useDataProvider,
} from 'react-admin'
// import { useFormState } from 'react-final-form'
import useStyles from './styles'
// import { ColorInput } from '../components/ColourPicker'
// import imageMask from './test 2.jpg'

const ConfigurationPanel = () => {
  const classes = useStyles()
  const redirect = useRedirect()

  const dataProvider = useDataProvider()

  // const components = [
  //   {
  //     id: 0,
  //     svg_shape_index: 0,
  //     name: 'Brim',
  //     default_colour: '#123456',
  //     options: [
  //       { option_value: '#123456', option: { name: 'Colour' } },
  //       { option_value: '#654321', option: { name: 'Colour' } },
  //       { option_value: '#ABCDEF', option: { name: 'Colour' } },
  //       { option_value: '#FEDCBA', option: { name: 'Colour' } },
  //     ],
  //     customisations: [{ option_value: 'custom', option: { name: 'Color' } }],
  //   },
  //   {
  //     id: 1,
  //     svg_shape_index: 1,
  //     name: 'Front Panel',
  //     default_colour: '#123456',
  //     options: [
  //       { option_value: '#123456', option: { name: 'Colour' } },
  //       { option_value: '#654321', option: { name: 'Colour' } },
  //       { option_value: '#ABCDEF', option: { name: 'Colour' } },
  //       { option_value: '#FEDCBA', option: { name: 'Colour' } },
  //     ],
  //     customisations: [{ option_value: 'custom', option: { name: 'Color' } }],
  //   },
  //   {
  //     id: 2,
  //     svg_shape_index: 2,
  //     name: 'Back Panel',
  //     default_colour: '#123456',
  //     options: [
  //       { option_value: '#123456', option: { name: 'Colour' } },
  //       { option_value: '#654321', option: { name: 'Colour' } },
  //       { option_value: '#ABCDEF', option: { name: 'Colour' } },
  //       { option_value: '#FEDCBA', option: { name: 'Colour' } },
  //     ],
  //     customisations: [{ option_value: 'custom', option: { name: 'Color' } }],
  //   },
  //   {
  //     id: 3,
  //     svg_shape_index: 3,
  //     name: 'Button',
  //     default_colour: '#123456',
  //     options: [
  //       { option_value: '#123456', option: { name: 'Colour' } },
  //       { option_value: '#654321', option: { name: 'Colour' } },
  //       { option_value: '#ABCDEF', option: { name: 'Colour' } },
  //       { option_value: '#FEDCBA', option: { name: 'Colour' } },
  //     ],
  //     customisations: [{ option_value: 'custom', option: { name: 'Color' } }],
  //   },
  // ]

  // const formatComponents = components.map((item) => ({
  //   name: item.name,
  //   colours: item.options.filter((val) => val.option?.name === 'Colour'),
  //   fillColour:
  //     item.default_colour || item.options.find((col) => col.option?.name === 'Colour').option_value,
  // }))

  return (
    <div>
      <Card
        style={{
          padding: '10px 5px',
          border: 'solid 1px gray',
          borderShadow: '10px gray',
          margin: '20px',
        }}
      >
        <Box p="30px 60px">
          <Box style={{ fontSize: '20px', fontWeight: 600 }}>Global Configuration Options</Box>
          <Box display="flex" mt="20px">
            <Box flex={1} display="flex" flexDirection="column">
              <Box flex={1} display="flex" flexDirection="column">
                <span className={classes.sectionHeader}>Global Values</span>
                <Button
                  className={classes.listButton}
                  onClick={() => redirect('/defaultRates', 'list')}
                >
                  Default Rates
                </Button>
              </Box>
              <span className={classes.sectionHeader}>Product Related</span>
              <Button className={classes.listButton} onClick={() => redirect('/material', 'list')}>
                Materials
              </Button>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/packagingStyle', 'list')}
              >
                Packaging Styles
              </Button>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/imprintTechnique', 'list')}
              >
                Imprint Styles
              </Button>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/categories', 'list')}
              >
                Categories
              </Button>
              <Button className={classes.listButton} onClick={() => redirect('/tags', 'list')}>
                Tags
              </Button>
              <Button className={classes.listButton} onClick={() => redirect('/brands', 'list')}>
                Brands
              </Button>
              <Box width="100%">
                <Button
                  style={{ textDecoration: 'underline' }}
                  onClick={async () => {
                    const resp = await dataProvider.getList('variant', {})

                    // View all variants, and find those that have the same option values. Values may be in the option_value object or value field.
                    // When variants have the same values, and the same number of values, they can be merged, so get the ids of them and push to a 'mergeable' array.
                    // Loop through the variants, for each 'active variant':
                    // 1. Check the number of values
                    // 2. Loop the variants again to find duplicates
                    // 3. Those with the same number of values and not the same id, loop through the values and check if the active variant contains each value.
                    // 4. If all of the matches are true, then these two can be merged.
                    // 5. Push the ids to the 'mergeable' array.

                    // console.log(resp.data)
                    const mergeableVariants = []
                    resp.data?.map((variant) => {
                      const numValues = variant.values?.length

                      resp.data?.map((el) => {
                        if (
                          variant.id !== el.id &&
                          variant.product_id === el.product_id &&
                          el.values?.length === numValues
                        ) {
                          const matches = []
                          variant.values.map((val) => {
                            // console.log(val)
                            matches.push(
                              val.value
                                ? el.values?.some((vals) =>
                                    vals.value
                                      ? vals.value === val.value
                                      : vals.option_value === val.value,
                                  )
                                : el.values?.some((vals) =>
                                    vals.value
                                      ? vals.value === val.option_value?.value
                                      : vals.option_value?.value === val.option_value?.value,
                                  ),
                            )
                          })
                          // console.log(
                          //   matches,
                          //   variant.values
                          //     ?.map((item) => item.value || item.option_value?.value)
                          //     .join(),
                          //   el.values?.map((item) => item.value || item.option_value?.value).join(),
                          // )
                          if (!matches.some((match) => match === false)) {
                            // console.log('FOUND', variant, el)

                            if (
                              !mergeableVariants.some(
                                (mv) => mv.newId === el.id || mv.oldId === el.id,
                              )
                            ) {
                              mergeableVariants.push({
                                newId: variant.id,
                                newVals: variant.values
                                  ?.map((item) => item.value || item.option_value?.value)
                                  .join(),
                                oldId: el.id,
                                oldVals: el.values
                                  ?.map((item) => item.value || item.option_value?.value)
                                  .join(),
                              })
                            }
                          }
                        }

                        // console.log(foundVariant)
                      })
                    })
                    console.log('Mergeable variants:')
                    console.log(mergeableVariants)
                  }}
                >
                  Validate variants
                </Button>
              </Box>
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
              <span className={classes.sectionHeader}>Supplier Related</span>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/certificationType', 'list')}
              >
                Certification Types
              </Button>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/certificateType', 'list')}
              >
                Certificate Types
              </Button>
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
              <span className={classes.sectionHeader}>Order Related</span>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/paymentType', 'list')}
              >
                Payment Types
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
      <Card
        style={{
          padding: '10px 5px',
          border: 'solid 1px gray',
          borderShadow: '10px gray',
          margin: '20px',
        }}
      >
        <Box p="30px 60px">
          <Box style={{ fontSize: '20px', fontWeight: 600 }}>Platform Configuration Options</Box>
          <Box display="flex" mt="20px">
            <Box flex={1} display="flex" flexDirection="column">
              <span className={classes.sectionHeader}>Product Related</span>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/platformConfig', 'list')}
              >
                Platform Configuration
              </Button>
              <Button
                className={classes.listButton}
                onClick={() => redirect('/carouselContent', 'list')}
              >
                Carousel Content
              </Button>
              {/* <Box width="100%">
                <Button
                  style={{ textDecoration: 'underline' }}
                  onClick={async () => {
                    const ends_at = '2023-02-19T00:00:00+00:00'
                    const t = new Date(Date.now())
                    console.log(typeof t)
                    console.log(t)

                    const endDate = new Date(ends_at)
                    console.log(typeof endDate)
                    console.log(endDate)

                    const startedAt = endDate < t ? t.toISOString() : ends_at
                    console.log(startedAt)
                  }}
                >
                  Test Date
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Box>
        {/* <FormWithRedirect
          // {...props}
          initialValues={{
            components: formatComponents,
          }}
          render={(formProps) => <TestForm formProps={formProps} />}
        /> */}
      </Card>
    </div>
  )
}

// const TestForm = () => {
//   const { values } = useFormState()

//   // console.log(values)

//   return (
//     <Box display="flex" flexDirection="row">
//       <Box style={{ position: 'relative', width: '500px', height: '500px' }}>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 999.7 1016.53"
//           style={{
//             zIndex: 10,
//             width: '100%',
//             height: '100%',
//             position: 'relative',
//             mixBlendMode: 'multiply',
//           }}
//         >
//           <title>test-2-part</title>
//           <g onClick={() => console.log('0')}>
//             <path
//               fill={values.components[0].fillColour}
//               d="M296.13,608.44s81.06-50.68,347.23,60c0,0,65.11,19.15,71.24,17.62,0,0,1.78,7.66-4.6,10.72s-54,38.68-136,50.94c0,0-68.17,11.11-130.21-16.47L329.7,672.66s-68.17-43.66-147.44-18c0,0-68.17,32.55-52.09,61.66,0,0,5,5.74,21.45,9.19,0,0-40.22,2.29-28-36,0,0,22.21-39.19,91.91-56.81C215.57,632.7,294.34,608.19,296.13,608.44Z"
//             />
//           </g>
//           <g onClick={() => console.log('1')}>
//             <path
//               fill={values.components[1].fillColour}
//               d="M299.64,606s18.45-74.68,31.21-113c0,0,28.41-90.62,106.34-136.09a25.75,25.75,0,0,1,10.44-3.36c18.33-1.82,79.22-7.37,126.8-6.08,0,0,2,6.65,43.31,5.06a3,3,0,0,0,2.53-1.62,2.29,2.29,0,0,0-.65-3.19s46.21,3.83,69.7,24.26c0,0,44.68,35.49,21.19,147.83l-41.3,135.57L664.3,673.8a89.06,89.06,0,0,1-23.62-6.7s-91.91-34.66-113-39.64C527.7,627.46,394,577.68,299.64,606Z"
//             />
//           </g>
//           <g onClick={() => console.log('2')}>
//             <path
//               fill={values.components[2].fillColour}
//               d="M690.85,373.07s115.66,40.31,156.64,144.1c0,0,34.85,80.42,10.72,196.85,0,0-13,2.3-19.15.76,0,0-44.8-18-73.53-17.61,0,0-94.59-17.62-102.25-23.37l35.23-118.34s19.15-50.93,17.62-97.27S702.15,388.1,690.85,373.07Z"
//             />
//           </g>
//           <g onClick={() => console.log('3')}>
//             <path
//               fill={values.components[3].fillColour}
//               d="M574.58,346.72s.57-5.75,8.33-6.61,27.83-4.91,35.53,6.62c.12.17.54.33.71.44.9.56,3.44,3.66-.91,5.39C612.91,354.67,569.54,352.05,574.58,346.72Z"
//             />
//           </g>
//         </svg>
//         <img
//           src={imageMask}
//           style={{
//             position: 'absolute',
//             height: '100%',
//             left: '0px',
//             top: '0px',
//             width: '100%',
//             zIndex: 3,
//           }}
//         />
//       </Box>
//       <Box display="flex" flexDirection="column" justifyContent="center">
//         {values.components.map((item, index) => (
//           <Box key={item.id}>
//             <span>{item.name}</span>
//             <ColorInput
//               source={`components[${index}].fillColour`}
//               // className={`${classes.hideLabel} ${classes.colourPickerItem}`}
//               picker="Sketch"
//               label="Color"
//               // validate={required()}
//             />
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   )
// }

export default ConfigurationPanel
