import React from 'react'
import { DateInput, TextInput, DateTimeInput } from 'react-admin'
import { Box } from '@material-ui/core'
import SectionTitle from '../../../../components/SectionTitle'

const BasicBox = () => (
  <>
    <SectionTitle label="Status" />
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
        <DateTimeInput disabled source="created_at" fullWidth />
        <TextInput disabled source="created_by" />
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '1em' }}>
        <DateInput disabled source="approvedDate" fullWidth />
        <TextInput disabled source="approvedBy" fullWidth />
        {/* <NumberInput source="audit_score" validate={required()} fullWidth /> */}
      </Box>
    </Box>
    <TextInput source="company_name" fullWidth style={{ display: 'none' }} defaultValue="" />
    <TextInput source="company_website" fullWidth style={{ display: 'none' }} defaultValue="" />
  </>
)

export default BasicBox
