import React from 'react'
import { List, Datagrid, DateField, FunctionField, useRedirect } from 'react-admin'
import { Box, Button } from '@material-ui/core'

const NotificationList = (props) => {
  const { appUserCode, ...rest } = props
  const redirect = useRedirect()

  if (!appUserCode) return null

  return (
    <List
      {...rest}
      resource="notification"
      // filters={<OrganisationsFilter />}
      filter={{ userCode: appUserCode }}
      perPage={25}
      actions={false}
      empty={false}
      bulkActionButtons={false}
    >
      <Datagrid optimized path="clients">
        <DateField source="created_at" label="Created at" type="date" sortable={false} />
        <FunctionField
          label="Feed Group Action"
          render={(record) => {
            // console.log('FGACTIONREC', record)
            switch (record.verb) {
              case 'added product to sourcing request':
                return (
                  <Box display="flex">
                    <span>
                      {`${record.activity_count} ${
                        record.activity_count > 1
                          ? 'products were added to sourcing requests'
                          : 'product was added to sourcing requests'
                      }`}
                    </span>
                  </Box>
                )
              case 'submitted to client':
                return (
                  <Box display="flex">
                    <span>
                      {`${record.activity_count} ${
                        record.activity_count > 1
                          ? 'sourcing requests were submitted'
                          : 'sourcing request was submitted'
                      }`}
                    </span>
                  </Box>
                )
              default:
                return <span>{record.verb}</span>
            }
          }}
        />

        <FunctionField
          label="Feed Group Activities"
          render={(record) => {
            switch (record.verb) {
              case 'added product to sourcing request':
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    //   style={{ maxHeight: '50px', overflowY: 'auto' }}
                  >
                    {record.activities.map((item) => {
                      // console.log('ADDACTITEM', item)
                      return (
                        <Box
                          display="flex"
                          key={`actkey${item.id}`}
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <span>Sourcing Request {item.foreign_id}:</span>
                            <img
                              style={{ maxHeight: '45px', maxWidth: '45px' }}
                              src={item.meta?.new_product.product_photo}
                              alt=""
                            />
                          </Box>
                          <Button onClick={() => redirect(`/sourcingRequests/${item.foreign_id}`)}>
                            Go see
                          </Button>
                        </Box>
                      )
                    })}
                  </Box>
                )
              case 'submitted to client':
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    //   style={{ maxHeight: '50px', overflowY: 'auto' }}
                  >
                    {record.activities.map((item) => {
                      // console.log('SUBACTITEM', item)
                      return (
                        <Box
                          display="flex"
                          key={`actkey${item.id}`}
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <span>Sourcing Request {item.foreign_id}:</span>
                            {item.meta.products?.map((product) => (
                              <img
                                key={`prod${product.product_name}`}
                                style={{
                                  marginLeft: '10px',
                                  maxHeight: '45px',
                                  maxWidth: '45px',
                                }}
                                src={product?.product_photo}
                                alt=""
                              />
                            ))}
                          </Box>
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={() => redirect(`/sourcingRequests/${item.foreign_id}`)}
                          >
                            Go see
                          </Button>
                        </Box>
                      )
                    })}
                  </Box>
                )
              default:
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    //   style={{ maxHeight: '50px', overflowY: 'auto' }}
                  >
                    {record.activities.map((item) => {
                      // console.log('SUBACTITEM', item)
                      return (
                        <Box
                          display="flex"
                          key={`actkey${item.id}`}
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <span>
                              {item.object}-{item.foreign_id}
                            </span>
                            {item.meta.products?.map((product) => (
                              <img
                                key={`prod${product.product_name}`}
                                style={{
                                  marginLeft: '10px',
                                  maxHeight: '45px',
                                  maxWidth: '45px',
                                }}
                                src={product?.product_photo}
                                alt=""
                              />
                            ))}
                          </Box>
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={() =>
                              item.object === 'sourcing_request'
                                ? redirect(`/sourcingRequests/${item.foreign_id}`)
                                : redirect(`/order/${item.foreign_id}`)
                            }
                          >
                            Go see
                          </Button>
                        </Box>
                      )
                    })}
                  </Box>
                )
            }
          }}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

export default NotificationList
