import gql from 'graphql-tag'

export const scmQuoteUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation scmQuoteUpdateMany {
        update_scm_quote_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_scm_quote_many,
    }),
  }
}

export const scmQuoteDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation deleteSCMQuote {
            update_scm_quote(where: {id: {_eq: ${params.id}}}, _set: {default_packaging_option_id: null}) {
              affected_rows
            }
            delete_scm_quote_component_options(where: {component: {scm_quote_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_scm_quote_options(where: {scm_quote_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_scm_quote_option_values(where: {option: {scm_quote_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_scm_quote_product_size_packagings(where: {scm_quote_packaging_option: {scm_quote_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_scm_quote_packaging_options(where: {scm_quote_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_scm_quote_product_sizes(where: {scm_quote_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_scm_quote_component_colours(where: {component: {scm_quote_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_scm_quote_components(where: {scm_quote_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_scm_quote_payment_terms(where: {scm_quote_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_scm_quote_prices(where: {scm_quote_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_scm_quote_by_pk(id: ${params.id}) {
              id
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const scmQuoteGet = ({ context, params }) => {
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            query getSCMQuote {
              scm_quote_by_pk(id: ${params.id}) {
                id
                created_at
                updated_at
                client_id
                client_product_name
                launch_date
                valid_date
                notes
                client_notes
                warranty_conditions
                warranty_term
                sourcing_request_id
                product_quote_id
                client_tooling_cost
                client_sample_lead_time
                client_sample_price
                client_custom_sample_price
                status
                container_rate_20ft
                container_rate_40ft
                container_rate_40ft_hc
                freight_rate_sea
                freight_rate_air
                container_capacity_20ft
                container_capacity_40ft
                container_capacity_40ft_hc
                duty_rate
                does_sea_freight_include_duties
                custom_colour_option_moq
                custom_colour_option_price_modifier
                standard_colour_option_price_modifier
                standard_colour_option_moq
                image {
                  id
                  filename
                  url
                }
                default_packaging_option_id
                default_packaging_option {
                  id
                  scm_quote_packaging_option {
                    id
                    moq
                    price_modifier
                    pieces_per_carton
                    sea_lcl
                    air_lcl
                    sea_modifier
                    air_modifier
                    sea_buffer
                    air_buffer
                    type
                    product_quote_packaging_option_id
                    packaging_dimensions {
                      length
                      width
                      height
                      gross_weight
                    }
                    carton_dimensions {
                      length
                      width
                      height
                      gross_weight
                    }
                  }
                  scm_quote_product_size {
                    id
                    moq
                    price_modifier
                    product_quote_product_size_id
                    dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                  }
                }
                decorations {
                  id
                  label
                  price
                  max_dimensions
                  imprint_styles
                  imprint_style
                  product_quote_decoration_id
                  product_quote_decoration {
                    id
                    label
                    cost
                    max_dimensions
                    imprint_styles
                    imprint_style
                    preview_image {
                      id
                      filename
                      url
                    }
                  }
                  preview_image {
                    id
                    filename
                    url
                  }
                }
                options {
                  id
                  max_unique_selections
                  additional_cost_per_unique_choice
                  name
                  product_quote_option_id
                  product_quote_option {
                    id
                    name
                    max_unique_selections
                    additional_cost_per_unique_choice
                  }
                  values {
                    id
                    value
                    is_custom
                    moq
                    setup
                    price
                    component_options {
                      id
                      component {
                        id
                        name
                        product_quote_component_id
                      }
                    }
                    product_quote_option_value_id
                    product_quote_option_value {
                      value
                      is_custom
                      moq
                      setup
                      cost
                    }
                  }
                }
                scm_quote_packaging_options {
                  id
                  product_quote_packaging_option_id
                  product_quote_packaging_option {
                    id
                    moq
                    cost_modifier
                  }
                  price_modifier
                  sea_lcl
                  air_lcl
                  sea_modifier
                  air_modifier
                  sea_buffer
                  air_buffer
                  moq
                  label
                  pieces_per_carton
                  type
                  carton_dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                  packaging_dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                  scm_quote_product_size_packagings {
                    id
                    scm_quote_product_size_id
                    scm_quote_product_size {
                      id
                      moq
                      name
                      short_name
                      price_modifier
                      dimensions {
                        id
                        length
                        width
                        height
                        gross_weight
                      }
                      product_quote_product_size_id
                      product_quote_product_size {
                        id
                        moq
                        cost_modifier
                      }
                    }
                  }
                }
                product_components {
                  id
                  name
                  svg_shape_index
                  svg_shape_id
                  default_colour
                  allow_custom_colour
                  product_quote_component_id
                  colours {
                    id
                    value
                  }
                }
                client {
                  id
                  name
                  legal_name
                  default_delivery_address_id
                  default_billing_address_id
                }
                payment_terms(where: { deleted_at: { _is_null: true } }) {
                  id
                  milestone
                  value
                }
                prices(order_by: { quantity: asc_nulls_last }) {
                  id
                  price
                  quantity
                  production_lead_time
                  estimated_freight_air
                  estimated_freight_sea
                  fob_price
                  lead_time_air
                  lead_time_sea
                  total_cbm
                  shipment_weight
                  total_cartons
                  total_duties
                  containers
                  freight_cost_air
                  freight_cost_sea
                  freight_cost_air_per_piece
                  freight_cost_sea_per_piece
                  adjustment_sea
                  adjustment_air
                  margin_fob
                  internal_fob
                  internal_air
                  internal_sea
                  total_lead_time_sea
                  total_lead_time_air
                }
                product_quote_id
                product_quote {
                  id
                  supplier_id
                  pieces_per_carton
                  allow_order_splitting_by_size
                  allow_order_splitting_by_colour
                  freight_rate_sea
                  freight_rate_air
                  container_rate_20ft
                  container_rate_40ft
                  container_rate_40ft_hc
                  container_capacity_20ft
                  container_capacity_40ft
                  container_capacity_40ft_hc
                  included_packaging_option_id
                  does_sea_freight_include_duties
                  standard_colour_cost_modifier
                  standard_colour_moq
                  custom_colour_moq
                  custom_colour_price_modifier
                  svg_file
                  svg_mask {
                    id
                    filename
                    url
                  }
                  packaging_options {
                    id
                    cost_modifier
                    moq
                    label
                    pieces_per_carton
                    type
                    carton_dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                    packaging_dimensions {
                      id
                      length
                      width
                      height
                      gross_weight
                    }
                    product_quote_product_size_packagings {
                      id
                      product_quote_product_size_id
                      product_size {
                        id
                        moq
                        name
                        short_name
                        cost_modifier
                        dimensions {
                          id
                          length
                          width
                          height
                          gross_weight
                        }
                      }
                    }
                  }
                  components {
                    id
                    name
                    svg_shape_index
                    svg_shape_id
                    default_colour
                    colours {
                      id
                      value
                    }
                  }
                  decorations {
                    id
                    label
                    cost
                    max_dimensions
                    imprint_styles
                    imprint_style
                    preview_image {
                      id
                      filename
                      url
                    }
                  }
                  options {
                    id
                    name
                    max_unique_selections
                    additional_cost_per_unique_choice
                    values {
                      id
                      value
                      setup
                      is_custom
                      cost
                      moq
                      components {
                        id
                        component_id
                        component {
                          id
                          name
                        }                    
                      }
                    }
                  }
                  prices(order_by: { quantity: asc_nulls_last }) {
                    id
                    quantity
                    unit_price
                    lead_time
                    est_landed_cost_air
                    est_landed_cost_sea
                    total_cbm
                    total_cartons
                    total_duties
                    shipment_weight
                    freight_cost_air
                    freight_cost_sea
                    freight_cost_air_per_piece
                    freight_cost_sea_per_piece
                    containers
                  }
                  product {
                    id
                    name
                    catalogue_sku
                    brand
                    chargeable_weight_sea
                    chargeable_weight_air
                    duty_rate
                    short_description
                    hts_code
                    product_categories (where: {deleted_at: {_is_null: true }}) {
                      id
                      deleted_at
                      category {
                        id
                        name
                      }
                    }
                    product_tags {
                      id
                      tag {
                        id
                        name
                      }
                    }
                    images: product_files (where: { type: { _eq: ADDITIONAL_PHOTO}}) {
                      id
                      type
                      deleted_at
                      file {
                        id
                        filename
                        url
                      }
                    }
                    primary_photo {
                      id
                      url
                      filename
                    }
                    thumbnail_photo {
                      id
                      url
                      filename
                    }
                    product_dimensions {
                      id
                      gross_weight
                      length
                      width
                      height
                    }
                    carton_dimensions {
                      id
                      gross_weight
                      length
                      width
                      height
                    }
                    product_options {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                    product_specifications {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                    customisations {
                      id
                      deleted_at
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                    }
                    packaging_options {
                      id
                      label
                      type
                      carton_dimensions {
                        id
                        length
                        width
                        height
                        gross_weight
                      }
                      packaging_dimensions {
                        id
                        length
                        width
                        height
                        gross_weight
                      }
                    }
                    product_sizes {
                      id
                      name
                      short_name
                      dimensions {
                        id
                        length
                        width
                        height
                        gross_weight
                      }
                    }
                  }
                }
              }
            }
          `,
    parseResponse: ({ data, errors }) => ({
      data: data.scm_quote_by_pk,
      errors,
      // validUntil,
    }),
  }
}

export const scmQuoteList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter.clientId && {
        client_id: { _eq: params.filter.clientId },
      }),
      ...(params.filter.is_catalogue && {
        product_quote: {
          product: { is_catalogue: { _eq: params.filter.is_catalogue } },
        },
      }),
      ...(params.filter.date_gte &&
        !params.filter.date_lte && { launch_date: { _gte: params.filter.date_gte } }),
      ...(params.filter.date_lte &&
        !params.filter.date_gte && { launch_date: { _lte: params.filter.date_lte } }),
      ...(params.filter.date_lte &&
        params.filter.date_gte && {
          _and: [
            { launch_date: { _gte: params.filter.gte } },
            { launch_date: { _lte: params.filter.lte } },
          ],
        }),
      ...(params.filter.category && {
        product_quote: {
          product: { product_categories: { category_id: { _in: params.filter.category } } },
        },
      }),
      ...((params.filter.name || params.filter.q) && {
        product_quote: {
          product: { name: { _ilike: `%${params.filter.name || params.filter.q}%` } },
        },
      }),
      ...(params.filter.sourcingRequestId && {
        sourcing_request_id: { _eq: params.filter.sourcingRequestId },
      }),
      ...(params.filter.sourcingRequestIsNull && {
        sourcing_request_id: { _is_null: true },
      }),
      ...(params.filter.sourcingRequestIsNotNull && {
        sourcing_request_id: { _is_null: false },
      }),
    }
  }
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
          query getSCMQuotes ($where: scm_quote_bool_exp) {
           scm_quote(where: $where, limit: ${
             params.pagination?.perPage || 25
           }, offset: ${offset}, order_by: { ${params.sort?.field || 'id'}: ${
      params.sort?.order?.toLowerCase() || 'desc_nulls_last'
    } }) {
          id
          created_at
          updated_at
          client_id
          client_product_name
          launch_date
          valid_date
          notes
          client_notes
          warranty_conditions
          warranty_term
          sourcing_request_id
          product_quote_id
          client_tooling_cost
          client_sample_lead_time
          client_sample_price
          client_custom_sample_price
          status
          container_rate_20ft
          container_rate_40ft
          container_rate_40ft_hc
          freight_rate_sea
          freight_rate_air
          container_capacity_20ft
          container_capacity_40ft
          container_capacity_40ft_hc
          duty_rate
          does_sea_freight_include_duties
          custom_colour_option_moq
          custom_colour_option_price_modifier
          standard_colour_option_price_modifier
          standard_colour_option_moq
          image {
            id
            filename
            url
          }
          default_packaging_option_id
          default_packaging_option {
            id
            scm_quote_packaging_option {
              id
              moq
              price_modifier
              pieces_per_carton
              sea_lcl
              air_lcl
              sea_modifier
              air_modifier
              sea_buffer
              air_buffer
              type
              packaging_dimensions {
                length
                width
                height
                gross_weight
              }
              carton_dimensions {
                length
                width
                height
                gross_weight
              }
            }
            scm_quote_product_size {
              id
              moq
              price_modifier
              dimensions {
                id
                length
                width
                height
                gross_weight
              }
            }
          }
          decorations {
            id
            label
            price
            max_dimensions
            imprint_styles
            imprint_style
            product_quote_decoration_id
            product_quote_decoration {
              id
              label
              cost
              max_dimensions
              imprint_styles
              imprint_style
              preview_image {
                id
                filename
                url
              }
            }
            preview_image {
              id
              filename
              url
            }
          }
          options {
            id
            max_unique_selections
            additional_cost_per_unique_choice
            name
            product_quote_option_id
            product_quote_option {
              id
              name
              max_unique_selections
              additional_cost_per_unique_choice
            }
            values {
              id
              value
              is_custom
              moq
              setup
              price
              component_options {
                id
                component {
                  id
                  name
                  product_quote_component_id
                }
              }
              product_quote_option_value_id
              product_quote_option_value {
                value
                is_custom
                moq
                setup
                cost
              }
            }
          }
          scm_quote_packaging_options {
            id
            product_quote_packaging_option_id
            product_quote_packaging_option {
              id
              moq
              cost_modifier
            }
            price_modifier
            sea_lcl
            air_lcl
            sea_modifier
            air_modifier
            sea_buffer
            air_buffer
            moq
            label
            pieces_per_carton
            type
            carton_dimensions {
              id
              length
              width
              height
              gross_weight
            }
            packaging_dimensions {
              id
              length
              width
              height
              gross_weight
            }
            scm_quote_product_size_packagings {
              id
              scm_quote_product_size_id
              scm_quote_product_size {
                id
                moq
                name
                short_name
                price_modifier
                dimensions {
                  id
                  length
                  width
                  height
                  gross_weight
                }
                product_quote_product_size_id
                product_quote_product_size {
                  id
                  moq
                  cost_modifier
                }
              }
            }
          }
          product_components {
            id
            name
            svg_shape_index
            svg_shape_id
            default_colour
            allow_custom_colour
            product_quote_component_id
            colours {
              id
              value
            }
          }
          client {
            id
            name
            legal_name
            default_delivery_address_id
            default_billing_address_id
          }
          payment_terms(where: { deleted_at: { _is_null: true } }) {
            id
            milestone
            value
          }
          prices(order_by: { quantity: asc_nulls_last }) {
            id
            price
            quantity
            production_lead_time
            estimated_freight_air
            estimated_freight_sea
            fob_price
            lead_time_air
            lead_time_sea
            total_cbm
            shipment_weight
            total_cartons
            total_duties
            containers
            freight_cost_air
            freight_cost_sea
            freight_cost_air_per_piece
            freight_cost_sea_per_piece
            adjustment_sea
            adjustment_air
            margin_fob
            internal_fob
            internal_air
            internal_sea
            total_lead_time_sea
            total_lead_time_air
          }
          product_quote_id
          product_quote {
            id
            supplier_id
            packaging_options {
              id
              cost_modifier
              moq
              label
              pieces_per_carton
              type
              carton_dimensions {
                id
                length
                width
                height
                gross_weight
              }
              packaging_dimensions {
                id
                length
                width
                height
                gross_weight
              }
              product_quote_product_size_packagings {
                id
                product_quote_product_size_id
                product_size {
                  id
                  moq
                  name
                  short_name
                  cost_modifier
                  dimensions {
                    id
                    length
                    width
                    height
                    gross_weight
                  }
                }
              }
            }
            components {
              id
              name
              svg_shape_index
              svg_shape_id
              default_colour
              colours {
                id
                value
              }
            }
            decorations {
              id
              label
              cost
              max_dimensions
              imprint_styles
              imprint_style
              preview_image {
                id
                filename
                url
              }
            }
            options {
              id
              name
              max_unique_selections
              additional_cost_per_unique_choice
              values {
                id
                value
                setup
                is_custom
                cost
                moq
                components {
                  id
                  component_id
                  component {
                    id
                    name
                  }                    
                }
              }
            }
            prices(order_by: { quantity: asc_nulls_last }) {
              id
              quantity
              unit_price
              lead_time
              est_landed_cost_air
              est_landed_cost_sea
              total_cbm
              total_cartons
              total_duties
              shipment_weight
              freight_cost_air
              freight_cost_sea
              freight_cost_air_per_piece
              freight_cost_sea_per_piece
              containers
            }
            product {
              id
              name
              catalogue_sku
              brand
              chargeable_weight_sea
              chargeable_weight_air
              duty_rate
              short_description
              hts_code
              product_categories (where: {deleted_at: {_is_null: true }}) {
                id
                deleted_at
                category {
                  id
                  name
                }
              }
              product_tags {
                id
                tag {
                  id
                  name
                }
              }
              images: product_files (where: { type: { _eq: ADDITIONAL_PHOTO}}) {
                id
                type
                deleted_at
                file {
                  id
                  filename
                  url
                }
              }
              primary_photo {
                id
                url
                filename
              }
              thumbnail_photo {
                id
                url
                filename
              }
              product_dimensions {
                id
                gross_weight
                length
                width
                height
              }
              carton_dimensions {
                id
                gross_weight
                length
                width
                height
              }
              product_options {
                id
                deleted_at
                option_value {
                  id
                  value
                  option {
                    id
                    name
                  }
                }
              }
              product_specifications {
                id
                deleted_at
                option_value {
                  id
                  value
                  option {
                    id
                    name
                  }
                }
              }
              customisations {
                id
                deleted_at
                option_value {
                  id
                  value
                  option {
                    id
                    name
                  }
                }
              }
              packaging_options {
                id
                label
                type
                carton_dimensions {
                  id
                  length
                  width
                  height
                  gross_weight
                }
                packaging_dimensions {
                  id
                  length
                  width
                  height
                  gross_weight
                }
              }
              product_sizes {
                id
                name
                short_name
                dimensions {
                  id
                  length
                  width
                  height
                  gross_weight
                }
              }
            }
          }
        }
        scm_quote_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.scm_quote,
      total: data.scm_quote_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const scmQuoteApprove = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation MyMutation {
            update_scm_quote(where: {sourcing_request_id: {_eq: ${params.where.sourcing_request_id}}, status: {_eq: APPROVED}}, _set: {status: ACTIVE}) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data: data.supply_chain_management_company,
      errors,
    }),
  }
}

export const scmQuoteAvailablePackagingOptionsList = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query scmQuoteAvailablePackagingOptionsList {
        scm_quote {
          id
          scm_quote_packaging_options {
            id
            scm_quote_product_size_packagings {
              id
              scm_quote_packaging_option_id
              scm_quote_product_size_id
            }
          }
          product_quote {
            id
            product {
              name
            }
            packaging_options {
              id
              type
              label
              product_quote_product_size_packagings {
                id
                product_quote_packaging_option_id
                product_quote_product_size_id
                product_quote_product_size {
                  id
                  name
                  short_name
                }
              }
            }
            product_sizes {
              id
              sequence
              name
              short_name
            }
          }
        }
        scm_quote_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.scm_quote,
      total: data.scm_quote_aggregate.aggregate.count,
    }),
  }
}

export const scmQuoteTotals = ({ context, params }) => {
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  const variables = {}

  if (params.filter) {
    variables.clientId = params.filter.clientId
  }
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  // return {
  //   ...context,
  //   variables,
  //   query: gql`
  //         query getSCMQuotes ($where: scm_quote_bool_exp) {
  //          scm_quote(where: $where, limit: ${
  //            params.pagination?.perPage || 25
  //          }, offset: ${offset}, order_by: { ${params.sort?.field || 'id'}: ${
  //     params.sort?.order?.toLowerCase() || 'desc_nulls_last'
  //   } })
  //         }
  //         `}

  return {
    ...context,
    variables,
    query: gql`
      query scmQuoteTotals($clientId: Int!) {
        requestsTotal: scm_quote_aggregate(
          where: { client_id: { _eq: $clientId }, sourcing_request_id: { _is_null: false } }
        ) {
          aggregate {
            count
          }
        }
        suggestedTotal: scm_quote_aggregate(
          where: { client_id: { _eq: $clientId }, sourcing_request_id: { _is_null: true } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: {
        requestsTotal: data.requestsTotal.aggregate.count,
        suggestedTotal: data.suggestedTotal.aggregate.count,
        developmentTotal: 0,
        total: data.requestsTotal.aggregate.count + data.suggestedTotal.aggregate.count,
      },
      errors,
      // validUntil,
    }),
  }
}
