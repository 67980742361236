import React from 'react'
import { Box } from '@material-ui/core'
import POTrackingTable from './POTrackingTable'
// import POFactoryComparison from './POFactoryComparison'
// import useStyles from '../styles/poform'
import { ApolloProvider } from '@apollo/client'
import NewClient from '../../../../dataProvider/hasura/buildApolloClient'

const POManagement = (props) => {
  const { id } = props

  const localToken = localStorage.getItem('token')

  // console.log(localToken)
  const token = typeof localToken === 'string' ? localToken : localToken?.access_token

  // if (!localToken || localToken === 'undefined') {
  //   return null
  //   // throw new Error('Unauthenticated access')
  // }

  // console.log('POMPROPS', props)
  const client = NewClient(token)

  return (
    <Box>
      <ApolloProvider client={client}>
        <POTrackingTable id={id} />
      </ApolloProvider>

      {/* <Box display='flex'>
        <Box flex={1} style={{ height: '233px' }} p='10px 10px 10px 60px'>
          <POFactoryComparison />
        </Box>
        <Box flex={1} />
      </Box> */}
    </Box>
  )
}

export default POManagement
