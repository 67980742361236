import React from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import OfferTab from './offerDetailsTab'
import CatalogueDetailsTab from './catalogueDetailsTab'

const Simulator = (props) => {
  const { type, id } = props.match.params
  const [value, setValue] = React.useState(type === 'offer' ? 1 : 0)

  console.log(props)
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    )
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Catalogue" {...a11yProps(0)} />
        <Tab label="Offer" {...a11yProps(1)} />
        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <CatalogueDetailsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <OfferTab id={id} />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
  )
}

export default Simulator
