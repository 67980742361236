import React, { useState } from 'react'
import {
  Box,
  // Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Dialog,
  // DialogContent,
  // DialogTitle,
  Paper,
  // Checkbox,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import TextInput from '../../../components/OutlinedTextInput'
import { useNotify, useDataProvider } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import IconButton from '@material-ui/core/IconButton'
// import { roundTo } from '../../../utils/utils'
// import { saveFactoryQuote } from '../common/utils'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
// import InfoIcon from '@material-ui/icons/Info'
import NumberFormat from '../../components/CustomNumberFormat'
import useStyles from '../styles'

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '0',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const Configurator = () => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()

  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [editing, setEditing] = useState()
  const [saving, setSaving] = useState()

  // console.log('CONFIGURATORVALS', values)

  return (
    <div
      style={{
        // padding: '30px',
        borderRadius: '4px',
        marginTop: '20px',
        backgroundColor: editing ? '#eab2b9' : '#FFFFFF',
      }}
    >
      <Box display="flex" alignItems="center">
        <span className={classes.sectionHeader}>Selected Options</span>
        {editing ? (
          <div>
            {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
            <IconButton
              onClick={async () => {
                // console.log(values.variants)

                setSaving(true)

                values.variants.map(async (variant) => {
                  variant.colour?.id
                    ? await dataProvider.update('variant_values', {
                        data: { id: variant.colour.id, value: variant.colour.value },
                      })
                    : null
                  variant.packaging?.id
                    ? await dataProvider.update('variant_values', {
                        data: { id: variant.packaging.id, value: variant.packaging.value },
                      })
                    : null
                  variant.size?.id
                    ? await dataProvider.update('variant_values', {
                        data: { id: variant.size.id, value: variant.size.value },
                      })
                    : null
                  const offerPurchaseItemParams = {
                    id: variant.itemId,
                    quantity: variant.quantity,
                    amount: Math.round(variant.amount * 100),
                    unit_price: Math.round(variant.unitPrice * 100),
                    shipping_method: variant.shippingMethod,
                    incoterms: variant.incoterms,
                  }
                  await dataProvider.update('offer_purchase_items', {
                    data: offerPurchaseItemParams,
                  })

                  notify('Updated', { type: 'info' })
                  setSaving(false)
                  setEditing(false)
                })

                //   await saveFactoryQuote({
                //     setSaving,
                //     values,
                //     dataProvider,
                //     notify,
                //     setEditing,
                //     form,
                //   })
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>

            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </div>
        ) : (
          <div>
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </Box>

      <Box mt="20px">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Id
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Packaging Type
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Product Size
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Colour
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Shipping Method
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Incoterms
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Quantity
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Unit Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '13%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.variants?.map((item, index) => (
                <VariantRow key={`price${item.id}`} index={index} id={item.id} editing={editing} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {editing && values.factoryPrices?.length < 3 ? (
          <IconButton
            aria-label="add-price"
            // className={classes.infoBtn}
            onClick={async () => {
              const newFactoryPrices = [...values.factoryPrices]
              // prices.push({ id: uniqueId() })
              // console.log(values.factoryPrices, prices)
              const resp = await dataProvider.create('product_quote_price', {
                data: { product_quote_id: values.product_quotes[0].id },
              })

              newFactoryPrices.push({
                id: resp.data.id,
              })

              form.change('factoryPrices', newFactoryPrices)
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </div>
  )
}

const VariantRow = ({ index, id, editing = false }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const { values } = useFormState()
  const form = useForm()

  // console.log(values.variants[index])

  // console.log(index)

  // const [seaInfoOpen, setSeaInfoOpen] = useState(false)
  //   const [infoOpen, setInfoOpen] = useState(false)
  return (
    <TableRow key={`priceRow${index}`}>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        {index}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          disabled={!editing}
          inputProps={{
            maxLength: 100,
          }}
          style={{ padding: 0, margin: 0, height: '100%' }}
          source={`variants[${index}].packaging.value`}
          label={false}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          disabled={!editing}
          style={{ padding: 0, margin: 0, height: '100%' }}
          inputProps={{
            maxLength: 100,
          }}
          source={`variants[${index}].size.value`}
          label={false}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          disabled={!editing}
          style={{ padding: 0, margin: 0, height: '100%' }}
          inputProps={{
            maxLength: 100,
          }}
          source={`variants[${index}].colour.value`}
          label={false}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <TextInput
          variant="standard"
          fullWidth
          disabled={!editing}
          style={{ padding: 0, margin: 0, height: '100%' }}
          inputProps={{
            maxLength: 100,
          }}
          source={`variants[${index}].shippingMethod`}
          label={false}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <TextInput
          variant="standard"
          fullWidth
          disabled={!editing}
          style={{ padding: 0, margin: 0, height: '100%' }}
          inputProps={{
            maxLength: 100,
          }}
          source={`variants[${index}].incoterms`}
          label={false}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!editing}
          source={`variants[${index}].quantity`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled={!editing}
          source={`variants[${index}].unitPrice`}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          disabled={!editing}
          source={`variants[${index}].amount`}
        />
      </BodyTableCell>

      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <Box display="flex">
          <IconButton
            aria-label="info"
            className={classes.infoBtn}
            disabled={!editing}
            onClick={async () => {
              const newVariants = [...values.variants]
              newVariants.splice(index, 1)
              if (typeof id === 'number' && newVariants?.length > 1) {
                const delVariants = [id]
                await dataProvider.delete('variant', { delVariants })
                notify('Deleted', { type: 'info' })
                form.change('variants', newVariants)
              } else if (newVariants.length > 2) {
                notify('There must be at least one variant', { type: 'warning' })
              }
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </BodyTableCell>
    </TableRow>
  )
}

export default Configurator
