import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import {
  Edit,
  FormWithRedirect,
  useDataProvider,
  // useNotify,
  // useGetIdentity,
  // useRedirect,
} from 'react-admin'
// import Confirm from '../components/Confirm'
// import FormData from './components/EditFormData'
import ItemsTable from './components/ItemsTable'
import ProductsTable from './components/ProductsTable'
import ClientQuotesSection from './components/ClientQuotesSection'

const SourcingRequestEdit = (props) => {
  return (
    <Edit
      {...props}
      actions={null}
      component="div"
      id={props.match.params.id}
      mutationMode="pessimistic"
    >
      <SourcingRequestEditForm id={props.match.params.id} />
    </Edit>
  )
}

const SourcingRequestEditForm = (props) => {
  const dataProvider = useDataProvider()
  // const { identity } = useGetIdentity()
  const { record } = props

  const [milestones, setMilestones] = useState([])
  const [config, setConfig] = useState()
  // console.log(values)

  useEffect(() => {
    const getDataLists = async () => {
      const debugMode = false
      debugMode && console.log('GETTING DATA LISTS')
      try {
        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setMilestones(milestonesTemp.filter((item) => item.type === 'client'))

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const config = {}
        configTemp.global_preferences.forEach(
          (item) => (config[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(config)
      } catch (error) {
        console.log(error)
        // notify(error.message.split('.')[0], { type: 'warning' })

        debugMode && console.timeEnd('SAVING DATA TIMER')
        debugMode && console.groupEnd()
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  const chosenCategories = record?.categories?.map((item) => ({
    ...item.category,
    category_id: item.category?.id,
    id: item.category?.id,
  }))

  const products = record.products?.map((el) => ({
    ...el,
    product: {
      ...el.product,
      product_quotes: el.product?.product_quotes?.map((pq) => ({
        ...pq,
        toolingCost: pq.tooling_cost / 100,
        sampleCost: pq.sample_cost / 100,
        cartonDimensions: {
          id: pq.included_packaging_option?.carton_dimensions?.id,
          length: pq.included_packaging_option?.carton_dimensions?.length / 100,
          width: pq.included_packaging_option?.carton_dimensions?.width / 100,
          height: pq.included_packaging_option?.carton_dimensions?.height / 100,
          weight: pq.included_packaging_option?.carton_dimensions?.gross_weight / 100,
          packagingOptionId: pq.included_packaging_option?.id,
        },
        prices: pq.prices?.map((pp) => ({
          id: pp.id,
          quantity: pp.quantity,
          lead_time: pp.lead_time,
          unitPrice: pp.unit_price / 100,
        })),
      })),
    },
  }))

  return (
    <FormWithRedirect
      {...props}
      // validate={(values) => {
      //   // console.log(values)
      //   const errors = {}
      //   values.products?.length < 1 ? (errors.products = 'Required at least one product') : null
      //   !values.feedback ? (errors.feedback = 'Required feedback') : null
      //   // values.status !== 'IN_PROCESS' ? (errors.status = 'Request status not valid') : null
      //   return errors
      // }}
      initialValues={{
        products,
        chosenCategories,
        sr_categories: record.categories || [],
        // products: [{}],
      }}
      render={() => (
        <Box p="0px 20px" display="flex">
          <Box p="10px" flex={1} />
          <Box p="10px" flex={8}>
            <ItemsTable id={record.id} />
            <ProductsTable milestones={milestones} config={config} />
            <ClientQuotesSection
              quotes={record.quotes}
              milestones={milestones}
              config={config}
              items={record.products}
            />
          </Box>
          <Box p="10px" flex={1} />

          {/* <FormData
            title="Edit Product"
            sourcing_request_id={record.id}
            isManager={identity?.isManager}
            formProps={formProps}
          /> */}
          {/* <Box display="flex" justifyContent="center" alignItems="center" mt="0px">
            {!record.payment_confirmed && (
              <Confirm
                redirect="/sourcingRequests"
                record={formProps.record}
                basePath={formProps.basePath}
                label="CONFIRM PAYMENT"
                undoable
                invalid={false}
                ignoreInvalid
                handleSubmit={async () => {
                  await dataProvider.update('sourcing_request', {
                    data: { id: record.id, payment_confirmed: true },
                  })
                  // await handleSave({ type: 'PAYMENT', submission })
                }}
                saving={formProps.saving}
                disabled={formProps.saving}
                resource="sourcing_request"
              />
            )}
            <Confirm
              redirect="/sourcingRequests"
              // redirect={null}
              record={formProps.record}
              basePath={formProps.basePath}
              label="SUBMIT TO CLIENT"
              undoable
              invalid={formProps.invalid}
              handleSubmit={async () => {
                await dataProvider.update('sourcing_request', {
                  data: { id: record.id, status: 'FEEDBACK' },
                })
                // await handleSave({ type: 'SUBMIT', submission })
              }}
              saving={formProps.saving}
              disabled={formProps.saving || !identity?.isManager}
              resource="offer"
            />
          </Box> */}
        </Box>
      )}
    />
  )
}

export default SourcingRequestEdit
