import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { useFormState } from 'react-final-form'
import useStyles from '../styles'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '8px',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
  },
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    fontSize: 12,
  },
}))(TableCell)
const StyledTableCellBold = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    // color: '#000000',
    fontSize: 12,
    // fontWeight: 600,
  },
}))(TableCell)
const StyledTableSubtitleCell = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    color: '#000000',
    margin: '0px',
    borderTop: '2px solid #000000',
    fontSize: 12,
    padding: '8px',
    fontWeight: 600,
  },
}))(TableCell)
const StyledTableTitleCell = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    margin: '0px',
    color: '#000000',
    borderTop: '2px solid #000000',
    borderBottom: '3px solid #000000',
    padding: '8px',
    fontSize: 12,
    fontWeight: 600,
  },
}))(TableCell)

const ExpensesTables = (props) => {
  const classes = useStyles()
  // console.log('EXPTABLEPROPS', props)
  const { values } = useFormState()

  const {
    quantity,
    factoryUnit,
    factoryTotal,
    qualityUnit,
    qualityTotal,
    logisticsUnit,
    logisticsTotal,
    dutiesUnit,
    dutiesTotal,
    supplyChainOpsTotal,
    supplyChainOpsUnit,
    supplyChainUnit,
    supplyChainTotal,
    calculatedExpensesFields,
  } = props

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                width="35%"
                style={{
                  borderTop: 'black solid 2px',
                  borderBottom: 'black solid 2px',
                  borderRight: 'none',
                }}
              />
              <StyledTableCell
                width="15%"
                style={{
                  borderTop: 'black solid 2px',
                  borderBottom: 'black solid 2px',
                  borderLeft: 'none',
                }}
              />
              <StyledTableCell
                width="25%"
                align="center"
                style={{ borderTop: 'black solid 2px', borderBottom: 'black solid 2px' }}
              >
                Per Piece
              </StyledTableCell>
              <StyledTableCell
                width="25%"
                align="right"
                style={{ borderTop: 'black solid 2px', borderBottom: 'black solid 2px' }}
              >
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell colSpan={2}>Order Quantity</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell colSpan={1} align="right">
                <NumberFormat
                  value={quantity}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>Product Cost</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={factoryUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableSubtitleCell colSpan={3}>TOTAL FACTORY COST</StyledTableSubtitleCell>
              <StyledTableSubtitleCell align="right">
                <NumberFormat
                  value={factoryTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableSubtitleCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Supply Chain Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.supplyChainFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={supplyChainUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={supplyChainTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>Quality Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={qualityUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={qualityTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>Bulk Freight Fees - B2B Only</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={logisticsUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={logisticsTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>Duties - B2B Only</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={dutiesUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={dutiesTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableTitleCell colSpan={2}>
                SUPPLY CHAIN FEES WITH QUALITY
              </StyledTableTitleCell>
              <StyledTableTitleCell align="right">
                <NumberFormat
                  value={supplyChainOpsUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableTitleCell>
              <StyledTableTitleCell align="right">
                <NumberFormat
                  value={supplyChainOpsTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableTitleCell>
            </TableRow>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell colSpan={3}>Rate</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Software Licensing Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.bulkSoftwareFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkSoftwareUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkSoftwareTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>

            <TableRow>
              <StyledTableCell>Bulk Order Commission Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.bulkCommissionFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkCommissionUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkCommissionTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell>Bulk Order Partner Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.bulkPartnerFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkPartnerUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkPartnerTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell>Bulk Order Licensing & Royalties</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.bulkRoyaltiesFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkRoyaltiesUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkRoyaltiesTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableTitleCell colSpan={2}>
                TOTAL SUPPLY CHAIN SERVICE FEES
              </StyledTableTitleCell>
              <StyledTableTitleCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkServiceFeesUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableTitleCell>
              <StyledTableTitleCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.bulkServiceFeesTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableTitleCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Payment Processing Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.paymentProcessingFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.paymentProcessingUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.paymentProcessingTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableTitleCell colSpan={2}>TOTAL CLIENT COST</StyledTableTitleCell>
              <StyledTableTitleCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.clientCostUnit}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableTitleCell>
              <StyledTableTitleCell align="right">
                <NumberFormat
                  value={calculatedExpensesFields?.clientCostTotal}
                  // className='numberFormat'
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableTitleCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ExpensesTables
