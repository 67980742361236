import OrderIcon from '@material-ui/icons/Collections'
import OrderList from './OrderList'
import OrderEdit from './OrderEdit'
import OrderCreate from './OrderCreate'
// import OrderShow from './OrderShow'

export default {
  list: OrderList,
  create: OrderCreate,
  edit: OrderEdit,
  icon: OrderIcon,
  // show: OrderShow,
}
