import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const sendConsumerOrderShippedEmail = ({ context, params }) => {
  const variables = {
    orderId: params.data.orderId,
  }
  return {
    ...context,
    variables,
    query: gql`
      mutation sendConsumerOrderShippedEmail($orderId: Int!) {
        sendConsumerOrderShippedEmail(arg1: { orderId: $orderId }) {
          success
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data,
    }),
  }
}
