/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react'
import { Card } from '@material-ui/core'
import { FormWithRedirect, useDataProvider, useNotify, Edit } from 'react-admin'
import moment from 'moment'
import FormData from './components/FormData'
import uploadFile from '../../../dataProvider/upload'

const OfferEdit = (props) => (
  <Edit actions={null} {...props} id={props.match.params.id} mutationMode="pessimistic">
    <OfferEditForm />
  </Edit>
)
const OfferEditForm = (props) => {
  // const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [packagingTypes, setPackagingTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [materials, setMaterials] = useState([])
  const [features, setFeatures] = useState([])
  const [certificationTypes, setCertificationTypes] = useState([])
  const [addresses, setAddresses] = useState([])
  const [qualityAgencies, setQualityAgencies] = useState([])
  const [logisticsProviders, setLogisticsProviders] = useState([])
  // const [banks, setBanks] = useState([])
  // const [bankRegions, setBankRegions] = useState([])
  // const [currencies, setCurrencies] = useState([])
  // const [cities, setCities] = useState([])
  // const [provinces, setProvinces] = useState([])
  // const [countries, setCountries] = useState([])
  const [factoryMilestones, setFactoryMilestones] = useState([])
  const [qualityMilestones, setQualityMilestones] = useState([])
  const [logisticsMilestones, setLogisticsMilestones] = useState([])
  const [inspectionLevels, setInspectionLevels] = useState([])
  const [inspectionTypes, setInspectionTypes] = useState([])
  // const [supplierList, setSupplierList] = useState([])
  const { record } = props

  // console.log('OFFEREDITPROPS', props.record)

  const handleSave = async (submission) => {
    // console.log('SUBMISSION:', submission, action)

    const params = {}
    const user = JSON.parse(localStorage.getItem('user'))
    params.sca_id = submission.sca_id || user.id

    // OFFER
    // console.log('1. OFFER', params)
    params.status = `^${submission.status}^`
    submission.id ? (params.id = submission.id) : null
    submission.name ? (params.name = submission.name) : null
    submission.upc_code ? (params.upc_code = submission.upc_code) : null
    submission.warranty ? (params.warranty = submission.warranty) : null
    submission.short_description ? (params.short_description = submission.short_description) : null
    submission.long_description ? (params.long_description = submission.long_description) : null
    submission.quality_agency_id ? (params.quality_agency_id = submission.quality_agency_id) : null
    submission.inspection_type ? (params.inspection_type = submission.inspection_type) : null
    submission.inspection_level ? (params.inspection_level = submission.inspection_level) : null
    submission.inspectionEstimate
      ? (params.inspection_estimate = parseInt(submission.inspectionEstimate) * 100)
      : null
    submission.inspection_work_days
      ? (params.inspection_work_days = submission.inspection_work_days)
      : null
    submission.logistics_agency_id
      ? (params.logistics_agency_id = submission.logistics_agency_id)
      : null
    submission.hts_code ? (params.hts_code = submission.hts_code) : null
    submission.dutyRate ? (params.duty_rate = submission.dutyRate * 100) : null
    submission.logisticsEstimate
      ? (params.logistics_estimate = parseInt(submission.logisticsEstimate * 100))
      : null
    submission.logistics_quote_valid_date
      ? (params.logistics_quote_valid_date = submission.logistics_quote_valid_date)
      : null
    submission.logistics_lead_time
      ? (params.logistics_lead_time = submission.logistics_lead_time)
      : null
    submission.logistics_disclaimer
      ? (params.logistics_disclaimer = submission.logistics_disclaimer)
      : null
    submission.retailPrice ? (params.retail_price = parseInt(submission.retailPrice * 100)) : null
    submission.moq ? (params.moq = submission.moq) : null
    submission.supply_chain_fees ? (params.supplyChainFees = submission.supplyChainFees * 100) : 0
    submission.royaltiesFees ? (params.bulk_royalties_fees = submission.royaltiesFees * 100) : 0
    submission.paymentFees ? (params.payment_processing_fees = submission.paymentFees * 100) : 0
    submission.softwareFees ? (params.bulk_software_fees = submission.softwareFees * 100) : 0
    submission.commissionFees ? (params.bulk_commission_fees = submission.commissionFees * 100) : 0
    submission.partnerFees ? (params.bulk_partner_fees = parseInt(submission.partnerFees * 100)) : 0
    submission.marketingBudget ? (params.marketing_budget = submission.marketingBudget * 100) : 0
    submission.price ? (params.investor_price_per_piece = submission.price * 100) : null
    submission.minimumPurchaseValue
      ? (params.minimum_purchase_value = submission.minimumPurchaseValue)
      : null
    submission.minimum_purchase_quantity
      ? (params.minimum_purchase_quantity = submission.minimum_purchase_quantity)
      : null
    submission.estimatedROI ? (params.estimated_ROI = submission.estimatedROI) : null
    params.consignee_id = addresses?.Consignee?.id || null
    params.shipper_id = addresses?.Shipper?.id || null
    params.destination_address_id = addresses?.ShippingAddress?.id || null
    submission.supplierPickupAddress
      ? (params.supplier_pickup_address_id = submission.supplierPickupAddress)
      : null
    // submission.supplierBillingAddress
    //   ? (params.supplier_billing_address_id = submission.supplierBillingAddress)
    //   : null
    submission.supplierFactoryAddress
      ? (params.supplier_factory_address_id = submission.supplierFactoryAddress)
      : null
    submission.supplierContact ? (params.supplier_contact_id = submission.supplierContact) : null

    // OFFER MEDIA / FILES
    // console.log('2. PRIMARYPHOTO', params)

    // PRIMARY PHOTO
    if (submission.primary_photo) {
      if (submission.primary_photo.id) {
        params.primary_photo = {
          data: {
            id: submission.primary_photo.id,
            url: submission.primary_photo.url,
            filename: submission.primary_photo.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.primary_photo.rawFile])
        params.primary_photo = {
          data: {
            url: file.data.files[0].url,
            filename: submission.primary_photo.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }
    // console.log('3. THUMBNAIL', params)

    // THUMBNAIL PHOTO
    if (submission.thumbnail) {
      if (submission.thumbnail.id) {
        params.thumbnail = {
          data: {
            id: submission.thumbnail.id,
            url: submission.thumbnail.url,
            filename: submission.thumbnail.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.thumbnail.rawFile])
        params.thumbnail = {
          data: {
            url: file.data.files[0].url,
            filename: submission.thumbnail.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    // console.log('4. UPCCODEPHOTO', params)
    // UPC CODE PHOTO
    if (submission.upc_code_photo) {
      if (submission.upc_code_photo.id) {
        params.upc_code_photo = {
          data: {
            id: submission.upc_code_photo.id,
            url: submission.upc_code_photo.url,
            filename: submission.upc_code_photo.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.upc_code_photo.rawFile])
        params.upc_code_photo = {
          data: {
            url: file.data.files[0].url,
            filename: submission.upc_code_photo.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    // console.log('5. INSPQUOTEFILE', params)
    // INSPECTION QUOTE FILE
    if (submission.inspection_quote_file) {
      if (submission.inspection_quote_file.id) {
        params.inspection_quote_file = {
          data: {
            id: submission.inspection_quote_file.id,
            url: submission.inspection_quote_file.url,
            filename: submission.inspection_quote_file.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.inspection_quote_file.rawFile])
        params.inspection_quote_file = {
          data: {
            url: file.data.files[0].url,
            filename: submission.inspection_quote_file.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    // console.log('6. CHECKFILE', params)
    // INSPECTION CHECKLIST FILE
    if (submission.inspection_checklist) {
      if (submission.inspection_checklist.id) {
        params.inspection_checklist = {
          data: {
            id: submission.inspection_checklist.id,
            url: submission.inspection_checklist.url,
            filename: submission.inspection_checklist.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.inspection_checklist.rawFile])
        params.inspection_checklist = {
          data: {
            url: file.data.files[0].url,
            filename: submission.inspection_checklist.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    // console.log('7. LOGQUOTEFILE', params)
    // LOGISTICS QUOTE FILE
    if (submission.logistics_quote_file) {
      if (submission.logistics_quote_file.id) {
        params.logistics_quote_file = {
          data: {
            id: submission.logistics_quote_file.id,
            url: submission.logistics_quote_file.url,
            filename: submission.logistics_quote_file.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.logistics_quote_file.rawFile])
        params.logistics_quote_file = {
          data: {
            url: file.data.files[0].url,
            filename: submission.logistics_quote_file.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    // OFFER.FILES

    // console.log('8. ADDPHOTOS', params)
    // ADDITIONAL PHOTOS
    const files = []
    if (submission.photos && submission.photos?.length > 0) {
      await Promise.all(
        submission.photos.map(async (photo) => {
          if (photo.id) {
            files.push({
              file: {
                data: {
                  id: photo.id,
                  filename: photo.filename,
                  url: photo.url,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              },
              type: '^ADDITIONAL_PHOTO^',
              is_visible_to_client: true,
            })
          } else {
            const file = await uploadFile([photo.rawFile])
            files.push({
              type: '^ADDITIONAL_PHOTO^',
              is_visible_to_client: true,
              file: {
                data: {
                  url: file.data.files[0].url,
                  filename: photo.rawFile.name,
                },
                // on_conflict: {
                //   constraint: '^file_filename_key^',
                //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                // },
              },
            })
          }
        }),
      )
    }

    // console.log('9. FACEACHEPHOTOS', params)
    // FACEBOOK PHOTOS
    if (submission.facebookPhotos && submission.facebookPhotos?.length > 0) {
      await Promise.all(
        submission.facebookPhotos.map(async (photo) => {
          if (photo.id) {
            files.push({
              file: {
                data: {
                  id: photo.id,
                  filename: photo.filename,
                  url: photo.url,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              },
              type: '^FACEBOOK_PHOTO^',
            })
          } else {
            const file = await uploadFile([photo.rawFile])
            files.push({
              type: '^FACEBOOK_PHOTO^',
              file: {
                data: { url: file.data.files[0].url, filename: photo.rawFile.name },
                // on_conflict: {
                //   constraint: '^file_filename_key^',
                //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                // },
              },
            })
          }
        }),
      )
    }

    // console.log('10. INSTASPAMPHOTOS', params)
    // INSTAGRAM PHOTOS
    if (submission.instagramPhotos && submission.instagramPhotos?.length > 0) {
      await Promise.all(
        submission.instagramPhotos.map(async (photo) => {
          if (photo.id) {
            files.push({
              file: {
                data: {
                  id: photo.id,
                  filename: photo.filename,
                  url: photo.url,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              },
              type: '^INSTAGRAM_PHOTO^',
            })
          } else {
            const file = await uploadFile([photo.rawFile])
            files.push({
              type: '^INSTAGRAM_PHOTO^',
              file: {
                data: { url: file.data.files[0].url, filename: photo.rawFile.name },
                // on_conflict: {
                //   constraint: '^file_filename_key^',
                //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                // },
              },
            })
          }
        }),
      )
    }

    // console.log('11. DESIGNFILES', params)
    // DESIGN FILES
    if (submission.designFiles && submission.designFiles?.length > 0) {
      await Promise.all(
        submission.designFiles.map(async (photo) => {
          if (photo.id) {
            files.push({
              file: {
                data: {
                  id: photo.id,
                  filename: photo.filename,
                  url: photo.url,
                },
                on_conflict: {
                  constraint: '^file_pkey^',
                  update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                },
              },
              type: '^DESIGN_FILE^',
            })
          } else {
            const file = await uploadFile([photo.rawFile])
            files.push({
              type: '^DESIGN_FILE^',
              file: {
                data: { url: file.data.files[0].url, filename: photo.rawFile.name },
                // on_conflict: {
                //   constraint: '^file_filename_key^',
                //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
                // },
              },
            })
          }
        }),
      )
    }

    if (files.length > 0) {
      params.offer_files = {
        data: files,
        on_conflict: {
          constraint: '^offer_file_offer_id_file_id_type_key^',
          update_columns: ['^type^', '^file_id^'],
        },
      }
    }

    // console.log('12. OFFERCATS', params)
    // OFFER.CATEGORIES
    if (submission.categories?.length > 0) {
      const cats = []
      submission.categories.map((item) => {
        if (item.id) {
          cats.push({
            category_id: item.id,
            deleted_at: null,
          })
        }
      })
      if (cats.length > 0) {
        params.offer_categories = {
          data: cats,
          on_conflict: {
            constraint: '^offer_categories_offer_id_category_id_key^',
            update_columns: ['^deleted_at^'],
          },
        }
      }
    }

    // console.log('13. OFFERFEATS', params)
    // OFFER.FEATURES
    if (submission.features?.length > 0) {
      const feats = []
      submission.features.map((item) => {
        if (item.id) {
          feats.push({
            feature_id: item.id,
            deleted_at: null,
          })
        }
      })
      if (feats.length > 0) {
        params.offer_features = {
          data: feats,
          on_conflict: {
            constraint: '^offer_features_offer_id_feature_id_key^',
            update_columns: ['^deleted_at^'],
          },
        }
      }
    }

    // console.log('14. OFFERTAGS', params)
    // OFFER.TAGS
    if (submission.tags?.length > 0) {
      const tags = []
      submission.tags.map((item) => {
        tags.push({
          tag: {
            data: {
              name: item,
            },
            on_conflict: { constraint: '^tag_name_key^', update_columns: '^name^' },
          },
        })
      })
      if (tags.length > 0) {
        params.offer_tags = {
          data: tags,
          on_conflict: {
            constraint: '^offer_tags_offer_id_tag_id_key^',
            update_columns: ['^tag_id^'],
          },
        }
      }
    }

    // console.log('15. OFFERPAYS', params)
    // OFFER.PAYMENT TERMS
    if (
      submission.factoryPaymentTerms?.length > 0 ||
      submission.qualityPaymentTerms?.length > 0 ||
      submission.logisticsPaymentTerms?.length > 0
    ) {
      const paymentTerms = []
      submission.factoryPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^FACTORY^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      submission.qualityPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^QUALITY^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      submission.logisticsPaymentTerms?.map((item) => {
        if (item.value && item.milestone) {
          paymentTerms.push({
            id: item.id,
            type: '^LOGISTICS^',
            value: item.value,
            milestone: item.milestone,
          })
        }
      })
      paymentTerms.length > 0
        ? (params.offer_payment_terms = {
            data: paymentTerms,
            on_conflict: {
              constraint: '^offer_payment_terms_offer_id_milestone_type_key^',
              update_columns: ['^value^', '^milestone^'],
            },
          })
        : null
    }

    // console.log('16. QUOTE', params)
    // QUOTE
    params.quote = {
      data: {
        product_dimensions: { data: {} },
        packaging_dimensions: { data: {} },
        carton_dimensions: { data: {} },
      },
    }
    submission.client_quote?.product_quote?.id
      ? (params.quote.data.id = submission.client_quote.product_quote.id)
      : null
    submission.quote?.packaging_type
      ? (params.quote.data.packaging_type = submission.quote?.packaging_type)
      : null
    // submission.quantity ? (params.quote.data.quantity = submission.quantity) : null
    submission.price ? (params.quote.data.price = parseInt(submission.price * 100)) : null
    submission.manufacturing_lead_time
      ? (params.manufacturing_lead_time = submission.manufacturing_lead_time)
      : null
    submission.client_quote?.product_quote?.valid_date
      ? (params.quote.data.valid_date = submission.client_quote.product_quote.valid_date)
      : null
    submission.pieces_per_carton
      ? (params.quote.data.pieces_per_carton = submission.pieces_per_carton)
      : null
    submission.client_quote?.product_quote?.supplier?.id
      ? (params.quote.data.supplier_id = submission.client_quote?.product_quote?.supplier?.id)
      : null

    // console.log('17. DIMS', params)
    // QUOTE.PRODUCT DIMENSIONS
    submission.productDimensions?.length
      ? (params.quote.data.product_dimensions.data.length = parseInt(
          submission.productDimensions?.length * 100,
        ))
      : null
    submission.productDimensions?.width
      ? (params.quote.data.product_dimensions.data.width = parseInt(
          submission.productDimensions?.width * 100,
        ))
      : null
    submission.productDimensions?.height
      ? (params.quote.data.product_dimensions.data.height = parseInt(
          submission.productDimensions?.height * 100,
        ))
      : null
    submission.productDimensions?.weight
      ? (params.quote.data.product_dimensions.data.gross_weight = parseInt(
          submission.productDimensions?.weight * 100,
        ))
      : null
    submission.productDimensions?.id
      ? (params.quote.data.product_dimensions.data.id = submission.productDimensions?.id)
      : null
    // QUOTE.PACKAGING DIMENSIONS
    submission.packagingDimensions?.length
      ? (params.quote.data.packaging_dimensions.data.length = parseInt(
          submission.packagingDimensions?.length * 100,
        ))
      : null
    submission.packagingDimensions?.width
      ? (params.quote.data.packaging_dimensions.data.width = parseInt(
          submission.packagingDimensions?.width * 100,
        ))
      : null
    submission.packagingDimensions?.height
      ? (params.quote.data.packaging_dimensions.data.height = parseInt(
          submission.packagingDimensions?.height * 100,
        ))
      : null
    submission.packagingDimensions?.weight
      ? (params.quote.data.packaging_dimensions.data.gross_weight = parseInt(
          submission.packagingDimensions?.weight * 100,
        ))
      : null
    submission.packagingDimensions?.id
      ? (params.quote.data.packaging_dimensions.data.id = submission.packagingDimensions?.id)
      : null
    // QUOTE.CARTON DIMENSIONS
    submission.cartonDimensions?.length
      ? (params.quote.data.carton_dimensions.data.length = parseInt(
          submission.cartonDimensions?.length * 100,
        ))
      : null
    submission.cartonDimensions?.width
      ? (params.quote.data.carton_dimensions.data.width = parseInt(
          submission.cartonDimensions?.width * 100,
        ))
      : null
    submission.cartonDimensions?.height
      ? (params.quote.data.carton_dimensions.data.height = parseInt(
          submission.cartonDimensions?.height * 100,
        ))
      : null
    submission.cartonDimensions?.weight
      ? (params.quote.data.carton_dimensions.data.gross_weight = parseInt(
          submission.cartonDimensions?.weight * 100,
        ))
      : null
    submission.cartonDimensions?.id
      ? (params.quote.data.carton_dimensions.data.id = submission.cartonDimensions?.id)
      : null

    if (Object.keys(params.quote.data.carton_dimensions.data).length === 0) {
      delete params.quote.data.carton_dimensions
    } else {
      params.quote.data.carton_dimensions.on_conflict = {
        constraint: '^dimension_pkey^',
        update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
      }
    }
    if (Object.keys(params.quote.data.packaging_dimensions.data).length === 0) {
      delete params.quote.data.packaging_dimensions
    } else {
      params.quote.data.packaging_dimensions.on_conflict = {
        constraint: '^dimension_pkey^',
        update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
      }
    }
    if (Object.keys(params.quote.data.product_dimensions.data).length === 0) {
      delete params.quote.data.product_dimensions
    } else {
      params.quote.data.product_dimensions.on_conflict = {
        constraint: '^dimension_pkey^',
        update_columns: ['^length^', '^width^', '^height^', '^gross_weight^', '^net_weight^'],
      }
    }

    // console.log('18. QUOTEFILE', params)
    // QUOTE FILE

    if (submission.quote?.quote) {
      if (submission.quote.quote.id) {
        params.quote.data.quote = {
          data: {
            id: submission.quote.quote.id,
            url: submission.quote.quote.url,
            filename: submission.quote.quote.filename,
          },
          on_conflict: {
            constraint: '^file_pkey^',
            update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          },
        }
      } else {
        const file = await uploadFile([submission.quote.quote.rawFile])
        params.quote.data.quote = {
          data: {
            url: file.data.files[0].url,
            filename: submission.quote.quote.rawFile.name,
          },
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        }
      }
    }

    // QUOTE.SPECIFICATIONS
    const specifications = []

    // console.log('20. COLOURS', params)
    // COLOURS
    if (submission.productColours?.length > 0) {
      // console.log('FOUNDCOLOURS', submission.productColours)
      submission.productColours.map((item) => {
        specifications.push({
          option_value: {
            data: {
              value: item.option_value?.value || item.name,
              option: {
                data: { name: 'Colour' },
                on_conflict: {
                  constraint: '^option_name_key^',
                  update_columns: '^name^',
                },
              },
            },
            on_conflict: {
              constraint: '^option_value_option_id_value_key^',
              update_columns: '^value^',
            },
          },
        })
      })
    }

    // console.log('21. MATS', params)
    // MATERIALS

    if (submission.productMaterials?.length > 0) {
      // console.log('FOUNDMATERIALS', submission.productMaterials)
      submission.productMaterials.map((item) => {
        specifications.push({
          option_value: {
            data: {
              value: item.option_value?.value || item.name,
              option: {
                data: { name: 'Material' },
                on_conflict: {
                  constraint: '^option_name_key^',
                  update_columns: '^name^',
                },
              },
            },
            on_conflict: {
              constraint: '^option_value_option_id_value_key^',
              update_columns: '^value^',
            },
          },
        })
      })
    }

    // OTHER SPECIFICATIONS
    // console.log('22. SPECS', params)
    if (submission.specifications?.length > 0) {
      // console.log('FOUNDSPECS', submission.specifications)
      submission.specifications.map((item) => {
        item.value.map((val) => {
          specifications.push({
            deleted_at: null,
            option_value: {
              data: {
                value: val,
                option: {
                  data: { name: item.name },
                  on_conflict: {
                    constraint: '^option_name_key^',
                    update_columns: '^name^',
                  },
                },
              },
              on_conflict: {
                constraint: '^option_value_option_id_value_key^',
                update_columns: '^value^',
              },
            },
          })
        })
      })
    }

    if (specifications.length > 0) {
      params.quote.data.specifications = {
        data: specifications,
        on_conflict: {
          constraint: '^product_quote_specification_product_quote_id_option_value_id_ke^',
          update_columns: ['^option_value_id^', '^deleted_at^'],
        },
      }
    }

    if (Object.keys(params.quote.data).length === 0) {
      delete params.quote
    } else {
      params.quote.on_conflict = {
        constraint: '^product_quote_pkey1^',
        update_columns: [
          '^valid_date^',
          '^quote_file_id^',
          // '^supplier_id^',
        ],
      }
    }

    // console.log('24. PARAMS:', params)

    if (Object.keys(params).length < 5) {
      return null
    }

    const offer = {
      objects: params,
      on_conflict: {
        constraint: '^offer_pkey^',
        update_columns: [
          '^long_description^',
          '^supplier_pickup_address_id^',
          '^supplier_contact_id^',
          '^supplier_factory_address_id^',
          '^sku^',
          '^short_description^',
          '^shipper_id^',
          '^warranty^',
          '^upc_code^',
          '^name^',
          '^retail_price^',
          '^marketing_budget^',
          '^duty_rate^',
          '^logistics_estimate^',
          '^inspection_estimate^',
          '^bulk_royalties_fees^',
          '^payment_processing_fees^',
          '^bulk_software_fees^',
          '^bulk_commission_fees^',
          '^moq^',
          '^supply_chain_fees^',
          '^bulk_partner_fees^',
          '^consignee_id^',
          '^destination_address_id^',
          '^hts_code^',
          '^inspection_level^',
          '^inspection_type^',
          '^inspection_work_days^',
          '^quality_agency_id^',
          '^logistics_agency_id^',
          '^logistics_disclaimer^',
          '^logistics_lead_time^',
          '^logistics_quote_valid_date^',
          '^quote_id^',
          '^status^',
          '^thumbnail_photo_id^',
          '^inspection_checklist_file_id^',
          '^logistics_quote_file_id^',
          '^inspection_quote_file_id^',
          '^primary_photo_id^',
          '^upc_code_photo_id^',
          '^minimum_purchase_value^',
          '^minimum_purchase_quantity^',
          '^manufacturing_lead_time^',
          '^estimated_ROI^',
          '^investor_price_per_piece^',
        ],
      },
    }

    // console.log('25. OFFER: ', offer)

    try {
      await dataProvider.create('offer', offer)

      // console.log(resp)

      // history.push({ pathname: `/projectDashboard/${projectID}`, query: { projectID } })
    } catch (error) {
      notify(error.message.split('.')[0], { type: 'warning' })
    }

    // console.log('CHECKINGDELETIONS...')

    // DELETIONS

    const currentTime = moment().toISOString()
    // console.log(currentTime)
    // PRIMARYPHOTO

    // THUMBNAIL
    // UPCPHOTO
    // INSPQUOTE
    // INSPCHECK
    // LOGQUOTE
    // OFFER_FILES

    const photos = []
    const designFiles = []
    const facebookPhotos = []
    const instagramPhotos = []

    // console.log('CHECKINGDELFILES...')
    if (record && record.offer_files.length > 0) {
      record.offer_files.map((item) => {
        switch (item.type) {
          case 'ADDITIONAL_PHOTO':
            photos.push(item)
            return null
          case 'FACEBOOK_PHOTO':
            facebookPhotos.push(item)
            return null
          case 'INSTAGRAM_PHOTO':
            instagramPhotos.push(item)
            return null
          case 'DESIGN_FILE':
            designFiles.push(item)
            return null
          default:
            return null
        }
      })
    }

    // console.log(designFiles, facebookPhotos, instagramPhotos, photos)
    const delDesFiles =
      designFiles
        ?.filter(({ file: { id: id1 } }) => !submission.designFiles?.some((id2) => id2.id === id1))
        ?.map((item) => item.id) || []
    // console.log(delDesFiles)

    const delFBFiles =
      facebookPhotos
        ?.filter(
          ({ file: { id: id1 } }) => !submission.facebookPhotos?.some((id2) => id2.id === id1),
        )
        ?.map((item) => item.id) || []
    // console.log(delFBFiles)

    const delIGFiles =
      instagramPhotos
        ?.filter(
          ({ file: { id: id1 } }) => !submission.instagramPhotos?.some((id2) => id2.id === id1),
        )
        ?.map((item) => item.id) || []
    // console.log(delIGFiles)

    const delAddFiles =
      photos
        ?.filter(({ file: { id: id1 } }) => !submission.photos?.some((id2) => id2.id === id1))
        ?.map((item) => item.id) || []
    // console.log(delAddFiles)

    const delFiles = [...delDesFiles, ...delFBFiles, ...delIGFiles, ...delAddFiles]
    if (delFiles.length > 0) {
      // console.log('DELETINGFILES', delFiles)
      try {
        await dataProvider.deleteOfferFiles('offer', { delFiles, currentTime })

        // console.log(data)
      } catch (error) {
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }

    // OFFER_TAGS
    // const delTags = record?.offer_tags
    //   ?.filter(({ tag: { id: id1 } }) => !submission.tags?.some(id2 => id2.id === id1))
    //   ?.map(item => item.id)

    // OFFER_CATS
    // console.log('CHECKINGDELCATS...')
    const delCats = record?.offer_categories
      ?.filter(({ category: { id: id1 } }) => !submission.categories?.some((id2) => id2.id === id1))
      ?.map((item) => item.id)
    if (delCats.length > 0) {
      // console.log('DELETINGCATS', delCats)
      try {
        await dataProvider.deleteOfferCategories('offer', { delCats, currentTime })

        // console.log(data)
      } catch (error) {
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }

    // OFFER_FEATS
    // console.log('RECORD', record)
    // console.log('SUBMISSION', record)
    const delFeats = record?.offer_features
      ?.filter(({ feature: { id: id1 } }) => !submission.features?.some((id2) => id2.id === id1))
      ?.map((item) => item.id)
    if (delFeats.length > 0) {
      // console.log('DELETINGFEATS', delFeats)
      try {
        await dataProvider.deleteOfferFeats('offer', { delFeats, currentTime })

        // console.log(data)
      } catch (error) {
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }
    // OFFER_TERMS
    // QUOTEDIMS
    // QUOTEFILE
    // QUOTECERTS
    // QUOTESPECS

    // console.log('QUOTESPECS', record?.quote?.specifications)

    const allSpecs = [...(submission.productColours || []), ...(submission.productMaterials || [])]

    submission.specifications?.map((spec) => {
      spec.value.map((val) => {
        allSpecs.push({
          option_value: {
            value: val,
            option: spec.name,
          },
        })
      })
    })

    // console.log('ALLSPECS', allSpecs)
    const delSpecs = record?.quote?.specifications
      ?.filter(
        ({ option_value: { value: id1 } }) =>
          !allSpecs?.some((id2) => id2.option_value?.value === id1),
      )
      ?.map((item) => item.id)

    if (delSpecs?.length > 0) {
      // console.log('DELETINGSPECS', delSpecs)
      try {
        await dataProvider.deleteOfferSpecs('offer', { delSpecs, currentTime })

        // console.log(data)
      } catch (error) {
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }

    notify('Updated Successfully.', 'info')
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        !abortController.signal.aborted && setPackagingTypes(packagingTypesTemp)

        const { data: certificationTypesList } = await dataProvider.getList('certificationType', {})
        !abortController.signal.aborted && setCertificationTypes(certificationTypesList)

        const { data: categoriesTemp } = await dataProvider.getList('categories', {})
        !abortController.signal.aborted &&
          setCategories(
            categoriesTemp.map((category) => ({ id: category.id, name: category.name })),
          )

        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        !abortController.signal.aborted && setMaterials(materialsTemp)

        const { data: qualityAgenciesTemp } = await dataProvider.getList('qualityAgency', {})
        !abortController.signal.aborted && setQualityAgencies(qualityAgenciesTemp)

        const { data: sellingFeaturesTemp } = await dataProvider.getList('sellingFeatures', {})
        !abortController.signal.aborted && setFeatures(sellingFeaturesTemp)

        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setFactoryMilestones(milestonesTemp.filter((item) => item.type === 'factory'))
        !abortController.signal.aborted &&
          setQualityMilestones(milestonesTemp.filter((item) => item.type === 'quality'))
        !abortController.signal.aborted &&
          setLogisticsMilestones(milestonesTemp.filter((item) => item.type === 'logistics'))

        const { data: inspLevelsTemp } = await dataProvider.getList('inspectionLevel', {})
        !abortController.signal.aborted && setInspectionLevels(inspLevelsTemp)

        const { data: inspectionTypesTemp } = await dataProvider.getList('inspectionType', {})
        !abortController.signal.aborted && setInspectionTypes(inspectionTypesTemp)

        const { data: logisticsTemp } = await dataProvider.getList('freightForwarder', {})
        !abortController.signal.aborted && setLogisticsProviders(logisticsTemp)

        const {
          data: { global_preferences: config },
        } = await dataProvider.globalConfig('globalConfig', {})
        const defSCM = parseInt(
          config.find((v) => v.setting_key === 'default_scm_company').setting_value,
        )
        const defLog = parseInt(
          config.find((v) => v.setting_key === 'default_logistics_provider').setting_value,
        )
        const defWar = parseInt(
          config.find((v) => v.setting_key === 'default_warehouse').setting_value,
        )

        const platformAddresses = await dataProvider.getPlatformAddressDetail(
          'getPlatformAddressDetail',
          { default_scm_id: defSCM, default_logistics_id: defLog, default_warehouse_id: defWar },
        )
        !abortController.signal.aborted && setAddresses(platformAddresses.data)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  // DATA MORPHING

  const photos = []
  const designFiles = []
  const facebookPhotos = []
  const instagramPhotos = []

  if (record.offer_files?.length > 0) {
    record.offer_files.map((item) => {
      switch (item.type) {
        case 'ADDITIONAL_PHOTO':
          photos.push(item.file)
          return null
        case 'FACEBOOK_PHOTO':
          facebookPhotos.push(item.file)
          return null
        case 'INSTAGRAM_PHOTO':
          instagramPhotos.push(item.file)
          return null
        case 'DESIGN_FILE':
          designFiles.push(item.file)
          return null
        default:
          return null
      }
    })
  }

  const factoryPaymentTerms = []
  const qualityPaymentTerms = []
  const logisticsPaymentTerms = []
  if (record.offer_payment_terms?.length > 0) {
    record.offer_payment_terms.map((item) => {
      switch (item.type) {
        case 'FACTORY':
          factoryPaymentTerms.push(item)
          return null
        case 'QUALITY':
          qualityPaymentTerms.push(item)
          return null
        case 'LOGISTICS':
          logisticsPaymentTerms.push(item)
          return null
        default:
          return null
      }
    })
  }

  const tags = []

  if (record.offer_tags?.length > 0) {
    record.offer_tags.map((item) => {
      tags.push(item.tag.name)
    })
  }

  const offerFeatures = []

  if (record.offer_features?.length > 0) {
    record.offer_features.map((item) => {
      offerFeatures.push({ id: item.feature.id })
    })
  }

  const offerCategories = []

  if (record.offer_categories?.length > 0) {
    record.offer_categories.map((item) => {
      offerCategories.push({ id: item.category.id })
    })
  }

  const quoteCertificates = []

  if (record.quote?.certificates?.length > 0) {
    record.quote.certificates.map((item) => quoteCertificates.push(item.certification))
  }

  const specifications = []
  const productColours = record.quote?.specifications?.filter(
    (spec) => spec.option_value?.option?.name === 'Colour',
  )
  const productMaterials = record.quote?.specifications?.filter(
    (spec) => spec.option_value?.option?.name === 'Material',
  )

  // console.log('RECORD', record)

  const uniqueSpecs = []

  record.quote?.specifications?.map((spec) => {
    if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
      uniqueSpecs.push(spec.option_value.option.name)
    }
  })

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material') {
      specifications.push({
        name: spec,
        value: record.quote?.specifications
          ?.filter((qspec) => qspec.option_value.option?.name === spec)
          .map((item) => item.option_value?.value),
      })
    }
  })

  const productDimensions = {
    id: record.quote?.product_dimensions?.id,
    length: record.quote?.product_dimensions?.length / 100,
    height: record.quote?.product_dimensions?.height / 100,
    width: record.quote?.product_dimensions?.width / 100,
    weight: record.quote?.product_dimensions?.gross_weight / 100,
  }

  const packagingDimensions = {
    id: record.quote?.packaging_dimensions?.id,
    length: record.quote?.packaging_dimensions?.length / 100,
    height: record.quote?.packaging_dimensions?.height / 100,
    width: record.quote?.packaging_dimensions?.width / 100,
    weight: record.quote?.packaging_dimensions?.gross_weight / 100,
  }

  const cartonDimensions = {
    id: record.quote?.carton_dimensions?.id,
    length: record.quote?.carton_dimensions?.length / 100,
    height: record.quote?.carton_dimensions?.height / 100,
    width: record.quote?.carton_dimensions?.width / 100,
    weight: record.quote?.carton_dimensions?.gross_weight / 100,
  }

  // console.log('PRDCLC', productDimensions, 'PKGCLC', packagingDimensions)

  // DATA VALIDATION RULES

  const validation = (values) => {
    // console.log(values, values.action)
    if (values.action === 'saving') {
      const errors = {}
      if (!values.name) {
        errors.name = 'The product name is required'
      }
      // console.log('SAVE_ERRORS', errors)
      return errors
    }
    const errors = {}
    if (!values?.primary_photo) {
      errors.primary_photo = 'The primary photo is required'
    }
    if (!values?.thumbnail) {
      errors.thumbnail = 'The thumbnail photo is required'
    }
    if (!values?.categories) {
      errors.categories = 'At least one category is required'
    }
    if (!values?.long_description) {
      errors.long_description = 'The long description is required'
    }
    if (!values?.short_description) {
      errors.short_description = 'The short description is required'
    }
    if (!values?.name) {
      errors.name = 'The product name is required'
    }
    if (!values?.price) {
      errors.price = 'The unit price is required'
    }
    // if (!values?.productColours) {
    //   errors.productColours = 'At least one product colour is required'
    // }
    // if (!values?.productMaterials) {
    //   errors.productMaterials = 'At least on product material is required'
    // }
    // if (!values?.qualityPaymentTerms) {
    //   errors.qualityPaymentTerms = 'The quality payment terms are required'
    // }
    if (!values?.quantity) {
      errors.quantity = 'The quantity is required'
    }
    // if (!values?.upc_code) {
    //   errors.upc_code = 'The UPC code is required'
    // }
    // if (!values?.warranty) {
    //   errors.warranty = 'The warranty is required'
    // }
    if (!values?.factoryPaymentTerms) {
      errors.factoryPaymentTerms = 'The factory payment terms are required'
    }
    if (!values?.manufacturing_lead_time) {
      errors.manufacturing_lead_time = 'The factory lead time is required'
    }
    if (!values?.client_quote?.product_quote?.supplier?.id) {
      errors.client_quote
        ? (errors.client_quote.product_quote.supplier = { id: 'The supplier is required' })
        : (errors.client_quote = {
            product_quote: { supplier: { id: 'The supplier is required' } },
          })
    }
    if (!values?.client_quote?.product_quote?.valid_date) {
      errors.client_quote
        ? (errors.client_quote.product_quote.valid_date =
            'The factory quote valid date is required')
        : (errors.client_quote = {
            product_quote: { valid_date: 'The factory quote valid date is required' },
          })
    }
    // if (!values?.quote?.quote) {
    //   errors.quote
    //     ? (errors.quote.quote = 'The quote file is required')
    //     : (errors.quote = { quote: 'The quote file is required' })
    // }
    // if (!values?.features || values?.features?.length < 3) {
    //   errors.feature = 'At least three features are required'
    // }
    // if (!values?.quote?.packaging_type) {
    //   errors.quote
    //     ? (errors.quote.packaging_type = 'The packaging type is required')
    //     : (errors.quote = { packaging_type: 'The packaging type is required' })
    // }
    // if (!values?.pieces_per_carton) {
    //   errors.quote
    //     ? (errors.quote.pieces_per_carton = 'The pieces per carton is required')
    //     : (errors.pieces_per_carton = 'The pieces per carton is required')
    // }
    // if (!values?.cartonDimensions?.height) {
    //   errors.cartonDimensions = 'The carton dimensions height is required'
    // }
    // if (!values?.cartonDimensions?.length) {
    //   errors.cartonDimensions = 'The carton dimensions length is required'
    // }
    // if (!values?.cartonDimensions?.width) {
    //   errors.cartonDimensions = 'The carton dimensions width is required'
    // }
    // if (!values?.cartonDimensions?.weight) {
    //   errors.cartonDimensions = 'The carton dimensions weight is required'
    // }
    // if (!values?.productDimensions?.height) {
    //   errors.productDimensions = 'The product dimensions height is required'
    // }
    // if (!values?.productDimensions?.length) {
    //   errors.productDimensions = 'The product dimensions length is required'
    // }
    // if (!values?.productDimensions?.width) {
    //   errors.productDimensions = 'The product dimensions width is required'
    // }
    // if (!values?.productDimensions?.weight) {
    //   errors.productDimensions = 'The product dimensions weight is required'
    // }
    // if (!values?.packagingDimensions?.height) {
    //   errors.packagingDimensions = 'The packaging dimensions height is required'
    // }
    // if (!values?.packagingDimensions?.length) {
    //   errors.packagingDimensions = 'The packaging dimensions length is required'
    // }
    // if (!values?.packagingDimensions?.width) {
    //   errors.packagingDimensions = 'The packaging dimensions width is required'
    // }
    // if (!values?.packagingDimensions?.weight) {
    //   errors.packagingDimensions = 'The packaging dimensions weight is required'
    // }

    if (typeof values?.commissionFees !== 'number') {
      errors.commissionRate = 'The commission rate is required'
    }
    if (typeof values?.marketingBudget !== 'number') {
      errors.marketingBudgetRate = 'The marketing budget is required'
    }
    if (!values?.moq) {
      errors.moq = 'The target quantity is required'
    }
    if (!values?.minimum_purchase_quantity) {
      errors.moq = 'The minimum purchase quantity is required'
    }
    if (!values?.retailPrice) {
      errors.retailPrice = 'The retail price is required'
    }
    if (typeof values?.supplyChainFees !== 'number') {
      errors.supplyChainRate = 'The supply chain rate is required'
    }
    if (typeof values?.inspectionEstimate !== 'number') {
      errors.inspectionEstimate = 'The inspection estimate is required'
    }
    // if (!values?.inspection_checklist) {
    //   errors.inspection_checklist = 'The inspection checklist is required'
    // }
    // if (!values?.inspection_level) {
    //   errors.inspection_level = 'The inspection level is required'
    // }
    // if (!values?.inspection_quote_file) {
    //   errors.inspection_quote_file = 'The inspection quote file is required'
    // }
    // if (!values?.inspection_type) {
    //   errors.inspection_type = 'The inspection type is required'
    // }
    if (!values?.inspection_work_days) {
      errors.inspection_work_days = 'The inspection work days is required'
    }
    // if (!values?.quality_agency_id) {
    //   errors.quality_agency_id = 'The quality agency is required'
    // }
    if (typeof values.dutyRate !== 'number') {
      errors.dutyRate = 'The freight duty rate is required'
    }
    if (!values?.hts_code) {
      errors.hts_code = 'The HTS Code is required'
    }
    if (!values?.logisticsEstimate) {
      errors.logisticsEstimate = 'The freight estimate is required'
    }
    // if (!values?.logisticsPaymentTerms) {
    //   errors.logisticsPaymentTerms = 'The freight payment terms are required'
    // }
    // if (!values?.logistics_agency_id) {
    //   errors.logistics_agency_id = 'The freight agency is required'
    // }
    // if (!values?.logistics_disclaimer) {
    //   errors.logistics_disclaimer = 'The freight disclaimer is required'
    // }
    if (!values?.logistics_lead_time) {
      errors.logistics_lead_time = 'The freight lead time is required'
    }
    // if (!values?.logistics_quote_file) {
    //   errors.logistics_quote_file = 'The freight quote file is required'
    // }
    if (!values?.logistics_quote_valid_date) {
      errors.logistics_quote_valid_date = 'The freight quote valid date is required'
    }
    // console.log('SUBMIT_ERRORS', errors)
    return errors
  }

  // console.log(productColours, productMaterials)

  // RENDER

  return (
    <FormWithRedirect
      {...props}
      validate={validation}
      initialValues={{
        designFiles,
        facebookPhotos,
        instagramPhotos,
        photos,
        tags,
        specifications,
        productColours,
        productMaterials,
        productDimensions,
        packagingDimensions,
        cartonDimensions,
        factoryPaymentTerms,
        qualityPaymentTerms,
        logisticsPaymentTerms,
        paymentFees: record.payment_processing_fees / 100,
        supplyChainFees: record.supply_chain_fees / 100,
        commissionFees: record.bulk_commission_fees / 100,
        marketingBudget: record.marketing_budget / 100,
        fulfillmentFees: record.bulk_software_fees / 100,
        partnerFees: record.bulk_partner_fees / 100,
        royaltiesFees: record.bulk_royalties_fees / 100,
        retailPrice: record.retail_price / 100,
        dutyRate: record.duty_rate / 100,
        inspectionEstimate: record.inspection_estimate / 100,
        logisticsEstimate: record.logistics_estimate / 100,
        price: record.investor_price_per_piece / 100,
        quantity: record.moq,
        // pieces_per_carton: record.quote?.pieces_per_carton,
        features: offerFeatures,
        categories: offerCategories,
        certificates: quoteCertificates,
      }}
      render={(formProps) => (
        <Card>
          <FormData
            certificationTypes={certificationTypes}
            packagingTypes={packagingTypes}
            categories={categories}
            materials={materials}
            features={features}
            setFeatures={setFeatures}
            // supplierList={supplierList}
            addresses={addresses}
            qualityAgencies={qualityAgencies}
            logisticsProviders={logisticsProviders}
            // banks={banks}
            // bankRegions={bankRegions}
            // currencies={currencies}
            factoryMilestones={factoryMilestones}
            qualityMilestones={qualityMilestones}
            logisticsMilestones={logisticsMilestones}
            // cities={cities}
            // provinces={provinces}
            // countries={countries}
            inspectionTypes={inspectionTypes}
            inspectionLevels={inspectionLevels}
            quoteData={{}}
            formProps={formProps}
            handleSave={handleSave}
            recordStatus={record.status}
          />
        </Card>
      )}
    />
  )
}

export default OfferEdit
