import React from 'react'
import { FormWithRedirect, Edit } from 'react-admin'
// import { makeStyles } from '@material-ui/core/styles'
import ConsumerOrdersForm from './components/ConsumerOrdersForm'
import ConsumerOrdersHeader from './components/ConsumerOrdersHeader'
// import { roundTo } from '../../utils/utils'

const ConsumerOrdersEdit = (props) => {
  return (
    <Edit {...props} actions={null} component="div" id={props.id} mutationMode="pessimistic">
      <ConsumerOrdersEditForm id={props.id} {...props} />
    </Edit>
  )
}

// const useStyles = makeStyles({
//   tabPanel: {
//     padding: '0px',
//     '& .MuiBox-root': {
//       padding: '0px',
//     },
//   },
// })

const ConsumerOrdersEditForm = (props) => {
  return (
    <FormWithRedirect
      {...props}
      initialValues={{}}
      render={() => (
        <div>
          <ConsumerOrdersHeader />
          <ConsumerOrdersForm />
        </div>
      )}
    />
  )
}

export default ConsumerOrdersEdit
