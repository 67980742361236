import React from 'react'
import { Tooltip } from '@material-ui/core'
import { cloneDeep, uniqueId } from 'lodash'

export const roundNumber = (num: number, decimalPlaces = 0) => {
  const p = Math.pow(10, decimalPlaces)
  const n = num * p * (1 + Number.EPSILON)
  return Math.round(n) / p
}

export const getRandomString = (length) => {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyz' // characters used in string
  let result = '' // initialize the result variable passed out of the function
  for (let i = length; i > 0; i--) {
    result += characters[Math.floor(Math.random() * characters.length)]
  }
  return result
}

export const toUpperCase = (target) => target.charAt(0).toUpperCase() + target.slice(1)

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getSearchParam = (searchString, variable) => {
  if (!searchString || !variable) return null
  const query = searchString?.substring(1)
  // console.log(query)
  var vars = query.split('&')
  // console.log(vars)
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    // console.log(pair)
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

export const getDisplayAddress = (address, size = null) => {
  const stringAddress = `${address.delivery_mobile ? address.delivery_mobile + ',\r\n' : ''}${
    address.delivery_contact ? address.delivery_contact + ',' : ''
  }${address.label ? address.label + ' - ' : ''}${address.address_line1}${
    address.address_line2 ? ', ' + address.address_line2 : ''
  }${', ' + address.city}${', ' + address.province}${', ' + address.country_code}${
    address.post_code ? ', ' + address.post_code : ''
  }${address.delivery_email ? ', ' + address.delivery_email : ''}`

  return size && size > 2 ? stringAddress.substring(1, size) : stringAddress
}

export const getDisplayName = (user, size, emailSize, showPrivate = false) => {
  // console.log(user, size, emailSize, showPrivate)
  if (!user) return 'Unknown User'
  const { forename, surname, email, stripe_connected_account_id, stripe_customer_account_id } = user
  if (forename && surname) {
    const fullName = `${forename} ${surname}`

    let toolTip = email
    if (stripe_connected_account_id && showPrivate) {
      toolTip = toolTip + ' - ' + stripe_connected_account_id
    }
    if (stripe_customer_account_id && showPrivate) {
      toolTip = toolTip + ' - ' + stripe_customer_account_id
    }

    return size && fullName?.length > size ? (
      <Tooltip title={`${fullName} (${toolTip})`}>
        <span>{`${fullName?.substring(0, size)}...`}</span>
      </Tooltip>
    ) : (
      <Tooltip title={`${fullName} (${toolTip})`}>
        <span>{fullName}</span>
      </Tooltip>
    )
    // } else if (username) {
    //   return username
  }
  return emailSize && email.length > emailSize ? (
    <Tooltip title={email}>
      <span>{`${email?.substring(0, emailSize)}...`}</span>
    </Tooltip>
  ) : (
    email
  )
}

export const getSCMQuoteRequirements = (values) => {
  const quoteRequirements = []
  if (!values) {
    quoteRequirements.push('Missing Product')
    return quoteRequirements
  }

  // console.log('CHECKINGSCMREQ', values)
  !values.name && quoteRequirements.push('Name')
  !values.short_description && quoteRequirements.push('Short Description')
  // !values.chargeable_weight_air && quoteRequirements.push('Chargeable Weight - Air')
  // !values.chargeable_weight_sea && quoteRequirements.push('Chargeable Weight - Sea')
  !values.hts_code && quoteRequirements.push('HTS Code')

  !values.product_categories?.length > 0 ? quoteRequirements.push('Min 1 Category') : null

  !values.product_dimensions?.length &&
    !values.productDimensions?.length &&
    quoteRequirements.push('Product Length')
  !values.product_dimensions?.width &&
    !values.productDimensions?.width &&
    quoteRequirements.push('Product Width')
  !values.product_dimensions?.height &&
    !values.productDimensions?.height &&
    quoteRequirements.push('Product Height')
  !values.carton_dimensions?.length &&
    !values.cartonDimensions?.length &&
    quoteRequirements.push('Carton Length')
  !values.carton_dimensions?.width &&
    !values.cartonDimensions?.width &&
    quoteRequirements.push('Carton Width')
  !values.carton_dimensions?.height &&
    !values.cartonDimensions?.height &&
    quoteRequirements.push('Carton Height')
  !values.carton_dimensions?.gross_weight &&
    !values.cartonDimensions?.gross_weight &&
    quoteRequirements.push('Carton Weight')

  if (values.product_quotes?.length > 0) {
    !values.product_quotes[0].prices?.length > 0 && quoteRequirements.push('Min 1 Quote')
    !values.product_quotes[0].supplier_id && quoteRequirements.push('Supplier')
    !values.product_quotes[0].packaging_type && quoteRequirements.push('Packaging Type')
    !values.product_quotes[0].pieces_per_carton && quoteRequirements.push('Pieces per Carton')
  } else {
    quoteRequirements.push('Missing Factory Quote Data')
  }

  return quoteRequirements
}

export const toPath = (key) => {
  if (key === null || key === undefined || !key.length) {
    return []
  }
  if (typeof key !== 'string') {
    throw new Error('toPath() expects a string')
  }
  return key.split(/[.[\]]+/).filter(Boolean)
}

export const toStrPath = (arr, lastStr) => {
  if (!Array.isArray(arr)) {
    throw new Error('toPath() expects a array')
  }
  if (lastStr) {
    arr[arr.length - 1] = lastStr
  }
  return arr.join('.')
}

export const getIn = (state, complexKey) => {
  // Intentionally using iteration rather than recursion
  const path = toPath(complexKey)
  let current = state
  for (let i = 0; i < path.length; i++) {
    const key = path[i]
    if (
      current === undefined ||
      current === null ||
      typeof current !== 'object' ||
      (Array.isArray(current) && isNaN(key))
    ) {
      return undefined
    }
    current = current[key]
  }
  return current
}

const setInRecursor = (current, index, path, value, destroyArrays) => {
  if (index >= path.length) {
    // end of recursion
    return value
  }
  const key = path[index]

  // determine type of key
  if (isNaN(key)) {
    // object set
    if (current === undefined || current === null) {
      // recurse
      const result = setInRecursor(undefined, index + 1, path, value, destroyArrays)

      // delete or create an object
      return result === undefined ? undefined : { [key]: result }
    }
    if (Array.isArray(current)) {
      throw new Error('Cannot set a non-numeric property on an array')
    }
    // current exists, so make a copy of all its values, and add/update the new one
    const result = setInRecursor(current[key], index + 1, path, value, destroyArrays)
    if (result === undefined) {
      const numKeys = Object.keys(current).length
      if (current[key] === undefined && numKeys === 0) {
        // object was already empty
        return undefined
      }
      if (current[key] !== undefined && numKeys <= 1) {
        // only key we had was the one we are deleting
        if (!isNaN(path[index - 1]) && !destroyArrays) {
          // we are in an array, so return an empty object
          return {}
        }
        return undefined
      }
      // eslint-disable-next-line no-unused-vars
      const { [key]: _removed, ...final } = current
      return final
    }
    // set result in key
    return {
      ...current,
      [key]: result,
    }
  }
  // array set
  const numericKey = Number(key)
  if (current === undefined || current === null) {
    // recurse
    const result = setInRecursor(undefined, index + 1, path, value, destroyArrays)

    // if nothing returned, delete it
    if (result === undefined) {
      return undefined
    }

    // create an array
    const array = []
    array[numericKey] = result
    return array
  }
  if (!Array.isArray(current)) {
    throw new Error('Cannot set a numeric property on an object')
  }
  // recurse
  const existingValue = current[numericKey]
  const result = setInRecursor(existingValue, index + 1, path, value, destroyArrays)

  // current exists, so make a copy of all its values, and add/update the new one
  const array = [...current]
  if (destroyArrays && result === undefined) {
    array.splice(numericKey, 1)
    if (array.length === 0) {
      return undefined
    }
  } else {
    array[numericKey] = result
  }
  return array
}

export const setIn = (state, key, value, destroyArrays = false) => {
  if (state === undefined || state === null) {
    throw new Error(`Cannot call setIn() with ${String(state)} state`)
  }
  if (key === undefined || key === null) {
    throw new Error(`Cannot call setIn() with ${String(key)} key`)
  }
  // Recursive function needs to accept and return State, but public API should
  // only deal with Objects
  return setInRecursor(state, 0, toPath(key), value, destroyArrays)
}

export const hasChangeValues = (record, dirtyFields) => {
  let values = { id: record.id }
  Object.keys(dirtyFields).forEach((key) => {
    const data = getIn(record, key)
    const path = toPath(key)
    if (Array.isArray(path) && path.length > 1) {
      const idKey = toStrPath(path, 'id')
      const idData = getIn(record, idKey)
      values = setIn(values, key, data)
      values = setIn(values, idKey, idData)
    } else if ((data && !(typeof data === 'object')) || typeof data === 'boolean') {
      values = setIn(values, key, data)
    }
  })
  return values
}

export const roundTo = (n, digits) => {
  var negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  var multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return n
}

export const cloneRecord = (oldRecord) => {
  // console.log(oldRecord)
  if (oldRecord && typeof oldRecord == 'object') {
    const newRecord = cloneDeep(oldRecord)
    const recursiveForEach = (obj) => {
      Object.values(obj).forEach((key) => {
        if (key?.hasOwnProperty && typeof key == 'object') {
          delete key.id
          delete key.__typename
          // console.log(key)
          recursiveForEach(key)
        }
      })
    }
    recursiveForEach(newRecord)
    if (typeof newRecord.id === 'number') {
      delete newRecord.id
    }
    delete newRecord.__typename

    // console.log(newRecord)

    return newRecord
  }
  return null
}

export const duplicateProduct = ({ record }) => {
  console.log('DUPING', record)

  const sizeOptions =
    record.product_options?.length > 0
      ? record.product_options
          ?.filter(
            (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Size',
          )
          .map((item) => item.option_value.value)
      : []

  const otherSpecifications = []

  const uniqueSpecs = []
  record.product_specifications?.map((spec) => {
    if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
      uniqueSpecs.push(spec.option_value.option.name)
    }
  })

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material' && spec !== 'Imprint') {
      otherSpecifications.push({
        name: spec,
        option_value: {
          value: record.product_specifications
            .filter(
              (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
            )
            .map((item) => item.option_value?.value),
        },
      })
    }
  })

  const uniqueCustomSpecs = []
  record?.customisations?.map((spec) => {
    if (uniqueCustomSpecs.indexOf(spec.option_value.option.name) === -1) {
      uniqueCustomSpecs.push(spec.option_value.option.name)
    }
  })

  const productCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      option_value: {
        value: record?.customisations
          ?.filter((qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null)
          .map((item) => item.option_value?.value),
      },
    }
  })

  return {
    name: record.name,
    brand: record.brand,
    short_description: record.short_description,
    notes: record.notes,
    hts_code: record.hts_code,
    sca_user_id: record.sca_user_id,
    dutyRate: record.duty_rate === 0 ? 0 : record.duty_rate / 100 || null,
    chargeableWeightAir: record.chargeable_weight_air || null,
    chargeableWeightSea: record.chargeable_weight_sea || null,
    allow_colour_selection: record.allow_colour_selection || null,
    root_sku: record.root_sku,
    root_product_id: record.root_product_id || record.id,
    status: '^PENDING_SUBMISSION^',
    sampleCost:
      record.product_quotes.length > 0
        ? record.product_quotes[0]?.sample_cost === 0
          ? 0
          : record.product_quotes[0]?.sample_cost / 100 || null
        : null,
    customSampleCost:
      record.product_quotes.length > 0
        ? record.product_quotes[0]?.custom_sample_cost === 0
          ? 0
          : record.product_quotes[0]?.custom_sample_cost / 100 || null
        : null,
    toolingCost:
      record.product_quotes.length > 0
        ? record.product_quotes[0]?.tooling_cost === 0
          ? 0
          : record.product_quotes[0]?.tooling_cost / 100 || null
        : null,
    product_declarations: record.product_declarations,
    primary_photo: record.primary_photo,
    thumbnail_photo: record.thumbnail_photo,
    additional_files: record.additional_files,
    images: record.images,
    factoryPrices:
      record.product_quotes.length > 0 &&
      record.product_quotes[0].prices?.map((item) => {
        return {
          quantity: item.quantity,
          unitPrice: item.unit_price / 100 || null,
          production_lead_time: item.lead_time,
          est_landed_cost_air: item.est_landed_cost_air || null,
          est_landed_cost_sea: item.est_landed_cost_sea || null,
          shipment_weight: item.shipment_weight || null,
          total_cartons: item.total_cartons || null,
          total_cbm: item.total_cbm || null,
          total_duties: item.total_duties || null,
        }
      }),
    product_quotes: [
      {
        packaging_type:
          record.product_quotes.length > 0 ? record.product_quotes[0].packaging_type : null,
        pieces_per_carton:
          record.product_quotes.length > 0
            ? record.product_quotes[0].pieces_per_carton === 0
              ? 0
              : record.product_quotes[0].pieces_per_carton
            : null,
        sample_lead_time:
          record.product_quotes.length > 0
            ? record.product_quotes[0].sample_lead_time === 0
              ? 0
              : record.product_quotes[0].sample_lead_time
            : null,
        tooling_lead_time:
          record.product_quotes.length > 0
            ? record.product_quotes[0].tooling_lead_time === 0
              ? 0
              : record.product_quotes[0].tooling_lead_time
            : null,
        supplier_id: record.product_quotes.length > 0 ? record.product_quotes[0].supplier_id : null,
        valid_date: record.product_quotes.length > 0 ? record.product_quotes[0].valid_date : null,
        cartons_per_container_20ft:
          record.product_quotes.length > 0
            ? record.product_quotes[0].cartons_per_container_20ft
            : null,
        cartons_per_container_40ft:
          record.product_quotes.length > 0
            ? record.product_quotes[0].cartons_per_container_40ft
            : null,
        cartons_per_container_40ft_hc:
          record.product_quotes.length > 0
            ? record.product_quotes[0].cartons_per_container_40ft_hc
            : null,
        cartons_per_pallet:
          record.product_quotes.length > 0 ? record.product_quotes[0].cartons_per_pallet : null,
        allow_order_splitting_by_size:
          record.product_quotes.length > 0
            ? record.product_quotes[0].allow_order_splitting_by_size
            : null,
        container_capacity_20ft: record.product_quotes[0].container_capacity_20ft || null,
        container_capacity_40ft: record.product_quotes[0].container_capacity_40ft || null,
        container_capacity_40ft_hc: record.product_quotes[0].container_capacity_40ft_hc || null,
        container_rate_20ft: record.product_quotes[0].container_rate_20ft || null,
        container_rate_40ft: record.product_quotes[0].container_rate_40ft || null,
        container_rate_40ft_hc: record.product_quotes[0].container_rate_40ft_hc || null,
        freight_rate_air: record.product_quotes[0].freight_rate_air || null,
        freight_rate_sea: record.product_quotes[0].freight_rate_sea || null,
      },
    ],
    packagingDimensions: {
      length:
        record.packaging_dimensions?.length === 0
          ? 0
          : record.packaging_dimensions?.length / 100 || null,
      width:
        record.packaging_dimensions?.width === 0
          ? 0
          : record.packaging_dimensions?.width / 100 || null,
      height:
        record.packaging_dimensions?.height === 0
          ? 0
          : record.packaging_dimensions?.height / 100 || null,
      gross_weight:
        record.packaging_dimensions?.gross_weight === 0
          ? 0
          : record.packaging_dimensions?.gross_weight / 100 || null,
      lengthIN: record.packaging_dimensions?.length
        ? (record.packaging_dimensions?.length / 100 / 2.54).toFixed(2)
        : null,
      widthIN: record.packaging_dimensions?.width
        ? (record.packaging_dimensions?.width / 100 / 2.54).toFixed(2)
        : null,
      heightIN: record.packaging_dimensions?.height
        ? (record.packaging_dimensions?.height / 100 / 2.54).toFixed(2)
        : null,
      weightLB: record.packaging_dimensions?.gross_weight
        ? ((record.packaging_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
        : null,
    },
    productDimensions: {
      length:
        record.product_dimensions?.length === 0
          ? 0
          : record.product_dimensions?.length / 100 || null,
      width:
        record.product_dimensions?.width === 0 ? 0 : record.product_dimensions?.width / 100 || null,
      height:
        record.product_dimensions?.height === 0
          ? 0
          : record.product_dimensions?.height / 100 || null,
      gross_weight:
        record.product_dimensions?.gross_weight === 0
          ? 0
          : record.product_dimensions?.gross_weight / 100 || null,
      lengthIN: record.product_dimensions?.length
        ? (record.product_dimensions?.length / 100 / 2.54).toFixed(2)
        : null,
      widthIN: record.product_dimensions?.width
        ? (record.product_dimensions?.width / 100 / 2.54).toFixed(2)
        : null,
      heightIN: record.product_dimensions?.height
        ? (record.product_dimensions?.height / 100 / 2.54).toFixed(2)
        : null,
      weightLB: record.product_dimensions?.gross_weight
        ? ((record.product_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
        : null,
    },
    cartonDimensions: {
      length:
        record.carton_dimensions?.length === 0 ? 0 : record.carton_dimensions?.length / 100 || null,
      width:
        record.carton_dimensions?.width === 0 ? 0 : record.carton_dimensions?.width / 100 || null,
      height:
        record.carton_dimensions?.height === 0 ? 0 : record.carton_dimensions?.height / 100 || null,
      gross_weight:
        record.carton_dimensions?.gross_weight === 0
          ? 0
          : record.carton_dimensions?.gross_weight / 100 || null,
      lengthIN: record.carton_dimensions?.length
        ? (record.carton_dimensions?.length / 100 / 2.54).toFixed(2)
        : null,
      widthIN: record.carton_dimensions?.width
        ? (record.carton_dimensions?.width / 100 / 2.54).toFixed(2)
        : null,
      heightIN: record.carton_dimensions?.height
        ? (record.carton_dimensions?.height / 100 / 2.54).toFixed(2)
        : null,
      weightLB: record.carton_dimensions?.gross_weight
        ? ((record.carton_dimensions?.gross_weight / 100) * 2.2).toFixed(2)
        : null,
    },
    categories: record.product_categories?.map((item) => item.category.id),
    tags: record.product_tags?.map((item) => item.tag.id),
    productImprints: record.product_specifications
      ?.filter((item) => !item.deleted_at && item.option_value.option.name === 'Imprint')
      .map((item) => {
        return {
          id: item.option_value.id,
          name: item.option_value.value,
          value: item.option_value.value,
        }
      }),
    productColours: record.product_specifications
      ?.filter((item) => !item.deleted_at && item.option_value.option.name === 'Colour')
      .map((item) => {
        return { option_value: { value: item.option_value.value } }
      }),
    productMaterials: record.product_specifications
      ?.filter((item) => !item.deleted_at && item.option_value.option.name === 'Material')
      .map((item) => {
        return {
          id: item.option_value.id,
          name: item.option_value.value,
          value: item.option_value.value,
        }
      }),
    imprintOptions: record.product_options
      ?.filter((item) => !item.deleted_at && item.option_value.option.name === 'Imprint')
      .map((item) => {
        return {
          id: item.option_value.id,
          name: item.option_value.value,
          value: item.option_value.value,
        }
      }),
    colourOptions: record.product_options
      ?.filter((item) => !item.deleted_at && item.option_value.option.name === 'Colour')
      .map((item) => {
        return { option_value: { value: item.option_value.value } }
      }),
    materialOptions: record.product_options
      ?.filter((item) => !item.deleted_at && item.option_value.option.name === 'Material')
      .map((item) => {
        return {
          id: item.option_value.id,
          name: item.option_value.value,
          value: item.option_value.value,
        }
      }),
    sizeOptions,
    productCustomisations,
    otherSpecifications,
  }
}

// Cartesian loop. Adapted from CodeManiac's answer here https://stackoverflow.com/questions/15298912/javascript-generating-combinations-from-n-arrays-with-m-elements

// { value: x, option: y, label: z}

export const getPlatformName = (platformEnum) => {
  switch (platformEnum) {
    case 'CLIQUESTOCK':
      return 'Clique Stock'
    case 'SOLIDBLANKS':
      return 'Solid Blanks'
    default:
      return platformEnum
  }
}

export const getConsumerOrderStatus = (status) => {
  switch (status) {
    case 'PENDING_AUTHORISATION':
      return 'Pending Payment'
    case 'PROCESSING':
      return 'Processing'
    case 'SHIPPED':
    case 'SHIPPING':
      return 'Shipped'
    default:
      return status
  }
}

export const getSaleableProductStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return 'Active'
    case 'PENDING':
      return 'Inactive'
    default:
      return status
  }
}

export const getOfferStatus = (status) => {
  switch (status) {
    case 'PENDING_SUBMISSION':
      return 'Pending Submission'
    case 'PENDING_PURCHASE_ORDER':
      return 'Offer Fulfillment'
    case 'PENDING_APPROVAL':
      return 'Pending Approval'
    case 'PRODUCTION':
      return 'Production'
    case 'SHIPPING':
      return 'Shipping'
    case 'SELLING':
      return 'Selling'
    case 'COMPLETED':
      return 'Completed'
    case 'CANCELLED':
      return 'Cancelled'
    default:
      return status
  }
}

export const generateProductVariants = ({ options = [], existingVariants = [] }) => {
  // console.log('OPTS', options, 'EXISTINGVARIANTS', existingVariants)
  const loopOver = (arr, addVar = [], finalNew = [], finalOld = []) => {
    if (arr.length > 1) {
      arr[0].values.forEach((v) => {
        loopOver(
          arr.slice(1),
          [
            ...addVar,
            {
              // label: arr[0].name.toLowerCase().replace(/[^A-Z0-9]+/gi, '_'),
              option: arr[0].name,
              value: v.value,
            },
          ],
          finalNew,
          finalOld,
        )
      })
    } else {
      arr[0].values.forEach((v) => {
        const allVals = [
          ...addVar,
          {
            // label: arr[0].name.toLowerCase().replace(/[^A-Z0-9]+/gi, '_'),
            option: arr[0].name,
            value: v.value,
          },
        ]

        const foundVariant = existingVariants?.find((el) => {
          if (el.variant?.values?.length === allVals.length) {
            const matches = []
            allVals.map((val) =>
              matches.push(
                el.variant?.values.some((elVal) => {
                  return elVal.value === val.value || el.value?.value === val.value
                }),
              ),
            )
            if (!matches.some((match) => !match)) {
              return el.id
            } else {
              return null
            }
          }
          return null
        })
        foundVariant
          ? finalOld.push({
              id: foundVariant?.id,
              available: true,
              active: foundVariant.active,
              alwaysInStock: foundVariant.always_in_stock,
              list_weighting: foundVariant.list_weighting,
              variant: {
                id: foundVariant?.variant?.id,
                sku: foundVariant?.variant?.sku,
                values: allVals,
                stock: foundVariant.stock,
                image: foundVariant.image,
                image_file_id: foundVariant.image?.id,
              },
            })
          : finalNew.push({
              id: `NSV${uniqueId()}`,
              available: false,
              active: false,
              stock: 0,
              variant: {
                id: `NV${uniqueId()}`,
                sku: null,
                values: allVals,
              },
            })
      })
    }
    return [...finalOld, ...finalNew]
  }

  return loopOver(options)
}
