import React from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  TopToolbar,
  // EditButton,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { getDisplayAddress } from '../../../utils/utils'
import AddressAdd from './AddressAdd'

const ListActions = (props) => {
  const { className, clientId } = props
  return (
    <TopToolbar className={className}>
      <AddressAdd clientId={clientId} />
    </TopToolbar>
  )
}

const AddressList = (props) => {
  const { clientId, ...rest } = props

  return (
    <Box p="10px 0px">
      <div>Company Addresses</div>
      <List
        {...rest}
        resource="address"
        filter={{ organisation_id: clientId }}
        perPage={25}
        actions={<ListActions clientId={clientId} />}
        empty={false}
        // bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid optimized path="clients">
          <TextField source="id" />
          <FunctionField label="Address" render={(record) => getDisplayAddress(record)} />
          <TextField source="type" />
          <DateField source="created_at" label="Created date" type="date" />
        </Datagrid>
      </List>
    </Box>
  )
}

export default AddressList
