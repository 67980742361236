import CustomOfferIcon from '@material-ui/icons/Collections'
import CustomOfferList from './CustomOfferList'
import CustomOfferEdit from './CustomOfferEdit'
import CustomOfferCreate from './CustomOfferCreate'
// import CustomOfferShow from './CustomOfferShow'

export default {
  list: CustomOfferList,
  create: CustomOfferCreate,
  edit: CustomOfferEdit,
  icon: CustomOfferIcon,
  // show: CustomOfferShow,
}
