import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { useNotify, useDataProvider, FormWithRedirect } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import lodashGet from 'lodash/get'
import useRefresh from 'ra-core/esm/sideEffect/useRefresh'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import useStyles from '../styles'
import TextInput from '../../../../../components/OutlinedTextInput'

const GeneralInformation = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()
  const [editName, setEditName] = useState(false)
  const [editChineseName, setEditChineseName] = useState(false)
  const [editLegalAddress, setEditLegalAddress] = useState(false)

  // console.log("GIPROPS", props)

  const updateSupplier = async (submission) => {
    const supplierID = props.data?.supplierID
    const params = {
      id: supplierID,
    }

    if (submission.companyName) {
      params.name = submission.companyName
    }
    if (submission.chineseName) {
      params.local_name = submission.chineseName
    }
    if (submission.legalAddress) {
      params.legal_address = submission.legalAddress
    }

    // console.log(params)
    try {
      const { data } = await dataProvider.updateSupplier('supplier', {
        ...params,
      })
      if (data.id !== undefined) {
        refresh()
      }
    } catch (error) {
      // console.log(error)
      notify(
        lodashGet(
          error.graphQLErrors,
          '0.extensions.exception.errors.0.message',
          'Data update error',
        ),
        'warning',
      )
    }
  }

  return (
    <Box p="20px 0">
      <span className={classes.sectionHeader}>General Information</span>
      <Box display="flex" className={classes.generalInformationBox}>
        <Box flex={1}>
          {editName ? (
            <FormWithRedirect
              key="formName"
              {...props}
              initialValues={{ companyName: props.data?.name }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="companyName"
                  label="Company Name:"
                  cancelAction={() => setEditName(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Company Name:</span>
              <span className={classes.fieldValueEditable}>
                {props.data?.name}
                <IconButton
                  onClick={() => {
                    setEditName(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
          {editChineseName ? (
            <FormWithRedirect
              key="formName"
              {...props}
              initialValues={{ chineseName: props.data?.localName }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="chineseName"
                  label="Company Name (Chinese):"
                  cancelAction={() => setEditChineseName(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Company Name (Chinese):</span>
              <span className={classes.fieldValueEditable}>
                {props.data?.localName}
                <IconButton
                  onClick={() => {
                    setEditChineseName(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
          {editLegalAddress ? (
            <FormWithRedirect
              key="formName"
              {...props}
              initialValues={{ legalAddress: props.data?.legalAddress }}
              render={() => (
                <EditFieldForm
                  updateSupplier={updateSupplier}
                  field="legalAddress"
                  label="Legal Address:"
                  cancelAction={() => setEditLegalAddress(false)}
                />
              )}
            />
          ) : (
            <Box display="flex">
              <span className={classes.fieldLabelEditable}>Legal Address:</span>
              <span className={classes.fieldValueEditable}>
                {props.data?.legalAddress}
                <IconButton
                  onClick={() => {
                    setEditLegalAddress(true)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <BorderColorIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          )}
          {/* <Box display='flex'>
            <span className={classes.fieldLabel}>Company Name (Chinese):</span>
            <span className={classes.fieldValue}>{props.data?.localName}</span>
          </Box> */}
          {/* <Box display='flex'>
            <span className={classes.fieldLabel}>Legal Address:</span>
            <span className={classes.fieldValue}>{props.data?.legalAddress}</span>
          </Box> */}
          <Box display="flex">
            <span className={classes.fieldLabel}>Verification Link:</span>
            <span className={classes.fieldValue}>{props.data?.verificationLink}</span>
          </Box>
        </Box>
        <Box flex={1}>
          <Box display="flex">
            <span className={classes.fieldLabel}>Business License Number:</span>
            <span className={classes.fieldValue}>{props.data?.businessLicenseNumber}</span>
            {props.data?.verificationLink && props.data?.status !== 'unverified' ? (
              <img
                style={{ maxWidth: '100%', marginLeft: '10px', height: '12px', width: '12px' }}
                src={require('../../../../../assets/success.png')}
                alt=""
              />
            ) : (
              <img
                style={{ maxWidth: '100%', marginLeft: '10px', height: '12px', width: '12px' }}
                src={require('../../../../../assets/close.png')}
                alt=""
              />
            )}
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Legal Representative:</span>
            <span className={classes.fieldValue}>{props.data?.legalRepresentative}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Registered Capital:</span>
            <span className={classes.fieldValue}>{props.data?.registeredCapital}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>License Scope:</span>
            <span className={classes.fieldValue}>{props.data?.licenseScope}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const EditFieldForm = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()

  return (
    <Box display="flex">
      <TextInput
        variant="outlined"
        fullWidth
        className={classes.areaText}
        InputProps={{
          maxLength: 300,
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
          },
        }}
        source={props.field}
        label={props.label}
      />
      <IconButton
        onClick={
          () => {
            // setSaving(true)

            const formState = form.getState()

            if (formState.invalid) {
              form.submit()
            } else {
              props.updateSupplier(values)
              props.cancelAction()
            }
          }
          // setSaving(false)
        }
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CheckCircleOutlineOutlined fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          props.cancelAction()
        }}
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CancelOutlined fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default GeneralInformation
