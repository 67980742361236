import ProjectsIcon from '@material-ui/icons/Collections'
import ProjectsList from './ProjectsList'
import ProjectsEdit from './ProjectsEdit'
// import ProjectsCreate from './ProjectsCreate'
// import ProjectsShow from './ProjectsShow'

export default {
  list: ProjectsList,
  // create: ProjectsCreate,
  edit: ProjectsEdit,
  icon: ProjectsIcon,
  // show: ProjectsShow,
}
