import React, { useState, useEffect, cloneElement } from 'react'
import { Box } from '@material-ui/core'
import {
  FormWithRedirect,
  // useDataProvider,
  // useNotify,
  useCreateController,
  CreateContextProvider,
} from 'react-admin'
import Confirm from '../components/Confirm'
import FormData from './components/FormData'
// import {
//   handleSave,
//   //  validation,
//   getDataLists,
// } from '../common/utils'

const MyCreate = (props) => {
  // console.log(props)
  const createControllerProps = useCreateController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = createControllerProps
  // console.log(record)
  return (
    <CreateContextProvider value={createControllerProps}>
      <div>
        {/* <h1>{defaultTitle}</h1> */}
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </CreateContextProvider>
  )
}

const Create = (props) => {
  // const dataProvider = useDataProvider()
  // const notify = useNotify()
  const [dataLists, setDataLists] = useState([])

  // const handlerConfirm = async (record) => {
  //   console.log('REC', record)
  // }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    // getDataLists(setDataLists, dataProvider, notify, abortController)
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <MyCreate {...props}>
      <FormWithRedirect
        {...props}
        initialValues={{
          images: [],
          additional_files: [],
          // productColours: [{}],
          // colourOptions: [{}],
          // productMaterials: [{}],
          // materialOptions: [{}],
          productImprints: [],
          imprintOptions: [],
          // otherSpecifications: [],
          // product_declarations: [{}],
        }}
        render={(formProps) => (
          <Box>
            <FormData dataLists={dataLists} setDataLists={setDataLists} title="New Product" />
            <Box display="flex" justifyContent="center" alignItems="center" mt="30px">
              <Confirm
                // redirect='/search'
                // redirect="show"
                redirect="/products"
                record={formProps.record}
                basePath={formProps.basePath}
                label="SAVE"
                undoable
                invalid={formProps.invalid}
                handleSubmit={async () => {
                  //   await handleSave(record, 'save', dataProvider, notify)
                }}
                saving={formProps.saving}
                disabled={formProps.saving || !dataLists.materials}
                resource="supplier_catalog_item"
              />
            </Box>
          </Box>
        )}
      />
    </MyCreate>
  )
}

export default Create
