import TasksIcon from '@material-ui/icons/ListAlt'
import TasksList from './TasksList'
// import TasksEdit from './TasksEdit'
// import TasksCreate from './TasksCreate'
// import TasksShow from './TasksShow'

export default {
  list: TasksList,
  // create: TasksCreate,
  // edit: TasksEdit,
  icon: TasksIcon,
  // show: TasksShow,
}
