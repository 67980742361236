import React from 'react'
import { FormWithRedirect, Edit, useNotify, useDataProvider } from 'react-admin'
import { Card, CardContent } from '@material-ui/core'
import FormData from './EditFormData'
import { hasChangeValues } from '../../../utils/utils'
import CustomToolbar from '../../components/CustomToolbar'
// import SectionTitle from '../../components/SectionTitle'
import MemberList from './MemberList'
import AddressList from './AddressList'

const ClientEdit = (props) => (
  <Edit {...props} title="">
    <ClientForm title={null} />
  </Edit>
)
const ClientForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSubmit = async (record, formState) => {
    const changeRecord = hasChangeValues(record, formState.dirtyFields)

    if (formState.dirtyFields?.is_public) {
      changeRecord.is_public = record.is_public
    }
    if (formState.dirtyFields?.allow_global_notifications) {
      changeRecord.allow_global_notifications = record.allow_global_notifications
    }
    // console.log(changeRecord, record, formState)

    try {
      await dataProvider.update('organisation', {
        data: { ...changeRecord },
      })

      notify('Updated successfully', { type: 'info' })
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      {...props}
      // validate={}
      render={(formProps) => (
        <Card>
          <CardContent>
            <FormData record={formProps.record} />
            <MemberList basePath="/clients" clientId={formProps.record.id} />
            <AddressList basePath="/clients" clientId={formProps.record.id} />
          </CardContent>
          <CustomToolbar
            redirect="/clients"
            record={formProps.record}
            basePath={formProps.basePath}
            undoable
            invalid={formProps.invalid}
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            resource="organisation"
          />
        </Card>
      )}
    />
  )
}

export default ClientEdit
