import React from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  FunctionField,
  SelectInput,
  // AutocompleteInput,
  // useTranslate,
  Datagrid,
  TextField,
  NumberField,
  DateInput,
  NullableBooleanInput,
} from 'react-admin'
import { Tooltip } from '@material-ui/core'
import { getDisplayName } from '../../utils/utils'

export const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
    <ReferenceInput
      source="supplier"
      reference="suppliers"
      // sort={{ field: 'id', order: 'ASC' }}
      alwaysOn
      style={{ minWidth: '200px' }}
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="sca"
      reference="users"
      label="SCA"
      // sort={{ field: 'id', order: 'ASC' }}
      filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="category"
      reference="category"
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <DateInput
      source="date_gte"
      label="Quote date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Quote date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <NullableBooleanInput
      source="base_products"
      label="Base Products Only"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
  </Filter>
)

const ProductList = (props) => {
  // const user = JSON.parse(localStorage.getItem('user'))

  const rowStyle = (record) => ({
    backgroundColor: record?.is_catalogue ? '#ddd' : 'white',
    cursor: record?.is_catalogue ? 'default' : 'pointer',
  })

  return (
    <div>
      <List
        {...props}
        basePath={'/productDebug'}
        resource="productDebug"
        // filter={{
        // eslint-disable-next-line camelcase
        // sca_user_id: props.isManager ? null : user?.id,
        //   status: props.filter?.status ? props.filter?.status : null,
        // }}
        filters={<ProductFilter />}
        // filterDefaultValues={{ base_products: true }}
        actions={null}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid
          path="product_debug"
          rowClick={(id, resource, record) => (record?.is_catalogue ? null : 'edit')}
          rowStyle={rowStyle}
        >
          <TextField source="id" label="Id" />
          <FunctionField
            label="Product Name"
            render={(record) => {
              const name = record.name
              return name?.length > 40 ? (
                <Tooltip title={name}>
                  <span>{`${name?.substring(0, 40)}...`}</span>
                </Tooltip>
              ) : (
                name
              )
            }}
          />
          <NumberField label="Categories" source="product_categories_aggregate.aggregate.count" />
          <NumberField label="Quotes" source="product_quotes_aggregate.aggregate.count" />
          <NumberField
            sortable={false}
            label="Client Quotes"
            source="product_quotes[0].scm_quotes_aggregate.aggregate.count"
          />
          <NumberField
            label="Sourcing Requests"
            source="sourcing_requests_aggregate.aggregate.count"
          />
          <NumberField label="Carton Dims" source="carton_dimensions_id" />
          <NumberField label="Product Dims" source="product_dimensions_id" />
          <TextField source="status" label="Status" />
          <FunctionField render={(record) => getDisplayName(record.sca)} label="SCA" />
        </Datagrid>
      </List>
    </div>
  )
}

export default ProductList
