import gql from 'graphql-tag'

export const offerGetQuery = `
  id
  created_at
  status
  pieces_per_carton
  cartons_per_pallet
  cartons_per_container_20ft
  cartons_per_container_40ft
  cartons_per_container_40ft_hc
  allow_order_splitting_by_size
  packaging_type
  retail_price
  duty_rate
  logistics_estimate
  inspection_estimate
  payment_processing_fees
  supply_chain_fees
  bulk_software_fees
  bulk_commission_fees
  bulk_partner_fees
  bulk_royalties_fees
  supply_chain_fees_destination
  quality_fees_destination
  bulk_payment_fees_destination
  bulk_software_fees_destination
  bulk_commission_fees_destination
  bulk_partner_fees_destination
  bulk_royalties_fees_destination
  international_freight_fees_destination
  moq
  notes
  freight_method
  incoterms
  consignee_id
  destination_address_id
  estimated_ROI
  hts_code
  inspection_level
  inspection_type
  inspection_work_days
  investor_price_per_piece
  quality_agency_id
  logistics_agency_id
  logistics_disclaimer
  logistics_lead_time
  logistics_lead_time_to_port
  logistics_lead_time_to_warehouse
  logistics_quote_valid_date
  logistics_tracking_number
  logistics_tracking_url
  logistics_carrier
  logistics_tracking_number
  logistics_tracking_url
  logistics_carrier
  long_description
  manufacturing_lead_time
  minimum_purchase_value
  minimum_purchase_quantity
  supplier_pickup_address_id
  supplier_contact_id
  supplier_factory_address_id
  saleable_product_id
  sku
  short_description
  sca_id
  shipper_id
  type
  warranty
  upc_code
  total_sold
  group_purchase_price
  saleable_product {
    id
    consumer_service_fees
    consumer_royalties_fees
    marketing_fees
    fulfillment_fees
    commission_fees
    consumer_software_fees
    consumer_partner_fees
    consumer_payment_fees
    retail_price
    fob_price
    dropshipping_estimate
  }
  product_dimensions {
    id
    length
    height
    gross_weight
    width
  }
  packaging_dimensions {
    id
    length
    height
    gross_weight
    width
  }
  carton_dimensions {
    id
    length
    height
    width
    gross_weight
  }
  saleable_products {
    id
  }
  sca {
    id
    surname
    forename
    email
    username
  }
  name
  thumbnail {
    id
    filename
    url
  }
  primary_photo {
    id
    filename
    url
  }
  client_quote {
    id
    warranty_term
    warranty_conditions
    product_quote {
      id
      quantity
      price
      valid_date
      sample_cost
      custom_sample_cost
      sample_lead_time
      tooling_cost
      other_requirements
      prices (order_by: {quantity: asc_nulls_last}) {
        id
        quantity
        unit_price
        lead_time
        est_landed_cost_air
      est_landed_cost_sea
      total_cbm
      total_cartons
      total_duties
      shipment_weight
      }
      product {
        id
        materials {
          id
          type
          name
          vendor
          vendor_code
          unit_of_measurement
          quantity_per_piece
          notes
          estimated_cost
          colours {
            id
            reference
            colour
          }
        }
        product_declarations {
          id
          declaration
          file {
            id
            filename
            url
          }
        }
      }
      supplier {
        id
        name
        sca_user {
          id
          forename
          surname
          email
          username
        }
        bank_detail {
          id
          beneficiary_address_id
          beneficiary_name
          branch_address
          branch_name
          clearing_code
          currency
          bank_id
          account_number
          swift_code
          tax_number
        }
      }
      lead_time
      certificates {
        id
        certification {
          id
          name
          type
          notifying_body
          number
          valid_end_date
          valid_start_date
          document {
            id
            filename
            url
          }
        }
      }
      quote {
        id
        filename
        url
      }
    }
  }
  platforms {
    id
    platform
  }
  batches {
    id
    quantity
    stock
    saleable_product_variant {
      id
      stock
      active
      always_in_stock
      list_weighting
      image_file_id
      image {
        id
        filename
        url
      }
      variant {
        id
        sku
        name
        values {
          id
          value
          option_name
          option_id
        }
      }
    }
  }
  orders: offer_purchases_aggregate (where: { status:  {_in: [PAYMENT_RECEIVED]}}) {
    aggregate {
      count
    }
  }
  purchased: total_sold
  stock: batches_aggregate {
    aggregate {
      sum {
        stock
      }
    }
  }
  offer_purchases (order_by: {id: desc_nulls_last}) {
    id
    amount
    quantity
    status
    created_at
    brand
    required_delivery_date
    client_price
    client_quantity
    client_sku
    purchase_order_number
    purchase_order_date
    purchase_order_file {
      id
      filename
      url
    }
    client {
      id
      name
      stripe_connected_account_id
      stripe_customer_account_id
    }
    buyer {
      id
      forename
      surname
      email
    }
    offer_purchase_invoices {
      id
      invoice {
        id
        fulfilled_date
        issue_date
        status
        subject
        amount_due
        invoice_payments {
          id
          payer_id
          payment_intent_id
          amount
          status
          created_at
          payer {
            id
            forename
            surname
            email
          }
        }
      }
    }
  }
  inspection_checklist {
    id
    filename
    url
  }
  inspection_quote_file {
    id
    filename
    url
  }
  logistics_quote_file {
    id
    filename
    url
  }
  upc_code_photo {
    id
    filename
    url
  }
  offer_categories (where:{deleted_at: {_is_null: true}}) {
    id
    category {
      id
      name
    }
  }
  offer_features (where:{deleted_at: {_is_null: true}}) {
    id
    feature {
      id
      name
      value
    }
  }
  offer_files (where:{deleted_at: {_is_null: true}}) {
    id
    type
    file {
      id
      filename
      url
    }
  }
  offer_payment_terms (where:{deleted_at: {_is_null: true}}) {
    id
    milestone
    type
    value
    types {
      type
    }
  }
  offer_tags (where:{deleted_at: {_is_null: true}}) {
    id
    tag {
      id
      name
    }
  }
`

export const offerCreateTransfer = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            mutation createTransfer {
              createTransfer(
                args: {
                  ${jsonParams}
                }
            ) {
                stripeTransferId
                transferId
              }
            }
        `,
    parseResponse: ({ data, errors }) => ({
      data: data.createTransfer,
      errors,
    }),
  }
}

export const offerStatusUpdate = ({ context, params }) => {
  // console.log('UPDATINGOFFER', params)
  // console.log(`mutation {
  //   update_offer_by_pk(pk_columns: {id: ${params.id}},
  //    _set: {status: ${params.status.toUpperCase()}
  //    }
  //   ) {
  //     id
  //   }
  // }`)
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            mutation {
              update_offer_by_pk(pk_columns: {id: ${params.id}},
              _set: {status: ${params.status.toUpperCase()}}
              ) {
                id
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.update_offer_by_pk,
    }),
  }
}

export const offerDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation deleteOffer {
            delete_offer_transfer(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_offer_file(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_invoice_payment(where: {invoice: {offer_purchase_invoices: {offer_purchase: {offer_id: {_eq: ${params.id}}}}}}) {
              affected_rows
            }
            delete_offer_purchase_invoice(where: {offer_purchase: {offer_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_invoice(where: {offer_purchase_invoices: {offer_purchase: {offer_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_offer_purchase_items(where: {offer_purchase: {offer_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_offer_purchase(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_offer_specifications(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_offer_categories(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
              delete_offer_payment_terms(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_offer_tracking_event(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_offer_tags(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_offer_features(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_organisation_inventory(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_organisation_offers(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_saleable_product(where: {offer_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_saleable_product_sale(where: {saleable_product: {offer_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_saleable_product_sale_transfer(where: {saleable_product_sale: {saleable_product: {offer_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_saleable_product_seller(where: {saleable_product: {offer_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_saleable_product_sale_allocation(where: {saleable_product_sale: {saleable_product: {offer_id: {_eq: ${params.id}}}}}) {
              affected_rows
            }
            delete_offer(where: {id: {_eq: ${params.id}}}) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const offerGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          query getOffer {
            offer_by_pk(id: ${params.id}) {
              ${offerGetQuery}
            }
          }
        `,
    parseResponse: ({ data }) => {
      if (data && data.offer_by_pk) {
        // console.log("data", data)
        return {
          data: data.offer_by_pk,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const offerList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  const variables = {}
  const offerStatus = params.filter?.status ? params.filter?.status?.replace(/"\^|\^"/g, '') : null
  // const offerType = params.filter?.type ? params.filter?.type?.replace(/"\^|\^"/g, '') : null

  // console.log(params)
  if (
    params.filter.status ||
    params.id ||
    params.filter.type ||
    params.filter.saleable_product_id
  ) {
    variables.where = {
      ...(params.filter.saleable_product_id && {
        saleable_product_id: { _eq: params.filter.saleable_product_id },
      }),
      ...(params.filter.id && { id: { _eq: params.filter.id } }),
      ...(offerStatus && { status: { _eq: offerStatus } }),
      ...(params.filter.type && typeof params.filter.type === 'string'
        ? { type: { _eq: params.filter.type } }
        : { type: { _in: params.filter.type } }),
    }
  }
  // const validUntil = new Date()
  // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables,
    query: gql`
          query getOffers ($where: offer_bool_exp) {
           offer(where: $where, limit: ${
             params.pagination.perPage
           }, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              ${offerGetQuery}
            }
            offer_aggregate (where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => {
      if (data && data.offer) {
        // console.log("data", data)
        return {
          data: data.offer,
          total: data.offer_aggregate.aggregate.count,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const offerPaymentIntent = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
        mutation getPaymentIntent {
          createPaymentIntent(
            args: {
              ${jsonParams}
            }
        ) {
            offerPurchaseId
            customerId
            ephemeralKey
            paymentIntentId
            paymentIntentSecret
            publishableKey
          }
        }
      `,
    parseResponse: ({ data, errors }) => ({
      data: data.createPaymentIntent,
      errors,
    }),
  }
}

export const createOfferPurchaseOrder = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation createOfferPurchase(
        $notes: String
        $platform: String!
        $offerId: Int!
        $quantity: Int!
        $deliveryAddress: String!
        $contactName: String!
        $contactEmail: String!
        $contactPhone: String!
        $buyerId: Int!
      ) {
        createOfferPurchase(
          arg1: {
            notes: $notes
            platform: $platform
            buyerId: $buyerId
            contactName: $contactName
            contactEmail: $contactEmail
            contactPhone: $contactPhone
            deliveryAddress: $deliveryAddress
            items: { offerId: $offerId, variants: { quantity: $quantity } }
          }
        ) {
          offerPurchaseIds
          orderId
          paymentIntentSecret
          publishableKey
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.createOfferPurchase,
      errors,
    }),
  }
}

export const createOfferConsumerOrder = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation createOfferPurchase(
        $notes: String
        $platform: String!
        $deliveryAddress: String!
        $contactName: String!
        $contactEmail: String!
        $contactPhone: String!
        $buyerId: Int!
        $items: [Item]
      ) {
        createOfferPurchase(
          arg1: {
            notes: $notes
            platform: $platform
            buyerId: $buyerId
            contactName: $contactName
            contactEmail: $contactEmail
            contactPhone: $contactPhone
            deliveryAddress: $deliveryAddress
            items: $items
          }
        ) {
          offerPurchaseIds
          orderId
          clientSecret
          publishableKey
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data: data.createOfferPurchase,
      errors,
    }),
  }
}

export const offerCreate = ({ context, params }) => {
  // console.log(params)
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  // console.log(`
  //     mutation {
  //       insert_offer(
  //         ${jsonParams}
  //       )
  //       {
  //         returning {
  //           id
  //           sca_id
  //         }
  //       }
  //     }
  //   `)
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
  mutation {
    insert_offer(
        ${jsonParams}
    ) {
      returning {
        id
        sca_id
        status
        primary_photo_id
        primary_photo {
          id
          filename
          url
        }
        thumbnail_photo_id
        thumbnail {
          id
          filename
          url
        }
        logistics_quote_file_id
        logistics_quote_file {
          id
          filename
          url
        }
        inspection_quote_file_id
        inspection_quote_file {
          id
          filename
          url
        }
        inspection_checklist_file_id
        inspection_checklist {
          id
          filename
          url
        }
        quote {
          id
          quote_file_id
          quote {
            id
            filename
            url
          }
        }
        offer_files {
          id
          file {
            id
            filename
            url
          }
        }
        offer_purchases {
          id
          purchase_order_file_id
          purchase_order_file {
            id
            filename
            url
          }
        }
      }
    }
  }
`,
    parseResponse: ({ data, errors }) => ({
      data: data.insert_offer?.returning[0],
      errors,
    }),
  }
}
