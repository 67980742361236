import React, { useState, useEffect } from 'react'
import {
  FormWithRedirect,
  useRedirect,
  useNotify,
  useDataProvider,
  required,
  DateInput,
  AutocompleteArrayInput,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  CheckboxGroupInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import {
  Box,
  Card,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import { useFormState, useForm } from 'react-final-form'
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse'
import Confirm from '../../components/Confirm'
import NumberFormat from '../../components/CustomNumberFormat'
import { ColorInput } from '../../components/ColourPicker'
import SupplierSelectWithCreate from '../../components/SupplierSelectWithCreate'
import ChipInput from '../../../components/input/ChipInput'
import MultiplePhotoSelect from './MultiplePhotoSelect'
import DimensionsTable from './DimensionsTable'
import FactoryQuoteTable from './FactoryQuoteTable'
import useStyles from '../styles/importerStyles'

const StyledSimpleFormIterator = withStyles(() => ({
  root: {
    display: 'inline-flex',
  },
}))(SimpleFormIterator)

const ProductImport = (props) => {
  const [data, setData] = useState()
  const [dataLists, setDataLists] = useState({})
  const [saving, setSaving] = useState(0)

  const sourcing_request_id = parseInt(props.match?.params?.id) || null

  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  //   const identity = useGetIdentity()

  const handleSaveBulk = async ({ submission }) => {
    // console.log('SUBMISSION', submission)

    // console.log('TOTAL PRODUCTS:', submission.data?.length)

    await Promise.all(
      submission.data?.map(async (product) => {
        const params = {}

        submission.sourcing_request_id
          ? (params.sourcing_requests = {
              data: { sourcing_request_id: submission.sourcing_request_id },
            })
          : null

        params.name = product.Product_Name
        product.Brand ? (params.brand = product.Brand) : null
        product.Model_Num ? (params.catalogue_sku = product.Model_Num) : null
        params.short_description = product.Short_Description
        params.is_catalogue = product.other.some((item) => item === 'isCatalogue')
        product?.primary_photo ? (params.primary_photo = { data: product.primary_photo }) : null
        product?.thumbnail_photo
          ? (params.thumbnail_photo = { data: product.thumbnail_photo })
          : null
        product.dutyRate ? (params.duty_rate = Math.round(product.dutyRate * 100)) : null

        product.HTS_Code ? (params.hts_code = product.HTS_Code) : null

        params.product_dimensions = {
          data: {
            ...(product.productDimensions.length && {
              length: Math.round(product.productDimensions.length * 100),
            }),
            ...(product.productDimensions.width && {
              width: Math.round(product.productDimensions.width * 100),
            }),
            ...(product.productDimensions.height && {
              height: Math.round(product.productDimensions.height * 100),
            }),
            ...(product.productDimensions.gross_weight && {
              gross_weight: Math.round(product.productDimensions.gross_weight * 100),
            }),
          },
        }

        params.packaging_dimensions = {
          data: {
            ...(product.packagingDimensions.length && {
              length: Math.round(product.packagingDimensions.length * 100),
            }),
            ...(product.packagingDimensions.width && {
              width: Math.round(product.packagingDimensions.width * 100),
            }),
            ...(product.packagingDimensions.height && {
              height: Math.round(product.packagingDimensions.height * 100),
            }),
            ...(product.packagingDimensions.gross_weight && {
              gross_weight: Math.round(product.packagingDimensions.gross_weight * 100),
            }),
          },
        }

        params.carton_dimensions = {
          data: {
            ...(product.cartonDimensions.length && {
              length: Math.round(product.cartonDimensions.length * 100),
            }),
            ...(product.cartonDimensions.width && {
              width: Math.round(product.cartonDimensions.width * 100),
            }),
            ...(product.cartonDimensions.height && {
              height: Math.round(product.cartonDimensions.height * 100),
            }),
            ...(product.cartonDimensions.gross_weight && {
              gross_weight: Math.round(product.cartonDimensions.gross_weight * 100),
            }),
          },
        }

        if (
          params.product_dimensions?.data &&
          Object.keys(params.product_dimensions?.data).length === 0
        ) {
          delete params.product_dimensions
        }
        if (
          params.packaging_dimensions?.data &&
          Object.keys(params.packaging_dimensions?.data).length === 0
        ) {
          delete params.packaging_dimensions
        }
        if (
          params.carton_dimensions?.data &&
          Object.keys(params.carton_dimensions?.data).length === 0
        ) {
          delete params.carton_dimensions
        }

        const prices = []

        product.prices.map((item) => {
          // console.log(item)
          if (item.quantity && item.lead_time && item.price) {
            prices.push({
              unit_price: Math.round(item.price * 100),
              lead_time: item.lead_time,
              quantity: item.quantity,
            })
          }
        })

        params.product_quotes = {
          data: [
            {
              ...(product.valid_date && { valid_date: product.valid_date }),
              ...(product.packagingType && { packaging_type: product.packagingType }),
              ...(product.piecesPerCarton && { pieces_per_carton: product.piecesPerCarton }),
              ...(product.toolingCost && { tooling_cost: Math.round(product.toolingCost * 100) }),
              ...(product.sampleCost && { sample_cost: Math.round(product.sampleCost * 100) }),
              ...(product.sampleLeadTime && { sample_lead_time: product.sampleLeadTime }),
              allow_order_splitting_by_size: product.other.some((item) => item === 'isMultiSizePO'),
              supplier_id: product.supplierId,
              components: {
                data: [
                  {
                    name: 'Base',
                  },
                ],
              },
              ...(prices.length > 0 && {
                prices: {
                  data: prices,
                },
              }),
            },
          ],
        }

        product.categoryIds?.length > 0
          ? (params.product_categories = {
              data: product.categoryIds.map((cat) => ({ category_id: cat })),
            })
          : null

        product.materialIds?.length > 0
          ? (params.product_specifications = {
              data: product.materialIds.map((mat) => {
                const name = dataLists.materials.find((item) => item.id === mat).name
                return {
                  option_value: {
                    data: {
                      value: name,
                      option: {
                        data: { name: 'Material' },
                        on_conflict: {
                          constraint: 'option_name_key',
                          update_columns: 'name',
                        },
                      },
                    },
                    on_conflict: {
                      constraint: 'option_value_option_id_value_key',
                      update_columns: ['value', 'option_id'],
                    },
                  },
                }
              }),
            })
          : null

        product.productColours?.length > 0 || product.sizes?.length > 0
          ? (params.product_options = {
              data: [
                ...product.productColours.map((colour) => ({
                  option_value: {
                    data: {
                      value: colour.value,
                      option: {
                        data: { name: 'Colour' },
                        on_conflict: {
                          constraint: 'option_name_key',
                          update_columns: 'name',
                        },
                      },
                    },
                    on_conflict: {
                      constraint: 'option_value_option_id_value_key',
                      update_columns: ['value', 'option_id'],
                    },
                  },
                })),
                ...product.sizes.map((size) => ({
                  option_value: {
                    data: {
                      value: size,
                      option: {
                        data: { name: 'Size' },
                        on_conflict: {
                          constraint: 'option_name_key',
                          update_columns: 'name',
                        },
                      },
                    },
                    on_conflict: {
                      constraint: 'option_value_option_id_value_key',
                      update_columns: ['value', 'option_id'],
                    },
                  },
                })),
              ],
            })
          : null

        product.images?.length > 0 ? (params.additional_files = submission.images) : null

        // console.log('FINALPARAMS', params)

        try {
          await dataProvider.create('product', { data: params })

          // console.log('RESP', resp)
          setSaving((prevState) => (prevState += 1))
        } catch (error) {
          console.log(error)
          notify(error.message.split('.')[0], { type: 'warning' })
        }
      }),
    )

    // console.log('SAVINGCOMPLETED')
    submission.sourcing_request_id
      ? redirect(`/sourcingRequests/${sourcing_request_id}`)
      : redirect('/products')
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const getDataLists = async () => {
      try {
        const listsTemp = {}

        const { data: imprintsTemp } = await dataProvider.getList('imprints', {
          filter: { own: 'imprintTechnique' },
        })
        listsTemp.imprints = imprintsTemp

        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        listsTemp.packagingTypes = packagingTypesTemp

        const { data: suppliersTemp } = await dataProvider.getList('supplier', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
        listsTemp.suppliers = suppliersTemp

        const { data: categoriesTemp } = await dataProvider.getList('categories', {})
        listsTemp.categories = categoriesTemp

        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        listsTemp.materials = materialsTemp

        const { data: clientsTemp } = await dataProvider.getList('app_user', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
          filter: {},
          clientsOnly: true,
        })
        listsTemp.clients = clientsTemp

        if (!abortController.signal.aborted) {
          setDataLists(listsTemp)
        }
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
      }
    }

    getDataLists()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Box>
      {dataLists.materials?.length > 0 ? (
        <CSVReader setData={setData} dataLists={dataLists} />
      ) : null}
      {data ? <Button onClick={() => setData(null)}>Clear</Button> : null}
      {data ? (
        <FormWithRedirect
          //   validate={validation}
          // redirect="/customOffer"
          initialValues={{ data: data, sourcing_request_id }}
          redirect={null}
          render={(formProps) => (
            <div>
              <FormData
                dataLists={dataLists || {}}
                formProps={formProps}
                data={data}
                //   handleSave={handleSave}
              />
              {saving > 0 ? <span>Saving...</span> : null}
              <ReferenceInput
                source="sourcing_request_id"
                reference="sourcingRequests"
                pagination={{ page: 1, perPage: 25 }}
                sort={{ field: 'id', order: 'DESC' }}
                alwaysOn
                style={{ minWidth: '200px' }}
                variant="standard"
              >
                <AutocompleteInput
                  allowEmpty
                  optionText={(record) =>
                    `${record?.id}. ${record?.name} - ${record?.client?.name}`
                  }
                  // fullWidth
                />
              </ReferenceInput>
              <Confirm
                //   redirect="/products"
                redirect={null}
                record={formProps.record}
                basePath={formProps.basePath}
                label="SAVE"
                undoable
                invalid={formProps.invalid}
                handleSubmit={async (submission) => {
                  setSaving(submission.data?.length)
                  await handleSaveBulk({
                    submission,
                  })
                  setSaving(0)
                }}
                saving={formProps.saving}
                disabled={formProps.invalid || formProps.saving}
                resource="product"
              />
            </div>
          )}
        />
      ) : null}
    </Box>
  )
}

const CSVReader = ({ setData, dataLists }) => {
  const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
  const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40)
  const classes = useStyles()
  const { CSVReader } = useCSVReader()
  const [zoneHover, setZoneHover] = useState(false)
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR)

  const parseHeader = (props) => {
    // console.log(props)
    const header = props
      .replaceAll(' ', '_')
      .replaceAll('#', 'Num')
      .replaceAll('/', '')
      .replaceAll('-', '_')

    return header
  }

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        const products = []
        // console.log('---------------------------')
        // console.log(results)
        // console.log('---------------------------')
        results.data?.shift()
        results.data?.map((item) => {
          // console.log('ITEM', item)

          if (!item.Supplier || !item.Product_Name) {
            // console.log('empty')
            item = {}
            return {}
          }

          // console.log('DATALISTS', dataLists)

          item.images = []

          const mats = item.Materials?.split(',')
          // console.log(mats?.length, mats)
          item.invalidFields = []

          item.materialIds = []
          if (mats[0] !== '') {
            mats.map((material) => {
              const id = dataLists.materials?.find((mat) => mat.name === material)?.id
              if (id) {
                item.materialIds.push(id)
              } else {
                item.invalidFields.push({ field: 'Material', value: material })
              }
            })
          }
          item.categoryIds = []

          if (item.category) {
            const catId = dataLists.categories.find((cat) => cat.name === item.Category)?.id
            if (catId) {
              item.categoryIds.push(catId)
            } else {
              item.invalidFields.push({ field: 'Category', value: item.Category })
            }
          }

          if (item.Pkg_type) {
            const pkgType = dataLists.packagingTypes.find(
              (type) => type.name === item.Pkg_type || type.value === item.Pkg_type,
            )
            if (pkgType) {
              item.packagingType = pkgType.value
            } else {
              item.invalidFields.push({ field: 'Pkg Type', value: item.Pkg_type })
            }
          }

          const supplierId = dataLists.suppliers.find(
            (supplier) => supplier.name === item.Supplier,
          )?.id
          if (supplierId) {
            item.supplierId = supplierId
          } else {
            item.invalidFields.push({ field: 'Supplier', value: item.Supplier })
          }

          item.productColours = item.Colors?.split(',').map((val) => ({ value: val.trim() }))

          item.sizes =
            item.Sizes?.split(',')[0] !== '' ? item.Sizes?.split(',').map((val) => val.trim()) : []

          item.piecesPerCarton = parseInt(item.Qty_Carton)

          item.dutyRate = Number(item.Duty_Rate) || null
          item.sampleCost = Number(item.Sample_Cost) || null
          item.sampleLeadTime = parseInt(item.Sample_Lead_time) || null
          item.toolingCost = Number(item.Tooling_Cost) || null

          item.prices = [
            {
              price: Number(item.Unit_Price_1),
              quantity: parseInt(item.Quantity_1),
              lead_time: parseInt(item.Lead_Time_1),
            },
            {
              price: Number(item.Unit_Price_2),
              quantity: parseInt(item.Quantity_2),
              lead_time: parseInt(item.Lead_Time_2),
            },
            {
              price: Number(item.Unit_Price_3),
              quantity: parseInt(item.Quantity_3),
              lead_time: parseInt(item.Lead_Time_3),
            },
          ]

          item.productDimensions = {
            length: Number(item.Prod_L) || null,
            width: Number(item.Prod_W) || null,
            height: Number(item.Prod_H) || null,
            gross_weight: Number(item.Prod_Wg) || null,
          }
          item.packagingDimensions = {
            length: Number(item.Pkg_L) || null,
            width: Number(item.Pkg_W) || null,
            height: Number(item.Pkg_H) || null,
            gross_weight: Number(item.Pkg_Wg) || null,
          }
          item.cartonDimensions = {
            length: Number(item.Ctn_L) || null,
            width: Number(item.Ctn_W) || null,
            height: Number(item.Ctn_H) || null,
            gross_weight: Number(item.Ctn_Wg) || null,
          }

          item.other = []
          if (item.Catalog_product === 'Y' || item.Catalog_product === 'Yes') {
            item.other.push('isCatalogue')
          }
          if (item.Multi_size_PO === 'Y' || item.Multi_size_PO === 'Yes') {
            item.other.push('isMultiSizePO')
          }

          products.push(item)
        })

        // console.log('RESULTS', results)

        setZoneHover(false)
        setData(products)
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault()
        setZoneHover(true)
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault()
        setZoneHover(false)
      }}
      config={{
        header: true,
        transformHeader: parseHeader,
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
        <>
          <div {...getRootProps()} className={`${classes.zone} ${zoneHover && classes.zoneHover})`}>
            {acceptedFile ? (
              <>
                <div className={classes.file}>
                  <div className={classes.info}>
                    <span className={classes.size}>{formatFileSize(acceptedFile.size)}</span>
                    <span className={classes.name}>{acceptedFile.name}</span>
                  </div>
                  <span className={classes.name}>
                    Each row must have a Supplier and Product Name or it will not load
                  </span>

                  <div className={classes.progressBar}>
                    <ProgressBar />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    className={classes.remove}
                    onMouseOver={(event: Event) => {
                      event.preventDefault()
                      setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                    }}
                    onMouseOut={(event: Event) => {
                      event.preventDefault()
                      setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                    }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                </div>
              </>
            ) : (
              'Drop CSV file here or click to upload'
            )}
          </div>
        </>
      )}
    </CSVReader>
  )
}

const FormData = ({ data, dataLists }) => {
  useEffect(() => {
    return () => {}
  }, [])

  const { values } = useFormState()

  // console.log('FORMVALS', values)

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.images?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  return (
    <div>
      {data.map((item, index) => (
        <ProductCard
          key={`prodCard${index}`}
          item={item}
          values={values}
          index={index}
          dataLists={dataLists}
        />
      ))}
    </div>
  )
}

const ProductCard = ({ values, index, dataLists = {}, setDataLists }) => {
  const classes = useStyles()
  const form = useForm()
  const [accordianOpen, setAccordianOpen] = useState(false)

  // console.log(
  //   values,
  //   values.data?.length,
  //   index,
  //   values.data?.length === index,
  //   values.data?.length >= index,
  // )

  if (values.data?.length <= index) return null

  return (
    <Accordion
      key={`prod${index}`}
      expanded={accordianOpen}
      onChange={() => setAccordianOpen(!accordianOpen)}
      //   className={classes.accordionStyle}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Box mr="10px" style={{ width: '100%' }} display="flex" justifyContent="space-between">
          <Typography className={classes.sectionHeader}>
            {`Product ${index + 1} - ${values.data[index]?.Product_Name}`}
          </Typography>
          <Box>
            {!values.data[index].supplierId || !values.data[index].Product_Name ? (
              <span style={{ fontSize: '0.65rem', marginRight: '20px', color: 'red' }}>
                Invalid!
              </span>
            ) : null}
            <span style={{ fontSize: '0.65rem' }}>
              Unknown Fields: {values.data[index].invalidFields?.length}
            </span>
            <IconButton
              aria-label="delete"
              className={classes.deleteAddBtn}
              onClick={(e) => {
                e.stopPropagation()
                const newValues = [...values.data]
                // console.log(newValues)
                newValues.splice(index, 1)
                // console.log(newValues)
                form.change('data', newValues)
                // form.change(`data[${ind}].deleted_at`, currentTime)
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Card style={{ width: '100%' }}>
          {/* <span>{item.Product_Name}</span> */}
          <Box display="flex">
            <Box flex={2} p="0px 30px">
              <MultiplePhotoSelect id={index} values={values} />
              <SupplierSelectWithCreate
                options={dataLists.suppliers || []}
                setOptions={(newOptions) => {
                  const newLists = { ...dataLists }
                  newLists.suppliers = newOptions
                  setDataLists(newLists)
                }}
                validate={required()}
                label="Supplier"
                optionText={(value) => {
                  return value?.official_verification_link
                    ? value?.name
                    : `${value?.name} (Unverified)`
                }}
                source={`data[${index}].supplierId`}
              />
              <DateInput
                source={`data[${index}].valid_date`}
                label="Quote valid until"
                // fullWidth
                variant="standard"
                margin="none"
                className={classes.fieldInputLeft}
                style={{ minWidth: '120px' }}
              />
              <CheckboxGroupInput
                label="Other"
                source={`data[${index}].other`}
                choices={[
                  { id: 'isCatalogue', name: 'Catalogue' },
                  { id: 'isMultiSizePO', name: 'Multi-Size PO' },
                ]}
              />
            </Box>

            <Box flex={3} p="0px 30px">
              <Box display="flex">
                <TextInput
                  variant="standard"
                  // fullWidth
                  source={`data[${index}].Product_Name`}
                  label="Product name"
                  validate={required()}
                />
                <TextInput
                  variant="standard"
                  // fullWidth
                  source={`data[${index}].Model_Num`}
                  label="Model #"
                  // validate={required()}
                  style={{ marginLeft: '20px' }}
                />
                <TextInput
                  variant="standard"
                  // fullWidth
                  source={`data[${index}].Brand`}
                  label="Brand"
                  // validate={required()}
                  style={{ marginLeft: '20px' }}
                />
              </Box>
              <TextInput
                variant="standard"
                // fullWidth
                source={`data[${index}].Short_Description`}
                label="Short description"
                // validate={required()}
                style={{ marginLeft: '20px' }}
              />
              <Box display="flex">
                <div>
                  <NumberFormat
                    label="Sample Cost"
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    source={`data[${index}].sampleCost`}
                    // validate={required()}
                    prefix="$ "
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <NumberFormat
                    label="Sample Lead Time"
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    isNumericString
                    source={`data[${index}].sampleLeadTime`}
                    // validate={required()}
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <NumberFormat
                    label="Tooling Cost"
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    source={`data[${index}].toolingCost`}
                    // validate={required()}
                  />
                </div>
              </Box>
              <Box display="flex">
                <div>
                  <NumberFormat
                    label="Duty Rate"
                    className={classes.numberTableInput}
                    displayType="input"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    source={`data[${index}].dutyRate`}
                    // validate={required()}
                    suffix=" %"
                  />
                </div>
                <TextInput
                  variant="standard"
                  // fullWidth
                  source={`data[${index}].HTS_Code`}
                  label="HTS Code"
                  //   validate={required()}
                  style={{ marginLeft: '10px' }}
                />
              </Box>
              {/* <span className={classes.productSKU}>124-373</span> */}
              {dataLists.categories?.length > 0 ? (
                <AutocompleteArrayInput
                  source={`data[${index}].categoryIds`}
                  choices={dataLists.categories || []}
                  variant="standard"
                  label="Categories"
                />
              ) : (
                <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
              )}
              <ArrayInput source={`data[${index}].productColours`} label="Product colours">
                <StyledSimpleFormIterator
                  TransitionProps={{ enter: false, exit: false }}
                  addButton={
                    <Button
                      style={{
                        width: '100px',
                        backgroundColor: '#F8F8FA',
                        color: 'black',
                        marginTop: '-10px',
                      }}
                    >
                      <AddIcon />
                      ADD COLOR
                    </Button>
                  }
                  removeButton={
                    <DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />
                  }
                >
                  <FormDataConsumer>
                    {({ getSource, scopedFormData, rest }) => (
                      <Box flex={1}>
                        <ColorInput
                          source={getSource('value')}
                          {...rest}
                          record={scopedFormData}
                          className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                          picker="Sketch"
                          label="Color"
                          // validate={required()}
                        />
                      </Box>
                    )}
                  </FormDataConsumer>
                </StyledSimpleFormIterator>
              </ArrayInput>
              {dataLists?.materials?.length > 0 ? (
                <AutocompleteArrayInput
                  source={`data[${index}].materialIds`}
                  choices={dataLists.materials || []}
                  variant="standard"
                  label="Materials"
                />
              ) : (
                <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
              )}
              <ChipInput
                source={`data[${index}].sizes`}
                className={`${classes.fieldInputLeft}`}
                style={{ minWidth: '100%' }}
                variant="standard"
                margin="none"
                label="Sizes"
              />
            </Box>
            <Box flex={3} p="0px 30px" display="flex" flexDirection="column">
              <span style={{ fontSize: '14px' }}>Factory Quote</span>

              <FactoryQuoteTable prices={values.data[index].prices} productIndex={index} />

              <span style={{ fontSize: '14px', marginTop: '20px' }}>Product Dimensions</span>
              <DimensionsTable metric source={`data[${index}].productDimensions`} />
              <Box display="flex">
                <span style={{ fontSize: '14px', marginTop: '20px', marginRight: '30px' }}>
                  Packaging Dimensions
                </span>
                {dataLists?.packagingTypes?.length > 0 ? (
                  <SelectInput
                    label="Packaging type"
                    source={`data[${index}].packagingType`}
                    choices={dataLists?.packagingTypes}
                    className={classes.fieldInputNoMP}
                    optionText="name"
                    optionValue="value"
                    defaultValue=""
                    variant="standard"
                  />
                ) : (
                  <Skeleton style={{ width: '120px', height: '62px' }} animation="wave" />
                )}
              </Box>
              <DimensionsTable metric source={`data[${index}].packagingDimensions`} />
              <Box display="flex">
                <span style={{ fontSize: '14px', marginTop: '20px' }}>Carton Dimensions</span>
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={0}
                  fixedDecimalScale
                  isNumericString
                  source={`data[${index}].piecesPerCarton`}
                  // validate={required()}
                />
              </Box>
              <DimensionsTable metric source={`data[${index}].cartonDimensions`} />
            </Box>
            {values.data[index].invalidFields?.length > 0 ? (
              <Box flex={1}>
                <span>Unknown fields</span>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ maxHeight: '50px', overflowY: 'auto' }}
                >
                  {values.data[index].invalidFields?.map((item) => (
                    <span
                      style={{ fontSize: '10px' }}
                      key={`${item.field}${item.value}`}
                    >{`${item.field} - ${item.value}`}</span>
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProductImport
