import React from 'react'
import {
  FormWithRedirect,
  Create,
  Edit,
  useNotify,
  useDataProvider,
  TextInput,
  required,
} from 'react-admin'
import { Card, Box } from '@material-ui/core'
import Confirm from '../../components/Confirm'

export const FieldCreate = (props) => (
  <Create {...props}>
    <FormData params={props} />
  </Create>
)

export const FieldEdit = (props) => (
  <Edit {...props}>
    <FormData params={props} />
  </Edit>
)

const FormData = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(props)
  const handleSubmit = async (record) => {
    console.log(record)
    try {
      await dataProvider.create('system_dictionary', {
        data: {
          ...record,
          own: props.resource === 'material' ? record.own : props.resource,
        },
      })
      notify('Created Successfully.', { type: 'info' })
    } catch (error) {
      // console.log(error)
      notify(error.message, 'warning')
    }
  }

  return (
    <FormWithRedirect
      {...props}
      // validate={(values) => {
      //   const errors = {}
      //   if (values.name?.length > 16) {
      //     errors.name = 'Must be 16 characters or less'
      //   }
      //   return errors
      // }}
      render={(formProps) => (
        <Card>
          <Box display="flex" flexDirection="column">
            <TextInput
              // className={classes.inputFieldTable1}
              // fullWidth
              source="name"
              variant="outlined"
              validate={required()}
              label="Name:"
            />
            {props.resource === 'material' && (
              <TextInput
                // className={classes.inputFieldTable1}
                fullWidth
                source="own"
                variant="outlined"
                validate={required()}
                label="System Hierarchy:"
              />
            )}
            <TextInput
              // className={classes.inputFieldTable1}
              // fullWidth
              source="value"
              variant="outlined"
              validate={required()}
              label="System Value:"
            />
          </Box>
          <Confirm
            // redirect='/search'
            redirect={`/${props.resource}`}
            record={formProps.record}
            basePath={formProps.basePath}
            label="SUBMIT"
            undoable
            isSave
            // invalid={formProps.invalid}
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            disabled={formProps.saving}
            resource="system_dictionary"
          />
        </Card>
      )}
    />
  )
}
