import React from 'react'
import {
  Create,
  FormWithRedirect,
  NumberInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import CustomToolbar from '../../components/CustomToolbar'

export const styles = {
  doubleWidth: { width: 544 },
}

const QualityAgencyCreate = (props) => (
  <Create {...props}>
    <QualityAgencyForm />
  </Create>
)

const QualityAgencyForm = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const handleSubmit = async (record) => {
    try {
      await dataProvider.create('qualityAgency', {
        data: record,
      })
      // console.log(record, data)
      notify('Created Successfully', 'info')
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      render={(formProps) => (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Add New Quality Agency
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                <TextInput fullWidth source="contact.data.firstName" label="First Name" />
                <TextInput fullWidth source="contact.data.lastName" label="Last Name" />
                <TextInput fullWidth source="name" label="Company Name" />
                <TextInput fullWidth source="contact.data.position" label="Position" />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                <NumberInput fullWidth source="basicRate" label="Base Rate" />
                <TextInput fullWidth source="contact.data.email" label="Email" />
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={0.5} mr={{ xs: 0, sm: '1em' }}>
                    <TextInput fullWidth source="contact.data.phone_ext" label="PhoneExt" />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                    <TextInput fullWidth source="contact.data.phone" label="Phone Number" />
                  </Box>
                </Box>
                <TextInput fullWidth source="contact.data.mobile" label="Mobile Number" />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                <TextInput fullWidth source="contact.data.wechat" label="Wechat ID" />
                <TextInput fullWidth source="contact.data.skype" label="Skype ID" />
                <TextInput fullWidth source="contact.data.whatsapp" label="WhatsApp ID" />
                <TextInput fullWidth source="contact.data.fax" label="Fax" />
              </Box>
            </Box>
            <CustomToolbar
              resource="qualityAgency"
              saving={formProps.saving}
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleClick={handleSubmit}
              redirect="/qualityAgencies"
            />
          </CardContent>
        </Card>
      )}
    />
  )
}

export default QualityAgencyCreate
