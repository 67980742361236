import React, {
  useState,
  useCallback,
  useEffect,
  //  Fragment
} from 'react'
import {
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  IconButton,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import {
  //   required,
  // AutocompleteInput,
  // ReferenceInput,
  // required,
  useCreate,
  useRefresh,
  useNotify,
  useDataProvider,
  Create,
  FormWithRedirect,
  SaveButton,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { debounce } from 'lodash'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import TextInput from '../../../components/OutlinedTextInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import SCASelect from './CustomSCASelect'
import ClientSelectFieldInput from '../../components/fieldInputs/ClientSelectFieldInput'
// import NumberFormat from '../../components/CustomNumberFormat'
import ExcelExport from '../../../utils/ExcelExport'
// import BooleanFieldInput from './fieldInputs/BooleanFieldInput'
import { makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import uploadFile from '../../../dataProvider/aws_upload'
import CategoriesFieldInput from '../../components/fieldInputs/CategoriesFieldInput'
import AdditionalFilesFieldInput from '../../components/fieldInputs/AdditionalFilesFieldInput'

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '16px',
    // color: 'rgb(18, 183, 106)',
    marginTop: '20px',
  },
  boxContainer: {
    marginTop: '20px',
    padding: '20px 10px',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  imageDropZone: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 0px',
    textAlign: 'center',
    fontSize: '12px',
  },
  imageDropZoneEditing: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    cursor: 'pointer',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  loadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '55px',
    top: '55px',
  },
  previewImageBox: {
    maxWidth: '85px',
    maxHeight: '85px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    padding: '2px',
    backgroundColor: 'white',
    // border: 'solid gray 2px',
  },
  previewImage: {
    zIndex: '100',
    maxWidth: '100%',
    maxHeight: '100%',
    // cursor: 'pointer',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '100px',
    maxHeight: '100px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  deleteBtn: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
}))
const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '10px',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '10px',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ProductsTable = ({ id }) => {
  const form = useForm()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [create, { loading: updating }] = useCreate('sourcing_request_items')
  const classes = useStyles()
  // const [editing, setEditing] = useState(false)
  // const [saving, setSaving] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const { values } = useFormState()

  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  // function sleep(delay = 0) {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, delay)
  //   })
  // }

  const getOptionsAsync = async (query) => {
    setLoading(true)
    const resp = await dataProvider.getList('supplier', {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'name', order: 'ASC' },
      filter: { name: query },
    })
    setLoading(false)
    return resp.data
  }

  const [inputValue, setInputValue] = React.useState('')
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setOptions([])
      getOptionsAsync(text).then(callback)
    }, 500),
    [],
  )

  useEffect(() => {
    getOptionsDelayed(inputValue, (filteredOptions) => {
      setOptions(filteredOptions)
    })
  }, [inputValue, getOptionsDelayed])

  // console.log(values)

  const date = moment().format('YYYY-MM-DD')

  const handleSubmit = (submission) => {
    console.log(submission)

    const params = submission.newItems
      .filter((el) => el.name)
      .map((el) => ({
        ...(el.id && { id: el.id }),
        budget_per_piece: el.budget_per_piece,
        description: el.description,
        name: el.name,
        quantity: el.quantity,
        reference: el.reference,
        required_by: el.required_by,
        sourcing_request_id: id,
        image_id: el.image?.id || el.image_id,
      }))

    if (params.length === 0) {
      notify('No valid rows to insert', 'warning')
    }

    create(
      {
        payload: {
          data: params,
          on_conflict: {
            constraint: 'sourcing_request_items_pkey',
            update_columns: [
              'budget_per_piece',
              'description',
              'name',
              'quantity',
              'reference',
              'required_by',
              'image_id',
            ],
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setCreateOpen(false)
          console.log(data)
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      },
    )
  }

  // Priority, urgency, file bucket, brand, add new supplier, security, probability of success, budget/value, design required, categories
  return (
    <Box>
      <Box display="flex">
        <Box flex={1} m="0px 10px">
          <Card className={classes.boxContainer}>
            <span className={classes.sectionHeader}>RFQ Details</span>
            <div style={{ height: '10px' }} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="name"
              resource="sourcing_request"
              id={values.id}
              label="Project name:"
              value={values.name}
              className={classes.fieldInputLeft}
            />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="contact_email"
              resource="sourcing_request"
              id={values.id}
              label="Contact email:"
              value={values.contact_email}
              className={classes.fieldInputLeft}
            />
            <ClientSelectFieldInput
              field="client_id"
              resource="sourcing_request"
              id={values.id}
              className={classes.fieldInputLeft}
              fullWidth
              onSave={async () => {
                await dataProvider.update('sourcing_request', {
                  data: {
                    id: values.id,
                    delivery_address_id: null,
                    destination_post_code: null,
                  },
                })
              }}
            />
            <div style={{ height: '10px' }} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="brand_name"
              resource="sourcing_request"
              id={values.id}
              label="Brand name:"
              value={values.brand_name}
              className={classes.fieldInputLeft}
            />
            <CategoriesFieldInput
              variant="standard"
              fullWidth
              fieldLabel="Categories"
              formField="chosenCategories"
              oldField={'sr_categories'}
              resourceName="sourcing_request_categories"
              fieldName="category_id"
              referenceField="sourcing_request_id"
              referenceId={values.id}
              classes={{ container: classes.categoryInput }}
              onConflict={{
                on_conflict: {
                  constraint: 'sourcing_request_categories_sourcing_request_id_category_id_key',
                  update_columns: ['sourcing_request_id', 'category_id'],
                },
              }}
            />
          </Card>
        </Box>

        <Box flex={1} m="0px 10px">
          <Card className={classes.boxContainer}>
            <span className={classes.sectionHeader}>Internal</span>
            <div style={{ height: '10px' }} />
            <SCASelect
              source="sca_id"
              resource="sourcing_request"
              label="SCA"
              reference="users"
              id={values.id}
            />
            <div style={{ height: '10px' }} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="priority"
              resource="sourcing_request"
              id={values.id}
              label="Priority:"
              value={values.priority}
              className={classes.fieldInputLeft}
            />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="urgency"
              resource="sourcing_request"
              id={values.id}
              label="Urgency:"
              value={values.urgency}
              className={classes.fieldInputLeft}
            />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="success_probability"
              resource="sourcing_request"
              id={values.id}
              label="Probability of Success:"
              value={values.success_probability}
              className={classes.fieldInputLeft}
            />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="estimated_order_value"
              resource="sourcing_request"
              id={values.id}
              label="Estimated Order Value/Budget:"
              value={values.estimated_order_value}
              className={classes.fieldInputLeft}
            />
          </Card>
        </Box>
      </Box>

      <Card className={classes.boxContainer}>
        <span className={classes.sectionHeader}>Supporting Documents & Files</span>

        <AdditionalFilesFieldInput
          field="additionalFiles"
          resource="sourcing_request_file"
          resourceConstraint="sourcing_request_file_file_id_sourcing_request_id_type_key"
          referenceField="sourcing_request_id"
          referenceId={values.id}
          typesList={[
            { id: 0, name: 'Report', value: 'REPORT' },
            { id: 1, name: 'Product Design', value: 'PRODUCT_DESIGN' },
            { id: 2, name: 'Packaging Design', value: 'PACKAGING_DESIGN' },
            { id: 3, name: 'Logo Design', value: 'LOGO_DESIGN' },
            { id: 4, name: 'Media', value: 'MEDIA' },
            { id: 5, name: 'Product Imprint', value: 'PRODUCT_IMPRINT' },
            { id: 6, name: 'Packaging Imprint', value: 'PACKAGING_IMPRINT' },
            { id: 7, name: 'Internal', value: 'INTERNAL' },
            { id: 8, name: 'Other', value: 'OTHER' },
          ]}
        />
      </Card>

      <Card className={classes.boxContainer}>
        <span className={classes.sectionHeader}>Sourcing List</span>

        <Box mt="20px" display="flex" alignItems="center" justifyContent="space-between">
          <Button
            onClick={() => {
              setCreateOpen(true)
            }}
            style={{ border: 'solid 1px lightgrey' }}
          >
            Edit
          </Button>
          <Box display="flex">
            {/* <Autocomplete
              id="asynchronous-demo"
              style={{ width: 300 }}
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              onChange={(e, v) => form.change('supplier', v)}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={options}
              loading={loading}
              onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier"
                  variant="outlined"
                  style={{ fontSize: '0.8125rem', height: '36px' }}
                  // onChange={(e) => {
                  //   setLoading(true)
                  //   setSearchFilter(e.target.value)
                  // }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            /> */}

            {/* <Autocomplete
              optionText="name"
              optionValue="id"
              variant="standard"
              validate={required()} // Enable Required
              fullWidth
              label={false}
              onChange={(e, v, o) => console.log(e, v, o)}
            /> */}
            {/* <SelectInput optionText="name" fullWidth/> */}
            {values.items?.length > 0 && (
              <ExcelExport
                data={values.items?.map((el) => ({
                  [`Product Name`]: el.name,
                  [`Supplier Name`]: null,
                  [`Product Description`]: el.description,
                  [`Image`]: null,
                  [`Packaging`]: null,
                  [`Set-up Cost`]: null,
                  [`Sample Cost`]: null,
                  [`Sample Lead Time (days)`]: null,
                  [`Quantity`]: el.quantity,
                  [`Unit Price (USD)`]: null,
                  [`Lead Time (days)`]: null,
                  [`Carton Qty`]: null,
                  [`Carton GW (kg)`]: null,
                  [`Carton L (cm)`]: null,
                  [`Carton W (cm)`]: null,
                  [`Carton H (cm)`]: null,
                  [`HTS Code`]: null,
                }))}
                // fileName={`${values.supplier?.name}_SCMSOS-RFQ_${values.client_id}_${values.id}_${date}`}
                fileName={`SCMSOS-RFQ_${values.client_id}_${values.id}_${date}`}
                title="Export Factory"
                // disabled={!values.supplier}
              />
            )}
          </Box>
        </Box>
        <Box mt="10px">
          <TableContainer component={Paper}>
            <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Name
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '10%', fontSize: '12px', padding: '0px' }}
                    align="left"
                  >
                    Ref Image
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '25%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Requirements
                  </StyledTableCell>
                  {/* <StyledTableCell
                    style={{ width: '15%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Packaging
                  </StyledTableCell> */}
                  <StyledTableCell
                    style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Budget
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Deadline
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '20%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    URL Ref
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                    align="left"
                  >
                    Delete
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.items?.map((item, index) => (
                  <ItemRow
                    disabled={!createOpen}
                    field="items"
                    key={`price${item.id}`}
                    index={index}
                    id={item.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {createOpen && (
            <Create basePath="sourcingRequests" resource="sourcing_request_items">
              <FormWithRedirect
                redirect={null}
                save={handleSubmit}
                initialValues={{
                  newItems: [...values.items, {}],
                }}
                render={(formProps) => (
                  <ItemEditDialog
                    updating={updating}
                    formProps={formProps}
                    setCreateOpen={setCreateOpen}
                    createOpen={createOpen}
                  />
                )}
              />
            </Create>
          )}
        </Box>
      </Card>
    </Box>
  )
}

const ItemEditDialog = ({ formProps, createOpen, setCreateOpen }) => {
  const refresh = useRefresh()
  const form = useForm()
  const { values } = useFormState()
  // console.log(values)

  useEffect(() => {
    // console.log(Object.keys(values.newItems[values.newItems.length - 1]).length)
    if (Object.keys(values.newItems[values.newItems.length - 1]).length > 0) {
      const addItems = [...values.newItems]
      addItems.push({})
      form.change('newItems', addItems)
    }
  }, [values.newItems])
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={createOpen}
      onClose={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setCreateOpen(false)
      }}
      aria-label="CreateProd"
    >
      <DialogTitle>Create Product</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '0px' }}
                  align="left"
                >
                  Ref Image
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '25%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Requirements
                </StyledTableCell>
                {/* <StyledTableCell
                  style={{ width: '15%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Packaging
                </StyledTableCell> */}
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Quantity
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Budget
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Deadline
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  URL Ref
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.newItems?.map((item, index) => (
                <ItemRow
                  editing={true}
                  field="newItems"
                  key={`newItem${index}`}
                  index={index}
                  id={item.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <SaveButton
          style={{ margin: '20px 0px' }}
          saving={formProps.saving}
          handleSubmitWithRedirect={async () => {
            await formProps.handleSubmitWithRedirect()
            setCreateOpen(false)
            refresh()
          }}
        />
        <Button
          style={{ margin: '20px', border: 'solid 1px gray' }}
          onClick={() => {
            setCreateOpen(false)
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ItemRow = ({ index, disabled, field }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()

  // console.log(values)

  const [uploading, setUploading] = useState(false)
  //   const [zoomOpen, setZoomOpen] = useState(null)
  // console.log(values)
  const preview = values[field][index].image?.url || null

  // console.log(preview)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    multiple: false,
    disabled,
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles)
      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploading(true)
        const acceptedFile = acceptedFiles[0]
        // console.log(acceptedFile)
        const url = await uploadFile(acceptedFile)
        try {
          const fileResp = await dataProvider.create('file', {
            data: { filename: acceptedFiles[0].name, url },
          })

          await form.batch(() => {
            form.change(`${field}[${index}].image`, {
              id: fileResp.data.id,
              filename: acceptedFile.name,
              url,
            })
            form.change(`${field}[${index}].image_id`, fileResp.data.id)
          })

          notify('Upload Complete', { type: 'info' })

          setUploading(false)
        } catch (error) {
          console.log(error)
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  return (
    <TableRow
      key={`item${field}Row${index}`}
      style={index % 2 === 0 ? { backgroundColor: '#FFF' } : { backgroundColor: '#F3F3F3' }}
    >
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].name`}
          label={false}
          disabled={disabled}
          multiline
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="center"
        style={{ padding: '0px', width: '100px', height: '100px', position: 'relative' }}
      >
        {!disabled ? (
          preview ? (
            <div className={classes.previewImageBox}>
              <img
                src={preview}
                className={classes.previewImage}
                alt=""
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <IconButton
                aria-label="delete"
                className={classes.deleteBtn}
                onClick={async () => {
                  try {
                    setUploading(true)
                    // await dataProvider.update('scm_quote', {
                    //   data: { id: values.id, image_id: null },
                    // })
                    await form.batch(() => {
                      form.change(`${field}[${index}].image`, null)
                      form.change(`${field}[${index}].image_id`, null)
                    })
                    notify('Image Deleted', { type: 'info' })
                    setUploading(false)
                  } catch (error) {
                    notify(error.message, { type: 'warning' })
                    setUploading(false)
                  }
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          ) : (
            <Box
              // mr="20px"
              style={{ position: 'relative' }}
            >
              <div
                {...getRootProps({
                  className: disabled ? classes.imageDropZone : classes.imageDropZoneEditing,
                })}
              >
                <input {...getInputProps()} />
                {!uploading && !preview && <span>{`Drag / Upload`}</span>}
              </div>

              {uploading && <CircularProgress className={classes.loadingIcon} />}
            </Box>
          )
        ) : (
          preview && (
            <div className={classes.previewImageBox}>
              <img src={preview} className={classes.previewImage} alt="" />
            </div>
          )
        )}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].description`}
          label={false}
          disabled={disabled}
          multiline
          // validate={required()}
        />
      </BodyTableCell>
      {/* <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].packaging`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell> */}
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].quantity`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].budget_per_piece`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].required_by`}
          label={false}
          disabled={disabled}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{ margin: '0px', height: '100%' }}
          source={`${field}[${index}].reference`}
          label={false}
          disabled={disabled}
          multiline
          // validate={required()}
        />
      </BodyTableCell>

      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <IconButton
          aria-label="info"
          className={classes.infoBtn}
          onClick={async () => {
            // console.log(values.items[index].id)
            try {
              await dataProvider.delete('sourcing_request_items', {
                data: { id: values[field][index].id },
              })
              const newItems = [...values[field]]
              newItems.splice(index, 1)
              // console.log('items', newItems, values.items)
              // console.log('changing')
              form.change(field, newItems)
            } catch (error) {
              notify(error.message.split('.')[0], { type: 'warning' })
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

export default ProductsTable
