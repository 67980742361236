import React from 'react'
import {
  FormWithRedirect,
  Edit,
  Create,
  useNotify,
  useDataProvider,
  TextInput,
  required,
} from 'react-admin'
import { Card, Box } from '@material-ui/core'
import Confirm from '../../components/Confirm'
// import uploadFile from '../../../dataProvider/upload'

export const TagEdit = (props) => (
  <Edit {...props}>
    <FormData params={props} />
  </Edit>
)

export const TagCreate = (props) => (
  <Create {...props}>
    <FormData params={props} isCreate />
  </Create>
)

const FormData = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(props)
  const handleSubmit = async (record) => {
    const params = {
      data: {
        ...(record.id && { id: record.id }),
      },
      on_conflict: {
        constraint: '^tag_pkey^',
        update_columns: ['^name^'],
      },
    }

    params.data.name = record.name

    if (props.isCreate) {
      try {
        await dataProvider.create('tag', {
          ...params,
        })
        notify('Updated Successfully.', { type: 'info' })
      } catch (error) {
        // console.log(error)
        notify(error.message, 'warning')
      }
    } else {
      try {
        await dataProvider.update('tag', {
          ...params,
        })
        notify('Updated Successfully.', { type: 'info' })
      } catch (error) {
        // console.log(error)
        notify(error.message, 'warning')
      }
    }
  }

  return (
    <FormWithRedirect
      {...props}
      // validate={(values) => {
      //   const errors = {}
      //   if (values.name?.length > 16) {
      //     errors.name = 'Must be 16 characters or less'
      //   }
      //   return errors
      // }}
      render={(formProps) => (
        <Card>
          <Box display="flex" flexDirection="column">
            <TextInput
              // className={classes.inputFieldTable1}
              // fullWidth
              source="name"
              variant="outlined"
              validate={required()}
              label="Name:"
            />
          </Box>
          <Confirm
            redirect="/tags"
            // redirect={`/${props.resource}`}
            record={formProps.record}
            basePath={formProps.basePath}
            label="SUBMIT"
            undoable
            isSave
            // invalid={formProps.invalid}
            handleSubmit={handleSubmit}
            saving={formProps.saving}
            disabled={formProps.saving}
            resource="tag"
          />
        </Card>
      )}
    />
  )
}
