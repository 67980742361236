import React, { useState, useEffect } from 'react'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import ResourceWithPermissions from './components/ResourceWithPermissions'
// import './App.css'
import logdna from '@logdna/browser'
// import { ApolloProvider } from '@apollo/client'
// import client from './dataProvider/hasura/buildApolloClient'

import authProvider from './authProvider'
import themeReducer from './reducers/themeReducer'
import userTokenReducer from './reducers/userTokenReducer'
// import activeOrgReducer from './reducers/activeOrgReducer'
// import activeOrgNameReducer from './reducers/activeOrgNameReducer'
import { Login, Layout } from './layout'
import customRoutes from './routes'
import englishMessages from './i18n/en'
import buildHasuraProvider from './dataProvider/hasura/index'

// Users
import app_users from './pages/users/app_users'
import users from './pages/users/users'
import clients from './pages/users/clients'

// Dashboards
// import Dashboard from './pages/dashboards'

// Products
import sourcingRequests from './pages/sourcingRequests'
import projects from './pages/projects'
import tasks from './pages/tasks'
import products from './pages/products'
import saleableProducts from './pages/saleableProducts'
import catalogueProducts from './pages/catalogueProducts'
import customCatalogueProducts from './pages/customCatalogueProducts'
import scmQuote from './pages/scmQuote'
import saleableProductVariants from './pages/saleableProducts/subFields/saleableProductVariants'

// Suppliers
import suppliers from './pages/suppliers/suppliers'
// import supplierCatalogItems from './pages/suppliers/catalog_items'
// import suppliersVerification from "./pages/suppliers/verification"
import supplierAudit from './pages/suppliers/audits'

// Offers (Investments/Direct Purchases)
import offer from './pages/offers/offer'
import customOffer from './pages/offers/customOffer'
import offerPurchase from './pages/offers/offerPurchase'

// Orders (Investments/Direct Purchases)
import order from './pages/orders'
import consumerOrders from './pages/consumerOrders'

// Third Parties
import qualityAgencies from './pages/thirdParties/qualityAgencies'

// Supporting Fields
import brands from './pages/configuration/brands'
import material from './pages/configuration/dictionaryField'
import packagingStyle from './pages/configuration/dictionaryField'
import imprintTechnique from './pages/configuration/dictionaryField'
import paymentType from './pages/configuration/dictionaryField'
import certificationType from './pages/configuration/dictionaryField'
import certificateType from './pages/configuration/dictionaryField'
import categories from './pages/configuration/categories'
import tags from './pages/configuration/tags'
import carouselContent from './pages/configuration/carouselContent'
import defaultRates from './pages/configuration/defaultRates'
// import platformConfig from './pages/configuration/platformConfig'
// import globalConfig from './pages/configuration/globalConfig'

// import dataProviderFactory from './dataProvider'

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'zh') {
      return import('./i18n/zh').then((messages) => messages.default)
    }

    // Always fallback on english
    return englishMessages
  },
  'en',
  { allowMissing: true },
)

const App = () => {
  const [dataProvider, setDataProvider] = useState(null)
  // console.time('APPSTART')
  useEffect(() => {
    const fetchDataProvider = async () => {
      const provider = await buildHasuraProvider()

      setDataProvider(
        // GOTCHA: dataProviderInstance can be a function
        // () => dataProviderInstance,
        () => provider,
      )
    }

    // console.time('FETCHDP')
    fetchDataProvider()
    // console.timeEnd('FETCHDP')
  }, [])

  useEffect(() => {
    const initLogger = async () => {
      try {
        const LOGDNA_INGESTION_KEY = '0df56c85d5b5250a0b95784097b033a2'

        process.env.NODE_ENV !== 'development' && logdna.init(LOGDNA_INGESTION_KEY)
      } catch (error) {
        console.log('DEBUG ERROR', error)
      }
    }
    initLogger()
  }, [])

  if (!dataProvider || !authProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    )
  }
  return (
    <Admin
      disableTelemetry
      title=""
      dataProvider={dataProvider}
      customReducers={{
        theme: themeReducer,
        userToken: userTokenReducer,
        // activeOrg: activeOrgReducer,
        // activeOrgName: activeOrgNameReducer,
      }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      // dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      {/* NOTE: The order below will determine the order the items appear in the react menu! */}
      {(permissions) => [
        <ResourceWithPermissions
          key="products"
          name="products"
          permissions={permissions}
          {...products}
        />,
        <ResourceWithPermissions
          key="projects"
          name="projects"
          permissions={permissions}
          {...projects}
        />,
        <ResourceWithPermissions key="tasks" name="tasks" permissions={permissions} {...tasks} />,
        <ResourceWithPermissions
          key="sourcingRequests"
          name="sourcingRequests"
          permissions={permissions}
          {...sourcingRequests}
        />,
        <ResourceWithPermissions
          key="catalogueProducts"
          name="catalogueProducts"
          permissions={permissions}
          {...catalogueProducts}
        />,
        <ResourceWithPermissions
          key="customCatalogueProducts"
          name="customCatalogueProducts"
          permissions={permissions}
          {...customCatalogueProducts}
        />,
        <ResourceWithPermissions
          key="scmQuote"
          name="scmQuote"
          permissions={permissions}
          {...scmQuote}
        />,
        <ResourceWithPermissions key="offer" name="offer" permissions={permissions} {...offer} />,
        <ResourceWithPermissions
          key="customOffer"
          name="customOffer"
          permissions={permissions}
          {...customOffer}
        />,
        <ResourceWithPermissions
          key="offerPurchases"
          name="offerPurchases"
          permissions={permissions}
          {...offerPurchase}
        />,
        <ResourceWithPermissions key="order" name="order" permissions={permissions} {...order} />,
        <ResourceWithPermissions
          key="consumerOrder"
          name="consumerOrders"
          permissions={permissions}
          {...consumerOrders}
        />,
        <ResourceWithPermissions
          key="saleableProducts"
          name="saleableProducts"
          permissions={permissions}
          {...saleableProducts}
        />,
        <ResourceWithPermissions
          key="saleableProductVariants"
          name="saleableProductVariants"
          permissions={permissions}
          {...saleableProductVariants}
        />,
        <ResourceWithPermissions
          key="solidBlanks"
          name="solidBlanks"
          permissions={permissions}
          {...saleableProducts}
        />,
        <ResourceWithPermissions
          key="cliqueStock"
          name="cliqueStock"
          permissions={permissions}
          {...saleableProducts}
        />,

        <ResourceWithPermissions
          key="qualityAgencies"
          name="qualityAgencies"
          permissions={permissions}
          {...qualityAgencies}
        />,
        <ResourceWithPermissions key="users" name="users" permissions={permissions} {...users} />,
        <ResourceWithPermissions
          key="app_users"
          name="app_users"
          permissions={permissions}
          {...app_users}
        />,
        <ResourceWithPermissions
          key="clients"
          name="clients"
          permissions={permissions}
          {...clients}
        />,
        <ResourceWithPermissions
          key="suppliers"
          name="suppliers"
          permissions={permissions}
          {...suppliers}
        />,
        <ResourceWithPermissions
          key="supplierCertifications"
          name="supplierCertifications"
          permissions={permissions}
          {...suppliers}
        />,
        <Resource key="brands" name="brands" {...brands} />,
        <Resource key="material" name="material" {...material} />,
        <Resource key="otherSpecifications" name="otherSpecifications" />,
        <Resource key="productCustomisations" name="productCustomisations" />,
        <Resource key="packagingStyle" name="packagingStyle" {...packagingStyle} />,
        <Resource key="categories" name="categories" {...categories} />,
        <Resource key="tags" name="tags" {...tags} />,
        <Resource key="imprintTechnique" name="imprintTechnique" {...imprintTechnique} />,
        <Resource key="paymentType" name="paymentType" {...paymentType} />,
        <Resource key="certificationType" name="certificationType" {...certificationType} />,
        <Resource key="certificateType" name="certificateType" {...certificateType} />,
        <Resource key="carouselContent" name="carouselContent" {...carouselContent} />,
        <Resource key="defaultRates" name="defaultRates" {...defaultRates} />,
        // // <Resource key="platformConfig" name="platformConfig" {...platformConfig} />,
        // <Resource key="globalConfig" name="globalConfig" {...globalConfig} />,
        <Resource key="contact" name="contact" />,
        <Resource key="organisation" name="organisation" />,
        <Resource key="role" name="role" />,
        <Resource key="address" name="address" />,
        <Resource key="addressType" name="addressType" />,
        <Resource key="country" name="country" />,
        <Resource key="country_subdivision" name="country_subdivision" />,
        <Resource key="app_user" name="app_user" />,
        <Resource key="category" name="category" />,
        <Resource key="shippingPort" name="shippingPort" />,
        <Resource key="supplierNote" name="supplierNote" />,
        <Resource key="invoice" name="invoice" />,
        <Resource key="notification" name="notification" />,
        <Resource key="push_notification" name="push_notification" />,
        <Resource key="recommendation" name="recommendation" />,
        <Resource key="invoice_payment" name="invoice_payment" />,
        <Resource key="configSection" name="configSection" />,
        <Resource key="productDebug" name="productDebug" />,
        <ResourceWithPermissions key="organisation_members" name="organisation_members" />,
        <ResourceWithPermissions key="user_role" name="user_role" />,
        <ResourceWithPermissions
          key="supplierAudit"
          name="supplierAudit"
          permissions={permissions}
          {...supplierAudit}
        />,
      ]}
    </Admin>
  )
}

export default App
