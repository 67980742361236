import React, { useState, useEffect } from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import HeaderSection from './components/OfferHeaderSection'
import POManagement from '../shared/components/POManagement'
import OfferPurchases from './components/OfferPurchases'
import OfferEditForm from './components/OfferEditForm'
import { ApolloProvider } from '@apollo/client'
import NewClient from '../../../dataProvider/hasura/buildApolloClient'
import useStyles from './styles/index'
import { Edit, useDataProvider, useNotify } from 'react-admin'
import { getDataLists } from './common/utils'

const CustomOfferEdit = (props) => (
  <Edit {...props} actions={null} component="div" mutationMode="pessimistic">
    <CustomOffer />
  </Edit>
)

const CustomOffer = (props) => {
  // console.log('OFFEREDITPROPS', props)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [currentTab, setCurrentTab] = useState('one')
  const [status, setStatus] = useState(props.record?.status)
  const [scaUser, setSCAUser] = useState(props.record?.sca)
  const [dataLists, setDataLists] = useState()

  useEffect(() => {
    const abortController = new AbortController()
    !abortController.signal.aborted &&
      getDataLists(setDataLists, dataProvider, notify, abortController)
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  const localToken = localStorage.getItem('token')

  const token = typeof localToken === 'string' ? localToken : localToken.access_token
  const client = NewClient(token)

  return (
    <div>
      <Box m="10px 30px">
        <HeaderSection {...props} scaUser={scaUser} setSCAUser={setSCAUser} />
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          aria-label="offers"
          style={{ marginTop: '20px' }}
        >
          <Tab
            value="one"
            label="Offers Management"
            wrapped
            style={{ width: '250px' }}
            className={currentTab === 'one' ? classes.tabButton : null}
            // {...a11yProps('one')}
          />
          <Tab
            value="two"
            label="PO Management"
            style={{ width: '250px' }}
            disabled={
              !status || status === 'PENDING_SUBMISSION' || status === 'PENDING_PURCHASE_ORDER'
            }
            wrapped
            className={currentTab === 'two' ? classes.tabButton : null}
          />
          <Tab
            value="three"
            label="Accounting"
            style={{ width: '250px' }}
            disabled={
              !status || status === 'PENDING_SUBMISSION' || status === 'PENDING_PURCHASE_ORDER'
            }
            wrapped
            className={currentTab === 'three' ? classes.tabButton : null}
          />
        </Tabs>
        <TabPanel value={currentTab} index="one">
          <OfferEditForm
            {...props}
            setStatus={setStatus}
            dataLists={dataLists}
            setDataLists={setDataLists}
            scaUser={scaUser}
          />
        </TabPanel>
        <TabPanel value={currentTab} index="two">
          {status !== 'PENDING_SUBMISSION' && status !== 'PENDING_PURCHASE_ORDER' && (
            <POManagement id={parseInt(props.record.id)} />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index="three">
          {status !== 'PENDING_SUBMISSION' && status !== 'PENDING_PURCHASE_ORDER' && (
            <ApolloProvider client={client}>
              <OfferPurchases id={parseInt(props.record.id)} />
            </ApolloProvider>
          )}
        </TabPanel>
      </Box>
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default CustomOfferEdit
