import React from 'react'
import {
  Filter,
  List,
  SearchInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  // TopToolbar,
  // CreateButton,
} from 'react-admin'
import { getDisplayName } from '../../../utils/utils'

export const UserFilter = (props) => (
  <Filter {...props} filterValues={{ ...props.filterValues, qFields: ['email'] }}>
    <SearchInput InputProps={{ placeholder: 'Email' }} source="q" alwaysOn variant="standard" />
  </Filter>
)

// const ListActions = (props) => {
//   const { className } = props
//   return (
//     <TopToolbar className={className}>
//       <CreateButton />
//     </TopToolbar>
//   )
// }

const UserList = (props) => (
  <List
    empty={false}
    // bulkActionButtons={false}
    // actions={<ListActions />}
    actions={null}
    filters={<UserFilter />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid optimized rowClick="edit">
      <TextField source="id" />
      <TextField source="email" label="User email" />
      <FunctionField label="Display Name" render={(record) => getDisplayName(record) || 'None'} />
      <FunctionField label="Active Role" render={(record) => record.active_role?.name} />
      <DateField source="created_at" type="date" />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
)

export default UserList
