import React from 'react'
import {
  useRedirect,
  useDataProvider,
  Create,
  FormWithRedirect,
  TextInput,
  required,
  useGetIdentity,
} from 'react-admin'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  table: {
    overflowX: 'unset',
  },
}))

const CreateDialog = (props) => {
  //   const classes = useStyles()
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const { createOpen, setCreateOpen } = props

  const handleSubmit = async (submission) => {
    const {
      data: { id },
    } = await dataProvider.create('product', {
      data: {
        name: submission.name,
        sca_user_id: identity.userId,
        product_quotes: {
          data: [
            {
              components: {
                data: [
                  {
                    name: 'Base',
                  },
                ],
              },
            },
          ],
        },
      },
    })

    redirect(`/products/${id}`)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={createOpen || false}
      onClose={() => setCreateOpen(false)}
      aria-label="CreateSaleableProduct"
    >
      <DialogTitle>Create Product</DialogTitle>
      <DialogContent>
        <Create resource="variant" basePath="/saleableProduct">
          <FormWithRedirect
            {...props}
            render={() => (
              <div>
                {createOpen ? <CreateProductPopupForm handleSubmit={handleSubmit} /> : null}
              </div>
            )}
          />
        </Create>
      </DialogContent>
    </Dialog>
  )
}

const CreateProductPopupForm = ({ handleSubmit }) => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <Box>
      <TextInput
        variant="standard"
        fullWidth
        source="name"
        label="Product name"
        validate={required()}
      />
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="80px">
        <Button
          className={classes.createButton}
          onClick={async () => {
            await handleSubmit(values)
          }}
          disabled={!values.name}
          //   style={{
          //     color: 'black',
          //     border: 'solid 1px rgba(180, 125, 80, 0.5)',
          //     backgroundColor: 'rgba(255, 149, 102, 0.5)',
          //     '&:hover': {
          //       backgroundColor: 'rgba(255, 176, 145, 0.3)',
          //     },
          //   }}
        >
          CREATE
        </Button>
      </Box>
    </Box>
  )
}

export default CreateDialog
