import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'
import { offerGetQuery } from './offers'
const saleableProductGetQuery = `
  id
  total_stock
  total_inventory
  base_product_id
  brand
  created_at
  description
  long_description
  name
  primary_photo_id
  logistics_lead_time
  hts_code
  retail_price
  express_price
  member_price
  member_express_price
  fob_price
  sku
  status
  updated_at
  consumer_service_fees
  consumer_royalties_fees
  marketing_fees
  fulfillment_fees
  commission_fees
  consumer_software_fees
  consumer_partner_fees
  consumer_payment_fees
  courier_estimate
  consumer_service_rate
  consumer_software_rate
  marketing_rate
  fulfillment_rate
  commission_rate
  consumer_royalties_rate
  consumer_partner_rate
  consumer_payment_rate
  dropshipping_estimate
  dropshipping_fees_destination
  consumer_marketing_fees_destination
  consumer_software_fees_destination
  consumer_payment_fees_destination
  consumer_partner_fees_destination
  consumer_service_fees_destination
  consumer_royalties_fees_destination
  courier_estimate_destination
  options_group_by
  duty_rate
  selling_features
  unit_cost
  url_slug
  default_colour_value
  pendingStock: offers (where: {status: { _in: [PENDING_SUBMISSION, PENDING_PURCHASE_ORDER, PENDING_APPROVAL, PRODUCTION, SHIPPING]}}) {
    batches: batches_aggregate {
      aggregate { sum { quantity} }
    }
  }
  primary_photo {
    id
    filename
    url
  }
  size_chart {
    id
    filename
    url
  }
  categories {
    id
    category_id
    category {
      id
      name
      reference
    }
  }
  images:files (where: {type: {_eq: ADDITIONAL_PHOTO}}) {
    id
    file_id
    reference_option_value
    file {
      id
      filename
      url
    }
    type
  }
  platforms_aggregate {
    aggregate {
      count
    }
  }
  platforms {
    id
    platform
  }
  specifications {
    id
    specification {
      id
      value
      option {
        id
        name
      }
    }
  }
  tags {
    id
    tag_id
    tag {
      id
      name
    }
  }
  related_products {
    id
    saleable_product_variant {
      id
      image {
        id
        filename
        url
      }
      stock
      name
      saleable_product {
        id
        name
        total_stock
        primary_photo {
          id
          filename
          url
        }
      }
    }
  }
  banners (order_by: { id: asc_nulls_last}) {
    id
    template_id
    title
    content_1
    content_2
    background_colour
    active
    sequence
    image_id
    image {
      id
      filename
      url
    }
  }
  product_quote {
    id
    supplier_id
    supplier {
      id
      name
    }
    product {
      id
      materials {
        id
        type
        name
        vendor
        vendor_code
        unit_of_measurement
        quantity_per_piece
        notes
        estimated_cost
        colours {
          id
          reference
          colour
        }
      }
    }
    prices {
      id
      quantity
      unit_price
    }
    product_sizes {
      id
      name
      short_name
      dimensions_id
    }
    options {
      id
      name
      max_unique_selections
      additional_cost_per_unique_choice
      values {
        id
        value
        setup
        is_custom
        cost
        moq
        components {
          id
          component_id
          component {
            id
            name
          }                    
        }
      }
    }
  }
  organisation_inventories {
    id
    organisation_id
    stock
    owner {
      id
      name
    }
  }
  variants_aggregate(where: { _and: [{ active: { _eq: true}}, {_or: [{always_in_stock: {_eq: true}}, {stock: {_gt: 0}}]}]}) {
    aggregate {
      count
    }
  }
  variants {
    id
    stock
    active
    always_in_stock
    list_weighting
    is_listed
    show_variants
    url_slug
    name
    image_file_id
    image {
      id
      filename
      url
    }
    variant {
      id
      sku
      name
      values {
        id
        value
        option_name
      }
    }
  }
  sales (order_by: { id: desc_nulls_last}) {
    id
    quantity
    product_variant_id
    created_at
    product_variant {
      id
      variant {
        id
        sku
        name
        values {
          id
          option_name
          value
        }
      }
    }
    order {
      id
      status
      platform
    }
    saleable_product_sale_allocations_aggregate {
      aggregate {
        sum {
          quantity
        }
      }
    }
  }
  offers_aggregate (where: {status: {_nin: [CANCELLED, COMPLETED]}}) {
    aggregate {
      count
    }
  }
  offers (limit: 1, where: {status: {_nin: [SELLING, CANCELLED, COMPLETED]}}) {
    ${offerGetQuery}
  }
  product_dimensions {
    id
    length
    width
    height
    gross_weight
  }
  bulk_carton_type
  bulk_carton_label
  bulk_pieces_per_carton
  bulk_carton_dimensions {
    id
    length
    width
    height
    gross_weight
  }
  single_carton_type
  single_carton_label
  single_carton_dimensions {
    id
    length
    width
    height
    gross_weight      
  }
  packaging_type
  packaging_label
  packaging_dimensions {
    id
    length
    width
    height
    gross_weight
  }
  activities {
    id
    activity
  }
`

export const saleableProductsGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
    query getSaleableProduct {
      saleable_product_by_pk(id: ${params.id}) {
        ${saleableProductGetQuery}
      }
    }
    `,

    parseResponse: ({ data }) => {
      if (data && data.saleable_product_by_pk) {
        // console.log("data", data)
        return {
          data: data.saleable_product_by_pk,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const saleableProductsList = ({ params, context }) => {
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = {}
  if (params.filter) {
    variables.where = {
      ...(params.filter.omitId && {
        id: { _neq: params.filter.omitId },
      }),
      ...(params.filter.status && { status: { _in: params.filter.status } }),
      ...(params.filter.name && { name: { _ilike: `%${params.filter.name}%` } }),
      ...(params.filter.base_product_id && {
        base_product_id: { _eq: params.filter.base_product_id },
      }),
      ...(params.filter.base_products && { base_product_id: { _is_null: true } }),
      ...(params.filter.category && {
        categories: { category_id: { _in: params.filter.category } },
      }),
      ...(params.filter.platforms && {
        platforms: { platform: { _in: params.filter.platforms } },
      }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
      query getSaleableProducts ($where: saleable_product_bool_exp) {
        saleable_product(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
            ${saleableProductGetQuery}
          }
          saleable_product_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }
      `,
    parseResponse: ({ data }) => ({
      data: data.saleable_product,
      total: data.saleable_product_aggregate.aggregate.count,
    }),
  }
}

export const saleableProductAllocationDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation deleteOfferCustomerPurchases {
            delete_saleable_product_sale_allocation(
              where: { saleable_product_sale: { saleable_product: { offer_id: { _eq: ${params.offerId} } } } }
            ) {
              affected_rows
            }
            delete_saleable_product_sale_transfer(
              where: { saleable_product_sale: { saleable_product: { offer_id: { _eq: ${params.offerId} } } } }
            ) {
              affected_rows
            }
            delete_saleable_product_sale(
              where: { saleable_product: { offer_id: { _eq: ${params.offerId} } } }
            ) {
              affected_rows
            }
            delete_saleable_product(
              where: { offer_id: { _eq: ${params.offerId} } }
            ) {
              affected_rows
            }
            delete_offer_transfer(
              where: { offer_id: { _eq: ${params.offerId} } }
            ) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const saleableProductsPaymentIntent = ({ context, params }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
            mutation getCustomerPaymentIntent {
              createCustomerPaymentIntent(
                args: {
                  ${jsonParams}
                }
            ) {
                saleableProductSaleId
                paymentIntentId
                paymentIntentSecret
                publishableKey
              }
            }
          `,
    parseResponse: ({ data, errors }) => ({
      data: data.createCustomerPaymentIntent,
      errors,
    }),
  }
}

export const saleableProductFilesCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables,
    query: gql`
            mutation saleableProductFilesCreateMany {
              insert_saleable_product_files(
                ${jsonParams}
              ) {
                returning {
                  id
                  type
                  saleable_product_id
                  file {
                    id
                    filename
                    url
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_saleable_product_files?.returning) {
        return {
          data: data.insert_saleable_product_files.returning,
        }
      }
      return false
    },
  }
}

export const saleableProductFilesUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation saleableProductFilesUpdateMany {
        update_saleable_product_files_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_saleable_product_files_many,
    }),
  }
}

export const saleableProductBannersUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation saleableProductBannersUpdateMany {
        update_saleable_product_banners_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_saleable_product_banners_many,
    }),
  }
}

export const saleableProductVariantsUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation saleableProductVariantsUpdateMany {
        update_saleable_product_variants_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_saleable_product_variants_many,
    }),
  }
}

export const saleableProductVariantsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables,
    query: gql`
            mutation saleableProductVariantsCreateMany {
              insert_saleable_product_variants(
                ${jsonParams}
              ) {
                returning {             
                  id
                  stock
                  active
                  saleable_product_id
                  variant_id
                  variant {
                    id
                    name
                    sku
                    values {
                      id
                      value
                      option_value {
                        id
                        value
                        option {
                          id
                          name
                        }
                      }
                      option_name
                      option {
                        id
                        name
                      }
                    }        
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_saleable_product_variants?.returning) {
        return {
          data: data.insert_saleable_product_variants.returning,
        }
      }
      return false
    },
  }
}

export const saleableProductDelete = ({ context, params }) => {
  console.log(params)
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation deleteOfferCustomerPurchases {
            delete_saleable_product_sale_allocation(
              where: { saleable_product_sale: { saleable_product_id: { _eq: ${params.id} } } }
            ) {
              affected_rows
            }
            delete_saleable_product_sale_transfer(
              where: { saleable_product_sale: { saleable_product_id: { _eq: ${params.id} } } }
            ) {
              affected_rows
            }
            delete_saleable_product_sale(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_seller(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_specifications(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_files(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_activities(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_features(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_categories(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_platforms(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_tags(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product_variants(
              where: { saleable_product_id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
            delete_saleable_product(
              where: { id: { _eq: ${params.id} } }
            ) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const saleableProductSpecificationsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation insertSaleableProductSpecsMany {
              insert_saleable_product_specifications(
                ${jsonParams}
              ) {
                returning {
                  id
                  saleable_product_id
                  specification {
                    id
                    value
                    option {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_saleable_product_specifications?.returning) {
        return {
          data: data.insert_saleable_product_specifications.returning,
        }
      }
      return false
    },
  }
}

export const saleableProductVariantDeleteMany = ({ context, params, variables }) => {
  return {
    ...context,
    variables,
    query: gql`
        mutation saleableProductVariantDeleteMany {
          delete_saleable_product_variants(
          where: { id: { _in: ${params.ids} } }
        ) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.delete_saleable_product_variants) {
        return {
          data: data.delete_saleable_product_variants,
        }
      }
      return false
    },
  }
}
