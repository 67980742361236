import React from 'react'
import {
  Edit,
  FormWithRedirect,
  useDataProvider,
  useNotify,
  TextInput,
  NumberInput,
} from 'react-admin'
import { Box, Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { styles as createStyles } from './QualityAgencyCreate'
import CustomToolbar from '../../components/CustomToolbar'
// import uploadFile from '../../dataProvider/upload'
import { hasChangeValues } from '../../../utils/utils'

const QualityAgencyTitle = ({ record }) => <span>QualityAgency #{record.reference}</span>

const styles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const useStyles = makeStyles(styles)

const QualityAgencyEdit = (props) => (
  <Edit {...props} title={<QualityAgencyTitle />}>
    <QualityAgencyForm />
  </Edit>
)

const QualityAgencyForm = (props) => {
  const classes = useStyles()

  const dataProvider = useDataProvider()

  const notify = useNotify()

  const handleSubmit = async (record, formState) => {
    const changeRecord = hasChangeValues(record, formState.dirtyFields)

    const { contact = null, ...qualityAgency } = changeRecord

    // console.log('cr', changeRecord, 'contact', contact)

    // const promises = []

    try {
      if (contact) {
        await dataProvider.update('contact', {
          data: contact,
        })
        // console.log('resp', resp)
      }

      await dataProvider.update('qualityAgency', {
        data: qualityAgency,
      })
      notify('ra.notification.edited', 'info')
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form className={classes.form}>
            <CardContent>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <TextInput fullWidth source="contact.firstName" label="First Name" />
                  <TextInput fullWidth source="contact.lastName" label="Last Name" />
                  <TextInput fullWidth source="contact.companyName" label="Company Name" />
                  <TextInput fullWidth source="contact.position" label="Position" />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <NumberInput fullWidth source="basicRate" label="Base Rate" />
                  <TextInput fullWidth source="contact.email" label="Email" />
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={0.5} mr={{ xs: 0, sm: '1em' }}>
                      <TextInput fullWidth source="contact.phone_ext" label="PhoneExt" />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                      <TextInput fullWidth source="contact.phone" label="Phone Number" />
                    </Box>
                  </Box>
                  <TextInput fullWidth source="contact.mobile" label="Mobile Number" />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <TextInput fullWidth source="contact.wechat" label="Wechat ID" />
                  <TextInput fullWidth source="contact.skype" label="Skype ID" />
                  <TextInput fullWidth source="contact.whatsapp" label="WhatsApp ID" />
                  <TextInput fullWidth source="contact.fax" label="Fax" />
                </Box>
              </Box>
            </CardContent>
            <CustomToolbar
              redirect="/qualityAgencies"
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleSubmit={handleSubmit}
              saving={formProps.saving}
              resource="qualityAgencies"
            />
          </form>
        </Card>
      )}
    />
  )
}

export default QualityAgencyEdit
