// import { createTheme } from '@mui/material/styles'
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme()

const lightInputBg = {
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  '&$disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}

// const siderBG = '#222224'
const darkInputBg = {}

const publicCss = (inputBg) => ({
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        padding: '30px',
      },
    },
    MuiGrid: {
      'spacing-xs-8': {
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '12px',
      },
      input: {
        '&.Mui-disabled': {
          color: '#232323',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.6rem',
      },
      marginDense: {
        marginTop: '1px',
        lineHeight: 1,
      },
      contained: {
        position: 'absolute',
        bottom: 0,
        left: '10px', // 'calc(20% + 10px)'// '160px',
        [theme.breakpoints.down('sm')]: {
          left: '10px',
        },
        [theme.breakpoints.down('md')]: {
          left: '10px',
        },
      },
    },
    MuiFormControl: {
      marginDense: {
        // minHeight: '60px',
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px',
        width: '100%',
      },
      root: {
        // display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
    },
    // PrivateNotchedOutline: {
    //   legendLabelled: {
    //     display: 'none',
    //   },
    // },
    MuiFormGroup: {
      root: {
        justifyContent: 'flex-start',
        flexGrow: 2,
        width: 'calc(100% - 150px)',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#333333',
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
      },
    },
    MuiOutlinedInput: {
      root: {
        flex: 1,
      },
      input: {
        height: '0.65em',
      },
      inputMultiline: {
        height: 'auto',
      },
    },
    MuiFilledInput: {
      multiline: {
        '&$marginDense': {
          paddingTop: 18,
          paddingLeft: 10,
          paddingRight: 8,
        },
      },
      inputMarginDense: {
        paddingTop: 18,
        paddingLeft: 10,
        paddingRight: 8,
      },
      root: {
        fontSize: '0.8rem',
        ...inputBg,
      },
    },
    MuiButton: {
      root: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
        minWidth: 120,
        textTransform: 'none',
        // color: 'black',
        // border: 'solid 1px rgba(180, 125, 80, 0.5)',
        // backgroundColor: 'rgba(255, 149, 102, 0.5)',
        // '&:hover': {
        //   backgroundColor: 'rgba(255, 149, 102, 0.3)',
        // },
      },
      containedPrimary: {
        borderRadius: '4px',
        backgroundColor: '#00B3C3',
        '&:hover': {
          backgroundColor: '#abcdef',
        },
      },
    },
    MuiSwitch: {
      root: {
        width: '48px',
        height: '26px',
        padding: '4px',
      },
      switchBase: {
        padding: '3px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'rgba(204, 204, 204, 1)',
      },
    },
    MTableToolbar: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 0,
      },
      actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: '#EFF1F4',
      },
    },
    MuiTableCell: {
      body: {
        color: 'rgba(102, 102, 102, 1)',
        // borderRight: '1px solid #ddd',
      },
      sizeSmall: {
        // height: '50px',
        padding: '0.6rem',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#7E8796',
      },
      h1: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      },
      h2: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      },
      h3: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      },
      h4: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      },
      h5: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      },
      h6: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      },
      body1: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
        fontSize: '0.65rem',
        fontStyle: 'normal',
      },
      body2: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
        fontSize: '14px',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
        margin: '6px',
        fontSize: '14px',
      },
    },
    RaMenu: {
      open: {
        width: '200px',
      },
    },
    RaMenuItemLink: {
      root: {
        color: 'rgb(16, 24, 40)',
        fontWeight: 400,
        padding: '0.2em 0.2em 0.2em 0.9em',
      },
      active: {
        color: 'rgb(18, 183, 106)',
        fontWeight: 600,
        '& .MuiListItemIcon-root': {
          color: 'rgb(152, 162, 179)',
        },
      },
    },
    RaEdit: {
      card: {
        padding: '20px',
      },
    },
    RaAutocompleteArrayInput: {
      container: {
        flexGrow: 'unset',
        minWidth: '90%',
      },
      // inputRoot: {
      //   marginTop: '0px !important',
      // },
    },
    RaSidebar: {
      drawerPaper: {
        // width: '14em',
      },
      root: {
        height: 'auto',
        backgroundColor: '#EFEFEF',
      },
    },
    RaLabeled: {
      label: {
        width: '8em',
        fontSize: '14px',
        paddingRight: '0.4em',
        fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
        fontWeight: 400,
        lineLeight: '1.43px',
        letterSpacing: '0.01071em',
      },
    },
    RaSearchInput: {
      input: {
        marginTop: '16px',
      },
    },
    RaSimpleFormIterator: {
      root: {
        margin: '0px',
      },
      index: {
        display: 'none',
      },
      indexContainer: {
        display: 'none',
      },
      line: {
        borderBottom: '0px',
      },
      action: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    RaList: {
      main: {
        margin: '10px 30px',
        backgroundColor: '#FFF',
        padding: '20px 20px !important',
        borderRadius: '8px',
        border: 'solid 1px #CDCDCD',
        '& .MuiPaper-root': {
          // maxWidth: '78vw',
          boxShadow: 'none',
          // backgroundColor: '#FF95660D',
        },
      },
    },
    RaFilterForm: {
      form: {
        // height: '30px',
        minHeight: '30px',
        marginTop: '0px',
      },
    },
    RaFilterFormInput: {
      body: {
        height: '100%',
        margin: '10px',
        backgroundColor: '#FFF',
        padding: '5px',
        borderRadius: '8px',
        border: 'solid 1px #CDCDCD',
      },
    },
    RaTopToolbar: {
      root: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        margin: '10px 30px 10px 20px',
        padding: '5px 10px !important',
        '& .MuiButton-root': {
          margin: '0 2px',
          backgroundColor: '#FFF',
          borderRadius: '8px',
          border: 'solid 1px #CDCDCD',
          '&:hover': {
            backgroundColor: '#EFEFEF',
          },
        },
      },
    },
    // RaImageField: {
    // image: {
    // maxHeight: '50px',
    // },
    // },
    // MuiDrawer: {
    //   docked: {
    //     // marginTop: -10,
    //     zIndex: 99,
    //     // backgroundColor: siderBG,
    //     boxShadow: '1px 0px 23px -5px rgba(197,207,196,1)',
    //     // borderRadius: '0px 30px 30px 0px',
    //   },
    // },
    RaLayout: {
      root: {
        // backgroundColor: '#232323',
        // color: '#FFFFFF',
      },
      content: {
        padding: '0px !important',
      },
      appFrame: {
        marginTop: '0px !important',
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#75A570',
      },
    },
  },
  // sidebar: {
  //   width: 240,
  //   backgroundColor: siderBG,
  // },
})

export const darkTheme = {
  sidebar: {
    width: 200,
    closedWidth: 55,
  },
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    // background: {
    //   default: siderBG
    // },
  },
  ...publicCss(darkInputBg),
}

export const lightTheme = {
  sidebar: {
    width: 200,
    closedWidth: 55,
  },
  palette: {
    // background: {
    //   default: siderBG
    // },
    primary: {
      light: '#4e4e4f',
      main: '#222224',
      dark: '#171719',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7c7c7c',
      main: '#292929',
      dark: '#c1c1c1',
      contrastText: '#fff',
    },
  },
  ...publicCss(lightInputBg),
}
