import React from 'react'
import { Box } from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState } from 'react-final-form'
import { get } from 'lodash'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import RichTextInput from 'ra-input-rich-text'

const useStyles = makeStyles(() => ({
  rtfInputBox: {
    '& .ra-rich-text-input': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '220px',
      '& .MuiFormLabel-root': {
        fontSize: '12px',
      },
    },
  },
}))

const TextFieldInput = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const { values } = useFormState()

  const { source, resource, id, onSave, ...rest } = props

  const value = get(values, source)

  return (
    <Box display="flex" className={classes.rtfInputBox}>
      <RichTextInput
        source={source}
        // disabled={!editing}
        disabled
        className={classes.rtfInputBox}
        {...rest}
      />
      <IconButton
        onClick={async () => {
          try {
            await dataProvider.update(resource, {
              data: { id, [source]: value },
            })

            onSave ? onSave() : null

            notify('Update Complete', { type: 'info' })
          } catch (error) {
            notify(error.message, { type: 'warning' })
          }
        }}
        style={{ maxWidth: '50px', maxHeight: '50px' }}
      >
        <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
      </IconButton>
    </Box>
  )
}

export default TextFieldInput
