import QualityAgencyIcon from '@material-ui/icons/PlaylistAddCheck'

import QualityAgencyList from './QualityAgencyList'
import QualityAgencyEdit from './QualityAgencyEdit'
import QualityAgencyCreate from './QualityAgencyCreate'

export default {
  list: QualityAgencyList,
  edit: QualityAgencyEdit,
  create: QualityAgencyCreate,
  icon: QualityAgencyIcon,
}
