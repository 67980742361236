import * as React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { useInput, FieldTitle } from 'ra-core'
import { sanitizeInputRestProps, InputHelperText } from 'react-admin'
// import moment from 'moment'

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
const convertDateToString = value => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return
  const pad = '00'
  const yyyy = value.getFullYear().toString()
  const MM = (value.getMonth() + 1).toString()
  const dd = value.getDate().toString()
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`
}

const dateRegex = /^\d{4}-\d{2}-\d{2}$/
const defaultInputLabelProps = { shrink: true }

const getStringFromDate = value => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return ''
  }

  if (value instanceof Date) {
    return convertDateToString(value)
  }

  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value
  }

  return convertDateToString(new Date(value))
}

const DateInput = ({
  format = getStringFromDate,
  label,
  options,
  source,
  resource,
  helperText,
  margin = 'dense',
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  variant = 'outlined',
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  })

  return (
    <TextField
      id={id}
      {...input}
      variant={variant}
      margin={margin}
      type="date"
      error={!!(touched && error)}
      helperText={<InputHelperText touched={touched} error={error} helperText={helperText} />}
      label={
        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      InputLabelProps={defaultInputLabelProps}
      {...options}
      {...sanitizeInputRestProps(rest)}
    />
  )
}

DateInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
}

DateInput.defaultProps = {
  options: {},
}

export default DateInput
