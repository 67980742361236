import React, { useState, useEffect, useCallback } from 'react'
// import XLSX from 'sheetjs-style'
import {
  //   Box,
  //   Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //   IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  // Tooltip,
  withStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormState, useForm } from 'react-final-form'
import {
  //   useNotify,
  useDataProvider,
  useRefresh,
  // AutocompleteInput,
  // ReferenceInput,
  Create,
  FormWithRedirect,
  TextInput,
} from 'react-admin'
// import moment from 'moment'
import { debounce, uniqueId } from 'lodash'
import { useDropzone } from 'react-dropzone'
// import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import CancelIcon from '@material-ui/icons/Cancel'
// import ZoomInIcon from '@material-ui/icons/ZoomIn'
// import uploadFile from '../dataProvider/aws_upload'
// import InfoIcon from '@material-ui/icons/InfoOutlined'
import useLazyEffect from './useLazyEffect'
import Confirm from '../pages/components/Confirm'
import Excel from 'exceljs'
import { Buffer } from 'buffer'
import uploadFile from '../dataProvider/aws_upload'

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '10px',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '10px',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const useStyles = makeStyles({
  imageDropZone: {
    width: '200px',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    // opacity: '0.9',
    border: 'dashed gray 2px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '12px',
  },
})

const ExcelImport = ({ sourcingRequestId }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [uploading, setUploading] = useState(false)
  const [data, setData] = useState([])
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        setUploading(true)

        const fileData = await acceptedFiles[0].arrayBuffer()
        // const supplierName = acceptedFiles[0].path?.split('_')[0]
        // console.log(supplierName)

        // const workbook = read(fileData)
        const workbook = new Excel.Workbook()
        await workbook.xlsx.load(fileData)

        const worksheet = workbook.worksheets[0]

        const parsedData = []

        const headers = worksheet.getRow(1)
        if (!headers.cellCount) return
        const keys = headers.values
        worksheet.eachRow((row, index) => {
          if (index === 1) return
          const values = row.values

          const obj = {}
          values.map((el, i) => {
            obj[keys[i]] = values[i]
          })
          obj.photos = []
          parsedData.push(obj)
        })

        worksheet.getImages().map((el) => {
          // console.log(el.range.tl.nativeRow, el.range.tl.nativeCol, el.imageId)

          if (el.range.tl.nativeCol !== 3) return
          const img = workbook.model.media.find((m) => m.index === el.imageId)
          // console.log(img)
          const b64 = Buffer.from(img.buffer).toString('base64')
          // CHANGE THIS IF THE IMAGE YOU ARE WORKING WITH IS .jpg OR WHATEVER
          const mimeType = `image/${img.extension}` // e.g., image/png
          const imageFile = new File(img.buffer, `${img.name}.${img.extension}`, {
            type: mimeType,
          })

          const photo = {
            src: imageFile,
            preview: `data:${mimeType};base64,${b64}`,
            name: `${img.name}.${img.extension}`,
            type: img.type,
            mimeType,
          }
          parsedData[el.range.tl.nativeRow - 1].photos.push(photo)
          //   return photo
        })
        // setImages(imgs)

        // console.log(parsedData)

        const uniqueProducts = []

        await parsedData.map((el) => {
          if (
            !uniqueProducts.some(
              (up) =>
                up[`Product Name`] === el[`Product Name`] &&
                up[`Supplier Name`] === el[`Supplier Name`] &&
                up[`Product Description`] === el[`Product Description`],
            )
          ) {
            uniqueProducts.push(el)
          }
        })
        const productsData = []
        await Promise.all(
          uniqueProducts.map(async (product) => {
            const resp = await dataProvider.getList('supplier', {
              pagination: { page: 1, perPage: 1 },
              sort: { field: 'name', order: 'ASC' },
              filter: { name: product[`Supplier Name`] },
            })
            // console.log(resp)

            productsData.push({
              name: product[`Product Name`],
              supplier: resp.data[0],
              description: product[`Product Description`],
              quotes: parsedData.filter(
                (el) =>
                  el[`Product Name`] === product[`Product Name`] &&
                  el[`Product Description`] === product[`Product Description`],
              ),
            })
          }),
        )

        await setData(productsData)
        setReviewDialogOpen(true)

        try {
          //   console.log(acceptedFiles)
          setUploading(false)
        } catch (error) {
          console.log(error)
          setUploading(false)
        }
      }
      setUploading(false)
    },
  })

  return (
    <div>
      <div {...getRootProps({ className: classes.imageDropZone })}>
        <input {...getInputProps()} />
        <span>{`Import from Excel`}</span>
        <span>{`(Click / Drag)`}</span>

        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </div>
      {reviewDialogOpen && (
        <ReviewDialog
          open={reviewDialogOpen}
          setOpen={setReviewDialogOpen}
          data={data}
          sourcingRequestId={sourcingRequestId}
        />
      )}
    </div>
  )
}

const ReviewDialog = (props) => {
  const { open, setOpen, data, sourcingRequestId } = props

  // console.log(data)
  return (
    <Create basePath="sourcingRequests" resource="product_quote">
      <FormWithRedirect
        initialValues={{ products: data, sourcingRequestId }}
        {...props}
        render={(formProps) => (
          <ReviewDialogContent open={open} setOpen={setOpen} formProps={formProps} />
        )}
      />
    </Create>
  )
}

const ReviewDialogContent = (props) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const { open, setOpen, formProps } = props

  const colours = [
    '#EBD5D5',
    '#E5EBC5',
    '#C5E4EB',
    '#E9C5EB',
    '#C8E9B6',
    '#E9C886',
    '#86BCE9',
    '#E966E0',
    '#E9C6A7',
    '#F0BA73',
  ]

  // console.log('RDC', values)

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(false)
      }}
      aria-label="CreateCat"
    >
      <DialogTitle>Create Request</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '12%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Supplier
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '12%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Product
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Factory Image
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '15%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Packaging
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Set-up Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Sample Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Sample Lead Time
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Qty
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Unit Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Lead Time
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '15%', fontSize: '12px', padding: '10px' }}
                  align="center"
                  colSpan={5}
                >
                  Carton
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  HTS Code
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  G.W.
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  L
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  W
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '10px' }}
                  align="left"
                >
                  H
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values?.products?.map((product, productIndex) => {
                return product.quotes?.map((quote, index) => (
                  <ImportRow
                    key={`ptRow${productIndex}qtRow${index}${uniqueId()}`}
                    quote={quote}
                    index={index}
                    productIndex={productIndex}
                    product={product}
                    colours={colours}
                  />
                ))
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Confirm
          redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="SUBMIT"
          undoable
          invalid={formProps.invalid}
          handleSubmit={async () => {
            // console.log(values)

            const params = []

            await Promise.all(
              values.products.map(async (product) => {
                const newProduct = {
                  sourcing_request_id: values.sourcingRequestId,
                  product: {
                    data: {
                      name: product.name,
                      notes: product.description,
                      hts_code: product?.quotes[0]['HTS Code'],
                      product_quotes: {
                        data: {
                          supplier_id: product.supplier?.id,
                          tooling_cost: parseInt(product?.quotes[0]['Set-up Cost'] * 100),
                          sample_cost: parseInt(product?.quotes[0]['Sample Cost'] * 100),
                          sample_lead_time: product?.quotes[0]['Sample Lead Time (days)'],
                          packaging_type: product?.quotes[0].Packaging,
                          pieces_per_carton: parseInt(product?.quotes[0]['Carton Qty'] * 100),
                          carton_dimensions: {
                            data: {
                              length: parseInt(product?.quotes[0]['Carton L (cm)'] * 100),
                              width: parseInt(product?.quotes[0]['Carton W (cm)'] * 100),
                              height: parseInt(product?.quotes[0]['Carton H (cm)'] * 100),
                              gross_weight: parseInt(product?.quotes[0]['Carton GW (kg)'] * 100),
                            },
                          },
                          prices: {
                            data: product.quotes?.map((quote) => ({
                              quantity: quote.Quantity,
                              unit_price: parseInt(quote['Unit Price (USD)'] * 100),
                              lead_time: quote['Lead Time (days)'],
                            })),
                          },
                        },
                      },
                    },
                  },
                }

                if (product?.quotes?.length > 0 && product.quotes[0].photos?.length > 0) {
                  const fileURL = await uploadFile(product.quotes[0].photos[0])

                  const fileResp = await dataProvider.create('file', {
                    data: { filename: product.quotes[0].photos[0].name, fileURL },
                  })

                  newProduct.product.data.primary_photo_id = fileResp.data.id
                  newProduct.product.data.thumbnail_photo_id = fileResp.data.id
                }

                params.push(newProduct)
              }),
            )

            // const params = values.products.map((product) => ({
            //   sourcing_request_id: values.sourcingRequestId,
            //   product: {
            //     data: {
            //       name: product.name,
            //       notes: product.description,
            //       hts_code: product?.quotes[0]['HTS Code'],
            //       product_quotes: {
            //         data: {
            //           supplier_id: product.supplier?.id,
            //           tooling_cost: parseInt(product?.quotes[0]['Set-up Cost'] * 100),
            //           sample_cost: parseInt(product?.quotes[0]['Sample Cost'] * 100),
            //           sample_lead_time: product?.quotes[0]['Sample Lead Time (days)'],
            //           packaging_type: product?.quotes[0].Packaging,
            //           pieces_per_carton: parseInt(product?.quotes[0]['Carton Qty'] * 100),
            //           carton_dimensions: {
            //             data: {
            //               length: parseInt(product?.quotes[0]['Carton L (cm)'] * 100),
            //               width: parseInt(product?.quotes[0]['Carton W (cm)'] * 100),
            //               height: parseInt(product?.quotes[0]['Carton H (cm)'] * 100),
            //               gross_weight: parseInt(product?.quotes[0]['Carton GW (kg)'] * 100),
            //             },
            //           },
            //           prices: {
            //             data: product.quotes?.map((quote) => ({
            //               quantity: quote.Quantity,
            //               unit_price: parseInt(quote['Unit Price (USD)'] * 100),
            //               lead_time: quote['Lead Time (days)'],
            //             })),
            //           },
            //         },
            //       },
            //     },
            //   },
            // }))

            // console.log(params)

            await dataProvider.create('sourcing_request_products', {
              data: params,
            })

            setOpen(false)
            refresh()
          }}
          saving={formProps.saving}
          disabled={formProps.saving || formProps.invalid}
          resource="saleable_product_batch"
        />
      </DialogActions>
    </Dialog>
  )
}

const ImportRow = (props) => {
  const form = useForm()
  const dataProvider = useDataProvider()
  const { values } = useFormState()
  const { productIndex, index, colours } = props
  const colourIndex = productIndex % 10

  const [openSupplier, setOpenSupplier] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const getOptionsAsync = async (query) => {
    // console.log('getOptsAsync')
    setLoading(true)
    const resp = await dataProvider.getList('supplier', {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'name', order: 'ASC' },
      filter: { name: query },
    })
    setLoading(false)
    return resp.data
  }

  const [inputValue, setInputValue] = React.useState('')
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      // console.log('getOptsDelayed')
      setOptions([])
      getOptionsAsync(text).then(callback)
    }, 500),
    [],
  )

  useLazyEffect(() => {
    // console.log('uleGetOptsDelayed')

    getOptionsDelayed(inputValue, (filteredOptions) => {
      open && setOptions(filteredOptions)
    })
  }, [open, inputValue, getOptionsDelayed])

  // console.log('IR', values)

  return (
    <TableRow style={{ backgroundColor: colours[colourIndex] }}>
      <BodyTableCell align="right" style={{ padding: '0px', position: 'relative' }}>
        <Autocomplete
          id="asynchronous-demo"
          style={{ height: '100%' }}
          open={openSupplier}
          onOpen={() => {
            setOpenSupplier(true)
          }}
          onClose={() => {
            setOpenSupplier(false)
          }}
          value={values.products[productIndex].supplier}
          onChange={(e, v) => form.change(`products[${productIndex}].supplier`, v)}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading}
          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="standard"
              style={{ height: '100%' }}
              // onChange={(e) => {
              //   setLoading(true)
              //   setSearchFilter(e.target.value)
              // }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Product Name`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="center" style={{ padding: '0px' }}>
        {values.products[productIndex].quotes[index].photos?.length > 0 ? (
          <img
            style={{ maxHeight: '50px', maxWidth: '50px' }}
            src={values.products[productIndex].quotes[index].photos[0].preview}
            alt=""
          />
        ) : null}
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          multiline
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Product Description`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Packaging`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Set-up Cost`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Sample Cost`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Sample Lead Time (days)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Quantity`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Unit Price (USD)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Lead Time (days)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Carton Qty`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Carton GW (kg)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Carton L (cm)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Carton W (cm)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].Carton H (cm)`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <TextInput
          variant="standard"
          fullWidth
          style={{
            padding: '0px 10px',
            margin: '0px',
            height: '100%',
          }}
          source={`products[${productIndex}].quotes[${index}].HTS Code`}
          label={false}
          // validate={required()}
        />
      </BodyTableCell>
    </TableRow>
  )
}

export default ExcelImport
