import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { useNotify, useDataProvider, TextInput } from 'react-admin'
import { useDropzone } from 'react-dropzone'
import { uniqueId } from 'lodash'
import { useFormState, useForm } from 'react-final-form'
import { Markup } from 'interweave'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import NumberFormat from '../../components/CustomNumberFormat'
import uploadFile from '../../../dataProvider/aws_upload'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import RichTextInput from 'ra-input-rich-text'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFF',
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    backgroundColor: '#FFF',
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const useStyles = makeStyles(() => ({
  numberTableInput: {
    width: '99%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '1%',
    // height: '30px',
    minHeight: '30px',
    minWidth: '120px',
    padding: '0px',
    '& .MuiInput-underline::before': {
      borderBottom: 'none !important',
    },
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      minHeight: '30px',
      '& .input': {
        border: 'solid 1px green',
        width: '50%',
      },
    },
  },
  fieldInputLeft: {
    minWidth: '200px',
  },
  fieldInput: {
    minWidth: '120px',
    marginLeft: '20px',
  },
  primaryPhoto: {
    // border: 'dashed gray 2px',
    width: '250px',
    height: '250px',
    position: 'absolute',
    zIndex: '-1',
  },
  primaryPhotoZoomContainer: {
    border: 'solid gray 1vw',
    borderRadius: '1vw',
    maxWidth: '65vw',
    // maxHeight: '50vh',
    position: 'absolute',
    backgroundColor: 'white',
    top: '0px',
    left: '0px',
    // minWidth: '50vw',
    // minHeight: '50vh',
    zIndex: '1000',
  },
  primaryPhotoZoom: {
    maxWidth: '64vw',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '400px',
    // margin: '0px 0px 5px',
  },
  previewImageBox: {
    top: '0px',
    left: '0px',
    width: '247px',
    height: '247px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    border: 'solid gray 2px',
    // '&:hover': {
    //   border: 'gray dashed 2px',
    // },
  },
  previewImage: {
    zIndex: '100',
    maxWidth: '100%',
    maxHeight: '100%',
    // cursor: 'pointer',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '250px',
    maxHeight: '250px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  deleteBtn: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  zoomBtn: {
    position: 'absolute',
    top: '1px',
    left: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
  imageDropZone: {
    width: '250px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  imageDropZoneEditing: {
    width: '250px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    cursor: 'pointer',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  loadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '115px',
    top: '115px',
  },
  rtfInputBox: {
    '& .ra-rich-text-input': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '220px',
      '& .MuiFormLabel-root': {
        fontSize: '12px',
      },
    },
  },
  markupText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
}))

const ProductsFieldInput = () => {
  const { values } = useFormState()
  const form = useForm()
  const dataProvider = useDataProvider()
  const [saving, setSaving] = useState(false)

  // console.log(values)

  return (
    <Box>
      <Box display="flex">
        <Box flex={5}>
          <Box display="flex" justifyContent="space-between">
            <TextFieldInput
              variant="standard"
              fullWidth
              field="client_quote_created_by"
              resource="project"
              id={values.id}
              label="Created by:"
              style={{ minWidth: '220px' }}
              value={values.client_quote_created_by}
            />
            <DateFieldInput
              variant="standard"
              fullWidth
              field="client_quote_created_at"
              formField="client_quote_created_at"
              resource="project"
              style={{ minWidth: '220px' }}
              id={values.id}
              label="Created date:"
            />
          </Box>
          <TextFieldInput
            variant="standard"
            fullWidth
            field="client_quote_project_name"
            resource="project"
            id={values.id}
            label="Client project name:"
            value={values.client_quote_project_name}
          />

          <TextFieldInput
            variant="outlined"
            fullWidth
            multiline
            minRows={10}
            field="client_quote_notes"
            resource="project"
            id={values.id}
            label="Product or project comments:"
            value={values.client_quote_notes}
          />
        </Box>
        <Box flex={4} />
      </Box>

      {values.products.map((product, productIndex) => (
        <ProductRow
          key={`product${product.id}`}
          id={product.id}
          productIndex={productIndex}
          projectId={values.id}
          saving={saving}
          setSaving={setSaving}
        />
      ))}
      <Button
        onClick={async () => {
          const resp = await dataProvider.create('project_products', {
            data: { project_id: values.id },
          })
          const formProducts = values.products || []

          form.change('products', [...formProducts, { id: resp.data.id }])
        }}
      >
        Add product
      </Button>
    </Box>
  )
}

const ProductRow = ({ id, productIndex, projectId, saving, setSaving }) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [zoomOpen, setZoomOpen] = useState(null)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    multiple: false,
    disabled: !editing,
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles)
      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploading(true)

        const acceptedFile = acceptedFiles[0]

        // console.log(acceptedFile)

        const file = await uploadFile(acceptedFile)

        try {
          // check if the product id is an integer, if not create a product; get the id
          const isNewProduct = typeof values.products[productIndex].id !== 'number'
          // console.log(isNewProduct)

          let productId
          if (isNewProduct) {
            const resp = await dataProvider.create('project_products', {
              data: { project_id: projectId },
            })
            productId = resp?.data?.id
          } else {
            productId = values.products[productIndex].id
          }

          // console.log(productId)
          // update the form product id if necessary
          isNewProduct && form.change(`products[${productIndex}].id`, productId)
          // update the product image
          const imageResp = await dataProvider.create('project_products', {
            data: {
              id: productId,
              project_id: projectId,
              image: { data: { url: file, filename: acceptedFile.name } },
            },
            on_conflict: {
              constraint: 'project_products_pkey',
              update_columns: ['image_id'],
            },
          })

          // console.log(imageResp)

          form.change(`products[${productIndex}].image`, {
            id: imageResp.data.image_id,
            filename: acceptedFile.name,
            url: file,
          })

          notify('Upload Complete', { type: 'info' })
          setUploading(false)
        } catch (error) {
          console.log(error)
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  if (!values.products[productIndex]) {
    return null
  }

  const preview = values.products[productIndex].image?.url || null

  return (
    <Card style={{ marginTop: '20px', padding: '0px 20px 20px', border: '1px solid #ABABAB' }}>
      {zoomOpen ? (
        <Box className={classes.primaryPhotoZoomContainer}>
          <img
            src={zoomOpen}
            className={classes.primaryPhotoZoom}
            onClick={async () => {
              setZoomOpen(null)
            }}
          />
        </Box>
      ) : null}

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        {editing ? (
          <Box display="flex">
            <IconButton
              onClick={async () => {
                try {
                  // check if the product id is an integer
                  const isNewProduct = typeof values.products[productIndex].id !== 'number'
                  // console.log(isNewProduct)
                  let productId

                  // if new product, create product with base product fields (name/notes)
                  if (isNewProduct) {
                    const resp = await dataProvider.create('project_products', {
                      data: { project_id: projectId },
                    })
                    productId = resp?.data?.id
                  } else {
                    // else if old product, update base product fields (name/notes)
                    await dataProvider.update('project_products', {
                      data: {
                        id,
                        product_name: values.products[productIndex].product_name,
                        product_notes: values.products[productIndex].product_notes,
                      },
                    })
                    productId = values.products[productIndex].id
                  }
                  // console.log(productId)
                  isNewProduct && form.change(`products[${productIndex}].id`, productId)

                  // loop prices
                  await Promise.all(
                    values.products[productIndex].prices?.map(async (price, priceIndex) => {
                      if (typeof price.id === 'number') {
                        await dataProvider.update('project_products_prices', {
                          data: {
                            id: price.id,
                            fob_price: parseInt(price.fobPrice * 100),
                            ddp_sea: parseInt(price.ddpSea * 100),
                            ddp_air: parseInt(price.ddpAir * 100),
                            quantity: price.quantity,
                            total_lead_time: price.total_lead_time,
                          },
                        })
                      } else {
                        const priceResp = await dataProvider.create('project_products_prices', {
                          data: {
                            project_product_id: id,
                            fob_price: parseInt(price.fobPrice * 100),
                            ddp_sea: parseInt(price.ddpSea * 100),
                            ddp_air: parseInt(price.ddpAir * 100),
                            quantity: price.quantity,
                            total_lead_time: price.total_lead_time,
                          },
                        })
                        console.log(priceResp)

                        form.change(
                          `products[${productIndex}].prices[${priceIndex}].id`,
                          priceResp.data.id,
                        )
                      }
                    }),
                  )

                  notify('Update Complete', { type: 'info' })
                  setEditing(false)
                  setSaving(false)
                } catch (error) {
                  // console.log(error)
                  notify(error.message, { type: 'warning' })
                  setEditing(false)
                  setSaving(false)
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </Box>
        ) : deleting ? (
          <Box display="flex">
            <IconButton
              onClick={async () => {
                try {
                  setSaving(true)
                  if (typeof id === 'number') {
                    await dataProvider.delete('project_products_prices', {
                      where: { project_product_id: { _eq: id } },
                    })
                    await dataProvider.delete('project_products', { id })
                  }

                  const formProducts = values.products
                  formProducts.splice(productIndex, 1)

                  // console.log(formProducts)

                  form.change('products', formProducts)

                  notify('Product Deleted', { type: 'info' })
                  setSaving(false)
                } catch (error) {
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                  setSaving(false)
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleting(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <Box display="flex">
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleting(true)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box display="flex">
        <Box mr="20px" style={{ position: 'relative' }}>
          <div
            {...getRootProps({
              className: !editing ? classes.imageDropZone : classes.imageDropZoneEditing,
            })}
          >
            <input {...getInputProps()} />
            {!uploading && editing && <span>{`Drag / Upload`}</span>}
          </div>

          {uploading && <CircularProgress className={classes.loadingIcon} />}
          {preview && (
            <div className={classes.previewImageBox}>
              <img src={preview} className={classes.previewImage} alt="" />
              {editing && (
                <IconButton
                  aria-label="delete"
                  className={classes.deleteBtn}
                  onClick={async () => {
                    try {
                      setUploading(true)
                      await dataProvider.update('project_products', {
                        data: { id, image_id: null },
                      })
                      await form.batch(() => {
                        form.change(`products[${productIndex}].image`, null)
                        form.change(`products[${productIndex}].image_id`, null)
                      })
                      notify('Image Deleted', { type: 'info' })
                      setUploading(false)
                    } catch (error) {
                      notify(error.message, { type: 'warning' })
                      setUploading(false)
                    }
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="zoomin"
                className={classes.zoomBtn}
                onClick={async () => {
                  setZoomOpen(preview)
                }}
              >
                <ZoomInIcon />
              </IconButton>
            </div>
          )}
        </Box>
        <Box>
          <TextInput
            source={`products[${productIndex}].product_name`}
            label="Product name:"
            fullWidth
            variant="standard"
            margin="none"
            disabled={!editing}
            className={`${classes.fieldInputNoMP}`}
          />
          {editing ? (
            <Box display="flex" className={classes.rtfInputBox}>
              <RichTextInput
                label={false}
                disabled
                source={`products[${productIndex}].product_notes`}
                className={classes.rtfInputBox}
              />
            </Box>
          ) : (
            <Box m="10px 0px" display="flex" flexDirection="column">
              <span style={{ fontSize: '10px', marginBottom: '5px', color: 'rgba(0, 0, 0, 0.38)' }}>
                Product notes:
              </span>
              <Markup
                className={classes.markupText}
                content={values.products[productIndex].product_notes}
              />
            </Box>
          )}
          <TableContainer component="div">
            <Table
              aria-label="simple table"
              style={{ overflow: 'hidden', backgroundColor: 'lightgray' }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    FOB
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    DDP Sea
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    DDP Air
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '19%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Total Lead Time
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '5%', fontSize: '10px', padding: '10px' }}
                    align="left"
                  >
                    Delete
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.products[productIndex].prices?.map((item, index) => (
                  <QuoteRow
                    key={`price${item.id}`}
                    productIndex={productIndex}
                    index={index}
                    editing={editing}
                    setEditing={setEditing}
                    id={item.id}
                    setSaving={setSaving}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {editing ? (
            <IconButton
              aria-label="add-quote-price"
              // className={classes.infoBtn}
              disabled={!editing || saving}
              onClick={async () => {
                try {
                  const quotePrices =
                    values.products[productIndex].prices?.length > 0
                      ? [...values.products[productIndex].prices]
                      : []
                  quotePrices.push({
                    id: uniqueId(),
                  })
                  await form.change(`products[${productIndex}].prices`, quotePrices)
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              <AddIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    </Card>
  )
}

const QuoteRow = ({ index, productIndex, editing, setEditing, id, setSaving }) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  if (!values.products[productIndex].prices[index]) {
    return null
  }

  return (
    <TableRow key={`priceRow${index}`}>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`products[${productIndex}].prices[${index}].quantity`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          source={`products[${productIndex}].prices[${index}].fobPrice`}
          // validate={required()}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell align="right" style={{ padding: '0px' }}>
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          source={`products[${productIndex}].prices[${index}].ddpSea`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          source={`products[${productIndex}].prices[${index}].ddpAir`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="right"
        style={{
          padding: '0px',
        }}
      >
        <NumberFormat
          disabled={!editing}
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`products[${productIndex}].prices[${index}].total_lead_time`}
          // validate={required()}
        />
      </BodyTableCell>
      <BodyTableCell
        align="center"
        style={{
          padding: '0px',
        }}
      >
        {editing && (
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                if (typeof id === 'number') {
                  await dataProvider.delete('project_products_prices', { id })
                }

                const formPrices = values.products[productIndex].prices
                formPrices.splice(index, 1)
                // console.log(formPrices)
                form.change(`products[${productIndex}].prices`, formPrices)
                notify('Price Deleted', { type: 'info' })
                setSaving(false)
                setEditing(false)
              } catch (error) {
                console.log(error)
                setSaving(false)
              }
            }}
            style={{ maxWidth: '35px', maxHeight: '35px' }}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        )}
      </BodyTableCell>
    </TableRow>
  )
}

export default ProductsFieldInput
