import React from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  FunctionField,
  SelectInput,
  // AutocompleteInput,
  // useTranslate,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  TopToolbar,
  ExportButton,
  CreateButton,
  useRedirect,
} from 'react-admin'
import { Chip, Tooltip, Button, Box } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { ImportButton } from 'react-admin-import-csv'
import SupplierDefaultImg from '../../assets/supplier_default.png'
import { getDisplayName, duplicateProduct } from '../../utils/utils'

const ListActions = (props) => {
  const { className } = props
  return (
    <TopToolbar className={className}>
      <CreateButton />
      <ExportButton />
      <ImportButton {...props} />
    </TopToolbar>
  )
}

export const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Product Name"
      style={{ minWidth: '200px' }}
    />
    <ReferenceInput
      source="supplier"
      reference="suppliers"
      // sort={{ field: 'id', order: 'ASC' }}
      alwaysOn
      style={{ minWidth: '200px' }}
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="sca"
      reference="users"
      label="SCA"
      filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="category"
      reference="category"
      // sort={{ field: 'id', order: 'ASC' }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText="name" fullWidth />
    </ReferenceInput>
    <DateInput
      source="date_gte"
      label="Quote date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="Quote date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
  </Filter>
)

const ProductList = (props) => {
  // const user = JSON.parse(localStorage.getItem('user'))
  const redirect = useRedirect()

  return (
    <div>
      <List
        {...props}
        filter={{
          // eslint-disable-next-line camelcase
          // sca_user_id: props.isManager ? null : user?.id,
          is_catalogue: true,
          status: props.filter?.status ? props.filter?.status : null,
        }}
        filters={props.basePath === '/catalogueProducts' ? <ProductFilter /> : null}
        actions={<ListActions />}
        // actions={null}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid path="products" rowClick="edit">
          <TextField source="id" label="Id" />
          <FunctionField
            label="Photo"
            render={(record) => (
              <div style={{ maxHeight: '50px', maxWidth: '50px' }}>
                <img
                  style={{ maxHeight: '50px' }}
                  src={record.thumbnail_photo?.url || SupplierDefaultImg}
                  // alt=""
                  alt={<img src={SupplierDefaultImg} alt="" />}
                />
              </div>
            )}
          />
          <FunctionField
            label="Product Name"
            render={(record) => {
              const name = record.name
              return (
                <Box display="flex" justifyContent="space-between">
                  {name?.length > 40 ? (
                    <Tooltip title={name}>
                      <span>{`${name?.substring(0, 40)}...`}</span>
                    </Tooltip>
                  ) : (
                    <span>{name}</span>
                  )}
                  {record.product_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes[0].status === 'ACTIVE' &&
                    record.product_quotes[0]?.scm_quotes[0]?.prices?.filter((el) => !!el.fob_price)
                      ?.length > 0 &&
                    record.product_quotes[0]?.scm_quotes[0]?.payment_terms?.length > 0 && (
                      <span style={{ fontSize: '12px', color: 'blue' }}>Live!</span>
                    )}
                </Box>
              )
            }}
          />
          <FunctionField
            label="Categories"
            render={(record) =>
              record.product_categories?.map((cat) => (
                <Chip key={`cats${cat.id}`} label={cat.category.name} />
              ))
            }
          />
          {/* <TextField source="status" label="Status" /> */}

          <FunctionField
            render={(record) => {
              const minPrice =
                record.product_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes[0].prices?.length > 0
                  ? record.product_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes[0].prices?.reduce((prev, curr) =>
                      prev.quantity < curr.quantity ? prev : curr,
                    )
                  : null
              const maxPrice =
                record.product_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes[0].prices?.length > 1
                  ? record.product_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes[0].prices?.reduce((prev, curr) =>
                      prev.quantity > curr.quantity ? prev : curr,
                    )
                  : null

              return (
                <Box display="flex" flexDirection="column">
                  {minPrice ? <span>{minPrice.quantity}</span> : null}
                  {maxPrice ? <span> {maxPrice.quantity}</span> : null}
                </Box>
              )
            }}
            label="Quantity"
          />
          <FunctionField
            render={(record) => {
              const minPrice =
                record.product_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes[0].prices?.length > 0
                  ? record.product_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes[0].prices?.reduce((prev, curr) =>
                      prev.quantity < curr.quantity ? prev : curr,
                    )
                  : null
              const maxPrice =
                record.product_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes?.length > 0 &&
                record.product_quotes[0].scm_quotes[0].prices?.length > 1
                  ? record.product_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes?.length > 0 &&
                    record.product_quotes[0].scm_quotes[0].prices?.reduce((prev, curr) =>
                      prev.quantity > curr.quantity ? prev : curr,
                    )
                  : null
              return (
                <Box display="flex" flexDirection="column">
                  {minPrice ? (
                    <span>
                      {`$${(minPrice.fob_price / 100).toFixed(2)}/$${(
                        minPrice.estimated_freight_sea / 100
                      ).toFixed(2)}/$${(minPrice.estimated_freight_air / 100).toFixed(2)}`}
                    </span>
                  ) : null}

                  {maxPrice ? (
                    <span>
                      {`$${(maxPrice.fob_price / 100).toFixed(2)}/$${(
                        maxPrice.estimated_freight_sea / 100
                      ).toFixed(2)}/$${(maxPrice.estimated_freight_air / 100).toFixed(2)}`}
                    </span>
                  ) : null}
                </Box>
              )
            }}
            label="Quote FOB/Sea/Air"
          />
          {/* <TextField source="product_quotes[0].prices[0].quantity" label="MOQ" sortable={false} />
          <FunctionField
            render={(record) =>
              record.product_quotes.length > 0 && record.product_quotes[0].prices.length > 0
                ? `$ ${(record.product_quotes[0].prices[0].unit_price / 100).toFixed(2)}`
                : ''
            }
            label="Price"
          /> */}
          <FunctionField
            render={(record) =>
              record.product_quotes.length > 0 &&
              record.product_quotes[0].scm_quotes?.length > 0 &&
              record.product_quotes[0].scm_quotes[0].payment_terms?.length > 0
                ? record.product_quotes[0].scm_quotes[0].payment_terms.map((item, index) =>
                    index === 0 ? item.value : '-' + item.value,
                  )
                : ''
            }
            label="Payment Terms"
          />
          {/* <TextField source="product_quotes[0].prices[0].unit_price" label="Price" /> */}
          <FunctionField render={(record) => getDisplayName(record.sca)} label="SCA" />
          <DateField source="created_at" label="Created Date" />
          <FunctionField
            render={(record) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation()

                  const newRecord = duplicateProduct({ record })

                  redirect('create', 'products', {}, null, {
                    record: newRecord,
                  })
                }}
              >
                Duplicate
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </div>
  )
}

export default ProductList
