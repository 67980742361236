import React, { useState } from 'react'
import {
  useDataProvider,
  useNotify,
  useRefresh,
  FormWithRedirect,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import {
  Button,
  Dialog,
  //   DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import Confirm from '../../components/Confirm'

const OrganisationAddForm = ({ appUserId }) => {
  const [open, setOpen] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSubmit = async (submission) => {
    const params = {
      app_user_id: appUserId,
      organisation_id: submission.organisation_id,
    }

    try {
      await dataProvider.create('organisation_members', {
        objects: params,
      })

      setOpen(false)
      refresh()

      // notify('Created Successfully.', 'info')
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Add Organisation</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <FormWithRedirect
          render={(formProps) => (
            <div>
              <DialogContent>
                <ReferenceInput
                  label="Select new organisation"
                  source="organisation_id"
                  reference="organisation"
                  perPage={100}
                  validate={[required()]}
                >
                  <SelectInput optionText={(record) => record.name} fullWidth />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Confirm
                  redirect={null}
                  record={formProps.record}
                  basePath={formProps.basePath}
                  label="SAVE"
                  undoable
                  invalid={formProps.invalid}
                  handleSubmit={async (submission) => {
                    await handleSubmit(submission)
                  }}
                  saving={formProps.saving}
                  disabled={formProps.saving || formProps.invalid}
                  resource="organisation_members"
                />
                <Button onClick={() => setOpen(false)}>Cancel</Button>
              </DialogActions>
            </div>
          )}
        />
      </Dialog>
    </div>
  )
}

export default OrganisationAddForm
