import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  // InputLabel,
  IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { uniqueId } from 'lodash'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useDataProvider, useNotify } from 'react-admin'
import useStyles from '../styles'
import CheckoutForm from './checkoutIntent'
import NumberFormat from 'react-number-format'
import { getDisplayName, getDisplayAddress } from '../../../../utils/utils'

const OfferMakeConsumerPurchase = ({ disabled, data, stripePromise }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(data)
  const [clientSecret, setClientSecret] = useState('')
  const [appUsers, setAppUsers] = useState([])
  const [orderVariants, setOrderVariants] = useState([])
  // const [clients, setClients] = useState([])
  const [clientAddresses, setClientAddresses] = useState([])
  const [deliveryAddress, setDeliveryAddress] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')

  const [appUser, setAppUser] = useState('')
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(0)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const calcTotalQuantity = async () => {
      const totalQuantity = orderVariants.reduce((previousValue, currentValue) => {
        return previousValue + currentValue?.quantity || 0
      }, 0)
      //   console.log(totalQuantity)
      setQuantity(totalQuantity)
    }

    const abortController = new AbortController()
    orderVariants?.length > 0 && calcTotalQuantity()
    return () => {
      abortController.abort()
    }
  }, [orderVariants])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userData } = await dataProvider.getAppUsers()
        !abortController.signal.aborted && setAppUsers(userData.app_user)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const { data: clientsTemp } = await dataProvider.getList('organisation', {
  //         pagination: { page: 1, perPage: 100 },
  //         sort: { field: 'name', order: 'ASC' },
  //         filter: { member_id: appUser.id },
  //       })
  //       !abortController.signal.aborted && setClients(clientsTemp)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   const abortController = new AbortController()
  //   getData()
  //   return () => {
  //     abortController.abort()
  //   }
  // }, [dataProvider, notify, appUser])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('address', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'type', order: 'ASC' },
          filter: { organisation_id: appUser.default_organisation.id },
        })
        !abortController.signal.aborted && setClientAddresses(clientsTemp)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    // console.log(appUser)
    setDeliveryAddress('')
    appUser && getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, appUser])

  useEffect(() => {
    const getPI = async () => {
      if (open) {
        console.log(orderVariants)
        await dataProvider
          .createOfferConsumerOrder('offer', {
            clientId: appUser.default_organisation_id,
            buyerId: appUser.id,
            deliveryAddress: getDisplayAddress(deliveryAddress),
            contactName: contactName,
            contactEmail: appUser.email,
            contactPhone: contactPhone,
            platform: 'ADMIN',
            notes: '',
            items: [
              {
                offerId: data.id,
                variants: orderVariants.map((el) => ({
                  quantity: el.quantity,
                  options: el.variant.saleable_product_variant.variant.values?.map((opt) => ({
                    option_id: opt.option_id,
                    option_name: opt.option_name,
                    value: opt.value,
                  })),
                })),
              },
            ],
          })
          .then((result) => {
            // console.log('Success:', result)
            setClientSecret(result.data.clientSecret)
          })
          .catch((error) => {
            console.error('Error:', error)
            notify(error.message.split('.')[0], { type: 'warning' })
          })
        // console.log(res)
      }
    }
    getPI()
  }, [open])

  const renderVariantOption = (item) => {
    const colourValue = item.saleable_product_variant.variant.values.find(
      (obj) => obj.option === 'Color',
    )

    return (
      <Box display="flex" alignItems="center">
        <span>SKU:{item.saleable_product_variant.variant.sku}</span>
        {colourValue && (
          <div
            style={{
              marginLeft: '3px',
              width: '15px',
              height: '15px',
              backgroundColor: colourValue.value,
              borderRadius: '4px',
            }}
          />
        )}
        <span style={{ marginLeft: '3px' }}>
          {`${item.saleable_product_variant.variant.values
            ?.map((value) => value.value)
            .join(', ')}`}
        </span>
      </Box>
    )
  }

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" p="20px 0">
        {appUsers?.length > 0 ? (
          <Box display="flex" flexDirection="column">
            <span className={classes.title}>User Selection</span>
            <Box display="flex">
              <Select
                value={appUser}
                renderValue={(item) => getDisplayName(item, 20, 20, false)}
                onChange={(e) => setAppUser(e.target.value)}
                disabled={disabled}
                style={{ width: '200px' }}
              >
                {appUsers?.map((item) => (
                  <MenuItem value={item} key={item.id}>
                    {getDisplayName(item, 20, 20, true)}
                  </MenuItem>
                ))}
              </Select>
              <Select
                style={{ width: '200px', marginLeft: '25px' }}
                value={deliveryAddress}
                renderValue={(item) => getDisplayAddress(item)}
                onChange={(e) => setDeliveryAddress(e.target.value)}
              >
                {clientAddresses?.map((item) => (
                  <MenuItem value={item} key={item.id}>
                    {getDisplayAddress(item)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        ) : null}
        <Box display="flex">
          <TextField
            id="standard-text"
            label="Contact Name"
            type="text"
            fullWidth
            value={contactName}
            variant="standard"
            style={{ width: '200px' }}
            onChange={(e) => {
              // setQuantity(e.target.value)
              setContactName(e.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
          />
          <TextField
            id="standard-text"
            label="Contact Phone"
            type="text"
            fullWidth
            value={contactPhone}
            style={{ width: '200px', marginLeft: '25px' }}
            variant="standard"
            onChange={(e) => {
              // setQuantity(e.target.value)
              setContactPhone(e.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
          />
        </Box>
        <Box display="flex">
          <Box display="flex" flexDirection="column" ml="20px">
            <span className={classes.bodyText}>Stripe Connected Account ID:</span>
            <span className={classes.bodyText}>
              {appUser?.default_organisation?.stripe_connected_account_id || ''}
            </span>
          </Box>
          <Box display="flex" flexDirection="column" ml="20px">
            <span className={classes.bodyText}>Stripe Customer Account ID:</span>
            <span className={classes.bodyText}>
              {appUser?.default_organisation?.stripe_customer_account_id || ''}
            </span>
          </Box>
        </Box>
      </Box>
      <Box p={'20px'} display="flex" flexDirection="column">
        <span className={classes.title}>Variants</span>
        {orderVariants.map((el, index) => (
          <Box key={`ov${el.id}`} display="flex" flexDirection="row">
            <Select
              // labelId="deliveryAddressLabel"
              value={orderVariants[index].variant}
              renderValue={(item) => item.saleable_product_variant.variant.sku}
              style={{ minWidth: '100px' }}
              onChange={(e) => {
                const newOrderVariants = [...orderVariants]

                newOrderVariants[index].variant = e.target.value
                setOrderVariants(newOrderVariants)
              }}
            >
              {data.batches?.map((item) => {
                return (
                  <MenuItem value={item} key={item.id}>
                    {renderVariantOption(item)}
                  </MenuItem>
                )
              })}
            </Select>
            <TextField
              id="standard-number"
              label="Quantity"
              type="number"
              value={orderVariants[index].quantity}
              variant="standard"
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                // setQuantity(e.target.value)
                const newOrderVariants = [...orderVariants]

                newOrderVariants[index].quantity = Number(e.target.value)
                setOrderVariants(newOrderVariants)
              }}
              inputProps={{
                min: data.minimum_purchase_quantity > 0 ? data.minimum_purchase_quantity : 0,
                max: data.quote?.quantity - data.purchased?.aggregate?.sum?.quantity || null,
                step: data.minimum_purchase_quantity || 1,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
            />
            <NumberFormat
              value={
                ((data.group_purchase_price * orderVariants[index].quantity) / 100).toFixed(2) ||
                '$0.00'
              }
              // className='numberFormat'
              displayType="text"
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
            />
            <IconButton
              onClick={() => {
                const newOrderVariants = [...orderVariants]
                newOrderVariants.splice(index, 1)
                setOrderVariants(newOrderVariants)
              }}
              style={{
                maxWidth: '30px',
                maxHeight: '30px',
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        ))}
        <IconButton
          onClick={() => {
            const newOrderVariants = [...orderVariants]
            newOrderVariants.push({ id: uniqueId(), quantity: 0, variant: '' })
            setOrderVariants(newOrderVariants)
          }}
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Box
        m="10px 20px"
        flexDirection="column"
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <NumberFormat
          value={quantity}
          // className='numberFormat'
          displayType="text"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
        />
        <NumberFormat
          value={((data.group_purchase_price * quantity) / 100).toFixed(2) || '$0.00'}
          // className='numberFormat'
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
        />
        <Button
          className={classes.makePurchaseButton}
          disabled={
            !appUser?.id || !deliveryAddress?.id || quantity < 1
              ? true
              : false || data.moq <= data.purchased?.aggregate?.sum?.quantity
          }
          onClick={handleOpen}
        >
          Make Purchase
        </Button>
      </Box>
      {appUser?.id && !(data.moq <= data.purchased?.aggregate?.sum?.quantity) && (
        <MakePurchaseOrderDialog
          open={open}
          handleClose={handleClose}
          clientSecret={clientSecret}
          options={options}
          stripePromise={stripePromise}
          offerId={data.id}
        />
      )}
    </Box>
  )
}

const MakePurchaseOrderDialog = ({
  open,
  handleClose,
  clientSecret,
  options,
  stripePromise,
  offerId,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <Box>
          <DialogTitle>Make a Purchase</DialogTitle>
          {clientSecret && open && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm id={offerId} />
            </Elements>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default OfferMakeConsumerPurchase
