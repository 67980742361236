// import buildDataProvider from 'ra-data-graphql'
import buildDataProvider from './buildDataProvider'
import {
  GET_ONE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE_MANY,
} from './fetchActions'
import buildQuery from './buildQuery'
import buildGqlQuery from './buildGqlQuery'
import schema from '../graphql.schema.json'
import API_HOST from '../api'

export { buildQuery, buildGqlQuery }

const getGqlResource = (resource) => {
  switch (resource) {
    case 'contacts':
      return 'contact'

    case 'addresses':
      return 'address'

    case 'tags':
      return 'tag'

    case 'users':
      return 'user'

    case 'app_users':
      return 'app_user'

    case 'categories':
      return 'category'

    case 'notifications':
      return 'notification'

    case 'push_notifications':
      return 'push_notification'

    case 'scmQuote':
    case 'scmQuotes':
    case 'quote':
    case 'quotes':
    case 'scm_quotes':
    case 'client_quote':
    case 'client_quotes':
      return 'scm_quote'

    case 'bankBranch':
      return 'bankBranch'

    case 'shipping_port':
    case 'shippingPorts':
    case 'shippingPort':
      return 'shipping_port'

    case 'scmAgents':
      return 'scm_agent'

    case 'organisations':
    case 'clients':
    case 'client':
      return 'organisation'

    case 'organisation_member':
      return 'organisation_members'

    case 'roles':
      return 'role'

    case 'user_roles':
      return 'user_role'

    case 'qualityAgencies':
      return 'qualityAgency'

    case 'saleableProduct':
    case 'saleableProducts':
    case 'saleable_products':
    case 'solidBlanks':
    case 'cliqueStock':
      return 'saleable_product'

    case 'saleableProductVariant':
    case 'saleableProductVariants':
    case 'saleable_product_variant':
      return 'saleable_product_variants'

    case 'carouselContent':
      return 'app_carousel_products'

    case 'defaultRates':
    case 'defaultRate':
    case 'default_rates':
    case 'default_rate':
      return 'global_preferences'

    case 'platformConfig':
    case 'platform_config':
    case 'platformConfiguration':
    case 'platform_configuration':
    case 'configSection':
      return 'config_section'

    case 'projects':
      return 'project'

    case 'task':
      return 'tasks'

    case 'sourcingRequest':
    case 'request':
    case 'requests':
    case 'sourcingRequests':
    case 'sourcing_requests':
      return 'sourcing_request'

    case 'products':
    case 'catalogueProduct':
    case 'catalogueProducts':
    case 'customCatalogueProduct':
    case 'customCatalogueProducts':
      return 'product'

    case 'product_debug':
      return 'productDebug'

    case 'product_images':
      return 'product_image'

    case 'invoices':
      return 'invoice'

    case 'recommendations':
      return 'recommendation'

    case 'suppliers':
      return 'supplier'

    case 'supplier_contacts':
      return 'supplier_contact'

    case 'factories':
      return 'factory'

    case 'supplier_addresses':
      return 'supplier_address'

    case 'offers':
    case 'customOffer':
      return 'offer'

    case 'orders':
      return 'order'

    case 'consumerOrder':
    case 'consumerOrders':
    case 'consumer_Order':
    case 'consumer_Orders':
    case 'consumer_orders':
      return 'consumer_order'

    case 'offer_purchases':
    case 'offerPurchase':
    case 'offerPurchases':
      return 'offer_purchase'

    case 'access':
      return 'admin_profile'

    case 'qualityInspectionTypes':
      return 'qualityInspectionType'

    case 'qualityInspectionCityRates':
      return 'qualityInspectionCityRate'

    case 'brands':
      return 'brand'

    case 'paymentType':
    case 'paymentTypes':
    case 'certificationType':
    case 'certificationTypes':
    case 'certificateType':
    case 'certificateTypes':
    case 'material':
    case 'materials':
    case 'imprint':
    case 'imprints':
    case 'imprintTechnique':
    case 'imprintTechniques':
    case 'packagingStyle':
    case 'packagingStyles':
      return 'system_dictionary'

    default:
      return resource
  }
}

const defaultOptions = {
  buildQuery,
  clientOptions: {
    uri: `${API_HOST}graphql`,
  },
  introspection: {
    operationNames: {
      [GET_LIST]: (resource) => `${resource.name}`,
      [GET_ONE]: (resource) => `${resource.name}`,
      [GET_MANY]: (resource) => `${resource.name}`,
      [GET_MANY_REFERENCE]: (resource) => `${resource.name}`,
      [CREATE]: (resource) => `insert_${resource.name}`,
      [UPDATE]: (resource) => `update_${resource.name}`,
      [UPDATE_MANY]: (resource) => `update_${resource.name}`,
      [DELETE]: (resource) => `delete_${resource.name}`,
      [DELETE_MANY]: (resource) => `delete_${resource.name}`,
    },
    schema: schema.__schema,
  },
}

export default (options) =>
  buildDataProvider({
    ...defaultOptions,
    ...options,
  }).then(
    (dataProvider) => (fetchType, resource, params) =>
      dataProvider(fetchType, getGqlResource(resource), params),
  )
